import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Table,
  ButtonGroup
} from 'reactstrap';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Notifications, { notify } from 'react-notify-toast';
import PerfectScrollbar from 'perfect-scrollbar';
import Select from 'react-select';
import swal from 'sweetalert';
import { faEdit, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RotateSpinner } from 'react-spinners-kit';
import { getDataMenu } from 'data/menus';
import EjecutarApi from 'data/getPOST';
import { getVars ,URL_PUBLICA} from 'GlobalStorage';
import BuscarOwned from 'components/Usuarios/BuscarUserOwned';
import useWindowDimensions from 'components/useWindowDimensions';
import { required, requiredSelect } from 'data/validations';

var ps;
let Casillas = new Array();
let checkedArray = new Array();

function ElectoralRutaSeccion(props) {
  const mainPanel = useRef();
  const { height, width } = useWindowDimensions();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  const [Registros, setRegistros] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [catDL, setCatDL] = useState([]);
  const [rutaDetail, setRutaDetail] = useState([]);
  const [catTipoRuta, setcatTipoRuta] = useState([]);
  const [isModal, setisModal] = useState(false);
  const toggleModal = () => setisModal(true);
  const [freeCasillas, setFreeCasillas] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedCasillas, setSelectedCasillas] = useState(0);
  const [loadingRuta, setLoadingRuta] = useState(false);
  const [isChangeAbogado,setIsChangeAbogado] = useState(false);

  const [tipoResponsable, setTipoResponsable] = useState([3]);
  const RutaRules = {
    DL: { valid: false, rule: [requiredSelect], params: {} },
    TipoRuta: { valid: false, rule: [requiredSelect], params: {} },
    NombreRuta: { valid: false, rule: [required], params: {} },
    // Responsable: { valid: false, rule: [requiredSelect], params: {} },
  };
  const [flagValidations, setFlagValidations] = useState(RutaRules);
  const [flagChangeNombre, setFlagChangeNombre] = useState({
    DL: '',
    NRuta: '',
    TipoRuta: '',
  });
  const [formRuta, setFormRuta] = useState({
    id: null,
    DL: { value: '', label: '' },
    NombreRuta: '',
    TipoRuta: { value: '', label: '' },
    Responsable: { value: '', label: '' },
    NCasillas: 0,
    Casillas: [],
    Abogado:{ value: '', label: '' }
  });
  const [Errors, setErrors] = useState({
    DL: '',
    NombreRuta: '',
    TipoRuta: '',
    Responsable: '',
  });
  let i = 0;
  var NCasillasSelect = 0;
  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }

    if (props.history.action === 'PUSH') {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }

    setFormRuta({
      ...formRuta,
      NombreRuta: `DL${flagChangeNombre.DL}R${flagChangeNombre.NRuta}${flagChangeNombre.TipoRuta}`,
    });

    let menu = getDataMenu(111, token.menus);
    if (Object.keys(menu).length > 0) {
      //Cargar catálogos
      _setmenu(menu);
      EjecutarApi({}, 'getRutasCatalogosForm', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            setCatDL(res.data.cat_dl);
            setcatTipoRuta(res.data.cat_tipo_seccion);
          }
        })
        .catch((e) => {
          notify.show('Error al cargar Distritos Federales', 'error', 5000);
        });
    }

    getRutas();
  }, []);

  useEffect(() => {
    let NombreRuta = `DL${flagChangeNombre.DL}R${flagChangeNombre.NRuta}${flagChangeNombre.TipoRuta}`;
    setFormRuta({ ...formRuta, NombreRuta: NombreRuta });
  }, [flagChangeNombre]);

  useEffect(() => {
    Object.keys(RutaRules).forEach(function (key) {
      handleValidation(
        key,
        formRuta[key],
        RutaRules[key].rule,
        RutaRules[key].params
      );
    });
  }, [formRuta]);

  const getRutas = () => {
    EjecutarApi({}, 'getRutas', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoadingRuta(false);
        if (res.results) {
          setRutas(res.data);
        } else {
          notify.show('Error al obtener las rutas', 'error', 5000);
        }
      })
      .catch((e) => {
        setLoadingRuta(false);
        notify.show('Error al cargar el listado de rutas', 'error', 5000);
      });
  };

  const handleValidation = function (name, value, validators, params) {
    validators.forEach((validation) => {
      value = value === null ? '' : value;
      value = typeof value === 'string' ? value.replace(/\s+/, '') : value;
      const result = validation(value, params);
      setFlagValidations((prevstate) => ({
        ...prevstate,
        [name]: { valid: result.valid, rule: validators, params: params },
      }));

      if (!result.valid) {
        setErrors((prevstate) => ({ ...prevstate, [name]: result.message }));
      } else {
        setErrors((prevstate) => ({ ...prevstate, [name]: '' }));
      }
    });
  };

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const onChangeCheck = (e, item, k) => {
    if (e.target.checked) {
      checkedArray[k] = true;
      Casillas.push(item);
      let nCasillas = formRuta.NCasillas + Casillas.length;
      setSelectedCasillas(nCasillas);
      NCasillasSelect = formRuta.NCasillas + Casillas.length;
      if (formRuta.TipoRuta.value === 'R' && nCasillas > 50) {
        setIsDisabled(true);
        notify.show(
          'No se permite agregar más de 5 casillas al tipo de ruta seleccionado',
          'error',
          5000
        );
      } else if (
        (formRuta.TipoRuta.value === 'U' || formRuta.TipoRuta.value === 'M') &&
        nCasillas > 100
      ) {
        setIsDisabled(true);
        notify.show(
          'No se permite agregar más de 10 casillas al tipo de ruta seleccionado',
          'error',
          5000
        );
      } else {
        setIsDisabled(false);
      }
    } else {
      checkedArray[k] = false;
      let copy = Casillas.filter((row) => {
        return (
          row.Seccion !== item.Seccion ||
          row.NombreCasilla !== item.NombreCasilla
        );
      });
      let nCasillas = formRuta.NCasillas + copy.length;
      setSelectedCasillas(nCasillas);
      NCasillasSelect = formRuta.NCasillas + copy.length;
      if (nCasillas < 1) {
        setIsDisabled(true);
        notify.show(
          'No se han seleccionado las casillas que integran la ruta',
          'error',
          5000
        );
      } else if (formRuta.TipoRuta.value === 'R' && nCasillas > 5) {
        setIsDisabled(true);
        notify.show(
          'No se permite agregar más de 5 casillas al tipo de ruta seleccionado',
          'error',
          5000
        );
      } else if (
        (formRuta.TipoRuta.value === 'U' || formRuta.TipoRuta.value === 'M') &&
        nCasillas > 10
      ) {
        setIsDisabled(true);
        notify.show(
          'No se permite agregar más de 10 casillas al tipo de ruta seleccionado',
          'error',
          5000
        );
      } else {
        setIsDisabled(false);
      }

      Casillas = copy;
    }
  };

  const Exportar = () => {
    var url = new URL(
      URL_PUBLICA + `getReporteListadoElectoral?token=${token.access_token}`
    );
    window.open(url, '_blank');
  };

  const showRutaDetail = (e, row) => {
    setRegistros([]);
    e.preventDefault();
    setIsDisabled(false);
    setSelectedCasillas(row.NCasillas);
    //Casillas = [];
    setFormRuta({
      ...formRuta,
      id: row.id,
      DL: row.DL,
      NombreRuta: row.NombreRuta,
      TipoRuta: row.TipoRuta,
      Responsable: row.Responsable,
      NCasillas: row.NCasillas,
    });
    getFreeCasillas(row.DL.value);
    getRutaDetail(row.id);
  };

  const getRutaDetail = (id) => {
    setLoading4(true);
    EjecutarApi({ idRuta: id }, 'getRutaDetalle', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setRutaDetail(res.data);
        }
        setLoading4(false);
      })
      .catch((e) => {
        notify.show('Error al cargar casillas', 'error', 5000);
        setLoading4(false);
      });
  };

  const getFreeCasillas = (idDL) => {
    setLoading2(true);
    checkedArray = [];
    EjecutarApi({ idDL: idDL }, 'getFreeCasillas', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setFreeCasillas(res.data);
        }
        setLoading2(false);
      })
      .catch((e) => {
        notify.show('Error al cargar casillas', 'error', 5000);
        setLoading2(false);
      });
  };

  const deleteCasillaFromRuta = (e, row, k) => {
    e.preventDefault();
    swal({
      title: 'Atención',
      text:
        '¿Está seguro que desea eliminar la casilla de la ruta seleccionada?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: null,
          visible: true,
          className: 'btn-danger',
          closeModal: true,
        },
        confirm: {
          text: 'Si, eliminar!',
          value: true,
          visible: true,
          className: 'btn-success',
          closeModal: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        removeCasilla(row, k);
      } else {
      }
    });
  };

  const removeCasilla = (row, k) => {
    EjecutarApi(
      {
        Seccion: row.Seccion,
        idRuta: row.idRuta,
        NombreCasilla: row.NombreCasilla,
      },
      'removeCasillaFromRuta',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          notify.show(res.message, 'success', 5000);
          getRutas();
          getFreeCasillas(row.idDL);
          getRutaDetail(row.idRuta);
          let nCasillas = formRuta.NCasillas - 1;
          setSelectedCasillas(nCasillas);
          setFormRuta({ ...formRuta, NCasillas: nCasillas });
        }
      })
      .catch((e) => {
        notify.show('Error al eliminar casilla', 'error', 5000);
      });
  };

  const alert = (titulo, mensaje, icono, botones, afterWillDelete, tipo, idRuta, numeroCasillas = 0) => {
    swal({
      title: titulo,
      text: mensaje,
      icon: icono,
      buttons: true,
      dangerMode: true,
      buttons: botones,
    }).then((willDelete) => {
		afterWillDelete(willDelete, tipo, idRuta, numeroCasillas)
    });
  };
  const warnRemoveCasilla = (numeroCasillas, idRuta) => {
    alert(
      'Atención',
      '¿Está seguro de eliminar la ruta seleccionada',
      'warning',
      {
        cancel: {
          text: 'Cancelar',
          value: null,
          visible: true,
          className: 'btn-danger',
          closeModal: true,
        },
        confirm: {
          text: 'Si, eliminar!',
          value: true,
          visible: true,
          className: 'btn-success',
          closeModal: true,
        },
      },
	  afterResponse,
	  "PrimerAviso",
	  idRuta,
	  numeroCasillas
    );
  };

  const afterResponse = (willDelete, tipo, idRuta, numeroCasillas) => {
	switch(tipo){
		case "PrimerAviso":
			if (willDelete) {
				if(numeroCasillas > 0){
					warnAgainRuta(idRuta)
				}else{
					removeRuta(idRuta, true);
				}
			}
		break;
		case "SegundoAviso":
			if (willDelete) {
				removeRuta(idRuta, true);
			}
		break;
	}
  }
  /**En caso de que la ruta tenga casillas asignadas */
  const warnAgainRuta = (idRuta) => {
	alert(
		'Confirmación',
		'La ruta tiene casillas asignadas ¿Está seguro de eliminar?',
		'warning',
		{
		  cancel: {
			text: 'Cancelar',
			value: null,
			visible: true,
			className: 'btn-danger',
			closeModal: true,
		  },
		  confirm: {
			text: 'Si, eliminar!',
			value: true,
			visible: true,
			className: 'btn-success',
			closeModal: true,
		  },
		},
		afterResponse,
		"SegundoAviso",
		idRuta
	);
  };

  const removeRuta = (idRuta, confirmacion) => {
    setLoadingRuta(true);
    clearForm();
    setFreeCasillas([]);
    setRutaDetail([]);
    EjecutarApi(
      { idRuta, banderaConfirmacion: confirmacion },
      'removeRuta',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          notify.show(res.message, 'success', 5000);
          getRutas();
        }
      })
      .catch((e) => {
        setLoadingRuta(false);
        notify.show('Error al eliminar ruta', 'error', 5000);
      });
  };

  const changeDL = (e) => {
    setLoading2(true);
    let val = String(e.value);
    let dl = val.padStart(2, '00');

    checkedArray = [];
    EjecutarApi({ idDL: e.value }, 'getFreeCasillas', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          let idRuta = String(res.idRuta);
          let NRuta = idRuta.padStart(2, '00');
          setFlagChangeNombre({ ...flagChangeNombre, DL: dl, NRuta: NRuta });
          setFreeCasillas(res.data);
        }
        setLoading2(false);
        setFormRuta({ ...formRuta, DL: e });
      })
      .catch((e) => {
        setLoading2(false);
        setFormRuta({ ...formRuta, DL: e });
        notify.show('Error al cargar casillas', 'error', 5000);
      });
  };

  const changeTipoRuta = (e) => {
    if (e.value === 'R' && Casillas.length > 4) {
      notify.show(
        'No se permite agregar más de 5 casillas a la ruta',
        'error',
        5000
      );
    } else if ((e.value === 'U' || e.value === 'M') && Casillas.length > 9) {
      notify.show(
        'No se permite agregar más de 10 casillas a la ruta',
        'error',
        5000
      );
    }
    setFormRuta({ ...formRuta, TipoRuta: e });
    let val = String(e.value);
    setFlagChangeNombre({ ...flagChangeNombre, TipoRuta: val });
  };

  const getPromotor = (user) => {
    setFormRuta({
      ...formRuta,
      Responsable: {
        value: user.id == null ? '' : user.id,
        label: user.NombreCompleto == null ? '' : user.NombreCompleto,
      },
    });
  };

  const onHide = () => {
    setisModal(false);
    setIsChangeAbogado (false);
  };

  const clearForm = () => {
    setFormRuta({
      id: null,
      DL: { value: '', label: '' },
      NombreRuta: '',
      TipoRuta: { value: '', label: '' },
      Responsable: { value: '', label: '' },
      NCasillas: 0,
      Casillas: [],
    });
    setFreeCasillas([]);
    Casillas = [];
    setRutaDetail([]);
  };

  const saveNew = (params) => {
    EjecutarApi(params, 'setRuta', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          notify.show(res.message, 'success', 5000);
          getRutas();
          clearForm();
        } else {
          notify.show(res.message, 'error', 5000);
        }
        setIsDisabled(false);
        setLoading1(false);
      })
      .catch((e) => {
        setIsDisabled(false);
        setLoading1(false);
        notify.show('Error al guardar rutAA', 'error', 5000);
      });
  };

  const update = (params) => {
    EjecutarApi(params, 'updRuta', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {

          notify.show(res.message, 'success', 5000);
          getRutas();
          getRutaDetail(params.id);
          getFreeCasillas(params.idDL);
          Casillas = [];
        } else {
          notify.show(res.message, 'error', 5000);
        }
        setIsDisabled(false);
        setLoading1(false);
      })
      .catch((e) => {
        setIsDisabled(false);
        setLoading1(false);
        notify.show('Error al actualizar', 'error', 5000);
      });
  };

  const save = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    setLoading1(true);
    let flag = true;

    Object.keys(flagValidations).forEach(function (key) {
      flag = flag && flagValidations[key].valid;
    });

    let params = {
      id: formRuta.id,
      idDL: formRuta.DL.value,
      idAbogado: formRuta.Abogado.value,
      NombreAbogado: formRuta.Abogado,
      NombreRuta: formRuta.NombreRuta,
      idTipoRuta: formRuta.TipoRuta.value,
      TipoRuta: formRuta.TipoRuta.label,
      idResponsable: formRuta.Responsable.value,
      NombreResponsable: formRuta.Responsable.label,
      Casillas: Casillas,
    };

    if (!flag) {
      notify.show(
        'Verifique los datos ingresados en el formulario',
        'error',
        5000
      );
      setLoading1(false);
      return false;
    } else {
      if (formRuta.id !== null) {
        update(params);
      } else {
        delete params.id;
        saveNew(params);
      }
    }
  };
  const ExportarReporte = () => {
    var url = new URL(
      URL_PUBLICA + `getReporteRutas?token=${token.access_token}`
    );
    window.open(url, '_blank');
  };
  const changedAbogado =(e,item)=>{
    showRutaDetail(e,item);
    setTipoResponsable([8]);
    setIsChangeAbogado (true);
    
  }
  const getAbogado = (user) => {
    setFormRuta({
      ...formRuta,
      Abogado: {
        value: user.id == null ? '' : user.id,
        label: user.NombreCompleto == null ? '' : user.NombreCompleto,
      },
    });
    let params = {
      id: formRuta.id,
      idAbogado: user.id,
      NombreAbogado: user.NombreCompleto,
      Casillas : [],
      EditaRutaCompleta:2 //1-> todo ; 2 -> abogados y rc
     
    };
    update(params);
  };
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Col sm="12">
            {_menu.Ver === 1 ? (
              <Card>
                <CardHeader>
                  <h5 className="title">Asignación de Casillas a Rutas</h5>
                  <Col md="12">
                    {loading3 && (
                      <RotateSpinner
                        color={'#1261ff'}
                        size={30}
                        loading={loading3}
                      />
                    )}
                  </Col>
                  <Col>
                    {_menu.Exportar === 1 ? (
                      <Button
                        size="sm"
                        className="primary left-align"
                        color="primary"
                        onClick={() => ExportarReporte()}
                      >
                        <span className="btn-label">
                          <i className="fa fa-file-excel-o fa-lg" />
                        </span>
                        Exportar
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Col>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="6" md="6" sm="12">
                      <Card style={{ height: height - 299 }}>
                        <CardBody>
                          <FormGroup>
                            <Label className="labelForm">Distrito Local</Label>
                            <Select
                              options={catDL}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="EntidadNacimiento"
                              value={formRuta.DL}
                              onChange={(e) => changeDL(e)}
                              placeholder="Seleccionar"
                            />
                            <div className="errorFields">
                              <small>{Errors.DL}</small>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="labelForm">Tipo de Ruta</Label>
                            <Select
                              options={catTipoRuta}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="EntidadNacimiento"
                              value={formRuta.TipoRuta}
                              onChange={(e) => changeTipoRuta(e)}
                              placeholder="Entidad"
                            />
                            <div className="errorFields">
                              <small>{Errors.TipoRuta}</small>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="text"
                              value={formRuta.NombreRuta}
                              onChange={(e) => {
                                setFormRuta({
                                  ...formRuta,
                                  NombreRuta: e.target.value,
                                });
                              }}
                              placeholder="Ingrese el nombre de la ruta"
                            />
                            <div className="errorFields">
                              <small>{Errors.NombreRuta}</small>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="labelForm">Responsable</Label>
                            <InputGroup>
                              <Input
                                type="text"
                                value={
                                  formRuta.Responsable
                                    ? formRuta.Responsable.label
                                    : ''
                                }
                                // onKeyDown={onKeyDown}
                                readOnly={true}
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  style={{ margin: '0px 1px' }}
                                  color="warning"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggleModal();
                                  }}
                                >
                                  <i className="nc-icon nc-zoom-split" />
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                            <div className="errorFields">
                              <small>{Errors.Responsable}</small>
                            </div>
                          </FormGroup>
                          {_menu.Agregar === 1 && (
                            <>
                              <FormGroup>
                                <Button
                                  color="danger"
                                  onClick={() => clearForm()}
                                  size="sm"
                                >
                                  Limpiar
                                </Button>{' '}
                                <Button
                                  size="sm"
                                  color="primary"
                                  onClick={(e) => save(e)}
                                  disabled={isDisabled}
                                >
                                  Guardar
                                </Button>
                                {
                                  <Col>
                                    <RotateSpinner
                                      color={'#1261ff'}
                                      size={30}
                                      loading={loading1}
                                    />
                                  </Col>
                                }
                              </FormGroup>
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <Card style={{ height: height - 300 }}>
                        <CardHeader>
                          <Table>
                            <thead>
                              <tr>
                                <th style={{ width: width / 10 }} colSpan="4">
                                  Seleccionando {selectedCasillas} de{' '}
                                  {formRuta.TipoRuta.value === 'R' ? '5' : '10'}
                                </th>
                              </tr>
                              <tr>
                                <th style={{ width: width / 10 }}>
                                  Seleccionar
                                </th>
                                <th style={{ width: width / 10 }}>Sección</th>
                                <th style={{ width: width / 10 }}>Tipo</th>
                                <th style={{ width: width / 10 }}>Casilla</th>
                              </tr>
                            </thead>
                          </Table>
                        </CardHeader>
                        <CardBody height={300}>
                          <Table
                            width="100%"
                            style={{
                              height: height - 500,
                              overflow: 'scroll',
                              display: 'block',
                            }}
                          >
                            <tbody>
                              {freeCasillas.map((item, k) => {
                                return (
                                  <tr key={`row_${k}`}>
                                    <td
                                      style={{ width: width / 10 }}
                                      className="text-center"
                                    >
                                      <CustomInput
                                        key={k + 1}
                                        id={`check_${k + 1}`}
                                        checked={checkedArray[k]}
                                        onChange={(e) =>
                                          onChangeCheck(e, item, k)
                                        }
                                        type="checkbox"
                                      />
                                    </td>
                                    <td
                                      style={{ width: width / 10 }}
                                      className="text-center"
                                    >
                                      {item.Seccion}
                                    </td>
                                    <td style={{ width: width / 10 }}>
                                      {item.TipoSeccion}
                                    </td>
                                    <td style={{ width: width / 10 }}>
                                      {item.NombreCasilla}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                          {
                            <Col md="12">
                              <RotateSpinner
                                color={'#1261ff'}
                                size={30}
                                loading={loading2}
                              />
                            </Col>
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md="6" lg="6">
                      <Card style={{ height: height - 200 }}>
                        <CardHeader>
                          <h6 className="title">Rutas</h6>
                          {
                            <Col md="12">
                              <RotateSpinner
                                color={'#1261ff'}
                                size={30}
                                loading={loading3}
                              />
                            </Col>
                          }
                        </CardHeader>
                        <CardBody
                          style={{
                            height: height - 300,
                            overflow: 'scroll',
                            display: 'block',
                          }}
                        >
                          <Row>
                            <FormGroup className="col-md-12">
                              <RotateSpinner
                                color={'#1261ff'}
                                size={30}
                                loading={loadingRuta}
                              />
                              {rutas.map((item, k) => (
                                <div
                                  className="divv text-muted"
                                  key={`ruta_${k}`}
                                >
                                  <strong>
                                    <h6>{item.NombreRuta}</h6>
                                  </strong>
                                  Distrito: <strong>{item.DL.label}</strong>
                                  <br />
                                  Tipo: <strong>{item.TipoRuta.label}</strong>
                                  <br />
                                  No. Casillas:{' '}
                                  <strong>{item.NCasillas}</strong>
                                  <br />
                                  Responsable:{' '}
                                  <strong>{item.Responsable.label}</strong>
                                  <br />
                                  Abogado:{' '}
                                  {_menu.Editar === 1?
                                      item.Responsable.value === -1?<FontAwesomeIcon icon={faUser} />:
                                      <>
                                        <ButtonGroup>
                                          <Button
                                            className=" btn-round"
                                            color="primary"
                                            outline
                                            size="sm"
                                            id="EditPass"
                                            onClick={(e) => changedAbogado(e,item)}
                                          >
                                            <i className="fa fa-pencil" />
                                            {item.Responsable.label}
                                          </Button>
												                </ButtonGroup>
                                        
                                      </>
                                    :
                                    <strong>{item.Responsable.label}</strong>
                                  }

                                  
                                  
                                  <br />
                                  {_menu.Editar === 1 && (
                                     
                                    <>
                                      <Button
                                        className="btn-round btn-icon"
                                        color="primary"
                                        outline
                                        size="sm"
                                        id="EditPass"
                                        onClick={(e) => {
                                          showRutaDetail(e, item);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                    </>
                                  )}
                                  {_menu.Eliminar === 1 && (
                                    <>
                                      <Button
                                        color="danger"
                                        onClick={() =>
                                          warnRemoveCasilla(
                                            item.NCasillas,
                                            item.id
                                          )
                                        }
                                        size="sm"
                                        className="btn-round btn-icon"
                                        color="danger"
                                        outline
                                        size="sm"
                                        disabled={loadingRuta}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </>
                                  )}
                                  <hr />
                                </div>
                              ))}
                            </FormGroup>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6" lg="6">
                      <Card style={{ height: height - 200 }}>
                        <CardHeader>
                          <h6 className="title">Casillas</h6>
                          {
                            <Col md="12">
                              <RotateSpinner
                                color={'#1261ff'}
                                size={30}
                                loading={loading4}
                              />
                            </Col>
                          }
                        </CardHeader>
                        <CardBody
                          style={{
                            height: height - 300,
                            overflow: 'scroll',
                            display: 'block',
                          }}
                        >
                          <Row>
                            <FormGroup className="col-md-12">
                              {rutaDetail.map((item, k) => (
                                <div
                                  className="div text-muted"
                                  key={`rutaD_${k}`}
                                  id={`rutaD_${k}`}
                                >
                                  <Row>
                                    <Col sm="2" className="text-center">
                                      <Button
                                        className="btn-round btn-icon"
                                        color="danger"
                                        outline
                                        size="sm"
                                        id="EditPass"
                                        onClick={(e) => {
                                          deleteCasillaFromRuta(e, item, k);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </Col>
                                    <Col sm="6">
                                      Sección:<strong>{item.Seccion}</strong>
                                      <br />
                                      Casilla:{' '}
                                      <strong>{item.NombreCasilla}</strong>
                                      <br />
                                      Tipo Sección:{' '}
                                      <strong>{item.TipoSeccion}</strong>
                                      <br />
                                    </Col>
                                    {/*<a href="eliminar" className="small" onClick={(e) => deleteCasillaFromRuta(e, item, k)}>Eliminar</a>
																		<hr />*/}
                                  </Row>
                                  <hr />
                                </div>
                              ))}
                            </FormGroup>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              <>
                <Label>No tiene permisos para ver</Label>
              </>
            )}
          </Col>
        </div>
        <Footer fluid />
      </div>
      {isModal &&(
        <BuscarOwned
          onHide={() => onHide()}
          Owned={(e) => getPromotor(e)}
          Responsabilidades={tipoResponsable}
          Casillas={Casillas}
        />
      )}
      {isChangeAbogado &&(
        <BuscarOwned
          onHide={() => onHide()}
          Owned={(e) => getAbogado(e)}
          Responsabilidades={tipoResponsable}
        />
      )}
    </div>
  );
}

export default ElectoralRutaSeccion;
