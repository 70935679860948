import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col, Collapse, Input } from 'reactstrap';
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "../../data/getPOST";
import { getVars, URL_PUBLICA } from "../../GlobalStorage";

function IndexCompromiso({ Datos }) {
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
    const [catPropietario, setCatPropietario] = useState([{ value: 0, label: 'Seleccionar...' }]);
    const [catCapturista, setCatCapturista] = useState([{ value: 0, label: 'Seleccionar...' }]);
    const [filtro, setFiltro] = useState({
        Region: {},
        Municipio: {},
        Poligono: {},
        Seccion: '',
        Casilla: '',
        Celular: '',
        ClaveElector: '',
        NombreCompleto: '',
        Propuesta: {},
        DireccionVive: '',
        ColoniaVive: '',
        LocalidadVive: '',
        MunicipioVive: '',
        DireccionVota: '',
        ColoniaVota: '',
        LocalidadVota: '',
        MunicipioVota: '',
        RangoEdad: { value: 0, label: 'Seleccionar...' },
        Propietario: { value: 0, label: 'Seleccionar...' },
        Capturista: { value: 0, label: 'Seleccionar...' },
        Ranking: { value: 0, label: 'Seleccionar...' },
        Etiqueta: { value: 0, label: 'Seleccionar...' }
    })
    const [rangosEdad] = useState([
        { value: 0, label: 'Seleccionar...' },
        { value: 1, label: '18,25' },
        { value: 2, label: '25,30' },
        { value: 3, label: '31,40' },
        { value: 4, label: '41,50' },
        { value: 5, label: '51' },
    ])
    const [catRanking] = useState([
        { value: 0, label: 'Seleccionar...' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5 en adelante' },
    ])
    const [catEtiqueta] = useState([
        { value: 0, label: 'Seleccionar...' },
        { value: '1', label: 'Con Etiquetas' },
        { value: '2', label: 'Sin Etiquetas' },

    ])
    const [collapse, setCollapse] = useState({
        horizontalTabs: "home",
        verticalTabs: "info",
        pageTabs: "homePages",
        openedCollapses: ["collapseOne", "collapse1"]
    });
    const [catRegionestFilter, setCatRegionestFilter] = useState([])
    const [catPoligonost, setCatPoligonost] = useState([])
    const [catPoligonostFilter, setCatPoligonostFilter] = useState([])
    const [dataCombos, setDataCombo] = useState({
        dRegion: { label: 'Filtrar', value: 0 },
        dMunicipio: { label: 'Filtrar', value: 0 },
        dPoligono: { label: 'Filtrar', value: 0 },
        dSeccion: { label: 'Filtrar', value: 0 },
        dPropuesta: { label: 'Filtrar', value: 0 }
    })
    const [catMunicipiotFilter, setCatMunicipiotFilter] = useState([])
    const [catMunicipiot, setCatMunicipiot] = useState([])
    const [catSecciont, setCatSecciont] = useState([])
    const [catSecciontFilter, setCatSecciontFilter] = useState([])

    useEffect(() => {
        setLoading(true);
        //getCatalogosFiltrosPromocion  getCatRegion
        EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token)
            .then(res => {
                if (res.results) {
                    if (res.data) {
                        let regiones = [{ value: 0, label: 'Todos' }]
                        let regionesFormated = [];
                        res.data.regiones.forEach(item => {
                            if (item.value > 1) {

                                regionesFormated.push(
                                    {
                                        value: item.value,
                                        label: item.label
                                    })

                            }
                        }
                        )
                        setCatRegionestFilter(regiones.concat(regionesFormated));

                        let municipios = [{ value: 0, label: 'Seleccionar...' }]
                        let municipiosFormated = res.data.municipios.map(item => {

                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                            }

                        })
                        setCatMunicipiot(municipios.concat(municipiosFormated));
                        setCatMunicipiotFilter(municipios.concat(municipiosFormated));

                        let poligonos = [{ value: 0, label: 'Seleccionar...' }]
                        let poligonosFormated = [];
                        res.data.poligonos.forEach(item => {

                            if (item.value > 1)
                                poligonosFormated.push({
                                    value: item.value,
                                    label: item.label,
                                    idMunicipio: item.idMunicipio,
                                    idRegion: item.idRegion,
                                })
                        })
                        setCatPoligonost(poligonos.concat(poligonosFormated));
                        setCatPoligonostFilter(poligonos.concat(poligonosFormated));

                        let seccion = [{ value: 0, label: 'Seleccione' }]
                        let seccionFormated = res.data.secciones.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                                idMunicipio: item.idMunicipio,
                                idPoligono: item.idPoligono
                            }
                        })
                        setCatSecciont(seccion.concat(seccionFormated));
                        setCatSecciontFilter(seccion.concat(seccionFormated));
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
            })
            setCatPropietario([]);
            setCatCapturista([]);
       /*  EjecutarApi({}, "getListadoResponsablesYCapturistas", token.access_token, "GET")
            .then(function (res) {
                return res;
            })
            .then((res) => {
                var _capturista = [{ value: 0, label: 'Seleccionar...' }];
                var _propietario = [{ value: 0, label: 'Seleccionar...' }];

                setCatPropietario(_propietario.concat(res.data.Responsables));
                setCatCapturista(_capturista.concat(res.data.Capturistas))
            })
            .catch((e) => {

                notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            }); */
        setLoading(false);
    }, [])
    const collapsesToggle = (colapse) => {
        let openedCollapses = collapse.openedCollapses;
        if (openedCollapses.includes(colapse)) {
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses.filter(item => item !== colapse)
            });
        } else {
            openedCollapses.push(colapse);
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses
            });
        }
    };
    const changeRegionX = (dRegion) => {

        setLoading(true);

        if (dRegion.value > 1) {
            setDataCombo({ ...dataCombos, dRegion, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion: { label: 'Seleccione', value: 0 } });
            setCatMunicipiotFilter(catMunicipiot.filter(data => data.idRegion === dRegion.value));
            setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idRegion) === dRegion.value));
            setCatSecciontFilter(catSecciont.filter(data => data.idRegion === dRegion.value))
        } else {
            setDataCombo({ ...dataCombos, dRegion: { label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });
            setCatMunicipiotFilter(catMunicipiot);
            setCatPoligonostFilter(catPoligonost);
            setCatSecciontFilter(catSecciont);
        }
        setLoading(false);
    }
    const changeMunicipioX = (dMunicipio) => {

        setLoading(true);
        if (dMunicipio.value > 1) {
            setDataCombo({ ...dataCombos, dMunicipio: dMunicipio, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion: { label: 'Seleccione', value: 0 } });
            setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idMunicipio) === dMunicipio.value));
            setCatSecciontFilter(catSecciont.filter(data => data.idMunicipio === dMunicipio.value))
        } else {
            setDataCombo({ ...dataCombos, dMunicipio: dMunicipio, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion: { label: 'Seleccione', value: 0 } });
            setCatSecciontFilter(catSecciont);
            setCatPoligonostFilter(catPoligonost);
            setCatSecciontFilter(catSecciont);
        }
        setLoading(false);
    }

    const changePoligonoX = (dPoligono) => {
        setLoading(true);
        if (dPoligono.value > 1) {
            setDataCombo({
                ...dataCombos,
                dPoligono,
                dSeccion: { label: 'Seleccione', value: 0 }
            })
            setCatSecciontFilter(catSecciont.filter(data => data.idPoligono === dPoligono.value))
        } else {
            setDataCombo({ ...dataCombos, dPoligono });
            setCatSecciontFilter(catSecciont);
        }
        setLoading(false);
    }
    const changeSeccionX = (dSeccion) => {

        setLoading(true);
        setDataCombo({ ...dataCombos, dSeccion });
        setLoading(false);
    }
    const setFilter = (event) => {
        var filtrar = {
            Region: dataCombos.dRegion,
            Municipio: dataCombos.dMunicipio,
            Poligono: dataCombos.dPoligono,
            Seccion: dataCombos.dSeccion,
            Celular: filtro.Celular,
            ClaveElector: filtro.ClaveElector,
            NombreCompleto: filtro.NombreCompleto,
            Propuesta: dataCombos.dPropuesta,
            FechaCaptura: filtro.Fecha,
            DireccionVive: filtro.DireccionVive,
            RangoEdad: filtro.RangoEdad,
            Propietario: filtro.Propietario,
            Ranking: filtro.Ranking,
            Etiqueta: filtro.Etiqueta,
            Capturista: filtro.Capturista
        }

        Datos(filtrar);
    }

    const ClearFilter = () => {
        setDataCombo({
            ...dataCombos, dRegion: { label: 'Seleccione', value: 0 },
            dMunicipio: { label: 'Seleccione', value: 0 },
            dPoligono: { label: 'Seleccione', value: 0 },
            dSeccion: { label: 'Seleccione', value: 0 }
        });
        setCatMunicipiotFilter(catMunicipiot);
        setCatPoligonostFilter(catPoligonost);
        setCatSecciontFilter(catSecciont)
        let _filtro =
        {
            Region: {},
            Municipio: {},
            Poligono: {},
            Seccion: '',
            Casilla: '',
            Celular: '',
            ClaveElector: '',
            NombreCompleto: '',
            Propuesta: {},
            DireccionVive: '',
            ColoniaVive: '',
            LocalidadVive: '',
            MunicipioVive: '',
            DireccionVota: '',
            ColoniaVota: '',
            LocalidadVota: '',
            MunicipioVota: '',
            RangoEdad: { value: 0, label: 'Seleccionar...' },
            Propietario: { value: 0, label: 'Seleccionar...' },
            Capturista: { value: 0, label: 'Seleccionar...' },
            Ranking: { value: 0, label: 'Seleccionar...' },
            Etiqueta: { value: 0, label: 'Seleccionar...' }
        }
        setFiltro(_filtro);
        Datos(_filtro);

    }

    return (
        <Card className="card-plain">
            <CardBody>
                <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                >

                    <Card className="card-plain">
                        <CardHeader role="tab" className='text-left'>
                            <a
                                aria-expanded={collapse.openedCollapses.includes(
                                    "collapse3"
                                )}
                                data-parent="#accordion"
                                data-toggle="collapse"
                                onClick={() => collapsesToggle("collapse3")}
                                className="text-left"
                            >
                                <Button
                                    className="btn-icon btn-neutral"
                                    outline
                                    size="sm"
                                    id="EditUser"
                                >
                                    <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                                </Button>
                                Filtro
                    </a>
                        </CardHeader>
                        <Collapse
                            role="tabpanel"
                            isOpen={collapse.openedCollapses.includes(
                                "collapse3"
                            )}
                        >
                            <CardBody>
                                <Row>
                                    <Col sm="3" className="text-muted">Región:
                            {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                        )}
                                        {!loading && (
                                            <Select
                                                options={catRegionestFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dRegion}
                                                onChange={(e) => changeRegionX(e)}
                                                id="viewTypeR"
                                                placeholder="Filtrar por"
                                            />
                                        )}
                                    </Col>
                                    <Col sm="3" className="text-muted">Municipio:
                            {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                        )}            {!loading && (<Select
                                            options={catMunicipiotFilter}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={dataCombos.dMunicipio}
                                            onChange={(e) => changeMunicipioX(e)}
                                            id="viewTypeM"
                                            placeholder="Filtrar por"
                                        />)}
                                    </Col>
                                    <Col sm="3" className="text-muted">Polígono:
                                        {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                        )}            {!loading && (<Select
                                            options={catPoligonostFilter}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={dataCombos.dPoligono}
                                            onChange={(e) => changePoligonoX(e)}
                                            id="viewTypeP"
                                            placeholder="Filtrar por"
                                        />)}
                                    </Col>
                                    <Col sm="3" className="text-muted">Sección:
                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                        )}            {!loading && (<Select
                                            options={catSecciontFilter}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={dataCombos.dSeccion}
                                            onChange={(e) => changeSeccionX(e)}
                                            id="viewTypeS"
                                            placeholder="Filtrar por"
                                        />)}
                                    </Col>
                                    <Col sm="3" className="text-muted">Celular:<Input id="icveElector" value={filtro.Celular} onChange={(e) => setFiltro({ ...filtro, Celular: e.target.value })} /></Col>
                                    <Col sm="3" className="text-muted">Clave de Elector:<Input id="icveElector" value={filtro.ClaveElector} onChange={(e) => setFiltro({ ...filtro, ClaveElector: e.target.value })} /></Col>
                                    <Col sm="6" className="text-muted">Nombre: <Input id="inombre" value={filtro.NombreCompleto} onChange={(e) => setFiltro({ ...filtro, NombreCompleto: e.target.value })} /></Col>
                                    <Col sm="5" className="text-muted">Dirección:<Input id="" value={filtro.DireccionVive} onChange={(e) => setFiltro({ ...filtro, DireccionVive: e.target.value })} /></Col>
                                    <Col sm="3" className="text-muted">Rango Edad:
                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                        )}            {!loading && (<Select
                                            options={rangosEdad}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filtro.RangoEdad}
                                            onChange={(e) => setFiltro({ ...filtro, RangoEdad: e })}
                                            id="viewTypeS"
                                            placeholder="Filtrar por"
                                        />)}
                                    </Col>
                                    {/* <Col sm="3" className="text-muted">Responsable:
                                <Select
                                            options={catPropietario}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filtro.Propietario}
                                            onChange={(e) => setFiltro({ ...filtro, Propietario: e })}
                                            id="sPropietario"
                                            placeholder="Filtrar por..."
                                        />
                                    </Col>
                                    <Col sm="4" className="text-muted">Capturo:
                                <Select
                                            options={catCapturista}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filtro.Capturista}
                                            onChange={(e) => setFiltro({ ...filtro, Capturista: e })}
                                            id="sCapturista"
                                            placeholder="Filtrar por..."
                                        />
                                    </Col> */}
                                    <Col sm="3" className="text-muted">Ranking:
                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                        )}            {!loading && (<Select
                                            options={catRanking}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filtro.Ranking}
                                            onChange={(e) => setFiltro({ ...filtro, Ranking: e })}
                                            id="viewTypeS"
                                            placeholder="Filtrar por"
                                        />)}
                                    </Col>
                                    {/* <Col sm="3" className="text-muted">Etiqueta:
                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                        )}            {!loading && (<Select
                                            options={catEtiqueta}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filtro.Etiqueta}
                                            onChange={(e) => setFiltro({ ...filtro, Etiqueta: e })}
                                            id="viewTypeS"
                                            placeholder="Filtrar por"
                                        />)}
                                    </Col> */}
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e) => setFilter()}>
                                    <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                    Filtrar
                        </Button>{' '}
                                <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e) => ClearFilter()}>
                                    <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                                    Limpiar
                        </Button>
                            </CardFooter>
                        </Collapse>
                    </Card>
                </div>
            </CardBody>
        </Card>
    );
};
export default IndexCompromiso;