import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import App from './pages/Principal';
import Login from './pages/Splash';
import Numeralia from './pages/Numeralia/Numeralia';
import SendNotifications from './pages/SendNotifications';
import Compromisos from './pages/Compromiso/Compromisos';
import CompromisosNumeralia from './pages/Compromiso/CompromisoNumeralia';
import Voluntarios from './pages/Compromiso/Voluntarios';
import CompromisosAdd from './pages/Compromiso/CompromisosAdd';
import AvanceGeneral from './pages/Avances/AvanceGeneralRegion';
import AvanceSocial from './pages/Avances/AvanceSocial';

import ElectoralDF from './pages/Avances/AvanceElectoralDF';
import ElectoralDL from './pages/Avances/AvanceElectoralDL';
import MiPerfil from './pages/Usuarios/PerfilUsuario';
import Home from './pages/Home/Home';
//
import configuracionmetaCompromisos from './pages/Metas/ConfiguracionMeta';
import configuracionmetas from './pages/Metas/ConfiguracionMetaEstructura';
//
import Usuarios from './pages/Usuarios/UsuariosList';
import UsuariosDiaD from './pages/Usuarios/UsuariosDiaD';

import Social from './pages/Social/Social.jsx';
import SocialAvances from './pages/Social/SocialAvances.jsx';
import SocialCompromisos from './pages/Social/SocialCompromiso.jsx';
import SocialNumeralia from './pages/Social/SocialNumeralia';
import SocialResponsabilidad from './pages/Social/SocialResponsabilidad.jsx';
import SocialListado from './pages/Social/SocialListado';

import ElectoralRCs from './pages/Electoral/ElectoralRCs';
import ElectoralRGs from './pages/Electoral/ElectoralRGs';
import ElectoralCasaAzul from './pages/Electoral/ElectoralCasaAzul';
import ElectoralAbogado from './pages/Electoral/ElectoralAbogado';

//import ElectoralRCsAdd from './pages/Electoral/ElectoralRCsAdd';

import EPropuestaRCs from './pages/Electoral/EPropuestaRCs';
import EPropuestaRGs from './pages/Electoral/EPropuestaRGs';
import EPropuestaCA from './pages/Electoral/EPropuestaCasaAzul';
import EPropuestaA from './pages/Electoral/EPropuestaAbogado';
import ElectoralMunicipal from './pages/Avances/AvanceElectoralMunicipal';
import EstructuraElectoralMunicipal from './pages/Avances/AvanceEstructuraElectoral';
import ElectoralRegion from './pages/Avances/AvanceElectoralRegional';

import ColmenaEstructuraCompromisos from './pages/Avances/ColmenaEstructuraCompromisos';
import AvancesCompromisosRegion from './pages/Avances/AvancesCompromisosRegion';

import AvancesGeneralMunicipio from './pages/Avances/AvanceGeneralMunicipio';
import AvancesCompromisosMunicipio from './pages/Avances/AvancesCompromisosMunicipio';
import AvancesCompromisosPoligono from './pages/Avances/AvancesCompromisosPoligono';
import AvancesGeneralPoligono from './pages/Avances/AvanceGeneralPoligono';

import ListadoElectoral from './pages/Electoral/ElectoralListado';
import Observador from './pages/Electoral/ElectoralObservador';
import AcetarPropuestas from './pages/AceptarResponsabilidad/AceptarPropuestas';

import Mapas from './pages/Maps/MapTematico/ZMap';
import MapasCompromisos from './pages/Maps/Promocion/MapasCompromisos';
import MapasDFCompromisos from './pages/Maps/Electoral/Distritos/MapasDFCompromisos.jsx';
import MapasDFEstructura from './pages/Maps/Electoral/Distritos/MapasDFEstructura.jsx';
import MapasDLEstructura from './pages/Maps/Electoral/Distritos/MapasDLEstructura.jsx';
import MapasDLCompromisos from './pages/Maps/Electoral/Distritos/MapasDLCompromisos.jsx';
import MapasDFSeccionCompromisos from './pages/Maps/Electoral/Distritos/MapasDFSeccionCompromisos.jsx';
import MapasDFSeccionCompromisosTest from './pages/Maps/Electoral/Distritos/MapasDFSeccionCompromisosTest.jsx';
import MapasDLSeccionCompromisos from './pages/Maps/Electoral/Distritos/MapasDLSeccionCompromisos.jsx';
import MapasEstructura from './pages/Maps/Promocion/MapasEstructura';
import MapaActas from './pages/Maps/Electoral/MapaActas';
import MapaAvanceElectoral from './pages/Maps/Electoral/MapaAvanceElectoral';
import MapasRutas from './pages/Maps/Electoral/MapasRutas';
import Rutas from './pages/Rutas/Rutas';
import MovilMapasCompromisos from './pages/Maps/Promocion/MapasMovilCompromisos';
import MapasMovilDFCompromisos from './pages/Maps/Electoral/Distritos/MapasMovilDFCompromisos';
import MapasMovilDLCompromisos from './pages/Maps/Electoral/Distritos/MapasMovilDLCompromisos';
import MapasMovilDFEstructura from './pages/Maps/Electoral/Distritos/MapasMovilDFEstructura';
import MapasMovilDLEstructura from './pages/Maps/Electoral/Distritos/MapasMovilDLEstructura';
import MovilMapasEstructuras from './pages/Maps/Promocion/MapasMovilEstructuras';

import MapasSeccionImpresionDireccion from './pages/Maps/Impresion/MapasSeccionImpresionAllUsers';
import MapasSeccionDireccion from './pages/Maps/Impresion/MapasSeccionAllUsersDragMap';

import MapasInforme from './pages/Maps/Informe/MapasInforme';

import MapasSeccionImpresion from './pages/Maps/Impresion/MapasSeccionImpresion';
import MapasPoligonoImpresion from './pages/Maps/Impresion/MapasPoligonoImpresion';
import MapasMunicipioPoligonoImpresion from './pages/Maps/Impresion/MapasMunicipioPoligonoImpresion';
import MapasMunicipioSeccionImpresion from './pages/Maps/Impresion/MapasMunicipioSeccionImpresion';
import MapasRegionImpresion from './pages/Maps/Impresion/MapasRegionImpresion';
import MapasEntidadImpresion from './pages/Maps/Impresion/MapasEntidadImpresion';
import MapasDLImpresion from './pages/Maps/Impresion/MapasDLImpresion';
import MapasDFImpresion from './pages/Maps/Impresion/MapasDFImpresion';
import MovilMapasSeccionImpresion from './pages/Maps/Impresion/Movil/MovilMapasSeccionImpresion';
import MovilMapasPoligonoImpresion from './pages/Maps/Impresion/Movil/MovilMapasPoligonoImpresion';
import MovilMapasMunicipioPoligonoImpresion from './pages/Maps/Impresion/Movil/MovilMapasMunicipioPoligonoImpresion';
import MovilMapasMunicipioSeccionImpresion from './pages/Maps/Impresion/Movil/MovilMapasMunicipioSeccionImpresion';
import MovilMapasRegionImpresion from './pages/Maps/Impresion/Movil/MovilMapasRegionImpresion';
import MovilMapasEntidadImpresion from './pages/Maps/Impresion/Movil/MovilMapasEntidadImpresion';
import MovilMapasDLImpresion from './pages/Maps/Impresion/Movil/MovilMapasDLImpresion';
import MovilMapasDFImpresion from './pages/Maps/Impresion/Movil/MovilMapasDFImpresion';
import AvanceResponsabilidad from './pages/Avances/AvancesResponsabilidad';
import AvanceResponsabilidadPromocion from './pages/Avances/AvanceEstructuraPromocion';
import Plantillas from './pages/Plantillas/PlantillasDescarga';
import AvancesGeneralDF from './pages/Avances/AvancesGeneralDF';
import AvanceGeneralDL from './pages/Avances/AvanceGeneralDL';
import AvanceCompromiso from './pages/Avances/AvanceCapturaUser';
import AvancesCompromisoSeccion from './pages/Avances/AvanceCompromisoSeccion';
import AvanceCasillas from './pages/Electoral/AvanceCasillas';
import Capacitaciones from './pages/Capacitaciones/Capacitaciones';
import CapacitacionesAdd from './pages/Capacitaciones/CapacitacionesAdd';
//import MovilMapasEstructurasTest from './pages/MapasMovilEstructuras_test';
import MapasMovilSeccionCompromisos from './pages/Maps/Promocion/MapasMovilSeccionCompromisos';
import MapasMovilDFSeccionCompromisos from './pages/Maps/Electoral/Distritos/MapasMovilDFSeccionCompromisos';
import MapasMovilDLSeccionCompromisos from './pages/Maps/Electoral/Distritos/MapasMovilDLSeccionCompromisos';

import MapasSeccionCompromisos from './pages/Maps/Promocion/MapasSeccionCompromisos';

import Promocional from './pages/Compromiso/Promocional';

import Cuestionarios from './pages/CallCenter/Cuestionarios';
import CuestionariosAdd from './pages/CallCenter/CuestionariosAdd';
import CuestionariosEdit from './pages/CallCenter/CuestionariosEdit';
import Auditorias from './pages/CallCenter/Auditorias';
import AuditoriasAdd from './pages/CallCenter/AuditoriasAdd';

import NotFound from './pages/NotFound';

import ProtectedRoute from './ProtectedRoute';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.1.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import AvancePapel from 'pages/Avances/AvancePapel';
import Candidatos from 'pages/Candidatos';

import RCO from 'pages/RCO/index';
import MovilizacionSeccion from 'pages/RCO/MovilizacionSeccion';
import SocialCompromisoGrafica from 'pages/Social/SocialCompromisoGrafica';
import Partidos from 'pages/PREP/Partidos/Partidos';
import PartidosAdd from 'pages/PREP/Partidos/PartidosAdd';
import Planillas from 'pages/PREP/Planillas/Planillas';
import HistorialCapturas from 'pages/Compromiso/HistorialCapturas';
import ActaEscrutinioAdd from 'pages/PREP/ActaEscrutinio/ActaEscrutinioAdd';
import ActaEscrutinioJuridico from 'pages/PREP/ActaEscrutinio/ActaEscrutinioJuridico';
import PREPDashBoard from 'pages/PREP/PREPDashboard';
import PREPDashBoardMuestreo from 'pages/PREP/PREPDashboardMuestreo';

import TableroPorSeccion from 'pages/TableroAvances/PorSeccion';
import GraficaTablero from 'pages/TableroAvances/GraficasTablero';
import Rentabilidad from 'pages/Rentabilidad/Rentabilidad';
import Inversion from 'pages/Inversion/Graficas_TipoInversion';

import graficaSimulacroa from 'pages/TableroAvances/ResultadoSimulacro';

//sije
import SijeDashBoard from 'pages/Sije/SijeDashBoard.jsx';
import CasillasOperación from 'pages/Sije/CasillasOperacion.jsx';
import CasillasOperaciónMuestra from 'pages/Sije/CasillaOperacionMuestra';
import ReporteIncidencia from 'pages/Sije/Casillas_Incidencias';
import RegistroAsistencia from 'pages/Sije/SijeDiaDPaseLista';
import CierreCasillas from 'pages/Sije/SijeDiaDCierreCasilla';
import ElectoralRutaSeccion from 'pages/Electoral/ElectoralRutasSeccion';
import MovilizacionSocial from 'pages/Movilizacion/Movilizacion';

import MovilizacionDashboard from 'pages/RCO/MovilizacionDashboard';
import OperacionMovilizacion from 'pages/MovilizacionPromocion/OperacionMolizacion';
import MovilizacionPromocion from 'pages/MovilizacionPromocion/MovilizacionPromocion';
import PaseListaMovilizacion from 'pages/MovilizacionPromocion/PaseListaMovilizacion';

import CapturaFolioBingo from 'pages/Bingo/CapturaFolioBingo';
import CotejoBingo from 'pages/Bingo/CotejoBingo';

import SocialRCO from 'pages/RCO/SocialRCO';
import CapUserDiaD from 'pages/Usuarios/UsuariosDiaD';
import CasillasListJuridicos from 'pages/PREP/CasillasListJuridicos';

// Social Tree
import ThreeEstructura from './pages/Social/ThreeEstructura';

const Root = () => {
  return (
    <Router>
      <Switch>
        {/***** ES IMPORTANTE COLOCAR LA / EN EL PATH ****/}
        <ProtectedRoute exact path="/" component={App} />
        <ProtectedRoute path="/home" component={Home} />
        <ProtectedRoute path="/numeralia" component={Numeralia} />

        <ProtectedRoute
          path="/TableroPorSeccion"
          component={TableroPorSeccion}
        />

        <ProtectedRoute path="/cuestionario" component={Cuestionarios} />
        <ProtectedRoute
          path="/cuestionarios_add"
          component={CuestionariosAdd}
        />
        <ProtectedRoute
          path="/cuestionarios_edit"
          component={CuestionariosEdit}
        />
        <ProtectedRoute path="/auditorias" component={Auditorias} />
        <ProtectedRoute path="/auditorias_add" component={AuditoriasAdd} />
        <ProtectedRoute
          path="/compromisos_numeralia"
          component={CompromisosNumeralia}
        />
        <ProtectedRoute path="/compromisos" component={Compromisos} />
        <ProtectedRoute path="/compromisos_cap" component={CompromisosAdd} />
        <ProtectedRoute path="/voluntarios" component={Voluntarios} />
        <ProtectedRoute path="/mapas" component={Mapas} />
        <ProtectedRoute
          path="/mapa_avance_estructura"
          component={MapasEstructura}
        />
        <ProtectedRoute
          path="/mapa_avance_compromisos"
          component={MapasCompromisos}
        />
        <ProtectedRoute
          path="/mapa_avance_seccion"
          component={MapasSeccionCompromisos}
        />
        <ProtectedRoute path="/mapa_actas" component={MapaActas} />
        <ProtectedRoute
          path="/mapa_avance_electoral"
          component={MapaAvanceElectoral}
        />
        <ProtectedRoute
          path="/mapa_avance_compromisos_distrito_df"
          component={MapasDFCompromisos}
        />
        <ProtectedRoute
          path="/mapa_avance_compromisos_distrito_dl"
          component={MapasDLCompromisos}
        />
        <ProtectedRoute
          path="/mapa_avance_estructura_distrito_df"
          component={MapasDFEstructura}
        />
        <ProtectedRoute
          path="/mapa_avance_estructura_distrito_dl"
          component={MapasDLEstructura}
        />
        <ProtectedRoute
          path="/mapa_avance_seccion_df"
          component={MapasDFSeccionCompromisos}
        />
        <ProtectedRoute
          path="/mapaseccion_test"
          component={MapasDFSeccionCompromisosTest}
        />
        <ProtectedRoute
          path="/mapa_avance_seccion_dl"
          component={MapasDLSeccionCompromisos}
        />
        <ProtectedRoute path="/mapa_rutas" component={MapasRutas} />
        <ProtectedRoute path="/rutas" component={Rutas} />

        <ProtectedRoute path="/rco" component={RCO} />
        <ProtectedRoute
          path="/movilizacionbyseccion"
          component={MovilizacionSeccion}
        />
        <ProtectedRoute
          path="/historialcaptura"
          component={HistorialCapturas}
        />
        <ProtectedRoute
          path="/movilizaciondashboard"
          component={MovilizacionDashboard}
        />
        {/* Informe Mapas */}
        <ProtectedRoute path="/mapa_informe_estatal" component={MapasInforme} />

        {/* Impresión de Mapas */}
        <ProtectedRoute
          path="/mapa_direccion"
          component={MapasSeccionDireccion}
        />
        <ProtectedRoute
          path="/mapa_seccion_direccion"
          component={MapasSeccionImpresionDireccion}
        />
        <ProtectedRoute
          path="/mapa_impresion_sm"
          component={MapasSeccionImpresion}
        />
        <ProtectedRoute
          path="/mapa_impresion_ps"
          component={MapasPoligonoImpresion}
        />
        <ProtectedRoute
          path="/mapa_impresion_mp"
          component={MapasMunicipioPoligonoImpresion}
        />
        <ProtectedRoute
          path="/mapa_impresion_ms"
          component={MapasMunicipioSeccionImpresion}
        />
        <ProtectedRoute
          path="/mapa_impresion_rm"
          component={MapasRegionImpresion}
        />
        <ProtectedRoute
          path="/mapa_impresion_er"
          component={MapasEntidadImpresion}
        />
        <ProtectedRoute
          path="/mapa_impresion_dlp"
          component={MapasDLImpresion}
        />
        <ProtectedRoute
          path="/mapa_impresion_dfp"
          component={MapasDFImpresion}
        />

        {/* Impresión de Mapas Movil */}

        <Route
          path="/movil_mapa_impresion_sm"
          component={MovilMapasSeccionImpresion}
        />
        <Route
          path="/movil_mapa_impresion_ps"
          component={MovilMapasPoligonoImpresion}
        />
        <Route
          path="/movil_mapa_impresion_mp"
          component={MovilMapasMunicipioPoligonoImpresion}
        />
        <Route
          path="/movil_mapa_impresion_ms"
          component={MovilMapasMunicipioSeccionImpresion}
        />
        <Route
          path="/movil_mapa_impresion_rm"
          component={MovilMapasRegionImpresion}
        />
        <Route
          path="/movil_mapa_impresion_er"
          component={MovilMapasEntidadImpresion}
        />
        <Route
          path="/movil_mapa_impresion_dlp"
          component={MovilMapasDLImpresion}
        />
        <Route
          path="/movil_mapa_impresion_dfp"
          component={MovilMapasDFImpresion}
        />

        <Route
          path="/movil_mapa_compromisos"
          component={MovilMapasCompromisos}
        />
        <Route
          path="/movil_mapa_compromisos_df"
          component={MapasMovilDFCompromisos}
        />
        <Route
          path="/movil_mapa_compromisos_dl"
          component={MapasMovilDLCompromisos}
        />
        <Route
          path="/movil_mapa_estructuras_df"
          component={MapasMovilDFEstructura}
        />
        <Route
          path="/movil_mapa_estructuras_dl"
          component={MapasMovilDLEstructura}
        />
        <Route
          path="/movil_mapa_estructuras"
          component={MovilMapasEstructuras}
        />
        <Route
          path="/movil_mapa_libre"
          component={MapasMovilSeccionCompromisos}
        />
        <Route
          path="/movil_mapa_libre_df"
          component={MapasMovilDFSeccionCompromisos}
        />
        <Route
          path="/movil_mapa_libre_dl"
          component={MapasMovilDLSeccionCompromisos}
        />
        <ProtectedRoute path="/perfil" component={MiPerfil} />
        <ProtectedRoute path="/usuarios" component={Usuarios} />
        <ProtectedRoute path="/usuariosdiad" component={UsuariosDiaD} />
        <ProtectedRoute path="/admin/candidatos" component={Candidatos} />

        <ProtectedRoute path="/social" component={Social} />
        <ProtectedRoute path="/avsocial" component={SocialAvances} />
        <ProtectedRoute
          path="/socialcompromisos"
          component={SocialCompromisoGrafica}
        />
        <ProtectedRoute
          path="/asignarresponsabiliadsocial"
          component={SocialResponsabilidad}
        />
        <ProtectedRoute path="/socialnumeralia" component={SocialNumeralia} />
        <ProtectedRoute
          path="/listado_usuario_social"
          component={SocialListado}
        />

        <ProtectedRoute path="/rcs" component={ElectoralRCs} />
        <ProtectedRoute path="/rgs" component={ElectoralRGs} />
        <ProtectedRoute path="/casa_azul" component={ElectoralCasaAzul} />
        <ProtectedRoute path="/abogados" component={ElectoralAbogado} />
        <ProtectedRoute path="/listadoelectoral" component={ListadoElectoral} />
        <ProtectedRoute path="/observador" component={Observador} />

        <ProtectedRoute path="/EPropuestaRCs" component={EPropuestaRCs} />
        <ProtectedRoute path="/EPropuestaRGs" component={EPropuestaRGs} />
        <ProtectedRoute path="/EPropuestaCA" component={EPropuestaCA} />
        <ProtectedRoute path="/EPropuestaA" component={EPropuestaA} />

        <ProtectedRoute
          path="/colmena_estructura"
          component={ColmenaEstructuraCompromisos}
        />
        <ProtectedRoute
          path="/compromisos_region"
          component={AvancesCompromisosRegion}
        />
        <ProtectedRoute
          path="/compromisos_municipio"
          component={AvancesCompromisosMunicipio}
        />
        <ProtectedRoute
          path="/compromisos_poligonos"
          component={AvancesCompromisosPoligono}
        />
        <ProtectedRoute
          path="/compromiso_seccion"
          component={AvancesCompromisoSeccion}
        />

        <ProtectedRoute
          path="/send_notifications"
          component={SendNotifications}
        />
        <ProtectedRoute
          path="/electoral_municipio"
          component={ElectoralMunicipal}
        />
        <ProtectedRoute
          path="/estructura-electoral"
          component={EstructuraElectoralMunicipal}
        />
        <ProtectedRoute path="/electoral_region" component={ElectoralRegion} />
        <ProtectedRoute
          path="/meta_compromisos"
          component={configuracionmetaCompromisos}
        />
        <ProtectedRoute
          path="/meta_estructura"
          component={configuracionmetas}
        />
        <ProtectedRoute
          path="/avance_general_regional"
          component={AvanceGeneral}
        />
        <ProtectedRoute
          path="/avance_general_municipal"
          component={AvancesGeneralMunicipio}
        />
        <ProtectedRoute
          path="/avance_general_df"
          component={AvancesGeneralDF}
        />
        <ProtectedRoute path="/avance_general_dl" component={AvanceGeneralDL} />
        <ProtectedRoute
          path="/avance_compromiso"
          component={AvanceCompromiso}
        />
        <ProtectedRoute
          path="/avance_general_poligonal"
          component={AvancesGeneralPoligono}
        />
        <ProtectedRoute path="/electoral_df" component={ElectoralDF} />
        <ProtectedRoute path="/electoral_dl" component={ElectoralDL} />
        <ProtectedRoute path="/tablero" component={AvancePapel} />
        <ProtectedRoute path="/graficaTablero" component={GraficaTablero} />
        <ProtectedRoute
          path="/graficaSimulacro"
          component={graficaSimulacroa}
        />

        <ProtectedRoute
          path="/aceptar_responsabilidades"
          component={AcetarPropuestas}
        />

        <ProtectedRoute path="/Promocional" component={Promocional} />
        <ProtectedRoute path="/responsable" component={AvanceResponsabilidad} />
        <ProtectedRoute
          path="/estructura-promocion"
          component={AvanceResponsabilidadPromocion}
        />
        <ProtectedRoute path="/descarga_plantilla" component={Plantillas} />
        <Route path="/AvanceCasillas" component={AvanceCasillas}></Route>

        <Route path="/Partidos" component={Partidos}></Route>
        <Route path="/PartidosAdd" component={PartidosAdd}></Route>

        <Route path="/Capacitaciones" component={Capacitaciones}></Route>
        <Route path="/CapacitacionesAdd" component={CapacitacionesAdd} />

        <Route path="/Planillas" component={Planillas} />

        <ProtectedRoute
          path="/ActaEscrutinioAdd"
          component={ActaEscrutinioAdd}
        />
        <ProtectedRoute
          path="/ActaEscrutinioJuridico"
          component={ActaEscrutinioJuridico}
        />
        <ProtectedRoute path="/prep" component={PREPDashBoard} />
        <ProtectedRoute
          path="/prep_muestreo"
          component={PREPDashBoardMuestreo}
        />
        <ProtectedRoute
          path="/CasillasJuridico"
          component={CasillasListJuridicos}
        />
        {/* SIJE*/}
        <Route path="/sije_inicio" component={RegistroAsistencia} />
        <Route path="/sije_cierre" component={CierreCasillas} />
        <ProtectedRoute path="/sije" component={SijeDashBoard} />
        <Route path="/incidencias" component={ReporteIncidencia}></Route>
        <ProtectedRoute
          path="/casilla_operacion"
          component={CasillasOperación}
        />
        <ProtectedRoute
          path="/casilla_operacion_muestra"
          component={CasillasOperaciónMuestra}
        />
        <ProtectedRoute
          path="/movilizacion_social"
          component={MovilizacionSocial}
        />
        <ProtectedRoute
          path="/rutasecciones"
          component={ElectoralRutaSeccion}
        />
        <ProtectedRoute
          path="/tableromovilizacion"
          component={OperacionMovilizacion}
        />
        <ProtectedRoute path="/paselista" component={PaseListaMovilizacion} />
        <ProtectedRoute
          path="/capturamovilizacion"
          component={MovilizacionPromocion}
        />
        <ProtectedRoute
          path="/capturafoliobingo"
          component={CapturaFolioBingo}
        />
        <ProtectedRoute path="/cotejobingo" component={CotejoBingo} />

        <ProtectedRoute path="/RCOSocial" component={SocialRCO} />
        <ProtectedRoute path="/capuserdiad" component={CapUserDiaD} />

        <ProtectedRoute path="/avance_social" component={AvanceSocial} />
        <ProtectedRoute path="/estructura-social" component={ThreeEstructura} />

        <ProtectedRoute path="/graficaRentabilidad" component={Rentabilidad} />
        <ProtectedRoute path="/tiposinversion" component={Inversion} />
        <Route path="/login" component={Login} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
