import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardBody,
  Progress,
  CardFooter,
  CardTitle,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import PerfectScrollbar from 'perfect-scrollbar';
import Select from 'react-select';
import { CircleSpinner } from 'react-spinners-kit';

import { getVars, URL_PUBLICA } from 'GlobalStorage';
import EjecutarApi from 'data/getPOST';
import { getDataMenu } from 'data/menus';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import useWindowDimensions from 'components/useWindowDimensions';
import ChartColumComparison from 'components/Charts/PREP/ChartColumComparison';
import PrepTable from 'components/PREP/PrepTable';
import Acta from 'components/Sije/ActasResumen';
import StatsCard from 'components/PREP/StatsCard';
import { centerOnOneShapeSecciones } from 'components/Maps/ComponentesAuxiliares/AuxiliarFunctions';

var ps;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const PREPDashBoard = (props) => {
  const mainPanel = useRef();
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  const [DataChart, setDataChart] = useState([]);
  const [Series, setSeries] = useState([]);
  const [catTiposEleccion, setCatTiposEleccion] = useState([]);
  const [catMunicipios, setCatMunicipios] = useState([]);
  const [catDF, setCatDF] = useState([]);
  const [catDL, setCatDL] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [seccionesFiltradas, setSeccionesFiltradas] = useState([]);
  const [optionsChart, setOptionsChart] = useState({
    Title: '',
    Subtitle: '',
  });
  const [filters, setFilters] = useState({
    tipoVista: 1,
    tipoAgrupador: { value: 0, label: 'Sin seleccionar' },
    tipoEleccion: { value: 0, label: 'Sin seleccionar' },
    ambito: { value: 0, label: 'Sin seleccionar' },
    seccion: { value: null, label: 'Todas' },
  });
  const [catTiposAgrupador, setCatTiposAgrupador] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [scope, setScope] = useState([]);
  const [loadingChart, setLoadingChart] = useState(true);
  const [counter, setCounter] = useState(0);
  const [totalActas, setTotalActas] = useState({
    ActaRecibida: 0,
    TotalActas: 0,
  });
  const [participacion, setParticipacion] = useState({
    TotalVotos: 0,
    LNOM: 0,
    Avance: 0,
  });
  const [isLoadingParticipacion, setIsLoadingParticipacion] = useState(true);
  const [isMunicipioCargado, setIsMunicipioCargado] = useState(false);
  const [vistaActas, setVistaActas] = useState(1);
  const [hora, setHora] = useState('')
  const [isActualizando, setIsActualizando] = useState(false);

  useEffect(() => {
    let _menuStorage = getDataMenu(146, token.menus);
    _setmenu(_menuStorage)
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }

    if (props.history.action === 'PUSH') {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }

    getCatalogs();
  }, []);

  const getEstadisticas = (data) => {
    EjecutarApi(
      {
        ambito: data.ambito,
        tipoVista: data.tipoVista,
        idTipoEleccion: data.idTipoEleccion,
        totales: ['Actas'],
      },
      'sije/getTotales',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setTotalActas({
            ActaRecibida: res.data.Actas.recibidas,
            TotalActas: res.data.Actas.total,
          });
        } else {
          notify.show(`Error al cargar actas: ${res.message}`, 'error', 5000);
        }
      })
      .catch((e) => {
        notify.show(`Error al cargar actas: ${e.message}`, 'error', 5000);
      });
  };

  const Exportar = () => {

    const data = {
      tipoEleccion: filters.tipoEleccion.value,
      ambito: filters.ambito.value,
      ambitoLabel: filters.ambito.label,
      tipoAgrupador: filters.tipoAgrupador.value,
      tipoVista: filters.tipoVista,
      seccion: filters.seccion.value,
      Export: true

    };
    var datos = JSON.stringify(data)
    var url = new URL(
      `${URL_PUBLICA}prep/exportPREP?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");
  }

  const getParticipacion = (data) => {
    setIsLoadingParticipacion(true);
    EjecutarApi(data, 'prep/getParticipacionPREP', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setIsLoadingParticipacion(false);
        if (res.success && res.results) {
          setParticipacion(res.data);
        } else {
          notify.show(`Error al cargar participación: ${res.message}`, 'error', 5000);
        }
      })
      .catch((e) => {
        notify.show(`Error al cargar participación: ${e.message}`, 'error', 5000);
      });
  };

  // useInterval(() => {
  //   setCounter(counter + 1);
  // }, INTERVAL);

  // useEffect(() => {
  //   getChartData(
  //     filters.tipoEleccion.value,
  //     filters.ambito.value,
  //     filters.tipoAgrupador.value
  //   );
  // }, [counter]);

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const getCatalogs = () => {
    EjecutarApi(
      {
        catalogos: ['tipos_eleccion', 'municipios', 'dfs', 'dls', 'secciones', 'tipo_agrupador'],
      },
      'getCatalogosSije',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setCatTiposEleccion(res.data.tipos_eleccion);
          setCatMunicipios(res.data.municipios);
          setCatDF(res.data.dfs);
          setCatDL(res.data.dls);
          setCatTiposAgrupador(res.data.tipo_agrupador);
          setSecciones(
            [{ value: null, label: 'Todas' }].concat(res.data.secciones)
          );
          let ambitoInicial = [];
          let vistaActas = 1;
          switch (res.data.tipos_eleccion[0].value) {
            case 3:
              ambitoInicial = res.data.dfs;
              setSeccionesFiltradas(
                [{ value: null, label: 'Todas' }].concat(
                  res.data.secciones.filter(
                    (seccion) =>
                      seccion.idDF === ambitoInicial[0].value
                  )
                )
              );
              vistaActas = 3;
              break;
            case 5:
              ambitoInicial = res.data.dls;
              setSeccionesFiltradas(
                [{ value: null, label: 'Todas' }].concat(
                  res.data.secciones.filter(
                    (seccion) =>
                      seccion.idDL === ambitoInicial[0].value
                  )
                )
              );
              vistaActas = 2;
              break;
            case 6:
              ambitoInicial = res.data.municipios;
              setSeccionesFiltradas(
                [{ value: null, label: 'Todas' }].concat(
                  res.data.secciones.filter(
                    (seccion) =>
                      seccion.idMunicipio === ambitoInicial[0].value
                  )
                )
              );
              vistaActas = 1;
              break;

          }
          setVistaActas(vistaActas);
          setScope(ambitoInicial);
          setFilters({
            ...filters,
            tipoEleccion: res.data.tipos_eleccion[0],
            ambito: ambitoInicial[0],
            tipoAgrupador: { value: 4, label: 'Detalle por casillas' }
          })

          getChartData(
            res.data.tipos_eleccion[0].value,
            ambitoInicial[0].value,
            4
          );
          getEstadisticas({
            tipoVista: vistaActas,
            ambito: ambitoInicial[0].value,
            idTipoEleccion: res.data.tipos_eleccion[0].value
          });
          getParticipacion({
            tipoEleccion: res.data.tipos_eleccion[0].value,
            ambito: ambitoInicial[0].value,
          });
        }
      })
      .catch((e) => {
        notify.show(`Error al cargar filtros: ${e.message}`, 'error', 5000);
      });
  };

  const handleTipoVista = (vista) => {
    setFilters({ ...filters, tipoVista: vista });
    setSeries(DataChart[vista]);
    if (vista === 1) {
      setOptionsChart({ ...optionsChart, Subtitle: 'Vista por candidatos' });
    } else if (vista === 2) {
      setOptionsChart({ ...optionsChart, Subtitle: 'Vista por partidos' });
    }
  };

  const handleTipoEleccion = (tipo) => {
    let ambitoSelected = catMunicipios[0];
    let vistaActas = 1
    console.log(tipo)
    switch (tipo.value) {
      case 3:
        vistaActas = 3
        setOptionsChart({
          ...optionsChart,
          Title: 'Elección de Diputado Federal',
        });
        setScope(catDF);
        setCatTiposAgrupador([
          { value: 3, label: 'Detalle por distritos Federales' },
          { value: 4, label: 'Detalle por casillas' },
        ]);
        ambitoSelected = catDF[0];
        setSeccionesFiltradas(
          [{ value: null, label: 'Todas' }].concat(
            secciones.filter((seccion) => seccion.idDF === ambitoSelected.value)
          )
        );
        break;
      case 5:
        vistaActas = 2
        setOptionsChart({
          ...optionsChart,
          Title: 'Elección de Diputado Local',
        });
        setScope(catDL);
        setCatTiposAgrupador([
          { value: 2, label: 'Detalle por distritos Locales' },
          { value: 4, label: 'Detalle por casillas' },
        ]);
        ambitoSelected = catDL[0];
        setSeccionesFiltradas(
          [{ value: null, label: 'Todas' }].concat(
            secciones.filter((seccion) => seccion.idDL === ambitoSelected.value)
          )
        );
        break;
      case 6:
        ambitoSelected = catMunicipios[0];
        vistaActas = 1
        setOptionsChart({
          ...optionsChart,
          Title: 'Elección de Presidente Municipal',
        });
        setScope(catMunicipios);
        setCatTiposAgrupador([
          { value: 1, label: 'Detalle por municipios' },
          { value: 4, label: 'Detalle por casillas' },
        ]);
        setSeccionesFiltradas(
          [{ value: null, label: 'Todas' }].concat(
            secciones.filter((seccion) => seccion.idMunicipio === ambitoSelected.value)
          )
        );
        break;
    }
    console.log("AMBITO", ambitoSelected);
    setFilters({
      ...filters,
      tipoEleccion: tipo,
      ambito: ambitoSelected,
      tipoAgrupador: { value: 4, label: 'Agrupado por casillas' },
    });
    getChartData(tipo.value, ambitoSelected.value, 4);
    getParticipacion({
      tipoEleccion: tipo.value,
      ambito: ambitoSelected,
    });
    getEstadisticas({
      tipoVista: vistaActas,
      ambito: ambitoSelected.value,
      idTipoEleccion: tipo.value
    });
    setVistaActas(vistaActas);
  };

  const handleAmbito = (ambito) => {
    setFilters({
      ...filters,
      ambito: ambito,
      seccion: { value: null, label: 'Todos' },
    });

    switch (filters.tipoEleccion.value) {
      case 6:
        setSeccionesFiltradas(
          [{ value: null, label: 'Todas' }].concat(
            secciones.filter((seccion) => seccion.idMunicipio === ambito.value)
          )
        );
        break;
      case 5:
        setSeccionesFiltradas(
          [{ value: null, label: 'Todas' }].concat(
            secciones.filter((seccion) => seccion.idDL === ambito.value)
          )
        );
        break;
      case 3:
        setSeccionesFiltradas(
          [{ value: null, label: 'Todas' }].concat(
            secciones.filter((seccion) => seccion.idDF === ambito.value)
          )
        );
        break;
    }
    getChartData(
      filters.tipoEleccion.value,
      ambito.value,
      filters.tipoAgrupador.value
    );
    getParticipacion({
      tipoEleccion: filters.tipoEleccion.value,
      ambito: ambito.value,
    });

    getEstadisticas({
      tipoVista: vistaActas,
      ambito: ambito.value,
      idTipoEleccion: filters.tipoEleccion.value
    });
  };

  const handleTipoAgrupador = (tipo) => {
    let seccionSelected = filters.seccion;
    if (tipo.value != 4) {
      seccionSelected = { value: null, label: 'Todas' };
    }
    setFilters({ ...filters, tipoAgrupador: tipo, seccion: seccionSelected });
  };

  const getChartData = (tipoeleccion, ambito, tipoAgrupador) => {
    setLoadingChart(true);
    EjecutarApi(
      {
        tipoEleccion: tipoeleccion,
        ambito: ambito,
        tipoAgrupador: tipoAgrupador,
      },
      'prep/getChart',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setIsActualizando(false);
            setDataChart(res.data.series);
            setSeries(res.data.series[filters.tipoVista]);
            setHora(res.hora)
          } else {
            notify.show(res.message, 'warning', 5000);
          }
        } else {
          notify.show(res.message, 'warning', 5000);
        }
        setLoadingChart(false);
      })
      .catch((e) => {
        notify.show(`Error al cargar : ${e.message}}`, 'error', 5000);
        setLoadingChart(false);
      });
  };

  const handleChangeSeccion = (seccion) => {
    setFilters({ ...filters, seccion: seccion });
  };

  const Actualizar = () => {
    setIsActualizando(true);
    getChartData(
      filters.tipoEleccion.value,
      filters.ambito.value,
      filters.tipoAgrupador.value
    );
    getParticipacion({
      tipoEleccion: filters.tipoEleccion.value,
      ambito: filters.ambito.value,
    });

    getEstadisticas({
      tipoVista: vistaActas,
      ambito: filters.ambito.value,
      idTipoEleccion: filters.tipoEleccion.value
    });
  }

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {_menu.Ver === 1 ? (
            <>
              <Row>
                <Card>

                  <CardHeader>
                    <Row>
                      <br />
                      <Col sm="12" className="text-left text-align-bottom-left">
                        <Label className=" text-right text-align-bottom-left"><strong>Última Actualización {hora}</strong> </Label>
                        <Button onClick={(e) => Actualizar()} className='btn btn-link' color='primary'><i className="fa fa-refresh" aria-hidden="true"></i> Actualizar </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Row>
              <Row>
                <Col md="4">
                  <StatsCard headerColor="green" title="Elección">
                    <h4>
                      {filters.tipoEleccion.label} de{' '}
                      <strong>{filters.ambito.label}</strong>
                    </h4>
                    <justify>
                      <h6>
                        Mostrando <strong>{filters.tipoAgrupador.label}</strong>
                      </h6>
                      <h6>
                        <strong>{optionsChart.Subtitle}</strong>
                      </h6>
                    </justify>
                    <br></br>
                  </StatsCard>
                </Col>
                <Col md="4">
                  <StatsCard
                    headerColor="#001689"
                    title="Actas Recibidas"
                    isLoading={isLoadingParticipacion}
                  >
                    <Col md="12" lg="12" sm="12">
                      <br></br>
                      <br></br>
                      <center>
                        <h1>
                          <strong>{totalActas.ActaRecibida}</strong> de{' '}
                          <strong>{totalActas.TotalActas}</strong>
                        </h1>
                        <Progress value={totalActas.TotalActas === 0 ? 0 : totalActas.ActaRecibida / totalActas.TotalActas * 100} />
                      </center>
                      <br></br>
                    </Col>
                  </StatsCard>
                </Col>
                <Col md="4">
                  <StatsCard
                    headerColor="red"
                    title="Participación"
                    isLoading={isLoadingParticipacion}
                  >
                    <Col md="12" lg="12" sm="12">
                      <br></br>
                      <br></br>
                      <center>
                        <h2><strong>{participacion.Avance} %</strong></h2>
                        <Progress value={participacion.Avance} />
                        {/* <h1>
                      <strong>{participacion.TotalVotos}</strong> de{' '}
                      <strong>{participacion.LNOM}</strong>
                    </h1> */}
                      </center>
                      <br></br>
                    </Col>
                  </StatsCard>
                </Col>
              </Row>
              <Row>
                <Col md="12" lg="12" xs="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="12" md="12" xs="12">
                          {!loadingChart && (
                            <ChartColumComparison
                              Title={optionsChart.Title}
                              Subtitle={optionsChart.Subtitle}
                              Series={Series}
                              tipoVista={filters.tipoVista}
                            ></ChartColumComparison>
                          )}
                          {loadingChart && (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#686769"
                              loading={true}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <FormGroup className="col-md-12 text-primary">
                            <div className="form-check-radio">
                              <Label check>
                                <Input
                                  type="radio"
                                  name="exampleRadios1"
                                  id="exampleRadios11"
                                  checked={filters.tipoVista === 1 ? true : false}
                                  defaultChecked={
                                    filters.tipoVista === 1 ? true : false
                                  }
                                  onChange={(e) => handleTipoVista(1)}
                                  disabled={disabled}
                                />
                            Vista por candidatos
                            <span className="form-check-sign"></span>
                              </Label>
                            </div>
                            <div className="form-check-radio">
                              <Label check>
                                <Input
                                  type="radio"
                                  name="exampleRadios1"
                                  id="exampleRadios11"
                                  checked={filters.tipoVista === 2 ? true : false}
                                  defaultChecked={
                                    filters.tipoVista === 2 ? true : false
                                  }
                                  onChange={(e) => handleTipoVista(2)}
                                  disabled={disabled}
                                />
                            Vista por Partidos
                            <span className="form-check-sign"></span>
                              </Label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <Label for="selecMpiocasa" className="labelForm">
                              <span className="text-danger">*</span>Tipo de
                          agrupación:
                        </Label>
                            <Select
                              options={catTiposAgrupador}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={filters.tipoAgrupador}
                              onChange={(e) => handleTipoAgrupador(e)}
                              placeholder="Seleccione una opción"
                            // isDisabled={disabled}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                          <FormGroup>
                            <Label for="selecMpiocasa" className="labelForm">
                              <span className="text-danger">*</span>Tipo de elección:
                        </Label>
                            <Select
                              options={catTiposEleccion}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={filters.tipoEleccion}
                              onChange={(e) => handleTipoEleccion(e)}
                              placeholder="Seleccione una opción"
                            // isDisabled={disabled}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                          <FormGroup>
                            <Label for="selecMpiocasa" className="labelForm">
                              <span className="text-danger">*</span>Ámbito de
                          elección:
                        </Label>
                            <Select
                              options={scope}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={filters.ambito}
                              onChange={(e) => handleAmbito(e)}
                              placeholder="Seleccione una opción"
                            // isDisabled={disabled}
                            />
                          </FormGroup>
                        </Col>
                        {filters.tipoAgrupador.value === 4 && (
                          <Col sm="3" md="3" lg="3">
                            <FormGroup>
                              <Label for="seccion" className="labelForm">
                                Busqueda por sección:
                          </Label>
                              <Select
                                id="seccion"
                                options={seccionesFiltradas}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={filters.seccion}
                                onChange={(e) => handleChangeSeccion(e)}
                                placeholder="Seleccione una opción"
                              // isDisabled={disabled}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <hr />
                      <Row>
                        {_menu.Exportar === 1 ? (
                          <Col sm="12">
                            <Button
                              name="btnExportar"
                              size="sm"
                              type="button"
                              className="primary float-right"
                              color="primary"
                              onClick={() => Exportar()}
                            >
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-lg" />
                              </span>
                              Exportar
                            </Button>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                      <Row>
                        <Col lg="12" md="12" xs="12">
                          {!isActualizando && <PrepTable
                            tipoEleccion={filters.tipoEleccion.value}
                            tipoAgrupador={filters.tipoAgrupador.value}
                            ambito={filters.ambito.value}
                            tipoVista={filters.tipoVista}
                            seccion={filters.seccion.value}
                          />}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Card>
              <CardBody>
                <h2 className='text-center'>Sin permisos, consulte al administrador</h2>
              </CardBody>
            </Card>
          )}

        </div>

      </div>
      <Footer fluid />
    </div>
  );
};

export default PREPDashBoard;
