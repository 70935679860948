import React, { useEffect, useState } from 'react';

import defaultImage from 'assets/img/default-avatar.png';

const PictureUpload = (props) => {
  const [imagen, setImagen] = useState({
    file: null,
    imagePreviewUrl: defaultImage,
  });
  // constructor(props) {
  //   super(props);
  //   this.state = {

  //   };
  //   this.handleImageChange = this.handleImageChange.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }

  useEffect(() => {
    if (props.imagenEdit) {
      setImagen({
        file: null,
        imagePreviewUrl: props.imagenEdit,
      });
    }
  }, [props.imagenEdit]);

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      const image = {
        file: file,
        imagePreviewUrl: reader.result,
      };
      setImagen(image);
      props.regresarImagen(image);
    };
    reader.readAsDataURL(file);
  };
  // handleSubmit(e) {
  //   e.preventDefault();
  //   // this.state.file is the file/image uploaded
  //   // in this function you can save the image (this.state.file) on form submit
  //   // you have to call it yourself
  // }
  // render() {
  return (
    <div className="picture-container">
      <div className="picture">
        <img src={imagen.imagePreviewUrl} className="picture-src" alt="..." />
        <input type="file" onChange={(e) => handleImageChange(e)} />
      </div>
      <h6 className="description">Seleccione una imagen</h6>
    </div>
  );
  // }
};

export default PictureUpload;
