import React, {  useEffect, useState } from "react";

import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";

//Zincri imports
import ChoropethMapV3 from "../../../components/Maps/ChoropethMapV3.jsx";
import { getVars } from "../../../GlobalStorage";
import { URL_GENERAL } from "../../../data/Consts";


const Mapas = (props) => {
 
  const token = getVars("Token");
  const [municipios, setMunicipios] = useState({});
  const [rangos, setRangos] = useState([]);

  //Zincri States
  const position = {
    latitude: 20.678416,
    longitude: -101.354231,
  };
  //Zincri States

  const hostserver = URL_GENERAL;
  useEffect(() => {
    const token = getVars("Token").access_token;
    async function getMunicipios() {
      try {
        let config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        let res = await fetch(
          hostserver+"getMunicipios",
          config
        );
        let data = await res.json();
        var json_parser = JSON.parse(data);
        setMunicipios(json_parser);
      } catch (error) {
      }
    }
    async function getRangos() {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: 1 }),
      };
      try {
        let res = await fetch(hostserver+"getRangos", config);
        let data = await res.json();
        setRangos(data);
      } catch (error) {
      }
    }

    getMunicipios();
    getRangos();
  }, []);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <div className="content">
          <>
            <ChoropethMapV3
              key={"ChoropethMapV3"}
              center={[position.latitude, position.longitude]}
              zoom={8}
              data={municipios}
              rangos={rangos}
              fillColor={"#F44F3B"}
              borderColor={"white"}
            ></ChoropethMapV3>
          </>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
