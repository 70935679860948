import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    CardHeader,
    TabContent,
    TabPane
} from 'reactstrap';
import ReactTable from 'react-table';
import Sidebar from 'components/Sidebar';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Notifications, { notify } from 'react-notify-toast';
import Pagination from 'components/Pagination/pagination';
import EjecutarApi from 'data/getPOST';
import { getVars } from 'GlobalStorage';
import FiltroUsr from "components/Filtro/indexUsuario";
import DetalleUserSocial from "components/Social/DetalleUserSocial"

const SocialListado = (props) => {
    const [_menu, _setmenu] = useState({});
    const token = getVars('Token');
    const [loading, setLoading] = useState(false);
    const [CatUser, setCatUser] = useState([]);
    const [TotalUser, setTotalUser] = useState(0);
    const [Filtro, setFiltro] = useState([]);
    const [activeTab, setActiveTab] = useState("1");
    const [User, setUser] = useState(token.user.id);

    useEffect(() => { }, [User])
    const handleMiniClick = () => {
        document.body.classList.toggle('sidebar-mini');
    };

    const viewDetail = (item) => {

        setUser(item);
    }
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Col sm="12">
                        <Card>
                            <CardHeader></CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm="12" lg="12" md="12">
                                        <FiltroUsr Menu={_menu} Datos={{}} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                                            <ReactTable
                                                style={{
                                                    width: "99%",
                                                    height: "100%"
                                                }}
                                                size='sm'
                                                data={CatUser}
                                                noDataText="Sin información"
                                                manual
                                                columns={[
                                                    {
                                                        Header: "Nombre",
                                                        minWidth: 110,
                                                        id: "NombreCompleto",
                                                        accessor: (d) => d.NombreCompleto,
                                                        filterAll: false,
                                                    },
                                                    {
                                                        Header: "Celular",
                                                        minWidth: 40,
                                                        id: "Celular",
                                                        accessor: (d) => d.Celular,
                                                        filterAll: false,
                                                    },
                                                ]}
                                                className="-striped -highlight primary-pagination"
                                                loading={loading}
                                                showPagination={true}
                                                showPaginationBottom={true}
                                                showTotalPages={true}
                                                showPageJump={false}
                                                canNextFromData={true}
                                                defaultPageSize={10}
                                                total={TotalUser}
                                                pages={Math.floor(TotalUser / 10)}
                                                previousText="Anterior"
                                                nextText="Siguiente"
                                                ofText="de"
                                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                                PaginationComponent={Pagination}
                                                onFetchData={(stateComp) => {
                                                    setLoading(true);
                                                    const data = {
                                                        page: stateComp.page,
                                                        pageSize: stateComp.pageSize,
                                                        sorted: [],
                                                        tipo: 'and',
                                                        filtered: Filtro ? Filtro : []
                                                    };
                                                    EjecutarApi(data, "getListadoUsuariosSocial", token.access_token)
                                                        .then(function (res) {
                                                            return res;
                                                        })
                                                        .then((res) => {
                                                            setLoading(false);
                                                            setTotalUser(res.total);
                                                            setCatUser(
                                                                res.data.map((prop, key) => {
                                                                    return {
                                                                        id: prop.id,
                                                                        NombreCompleto: <><Button
                                                                            className="btn-link"
                                                                            color="primary"
                                                                            onClick={() => viewDetail(prop.id)}
                                                                        >{prop.Nombre}</Button></>,
                                                                        Celular: prop.Celular ? prop.Celular : 'Sin Celular',
                                                                        data: prop,
                                                                    };
                                                                })
                                                            );

                                                        })
                                                        .catch((e) => {
                                                            setLoading(false);
                                                            notify.show(
                                                                `Error al obtener Usuarios: ${e.error}`,
                                                                "custom",
                                                                5000,
                                                                {
                                                                    background: "#0857B6",
                                                                    fontSize: 14,
                                                                    text: "#FFFFFF",
                                                                }
                                                            );
                                                        });
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">

                                                <DetalleUserSocial User={User} />)

                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
                <Footer fluid />
            </div>
        </div>
    )
}
export default SocialListado;