import React, { useEffect, useState } from "react";

import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";

//Zincri imports
import ChoropethMapV5AvanceElectoral from "components/Maps/Electoral/Avances/ChoropethMapV5AvanceElectoral.jsx";
//import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col/* , InputGroup, Input, InputGroupAddon, Label  */ } from 'reactstrap';
import { getVars } from "GlobalStorage";
import { URL_GENERAL } from "data/Consts";
import { getDataMenu } from 'data/menus.js';
import Notifications from 'react-notify-toast';
// import EjecutarApi from "data/getPOST";
import ReactBSAlert from 'react-bootstrap-sweetalert';
let SUPERVISOR_ESTATAL_ELECTORAL = 98;
let COORDINADOR_GENERAL = 99;
let COORDINADOR_ESTATAL_ELECTORAL = 16;
let COORDINADOR_REGIONAL_ELECTORAL = 17;
let COORDINADOR_MUNICIPAL_ELECTORAL = 18;
let PRIMERO_EN_FILA = 40;
let CASA_AZUL = 15;
let OBSERVADOR = 9;
let ABOGADOS = 8;
let RGs = 3;
let RCs = 2;
const Mapas = (props) => {
    const token = getVars("Token");
    const [MensajeAlert, setMensajeAlert] = useState('');
    const [derechoMapa, setDerechoMapa] = useState([]);
    const [derechoTipo, setDerechoTipo] = useState([]);
    const [flagFinish, setFlagFinish] = useState(false);
    const [_data, set_data] = useState([]);
    const _position = [20.816157, -101.021312]; //[_position, setPosition] = useState([20.816157, -101.021312]);
    const [dataGeoJson, setDataGeoJson] = useState({});
    const [menu, _setmenu] = useState({});
    const [FlagAlert, setFlagAlert] = useState(false);
    const [tipoMapa, setTipoMapa] = useState(
        getVars("Token").user.Responsabilidad.value === COORDINADOR_GENERAL ||
            getVars("Token").user.Responsabilidad.value === SUPERVISOR_ESTATAL_ELECTORAL ||
            getVars("Token").user.Responsabilidad.value === COORDINADOR_REGIONAL_ELECTORAL ||
            getVars("Token").user.Responsabilidad.value === COORDINADOR_ESTATAL_ELECTORAL
            ?
            { value: 'region', label: "Región" }
            :
            getVars("Token").user.Responsabilidad.value === COORDINADOR_MUNICIPAL_ELECTORAL
                ?
                { value: 'municipio', label: "Muncipio" }
                :
                { value: 'seccion', label: "Sección" }
    );
    const [tipoAvance, setTipoAvance] = useState('RCs'); //RCs, RGs, ABOGADOS, OBSERVADORES, CASA AZUL,PRIMERO FILA
    const [tipoRCAvance, setTipoRCAvance] = useState('P1');  //P1,P2,S1,S2

    const zoom =//[zoom, setZoom] = useState(

        getVars("Token").user.Responsabilidad.value === COORDINADOR_GENERAL ||
            getVars("Token").user.Responsabilidad.value === SUPERVISOR_ESTATAL_ELECTORAL ||
            getVars("Token").user.Responsabilidad.value === COORDINADOR_REGIONAL_ELECTORAL ||
            getVars("Token").user.Responsabilidad.value === COORDINADOR_ESTATAL_ELECTORAL ?
            9 :
            getVars("Token").user.Responsabilidad.value === COORDINADOR_MUNICIPAL_ELECTORAL ?
                10 :
                11;
    const hostserver = URL_GENERAL;

    useEffect(() => {
        _setmenu(getDataMenu(105, token.menus));
        selectDerecho();
        setFlagFinish(true);
    }, []);

    const basicAlert = (
        <ReactBSAlert
            style={{
                display: 'block',
                marginTop: '-100px',
                padding: '2px 4px',
                font: '10px/12px Arial, Helvetica, sans-serif',
            }}
            title={MensajeAlert}
            onConfirm={() => setFlagAlert(false)}
            onCancel={() => setFlagAlert(false)}
            confirmBtnBsStyle="info"
        />
    );

    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };

    function selectDerecho() {
        let derecho_mapa = getVars("Token").user.Responsabilidad.value;
        setDerechoMapa(
            derecho_mapa === COORDINADOR_GENERAL ||
                derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
                derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL ? [1, 3, 4] :
                derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL ? [1, 3, 4] :
                    derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL ? [1, 3] :
                        derecho_mapa === PRIMERO_EN_FILA ||
                            derecho_mapa === CASA_AZUL ||
                            derecho_mapa === OBSERVADOR ||
                            derecho_mapa === ABOGADOS ||
                            derecho_mapa === RGs ||
                            derecho_mapa === RCs
                            ? [1] :
                            []
        );
        if (
            derecho_mapa === COORDINADOR_GENERAL ||
            derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
            derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL ||
            derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL
        ) {
            setTipoMapa({ value: 'region', label: "Región" });
            getRegiones("", "GET");
            //setDataGeoJson(Regiones);
        }
        if (derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL) {
            setTipoMapa({ value: 'municipio', label: "Municipio" });
            getMunicipios("", "GET");
            //setDataGeoJson(Municipios);
        }
        if (
            derecho_mapa === PRIMERO_EN_FILA ||
            derecho_mapa === CASA_AZUL ||
            derecho_mapa === OBSERVADOR ||
            derecho_mapa === ABOGADOS ||
            derecho_mapa === RGs ||
            derecho_mapa === RCs
        ) {
            setTipoMapa({ value: 'seccion', label: "Sección" });
            getSecciones("", "GET");
            //setDataGeoJson(Secciones);
        }
        setDerechoTipo([1, 2, 3, 4, 5, 6]);
        setTipoAvance('RCs');
        setTipoRCAvance('P1');
    }


    const handleTipoMapa = (tipo) => {
        setFlagFinish(false);
        //setZoom(9);
        setDataGeoJson({});
        setTipoMapa(tipo);
        //setPosition([20.816157, -101.021312]);
        if (tipo.value === 'region') {
            if (tipoAvance === 'RCs' || tipoAvance === 'PRIMERO FILA') {
                getRegiones("", "GET");
            }
            else {
                getRegionesSC("", "GET");
            }

            setFlagFinish(true);
        }
        if (tipo.value === 'municipio') {
            if (tipoAvance === 'RCs' || tipoAvance === 'PRIMERO FILA') {
                getMunicipios("", "GET");
            }
            else {
                getMunicipiosSC("", "GET");
            }
            setFlagFinish(true);
        }
        if (tipo.value === 'seccion') {
            getSecciones("", "GET");
            setFlagFinish(true);
        }
    }

    const handleTipoAvance = (tipo) => {
        setFlagFinish(false);
        //setZoom(9);
        setDataGeoJson({});
        setTipoAvance(tipo);
        //setPosition([20.816157, -101.021312]);
        if (tipo === 'RCs' || tipo === 'PRIMERO FILA') {
            if (tipoMapa.value === "region") {
                getRegiones("", "GET");
            }
            else if (tipoMapa.value === "municipio") {
                getMunicipios("", "GET");
            }
            else {
                getSecciones("", "GET");
            }
            setFlagFinish(true);
        }
        else {
            if (tipoMapa.value === "region") {
                getRegionesSC("", "GET");
            }
            else if (tipoMapa.value === "municipio") {
                getMunicipiosSC("", "GET");
            }
            else {
                setDataGeoJson({
                    "type": "FeatureCollection",
                    "features": [
                    ]
                });
                setMensajeAlert('¡Este tipo de avance, no se puede ver a niel seccion!');
                setFlagAlert(true);
            }
            setFlagFinish(true);
        }
    }

    const handleTipoRCAvance = (tipo) => {
        setFlagFinish(false);
        //setZoom(9);
        setDataGeoJson({});
        setTipoRCAvance(tipo);
        //setPosition([20.816157, -101.021312]);
        if (tipoMapa.value === 'region') {
            getRegiones("", "GET");
            setFlagFinish(true);
        }
        if (tipoMapa.value === 'municipio') {
            getMunicipios("", "GET");
            setFlagFinish(true);
        }
        if (tipoMapa.value === 'seccion') {
            getSecciones("", "GET");
            setFlagFinish(true);
        }
    }

    const handleFiltro = (filtered, tipo_mapa) => {
        setFlagFinish(false);
        setDataGeoJson({});
        //setZoom(9);
        //setPosition([20.816157, -101.021312]);

        var idMunicipio = filtered.filter(dato => dato.id === "idMunicipio");
        var idRegion = filtered.filter(dato => dato.id === "idRegion");
        var idSeccion = filtered.filter(dato => dato.id === "idSeccion");
        var TieneActas = filtered.filter(dato => dato.id === "TieneActas");
        var TipoAvance = filtered.filter(dato => dato.id === "TipoAvance");
        var TipoAvanceRC = filtered.filter(dato => dato.id === "TipoAvanceRC");

        idMunicipio = idMunicipio[0].value;
        idRegion = idRegion[0].value;
        idSeccion = idSeccion[0].value;
        TieneActas = TieneActas[0].value;
        TipoAvance = TipoAvance[0].value;
        TipoAvanceRC = TipoAvanceRC[0].value;
        setTipoAvance(TipoAvance);
        setTipoRCAvance(TipoAvanceRC);

        var filtered_plugin = [];
        var puntos = false;
        if (TieneActas !== 9999) {
            puntos = true;
            if (TieneActas !== 3)
                filtered_plugin.push({ id: "cat_casillasgto.TieneActas", value: TieneActas });
        }
        if (idRegion.length !== 0 && idMunicipio.length === 0 && idSeccion.length === 0) {
            setDataGeoJson({});
            setTipoMapa({ value: 'region', label: "Región" });
            filtered_plugin.push({ id: "secciones.idRegion", value: idRegion });
            if (TipoAvance === 'RCs' || TipoAvance === 'PRIMERO FILA') {
                getRegiones(idRegion, 'POST');
            }
            else {
                getRegionesSC(idRegion, 'POST');
            }

        }

        else if (idMunicipio.length !== 0 && idSeccion.length === 0) {
            setDataGeoJson({});
            setTipoMapa({ value: 'municipio', label: "Municipio" });
            filtered_plugin.push({ id: "secciones.idMunicipioReportes", value: idMunicipio });
            if (TipoAvance === 'RCs' || TipoAvance === 'PRIMERO FILA') {
                getMunicipios(idMunicipio, 'POST');
            }
            else {
                getMunicipiosSC(idMunicipio, 'POST');
            }

        }
        else if (idMunicipio.length !== 0 && idSeccion.length !== 0) {
            setDataGeoJson({});
            if(TipoAvance === 'RCs' || TipoAvance === 'PRIMERO FILA'){
                setTipoMapa({ value: 'seccion', label: "Sección" });
                filtered_plugin.push({ id: "secciones.Seccion", value: idSeccion });
                getSecciones(idSeccion, 'POST');
            }
            else{
                setTipoMapa({ value: 'municipio', label: "Municipio" });
                filtered_plugin.push({ id: "secciones.idMunicipioReportes", value: idMunicipio });
                if (TipoAvance === 'RCs' || TipoAvance === 'PRIMERO FILA') {
                    getMunicipios(idMunicipio, 'POST');
                }
                else {
                    getMunicipiosSC(idMunicipio, 'POST');
                }
            }

        }
        else if (idSeccion.length !== 0) {
            setDataGeoJson({});
            setTipoMapa({ value: 'seccion', label: "Sección" });
            filtered_plugin.push({ id: "secciones.Seccion", value: idSeccion });
            if (TipoAvance === 'RCs' || TipoAvance === 'PRIMERO FILA') {
                getSecciones(idSeccion, 'POST');
            }
            else {
                //getSeccionesSC(idSeccion, 'POST');
                //No aplica vista de secciones
            }

        }
        
        if (idSeccion.length === 0 && idMunicipio.length === 0 && idRegion.length === 0
            ||
            ((idSeccion.length !== 0 && !(TipoAvance === 'RCs' || TipoAvance === 'PRIMERO FILA')  )&& idMunicipio.length === 0 && idRegion.length === 0)
            ) {
            if (getVars("Token").user.Responsabilidad.value === COORDINADOR_MUNICIPAL_ELECTORAL) {
                if (TipoAvance === 'RCs' || TipoAvance === 'PRIMERO FILA') {
                    setTipoMapa({ value: 'municipio', label: "Municipio" });
                    getMunicipios("", "GET");
                }
                else {
                    setTipoMapa({ value: 'municipio', label: "Municipio" });
                    getMunicipiosSC("", "GET");
                }

            }
            else {
                if (TipoAvance === 'RCs' || TipoAvance === 'PRIMERO FILA') {
                    setTipoMapa({ value: 'region', label: "Región" });
                    getRegiones("", "GET");
                }
                else {
                    setTipoMapa({ value: 'region', label: "Región" });
                    getRegionesSC("", "GET");
                }
            }
            
        }
        setFlagFinish(true);
        /* let _token = token.access_token;
        const datos = {
            page: 0,
            tipo: 'and',
            pageSize: 10000,
            sorted: [],
            filtered: filtered_plugin
        };
            EjecutarApi(datos, 'getPuntosCasillasGto', _token, 'POST')
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    if(puntos) {
                        set_data(res.data.map((prop, key) => {

                            return {
                                Latitude: prop.Latitude,
                                Longitude: prop.Longitude,
                                idRCPropietario1: prop.idRCPropietario1,
                                idRCPropietario2: prop.idRCPropietario2,
                                idRCSuplente1: prop.idRCSuplente1,
                                idRCSuplente2: prop.idRCSuplente2,
                                RCPropietario1: prop.RCPropietario1,
                                RCPropietario2: prop.RCPropietario2,
                                RCSuplente1: prop.RCSuplente1,
                                RCSuplente2: prop.RCSuplente2,
                                TipoDomicilio: prop.TipoDomicilio,
                                Referencia: prop.Referencia,
                                Ubicacion: prop.Ubicacion,
                                LocalidadManzana: prop.LocalidadManzana,
                                Domicilio: prop.Domicilio,
                                TipoCasilla: prop.TipoCasilla,
                                Casilla: prop.Casilla,
                                TieneActas: prop.TieneActas,
                                LNOM: prop.LNOM,
                                PadronElecoral: prop.PadronElecoral,
                                TipoSeccion: prop.TipoSeccion,
                                Seccion: prop.Seccion,
                                Municipio: prop.Municipio,
                                data: prop
                            };

                        }

                        ));
                        setFlagFinish(true);
                    }
                    else {
                        set_data([]);
                        setFlagFinish(true);
                    }
                    

                } else {
                    // mandar mensaje de que no se encontró informacion
                }
            })
            .catch((e) => {
                setMensajeAlert(e.message);
                setFlagAlert(true);
            }); */


    }

    const handleDetalleMapa = (e) => {
        setDataGeoJson({});
        setTipoMapa(e);
        selectmapaparametros(e);
    }
    function selectmapaparametros(tipomapa) {
        if (tipomapa.value === 'municipio') {
            if (tipoAvance === 'RCs' || tipoAvance === 'PRIMERO FILA') {
                getMunicipiosRegion([tipomapa.clave], 'POST');
            }
            else {
                getMunicipiosRegionSC([tipomapa.clave], 'POST');
            }

        }
        if (tipomapa.value === 'seccion') {
            getSeccionesMunicipios([tipomapa.clave], 'POST');
        }
    }

    async function getRegiones(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }

            let res = await fetch(
                hostserver + "getRegionesAvanceElectoralGto",
                config
            );
            let data = await res.json();
            if (data.success && data.results) {
                var json_parser_principal = JSON.parse(data.principal);
                if (json_parser_principal.features === null) {
                    throw new Error(data.message);
                }
            }
            else {
                throw new Error(data.message);
            }
            setDataGeoJson(json_parser_principal);
        } catch (error) {
            if (error.message === 'Token Signature could not be verified.'
                || error.message === 'Sin autorizacion. El token ha expirado'
                || error.message === 'El token esta en la lista negra') {
                setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
                setFlagAlert(true);
            }
            else {
                setMensajeAlert(error.message);
                setFlagAlert(true);
            }
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
        }
    }
    async function getRegionesSC(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }

            let res = await fetch(
                hostserver + "getRegionesAvanceElectoralSCGto",
                config
            );
            let data = await res.json();
            if (data.success && data.results) {
                var json_parser_principal = JSON.parse(data.principal);
                if (json_parser_principal.features === null) {
                    throw new Error(data.message);
                }
            }
            else {
                throw new Error(data.message);
            }
            setDataGeoJson(json_parser_principal);
        } catch (error) {
            if (error.message === 'Token Signature could not be verified.'
                || error.message === 'Sin autorizacion. El token ha expirado'
                || error.message === 'El token esta en la lista negra') {
                setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
                setFlagAlert(true);
            }
            else {
                setMensajeAlert(error.message);
                setFlagAlert(true);
            }
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
        }
    }
    async function getMunicipios(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getMunicipiosAvanceElectoralGto",
                config
            );
            let data = await res.json();
            if (data.success && data.results) {
                var json_parser_principal = JSON.parse(data.principal);
                if (json_parser_principal.features === null) {
                    throw new Error(data.message);
                }
            }
            else {
                throw new Error(data.message);
            }
            setDataGeoJson(json_parser_principal);
        } catch (error) {
            if (error.message === 'Token Signature could not be verified.'
                || error.message === 'Sin autorizacion. El token ha expirado'
                || error.message === 'El token esta en la lista negra') {
                setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
                setFlagAlert(true);
            }
            else {
                setMensajeAlert(error.message);
                setFlagAlert(true);
            }
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
        }
    }
    async function getMunicipiosSC(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getMunicipiosAvanceElectoralSCGto",
                config
            );
            let data = await res.json();
            if (data.success && data.results) {
                var json_parser_principal = JSON.parse(data.principal);
                if (json_parser_principal.features === null) {
                    throw new Error(data.message);
                }
            }
            else {
                throw new Error(data.message);
            }
            setDataGeoJson(json_parser_principal);
        } catch (error) {
            if (error.message === 'Token Signature could not be verified.'
                || error.message === 'Sin autorizacion. El token ha expirado'
                || error.message === 'El token esta en la lista negra') {
                setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
                setFlagAlert(true);
            }
            else {
                setMensajeAlert(error.message);
                setFlagAlert(true);
            }
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
        }
    }
    async function getSecciones(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getSeccionesAvanceElectoralGto",
                config
            );
            let data = await res.json();
            if (data.success && data.results) {
                var json_parser_principal = JSON.parse(data.principal);
                if (json_parser_principal.features === null) {
                    throw new Error(data.message);
                }
            }
            else {
                throw new Error(data.message);
            }
            setDataGeoJson(json_parser_principal);
        } catch (error) {
            if (error.message === 'Token Signature could not be verified.'
                || error.message === 'Sin autorizacion. El token ha expirado'
                || error.message === 'El token esta en la lista negra') {
                setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
                setFlagAlert(true);
            }
            else {
                setMensajeAlert(error.message);
                setFlagAlert(true);
            }
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
        }
    }
    async function getMunicipiosRegion(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getMunicipiosAvanceElectoralRegionGto",
                config
            );
            let data = await res.json();
            if (data.success && data.results) {
                var json_parser_principal = JSON.parse(data.principal);
                if (json_parser_principal.features === null) {
                    throw new Error(data.message);
                }
            }
            else {
                throw new Error(data.message);
            }
            setDataGeoJson(json_parser_principal);
        } catch (error) {
            if (error.message === 'Token Signature could not be verified.'
                || error.message === 'Sin autorizacion. El token ha expirado'
                || error.message === 'El token esta en la lista negra') {
                setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
                setFlagAlert(true);
            }
            else {
                setMensajeAlert(error.message);
                setFlagAlert(true);
            }
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
        }
    }
    async function getMunicipiosRegionSC(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getMunicipiosAvanceElectoralRegionSCGto",
                config
            );
            let data = await res.json();
            if (data.success && data.results) {
                var json_parser_principal = JSON.parse(data.principal);
                if (json_parser_principal.features === null) {
                    throw new Error(data.message);
                }
            }
            else {
                throw new Error(data.message);
            }
            setDataGeoJson(json_parser_principal);
        } catch (error) {
            if (error.message === 'Token Signature could not be verified.'
                || error.message === 'Sin autorizacion. El token ha expirado'
                || error.message === 'El token esta en la lista negra') {
                setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
                setFlagAlert(true);
            }
            else {
                setMensajeAlert(error.message);
                setFlagAlert(true);
            }
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
        }
    }
    async function getSeccionesMunicipios(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getSeccionesAvanceElectoralMunicipioGto",
                config
            );
            let data = await res.json();
            if (data.success && data.results) {
                var json_parser_principal = JSON.parse(data.principal);
                if (json_parser_principal.features === null) {
                    throw new Error(data.message);
                }
            }
            else {
                throw new Error(data.message);
            }
            setDataGeoJson(json_parser_principal);
        } catch (error) {
            if (error.message === 'Token Signature could not be verified.'
                || error.message === 'Sin autorizacion. El token ha expirado'
                || error.message === 'El token esta en la lista negra') {
                setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
                setFlagAlert(true);
            }
            else {
                setMensajeAlert(error.message);
                setFlagAlert(true);
            }
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
        }
    }
    return (
        <>
            <Notifications />
            <div className="wrapper">
                <Sidebar
                    {...props}
                    menus={token.menus}
                    bgColor="black"
                    activeColor="info"
                />
                <div className="main-panel">
                    <Navbar
                        {...props}
                        handleMiniClick={handleMiniClick}
                        menus={token.menus}
                    />
                    <div className="content">
                        <>
                            {FlagAlert ? basicAlert : <></>}

                            <ChoropethMapV5AvanceElectoral
                                center={_position}
                                zoom={zoom}
                                derecho={derechoMapa}
                                derecho_tipo_avance={derechoTipo}
                                _derecho_tipo_rcs={[1, 2, 3, 4]}
                                _flagFinish={flagFinish}
                                data={_data}
                                Shape={dataGeoJson}
                                handleFiltro={handleFiltro}
                                handleTipoMapa={handleTipoMapa}
                                handleTipoAvance={handleTipoAvance}
                                handleTipoRCAvance={handleTipoRCAvance}
                                TipoAvance={tipoAvance}
                                TipoAvanceRC={tipoRCAvance}
                                handleDetalleMapa={handleDetalleMapa}
                                MenuPermisos={menu}
                                TipoMapa={tipoMapa}
                                Responsabilidad={getVars("Token").user.Responsabilidad.value}
                            >
                            </ChoropethMapV5AvanceElectoral>

                        </>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Mapas;