import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, CardBody, CardFooter, Row } from 'reactstrap';
import Notifications from "react-notify-toast";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import {getDataMenu} from 'data/menus';
import { getVars } from 'GlobalStorage';
//import Filtro from 'components/Filtro/indexNumeralia';

const CompromisoNumeralia = (props) => {
    const token = getVars('Token');
    const [Menu,setMenu] = useState({});
    useEffect(() =>{
        setMenu(getDataMenu(1, token.menus));
    },[])
    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
      };
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Card>
                            <CardBody>
                                 {/* <Filtro></Filtro> */}
                            </CardBody>
                        </Card>
                    </Row>
                   
                </div>
                <Footer fluid />
            </div>
        </div>
    );

};export default CompromisoNumeralia;