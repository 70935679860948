import React, { useEffect, useState, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
//Zincri imports
import ChoropethMap from 'components/Maps/Impresion/ChoropethMapV5WebSeccionAllUsersDragMap.jsx';
import { getVars } from 'GlobalStorage';
import { MagicSpinner } from 'react-spinners-kit';
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  UncontrolledTooltip,
  Input,
} from 'reactstrap';

import ReactBSAlert from 'react-bootstrap-sweetalert';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import { URL_GENERAL } from 'data/Consts';
import { getDataMenu } from 'data/menus';

import EjecutarApi from '../../../data/getPOST';

let style_spinner = {
  height: '500pt',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const Mapas = (props) => {
  const token = getVars('Token');
  const [dataGeoJson, setDataGeoJson] = useState({
    type: 'FeatureCollection',
    features: [],
  });

  const [tipoMapa, setTipoMapa] = useState({
    value: 'seccion',
    label: 'Seccional',
  });
  const [zoom, setZoom] = useState(9);
  const [menu, _setmenu] = useState({});
  const [flagFinish, setFlagFinish] = useState(true);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [cardShow, setCardShow] = useState(false);
  const [flagUpdateDirecciones, setFlagUpdateDirecciones] = useState(true);

  const [MensajeAlert, setMensajeAlert] = useState('');
  const hostserver = URL_GENERAL;
  const [arrayDirecciones, setArrayDirecciones] = useState([]);
  const [resultSeccion, setResultSeccion] = useState(0);
  const [latlng, setLatLng] = useState({ lat: '', lng: '' });
  const [resultManzana, setResultManzana] = useState({
    ClaveDireccion: 0,
    ClaveLocalidad: 0,
    Localidad: null,
    MANZANA: 0,
    Municipio: null,
    MunicipioReportes: null,
    Poligono: null,
    Region: null,
    SECCION: 0,
    idMunicipio: 0,
    idMunicipioReportes: 0,
    idPoligono: 0,
    idRegion: 0,
    Latitud: null,
    Longitud: null,
  });
  const [flagLoadingSpinner, setFlagLoadingSpinner] = useState(false);
  const [copy, setCopy] = useState({
    value: '',
    copied: false,
  });
  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };
  useEffect(() => {
    _setmenu(getDataMenu(160, token.menus));
    getDomiciliosForUser();
  }, [dataGeoJson]);

  const getDomiciliosForUser = () => {
    EjecutarApi(
      {
        page: 0,
        pageSize: 10,
        Asignadas: false,
      },
      'getAddressForUserGto',
      token.access_token,
      'POST'
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (res.data) {
            setArrayDirecciones(res.data);
          }
        } else {
          setArrayDirecciones([]);
        }
      })
      .catch((e) => {});
    setFlagUpdateDirecciones(false);
  };
  const basicAlert = (
    <ReactBSAlert
      style={{ display: 'block', marginTop: '-100px' }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );

  const clickDownloadFile = (e) => {
    if (menu.Exportar === 0) {
      setMensajeAlert('¡No cuenta con permisos para exportar!');
      setFlagAlert(true);
    }
    var url = new URL(
      hostserver +
        `getArchivoMapaWeb?token=${token.access_token}&HashCode=${e.feature.properties.HashCode}`
    );
    window.open(url, '_blank');
  };

  function btnBuscar(seccion) {
    const Seccion = seccion;
    //if (Seccion+"".length > 0) {
    if (Seccion <= 0 || Seccion > 3178) {
      setMensajeAlert('¡Sección incorrecta!');
      setFlagAlert(true);
    } else {
      btnSearchSeccion(Seccion);
    }

    /* } else {
      setMensajeAlert("¡Favor de especificar una sección!");
      setFlagAlert(true);
    } */
  }

  const HandleChangeseFlagLoadingSpinner = (item) => {
    setFlagLoadingSpinner(item);
  }
  async function FiltroSearchSeccion(body) {
    setZoom(12);
    setDataGeoJson({});
    const token = getVars('Token').access_token;
    try {
      let config = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      };

      let res = await fetch(
        hostserver + 'getSeccionesManzanasDireccionGtoWeb',
        config
      );
      let data = await res.json();
      var json_parser_secciones = JSON.parse(data.seccion);
      var json_parser_manzanas = JSON.parse(data.manzanas);

      if (data.success && data.results) {
        if (json_parser_secciones.features === null) {
          throw new Error(data.message);
        }
        if (json_parser_manzanas.features === null) {
          json_parser_manzanas = {
            type: 'FeatureCollection',
            features: [],
          };
        }
      } else {
        throw new Error(data.message);
      }

      json_parser_secciones.features.forEach((seccion_item) => {
        json_parser_manzanas.features.push(seccion_item);
      });
      setDataGeoJson(json_parser_manzanas);
    } catch (error) {
      if (
        error.message === 'Token Signature could not be verified.' ||
        error.message === 'Sin autorizacion. El token ha expirado' ||
        error.message === 'El token esta en la lista negra'
      ) {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      } else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
    }
  }

  async function btnSearchSeccion(Seccion) {
    //getRangos();
    setZoom(12);
    setDataGeoJson({});
    const token = getVars('Token').access_token;
    try {
      let config = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Region: [],
          Municipio: [],
          Poligono: [],
          Seccion: [Seccion],
        }),
      };

      let res = await fetch(
        hostserver + 'getSeccionesManzanasDireccionGtoWeb',
        config
      );
      let data = await res.json();

      if (data.success && data.results) {
        var json_parser_secciones = JSON.parse(data.seccion);
        var json_parser_manzanas = JSON.parse(data.manzanas);

        if (json_parser_secciones.features === null) {
          throw new Error(data.message);
        }
        if (json_parser_manzanas.features === null) {
          json_parser_manzanas = {
            type: 'FeatureCollection',
            features: [],
          };
        }
      } else {
        throw new Error(data.message);
      }

      /* json_parser_secciones.features.forEach(seccion_item => {
        json_parser_manzanas.features.push(seccion_item);
      }); */
      json_parser_manzanas.features.forEach((manzana_item) => {
        json_parser_secciones.features.push(manzana_item);
      });
      setDataGeoJson(json_parser_secciones);
    } catch (error) {
      if (
        error.message === 'Token Signature could not be verified.' ||
        error.message === 'Sin autorizacion. El token ha expirado' ||
        error.message === 'El token esta en la lista negra'
      ) {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      } else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
    }
  }

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  async function getSecciones(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + 'getSeccionesManzanasDireccionGtoWeb',
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
    } catch (error) {
      if (
        error.message === 'Token Signature could not be verified.' ||
        error.message === 'Sin autorizacion. El token ha expirado' ||
        error.message === 'El token esta en la lista negra'
      ) {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      } else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
    }
  }

  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapaparametros(e);
  };
  const handleSearhSeccion = (e) => {
    btnBuscar(e);
  };
  const handleLatLng = (e) => {
    setLatLng(e);
  };
  const handleSaveDireccion = (e) => {
    var resultManzana_recept = resultManzana;
    resultManzana_recept.Latitud = latlng.lat;
    resultManzana_recept.Longitud = latlng.lng;

    if (
      resultManzana_recept.ClaveDireccion !== 0 &&
      resultManzana_recept.ClaveLocalidad !== 0 &&
      resultManzana_recept.Localidad !== null &&
      resultManzana_recept.MANZANA !== 0 &&
      resultManzana_recept.Municipio !== null &&
      resultManzana_recept.MunicipioReportes !== null &&
      resultManzana_recept.Poligono !== null &&
      resultManzana_recept.Region !== null &&
      resultManzana_recept.SECCION !== 0 &&
      resultManzana_recept.idMunicipio !== 0 &&
      resultManzana_recept.idMunicipioReportes !== 0 &&
      resultManzana_recept.idPoligono !== 0 &&
      resultManzana_recept.idRegion !== 0 &&
      resultManzana_recept.Latitud !== 0 &&
      resultManzana_recept.Longitud !== 0
    ) {
      
      EjecutarApi(
        resultManzana_recept,
        'setAddressGto',
        token.access_token,
        'POST'
      )
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            if (res.data) {
              setMensajeAlert('¡Dirección Actualizada Correctamente!');
              setFlagAlert(true);
            }
          } else {
            setMensajeAlert('¡La Dirección No se Actualizó!');
            setFlagAlert(true);
          }
          setArrayDirecciones((state) => state.filter((i) => i.id !== resultManzana_recept.ClaveDireccion));
        })
        .catch((e) => {
          setMensajeAlert('¡Algo salió mal intentalo nuevamente!');
          setFlagAlert(true);
        });
    } else {
      setMensajeAlert('¡Verifica que la dirección contenga sección y manzana!');
      setFlagAlert(true);
    }
    return true;
  };

  
  const handeRemoveDireccion = (e) => {
      
      EjecutarApi(
        {id:e},
        'setRemoveAddressGto',
        token.access_token,
        'POST'
      )
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            if (res.data) {
              setMensajeAlert('¡Dirección Removida Correctamente!');
              setFlagAlert(true);
            }
          } else {
            setMensajeAlert('¡La Dirección no fue removida!');
            setFlagAlert(true);
          }
          //setArrayDirecciones((state) => state.filter((i) => i.id !== resultManzana_recept.ClaveDireccion));
        })
        .catch((e) => {
          setMensajeAlert('¡Algo salió mal intentalo nuevamente!');
          setFlagAlert(true);
        });
    return true;
  };
  const handleManzanaFromCoords = (coord_x, coord_y) => {
    EjecutarApi(
      { coord_x: coord_x, coord_y: coord_y },
      'getManzanaFromCoordsGto',
      token.access_token,
      'POST'
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        //if (res.results) {
        if (res.data) {
          setResultManzana({
            ...resultManzana,
            ClaveLocalidad: res.data.ClaveLocalidad,
            Localidad: res.data.Localidad,
            MANZANA: res.data.MANZANA,
            Municipio: res.data.Municipio,
            MunicipioReportes: res.data.MunicipioReportes,
            Poligono: res.data.Poligono,
            Region: res.data.Region,
            SECCION: res.data.SECCION,
            idMunicipio: res.data.idMunicipio,
            idMunicipioReportes: res.data.idMunicipioReportes,
            idPoligono: res.data.idPoligono,
            idRegion: res.data.idRegion,
          });
          HandleChangeseFlagLoadingSpinner(false);
        }
        //}
      })
      .catch((e) => {
        // Mensaje de que algo salio mal
      });
  };
  const handleSeccionFromCoords = (coord_x, coord_y) => {
    //setDataGeoJson({});
    EjecutarApi(
      { coord_x: coord_x, coord_y: coord_y },
      'getSeccionFromCoordsGto',
      token.access_token,
      'POST'
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        //if (res.results) {
        if (res.seccion) {
          setResultSeccion(res.seccion);
          HandleChangeseFlagLoadingSpinner(false);
        }
        //}
      })
      .catch((e) => {});
  };
  const handleTipoMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapa(e);
  };
  function selectmapa(tipomapa) {
    if (tipomapa.value === 'seccion') {
      getSecciones('', 'GET');
    }
  }

  function selectmapaparametros(tipomapa) {
    if (tipomapa.value === 'seccion') {
      getSecciones(tipomapa.clave, 'POST');
    }
  }

  const handleFiltro = (filtered, tipo_mapa) => {
    //setMunicipioSaved({});
    //setFilteredSaved(filtered);
    setFlagFinish(false);
    //setZoom(9);
    //setPosition([20.816157, -101.021312]);

    var idMunicipio = filtered.filter((dato) => dato.id === 'idMunicipio');
    var idRegion = filtered.filter((dato) => dato.id === 'idRegion');
    var idSeccion = filtered.filter((dato) => dato.id === 'idSeccion');
    var TieneActas = filtered.filter((dato) => dato.id === 'TieneActas');

    idMunicipio = idMunicipio[0].value;
    idRegion = idRegion[0].value;
    idSeccion = idSeccion[0].value;
    TieneActas = TieneActas[0].value;

    var filtered_plugin = [];
    var puntos = false;
    var data_filtro_impresion = {};
    if (TieneActas !== 9999) {
      puntos = true;
      if (TieneActas !== 3)
        filtered_plugin.push({
          id: 'cat_casillasgto.TieneActas',
          value: TieneActas,
        });
    }
    if (
      idRegion.length !== 0 &&
      idMunicipio.length === 0 &&
      idSeccion.length === 0
    ) {
      //setZoom(9);
      setDataGeoJson({});
      //setTipoMapa({ value: 'region', label: 'Regional' });
      filtered_plugin.push({ id: 'secciones.idRegion', value: idRegion });

      data_filtro_impresion = {
        Region: idRegion,
        Municipio: [],
        Poligono: [],
        Seccion: [],
      };
      //getRegiones(idRegion, 'POST');
    }

    if (idMunicipio.length !== 0 && idSeccion.length === 0) {
      //setZoom(10);
      setDataGeoJson({});
      //setTipoMapa({ value: 'municipio', label: 'Municipal' });
      filtered_plugin.push({
        id: 'secciones.idMunicipioReportes',
        value: idMunicipio,
      });
      data_filtro_impresion = {
        Region: [],
        Municipio: idMunicipio,
        Poligono: [],
        Seccion: [],
      };
      //getMunicipios(idMunicipio, 'POST');
    }
    if (idSeccion.length !== 0) {
      //setZoom(13);
      setDataGeoJson({});
      //setTipoMapa({ value: 'seccion', label: 'Seccional' });
      filtered_plugin.push({ id: 'secciones.Seccion', value: idSeccion });
      data_filtro_impresion = {
        Region: [],
        Municipio: [],
        Poligono: [],
        Seccion: idSeccion,
      };
      //getSecciones(idSeccion, 'POST');
    } else {
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      setFlagFinish(true);
      setMensajeAlert('¡Debe especificar el grupo de secciones a buscar!');
      setFlagAlert(true);
      return true;
    }
    FiltroSearchSeccion(data_filtro_impresion);
    setFlagFinish(true);
    /* if (
      idSeccion.length === 0 &&
      idMunicipio.length === 0 &&
      idRegion.length === 0
    ) {
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    } */
  };
  const onCopyToClipboard = (item) => {
    /* if (document.getElementById(`MyInput_l1_direccion${item}`)) {
    } */
  };
  const handleActualizar = () => {
    getDomiciliosForUser();
  };
  const handleRemoveCardAddress = (item) => {
    handeRemoveDireccion(item);
    let array_temporal = arrayDirecciones.filter((i) => i.id !== item);
    setArrayDirecciones((state) => state.filter((i) => i.id !== item));
  };

  return (
    <div className="wrapper">
      <div hidden id="dataVars" name="dataVars"></div>
      <div hidden id="idUser" name="idUser"></div>
      <div hidden id="DeviceID" name="DeviceID"></div>
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <div className="content">
          <Button hidden id="btnS" name="btnS" onClick={() => btnBuscar()}>
            {' '}
            Buscar
          </Button>
          <>
            {FlagAlert ? basicAlert : <></>}
            <Row>
              <Col style={{ padding: '0px' }} xs="4" sm="4" md="4">
                <div
                  style={{
                    height: '90vh',
                    overflow: 'scroll',
                    display: 'block',
                  }}
                >
                  <Card>
                    <CardBody style={{ padding: '5px' }}>
                      <Row>
                        <Col
                          style={{ padding: '15px 0px 15px 15px' }}
                          xs="10"
                          sm="10"
                          md="10"
                        >
                          <strong>
                            <Label>LISTADO DE DIRECCIONES ASIGNADAS</Label>
                          </strong>
                        </Col>
                        <Col style={{ padding: '0px' }}>
                          <Button
                            className="btn-round btn-icon btn-icon-mini btn-neutral"
                            color="info"
                            id={`tooltip_card_gtitle3`}
                            title=""
                            type="button"
                            onClick={() => {
                              setFlagUpdateDirecciones(true);
                              handleActualizar();
                            }}
                          >
                            <i className="nc-icon nc-refresh-69" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target={`tooltip_card_gtitle3`}
                          >
                            Actualizar
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {flagUpdateDirecciones ? (
                    <div style={style_spinner}>
                      <MagicSpinner
                        size={70}
                        color={'#008000'}
                        loading={true}
                      ></MagicSpinner>
                    </div>
                  ) : (
                    <>
                      {arrayDirecciones.map((item_completo) => {
                        const item = item_completo.id;
                        return (
                          <Card
                            key={`clave_card_089_${item}`}
                            onClick={() => {
                              let Clave = item;
                              /* setResultManzana({
                              ...resultManzana,
                              ClaveDireccion: Clave,
                            }); */
                              setResultManzana({
                                ClaveDireccion: Clave,
                                ClaveLocalidad: 0,
                                Localidad: null,
                                MANZANA: 0,
                                Municipio: null,
                                MunicipioReportes: null,
                                Poligono: null,
                                Region: null,
                                SECCION: 0,
                                idMunicipio: 0,
                                idMunicipioReportes: 0,
                                idPoligono: 0,
                                idRegion: 0,
                              });

                              setCardShow(true);
                            }}
                            color={
                              cardShow && resultManzana.ClaveDireccion == item
                                ? '#e5ffff'
                                : 'white'
                            }
                            style={{
                              backgroundColor:
                                cardShow && resultManzana.ClaveDireccion == item
                                  ? '#e5ffff'
                                  : 'white',
                              borderColor:
                                cardShow && resultManzana.ClaveDireccion == item
                                  ? '#e5ffff'
                                  : 'white',
                            }}
                          >
                            <CardTitle
                              style={{ padding: '0px', marginBottom: '0px' }}
                            >
                              <Button
                                onClick={() => {
                                  handleRemoveCardAddress(item);
                                }}
                                close
                              />
                            </CardTitle>
                            <CardBody style={{ padding: '5px 15px 10px 15px' }}>
                              <Row>
                                <Col
                                  style={{ padding: '0px 0px 0px 15px' }}
                                  xs="7"
                                  sm="7"
                                  md="7"
                                >
                                  <Label>
                                    <div
                                      id={`MyInput_l1_direccion${item}`}
                                      name={`MyInput_l1_direccion${item}`}
                                    >
                                      {item_completo.Direccion}
                                    </div>
                                  </Label>
                                </Col>
                                <Col
                                  style={{
                                    paddingRight: '0px',
                                    paddingLeft: '0px',
                                  }}
                                >
                                  <Label>
                                    <div
                                      id={`MyInput_lx${item}`}
                                      name={`MyInput_lx${item}`}
                                    >
                                      {'M:' +
                                        (resultManzana.ClaveDireccion === item
                                          ? resultManzana.MANZANA
                                            ? resultManzana.MANZANA
                                            : 0
                                          : 0)}
                                    </div>
                                  </Label>
                                  <br></br>
                                  <Label>
                                    <div
                                      id={`MyInput_ly${item}`}
                                      name={`MyInput_ly${item}`}
                                    >
                                      {'S:' +
                                        (resultManzana.ClaveDireccion === item
                                          ? resultManzana.SECCION
                                            ? resultManzana.SECCION
                                            : 0
                                          : 0)}
                                    </div>
                                  </Label>
                                </Col>
                                <Col style={{ padding: '0px' }}>
                                  <Row>
                                    {/* <Col style={{ padding: '0pt' }}> */}
                                    <CopyToClipboard
                                      onCopy={onCopyToClipboard(item)}
                                      text={
                                        document.getElementById(
                                          `MyInput_l1_direccion${item}`
                                        )
                                          ? document.getElementById(
                                              `MyInput_l1_direccion${item}`
                                            ).innerHTML
                                          : ''
                                      }
                                    >
                                      <Button
                                        className="btn-round btn-icon btn-icon-mini btn-neutral"
                                        color="dark"
                                        id={`tooltip_card_c${item}`}
                                        title=""
                                        type="button"
                                      >
                                        <i className="nc-icon nc-single-copy-04" />
                                      </Button>
                                    </CopyToClipboard>
                                    {/* </Col> */}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={`tooltip_card_c${item}`}
                                    >
                                      Copiar
                                    </UncontrolledTooltip>

                                    {/* <Col style={{ padding: '0pt' }}> */}
                                    <Button
                                      className="btn-round btn-icon btn-icon-mini btn-neutral"
                                      color="info"
                                      id={`tooltip_card_g${item}`}
                                      title=""
                                      type="button"
                                      onClick={() => {
                                        handleSaveDireccion(item);
                                      }}
                                    >
                                      <i className="nc-icon nc-cloud-upload-94" />
                                    </Button>
                                    {/* </Col> */}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={`tooltip_card_g${item}`}
                                    >
                                      Guardar
                                    </UncontrolledTooltip>
                                  </Row>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        );
                      })}
                    </>
                  )}
                </div>
              </Col>
              <Col style={{ padding: '0px' }} xs="8" sm="8" md="8">
                <ChoropethMap
                  key={'ChoropethMapV4'}
                  center={[position.latitude, position.longitude]}
                  zoom={zoom}
                  data={dataGeoJson}
                  rangos={[]}
                  tipo={tipoMapa.value}
                  label_tipo={tipoMapa.label}
                  handleTipoMapa={handleTipoMapa}
                  handleDetalleMapa={handleDetalleMapa}
                  handleSearhSeccion={handleSearhSeccion}
                  handleSeccionFromCoords={handleSeccionFromCoords}
                  handleManzanaFromCoords={handleManzanaFromCoords}
                  clickDownloadFile={clickDownloadFile}
                  resultSeccion={resultSeccion}
                  menuExportar={menu}
                  MenuPermisos={menu}
                  handleLatLng={handleLatLng}
                  Responsabilidad={getVars('Token').user.Responsabilidad.value}
                  _flagFinish={flagFinish}
                  handleFiltro={handleFiltro}
                  handleFlagSpinner = {flagLoadingSpinner}
                  HandleChangeseFlagLoadingSpinner= {HandleChangeseFlagLoadingSpinner}
                ></ChoropethMap>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
