import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer"
import Sidebar from "../components/Sidebar";

import { getVars } from '../GlobalStorage';
const NotFound = (props) => {

 const token = getVars('Token');


 const handleMiniClick = () => {
      
      document.body.classList.toggle("sidebar-mini");
  };

  return(<div className="wrapper">
        <Sidebar
          {...props}
          menus={token.menus}
          bgColor="black"
          activeColor="info"
        />
          <div className="main-panel">
            <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
              <div className="content">
                <h3>Elemento no encontrado</h3>
              </div>
            <Footer fluid />
          </div>

        </div>);
};

export default NotFound;
