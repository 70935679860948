import React, { useState, useEffect } from "react";
import { Form } from "reactstrap";
import { notify } from "react-notify-toast";
import EjecutarApi from "../../data/getPOST";
import Menus from "../Menus";

const UpdMenus = ({ token, idUser, data, isMovil, flagLoading }) => {
  const privileges = [];
  const [loading, setLoading] = useState(flagLoading);

  useEffect(() => {
    setLoading(flagLoading);
  }, [flagLoading]);

  const sendForm = () => {
    setLoading(true);
    EjecutarApi(
      { id: idUser, menu: privileges, isMovil: isMovil },
      "updMenu",
      token
    )
      .then(function (res) {
        return res;
      })
      .then(response => {
        if (response.success) {
          notify.show(response.message, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF"
          });
          setLoading(false);
        }
      }).catch(e => {
        setLoading(false);
      });
  };
  const mystyle = {
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative"
  };

  return (
    <>
      <Form
        className="form"
        onSubmit={e => {
          sendForm();
          e.preventDefault();
        }}
        style={mystyle}
      >
        <Menus data={data} isMovil={isMovil} privileges={privileges} flagLoading={loading}></Menus>
      </Form>
    </>
  );
};
export default UpdMenus;
