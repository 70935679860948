import React, { useState, useEffect } from "react";
import { Map, TileLayer, GeoJSON , ZoomControl} from 'react-leaflet';
import L from 'leaflet';
import { MagicSpinner } from "react-spinners-kit";
import {centerOnOneShape} from "../../ComponentesAuxiliares/AuxiliarFunctions";
import {
    Button,
    ButtonGroup
} from "reactstrap";
let style_b = {
    'color': '#000000',
}
let style_square_informacion = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
    //'width':'180px'
}
let style_square_radio_buttons = {
    'padding': '2px 4px',
    'font': '10px/12px ',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
    'position': 'relative',
    'top': '0px',
    'left': '35px'
}

let style_button_radiogroup = {
    'padding': '2px 4px',
    'font': '10px/12px ',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
}

let style_spinner = {
    'height': '500pt',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center'
}
let style_square_inferior_rangos = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    /* 'background': 'white', */
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
    'textAlign': 'left',
    'lineHeight': '18px',
    'color': '#555',
    //'width':'185px'
}

function ChoropethMap({ center, zoom, data, rangos, tipo, label_tipo, handleTipoMapa, handleDetalleMapa, derecho_mapa,hadleDataTabla }) {
    
    const [_center, setCenter] = useState(center ? center : [20.678416, -101.354231]);
    const [_zoom, setZoom] = useState(zoom ? zoom : 12);
    const _data = data ? data : null;
    const _rangos = rangos ? rangos : [];
    const flag = Object.keys(_data).length === 0 ? false : true;
    const _tipo = tipo ? tipo : 'municipio';
    const _derecho_mapa = derecho_mapa;
    const [tipoMapa, setTipoMapa] = useState({ value: tipo, label: label_tipo });
    const [countRango, setCountRango] = useState([]);
    const [flagData, setFlagData] = useState(true);
    const [buttonDetalle, setButonDetalle] = useState(false);
    

    const [prop, setProp] = useState(
        tipo === 'municipio' ?
            { 'nom_mun': '', 'cve_mun': '' }
            : tipo === 'distrito' ?
                { 'distrito': '' } : tipo === 'seccion' ? { 'seccion': '' } : { 'poligono': '' }
    );
    function roundTo(value, places) {
        var power = Math.pow(10, places);
        return Math.round(value * power) / power;
    }

    function style(feature, flag) {
        let style;
        if (flag === undefined || flag !== true) {
            style = {
                fillColor: feature.properties.color,
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.3
            };
        }
        else {
            style = {
                weight: 5,
                color: '#666',
                dashArray: '',
                fillOpacity: 0.5
            };

        }
        return style;
    }

    function style_rangos(color, flag) {
        let style;
        if (flag && color !== null && color !== undefined) {
            style = {
                'width': '18px',
                'height': '18px',
                'float': 'left',
                'marginRight': '8px',
                'opacity': '0.7',
                'background': color
            };
        }
        else {
            style = {
                'width': '18px',
                'height': '18px',
                'float': 'left',
                'marginRight': '8px',
                'opacity': '0.7',
                'background': '#FFEDA0'
            };

        }
        return style;
    }


    const highlightFeature = (e) => {
        //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.
        var layer = e.target;
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
        if (_tipo === 'municipio')
            setProp({
                'nom_mun': e.target.feature.properties.nom_mun,
                'cve_mun': e.target.feature.properties.cve_mun,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'distrito': e.target.feature.properties.distrito,
                'lnom': e.target.feature.properties.lnom,
                'prop_save':e
            });
        else if (_tipo === 'distrito')
            setProp({
                'clave': e.target.feature.properties.clave,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'distrito': e.target.feature.properties.distrito,
                'prop_save':e
            });
        else if (_tipo === 'seccion')
            setProp({
                'seccion': e.target.feature.properties.seccion,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'distrito': e.target.feature.properties.distrito,
                'prop_save':e
            });
        else if (_tipo === 'poligono')
            setProp({
                'pg_poligon': e.target.feature.properties.pg_poligon,
                'poligono': e.target.feature.properties.poligono,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'distrito': e.target.feature.properties.distrito,
                'prop_save':e
            });

        layer.setStyle(style(layer.feature, true));
    }

    const clickDetalle = (e) => {
        setButonDetalle(false);

        var siguiente_nivel = "distrito";
        var siguiente_nivel_label = "Distrital";
        var siguiente_nivel_clave = "";
        var distrito_clave = "";

        if (tipoMapa.value === "distrito") {
            siguiente_nivel = "municipio";
            siguiente_nivel_label = "Municipal";
            siguiente_nivel_clave = e.target.feature.properties.clave;
            distrito_clave = e.target.feature.properties.distrito;
        }
        if (tipoMapa.value === "municipio") {
            siguiente_nivel = "poligono";
            siguiente_nivel_label = "Poligonal";
            siguiente_nivel_clave = e.target.feature.properties.cve_mun;
            distrito_clave = e.target.feature.properties.distrito;
        }
        if (tipoMapa.value === "poligono") {
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Seccional";
            siguiente_nivel_clave = e.target.feature.properties.poligono;
            distrito_clave = e.target.feature.properties.distrito;
        }
        if (tipoMapa.value === "seccion") {
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Seccional";
            distrito_clave = e.target.feature.properties.distrito;
            //siguiente_nivel = "manzana";
            //siguiente_nivel_label = "Manzanas";
            //siguiente_nivel_clave = e.target.feature.properties.seccion; 
            return; //CLICK NO PROGRAMADO
        }
        handleDetalleMapa({ value: siguiente_nivel, label: siguiente_nivel_label, clave: siguiente_nivel_clave, distrito: distrito_clave });
        setTipoMapa({ value: siguiente_nivel, label: siguiente_nivel_label });
    }

    const clickFeature = (e) => {
        setButonDetalle(true);
        highlightFeature(e);
        //Aqui configuramos lo que queremos que haga un feature cuando le den click.
        //alert(e.target.feature.properties.nom_mun);
    }



    const onEachFeature = (feature, layer) => {
        //Organizamos los eventos del GeoJson.
        layer.on({
            //mouseover: highlightFeature,
            //mouseout: resetHighlight,
            click: clickFeature
        });
    }

    var geojson = (
        <GeoJSON onEachFeature={onEachFeature} style={style} key={"geojson"} data={_data}></GeoJSON>
    )
    useEffect(() => {
        setTipoMapa({ value: tipo, label: label_tipo });
        var array_counter = [];
        var color_rangos = [];
        if (_rangos.length !== 0 && _rangos.length !== undefined){
            color_rangos =  _rangos.map((item) => {
                return item.Color
            });
            array_counter = color_rangos.map((item) => {
                return 0
            });
        }
        if (Object.keys(_data).length !== 0) {
            _data.features.forEach(function (f) {
                
                color_rangos.forEach(function (item,i) {
                    if (item === f.properties.color) {
                        array_counter[i] = array_counter[i] +1;
                    }
                });
            });
            setCountRango(array_counter);
            if(_data.features.length !== 0){
                const {zoom, center} = centerOnOneShape(flagData,_data);
                setCenter(center);
                setZoom(zoom-1);
            }
        }

    }, [data,tipo,label_tipo]);

    const miTipoMapaChange = (e) => {
        setTipoMapa(e);
        handleTipoMapa(e);
    }

    return (
        <>
            {
                flag ?
                    <Map zoom={_zoom} zoomControl={false} center={_center} style={{ width: '100%', height: '100vh' }}>

                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <ZoomControl  position="topleft"></ZoomControl>
                        <div className="leaflet-control-container">
                            <div className="leaflet-bottom leaflet-left">
                                <div className="leaflet-control" style={style_square_informacion}>
                                    {
                                        (_tipo === 'municipio') ?
                                            <>
                                                <h6>INFORMACIÓN:</h6>
                                                {(prop.nom_mun !== '' && prop.nom_mun !== undefined) ?
                                                    <>
                                                        <b style={style_b}>Distrito:</b><b style={{ color: prop.color }}> {prop.distrito}</b><br></br>
                                                        <b style={style_b}>Municipio:</b><b style={{ color: prop.color }}> {prop.nom_mun}</b><br></br>
                                                        <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                        <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                        <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                        <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                        <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta ===0 ? "--" :roundTo(prop.avance_porcentual, 2)+ "%"}</b><br></br>
                                                    </>
                                                    : <b> Pulse sobre un municipio</b>}
                                            </>
                                            :
                                            (_tipo === 'distrito') ?
                                                <>
                                                    <h6>INFORMACIÓN:</h6>
                                                    {(prop.distrito !== '' && prop.distrito !== undefined) ?
                                                        <>
                                                            <b style={style_b}>Distrito Local:</b><b style={{ color: prop.color }}> {prop.distrito}</b><br></br>
                                                            <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                            <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                            <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                            <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                            <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta ===0 ? "--" :roundTo(prop.avance_porcentual, 2)+ "%"}</b><br></br>
                                                            
                                                        </>
                                                        : <b> Pulse sobre un distrito</b>}
                                                </>
                                                :
                                                (_tipo === 'seccion') ?
                                                    <>
                                                        <h6>INFORMACIÓN:</h6>
                                                        {(prop.seccion !== '' && prop.seccion !== undefined) ?
                                                            <>
                                                                <b style={style_b}>Distrito:</b><b style={{ color: prop.color }}> {prop.distrito}</b><br></br>
                                                                <b style={style_b}>Sección:</b><b style={{ color: prop.color }}> {prop.seccion}</b><br></br>
                                                                <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                                <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                                <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                                <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta ===0 ? "--" :roundTo(prop.avance_porcentual, 2)+ "%"}</b><br></br>
                                                                
                                                            </>
                                                            : <b> Pulse sobre una seccion</b>}
                                                    </>
                                                    :
                                                    <>
                                                        <h6>INFORMACIÓN:</h6>
                                                        {(prop.poligono !== '' && prop.poligono !== undefined) ?
                                                            <>
                                                                <b style={style_b}>Distrito:</b><b style={{ color: prop.color }}> {prop.distrito}</b><br></br>
                                                                <b style={style_b}>Polígono:</b><b style={{ color: prop.color }}> {prop.pg_poligon}</b><br></br>
                                                                <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                                <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                                <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                                <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta ===0 ? "--" :roundTo(prop.avance_porcentual, 2)+ "%"}</b><br></br>
                                                                
                                                            </>
                                                            : <b> Pulse sobre un poligono</b>}
                                                    </>

                                    }
                                    
                                    {
                                    buttonDetalle && prop.seccion === undefined? 
                                    <ButtonGroup>
                                        <Button
                                        style={style_button_radiogroup}
                                        size ="sm"
                                        className="btn btn-sm btn-round"
                                        color={'success'}
                                        outline
                                        type="button" 
                                        onClick={(e)=>{clickDetalle(prop.prop_save)}}>Ver Detalle</Button>
                                    </ButtonGroup>:<></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-left">
                                <div className="leaflet-control" style={style_square_radio_buttons}>

                                    <ButtonGroup className="btn-group btn-group-sm flex-wrap" size="sm" style={{backgroundColor:"FFF"}} >
                                        {_derecho_mapa.includes(4) ?
                                            <Button
                                                style={style_button_radiogroup}
                                                size ="sm"
                                                className="btn-round btn btn-sm"
                                                color={tipoMapa.value === 'distrito' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "distrito", label: "Distrital" })}
                                            >
                                                Distrito
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(3) ?
                                            <Button
                                                style={style_button_radiogroup}
                                                size ="sm"
                                                className="btn-round btn btn-sm"
                                                color={tipoMapa.value === 'municipio' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "municipio", label: "Municipal" })}
                                            >
                                                Municipio
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(2) ?
                                            <Button
                                                style={style_button_radiogroup}
                                                size ="sm"
                                                className="btn-round btn btn-sm"
                                                color={tipoMapa.value === 'poligono' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "poligono", label: "Poligonal" })}
                                            >
                                                Polígono
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(1) ?
                                            <Button
                                                style={style_button_radiogroup}
                                                size ="sm"
                                                className="btn-round btn btn-sm"
                                                color={tipoMapa.value === 'seccion' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "seccion", label: "Seccional" })}
                                            >
                                                Sección
                                            </Button>
                                            :
                                            <></>}
                                    </ButtonGroup>

                                </div>
                            </div>
                        </div>

                        <div className="leaflet-control-container">
                            <div className="leaflet-bottom leaflet-right">
                                <div className="legend leaflet-control" style={style_square_inferior_rangos}>
                                    <h6>Avance Compromisos:</h6>
                                    {(_rangos !== null && _rangos.length !== 0 && _rangos.length !== undefined) ?
                                        <>
                                            {
                                                _rangos.map((rango, i) => {
                                                    return <div key={(rango.id).toString()}><i style={style_rangos(rango.Color, true)}></i><b>{`${rango.Label} - Cantidad ${countRango[i]}`}</b><br></br></div>
                                                })
                                            }
                                        </>
                                        : <b> Cargando...</b>}
                                </div>
                            </div>
                        </div>
                        {geojson}
                    </Map>
                    :

                    <div style={style_spinner}>
                        <MagicSpinner size={70} color={"#008000"} loading={true}></MagicSpinner>
                    </div>
            }
        </>
    );
}

export default ChoropethMap;
