
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardHeader,  CardBody } from "reactstrap";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Opcion from "../../components/Charts/Inversion/inversion_Combinada";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import Notifications, { notify } from "react-notify-toast";
import { CircleSpinner } from "react-spinners-kit";
import Select from "react-select";
import { getVars } from "../../GlobalStorage";

const Graficas_TipoInversion = props => {
    const mainPanel = useRef();
    const token = getVars("Token").access_token;
    const [loading, setLoading] = useState(true);
    useEffect(() =>{
        console.log(Opcion);
    },[])

    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };
 
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Col sm="12" md="12" lg="12">
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    
                                    <Card>
                                        <CardHeader><h5 className="title">Tipos de Inversión</h5></CardHeader>
                                        <CardBody>
                                            
                                            
                                            {Opcion?<>
                                                <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={Opcion[0].options13}
                                                        type='column'
                                                    />
                                                </Col>
                                            </Row>
                                            
                                            <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={Opcion[0].options8}
                                                        type='column'
                                                    />
                                                </Col>
                                            </Row>
                                            <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={Opcion[0].options9}
                                                        type='column'
                                                    />
                                                </Col>
                                            </Row>
                                            <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={Opcion[0].options10}
                                                        type='column'
                                                    />
                                                </Col>
                                            </Row>
                                            <Row id="container">
                                            <Col sm="12" md="12" lg="12">
                                                <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={Opcion[0].options11}
                                                        type='column'
                                                    />
                                            </Col>
                                            </Row>
                                            <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={Opcion[0].options12}
                                                        type='column'
                                                    />
                                                </Col>
                                            </Row>
                                            </>:(<>
                                                <Row>
                                                <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                                /></Row>
                                            </>)}
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                        </Col>
                </div>
                <Footer fluid />
            </div>
        </div>
    )
}
export default Graficas_TipoInversion;