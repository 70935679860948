import React from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Spinner,
} from 'reactstrap';

const InfoResponsables = (props) => {
  const { responsable, isOpen, setIsOpen, isLoading } = props;
  
  const style = {
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      maxHeight: '10rem',
      left: '50%',
      padding: '2rem',
      position: 'fixed',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '20rem',
      width: '90%',
      maxWidth: '60rem',
    },
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={style}
        toggle={setIsOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={setIsOpen}>
          Informacion del <strong>{responsable.TipoPropietario}</strong> de la
          casilla{' '}
          <strong>
            {responsable.Casilla}</strong> con Seccion<strong>{responsable.Seccion}
          </strong>
        </ModalHeader>
        <ModalBody>
          {isLoading && <Spinner color="primary" />}
          {!isLoading && (
            <>
              <Row>
                <Col>
                  <strong>Nombre:</strong> {responsable.Nombre}
                </Col>
                <Col>
                  <strong>Sección donde vota: </strong> {
                  (responsable.SeccionVota != responsable.Seccion) ? 
                  (<strong style={{ color: "#FA9B06" }}>{responsable.SeccionVota}</strong>) :<strong style={{ color: "#FA9B06" }}>{responsable.SeccionVota}</strong> }
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>Domicilio: </strong>
                  {responsable.Municipio}
                  {', '}
                  {responsable.Colonia}
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>Fecha de creación: </strong> {responsable.FechaCreate}
                </Col>
                <Col>
                  <strong>Asignado por: </strong> {responsable.Responsable}
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={setIsOpen}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InfoResponsables;
