import React, { useState,useEffect } from 'react';
import {Row,Col, Button, CardHeader,Card} from 'reactstrap';
import { notify } from "react-notify-toast";
import ReactTable from 'react-table';
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";
import Pagination from '../Pagination/pagination';
//import FiltroDetalle from 'components/Responsable/FiltroCompromisosDestalle';
import {getDataMenu} from '../../data/menus.js';
//import { URL_GENERAL } from "../../data/Consts";
import { URL_GENERAL } from "../../data/Consts";
import FiltroDetalleCompromisos from 'components/Estructura/FiltroCompromisosDestalle';

const ListadoCompromisos = ({props,onHide}) => {
    const [loading, setLoading] = useState(false);
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    const [compromisos, setCompromisos] = useState([]);
    const [sorted, setSorted] = useState([]);
    const [totalCompromisos, setTotalCompromisos] = useState(0);
    var titulo = props.value.toUpperCase()+" "+props.Label;
    
    const [nombre,setNombre]=useState("");
    var  filtro=[];
    switch (props.zona) {
      case "region":
        filtro.push({id:"idRegion", value:props.id})
        break;
      case "municipio":
        filtro.push({id:"idMunicipioReportes", value:props.id})
        break;
      case "poligono":
        filtro.push({id:"idPoligono", value:props.id})
        break;
      case "seccion":
        filtro.push({id:"Seccion", value:props.id})
        break;
    }

    useEffect(()=>{
        _setmenu(getDataMenu(100,token.menus));
    },[])

    const Exportar = (idEstructura) => {

      const data = {
        page: 0,
        pageSize: 10,
        sorted: sorted,
        tipo:'and',
        filtered: filtro,
        Nombre:nombre,
        Export: true
      };

    
      var datos = JSON.stringify(data)
      var url = new URL(
          `${URL_GENERAL}exportCompromisosZona?token=${token.access_token}&serializar=${datos}`
      );
      window.open(url, "_blank");

    };



    const setFilter =(filtered)=>{
      
        var sorted=[];
        if(filtered.length !==0){
        
         var nombre=filtered[0]['id']==="Nombre"?(filtered[0]['value']):"";
  
          if(filtered[1]['id']==="compromisos.Celular" && filtered[1]['value'] !==""){
            filtro.push(filtered[1])
          }
  
          setNombre(nombre)
        }
        
    
          const data = {
            page: 0,
            pageSize: 10,
            sorted: sorted,
            tipo:'and',
            filtered: filtro,
            Nombre:nombre,
          };	
  
          EjecutarApi(
            data,
            'getCompromisosZona',
            token.access_token
          )
            .then(function (res) {
              return res;
            })
            .then((res) => {
              if (res.results) {
                if (res.data) {
                  setLoading(false);
                  setTotalCompromisos(res.total)
                  setCompromisos(
                    res.data.map((item) => {
                      return {
                        Nombre:item.NombreCompleto,
                        Celular:item.Celular?item.Celular:'Sin Celular',
                        Seccion:item.SeccionVota?item.SeccionVota:'Sin Sección'
                      };
                    })
                  );
                }
              }
            })
            .catch((e) => {
            
              setLoading(false);
              notify.show(
                `Error: ${e.error}`,
                'custom',
                5000,
                {
                  background: '#0857B6',
                  fontSize: 14,
                  text: '#FFFFFF',
                }
              );
            });
      
        } 
    
    const Limpiar=(filtered)=>{
        setFilter(filtered);
      } 

    return(
        <> 
        <Card>
            <Col md="12">
            <CardHeader>
                <Row>
                    <Col  md="12" >
                        <Button size="sm" color='primary' onClick={()=> onHide()}>Regresar</Button>
                        {_menu.Exportar===1&&(<Button size="sm" color='success' onClick={()=> Exportar()}>Exportar</Button>)}
                    </Col>
                    
                </Row>
                <Row>
                    <Col  md='12' className="text-center" >
                         <h4>Listado de Compromisos:<br/>
                            {titulo}</h4> 
                    </Col>
                </Row>
                <Row>
                  <Col>
                    <FiltroDetalleCompromisos
                         filtrar={setFilter} limpiar={Limpiar} />
                  </Col>
                </Row>
            </CardHeader>
                <Row>
                    <Col sm="12" >
                    <div style={{ width: '100%', overflow: 'auto' }}>
                                  <ReactTable
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                    }}
                                    name="compromisos"
                                    data={compromisos}
                                    noDataText="Sin información"
                                    manual
                                    columns={[
                                      {
                                        Header: 'Sección',
                                        minWidth: 80,
                                        id: 'seccion',
                                        accessor: (d) => d.Seccion,
                                        filterAll: false,
                                      },
                                      {
                                        Header: 'Nombre',
                                        minWidth: 110,
                                        id: 'Nombre',
                                        accessor: (d) => d.Nombre,
                                        filterAll: false,
                                      },
                                      
                                      {
                                        Header: 'Celular',
                                        minWidth: 50,
                                        id: 'Celular',
                                        show:props.responsibilityID === 99?true:false ,
                                        accessor: (d) => d.Celular,
                                        filterAll: false,
                                      }
                                    ]}
                                    className="-striped -highlight primary-pagination"
                                    loading={loading}
                                    showPagination={true}
                                    showPaginationBottom={true}
                                    showTotalPages={true}
                                    showPageJump={false}
                                    canNextFromData={true}
                                    defaultPageSize={10}
                                    total={totalCompromisos}
                                    pages={Math.ceil(totalCompromisos / 10)}
                                    previousText="Anterior"
                                    nextText="Siguiente"
                                    ofText="de"
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    PaginationComponent={Pagination}
                                    onFetchData={(stateComp) => {
                                      setLoading(true);
                                      var parametro={
                                        filtered:filtro?filtro:[],
                                        Nombre:nombre?nombre:"",
                                        page:stateComp.page,
                                        pageSize:stateComp.pageSize}
                                      EjecutarApi(
                                        parametro,
                                        'getCompromisosZona',
                                        token.access_token
                                      )
                                        .then(function (res) {
                                          return res;
                                        })
                                        .then((res) => {
                                          if (res.results) {
                                            if (res.data) {
                                              setLoading(false);
                                              setTotalCompromisos(res.total)
                                              setCompromisos(
                                                res.data.map((item) => {
                                                  return {
                                                    Nombre:item.NombreCompleto,
                                                    Celular:item.Celular?item.Celular:'Sin Celular',
                                                    Seccion:item.SeccionVota?item.SeccionVota:'Sin Sección'
                                                  };
                                                })
                                              );
                                            }
                                          }
                                        })
                                        .catch((e) => {
                                         
                                          setLoading(false);
                                          notify.show(
                                            `Error: ${e.error}`,
                                            'custom',
                                            5000,
                                            {
                                              background: '#0857B6',
                                              fontSize: 14,
                                              text: '#FFFFFF',
                                            }
                                          );
                                        });
                                    }}
                                  />
                                </div>
                    </Col>
                </Row>
            </Col>
        </Card>
        </>  
    );
};
export default ListadoCompromisos;