import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
const NumeraliaMetas = ({ datos, Titulos }) => {
    const data = {
        chart: {
            type: 'column'
        },
        title: {
            text: Titulos.title
        },
        subtitle: {
            text: Titulos.subtitle
        },
        xAxis: Titulos.xAxis,
        yAxis: Titulos.yAxis,
        legend: {
            shadow: false
        },
        tooltip: {
            shared: true
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: { fontSize: "8px" }
                }
            }
        },
        series: datos
    };
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={data}
            type='column'
        />
    )
}
export default NumeraliaMetas;