import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Spinner
} from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import { CircleSpinner } from 'react-spinners-kit';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Pagination from 'components/Pagination/pagination';
import EjecutarApi from 'data/getPOST';
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from 'GlobalStorage';
import useWindowDimensions from 'components/useWindowDimensions';
import Select from 'react-select';
import moment from 'moment';

const CapturaFolioBingo = (props) => {

  const [_menu, _setmenu] = useState({});
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const token = getVars('Token');
  const [municipio, setMunicipio] = useState('');
  const [dataBingos, setDataBingos] = useState([]);
  const [totalBingos, setTotalBingos] = useState([]);
  const [catCasilla, setcatCasilla] = useState([{ value: 0, label: 'Debe validar la sección' }]);
  const [Bingo, setBingos] = useState({
    Seccion: null,
    Bingo: null,
    Casilla: {value: null, label:''},
    Hoja: null,
  })

  const [catHoja] = useState([
    { value: 0, label: 'Seleccionar...' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10',label: '10' },
  ])
  const styleHeader = {
    textAlign: "center",
    overflow: 'visible',
    fontSize: '12px',
    color: '#363636',
    lineHeight: 'normal !important',
  };


  useEffect (() =>{
    let _menuStorage = getDataMenu(183, token.menus);
    if(!_menuStorage.Ver)
    {
      _menuStorage = getDataMenu(184, token.menus);
    }
    _setmenu(_menuStorage)
  },[])

  const onblurSeccion = (Seccion) => {
    setLoading(true);
    EjecutarApi({ "seccion": Seccion }, 'validarSeccionSije', token.access_token)
      .then(function (res) {
          return res;
      })
      .then((res) => {
        let color = 'success';
        if (res.results) {
            setLoading(false);
            setMunicipio(res.data.map((item) => {
                return item.MunicipioReporte;
            }))
        }
        else {
            color = "error";
        }
        notify.show(res.message, color, 5000);
        setLoading(false);
      })
      .catch((e) => {
          setLoading(false);
      });
    EjecutarApi({ "seccion": Seccion }, 'getCasillasBySeccion', token.access_token)
      .then(function (res) {
          return res;
      })
      .then((res) => {
          if (res.success) {
              setcatCasilla(res.data);
          }else
          {
            notify.show(`No hay resultados de casillas`, 'error', 5000, {
              background: 'red',
              fontSize: 14,
              text: '#FFFFFF',
            });
          }
      })
      .catch((e) => {
          setLoading(false);
      }).finally(x => {
        setDataBingos([]);
      })
  }

  const conChangeCasilla = (e) => {
    setBingos( {...Bingo, Casilla: e  })
    const data = {
      page: 0,
      pageSize: 10,
      filtered:[
        {id:'b.Seccion', value:Bingo.Seccion},
        {id:'b.NombreCasilla', value:e.label}
      ]
    };
    getRegistros(data);
  }

  const btnSaveBingo = (e) => {
    e.preventDefault();

    if (Bingo.Seccion === null) {
      notify.show("Hace Falta Campo Sección", "warning", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Bingo.Casilla.value === null) {
        notify.show("Hace Falta Campo Casilla", "warning", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      return true;
    }
    if (Bingo.Hoja === null) {
      notify.show("Hace Falta campo Hoja", "warning", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;

    }
    if (Bingo.Bingo === null) {
        notify.show("Hace Falta el Campo Bingo", "warning", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      return true;
    }
    const datos = {
      Seccion: Bingo.Seccion,
      Bingo: Bingo.Bingo,
      Casilla: Bingo.Casilla ? Bingo.Casilla.label : '',
      Hoja: Bingo.Hoja.value,
    };
    DatosBingo(datos);
  }

  const DatosBingo = (datos) => {
    setLoading(true);
    let newitems= dataBingos.filter((prop) => prop.Bingo !== datos.Bingo)
    EjecutarApi(datos, "movilizacion/setMovilizadoWeb", token.access_token)
    .then(function (res) {
      return res;
    })
    .then((res) => {
      if (res.success) {
        if (res.results) {
          notify.show(res.message, "success", 5000, {
            background: "#18c97d",
            fontSize: 24,
            text: "#FFFFFF",
          });
          const data = {
            page: 0,
            pageSize: 10,
            filtered:[
              {id:'b.Seccion', value:Bingo.Seccion},
              {id:'b.NombreCasilla', value:Bingo.Casilla.label}
            ]
          };
          getRegistros(data);
          
          // setDataBingos(
          //   newitems.concat({...datos,
          //   Usuario: token.user.Nombre,
          //   Hora: moment(new Date()).format("YYYY-MM-DD HH:mm")
          // }));
        
          /* setDataBingos(dataBingos.concat({...datos,
            Nombre: token.user.Nombre,
            Hora: moment(new Date()).format("YYYY-MM-DD HH:mm")
          })); */
        } else {
          notify.show(
            `Error al agregar registro: ${res.message}!!`,
            "custom",
            5000,
            {
              background: "#0857B6",
              fontSize: 24,
              text: "#FFFFFF",
            }
          );
        }
      }
    })
    .catch((e) => {
      notify.show(`Error al agregar el registro: ${e.error}`, "custom", 5000, {
        background: "#0857B6",
        fontSize: 24,
        text: "#FFFFFF",
      });
    })
    .finally(e => {
      setLoading(false);
      clearForm();
    })
  };

  const fetchData = useCallback(({ pageSize, page, sorted,params }) => {
    const data = {
      page: page,
      pageSize: pageSize
    };
    if(params.Seccion !== null && params.Casilla.value !== null){
      data.filtered = [
        {id:'b.Seccion', value:params.Seccion},
        {id:'b.NombreCasilla', value:params.Casilla.label}
      ]
    }
    getRegistros(data);
  }, []);

  const getRegistros = (data) => {
    setLoading(true);
    EjecutarApi(data, 'movilizacion/getMovilizadoWeb', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setTotalBingos(res.total);
        setDataBingos(
          res.data.map((prop, key) => {
            return {
              Bingo: prop.Bingo,
              Hoja: prop.Hoja,
              Usuario: prop.Usuario,
              Hora: prop.Hora,
              Casilla:prop.NombreCasilla,
              Seccion:prop.Seccion
            }
          })
        )
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener setDataBingos: ${e.error}`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };
  const header = (text) => {
    return <h6 style={styleHeader}>{text}</h6>;
  };

  const columns = [
    {
      Header: header('Sección'),
      minWidth: 10,
      id: 'Seccion',
      accessor: (d) => d.Seccion,
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Casilla'),
      minWidth: 10,
      id: 'NombreCasilla',
      accessor: (d) => d.Casilla,
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Hora'),
      minWidth: 10,
      id: 'Hora',
      accessor: (d) => d.Hora,
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Hoja'),
      minWidth: 10,
      id: 'Hoja',
      accessor: (d) => d.Hoja,
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Bingo'),
      minWidth: 10,
      id: 'Bingo',
      accessor: (d) => d.Bingo,
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Usuario'),
      minWidth: 20,
      filterAll: false,
      id: 'Usuario',
      accessor: (d) => d.Usuario,
      headerStyle: { fontSize: '2px' }
    }
  ];

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const clearForm = () => {
    setBingos({...Bingo,
        Bingo: ''
    });
  }
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
        {_menu.Ver === 1 ? (
        <>
          <Col sm="12">
            <Card style={{ width: 1500, height: 900}}>
              <br></br>
              <CardBody>
                <Row>
                  <Col lg="3" md="3" sm="12">
                    <Card style={{ height: 400}}>
                      <CardBody>
                      <center><strong className="title text-center" style={{ color: "#0041A0"}}>Captura Folio Bingo</strong></center>
                      <hr/>
                      {_menu.Agregar ?
                      <>
                        <form id="myForm" onSubmit={btnSaveBingo}>
                        <FormGroup>
                        <FormGroup>
                          <Label for="seccion"><span className="text-danger">*</span>Hoja:</Label>
                            <Select
                              options={catHoja}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={Bingo.Hoja}
                              onChange={(e) => setBingos({ ...Bingo, Hoja: e })}
                              placeholder="Selecionar..."
                            />
                        </FormGroup>
                        <FormGroup >
                          <Label for="seccion"><span className="text-danger">*</span>Sección:</Label>
                          <Input id="seccion"
                              maxLength='4'
                              type="text"
                              value={Bingo.Seccion}
                              onChange={(e) => { setBingos({ ...Bingo, Bingo: '',
                                Casilla: {value: null, label:''},  Seccion: e.target.value.replace(/\D/, "") }) }}
                              onBlur={(e) => onblurSeccion(e.target.value.replace(/\D/, ""))}/>
                        </FormGroup>
                        <FormGroup>
                          <Label for="Casilla"><span className="text-danger">*</span>Casilla:</Label>
                            <Select className="react-select primary"
                              classNamePrefix="react-select"
                              id="Casilla"
                              placeholder="Debe validar la sección"
                              options={catCasilla}
                              onChange={(e) => conChangeCasilla(e)}
                              isLoading={loading}
                              value={Bingo.Casilla}
                              loadingMessage={"...Filtrando..."}
                          />
                        </FormGroup>
                        <FormGroup >
                          <Label for="NombreCompleto"><span className="text-danger">*</span>Bingo:</Label>
                          <InputGroup>
                            <Col md="8">
                              <Input  id="NombreCompleto" maxLength='4'type="number" value={Bingo.Bingo}
                                onChange={(e) => { setBingos({ ...Bingo, Bingo: e.target.value }) }}
                              ></Input>
                            </Col>
                            <InputGroupAddon addonType="append">
                              <FormGroup>
                                <Button
                                  style={{ margin: '0px 1px', zIndex: 0 }}
                                  color="primary"
                                  type="submit"
                                  >
                                  <i className="fa fa-paper-plane-o fa-sm" />
                                </Button>
                              </FormGroup>
                            </InputGroupAddon>
                          </InputGroup>
                          {loading && <Spinner />}
                          <div className="errorFields">
                          </div>
                        </FormGroup>
                      </FormGroup>
                      </form>
                      </> :<></>
                      }
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="9" md="9" sm="12">
                    <Card style={{ height: height - 400 }}>
                      <CardBody height={300}>
                        <div style={{ width: '100%' }}>
                          <ReactTable
                            className="text-aling: center"
                            style={{
                              width: '100%',
                              height: '100%',
                              textAlign: "center"
                            }}
                            data={dataBingos}
                            noDataText="Sin información"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={loading}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={totalBingos}
                            pages={Math.floor(totalBingos / 10)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={fetchData}
                            params={Bingo}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </>
        ) : (
            <>
              <Label>No tiene permisos para ver</Label>
            </>
        )}
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};

export default CapturaFolioBingo;