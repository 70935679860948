import React, { useState, useEffect } from 'react';
import {
    InputGroup, InputGroupAddon,
    InputGroupText, Row, Col, Input,
    Badge, CardBody, Card, CardTitle,
    CardHeader, Table
} from 'reactstrap';
import { notify } from "react-notify-toast";
import { RotateSpinner } from "react-spinners-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import EjecutarApi from "../../data/getPOST";
import { getVars } from "../../GlobalStorage";
import { setSourceMapRange } from 'typescript';

const TreeCoordinadores = (props) => {
    const { estructura } = props;
    const [loading, setLoading] = useState(true);
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    const [HasMore, setHasMore] = useState(true);
    const [nextFetch, setnextFetch] = useState(1);
    const [idUser, setIdUser] = useState();
    const [responsable, setResponsable] = useState();
    const [DatosResponsable, setDatosResponsable] = useState([]);
    const [DetalleResponsable, setDetalleResponsable] = useState({});
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setLoading(true);
        setDatosResponsable([]);
        setTotal(0);
        var parametro = {
            idEstructura: [estructura],
            page: nextFetch,
            pageSize: 10,
            NombreCompleto: responsable
        };
        EjecutarApi(parametro, 'getUsersSocialStructuresWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(res.data.Coordinadores);
                    setnextFetch(1);
                    setTotal(res.data.TotalCoordinadores);
                    if (res.data.Coordinadores.length === 0) {
                        setHasMore(false);
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de coordinadores: ${e.error}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }, [estructura])

    var DetalleUsuario = (item) => {
        setIdUser(item);
        var parametro = {
            "idUser": item, "idEstructura": estructura,
        }
        //setResponsable({ Nombre: item.Nombre, Responsabilidad: item.ResponsabilidadData })
        EjecutarApi(parametro, 'getStatsDetalleCoordinador', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setDetalleResponsable(res.data);
                    setLoading(false);
                } else {
                    notify.show(`No existe información para mostrar!!!!`, "error", 5000, {
                        background: "#d61818",
                        text: "#FFFFFF",
                    });
                    setLoading(false)
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en el listado: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }


    const handleBuscar = () => {
        setLoading(true);
        setDatosResponsable([]);
        setTotal(0);
        var parametro = {
            idEstructura: [estructura],
            page: 1,
            pageSize: 10,
            NombreCompleto: responsable
        };
        EjecutarApi(parametro, 'getUsersSocialStructuresWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(res.data.Coordinadores);
                    setnextFetch(1);
                    setTotal(res.data.TotalCoordinadores);
                    if (res.data.Coordinadores.length === 0) {
                        setHasMore(false);
                    }

                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de coordinadores: ${e.error}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    };


    const fetchHasMore = () => {
        setLoading(true);
        if (nextFetch * 10 >= total) {
            setLoading(false);
            setHasMore(false);
            return;
        }

        var parametro = {
            idEstructura: [estructura],
            page: nextFetch,
            pageSize: 10,
            NombreCompleto: responsable,
        };
        EjecutarApi(parametro, 'getUsersSocialStructuresWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(DatosResponsable.concat(res.data.Coordinadores));
                    setnextFetch(nextFetch + 1);
                    setHasMore(false);
                    setTotal(res.data.TotalCoordinadores);
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de coordinadores: ${e.error}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    };

    return (
        <>
            <Col md="12">
                <Row>
                    <Col sm="12">
                        <InputGroup className="no-border">
                            <Input value={responsable} onChange={(e) => setResponsable(e.target.value)} placeholder="Buscar..." type="text" />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <i className="nc-icon nc-zoom-split" onClick={() => handleBuscar()} />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                <RotateSpinner color={"#1261ff"} size={30} loading={loading} />
                <Row>{(<>
                    <Col sm="12">
                        <InfiniteScroll
                            dataLength={DatosResponsable.length}
                            next={fetchHasMore}
                            hasMore={HasMore}
                            loader={DatosResponsable.length === 0 && !loading ? <h5>No hay datos</h5> : <h5>cargando...</h5>}
                            height={500}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>¡Final de la lista de registros!</b>
                                </p>}
                        >
                            {DatosResponsable.map((item, key) =>

                                <div key={'key' + key.toString()} className="divv text-muted" /* onClick={() => DetalleUsuario(item)} */ >
                                    <Card>
                                        <CardBody className="card-body">
                                            <Row>
                                                <Col sm="12" className='text-center'>
                                                    <strong>{`${item.Nombre} ${item.Paterno} ${item.Materno}`}</strong>
                                                    <p className="card-category">
                                                        {item.Responsabilidad}
                                                    </p>
                                                </Col>
                                                <hr />
                                            </Row>
                                            <Row>
                                                <Col className="text-left">
                                                    <Badge
                                                        onClick={() => DetalleUsuario(item.id)}
                                                        color="light"
                                                    >
                                                        {item.id !== idUser ? <i class="fa fa-caret-down fa-2x text-primary"></i> : <i class="fa fa-caret-up fa-2x text-primary"></i>}
                                                    </Badge>
                                                </Col>
                                            </Row>
                                            {item.id === idUser && !loading && DetalleResponsable.user &&
                                                <>
                                                    <Row>
                                                        <Col sm="12" className='text-center'>
                                                            <p className="card-category">
                                                                {DetalleResponsable.user ? DetalleResponsable.user.ResponsabilidadSecundaria : ''}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="12" className='text-center text-muted'>
                                                            <h6>Total Compromisos</h6>

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="6" className='text-center card-category'>{DetalleResponsable.totalCompromisosSegmentos ? DetalleResponsable.totalCompromisosSegmentos.total : ''}</Col>
                                                        <Col sm="6" className='text-center card-category'>{DetalleResponsable.totalCompromisos ? DetalleResponsable.totalCompromisos.total : ''}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="6" className='text-center card-category'><strong>Segmento</strong></Col>
                                                        <Col sm="6" className='text-center card-category' ><strong>General</strong></Col>
                                                    </Row>
                                                    <Row>
                                                        {DetalleResponsable.enlaces &&
                                                            <Col sm="12" className='text-center text-muted'>
                                                                <CardHeader>
                                                                    <CardTitle tag="h6">Enlaces Asignados</CardTitle>
                                                                </CardHeader>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <Table responsive>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="text-center">Celular</td>
                                                                                    <td className="text-center">Nombre</td>
                                                                                    <td className="text-center">Compromisos</td>
                                                                                    <td className="text-center">Segmento</td>
                                                                                </tr>
                                                                                {DetalleResponsable.enlaces.length > 0 ? (<>
                                                                                    {DetalleResponsable.enlaces.map((item) =>
                                                                                        <tr>
                                                                                            <td>{item.Celular}</td>
                                                                                            <td>{item.Nombre}</td>
                                                                                            <td className="text-center">{item.Compromisos}</td>
                                                                                            <td className="text-center">{item.Segmento}</td>
                                                                                        </tr>
                                                                                    )}
                                                                                </>) : (<></>)

                                                                                }
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>

                                                                </Row>

                                                            </Col>
                                                        }
                                                    </Row>
                                                </>
                                            }
                                        </CardBody>
                                    </Card>
                                    <hr />
                                </div>
                            )}
                        </InfiniteScroll>
                    </Col>
                </>)}
                </Row>
                <Row>
                    <Col sm="12">
                        Coordinadores : {DatosResponsable.length} de {total}
                    </Col>
                </Row>
            </Col>
        </>
    );
};
export default TreeCoordinadores;