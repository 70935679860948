
import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer"
import Sidebar from "components/Sidebar";
import { getVars } from 'GlobalStorage';
import ReactTable from 'react-table';
import { URL_GENERAL } from "data/Consts";
import {
  Button, Card, CardHeader, CardBody, Row, Col, TabContent,
  TabPane, NavItem,
  NavLink,
  Nav,
} from 'reactstrap';
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import Pagination from "components/Pagination/pagination";
import Filtro from "components/Responsable/Filtro";
import ListadoCompromisos from "components/Responsable/ListadoCompromisos";

const AvanceResponsabilidad = (props) => {

  // Configuracion
  const [setsidebarMini] = useState(true);
  const [backgroundColor] = useState("black");
  const [activeColor] = useState("info");
  const [loading, setLoading] = useState(false);
  const [loadingTabla, setLoadingTabla] = useState(false);
  const token = getVars('Token');
  const [horizontalTabs, setHorizontalTabs] = useState({
    horizontalTabs: "Regional",
    openedCollapses: ["collapseOne", "collapse1"]
  })

  const [filtro, setFiltro] = useState([]);
  const [region, setRegion] = useState([]);
  const [municipio, setMunicipio] = useState([]);
  const [poligono, setPoligono] = useState([]);
  const [seccion, setSeccion] = useState([]);
  const [manzana, setManzana] = useState([]);
  const [nombre, setNombre] = useState("");
  const [showCompromisos, setShowCompromisos] = useState(false);
  const [compromisos, setCompromisos] = useState(false);
  const [Responsable, setResponsable] = useState(0);

  const [total, setTotal] = useState(0);
  const [meta, setMeta] = useState([]);
  const [tabla, setTabla] = useState("region");

  const [horizontalAnter, setHorizontalAnter] = useState("Regional")
  // Catalogos

  const [col, setCol] = useState("12");
  const [flagTab, setFlagTab] = useState(1);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  //catalogos

  const [menu, _setmenu] = useState({});
  const responsibilityID = token.user.Responsabilidad.value;

  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(100, token.menus))

    if (responsibilityID != 99) {
      setTabla("municipio")
      setHorizontalTabs(
        {
          horizontalTabs: "Municipal",
          openedCollapses: ["collapseOne", "collapse1"]
        })
    }
    else if (responsibilityID === 11) {
      setTabla("poligono")
      setHorizontalTabs(
        {
          horizontalTabs: "Poligonal",
          openedCollapses: ["collapseOne", "collapse1"]
        })
    }
    else if (responsibilityID === 12) {
      setTabla("seccion")
      setHorizontalTabs(
        {
          horizontalTabs: "Seccional",
          openedCollapses: ["collapseOne", "collapse1"]
        })
    }
  }, []);

  useEffect(() => {

    if (horizontalTabs.horizontalTabs === "Regional") {
        setFlagTab(1)
    }
    if (horizontalTabs.horizontalTabs === "Municipal") {
        setFlagTab(2)
    }
    if (horizontalTabs.horizontalTabs === "Poligonal") {
        setFlagTab(3)
    }
    if (horizontalTabs.horizontalTabs === "Seccional") {
        setFlagTab(4)
    }
    if (horizontalTabs.horizontalTabs === "Manzanero") {
        setFlagTab(5)
    }
    
}, [horizontalTabs])

  const showMessage = (message, background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
    } else {
      setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA

  const setFilter = (filtered) => {
    setLoadingTabla(true)

        var sorted = [];
        var filtro = [];
        var filtroGrafica = [];
        var region = [];
        var municipio = [];
        var seccion = [];
        var poligono = [];
        var manzana = [];
        var nombre = "";
        var Responsable =0;

    if (filtered.length !== 0) {
      region = filtered[0]['id'] === "idRegion" ? (filtered[0]['value']) : [];
      municipio = filtered[1]['id'] === "idMunicipio" ? (filtered[1]['value']) : [];
      seccion = filtered[3]['id'] === "S.Seccion" ? (filtered[3]['value']) : [];
      poligono = filtered[2]['id'] === "idPoligono" ? (filtered[2]['value']) : [];
      manzana = filtered[8]['id'] === "idManzana" ? (filtered[8]['value']) : [];
      nombre = filtered[4]['id'] === "Nombre" ? (filtered[4]['value']) : "";
      Responsable = filtered[9]['id'] === "Responsable" ? (filtered[9]['value']) : 0;

      if (filtered[5]['id'] === "u.email" && filtered[5]['value'] !== "") {
          filtro.push(filtered[5])
      }
      if (filtered[6]['id'] === "S.Rentabilidad" && filtered[6]['value'] !== "") {
          filtro.push(filtered[6])
      }
      if (filtered[7]['id'] === "cm.PRIORIDAD" && filtered[7]['value'] !== "") {
          filtro.push(filtered[7])
      }


      setFiltro(filtro)

      setRegion(region)
      setMunicipio(municipio)
      setPoligono(poligono)
      setSeccion(seccion)
      setManzana(manzana)
      setNombre(nombre)
      setResponsable(Responsable)

      if (tabla === "Regional") {
          sorted.push({ "id": "idRegion", "desc": false })

      } else if (tabla === "Municipal") {
          sorted.push({ "id": "idRegion", "desc": false })
          sorted.push({ "id": "idMunicipio", "desc": false })

      }
      else if (tabla === "Poligonal") {
          sorted.push({ "id": "idRegion", "desc": false })
          sorted.push({ "id": "idPoligono", "desc": false })

      }
      else if (tabla === "Seccional") {

          sorted.push({ "id": "idRegion", "desc": false })
          sorted.push({ "id": "Seccion", "desc": false })

      }
      else if (tabla === "Manzanero") {
          sorted.push({ "id": "cm.Manzana", "desc": false })
      }
  }


    const data = {
      page: 0,
      pageSize: 10,
      sorted: sorted,
      tipo: 'and',
      filtered: filtro,
      Region: region,
      Municipio: municipio,
      Poligono: poligono,
      Seccion: seccion,
      Manzana: manzana,
      Nombre: nombre,
      Responsable: Responsable,
      tabla: tabla
    };

    EjecutarApi(data, "getResponsablesCompromisos", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setLoadingTabla(false);
            setTotal(res.total);
            setMeta(
              res.data.map((prop, key) => {
                var porcentaje = parseFloat(prop.porcentaje)
                var ddporcentaje = porcentaje.toFixed(2)
                return {
                  id: prop.id,
                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                  Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                  Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                  Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                  meta: prop.MetaEstructura,
                  Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                  cel: prop.ResponsableCel ? prop.ResponsableCel : "Sin Celular",
                  Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                  metaCompromisos: prop.MetaCompromisos,
                  Prioridad: prop.Prioridad,
                  Manzana: prop.manzana ? prop.manzana : 'Sin Manzana',
                  AvanceCompromisos: prop.Compromisos > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 0)} className="btn-link" color="primary">{prop.Compromisos}</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                  AvanceVoluntarios: prop.Voluntarios > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 1)} className="btn-link" color="primary">{prop.Voluntarios}</Button></>) : (prop.Voluntarios ? prop.Voluntarios : 0),
                  AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                  AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                  porcentaje: ddporcentaje ? ddporcentaje : 0,
                  data: prop,
                };
              })
            );
          }
        }
      })
      .catch((e) => {
        setLoadingTabla(false);
        notify.show(
          `Error al obtener getResponsablesCompromisos: ${e.message}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
    setLoadingTabla(true)
    setLoading(true)
  }

  const Limpiar = (filtered) => {
    setFiltro([]);
    setRegion("");
    setMunicipio("");
    setPoligono("");
    setSeccion("");
    setNombre("");
    setResponsable(0);
    setFlagTab(-1);
    changeTap(horizontalTabs.horizontalTabs)
  }

  const changeTap = (e) => {
    setLoadingTabla(true);
    setHorizontalAnter(horizontalTabs.horizontalTabs)
    setHorizontalTabs({ ...horizontalTabs, horizontalTabs: e })

  if ((horizontalTabs.horizontalTabs === "Manzanero" || horizontalTabs.horizontalTabs === "Municipal" || horizontalTabs.horizontalTabs === "Poligonal" || horizontalTabs.horizontalTabs === "Seccional") && (e=== "Regional")) {
      setMunicipio([]);
      setPoligono([]);
      setSeccion([]);
      setManzana([]);
      var filtrado = filtro.filter(fil => (fil.id !== 'cm.PRIORIDAD'))
      var filtrados = filtrado.filter(fil => ( fil.id !== 'S.Rentabilidad' ))
      setFiltro(filtrados)
  }
  else if ((horizontalTabs.horizontalTabs=== "Manzanero") && (horizontalTabs.horizontalTabs === "Poligonal") || (horizontalTabs.horizontalTabs === "Seccional") && (e === "Municipal")) {
      setPoligono([]);
      setSeccion([]);
      setManzana([]);
      var filtrado = filtro.filter(fil => (fil.id !== 'cm.PRIORIDAD'))
      var filtrados = filtrado.filter(fil => ( fil.id !== 'S.Rentabilidad' ))
      setFiltro(filtrados)
  }
  else if ((horizontalTabs.horizontalTabs === "Manzanero" || horizontalTabs.horizontalTabs=== "Seccional")  && (e === "Poligonal")) {
  
      setSeccion([]);
      setManzana([]);
      var filtrado = filtro.filter(fil => (fil.id !== 'cm.PRIORIDAD'))
      var filtrados = filtrado.filter(fil => ( fil.id !== 'S.Rentabilidad' ))
      setFiltro(filtrados)
  }
  else if ((horizontalTabs.horizontalTabs === "Manzanero") && (e === "Seccional")) {
      
      var filtrado = filtro.filter(fil => fil.id !== 'cm.PRIORIDAD')
      setFiltro(filtrado)
      setManzana([]);
  }
    const tbla = (e === "Regional") ? "region" : (e === "Municipal") ? "municipio" : (e === "Poligonal") ? "poligono" : (e === "Seccional") ? "seccion" : (e === "Manzanero") ? "manzanero" : ""
    setTabla(tbla)
    setCol("12")
    setLoadingTabla(false)
  }

  const onClickShowCompromisos = (props, isVoluntario) => {
    setShowCompromisos(true);

    setCompromisos({
      nombre: props.Responsable,
      UserOwned: props.idResponsable,
      isVoluntario: isVoluntario
    });
  }

  const Exportar = () => {

    const data = {
        page: 0,
        pageSize: 10,
        tipo: 'and',
        filtered: filtro,
        Region: region,
        Municipio: municipio,
        Poligono: poligono,
        Seccion: seccion,
        Manzana: manzana,
        Nombre: nombre,
        tabla: tabla,
        Export: true
    };

  
        var datos = JSON.stringify(data)
        var url = new URL(
            `${URL_GENERAL}ExportarResponsablesCompromisos?token=${token.access_token}&serializar=${datos}`
        );
        window.open(url, "_blank");
    
    
}

  const onHide = () => {
    setShowCompromisos(false)
  };
  //Estructura

  const showMunicipioEstructura = (responsibilityID !== 11 && responsibilityID !== 12 && responsibilityID !== 13)

  const showRegion = (horizontalTabs.horizontalTabs === "Regional" || horizontalTabs.horizontalTabs === "Municipal" || horizontalTabs.horizontalTabs === "Poligonal" || horizontalTabs.horizontalTabs === "Seccional") && (responsibilityID !== 53 && responsibilityID !== 54 && responsibilityID !== 55)

  const showPoligonosEstructura = (horizontalTabs.horizontalTabs === "Poligonal" || horizontalTabs.horizontalTabs === "Seccional")

  const showSeccionesEstructura = (horizontalTabs.horizontalTabs === "Seccional")

  const showNavRegional = (responsibilityID !== 10 && responsibilityID !== 11 && responsibilityID !== 12 && responsibilityID !== 13 && responsibilityID !== 53 && responsibilityID !== 54 && responsibilityID !== 55)
  const showNavMunicipal = (responsibilityID !== 12 && responsibilityID !== 13)
  const showNavPoligonal = (responsibilityID !== 12)

  const buttonFiltros = (responsibilityID === 10 || responsibilityID !== 12 || responsibilityID !== 13 || responsibilityID !== 55)


  return (
    <div className="wrapper">
      <Notifications />
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
        <div className="content">

          <div className="content">
            {/* Fechas Compromiso*/}
            {/* Configuracón Estructura Metas */}
            {!showCompromisos &&
              <Row>
                <Col sm={col}>
                  <Card>
                    <CardHeader>
                      <Row>
                        {(menu.Exportar === 1 && !loadingTabla) ? (
                          <Col sm='8'>
                            <Button name="btnExportar" size='sm' type="button" className='primary float-left' color='primary' onClick={() => Exportar()}>
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-lg" />
                              </span>
                              Exportar
              </Button>
                          </Col>
                        ) : <></>}
                      </Row>
                      <Filtro filtrar={setFilter} limpiar={Limpiar} horizontalTabs={horizontalTabs.horizontalTabs} responsibilityID={responsibilityID} horizontalAnter={horizontalAnter}></Filtro>
                    </CardHeader>
                    <CardBody>
                      <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper">
                          <Nav id="tabs" role="tablist" tabs>
                            {showNavRegional &&
                              <NavItem>
                                <NavLink
                                  aria-expanded={horizontalTabs.horizontalTabs === "Regional"}
                                  data-toggle="tab"
                                  role="tab"
                                  className={horizontalTabs.horizontalTabs === "Regional"
                                    ? "active"
                                    : ""
                                  }
                                  onClick={() => changeTap("Regional")}
                                >
                                  Regional
              </NavLink>
                              </NavItem>
                            }
                            {showMunicipioEstructura &&
                              <NavItem>
                                <NavLink
                                  aria-expanded={horizontalTabs.horizontalTabs === "Municipal"
                                  }
                                  data-toggle="tab"
                                  role="tab"
                                  className={horizontalTabs.horizontalTabs === "Municipal"
                                    ? "active"
                                    : ""
                                  }
                                  onClick={() => changeTap("Municipal")}
                                >
                                  Municipal
                </NavLink>
                              </NavItem>
                            }
                            <NavItem>
                              <NavLink
                                aria-expanded={horizontalTabs.horizontalTabs === "Poligonal"}
                                data-toggle="tab"
                                //href="#pablo"
                                role="tab"
                                className={horizontalTabs.horizontalTabs === "Poligonal"
                                  ? "active"
                                  : ""
                                }
                                onClick={() =>
                                  changeTap("Poligonal")}
                              >
                                Poligonal
                </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                aria-expanded={horizontalTabs.horizontalTabs === "Seccional"}
                                data-toggle="tab"
                                //href="#pablo"
                                role="tab"
                                className={horizontalTabs.horizontalTabs === "Seccional"
                                  ? "active"
                                  : ""
                                }
                                onClick={() =>
                                  changeTap("Seccional")}
                              >
                                Seccional
                </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                aria-expanded={horizontalTabs.horizontalTabs === "Manzanero"}
                                data-toggle="tab"
                                //href="#pablo"
                                role="tab"
                                className={horizontalTabs.horizontalTabs === "Manzanero"
                                  ? "active"
                                  : ""
                                }
                                onClick={() =>
                                  changeTap("Manzanero")}
                              >
                                Manzanero
                </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </div>
                      <TabContent
                        className="text-center"
                        id="my-tab-content"
                        activeTab={horizontalTabs.horizontalTabs}
                      >
                        {showNavRegional &&
                          <TabPane tabId="Regional" role="tabpanel">
                            {flagTab === 1 ?
                              <ReactTable
                                size='sm'
                                data={meta}
                                noDataText="Sin información"
                                manual
                                columns={[
                                  {
                                    Header: "Region",
                                    minWidth: 20,
                                    id: "Region",
                                    accessor: (d) => d.Region,
                                    filterAll: false,
                                    headerStyle: { fontSize: '12px', align: "center" },
                                    style: { fontSize: '12px' },
                                  }, {
                                    Header: "Responsable",
                                    accessor: (d) => d.Responsable,
                                    minWidth: 70,
                                    id: "Responsable",
                                    Cell: props => <>{props.value === "Sin Resposable" ? <strong style={{ color: "#FF0001" }}>{props.value}</strong> : <strong style={{ color: "#4BB543" }}>{props.value}</strong>}</>,
                                    filterable: false,
                                    headerStyle: { fontSize: '12px', text: "center" },
                                    style: { fontSize: '12px' },
                                  },

                                  {
                                    Header: "Celular",
                                    accessor: (d) => d.cel,
                                    minWidth: 40,
                                    show: responsibilityID === 99 ? true : false,
                                    id: "cel",
                                    filterable: false,
                                    headerStyle: { fontSize: '12px', align: "center" },
                                    style: { fontSize: '12px' },
                                  },
                                  {
                                    Header: "Meta Estructura",
                                    accessor: (d) => d.meta,
                                    minWidth: 40,
                                    id: "meta",
                                    filterable: false,
                                    headerStyle: { fontSize: '12px', align: "center" },
                                    style: { fontSize: '12px' },
                                  },
                                  {
                                    Header: "Av Estructura",
                                    accessor: (d) => d.AvanceEstructura,
                                    minWidth: 40,
                                    id: "AvanceEstructura",
                                    filterable: false,
                                    headerStyle: { fontSize: '12px', align: "center" },
                                    style: { fontSize: '12px' },
                                  },
                                  {
                                    Header: "Meta Compromisos",
                                    accessor: (d) => d.metaCompromisos,
                                    minWidth: 40,
                                    id: "metaCompromisos",
                                    filterable: false,
                                    headerStyle: { fontSize: '12px', align: "center" },
                                    style: { fontSize: '12px' },
                                  },

                                  {
                                    Header: "Av Esperado",
                                    accessor: (d) => d.Esperada,
                                    minWidth: 40,
                                    id: "esperada",
                                    filterable: false,
                                    headerStyle: { fontSize: '12px', align: "center" },
                                    style: { fontSize: '12px' },
                                  },
                                  {
                                    Header: "Compromisos",
                                    accessor: (d) => d.AvanceCompromisos,
                                    minWidth: 40,
                                    id: "AvanceCompromisos",
                                    filterable: false,
                                    headerStyle: { fontSize: '12px', align: "center" },
                                    style: { fontSize: '12px' },
                                  },
                                  {
                                    Header: "Voluntarios",
                                    accessor: (d) => d.AvanceVoluntarios,
                                    minWidth: 40,
                                    id: "AvanceVoluntarios",
                                    filterable: false,
                                    headerStyle: { fontSize: '12px', align: "center" },
                                    style: { fontSize: '12px' },
                                  },
                                  {
                                    Header: "% de Av Compromisos",
                                    accessor: (d) => d.AvanceEsperado,
                                    Cell: props => <div>{props.value + " %"}</div>,
                                    minWidth: 40,
                                    id: "AvanceEsperado",
                                    filterable: false,
                                    headerStyle: { fontSize: '12px', align: "center" },
                                    style: { fontSize: '12px' },
                                  },
                                ]}
                                className="-striped -highlight primary-pagination"
                                loading={loadingTabla}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={10}
                                total={total}
                                pages={Math.floor(total / 10)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                PaginationComponent={Pagination}
                                onFetchData={(stateComp, instance) => {
                                  setLoadingTabla(true);
                                  const data = {
                                    page: stateComp.page,
                                    pageSize: stateComp.pageSize,
                                    sorted: [{ "id": "r.id", "desc": false }],
                                    tipo: 'and',
                                    filtered: filtro ? filtro : [],
                                    Region: region ? region : [],
                                    Nombre: nombre ? nombre : "",
                                    Responsable: Responsable ? Responsable :0,
                                    tabla: "region"
                                  };

                                  EjecutarApi(data, "getResponsablesCompromisos", token.access_token)
                                    .then(function (res) {
                                      return res;
                                    })
                                    .then((res) => {
                                      setTotal(res.total);
                                      setMeta(
                                        res.data.map((prop, key) => {
                                          var porcentaje = parseFloat(prop.porcentaje)
                                          var ddporcentaje = porcentaje.toFixed(2)
                                          return {
                                            id: prop.id,
                                            Region: prop.Region ? prop.Region : "Sin Region",
                                            meta: prop.MetaEstructura,
                                            Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                                            cel: prop.ResponsableCel,
                                            Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                                            metaCompromisos: prop.MetaCompromisos,
                                            AvanceCompromisos: prop.Compromisos > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 0)} className="btn-link" color="primary">{prop.Compromisos}</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                            AvanceVoluntarios: prop.Voluntarios > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 1)} className="btn-link" color="primary">{prop.Voluntarios}</Button></>) : (prop.Voluntarios ? prop.Voluntarios : 0),
                                            AvanceEsperado: prop.AvanceMetaEsperada,
                                            AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                                            porcentaje: ddporcentaje ? ddporcentaje : 0,
                                            data: prop,
                                          };
                                        })
                                      );
                                      setLoadingTabla(false);
                                    })
                                    .catch((e) => {
                                      setLoading(false);
                                      notify.show(
                                        `Error al obtener tabla metas estructura region: ${e.message}`,
                                        "custom",
                                        5000,
                                        {
                                          background: "#0857B6",
                                          fontSize: 14,
                                          text: "#FFFFFF",
                                        }
                                      );
                                    });
                                }}
                              /> : <></>
                            }
                          </TabPane>
                        }
                        <TabPane tabId="Municipal" role="tabpanel">
                          {flagTab === 2 ?
                            <ReactTable
                              size='sm'
                              data={meta}
                              noDataText="Sin información"
                              manual
                              columns={[
                                {
                                  Header: <>{responsibilityID == 53 ? ("Distrito Federal") : (responsibilityID == 54 ? ("Distrito Local") : ("Region"))}</>,
                                  minWidth: 20,
                                  id: "Region",
                                  accessor: (d) => d.Region,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Municipio",
                                  minWidth: 40,
                                  id: "Municipio",
                                  accessor: (d) => d.Municipio,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                }, {
                                  Header: "Responsable",
                                  accessor: (d) => d.Responsable,
                                  minWidth: 70,
                                  id: "Responsable",
                                  Cell: props => <>{props.value === "Sin Resposable" ? <strong style={{ color: "#FF0001" }}>{props.value}</strong> : <strong style={{ color: "#4BB543" }}>{props.value}</strong>}</>,
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', text: "center" },
                                  style: { fontSize: '12px' },
                                },

                                {
                                  Header: "Celular",
                                  accessor: (d) => d.cel,
                                  minWidth: 40,
                                  show: responsibilityID === 99 ? true : false,
                                  id: "cel",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Meta Estructura",
                                  accessor: (d) => d.meta,
                                  minWidth: 40,
                                  id: "meta",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Av Estructura",
                                  accessor: (d) => d.AvanceEstructura,
                                  minWidth: 40,
                                  id: "AvanceEstructura",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Meta Compromisos",
                                  accessor: (d) => d.metaCompromisos,
                                  minWidth: 40,
                                  id: "metaCompromisos",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },

                                {
                                  Header: "Av Esperado",
                                  accessor: (d) => d.Esperada,
                                  minWidth: 40,
                                  id: "esperada",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Compromisos",
                                  accessor: (d) => d.AvanceCompromisos,
                                  minWidth: 40,
                                  id: "AvanceCompromisos",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Voluntarios",
                                  accessor: (d) => d.AvanceVoluntarios,
                                  minWidth: 40,
                                  id: "AvanceV",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "% de Av Compromisos",
                                  accessor: (d) => d.AvanceEsperado,
                                  Cell: props => <div>{props.value + " %"}</div>,
                                  minWidth: 40,
                                  id: "AvanceEsperado",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                              ]}

                              className="-striped -highlight primary-pagination"
                              loading={loadingTabla}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={10}
                              total={total}
                              pages={Math.floor(total / 10)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={(stateComp, instance) => {
                                setLoadingTabla(true);
                                const data = {
                                  page: stateComp.page,
                                  pageSize: stateComp.pageSize,
                                  sorted: [{ "id": "m.id", "desc": false }],
                                  filtered: filtro ? filtro : [],
                                  Region: region ? region : [],
                                  Municipio: municipio ? municipio : [],
                                  Nombre: nombre ? nombre : "",
                                  Responsable: Responsable ? Responsable :0,
                                  tipo: 'and',
                                  tabla: "municipio"
                                };
                                EjecutarApi(data, "getResponsablesCompromisos", token.access_token)
                                  .then(function (res) {
                                    return res;
                                  })
                                  .then((res) => {
                                    setLoadingTabla(false);
                                    setTotal(res.total);
                                    setMeta(
                                      res.data.map((prop, key) => {
                                        var porcentaje = parseFloat(prop.porcentaje)
                                        var ddporcentaje = porcentaje.toFixed(2)
                                        return {
                                          id: prop.id,
                                          Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                          Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                                          meta: prop.MetaEstructura,
                                          Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                                          cel: prop.ResponsableCel,
                                          Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                                          metaCompromisos: prop.MetaCompromisos,
                                          AvanceCompromisos: prop.Compromisos > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 0)} className="btn-link" color="primary">{prop.Compromisos}</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                          AvanceVoluntarios: prop.Voluntarios > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 1)} className="btn-link" color="primary">{prop.Voluntarios}</Button></>) : (prop.Voluntarios ? prop.Voluntarios : 0),
                                          AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                                          AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                                          porcentaje: ddporcentaje ? ddporcentaje : 0,
                                          data: prop,
                                        };
                                      })
                                    );

                                  })
                                  .catch((e) => {
                                    setLoadingTabla(false);
                                    notify.show(
                                      `Error al obtener tabla metas: ${e.message}`,
                                      "custom",
                                      5000,
                                      {
                                        background: "#0857B6",
                                        fontSize: 14,
                                        text: "#FFFFFF",
                                      }
                                    );
                                  });
                              }
                              }
                            /> : <></>
                          }

                        </TabPane>
                        <TabPane tabId="Poligonal" role="tabpanel">
                          {flagTab === 3 ?
                            <ReactTable
                              size='sm'
                              data={meta}
                              noDataText="Sin información"
                              manual
                              columns={[
                                {
                                  Header: <>{responsibilityID == 53 ? ("Distrito Federal") : (responsibilityID == 54 ? ("Distrito Local") : ("Region"))}</>,
                                  minWidth: 20,
                                  id: "Region",
                                  accessor: (d) => d.Region,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Municipio",
                                  minWidth: 40,
                                  id: "Municipio",
                                  accessor: (d) => d.Municipio,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Polígono",
                                  minWidth: 25,
                                  id: "Poligono",
                                  accessor: (d) => d.Poligono,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '13px' },
                                }, {
                                  Header: "Responsable",
                                  accessor: (d) => d.Responsable,
                                  minWidth: 70,
                                  id: "Responsable",
                                  Cell: props => <>{props.value === "Sin Resposable" ? <strong style={{ color: "#FF0001" }}>{props.value}</strong> : <strong style={{ color: "#4BB543" }}>{props.value}</strong>}</>,
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', text: "center" },
                                  style: { fontSize: '12px' },
                                },

                                {
                                  Header: "Celular",
                                  accessor: (d) => d.cel,
                                  minWidth: 40,
                                  show: responsibilityID === 99 ? true : false,
                                  id: "cel",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Meta Estructura",
                                  accessor: (d) => d.meta,
                                  minWidth: 40,
                                  id: "meta",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Av Estructura",
                                  accessor: (d) => d.AvanceEstructura,
                                  minWidth: 40,
                                  id: "AvanceEstructura",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Meta Compromisos",
                                  accessor: (d) => d.metaCompromisos,
                                  minWidth: 40,
                                  id: "metaCompromisos",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },

                                {
                                  Header: "Av Esperado",
                                  accessor: (d) => d.Esperada,
                                  minWidth: 40,
                                  id: "esperada",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Compromisos",
                                  accessor: (d) => d.AvanceCompromisos,
                                  minWidth: 40,
                                  id: "AvanceCompromisos",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Voluntarios",
                                  accessor: (d) => d.AvanceVoluntarios,
                                  minWidth: 40,
                                  id: "AvanceV",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "% de Av Compromisos",
                                  accessor: (d) => d.AvanceEsperado,
                                  Cell: props => <div>{props.value + " %"}</div>,
                                  minWidth: 40,
                                  id: "AvanceEsperado",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                              ]}

                              className="-striped -highlight primary-pagination"
                              loading={loadingTabla}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={10}
                              total={total}
                              pages={Math.floor(total / 10)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={(stateComp, instance) => {
                                setLoadingTabla(true);
                                const data = {
                                  page: stateComp.page,
                                  pageSize: stateComp.pageSize,
                                  sorted: [{ "id": "p.id", "desc": false }],
                                  tipo: 'and',
                                  filtered: filtro ? filtro : [],
                                  Region: region ? region : [],
                                  Municipio: municipio ? municipio : [],
                                  Poligono: poligono ? poligono : [],
                                  Nombre: nombre ? nombre : "",
                                  Responsable: Responsable ? Responsable :0,
                                  tabla: "poligono"
                                };
                                EjecutarApi(data, "getResponsablesCompromisos", token.access_token)
                                  .then(function (res) {
                                    return res;
                                  })
                                  .then((res) => {
                                    setLoadingTabla(false);
                                    setTotal(res.total);
                                    setMeta(
                                      res.data.map((prop, key) => {
                                        var porcentaje = parseFloat(prop.porcentaje)
                                        var ddporcentaje = porcentaje.toFixed(2)
                                        return {
                                          id: prop.id,
                                          Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                          Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                                          Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                          meta: prop.MetaEstructura,
                                          Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                                          cel: prop.ResponsableCel,
                                          Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                                          metaCompromisos: prop.MetaCompromisos,
                                          AvanceCompromisos: prop.Compromisos > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 0)} className="btn-link" color="primary">{prop.Compromisos}</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                          AvanceVoluntarios: prop.Voluntarios > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 1)} className="btn-link" color="primary">{prop.Voluntarios}</Button></>) : (prop.Voluntarios ? prop.Voluntarios : 0),
                                          AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                                          AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                                          porcentaje: ddporcentaje ? ddporcentaje : 0,
                                          data: prop,
                                        };
                                      })
                                    );

                                  })
                                  .catch((e) => {
                                    setLoadingTabla(false);
                                    notify.show(
                                      `Error al obtener tabla metas: ${e.message}`,
                                      "custom",
                                      5000,
                                      {
                                        background: "#0857B6",
                                        fontSize: 14,
                                        text: "#FFFFFF",
                                      }
                                    );
                                  });
                              }}
                            /> : <></>
                          }
                        </TabPane>
                        <TabPane tabId="Seccional" role="tabpanel">
                          {flagTab === 4 ?
                            <ReactTable
                              size='sm'
                              data={meta}
                              noDataText="Sin información"
                              manual
                              columns={[
                                {
                                  Header: <>{responsibilityID == 53 ? ("Distrito Federal") : (responsibilityID == 54 ? ("Distrito Local") : ("Region"))}</>,
                                  minWidth: 20,
                                  id: "Region",
                                  accessor: (d) => d.Region,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Municipio",
                                  minWidth: 40,
                                  id: "Municipio",
                                  accessor: (d) => d.Municipio,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Polígono",
                                  minWidth: 25,
                                  id: "Poligono",
                                  accessor: (d) => d.Poligono,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '13px' },
                                },
                                {
                                  Header: "Sección",
                                  minWidth: 20,
                                  id: "Seccion",
                                  accessor: (d) => d.Seccion,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '13px' },
                                },
                                {
                                  Header: "Responsable",
                                  accessor: (d) => d.Responsable,
                                  minWidth: 70,
                                  id: "Responsable",
                                  Cell: props => <>{props.value === "Sin Resposable" ? <strong style={{ color: "#FF0001" }}>{props.value}</strong> : <strong style={{ color: "#4BB543" }}>{props.value}</strong>}</>,
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', text: "center" },
                                  style: { fontSize: '12px' },
                                },

                                {
                                  Header: "Celular",
                                  accessor: (d) => d.cel,
                                  minWidth: 40,
                                  show: responsibilityID === 99 ? true : false,
                                  id: "cel",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Meta Estructura",
                                  accessor: (d) => d.meta,
                                  minWidth: 40,
                                  id: "meta",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Av Estructura",
                                  accessor: (d) => d.AvanceEstructura,
                                  minWidth: 40,
                                  id: "AvanceEstructura",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Meta Compromisos",
                                  accessor: (d) => d.metaCompromisos,
                                  minWidth: 40,
                                  id: "metaCompromisos",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },

                                {
                                  Header: "Av Esperado",
                                  accessor: (d) => d.Esperada,
                                  minWidth: 40,
                                  id: "esperada",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Compromisos",
                                  accessor: (d) => d.AvanceCompromisos,
                                  minWidth: 40,
                                  id: "AvanceCompromisos",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Voluntarios",
                                  accessor: (d) => d.AvanceVoluntarios,
                                  minWidth: 40,
                                  id: "AvanceV",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "% de Av Compromisos",
                                  accessor: (d) => d.AvanceEsperado,
                                  Cell: props => <div>{props.value + " %"}</div>,
                                  minWidth: 40,
                                  id: "AvanceEsperado",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                              ]}

                              className="-striped -highlight primary-pagination"
                              loading={loadingTabla}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={10}
                              total={total}
                              pages={Math.floor(total / 10)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={(stateComp, instance) => {
                                setLoadingTabla(true);
                                const data = {
                                  page: stateComp.page,
                                  pageSize: stateComp.pageSize,
                                  sorted: [{ "id": "S.Seccion", "desc": false }],
                                  tipo: 'and',
                                  filtered: filtro ? filtro : [],
                                  Region: region ? region : [],
                                  Municipio: municipio ? municipio : [],
                                  Poligono: poligono ? poligono : [],
                                  Seccion: seccion ? seccion : [],
                                  Nombre: nombre ? nombre : "",
                                  Responsable: Responsable ? Responsable :0,
                                  tabla: "seccion"
                                };
                                EjecutarApi(data, "getResponsablesCompromisos", token.access_token)
                                  .then(function (res) {
                                    return res;
                                  })
                                  .then((res) => {
                                    setLoadingTabla(false);
                                    setTotal(res.total);
                                    setMeta(
                                      res.data.map((prop, key) => {
                                        var porcentaje = parseFloat(prop.porcentaje)
                                        var ddporcentaje = porcentaje.toFixed(2)
                                        return {
                                          id: prop.id,
                                          Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                          Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                                          Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                          Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                                          meta: prop.MetaEstructura,
                                          Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                                          cel: prop.ResponsableCel ? prop.ResponsableCel : "Sin Celular",
                                          Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                                          metaCompromisos: prop.MetaCompromisos,
                                          AvanceCompromisos: prop.Compromisos > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 0)} className="btn-link" color="primary">{prop.Compromisos}</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                          AvanceVoluntarios: prop.Voluntarios > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 1)} className="btn-link" color="primary">{prop.Voluntarios}</Button></>) : (prop.Voluntarios ? prop.Voluntarios : 0),
                                          AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                                          AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                                          porcentaje: ddporcentaje ? ddporcentaje : 0,
                                          data: prop,
                                        };
                                      })
                                    );

                                  })
                                  .catch((e) => {
                                    setLoadingTabla(false);
                                    notify.show(
                                      `Error al obtener tabla metas: ${e.message}`,
                                      "custom",
                                      5000,
                                      {
                                        background: "#0857B6",
                                        fontSize: 14,
                                        text: "#FFFFFF",
                                      }
                                    );
                                  });
                              }}
                            />
                            : <></>
                          }

                        </TabPane>
                        <TabPane tabId="Manzanero" role="tabpanel">
                          {flagTab === 5 ?
                            <ReactTable
                              size='sm'
                              data={meta}
                              noDataText="Sin información"
                              manual
                              columns={[
                                {
                                  Header: <>{responsibilityID == 53 ? ("Distrito Federal") : (responsibilityID == 54 ? ("Distrito Local") : ("Region"))}</>,
                                  minWidth: 20,
                                  id: "Region",
                                  accessor: (d) => d.Region,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Municipio",
                                  minWidth: 40,
                                  id: "Municipio",
                                  accessor: (d) => d.Municipio,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Polígono",
                                  minWidth: 25,
                                  id: "Poligono",
                                  accessor: (d) => d.Poligono,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '13px' },
                                },
                                {
                                  Header: "Sección",
                                  minWidth: 20,
                                  id: "Seccion",
                                  accessor: (d) => d.Seccion,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '13px' },
                                },
                                {
                                  Header: "Manzana",
                                  minWidth: 20,
                                  id: "Manzana",
                                  accessor: (d) => d.Manzana,
                                  filterAll: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '13px' },
                                },
                                {
                                  Header: "Prioridad",
                                  accessor: (d) => d.Prioridad,
                                  minWidth: 40,
                                  id: "Prioridad",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Meta Estructura",
                                  accessor: (d) => d.meta,
                                  minWidth: 40,
                                  id: "meta",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Av Estructura",
                                  accessor: (d) => d.AvanceEstructura,
                                  minWidth: 40,
                                  id: "AvanceEstructura",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Responsable",
                                  accessor: (d) => d.Responsable,
                                  minWidth: 70,
                                  id: "Responsable",
                                  Cell: props => <>{props.value === "Sin Resposable" ? <strong style={{ color: "#FF0001" }}>{props.value}</strong> : <strong style={{ color: "#4BB543" }}>{props.value}</strong>}</>,
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', text: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Celular",
                                  accessor: (d) => d.cel,
                                  minWidth: 40,
                                  show: responsibilityID === 99 ? true : false,
                                  id: "cel",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Compromisos",
                                  accessor: (d) => d.AvanceCompromisos,
                                  minWidth: 40,
                                  id: "AvanceCompromisos",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                },
                                {
                                  Header: "Voluntarios",
                                  accessor: (d) => d.AvanceVoluntarios,
                                  minWidth: 40,
                                  id: "AvanceV",
                                  filterable: false,
                                  headerStyle: { fontSize: '12px', align: "center" },
                                  style: { fontSize: '12px' },
                                }
                              ]}

                              className="-striped -highlight primary-pagination"
                              loading={loadingTabla}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={10}
                              total={total}
                              pages={Math.floor(total / 10)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={(stateComp, instance) => {

                                setLoadingTabla(true);
                                const data = {
                                  page: stateComp.page,
                                  pageSize: stateComp.pageSize,
                                  sorted: [{ "id": "cm.id", "desc": false }],
                                  tipo: 'and',
                                  filtered: filtro ? filtro : [],
                                  Region: region ? region : [],
                                  Municipio: municipio ? municipio : [],
                                  Poligono: poligono ? poligono : [],
                                  Seccion: seccion ? seccion : [],
                                  Nombre: nombre ? nombre : "",
                                  Manzana: manzana ? manzana : [],
                                  Responsable: Responsable ? Responsable :0,
                                  tabla: "manzanero"
                                };
                                EjecutarApi(data, "getResponsablesCompromisos", token.access_token)
                                  .then(function (res) {
                                    return res;
                                  })
                                  .then((res) => {
                                    setLoadingTabla(false);
                                    setTotal(res.total);
                                    setMeta(
                                      res.data.map((prop, key) => {
                                        return {
                                          id: prop.id,
                                          Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                          Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                                          Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                          Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                                          Manzana: prop.manzana ? prop.manzana : 'Sin Manzana',
                                          Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                                          cel: prop.ResponsableCel ? prop.ResponsableCel : "Sin Celular",
                                          Prioridad: prop.Prioridad,
                                          AvanceCompromisos: prop.Compromisos > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 0)} className="btn-link" color="primary">{prop.Compromisos}</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                          AvanceVoluntarios: prop.Voluntarios > 0 ? (<><Button onClick={() => onClickShowCompromisos(prop, 1)} className="btn-link" color="primary">{prop.Voluntarios}</Button></>) : (prop.Voluntarios ? prop.Voluntarios : 0),
                                          AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                                          meta:prop.MetaEstructura,
                                          data: prop,
                                        };
                                      })
                                    );

                                  })
                                  .catch((e) => {
                                    setLoadingTabla(false);
                                    notify.show(
                                      `Error al obtener tabla metas: ${e.message}`,
                                      "custom",
                                      5000,
                                      {
                                        background: "#0857B6",
                                        fontSize: 14,
                                        text: "#FFFFFF",
                                      }
                                    );
                                  });
                              }}
                            />
                            : <></>}
                        </TabPane>

                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
            {
              showCompromisos &&
              <ListadoCompromisos
                props={compromisos}
                onHide={() => onHide()}>
              </ListadoCompromisos>
            }
          </div>
        </div>
        <Footer fluid />
      </div>
    </div>
  );

}
export default AvanceResponsabilidad;