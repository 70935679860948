import React, { useState, useEffect } from "react";
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { MagicSpinner } from "react-spinners-kit";
import {SinPermisos} from "../ComponentesAuxiliares/SinPermisos";
import {centerOnOneShape} from "../ComponentesAuxiliares/AuxiliarFunctions";
import {
    Button,
    ButtonGroup,Table
} from "reactstrap";
let style_b = {
    'color': '#000000',
}

let style_button_radiogroup = {
    'padding': '2px 4px',
    'font': '10px/12px ',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
}

let style_square_superior = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
}
let style_square_inferior_tabla = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.9)',
    /* 'background': 'white', */
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
    'zIndex': '100'
}
let style_square_superior_left = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
    //'width':'200px'
}
let style_spinner = {
    'height': '500pt',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center'
}
let style_square_inferior_rangos = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    /* 'background': 'white', */
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
    'textAlign': 'left',
    'lineHeight': '18px',
    'color': '#555'
}

function ChoropethMap({ MenuPermisos,center, zoom, data, rangos, tipo, label_tipo, handleTipoMapa, handleDetalleMapa, derecho_mapa,setExport }) {
    
    const [_center, setCenter] = useState(center ? center : [20.678416, -101.354231]);
    const [_zoom, setZoom] = useState(zoom ? zoom : 12);
    const _data = data ? data : null;
    const _rangos = rangos ? rangos : [];
    const flag = Object.keys(_data).length === 0 ? false : true;
    const _tipo = tipo ? tipo : 'municipio';
    const _derecho_mapa = derecho_mapa;
    const [tipoMapa, setTipoMapa] = useState({ value: tipo, label: label_tipo });
    const [countRango, setCountRango] = useState([]);
    const [flagData] = useState(true);
    const [flagTabla, setFlagTabla] = useState(false);
    const [buttonDetalle, setButonDetalle] = useState(false);
    //const exportar = Exportar ? Exportar : 0;

    const [prop, setProp] = useState(
        tipo === 'municipio' ?
            { 'nom_mun': '', 'cve_mun': '' }
            : tipo === 'region' ?
                { 'region': '' } : tipo === 'seccion' ? { 'seccion': '' } : { 'poligono': '' }
    );
    function roundTo(value, places) {
        var power = Math.pow(10, places);
        return Math.round(value * power) / power;
    }

    function style(feature, flag) {
        let style;
        if (flag === undefined || flag !== true) {
            style = {
                fillColor: feature.properties.color,
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.3
            };
        }
        else {
            style = {
                weight: 5,
                color: '#666',
                dashArray: '',
                fillOpacity: 0.5
            };

        }
        return style;
    }

    function style_rangos(color, flag) {
        let style;
        if (flag && color !== null && color !== undefined) {
            style = {
                'width': '18px',
                'height': '18px',
                'float': 'left',
                'marginRight': '8px',
                'opacity': '0.7',
                'background': color
            };
        }
        else {
            style = {
                'width': '18px',
                'height': '18px',
                'float': 'left',
                'marginRight': '8px',
                'opacity': '0.7',
                'background': '#FFEDA0'
            };

        }
        return style;
    }


    const highlightFeature = (e) => {
        //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.

        var layer = e.target;
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
        if (_tipo === 'municipio')
            setProp({
                'nom_mun': e.target.feature.properties.nom_mun,
                'cve_mun': e.target.feature.properties.cve_mun,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e
            });
        else if (_tipo === 'region')
            setProp({
                'region': e.target.feature.properties.region,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e
            });
        else if (_tipo === 'seccion')
            setProp({
                'seccion': e.target.feature.properties.seccion,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e
            });
        else if (_tipo === 'poligono')
            setProp({
                'pg_poligon': e.target.feature.properties.pg_poligon,
                'poligono': e.target.feature.properties.poligono,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e
            });

        layer.setStyle(style(layer.feature, true));
    }

    const handleExport = (e)=>{
        setExport(e)
    }

    const clickFeature = (e) => {
        setButonDetalle(true);
        highlightFeature(e);
    }

    const clickDetalle = (e) => {
        setButonDetalle(false);

        var siguiente_nivel = "region";
        var siguiente_nivel_label = "Regional";
        var siguiente_nivel_clave = "";

        if (tipoMapa.value === "region") {
            //setZoom(zoom + 1);
            siguiente_nivel = "municipio";
            siguiente_nivel_label = "Municipal";
            siguiente_nivel_clave = e.target.feature.properties.region;
        }
        if (tipoMapa.value === "municipio") {
            //setZoom(zoom + 1);
            siguiente_nivel = "poligono";
            siguiente_nivel_label = "Poligonal";
            siguiente_nivel_clave = e.target.feature.properties.cve_mun;
        }
        if (tipoMapa.value === "poligono") {
            //setZoom(zoom + 1);
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Seccional";
            siguiente_nivel_clave = e.target.feature.properties.poligono;
        }
        if (tipoMapa.value === "seccion") {
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Seccional";
            /* siguiente_nivel = "manzana";
            siguiente_nivel_label = "Manzanas";
            siguiente_nivel_clave = e.target.feature.properties.seccion; */
            return; //CLICK NO PROGRAMADO
        }
        handleDetalleMapa({ value: siguiente_nivel, label: siguiente_nivel_label, clave: siguiente_nivel_clave });
        setTipoMapa({ value: siguiente_nivel, label: siguiente_nivel_label });
        //Aqui configuramos lo que queremos que haga un feature cuando le den click.
        //alert(e.target.feature.properties.nom_mun);
    }

    const resetHighlight = (e) => {
        //Con esto reseteamos el color en donde paso el mouse, para que no siga marcado.
        var layer = e.target;
        layer.setStyle(style(e.target.feature));
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
    }



    const onEachFeature = (feature, layer) => {
        //Organizamos los eventos del GeoJson.
        layer.on({
            //mouseover: highlightFeature,
            //mouseout: resetHighlight,
            click: clickFeature
        });
    }

    var geojson = (
        <GeoJSON onEachFeature={onEachFeature} style={style} key={"geojson"} data={_data}></GeoJSON>
    )
    useEffect(() => {
        setButonDetalle(false);
        setProp({});

        var array_counter = [];
        var color_rangos = [];
        if (_rangos.length !== 0 && _rangos.length !== undefined) {
            color_rangos = _rangos.map((item) => {
                return item.Color
            });
            array_counter = color_rangos.map((item) => {
                return 0
            });
        }
        if (Object.keys(_data).length !== 0) {
            _data.features.forEach(function (f) {

                color_rangos.forEach(function (item, i) {
                    if (item === f.properties.color) {
                        array_counter[i] = array_counter[i] + 1;
                    }
                });
            });
            setCountRango(array_counter);
            if(_data.features.length !== 0){
                const {zoom, center} = centerOnOneShape(flagData,_data);
                setCenter(center);
                setZoom(zoom);
            }
        }

        
    }, [data,rangos]);


    const miTipoMapaChange = (e) => {
        /* if (e.value !== 'poligono' && e.value !== 'seccion') {
            setCenter([20.917639, -101.091132]);
            //setZoom(9);
        } */
        setTipoMapa(e);
        handleTipoMapa(e);

    }

    return (
        <>
            {
                flag ?
                MenuPermisos.Ver === 1?
                    <Map zoom={_zoom} zoomControl={false} center={_center} style={{ width: '100%', height: '90vh' }}>

                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-right">
                                <div className="leaflet-control" style={style_square_superior}>
                                    {
                                        (_tipo === 'municipio') ?
                                            <>
                                                <h6>INFORMACIÓN:</h6>
                                                {(prop.nom_mun !== '' && prop.nom_mun !== undefined) ?
                                                    <>
                                                        <b style={style_b}>Municipio:</b><b style={{ color: prop.color }}> {prop.nom_mun}</b><br></br>
                                                        <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                        <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                        <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                        <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                        <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta === 0 ? "--" : roundTo(prop.avance_porcentual, 2) + "%"}</b><br></br>
                                                    </>
                                                    : <b> ¡Pulse sobre un municipio!</b>}
                                            </>
                                            :
                                            (_tipo === 'region') ?
                                                <>
                                                    <h6>INFORMACIÓN:</h6>
                                                    {(prop.region !== '' && prop.region !== undefined) ?
                                                        <>
                                                            <b style={style_b}>Región:</b><b style={{ color: prop.color }}> {prop.region}</b><br></br>
                                                            <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                            <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                            <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                            <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                            <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta === 0 ? "--" : roundTo(prop.avance_porcentual, 2) + "%"}</b><br></br>

                                                        </>
                                                        : <b> ¡Pulse sobre una región!</b>}
                                                </>
                                                :
                                                (_tipo === 'seccion') ?
                                                    <>
                                                        <h6>INFORMACIÓN:</h6>
                                                        {(prop.seccion !== '' && prop.seccion !== undefined) ?
                                                            <>
                                                                <b style={style_b}>Sección:</b><b style={{ color: prop.color }}> {prop.seccion}</b><br></br>
                                                                <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                                <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                                <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                                <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta === 0 ? "--" : roundTo(prop.avance_porcentual, 2) + "%"}</b><br></br>

                                                            </>
                                                            : <b> ¡Pulse sobre una sección!</b>}
                                                    </>
                                                    :
                                                    <>
                                                        <h6>INFORMACIÓN:</h6>
                                                        {(prop.poligono !== '' && prop.poligono !== undefined) ?
                                                            <>
                                                                <b style={style_b}>Polígono:</b><b style={{ color: prop.color }}> {prop.pg_poligon}</b><br></br>
                                                                <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                                <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                                <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                                <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta === 0 ? "--" : roundTo(prop.avance_porcentual, 2) + "%"}</b><br></br>

                                                            </>
                                                            : <b> ¡Pulse sobre un polígono!</b>}
                                                    </>

                                    }
                                    {
                                    buttonDetalle && prop.seccion === undefined? 
                                    <ButtonGroup>
                                        <Button
                                        style={style_button_radiogroup}
                                        size ="sm"
                                        className="btn btn-sm btn-round"
                                        color={'success'}
                                        outline
                                        type="button" 
                                        onClick={(e)=>{clickDetalle(prop.prop_save)}}>Ver Detalle</Button>
                                    </ButtonGroup>:<></>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="leaflet-control-container">
                            <div className="leaflet-bottom leaflet-left">
                                <div className="leaflet-control" style={style_square_inferior_tabla}>
                                    <Button
                                        className="btn-round btn-sm"
                                        color={'success'}
                                        outline
                                        size="sm"
                                        type="button"
                                        onClick={() => setFlagTabla(!flagTabla)}
                                    >
                                        {(flagTabla) ? "Esconder" : "Mostrar"}
                                    </Button>
                                    {

                                        (flagTabla) ?
                                            <>
                                            {MenuPermisos.Exportar === 1 ? (
                                                <Button
                                                    className="btn-round btn-sm"
                                                    color={'success'}
                                                    outline
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {handleExport(_data)}}
                                                >
                                                    Exportar
                                                </Button> 
                                                ):<></>}
                                                <Table responsive striped border="2px" style={{ 'height': '200px', 'overflow': 'scroll', 'display': 'block' }}>
                                                <thead className="text-primary">
                                                        <tr>
                                                            {(_tipo === 'region') ?
                                                                <th>Región</th>
                                                                :
                                                                (_tipo === 'municipio') ?
                                                                    <th>Municipio</th>
                                                                    :
                                                                    (_tipo === 'poligono') ?
                                                                        <th>Polígono</th>
                                                                        :
                                                                        (_tipo === 'seccion') ?
                                                                            <th>Sección</th>
                                                                            :
                                                                            <th>Sin especificar</th>
                                                            }
                                                            <th>LNOM</th>
                                                            <th>Meta </th>
                                                            <th>Avance Esperado</th>
                                                            <th>Compromisos</th>
                                                            <th>Porcentaje de Avance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                            {
                                                            _data.features.map(function (item, i) {
                                                                return (
                                                                    <tr key={i}>
                                                                        {
                                                                            (_tipo === 'region') ?
                                                                                <td><div style={{ color: item.properties.color }} >{item.properties.region}</div></td>
                                                                                :
                                                                                (_tipo === 'municipio') ?
                                                                                    <td><div style={{ color: item.properties.color }} >{item.properties.nom_mun}</div></td>
                                                                                    :
                                                                                    (_tipo === 'poligono') ?
                                                                                        <td><div style={{ color: item.properties.color }} >{item.properties.pg_poligon}</div></td>
                                                                                        :
                                                                                        (_tipo === 'seccion') ?
                                                                                            <td><div style={{ color: item.properties.color }} >{item.properties.seccion}</div></td>
                                                                                            :
                                                                                            <th>Sin especificar</th>
                                                                        }
                                                                        <td><div style={{ color: item.properties.color }} >{new Intl.NumberFormat('en-EN').format(item.properties.lnom)}</div></td>
                                                                        <td><div style={{ color: item.properties.color }} >{new Intl.NumberFormat('en-EN').format(item.properties.meta_total)}</div></td>
                                                                        <td><div style={{ color: item.properties.color }} >{new Intl.NumberFormat('en-EN').format(item.properties.meta)}</div></td>
                                                                        <td><div style={{ color: item.properties.color }} >{new Intl.NumberFormat('en-EN').format(item.properties.avance)}</div></td>
                                                                        <td><div style={{ color: item.properties.color }} >{(item.properties.avance === 0 && item.properties.meta === 0) ? "--" : roundTo(item.properties.avance_porcentual, 2) + "%"}</div></td>
                                                                    </tr>
                                                                )
                                                            })

                                                        }
                                                        
                                                    </tbody>
                                                </Table>
                                            </>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-left">
                                <div className="leaflet-control" style={style_square_superior_left}>


                                    <ButtonGroup style={{ backgroundColor: '#FFF' }}>
                                        {_derecho_mapa.includes(4) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'region' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "region", label: "Regional" })}
                                            >
                                                Región
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(3) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'municipio' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "municipio", label: "Municipal" })}
                                            >
                                                Municipio
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(2) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'poligono' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "poligono", label: "Poligonal" })}
                                            >
                                                Polígono
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(1) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'seccion' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "seccion", label: "Seccional" })}
                                            >
                                                Seccion
                                            </Button>
                                            :
                                            <></>}
                                    </ButtonGroup>

                                </div>
                            </div>
                        </div>

                        <div className="leaflet-control-container">
                            <div className="leaflet-bottom leaflet-right">
                                <div className="legend leaflet-control" style={style_square_inferior_rangos}>
                                    <h6>Avance Compromisos:</h6>
                                    {(_rangos !== null && _rangos.length !== 0 && _rangos.length !== undefined) ?
                                        <>
                                            {
                                                _rangos.map((rango, i) => {
                                                    return <div key={(rango.id).toString()}><i style={style_rangos(rango.Color, true)}></i><b>{`${rango.Label} - Cantidad ${countRango[i]}`}</b><br></br></div>
                                                })
                                            }
                                        </>
                                        : <b> Cargando...</b>}
                                </div>
                            </div>
                        </div>
                        
                        {geojson}
                    </Map>
                    :
                    <SinPermisos></SinPermisos>
                    :

                    <div style={style_spinner}>
                        <MagicSpinner size={70} color={"#008000"} loading={true}></MagicSpinner>
                    </div>
            }
        </>
    );
}

export default ChoropethMap;
