import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardDeck,
  CardText,
  CardBody,
  Row,
  Col,
  Jumbotron
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import { CircleSpinner } from "react-spinners-kit";
import PerfectScrollbar from "perfect-scrollbar";

import { getVars } from "GlobalStorage";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import { URL_GENERAL } from "data/Consts";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from "data/menus";
import useWindowDimensions from "components/useWindowDimensions";

var ps;

const Plantillas = (props) => {
  const token = getVars('Token');
  const mainPanel = useRef();
  const [_menu, _setmenu] = useState({});
  const [documentos, setDocumentos] = useState({
    F1xN: 0,
    FSeccional: 0,
    FPoligonal: 0,
    Manzanera1xN: 0
  });
  const [loading, setLoading] = useState(true);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    if (props.history.action === "PUSH") {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }

    let menu = getDataMenu(108, token.menus);
    _setmenu(menu)
    if (Object.keys(menu).length > 0) {
      if (menu.Exportar === 1) {
        EjecutarApi({}, "getFCOPrivileges", token.access_token)
          .then(function (res) {
            return res;
          })
          .then((response) => {
            if (response.results) {
              setDocumentos(response.data);
            }
            setLoading(false);
          }).catch((e) => {
            setLoading(false);
            notify.show("Error al obtener los privilegios", "error", 5000);
          });
      }
    } else {
      //El usuario no tiene el menú asignado 
    }
  }, [])

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const Exportar = (e) => {
    var url = new URL(
      `${URL_GENERAL}getPDF?tipo=${e}&token=${token.access_token}`
    );
    window.open(url, "_blank");
  }

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel} style={{
        backgroundImage: `url(${require('../../assets/img/home/Back.jpg')})`,
        height: height
      }}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <br />
        <div>
          <Row >
            <Col sm="12" md={{ size: 8, offset: 2 }}>
              <Jumbotron style={{ "background-color": "transparent" }} className=' text-sm-center'>
                <h1 className="display-3">Formato de Control Operativo</h1>
              </Jumbotron>
            </Col>
          </Row>
          <Row className='text-center'>
            <Col sm="12" md="12" lg="12">
              {loading && (
                <CircleSpinner
                  className="text-center"
                  size={30}
                  color="#686769"
                  loading={loading}
                />
              )}
            </Col>
          </Row>
          <Row className=' text-sm-center'>
            <Col sm="12" md={{ size: 8, offset: 2 }}>
              <CardDeck>
                {documentos.FPoligonal === 1 &&
                  < Card >
                    <CardBody>
                      <CardText tag="h5">Poligonal
                        <br></br>
                        <i class="fa fa-file-text-o fa-5x" aria-hidden="true"></i>
                      </CardText>
                      <h6>FCO-04</h6>
                      {(_menu.Exportar === 1) ? (
                        <Button className="btn btn-sm" outline color="info" onClick={() => Exportar("poligono")}>Descargar Formato para Impresión</Button>) : <></>}
                    </CardBody>
                  </Card>
                }
                {documentos.FSeccional === 1 &&
                  <Card >
                    <CardBody>
                      <CardText tag="h5">Seccional <br></br>
                        <i class="fa fa-file-text-o fa-5x" aria-hidden="true"></i>
                      </CardText>
                      <h6>FCO-03</h6>
                      {(_menu.Exportar === 1) ? (
                        <Button className="btn btn-sm" outline color="info" onClick={() => Exportar("seccion")}>Descargar Formato para Impresión</Button>) : <></>}
                    </CardBody>
                  </Card>
                }
                {documentos.Manzanera1xN === 1 &&
                  <Card >
                    <CardBody>
                      <CardText tag="h5">Manzanera<br></br>
                        <i class="fa fa-file-text-o fa-5x" aria-hidden="true"></i>
                      </CardText>
                      <h6>FCO-02</h6>
                      {(_menu.Exportar === 1) ? (
                        <Button className="btn btn-sm" outline color="info" onClick={() => Exportar("manzana")}>Descargar Formato para Impresión</Button>) : <></>}
                    </CardBody>
                  </Card>
                }
                {documentos.F1xN === 1 &&
                  <Card >
                    <CardBody>
                      <CardText tag="h5">1xN<br></br>
                        <i class="fa fa-file-text-o fa-5x" aria-hidden="true"></i>
                      </CardText>
                      <h6>FCO-01</h6>
                      {(_menu.Exportar === 1) ? (
                        <Button className="btn btn-sm" outline color="info" onClick={() => Exportar("1xn")}>Descargar Formato para Impresión</Button>) : <></>}
                    </CardBody>
                  </Card>
                }
              </CardDeck>
            </Col>
          </Row>
          <Footer fluid />
        </div>
      </div>
    </div>
  );
};

export default Plantillas;
