import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Label
} from "reactstrap";
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";

import Navbar from "components/Navbar";

import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
//import Filter from "../components/Filtro/compromisoFiltro";
import { URL_GENERAL } from "data/Consts";
import { getDataMenu } from 'data/menus.js';
import EjecutarApi from "data/getPOST";
import { getVars, } from "GlobalStorage";


const AvancesCompromisosPoligono = (props) => {
  // const { dispatch } = useContext(Context);
  const [estatalDetalle, setEstatalDetalle] = useState([]);
  const [estatal, setEstatal] = useState({ Regional: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Municipios: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Poligonos: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Secciones: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 } });
  const [loading, setLoading] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [total, setTotal] = useState(0);
  const [Filtro, setFiltro] = useState([]);
  const [catMunicipio, setCatMunicipio] = useState([{ value: 0, label: 'Todos' }])
  const [catPoligonos, setCatPoligonos] = useState([{ value: 0, label: 'Todos' }])
  const [catRegiones, setCatRegiones] = useState([{ value: 0, label: 'Todos' }])

  const [catMunicipioFilter, setCatMunicipioFilter] = useState([])
  const [catPoligonosFilter, setCatPoligonosFilter] = useState([])
  const [municipioSelected, setMunicipioSelected] = useState({ value: 0, label: 'Todos' });
  const [regionSelected, setRegionSelected] = useState({ value: 0, label: 'Todos' })
  const [poligonoSelected, setPoligonoSelected] = useState({ value: 0, label: 'Todos' })

  const token = getVars("Token");
  const responsibilityID = token.user.Responsabilidad.value;

  useEffect(() => {
    setLoading(true)
    _setmenu(getDataMenu(41, token.menus))

    let regionesFormated = [];
    let municipioFormated = [];
    let poligonoFormated = [];
    let seccionFormated = [];
    EjecutarApi({}, "getCatalogosFiltrosPromocionClonIdMunicipioReportes", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {
            let regiones = [{ value: 0, label: 'Todos' }]
            let municipios = [{ value: 0, label: 'Todos' }]
            let poligonos = [{ value: 0, label: 'Todos' }]

            regionesFormated = res.data.regiones.map(item => {
              return {
                value: item.value,
                label: item.label
              }
            })
            const result = regionesFormated.filter(reg => reg.label !== 'NO DEFINIDO');
            setCatRegiones(regiones.concat(result))
            //CatMunicipio llenar

            municipioFormated = res.data.municipios.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
              }
            })
            setCatMunicipio(municipios.concat(municipioFormated))

            //CatPoligono llenar

            poligonoFormated = res.data.poligonos.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
                idMunicipio: item.idMunicipio,
              }
            })
            const polg = poligonoFormated.filter(pol => pol.label !== 'NO DEFINIDO');
            setCatPoligonos(poligonos.concat(polg))


            setCatMunicipioFilter(municipios.concat(municipioFormated))
            setCatPoligonosFilter(poligonos.concat(polg))

          } else {
            showMessage("No Info: getCatalogosFiltrosPromocion", '#0E7CF1')
          }
        }
      })
      .catch((e) => {
        showMessage("Error: getCatalogosFiltrosPromocion", "#F1370E")
      }).then(() => {
        setLoading(false);
      })

    EjecutarApi({}, "getResumenEstructuraEstatal", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false)
        setEstatal(res.data);
      })
      .catch((e) => {
        setLoading(false)
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });


  }, []); //loadingTotal, loadingCompromisos

  const showMessage = (message, background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }

  const fitroSend = (e, handle) => {
    setLoading(true)
    var filtro = [];
    if (handle !== "clear") {
      if (e.value > 0 && handle === "region") { filtro.push({ "id": "r.id", "value": e.value }) }
      if (e.value > 0 && handle === "municipio") { filtro.push({ "id": "MA.idMunicipioReportes", "value": e.value }) }
      if (e.value > 0 && handle === "poligono") { filtro.push({ "id": "MA.id", "value": e.value }) }

      if (regionSelected.value > 0 && handle !== "region") { filtro.push({ "id": "r.id", "value": regionSelected.value }) }
      if (municipioSelected.value > 0 && handle !== "municipio") { filtro.push({ "id": "MA.idMunicipioReportes", "value": municipioSelected.value }) }
    }
    if (e === 0 && handle === "") { setFiltro(Filtro) }
    else { setFiltro(filtro); }

    const data = {
      page: 0,
      pageSize: 10,
      sorted: [{ "id": "porcentaje", "desc": true }],
      tipo: 'and',
      filtered: filtro
    };
    setLoading(true)
    EjecutarApi(data, "getAvancesCompromisosPoligono", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setTotal(res.total);
            setEstatalDetalle(
              res.data.map((prop, key) => {
                return {
                  Region: prop.Region,
                  Municipio: prop.Municipio,
                  Poligono: prop.Poligono,
                  meta: prop.meta,
                  Compromisos: prop.CompromisosUnicos,
                  Avance: prop.avanceEsperadoNU,
                  porcentaje: new Intl.NumberFormat().format(prop.porcentaje),
                  data: prop,
                };
              })
            );
            setLoading(false);
          }
        }

      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener getMetaCompromisosGeneral_bcsFiltro: ${e.error}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
  }

  const LimpiarFiltro = () => {
    setRegionSelected({ value: 0, label: 'Todos' })
    setMunicipioSelected({ value: 0, label: 'Todos' })
    setPoligonoSelected({ value: 0, label: 'Todos' })

    fitroSend("", "clear");
  }

  const handleRegiones = (e) => {
    setRegionSelected(e)

    if (e.value > 1) {
      let municipios = [{ value: 0, label: 'Todos' }]
      let poligonos = [{ value: 0, label: 'Todos' }]

      setCatMunicipioFilter(municipios.concat(catMunicipio.filter(data => data.idRegion === e.value)));
      const poligono = []
      catPoligonos.forEach(item => {
        var number = parseInt(item.idRegion);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          poligono.push(temp);
        }
      });
      setCatPoligonosFilter(poligonos.concat(poligono));


      fitroSend(e, "region");

    } else {
      setCatMunicipioFilter(catMunicipio);
      setCatPoligonosFilter(catPoligonos);
    }
  }

  const handleMunicipio = (e) => {
    setPoligonoSelected([{ value: 0, label: 'Todos' }])
    setMunicipioSelected(e)

    if (e.value > 1) {
      let poligonos = [{ value: 0, label: 'Todos' }]

      const poligono = []
      catPoligonos.forEach(item => {
        var number = parseInt(item.idMunicipio);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          poligono.push(temp);
        }
      });
      setCatPoligonosFilter(poligonos.concat(poligono));

      fitroSend(e, "municipio");

    } else {

      setCatMunicipioFilter(catMunicipio);
      setCatPoligonosFilter(catPoligonos);
      fitroSend("", "clear");

    }
  }

  const handlePoligonos = (e) => {
    setPoligonoSelected(e)

    if (e.value < 1) {
      setCatPoligonosFilter(catPoligonos);
    }
    fitroSend(e, "poligono");
  }


  const Exportar = () => {
    setLoading(true)
    var url = new URL(
      `${URL_GENERAL}getReporteAvancePoligonal?token=${token.access_token}`
    );
    window.open(url, "_blank");
    setLoading(false)
  }

  const showPoligono = responsibilityID !== 12 && responsibilityID !== 12 && responsibilityID !== 13

  const showMunicipio = (responsibilityID === 10 || responsibilityID !== 12 || responsibilityID !== 13 || responsibilityID !== 55)


  const showRegion = responsibilityID !== 11 && responsibilityID !== 12 && responsibilityID !== 13 && responsibilityID !== 53 && responsibilityID !== 54 && responsibilityID !== 55


  var columna = []
  const styleHeader = {
    textAlign: "center",
    overflow: "visible",
    fontSize: "11px",
    color: "#363636",
    lineHeight: "normal !important"
  }

  const header = (text) => {
    return (<Label style={styleHeader}>{text}</Label>)
  }

  if (responsibilityID === 53) {
    columna = [
      {
        Header: header("Distrito Federal"),
        minWidth: 30,
        id: "Region",
        accessor: (d) => d.Region,
        filterAll: false,
      },
      {
        Header: header("Municipio"),
        minWidth: 70,
        id: "Municipio",
        accessor: (d) => d.Municipio,
        filterAll: false,
      },
      {
        Header: header("Polígono"),
        minWidth: 40,
        id: "Poligono",
        accessor: (d) => d.Poligono,
        filterAll: false,
      },
      {
        Header: header("Meta"),
        minWidth: 40,
        id: "meta",
        accessor: (d) => d.meta,
        filterAll: false,
      },
      {
        Header: header("Avance Esperado"),
        minWidth: 40,
        id: "Avance",
        accessor: (d) => d.Avance,
        filterAll: false,
      },
      {
        Header: header("Avance Compromisos"),
        minWidth: 40,
        id: "Compromisos",
        accessor: (d) => d.Compromisos,
        filterAll: false,
      },
      {
        Header: header("% Avance Compromiso"),
        minWidth: 40,
        id: "porcentaje",
        accessor: (d) => d.porcentaje,
        filterAll: false,
      },
    ]
  }
  if (responsibilityID === 54) {
    columna = [
      {
        Header: header("Distrito Local"),
        minWidth: 30,
        id: "Region",
        accessor: (d) => d.Region,
        filterAll: false,
      },
      {
        Header: header("Municipio"),
        minWidth: 70,
        id: "Municipio",
        accessor: (d) => d.Municipio,
        filterAll: false,
      },
      {
        Header: header("Polígono"),
        minWidth: 40,
        id: "Poligono",
        accessor: (d) => d.Poligono,
        filterAll: false,
      },
      {
        Header: header("Meta"),
        minWidth: 40,
        id: "meta",
        accessor: (d) => d.meta,
        filterAll: false,
      },
      {
        Header: header("Avance Esperado"),
        minWidth: 40,
        id: "Avance",
        accessor: (d) => d.Avance,
        filterAll: false,
      },
      {
        Header: header("Avance Compromisos"),
        minWidth: 40,
        id: "Compromisos",
        accessor: (d) => d.Compromisos,
        filterAll: false,
      },
      {
        Header: header("% Avance Compromiso"),
        minWidth: 40,
        id: "porcentaje",
        accessor: (d) => d.porcentaje,
        filterAll: false,
      },
    ]
  }
  else {
    columna = [
      {
        Header: header("Region"),
        minWidth: 30,
        id: "Region",
        accessor: (d) => d.Region,
        filterAll: false,
      },
      {
        Header: header("Municipio"),
        minWidth: 70,
        id: "Municipio",
        accessor: (d) => d.Municipio,
        filterAll: false,
      },
      {
        Header: header("Polígono"),
        minWidth: 40,
        id: "Poligono",
        accessor: (d) => d.Poligono,
        filterAll: false,
      },
      {
        Header: header("Meta"),
        minWidth: 40,
        id: "meta",
        accessor: (d) => d.meta,
        filterAll: false,
      },
      {
        Header: header("Avance Esperado"),
        minWidth: 40,
        id: "Avance",
        accessor: (d) => d.Avance,
        filterAll: false,
      },
      {
        Header: header("Avance Compromisos"),
        minWidth: 40,
        id: "Compromisos",
        accessor: (d) => d.Compromisos,
        filterAll: false,
      },
      {
        Header: header("% Avance Compromiso"),
        minWidth: 40,
        id: "porcentaje",
        accessor: (d) => d.porcentaje,
        filterAll: false,
      },
    ]
  }

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Poligonales</p>
                        <CardTitle tag="p">{`${estatal.Poligonos.AvanceEstructura} de ${estatal.Poligonos.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Poligonos.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Seccionales</p>
                        <CardTitle tag="p">{`${estatal.Secciones.AvanceEstructura} de ${estatal.Secciones.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Secciones.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardTitle>
                  <Col>
                    <Row>
                      {
                        showRegion ? (<Col>
                          <FormGroup>
                            <Label for="Estado">
                              Región
                          </Label>
                            <Select
                              options={catRegiones}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              defaultValue={catRegiones[0]}
                              value={regionSelected}
                              onChange={(e) => handleRegiones(e)}
                              id="regiones"
                              placeholder="regiones"
                            />
                          </FormGroup>
                        </Col>) : (<></>)
                      }
                      {
                        showMunicipio ? (
                          <Col>
                            <FormGroup>
                              <Label for="Municipio">
                                Municipios
                           </Label>
                              <Select
                                options={catMunicipioFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={municipioSelected}
                                onChange={(e) => handleMunicipio(e)}
                                id="municipio"
                                placeholder="Municipio"
                              />
                            </FormGroup>
                          </Col>
                        ) : (<></>)
                      }
                      {
                        showPoligono ? (
                          <Col>
                            <FormGroup>
                              <Label for="Estado">
                                Polígonos
                            </Label>
                              <Select
                                options={catPoligonosFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={poligonoSelected}
                                onChange={(e) => handlePoligonos(e)}
                                id="poligonos"
                                placeholder="poligonos"
                              />
                            </FormGroup>
                          </Col>
                        ) : (<></>)
                      }

                    </Row>
                    <Row>
                      <Col lg="12" md="12">
                        <Col sm='6'>
                          <Button name="btnLimpiar" size="sm" type="button" className="success float-left" color='warning' onClick={() => { LimpiarFiltro() }}>Limpiar</Button>
                        </Col>
                        <Col>
                          {_menu.Exportar === 1 ? (
                            <Button color="success" size="sm" className='primary float-right' onClick={() => Exportar()}>
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-lg" />
                              </span>
                              Exportar
                  </Button>
                          ) : <></>}
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </CardTitle>
                {loading && (
                  <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={loading}
                  />
                )}
                <Col md="12" lg="12">
                  <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                    <ReactTable
                      style={{
                        width: "100%",
                        height: "100%"
                      }}
                      data={estatalDetalle}
                      noDataText="Sin información"
                      manual
                      columns={[
                        {
                          Header: <>{responsibilityID == 53 ? ("Distrito Federal") : (responsibilityID == 54 ? ("Distrito Local") : ("Region"))}</>,
                          minWidth: 30,
                          id: "Region",
                          accessor: (d) => d.Region,
                          filterAll: false,
                        },
                        {
                          Header: header("Municipio"),
                          minWidth: 50,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                        },
                        {
                          Header: header("Polígono"),
                          minWidth: 40,
                          id: "Poligono",
                          accessor: (d) => d.Poligono,
                          filterAll: false,
                        },
                        {
                          Header: header("Meta"),
                          minWidth: 40,
                          id: "meta",
                          accessor: (d) => d.meta,
                          filterAll: false,
                        },
                        {
                          Header: header("Avance Esperado"),
                          minWidth: 40,
                          id: "Avance",
                          accessor: (d) => d.Avance,
                          filterAll: false,
                        },
                        {
                          Header: header("Avance Compromisos"),
                          minWidth: 40,
                          id: "Compromisos",
                          accessor: (d) => d.Compromisos,
                          filterAll: false,
                        },
                        {
                          Header: header("% Avance Compromiso"),
                          minWidth: 40,
                          id: "porcentaje",
                          accessor: (d) => d.porcentaje,
                          filterAll: false,
                        },
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={total}
                      pages={Math.floor(total / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      PaginationComponent={Pagination}
                      onFetchData={(stateComp, instance) => {
                        setLoading(true);
                        const data = {
                          page: stateComp.page,
                          pageSize: stateComp.pageSize,
                          sorted: [],
                          tipo: 'and',
                          filtered: Filtro ? Filtro : []
                        };
                        EjecutarApi(data, "getAvancesCompromisosPoligono", token.access_token)
                          .then(function (res) {
                            return res;
                          })
                          .then((res) => {
                            if (res.success) {
                              if (res.results) {
                                setLoading(false);
                                setTotal(res.total)
                                setEstatalDetalle(
                                  res.data.map((prop, key) => {
                                    return {
                                      Region: prop.Region,
                                      Municipio: prop.Municipio,
                                      Poligono: prop.Poligono,
                                      meta: prop.meta,
                                      Compromisos: prop.CompromisosUnicos,
                                      Avance: prop.avanceEsperadoNU,
                                      porcentaje: new Intl.NumberFormat().format(prop.porcentaje),
                                      data: prop,
                                    };
                                  })
                                );

                              }
                            }

                          })
                          .catch((e) => {
                            notify.show(
                              `Error al obtener tabla metas: ${e.message}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );
                          });
                      }}

                    />
                  </div>
                </Col>
              </Card>
            </Col>
          </Row>


        </div>
        <Footer fluid />
      </div>
    </div >
  );
};

export default AvancesCompromisosPoligono;
