import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const TipoAreaChart = (props) => {
  let serie = props.data
    const options = {
      credits: {
         enabled: false
      },
      chart: {
         type: 'column',
         height: '260px'
     },
     title: {
         text: ''
     },
     subtitle: {
         text: ''
     },
     xAxis: {
         categories: serie.categories,
         crosshair: false
     },
     yAxis: {
       visible: true,
         min: 0,
         title: {
             text: ''
         }
     },
     plotOptions: {
         column: {
             pointPadding: 0.2,
             borderWidth: 0,
             dataLabels: {
                 enabled: true
             }
         }
     },
     series: [{
         showInLegend: false,
         name: '',
         data: serie.data
     }]
  }


  return (
      <HighchartsReact
       highcharts={Highcharts}
       options={options}
     />
  )
}

export default TipoAreaChart
