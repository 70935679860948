import React, { useState, useEffect } from "react";
import {
    Row, Col, Input, Button, Label, FormGroup, Card, InputGroup, InputGroupAddon,
    CardBody, CardFooter, CardHeader
} from 'reactstrap';
import Select from "react-select";
import { notify } from "react-notify-toast";

import EjecutarApi from "../../data/getPOST";
import { getVars } from "../../GlobalStorage";
import { CircleSpinner } from "react-spinners-kit";
import useWindowDimensions from "components/useWindowDimensions";

const DiaDInstalacion = ({ menu, btnGuardar, Titulo, isReporto ,Hora}) => {
    const { height, width } = useWindowDimensions();
    const token = getVars("Token");
    //let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [catCasilla, setcatCasilla] = useState([{ value: 0, label: 'Debe validar la sección' }]);
    const [municipio, setMunicipio] = useState('');
    const [catRC, setCatRC] = useState([{ value: 0, label: "Seleccione" }])
    const [isModal, setisModal] = useState(false);
    const [Datos, setDatos] = useState({
        Seccion: '',
        NombreCasilla: '',
        idRG: 0,
        reporto: '',
        hora: '',
        minutos: '',
        anio: '',
        mes: '',
        dia: '',
        segundos: ''
    })
    useEffect(() => {
        var d = new Date();
        var horas = d.getHours();
        var minutos = d.getMinutes();
        var segundos = d.getSeconds();

        if(horas < 10) { horas = '0' + horas; }
        if(minutos < 10) { minutos = '0' + minutos; }
        if(segundos < 10) { segundos = '0' + segundos; }

        setDatos({
            ...Datos,
            hora: `${horas}:${minutos}`,
            minutos: d.getMinutes(),
            anio: d.getFullYear(),
            mes: d.getMonth()+1,
            segundos: d.getSeconds(),
            dia: d.getDate(),
        });
        //"2021-05-01 07:30:00",
    }, [])
    const onblurSeccion = (Seccion) => {
        setLoading(true);
        if(Seccion !== ""){
            EjecutarApi({ "seccion": Seccion }, 'validarSeccionSije', token.access_token)
                .then(function (res) {
                    return res;
                })
                .then((res) => {
                    if (res.results) {
                        setLoading(false);
                        setMunicipio(res.data.map((item) => {
                            return item.MunicipioReporte;
                        }))
                        EjecutarApi({ "seccion": Seccion }, 'getCasillasBySeccion', token.access_token)
                        .then(function (res) {
        
                            return res;
                        })
                        .then((res) => {
                            if (res.success) {
                                setcatCasilla(res.data);
        
                            }
        
                        })
                        .catch((e) => {
                            setLoading(false);
                            notify.show(`${e.error}`, 'error', 5000);
                        });
                    }
                    else {
                        notify.show(res.message, 'error', 5000);
                        setLoading(false);
                    }

                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error en: ${e.error}`, 'error', 5000);
                });
        
            }
        else{
            notify.show(`Hace falta la sección`, 'warning', 5000);
        }
        setLoading(false);
    }

    const conChangeCasilla = (e) => {
        setLoading(true);
        setDatos({ ...Datos,idRG: {value: 0, label:"Seleccione"},NombreCasilla: { value: e.value, label: e.label } })

        var parametro = {
            "Seccion": parseInt(Datos.Seccion),
            "NombreCasilla": e.label,
        };
        EjecutarApi(parametro, 'getRepresentantesCasillas', token.access_token)
            .then(function (res) {

                return res;
            })
            .then((res) => {
                if (res.results) {
                    var dato={};
                    if(!isReporto){
                     let otro= { value: 0, label: "Otro" };
                      dato = res.data.rcs;
                     dato.push(otro);
                    }else{
                     dato = res.data.rcs;
                    }
                    setCatRC(dato);
                }

            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en: ${e.error}`, 'error', 5000);
            });
        setLoading(false);
    }
    const toggleModal = () => setisModal(true)

    const btnCancelar = () => {
        var d = new Date();
        var horas = d.getHours();
        var minutos = d.getMinutes();
        var segundos = d.getSeconds();

        if(horas < 10) { horas = '0' + horas; }
        if(minutos < 10) { minutos = '0' + minutos; }
        if(segundos < 10) { segundos = '0' + segundos; }

        setDatos({
            Seccion: '',
            NombreCasilla: '',
            idRG:{value: null, label:"Seleccione"},
            reporto: '',
            hora: `${horas}:${minutos}`,
            minutos: d.getMinutes(),
            anio: d.getFullYear(),
            mes: d.getMonth(),
            dia: d.getDate(),
            segundos: d.getSeconds()
        });
        setMunicipio('');
        setcatCasilla([{ value: 0, label: 'Debe validar la sección' }]);
        setCatRC([{ value: 0, label: "Seleccione" }]);
    }
    return (
        <>
            <Card style={{ height: height - 118 }}>
                <CardHeader>
                    <h6 className='text-center'>{Titulo}</h6>
                </CardHeader>
                <CardBody style={{ 'width': '100%', 'overflow': 'auto' }}>
                    <Col sm="12" md="12" lg="12">
                        <Row>
                            <FormGroup className="col-md-12">
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                />
                                )}  
                                <Label for="seccion"><span className="text-danger">*</span>Sección:</Label>
                                <Input id="seccion"
                                    maxLength='4' value={Datos.Seccion}
                                    onChange={(e) => { setDatos({ ...Datos, Seccion: e.target.value.replace(/\D/, "") }) }}
                                    onBlur={(e) => onblurSeccion(e.target.value.replace(/\D/, ""))}
                                ></Input>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup className="col-md-12" >
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                )}
                                <Label for="Mpioseccion">Municipio:</Label>
                                <Input id='Mpioseccion' value={municipio} disabled></Input>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup className="col-md-12">
                                <Label for="casilla"><span className="text-danger">*</span>Casilla:</Label>
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                )}
                                <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="casilla"
                                    placeholder="Debe validar la sección"
                                    options={catCasilla}
                                    onChange={(e) => conChangeCasilla(e)}
                                    value={Datos.NombreCasilla}
                                />
                            </FormGroup>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12" >
                                <Label for="casilla"><span className="text-danger">*</span>Reporta (RC/RG):</Label>
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                )}
                                <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="casilla"
                                    placeholder="Debe validar la sección"
                                    value={Datos.idRG}
                                    options={catRC}
                                    onChange={(e) => setDatos({ ...Datos, idRG: { value: e.value, label: e.label } })}
                                />
                            </Col>
                        </Row>
                        {( isReporto || ( Datos.idRG.value === 0 && Datos.idRG.label === "Otro")) && (<>
                            <Row>
                                <FormGroup className="col-md-12">
                                    <Label for="rpt"><span className="text-danger">*</span>Reportó:</Label>
                                    <Input id="rpt"
                                        value={Datos.reporto}
                                        onChange={(e) => { setDatos({ ...Datos, reporto: e.target.value }) }}

                                    ></Input>
                                </FormGroup>
                            </Row>
                        </>)}

                        <Row>
                        <Col sm="6" md="6" lg="12">
                        <Label className="text-muted"> <span className="text-danger">*</span>{Hora}</Label>
                        <Input id='btn' className='text-center' value={Datos.hora} style={{ width: "100pt" }}
                            onChange={(e) => setDatos({ ...Datos, hora: e.target.value })}
                            type="time" />
                         </Col>
                        </Row>

                    </Col>
                    <Row>
                        <FormGroup className="col-md-12 text-center" >
                            <Button size='sm' color='primary'
                                className='primary'
                                onClick={(e) => btnGuardar(Datos)} >Guardar</Button>{" "}
                            <Button size='sm' color='danger' className='danger'
                                onClick={btnCancelar} >Limpiar</Button>
                        </FormGroup>
                    </Row>
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        </>

    );
}
export default DiaDInstalacion;

