import React, { useState,useEffect } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row,Col,Collapse,Input, Label } from 'reactstrap';
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";
import Select from "react-select";
import { MetroSpinner } from 'react-spinners-kit';

function FiltroEnlace ({Menu,Datos}){
  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
	const [filtro,setFiltro] = useState({
    Nombre:'',
    Celular:'',
    Municipio:'',
    Estructura:'',
    nickName:'',
    total:0,
    totalSegmentos:0,
	})
    const [collapse, setCollapse] = useState({
		horizontalTabs: "home",
		verticalTabs: "info",
		pageTabs: "homePages",
		openedCollapses: ["collapseOne", "collapse1"]
    });

    const collapsesToggle = (colapse) => {
		let openedCollapses = collapse.openedCollapses;
		if (openedCollapses.includes(colapse)) {
		  setCollapse({...collapse,
			openedCollapses: openedCollapses.filter(item => item !== colapse)
		  });
		} else {
		  openedCollapses.push(colapse);
		  setCollapse({...collapse, 
			openedCollapses: openedCollapses
		  });
		}
    };
    useEffect(()=>{
      
    },[])
    const setFilter=(event)=>{

      setLoading(true)
        var _filtrar = []
        var aux={}
        if (filtro.nickName.length>0)
        {
          
          _filtrar.push({id:"c.nickName",value:filtro.nickName })
        }
        if (filtro.Celular.length>0)
        {
          _filtrar.push({id:"users.email",value:filtro.Celular})
        
        }
        if (filtro.Nombre.length>0)
        {
          _filtrar.push({"id":"concat_ws(' ',users.Nombre,users.Paterno,users.Materno)","value":filtro.Nombre})
        }
        if (filtro.Municipio.length>0)
        {
          _filtrar.push({"id":"m.Municipio","value":filtro.Municipio})
        }
        if (filtro.total.length>0)
        {
          _filtrar.push({"id":"b.total","value":filtro.total})
        }
        if (filtro.totalSegmentos.length>0)
        {
          _filtrar.push({"id":"a.total","value":filtro.totalSegmentos})
        }

        setLoading(false)
        Datos(_filtrar);

    }

    const ClearFilter=()=>{
      setLoading(true)
        setFiltro({
          Nombre:'',
          Celular:'',
          Municipio:'',
          Estructura:'',
          nickName:'',
          total:0,
          totalSegmentos:0})
        setLoading(false)
    }
    
    return (
        <Card className="card-plain">
            <CardBody>
            <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                role="tablist"
            >
                <Card className="card-plain">
                <CardHeader role="tab">
                    <a
                        aria-expanded={collapse.openedCollapses.includes(
                            "collapse3"
                          )}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle("collapse3")}
                        className="text-right"
                    >
                    <i className="fa fa-filter fa-lg" aria-hidden="true"></i>{" "}
                    Filtro
                    </a>
                </CardHeader>
                <Collapse
                    role="tabpanel"
                    isOpen={collapse.openedCollapses.includes(
                        "collapse3"
                      )}
                >
                    <CardBody>
                    {loading && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                />)}
                      {Menu.Ver &&(<>
                        <Row>
                            <Col sm="4" className="text-muted">Nickname:
                              <Input id="nock" value={filtro.nickName} 
                                  onChange={(e)=>setFiltro({...filtro, nickName:e.target.value.toUpperCase()})} /></Col>            
                            <Col sm="4" className="text-muted">Celular Responsable:
                              <Input id="celulaar" value={filtro.Celular} maxLength='10' 
                                  onChange={(e)=>setFiltro({...filtro,  Celular:e.target.value.toUpperCase()})} /></Col>
                            <Col sm="4" className="text-muted">Enlace: 
                              <Input id="inombre" value={filtro.Nombre} 
                                  onChange={(e)=>setFiltro({...filtro, Nombre:e.target.value.toUpperCase()})} /></Col>
                            <Col sm="4">
                              <Label>Municipio:</Label>
                              <Input id="inombre" value={filtro.Municipio} 
                                  onChange={(e)=>setFiltro({...filtro, Municipio:e.target.value.toUpperCase()})} />
                            </Col>
                            <Col sm="4">
                              <Label>Total Compromisos:</Label>
                              <Input id="inombre" value={filtro.total} 
                                  onChange={(e)=>setFiltro({...filtro, total:e.target.value})} />
                            </Col>
                            <Col sm="4">
                              <Label>Total Segmentos:</Label>
                              <Input id="inombre" value={filtro.totalSegmentos} 
                                  onChange={(e)=>setFiltro({...filtro, totalSegmentos:e.target.value})} />
                            </Col>
                         </Row>
                        </>
                      )}
                    </CardBody>
                    <CardFooter>
                        {loading && <MetroSpinner size={30} color="#0000FF" loading={loading} />}
                        <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e)=>setFilter()}>
                            <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                            Filtrar
                        </Button>{' '}
                        <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e)=> ClearFilter()}>
                            <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                            Limpiar
                        </Button>
                    </CardFooter>
                </Collapse>
                </Card>
            </div>
            </CardBody>
        </Card>
    );
};
export default FiltroEnlace;