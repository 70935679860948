
import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer"
import Sidebar from "../../components/Sidebar";
import { getVars } from '../../GlobalStorage';
import ReactTable from 'react-table';
import { URL_GENERAL } from "../../data/Consts";
import {
    Button, Card, CardHeader, CardBody, Row, Col, TabContent,
    TabPane, NavItem, CardFooter, CardTitle,
    NavLink,
    Nav,
} from 'reactstrap';
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "../../data/getPOST";
import Pagination from "../../components/Pagination/pagination";
import Filtro from "../../components/Estructura/Filtro";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ListadoCompromisos from "../../components/Estructura/ListadoCompromisos";
import NumeraliaGrf from 'components/Charts/NumeraliaMetas/index';
import ListadoResponsables from "../../components/Estructura/ListadoResponsables";
import { getDataMenu } from 'data/menus.js';

const AvanceResponsabilidadPromocion = (props) => {

    // Configuracion
    const [setsidebarMini] = useState(true);
    const [backgroundColor] = useState("black");
    const [activeColor] = useState("info");
    const [estatal, setEstatal] = useState({ Regional: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Municipios: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Poligonos: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Secciones: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 } });

    const [loading, setLoading] = useState(false);
    const [loadingTabla, setLoadingTabla] = useState(false);
    const token = getVars('Token');
    const [horizontalTabs, setHorizontalTabs] = useState({
        horizontalTabs: "Regional",
        openedCollapses: ["collapseOne", "collapse1"]
    })
    const [confirm, setConfirm] = useState(false);
    const [horizontalAnter, setHorizontalAnter] = useState("Regional")

    const [filtro, setFiltro] = useState([]);
    const [sortered, setSortered] = useState([]);
    const [region, setRegion] = useState([]);
    const [municipio, setMunicipio] = useState([]);
    const [poligono, setPoligono] = useState([]);
    const [seccion, setSeccion] = useState([]);
    const [nombre, setNombre] = useState("");
    const [showCompromisos, setShowCompromisos] = useState(false);
    const [showResponsable, setShowResponsable] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [compromisos, setCompromisos] = useState(false);
    const [manzana, setManzana] = useState([]);
    const [reponsables, setResponsables] = useState(false);
    const [Responsable, setResponsable] = useState(0);


    const [total, setTotal] = useState(0);
    const [meta, setMeta] = useState([]);
    const [tabla, setTabla] = useState("region");
    const [titulo, setTitulo] = useState("Región");
    const [grafica, setGrafica] = useState(null);

    // Catalogos

    const [col, setCol] = useState("12");

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    //catalogos

    const [menu, setearMenu] = useState({});
    const [flagTab, setFlagTab] = useState(1);
    const responsibilityID = token.user.Responsabilidad.value;

    useEffect(() => {
        setLoading(true);
        setearMenu(getDataMenu(132, token.menus));

        EjecutarApi({}, "getResumenEstructuraEstatal", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                setLoading(false)
                setEstatal(res.data);
            })
            .catch((e) => {
                setLoading(false)
                notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });

        if (responsibilityID != 99 && responsibilityID !== 11 && responsibilityID !== 12) {
            setTabla("municipio")
            setHorizontalTabs(
                {
                    horizontalTabs: "Municipal",
                    openedCollapses: ["collapseOne", "collapse1"]
                })
        }
        else if (responsibilityID === 11) {
            setTabla("poligono")
            setHorizontalTabs(
                {
                    horizontalTabs: "Poligonal",
                    openedCollapses: ["collapseOne", "collapse1"]
                })
        }
        else if (responsibilityID === 12) {
            setTabla("seccion")
            setHorizontalTabs(
                {
                    horizontalTabs: "Seccional",
                    openedCollapses: ["collapseOne", "collapse1"]
                })
        }
    }, []);

    useEffect(() => {

        if (horizontalTabs.horizontalTabs === "Regional") {
            setFlagTab(1)
        }
        if (horizontalTabs.horizontalTabs === "Municipal") {
            setFlagTab(2)
        }
        if (horizontalTabs.horizontalTabs === "Poligonal") {
            setFlagTab(3)
        }
        if (horizontalTabs.horizontalTabs === "Seccional") {
            setFlagTab(4)
        }
        if (horizontalTabs.horizontalTabs === "Manzanero") {
            setFlagTab(5)
        }
        if (horizontalTabs.horizontalTabs === "MAS") {
            setFlagTab(6)
        }

    }, [horizontalTabs])



    const showMessage = (message, background) => {
        notify.show(message, "custom", 5000, {
            background: background,
            fontSize: 14,
            text: "#FFFFFF",
        });
    }
    const handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            setsidebarMini(false);
        } else {
            setsidebarMini(true);
        }
        document.body.classList.toggle("sidebar-mini");
    };


    // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA



    const setFilter = (filtered, tbla) => {
        setMeta([])
        setLoadingTabla(true)
        setShowResult(true)
        var sorted = [];
        var filtro = [];
        var filtroGrafica = [];
        var region = [];
        var municipio = [];
        var seccion = [];
        var poligono = [];
        var manzana = [];
        var nombre = "";
        var Responsable = 0;

        if (filtered.length !== 0) {
            region = filtered[0]['id'] === "idRegion" ? (filtered[0]['value']) : [];
            municipio = filtered[1]['id'] === "idMunicipio" ? (filtered[1]['value']) : [];
            seccion = filtered[3]['id'] === "S.Seccion" ? (filtered[3]['value']) : [];
            poligono = filtered[2]['id'] === "idPoligono" ? (filtered[2]['value']) : [];
            manzana = filtered[8]['id'] === "idManzana" ? (filtered[8]['value']) : [];
            nombre = filtered[4]['id'] === "Nombre" ? (filtered[4]['value']) : "";
            Responsable = filtered[9]['id'] === "Responsable" ? (filtered[9]['value']) : 0;

            if (filtered[5]['id'] === "u.email" && (filtered[5]['value'] !== undefined && filtered[5]['value'] !== "")) {
                filtro.push(filtered[5])
            }
            if (filtered[6]['id'] === "S.Rentabilidad" && (filtered[6]['value'] !== undefined && filtered[6]['value'] !== "")) {
                filtro.push(filtered[6])
            }
            if (filtered[7]['id'] === "cm.PRIORIDAD" && (filtered[7]['value'] !== undefined && filtered[7]['value'] !== "")) {
                filtro.push(filtered[7])
            }


            setFiltro(filtro)

            setRegion(region)
            setMunicipio(municipio)
            setPoligono(poligono)
            setSeccion(seccion)
            setManzana(manzana)
            setNombre(nombre)
            setResponsable(Responsable)

            if (tabla === "Regional") {
                sorted.push({ "id": "idRegion", "desc": false })

            } else if (tabla === "Municipal") {
                sorted.push({ "id": "idRegion", "desc": false })
                sorted.push({ "id": "idMunicipio", "desc": false })

            }
            else if (tabla === "Poligonal") {
                sorted.push({ "id": "idRegion", "desc": false })
                sorted.push({ "id": "idPoligono", "desc": false })

            }
            else if (tabla === "Seccional") {

                sorted.push({ "id": "idRegion", "desc": false })
                sorted.push({ "id": "Seccion", "desc": false })

            }
            else if (tabla === "Manzanero") {
                sorted.push({ "id": "cm.Manzana", "desc": false })
            }
        }

        setSortered(sorted)

        const data = {
            page: 0,
            pageSize: 10,
            sorted: sorted,
            tipo: 'and',
            filtered: filtro,
            Region: region,
            Municipio: municipio,
            Poligono: poligono,
            Seccion: seccion,
            Manzana: manzana,
            Nombre: nombre,
            Responsable: Responsable,
            tabla: tabla
        };

        const dataEstatal = {

            Region: region,
            Municipio: municipio,
            Poligono: poligono,
            Seccion: seccion,
            tipo: 'and'
        };

        EjecutarApi(dataEstatal, "getResumenEstructuraEstatal", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                setLoading(false)
                setEstatal(res.data);
            })
            .catch((e) => {
                setLoading(false)
                notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });



        EjecutarApi(data, "getCompromisoAvancePromocion", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success) {
                    if (res.results) {
                        setLoadingTabla(false);
                        setTotal(res.total);
                        setMeta(
                            res.data.map((prop, key) => {
                                var porcentaje = parseFloat(prop.porcentaje)
                                var ddporcentaje = porcentaje.toFixed(2)
                                if (tbla) {
                                    var propTabla = (tbla === "region") ? (prop.Region) : ((tbla === "municipio") ? (prop.Municipio) : ((tbla === "poligono") ? (prop.Poligono) : ((tbla === "seccion") ? (prop.Seccion) : ((tbla === "manzana") ? (prop.manzana) : (" ")))))
                                    var titulo = (tbla === "region") ? ("Región") : ((tbla === "municipio") ? ("Municipio") : ((tbla === "poligono") ? ("Polígono") : ((tbla === "seccion") ? ("Sección") : ((tbla === "manzana") ? ("Manzana") : (" ")))))
                                } else {

                                    var propTabla = (tabla === "region") ? (prop.Region) : ((tabla === "municipio") ? (prop.Municipio) : ((tabla === "poligono") ? (prop.Poligono) : ((tabla === "seccion") ? (prop.Seccion) : ((tabla === "manzana") ? (prop.manzana) : (" ")))))
                                    var titulo = (tabla === "region") ? ("Región") : ((tabla === "municipio") ? ("Municipio") : ((tabla === "poligono") ? ("Polígono") : ((tabla === "seccion") ? ("Sección") : ((tabla === "manzana") ? ("Manzana") : (" ")))))
                                }

                                return {
                                    id: <>{responsibilityID == 53 ? prop.id : prop.id}</>,
                                    Municipio: <>{responsibilityID == 53 ? (prop.Municipio ? prop.Municipio : "Sin Municipio") : (prop.Municipio ? prop.Municipio : "Sin Municipio")} </>,
                                    Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                                    Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                    Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                                    Manzana: prop.manzana ? prop.manzana : 'Sin Manzana',
                                    meta: prop.MetaEstructura,
                                    Prioridad: prop.Prioridad,
                                    Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                                    metaCompromisos: prop.MetaCompromisos,
                                    AvanceCompromisos: prop.Compromisos > 0 ? (<><h6>{prop.Compromisos}</h6><Button onClick={() => onClickShowCompromisos(prop, titulo, propTabla)} className="btn-round" size="sm" color="primary">Ver</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                    AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                                    AvanceEstructura: prop.AvanceEstructura > 0 ? (<><h6>{prop.AvanceEstructura}</h6><Button onClick={() => onClickShowResponsables(prop, titulo, propTabla)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceEstructura ? prop.AvanceEstructura : 0),
                                    PorcentajeEstructura: prop.PorcentajeEstructura ? prop.PorcentajeEstructura : 0,
                                    porcentaje: ddporcentaje ? ddporcentaje : 0,
                                    data: prop,
                                };
                            })
                        );

                        var categorias = res.grafica.map((prop, key) => {
                            //return prop.Seccion;

                            if (tbla) {
                                return (tbla === "region") ? (prop.Region) : ((tbla === "municipio") ? (prop.Municipio) : ((tbla === "poligono") ? (prop.Poligono) : ((tbla === "seccion") ? (prop.Seccion) : ((tbla === "manzana") ? (prop.manzana) : (" ")))))
                            } else {

                                return (tabla === "region") ? (prop.Region) : ((tabla === "municipio") ? (prop.Municipio) : ((tabla === "poligono") ? (prop.Poligono) : ((tabla === "seccion") ? (prop.Seccion) : ((tabla === "manzana") ? (prop.manzana) : (" ")))))

                            }
                        })

                        var Meta = res.grafica.map((prop, key) => {
                            return prop.MetaEstructura;
                        })

                        var Avance = res.grafica.map((prop, key) => {
                            return prop.AvanceEstructura;
                        })

                        /* } */


                        const data = {

                            title: `Avance ${horizontalTabs.horizontalTabs}`,
                            xAxis: {
                                categories: categorias,
                                title: {
                                    text: titulo
                                }

                            },
                            yAxis: [{
                                min: 0,
                                title: {
                                    text: `Avance de ${titulo}`
                                }
                            }],
                        };

                        const series = [
                            {
                                name: 'Meta',
                                color: 'rgba(147,220,236,.9)',
                                data: Meta,
                                pointPadding: 0.3,
                                pointPlacement: -0.2
                            }, {
                                name: 'Avance',
                                color: '#0041A0',
                                data: Avance,
                                pointPadding: 0.4,
                                pointPlacement: -0.2
                            }];
                        setGrafica({
                            data: data,
                            series: series
                        })

                    }
                }
            })
            .catch((e) => {
                setLoadingTabla(false);
                notify.show(
                    `Error al obtener getResponsablesCompromisos: ${e.message}`,
                    "custom",
                    5000,
                    {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    }
                );
            });



        setLoadingTabla(true)
        setLoading(true)
    }

    const Limpiar = (filtered) => {
        setShowResult(false)

        setFiltro([]);
        setRegion([]);
        setMunicipio([]);
        setPoligono([]);
        setSeccion([]);
        setNombre("");
        setResponsable(0);
        if (horizontalTabs.horizontalTabs === "MAS") {
            changeTap("Regional")
        }
        else {
            changeTap(horizontalAnter)
        }
    }


    const changeTap = (e) => {
        setMeta([])
        setLoadingTabla(true);
        setHorizontalAnter(horizontalTabs.horizontalTabs)
        setHorizontalTabs({ ...horizontalTabs, horizontalTabs: e })

        if (horizontalTabs.horizontalTabs === "MAS" || horizontalAnter === "MAS") {
            setShowResult(false)
            setFiltro([]);
            setRegion([]);
            setMunicipio([]);
            setPoligono([]);
            setSeccion([]);
            setManzana([]);
            setNombre("");
            setResponsable(0);
        }
        if ((horizontalTabs.horizontalTabs === "Manzanero" || horizontalTabs.horizontalTabs === "Municipal" || horizontalTabs.horizontalTabs === "Poligonal" || horizontalTabs.horizontalTabs === "Seccional") && (e === "Regional")) {
            setMunicipio([]);
            setPoligono([]);
            setSeccion([]);
            setManzana([]);
            var filtrado = filtro.filter(fil => (fil.id !== 'cm.PRIORIDAD'))
            var filtrados = filtrado.filter(fil => (fil.id !== 'S.Rentabilidad'))
            setFiltro(filtrados)
        }
        else if ((horizontalTabs.horizontalTabs === "Manzanero") && (horizontalTabs.horizontalTabs === "Poligonal") || (horizontalTabs.horizontalTabs === "Seccional") && (e === "Municipal")) {
            setPoligono([]);
            setSeccion([]);
            setManzana([]);
            var filtrado = filtro.filter(fil => (fil.id !== 'cm.PRIORIDAD'))
            var filtrados = filtrado.filter(fil => (fil.id !== 'S.Rentabilidad'))
            setFiltro(filtrados)
        }
        else if ((horizontalTabs.horizontalTabs === "Manzanero" || horizontalTabs.horizontalTabs === "Seccional") && (e === "Poligonal")) {

            setSeccion([]);
            setManzana([]);
            var filtrado = filtro.filter(fil => (fil.id !== 'cm.PRIORIDAD'))
            var filtrados = filtrado.filter(fil => (fil.id !== 'S.Rentabilidad'))
            setFiltro(filtrados)
        }
        else if ((horizontalTabs.horizontalTabs === "Manzanero") && (e === "Seccional")) {

            var filtrado = filtro.filter(fil => fil.id !== 'cm.PRIORIDAD')
            setFiltro(filtrado)
            setManzana([]);
        }
        else if ((horizontalAnter === e)) {
            setShowResult(false)
            setFiltro([]);
            setRegion([]);
            setMunicipio([]);
            setPoligono([]);
            setSeccion([]);
            setManzana([]);
            setNombre("");
            setResponsable(0)
            setFlagTab(-1);
        }
        const tbla = (e === "Regional") ? "region" : (e === "Municipal") ? "municipio" : (e === "Poligonal") ? "poligono" : (e === "Seccional") ? "seccion" : (e === "Manzanero") ? "manzana" : ""
        setTabla(tbla)
        const titulo = (e === "Regional") ? "Región" : (e === "Municipal") ? "Municipio" : (e === "Poligonal") ? "Polígono" : (e === "Seccional") ? "Sección" : (e === "Manzanero") ? "manzana" : ""
        setTitulo(titulo)
        setCol("12")
        setLoadingTabla(false)

        //setFilter([], tbla);
    }

    const onClickShowCompromisos = (props, value, label) => {
        const id = (responsibilityID == 53) ? props.id : props.id

        setShowCompromisos(true);
        setCompromisos({
            value: value,
            zona: tabla,
            id: id,
            Label: label,
            responsibilityID: responsibilityID
        });
    }

    const onClickShowResponsables = (props, value, zona) => {
        setShowResponsable(true);
        const id = (responsibilityID == 53) ? props.id : props.id
        setResponsables({
            responsibilityID: responsibilityID,
            value: value,
            id: id,
            label: zona,
            zona: tabla
        });
    }

    const confirmMessage = (

        <ReactBSAlert
            warning
            title="Necesita Seleccionar un Municipio para poder exportar"
            onConfirm={() => setConfirm(false)}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Ok!"
        >
        </ReactBSAlert>
    );

    const Exportar = () => {

        const data = {
            page: 0,
            pageSize: 10,
            tipo: 'and',
            sorted: sortered,
            filtered: filtro,
            Region: region,
            Municipio: municipio,
            Poligono: poligono,
            Seccion: seccion,
            Manzana: manzana,
            Nombre: nombre,
            tabla: tabla,
            Export: true
        };

        if (tabla === "manzana") {

            if (municipio.length !== 0) {
                var datos = JSON.stringify(data)
                var url = new URL(
                    `${URL_GENERAL}exportCompromisoAvancePromocion?token=${token.access_token}&serializar=${datos}`
                );
                window.open(url, "_blank");
            }
            else {
                setConfirm(true)
            }

        } else {
            var datos = JSON.stringify(data)
            var url = new URL(
                `${URL_GENERAL}exportCompromisoAvancePromocion?token=${token.access_token}&serializar=${datos}`
            );
            window.open(url, "_blank");
        }

    }


    const ExportarResponsables = () => {

        const data = {
            page: 0,
            pageSize: 10,
            tipo: 'and',
            filtered: filtro,
            Region: region,
            Municipio: municipio,
            Poligono: poligono,
            Seccion: seccion,
            Manzana: manzana,
            Nombre: nombre,
            tabla: tabla,
            Export: true
        };


        var datos = JSON.stringify(data)
        var url = new URL(
            `${URL_GENERAL}ExportarResponsablesCompromisos?token=${token.access_token}&serializar=${datos}`
        );
        window.open(url, "_blank");


    }

    const onHide = () => {
        setShowCompromisos(false);
        setShowResponsable(false);

        setFiltro([]);

        setRegion([]);
        setMunicipio([]);
        setPoligono([]);
        setSeccion([]);
        setManzana([]);
        setResponsable(0);
        setNombre("");
    };
    //Estructura

    const showMunicipioEstructura = (responsibilityID !== 11 && responsibilityID !== 12 && responsibilityID !== 13)
    const showNavRegional = (responsibilityID !== 10 && responsibilityID !== 11 && responsibilityID !== 12 && responsibilityID !== 13 && responsibilityID !== 53 && responsibilityID !== 54 && responsibilityID !== 55)
    const showPoligonoEstructura = (responsibilityID !== 12 && responsibilityID !== 13)


    return (
        <div className="wrapper">
            <Notifications />
            <Sidebar
                {...props}
                menus={token.menus}
                bgColor={backgroundColor}
                activeColor={activeColor}
            />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
                <div className="content">

                    <div className="content">
                        {/* Fechas Compromiso*/}
                        {/* Configuracón Estructura Metas */}
                        {(!showCompromisos && !showResponsable) &&
                            <div>
                                <Row>
                                    {showNavRegional &&
                                        <Col lg="3" md="6" sm="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="4" xs="5">
                                                            <div className="icon-big text-center icon-warning">
                                                                <i className="fa fa-users text-success" />
                                                            </div>
                                                        </Col>
                                                        <Col md="8" xs="7">
                                                            <div className="numbers">
                                                                <p className="card-category">Regionales</p>
                                                                <CardTitle tag="p">{`${estatal.Regional.AvanceEstructura} de ${estatal.Regional.MetaEstructuraPartido}`}</CardTitle>
                                                                <p />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter>
                                                    <hr />
                                                    <div className="stats">
                                                        <i className="fa fa-calendar-o" />
                                                        {`${new Intl.NumberFormat().format(estatal.Regional.AvanceCompromisos)}`}
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    }
                                    {showMunicipioEstructura &&
                                        <Col lg="3" md="6" sm="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="4" xs="5">
                                                            <div className="icon-big text-center icon-warning">
                                                                <i className="fa fa-users text-success" />
                                                            </div>
                                                        </Col>
                                                        <Col md="8" xs="7">
                                                            <div className="numbers">
                                                                <p className="card-category">Municipales</p>
                                                                <CardTitle tag="p">{`${estatal.Municipios.AvanceEstructura} de ${estatal.Municipios.MetaEstructuraPartido}`}</CardTitle>
                                                                <p />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter>
                                                    <hr />
                                                    <div className="stats">
                                                        <i className="fa fa-calendar-o" />
                                                        {`${new Intl.NumberFormat().format(estatal.Municipios.AvanceCompromisos)}`}
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                    }
                                    {showPoligonoEstructura &&
                                        <Col lg="3" md="6" sm="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="4" xs="5">
                                                            <div className="icon-big text-center icon-warning">
                                                                <i className="fa fa-users text-success" />
                                                            </div>
                                                        </Col>
                                                        <Col md="8" xs="7">
                                                            <div className="numbers">
                                                                <p className="card-category">Poligonales</p>
                                                                <CardTitle tag="p">{`${estatal.Poligonos.AvanceEstructura} de ${estatal.Poligonos.MetaEstructuraPartido}`}</CardTitle>
                                                                <p />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter>
                                                    <hr />
                                                    <div className="stats">
                                                        <i className="fa fa-calendar-o" />
                                                        {`${new Intl.NumberFormat().format(estatal.Poligonos.AvanceCompromisos)}`}
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Col>

                                    }

                                    <Col lg="3" md="6" sm="6">
                                        <Card className="card-stats">
                                            <CardBody>
                                                <Row>
                                                    <Col md="4" xs="5">
                                                        <div className="icon-big text-center icon-warning">
                                                            <i className="fa fa-users text-success" />
                                                        </div>
                                                    </Col>
                                                    <Col md="8" xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">Seccionales</p>
                                                            <CardTitle tag="p">{`${estatal.Secciones.AvanceEstructura} de ${estatal.Secciones.MetaEstructuraPartido}`}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <hr />
                                                <div className="stats">
                                                    <i className="fa fa-calendar-o" />
                                                    {`${new Intl.NumberFormat().format(estatal.Secciones.AvanceCompromisos)}`}
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    {grafica &&
                                        <Col sm="12">
                                            <NumeraliaGrf datos={grafica.series} Titulos={grafica.data} />
                                        </Col>
                                    }
                                    <Col sm={col}>
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    {(menu.Exportar === 1) ? (
                                                        <Col sm='8'>
                                                            <Button name="btnExportar" size='sm' type="button" className='primary float-left' color='primary' onClick={() => Exportar()}>
                                                                <span className="btn-label">
                                                                    <i className="fa fa-file-excel-o fa-lg" />
                                                                </span>
                                                                    Exportar
                                                            </Button>
                                                            <Button name="btnExportar" size='sm' type="button" className='primary float-left' color='primary' onClick={() => ExportarResponsables()}>
                                                                <span className="btn-label">
                                                                    <i className="fa fa-file-excel-o fa-lg" />
                                                                </span>
                                                                    Exportar Listado Responsables
                                                            </Button>
                                                        </Col>
                                                    ) : <></>}
                                                </Row>
                                                <Filtro filtrar={setFilter} limpiar={Limpiar} horizontalTabs={horizontalTabs.horizontalTabs} responsibilityID={responsibilityID} horizontalAnter={horizontalAnter} ></Filtro>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="nav-tabs-navigation">
                                                    <div className="nav-tabs-wrapper">
                                                        <Nav id="tabs" role="tablist" tabs>
                                                            {showNavRegional &&
                                                                <NavItem>
                                                                    <NavLink
                                                                        aria-expanded={horizontalTabs.horizontalTabs === "Regional"}
                                                                        data-toggle="tab"
                                                                        role="tab"
                                                                        className={horizontalTabs.horizontalTabs === "Regional"
                                                                            ? "active"
                                                                            : ""
                                                                        }
                                                                        onClick={() => changeTap("Regional")}
                                                                    >
                                                                        Regional
              </NavLink>
                                                                </NavItem>
                                                            }
                                                            {showMunicipioEstructura &&
                                                                <NavItem>
                                                                    <NavLink
                                                                        aria-expanded={horizontalTabs.horizontalTabs === "Municipal"
                                                                        }
                                                                        data-toggle="tab"
                                                                        role="tab"
                                                                        className={horizontalTabs.horizontalTabs === "Municipal"
                                                                            ? "active"
                                                                            : ""
                                                                        }
                                                                        onClick={() => changeTap("Municipal")}
                                                                    >
                                                                        Municipal
                </NavLink>
                                                                </NavItem>
                                                            }
                                                            {showPoligonoEstructura &&
                                                                <NavItem>
                                                                    <NavLink
                                                                        aria-expanded={horizontalTabs.horizontalTabs === "Poligonal"}
                                                                        data-toggle="tab"
                                                                        //href="#pablo"
                                                                        role="tab"
                                                                        className={horizontalTabs.horizontalTabs === "Poligonal"
                                                                            ? "active"
                                                                            : ""
                                                                        }
                                                                        onClick={() =>
                                                                            changeTap("Poligonal")}
                                                                    >
                                                                        Poligonal
                </NavLink>
                                                                </NavItem>
                                                            }
                                                            <NavItem>
                                                                <NavLink
                                                                    aria-expanded={horizontalTabs.horizontalTabs === "Seccional"}
                                                                    data-toggle="tab"
                                                                    //href="#pablo"
                                                                    role="tab"
                                                                    className={horizontalTabs.horizontalTabs === "Seccional"
                                                                        ? "active"
                                                                        : ""
                                                                    }
                                                                    onClick={() =>
                                                                        changeTap("Seccional")}
                                                                >
                                                                    Seccional
                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink
                                                                    aria-expanded={horizontalTabs.horizontalTabs === "Manzanero"}
                                                                    data-toggle="tab"
                                                                    //href="#pablo"
                                                                    role="tab"
                                                                    className={horizontalTabs.horizontalTabs === "Manzanero"
                                                                        ? "active"
                                                                        : ""
                                                                    }
                                                                    onClick={() =>
                                                                        changeTap("Manzanero")}
                                                                >
                                                                    Manzanero
                </NavLink>
                                                            </NavItem>
                                                            {
                                                                showResult &&
                                                                <NavItem>
                                                                    <NavLink
                                                                        aria-expanded={horizontalTabs.horizontalTabs === "MAS"}
                                                                        data-toggle="tab"
                                                                        role="tab"
                                                                        className={horizontalTabs.horizontalTabs === "MAS"
                                                                            ? ("active", "text-primary")
                                                                            : ("", "text-primary")
                                                                        }
                                                                        onClick={() =>
                                                                            changeTap("MAS")}
                                                                    >
                                                                        Ver Más Resultados
                                                            </NavLink>
                                                                </NavItem>
                                                            }
                                                        </Nav>
                                                    </div>
                                                </div>
                                                <TabContent
                                                    className="text-center"
                                                    id="my-tab-content"
                                                    activeTab={horizontalTabs.horizontalTabs}
                                                >
                                                    {showNavRegional &&
                                                        <TabPane tabId="Regional" role="tabpanel">
                                                            {/* {horizontalTabs.horizontalTabs === "Regional" ? */}
                                                            {flagTab === 1 ?
                                                                <ReactTable
                                                                    size='sm'
                                                                    data={meta}
                                                                    noDataText="Sin información"
                                                                    manual
                                                                    columns={[
                                                                        {
                                                                            Header: "Region",
                                                                            minWidth: 20,
                                                                            id: "Region",
                                                                            accessor: (d) => d.Region,
                                                                            filterAll: false,
                                                                            headerStyle: { fontSize: '12px', align: "center" },
                                                                            style: { fontSize: '12px' },
                                                                        },
                                                                        {
                                                                            Header: "Meta Estructura",
                                                                            accessor: (d) => d.meta,
                                                                            minWidth: 40,
                                                                            id: "meta",
                                                                            filterable: false,
                                                                            headerStyle: { fontSize: '12px', align: "center" },
                                                                            style: { fontSize: '12px' },
                                                                        },
                                                                        {
                                                                            Header: "A.Estructura",
                                                                            accessor: (d) => d.AvanceEstructura,
                                                                            minWidth: 40,
                                                                            id: "AvanceEstructura",
                                                                            filterable: false,
                                                                            headerStyle: { fontSize: '12px', align: "center" },
                                                                            style: { fontSize: '12px' },
                                                                        },
                                                                        {
                                                                            Header: "% A.Estructura",
                                                                            accessor: (d) => d.PoncentajeEstructura,
                                                                            Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                                                            minWidth: 40,
                                                                            id: "PoncentajeEstructura",
                                                                            filterable: false,
                                                                            headerStyle: { fontSize: '12px', align: "center" },
                                                                            style: { fontSize: '12px' },
                                                                        },
                                                                        {
                                                                            Header: "Meta Compromisos",
                                                                            accessor: (d) => d.metaCompromisos,
                                                                            minWidth: 40,
                                                                            id: "metaCompromisos",
                                                                            filterable: false,
                                                                            headerStyle: { fontSize: '12px', align: "center" },
                                                                            style: { fontSize: '12px' },
                                                                        },

                                                                        {
                                                                            Header: "A.Esperado",
                                                                            accessor: (d) => d.Esperada,
                                                                            minWidth: 40,
                                                                            id: "esperada",
                                                                            filterable: false,
                                                                            headerStyle: { fontSize: '12px', align: "center" },
                                                                            style: { fontSize: '12px' },
                                                                        },
                                                                        {
                                                                            Header: "A.Compromisos",
                                                                            accessor: (d) => d.AvanceCompromisos,
                                                                            minWidth: 40,
                                                                            id: "AvanceCompromisos",
                                                                            filterable: false,
                                                                            headerStyle: { fontSize: '12px', align: "center" },
                                                                            style: { fontSize: '12px' },
                                                                        },
                                                                        {
                                                                            Header: "% A.Compromisos",
                                                                            accessor: (d) => d.AvanceEsperado,
                                                                            Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                                                            minWidth: 40,
                                                                            id: "AvanceEsperado",
                                                                            filterable: false,
                                                                            headerStyle: { fontSize: '12px', align: "center" },
                                                                            style: { fontSize: '12px' },
                                                                        },
                                                                    ]}
                                                                    className="-striped -highlight primary-pagination"
                                                                    loading={loadingTabla}
                                                                    showPagination={true}
                                                                    showPaginationBottom={true}
                                                                    showTotalPages={true}
                                                                    showPageJump={false}
                                                                    canNextFromData={true}
                                                                    defaultPageSize={10}
                                                                    total={total}
                                                                    pages={Math.floor(total / 10)}
                                                                    previousText="Anterior"
                                                                    nextText="Siguiente"
                                                                    ofText="de"
                                                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                                                    PaginationComponent={Pagination}
                                                                    onFetchData={(stateComp, instance) => {
                                                                        setLoadingTabla(true);
                                                                        const data = {
                                                                            page: stateComp.page,
                                                                            pageSize: stateComp.pageSize,
                                                                            sorted: [{ "id": "r.id", "desc": false }],
                                                                            tipo: 'and',
                                                                            filtered: filtro ? filtro : [],
                                                                            Region: region ? region : [],
                                                                            Municipio: municipio ? municipio : [],
                                                                            Poligono: poligono ? poligono : [],
                                                                            Seccion: seccion ? seccion : [],
                                                                            Nombre: nombre ? nombre : "",
                                                                            Responsable: Responsable ? Responsable : 0,
                                                                            tabla: "region"
                                                                        };

                                                                        EjecutarApi(data, "getCompromisoAvancePromocion", token.access_token)
                                                                            .then(function (res) {
                                                                                return res;
                                                                            })
                                                                            .then((res) => {
                                                                                setLoadingTabla(false);
                                                                                setTotal(res.total);
                                                                                setMeta(
                                                                                    res.data.map((prop, key) => {
                                                                                        var porcentaje = parseFloat(prop.porcentaje)
                                                                                        var ddporcentaje = porcentaje.toFixed(2)
                                                                                        return {
                                                                                            id: prop.id,
                                                                                            Region: prop.Region ? prop.Region : "Sin Region",
                                                                                            meta: prop.MetaEstructura,
                                                                                            Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                                                                                            cel: prop.ResponsableCel,
                                                                                            Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                                                                                            metaCompromisos: prop.MetaCompromisos,
                                                                                            AvanceCompromisos: prop.Compromisos > 0 ? (<><h6>{prop.Compromisos}</h6><Button onClick={() => onClickShowCompromisos(prop, "Región", prop.Region)} className="btn-round" size="sm" color="primary">Ver</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                                                                            AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                                                                                            AvanceEstructura: prop.AvanceEstructura > 0 ? (<><h6>{prop.AvanceEstructura}</h6><Button onClick={() => onClickShowResponsables(prop, "Región", prop.Region)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceEstructura ? prop.AvanceEstructura : 0),
                                                                                            PoncentajeEstructura: (prop.PorcentajeEstructura > 0) ? prop.PorcentajeEstructura : 0,
                                                                                            porcentaje: ddporcentaje ? ddporcentaje : 0,
                                                                                            data: prop,
                                                                                        };
                                                                                    })
                                                                                );

                                                                                var categorias = res.data.map((prop, key) => {
                                                                                    return prop.Region;
                                                                                })

                                                                                var Meta = res.data.map((prop, key) => {
                                                                                    return prop.MetaEstructura;
                                                                                })

                                                                                var Avance = res.data.map((prop, key) => {
                                                                                    return prop.AvanceEstructura;
                                                                                })

                                                                                const data = {

                                                                                    title: `Avance ${horizontalTabs.horizontalTabs}`,
                                                                                    xAxis: {
                                                                                        categories: categorias,
                                                                                        title: {
                                                                                            text: titulo
                                                                                        }
                                                                                    },
                                                                                    yAxis: [{
                                                                                        min: 0,
                                                                                        title: {
                                                                                            text: `Avance de ${titulo}`
                                                                                        }
                                                                                    }],
                                                                                };

                                                                                const series = [
                                                                                    {
                                                                                        name: 'Meta',
                                                                                        color: 'rgba(147,220,236,.9)',
                                                                                        data: Meta,
                                                                                        pointPadding: 0.3,
                                                                                        pointPlacement: -0.2
                                                                                    }, {
                                                                                        name: 'Avance',
                                                                                        color: '#0041A0',
                                                                                        data: Avance,
                                                                                        pointPadding: 0.4,
                                                                                        pointPlacement: -0.2
                                                                                    }];

                                                                                setGrafica({
                                                                                    data: data,
                                                                                    series: series
                                                                                })
                                                                            })
                                                                            .catch((e) => {
                                                                                setLoadingTabla(false);
                                                                                notify.show(
                                                                                    `Error al obtener tabla metas estructura region: ${e.message}`,
                                                                                    "custom",
                                                                                    5000,
                                                                                    {
                                                                                        background: "#0857B6",
                                                                                        fontSize: 14,
                                                                                        text: "#FFFFFF",
                                                                                    }
                                                                                );
                                                                            });
                                                                        const dataEstatal = {

                                                                            Region: region,
                                                                            Municipio: municipio,
                                                                            Poligono: poligono,
                                                                            Seccion: seccion,
                                                                            tipo: 'and'
                                                                        };
                                                                        EjecutarApi(dataEstatal, "getResumenEstructuraEstatal", token.access_token)
                                                                            .then(function (res) {
                                                                                return res;
                                                                            })
                                                                            .then((res) => {
                                                                                setLoading(false)
                                                                                setEstatal(res.data);
                                                                            })
                                                                            .catch((e) => {
                                                                                setLoading(false)
                                                                                notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                                                                                    background: "#0857B6",
                                                                                    fontSize: 14,
                                                                                    text: "#FFFFFF",
                                                                                });
                                                                            });

                                                                    }}
                                                                /> : <></>}: <></>
                                                        </TabPane>
                                                    }
                                                    <TabPane tabId="Municipal" role="tabpanel">
                                                        {/* {horizontalTabs.horizontalTabs === "Municipal" ? */}
                                                        {flagTab === 2 ?
                                                            <ReactTable
                                                                size='sm'
                                                                data={meta}
                                                                noDataText="Sin información"
                                                                manual
                                                                columns={[
                                                                    {
                                                                        Header: <>{responsibilityID == 53 ? ("Distrito Federal") : (responsibilityID == 54 ? ("Distrito Local") : ("Region"))}</>,
                                                                        minWidth: 20,
                                                                        id: "Region",
                                                                        accessor: (d) => d.Region,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Municipio",
                                                                        minWidth: 40,
                                                                        id: "Municipio",
                                                                        accessor: (d) => d.Municipio,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Meta Estructura",
                                                                        accessor: (d) => d.meta,
                                                                        minWidth: 40,
                                                                        id: "meta",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "A.Estructura",
                                                                        accessor: (d) => d.AvanceEstructura,
                                                                        minWidth: 40,
                                                                        id: "AvanceEstructura",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    }, {
                                                                        Header: "% A.Estructura",
                                                                        accessor: (d) => d.PorcentajeEstructura,
                                                                        Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                                                        minWidth: 40,
                                                                        id: "PoncentajeEstructura",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Meta Compromisos",
                                                                        accessor: (d) => d.metaCompromisos,
                                                                        minWidth: 40,
                                                                        id: "metaCompromisos",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },

                                                                    {
                                                                        Header: "A.Esperado",
                                                                        accessor: (d) => d.Esperada,
                                                                        minWidth: 40,
                                                                        id: "esperada",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "A.Compromisos",
                                                                        accessor: (d) => d.AvanceCompromisos,
                                                                        minWidth: 40,
                                                                        id: "AvanceCompromisos",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "% A.Compromisos",
                                                                        accessor: (d) => d.AvanceEsperado,
                                                                        Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                                                        minWidth: 40,
                                                                        id: "AvanceEsperado",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                ]}

                                                                className="-striped -highlight primary-pagination"
                                                                loading={loadingTabla}
                                                                showPagination={true}
                                                                showPaginationBottom={true}
                                                                showTotalPages={true}
                                                                showPageJump={false}
                                                                canNextFromData={true}
                                                                defaultPageSize={10}
                                                                total={total}
                                                                pages={Math.floor(total / 10)}
                                                                previousText="Anterior"
                                                                nextText="Siguiente"
                                                                ofText="de"
                                                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                                                PaginationComponent={Pagination}
                                                                onFetchData={(stateComp, instance) => {
                                                                    setLoadingTabla(true);
                                                                    const data = {
                                                                        page: stateComp.page,
                                                                        pageSize: stateComp.pageSize,
                                                                        sorted: [{ "id": "S.idMunicipio", "desc": false }],
                                                                        filtered: filtro ? filtro : [],
                                                                        Region: region ? region : [],
                                                                        Municipio: municipio ? municipio : [],
                                                                        Poligono: poligono ? poligono : [],
                                                                        Seccion: seccion ? seccion : [],
                                                                        Nombre: nombre ? nombre : "",
                                                                        Responsable: Responsable ? Responsable : 0,
                                                                        tipo: 'and',
                                                                        tabla: "municipio"
                                                                    };
                                                                    const dataEstatal = {

                                                                        Region: region,
                                                                        Municipio: municipio,
                                                                        Poligono: poligono,
                                                                        Seccion: seccion,
                                                                        tipo: 'and'
                                                                    };
                                                                    EjecutarApi(data, "getCompromisoAvancePromocion", token.access_token)
                                                                        .then(function (res) {
                                                                            return res;
                                                                        })
                                                                        .then((res) => {
                                                                            setLoadingTabla(false);
                                                                            setTotal(res.total);
                                                                            setMeta(
                                                                                res.data.map((prop, key) => {
                                                                                    var porcentaje = parseFloat(prop.porcentaje)
                                                                                    var ddporcentaje = porcentaje.toFixed(2)
                                                                                    return {
                                                                                        id: <>{responsibilityID == 53 ? prop.id : prop.id}</>,
                                                                                        Municipio: <>{responsibilityID == 53 ? (prop.Municipio ? prop.Municipio : "Sin Municipio") : (prop.Municipio ? prop.Municipio : "Sin Municipio")} </>,
                                                                                        Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                                                                                        meta: prop.MetaEstructura,
                                                                                        Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                                                                                        cel: prop.ResponsableCel,
                                                                                        Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                                                                                        metaCompromisos: prop.MetaCompromisos,
                                                                                        AvanceCompromisos: prop.Compromisos > 0 ? (<><h6>{prop.Compromisos}</h6><Button onClick={() => onClickShowCompromisos(prop, "Municipio", prop.Municipio)} className="btn-round" size="sm" color="primary">Ver</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                                                                        AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                                                                                        AvanceEstructura: prop.AvanceEstructura > 0 ? (<><h6>{prop.AvanceEstructura}</h6><Button onClick={() => onClickShowResponsables(prop, "Municipio", prop.Municipio)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceEstructura ? prop.AvanceEstructura : 0),
                                                                                        porcentaje: ddporcentaje ? ddporcentaje : 0,
                                                                                        PorcentajeEstructura: prop.PorcentajeEstructura ? prop.PorcentajeEstructura : 0,
                                                                                        data: prop,
                                                                                    };
                                                                                })
                                                                            );
                                                                            var categorias = res.grafica.map((prop, key) => {
                                                                                return prop.Municipio;
                                                                            })

                                                                            var Meta = res.grafica.map((prop, key) => {
                                                                                return prop.MetaEstructura;
                                                                            })

                                                                            var Avance = res.grafica.map((prop, key) => {
                                                                                return prop.AvanceEstructura;
                                                                            })

                                                                            const data = {

                                                                                title: `Avance ${horizontalTabs.horizontalTabs}`,
                                                                                xAxis: {
                                                                                    categories: categorias,
                                                                                    title: {
                                                                                        text: titulo
                                                                                    }
                                                                                },
                                                                                yAxis: [{
                                                                                    min: 0,
                                                                                    title: {
                                                                                        text: `Avance de ${titulo}`
                                                                                    }
                                                                                }],
                                                                            };

                                                                            const series = [
                                                                                {
                                                                                    name: 'Meta',
                                                                                    color: 'rgba(147,220,236,.9)',
                                                                                    data: Meta,
                                                                                    pointPadding: 0.3,
                                                                                    pointPlacement: -0.2
                                                                                }, {
                                                                                    name: 'Avance',
                                                                                    color: '#0041A0',
                                                                                    data: Avance,
                                                                                    pointPadding: 0.4,
                                                                                    pointPlacement: -0.2
                                                                                }];
                                                                            setGrafica({
                                                                                data: data,
                                                                                series: series
                                                                            })

                                                                        })
                                                                        .catch((e) => {
                                                                            setLoadingTabla(false);
                                                                            notify.show(
                                                                                `Error al obtener tabla metas: ${e.message}`,
                                                                                "custom",
                                                                                5000,
                                                                                {
                                                                                    background: "#0857B6",
                                                                                    fontSize: 14,
                                                                                    text: "#FFFFFF",
                                                                                }
                                                                            );
                                                                        });

                                                                    EjecutarApi(dataEstatal, "getResumenEstructuraEstatal", token.access_token)
                                                                        .then(function (res) {
                                                                            return res;
                                                                        })
                                                                        .then((res) => {
                                                                            setLoading(false)
                                                                            setEstatal(res.data);
                                                                        })
                                                                        .catch((e) => {
                                                                            setLoading(false)
                                                                            notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                                                                                background: "#0857B6",
                                                                                fontSize: 14,
                                                                                text: "#FFFFFF",
                                                                            });
                                                                        });

                                                                }
                                                                }
                                                            /> : <></>
                                                        }

                                                    </TabPane>
                                                    <TabPane tabId="Poligonal" role="tabpanel">
                                                        {/* {horizontalTabs.horizontalTabs === "Poligonal" ? */}
                                                        {flagTab === 3 ?
                                                            <ReactTable
                                                                size='sm'
                                                                data={meta}
                                                                noDataText="Sin información"
                                                                manual
                                                                columns={[
                                                                    {
                                                                        Header: <>{responsibilityID == 53 ? ("Distrito Federal") : (responsibilityID == 54 ? ("Distrito Local") : ("Region"))}</>,
                                                                        minWidth: 20,
                                                                        id: "Region",
                                                                        accessor: (d) => d.Region,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Municipio",
                                                                        minWidth: 40,
                                                                        id: "Municipio",
                                                                        accessor: (d) => d.Municipio,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Polígono",
                                                                        minWidth: 25,
                                                                        id: "Poligono",
                                                                        accessor: (d) => d.Poligono,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '13px' },
                                                                    },
                                                                    {
                                                                        Header: "Meta Estructura",
                                                                        accessor: (d) => d.meta,
                                                                        minWidth: 40,
                                                                        id: "meta",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "A.Estructura",
                                                                        accessor: (d) => d.AvanceEstructura,
                                                                        minWidth: 40,
                                                                        id: "AvanceEstructura",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    }, {
                                                                        Header: "% A.Estructura",
                                                                        accessor: (d) => d.PorcentajeEstructura,
                                                                        Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                                                        minWidth: 40,
                                                                        id: "PoncentajeEstructura",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Meta Compromisos",
                                                                        accessor: (d) => d.metaCompromisos,
                                                                        minWidth: 40,
                                                                        id: "metaCompromisos",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },

                                                                    {
                                                                        Header: "A.Esperado",
                                                                        accessor: (d) => d.Esperada,
                                                                        minWidth: 40,
                                                                        id: "esperada",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "A.Compromisos",
                                                                        accessor: (d) => d.AvanceCompromisos,
                                                                        minWidth: 40,
                                                                        id: "AvanceCompromisos",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "% A.Compromisos",
                                                                        accessor: (d) => d.AvanceEsperado,
                                                                        Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                                                        minWidth: 40,
                                                                        id: "AvanceEsperado",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                ]}

                                                                className="-striped -highlight primary-pagination"
                                                                loading={loadingTabla}
                                                                showPagination={true}
                                                                showPaginationBottom={true}
                                                                showTotalPages={true}
                                                                showPageJump={false}
                                                                canNextFromData={true}
                                                                defaultPageSize={10}
                                                                total={total}
                                                                pages={Math.floor(total / 10)}
                                                                previousText="Anterior"
                                                                nextText="Siguiente"
                                                                ofText="de"
                                                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                                                PaginationComponent={Pagination}
                                                                onFetchData={(stateComp, instance) => {
                                                                    setLoadingTabla(true);
                                                                    const data = {
                                                                        page: stateComp.page,
                                                                        pageSize: stateComp.pageSize,
                                                                        sorted: [{ "id": "p.idMunicipioReportes", "desc": false }, { "id": "p.id", "desc": false }],
                                                                        tipo: 'and',
                                                                        filtered: filtro ? filtro : [],
                                                                        Region: region ? region : [],
                                                                        Municipio: municipio ? municipio : [],
                                                                        Poligono: poligono ? poligono : [],
                                                                        Seccion: seccion ? seccion : [],
                                                                        Nombre: nombre ? nombre : "",
                                                                        Responsable: Responsable ? Responsable : 0,
                                                                        tabla: "poligono"
                                                                    };
                                                                    EjecutarApi(data, "getCompromisoAvancePromocion", token.access_token)
                                                                        .then(function (res) {
                                                                            return res;
                                                                        })
                                                                        .then((res) => {
                                                                            setLoadingTabla(false);
                                                                            setTotal(res.total);
                                                                            setMeta(
                                                                                res.data.map((prop, key) => {
                                                                                    var porcentaje = parseFloat(prop.porcentaje)
                                                                                    var ddporcentaje = porcentaje.toFixed(2)
                                                                                    return {
                                                                                        id: prop.id,
                                                                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                                                                        Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                                                                                        Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                                                                        meta: prop.MetaEstructura,
                                                                                        Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                                                                                        cel: prop.ResponsableCel,
                                                                                        Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                                                                                        metaCompromisos: prop.MetaCompromisos,
                                                                                        AvanceCompromisos: prop.Compromisos > 0 ? (<><h6>{prop.Compromisos}</h6><Button onClick={() => onClickShowCompromisos(prop, "Polígono", prop.Poligono)} className="btn-round" size="sm" color="primary">Ver</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                                                                        AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                                                                                        AvanceEstructura: prop.AvanceEstructura > 0 ? (<><h6>{prop.AvanceEstructura}</h6><Button onClick={() => onClickShowResponsables(prop, "Polígono", prop.Poligono)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceEstructura ? prop.AvanceEstructura : 0),
                                                                                        PorcentajeEstructura: prop.PorcentajeEstructura ? prop.PorcentajeEstructura : 0,
                                                                                        porcentaje: ddporcentaje ? ddporcentaje : 0,
                                                                                        data: prop,
                                                                                    };
                                                                                })
                                                                            );
                                                                            var categorias = res.data.map((prop, key) => {
                                                                                return prop.Poligono;
                                                                            })

                                                                            var Meta = res.data.map((prop, key) => {
                                                                                return prop.MetaEstructura;
                                                                            })

                                                                            var Avance = res.data.map((prop, key) => {
                                                                                return prop.AvanceEstructura;
                                                                            })

                                                                            const data = {

                                                                                title: `Avance ${horizontalTabs.horizontalTabs}`,
                                                                                xAxis: {
                                                                                    categories: categorias,
                                                                                    title: {
                                                                                        text: titulo
                                                                                    }

                                                                                },
                                                                                yAxis: [{
                                                                                    min: 0,
                                                                                    title: {
                                                                                        text: `Avance de ${titulo}`
                                                                                    }
                                                                                }],
                                                                            };

                                                                            const series = [
                                                                                {
                                                                                    name: 'Meta',
                                                                                    color: 'rgba(147,220,236,.9)',
                                                                                    data: Meta,
                                                                                    pointPadding: 0.3,
                                                                                    pointPlacement: -0.2
                                                                                }, {
                                                                                    name: 'Avance',
                                                                                    color: '#0041A0',
                                                                                    data: Avance,
                                                                                    pointPadding: 0.4,
                                                                                    pointPlacement: -0.2
                                                                                }];
                                                                            setGrafica({
                                                                                data: data,
                                                                                series: series
                                                                            })
                                                                        })
                                                                        .catch((e) => {
                                                                            setLoadingTabla(false);
                                                                            notify.show(
                                                                                `Error al obtener tabla metas: ${e.message}`,
                                                                                "custom",
                                                                                5000,
                                                                                {
                                                                                    background: "#0857B6",
                                                                                    fontSize: 14,
                                                                                    text: "#FFFFFF",
                                                                                }
                                                                            );
                                                                        });

                                                                    const dataEstatal = {

                                                                        Region: region,
                                                                        Municipio: municipio,
                                                                        Poligono: poligono,
                                                                        Seccion: seccion,
                                                                        tipo: 'and'
                                                                    };
                                                                    EjecutarApi(dataEstatal, "getResumenEstructuraEstatal", token.access_token)
                                                                        .then(function (res) {
                                                                            return res;
                                                                        })
                                                                        .then((res) => {
                                                                            setLoading(false)
                                                                            setEstatal(res.data);
                                                                        })
                                                                        .catch((e) => {
                                                                            setLoading(false)
                                                                            notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                                                                                background: "#0857B6",
                                                                                fontSize: 14,
                                                                                text: "#FFFFFF",
                                                                            });
                                                                        });

                                                                }}
                                                            /> : <></>
                                                        }
                                                    </TabPane>
                                                    <TabPane tabId="Seccional" role="tabpanel">
                                                        {/* {horizontalTabs.horizontalTabs === "Seccional" ? */}
                                                        {flagTab === 4 ?
                                                            <ReactTable
                                                                size='sm'
                                                                data={meta}
                                                                noDataText="Sin información"
                                                                manual
                                                                columns={[
                                                                    {
                                                                        Header: <>{responsibilityID == 53 ? ("Distrito Federal") : (responsibilityID == 54 ? ("Distrito Local") : ("Region"))}</>,
                                                                        minWidth: 20,
                                                                        id: "Region",
                                                                        accessor: (d) => d.Region,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Municipio",
                                                                        minWidth: 40,
                                                                        id: "Municipio",
                                                                        accessor: (d) => d.Municipio,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Polígono",
                                                                        minWidth: 25,
                                                                        id: "Poligono",
                                                                        accessor: (d) => d.Poligono,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '13px' },
                                                                    },
                                                                    {
                                                                        Header: "Sección",
                                                                        minWidth: 20,
                                                                        id: "Seccion",
                                                                        accessor: (d) => d.Seccion,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '13px' },
                                                                    },
                                                                    {
                                                                        Header: "Meta Estructura",
                                                                        accessor: (d) => d.meta,
                                                                        minWidth: 40,
                                                                        id: "meta",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "A.Estructura",
                                                                        accessor: (d) => d.AvanceEstructura,
                                                                        minWidth: 40,
                                                                        id: "AvanceEstructura",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    }, {
                                                                        Header: "% A.Estructura",
                                                                        accessor: (d) => d.PorcentajeEstructura,
                                                                        Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                                                        minWidth: 40,
                                                                        id: "PoncentajeEstructura",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Meta Compromisos",
                                                                        accessor: (d) => d.metaCompromisos,
                                                                        minWidth: 40,
                                                                        id: "metaCompromisos",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },

                                                                    {
                                                                        Header: "A.Esperado",
                                                                        accessor: (d) => d.Esperada,
                                                                        minWidth: 40,
                                                                        id: "esperada",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "A.Compromisos",
                                                                        accessor: (d) => d.AvanceCompromisos,
                                                                        minWidth: 40,
                                                                        id: "AvanceCompromisos",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "% A.Compromisos",
                                                                        accessor: (d) => d.AvanceEsperado,
                                                                        Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                                                        minWidth: 40,
                                                                        id: "AvanceEsperado",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                ]}

                                                                className="-striped -highlight primary-pagination"
                                                                loading={loadingTabla}
                                                                showPagination={true}
                                                                showPaginationBottom={true}
                                                                showTotalPages={true}
                                                                showPageJump={false}
                                                                canNextFromData={true}
                                                                defaultPageSize={10}
                                                                total={total}
                                                                pages={Math.floor(total / 10)}
                                                                previousText="Anterior"
                                                                nextText="Siguiente"
                                                                ofText="de"
                                                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                                                PaginationComponent={Pagination}
                                                                onFetchData={(stateComp, instance) => {
                                                                    setLoadingTabla(true);
                                                                    const data = {
                                                                        page: stateComp.page,
                                                                        pageSize: stateComp.pageSize,
                                                                        sorted: [{ "id": "S.Seccion", "desc": false }],
                                                                        tipo: 'and',
                                                                        filtered: filtro ? filtro : [],
                                                                        Region: region ? region : [],
                                                                        Municipio: municipio ? municipio : [],
                                                                        Poligono: poligono ? poligono : [],
                                                                        Seccion: seccion ? seccion : [],
                                                                        Nombre: nombre ? nombre : "",
                                                                        Responsable: Responsable ? Responsable : 0,
                                                                        tabla: "seccion"
                                                                    };
                                                                    EjecutarApi(data, "getCompromisoAvancePromocion", token.access_token)
                                                                        .then(function (res) {
                                                                            return res;
                                                                        })
                                                                        .then((res) => {
                                                                            setLoadingTabla(false);
                                                                            setTotal(res.total);
                                                                            setMeta(
                                                                                res.data.map((prop, key) => {
                                                                                    var porcentaje = parseFloat(prop.porcentaje)
                                                                                    var ddporcentaje = porcentaje.toFixed(2)
                                                                                    return {
                                                                                        id: prop.id,
                                                                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                                                                        Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                                                                                        Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                                                                        Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                                                                                        meta: prop.MetaEstructura,
                                                                                        Esperada: prop.MetaEsperada ? prop.MetaEsperada : 0,
                                                                                        metaCompromisos: prop.MetaCompromisos,
                                                                                        AvanceCompromisos: prop.Compromisos > 0 ? (<><h6>{prop.Compromisos}</h6><Button onClick={() => onClickShowCompromisos(prop, "Sección", prop.Seccion)} className="btn-round" size="sm" color="primary">Ver</Button></>) : (prop.Compromisos ? prop.Compromisos : 0),
                                                                                        AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                                                                                        AvanceEstructura: prop.AvanceEstructura > 0 ? (<><h6>{prop.AvanceEstructura}</h6><Button onClick={() => onClickShowResponsables(prop, "Sección", prop.Seccion)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceEstructura ? prop.AvanceEstructura : 0),
                                                                                        PorcentajeEstructura: prop.PorcentajeEstructura ? prop.PorcentajeEstructura : 0,
                                                                                        porcentaje: ddporcentaje ? ddporcentaje : 0,
                                                                                        data: prop,
                                                                                    };
                                                                                })
                                                                            );

                                                                            var categorias = res.data.map((prop, key) => {
                                                                                return prop.Seccion;
                                                                            })

                                                                            var Meta = res.data.map((prop, key) => {
                                                                                return prop.MetaEstructura;
                                                                            })

                                                                            var Avance = res.data.map((prop, key) => {
                                                                                return prop.AvanceEstructura;
                                                                            })

                                                                            const data = {

                                                                                title: `Avance ${horizontalTabs.horizontalTabs}`,
                                                                                xAxis: {
                                                                                    categories: categorias,
                                                                                    title: {
                                                                                        text: titulo
                                                                                    }

                                                                                },
                                                                                yAxis: [{
                                                                                    min: 0,
                                                                                    title: {
                                                                                        text: `Avance de ${titulo}`
                                                                                    }
                                                                                }],
                                                                            };

                                                                            const series = [
                                                                                {
                                                                                    name: 'Meta',
                                                                                    color: 'rgba(147,220,236,.9)',
                                                                                    data: Meta,
                                                                                    pointPadding: 0.3,
                                                                                    pointPlacement: -0.2
                                                                                }, {
                                                                                    name: 'Avance',
                                                                                    color: '#0041A0',
                                                                                    data: Avance,
                                                                                    pointPadding: 0.4,
                                                                                    pointPlacement: -0.2
                                                                                }];
                                                                            setGrafica({
                                                                                data: data,
                                                                                series: series
                                                                            })


                                                                        })
                                                                        .catch((e) => {
                                                                            setLoadingTabla(false);
                                                                            notify.show(
                                                                                `Error al obtener tabla metas: ${e.message}`,
                                                                                "custom",
                                                                                5000,
                                                                                {
                                                                                    background: "#0857B6",
                                                                                    fontSize: 14,
                                                                                    text: "#FFFFFF",
                                                                                }
                                                                            );
                                                                        });

                                                                    const dataEstatal = {

                                                                        Region: region,
                                                                        Municipio: municipio,
                                                                        Poligono: poligono,
                                                                        Seccion: seccion,
                                                                        tipo: 'and'
                                                                    };
                                                                    EjecutarApi(dataEstatal, "getResumenEstructuraEstatal", token.access_token)
                                                                        .then(function (res) {
                                                                            return res;
                                                                        })
                                                                        .then((res) => {
                                                                            setLoading(false)
                                                                            setEstatal(res.data);
                                                                        })
                                                                        .catch((e) => {
                                                                            setLoading(false)
                                                                            notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                                                                                background: "#0857B6",
                                                                                fontSize: 14,
                                                                                text: "#FFFFFF",
                                                                            });
                                                                        });
                                                                }}
                                                            />
                                                            : <></>
                                                        }

                                                    </TabPane>
                                                    <TabPane tabId="Manzanero" role="tabpanel">
                                                        {/* {horizontalTabs.horizontalTabs === "Seccional" ? */}
                                                        {flagTab === 5 ?
                                                            <ReactTable
                                                                size='sm'
                                                                data={meta}
                                                                noDataText="Sin información"
                                                                manual
                                                                columns={[
                                                                    {
                                                                        Header: <>{responsibilityID == 53 ? ("Distrito Federal") : (responsibilityID == 54 ? ("Distrito Local") : ("Region"))}</>,
                                                                        minWidth: 20,
                                                                        id: "Region",
                                                                        accessor: (d) => d.Region,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Municipio",
                                                                        minWidth: 40,
                                                                        id: "Municipio",
                                                                        accessor: (d) => d.Municipio,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Polígono",
                                                                        minWidth: 25,
                                                                        id: "Poligono",
                                                                        accessor: (d) => d.Poligono,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '13px' },
                                                                    },
                                                                    {
                                                                        Header: "Sección",
                                                                        minWidth: 20,
                                                                        id: "Seccion",
                                                                        accessor: (d) => d.Seccion,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '13px' },
                                                                    },
                                                                    {
                                                                        Header: "Manzana",
                                                                        minWidth: 20,
                                                                        id: "Manzana",
                                                                        accessor: (d) => d.Manzana,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '13px' },
                                                                    },
                                                                    {
                                                                        Header: "Prioridad",
                                                                        accessor: (d) => d.Prioridad,
                                                                        minWidth: 40,
                                                                        id: "cm.PRIORIDAD",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Meta",
                                                                        accessor: (d) => d.meta,
                                                                        minWidth: 40,
                                                                        id: "meta",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "A.Estructura",
                                                                        accessor: (d) => d.AvanceEstructura,
                                                                        minWidth: 40,
                                                                        id: "AvanceEstructura",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    }, {
                                                                        Header: "% A.Estructura",
                                                                        accessor: (d) => d.PorcentajeEstructura,
                                                                        Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                                                        minWidth: 40,
                                                                        id: "PoncentajeEstructura",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    }
                                                                ]}

                                                                className="-striped -highlight primary-pagination"
                                                                loading={loadingTabla}
                                                                showPagination={true}
                                                                showPaginationBottom={true}
                                                                showTotalPages={true}
                                                                showPageJump={false}
                                                                canNextFromData={true}
                                                                defaultPageSize={10}
                                                                total={total}
                                                                pages={Math.floor(total / 10)}
                                                                previousText="Anterior"
                                                                nextText="Siguiente"
                                                                ofText="de"
                                                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                                                PaginationComponent={Pagination}
                                                                onFetchData={(stateComp, instance) => {
                                                                    setLoadingTabla(true);
                                                                    const data = {
                                                                        page: stateComp.page,
                                                                        pageSize: stateComp.pageSize,
                                                                        sorted: [{ "id": "cm.Manzana", "desc": false }],
                                                                        tipo: 'and',
                                                                        filtered: filtro ? filtro : [],
                                                                        Region: region ? region : [],
                                                                        Municipio: municipio ? municipio : [],
                                                                        Poligono: poligono ? poligono : [],
                                                                        Seccion: seccion ? seccion : [],
                                                                        Manzana: manzana ? manzana : [],
                                                                        Nombre: nombre ? nombre : "",
                                                                        Responsable: Responsable ? Responsable : 0,
                                                                        tabla: "manzana"
                                                                    };
                                                                    EjecutarApi(data, "getCompromisoAvancePromocion", token.access_token)
                                                                        .then(function (res) {
                                                                            return res;
                                                                        })
                                                                        .then((res) => {
                                                                            setLoadingTabla(false);
                                                                            setTotal(res.total);
                                                                            setMeta(
                                                                                res.data.map((prop, key) => {
                                                                                    return {
                                                                                        id: prop.id,
                                                                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                                                                        Region: <>{responsibilityID == 53 ? (prop.DistritoFederal) : (responsibilityID == 54 ? (prop.DistritoLocal) : (prop.Region))}</>,
                                                                                        Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                                                                        Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                                                                                        Manzana: prop.manzana ? prop.manzana : 'Sin Mnazana',
                                                                                        Prioridad: prop.Prioridad,
                                                                                        meta: prop.MetaEstructura, AvanceEsperado: new Intl.NumberFormat().format(prop.AvanceMetaEsperada),
                                                                                        AvanceEstructura: prop.AvanceEstructura > 0 ? (<><h6>{prop.AvanceEstructura}</h6><Button onClick={() => onClickShowResponsables(prop, "Manzana", prop.manzana)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceEstructura ? prop.AvanceEstructura : 0),
                                                                                        PorcentajeEstructura: (prop.PorcentajeEstructura > 0) ? prop.PorcentajeEstructura : 0,
                                                                                        data: prop,
                                                                                    };
                                                                                })
                                                                            );

                                                                            var categorias = res.data.map((prop, key) => {
                                                                                return prop.manzana;
                                                                            })

                                                                            var Meta = res.data.map((prop, key) => {
                                                                                return prop.MetaEstructura;
                                                                            })

                                                                            var Avance = res.data.map((prop, key) => {
                                                                                return prop.AvanceEstructura;
                                                                            })

                                                                            const data = {

                                                                                title: `Avance ${horizontalTabs.horizontalTabs}`,
                                                                                xAxis: {
                                                                                    categories: categorias,
                                                                                    title: {
                                                                                        text: titulo
                                                                                    }

                                                                                },
                                                                                yAxis: [{
                                                                                    min: 0,
                                                                                    title: {
                                                                                        text: `Avance de ${titulo}`
                                                                                    }
                                                                                }],
                                                                            };

                                                                            const series = [
                                                                                {
                                                                                    name: 'Meta',
                                                                                    color: 'rgba(147,220,236,.9)',
                                                                                    data: Meta,
                                                                                    pointPadding: 0.3,
                                                                                    pointPlacement: -0.2
                                                                                }, {
                                                                                    name: 'Avance',
                                                                                    color: '#0041A0',
                                                                                    data: Avance,
                                                                                    pointPadding: 0.4,
                                                                                    pointPlacement: -0.2
                                                                                }];
                                                                            setGrafica({
                                                                                data: data,
                                                                                series: series
                                                                            })


                                                                        })
                                                                        .catch((e) => {
                                                                            setLoadingTabla(false);
                                                                            notify.show(
                                                                                `Error al obtener tabla metas: ${e.message}`,
                                                                                "custom",
                                                                                5000,
                                                                                {
                                                                                    background: "#0857B6",
                                                                                    fontSize: 14,
                                                                                    text: "#FFFFFF",
                                                                                }
                                                                            );
                                                                        });

                                                                    const dataEstatal = {

                                                                        Region: region,
                                                                        Municipio: municipio,
                                                                        Poligono: poligono,
                                                                        Seccion: seccion,
                                                                        tipo: 'and'
                                                                    };
                                                                    EjecutarApi(dataEstatal, "getResumenEstructuraEstatal", token.access_token)
                                                                        .then(function (res) {
                                                                            return res;
                                                                        })
                                                                        .then((res) => {
                                                                            setLoading(false)
                                                                            setEstatal(res.data);
                                                                        })
                                                                        .catch((e) => {
                                                                            setLoading(false)
                                                                            notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                                                                                background: "#0857B6",
                                                                                fontSize: 14,
                                                                                text: "#FFFFFF",
                                                                            });
                                                                        });
                                                                }}
                                                            />
                                                            : <></>
                                                        }

                                                    </TabPane>
                                                    <TabPane tabId="MAS" role="tabpanel">
                                                        {flagTab === 6 ?
                                                            <ReactTable
                                                                size='sm'
                                                                data={meta}
                                                                noDataText="Sin información"
                                                                manual
                                                                columns={[
                                                                    {
                                                                        Header: "Responsabilidad",
                                                                        minWidth: 40,
                                                                        id: "Responsabilidad",
                                                                        accessor: (d) => d.Responsabilidad,
                                                                        filterAll: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Nombre",
                                                                        accessor: (d) => d.Responsable,
                                                                        minWidth: 40,
                                                                        id: "Nombre",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    },
                                                                    {
                                                                        Header: "Celular",
                                                                        accessor: (d) => d.cel,
                                                                        minWidth: 40,
                                                                        id: "Celular",
                                                                        filterable: false,
                                                                        headerStyle: { fontSize: '12px', align: "center" },
                                                                        style: { fontSize: '12px' },
                                                                    }
                                                                ]}

                                                                className="-striped -highlight primary-pagination"
                                                                loading={loadingTabla}
                                                                showPagination={true}
                                                                showPaginationBottom={true}
                                                                showTotalPages={true}
                                                                showPageJump={false}
                                                                canNextFromData={true}
                                                                defaultPageSize={10}
                                                                total={total}
                                                                pages={Math.floor(total / 10)}
                                                                previousText="Anterior"
                                                                nextText="Siguiente"
                                                                ofText="de"
                                                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                                                PaginationComponent={Pagination}
                                                                onFetchData={(stateComp, instance) => {
                                                                    setLoadingTabla(true);
                                                                    const data = {
                                                                        page: stateComp.page,
                                                                        pageSize: stateComp.pageSize,
                                                                        sorted: [],
                                                                        filtered: filtro ? filtro : [],
                                                                        Region: region ? region : [],
                                                                        Municipio: municipio ? municipio : [],
                                                                        Poligono: poligono ? poligono : [],
                                                                        Seccion: seccion ? seccion : [],
                                                                        Nombre: nombre ? nombre : "",
                                                                        Responsable: Responsable ? Responsable : 0,
                                                                        tipo: 'and'
                                                                    };
                                                                    EjecutarApi(data, "getResponsables", token.access_token)
                                                                        .then(function (res) {
                                                                            return res;
                                                                        })
                                                                        .then((res) => {
                                                                            setLoadingTabla(false);
                                                                            setTotal(res.total);
                                                                            setMeta(
                                                                                res.data.map((prop, key) => {
                                                                                    return {
                                                                                        id: prop.id,
                                                                                        Responsabilidad: prop.ResponsabilidadData ? prop.ResponsabilidadData : "Sin Responsabilidad",
                                                                                        Responsable: prop.Responsable ? prop.Responsable : "Sin Resposable",
                                                                                        cel: prop.ResponsableCel,
                                                                                        data: prop,
                                                                                    };
                                                                                })
                                                                            );

                                                                        })
                                                                        .catch((e) => {
                                                                            setLoadingTabla(false);
                                                                            notify.show(
                                                                                `Error al obtener tabla metas: ${e.message}`,
                                                                                "custom",
                                                                                5000,
                                                                                {
                                                                                    background: "#0857B6",
                                                                                    fontSize: 14,
                                                                                    text: "#FFFFFF",
                                                                                }
                                                                            );
                                                                        });
                                                                }
                                                                }
                                                            /> : <></>
                                                        }

                                                    </TabPane>

                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    {confirm ? confirmMessage : ''}
                                </Row>
                            </div>
                        }
                        {
                            (showCompromisos) &&
                            <ListadoCompromisos
                                props={compromisos}
                                onHide={() => onHide()}>
                            </ListadoCompromisos>
                        }
                        {
                            showResponsable &&
                            <ListadoResponsables
                                props={reponsables}
                                onHide={() => onHide()}>
                            </ListadoResponsables>
                        }
                    </div>
                </div>
                <Footer fluid />
            </div>
        </div>
    );

}
export default AvanceResponsabilidadPromocion;