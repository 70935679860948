import React, { useState, useEffect } from 'react';

import Notifications, { notify } from 'react-notify-toast';

import { getVars, setVars } from 'GlobalStorage';
import EjecutarApi from 'data/getPOST';
import { URL_IMAGENES } from 'GlobalStorage';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Row,
  Col,
  Input,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { SwatchesPicker } from 'react-color';
import PictureUpload from 'components/Perfil/PictureUpload';

const PlanillasForm = (props) => {
  const {
    catalogos,
    isOpen,
    setIsOpen,
    idEdit,
    isLoading,
    setIsLoading,
    tiposEleccion,
    getPlanillas,
  } = props;
  const token = getVars('Token');
  const style = {
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      maxHeight: '10rem',
      left: '50%',
      padding: '2rem',
      position: 'fixed',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '20rem',
      width: '90%',
      maxWidth: '60rem',
    },
  };
  const [catalogosFiltrosAdd, setCatalogosFiltrosAdd] = useState([]);
  const [partidos, setPartidos] = useState([]);
  const [candidato, setCandidato] = useState({
    Tipo: { value: 0, label: 'Sin seleccionar' },
    Nombre: '',
    Partido: { value: 0, label: 'Sin seleccionar' },
    Municipio: { value: 0, label: 'Sin seleccionar' },
    DL: { value: 0, label: 'Sin seleccionar' },
    DF: { value: 0, label: 'Sin seleccionar' },
    Color: '#fff',
    Imagen: {
      file: null,
      imagePreviewUrl: '',
    },
  });
  const [errors, setErrors] = useState({
    Nombre: '',
    Tipo: '',
    Municipio: '',
    Partido: '',
    DL: '',
    DF: '',
  });
  const [isLoadingAsignacion, setIsLoadingAsignacion] = useState(false);

  useEffect(() => {
    if (catalogos.municipios.length > 0) {
      setCatalogosFiltrosAdd({
        ...catalogosFiltrosAdd,
        municipios: [{ value: 0, label: 'Sin seleccionar' }].concat(
          catalogos.municipios
        ),
        dls: [{ value: 0, label: 'Sin seleccionar' }].concat(catalogos.dls),
        dfs: [{ value: 0, label: 'Sin seleccionar' }].concat(catalogos.dfs),
      });
    }
  }, [catalogos]);

  useEffect(() => {
    setIsLoading(true);
    const getPartidos = () => {
      EjecutarApi({}, 'getPartidos', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          setIsLoading(false);
          if (res.success && res.results) {
            setPartidos(
              res.data.map((partido) => {
                return {
                  value: partido.id,
                  label: partido.Partido,
                };
              })
            );
          }
        })
        .catch((e) => {
          setIsLoading(false);
          notify.show(
            `Error al cargar partidos: ${e.message} 'Favor de renovar sesión'
          }`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        });
    };
    getPartidos();
  }, []);

  useEffect(() => {
    const getDataCandidato = () => {
      setIsLoading(true);
      EjecutarApi(
        { id: idEdit },
        'getCandidatoPlanillaById',
        token.access_token
      )
        .then(function (res) {
          return res;
        })
        .then((res) => {
          setIsLoading(false);
          if (res.success && res.results) {
            let candidatoData = res.data;
            candidatoData.Nombre = candidatoData.Candidato;
            candidatoData.Imagen = {
              file: null,
              imagePreviewUrl: candidatoData.Logo
                ? `${URL_IMAGENES}/${candidatoData.Logo}`
                : '',
            };
            setCandidato(res.data);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          notify.show(
            `Error al cargar filtros: ${e.message} 'Favor de renovar sesión'
            }`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        });
    };

    if (idEdit > 0) {
      getDataCandidato();
    }
  }, [idEdit]);

  const handleSeleccionTipoCandidato = (tipo) => {
    let values = {
      DL: candidato.DL,
      DF: candidato.DF,
      Municipio: candidato.Municipio,
    };
    setErrors({
      ...errors,
      DL: '',
      DF: '',
      Municipio: '',
    });
    switch (tipo.value) {
      case 6:
        values.DL = { value: 0, label: 'Sin seleccionar' };
        values.DF = { value: 0, label: 'Sin seleccionar' };
        break;
      case 5:
        values.Municipio = { value: 0, label: 'Sin seleccionar' };
        values.DF = { value: 0, label: 'Sin seleccionar' };
        break;
      case 3:
        values.Municipio = { value: 0, label: 'Sin seleccionar' };
        values.DL = { value: 0, label: 'Sin seleccionar' };
        break;
      case 4:
        values.DL = { value: 0, label: 'Sin seleccionar' };
        values.DF = { value: 0, label: 'Sin seleccionar' };
        values.Municipio = { value: 0, label: 'Sin seleccionar' };
        break;
    }
    setCandidato({
      ...candidato,
      Tipo: tipo,
      DL: values.DL,
      DF: values.DF,
      Municipio: values.Municipio,
    });
  };

  const handleSaveCandidato = () => {
    let candidatoJSON = {};
    let numeroErrores = 0;
    let erroresDetectados = {
      Nombre: '',
      Tipo: '',
      Municipio: '',
      Partido: '',
      DL: '',
      DF: '',
    };
    if (candidato.Nombre.trim().length <= 2) {
      erroresDetectados.Nombre = 'Debe ingresar un nombre valido';
      numeroErrores++;
    } else {
      erroresDetectados.Nombre = '';
    }
    if (candidato.Partido.value === 0) {
      erroresDetectados.Partido = 'Debe seleccionar un partido';
      numeroErrores++;
    } else {
      erroresDetectados.Partido = '';
    }
    if (candidato.Tipo.value === 0) {
      erroresDetectados.Tipo = 'Debe seleccionar un tipo de elección';
      numeroErrores++;
    } else {
      erroresDetectados.Tipo = '';
    }
    if (candidato.Tipo.value === 6 && candidato.Municipio.value === 0) {
      erroresDetectados.Municipio = 'Debe seleccionar un municipio';
      numeroErrores++;
    } else {
      erroresDetectados.Municipio = '';
    }
    if (candidato.Tipo.value === 3 && candidato.DF.value === 0) {
      erroresDetectados.DF = 'Debe seleccionar un distrito federal';
      numeroErrores++;
    } else {
      erroresDetectados.DF = '';
    }
    if (candidato.Tipo.value === 5 && candidato.DL.value === 0) {
      erroresDetectados.DL = 'Debe seleccionar un distrito federal';
      numeroErrores++;
    } else {
      erroresDetectados.DL = '';
    }
    setErrors(erroresDetectados);
    if (numeroErrores == 0) {
      candidatoJSON = {
        Candidato: candidato.Nombre,
        idTipoEleccion: candidato.Tipo.value,
        idPartido: candidato.Partido.value,
        idMunicipio: candidato.Municipio.value ? candidato.Municipio.value : 0,
        idDL: candidato.DL.value ? candidato.DL.value : 0,
        idDF: candidato.DF.value ? candidato.DF.value : 0,
        Color: candidato.Color,
        image: candidato.Imagen.file ? candidato.Imagen.imagePreviewUrl : null,
        logo: candidato.Logo ? candidato.Logo : null
      };
      if (idEdit === 0) {
        handleCreatePlanilla(candidatoJSON);
      } else {
        candidatoJSON.id = idEdit;
        handleUpdatePlanilla(candidatoJSON);
      }
    }
  };

  const handleCreatePlanilla = (candidatoJSON) => {
    setIsLoadingAsignacion(true);
    EjecutarApi(candidatoJSON, 'createCandidatoPlanilla', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setIsLoadingAsignacion(false);
        if (res.success && res.results) {
          getPlanillas();
          notify.show(`${res.message}`, 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
          setIsOpen(-1);
        }
      })
      .catch((e) => {
        setIsLoadingAsignacion(false);
        notify.show(
          `Error al cargar partidos: ${e.message} 'Favor de renovar sesión'
          }`,
          'error',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const handleUpdatePlanilla = (candidatoJSON) => {
    setIsLoadingAsignacion(true);
    EjecutarApi(candidatoJSON, 'updateCandidatoPlanilla', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setIsLoadingAsignacion(false);
        if (res.success && res.results) {
          getPlanillas();
          notify.show(`${res.message}`, 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
          setIsOpen(-1);
        }
      })
      .catch((e) => {
        setIsLoadingAsignacion(false);
        notify.show(
          `Error al cargar partidos: ${e.message} 'Favor de renovar sesión'
          }`,
          'error',
          5000,
          {
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      style={style}
      toggle={() => setIsOpen(-1)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader toggle={() => setIsOpen(-1)}>
        {idEdit == 0 ? 'Creacion' : 'Edición'} de <strong>candidato</strong>
      </ModalHeader>
      {isLoading && <Spinner color="primary" />}
      {!isLoading && (
        <>
          <ModalBody>
            <Row>
              <Col md="6">
                <span className="text-danger">*</span>Nombre completo
                <Input
                  value={candidato.Nombre}
                  onChange={(e) =>
                    setCandidato({ ...candidato, Nombre: e.target.value })
                  }
                  placeholder="Ingrese un nombre"
                />
                <span className="form-check-sign" style={{ color: 'red' }}>
                  {errors.Nombre}
                </span>
              </Col>
              <Col md="3">
                <span className="text-danger">*</span>Partido
                <Select
                  options={partidos}
                  value={candidato.Partido}
                  onChange={(e) => setCandidato({ ...candidato, Partido: e })}
                />
                <span className="form-check-sign" style={{ color: 'red' }}>
                  {errors.Partido}
                </span>
              </Col>
              <Col md="3">
                <span className="text-danger">*</span>Tipo de candidato
                <Select
                  options={tiposEleccion}
                  value={candidato.Tipo}
                  onChange={(e) => handleSeleccionTipoCandidato(e)}
                />
                <span className="form-check-sign" style={{ color: 'red' }}>
                  {errors.Tipo}
                </span>
              </Col>
              <Col md="4">
                {candidato.Tipo.value === 6 && (
                  <span className="text-danger">*</span>
                )}
                Municipio
                <Select
                  options={catalogosFiltrosAdd.municipios}
                  isDisabled={candidato.Tipo.value !== 6}
                  value={candidato.Municipio}
                  onChange={(e) => setCandidato({ ...candidato, Municipio: e })}
                />
                <span className="form-check-sign" style={{ color: 'red' }}>
                  {errors.Municipio}
                </span>
              </Col>
              <Col md="4">
                {candidato.Tipo.value === 3 && (
                  <span className="text-danger">*</span>
                )}
                Distrito federal
                <Select
                  options={catalogosFiltrosAdd.dfs}
                  isDisabled={candidato.Tipo.value !== 3}
                  value={candidato.DF}
                  onChange={(e) => setCandidato({ ...candidato, DF: e })}
                />
                <span className="form-check-sign" style={{ color: 'red' }}>
                  {errors.DF}
                </span>
              </Col>
              <Col md="4">
                {candidato.Tipo.value === 5 && (
                  <span className="text-danger">*</span>
                )}
                Distrito local
                <Select
                  options={catalogosFiltrosAdd.dls}
                  isDisabled={candidato.Tipo.value !== 5}
                  value={candidato.DL}
                  onChange={(e) => setCandidato({ ...candidato, DL: e })}
                />
                <span className="form-check-sign" style={{ color: 'red' }}>
                  {errors.DL}
                </span>
              </Col>
              <Col md="6">
                Color
                <SwatchesPicker
                  color={candidato.Color}
                  onChangeComplete={(e) =>
                    setCandidato({ ...candidato, Color: e.hex })
                  }
                />
              </Col>
              <Col md="6">
                Logo
                <PictureUpload
                  regresarImagen={(imagen) =>
                    setCandidato({ ...candidato, Imagen: imagen })
                  }
                  imagenEdit={candidato.Imagen.imagePreviewUrl}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setIsOpen(-1)}>
              Cancelar
            </Button>
            <Button
              color="primary"
              onClick={handleSaveCandidato}
              disabled={isLoadingAsignacion}
            >
              Guardar
            </Button>
            {isLoadingAsignacion && <Spinner />}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default PlanillasForm;
