import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { CircleSpinner } from "react-spinners-kit";
import { getVars } from "../GlobalStorage";

const Menus = ({ data, isMovil, privileges, flagLoading }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(flagLoading)
  }, [flagLoading]);

  const toglePrivilegiosTodos = flag => {
    let element = isMovil ? "mobile_menus" : "web_menus";
    let els = document
      .getElementById(element)
      .getElementsByClassName("btn btn-xs btn-sm");
    if (flag === 1) {
      for (let i = 0; i < els.length; i++) {
        els[i].classList.add("btn-info");
      }
      for (let i = 0; i < data.menus.length; i++) {
        privileges[i].Ver = data.menus[i].Ver === 1 ? 1 : 0;
        privileges[i].Agregar = data.menus[i].Agregar === 1 ? 1 : 0;
        privileges[i].Editar = data.menus[i].Editar === 1 ? 1 : 0;
        privileges[i].Eliminar = data.menus[i].Eliminar === 1 ? 1 : 0;
        privileges[i].Exportar = data.menus[i].Exportar === 1 ? 1 : 0;
        privileges[i].Seguimiento = data.menus[i].Seguimiento === 1 ? 1 : 0;
        privileges[i].Electoral = data.menus[i].Electoral === 1 ? 1 : 0;
        privileges[i].Territorial = data.menus[i].Territorial === 1 ? 1 : 0;
        privileges[i].Social = data.menus[i].Social === 1 ? 1 : 0;
        privileges[i].Propuesta = data.menus[i].Propuesta === 1 ? 1 : 0;
        privileges[i].Capturista = data.menus[i].Capturista === 1 ? 1 : 0;
      }

      for (let i = 0; i < data.othersUser.length; i++) {
        let j = (data.menus.length + i) - 1;
        privileges[j].Ver = data.othersUser[i].Ver === 1 ? 1 : 0;
        privileges[j].Agregar = data.othersUser[i].Agregar === 1 ? 1 : 0;
        privileges[j].Editar = data.othersUser[i].Editar === 1 ? 1 : 0;
        privileges[j].Eliminar = data.othersUser[i].Eliminar === 1 ? 1 : 0;
        privileges[j].Exportar = data.othersUser[i].Exportar === 1 ? 1 : 0;
        privileges[j].Seguimiento = data.othersUser[i].Seguimiento === 1 ? 1 : 0;
        privileges[j].Electoral = data.othersUser[i].Electoral === 1 ? 1 : 0;
        privileges[j].Territorial = data.othersUser[i].Territorial === 1 ? 1 : 0;
        privileges[j].Social = data.othersUser[i].Social === 1 ? 1 : 0;
        privileges[j].Propuesta = data.othersUser[i].Propuesta === 1 ? 1 : 0;
        privileges[i].Capturista = data.othersUser[i].Capturista === 1 ? 1 : 0;
      }
    } else {
      for (let i = 0; i < els.length; i++) {
        els[i].classList.remove("btn-info");
      }
      for (let i = 0; i < privileges.length; i++) {
        privileges[i].Ver = 0;
        privileges[i].Agregar = 0;
        privileges[i].Editar = 0;
        privileges[i].Eliminar = 0;
        privileges[i].Exportar = 0;
        privileges[i].Seguimiento = 0;
        privileges[i].Electoral = 0;
        privileges[i].Territorial = 0;
        privileges[i].Social = 0;
        privileges[i].Propuesta = 0;
        privileges[i].Capturista = 0;
      }
    }
  };

  const togglePrivilege = (e, index, name) => {
    let classes = "btn btn-xs btn-sm";
    if (privileges[index][name] === 1) {
      privileges[index][name] = 0;
      e.target.className = classes.replace(
        "btn btn-xs btn-sm btn-info",
        "btn btn-xs btn-sm"
      );
    } else {
      privileges[index][name] = 1;
      e.target.className = classes.replace(
        "btn btn-xs btn-sm",
        "btn btn-xs btn-sm btn-info"
      );
    }
  };
  return (
    <Table id={isMovil ? "mobile_menus" : "web_menus"}>
      <thead>
        <tr>
          <th width="35%">Menú</th>
          <th width="5%"></th>
          <th width="60%">
            Priviegios
            <div className="pull-right">
             {/*  <a
                style={{ color: "#FFFFFF" }}
                onClick={() => toglePrivilegiosTodos(1)}
                className="btn btn-sm btn-info"
              >
                <i className="fa fa-check"></i> Todos
              </a>
              <a
                style={{ color: "#FFFFFF" }}
                onClick={() => toglePrivilegiosTodos(0)}
                className="btn btn-sm"
              >
                <i className="fa fa-ban"></i> Ninguno
              </a> */}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="2" className="text-center" width="50%">
            <strong>
              {isMovil ? "PRIVILEGIOS MÓVILES" : "PRIVILEGIOS WEB"}
            </strong>
          </td>
          <td width="50%"></td>
        </tr>
        <tr>
          <td colSpan="3" width="100%">
            {loading && (
              <CircleSpinner
                className="text-center"
                size={30}
                color="#686769"
                loading={loading}
              />
            )}
          </td>
        </tr>
        {loading && (
          <CircleSpinner
            className="text-center"
            size={30}
            color="#686769"
            loading={loading}
          />
        )}
        {data.menus.map((row, index) => {

          let uItem = data.selected.filter(item => item.id == row.id);
          let exists = privileges.filter(item => item.idMenu == row.id);
          if (exists.length === 0) {
            if (uItem.length > 0) {
              privileges.push({
                idMenu: row.id,
                Ver: uItem[0].Ver,
                Agregar: uItem[0].Agregar,
                Editar: uItem[0].Editar,
                Exportar: uItem[0].Exportar,
                Eliminar: uItem[0].Eliminar,
                Seguimiento: uItem[0].Seguimiento,
                Electoral: uItem[0].Electoral,
                Territorial: uItem[0].Territorial,
                Social: uItem[0].Social,
                Propuesta: uItem[0].Propuesta,
                Capturista: uItem[0].Capturista,
              });
            } else {
              privileges.push({
                idMenu: row.id,
                Ver: 0,
                Agregar: 0,
                Editar: 0,
                Exportar: 0,
                Eliminar: 0,
                Seguimiento: 0,
                Electoral: 0,
                Territorial: 0,
                Social: 0,
                Propuesta: 0,
                Capturista:0
              });
            }
          }

          return (
            <tr key={index}>
              <td width="25%">
                <span style={{ marginLeft: row.parentid > 0 ? "30px" : "5px" }}>
                  <i className={row.icon}></i> {row.Menu}
                </span>
              </td>
              <td width="5%"></td>
              <td width="70%" className="small">
                {(row.Ver === 1 || row.Ver === 0 )&& (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Ver")}
                    className={
                      privileges[index].Ver
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Ver
                  </a>
                )}
                {(row.Agregar === 1 || row.Agregar === 0) && (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Agregar")}
                    className={
                      privileges[index].Agregar
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Agregar
                  </a>
                )}
                {(row.Editar === 1 || row.Editar === 0) && (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Editar")}
                    className={
                      privileges[index].Editar
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Editar
                  </a>
                )}
                {(row.Eliminar === 1 || row.Eliminar === 0) && (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Eliminar")}
                    className={
                      privileges[index].Eliminar
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Eliminar
                  </a>
                )}
                {(row.Exportar === 1 || row.Exportar === 0 )&& (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Exportar")}
                    className={
                      privileges[index].Exportar
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Exportar
                  </a>
                )}
                {/* {row.Seguimiento === 1 && (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Seguimiento")}
                    className={
                      privileges[index].Seguimiento
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Seguimiento
                  </a>
                )}
                {row.Territorial === 1 && (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Territorial")}
                    className={
                      privileges[index].Territorial
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Territorial
                  </a>
                )}
                {row.Social === 1 && (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Social")}
                    className={
                      privileges[index].Social
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Social
                  </a>
                )}
                {row.Electoral === 1 && (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Electoral")}
                    className={
                      privileges[index].Electoral
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Electoral
                  </a>
                )} */}
                {(row.Propuesta === 1 || row.Propuesta === 0) && (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Propuesta")}
                    className={
                      privileges[index].Propuesta
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Propuesta
                  </a>
                )}
                {(row.Capturista === 1 || row.Capturista === 0) && (
                  <a
                    style={{ color: "#FFFFFF" }}
                    onClick={e => togglePrivilege(e, index, "Capturista")}
                    className={
                      privileges[index].Capturista
                        ? "btn btn-xs btn-sm btn-info"
                        : "btn btn-xs btn-sm"
                    }
                  >
                    Capturista
                  </a>
                )}
              </td>
            </tr>
          );
        })}
        {
          data.others.length > 0 && (
            <>
              <tr>
                <td colSpan="2" className="text-center" width="50%">
                  <strong>
                    OTROS PRIVILEGIOS
                  </strong>
                </td>
                <td width="50%"></td>
              </tr>

              {data.others.map((row, index) => {
                let exists = privileges.filter(item => item.idMenu == row.id);
                let i = data.menus.length + index;
                let new_index = data.menus.length + index;
                if (exists.length === 0) {
                  privileges.push({
                    idMenu: row.id,
                    Ver: row.Ver,
                    Agregar: row.Agregar,
                    Editar: row.Editar,
                    Exportar: row.Exportar,
                    Eliminar: row.Eliminar,
                    Seguimiento: row.Seguimiento,
                    Electoral: row.Electoral,
                    Territorial: row.Territorial,
                    Social: row.Social,
                    Propuesta: row.Propuesta,
                    Capturista:row.Capturista
                  });
                }
                return (
                  <tr key={`other_${new_index}`}>
                    <td width="25%">
                      <span style={{ marginLeft: row.parentid > 0 ? "30px" : "5px" }}>
                        <i className={row.icon}></i> {row.Menu}
                      </span>
                    </td>
                    <td width="5%"></td>
                    <td width="70%" className="small">
                      {(row.Ver === 1 || row.Ver === 0) && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Ver")}
                          className={
                            privileges[i].Ver
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Ver
                        </a>
                      )}
                      {(row.Agregar === 1 || row.Agregar === 0) && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Agregar")}
                          className={
                            privileges[i].Agregar
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Agregar
                        </a>
                      )}
                      {(row.Editar === 1 || row.Editar === 0) && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Editar")}
                          className={
                            privileges[i].Editar
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Editar
                        </a>
                      )}
                      {(row.Eliminar === 1 || row.Eliminar === 0)  && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Eliminar")}
                          className={
                            privileges[i].Eliminar
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Eliminar
                        </a>
                      )}
                      {(row.Exportar === 1 || row.Exportar === 0) && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Exportar")}
                          className={
                            privileges[i].Exportar
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Exportar
                        </a>
                      )}
                      {/* {row.Seguimiento === 1 && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Seguimiento")}
                          className={
                            privileges[i].Seguimiento
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Seguimiento
                        </a>
                      )}
                      {row.Territorial === 1 && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Territorial")}
                          className={
                            privileges[i].Territorial
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Territorial
                        </a>
                      )}
                      {row.Social === 1 && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Social")}
                          className={
                            privileges[i].Social
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Social
                        </a>
                      )}
                      {row.Electoral === 1 && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Electoral")}
                          className={
                            privileges[i].Electoral
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Electoral
                        </a>
                      )} */}
                      {(row.Propuesta === 1 || row.Propuesta === 0) && (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Propuesta")}
                          className={
                            privileges[i].Propuesta
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Propuesta
                        </a>
                      )}
                      {(row.Capturista === 1 || row.Capturista === 0 )&& (
                        <a
                          style={{ color: "#FFFFFF" }}
                          onClick={e => togglePrivilege(e, new_index, "Capturista")}
                          className={
                            privileges[i].Capturista
                              ? "btn btn-xs btn-sm btn-info"
                              : "btn btn-xs btn-sm"
                          }
                        >
                          Capturista
                        </a>
                      )}
                    </td>
                  </tr>
                )
              })}
            </>
          )
        }
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="3" width="100%" className="text-center">
            <button type="submit" className="btn btn-sm btn-success" disabled={loading}>
              <i className="fa fa-check"></i> Guardar
            </button>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};
export default Menus;
