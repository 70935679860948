import React, { useState, useEffect } from "react";
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import {
    Button,
    Col,
    Card,
    Row,
    ButtonGroup,
    CardHeader,
    CardBody
} from 'reactstrap';
import { MagicSpinner } from "react-spinners-kit";
import SeccionesCard from "./RutasFiles/SeccionesCard";
import PluginFilterMap from "./RutasFiles/PluginFilterMapRutas";
import {SinPermisos} from "../ComponentesAuxiliares/SinPermisos";
import { getVars,setVars } from "GlobalStorage";
import {centerOnOneShape} from "../ComponentesAuxiliares/AuxiliarFunctions";

let style_b = {
    'color': '#000000',
}
let style_square_superior = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
}
let style_button_radiogroup = {
    'padding': '2px 4px',
    'font': '10px/12px ',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
}
let style_square_inferior_tabla = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.9)',
    /* 'background': 'white', */
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
    'zIndex': '100',
    'pointerEvents': 'all',
    'MozUserSelect': 'none'
}
let style_square_superior_left = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
    //'width':'200px'
}
let style_spinner = {
    'height': '500pt',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center'
}

function ChoropethMap({ center, RGsRutaParameter, ParameterSeccionesSelected, handleDeletePropRuta, MenuPermisos, SeccionesSelectedParameter, handleFiltro, _flagFinish, TipoMapa, handleDetalleMapa, zoom, data, divisiones, rangos, tipo, label_tipo, handleTipoMapa, derecho_mapa, handleSaveEditRoute, handleSaveRoute, Responsabilidad /* ,handleDetalleMapa, Exportar,setExport */ }) {
    const [_center, setCenter] = useState(center ? center : [20.678416, -101.354231]);
    const [_zoom, setZoom] = useState(zoom ? zoom : 12);
    const token = getVars('Token');
    const [SeccionesSelected, setSeccionesSelected] = useState([]);
    const [DistritoSelected, setDistritoSelected] = useState([0]);
    const _data = data ? data : null;
    const _divisiones = divisiones ? divisiones : null;
    const _rangos = rangos ? rangos : [];
    const flag = Object.keys(_data).length === 0 ? false : true;
    const _tipo = tipo ? tipo : 'municipio';
    //const flagFinish = _flagFinish;
    const _derecho_mapa = derecho_mapa;
    //const [TipoMapa, setTipoMapa] = useState({ value: TipoMapa.value, label: TipoMapa.label});//useState({ value: tipo, label: label_tipo });
    const [flagData] = useState(true);
    const [FlagEdit, setFlagEdit] = useState(false);

    //var FlagEdit = false;
    const [flagTabla, setFlagTabla] = useState(true);
    const [buttonDetalle, setButonDetalle] = useState(false);
    //const exportar = Exportar ? Exportar : 0;

    const [prop, setProp] = useState(
        tipo === 'municipio' ?
            { 'nom_mun': '', 'cve_mun': '' }
            : tipo === 'distrito' ?
                { 'distrito': '' } : tipo === 'seccion' ? { 'seccion': '' } : { 'poligono': '' }
    );
    function style_secciones(feature, flag) {
        let style;

        if (flag === undefined || flag !== true) {
            if (SeccionesSelected.includes(feature.properties.seccion)) {
                style = {
                    weight: 2,
                    fillColor: '#4BB543',
                    //color: '#666',
                    dashArray: '',
                    fillOpacity: 0.5
                };

            }
            else {
                style = {
                    fillColor: feature.properties.color_seccion,
                    weight: 2,
                    opacity: 1,
                    color: 'white',
                    dashArray: '3',
                    fillOpacity: 0.5
                };
            }
        }
        else {
            style = {
                weight: 5,
                fillColor: '#4BB543',
                color: '#666',
                dashArray: '',
                fillOpacity: 0.5
            };

        }
        return style;
    }

    function style(feature, flag) {

        let style;

        if (feature.properties.seccion !== undefined) {
            return style_secciones(feature, flag);
        }
        if (flag === undefined || flag !== true) {
            style = {
                //fillColor: feature.properties.color_actas,
                fillColor: '#0066FF',
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.3
            };
        }
        else {
            style = {
                weight: 5,
                color: '#666',
                dashArray: '',
                fillOpacity: 0.5
            };

        }
        return style;
    }

    function style_divisiones(feature, flag) {
        let style;

        style = {
            weight: 5,
            color: '#000',
            dashArray: '',
            fillOpacity: 0
        };
        return style;
    }

    const setFilter = (filtered) => {

        setSeccionesSelected([]);
        setDistritoSelected([0]);
        setProp({});
        setButonDetalle(false);
        handleFiltro(filtered, _tipo);
    }


    const highlightFeature_seccion_create = (e) => {
        //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.

        var layer = e.target;
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
        var distrito_selected = DistritoSelected;
        var secciones_selected = getVars('SeccionesSelected');//SeccionesSelected;


        if (distrito_selected[0] === 0) {
            distrito_selected[0] = e.target.feature.properties.distrito;
            setDistritoSelected(state => [...[], ...distrito_selected]);
        }
        if (_tipo === 'seccion')
            setProp({
                'seccion': e.target.feature.properties.seccion,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'distrito': e.target.feature.properties.distrito,
                'prop_save': e
            });
        if (e.target.feature.properties.distrito === distrito_selected[0]) {

            let array_temporal = secciones_selected.filter(seccion => seccion === e.target.feature.properties.seccion);
            if (array_temporal.length !== 0) {
                const index = secciones_selected.indexOf(e.target.feature.properties.seccion);
                if (index > -1) {
                    secciones_selected.splice(index, 1);
                }
                setSeccionesSelected(state => [...[], ...secciones_selected]);
                setVars("SeccionesSelected", secciones_selected);
                layer.setStyle(style(layer.feature, false));
            }
            else {
                secciones_selected.push(e.target.feature.properties.seccion);
                setSeccionesSelected(state => [...[], ...secciones_selected]);
                setVars("SeccionesSelected", secciones_selected);
                layer.setStyle(style(layer.feature, true));
            }

        }
        else {
            resetHighlight(e);
        }
        if (secciones_selected.length === 0) {
            distrito_selected[0] = 0;
            setDistritoSelected(state => [...[], ...distrito_selected]);
        }
    }
    const highlightFeature_seccion_edicion = (e) => {
        //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.

        var layer = e.target;
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
        var distrito_selected = DistritoSelected;
        var secciones_selected = getVars('SeccionesSelected');

     

        if (distrito_selected[0] === 0) {
            distrito_selected[0] = e.target.feature.properties.distrito;
            setDistritoSelected(state => [...[], ...distrito_selected]);
        }
        if (_tipo === 'seccion')
            setProp({
                'seccion': e.target.feature.properties.seccion,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'distrito': e.target.feature.properties.distrito,
                'prop_save': e
            });
        if (e.target.feature.properties.distrito === distrito_selected[0]) {
            let array_temporal = secciones_selected.filter(seccion => seccion === e.target.feature.properties.seccion);

            if (array_temporal.length !== 0) {
                const index = secciones_selected.indexOf(e.target.feature.properties.seccion);
                if (index > -1) {
                    secciones_selected.splice(index, 1);
                }
                //setSeccionesSelected(state => [...state.filter(seccion => seccion !== e.target.feature.properties.seccion), ...[]]);

                setVars('SeccionesSelected', secciones_selected);
                layer.setStyle(style(layer.feature, false));

            }
            else {

                //setSeccionesSelected(state => [...state, e.target.feature.properties.seccion]);
                secciones_selected.push(e.target.feature.properties.seccion);

                setVars('SeccionesSelected', secciones_selected);
                layer.setStyle(style(layer.feature, true));
            }
            setSeccionesSelected(getVars('SeccionesSelected'));

        }
        else {
            resetHighlight(e);
        }
        if (secciones_selected.length === 0) {
            distrito_selected[0] = 0;
            setDistritoSelected(state => [...[], ...distrito_selected]);
        }
    }
    const highlightFeature = (e) => {
        //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.

        var layer = e.target;
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }


        var text;
        if (layer.feature.properties.region !== undefined) {
            text = "REGIÓN " + layer.feature.properties.region;
            setProp({
                'region': e.target.feature.properties.region,
                'avance_actas': e.target.feature.properties.avance_actas,
                'color_actas': e.target.feature.properties.color_actas,
                'total_ca': e.target.feature.properties.totalcasillas_con_actas,
                'total_sa': e.target.feature.properties.totalcasillas_sin_actas,
                'total': e.target.feature.properties.totalcasillas,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e
            });
        }
        else if (layer.feature.properties.nom_mun !== undefined) {
            text = "" + layer.feature.properties.nom_mun;
            setProp({
                'nom_mun': e.target.feature.properties.nom_mun,
                'cve_mun': e.target.feature.properties.cve_mun,
                'avance_actas': e.target.feature.properties.avance_actas,
                'color_actas': e.target.feature.properties.color_actas,
                'total_ca': e.target.feature.properties.totalcasillas_con_actas,
                'total_sa': e.target.feature.properties.totalcasillas_sin_actas,
                'total': e.target.feature.properties.totalcasillas,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e

            });
        }
        else {
            text = "SECCIÓN " + layer.feature.properties.seccion;
            setProp({
                'seccion': e.target.feature.properties.seccion,
                'avance_actas': e.target.feature.properties.avance_actas,
                'color_actas': e.target.feature.properties.color_actas,
                'total_ca': e.target.feature.properties.totalcasillas_con_actas,
                'total_sa': e.target.feature.properties.totalcasillas_sin_actas,
                'total': e.target.feature.properties.totalcasillas,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e
            });
        }

        layer.bindTooltip(text + "", { permanent: false, direction: "center" });
        layer.setStyle(style(layer.feature, true));
    }
    //const clickFeature = (e) => {
    const clickFeature = (e) => {
        let FlagEditSaved = getVars('FlagEdit');
        setButonDetalle(true);
        if (e.target.feature.properties.seccion !== undefined) {
            if (e.target.feature.properties.selected === 1) {
                if (FlagEditSaved) {
                    highlightFeature_seccion_edicion(e);
                }
                else {
                    highlightFeature_seccion_create(e);
                }
            }
            else {
                if (FlagEditSaved) {
                    highlightFeature_seccion_edicion(e);
                }
            }

        }
        else {
            highlightFeature(e);
        }



        //Aqui configuramos lo que queremos que haga un feature cuando le den click.

    }
    /* const handleExport = (e)=>{
        setExport(e)
    } */
    const clickDetalle = (e) => {
        setSeccionesSelected([]);
        setDistritoSelected([0]);
        setProp({});
        setButonDetalle(false);
        if (e.latlng) {
            setCenter([e.latlng.lat, e.latlng.lng]);
        }


        var siguiente_nivel = "region";
        var siguiente_nivel_label = "Regional";
        var siguiente_nivel_clave = "";

        if (TipoMapa.value === "region") {
            //setZoom(_zoom + 1);
            siguiente_nivel = "municipio";
            siguiente_nivel_label = "Municipal";
            siguiente_nivel_clave = e.target.feature.properties.region;
        }
        if (TipoMapa.value === "municipio") {
            //setZoom(_zoom + 1);
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Seccional";
            siguiente_nivel_clave = e.target.feature.properties.cve_mun;
        }
        if (TipoMapa.value === "seccion") {
            //setZoom(_zoom + 1);
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Seccional";
            //siguiente_nivel = "manzana";
            //siguiente_nivel_label = "Manzanas";
            //siguiente_nivel_clave = e.target.feature.properties.seccion; 
            return; //CLICK NO PROGRAMADO
        }
        handleDetalleMapa({ value: siguiente_nivel, label: siguiente_nivel_label, clave: siguiente_nivel_clave });
        //setTipoMapa({ value: siguiente_nivel, label: siguiente_nivel_label });
    }
    const clickFeature_seccion = (e) => {

        highlightFeature(e)

    }

    const resetHighlight = (e) => {
        //Con esto reseteamos el color en donde paso el mouse, para que no siga marcado.
        var layer = e.target;
        layer.setStyle(style(e.target.feature));
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
    }



    const onEachFeature_seccion = (feature, layer) => {
        //Organizamos los eventos del GeoJson.
        layer.on({
            //mouseover: highlightFeature,
            //mouseout: resetHighlight,
            click: clickFeature
        });
    }

    const onEachFeature = (feature, layer) => {
        //Organizamos los eventos del GeoJson.
        layer.on({
            //mouseout: resetHighlight,
            click: clickFeature
        });
        var text;
        if (layer.feature.properties.region !== undefined) {
            text = "REGIÓN " + layer.feature.properties.region;
            layer.bindTooltip(text + "", { permanent: false, direction: "center" });
        }
        else if (layer.feature.properties.nom_mun !== undefined) {
            text = "" + layer.feature.properties.nom_mun;
            layer.bindTooltip(text + "", { permanent: false, direction: "center" });
        }
        else {
            text = "S: " + layer.feature.properties.seccion + " DF: " + layer.feature.properties.distrito;
            layer.bindTooltip(text + "", { permanent: false, direction: "center" });
        }

        //layer.bindTooltip(text + "", { permanent: false, direction: "center" });
    }


    const onEachFeatureDivisiones = (feature, layer) => {
        //Organizamos los eventos del GeoJson.
        layer.on({
            //mouseover: highlightFeature,
            //mouseout: resetHighlight,
            //click: clickFeature
        });
    }

    var geojson = (
        <GeoJSON onEachFeature={onEachFeature} style={style} key={"geojson"} data={_data}></GeoJSON>
    )
    var geojson_divisiones = (
        <GeoJSON onEachFeature={onEachFeatureDivisiones} style={style_divisiones} key={"_divisiones"} data={_divisiones}></GeoJSON>
    )


    useEffect(() => {


        setSeccionesSelected(SeccionesSelectedParameter);
        

        if (Object.keys(_data).length !== 0) {
            if(_data.features.length !== 0){
                const {zoom, center} = centerOnOneShape(flagData,_data);
                setCenter(center);
                setZoom(zoom);
            }
        }

    }, [data, rangos, _flagFinish, TipoMapa, zoom, MenuPermisos]);
    const miTipoMapaChange = (e) => {
        setButonDetalle(false);
        setSeccionesSelected([]);
        setDistritoSelected([0]);
        if (e.value !== 'poligono' && e.value !== 'seccion') {
            setCenter([20.917639, -101.091132]);
            //setZoom(8.5); Aqui puedo checar la responsabilidad para saber que zoom darle
        }
        //setTipoMapa(e);
        handleTipoMapa(e);

    }

    const handleSeccionesEditRuta = (e) => {
        setFlagEdit(true);
        setVars('SeccionesSelected', e)
        setSeccionesSelected(e);
    }

    const handleDeleteRuta = (e) => {
        handleDeletePropRuta(e);
    }

    const handleSaveRuta = (e, data) => {
        if (e) {
            handleSaveRoute(SeccionesSelected, data);
            //setSeccionesSelected([]);
        }
    }

    const forSeccionesSelected = (e) => {
        setVars('SeccionesSelected', []);
        setSeccionesSelected([]);
        ParameterSeccionesSelected([]);
    }

    const handleSaveEditRuta = (e, data) => {
        if (e) {
            handleSaveEditRoute(SeccionesSelected, data);
        }
    }
    return (
        <>
            {
                flag ?
                    MenuPermisos.Ver === 1 ?
                        <>
                            <Map style={{ zIndex: "50" }} zoom={zoom} zoomControl={false} center={_center} style={{ width: '100%', height: '90vh' }}>

                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                />
                                <div className="leaflet-control-container">
                                    <div className="leaflet-top leaflet-right">
                                        <div className="leaflet-control" style={style_square_superior}>
                                            {

                                                (TipoMapa.value === 'municipio') ?
                                                    <>
                                                        <h6>INFORMACIÓN:</h6>
                                                        {(prop.nom_mun !== undefined && prop.nom_mun !== '') ?
                                                            <>
                                                                <b style={style_b}>Municipio:</b><b style={{ color: prop.color_actas }}> {prop.nom_mun}</b><br></br>
                                                                <b style={style_b}>LNOM:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                {/* <b style={style_b}>Total de Casillas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total)}</b><br></br>
                                                        <b style={style_b}>Total de Casillas con Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_sa)}</b><br></br>
                                                        <b style={style_b}>Total de Casillas sin Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_ca)}</b><br></br>
                                                        <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color_actas }}>{prop.total_ca === 0 && prop.total === 0 ? "--" : roundTo(prop.avance_actas, 2) + "%"}</b><br></br> */}
                                                            </>
                                                            : <b> Pulse sobre un municipio</b>}
                                                    </>
                                                    :
                                                    (TipoMapa.value === 'region') ?
                                                        <>
                                                            <h6>INFORMACIÓN:</h6>
                                                            {(prop.region !== undefined && prop.region !== '') ?
                                                                <>
                                                                    <b style={style_b}>Región:</b><b style={{ color: prop.color_actas }}> {prop.region}</b><br></br>
                                                                    <b style={style_b}>LNOM:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                    {/* <b style={style_b}>Total de Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total)}</b><br></br>
                                                            <b style={style_b}>Total de Casillas con Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_ca)}</b><br></br>
                                                            <b style={style_b}>Total de Casillas sin Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_sa)}</b><br></br>
                                                            <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color_actas }}>{prop.total_ca === 0 && prop.total === 0 ? "--" : roundTo(prop.avance_actas, 2) + "%"}</b><br></br> */}

                                                                </>
                                                                : <b> Pulse sobre una region</b>}
                                                        </>
                                                        :
                                                        (TipoMapa.value === 'seccion') ?
                                                            <>
                                                                <h6>INFORMACIÓN:</h6>
                                                                {(prop.seccion !== undefined && prop.seccion !== '') ?
                                                                    <>
                                                                        <b style={style_b}>Distrito:</b><b style={{ color: prop.color }}> {prop.distrito}</b><br></br>
                                                                        <b style={style_b}>Sección:</b><b style={{ color: prop.color }}> {prop.seccion}</b><br></br>
                                                                        <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                        {/* <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                                <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                                <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                                <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta === 0 ? "--" : roundTo(prop.avance_porcentual, 2) + "%"}</b><br></br> */}

                                                                    </>
                                                                    : <b> Pulse sobre una seccion</b>}
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                            }


                                            {
                                                buttonDetalle && TipoMapa.value !== 'seccion' ?
                                                    <ButtonGroup>
                                                        <Button
                                                            style={style_button_radiogroup}
                                                            size="sm"
                                                            className="btn btn-sm btn-round"
                                                            color={'success'}
                                                            outline
                                                            type="button"
                                                            onClick={(e) => { clickDetalle(prop.prop_save) }}>Ver Detalle</Button>
                                                    </ButtonGroup> : <></>
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="leaflet-control-container">
                                    <div className="leaflet-top leaflet-left">
                                        <div className="leaflet-control" style={style_square_superior_left}>


                                            <ButtonGroup style={{ backgroundColor: '#FFF' }}>
                                                {_derecho_mapa.includes(4) ?
                                                    <Button
                                                        className="btn-round"
                                                        color={TipoMapa.value === 'region' ? 'success' : 'info'}
                                                        outline
                                                        type="button"
                                                        onClick={() => miTipoMapaChange({ value: "region", label: "Regional" })}
                                                    >
                                                        Región
                                            </Button>
                                                    :
                                                    <></>}
                                                {_derecho_mapa.includes(3) ?
                                                    <Button
                                                        className="btn-round"
                                                        color={TipoMapa.value === 'municipio' ? 'success' : 'info'}
                                                        outline
                                                        type="button"
                                                        onClick={() => miTipoMapaChange({ value: "municipio", label: "Municipal" })}
                                                    >
                                                        Municipio
                                            </Button>
                                                    :
                                                    <></>}
                                                {_derecho_mapa.includes(2) ?
                                                    <Button
                                                        className="btn-round"
                                                        color={TipoMapa.value === 'poligono' ? 'success' : 'info'}
                                                        outline
                                                        type="button"
                                                        onClick={() => miTipoMapaChange({ value: "poligono", label: "Poligonal" })}
                                                    >
                                                        Polígono
                                            </Button>
                                                    :
                                                    <></>}
                                                {_derecho_mapa.includes(1) ?
                                                    <Button
                                                        className="btn-round"
                                                        color={TipoMapa.value === 'seccion' ? 'success' : 'info'}
                                                        outline
                                                        type="button"
                                                        onClick={() => miTipoMapaChange({ value: "seccion", label: "Seccional" })}
                                                    >
                                                        Seccion
                                            </Button>
                                                    :
                                                    <></>}
                                            </ButtonGroup>

                                        </div>
                                    </div>
                                </div>

                                {geojson_divisiones}
                                {geojson}

                            </Map>
                            <div style={{
                                position: 'absolute',
                                bottom: '25px',
                                right: '10px'
                            }}
                                id="lcmap" name="lcmap" className="leaflet-control-container">
                                <div className="leaflet-bottom leaflet-right">
                                    <div className="leaflet-control" style={style_square_inferior_tabla}
                                    >
                                        <Button
                                            className="btn-round btn-sm"
                                            color={'success'}
                                            outline
                                            size="sm"
                                            type="button"
                                            onClick={() => setFlagTabla(!flagTabla)}
                                        >
                                            {(flagTabla) ? "Esconder" : "Mostrar"}
                                        </Button>
                                        {

                                            (flagTabla) ?
                                                <>
                                                    {/* {exportar === 1 ? (
                                                <Button
                                                    className="btn-round btn-sm"
                                                    color={'success'}
                                                    outline
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {handleExport(_data)}}
                                                >
                                                    Exportar
                                                </Button> 
                                                ):<></>
                                            } */}
                                                    {TipoMapa.value === 'seccion' ?
                                                        <div

                                                        >
                                                            <SeccionesCard
                                                                token={token}
                                                                RGRutaCrear={RGsRutaParameter}
                                                                Responsabilidad={Responsabilidad}
                                                                MenuPermisosParameter={MenuPermisos}
                                                                distrito_actual={DistritoSelected[0]}
                                                                secciones_array={SeccionesSelected}
                                                                handleSaveRuta={handleSaveRuta}
                                                                handleSaveEditRuta={handleSaveEditRuta}
                                                                handleSeccionesEdit={handleSeccionesEditRuta}
                                                                handleDeleteRuta={handleDeleteRuta}
                                                                HandleSeccionesSelectedReset={() => { setSeccionesSelected(state => [...[], ...[]]); forSeccionesSelected([]); }}
                                                            ></SeccionesCard>
                                                        </div>
                                                        :
                                                        <>
                                                            <Row>
                                                                <Col
                                                                    style={{
                                                                        width: '300pt',
                                                                    }}
                                                                >
                                                                    <Card>
                                                                        <CardHeader>
                                                                            <p align="center">
                                                                                <strong>RUTAS ELECTORALES</strong>
                                                                            </p>

                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col>
                                                                                    <p align="center">
                                                                                        <strong>¡Las rutas solo se pueden crear a nivel sección!</strong>
                                                                                    </p>

                                                                                </Col>
                                                                            </Row>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </>}

                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>

                        </>
                        :
                        <SinPermisos></SinPermisos>

                    :

                    <div style={style_spinner}>
                        <MagicSpinner size={70} color={"#008000"} loading={true}></MagicSpinner>
                    </div>


            }
            <PluginFilterMap
                flagFinish={_flagFinish}
                filtrar={setFilter}
                clasess="dropdowns"
                Responsabilidad={Responsabilidad}
            />
        </>
    );
}

export default ChoropethMap;
