
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardHeader,  CardBody } from "reactstrap";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";

import Notifications, { notify } from "react-notify-toast";
import { CircleSpinner } from "react-spinners-kit";
import Select from "react-select";
import ChartProductividad from 'components/Charts/Tablero/ChartProductividad.jsx';
import ChartRezago from 'components/Charts/Tablero/ChartRezagoCaptura'
import EjecutarApi from '../../data/getPOST';
import { getVars } from "../../GlobalStorage";
import ChartPorPoligono from 'components/Charts/Tablero/ChartPorPoligono';
import ChartPorSeccion from 'components/Charts/Tablero/ChartPorSeccion';
import ChartPorManzana from 'components/Charts/Tablero/ChartPorManzana';

const GraficasTablero = props => {
    const mainPanel = useRef();
    const token = getVars("Token").access_token;
    const [loading, setLoading] = useState(false);
    const [catMpios, setCatMpios] = useState([
        { label: 'Filtrar', value: 0 }]);
    const [dataProductividad, setdataProductividad] = useState([]);
    const [categorias,setCategorias] = useState(['13Abril', '14Abril', '15Abril', '16Abril', '17Abril', '18Abril', '19Abril', '20Abril', '21Abril', '22Abril','23Abril','24Abril']);

    const [categoriasReal,setCategoriasReal] = useState([]);
    //const [Mpio, setMpio] = useState({ label: 'Abasolo', value: 1 });

    const [dataRezago, setdataRezago] = useState([]);
    const [dataInactividad, setdataInactividad] = useState([]);
    const [loadingChart, setLoadingChart] = useState(true);
    const [misAvancesChartItems, setMisAvancesChartItems] = useState([]);
    const [state, setState] = useState({ idRegion: { value: null, label: 'Seleccione' }, 
            idMunicipio: { label: 'Seleccione', value: 0 }, 
            dMunicipio: { label: 'Seleccione', value: 0 },
            Tipo: { label: 'Seccional', value: 2 } });

    const [MetaLinePoligono, setMetaLinePoligono] = useState(null);
    const [charSubtitlePoligono, setChartSubtitlePoligono] = useState(null);
    const [charCategoriasPoligono, setChartCategoriasPoligono] = useState(null);
    const [charAvancePoligono, setChartAvanceSPoligono] = useState(null);
    const [charMetaPoligono, setChartMetaPoligono] = useState(null);
    const [dataChartPoligono, setDataChartPoligono] = useState([]);
    const [seriesPoligono, setSeriesPoligono] = useState([]);

    const [MetaLineSeccion, setMetaLineSeccion] = useState(null);
    const [charSubtitleSeccion, setChartSubtitleSeccion] = useState(null);
    const [charCategoriasSeccion, setChartCategoriasSeccion] = useState(null);
    const [charAvanceSeccion, setChartAvanceSeccion] = useState(null);
    const [charMetaSeccion, setChartMetaSeccion] = useState(null);
    const [dataChart, setDataChart] = useState([]);
    const [series, setSeries] = useState([]);

    const [MetaLineManzanero, setMetaLineManzanero] = useState(null);
    const [charSubtitleManzanero, setChartSubtitleManzanero] = useState(null);
    const [charCategoriasManzanero, setChartCategoriasManzanero] = useState(null);
    const [charAvanceManzanero, setChartAvanceManzanero] = useState(null);
    const [charMetaManzanero, setChartMetaManzanero] = useState(null);
    const [dataChartManzanero, setDataChartManzanero] = useState([]);
    const [seriesManzanero, setSeriesManzanero] = useState([]);
    
    const [misAvancesChart, setMisAvancesChart] = useState([]);
    const [isChartView, setIsChartView] = useState(true);
    const [catRegionesFilter, setCatRegionesFilter] = useState([]);
    const [catMunicipios, setCatMunicipios] = useState([]);
    const [catMunicipiosFilter, setCatMunicipiosFilter] = useState([]);
    const [catRegiones, setCatRegiones] = useState([]);
    const [Titulo,setTitulo]=useState("");

    useEffect(() =>{
        let region ={};
        EjecutarApi({}, "getCatalogosFiltrosPromocion", token, "POST")
        .then(res => {
            if (res.results) {
                if (res.data) {
                    setCatRegiones(res.data.regiones);
                    region = res.data.regiones[0];
                    var fmunicipios = res.data.municipios.filter(fil => (fil.idRegion === region.value ));
                    
                    setCatMunicipiosFilter(fmunicipios);
                    setState({ ...state, idRegion: region, dMunicipio:fmunicipios[0], idMunicipio:fmunicipios[0]});
                    setCatMunicipios(res.data.municipios);
                    setCatRegionesFilter(res.data.regiones);
                }
            }
        }).catch((e) => {
            setLoading(false);
        })
        .finally(x => {
            setLoading(false);
        })

    
    },[])
   
    useEffect(() => {
        let tipo = state.Tipo;
        let idMunicipio = state.dMunicipio.value;
  
          getDataChart({ idMunicipio: idMunicipio },"getPoligonalesByMunicipioDia");
          getDataChart({ idMunicipio: idMunicipio }, "getSeccionalesByMunicipioDia");
          getDataChart({ idMunicipio: idMunicipio }, "getManzanerasByMunicipioDia");
        
        if(state.idMunicipio.value>0)
        {
            graficas(null,state.idMunicipio)
        }else if (state.idRegion.value)
        {
            graficas(state.idRegion,null)
        }
        
    }, [state])
    
    useEffect(() => {

      }, [misAvancesChartItems])

    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };
    const changeMunicipio = (idMunicipio) => {
        setLoading(true)
        if (idMunicipio.value > 1) {
            setState({ ...state, idRegion:state.idRegion, dMunicipio: idMunicipio, idMunicipio: idMunicipio });
            graficas(0,idMunicipio)

        } else {
            setState({ ...state, idRegion: state.idRegion , dMunicipio: state.dMunicipio ,idMunicipio: state.idMunicipio });
            setCatMunicipiosFilter(catMunicipiosFilter);
        }
    }

    const getDataChart = (data, apiName) => {
        setLoadingChart(true);

       if(apiName === 'getSeccionalesByMunicipioDia'){
            EjecutarApi(data, apiName, token)
            .then(function (res) {
              return res;
            })
            .then((res) => {
              if (res.success) {
                if (res.results) {
                  const Categorias = [];
                  const Avance = [];
                  const Meta = [];
                  res.data.forEach(item => {
                    Categorias.push(`${item.Dia}`);
                    Avance.push(item.Acumulado);
                    Meta.push(parseInt(item.MetaEstructuraPartido));
                    setMetaLineSeccion(item.MetaEstructuraPartido);
                    setChartSubtitleSeccion(`SECCIONAL DE  ${item.Municipio}`);
                  })
                  setChartCategoriasSeccion(Categorias);
                  setChartAvanceSeccion(Avance);
                  setChartMetaSeccion(Meta);
      
                  setDataChart(res.data.data);
                  setSeries(res.data.series);
                }
              }
            })
            .catch((e) => {
              notify.show(`Error al obtener datos de la gráfica`, "error", 5000);
      
            })
            .finally(x => {
              setLoadingChart(false);
            })
        }
        else if(apiName === 'getPoligonalesByMunicipioDia'){
            EjecutarApi(data, apiName, token)
            .then(function (res) {
              return res;
            })
            .then((res) => {
              if (res.success) {
                if (res.results) {
                  const Categorias = [];
                  const Avance = [];
                  const Meta = [];
                  res.data.forEach(item => {
                    Categorias.push(`${item.Dia}`);
                    Avance.push(item.Acumulado);
                    Meta.push(parseInt(item.MetaEstructuraPartido));
                    setMetaLinePoligono(parseInt(item.MetaEstructuraPartido));
                    setChartSubtitlePoligono(`POLIGONAL DE ${item.Municipio}`);
                  })
                  setChartCategoriasPoligono(Categorias);
                  setChartAvanceSPoligono(Avance);
                  setChartMetaPoligono(Meta);
      
                  setDataChartPoligono(res.data.data);
                  setSeriesPoligono(res.data.series);
                }
              }
            })
            .catch((e) => {
              notify.show(`Error al obtener datos de la gráfica`, "error", 5000);
      
            })
            .finally(x => {
              setLoadingChart(false);
            })
        }
        else if(apiName === 'getManzanerasByMunicipioDia'){
            EjecutarApi(data, apiName, token)
            .then(function (res) {
              return res;
            })
            .then((res) => {
              if (res.success) {
                if (res.results) {
                  const Categorias = [];
                  const Avance = [];
                  const Meta = [];
                  res.data.forEach(item => {
                    Categorias.push(`${item.Dia}`);
                    Avance.push(item.Acumulado);
                    Meta.push(parseInt(item.MetaEstructuraPartido));
                    setMetaLineManzanero(parseInt(item.MetaEstructuraPartido));
                    setChartSubtitleManzanero(`MANZANERO DE ${item.Municipio}`);
                  })
                  setChartCategoriasManzanero(Categorias);
                  setChartAvanceManzanero(Avance);
                  setChartMetaManzanero(Meta);
      
                  setDataChartManzanero(res.data.data);
                  setSeriesManzanero(res.data.series);
                }
              }
            })
            .catch((e) => {
              notify.show(`Error al obtener datos de la gráfica`, "error", 5000);
      
            })
            .finally(x => {
              setLoadingChart(false);
            })
        }
     
    }
    
    const changeRegion = (idRegion) => {
        if (idRegion.value > 1) {
            setState({ ...state, idRegion, dMunicipio: { label: 'Seleccione', value: 0 } });
            const misMunis = catMunicipios.filter(data => data.idRegion === idRegion.value);
            setState({ ...state, idRegion, dMunicipio: misMunis[0] , idMunicipio: misMunis[0] });
            setCatMunicipiosFilter(misMunis);
           
            graficas(0,misMunis[0])

        } else {
            const misMunis = catMunicipios.filter(data => data.idRegion === state.idRegion);
            setState({ ...state, idRegion: state.idRegion , dMunicipio: misMunis[0] ,idMunicipio: misMunis[0] });
            setCatMunicipiosFilter(misMunis);
        }
    }
    const graficas =(Region,Municipio)=>{
        setLoading(true)
        let parametro={};
        if (Region)
        {
            parametro={idRegion: Region.value}
            setTitulo(Region.label);
        }else if(Municipio)
        {
            parametro={idMunicipio: Municipio.value}
            setTitulo(Municipio.label);
        }
        else {
            return;
        }
        EjecutarApi(parametro, "getProductividadRealTime", token, "POST")
            .then(function (res) {
                return res;
            })
            .then(res => {
                if (res.results) {
                    if (res.data) {
                        var filtrado = res.data.series//.filter(fil => (fil.name !== 'Meta Compromiso'))
                        setCategoriasReal(res.data.options.xAxis.categories);
                        setdataProductividad(filtrado)
                        setLoading(false);
                    }
                }

            })
            .catch((e) => {
                setLoading(false);
            })
        setLoading(true)
        EjecutarApi(parametro, "getRezagoCaptura", token, "POST")
            .then(function (res) {
                return res;
            })
            .then(res => {
                if (res.results) {
                    if (res.data) {
                        let SeccionalRezagoAnterior = [];
                        res.data.map(item => SeccionalRezagoAnterior.push(parseInt(item.SeccionalResagoAnterior,10)));
                        let CompromisoAnterior = [];
                        res.data.map(item => CompromisoAnterior.push(parseInt(item.CompromisoAnterior,10)));
                        let JMNZ1Rezago = [];
                        res.data.map(item => JMNZ1Rezago.push(parseInt(item.JMNZ1Resago,10)));

                        setdataRezago([
                            {
                                name: 'Rezago Seccional',
                                data: SeccionalRezagoAnterior
                            },
                            {
                                name: 'Rezago Manzanas',
                                data: JMNZ1Rezago
                            },
                            {
                                name: 'Rezago Compromisos',
                                data: CompromisoAnterior
                            }
                        ])
                        setLoading(false);
                    }
                }

            })
            .catch((e) => {
                setLoading(false);
            })

        setLoading(true)
        EjecutarApi(parametro, "getInactividadEstructura", token, "POST")
            .then(function (res) {
                return res;
            })
            .then(res => {
                if (res.results) {
                    if (res.data) {

                        let SeccionalTrabaja = [];
                        res.data.map(item => SeccionalTrabaja.push(item.SeccionalTrabaja));
                        let SeccionalNvosTrabaja = [];
                        res.data.map(item => SeccionalNvosTrabaja.push(item.SeccionalNvosTrabaja));
                        let JMNZ1Trabajado = [];
                        res.data.map(item => JMNZ1Trabajado.push(item.JMNZ1Trabajado));
                        let JMN1Nuevos = [];
                        res.data.map(item => JMN1Nuevos.push(item.JMN1Nuevos));

                        setdataInactividad([
                            {
                                name: 'Seccional que salieron a trabajar',
                                data: SeccionalTrabaja
                            },
                            {
                                name: 'Seccionales Nuevos',
                                data: SeccionalNvosTrabaja
                            },
                            {
                                name: 'Manzanera que salieron a trabajar',
                                data: JMNZ1Trabajado
                            }
                            ,
                            {
                                name: 'Manzanera Nuevos',
                                data: JMN1Nuevos
                            }
                        ])
                        setLoading(false);
                    }
                }

            })
            .catch((e) => {
                setLoading(false);
            })

    }

    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel" ref={mainPanel}>
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Col sm="12" md="12" lg="12">
                        <Row>
                            <Col sm="12" md="12" lg="12" className="text-muted">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {/* <Col sm="4" className="text-muted">Tipo:
                                                {loading && (
                                                    <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                    />
                                                )}            
                                                {!loading && (<Select
                                                    options={[{ label: 'Poligonal', value: 1 }, { label: 'Seccional', value: 2 }, { label: 'Manzanero', value: 3 }]}
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    value={state.Tipo}
                                                    onChange={(e) => setState({ ...state, Tipo: e })}
                                                    id="viewTypeM"
                                                    placeholder="Tipo"
                                                />)}
                                            </Col> */}
                                            <Col sm="4" className="text-muted">Región:
                                                {loading && (
                                                <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catRegionesFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={state.idRegion}
                                                onChange={(e) => changeRegion(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                            </Col>
                                            <Col sm="4" className="text-muted">Municipio:
                                                {loading && (
                                            <CircleSpinner
                                            className="text-center"
                                            size={30}
                                            color="#686769"
                                            loading={loading}
                                            />
                                        )}            {!loading && (<Select
                                            options={catMunicipiosFilter}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={state.idMunicipio}
                                            onChange={(e) => changeMunicipio(e)}
                                            id="viewTypeM"
                                            placeholder="Filtrar por"
                                        />)}
                                        </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <Card>
                                    <CardBody>
                                        <h5 className="title"></h5>
                                        <Row id="container">
                                            {dataRezago && <div style={{ width: '100%' }}>
                                                <ChartProductividad Categorias={categoriasReal} Series={dataProductividad} TituloMpio={Titulo} /></div>}
                                            {!dataRezago && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={true}
                                                />
                                            )}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Card>
                                    <CardBody>
                                        <h5 className="title"></h5>
                                        <Row id="container">
                                            {dataRezago && <div style={{ width: '100%' }}>
                                                <ChartRezago Categorias={categorias} Series={dataRezago} TituloMpio={Titulo} Titulo="Gráfica Rezagos Captura" /></div>}
                                            {!dataRezago && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={true}
                                                />
                                            )}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Card>
                                    <CardBody>
                                        <h5 className="title"></h5>
                                        <Row id="container">
                                            {dataInactividad && <div style={{ width: '100%' }}>
                                                <ChartRezago Categorias={categorias} Series={dataInactividad} TituloMpio={Titulo} Titulo="Inactividad Línea de tiempo" /></div>}
                                            {!dataInactividad && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={true}
                                                />
                                            )}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Card>
                                    <CardBody>
                                    {misAvancesChart}
                                    <Row>
                                        {<Col sm="12">{isChartView && (
                                        !loadingChart ? <ChartPorPoligono
                                            yAxisText="Poligonales"
                                            MetaLine={MetaLinePoligono}
                                            Categorias={charCategoriasPoligono}
                                            Subtitle={charSubtitlePoligono}
                                            Avance={charAvancePoligono}
                                            Meta={charMetaPoligono}
                                        /> :
                                            (
                                            <Row>
                                                <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loadingChart}
                                                /></Row>))}
                                        </Col>}
                                        </Row>
                                    </CardBody>            
                                </Card>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Card>
                                    <CardBody>
                                    {misAvancesChart}
                                    <Row>
                                        { <Col sm="12">{isChartView && (
                                        !loadingChart ? <ChartPorSeccion
                                            yAxisText="Seccionales"
                                            MetaLine={MetaLineSeccion}
                                            Categorias={charCategoriasSeccion}
                                            Subtitle={charSubtitleSeccion}
                                            Avance={charAvanceSeccion}
                                            Meta={charMetaSeccion}
                                        /> :
                                            (
                                            <Row>
                                                <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loadingChart}
                                                /></Row>))}
                                        </Col>}
                                        </Row>
                                    </CardBody>            
                                </Card>
                                </Col>
                                <Col sm="6" md="6" lg="6">
                                <Card>
                                    <CardBody>
                                    {misAvancesChart}
                                    <Row>
                                        { <Col sm="12">{isChartView && (
                                        !loadingChart ? <ChartPorManzana
                                            yAxisText="Manzaneras"
                                            MetaLine={MetaLineManzanero}
                                            Categorias={charCategoriasManzanero}
                                            Subtitle={charSubtitleManzanero}
                                            Avance={charAvanceManzanero}
                                            Meta={charMetaManzanero}
                                        /> :
                                            (
                                            <Row>
                                                <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loadingChart}
                                                /></Row>))}
                                        </Col>}
                                    </Row>
                                    </CardBody>            
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </div>
                <Footer fluid />
            </div>
        </div>
    )
}
export default GraficasTablero;