import React, { useEffect, useState, useCallback } from 'react';

import { URL_GENERAL } from "../../data/Consts";
import EjecutarApi from '../../data/getPOST';
import { getVars } from '../../GlobalStorage';

import ReactTable from 'react-table';
import {
  Spinner,
  Card,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  CardBody,
  CardHeader,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import Notifications, { notify } from 'react-notify-toast';
import Pagination from 'components/Pagination/pagination';
import swal from 'sweetalert';

const CasillasListJuridicos = (props) => {
  const token = getVars('Token');
  const [menu, _setmenu] = useState({});
  const [catalogos, setCatalogos] = useState({
    municipios: [],
    dls: [],
    dfs: [],
    tiposEleccion: [],
    secciones: [],
  });
  const [catalogosFiltered, setCatalogosFiltered] = useState({
    municipios: [],
    dls: [],
    dfs: [],
    tiposEleccion: [],
    secciones: [],
  });
  const [filtro, setFiltro] = useState({
    tipoEleccion: { value: 0, label: 'Sin seleccionar' },
    municipio: { value: 0, label: 'Todos' },
    dl: { value: 0, label: 'Todos' },
    df: { value: 0, label: 'Todos' },
    seccion: { value: 0, label: 'Todas' },
  });
  const [formatedFilter, setFormatedFilter] = useState({
    tipoEleccion: { value: 0, label: 'Sin seleccionar' },
    filters: [],
  });
  const [isLoadingCatalogos, setIsLoadingCatalogos] = useState(true);
  const [isLoadingTabla, setIsLoadingTabla] = useState(true);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getCatalogs = () => {
      EjecutarApi(
        {
          catalogos: [
            'municipios',
            'dfs',
            'dls',
            'secciones',
            'tipos_eleccion',
          ],
        },
        'getCatalogosSije',
        token.access_token,
        'POST'
      )
        .then(function (res) {
          return res;
        })
        .then((response) => {
          if (response.success && response.results) {
            let catalogs = {};
            catalogs.municipios = response.data.municipios;
            catalogs.dls = [{ value: 0, label: 'Todos' }].concat(
              response.data.dls
            );
            catalogs.dfs = [{ value: 0, label: 'Todos' }].concat(
              response.data.dfs
            );
            catalogs.tiposEleccion = response.data.tipos_eleccion;
            catalogs.secciones = [{ value: 0, label: 'Todos' }].concat(
              response.data.secciones
            );
            setCatalogos(catalogs);
            setCatalogosFiltered(catalogs);
            setFiltro((prevState) => {
              return { ...prevState, tipoEleccion: catalogs.tiposEleccion[0] };
            });
            const data = {
              page: 0,
              pageSize: 10,
              tipoEleccion: catalogs.tiposEleccion[0].value,
            };
            getRegistros(data);
          } else {
            swal({
              title: 'Error',
              text: `Hubo un problema: ${response.message}, contacte al administrador  `,
              icon: 'warning',
              dangerMode: true,
            });
          }
          setIsLoadingCatalogos(false);
        })
        .catch((e) => {
          setIsLoadingCatalogos(false);
          swal({
            title: 'Error',
            text: `Hubo un problema: ${e}, contacte al administrador  `,
            icon: 'warning',
            dangerMode: true,
          });
        });
    };
    getCatalogs();
  }, []);

  const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
    const data = {
      page: page,
      pageSize: pageSize,
      filtered: params.filters,
      sorted: sorted,
      tipo: 'and',
      tipoEleccion: params.tipoEleccion.value,
    };
    if (params.tipoEleccion.value > 0) getRegistros(data);
  }, []);

  const getRegistros = (data) => {
    setIsLoadingTabla(true);
    EjecutarApi(
      {
        ...data,
        Export: false,
      },
      'getCasillasResultadosJuridico',
      token.access_token,
      'POST'
    )
      .then(function (res) {
        return res;
      })
      .then((response) => {
        if (response.success && response.results) {
          setColumns(response.columns);
          setData(response.data);
          setTotal(response.total);
        } else {
          swal({
            title: 'Error',
            text: `Hubo un problema: ${response.message}, contacte al administrador  `,
            icon: 'warning',
            dangerMode: true,
          });
        }
        setIsLoadingTabla(false);
      })
      .catch((e) => {
        setIsLoadingTabla(false);
        swal({
          title: 'Error',
          text: `Hubo un problema: ${e}, contacte al administrador  `,
          icon: 'warning',
          dangerMode: true,
        });
      });
  };

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const handleFilter = () => {
    let filters = [];
    if (filtro.municipio.value > 0) {
      filters.push({
        id: 'idMunicipio',
        value: filtro.municipio.value,
      });
    }
    if (filtro.df.value > 0) {
      filters.push({
        id: 'idDF',
        value: filtro.df.value,
      });
    }
    if (filtro.dl.value > 0) {
      filters.push({
        id: 'idDL',
        value: filtro.dl.value,
      });
    }
    if (filtro.seccion.value > 0) {
      filters.push({
        id: 'Seccion',
        value: filtro.seccion.value,
      });
    }
    const data = {
      page: 0,
      pageSize: 10,
      tipoEleccion: filtro.tipoEleccion.value,
      filtered: filters,
    };
    setFormatedFilter({ ...formatedFilter, filters });
    getRegistros(data);
  };

  const cleanFilter = () => {
    setFiltro({
      ...filtro,
      municipio: { value: 0, label: 'Todos' },
      dl: { value: 0, label: 'Todos' },
      df: { value: 0, label: 'Todos' },
      seccion: { value: 0, label: 'Todas' },
    });
    setFormatedFilter({ ...formatedFilter, filters: [] });
    const data = {
      page: 0,
      pageSize: 10,
      tipoEleccion: filtro.tipoEleccion.value,
      filtered: [],
    };
    getRegistros(data);
  };

  const handleChangeCatalogs = (value, id) => {
    let newState = {};
    let seccionesFiltradas;
    switch (id) {
      case 'municipio':
        newState = {
          df: { value: 0, label: 'Todos' },
          municipio: value,
          dl: { value: 0, label: 'Todos' },
          seccion: { value: 0, label: 'Todas' },
        };
        seccionesFiltradas = [{ value: 0, label: 'Todas' }].concat(
          catalogos.secciones.filter(
            (seccion) => seccion.idMunicipio === value.value
          )
        );
        break;
      case 'df':
        newState = {
          df: value,
          municipio: { value: 0, label: 'Todos' },
          dl: { value: 0, label: 'Todos' },
          seccion: { value: 0, label: 'Todas' },
        };
        seccionesFiltradas = [{ value: 0, label: 'Todas' }].concat(
          catalogos.secciones.filter((seccion) => seccion.idDf === value.value)
        );
        break;
      case 'dl':
        newState = {
          df: { value: 0, label: 'Todos' },
          municipio: { value: 0, label: 'Todos' },
          dl: value,
          seccion: { value: 0, label: 'Todas' },
        };
        seccionesFiltradas = [{ value: 0, label: 'Todas' }].concat(
          catalogos.secciones.filter((seccion) => seccion.idDL === value.value)
        );
        break;
    }
    setCatalogosFiltered({
      ...catalogosFiltered,
      secciones: seccionesFiltradas,
    });
    setFiltro({ ...filtro, ...newState });
  };

  const Export = () => {
    const data = {
      tipo:'and',
      filtered: formatedFilter.filters,
      Export: true,
      tipoEleccion: filtro.tipoEleccion.value
    };
    var datos = JSON.stringify(data)
    var url = new URL(
        `${URL_GENERAL}exportCasillasResultadosJuridico?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");

  };

  return (
    <>
      <div className="wrapper">
        {' '}
        <Sidebar
          {...props}
          menus={token.menus}
          bgColor="black"
          activeColor="info"
        />
        <div className="main-panel">
          <Navbar
            {...props}
            handleMiniClick={handleMiniClick}
            menus={token.menus}
          />
          <Notifications />
          <div className="content">
            <Card>
              {isLoadingCatalogos && (
                <center>
                  <Spinner />
                </center>
              )}
              {!isLoadingCatalogos && (
                <CardHeader>
                  <Form>
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <Label>Tipo de elección</Label>
                          <Select
                            options={catalogos.tiposEleccion}
                            isLoading={isLoadingCatalogos}
                            value={filtro.tipoEleccion}
                            onChange={(e) => {
                              setFiltro({
                                ...filtro,
                                tipoEleccion: e,
                                municipio: { value: 0, label: 'Todos' },
                                dl: { value: 0, label: 'Todos' },
                                df: { value: 0, label: 'Todos' },
                                seccion: { value: 0, label: 'Todas' },
                              });
                              setFormatedFilter({
                                ...formatedFilter,
                                tipoEleccion: e,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {filtro.tipoEleccion.value === 3 && (
                        <Col sm="4">
                          <FormGroup>
                            <Label>Distrito federal</Label>
                            <Select
                              options={catalogos.dfs}
                              isLoading={isLoadingCatalogos}
                              value={filtro.df}
                              onChange={(e) => {
                                handleChangeCatalogs(e, 'df');
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {filtro.tipoEleccion.value === 5 && (
                        <Col sm="4">
                          <FormGroup>
                            <Label>Distrito local</Label>
                            <Select
                              options={catalogos.dls}
                              isLoading={isLoadingCatalogos}
                              value={filtro.dl}
                              onChange={(e) => {
                                handleChangeCatalogs(e, 'dl');
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {filtro.tipoEleccion.value === 6 && (
                        <Col sm="4">
                          <FormGroup>
                            <Label>Municipio</Label>
                            <Select
                              options={catalogos.municipios}
                              isLoading={isLoadingCatalogos}
                              value={filtro.municipio}
                              onChange={(e) => {
                                handleChangeCatalogs(e, 'municipio');
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {filtro.tipoEleccion.value > 0 && (
                        <Col sm="4">
                          <FormGroup>
                            <Label>Sección</Label>
                            <Select
                              options={catalogosFiltered.secciones}
                              isLoading={isLoadingCatalogos}
                              value={filtro.seccion}
                              onChange={(e) => {
                                setFiltro({
                                  ...filtro,
                                  seccion: e,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col sm="4">
                        <Button
                          size="sm"
                          color="primary"
                          onClick={(e) => handleFilter()}
                        >
                          <i
                            className="fa fa-filter fa-lg"
                            aria-hidden="true"
                          ></i>
                          Filtrar
                        </Button>{' '}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={(e) => cleanFilter()}
                        >
                          <i
                            className="fa fa-times fa-lg"
                            aria-hidden="true"
                          ></i>
                          Limpiar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardHeader>
              )}
              <CardBody style={{ marginTop: '5%' }}>
                <Button color="primary" size="sm" onClick={() => Export()}>
                  <span className="btn-label">
                    <i className="fa fa-file-excel-o fa-lg" />
                  </span>
                  Exportar
                </Button>
                {isLoadingTabla && (
                  <center>
                    <Spinner />
                  </center>
                )}
                <div style={{ width: '100%', overflow: 'auto' }}>
                  <ReactTable
                    style={{
                      width: '400%',
                      height: '100%',
                    }}
                    size="sm"
                    data={data}
                    noDataText="Sin información"
                    manual
                    columns={columns}
                    className="-striped -highlight primary-pagination"
                    loading={isLoadingTabla}
                    showPagination={true}
                    showPaginationBottom={true}
                    showTotalPages={true}
                    showPageJump={false}
                    canNextFromData={true}
                    defaultPageSize={10}
                    total={total}
                    pages={Math.floor(total / 10)}
                    previousText="Anterior"
                    nextText="Siguiente"
                    ofText="de"
                    pageSizeOptions={[10, 20, 25, 50, 100]}
                    PaginationComponent={Pagination}
                    onFetchData={fetchData}
                    params={formatedFilter}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default CasillasListJuridicos;
