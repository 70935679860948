import React, { useEffect, useState } from "react";
import { Form, Button, Label, FormGroup, Input, Row } from "reactstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { notify } from "react-notify-toast";
import ReactBSAlert from "react-bootstrap-sweetalert";

import moment from "moment";
import { CircleSpinner } from "react-spinners-kit";

import { getVars } from "../../GlobalStorage";
import EjecutarApi from "../../data/getPOST";

function DetalleUsuario({ show, onHide, data, catalogos, loaded }) {

  const [compromiso, setCompromiso] = useState({
    id: data.id,
    Email: data.Email,
    PinCode: data.PinCode ? data.PinCode : '',
    Nombre: data.Nombre ? data.Nombre : '',
    Paterno: data.Paterno ? data.Paterno : '',
    Materno: data.Materno ? data.Materno : '',
    INE: data.INE ? data.INE : '',
    EstadoNacimiento: data.EstadoNacimiento ? data.EstadoNacimiento : '', //value & label
    Nivel: data.Nivel ? data.Nivel : '',
    MunicipioVota: data.MunicipioVota ? data.MunicipioVota : '',
    SeccionVota: data.SeccionVota ? data.SeccionVota : '',
    CalleVota: data.CalleVota ? data.CalleVota : '',
    NumExtVota: data.NumExtVota ? data.NumExtVota : '',
    NumIntVota: data.NumIntVota ? data.NumIntVota : '',
    CPVota: data.CPVota ? data.CPVota : '',
    ColoniaVota: data.ColoniaVota ? data.ColoniaVota : '',
    CalleVive: data.CalleVive ? data.CalleVive : '',
    NumExtVive: data.NumExtVive ? data.NumExtVive : '',
    NumIntVive: data.NumIntVive ? data.NumIntVive : '',
    CPVive: data.CPVive ? data.CPVive : '',
    ColoniaVive: data.ColoniaVive ? data.ColoniaVive : '',
    FechaNacimiento: data.FechaNacimiento ? data.FechaNacimiento : '',
    MunicipioVive: data.MunicipioVive ? data.MunicipioVive : '',
    Sexo: data.Sexo ? data.Sexo : '',
    Celular: data.Celular ? data.Celular : '',
    Telefono: data.Telefono ? data.Telefono : '',
    TelMensajes: data.TelMensajes ? data.TelMensajes : '',
    CURP: data.CURP ? data.CURP : '',
    hashCode: data.hashCode ? data.hashCode : '',
    hashValidate: data.hashValidate ? data.hashValidate : '',
    DefaultPage: data.DefaultPage ? data.DefaultPage : '',
    DeviceID: data.DeviceID ? data.DeviceID : '',
    DeviceOS: data.DeviceOS ? data.DeviceOS : '',
    Token: data.Token ? data.Token : '',
    Voluntario: data.Voluntario ? data.Voluntario : '', //value,label
    PerfilUser: data.PerfilUser ? data.PerfilUser : '',
    NivelEstudios: data.NivelEstudios ? data.NivelEstudios : '', //value,label
    ExperienciaElectoral: data.ExperienciaElectoral ? data.ExperienciaElectoral : '',
    ExperienciaDonde: data.ExperienciaDonde ? data.ExperienciaDonde : '',
    ExperienciaAnio: data.ExperienciaAnio ? data.ExperienciaAnio : '',
    Correo: data.Correo ? data.Correo : '',
    AcercaDe: data.AcercaDe ? data.AcercaDe : '',
    CelInvito: data.CelInvito ? data.CelInvito : '',
    NombreInvito: data.NombreInvito ? data.NombreInvito : '',
    UserValido: data.UserValido ? data.UserValido : '',
    FechaValido: data.FechaValido ? data.FechaValido : '',
    UserBloqueo: data.UserBloqueo ? data.UserBloqueo : '',
    FechaBloqueo: data.FechaBloqueo ? data.FechaBloqueo : '',
    Estatus: data.Estatus ? data.Estatus : '',
    PerfilSistema: data.PerfilSistema ? data.PerfilSistema : '',
    TituloObtenido: data.TituloObtenido ? data.TituloObtenido : '',
    SituacionLaboral: data.SituacionLaboral ? data.SituacionLaboral : '',
    isVoluntario: data.isVoluntario ? data.isVoluntario : '',
    MismaAddress: data.MismaAddress ? data.MismaAddress : '',
    EmpresaLabora: data.EmpresaLabora ? data.EmpresaLabora : '',
    UserCreate: data.UserCreate ? data.UserCreate : '',
    FechaCreate: data.FechaCreate ? data.FechaCreate : '',
    UserUpdate: data.UserUpdate ? data.UserUpdate : '',
    LastUpdate: data.LastUpdate ? data.LastUpdate : '',
    foto64: data.foto64,
    Responsabilidad: data.Responsabilidad ? data.Responsabilidad : '',
    Coordinador: data.Coordinador ? data.Coordinador : '',
    LocalidadVive: data.idLocalidadVive ? data.idLocalidadVive : '',
    LocalidadVota: data.idLocalidadVota ? data.idLocalidadVota : '',

  });

  const [catEntidad, setCatEntidad] = useState([]);
  const [catSexo, setCatSexo] = useState([]);
  const [catVoluntario, setCatVoluntario] = useState([]);
  const [catSituacionLaboral, setSituacionLaboral] = useState([]);
  const [catNivelEstudios, setNivelEstudios] = useState([]);
  const [catMunicipio, setCatMunicipio] = useState([]);
  const [catLocalidad, setCatLocalidad] = useState([]);
  const [catColonias, setCatColonia] = useState([]);
  const [catMunicipioVota, setCatMunicipioVota] = useState([]);
  const [catLocalidadVota, setCatLocalidadVota] = useState([]);
  const [catColoniasVota, setCatColoniaVota] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getVars("Token").access_token;
  const [flagUpdatePerfil, setflagUpdatePerfil] = useState(false);

  useEffect(() => {
    setCatEntidad(catalogos.Entidad);
    setCatSexo(catalogos.Sexo);
    setCatMunicipio(catalogos.Municipio);
    setCatMunicipioVota(catalogos.Municipio);
    setCatVoluntario(catalogos.Voluntario);
    setSituacionLaboral(catalogos.SituacionLaboral);
    setNivelEstudios(catalogos.NivelEstudios);
  }, [catalogos])

  useEffect(() => {
    if (loaded) {
      setCompromiso({
        id: data.id,
        Email: data.Email,
        PinCode: data.PinCode,
        Nombre: data.Nombre,
        Paterno: data.Paterno,
        Materno: data.Materno,
        INE: data.INE === null ? '' : data.INE,
        EstadoNacimiento: data.EstadoNacimiento, //value & label
        Nivel: data.Nivel,
        MunicipioVota: data.MunicipioVota,
        SeccionVota: data.SeccionVota,
        CalleVota: data.CalleVota === null ? '' : data.CalleVota,
        NumExtVota: data.NumExtVota === null ? '' : data.NumExtVota,
        NumIntVota: data.NumIntVota === null ? '' : data.NumIntVota,
        CPVota: data.CPVota === null ? '' : data.CPVota,
        ColoniaVota: data.ColoniaVota === null ? '' : data.ColoniaVota,
        CalleVive: data.CalleVive === null ? '' : data.CalleVive,
        NumExtVive: data.NumExtVive === null ? '' : data.NumExtVive,
        NumIntVive: data.NumIntVive === null ? '' : data.NumIntVive,
        CPVive: data.CPVive === null ? '' : data.CPVive,
        ColoniaVive: data.ColoniaVive === null ? '' : data.ColoniaVive,
        FechaNacimiento: data.FechaNacimiento === null ? '' : data.FechaNacimiento,
        MunicipioVive: data.MunicipioVive,
        Sexo: data.Sexo,
        Celular: data.Celular === null ? '' : data.Celular,
        Telefono: data.Telefono === null ? '' : data.Telefono,
        TelMensajes: data.TelMensajes === null ? '' : data.TelMensajes,
        CURP: data.CURP === null ? '' : data.CURP,
        hashCode: data.hashCode,
        hashValidate: data.hashValidate,
        DefaultPage: data.DefaultPage,
        DeviceID: data.DeviceID,
        DeviceOS: data.DeviceOS,
        Token: data.Token,
        Voluntario: data.Voluntario, //value,label
        PerfilUser: data.PerfilUser,
        NivelEstudios: data.NivelEstudios, //value,label
        ExperienciaElectoral: data.ExperienciaElectoral,
        ExperienciaDonde: data.ExperienciaDonde === null ? '' : data.ExperienciaDonde,
        ExperienciaAnio: data.ExperienciaAnio === null ? '' : data.ExperienciaAnio,
        Correo: data.Correo === null ? '' : data.Correo,
        AcercaDe: data.AcercaDe === null ? '' : data.AcercaDe,
        CelInvito: data.CelInvito === null ? '' : data.CelInvito,
        NombreInvito: data.NombreInvito === null ? '' : data.NombreInvito,
        UserValido: data.UserValido,
        FechaValido: data.FechaValido,
        UserBloqueo: data.UserBloqueo,
        FechaBloqueo: data.FechaBloqueo,
        Estatus: data.Estatus,
        PerfilSistema: data.PerfilSistema,
        TituloObtenido: data.TituloObtenido,
        SituacionLaboral: data.SituacionLaboral,
        isVoluntario: data.isVoluntario,
        MismaAddress: data.MismaAddress,
        EmpresaLabora: data.EmpresaLabora,
        UserCreate: data.UserCreate,
        FechaCreate: data.FechaCreate,
        UserUpdate: data.UserUpdate,
        LastUpdate: data.LastUpdate,
        foto64: data.foto64,
        Responsabilidad: data.Responsabilidad,
        Coordinador: data.Coordinador,
        LocalidadVive: data.idLocalidadVive,
        LocalidadVota: data.idLocalidadVota,
      });

      if (data.MunicipioVive.label.length > 0) {
        EjecutarApi({ Municipio: data.MunicipioVive.label }, "getLocalidadWeb", token, "POST")
          .then(function (res) {
            return res;
          })
          .then((items) => {
            if (items.results) {
              setCatLocalidad(items.data.localidades);
              let loc = items.data.localidades.find(item => item.value === String(data.idLocalidadVive));
              if (loc !== undefined) {
                setCompromiso(prevstate => ({ ...prevstate, LocalidadVive: loc }));
              }

              setCatColonia(items.data.colonias);
              let col = items.data.colonias.find(item => item.label === String(data.ColoniaVive));
              if (col !== undefined) {
                setCompromiso(prevstate => ({ ...prevstate, ColoniaVive: col }));
              }
            } else {
              notify.show("No tiene permisos, consulte a su administrador!", "custom", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
              });
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            notify.show("Error: al ejecutar las consultas, verfique con su administrador!", "error", 5000, {
              background: "#0857B6",
              fontSize: 14,
              text: "#FFFFFF",
            });
          });
      }
      if (data.MunicipioVota.label !== null && data.MunicipioVota.label !== undefined) {
        if (data.MunicipioVota.label.lenght > 0) {
          EjecutarApi({ idMunicipio: data.MunicipioVota.label }, "getLocalidadWeb", token, "POST")
            .then(function (res) {
              return res;
            })
            .then((items) => {
              if (items.results) {
                setCatLocalidadVota(items.data.localidades);
                setCatColoniaVota(items.data.colonias);
                let loc = items.data.localidades.find(item => item.value === String(data.idLocalidadVota));
                if (loc !== undefined) {
                  setCompromiso(prevstate => ({ ...prevstate, LocalidadVota: loc }));
                }

                let col = items.data.colonias.find(item => item.label === String(data.ColoniaVota));
                if (col !== undefined) {
                  setCompromiso(prevstate => ({ ...prevstate, ColoniaVota: col }));
                }
              } else {
                notify.show("No tiene permisos para ver esta información contacte a su administrador!!!", "custom", 5000, {
                  background: "#0857B6",
                  fontSize: 14,
                  text: "#FFFFFF",
                });
              }
              setLoading(false);
            })
            .catch((e) => {
              setLoading(false);
              notify.show("Error: getLocalidadWeb", "custom", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
              });
            });
        }
      }


      if (data.idVoluntario !== null) {
        let vol = catVoluntario.find(item => item.value === data.idVoluntario);
        if (vol !== undefined) {
          setCompromiso(prevstate => ({ ...prevstate, Voluntario: vol }));
        } else {
          setCompromiso(prevstate => ({ ...prevstate, Voluntario: { value: 1, label: "NO DEFINIDO" } }));
        }
      }

      if (data.idNivelEstudios !== null) {
        let vol = catNivelEstudios.find(item => item.value === data.idNivelEstudios);
        if (vol !== undefined) {
          setCompromiso(prevstate => ({ ...prevstate, NivelEstudios: vol }));
        } else {
          setCompromiso(prevstate => ({ ...prevstate, NivelEstudios: { value: 1, label: "NO DEFINIDO" } }));
        }
      }

      if (data.idSituacionLaboral !== null) {
        let vol = catSituacionLaboral.find(item => item.value === data.idSituacionLaboral);
        if (vol !== undefined) {
          setCompromiso(prevstate => ({ ...prevstate, SituacionLaboral: vol }));
        } else {
          setCompromiso(prevstate => ({ ...prevstate, SituacionLaboral: { value: 1, label: "NO DEFINIDO" } }));
        }
      }
    }
  }, [data])

  const saveCompromiso = () => {
    setLoading(true);
    setflagUpdatePerfil(false);

    var parametros = {
      id: compromiso.id,
      Nombre: compromiso.Nombre,
      Paterno: compromiso.Paterno,
      Materno: compromiso.Materno,
      INE: compromiso.INE,
      idEstadoNacimiento: compromiso.EstadoNacimiento.idEstadoNacimiento, //value & label
      idMunicipioVota: compromiso.MunicipioVota.idMunicipio,
      CalleVota: compromiso.CalleVota,
      NumExtVota: compromiso.NumExtVota,
      NumIntVota: compromiso.NumIntVota,
      CPVota: compromiso.CPVota,
      ColoniaVota: compromiso.ColoniaVota,
      CalleVive: compromiso.CalleVive,
      NumExtVive: compromiso.NumExtVive,
      NumIntVive: compromiso.NumIntVive,
      CPVive: compromiso.CPVive,
      ColoniaVive: compromiso.ColoniaVive,
      FechaNacimiento: compromiso.FechaNacimiento,
      idMunicipioVive: compromiso.MunicipioVive.idMunicipioVive,
      idSexo: compromiso.Sexo.value,
      Celular: compromiso.Celular,
      Telefono: compromiso.Telefono,
      TelMensajes: compromiso.TelMensajes,
      CURP: compromiso.CURP,
      idVoluntario: compromiso.Voluntario.value, //value,label
      idNivelEstudios: compromiso.NivelEstudios.value, //value,label
      ExperienciaElectoral: compromiso.ExperienciaElectoral,
      ExperienciaDonde: compromiso.ExperienciaDonde,
      ExperienciaAnio: compromiso.ExperienciaAnio,
      Correo: compromiso.Correo,
      AcercaDe: compromiso.AcercaDe,
      CelInvito: compromiso.CelInvito,
      NombreInvito: compromiso.NombreInvito,
      TituloObtenido: compromiso.TituloObtenido,
      idSituacionLaboral: compromiso.SituacionLaboral.value,
      isVoluntario: compromiso.isVoluntario,
      MismaAddress: compromiso.MismaAddress,
      EmpresaLabora: compromiso.EmpresaLabora,
      idLocalidadVive: compromiso.LocalidadVive.value,
      idLocalidadVota: compromiso.LocalidadVota ? compromiso.LocalidadVota.value : null,

    };

    EjecutarApi(parametros, "updUsername", token, "POST") //updUsername
      .then(function (res) {
        if (res.success) {
          notify.show(`El registro se actualizo con éxito!!`, "custom", 5000, {
            background: "#18c97d",
            fontSize: 14,
            text: "#FFFFFF",
          });
          //props = compromisoEdit;
          setLoading(false);
        } else {
          notify.show(`Error al actualizar: ${res.message}!!`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        return true;
      })
      .catch((e) => {
        setLoading(false);
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };
  const searchCURP = () => {
    setLoading(true);
    var _curp = compromiso.CURP;
    if (compromiso.CURP === "") {
      setLoading(false);
      notify.show(`Error: Ingresar una CURP valida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
      });
      return true;
    }

    if (compromiso.CURP.length !== 18) {
      setLoading(false);
      notify.show(`Error: CURP inválida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
      });
      return true;
    }

    //	const CURP = vale.CURP;

    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${_curp}`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        setLoading(false);
        if (dataRS.Mensaje === "OK") {
          //aqui
          var FN = dataRS.Resultado.fechNac.split("/");
          setCompromiso({
            ...compromiso,
            Nombre: dataRS.Resultado.nombres,
            Paterno: dataRS.Resultado.apellido1,
            Materno: dataRS.Resultado.apellido2,
            Sexo:
              dataRS.Resultado.sexo === "H"
                ? { value: "M", label: "Masculino" }
                : { value: "F", label: "Femenino" },
            FechaCumple: FN[2] + "-" + FN[1] + "-" + FN[0],
          });

        } else {
          setLoading(false);
          if (
            dataRS.Mensaje === "La CURP no se encuentra en la base de datos"
          ) {
            notify.show(
              `PASO 3 Error en CURP...! ${dataRS.Mensaje}`,
              "custom",
              5000,
              {
                background: "#d61818",
                text: "#FFFFFF",
              }
            );
          } else if (
            dataRS.Mensaje ===
            "El campo CURP: No cumple con el formato especificado."
          ) {


            notify.show(
              `PASO 4 Error en CURP...! ${dataRS.Mensaje}`,
              "custom",
              5000,
              {
                background: "#d61818",
                text: "#FFFFFF",
              }
            );
          } else {
          }
          return true;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const municipioChange = (mun) => {
    if (mun) {

      EjecutarApi({ Municipio: mun }, 'getLocalidadWeb', token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            setCatLocalidad(
              res.data.map((status_) => {
                return {
                  value: status_["Id"],
                  label: status_["Nombre"],
                };
              })
            );
          }
        })
        .catch((e) => {
          notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
          });
        });
    }
  };
  const searchCP = (e) => {
    var dato = { CP: compromiso.CPVive };
    EjecutarApi(dato, "getDataByCP", token, "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setCompromiso({ ...compromiso, MunicipioVive: res.data.municipio });
          setCatLocalidad(res.data.localidades);
          setCatColonia(res.data.colonias);
        }
      })
      .catch((e) => {
        notify.show(`Error en CP...! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  };
  const alertaActualizacion = (
    <>
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="¿Está seguro que desea actualizar los datos?"
        onConfirm={() => saveCompromiso()}
        onCancel={() => setflagUpdatePerfil(false)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Si, actualizar!"
        cancelBtnText="Cancel"
        showCancel
      ></ReactBSAlert>
    </>
  );
  return (
    <>
      <div>
        <Form className="form-horizontal" id="TypeValidation">
          <Row>
            {loading && (
              <CircleSpinner
                className="text-center"
                size={30}
                color="#686769"
                loading={loading}
              />
            )}
            <FormGroup className={"col-md-4"}>
              <Label for="INE">
                {" "}
                <span className="text-danger">*</span>Clave Elector
              </Label>
              <Input
                type="text"
                id="INE"
                value={compromiso.INE}
                onChange={(event) => {
                  setCompromiso({
                    ...compromiso,
                    INE: event.target.value,
                  });
                }}
                maxLength="18"
              />
            </FormGroup>

            <FormGroup className="col-md-5">
              <Label for="CURP">CURP</Label>
              <Input
                type="text"
                id="CURP"
                value={compromiso.CURP}
                onChange={(event) => {
                  setCompromiso({
                    ...compromiso,
                    CURP: event.target.value,
                  });
                }}
                maxLength="18"
              />
            </FormGroup>

            <Button size="sm" color="primary" onClick={searchCURP} >
              Buscar
            </Button>
          </Row>

          <Row>
            <FormGroup className={"col-md-4"}>
              <Label for="Nombre">
                <span className="text-danger">*</span>Nombre(s)
              </Label>
              <Input
                id="Nombre"
                value={compromiso.Nombre}
                type="text"
                onChange={(e) =>
                  setCompromiso({
                    ...compromiso,
                    Nombre: e.target.value,
                  })
                }
              />
            </FormGroup>

            <FormGroup className={"col-md-4"}>
              <Label for="inputApellido_1">
                <span className="text-danger">*</span>Apellido Paterno
              </Label>
              <Input
                id="Paterno"
                value={compromiso.Paterno}
                type="text"
                onChange={(e) =>
                  setCompromiso({
                    ...compromiso,
                    Paterno: e.target.value,
                  })
                }
              />
            </FormGroup>

            <FormGroup className={"col-md-4"}>
              <Label for="Materno">
                <span className="text-danger">*</span>Apellido Materno
              </Label>
              <Input
                id="Materno"
                value={compromiso.Materno}
                type="text"
                onChange={(e) =>
                  setCompromiso({
                    ...compromiso,
                    Materno: e.target.value,
                  })
                }
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className={"col-md-4"}>
              <Label for="Fechacumple">
                <span className="text-danger">*</span>Fecha de nacimiento
              </Label>
              <ReactDatetime
                id="Fechacumple"
                name="Fechacumple"
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                closeOnSelect
                value={compromiso.FechaNacimiento}
                onChange={(e) =>
                  setCompromiso({
                    ...compromiso,
                    FechaNacimiento: moment(e._d).format("YYYY-MM-DD"),
                  })
                }
                inputProps={{ className: "form-control" }}
              />
            </FormGroup>
            <FormGroup className={"col-md-4"}>
              <Label for="Sexo">
                <span className="text-danger">*</span>Sexo
              </Label>
              <Select
                options={catSexo}
                className="react-select primary"
                classNamePrefix="react-select"
                value={compromiso.Sexo}
                onChange={(e) => setCompromiso({ ...compromiso, Sexo: e })}
                id="Sexo"
                placeholder="Sexo"
              />
            </FormGroup>
            <FormGroup
              className={
                compromiso.EstadoNacimiento.label.length < 3
                  ? "col-md-4 has-danger"
                  : "col-md-4"
              }
            >
              <Label for="EntidadNacimiento">
                <span className="text-danger">*</span>Estado de nacimiento
              </Label>
              <Select
                options={catEntidad}
                className="react-select primary"
                classNamePrefix="react-select"
                id="EntidadNacimiento"
                value={compromiso.EstadoNacimiento}
                onChange={(e) =>
                  setCompromiso({ ...compromiso, EstadoNacimiento: e })
                }
                placeholder="Entidad"
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col-md-12 text-center text-warning">
              <strong>Capture dirección donde vive</strong>
              <br />
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label for="CalleVive">Calle</Label>
              <Input
                id="CalleVive"
                value={compromiso.CalleVive}
                type="text"
                onChange={(e) =>
                  setCompromiso({ ...compromiso, CalleVive: e.target.value })
                }
              />
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="NumExtVive">Número Ext.</Label>
              <Input
                id="NumExtVive"
                value={compromiso.NumExtVive}
                type="text"
                onChange={(e) =>
                  setCompromiso({ ...compromiso, NumExtVive: e.target.value })
                }
              />
            </FormGroup>

            <FormGroup className="col-md-2">
              <Label for="NumIntVive">Número Int.</Label>
              <Input
                id="NumIntVive"
                value={compromiso.NumIntVive}
                type="text"
                onChange={(e) =>
                  setCompromiso({ ...compromiso, NumIntVive: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup className="col-md-2">
              <Label for="CPVive">CP</Label>
              <Input
                id="CPVive"
                value={compromiso.CPVive}
                type="text"
                maxLength={5}
                onChange={(event) => {
                  setCompromiso({
                    ...compromiso,
                    CPVive: event.target.value.replace(/\D/, ""),
                  });
                }}
              />
            </FormGroup>
            <Button onClick={() => searchCP()} color="primary">
              Buscar{" "}
            </Button>
            <FormGroup className="col-md-4">
              <Label for="MunicipioVive">
                <span className="text-danger">*</span>Municipio
              </Label>
              <Select
                options={catMunicipio}
                className="react-select primary"
                classNamePrefix="react-select"
                id="MunicipioVive"
                name="MunicipioVive"
                value={compromiso.MunicipioVive}
                onChange={(e) => {
                  setCompromiso({ ...compromiso, MunicipioVive: e });
                  municipioChange(e.label);
                }}
              />
            </FormGroup>
            <FormGroup className="col-md-4">
              <Label for="LocalidadVive">
                Localidad:<span className="text-danger">*</span>
              </Label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                options={catLocalidad}
                value={compromiso.LocalidadVive}
                id="Localidad"
                onChange={(event) => {
                  setCompromiso({ ...compromiso, LocalidadVive: event });
                }}
                placeholder="seleccione su localidad"
              />
            </FormGroup>
            <FormGroup className="col-md-3">
              <Label for="ColoniaVive">
                <span className="text-danger">*</span>Colonia
              </Label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                options={catColonias}
                value={compromiso.ColoniaVive}
                id="colonia"
                onChange={(event) => {
                  setCompromiso({ ...compromiso, ColoniaVive: event.label });
                }}
                placeholder="seleccione su colonia"
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col-md-3">
              <Label for="Tel">Teléfono celular</Label>
              <Input
                id="Tel"
                value={compromiso.Celular}
                pattern="[0-9]*"
                maxLength={10}
                type="text"
                onChange={(e) =>
                  setCompromiso({
                    ...compromiso,
                    Celular: e.target.value.replace(/\D/, ""),
                  })
                }
              />
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="Celular">Teléfono fijo</Label>
              <Input
                id="Celular"
                value={compromiso.Telefono}
                pattern="[0-9]*"
                maxLength={10}
                type="text"
                onChange={(e) =>
                  setCompromiso({
                    ...compromiso,
                    Telefono: e.target.value.replace(/\D/, ""),
                  })
                }
              />
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="Celular_1">Teléfono mensajes</Label>
              <Input
                id="TelMSG"
                value={compromiso.TelMensajes}
                pattern="[0-9]*"
                maxLength={10}
                type="text"
                onChange={(e) =>
                  setCompromiso({
                    ...compromiso,
                    TelMensajes: e.target.value.replace(/\D/, ""),
                  })
                }
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup check className="col-md-3 text-center">
              <Label check>
                <Input
                  type="checkbox"
                  defaultChecked={compromiso.isVoluntario}
                  onChange={() =>
                    setCompromiso({
                      ...compromiso,
                      isVoluntario: !compromiso.isVoluntario,
                    })
                  }
                />{" "}
                Desea ser voluntario
                <span className="form-check-sign  text-center">
                  <span className="check"></span>
                </span>
              </Label>
            </FormGroup>
          </Row>

          {compromiso.isVoluntario === 1 && (
            <div>
              <Row>
                <FormGroup className="col-md-3  text-center">
                  <Label for="Voluntario">Voluntario para</Label>
                  <Select
                    options={catVoluntario}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    id="Voluntario"
                    value={compromiso.Voluntario}
                    onChange={(e) =>
                      setCompromiso({ ...compromiso, Voluntario: e })
                    }
                  />
                </FormGroup>
                <FormGroup className="col-md-2">
                  <Label for="SeccionVota">
                    <span className="text-danger">*</span>Sección vota
                  </Label>
                  <Input
                    id="SeccionVota"
                    value={compromiso.SeccionVota}
                    pattern="[0-9]*"
                    maxLength={5}
                    type="text"
                    onChange={(event) =>
                      setCompromiso({
                        ...compromiso,
                        SeccionVota: event.target.value.replace(/\D/, ""),
                      })
                    }
                  />
                </FormGroup>

              </Row>

              <Row>
                <FormGroup className="col-md-12 text-center">
                  <label>¿La dirección de ID Único y donde vive son la misma?</label>
                  <br />
                  <div className="form-check-radio form-check-inline">
                    <Label className="form-check-label">
                      <Input
                        type="radio"
                        name="exampleRadios1"
                        id="exampleRadios11"
                        value="option1"
                        defaultChecked={compromiso.MismaAddress ? true : false}
                        onChange={() =>
                          setCompromiso({ ...compromiso, MismaAddress: true })
                        }
                      />
                      Si
                      <span className="form-check-sign"></span>
                    </Label>
                  </div>
                  <div className="form-check-radio form-check-inline">
                    <Label className="form-check-label">
                      <Input
                        type="radio"
                        name="exampleRadios1"
                        id="exampleRadios12"
                        value="option2"
                        defaultChecked={!compromiso.MismaAddress ? true : false}
                        onChange={() =>
                          setCompromiso({
                            ...compromiso,
                            MismaAddress: false,
                          })
                        }
                      />
                      No
                      <span className="form-check-sign"></span>
                    </Label>
                  </div>
                </FormGroup>
              </Row>
            </div>
          )
          }
          {!compromiso.MismaAddress && (
            <div>

              <Row>
                <FormGroup className="col-md-12 text-center text-info">
                  <strong>Capture información de la credencial INE</strong>
                  <br />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className="col-md-4">
                  <Label for="CalleINE">Calle</Label>
                  <Input
                    id="CalleINE"
                    value={compromiso.CalleVota}
                    type="text"
                    onChange={(e) =>
                      setCompromiso({
                        ...compromiso,
                        CalleVota: e.target.value,
                      })
                    }
                  />
                </FormGroup>

                <FormGroup className="col-md-2">
                  <Label for="NumExtINE">Número Ext.</Label>
                  <Input
                    id="NumExtINE"
                    value={compromiso.NumExtVota}
                    type="text"
                    onChange={(e) =>
                      setCompromiso({
                        ...compromiso,
                        NumExtVota: e.target.value,
                      })
                    }
                  />
                </FormGroup>

                <FormGroup className="col-md-2">
                  <Label for="NumIntINE">Número Int.</Label>
                  <Input
                    id="NumIntINE"
                    value={compromiso.NumIntVota}
                    type="text"
                    onChange={(e) =>
                      setCompromiso({
                        ...compromiso,
                        NumIntVota: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup className="col-md-2">
                  <Label for="CPINE">CP</Label>
                  <Input
                    id="CPINE"
                    value={compromiso.CPVota}
                    type="text"
                    maxLength={5}
                    onChange={(event) => {
                      setCompromiso({
                        ...compromiso,
                        CPVota: event.target.value.replace(/\D/, ""),
                      });
                    }}
                  />
                </FormGroup>
                <Button onClick={() => searchCP()} color="primary">
                  Buscar{" "}
                </Button>
                <FormGroup className="col-md-4">
                  <Label for="MunicipioVota">
                    <span className="text-danger">*</span>Municipio
                  </Label>
                  <Select
                    options={catMunicipioVota}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    id="MunicipioVota"
                    name="MunicipioVota"
                    value={compromiso.MunicipioVota}
                    onChange={(e) => {
                      setCompromiso({ ...compromiso, MunicipioVota: e });
                      municipioChange(e.label);
                    }}
                  />
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="LocalidadINE">
                    Localidad:<span className="text-danger">*</span>
                  </Label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    options={catLocalidadVota}
                    value={compromiso.LocalidadVota}
                    id="LocalidadINE"
                    onChange={(event) => {
                      setCompromiso({
                        ...compromiso,
                        LocalidadVota: event,
                      });
                    }}
                    placeholder="seleccione su localidad"
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label for="ColoniaINE">
                    <span className="text-danger">*</span>Colonia
                  </Label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    options={catColoniasVota}
                    id="colonia"
                    onChange={(event) => {
                      setCompromiso({
                        ...compromiso,
                        ColoniaVota: event.label,
                      });
                    }}
                    placeholder="seleccione su colonia"
                  />
                </FormGroup>
              </Row>

              <Row>
                <FormGroup className="col-md-3 text-center">
                  <Label for="seccionVota">
                    <span className="text-danger">*</span>Sección Vota
                  </Label>
                  <Input
                    type="text"
                    value={compromiso.SeccionVota}
                    onChange={(e) =>
                      setCompromiso({
                        ...compromiso,
                        SeccionVota: e.target.value,
                      })
                    }
                  />{" "}
                </FormGroup>
              </Row>
            </div>
          )}
          <div>
            <Row>
              <FormGroup className="col-md-12 text-center text-info">
                <strong>Información Adicional</strong>
                <br />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-5">
                <Label>Nivel de Estudios:</Label>
                <Select
                  id="nivel"
                  options={catNivelEstudios}
                  value={compromiso.NivelEstudios}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      NivelEstudios: e,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-5">
                <Label>Titulo Obtenido:</Label>
                <Input
                  id="nivel"
                  value={compromiso.TituloObtenido}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      TituloObtenido: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-5">
                <Label>Situacion Laboral:</Label>
                <Select
                  id="nivel"
                  options={catSituacionLaboral}
                  value={compromiso.SituacionLaboral}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      SituacionLaboral: e,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-5">
                <Label>Detalle Laboral:</Label>
                <Input
                  id="detalle"
                  value={compromiso.EmpresaLabora}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      EmpresaLabora: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-5">
                <Label>Correo:</Label>
                <Input
                  id="Correo"
                  value={compromiso.Correo}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      Correo: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup check className="col-md-4 text-center">
                <Label check>
                  {" "}
                  <Input
                    type="checkbox"
                    defaultChecked={compromiso.ExperienciaElectoral}
                    onChange={(e) =>
                      setCompromiso({
                        ...compromiso,
                        ExperienciaElectoral: e.target.value,
                      })
                    }
                  />{" "}
                  Tiene experiencia en elecciones
                  <span className="form-check-sign  text-center">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <FormGroup className="col-md-3">
                <Label>Año:</Label>
                <Input
                  id="anio"
                  value={compromiso.ExperienciaAnio}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      ExperienciaAnio: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-3">
                <Label>Lugar Experiencia:</Label>
                <Input
                  id="ExperienciaDonde"
                  value={compromiso.ExperienciaDonde}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      ExperienciaDonde: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-10">
                <Label>Actividades:</Label>
                <Input
                  id="actividades"
                  value={compromiso.AcercaDe}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      AcercaDe: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col-md-4">
                <Label>Celúlar de quién lo invito:</Label>
                <Input
                  id="CelInvito"
                  value={compromiso.CelInvito}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      CelInvito: e.target.value,
                    })
                  }
                />
              </FormGroup>

              <FormGroup className="col-md-6">
                <Label>Nombre de quién lo invito:</Label>
                <Input
                  id="NombreInvito"
                  value={compromiso.NombreInvito}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      NombreInvito: e.target.value,
                    })
                  }
                />
              </FormGroup>
              {/*<FormGroup className="col-md-3">
                <Label>UserValido:</Label>
                <Input
                  id="UserValido"
                  value={compromiso.UserValido}
                  type="text"
                  onChange={(e) =>
                    setCompromiso({
                      ...compromiso,
                      UserValido: e.target.value,
                    })
                  }
                />
              </FormGroup>*/}
            </Row>
          </div>
          <Row>
            <FormGroup className="col-md-12 pr-2 text-center">
              <Button onClick={() => setflagUpdatePerfil(true)} color="primary">
                Guardar
              </Button>
            </FormGroup>
          </Row>
        </Form>
        {flagUpdatePerfil ? alertaActualizacion : <></>}
      </div>
    </>
  );
}

export default DetalleUsuario;
