import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  FormGroup,
  Table,
} from 'reactstrap';
import { RotateSpinner, MetroSpinner } from 'react-spinners-kit';
import { getDataMenu } from 'data/menus';
import { getVars, URL_PUBLICA } from 'GlobalStorage';
import { SettingsSystemDaydreamTwoTone } from "@material-ui/icons";

const ListSeccionesComponent = ({ seccionesShow, Data, change }) => {

  const token = getVars('Token');
  //const [seccionesShowFiltered,setSeccionesShowFiltered]=useState(seccionesShow);
  const seccionesShowFiltered = seccionesShow;
  const [_menu, _setmenu] = useState({});
  const responsibilityID = token.user.Responsabilidad.value;
  /* const handleNullSeccionesInterno = (valor) => {
    handleNullSecciones(valor);
  } */

  useEffect(() => {
    _setmenu(getDataMenu(185, token.menus));
  }, [])

  useEffect(() => {
    //setSeccionesShowFiltered(seccionesShow)
  }, [seccionesShow])

  const setvalue = (item, key, value) => {
    Data([item, key, value])
  }

  return (
    <>
      {
        (seccionesShowFiltered) ?
         ( <tbody>
            {
              seccionesShowFiltered.map((item, key) => {
                return (
                  <tr key={`row_${key}`}>
                    <td className="text-center" style={{ width: "9%" }}>
                     <h6>{item.Seccion}</h6> 
                    </td>
                    <td className="text-center" style={{ width: "9%" }}>
                     <h6>{item.Celular+' '+item.NombreCompleto }</h6> 
                    </td>
                    <td className="text-center" style={{ width: "9%" }}>
                     <h6>{item.Responsabilidad }</h6> 
                    </td>
                    <td style={{ width: "5%" }} className="text-center">
                      <Input valid={_menu.Seguimiento===1?false:item.flag} disabled={_menu.Seguimiento===1?false:item.flag} style={{ width: "90px" }} type="number" value={item.Movilizados} onBlur={(e) => {
                        setvalue(item.idUser, `row_${key}`, e.target.value)
                      }} ></Input>
                    </td>
                  </tr>
                );
              })}
          </tbody> ):<tbody>
            <tr>
              <td style={{ width: "50%" }}>
                Debe Seleccionar un Polígono
              </td>
            </tr>
          </tbody>
      }
    </>

  );
}
export default ListSeccionesComponent;