var CryptoJS = require('crypto-js');

//const baseURL = "http://127.0.0.1:8000";
const baseURL = "https://apireportes.siangto.com";

const baseUrlReportes ="https://apireportes.siangto.com";
//const baseUrlReportes = "http://127.0.0.1:8000";

export const URL_PUBLICA = `${baseUrlReportes}/api/`;
export const URL_PUBLICA_REPORTE = `${baseUrlReportes}/api/`;
export const URL_IMAGENES = `${baseUrlReportes}/storage/`;


export const baseGeoCodeURL = "https://maps.googleapis.com/maps/api/geocode/json?key=";
export const API_GOOGLE = "AIzaSyAviWQTeMaF9TrsGtwkGOX8wkKno7L-dNo";

export const INTERVAL = 10000;
export const tiempoSije = {
	horaInicio: '05:00:00',
	horaFin: '23:00:00',
	fecha: (new Date()).toISOString().substring(0, 10)
}

export const numberWithCommas =(x) =>{
	if(x)
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	else
		return '-';
}


export const setVars = (id, Value) => {
	var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(Value), 'c273ncriPt0*-#').toString();

	sessionStorage.setItem(id, ciphertext);

	var bytes = CryptoJS.AES.decrypt(ciphertext, 'c273ncriPt0*-#');
	var originalText = bytes.toString(CryptoJS.enc.Utf8);

	return originalText;
};

export const getVars = (id, defaultvar = {}) => {
	var data = sessionStorage.getItem(id);

	var originalText = null;
	if (data !== null) {
		var bytes = CryptoJS.AES.decrypt(data, 'c273ncriPt0*-#');
		originalText = bytes.toString(CryptoJS.enc.Utf8);
	}

	return originalText !== null ? JSON.parse(originalText) : defaultvar;
};