import React, { useState } from 'react';

import {
  Button,
  Col,
  Card,
  Row,
  CardHeader,
  CardBody,
  Collapse,
  CardFooter,
  Input,
} from 'reactstrap';
import Select from 'react-select';

const Filtro = (props) => {
  const { filtrar } = props;
  const optionsTipo = [
    { label: 'Coalisión', value: 1 },
    { label: 'Únicos', value: 0 },
    { label: 'Todos', value: -1 },
  ];
  const [filtros, setFiltros] = useState({
    isCoalicion: { label: 'Todos', value: -1 },
    Partido: '',
  });
  const [collapse, setCollapse] = useState({
    horizontalTabs: 'home',
    verticalTabs: 'info',
    pageTabs: 'homePages',
    openedCollapses: ['collapse1'],
  });

  const collapsesToggle = (colapse) => {
    let openedCollapses = collapse.openedCollapses;
    if (openedCollapses.includes(colapse)) {
      setCollapse({
        ...collapse,
        openedCollapses: openedCollapses.filter((item) => item !== colapse),
      });
    } else {
      openedCollapses.push(colapse);
      setCollapse({ ...collapse, openedCollapses: openedCollapses });
    }
  };

  const formatFiltro = () => {
    let filter = [];
    if(filtros.Partido.trim().length > 0){
        filter.push({id:'Partido', value:filtros.Partido.trim()})
    }
    if(filtros.isCoalicion.value > -1){
        filter.push({id:'Coalicion', value:filtros.isCoalicion.value})
    }

    filtrar(filter);
  };

  const clearFiltro = () => {
    setFiltros({
      isCoalicion: { label: 'Todos', value: -1 },
      Partido: '',
    });

    filtrar([])
  };

  return (
    <Card className="card-plain">
      <CardBody>
        <div
          aria-multiselectable={true}
          className="card-collapse"
          id="accordion"
          role="tablist"
        >
          <Card className="card-plain">
            <CardHeader role="tab">
              <a
                aria-expanded={collapse.openedCollapses.includes('collapse1')}
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={() => collapsesToggle('collapse1')}
                className="text-right"
              >
                <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                Filtro
              </a>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={collapse.openedCollapses.includes('collapse1')}
            >
              <CardBody>
                <Row>
                  <Col md="4">
                    Partido:
                    <Input
                      value={filtros.Partido}
                      onChange={(e) =>
                        setFiltros({ ...filtros, Partido: e.target.value })
                      }
                      placeholder="Ingrese un partido para buscar"
                    />
                  </Col>
                  <Col md="4">
                    Tipo de partido
                    <Select
                      options={optionsTipo}
                      value={filtros.isCoalicion}
                      onChange={(e) =>
                        setFiltros({ ...filtros, isCoalicion: e })
                      }
                    ></Select>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  size="sm"
                  id="btnfiltro"
                  color="primary"
                  className="primary"
                  onClick={formatFiltro}
                >
                  {' '}
                  <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                  Filtrar
                </Button>
                <Button
                  size="sm"
                  id="btnfiltro"
                  color="danger"
                  className="danger"
                  onClick={clearFiltro}
                >
                  <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                  Limpiar
                </Button>
              </CardFooter>
            </Collapse>
          </Card>
        </div>
      </CardBody>
    </Card>
  );
};

export default Filtro;
