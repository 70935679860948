import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import Rating from '@material-ui/lab/Rating';
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from "GlobalStorage";
import CompromisosEdit from "components/Compromisos/CompromisosEdit";
import ModalFiltro from 'components/Filtro/indexCompromiso';
import Box from '@material-ui/core/Box';
import bloqueado from 'data/bloqueado.json';
import NumeraliaGrf from 'components/Charts/NumeraliaMetas/index';
/* import NumeraliaGrf from 'components/Charts/CompromisosMetas/index'; */
import { convertToObject } from "typescript";
import PerfectScrollbar from "perfect-scrollbar";
var ps;
const Compromisos = (props) => {
  const mainPanel = useRef();
  const [ListadoBloqueado, setListadoBloqueado] = useState([]);
  const [isBloqueado, setIsBloqueado] = useState(false);
  const [compromisos, setCompromisos] = useState([]);
  const [total, setTotal] = useState({ hoy: 0, total: 0, voluntarios: 0 });
  const [totalCompromisos, setTotalCompromisos] = useState(0);
  const [isEditing, setEditing] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [compromisoEdit, setCompromisoEdit] = useState({});
  const [Filter, setFiltro] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
  const [MenuModal] = useState({
    Compromiso: true,
    Voluntarios: false,
    RCs: false,
    RGs: false,
    Abogado: false,
    CasaAzul: false,
    Municipio: false
  });
  const [loadingChart, setLoadingChart] = useState(true);
  const [series, setSeries] = useState([]);
  const [dataChart, setDataChart] = useState([]);

  const btnEditar = (item) => {
    setCompromisoEdit(item);
    setEditing(true);
  };

  const btnCancelarEditar = (props) => {

    setEditing(false);
  };
  const btnActualizar = (props) => {

    setEditing(false);
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    if (props.history.action === "PUSH") {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    _setmenu(getDataMenu(9, token.menus));
    EjecutarApi({}, "totalCompromisos", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setTotal(res.data);
        setTotalCompromisos(res.data.total);
      })
      .catch((e) => {
        notify.show(`Error en totales: ${e.error}`, "error", 5000);
      });
  }, [])

  const getDataChart = (data) => {
    setLoadingChart(true);
    EjecutarApi(data, "compromisosChart", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          let data = res.data.series.filter(fil => (fil.name !== 'LNOM'))

          const dato = {

            title: `${res.data.data.title}`,
            subtitle:`Última Actualizacion: ${res.data.fecha}`,
            xAxis: {
              categories: res.data.data.xAxis.categories,
              title: {
                text: ``
              }
            },
            yAxis: [{
              min: 0,
              title: {
                text: `${res.data.data.yAxis.title.text}`
              }
            }],
          };

          const series = [
            {
              name: 'Meta',
              color: 'rgba(147,220,236,.9)',
              data: res.data.series[1]['data'],
              pointPadding: 0.3,
              pointPlacement: -0.2
            }, {
              name: 'Avance',
              color: '#0041A0',
              data: res.data.series[2]['data'],
              pointPadding: 0.4,
              pointPlacement: -0.2
            }];

          setDataChart(dato);
          setSeries(series);

        }
        setLoadingChart(false);
      })
      .catch((e) => {
        notify.show(`Error al obtener datos de la gráfica`, "error", 5000);
        setLoadingChart(false);
      });
  }

  const [isChartView, setIsChartView] = useState(true);
  const [idResponsabilidad] = useState([1, 2, 3, 9, 8, 12, 13, 14, 15, 16, 17, 18, 31, 34, 35, 40, 56, 98, 100, 101])
  useEffect(() => {
    ///
    let encontrado = bloqueado.data.filter(item => item.value === token.user.id);
    let notSee = idResponsabilidad.filter(item => item === token.user.Responsabilidad.value);

    if (encontrado.length > 0) {
      setIsBloqueado(true)
    }
    if (notSee.length > 0) {
      setIsChartView(false);
    } else {
      getDataChart({ filtered: [] });
    }

  }, []);
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const Export = () => {

    const data = {
      page: 0,
      pageSize: 10,
      filtered: Filter,
      Export: true
    };

    var datos = JSON.stringify(data)
    var url = new URL(
      `${URL_PUBLICA}exportCompromisosByListado?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");

  }

  const handleFiltro = (filter) => {
    setLoading(true);
    var _filtro = [];
    if (filter.Region.value > 0) {
      _filtro.push({ id: 'idRegionVota', value: filter.Region.value })
    }
    if (filter.Municipio.value > 0) {
      _filtro.push({ id: 'idMunicipioVota', value: filter.Municipio.value })
    }
    if (filter.Poligono.value > 0) {
      _filtro.push({ id: 'idPoligonoVota', value: filter.Poligono.value })
    }
    if (filter.Seccion.value > 0) {
      _filtro.push({ id: "SeccionVota", value: filter.Seccion.value })
    }
    if (filter.Celular.length > 0) {
      _filtro.push({ id: "Telefono", value: filter.Celular })
    }
    if (filter.NombreCompleto.length > 0) {
      _filtro.push({ id: "NombreCompleto", value: filter.NombreCompleto })
    }
    if (filter.DireccionVive.length > 0) {
      _filtro.push({ id: "DireccionVive", value: filter.DireccionVive })
    }
    if (filter.RangoEdad.value > 0) {
      var cadena = []
      cadena = cadena.push(parseInt(filter.RangoEdad.label.split(",")))
      _filtro.push({ id: "Edad", value: filter.RangoEdad.label })
    }
    if (filter.ClaveElector.length > 0) {
      _filtro.push({ id: "ClaveElector", value: filter.ClaveElector })
    }
    if (filter.Propietario.length > 0) {
      _filtro.push({ id: "Propietario", value: filter.Propietario })
    }
    if (filter.Ranking.value > 0) {
      _filtro.push({ id: "Ranking", value: filter.Ranking.value })
    }
    if (filter.Etiqueta.value > 0) {
      _filtro.push({ id: "Etiqueta", value: filter.Etiqueta.value })
    } //Capturista
    if (filter.Capturista.value > 0) {
      _filtro.push({ id: "UserCreate", value: filter.Capturista.value })
    }
    if (filter.Propietario.value > 0) {
      _filtro.push({ id: "UserOwned", value: filter.Propietario.value })
    }
    setFiltro(_filtro);
    //Ejecutando Filtro

    let _menuStorage = getDataMenu(8, token.menus);
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [{ id: "LastUpdate", desc: true }],
      filtered: _filtro,
    };

    EjecutarApi(data, "getCompromisosByListado", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {

        setLoading(false);
        ////setTotalCompromisos(res.total);
        setCompromisos(
          res.data.map((prop, key) => {

            return {
              id: prop.id,
              Municipio: prop.MunicipioVota ? prop.MunicipioVota.label : "Sin Municipio",
              Region: prop.RegionVota ? prop.RegionVota.label : "Sin Region",
              NombreCompleto: _menuStorage.Editar === 1 ? (<><Button
                className="btn-link"
                color="primary"
                onClick={() => {
                  btnEditar(prop);
                }}
              >{`${prop["Nombres"]} ${prop["Paterno"]} `}</Button></>) : (<>{`${prop["Nombres"]} ${prop["Paterno"]} `}</>),

              SeccionVota: prop.SeccionVota ? prop.SeccionVota : 'Sin Sección',
              isVoluntario:
                prop["isVoluntario"] === 1 ? "Si" : "No",
              Responsabiliad: prop.Responsabilidad ? prop.Responsabilidad.label : "Sin Responsabilidad",
              /*Ranking: <>
                <Box component="fieldset" mb={3} borderColor="transparent">
                  <Rating name="read-only" value={prop.Ranking} max={10} readOnly />
                </Box>
              </>,
              Etiquetas: prop.Segmentos.length > 0 ? prop.Segmentos.map(item => {
                return <>{item.label}</>
              }) : '',*/
              Colonia: prop.Colonia,
              data: prop,
            };
          })
        );
      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener Compromisos: ${e.error}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });

    EjecutarApi(data, "totalCompromisos", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setTotal(res.data);
        setTotalCompromisos(res.data.total);
      })
      .catch((e) => {
        notify.show(`Error en totales: ${e.error}`, "error", 5000);
      });

    let notSee = idResponsabilidad.filter(item => item === token.user.Responsabilidad.value);
    if (notSee.length === 0) {
      getDataChart({ filtered: _filtro });
    }
  }


  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {!isBloqueado && (<>
            {!isEditing && (<>
              <Row>
                <Col lg="3" md="6" sm="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-primary">
                            <i className="fa fa-users text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Capturados hoy</p>
                            <CardTitle tag="p">{total.hoy}</CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-calendar-o" />
                        Last day
                    </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="3" md="6" sm="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-primary">
                            <i className="fa fa-users text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Compromisos</p>
                            <CardTitle tag="p">{total.total}</CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-refresh" />
                        Actualización
                    </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="3" md="6" sm="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-favourite-28 text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Voluntarios</p>
                            <CardTitle tag="p">{total.voluntarios}</CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-clock-o" />
                        Actual
                    </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm="12">{isChartView && (
                  !loadingChart ? <NumeraliaGrf datos={series} Titulos={dataChart} /> :
                    (
                      <Row>
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loadingChart}
                        /></Row>))}
                </Col>
              </Row>
            </>)}
            <Row>
              <Col>
                <Card>
                  <CardHeader>

                  </CardHeader>
                  <CardBody>
                    {isEditing && (
                      <CompromisosEdit
                        btnCancelar={btnCancelarEditar}
                        btnActualizar={btnActualizar}
                        compromisoID={compromisoEdit}
                      />
                    )}
                    {!isEditing && (<>
                      <Row>
                        {/* {_menu.Agregar === 1 ? (
                          <Link to="compromisos_cap">
                            <Button color="primary" size="sm">
                              <span className="btn-label">
                                <i className="fa fa-user-plus fa-lg" />
                              </span>
                              Capturar
                                    </Button>
                          </Link>
                        ) : (<></>)} */}
                        {_menu.Exportar === 1 ? (

                          <Button color="primary" size="sm" onClick={() => Export()}>
                            <span className="btn-label">
                              <i className="fa fa-file-excel-o fa-lg" />
                            </span>
                            Exportar
                                    </Button>

                        ) : (<></>)}
                      </Row>
                      <Row>
                        <Col sm="12">
                          <ModalFiltro Menu={MenuModal} Datos={(e) => handleFiltro(e)} />
                        </Col>
                      </Row>
                      <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                        <ReactTable
                          style={{
                            width: "110%",
                            height: "100%"
                          }}
                          data={compromisos}
                          noDataText="Sin información"
                          manual
                          columns={[
                            {
                              Header: "Región",
                              minWidth: 30,
                              id: "Region",
                              accessor: (d) => d.Region,
                              filterAll: false,
                            },
                            {
                              Header: "Municipio",
                              minWidth: 70,
                              id: "Municipio",
                              accessor: (d) => d.Municipio,
                              filterAll: false,
                            },
                            // {
                            //   Header: "Ranking",
                            //   minWidth: 50,
                            //   id: "Ranking",
                            //   accessor: (d) => d.Ranking,
                            //   filterAll: false,
                            // },
                            // {
                            //   Header: "Etiquetas",
                            //   minWidth: 50,
                            //   id: "Etiquetas",
                            //   accessor: (d) => d.Etiquetas,
                            //   filterAll: false,
                            // },
                            {
                              Header: "Nombre",
                              minWidth: 110,
                              id: "NombreCompleto",
                              accessor: (d) => d.NombreCompleto,
                              filterAll: false,
                            },
                            // {
                            //   Header: "Colonia",
                            //   minWidth: 80,
                            //   id: "Colonia",
                            //   accessor: (d) => d.Colonia,
                            //   filterAll: false,
                            // },
                            {
                              Header: "Sección Vota",
                              minWidth: 40,
                              id: "SeccionVota",
                              accessor: (d) => d.SeccionVota,
                              filterAll: false,
                            },
                            {
                              Header: "Voluntario",
                              accessor: (d) => d.isVoluntario,
                              minWidth: 40,
                              id: "isVoluntario",
                              filterable: false,
                            },
                            {
                              Header: "Responsabilidad",
                              accessor: (d) => d.Responsabiliad,
                              minWidth: 40,
                              id: "Responsabilidad",
                              filterable: false,
                            },
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loading}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={totalCompromisos}
                          pages={Math.floor(totalCompromisos / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                            setLoading(true);
                            let _menuStorage = getDataMenu(8, token.menus);
                            const data = {
                              page: stateComp.page,
                              pageSize: stateComp.pageSize,
                              sorted: stateComp.sorted,
                              filtered: Filter,
                            };
                            EjecutarApi(data, "getCompromisosByListado", token.access_token)
                              .then(function (res) {
                                return res;
                              })
                              .then((res) => {
                                setLoading(false);
                                //setTotalCompromisos(res.total);
                                setCompromisos(
                                  res.data.map((prop, key) => {

                                    return {
                                      id: prop.id,
                                      Municipio: prop.MunicipioVota ? prop.MunicipioVota.label : "Sin Municipio",
                                      Region: prop.RegionVota ? prop.RegionVota.label : "Sin Region",
                                      NombreCompleto: _menuStorage.Editar === 1 ? (<><Button
                                        className="btn-link"
                                        color="primary"
                                        onClick={() => {
                                          btnEditar(prop);
                                        }}
                                      >{`${prop["Nombres"]} ${prop["Paterno"]} `}</Button></>) : (<>{`${prop["Nombres"]} ${prop["Paterno"]}`}</>),

                                      SeccionVota: prop.SeccionVota ? prop.SeccionVota : 'Sin Sección',
                                      isVoluntario:
                                        prop["isVoluntario"] === 1 ? "Si" : "No",
                                      Responsabiliad: prop.Responsabilidad ? prop.Responsabilidad.label : "Sin Responsabilidad",
                                      /*Ranking: <>
                                          <Box component="fieldset" mb={3} borderColor="transparent">
                                            <Rating name="read-only" value={prop.Ranking} max={10} readOnly />
                                          </Box>
                                        </>,
                                        Etiquetas: prop.Segmentos.length > 0 ? prop.Segmentos.map(item => {
                                          return <>{item.label}</>
                                        }) : '',*/
                                      Colonia: prop.Colonia,
                                      data: prop,
                                    };
                                  })
                                );

                              })
                              .catch((e) => {
                                setLoading(false);
                                notify.show(
                                  `Error al obtener Compromisos: ${e.error}`,
                                  "custom",
                                  5000,
                                  {
                                    background: "#0857B6",
                                    fontSize: 14,
                                    text: "#FFFFFF",
                                  }
                                );


                              });
                          }}
                        />
                      </div>
                    </>)}
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </>)
          }
        </div>

        <Footer fluid />
      </div>
    </div>
  );
};

export default Compromisos;  