import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import { getVars } from "GlobalStorage";
import { MetroSpinner } from 'react-spinners-kit';

const CardAvances = (props) => {
  const { statTotales } = props;
  const token = getVars("Token");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //cardTotal();
  }, [statTotales]); //loadingTotal, loadingCompromisos



  return (
    <Row>
      {
        loading &&
        <Col lg="12" md="12" sm="12">
          <MetroSpinner size={30} color="#686769" loading={loading} />
        </Col>
      }
      <Col lg="4" md="4" sm="4">
        <Card className="card-stats">
          <CardBody>
            <Row>
              <Col md="4" xs="5">
                <div name='d1' className="icon-big text-center icon-primary">
                  <i className="fa fa-cogs text-primary" />
                </div>
              </Col>
              <Col md="8" xs="7">
                <div name='d2' className="numbers">
                  <p className="card-category">Total Estructuras</p>
                  <CardTitle tag="p">{statTotales.totalEstructuras ? statTotales.totalEstructuras : 0}</CardTitle>
                  <p />
                </div>
              </Col>
            </Row>
            {/* <Row>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Estatales
                    </Col>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Municiales
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalEstructuras ? statTotales.totalEstructuras.estructuras[0].total : 0}</h6>
                    </Col>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalEstructuras ? statTotales.totalEstructuras.estructuras[1].total : 0}</h6>
                    </Col>
                  </Row> */}
          </CardBody>
          <CardFooter>
            <hr />
            <div name='d3' className="stats" /* onClick={() => cardTotal()} */>
              <i className="fa fa-refresh" />
              Actualizar
            </div>
          </CardFooter>
        </Card>
      </Col>
      <Col lg="4" md="6" sm="6">
        <Card className="card-stats">
          <CardBody>
            <Row>
              <Col md="4" xs="5">
                <div name='d4' className="icon-big text-center icon-primary">
                  <i className="fa fa-users text-primary" />
                </div>
              </Col>
              <Col md="8" xs="7">
                <div name='d5' className="numbers">
                  <p className="card-category">Total de Compromisos</p>
                  <CardTitle tag="p">{statTotales.totalCompromisos ? statTotales.totalCompromisos : 0}</CardTitle>
                  <p />
                </div>
              </Col>
            </Row>
            {/*  <Row>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Mujeres
                    </Col>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Hombres
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalCompromisos ? statTotales.totalCompromisos.compromisos[1].total : 0}</h6>
                    </Col>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalCompromisos ? statTotales.totalCompromisos.compromisos[0].total : 0}</h6>
                    </Col>
                  </Row> */}
          </CardBody>
          <CardFooter>
            <hr />
            <div name='d6' className="stats">
              <i className="fa fa-refresh" />
              Actualizar
            </div>
          </CardFooter>
        </Card>
      </Col>
      <Col lg="4" md="6" sm="6">
        <Card className="card-stats">
          <CardBody>
            <Row>
              <Col md="4" xs="5">
                <div name='d7' className="icon-big text-center icon-primary">
                  <i className="fa fa-user text-primary" />
                </div>
              </Col>
              <Col md="8" xs="7">
                <div className="numbers">
                  <p className="card-category">Responsables</p>
                  <CardTitle tag="p">{statTotales.totalEnlaces ? statTotales.totalEnlaces : 0}</CardTitle>
                  <p />
                </div>
              </Col>
            </Row>
            {/* <Row>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Mujeres
                    </Col>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Hombres
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalEnlaces ? statTotales.totalEnlaces.enlaces[1].total : 0}</h6>
                    </Col>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalEnlaces ? statTotales.totalEnlaces.enlaces[0].total : 0}</h6>
                    </Col>
                  </Row> */}
          </CardBody>
          <CardFooter>
            <hr />
            <div name='d8' className="stats">
              <i className="fa fa-clock-o" />
              Actual
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};


export default CardAvances;
