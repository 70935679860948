import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import { useHistory } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';

import EjecutarApi from 'data/getPOST';
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from 'GlobalStorage';
import Pagination from "components/Pagination/pagination";
import ModalFiltro from 'components/Filtro/index';
import SetResponsabilidad from './VoluntariosSetResponsabilidad'

function Voluntarios(props) {
	let history = useHistory();
	const token = getVars('Token');
	const [setsidebarMini] = useState(true);
	const [backgroundColor] = useState('black');
	const [activeColor] = useState('info');
	const mainPanel = useRef();
	const [Datos, setDatos] = useState([]);
	const [compromisos, setCompromisos] = useState([]);
	const [total, setTotal] = useState({});
	const [isEditing, setEditing] = useState(false);
	const [totalCompromisos, setTotalCompromisos] = useState(0);
	const [_menu, _setmenu] = useState({});
	const [loading, setLoading] = useState(false);
	const [Filtro, setFiltro] = useState([]);
	const [isInfo, setIsInfo] = useState({});
	const [informacion, setInformacion] = useState({});
	function roundTo(value, places) {
		var power = Math.pow(10, places);
		return Math.round(value * power) / power;
	}
	const getEstadisticas = (data) => {
		EjecutarApi(data, 'getEstadisticasCompromisos', token.access_token, "POST")
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.results) {
					setTotal(res.data);

				}

			})
			.catch((e) => {
				notify.show(`Error en totales: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
			});

	}
	// useEffect(() => {
	// 	getEstadisticas({});
	// }, [])

	useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			//ps = new PerfectScrollbar(mainPanel.current);
			document.body.classList.toggle("perfect-scrollbar-on");
		}
		if (navigator.platform.indexOf("Win") > -1) {
			// ps.destroy();
			document.body.classList.toggle("perfect-scrollbar-on");
		}
		_setmenu(getDataMenu(7, token.menus));

		if (navigator.platform.indexOf('Win') > -1) {
			//ps = new PerfectScrollbar(mainPanel.current);
			document.body.classList.toggle('perfect-scrollbar-on');
		}

		/* EjecutarApi({}, 'getEstadisticasCompromisos', token.access_token, "POST")
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.results) {
					setTotal(res.data);

				}

			})
			.catch((e) => {
				notify.show(`Error en totales: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
			}); */
	}, [total]); //loadingTotal, loadingCompromisos

	const handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			setsidebarMini(false);
		} else {
			setsidebarMini(true);
		}
		document.body.classList.toggle('sidebar-mini');
	};

	const handleSetResposabilidad = (item) => {
		let permiso = getDataMenu(7, token.menus)
		EjecutarApi({
			filterBy: "c.id",
			filterValue: item.id
		}, "getVoluntarioByParams", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.success) {
					if (res.results) {
						setDatos({ Menu: permiso, idCompromiso: item.id, Compromiso: res.data });
						setEditing(true);
					} else {
						setEditing(false);
						notify.show(res.message, "warning", 5000);
					}
				}
			}).catch((e) => {
				notify.show(`Error al obtener los datos del compromiso: ${e.error}`, "error", 5000);
				setEditing(true);
			})
		// setDatos({ Menu: permiso, idCompromiso: item.id, Compromiso: item });
		// setEditing(true);
	};
	
	const Exportar = () => {

		const data = {
			page: 0,
			pageSize: 10,
			sorted: [{ id: "c.LastUpdate", desc: true }],
			tipo: 'and',
			filtered: Filtro ? Filtro : [],
			Export: true
		};

		var datos = JSON.stringify(data)
		var url = new URL(
			`${URL_PUBLICA}exportCompromisosVoluntarios?token=${token.access_token}&serializar=${datos}`
		);
		window.open(url, "_blank");


	}

	const btnCancelarEditar = (item) => {
		setEditing(false);
	};
	const handleFiltro = (filter) => {
		setLoading(true);


		var _filtro = [];
		var _filtro_cards = {};
		if (filter.Region.value > 0) {
			_filtro.push({ id: 'r.Region', value: filter.Region.label })
			_filtro_cards = Object.assign(_filtro_cards, { 'region': [filter.Region.value] });
		}
		if (filter.Municipio.value > 0) {
			_filtro.push({ id: 'mun.Municipio', value: filter.Municipio.label })
			_filtro_cards = Object.assign(_filtro_cards, { 'municipio': [filter.Municipio.value] });
		}
		if (filter.Poligono.value > 0) {
			_filtro.push({ id: "s.idPoligono", value: filter.Poligono.value })
			_filtro_cards = Object.assign(_filtro_cards, { 'poligono': [filter.Poligono.value] });
		}
		if (filter.Seccion.value > 0) {
			_filtro.push({ id: "s.Seccion", value: filter.Seccion.value })
			_filtro_cards = Object.assign(_filtro_cards, { 'seccion': [filter.Seccion.value] });
		}
		if (filter.ClaveElector.length > 0) {
			_filtro.push({ id: "c.ClaveElector", value: filter.ClaveElector })
			_filtro_cards = Object.assign(_filtro_cards, { 'ClaveElector': filter.ClaveElector });
		}
		if (filter.Nombre.length > 0) {
			_filtro.push({ id: "c.Nombres", value: filter.Nombre })
			_filtro_cards = Object.assign(_filtro_cards, { 'Nombres': filter.Nombre });
		}
		if (filter.Paterno.length > 0) {
			_filtro.push({ id: "c.Paterno", value: filter.Paterno })
			_filtro_cards = Object.assign(_filtro_cards, { 'Paterno': filter.Paterno });
		}
		if (filter.Materno.length > 0) {
			_filtro.push({ id: "c.Materno", value: filter.Materno })
			_filtro_cards = Object.assign(_filtro_cards, { 'Materno': filter.Materno });
		}
		if (filter.Propuesta.value > 0) {
			_filtro.push({ id: "c.idResponsabilidadPropuesta", value: filter.Propuesta.value })
		}
		if (filter.DireccionVive.length > 0) {
			_filtro.push({ id: "concat_ws(' ',c.CalleVive, c.NumExtVive, c.NumIntVive, c.CPVive)", value: filter.DireccionVive })
			_filtro_cards = Object.assign(_filtro_cards, { 'Direccion': filter.DireccionVive });
		}
		if (filter.Celular.length > 0) {
			_filtro.push({ id: "c.Celular", value: filter.Celular })
			_filtro_cards = Object.assign(_filtro_cards, { 'Celular': filter.Celular });
		}
		if (filter.Responsabilidad.value > 0) {
			_filtro.push({ id: "c.idResponsabilidad", value: filter.Responsabilidad.value })
			_filtro_cards = Object.assign(_filtro_cards, { 'idResponsabilidad': [filter.Responsabilidad.value] });
		}
		if (filter.TieneUsuario.value >= 0) {
			_filtro.push({ id: "TieneUsuario", value: filter.TieneUsuario.value })
			_filtro_cards = Object.assign(_filtro_cards, { 'TieneUsuario': [filter.TieneUsuario.value] });
		}
		setFiltro(_filtro);
		//Ejecutando Filtro
		const data = {
			page: 0,
			pageSize: 10,
			sorted: [{ id: "c.LastUpdate", desc: true }],
			tipo: 'and',
			filtered: _filtro
		};
		let _menuStorage = getDataMenu(7, token.menus);

		EjecutarApi(data, "getCompromisosVoluntarios", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				setLoading(false);
				setTotalCompromisos(res.total);
				setCompromisos(
					res.data.map((prop, key) => {
						return {
							id: prop.id,
							Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
							Region: prop.Region ? prop.Region : "Sin Region",
							NombreCompleto: prop.flagEdita === 1 && _menuStorage.Editar === 1 ?
								(<><Button
									className="btn-link"
									color="primary"
									onClick={() => { handleSetResposabilidad(prop); }}
								><i className="fa fa-pencil" />{prop.Nombre}</Button></>)
								: prop.Nombre,
							SeccionVota: prop.SeccionVota ? prop.SeccionVota : 'Sin Sección',
							isVoluntario: prop["isVoluntario"] === 1 ? "Si" : "No",
							// Propuesta: prop.Propuesta.value > 1 ? `${prop.Propuesta.label} ${prop.Propuesta.valor}` : prop.Propuesta.label,
							FechaAsignoResponsabilidad: prop.FechaAsignoResponsabilidad,
							// data: prop,
							// usuario: prop.TieneUsuario === 1 ? 'Si' : 'No',
							Responsabilidad: prop.ResponsabilidadData,
							Ranking: <>
								<Box component="fieldset" mb={3} borderColor="transparent">
									<Rating name="read-only" value={prop.Ranking} max={10} readOnly onClick={{}} />
								</Box>
							</>,
						};
					})
				);

			})
			.catch((e) => {
				setLoading(false);
				notify.show(
					`Error al obtener Compromisos: ${e.error}`,
					"custom",
					5000,
					{
						background: "#0857B6",
						fontSize: 14,
						text: "#FFFFFF",
					}
				);


			});
	}

	function roundTo(value, places) {
		var power = Math.pow(10, places);
		return Math.round(value * power) / power;
	}
	
	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel">
				<Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
				<div className="content">
					{/* <Row>
						<Col lg="4" md="6" sm="6">
							<Card className="card-stats">
								<CardBody>
									<Row>
										<Col md="4" xs="5">
											<p className="card-category"><h6>GENERAL</h6></p>
											<div className="icon-big text-center icon-warning">
												<i className="fa fa-users text-primary" />
											</div>
										</Col>
										<Col md="8" xs="7">
											<div className="numbers">
												<p className="card-category">Bolsa de Voluntarios</p>
												<CardTitle tag="p">{Object.keys(total).length !== 0 ? new Intl.NumberFormat('en-EN').format(total.General.BolsaTotalVoluntarios) : ''}</CardTitle>
												<p />
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-muted text-center' >
											No Asignados
											</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											Asignados
											</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											Porcentaje
											</Col>
									</Row>
									<Row>

										<Col md="4" xs="5" className='text-center' >
											<h6>{Object.keys(total).length !== 0 ? total.General.TotalNoAsignados : ''}</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											<h6>{Object.keys(total).length !== 0 ? total.General.TotalAceptados : ''}</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											<h6>{Object.keys(total).length !== 0 ? total.General.BolsaTotalVoluntarios !== 0 ? roundTo((total.General.TotalAceptados * 100.0) / total.General.BolsaTotalVoluntarios, 2) : '0' : '0'} %</h6>
										</Col>

									</Row>
								</CardBody>
								<CardFooter>
									<hr />
									<div className="stats">
										<i className="fa fa-clock-o" />
										Actual
										</div>
								</CardFooter>
							</Card>
						</Col>
						<Col lg="4" md="6" sm="6">
							<Card className="card-stats">
								<CardBody>
									<Row>
										<Col md="4" xs="5">
											<p className="card-category"><h6>ELECTORAL</h6></p>
											<div className="icon-big text-center icon-warning">
												<i className="fa fa-sitemap text-primary" />
											</div>
										</Col>
										<Col md="8" xs="7">
											<div className="numbers">
												<p className="card-category">Bolsa De Voluntarios</p>
												<CardTitle tag="p">{Object.keys(total).length !== 0 ? new Intl.NumberFormat('en-EN').format(total.General.BolsaTotalVoluntarios) : ''}</CardTitle>
												<p />
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-muted text-center' >
											No Asignados
											</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											Asignados
											</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											Porcentaje
											</Col>
									</Row>
									<Row>

										<Col md="4" xs="5" className='text-center' >
											<h6>{Object.keys(total).length !== 0 ? total.General.TotalNoAsignados : ''}</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											<h6>{Object.keys(total).length !== 0 ? total.Electoral.TotalAceptadosElectoral : ''}</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											<h6>{Object.keys(total).length !== 0 ? total.General.BolsaTotalVoluntarios !== 0 ? roundTo((total.Electoral.TotalAceptadosElectoral * 100.0) / total.General.BolsaTotalVoluntarios, 2) : '0' : '0'} %</h6>
										</Col>

									</Row>
								</CardBody>
								<CardFooter>
									<hr />
									<div className="stats">
										<i className="fa fa-clock-o" />
										Actual
										</div>
								</CardFooter>
							</Card>
						</Col>
						<Col lg="4" md="6" sm="6">
							<Card className="card-stats">
								<CardBody>
									<Row>
										<Col md="4" xs="5">
											<p className="card-category"><h6>TERRITORIAL</h6></p>
											<div className="icon-big text-center icon-warning">
												<i className="fa fa-line-chart text-primary" />
											</div>
										</Col>
										<Col md="8" xs="7">
											<div className="numbers">
												<p className="card-category">Bolsa de Voluntarios</p>
												<CardTitle tag="p">{Object.keys(total).length !== 0 ? new Intl.NumberFormat('en-EN').format(total.General.BolsaTotalVoluntarios) : ''}</CardTitle>
												<p />
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-muted text-center' >
											No Asignados
											</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											Asignados
											</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											Porcentaje
											</Col>
									</Row>
									<Row>

										<Col md="4" xs="5" className='text-center' >
											<h6>{Object.keys(total).length !== 0 ? total.General.TotalNoAsignados : ''}</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											<h6>{Object.keys(total).length !== 0 ? total.Promocion.TotalAceptadosPromocion : ''}</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											<h6>{Object.keys(total).length !== 0 ? total.General.BolsaTotalVoluntarios !== 0 ? roundTo((total.Promocion.TotalAceptadosPromocion * 100.0) / total.General.BolsaTotalVoluntarios, 2) : '0' : '0'} %</h6>
										</Col>

									</Row>
								</CardBody>
								<CardFooter>
									<hr />
									<div className="stats">
										<i className="fa fa-clock-o" />
										Actual
										</div>
								</CardFooter>
							</Card>
						</Col>
					</Row> */}
					<Row>
						<Col>
							{isEditing ? (
								<SetResponsabilidad
									btnCancelar={btnCancelarEditar}
									menu={Datos}

								/>
							) :
								<Card>
									<CardHeader>
										<Row>
											<Col sm="4">
												{_menu.Exportar === 1 ? (
													<Button size='sm' className='primary left-align' color='primary' onClick={() => Exportar()}>
														<span className="btn-label">
															<i className="fa fa-file-excel-o fa-lg" />
														</span>
														Exportar
												</Button>
												) : <></>}
											</Col>
										</Row>
										<Row>
											<Col sm="12">
												<ModalFiltro Menu={_menu} Datos={(e) => handleFiltro(e)} />
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<div style={{ 'width': '100%', 'overflow': 'auto' }}>
											<ReactTable
												style={{
													width: "110%",
													height: "100%"
												}}
												size='sm'
												data={compromisos}
												noDataText="Sin información"
												manual
												columns={[
													{
														Header: "Region",
														minWidth: 30,
														id: "r.Region",
														accessor: (d) => d.Region,
														filterAll: false,
													},
													{
														Header: "Municipio",
														minWidth: 70,
														id: "mun.Municipio",
														accessor: (d) => d.Municipio,
														filterAll: false,
													},
													// {
													// 	Header: "Ranking",
													// 	minWidth: 50,
													// 	id: "Ranking",
													// 	accessor: (d) => d.Ranking,
													// 	filterAll: false,
													// },
													{
														Header: "Nombre",
														minWidth: 110,
														id: "c.Nombres",
														accessor: (d) => d.NombreCompleto,
														filterAll: false,
													},
													{
														Header: "Sección Vota",
														minWidth: 40,
														id: "c.SeccionVota",
														accessor: (d) => d.SeccionVota,
														filterAll: false,
													},
													{
														Header: "Responsabilidad",
														accessor: (d) => d.Responsabilidad,
														minWidth: 50,
														id: "c.ResponsabilidadData",
														filterable: false,
													},
													// {
													// 	Header: "Propuesta",
													// 	accessor: (d) => d.Propuesta,
													// 	minWidth: 50,
													// 	id: "Propuesta",
													// 	filterable: false,
													// },
													{
														Header: "¿Tiene Usuario?",
														minWidth: 45,
														id: "u2.id",
														accessor: (d) => d.usuario,
														filterAll: false,
													},
													// {
													// 	Header: "Fecha",
													// 	accessor: (d) => d.FechaAsignoResponsabilidad,
													// 	minWidth: 50,
													// 	id: "c.FechaAsignoResponsabilidad",
													// 	filterable: false,
													// },
												]}
												className="-striped -highlight primary-pagination -scrollbar"
												loading={loading}
												showPagination={true}
												showPaginationBottom={true}
												showTotalPages={true}
												showPageJump={false}
												canNextFromData={true}
												defaultPageSize={10}
												total={totalCompromisos}
												pages={Math.floor(totalCompromisos / 10)}
												previousText="Anterior"
												nextText="Siguiente"
												ofText="de"
												pageSizeOptions={[10, 20, 25, 50, 100]}
												PaginationComponent={Pagination}
												
												onFetchData={(stateComp, instance) => {
													setLoading(true);
													let _menuStorage = getDataMenu(7, token.menus);
													const data = {
														page: stateComp.page,
														pageSize: stateComp.pageSize,
														sorted: stateComp.sorted,
														tipo: 'and',
														filtered: Filtro ? Filtro : []
													};
													EjecutarApi(data, "getCompromisosVoluntarios", token.access_token)
														.then(function (res) {
															return res;
														})
														.then((res) => {
															setLoading(false);
															setTotalCompromisos(res.total);
															setCompromisos(
																res.data.map((prop, key) => {
																	return {
																		id: prop.id,
																		Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
																		Region: prop.Region ? prop.Region : "Sin Region",
																		NombreCompleto: prop.flagEdita === 1 && _menuStorage.Editar === 1 ?
																			(<><Button
																				className="btn-link"
																				color="primary"
																				onClick={() => { handleSetResposabilidad(prop); }}
																			><i className="fa fa-pencil" />{prop.Nombre}</Button></>)
																			: prop.Nombre,
																		SeccionVota: prop.SeccionVota ? prop.SeccionVota : 'Sin Sección',
																		isVoluntario: prop["isVoluntario"] === 1 ? "Si" : "No",
																		// Propuesta: prop.Propuesta.value > 1 ? `${prop.Propuesta.label} ${prop.Propuesta.valor}` : prop.Propuesta.label,
																		FechaAsignoResponsabilidad: prop.FechaAsignoResponsabilidad,
																		// data: prop,
																		// usuario: prop.TieneUsuario === 1 ? 'Si' : 'No',
																		Responsabilidad: prop.ResponsabilidadData,
																		Ranking: <>
																			<Box component="fieldset" mb={3} borderColor="transparent">
																				<Rating name="read-only" value={prop.Ranking} max={10} readOnly onClick={{}} />
																			</Box>
																		</>,
																	};
																})
															);

														})
														.catch((e) => {
															setLoading(false);
															notify.show(
																`Error al obtener Compromisos: ${e.error}`,
																"custom",
																5000,
																{
																	background: "#0857B6",
																	fontSize: 14,
																	text: "#FFFFFF",
																}
															);
														});
												}}
											/>
										</div>
									</CardBody>
									<CardFooter>
										{isInfo && (<>

										</>)}
									</CardFooter>
								</Card>
							}
						</Col>
					</Row>
				</div>
				<Footer fluid />
			</div>
		</div>
	);
};

export default Voluntarios;
