import React, { useEffect, useState } from "react";

import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";

//Zincri imports
import ChoropethMap from "components/Maps/Electoral/Distritos/ChoropethMapV5DistritoFederalEstructura.jsx";
import { getVars } from "GlobalStorage";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { URL_GENERAL } from "data/Consts";
import { getDataMenu } from "data/menus";
let PRESIDENTE_DE_LA_REPUBLICA = 50;
let GOBERNADOR = 51;
let SENADOR = 52;
let DIPUTADO_FEDERAL = 53;
let SUPERVISOR_ESTATAL_ELECTORAL = 98;
let COORDINADOR_ESTATAL_TERRTORIAL = 97;
let COORDINADOR_GENERAL = 99;
let COORDINADOR_GENERAL_SOCIAL = 30;
//let CONST_REGIONAL = 10;
let CONST_MUNICIPAL = 11;
let CONST_POLIGONAL = 12;
let CONST_SECCIONAL = 13;

const Mapas = (props) => {

  const token = getVars("Token");
  const [menu, _setmenu] = useState({});
  const [dataGeoJson, setDataGeoJson] = useState({});
  const [tipoMapa, setTipoMapa] = useState(
    getVars("Token").user.Responsabilidad.value === CONST_SECCIONAL ?
      { value: "seccion", label: "Seccional" } :
      getVars("Token").user.Responsabilidad.value === CONST_POLIGONAL ?
        { value: "poligono", label: "Poligonal" } :
        getVars("Token").user.Responsabilidad.value === CONST_MUNICIPAL ?
          { value: "municipio", label: "Municipal" } :
          { value: "distrito", label: "Distrital" }
  );
  const zoom =
    getVars("Token").user.Responsabilidad.value === CONST_SECCIONAL ?
      9 :
      getVars("Token").user.Responsabilidad.value === CONST_POLIGONAL ?
        9 :
        getVars("Token").user.Responsabilidad.value === CONST_MUNICIPAL ?
          8.5 :
          8.5;
  const [derechoMapa, setDerechoMapa] = useState([]);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState("");
  //const [catTipoMapa, setCatTipoMapa] = useState([]);

  const [rangos, setRangos] = useState([]);
  const hostserver = URL_GENERAL;



  /* const position = {
    latitude: 20.678416,
    longitude: -101.354231,
  }; */
  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  useEffect(() => {
    _setmenu(getDataMenu(85, token.menus));

    // const token = getVars("Token").access_token;
    //AQUI VA IR LA RESPONSABILIDAD PARA MARCAR EL MAPA POR DEFECTO 
    //depende su responsabilidad setearemos el estado para mandarselo al hook del mapa
    selectDerecho();

    async function getRangos() {
      let config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      };
      try {
        let res = await fetch(hostserver + "getRangosEstructuraGto", config);
        let data = await res.json();
        setRangos(data);
      } catch (error) {
        setFlagAlert(true);
        setDataGeoJson({
          "type": "FeatureCollection",
          "features": [
          ]
        });
      }
    }
    getRangos();
  }, []);
  const basicAlert = (
    <ReactBSAlert
      style={{
        display: "block",
        marginTop: "-100px",
        padding: '2px 4px',
        font: '10px/12px Arial, Helvetica, sans-serif'
      }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );

  function selectDerecho() {
    let derecho_mapa = getVars("Token").user.Responsabilidad.value;

    setDerechoMapa(
      derecho_mapa === COORDINADOR_GENERAL ||
        derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
        derecho_mapa === COORDINADOR_GENERAL_SOCIAL ||
        derecho_mapa === PRESIDENTE_DE_LA_REPUBLICA ||
        derecho_mapa === GOBERNADOR ||
        derecho_mapa === SENADOR ||
        derecho_mapa === DIPUTADO_FEDERAL ||
        derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL ? [1, 2, 3, 4] :
        derecho_mapa === CONST_MUNICIPAL ? [1, 2, 3] :
          derecho_mapa === CONST_POLIGONAL ? [1, 2] :
            derecho_mapa === CONST_SECCIONAL ? [1] :
              []
    );
    if (derecho_mapa === COORDINADOR_GENERAL ||
      derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
      derecho_mapa === COORDINADOR_GENERAL_SOCIAL ||
      derecho_mapa === PRESIDENTE_DE_LA_REPUBLICA ||
      derecho_mapa === GOBERNADOR ||
      derecho_mapa === SENADOR ||
      derecho_mapa === DIPUTADO_FEDERAL ||
      derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL) {
      getDistritos("", "GET");
    }
    if (derecho_mapa === CONST_MUNICIPAL) {
      getMunicipios("", "GET");
    }
    if (derecho_mapa === CONST_POLIGONAL) {
      getPoligonos("", "GET");
    }
    if (derecho_mapa === CONST_SECCIONAL) {
      getSecciones("", "GET");
    }

  }

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };


  async function getDistritos(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro.clave, distrito: clave_parametro.distrito }),
        };
      }
      let res = await fetch(
        hostserver + "getDistritoDFEstructuraGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function getMunicipios(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro.clave, distrito: clave_parametro.distrito }),
        };
      }
      let res = await fetch(
        hostserver + "getMunicipiosDistritoDFEstructuraGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function getSecciones(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro.clave, distrito: clave_parametro.distrito }),
        };
      }
      let res = await fetch(
        hostserver + "getSeccionesDFEstructuraGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function getPoligonos(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro.clave, distrito: clave_parametro.distrito }),
        };
      }
      let res = await fetch(
        hostserver + "getPoligonosDFEstructuraGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }


  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapaparametros(e);
  }

  const handleTipoMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapa(e);
  }
  function selectmapa(tipomapa) {
    if (tipomapa.value === 'distrito') {
      getDistritos("", "GET");
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios("", "GET");
    }
    if (tipomapa.value === 'poligono') {
      getPoligonos("", "GET");
    }
    if (tipomapa.value === 'seccion') {
      getSecciones("", "GET");
    }
  }

  function selectmapaparametros(tipomapa) {
    if (tipomapa.value === 'distrito') {
      getDistritos(tipomapa, "POST");
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios(tipomapa, "POST");
    }
    if (tipomapa.value === 'poligono') {
      getPoligonos(tipomapa, "POST");
    }
    if (tipomapa.value === 'seccion') {
      getSecciones(tipomapa, "POST");
    }
  }

  const handleExport = (e) => {

    var dato = [];
    e.features.forEach(element => {

      if (tipoMapa.value === 'distrito') {
        dato.push(element.properties['clave'])
      }
      else if (tipoMapa.value === 'municipio') {
        dato.push(element.properties['cve_mun'])
      }
      else if (tipoMapa.value === 'poligono') {
        dato.push(element.properties['poligono'])
      }
      else if (tipoMapa.value === 'seccion') {
        dato.push(element.properties['seccion'])
      }
    });
    const json = {
      data: dato,
      tabla: tipoMapa.value
    }

    const serializado = JSON.stringify(json)

    var url = new URL(
      hostserver + `getReporteMapasEstructura?token=${token.access_token}&datos=${serializado}`
    );
    //window.open(url, "_blank"); 
  }

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <div className="content">
          <>
            {FlagAlert ? basicAlert : <></>}
            <ChoropethMap
              key={"ChoropethMapV4"}
              center={[position.latitude, position.longitude]}
              zoom={zoom}
              data={dataGeoJson}
              rangos={rangos}
              tipo={tipoMapa.value}
              label_tipo={tipoMapa.label}
              handleTipoMapa={handleTipoMapa}
              handleDetalleMapa={handleDetalleMapa}
              derecho_mapa={derechoMapa}
              Exportar={menu.Exportar}
              MenuPermisos={menu}
              setExport={handleExport}
            ></ChoropethMap>

          </>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
