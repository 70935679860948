import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  Row,
  Col,
} from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';
import Pagination from '../../components/Pagination/pagination';
import EjecutarApi from '../../data/getPOST';
import { getDataMenu } from '../../data/menus';
import ModalFiltro from '../../components/Filtro/indexCasillasIncidencias';

import { getVars, URL_PUBLICA_REPORTE, tiempoSije,INTERVAL } from 'GlobalStorage';
import IncidenciaAdd from './IncidenciaAdd';
import IncidenciaEdit from './IncidenciasEdit';

const CasillasIncidencia = (props) => {
  const DataIncidencias = [
    {
      Hora: ' 9:35',
      Municipio: 'La PAZ',
      DL: '7-La Paz',
      DF: '7-La Paz',
      Seccion: 714,
      Casilla: 'C1',
      TipoIncidencia:
        '5.-SUSPENSIÓN TEMPORAL DE LA VOTACIÓN POR CAUSAS DE FUERZA MAYOR POR:',
      Circunstancias:
        'Al momento los funcionarios y personas que se encontraban votando se percataron que el Partido Verde y Nueva Alianza tienen un candidato comun, por lo que representantes de los partidos presentes piden se suspendan la votacion y la ciudadania ya no quiere segui votando.',
      Estatus: 'Pendiente',
      HoraSolucion: '',
      DescripcionSolucion: '',
      RG: ' Zincri Mendoza',
      TelRG: 9613879,
      Clasificación: 'Favorable',
    },
    {
      Hora: ' 9:35',
      Municipio: 'La PAZ',
      DL: '7-La Paz',
      DF: '7-La Paz',
      Seccion: 714,
      Casilla: 'C1',
      TipoIncidencia:
        '5.-SUSPENSIÓN TEMPORAL DE LA VOTACIÓN POR CAUSAS DE FUERZA MAYOR POR:',
      Circunstancias:
        'Al momento los funcionarios y personas que se encontraban votando se percataron que el Partido Verde y Nueva Alianza tienen un candidato comun, por lo que representantes de los partidos presentes piden se suspendan la votacion y la ciudadania ya no quiere segui votando.',
      Estatus: 'Pendiente',
      HoraSolucion: '',
      DescripcionSolucion: '',
      RG: ' Zincri Mendoza',
      TelRG: 9613879,
      Clasificación: 'Favorable',
    },
    {
      Hora: ' 9:35',
      Municipio: 'La PAZ',
      DL: '7-La Paz',
      DF: '7-La Paz',
      Seccion: 714,
      Casilla: 'C1',
      TipoIncidencia:
        '5.-SUSPENSIÓN TEMPORAL DE LA VOTACIÓN POR CAUSAS DE FUERZA MAYOR POR:',
      Circunstancias:
        'Al momento los funcionarios y personas que se encontraban votando se percataron que el Partido Verde y Nueva Alianza tienen un candidato comun, por lo que representantes de los partidos presentes piden se suspendan la votacion y la ciudadania ya no quiere segui votando.',
      Estatus: 'Pendiente',
      HoraSolucion: '',
      DescripcionSolucion: '',
      RG: ' Zincri Mendoza',
      TelRG: 9613879,
      Clasificación: 'Favorable',
    },
  ];
  const [data, setData] = useState([]);
  const [Filtro, setFiltro] = useState([{ id: 'casillas_incidencias.isResuelto', value:0}]);
  const [MenuModal] = useState({
    Compromiso: false,
    Voluntarios: false,
    RCs: true,
    RGs: false,
    Abogado: false,
    CasaAzul: false,
    Municipio: false,
  });
  const token = getVars('Token');
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [isCaptura, setIsCaptura] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editIncidencia, setEditIncidencia] = useState([]);
  const [_menu, _setmenu] = useState({});

  useEffect(() => {
    _setmenu(getDataMenu(165, token.menus));
}, [])



  const getIncidencias = (data) => {
    setIsLoading(true);
    EjecutarApi(data, 'getCasillasIncidencias', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
        setTotal(res.total);
      })
      .catch((e) => {
        setIsLoading(false);
        notify.show(
          `Error al cargar incidencias: ${e.message} Favor de renovar sesión`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const handleFiltro = (filter) => {

    var _filtro = [];
    if (filter.DF.value > 0) {
      _filtro.push({
        id: 'cat_municipios_reportes.id',
        value: filter.DF.value,
      });
    }
	if (filter.Poligono.value > 0) {
		_filtro.push({
		  id: 'cat_poligonos.id',
		  value: filter.Poligono.value,
		});
	  }
    if (filter.DL.value > 0) {
      _filtro.push({
        id: 'cat_municipios_reportes.id',
        value: filter.DL.value,
      });
    }
    if (filter.Municipio.value > 0) {
      _filtro.push({
        id: 'cat_municipios_reportes.id',
        value: filter.Municipio.value,
      });
    }
    if (filter.Seccion.value > 0) {
      _filtro.push({
        id: 'casillas_incidencias.Seccion',
        value: filter.Seccion.value,
      });
    }
    console.log(filter.idIncidencia.value)
    if (filter.idIncidencia.value > 0) {
      _filtro.push({
        id: 'casillas_incidencias.idIncidencia',
        value: filter.idIncidencia.value,
      });
    }
    if (filter.Casilla.trim().length > 0) {
      _filtro.push({
        id: 'casillas_incidencias.NombreCasilla',
        value: filter.Casilla,
      });
    }
    if (filter.Estatus.value > 0) {
      _filtro.push({
        id: 'casillas_incidencias.idStatus',
        value: filter.Estatus.value,
      });
    }
    if (filter.TelRG.trim().length > 0 && parseFloat(filter.TelRG.trim())) {
      _filtro.push({ id: 'rgs.Celular', value: filter.TelRG.trim() });
    }
    if (filter.RG.trim().length > 0) {
      _filtro.push({ id: 'rgs.NombreCompleto', value: filter.RG.trim() });
    }
    if (filter.isResuelto.value === 0 || filter.isResuelto.value === 1) {
      _filtro.push({ id: 'casillas_incidencias.isResuelto', value: filter.isResuelto.value });
    }

    setFiltro(_filtro);

    const data = {
      page: 0,
      pageSize: 10,
      filtered: _filtro,
      tipo: 'and',
    };
    getIncidencias(data);
  };

  const styleHeader = {
    textAlign: "center",
    overflow: 'visible',
    fontSize: '11px',
    color: '#363636',
    align:'center',
    lineHeight: 'normal !important',
  };
  const header = (text) => {
    return <Label style={styleHeader}>{text}</Label>;
  };

  const columns = [
    {
      Header: header('Municipio'),
      id: 'cat_municipios_reportes.Municipio',
      accessor: 'Municipio',
      minWidth: 40,
      filterAll: false,
      headerStyle:{ fontSize: '9px'}
    },
    {
      Header: header('Sección'),
      id: 'casillas_incidencias.Seccion',
      accessor: 'Seccion',
      minWidth: 30,
      filterAll: false,
      headerStyle:{ fontSize: '9px'}
    },
    {
      Header: header('Casilla'),
      id: 'casillas_incidencias.NombreCasilla',
      accessor: 'NombreCasilla',
      minWidth: 30,
      filterAll: false,
      headerStyle:{ fontSize: '9px'}
    },
    {
      Header: header('Hora Incidencia'),
      id: 'casillas_incidencias.HoraIncidencia',
      accessor: 'HoraIncidencia',
      minWidth: 30,
      filterAll: false,
      headerStyle:{ fontSize: '9px'}
    },
    {
      Header: header('incidencia'),
      id: 'cat_incidencias_status.Incidencia',
      accessor: 'Incidencia',
      cellBordered:true,
      maxWidth: 200,
      filterable: false,
      headerStyle:{ fontSize: '9px'}
    },
    {
      Header: header('Solucionado'),
      id: 'casillas_incidencias.isResuelto',
      accessor: 'isResuelto',
      minWidth: 40,
      filterable: false,
      headerStyle:{ fontSize: '9px'}
    },
    {
      Header: header('Hora Solución'),
      id: 'casillas_incidencias.HoraSolucion',
      accessor: 'HoraSolucion',
      minWidth: 40,
      filterable: false,
      headerStyle:{ fontSize: '9px'}
    },
    {
      Header: header('Estatus'),
      id: 'cat_incidencias_status.Estatus',
      accessor: 'Estatus',
      minWidth: 40,
      filterable: false,
      headerStyle:{ fontSize: '9px'}
    }
  ];

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const onHide = () => {
    setIsCaptura(false)
    setIsEdit(false)
    
  }

  const Exportar = () => {
   
    const data = {
      page: 1,
      pageSize: 10,
      filtered: Filtro,
      sort:[{ id: "Updated_at", desc: false }],
      tipo: 'and',
      Export:true
    };

    var datos = JSON.stringify(data)
    var url = new URL(
        `${URL_PUBLICA_REPORTE}ExportCasillasIncidencias?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");

  }

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {
            !isCaptura && !isEdit?
            <Card>
            <CardHeader>
              <h5 className="title">Reporte de Incidencias</h5>
              <Col sm="12" md="12" lg="12">
                <Row>
                  {
                    _menu.Agregar ?
                   <>
                   <Button color="success" size="sm" onClick={() => setIsCaptura(true)} >
                    <span className="btn-label">
                      <i className="fa fa-user-plus fa-lg" />
                    </span>
                    Capturar
                    </Button>
                    </>
                    :<></>
                  }
                  {_menu.Exportar === 1 ? (
                  <Button
                    size="sm"
                    className="primary left-align"
                    color="primary"
                    onClick={() => Exportar(true)}
                  >
                    <span className="btn-label">
                      <i className="fa fa-file-excel-o fa-lg" />
                    </span>
                    Exportar
                  </Button> 
                    ) : <></>} 
                </Row>
                <Row>
                  <Col sm="12">
                    <ModalFiltro
                      Menu={MenuModal}
                      Datos={(e) => handleFiltro(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </CardHeader>
            <CardBody>
              {/*  {<RotateSpinner color={"#1261ff"} size={30} loading={loading} />} */}
              <div style={{ width: '100%', overflow: 'auto' }}>
                <ReactTable
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  size="sm"
                  data={data}
                  noDataText="Sin información"
                  manual
                  columns={columns}
                  className="-striped -highlight primary-pagination"
                  loading={isLoading}
                  showPagination={true}
                  showPaginationBottom={true}
                  showTotalPages={true}
                  showPageJump={false}
                  canNextFromData={true}
                  defaultPageSize={10}
                  total={total}
                  pages={Math.floor(total / 10)}
                  previousText="Anterior"
                  nextText="Siguiente"
                  ofText="de"
                  pageSizeOptions={[10, 20, 25, 50, 100]}
                  getTdProps={(state, rowInfo, column) => {
                    return {
                      onClick: (e, t) => { 
                        if(rowInfo){
                          setIsEdit(true); setEditIncidencia(rowInfo.row._original.id);
                        }
                      },
                      style: {
                          background: rowInfo && rowInfo.row.selected ? 'green' : 0,
                          height: '100px',
                          width: '40px',
                          cursor: 'pointer'
                      }
                  }
                }}
                
                  PaginationComponent={Pagination}
                  onFetchData={(stateComp) => {
                    const data = {
                      page: stateComp.page,
                      pageSize: stateComp.pageSize,
                      filtered: Filtro,
                      sort:[{ id: "Updated_at", desc: false }],
                      tipo: 'and',
                    };
                    getIncidencias(data);
                  }}
                  params={Filtro}
                />
              </div>
            </CardBody>
          </Card>
            :(isCaptura)?(<IncidenciaAdd onHide={() => onHide()}></IncidenciaAdd>):(<IncidenciaEdit props={editIncidencia} onHide={() => onHide()}></IncidenciaEdit>)
          }
          
        
        </div>
      </div>
    </div>
  );
};
export default CasillasIncidencia;
