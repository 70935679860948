import React, { useState,useEffect } from 'react';
import { Row,Col, Input, Button,FormGroup,Modal,ModalHeader,ModalBody, ModalFooter, Card,CardBody} from 'reactstrap';
import { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';
import ReactTable from 'react-table';

import Pagination from "../Pagination/pagination";
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";


const Responsabilidad = ({props,Datos,onHide}) => {
    //const [close, setClose] = useState(true);
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
    const [TotalRegistros,setTotalRegistros]= useState(0);
	  const [Registros,setRegistros]=useState([]);
   
    const [Comentarios,setComentarios]=useState('');
    const [ComentarioBaja]=useState('');
    const [isContrapropuesta] = useState(false);
    
    const [VoluntarioNombre, setVoluntarioNombre] = useState('');
    const [VoluntarioPaterno, setVoluntarioPaterno] = useState('');
    const [VoluntarioMaterno, setVoluntarioMaterno] = useState('');
    const [idContrapropuesta]=useState(0);
    const [showGrid, setshowGrid] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [showBtnSetContrap, setShowBtnSetContrap] = useState(false);

    useEffect(() => {
       

    },[])
    
    const handleAceptar=()=>{
        var parametros={idPropuesta:Datos.idPropuesta,idResponsabilidad:2};
        EjecutarApi(parametros, 'aceptarPropuestaElectoral', token.access_token)
			.then(function(res) {
				return res;
			})
			.then((res) => {
                
                if(res.success)
                {
                    setLoading(false);
                    notify.show(`Propuesta Aceptada!`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    onHide();
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
            });
    }
    const handleRechazar=()=>{
        var parametros={
            idPropuesta: Datos.idPropuesta,
            Comentario:Comentarios

        }
        EjecutarApi(parametros, 'cancelarPropuestas', token.access_token)
			.then(function(res) {
				return res;
			})
			.then((res) => {
                
                if(res.success)
                {
                    setLoading(false);
                    notify.show(`Propuesta Cancelada!`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    onHide();
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
            });
    }
    /*const handleContra=()=>{
        setisContrapropuesta(true);
    }*/
    const handleBuscaVoluntario=()=>{
        setshowGrid(true);
        
    }
    const handleRowContrapropuesta=()=>{
        setShowBtnSetContrap(true);
        setShowButtons(false);
    }
    const handleAceptarContrap=()=>{
      var parametros={idPropuesta:Datos.idPropuesta,idResponsabilidad:2};
      parametros={
        idCompromiso: idContrapropuesta,
        idResponsabilidad: 2,
        comentarioPropuesta: ComentarioBaja,
        comentarioContraPropuesta: Comentarios,
        idPropuesta: Datos.id
    
      }
      EjecutarApi(parametros, 'aceptarPropuestaElectoral', token.access_token)
    .then(function(res) {
      return res;
    })
    .then((res) => {
              
              if(res.success)
              {
                  setLoading(false);
                  notify.show(`Propuesta Aceptada!`, 'custom', 5000, {
                      background: '#0857B6',
                      fontSize: 14,
                      text: '#FFFFFF'
                  });
                 
              }
              
    })
    .catch((e) => {
      setLoading(false);
      notify.show(`Error en: ${e.error}`, 'custom', 5000, {
        background: '#0857B6',
        fontSize: 14,
        text: '#FFFFFF'
      });
          });
    }
    return (
      <Modal
        isOpen={true}
        toggle={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vtop"
        centered
      >
        <ModalHeader toggle={onHide} >
          Aceptar Responsabilidad
        
        </ModalHeader>
        <ModalBody><Col sm="12">
            {loading && (
                      <MetroSpinner
                        size={30}
                        color="#0000FF"
                        loading={loading}
                      />
                    )}
            <Row>
                <Col sm="6">
                    <Card>
                    <CardBody>
                        
                        <Row>
                        <Col>Quién Propone: <strong className="text-muted" >{Datos.UserAsignoResponsabilidad}</strong> </Col>
                        </Row>
                        <Row>
                        <Col>Celular Propone: <strong className="text-muted" >{Datos.CelularResponsable}</strong></Col>
                        </Row>
                        <Row>
                        <Col>Fecha de Propuesta: <strong className="text-muted" >{Datos.FechaAsignoResponsabilidad}</strong></Col>
                        </Row>
                    </CardBody>
                    </Card>
                </Col>
                <Col sm="6">
                <Card>
                  <CardBody>
                    <Col sm="12">
                        <Row>
                            <Col>Propuesta:  <strong className="text-muted" >{Datos.Fullname}</strong></Col>
                        </Row>
                        <Row>
                            <Col>Sección: <strong className="text-muted" >{Datos.Seccion}</strong> </Col>
                        </Row>
                        <Row>
                            <Col>Nombre Casilla:  <strong className="text-muted" >{Datos.NombreCasilla}</strong></Col>
                        </Row>
                        <Row>
                            <Col>Propietario:  <strong className="text-muted" >{Datos.Propietario}</strong></Col>
                        </Row>
                        <Row>
                            <Col>¿Ha sido funcionario/representante de casillla?  <strong className="text-muted" >{Datos.TieneExperiencia===0?'No':'Si'}</strong></Col>
                        </Row>
                        <Row>
                            <Col>¿Tiene Auto?  <strong className="text-muted" >{Datos.TieneAuto===0?'No':'Si'}</strong></Col>
                        </Row>
                        <Row>
                            <Col>¿Aceptar cuidar otra casilla?  <strong className="text-muted" >{Datos.AceptaCuidarOtra===0?'No':'Si'}</strong></Col>
                        </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            </Col>
          {isContrapropuesta?<>
            <Row>
                <Col sm="3">
                    Nombre:
                    <Input id='bvoluntarioN' value={VoluntarioNombre} onChange={(e) => setVoluntarioNombre(e.target.value)} />
                </Col>
                <Col sm="3">
                    Paterno:
                    <Input id='bvoluntarioP' value={VoluntarioPaterno} onChange={(e) => setVoluntarioPaterno(e.target.value)} />
                </Col>
                <Col sm="3">
                    Materno:
                    <Input id='bvoluntarioM' value={VoluntarioMaterno} onChange={(e) => setVoluntarioMaterno(e.target.value)} />
                </Col>
                <Col sm="3"><Button 
                  id="btnRechazar"
                  color="warning"
                  className="warning"
                  onClick={() => handleBuscaVoluntario()}
                >
                  Buscar
                </Button></Col>
            </Row>
            <Row>
                <Col sm="12">
                  {showGrid?
                      <div style={{ 'width': '100%', 'overflow': 'auto'}}>
                      <ReactTable 
                            style={{
                                width: "110%",
                                  height: "100%"
                                }}
                            size='sm'
                            data={Registros}
                            noDataText="Sin información"
                            manual
                            columns={[
                              {
                                Header: "Municipio",
                                minWidth: 120,
                                id: "Municipio",
                                accessor: (d) => d.Municipio,
                                filterAll: false,
                              },
                              {
                                Header: "Localidad",
                                minWidth: 30,
                                id: "Localidad",
                                accessor: (d) => d.Localidad,
                                filterAll: false,
                              },
                              {
                                Header: "Colonia",
                                minWidth: 30,
                                id: "Colonia",
                                accessor: (d) => d.Colonia,
                                filterAll: false,
                              },

                              {
                                Header: "Nombre",
                                minWidth: 40,
                                id: "Nombre",
                                accessor: (d) => d.Nombre,
                                filterAll: false,
                              },
                              {
                                Header: "is Voluntario",
                                minWidth: 40,
                                id: "Voluntario",
                                accessor: (d) => d.Voluntario,
                                filterAll: false,
                              }
                            ]}
                            className="-striped -highlight primary-pagination"
                            loading={loading}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={5}
                            total={TotalRegistros}
                            pages={Math.floor(TotalRegistros / 5)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[5]}
                            PaginationComponent={Pagination}
                            onFetchData={(stateComp, instance) => {
                                var parametros={
                                  page: stateComp.page, 
                                  pageSize:stateComp.pageSize,
                                  filtered:[{
                                        id:"nombre",value:"VoluntarioNombre"},
                                        {id:"paterno",value:"VoluntarioPaterno"},
                                        {id:"paterno",value:"VoluntarioMaterno"}]};
                                EjecutarApi(parametros, 'buscarResponsablesByName', token.access_token)
                                  .then(function(res)
                                  {
                                      return(res);
                                  }
                                  )
                                  .then((res)=>{
                                      setTotalRegistros(res.total);
                                      setRegistros(res.data.map((item) => {
                                          return {
                                              Municipio:item.idMunicipioVota.label,
                                              Localidad:item.Localidad,
                                              Colonia:item.Colonia,
                                              Nombre:(<><Button
                                                className="btn-link"
                                                color="primary"
                                                onClick={() => { handleRowContrapropuesta(item); }}
                                                >{item.FullName}</Button></>)
                                              
                                              
                                              ,
                                              Voluntario: item.Propuesta
                                          }
                                      }))
                                      
                                  })    
                            }}
                        />
                      </div>      
                    :<></>}
                </Col>
            </Row>
           </>
          :<></>}
        </ModalBody>
        <ModalFooter>
        {showButtons? <Row>
            <Col md="4">
              <FormGroup sm="4">
                <Button
                  id="btnAceptar"
                  color="success"
                  className="success"
                  onClick={() => handleAceptar()}
                >
                  Aceptar
                </Button> {' '}
              </FormGroup>
            </Col>
            <Col md="1">{' '}</Col>
            <Col md="3">
           {/*  <FormGroup sm="4">
                <Button
                  id="btnRechazar"
                  color="danger"
                  className="danger"
                  onClick={() => handleRechazar()}
                >
                  Rechazar
                </Button>
              </FormGroup>
        */}</Col>
          </Row>:<></>} 
        {showBtnSetContrap? <Row>
            <Col md="3">
              <FormGroup sm="4">
                <Button
                  id="btnAceptar"
                  color="success"
                  className="success"
                  onClick={() => handleAceptarContrap()}
                >
                  Aceptar
                </Button>
              </FormGroup>
            </Col>
            <Col md="3">
              {/*<FormGroup sm="4">
                <Button
                  id="btnRechazar"
                  color="danger"
                  className="danger"
                  onClick={() => setShowButtons(true)}
                >
                  Cancelar
                </Button>
        </FormGroup>*/}
            </Col>
          </Row>:<></>} 
        </ModalFooter>
      </Modal>
    );
};
export default Responsabilidad;