import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer"
import Sidebar from "components/Sidebar";
import { getVars } from 'GlobalStorage';
import ReactTable from 'react-table';

import {
  Button, Card, CardHeader, CardBody, Row, Col, TabContent,
  TabPane, NavItem, Label, FormGroup,
  NavLink,
  Nav,
} from 'reactstrap';
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { CircleSpinner } from "react-spinners-kit";
import { URL_GENERAL } from "data/Consts";
import Pagination from "components/Pagination/pagination";

const ElectoralMunicipal = (props) => {

  // Configuracion
  const [setsidebarMini] = useState(true);
  const [backgroundColor] = useState("black");
  const [activeColor] = useState("info");
  const [loading, setLoading] = useState(false);
  const [loadingTabla, setLoadingTabla] = useState(false);
  const token = getVars('Token');
  const [horizontalTabs, setHorizontalTabs] = useState({
    horizontalTabs: "RCS",
    openedCollapses: ["collapseOne", "collapse1"]
  })
  var Responsabilidad = token.user.Responsabilidad.value;
 
  const [Filtro, setFiltro] = useState([]);
  const [total, setTotal] = useState(0);
  const [meta, setMeta] = useState([]);
  const [municipioSelected, setMunicipioSelected] = useState({ value: 0, label: 'Todos' });
  const [regionSelected, setRegionSelected] = useState({ value: 0, label: 'Todos' })
  const [tabla, setTabla] = useState("RCS");

  // Catalogos

  const [showMeta, setShowMeta] = useState(1);
  const [col, setCol] = useState("12");


  //catalogos
  const [catMunicipioFilter, setCatMunicipioFilter] = useState([])

  const [catRegiones, setCatRegiones] = useState([{ value: 0, label: 'Todos' }])
  const [catMunicipio, setCatMunicipio] = useState([{ value: 0, label: 'Todos' }])


  const [menu, _setmenu] = useState({});

  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(72, token.menus));

    let regionesFormated = [];
    let municipioFormated = [];
    EjecutarApi({}, "getCatalogosFiltrosElectoralClonIdMunicipioReportes", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {
            let regiones = [{ value: 0, label: 'Todos' }]
            let municipios = [{ value: 0, label: 'Todos' }]

            regionesFormated = res.data.regiones.map(item => {
              return {
                value: item.value,
                label: item.label
              }
            })
            const result = regionesFormated.filter(reg => reg.label !== 'NO DEFINIDO');
            setCatRegiones(regiones.concat(result))
            //CatMunicipio llenar

            municipioFormated = res.data.municipios.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
              }
            })
            setCatMunicipio(municipios.concat(municipioFormated))

            setCatMunicipioFilter(municipios.concat(municipioFormated))

          } else {
            showMessage("No Info: getCatalogosFiltrosElectoral", '#0E7CF1')
          }
        }
      })
      .catch((e) => {
        showMessage("Error: getCatalogosFiltrosElectoral", "#F1370E")
      }).then(() => {
        setLoading(false);
      })

  }, [tabla]);

  useEffect(() => {
    if (horizontalTabs.horizontalTabs === "RCS") {
        setShowMeta(1)
    }
    if (horizontalTabs.horizontalTabs === "RGS") {
        setShowMeta(2)
    }
    if (horizontalTabs.horizontalTabs === "Abogados") {
        setShowMeta(3)
    }
    if (horizontalTabs.horizontalTabs === "Observador") {
        setShowMeta(4)
    }
    if (horizontalTabs.horizontalTabs === "CasaAmiga") {
        setShowMeta(5)
    }
    if (horizontalTabs.horizontalTabs === "PrimeroFila") {
      setShowMeta(6)
  }
}, [horizontalTabs])


  const showMessage = (message, background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
    } else {
      setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };



  // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA

  const handleRegiones = (e) => {
    setLoadingTabla(true)
    setLoading(true)
    setRegionSelected(e)

    if (e.value > 1) {
      let municipios = [{ value: 0, label: 'Todos' }]

      setCatMunicipioFilter(municipios.concat(catMunicipio.filter(data => data.idRegion === e.value)));
      fitroSend(e, "region");

    } else {
      setCatMunicipioFilter(catMunicipio);
      fitroSend(e, "region");
    }
    setLoading(false);
  }

  const fitroSend = (e, handle, t = false) => {
    var filtro = [];
    if (handle !== "clear") {
      if (e.value > 0 && handle === "region") { filtro.push({ "id": "municipio.idRegion", "value": e.value }) }
      if (e.value > 0 && handle === "municipio") { filtro.push({ "id": "municipio.id", "value": e.value }) }
      if (regionSelected.value > 0 && handle !== "region") { filtro.push({ "id": "municipio.idRegion", "value": regionSelected.value }) }
      if (municipioSelected.value > 0 && handle !== "municipio") { filtro.push({ "id": "municipio.id", "value": municipioSelected.value }) }
    }


    setFiltro(filtro); 

    const dato = (t) ? t = t : t = tabla;
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      tipo: 'and',
      filtered: filtro,
      Electoral: dato
    };
    EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setLoadingTabla(false);
            setTotal(res.total);
            if (t !== "RCS") {
              setMeta(res.data.map((prop, key) => {
                var porcentaje = parseFloat(prop.Porcentaje)
                var ddporcentaje = porcentaje.toFixed(2)
                return {
                  id: prop.id,
                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                  meta: prop.Meta,
                  AvanceEstructura: prop.ElectoralDia ? prop.ElectoralDia : 0,
                  AvanceRGs: prop.Total ? prop.Total : 0,
                  porcentaje: ddporcentaje ? ddporcentaje : 0,
                  data: prop,
                };
              })
              );
            }
            else if (t === "RCS") {
              setMeta(res.data.map((prop, key) => {
                return {
                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                  meta: prop.Meta,
                  metaGeneral: prop.MetaGeneral,
                  totalGeneral: prop.TotalGeneral ? prop.TotalGeneral:0,
                  porcentajeGeneral: prop.porcentajeGeneral ? prop.porcentajeGeneral:0 ,
                  AvanceEstructuraGeneral: prop.ElectoralDiaGeneral ? prop.ElectoralDiaGeneral : 0,
                  AvanceEstructura: prop.ElectoralDia ? prop.ElectoralDia : 0,
                  AvancePro1: prop.AvanceRCP  ? prop.AvanceRCP : 0,
                  AvancePro2: prop.AvanceRCPP ?  prop.AvanceRCPP : 0,
                  AvanceSup1: prop.AvanceRCS ?  prop.AvanceRCS : 0,
                  AvanceSup2: prop.AvanceRCSS ? prop.AvanceRCSS : 0,
                  PorcentajePropietario1: prop.porcentaje1 ? (prop.porcentaje1 <= 100 ? prop.porcentaje1 : 100) : 0,
                  PorcentajePropietario2: prop.porcentaje2 ?( prop.porcentaje2 <= 100 ? prop.porcentaje2: 100): 0,
                  PorcentajeSuplente1: prop.porcentaje3 ? (prop.porcentaje3 <=100 ? prop.porcentaje3 : 100) : 0,
                  PorcentajeSuplente2: prop.porcentaje4 ? (prop.porcentaje4 <= 100  ? prop.porcentaje4:100): 0,
                  data: prop,
                };
              })
              );
            }

          }
        }
      })
      .catch((e) => {
        setLoadingTabla(false);
        notify.show(
          `Error al obtener getMetaEstructurasGeneralFiltro: ${e.message}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
  }

  const LimpiarFiltro = () => {
    setMunicipioSelected({ value: 0, label: 'Todos' })
    setRegionSelected({ value: 0, label: 'Todos' })

    fitroSend("", "clear");
  }

  const changeTap = (e) => {
    setLoadingTabla(true);
    setHorizontalTabs({ ...horizontalTabs, horizontalTabs: e })


    setTabla(e)
    setCol("12")
    setShowMeta(-1)
  }

  const handleMunicipio = (e) => {
    setLoadingTabla(true)
    setLoading(true)
    setMunicipioSelected(e)

    if (e.value > 1) {
      fitroSend(e, "municipio");

    } else {
      setCatMunicipioFilter(catMunicipio);
      fitroSend(e, "municipio");
    }
    setLoading(false);
  }


  const Exportar = () => {
   
    
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      tipo: 'and',
      filtered: Filtro,
      Electoral: tabla,
      Export:true
  };

    var datos = JSON.stringify(data)
    var url = new URL(
        `${URL_GENERAL}ExportEstructuraMunicipalElectoral?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");

  }


  //Estructura

  const showRegion = Responsabilidad === 99 || Responsabilidad === 98 || Responsabilidad === 16

  const showMunicipio = (Responsabilidad === 99 || Responsabilidad === 98 || Responsabilidad === 16 || Responsabilidad === 17 || Responsabilidad === 53 || Responsabilidad === 54 || Responsabilidad === 55)

    const styleHeader = {
      textAlign: "center",
      overflow: "visible",
      fontSize: "11px",
      color: "#363636",
      lineHeight: "normal !important"
    }

    const header = (text) => {
      return (<Label style={styleHeader}>{text}</Label>)
  }
  return (
    <div className="wrapper">
      <Notifications />
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
        <div className="content">

          <div className="content">
            {/* Configuracón Estructura Metas */}

            <Row>
              <Col sm={col}>
                <Card>
                  <CardHeader>
                    <Row >
                      {showRegion &&
                        <Col lg="4" md="4" sm="4">
                          <FormGroup>
                            <Label for="Estado">
                              Región
                              </Label>
                            <Select
                              options={catRegiones}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              defaultValue={catRegiones[0]}
                              value={regionSelected}
                              onChange={(e) => handleRegiones(e)}
                              id="regiones"
                              placeholder="regiones"
                            />
                          </FormGroup>
                        </Col>}
                      {showMunicipio &&
                        <Col lg="4" md="4" sm="4">
                          <FormGroup>
                            <Label for="Municipio">
                              Municipios
                              </Label>
                            <Select
                              options={catMunicipioFilter}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={municipioSelected}
                              onChange={(e) => handleMunicipio(e)}
                              id="municipio"
                              placeholder="Municipio"
                            />
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col sm='4'>
                        <Button name="btnLimpiar" size="sm" type="button" className="success float-left" color='warning' onClick={() => { LimpiarFiltro() }}>Limpiar</Button>
                      </Col>
                      {(menu.Exportar === 1 && !loadingTabla) ? (
                        <Col sm='8'>
                          <Button name="btnExportar" size='sm' type="button" className='primary float-right' color='primary' onClick={() => Exportar()}>
                            <span className="btn-label">
                              <i className="fa fa-file-excel-o fa-lg" />
                            </span>
                            Exportar
							</Button>
                        </Col>
                      ) : <></>}
                    </Row>

                  </CardHeader>
                  <CardBody>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav id="tabs" role="tablist" tabs>
                          <NavItem>
                            <NavLink
                              aria-expanded={horizontalTabs.horizontalTabs === "RCS"}
                              data-toggle="tab"
                              role="tab"
                              className={horizontalTabs.horizontalTabs === "RCS"
                                ? "active"
                                : ""
                              }
                              onClick={() => { changeTap("RCS") }}
                            >
                              RCs
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-expanded={horizontalTabs.horizontalTabs === "RGS"
                              }
                              data-toggle="tab"
                              //href="#pablo"
                              role="tab"
                              className={horizontalTabs.horizontalTabs === "RGS"
                                ? "active"
                                : ""
                              }
                              onClick={() => changeTap("RGS")}
                            >
                              RGs
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-expanded={horizontalTabs.horizontalTabs === "Abogados"}
                              data-toggle="tab"
                              //href="#pablo"
                              role="tab"
                              className={horizontalTabs.horizontalTabs === "Abogados"
                                ? "active"
                                : ""
                              }
                              onClick={() =>
                                changeTap("Abogados")}
                            >
                              Abogados
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-expanded={horizontalTabs.horizontalTabs === "Observador"}
                              data-toggle="tab"
                              //href="#pablo"
                              role="tab"
                              className={horizontalTabs.horizontalTabs === "Observador"
                                ? "active"
                                : ""
                              }
                              onClick={() =>
                                changeTap("Observador")}
                            >
                              Observadores
                        </NavLink>
                          </NavItem>
                          <NavItem>
                                <NavLink
                                  aria-expanded={horizontalTabs.horizontalTabs === "PrimeroFila"}
                                  data-toggle="tab"
                                  //href="#pablo"
                                  role="tab"
                                  className={horizontalTabs.horizontalTabs === "PrimeroFila"
                                    ? "active"
                                    : ""
                                  }
                                  onClick={() =>
                                    changeTap("PrimeroFila")}
                                >
                                  Primero En Fila
                       </NavLink>
                              </NavItem>
                          <NavItem>
                            <NavLink
                              aria-expanded={horizontalTabs.horizontalTabs === "CasaAmiga"}
                              data-toggle="tab"
                              //href="#pablo"
                              role="tab"
                              className={horizontalTabs.horizontalTabs === "CasaAmiga"
                                ? "active"
                                : ""
                              }
                              onClick={() =>
                                changeTap("CasaAmiga")}
                            >
                              Casa Azul
                    </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                    <TabContent
                      className="text-center"
                      id="my-tab-content"
                      activeTab={horizontalTabs.horizontalTabs}
                    >
                      <TabPane tabId="RCS" role="tabpanel">
                      {showMeta=== 1 ?
                        <ReactTable
                          size='sm'
                          data={meta}
                          noDataText="Sin información"
                          manual
                          columns={[
                            {
                              Header: header( "Municipio"),
                              minWidth: 40,
                              id: "Municipio",
                              accessor: (d) => d.Municipio,
                              filterAll: false,
                            },
                            {
                              Header: header("Meta General"),
                              accessor: (d) => d.metaGeneral,
                              minWidth: 30,
                              id: "MetaGeneral",
                              filterable: false,
                            },
                            {
                              Header: header("A.Esperado General"),
                              accessor: (d) => d.AvanceEstructuraGeneral,
                              minWidth: 40,
                              id: "ElectoralDiaGeneral",
                              filterable: false,
                            },
                            {
                              Header: header("A.General"),
                              accessor: (d) => d.totalGeneral,
                              minWidth: 40,
                              id: "TotalGeneral",
                              filterable: false,
                            },
                            {
                              Header: header("% A.General"),
                              accessor: (d) => d.porcentajeGeneral,
                              minWidth: 40,
                              Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                              id: "porcentajeGeneral",
                              filterable: false,
                            },
                            {
                              Header:header("Meta"),
                              accessor: (d) => d.meta,
                              minWidth: 40,
                              id: "municipio.Meta",
                              filterable: false,
                            },
                            {
                              Header: header("A. Esperado"),
                              accessor: (d) => d.AvanceEstructura,
                              minWidth: 40,
                              id: "AvanceEstructura",
                              filterable: false,
                            },
                            {
                              Header: header("A. P1"),
                              accessor: (d) => d.AvancePro1,
                              minWidth: 40,
                              id: "AvancePro1",
                              filterable: false,
                            },
                            {
                              Header: header("A. P2"),
                              accessor: (d) => d.AvancePro2,
                              minWidth: 40,
                              id: "AvancePro2",
                              filterable: false,
                            },
                            {
                              Header: header("A. S1"),
                              accessor: (d) => d.AvanceSup1,
                              minWidth: 40,
                              id: "AvanceSub1",
                              filterable: false,
                            },
                            {
                              Header: header("A. S2"),
                              accessor: (d) => d.AvanceSup2,
                              minWidth: 40,
                              id: "AvanceSub2",
                              filterable: false,
                            },
                            {
                              Header: header("% A.P1"),
                              accessor: (d) => d.PorcentajePropietario1,
                              Cell: props => <div>{props.value + " %"}</div>,
                              minWidth: 40,
                              id: "porcentajePro1",
                              filterable: false,
                            },
                            {
                              Header: header("% A. P2"),
                              accessor: (d) => d.PorcentajePropietario2,
                              Cell: props => <div>{props.value + " %"}</div>,
                              minWidth: 40,
                              id: "porcentajePro2",
                              filterable: false,
                            },
                            {
                              Header: header("% A. S1"),
                              accessor: (d) => d.PorcentajeSuplente1,
                              Cell: props => <div>{props.value + " %"}</div>,
                              minWidth: 40,
                              id: "porcentajeSu1",
                              filterable: false,
                            },
                            {
                              Header: header("% A. S2"),
                              accessor: (d) => d.PorcentajeSuplente2,
                              Cell: props => <div>{props.value + " %"}</div>,
                              minWidth: 40,
                              id: "porcentajeSu2",
                              filterable: false,
                            },
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loadingTabla}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.floor(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                            setLoadingTabla(true);
                            let _menuStorage = getDataMenu(72, token.menus);
                            const data = {
                              page: stateComp.page,
                              pageSize: stateComp.pageSize,
                              sorted: [{ id: "municipio.Municipio", desc: false }],
                              tipo: 'and',
                              filtered: Filtro ? Filtro : [],
                              Electoral: "RCS"
                            };
                            EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                              .then(function (res) {
                                return res;
                              })
                              .then((res) => {
                                if (res.success) {
                                  if (res.results) {
                                    setTotal(res.total);
                                    setMeta(
                                      res.data.map((prop, key) => {
                                        return {
                                          Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                          meta: prop.Meta,
                                          metaGeneral: prop.MetaGeneral,
                                          totalGeneral: prop.TotalGeneral ? prop.TotalGeneral:0,
                                          porcentajeGeneral: prop.porcentajeGeneral ? prop.porcentajeGeneral:0 ,
                                          AvanceEstructuraGeneral: prop.ElectoralDiaGeneral ? prop.ElectoralDiaGeneral : 0,
                                          AvanceEstructura: prop.ElectoralDia ? prop.ElectoralDia : 0,
                                          AvancePro1: prop.AvanceRCP  ? prop.AvanceRCP : 0,
                                          AvancePro2: prop.AvanceRCPP ?  prop.AvanceRCPP : 0,
                                          AvanceSup1: prop.AvanceRCS ?  prop.AvanceRCS : 0,
                                          AvanceSup2: prop.AvanceRCSS ? prop.AvanceRCSS : 0,
                                          PorcentajePropietario1: prop.porcentaje1 ? (prop.porcentaje1 <= 100 ? prop.porcentaje1 : 100) : 0,
                                          PorcentajePropietario2: prop.porcentaje2 ?( prop.porcentaje2 <= 100 ? prop.porcentaje2: 100): 0,
                                          PorcentajeSuplente1: prop.porcentaje3 ? (prop.porcentaje3 <=100 ? prop.porcentaje3 : 100) : 0,
                                          PorcentajeSuplente2: prop.porcentaje4 ? (prop.porcentaje4 <= 100  ? prop.porcentaje4:100): 0,
                                          data: prop,
                                        };
                                      })
                                    );
                                    setLoadingTabla(false);
                                  }
                                }

                              })
                              .catch((e) => {
                                setLoading(false);
                                notify.show(
                                  `Error al obtener tabla metas estructura region: ${e.message}`,
                                  "custom",
                                  5000,
                                  {
                                    background: "#0857B6",
                                    fontSize: 14,
                                    text: "#FFFFFF",
                                  }
                                );
                              });
                          }}
                        />
                        :<></>}
                      </TabPane>
                      <TabPane tabId="RGS" role="tabpanel">
                        {showMeta=== 2 ?
                          <ReactTable
                            size='sm'
                            data={meta}
                            noDataText="Sin información"
                            manual
                            columns={[
                              {
                                Header: "Municipio",
                                minWidth: 70,
                                id: "Municipio",
                                accessor: (d) => d.Municipio,
                                filterAll: false,
                              },
                              {
                                Header: "Meta",
                                accessor: (d) => d.meta,
                                minWidth: 40,
                                id: "meta",
                                filterable: false,
                              },
                              {
                                Header: "Avance Esperado",
                                accessor: (d) => d.AvanceEstructura,
                                minWidth: 40,
                                id: "AvanceEstructura",
                                filterable: false,
                              },
                              {
                                Header: "Avance RGs",
                                accessor: (d) => d.AvanceRGs,
                                minWidth: 40,
                                id: "AvanceRGS",
                                filterable: false,
                              },
                              {
                                Header: "% Avance",
                                accessor: (d) => d.porcentaje,
                                Cell: props => <div>{props.value + " %"}</div>,
                                minWidth: 40,
                                id: "porcentaje",
                                filterable: false,
                              },

                            ]}
                            className="-striped -highlight primary-pagination"
                            loading={loadingTabla}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            pages={Math.floor(total / 10)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={(stateComp, instance) => {
                              setLoadingTabla(true);
                              let _menuStorage = getDataMenu(72, token.menus);
                              const data = {
                                page: stateComp.page,
                                pageSize: stateComp.pageSize,
                                sorted: [{ id: "municipio.Municipio", desc: false }],
                                tipo: 'and',
                                filtered: Filtro ? Filtro : [],
                                Electoral: "RGS"
                              };
                              EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                .then(function (res) {
                                  return res;
                                })
                                .then((res) => {
                                  setLoadingTabla(false);
                                  setTotal(res.total);
                                  setMeta(
                                    res.data.map((prop, key) => {
                                      var porcentaje = parseFloat(prop.Porcentaje)
                                      var ddporcentaje = porcentaje.toFixed(2)
                                      return {
                                        id: prop.id,
                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                        meta: prop.Meta,
                                        AvanceEstructura: prop.ElectoralDia ? prop.ElectoralDia : 0,
                                        AvanceRGs: prop.Total ? prop.Total : 0,
                                        porcentaje: ddporcentaje ? ddporcentaje : 0,
                                        data: prop,
                                      };
                                    })
                                  );

                                })
                                .catch((e) => {
                                  setLoadingTabla(false);
                                  notify.show(
                                    `Error al obtener tabla metas: ${e.message}`,
                                    "custom",
                                    5000,
                                    {
                                      background: "#0857B6",
                                      fontSize: 14,
                                      text: "#FFFFFF",
                                    }
                                  );
                                });
                            }}
                          /> : <></>
                        }

                      </TabPane>
                      <TabPane tabId="Abogados" role="tabpanel">
                        {showMeta=== 3 ?
                          <ReactTable
                            size='sm'
                            data={meta}
                            noDataText="Sin información"
                            manual
                            columns={[
                              {
                                Header: "Municipio",
                                minWidth: 70,
                                id: "Municipio",
                                accessor: (d) => d.Municipio,
                                filterAll: false,
                              },
                              {
                                Header: "Meta",
                                accessor: (d) => d.meta,
                                minWidth: 40,
                                id: "meta",
                                filterable: false,
                              },
                              {
                                Header: "Avance Esperado",
                                accessor: (d) => d.AvanceEstructura,
                                minWidth: 40,
                                id: "AvanceEstructura",
                                filterable: false,
                              },
                              {
                                Header: "Avance Abogados",
                                accessor: (d) => d.AvanceRGs,
                                minWidth: 40,
                                id: "AvanceRGS",
                                filterable: false,
                              },
                              {
                                Header: "% Avance",
                                accessor: (d) => d.porcentaje,
                                Cell: props => <div>{props.value + " %"}</div>,
                                minWidth: 40,
                                id: "porcentaje",
                                filterable: false,
                              },

                            ]}
                            className="-striped -highlight primary-pagination"
                            loading={loadingTabla}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            pages={Math.floor(total / 10)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={(stateComp, instance) => {
                              setLoadingTabla(true);
                              let _menuStorage = getDataMenu(72, token.menus);
                              const data = {
                                page: stateComp.page,
                                pageSize: stateComp.pageSize,
                                sorted: [{ id: "municipio.Municipio", desc: false }],
                                tipo: 'and',
                                filtered: Filtro ? Filtro : [],
                                Electoral: "Abogados"
                              };
                              EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                .then(function (res) {
                                  return res;
                                })
                                .then((res) => {
                                  setLoadingTabla(false);
                                  setTotal(res.total);
                                  setMeta(
                                    res.data.map((prop, key) => {
                                      var porcentaje = parseFloat(prop.Porcentaje)
                                      var ddporcentaje = porcentaje.toFixed(2)
                                      return {
                                        id: prop.id,
                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                        meta: prop.Meta,
                                        AvanceEstructura: prop.ElectoralDia ? prop.ElectoralDia : 0,
                                        AvanceRGs: prop.Total ? prop.Total : 0,
                                        porcentaje: ddporcentaje ? ddporcentaje : 0,
                                        data: prop,
                                      };
                                    })
                                  );

                                })
                                .catch((e) => {
                                  setLoadingTabla(false);
                                  notify.show(
                                    `Error al obtener tabla metas: ${e.message}`,
                                    "custom",
                                    5000,
                                    {
                                      background: "#0857B6",
                                      fontSize: 14,
                                      text: "#FFFFFF",
                                    }
                                  );
                                });
                            }}
                          /> : <></>
                        }

                      </TabPane>
                      <TabPane tabId="Observador" role="tabpanel">
                        {showMeta=== 4 ?
                          <ReactTable
                            size='sm'
                            data={meta}
                            noDataText="Sin información"
                            manual
                            columns={[
                              {
                                Header: "Municipio",
                                minWidth: 70,
                                id: "Municipio",
                                accessor: (d) => d.Municipio,
                                filterAll: false,
                              },
                              {
                                Header: "Meta",
                                accessor: (d) => d.meta,
                                minWidth: 40,
                                id: "meta",
                                filterable: false,
                              },
                              {
                                Header: "Avance Esperado",
                                accessor: (d) => d.AvanceEstructura,
                                minWidth: 40,
                                id: "AvanceEstructura",
                                filterable: false,
                              },
                              {
                                Header: "Avance Observador",
                                accessor: (d) => d.AvanceRGs,
                                minWidth: 40,
                                id: "AvanceRGS",
                                filterable: false,
                              },
                              {
                                Header: "% Avance",
                                accessor: (d) => d.porcentaje,
                                Cell: props => <div>{props.value + " %"}</div>,
                                minWidth: 40,
                                id: "porcentaje",
                                filterable: false,
                              },

                            ]}
                            className="-striped -highlight primary-pagination"
                            loading={loadingTabla}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            pages={Math.floor(total / 10)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={(stateComp, instance) => {
                              setLoadingTabla(true);
                              let _menuStorage = getDataMenu(72, token.menus);
                              const data = {
                                page: stateComp.page,
                                pageSize: stateComp.pageSize,
                                sorted: [{ id: "municipio.Municipio", desc: false }],
                                tipo: 'and',
                                filtered: Filtro ? Filtro : [],
                                Electoral: "Observador"
                              };
                              EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                .then(function (res) {
                                  return res;
                                })
                                .then((res) => {
                                  setLoadingTabla(false);
                                  setTotal(res.total);
                                  setMeta(
                                    res.data.map((prop, key) => {
                                      var porcentaje = parseFloat(prop.Porcentaje)
                                      var ddporcentaje = porcentaje.toFixed(2)
                                      return {
                                        id: prop.id,
                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                        meta: prop.Meta,
                                        AvanceEstructura: prop.ElectoralDia ? prop.ElectoralDia : 0,
                                        AvanceRGs: prop.Total ? prop.Total : 0,
                                        porcentaje: ddporcentaje ? ddporcentaje : 0,
                                        data: prop,
                                      };
                                    })
                                  );

                                })
                                .catch((e) => {
                                  setLoadingTabla(false);
                                  notify.show(
                                    `Error al obtener tabla metas: ${e.message}`,
                                    "custom",
                                    5000,
                                    {
                                      background: "#0857B6",
                                      fontSize: 14,
                                      text: "#FFFFFF",
                                    }
                                  );
                                });
                            }}
                          /> : <></>
                        }

                      </TabPane>
                      <TabPane tabId="PrimeroFila" role="tabpanel">
                            {showMeta === 6 ?
                              <ReactTable
                                size='sm'
                                data={meta}
                                noDataText="Sin información"
                                manual
                                columns={[
                                  {
                                    Header: "Municipio",
                                    minWidth: 70,
                                    id: "Municipio",
                                    accessor: (d) => d.Municipio,
                                    filterAll: false,
                                  },
                                  {
                                    Header: "Meta",
                                    accessor: (d) => d.meta,
                                    minWidth: 40,
                                    id: "meta",
                                    filterable: false,
                                  },
                                  {
                                    Header: "A.Esperado",
                                    accessor: (d) => d.AvanceEstructura,
                                    minWidth: 40,
                                    id: "AvanceEstructura",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance Primero en Fila",
                                    accessor: (d) => d.AvanceRGs,
                                    minWidth: 40,
                                    id: "AvanceRGS",
                                    filterable: false,
                                  },
                                  {
                                    Header: "% Avance",
                                    accessor: (d) => d.porcentaje,
                                    Cell: props => <div>{props.value + " %"}</div>,
                                    minWidth: 40,
                                    id: "porcentaje",
                                    filterable: false,
                                  },

                                ]}
                                className="-striped -highlight primary-pagination"
                                loading={loadingTabla}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={10}
                                total={total}
                                pages={Math.floor(total / 10)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                PaginationComponent={Pagination}
                                onFetchData={(stateComp, instance) => {
                                  setLoadingTabla(true);
                                  let _menuStorage = getDataMenu(72, token.menus);
                                  const data = {
                                    page: stateComp.page,
                                    pageSize: stateComp.pageSize,
                                    sorted: [{ id: "municipio.Municipio", desc: false }],
                                    tipo: 'and',
                                    filtered: Filtro ? Filtro : [],
                                    Electoral: "PrimeroFila"
                                  };
                                  EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                    .then(function (res) {
                                      return res;
                                    })
                                    .then((res) => {
                                      setLoadingTabla(false);
                                      setTotal(res.total);
                                      setMeta(
                                        res.data.map((prop, key) => {
                                          var porcentaje = parseFloat(prop.Porcentaje)
                                          var ddporcentaje = porcentaje.toFixed(2)
                                          return {
                                            id: prop.id,
                                            Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                            meta: prop.Meta,
                                            AvanceEstructura: prop.Meta ? prop.Meta : 0,
                                            AvanceRGs: prop.Total > 0 ? prop.Total : 0,
                                            porcentaje: ddporcentaje ? ddporcentaje : 0,
                                            data: prop,
                                          };
                                        })
                                      );

                                    })
                                    .catch((e) => {
                                      setLoadingTabla(false);
                                      notify.show(
                                        `Error al obtener tabla metas: ${e.message}`,
                                        "custom",
                                        5000,
                                        {
                                          background: "#0857B6",
                                          fontSize: 14,
                                          text: "#FFFFFF",
                                        }
                                      );
                                    });
                                }}
                              /> : <></>
                            }

                          </TabPane>
 
                      <TabPane tabId="CasaAmiga" role="tabpanel">
                        {showMeta=== 5?
                          <ReactTable
                            size='sm'
                            data={meta}
                            noDataText="Sin información"
                            manual
                            columns={[
                              {
                                Header: "Municipio",
                                minWidth: 70,
                                id: "Municipio",
                                accessor: (d) => d.Municipio,
                                filterAll: false,
                              },
                              {
                                Header: "Meta",
                                accessor: (d) => d.meta,
                                minWidth: 40,
                                id: "meta",
                                filterable: false,
                              },
                              {
                                Header: "Avance Esperado",
                                accessor: (d) => d.AvanceEstructura,
                                minWidth: 40,
                                id: "AvanceEstructura",
                                filterable: false,
                              },
                              {
                                Header: "Avance Casa Azul",
                                accessor: (d) => d.AvanceRGs,
                                minWidth: 40,
                                id: "AvanceRGS",
                                filterable: false,
                              },
                              {
                                Header: "% Avance",
                                accessor: (d) => d.porcentaje,
                                Cell: props => <div>{props.value + " %"}</div>,
                                minWidth: 40,
                                id: "porcentaje",
                                filterable: false,
                              },

                            ]}
                            className="-striped -highlight primary-pagination"
                            loading={loadingTabla}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            pages={Math.floor(total / 10)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={(stateComp, instance) => {
                              setLoadingTabla(true);
                              let _menuStorage = getDataMenu(72, token.menus);
                              const data = {
                                page: stateComp.page,
                                pageSize: stateComp.pageSize,
                                sorted: [{ id: "municipio.Municipio", desc: false }],
                                tipo: 'and',
                                filtered: Filtro ? Filtro : [],
                                Electoral: "CasaAmiga"
                              };
                              EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                .then(function (res) {
                                  return res;
                                })
                                .then((res) => {
                                  setLoadingTabla(false);
                                  setTotal(res.total);
                                  setMeta(
                                    res.data.map((prop, key) => {
                                      var porcentaje = parseFloat(prop.Porcentaje)
                                      var ddporcentaje = porcentaje.toFixed(2)
                                      return {
                                        id: prop.id,
                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                        meta: prop.Meta,
                                        AvanceEstructura: prop.ElectoralDia ? prop.ElectoralDia : 0,
                                        AvanceRGs: prop.Total ? prop.Total : 0,
                                        porcentaje: ddporcentaje ? ddporcentaje : 0,
                                        data: prop,
                                      };
                                    })
                                  );

                                })
                                .catch((e) => {
                                  setLoadingTabla(false);
                                  notify.show(
                                    `Error al obtener tabla metas: ${e.message}`,
                                    "custom",
                                    5000,
                                    {
                                      background: "#0857B6",
                                      fontSize: 14,
                                      text: "#FFFFFF",
                                    }
                                  );
                                });
                            }}
                          /> : <></>
                        }

                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Footer fluid />
        {loading && (
          <CircleSpinner
            className="text-center"
            size={30}
            color="#686769"
            loading={loading}
          />
        )}
      </div>
    </div>
  );

}
export default ElectoralMunicipal;
