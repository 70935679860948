import React, { useState, useEffect } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    InputGroup,
    InputGroupAddon,
    Badge,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from "reactstrap";
import { RotateSpinner } from 'react-spinners-kit';
import Notifications, { notify } from 'react-notify-toast';
import { getDataMenu } from 'data/menus';
import { getVars } from "GlobalStorage";
import EjecutarApi from "data/getPOST";

const IncidenciaEdit = ({ props, onHide }) => {

    const token = getVars("Token");
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [reponsable, setResponsable] = useState([
        {
            Responsabilidad: "RGs",
            Responsable: ""
        },
        {
            Responsabilidad: "RCs",
            Responsable: ""
        },
        {
            Responsabilidad: "RCs",
            Responsable: ""
        }]);

    const [incidencia, setIncidencia] = useState(
        {
            id: (props) ? props : null,
            Seccion: 0,
            AsentadoEnActa: "I",
            ComoSeResolvio: "",
            DescripcionIncidencia: "",
            Estatus: "",
            HoraIncidencia: "",
            HoraSolucion: "",
            Incidencia: "",
            Municipio: "",
            NombreCasilla: "",
            NombreCompletoResponsable: "",
            id: 0,
            idIncidencia: 0,
            idStatus: 0,
            isResuelto: 0,
            rcp1: "",
            rcp2: "",
            rcs1: "",
            rcs2: "",
            updated_at: ""
        });
    const [modificado,setModificado]=useState(false);
    const [seguimiento, setSeguimiento] = useState([{
        "id": null,
        "idIncidencia": 0,
        "Comentarios": "",
        "HoraComentario": "",
        "UserCreate": ""
    },
    {
        "id": null,
        "idIncidencia": 0,
        "Comentarios": "",
        "HoraComentario": "",
        "UserCreate": ""
    }]);

    const [comentario, setComentario] = useState({
        idIncidencia: props,
        Comentarios: ''
    })
    const [isModal, setisModal] = useState(false);
    const toggleModal = () => setisModal(true)
    const [menu, _setmenu] = useState({});
    const [isComentario, setIsComentario] = useState(false);

    useEffect(() => {
        dataIncidencia()

    }, [isComentario])

    useEffect(() => {
        _setmenu(getDataMenu(165, token.menus));
        var d = new Date();

        var horas = d.getHours();
        var minutos = d.getMinutes();
        var segundos = d.getSeconds();

        if (horas < 10) { horas = '0' + horas; }
        if (minutos < 10) { minutos = '0' + minutos; }
        if (segundos < 10) { segundos = '0' + segundos; }

        setIncidencia({
            ...incidencia,
            horaSolucion: `${horas}:${minutos}`,
            minutosSolucion: d.getMinutes(),
            anioSolucion: d.getFullYear(),
            mesSolucion: d.getMonth() + 1,
            segundosSolucion: d.getSeconds(),
            diaSolucion: d.getDate()
        });


        //"2021-05-01 07:30:00",
    }, [])



    const dataIncidencia = () => {
        setLoading(true)
        EjecutarApi({ id: props }, "getIncidencia", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.results) {
                    if (items.data) {
                        setSeguimiento(items.data.Seguimiento)
                        if (items.data.isResuelto === "SI") {
                            setIsDisabled(true)
                            setIncidencia(items.data)
                        }
                        else {
                            var d = new Date();
                            var horas = d.getHours();
                            var minutos = d.getMinutes();
                            var segundos = d.getSeconds();

                            if (horas < 10) { horas = '0' + horas; }
                            if (minutos < 10) { minutos = '0' + minutos; }
                            if (segundos < 10) { segundos = '0' + segundos; }

                            setIncidencia({
                                ...items.data,
                                horaSolucion: `${horas}:${minutos}`,
                                minutosSolucion: d.getMinutes(),
                                anioSolucion: d.getFullYear(),
                                mesSolucion: d.getMonth() + 1,
                                segundosSolucion: d.getSeconds(),
                                diaSolucion: d.getDate()
                            });

                        }
                    }
                } else {
                    notify.show("No Info: getIncidencia", "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show("Error: getIncidencia", e.message, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });
    }
    const save = () => {

        if (incidencia.Seccion === 0) {
            notify.show("Hace Falta la Sección", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });

            return true;

        }
        if (!incidencia.NombreCasilla) {
            notify.show("Hace Falta la Casilla", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
        }
        if (!incidencia.Incidencia) {
            notify.show("Hace Falta Indicar la Incidencia", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
            return true;
        }
        if (incidencia.DescripcionIncidencia.length < 5) {
            notify.show("Realice una descripción de la incidencia", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
            return true;
        }
        if (incidencia.isResuelto === "SI") {
            if (incidencia.ComoSeResolvio.length < 5) {
                notify.show("Hace Falta Indicar la Solución", "warning", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
                return true;
            }
            if ((incidencia.horaSolucion.length < 5 && incidencia.horaSolucion.length > 0) || incidencia.horaSolucion === '') {
                notify.show("Hace falta la hora de solución", "warning", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
                return true;
            }
        }
        if(modificado) {  
            let datos = {
            id: (props) ? props : null,
            isResuelto: (incidencia.isResuelto === "SI") ? 1 : 0,
            ComoSeResolvio: (incidencia.ComoSeResolvio) ? incidencia.ComoSeResolvio : '',
            HoraSolucion: `${incidencia.anioSolucion}-${incidencia.mesSolucion}-${incidencia.diaSolucion} ${incidencia.horaSolucion}:${incidencia.segundosSolucion}`,
            idStatus: incidencia.status
        }
        EjecutarApi(datos, "updIncidenciaCasilla", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.results) {
                    notify.show(`${items.message}`, "success", 5000, {
                        background: "#0857B6",
                        fontSize: 24,
                        text: "#FFFFFF",
                    });
                    setIsComentario(!isComentario)
                } else {
                    notify.show(`${items.message}`, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show("Error: updIncidenciaCasilla", e.message, "danger", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });}
            else{
                notify.show(`No se detectaron cambios en la incidencia`, 'warning', 5000);
            }


    }

    const send = () => {
        setIsDisabled(true)
        if (comentario.Comentarios.length < 5) {
            setIsDisabled(false)
            notify.show("Hace Falta un comentario", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
            return true;
        }

        EjecutarApi(comentario, "setSeguimiento", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.results) {
                    notify.show(`${items.mesagge}`, "success", 5000, {
                        background: "#0857B6",
                        fontSize: 24,
                        text: "#FFFFFF",
                    });
                    setComentario({ ...comentario, Comentarios: "" })
                    setSeguimiento(items.data)
                    setIsDisabled(false)
                    setIsComentario(!isComentario)

                } else {
                    notify.show(`${items.mesagge}`, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                    setIsDisabled(false)
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setIsDisabled(false)
                notify.show(`Error: setSeguimiento ${e.message}`, "danger", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });
    }

    return (
        <>
            <div className="content">
                <Row>
                    <Notifications />
                    <Col md="4">
                        <Card>
                        <RotateSpinner
                        color={'#1261ff'}
                        size={30}
                        loading={loading}
                    />
                            <CardHeader style={{ backgroundColor: "#005CB9" }}>
                                <CardTitle >
                                    <h5 className="text-white text-center margin-none strong"><i className="fa fa-address-card-o"></i> <Label className="text-white text-medium margin-none strong">Responsables Casilla</Label></h5>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="container-center" style={{ height: '135px', 'overflow': 'auto' }}>
                                    <ul className="list-unstyled team-members list-center text-center">
                                        <li>
                                            <Row>
                                                <Col md="2" xs="2">
                                                    <br />
                                                    <strong>
                                                        <spam style={{ fontWeight: "italic", fontSize: "8pt", color: "#000000" }}>
                                                            RGs:
                                                    </spam>
                                                    </strong>
                                                </Col>
                                                <Col md="10" xs="10">
                                                    <br />
                                                    {incidencia.NombreCompletoResponsable}
                                                </Col>
                                            </Row>
                                        </li>
                                        <li>
                                            <Row>
                                                <Col md="2" xs="2">
                                                    <br />
                                                    <strong>
                                                        <spam style={{ fontWeight: "italic", fontSize: "8pt", color: "#000000" }}>
                                                            RCP1:
                                                    </spam>
                                                    </strong>
                                                </Col>
                                                <Col md="10" xs="10">
                                                    <br />
                                                    {incidencia.rcp1}
                                                </Col>
                                            </Row>
                                        </li>
                                        <li>
                                            <Row>
                                                <Col md="2" xs="2">
                                                    <br />
                                                    <strong>
                                                        <spam style={{ fontWeight: "italic", fontSize: "8pt", color: "#000000" }}>
                                                            RCP2:
                                                    </spam>
                                                    </strong>
                                                </Col>
                                                <Col md="10" xs="10">
                                                    <br />
                                                    {incidencia.rcp2}
                                                </Col>
                                            </Row>
                                        </li>
                                        <li>
                                            <Row>
                                                <Col md="2" xs="2">
                                                    <br />
                                                    <strong>
                                                        <spam style={{ fontWeight: "italic", fontSize: "8pt", color: "#000000" }}>
                                                            RCS1:
                                                    </spam>
                                                    </strong>
                                                </Col>
                                                <Col md="10" xs="10">
                                                    <br />
                                                    {incidencia.rcs1}
                                                </Col>
                                            </Row>
                                        </li>
                                        <li>
                                            <Row>
                                                <Col md="2" xs="2">
                                                    <br />
                                                    <strong>
                                                        <spam style={{ fontWeight: "italic", fontSize: "8pt", color: "#000000" }}>
                                                            RCS2:
                                                    </spam>
                                                    </strong>
                                                </Col>
                                                <Col md="10" xs="10">
                                                    <br />
                                                    {incidencia.rcs2}
                                                </Col>
                                            </Row>
                                        </li>
                                    </ul>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="8">
                        <Card className="">
                            <CardHeader style={{ backgroundColor: "#F0C823" }}>
                                <h5 className="text-white text-center margin-none strong"><i className="fa fa-info-circle"></i> <Label className="text-white text-medium margin-none strong">Información Casilla</Label></h5>
                            </CardHeader>
                            <CardBody>
                                <div className="button-container">
                                    <Row>
                                        <Col className="ml-auto" lg="3" md="3" xs="3" key="HSI">
                                            <br />
                                            <h6 className="text-center">
                                                <b> {incidencia.HoraIncidencia}</b><br />
                                                <hr />
                                                <small>Hora Incidencia</small>
                                            </h6>
                                        </Col>
                                        <Col className="ml-auto mr-auto" lg="3" md="3" xs="3" key="ES">
                                            <br />
                                            <h6 className="text-center">
                                                <b>{incidencia.Estatus}</b><br />
                                                <hr />
                                                <small>Estatus</small>
                                            </h6>
                                        </Col>
                                        <Col className="ml-auto mr-auto" lg="3" md="3" xs="3" key="RS">
                                            <br />
                                            <h6 className="text-center">
                                                <b>{incidencia.isResuelto}</b><br />
                                                <hr />
                                                <small>Resuelto</small>
                                            </h6>
                                        </Col>
                                        {incidencia.isResuelto === "SI" ?
                                            <Col className="ml-auto" lg="2" md="2" xs="2" key="HS">
                                                <br />
                                                <h6 className="text-center">
                                                    <b>{incidencia.HoraSolucion}</b><br />
                                                    <hr />
                                                    <small>Hora Solución</small>
                                                </h6>
                                            </Col> :
                                            <Col className="ml-auto" lg="2" md="2" xs="2" key="HyyS"></Col>
                                        }
                                    </Row>
                                </div>
                            </CardBody>
                            <CardFooter>
                                <small className="text-muted text-center"> <i className="fa fa-clock-o"></i>  Actualizado: {incidencia.updated_at}</small>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card key="edicion">
                            <CardHeader>
                                <Button color="success" size="sm" onClick={() => onHide()} >
                                    <span className="btn-label">
                                    </span>
                                 Regresar
                            </Button>
                                <h5 className="title text-center">Edición de Incidencia</h5>
                            </CardHeader>
                            {menu.Editar ?
                            <>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup sm="8">
                                                <strong>
                                                    <spam style={{ fontWeight: "italic", fontSize: "8pt", color: "#000000" }}>
                                                    Reporto: {incidencia.NombreCompletoReporto}
                                                    </spam>
                                                </strong>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <FormGroup className="col-md-4 text-warning" >
                                            <Label for="seccion"><span className="text-danger">*</span>Sección:</Label>
                                            <InputGroup>
                                                <Input id="seccion" maxLength='4' value={incidencia.Seccion} disabled
                                                ></Input>
                                                <InputGroupAddon addonType="append">
                                                    <Button id="btnSeccion" color='warning' style={{ margin: '0px 1px' }} disabled
                                                    >
                                                        <i className="fa fa-search fa-lg"></i></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <Col md="4">
                                            <FormGroup sm="4">
                                                <Label for="casilla"><span className="text-danger">*</span>Casilla:</Label>
                                                <Input className="react-select primary"
                                                    id="casilla"
                                                    value={incidencia.NombreCasilla}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" md="12" sm="12">
                                            <FormGroup>
                                                <Label><span className="text-danger">*</span>Incidencia</Label>
                                                <Input htmlFor="incidencia"
                                                    style={{ height: "60pt" }}
                                                    className="textarea"
                                                    type="textarea"
                                                    cols="80"
                                                    rows="2"
                                                    value={incidencia.Incidencia}
                                                    disabled
                                                >
                                                </Input>

                                            </FormGroup>
                                        </Col>
                                        <Col lg="8" md="8" sm="8">
                                            <FormGroup className="col-md-12">
                                                <label > <span className="text-danger">*</span> Fue Asentado en el Acta de la Jornada Electoral</label>

                                            </FormGroup>
                                            <div className="d-flex" >
                                                <FormGroup check className="col-md-4 text-center">
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={incidencia.AsentadoEnActa === "SI"}
                                                            value={incidencia.AsentadoEnActa}
                                                            disabled
                                                        />{" "} Si fue Asentado
                                                <span className="form-check-sign">
                                                            <span className="check"></span>
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check className="col-md-4 text-center" >
                                                    <Label check /* style={{"margin-rigth": "-140px"}} */>
                                                        <Input
                                                            color="info"
                                                            type="checkbox"
                                                            checked={incidencia.AsentadoEnActa === "NO"}
                                                            value={incidencia.AsentadoEnActa}
                                                            disabled
                                                        />{" "}No fue Asentado
                                                <span className="form-check-sign ">
                                                            <span className="check"></span>
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label> <span className="text-danger">*</span>Descripción Incidencia</label>
                                                <Input
                                                    style={{ height: "45pt" }}
                                                    className="textarea"
                                                    type="textarea"
                                                    cols="80"
                                                    rows="2"
                                                    value={incidencia.DescripcionIncidencia}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm="6" md="6" lg="6" className=' text-center'>
                                            <Label className="text-muted"> <span className="text-danger">*</span>Hora Incidencia (Formato: 24 hrs) </Label>
                                            <InputGroup>
                                                <Input id='btn' maxLength='5' value={incidencia.HoraIncidencia} disabled
                                                    type='text' max='23' min='0' />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        style={{ width: "60pt", margin: '0px 1px', zIndex: 0 }}
                                                        color="primary" disabled
                                                    ><i className="fa fa-clock-o" />hrs</Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col lg="8" md="8" sm="8">

                                            <FormGroup className="col-md-6 ">
                                                <label  > <span className="text-danger">*</span>¿Fue Resuelto el Incidente?</label>

                                            </FormGroup>
                                            <div className="d-flex" >
                                                <FormGroup check className="col-md-3 text-center">
                                                    <Label check>
                                                        <Input
                                                            className="custom-control-input"
                                                            type="checkbox"
                                                            checked={incidencia.isResuelto === "SI"}
                                                            value={incidencia.isResuelto}
                                                            disabled={isDisabled}
                                                            onChange={() => {
                                                                setModificado(true);
                                                                setIncidencia({
                                                                    ...incidencia,
                                                                    isResuelto: "SI",
                                                                    status: 3
                                                                })
                                                            }
                                                            }
                                                        />{" "} Si
                                                <span className="form-check-sign">
                                                            <span className="check"></span>
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check className="col-md-3 text-center" >
                                                    <Label check /* style={{"margin-rigth": "-140px"}} */>
                                                        <Input
                                                            color="info"
                                                            type="checkbox"
                                                            checked={incidencia.isResuelto === "NO"}
                                                            value={incidencia.isResuelto}
                                                            disabled={isDisabled}
                                                            onChange={() => {
                                                                setModificado(false);
                                                                setIncidencia({
                                                                    ...incidencia,
                                                                    isResuelto: "NO",
                                                                })
                                                            }
                                                            }
                                                        />{" "}No
                                                <span className="form-check-sign ">
                                                            <span className="check"></span>
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {incidencia.isResuelto === "SI" ?
                                            <>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label> <span className="text-danger">*</span>Descripción de Como se Soluciono el Incidente</label>
                                                        <Input
                                                            style={{ height: "45pt" }}
                                                            onChange={(e) => { setIncidencia({ ...incidencia, ComoSeResolvio: e.target.value }) }}
                                                            className="textarea"
                                                            type="textarea"
                                                            cols="80"
                                                            rows="2"
                                                            disabled={isDisabled}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {!isDisabled ?
                                                    <Col sm="6" md="6" lg="6">
                                                        <Label className="text-muted"> <span className="text-danger">*</span>Hora Solución (Formato: 24 hrs) </Label>
                                                        <Input id='btn' className='text-center' value={incidencia.horaSolucion} style={{ width: "100pt" }}
                                                            onChange={(e) => setIncidencia({ ...incidencia, horaSolucion: e.target.value })}/*  */
                                                            type="time" />
                                                    </Col>
                                                    :
                                                    <Col sm="6" md="6" lg="6" className=' text-center'>
                                                        <Label className="text-muted"> <span className="text-danger">*</span>Hora Solución (Formato: 24 hrs) </Label>
                                                        <InputGroup>
                                                            <Input id='btn' maxLength='5' value={incidencia.HoraSolucion} disabled
                                                                type='text' max='23' min='0' />
                                                            <InputGroupAddon addonType="append">
                                                                <Button
                                                                    style={{ width: "60pt", margin: '0px 1px', zIndex: 0 }}
                                                                    color="primary" disabled
                                                                ><i className="fa fa-clock-o" />hrs</Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>}
                                            </> : <></>
                                        }
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <FormGroup>
                                    <Button
                                        size="sm"
                                        color="primary"
                                        onClick={(e) => save(e)}
                                    >
                                        Editar
                                    </Button>
                                </FormGroup>
                        
                             </CardFooter>
                             </>
                        :<Card>No cuenta con los permisos necesarios</Card>}
                            

                        </Card>

                    </Col>
                    <Col md="6">
                        <Card key="seguimiento" >
                            <CardHeader>
                                <h5 className="title text-center">Seguimiento</h5>
                            </CardHeader>
                            <Card className="card-timeline card-plain">
                                <CardHeader>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <Label>Descripción del Seguimiento</Label>
                                                <Input
                                                    style={{ height: "45pt" }}
                                                    className="textarea"
                                                    type="textarea"
                                                    cols="80"
                                                    rows="2"
                                                    value={comentario.Comentarios}
                                                    onChange={(e) => { setComentario({ ...comentario, Comentarios: e.target.value }) }}
                                                    disabled={isDisabled}
                                                />
                                            </FormGroup>
                                        </Col>{/* 
                                <Col sm="4" md="4" lg="4" className='text-center text-center'>
                                                    <Label className="text-muted">Hora de Seguimiento (Formato: 24 hrs, ejemplo: 13:30) </Label>
                                                    <InputGroup>
                                                        <Input id='btn' maxLength='2' value={incidencia.horaSolucion} disabled={isDisabled}
                                                            onChange={(e) => setIncidencia({ ...incidencia, horaSolucion: e.target.value.replace(/\D/, "") })}
                                                            type='text' max='23' min='0' />
                                                        <InputGroupAddon addonType="append">
                                                            <Button
                                                                style={{ width: "40pt",height: "35pt", margin: '0px 1px', zIndex: 0 }}
                                                                color="primary" disabled={isDisabled}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    toggleModal();
                                                                }}><i className="fa fa-clock-o" />hrs</Button>
                                                        </InputGroupAddon>
                                                        <Input id="seccion" maxLength='2' value={incidencia.minutosSolucion} disabled={isDisabled}
                                                            onChange={(e) => setIncidencia({ ...incidencia, minutosSolucion: e.target.value })}
                                                            type='text' ></Input>
                                                        <InputGroupAddon addonType="append">
                                                            <Button
                                                                style={{ width: "40pt",height: "35pt", margin: '0px 1px', zIndex: 0 }}
                                                                color="primary" disabled={isDisabled}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    toggleModal();
                                                                }}><i className="fa fa-clock-o" />min</Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                 */}
                                        <Button color="success" size="sm" style={{ height: '40px', position: 'down' }} onClick={() => send()} disabled={isDisabled} >
                                            <span className="btn-label">
                                                <i className="fa fa-paper-plane-o fa-sm" />
                                            </span>
                                 Enviar
                                 </Button>
                                    </Row>
                                </CardHeader>
                                <CardBody >
                                    <div className="container-center" style={{ height: '505px', 'overflow': 'auto' }}>
                                        <Col>
                                            <ul className="timeline timeline-simple">
                                                {seguimiento.length > 0 ? (
                                                    seguimiento.map((prop, key) => {
                                                        return (
                                                            <li className="timeline-inverted" key={"KEY_5" + key} >
                                                                <div className="timeline-badge success">
                                                                    <i className="fa fa-comment-o fa-lg" />
                                                                </div>
                                                                <div className="timeline-panel" style={{
                                                                    backgroundColor: '#F1FFFF',
                                                                    borderColor: '#F1FFFF',
                                                                }}>
                                                                    <div className="timeline-heading" >
                                                                        <Badge color="success" pill>
                                                                            {prop.UserCreate}
                                                                        </Badge>
                                                                    </div>
                                                                    <div className="timeline-body" style={{
                                                                        backgroundColor: '#F1FFFF',
                                                                        borderColor: '#F1FFFF',
                                                                    }}>
                                                                        <p>
                                                                            {prop.Comentarios}
                                                                        </p>
                                                                    </div>
                                                                    <h6>
                                                                        <i className="fa fa-clock-o"></i> {prop.HoraComentario}

                                                                    </h6>
                                                                </div>
                                                            </li>
                                                        )
                                                    }))
                                                    : <><h6 className="text-center">¡No hay comentarios de Seguimiento!</h6></>
                                                }

                                            </ul>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );

}

export default IncidenciaEdit;
