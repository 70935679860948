import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  CardHeader
} from "reactstrap";
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
import { URL_GENERAL } from "data/Consts";
import { getDataMenu } from 'data/menus.js';
//import Filter from "../components/compromisoFiltro/compromisoFiltro";

import EjecutarApi from "data/getPOST";
import { getVars } from "GlobalStorage";

const AvancesGeneralDL = (props) => {
  // const { dispatch } = useContext(Context);
  const [estatalDetalle, setEstatalDetalle] = useState([]);
  const [estatal, setEstatal] = useState({ Regional: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Municipios: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Poligonos: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Secciones: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 } });
  const [loading, setLoading] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [total, setTotal] = useState(0);
  const [regionSelected, setRegionSelected] = useState({ value: 0, label: 'Todos' });
  const [catRegiones, setCatRegiones] = useState([{ value: 0, label: 'Todos' }])
  const token = getVars("Token");

  const responsibilityID = token.user.Responsabilidad.value;

  useEffect(() => {
    setLoading(true)
    _setmenu(getDataMenu(109, token.menus));

    let regionesFormated = [];
    EjecutarApi({}, "getCatDFResponsabilidad", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {
            let regiones = [{ value: 0, label: 'Todos' }]

            regionesFormated = res.data.map(item => {
              return {
                value: item.value,
                label: item.label
              }
            })
            const result = regionesFormated.filter(reg => reg.label !== 'NO DEFINIDO');
            setCatRegiones(regiones.concat(result))
            //CatMunicipio llenar

          } else {
            showMessage("No Info: getCatalogosFiltrosPromocion", '#0E7CF1')
          }
        }
      })
      .catch((e) => {
        showMessage("Error: getCatalogosFiltrosPromocion", "#F1370E")
      }).then(() => {
        setLoading(false);
      })
    EjecutarApi({}, "getResumenEstructuraEstatal", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false)
        setEstatal(res.data);
      })
      .catch((e) => {
        setLoading(false)
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });


  }, []); //loadingTotal, loadingCompromisos

  const showMessage = (message, background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }

  // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA

  const handleRegiones = (e) => {
    setLoading(true)
    setRegionSelected(e)

    if (e.value > 0) {
      fitroSend("", e, "region");

    } else {
      fitroSend("", e, "region");
    }
    setLoading(false);
  }

  const fitroSend = (municipio, region, dato) => {

    var Region = "";

    if (region.value > 0) { Region = region.value }

    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      tipo: 'and',
      DF: Region !== "" ? Region : []
    };
    EjecutarApi(data, "getAvancesGeneralCompromisosDL", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setTotal(res.total);
            setEstatalDetalle(
              res.data.map((prop, key) => {
                return {
                  distrito: prop.DistritoFederal ? prop.DistritoFederal : "Sin Distrito Federal",
                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                  meta: prop.meta,
                  data: prop,
                  general: prop.general ? prop.general : "0",
                  porcentaje: new Intl.NumberFormat().format(prop.porcentaje),
                  promocion: prop.promocion ? prop.promocion : "0",
                  social: prop.social ? prop.social : "0",
                  electoral: prop.electoral ? prop.electoral : "0",
                };
              })
            );
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener getMetaEstructurasGeneralFiltro: ${e.message}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
  }

  const LimpiarFiltro = () => {
    setRegionSelected({ value: 0, label: 'Todos' })
    fitroSend([], [], "clear");
  }



  const Exportar = () => {
    setLoading(true)
    var url = new URL(
      `${URL_GENERAL}getReporteAvanceGeneralDF?token=${token.access_token}`
    );
    window.open(url, "_blank");
    setLoading(false)
  }

  const showRegion = responsibilityID === 99 || responsibilityID === 98 || responsibilityID === 10 || responsibilityID !== 54

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Municipales</p>
                        <CardTitle tag="p">{`${estatal.Municipios.AvanceEstructura} de ${estatal.Municipios.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-line-chart" />
                    {`Avance ${new Intl.NumberFormat().format(estatal.Municipios.porcentaje)} %`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Poligonales</p>
                        <CardTitle tag="p">{`${estatal.Poligonos.AvanceEstructura} de ${estatal.Poligonos.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-line-chart" />
                    {`Avance ${new Intl.NumberFormat().format(estatal.Poligonos.porcentaje)} %`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Seccionales</p>
                        <CardTitle tag="p">{`${estatal.Secciones.AvanceEstructura} de ${estatal.Secciones.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-line-chart" />
                    {`Avance ${new Intl.NumberFormat().format(estatal.Secciones.porcentaje)} %`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Compromisos</p>
                        <CardTitle tag="p">{`${estatal.Secciones.AvanceGeneral} de ${estatal.Secciones.MetaCompromisosPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-line-chart" />
                    {`Avance ${new Intl.NumberFormat().format(estatal.Secciones.PorcentajeGeneral)} %`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12"><Card>
              <CardHeader>
                <Row >
                  {showRegion &&
                    <Col lg="4" md="4" sm="4">
                      <FormGroup>
                        <Label for="Estado">
                          Distrito Local
                              </Label>
                        <Select
                          options={catRegiones}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          defaultValue={catRegiones[0]}
                          value={regionSelected}
                          onChange={(e) => handleRegiones(e)}
                          id="regiones"
                          placeholder="regiones"
                        />
                      </FormGroup>
                    </Col>}
                </Row>
                <Row>
                  <Col>
                    <Button name="btnLimpiar" size="sm" type="button" className="success float-left" color='warning' onClick={() => { LimpiarFiltro() }}>Limpiar</Button>
                  </Col>
                  {(_menu.Exportar === 1 && !loading) ? (
                    <Col sm='8'>
                      <Button name="btnExportar" size='sm' type="button" className='primary float-right' color='primary' onClick={() => Exportar()}>
                        <span className="btn-label">
                          <i className="fa fa-file-excel-o fa-lg" />
                        </span>
                        Exportar
							</Button>
                    </Col>
                  ) : <></>}
                </Row>
              </CardHeader>
              {loading && (
                <CircleSpinner
                  className="text-center"
                  size={30}
                  color="#686769"
                  loading={loading}
                />
              )}
              <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                <ReactTable
                  style={{
                    width: "110%",
                    height: "100%"
                  }}
                  data={estatalDetalle}
                  noDataText="Sin información"
                  manual
                  columns={[
                    {
                      Header: "Distrito Federal",
                      minWidth: 20,
                      id: "distrito",
                      accessor: (d) => d.distrito,
                      filterAll: false,
                    },
                    {
                      Header: "Municipios",
                      minWidth: 20,
                      id: "Municipios",
                      accessor: (d) => d.Municipio,
                      filterAll: false,
                    },
                    {
                      Header: "Meta",
                      minWidth: 20,
                      id: "meta",
                      accessor: (d) => d.meta,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Regional.MetaCompromisosPartido}
                        </span>
                      )
                    }, {
                      Header: "Avance Compromisos",
                      minWidth: 20,
                      id: "General",
                      accessor: (d) => d.general,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Regional.AvanceGeneral}
                        </span>
                      )
                    },
                    {
                      Header: "% Avance Compromisos",
                      minWidth: 20,
                      id: "Avance",
                      accessor: (d) => d.porcentaje,
                      Cell: props => <div>{props.value + " %"}</div>,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Regional.PorcentajeGeneral + " %"}
                        </span>
                      )
                    },
                    {
                      Header: "Meta Estructura",
                      minWidth: 20,
                      id: "metaEstructura",
                      accessor: (d) => d.metaEstructura,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Municipios.MetaEstructuraPartido}
                        </span>
                      )
                    },
                    {
                      Header: "Avance Estructura",
                      minWidth: 20,
                      id: "GeneralEstructura",
                      accessor: (d) => d.AvanceEstructura,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Municipios.AvanceEstructura}
                        </span>
                      )
                    },
                    {
                      Header: "% Avance Estructura",
                      minWidth: 20,
                      id: "AvanceEstructura",
                      accessor: (d) => d.porcentajeEstructura,
                      Cell: props => <div>{props.value + " %"}</div>,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Municipios.porcentaje + " %"}
                        </span>
                      )
                    }, {
                      Header: "Coordinadores Sociales",
                      minWidth: 20,
                      id: "Coordinadores",
                      accessor: (d) => d.Coordinadores,
                      filterAll: false
                    },
                    {
                      Header: "Avance",
                      minWidth: 20,
                      id: "Avance",
                      accessor: (d) => d.porcentaje,
                      Cell: props => <div>{props.value + " %"}</div>,
                      filterAll: false,
                    },
                    {
                      Header: "Promoción",
                      minWidth: 20,
                      id: "Promocion",
                      accessor: (d) => d.promocion,
                      filterAll: false,
                    }, {
                      Header: "Electoral",
                      minWidth: 20,
                      id: "electoral",
                      accessor: (d) => d.electoral,
                      filterAll: false,
                    }, {
                      Header: "Social",
                      minWidth: 20,
                      id: "Social",
                      accessor: (d) => d.social,
                      filterAll: false,
                    }, {
                      Header: "Compromiso",
                      minWidth: 20,
                      id: "compromiso",
                      accessor: (d) => d.compromiso,
                      filterAll: false,
                    }
                  ]}
                  className="-striped -highlight primary-pagination"
                  loading={loading}
                  showPagination={true}
                  showPaginationBottom={true}
                  showTotalPages={true}
                  showPageJump={false}
                  canNextFromData={true}
                  defaultPageSize={10}
                  total={total}
                  previousText="Anterior"
                  nextText="Siguiente"
                  ofText="de"
                  pageSizeOptions={[10, 20, 25, 50, 100]}
                  PaginationComponent={Pagination}
                  onFetchData={(stateComp, instance) => {
                    setLoading(true);
                    const data = {
                      page: stateComp.page,
                      pageSize: stateComp.pageSize,
                      DF: [],
                      sorted: [{ id: "df.id", desc: false }],
                      tipo: 'and',
                      /* filtered: Filtro?Filtro:[], */
                    };
                    EjecutarApi(data, "getAvancesGeneralCompromisosDF", token.access_token)
                      .then(function (res) {
                        return res;
                      })
                      .then((res) => {
                        if (res.success) {
                          if (res.results) {
                            setTotal(res.total);
                            setEstatalDetalle(
                              res.data.map((prop, key) => {
                                return {
                                  distrito: prop.DistritoFederal ? prop.DistritoFederal : "Sin Distrito Federal",
                                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                  meta: prop.meta,
                                  data: prop,
                                  general: prop.general ? prop.general : "0",
                                  porcentaje: new Intl.NumberFormat().format(prop.porcentaje),
                                  promocion: prop.promocion ? prop.promocion : "0",
                                  social: prop.social ? prop.social : "0",
                                  metaEstructura: prop.MetaEstructuraPartido ? prop.MetaEstructuraPartido : "0",
                                  AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : "0",
                                  porcentajeEstructura: new Intl.NumberFormat().format(prop.porcentajeEstructura),
                                  Coordinadores: prop.Coordinadores ? prop.Coordinadores : "0",
                                  compromiso: prop.compromiso ? prop.compromiso : "0",
                                  electoral: prop.electoral ? prop.electoral : "0",
                                };
                              })
                            );
                            setLoading(false);
                          }
                        }

                      })
                      .catch((e) => {
                        setLoading(false);
                        notify.show(
                          `Error al obtener tabla metas estructura region: ${e.message}`,
                          "custom",
                          5000,
                          {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                          }
                        );
                      });
                  }}
                />
              </div>
            </Card>
            </Col>
          </Row>


        </div>
        <Footer fluid />
      </div>
    </div >
  );
};

export default AvancesGeneralDL;
