import React, { useEffect, useState } from "react";
import ReactTable from "react-table";

import { notify } from "react-notify-toast";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";
import EjecutarApi from "../../data/getPOST";
import Pagination from "../Pagination/pagination";

function UsuarioLog({ idUser }) {
  const token = getVars("Token");
  const [_idUser] = useState(idUser);
  const [logs, setLogs] = useState([]);
  const [logsTotal, setLogsTotal] = useState(0);
  const [loading, setLoading] = useState(false);


  useEffect(() => { }, []);

  return (
    <>
      <div style={{ 'width': '100%', 'overflow': 'auto' }}>
        <ReactTable
          style={{
            width: "100%",
            height: "100%"
          }}
          size='sm'
          data={logs}
          columns={[
            {
              Header: "Cuenta",
              minWidth: 30,
              id: "cuenta",
              accessor: (d) => d.cuenta,

            },
            {
              Header: "Nombre",
              minWidth: 70,
              id: "Nombre",
              accessor: (d) => d.Nombre,

            },
            {
              Header: "Tipo",
              minWidth: 30,
              id: "Tipo",
              accessor: (d) => d.Tipo,

            },

            {
              Header: "Plataforma",
              minWidth: 30,
              id: "isApp",
              accessor: (d) => d.isApp,

            },
            {
              Header: "Fecha Hora",
              minWidth: 40,
              id: "FechaHora",
              accessor: (d) => d.FechaHora,

            },
          ]}
          noDataText="Sin información"
          manual
          className="-striped -highlight primary-pagination"
          loading={loading}
          showPagination={true}
          showPaginationBottom={true}
          showTotalPages={true}
          showPageJump={false}
          canNextFromData={true}
          defaultPageSize={10}
          total={logsTotal}
          pages={Math.floor(logsTotal / 10)}
          previousText="Anterior"
          nextText="Siguiente"
          ofText="de"
          pageSizeOptions={[10, 20, 25, 50, 100]}
          PaginationComponent={Pagination}
          onFetchData={(stateComp) => {
            setLoading(true);
            var dato = {
              filtered: [{ id: "idUser", value: _idUser }],
              page: stateComp.page,
              pageSize: stateComp.pageSize,
              sorted: [{ "id": "FechaHora", "desc": true }],
            };
            EjecutarApi(dato, "getUserLoginLog", token.access_token)
              .then(function (res) {
                return res;
              })
              .then((res) => {
                if (res.results) {
                  setLogs(
                    res.data.map((prop) => {
                      setLoading(false);
                      setLogsTotal(res.total);
                      return {
                        cuenta: prop.cuenta,
                        Nombre: prop.Nombre,
                        Tipo: prop.Tipo,
                        isApp: prop.isApp === 1 ? "App" : "Web",
                        FechaHora: prop.FechaHora,
                      };
                    })
                  );
                } else {
                  setLoading(false);
                  notify.show(
                    "Error al leer: getUserLoginLog",
                    "custom",
                    5000,
                    {
                      background: "#0857B6",
                      fontSize: 14,
                      text: "#FFFFFF",
                    }
                  );
                }
                setLoading(false);
              })
              .catch((e) => {
                setLoading(false);
                notify.show("Error: getUserLoginLog", "custom", 5000, {
                  background: "#0857B6",
                  fontSize: 14,
                  text: "#FFFFFF",
                });
              });
          }}
        />
      </div>
    </>
  );
}

export default UsuarioLog;
