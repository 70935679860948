import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Card, CardHeader, CardBody, Row, Col, CardFooter, CardTitle } from "reactstrap";
import { useHistory } from "react-router-dom";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Notifications, { notify } from "react-notify-toast";
import { RotateSpinner } from "react-spinners-kit";
import ReactTable from 'react-table';
import PerfectScrollbar from "perfect-scrollbar";
import swal from "sweetalert";
import { School } from '@material-ui/icons';

import EjecutarApi from "data/getPOST";
import { getDataMenu } from "data/menus";
import Pagination from "components/Pagination/pagination";
import { getVars, URL_PUBLICA } from "GlobalStorage";
import Responsabilidad from "components/Responsabilidad/index";
import AddResponsabilidad from 'components/Voluntarios/AddResponsabilidad';
import ModalFiltro from 'components/Filtro/indexRC';
import ModalCapacitados from 'components/Capacitacion/ModalCapacitadosRC';

var ps;

function ElectoralRCs(props) {
	const mainPanel = useRef();
	const token = getVars("Token");
	let history = useHistory();
	const [_menu, _setmenu] = useState({});
	const [menu, setearMenu] = useState({});
	const [loading, setLoading] = useState(false);
	const [TotalRegistros, setTotalRegistros] = useState(0);
	const [Registros, setRegistros] = useState([]);
	const [itemVoluntario, setitemVoluntario] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [modal, setModal] = useState(false);
	const [itemData, setItemData] = useState(null);
	const [Filtro, setFiltro] = useState([]);
	const [FiltroTxt, setFiltroTxt] = useState([]);
	const [data, setData] = useState();
	const [catCapacitaciones, setCatCapacitaciones] = useState([]);
	const [isCapacitacionSave, setCapacitacionSave] = useState(false);
	const [isLoadingDatos, setIsLoadingDatos] = useState(false);
	const [MenuModal] = useState({
		Compromiso: false,
		Voluntarios: false,
		RCs: true,
		RGs: false,
		Abogado: false,
		CasaAzul: false,
		Municipio: false
	});
	const [
		ModalCapacitado,
		setModalCapacitado,
	] = useState(false);

	useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current);
			document.body.classList.toggle("perfect-scrollbar-on");
		}

		if (props.history.action === "PUSH") {
			mainPanel.current.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
		}


		let menuCapacitado = getDataMenu(139, token.menus);
		if (Object.keys(menuCapacitado).length > 0) {
			setearMenu(menuCapacitado);
		}
		let menu = getDataMenu(31, token.menus);
		if (Object.keys(menu).length > 0) {
			_setmenu(menu);
		} else {
			//El usuario no tiene el menú asignado 
		}


		if (menuCapacitado.Agregar === 1) {
			EjecutarApi({}, 'getCapacitacionesWeb', token.access_token)
				.then(function (res) {
					return res;
				})
				.then((res) => {
					if (res.success) {
						if (res.results) {
							if (res.data.length) {
								setCatCapacitaciones(res.data.map(item => {
									return { label: item.Nombre, value: item.id }
								}))

							}
						}
					}
					setData(res.data);
				})
				.catch((e) => {
					notify.show(`Error: ${e.error}`, 'custom', 5000, {
						background: '#0857B6',
						fontSize: 14,
						text: '#FFFFFF',
					});
				});
		}

	}, [])

	const onHide = () => {
		setModal(false);
	}

	const onHide2 = () => {
		setShowModal(false);
	}

	const handleMiniClick = () => {
		document.body.classList.toggle("sidebar-mini");
	};

	const handleBtnSetResposabilidad = () => {
		history.push("/rcs_cap");
	};

	const Exportar = () => {
		
		const data = {

			tipo: 'and',
			filtered: Filtro,
			idResponsabilidad: 2,
			Export:true
		};
	  
		  var datos = JSON.stringify(data)
		  var url = new URL(
			  `${URL_PUBLICA}ExportPropuestasElectorales?token=${token.access_token}&serializar=${datos}`
		  );
		  window.open(url, "_blank");
	}

	const ExportarAnt = () =>{
		let filtro="";
		if(FiltroTxt.length > 1){
			FiltroTxt.forEach(element => {
				filtro=filtro+`&${element}`
			});
		}else if(FiltroTxt.length == 1){
			filtro=`&${FiltroTxt[0]}`
		}

		var url = new URL(URL_PUBLICA +
			`getTxtEstructuraAnteCasilla?token=${token.access_token}${filtro}`
		);
		window.open(url, "_blank");
	}

	const ExportarAntSin = () =>{
		let filtro="";
		if(FiltroTxt.length > 1){
			FiltroTxt.forEach(element => {
				filtro=filtro+`&${element}`
			});
		}else if(FiltroTxt.length == 1){
			filtro=`&${FiltroTxt[0]}`
		}

		var url = new URL(URL_PUBLICA +
			`getTxtEstructuraAnteCasillaSinDomicilio?token=${token.access_token}${filtro}`
		);
		window.open(url, "_blank");
	}
	const handleFiltro = (filter) => {
		var _filtro = [];
		var filtroTxt =[]

		// if (filter.Region.value > 0) {
		// 	_filtro.push({ id: 's.idRegion', value: filter.Region.value })
		// }
		if (filter.DF.value > 0) {
			_filtro.push({ id: 'df.id', value: filter.DF.value })
			filtroTxt.push(`DF=${filter.DF.value}`)
		}
		if (filter.DL.value > 0) {
			_filtro.push({ id: 'dl.id', value: filter.DL.value })
			filtroTxt.push(`DL=${filter.DL.value}`)
		}
		if (filter.Municipio.value > 0) {
			_filtro.push({ id: 'cm.id', value: filter.Municipio.value })
			filtroTxt.push(`municipios=${filter.Municipio.value}`)
		}
		if (filter.Poligono.value > 0) {
			_filtro.push({ id: "s2.idPoligono", value: filter.Poligono.value })
		}
		if (filter.Seccion.value > 0) {
			_filtro.push({ id: "casi.Seccion", value: filter.Seccion.value })
		}
		if (filter.ClaveElector.length > 0) {
			_filtro.push({ id: "rcs.ClaveElector", value: filter.ClaveElector })
		}
		if (filter.Nombre.length > 0) {
			_filtro.push({ id: "rcs.Nombre", value: filter.Nombre })
		}
		if (filter.Paterno.length > 0) {
			_filtro.push({ id: "rcs.Paterno", value: filter.Paterno })
		}
		if (filter.Materno.length > 0) {
			_filtro.push({ id: "rcs.Materno", value: filter.Materno })
		}
		if (filter.Casilla.value > 0) {
			_filtro.push({ id: "rcs.NombreCasilla", value: filter.Casilla.label })
		}
		if (filter.TipoCasilla.value > 0) {
			_filtro.push({ id: "rcs.idPropietario", value: filter.TipoCasilla.value })
			filtroTxt.push(`Propietario=${filter.TipoCasilla.value}`)
		}
		if (filter.Funcionario > 0) {
			_filtro.push({ id: "rcs.TieneExperiencia", value: filter.Funcionario ? 1 : 0 })
		}
		if (filter.Auto > 0) {
			_filtro.push({ id: "rcs.TieneAuto", value: filter.Auto ? 1 : 0 })
		}
		if (filter.CuidarCasilla > 0) {
			_filtro.push({ id: "rcs.AceptaCuidarOtra", value: filter.CuidarCasilla ? 1 : 0 })
		}
		if (filter.Celular.length > 0) {
			_filtro.push({ id: "rcs.Celular", value: filter.Celular })
		}

		setFiltro(_filtro);
		setFiltroTxt(filtroTxt);

		const data = {
			page: 0,
			pageSize: 10,
			filtered: _filtro,
			idResponsabilidad: 2,
			tipo: "and"
		};

		getRegistros(data);
	}

	const columns = [
		{
			Header: "DF",
			id: "DFED",
			accessor: "DF",
			minWidth: 40,
			filterAll: false
		},

		{
			Header: "DL",
			id: "DLOC",
			accessor: "DL",
			minWidth: 40,
			filterAll: false,
		},
		{
			Header: "Municipio",
			id: "cm.Municipio",
			accessor: "Municipio",
			minWidth: 40,
			filterAll: false,
		},

		{
			Header: "Sección",
			id: "casi.Seccion",
			accessor: "Seccion",
			minWidth: 35,
			filterAll: false,
		},
		{
			Header: "Casilla",
			id: "casi.NombreCasilla",
			accessor: "Casilla",
			minWidth: 35,
			filterAll: false,
		},
		{
			Header: "Propietario",
			id: "ccp.TipoPropietario",
			accessor: "Propietario",
			minWidth: 40,
			filterAll: false,
		},
		{
			Header: "Nombre",
			id: "rcs.Nombre",
			accessor: "Responsable",
			minWidth: 70,
			filterAll: false,
		},
		{
			Header: 'Capacitado',
			id: 'capacitado',
			show: menu.Agregar === 1 ? true : false,
			accessor: "capacitado",
			filterAll: false,
		},
		{
			Header: "Cuidar Otra?",
			accessor: "AceptaCuidarOtra",
			id: "AceptaCuidarOtra",
			minWidth: 40,
			filterable: false,
		},
		{
			Header: "Experiencia",
			accessor: "TieneExperiencia",
			id: "TieneExperiencia",
			minWidth: 40,
			filterable: false,
		},
		{
			Header: "Auto",
			accessor: "TieneAuto",
			id: "TieneAuto",
			minWidth: 40,
			filterable: false,
		}
	];

	const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
		const data = {
			page: page,
			pageSize: pageSize,
			filtered: params,
			sorted: sorted,
			idResponsabilidad: 2,
			tipo: "and"
		};
		getRegistros(data);
	}, []);

	const getRegistros = (data) => {
		setLoading(true);
		let _menuStorage = getDataMenu(31, token.menus);
		EjecutarApi(data, "getCompromisosResponsabilidadPropuesta", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				setLoading(false);
				setTotalRegistros(res.total);
				setRegistros(
					res.data.map((item) => {
						return {
							id: item.idProuesta,
							DF: item.DF.label,
							DL: item.DL.label,
							Municipio: item.Municipio.label,
							Zona: item.Zona.label,
							Partido: item.siglas,
							Seccion: item.Seccion,
							Casilla: item.NombreCasilla,
							Propietario: item.Propietario,
							Responsable: _menuStorage.Eliminar === 1 ? (
								<div className="text-center"><Button className="btn-link"
									type="button"
									color="danger"
									onClick={(e) => EliminarResponsablidad(item, e)}
								>
									<i class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
								</Button>{item.NombreCompleto}<br />Municipio: {item.Municipio.label}
								</div>) : (<>{item.NombreCompleto}<br />Municipio: {item.Municipio.label}</>),
							FechaPropuesta: item.FechaAsignoResponsabilidad,
							QuienPropone: item.UserAsignoResponsabilidad,
							OCR: item.OCR,
							capacitado: <><Button className="btn-round" size="sm" color="primary" onClick={() =>
								getCapacitados(item)
							}><span className="btn-label">
									<School style={{ color: '#FFF' }} />
								</span></Button> </>,
							VigenciaINE: item.VigenciaINE,
							TieneExperiencia: item.TieneExperiencia === 1 ? 'Si' : 'No',
							TieneAuto: item.TieneAuto === 1 ? 'Si' : 'No',
							AceptaCuidarOtra: item.AceptaCuidarOtra === 1 ? 'Si' : 'No'
						};
					})
				);
			})
			.catch((e) => {
				setLoading(false);
				notify.show("Error al cargar registros", "error", 5000);
			})
	}
	const getCapacitados = (item) => {
		setItemData(item);
		setModalCapacitado(true)

	};

	const EliminarResponsablidad = (item, e) => {
		e.preventDefault();
		swal({
			title: "¿Está Seguro?",
			text: `Que desea eliminar la responsabilidad de ${item.Fullname}, como RC`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancelar",
					value: null,
					visible: true,
					className: "btn-danger",
					closeModal: true,
				},
				confirm: {
					text: "Si, eliminar!",
					value: true,
					visible: true,
					className: "btn-success",
					closeModal: true
				}
			}
		})
			.then((willDelete) => {
				if (willDelete) {
					removeResponsablidad(item);
				} else {

				}
			});
	}
	const removeResponsablidad = (item) => {
		EjecutarApi({ ClaveElector: item.ClaveElector }, 'eliminarResponsablesWeb', token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.results) {
					notify.show(res.message, "success", 5000, {
						background: "#d61818",
						text: "#FFFFFF",
					});
					let data = {
						page: 0,
						pageSize: 10,
						filtered: Filtro,
						sorted: [],
						idResponsabilidad: 2,
						tipo: "and"
					}

					getRegistros(data)
				}
				else {
					notify.show(res.message, "error", 5000, {
						background: "#d61818",
						text: "#FFFFFF",
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
					background: "#d61818",
					text: "#FFFFFF",
				});
			});
	};

	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel ps ps--active-y" ref={mainPanel}>
				<Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
				<div className="content">
					<Row>
						<Col sm="12">
							<Row>
								<Col lg="3" md="6" sm="6">
									<Card className="card-stats">
										<CardBody>
											<Row>
												<Col md="4" xs="5">
													<div className="icon-big text-center icon-warning">
														<i className="fa fa-users text-success" />
													</div>
												</Col>
												<Col md="8" xs="7">
													<div className="numbers">
														<p className="card-category">Casilla Propietario</p>
														<CardTitle tag="p"></CardTitle>
														<p />
													</div>
												</Col>
											</Row>
										</CardBody>
										<CardFooter>
											<hr />
											<div className="stats">
												<i className="fa fa-calendar-o" />
												Last day
										</div>
										</CardFooter>
									</Card>
								</Col>
								<Col lg="3" md="6" sm="6">
									<Card className="card-stats">
										<CardBody>
											<Row>
												<Col md="4" xs="5">
													<div className="icon-big text-center icon-warning">
														<i className="fa fa-users text-info" />
													</div>
												</Col>
												<Col md="8" xs="7">
													<div className="numbers">
														<p className="card-category">Casilla Suplente 1</p>
														<CardTitle tag="p"></CardTitle>
														<p />
													</div>
												</Col>
											</Row>
										</CardBody>
										<CardFooter>
											<hr />
											<div className="stats">
												<i className="fa fa-refresh" />
												Actualización
										</div>
										</CardFooter>
									</Card>
								</Col>
								<Col lg="3" md="6" sm="6">
									<Card className="card-stats">
										<CardBody>
											<Row>
												<Col md="4" xs="5">
													<div className="icon-big text-center icon-warning">
														<i className="nc-icon nc-favourite-28 text-primary" />
													</div>
												</Col>
												<Col md="8" xs="7">
													<div className="numbers">
														<p className="card-category">Casilla Suplente 2</p>
														<CardTitle tag="p"></CardTitle>
														<p />
													</div>
												</Col>
											</Row>
										</CardBody>
										<CardFooter>
											<hr />
											<div className="stats">
												<i className="fa fa-clock-o" />
												Actual
									</div>
										</CardFooter>
									</Card>
								</Col>
							</Row>
							{ModalCapacitado && itemData !== null && (
								<ModalCapacitados
									isOpen={ModalCapacitado}
									item={itemData}
									token={token}
									catCapacitaciones={catCapacitaciones}
									setIsOpen={() =>
										setModalCapacitado(false)
									}
								/>
							)}
							<Card>
								<CardHeader>
									<h5 className="title">Representantes de casillas</h5>
									<Col sm="12" md="12" lg="12">
										<Row>
											{/*_menu.Agregar === 1 ? (<><Button color="success" size="sm" onClick={() => handleBtnSetResposabilidad()} >
												<span className="btn-label">
													<i className="fa fa-user-plus fa-lg" />
												</span>
												Capturar
											</Button></>) : <></>*/}
											{_menu.Exportar === 1 ? (
												<Button size='sm' className='primary left-align' color='primary' onClick={() => Exportar()}>
													<span className="btn-label">
														<i className="fa fa-file-excel-o fa-lg" />
													</span>
													Exportar 
												</Button>
											) : <></>}
											{_menu.Seguimiento === 1 ? (
												<>
												<Button size='sm' className='primary left-align' color='primary' onClick={() => ExportarAnt()}>
												<span className="btn-label">
													<i className="fa fa-file-excel-o fa-lg" />
												</span>
												Exportar txt
											</Button>
											<Button size='sm' className='primary left-align' color='primary' onClick={() => ExportarAntSin()}>
												<span className="btn-label">
													<i className="fa fa-file-excel-o fa-lg" />
												</span>
												Exportar txt Sin Domicilio
											</Button>
											</>
											) : <></>}
										</Row>
										<Row>
											<Col sm="12">
												<ModalFiltro Menu={MenuModal} Datos={(e) => handleFiltro(e)} />
											</Col>
										</Row>
									</Col>
								</CardHeader>
								<CardBody>
									{<RotateSpinner color={"#1261ff"} size={30} loading={loading} />}
									<div style={{ 'width': '100%', 'overflow': 'auto' }}>
										<ReactTable
											style={{
												width: "100%",
												height: "100%"
											}}
											size='sm'
											data={Registros}
											noDataText="Sin información"
											manual
											columns={columns}
											className="-striped -highlight primary-pagination"
											loading={loading}
											showPagination={true}
											showPaginationBottom={true}
											showTotalPages={true}
											showPageJump={false}
											canNextFromData={true}
											defaultPageSize={10}
											total={TotalRegistros}
											pages={Math.floor(TotalRegistros / 10)}
											previousText="Anterior"
											nextText="Siguiente"
											ofText="de"
											pageSizeOptions={[10, 20, 25, 50, 100]}
											PaginationComponent={Pagination}
											onFetchData={fetchData}
											params={Filtro}
										/>
									</div>

								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
				<Footer fluid />
				{showModal ? <Responsabilidad props={props} Datos={itemVoluntario} onHide={onHide2} /> : <></>}
				{modal ? <AddResponsabilidad menu={MenuModal} props={props} onHide={onHide} /> : <></>}
			</div>
		</div>
	);
}
export default ElectoralRCs;