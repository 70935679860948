import React, { useState, useEffect } from 'react';

import { Row, Col, Input, Button, Label, FormGroup, ButtonGroup, Card, CardTitle, CardBody, CardFooter, InputGroup, InputGroupAddon } from 'reactstrap';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';
import Select from "react-select";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import swal from "sweetalert";
import EjecutarApi from "data/getPOST";
import { getVars } from "GlobalStorage";
import BuscarOwned from "components/Usuarios/BuscarUserOwned"
import BuscaResponsable from 'components/Usuarios/BuscaResponsableElectoral'
import { getDataMenu } from 'data/menus';

const VoluntariosSetResponsabilidad = ({ menu, btnCancelar }) => {
    const [CompromisoResponsabilidad, setCompromisoResponsabilidad] = useState(menu.Compromiso ? menu.Compromiso.Responsabilidad : 'Sin Responsabilidad')
    const [loading, setLoading] = useState(false);
    const [MenuR, setMenuR] = useState(menu.Menu);
    const token = getVars("Token");
    let history = useHistory();
    const [showElectoral, setShowElectoral] = useState(false);
    const [showPromocion, setShowPromocion] = useState(false);
    const [catPromocion, setcatPromocion] = useState([{ value: 11, label: 'No Definido' }]);
    const [catCasilla, setcatCasilla] = useState([{ value: 0, label: 'Debe validar la sección' }]);
    const [catPropietario, setcatPropietario] = useState([{ value: 0, label: 'Debe validar la sección' }]);
    const [catDistrito, setcatDistrito] = useState([{ value: 0, label: 'Debe validar la sección' }]);
    const [catCasaColonia, setCatCasaColonia] = useState([]);
    const [catCasaLocalidad, setCatCasaLocalidad] = useState([]);
    const [catMunicipioCasa, setCatMuinipioCasa] = useState([]);
    const [catPoligonoCasa, setCatPoligonoCasa] = useState([]);
    const [catSeccionCasa, setCatSeccionCasa] = useState([]);
    const [catRegion, setCatRegion] = useState([]);
    const [showButton, setShowButton] = useState(false);
    const [Distrito, setDistrito] = useState([{ value: 0, label: 'Debe validar la sección' }]);
    const [isElectoral, setIsElectoral] = useState({
        RC: false,
        RG: false,
        Abogado: false,
        CasaAzul: false,
        isRC: {
            Partido: false
        },
        isRG: {
            Partido: false
        },
        Observador: false,
        PrimeroFila: false
    });
    const [isPromocion, setIsPromocion] = useState({
        Regional: false,
        Municipal: false,
        Poligonal: false,
        Seccional: false,
        Manzanero: false
    })
    const [isSocial, setIsSocial] = useState({
        CoorGralSocial: false,
        CoorEstrSocial: false,
        CoorRegSocial: false,
        CoorMpalSocial: false,
        CoorEstatalSocial: false,
        CoordeEstructura: false
    })
    const [catSocial, setCatSocial] = useState([
        { value: 30, label: 'Coordinador General Social' },
        { value: 31, label: 'Coordinador Estructura Social' },
        { value: 32, label: 'Coordinador Regional Social' },
        { value: 33, label: 'Coordinador Municipal Social' }])
    const [showSocial, setShowSocial] = useState(false);
    const [MpioSeccion, setMpioSeccion] = useState('');
    const [Seccion, setSeccion] = useState('');
    const [Casilla, setCasilla] = useState([]);
    const [Propietario, setPropietario] = useState([]);
    const [catResponsabilidad, setCatResponsabilidad] = useState([
        { label: 'RCs', value: 1 },
        { label: 'RGs', value: 2 },
        { label: 'Casa Azul', value: 3 },
        { label: 'Abogado', value: 4 },
        { label: 'Primero en Fila', value: 5 },
    ]);
    const [idResponsabilidad, setidResponsabilidad] = useState(0);
    const [SeccionMpio, setSeccionMpio] = useState('');
    const [Promocion, setPromocion] = useState({
        idMunicipio: 0,
        idRegion: 0,
        idPoligono: 0,
        idPoligonoMunicipio: 0,
        idSecional: ''
    })
    //const [ResPromocion,setResPromocion] = useState('');
    const [Casa, setCasa] = useState({
        Calle: "",
        NumExt: "",
        NumInt: "",
        Colonia: [],
        Latitud: "",
        Longitud: "",
        TieneInter: false,
        VelocidadInter: "",
        TotalEquipos: "",
        NombreDueno: "",
        CelularDueno: "",
        TelCasa: '',
        Referencia: "",
        idMunicipio: [],
        idPoligono: [],
        Seccion: [],
        Localidad: []

    });
    const [Responsable, setResponsable] = useState({
        value: 0,
        label: ''
    });
    const [showMensaje, setShowMensaje] = useState(false);
    const [showResponsable, setShowResponsable] = useState(false);
    const [tieneExperiencia, setTieneExperiencia] = useState(1);
    const [tieneAuto, setTieneAuto] = useState(1);
    const [AceptaCuidarOtra, setAceptaCuidarOtra] = useState(1);
    const [OCR, setOCR] = useState('');
    const [VigenciaINE, setVigenciaINE] = useState('');
    const [catEstructura, setCatEstructura] = useState([]);
    const [Estructura, setEstructura] = useState({
        idEstructura: {},
        Codigo: '',
        idRegion: {},
        idMunicipio: {},
        idResponsabilidad: {}
    })
    const [Manzanera, setManzanera] = useState({
        Seccion: '',
        Manzana: '',
        idSeccion: 0,
        idManzana: 0,
        ManzanaSeleccionada: 0
    })
    const [compromisoManzanera, setCompromisoManzanera] = useState(menu.Compromiso.Manzanas)
    const [catManzanas, setCatManzanas] = useState([]);
    const [isModal, setisModal] = useState(false);
    const [_menu, _setmenu] = useState({});
    const [activeAction, setActiveAction] = useState("");
    const [IsInfoAdd, setIsInfoAdd] = useState(false);
    const [ArrPermitidos] = useState([99, 97,16])
    const [tipoCapturista]=useState([
        {value:1,label:"Estatal"},
        {value:2,label:"Regional"},
        {value:3,label:"Municipal"},
    ]);
    const [optCapElectoral,setOptCapElectoral]=useState([]);
    const [optCapPromocion,setOptCapPromocion]=useState([]);
    const [isCapturista,setIsCapturista] =useState({
        Electoral:false,
        Promocion:false,
        Social:false,
    });
    const [MultiValueResponsabilidad, setMultiValueResponsabilidad] = useState([]);
    const [CapElectoral, setCapElectoral] = useState({
        Tipo:{value:-1,label:'Seleccionar...'},
        Region:{value:-1,label:'Seleccionar...'},
        Municipio:{value:-1,label:'Seleccionar...'},
        Responsabilidad:[],
        idResponsabilidad:0,
        ResponsabilidadDataID:0,
        idUser:0
    });
    const [capTerritorial, setCapTerritorial] = useState({
        Tipo:{value:-1,label:'Seleccionar...'},
        Region:{value:-1,label:'Seleccionar...'},
        Municipio:{value:-1,label:'Seleccionar...'},
        Responsabilidad:[],
        idResponsabilidad:0,
        ResponsabilidadDataID:0,
        idUser:0
    });
    const [isCapElectoral, setIsCapElectoral] = useState({
        Municipal:false,
        Regional:false,
        Electoral:false,
    });
    const [isCapPromocion, setIsCapPromocion] = useState({
        Regional: false,
        Municipal: false,
        Poligonal: false,
        Seccional: false,
        Manzanero: false
    });
    const [isCapturaPermitida,setIisCapturaPermitida] = useState(false);
    const [arrCapturaPermitida] = useState([99, 97,16]);
    useEffect(() => {
        setLoading(true);
        let visible = ArrPermitidos.filter(item => item == token.user.Responsabilidad.value);
        if (visible.length > 0) {
            setIsInfoAdd(true);
        }
        let capturista= arrCapturaPermitida.filter(item=> item==token.user.Responsabilidad.value);
        if(capturista.length>0)
        {
            setIisCapturaPermitida(true);
        }
        _setmenu(getDataMenu(9, token.menus));
        setResponsable({ value: token.user.id, label: `${token.user.Responsabilidad.label}${' - '}${token.user.Nombre} ${token.user.Paterno} ${token.user.Materno}` })
        EjecutarApi({}, 'getCatalogosPorResponsabilidadWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                var promocion = []
                var social = []
                var Territorial = []

                if (res.success) {
                    res.data.forEach(item => {
                        if (item.Tipo === 'Electoral') {
                            promocion.push({
                                label: item.label,
                                value: item.value
                            })
                        }
                    })
                    res.data.forEach(item => {
                        if (item.Tipo === 'Social') {
                            social.push({
                                label: item.label,
                                value: item.value
                            })
                        }
                    })
                    res.data.forEach(item => {
                        if (item.Tipo === 'Promoción') {
                            Territorial.push({
                                label: item.label,
                                value: item.value
                            })
                        }
                    })
                    setCatResponsabilidad(promocion);
                    setcatPromocion(Territorial);
                    setCatSocial(social)
                    setLoading(false);
                }

            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                    background: '#0857B6',
                    fontSize: 14,
                    text: '#FFFFFF'
                });
            });

    }, [])


    const handlePoligoCasaChanged = (e) => {
        setCasa({ ...Casa, idPoligono: e });
        var parametro = { tipo: "and", filtered: [{ id: "s.idPoligono", value: e.value }] }
        EjecutarApi(parametro, 'getSeccionesByPoligono', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setCatSeccionCasa(res.data.map((item) => {
                        return {
                            label: item.Seccion,
                            value: item.Seccion
                        }
                    }));
                }
            })
            .catch((e) => {

                notify.show(`Error en la búsqueda de Municipio Vive! ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }
    const handleShowPromocion = (e) => {
        cleanCampos("Promocion")

        setMenuR({ ...MenuR, AddResponsabilidad: false });
        setShowElectoral(false);
        setShowPromocion(true);
        setShowSocial(false);

        //setMenuR({...MenuR, AddResponsabilidad:false });
        //setShowElectoral(false);
        //setShowPromocion(true);
        /*setIsElectoral({RC:false,
            RG:false,
            Abogado:false,
            CasaAzul:false,
            Municipio:false,
            isRC:{
                Partido:false
            },
            Observador:false
            });*/

    }
    const llenadoCatDistrito = () => {
        setLoading(true);
        //Partido
        EjecutarApi({}, 'getListadoDF', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setcatDistrito(res.data);
                }

            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                    background: '#0857B6',
                    fontSize: 14,
                    text: '#FFFFFF'
                });
            });

    }
    const llenadoCatMunicipio = () => {
        EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.results) {
                    if (items.data) {
                        setCatMuinipioCasa(
                            items.data.municipios.map((item) => {
                                return {
                                    value: item.value,
                                    label: item.label,
                                };
                            }));
                        setLoading(false);
                    }
                } else {
                    notify.show("No Info: getCatalogosFiltrosPromocion", "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show("Error: getCatalogosFiltrosPromocion", "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });
    }
    const llenadoCatRegion = () => {
        EjecutarApi({ option: 'region' }, "getCatalogosFiltrosPromocion", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    if (res.data) {
                        setCatRegion(res.data.regiones.map((item) => {
                            return {
                                value: item.value,
                                label: item.label
                            };
                        })
                        );
                        setLoading(false);
                    }

                } else {
                    notify.show("No Info: getCatalogosFiltrosPromocion", "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show("Error: getCatalogosFiltrosPromocion", "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });
    }
    const btnValidaSeccion = () => {
        EjecutarApi({ "seccion": Seccion }, 'validarSeccion', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    notify.show(`Sección válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    setMpioSeccion(res.data[0].Municipio)
                    //getCasillasBySeccion 
                    EjecutarApi({ "seccion": Seccion }, 'getCasillasBySeccion', token.access_token)
                        .then(function (res) {

                            return res;
                        })
                        .then((res) => {
                            if (res.success) {
                                setcatCasilla(res.data);
                                setLoading(false);
                            }

                        })
                        .catch((e) => {
                            setLoading(false);
                            notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                                background: '#0857B6',
                                fontSize: 14,
                                text: '#FFFFFF'
                            });
                        });
                    //getCasillaPropietario 
                    EjecutarApi({ "seccion": Seccion }, 'getCasillaPropietario', token.access_token)
                        .then(function (res) {
                            return res;
                        })
                        .then((res) => {
                            if (res.success) {
                                setcatPropietario(res.data);
                                setLoading(false);
                            }

                        })
                        .catch((e) => {
                            setLoading(false);
                            notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                                background: '#0857B6',
                                fontSize: 14,
                                text: '#FFFFFF'
                            });
                        });
                }
                else {
                    notify.show(`Sección No válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    setLoading(false);
                }

            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                    background: '#0857B6',
                    fontSize: 14,
                    text: '#FFFFFF'
                });
            });

    }
    const btnValidarSeccion = () => {
        EjecutarApi({ "seccion": Promocion.idSecional }, 'validarSeccion', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setSeccionMpio(res.data.map((item) => {
                        return item.MunicipioReporte;
                    }))
                    notify.show(`Sección válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });

                }
                else {
                    notify.show(`Sección No válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    setLoading(false);
                }

            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                    background: '#0857B6',
                    fontSize: 14,
                    text: '#FFFFFF'
                });
            });

    }
    const handleShowElectoral = () => {
        cleanCampos("Electoral")

        setMenuR({ ...MenuR, AddResponsabilidad: false });
        setShowElectoral(true);
        setShowPromocion(false);
        setShowSocial(false);


    }
    const handlePromocion = (e) => {
        setidResponsabilidad(e.value);
        switch (e.label) {
            case 'RGS':
                setIsElectoral({
                    RC: false,
                    RG: true,
                    Abogado: false,
                    CasaAzul: false,
                    Municipio: false,
                    isRC: {
                        Partido: false
                    },
                    Observador: false,
                    PrimeroFila: false
                });
                break;
            case 'RCS':
                setIsElectoral({
                    RC: true,
                    RG: false,
                    Abogado: false,
                    CasaAzul: false,
                    Municipio: false,
                    Observador: false,
                    PrimeroFila: false
                });

                break;
            case 'ABOGADOS':
                setIsElectoral({
                    RC: false,
                    RG: false,
                    Abogado: true,
                    CasaAzul: false,
                    Municipio: false,
                    Observador: true,
                    PrimeroFila: false
                });

                break;
            case 'CASA AZUL':
                setIsElectoral({
                    RC: false,
                    RG: false,
                    Abogado: false,
                    CasaAzul: true,
                    Municipio: false,
                    Observador: false,
                    PrimeroFila: false
                });
                break;
            case 'PRIMERO EN FILA':
                setIsElectoral({
                    RC: false,
                    RG: false,
                    Abogado: false,
                    CasaAzul: false,
                    Municipio: false,
                    Observador: false,
                    PrimeroFila: true
                });
                break;
            case 'MUNICIPIO':
                setIsElectoral({
                    RC: false,
                    RG: false,
                    Abogado: false,
                    CasaAzul: false,
                    Municipio: true,
                    Observador: false
                });
                break;
            case 'REGIONAL':
                setIsPromocion({
                    Regional: true,
                    Municipal: false,
                    Poligonal: false,
                    Seccional: false,
                    Manzanero: false
                });
                llenadoCatRegion();
                break;
            case 'MUNICIPAL':
                setIsPromocion({
                    Regional: false,
                    Municipal: true,
                    Poligonal: false,
                    Seccional: false,
                    Manzanero: false
                });
                llenadoCatMunicipio();
                break;
            case 'POLIGONAL':
                setIsPromocion({
                    Regional: false,
                    Municipal: false,
                    Poligonal: true,
                    Seccional: false,
                    Manzanero: false
                });
                llenadoCatMunicipio();
                break;
            case 'SECCIONAL':
                setIsPromocion({
                    Regional: false,
                    Municipal: false,
                    Poligonal: false,
                    Seccional: true,
                    Manzanero: false
                });
                break;
            case 'MANZANERA':
                setIsPromocion({
                    Regional: false,
                    Municipal: false,
                    Poligonal: false,
                    Seccional: false,
                    Manzanero: true
                });
                break;
            case 'COORDINADOR ESTATAL TERRITORIAL':
                setIsPromocion({
                    Regional: false,
                    Municipal: false,
                    Poligonal: false,
                    Seccional: false,
                    Manzanero: false,
                    CoordEstatalTerritorial: true,
                });
                break;
            default:
                setIsElectoral({
                    RC: false,
                    RG: false,
                    Abogado: false,
                    CasaAzul: false,
                    Municipio: false
                });
                break;
        }
        setShowButton(true);
    }

    const cleanCampos = (Action) => {
        if (activeAction !== Action) {
            setActiveAction(Action);
            /**
             * Ocultar y limpiar campos
             * 1 - Electoral
             * 2 - Promocion
             * 3 - Social 
            */
            setIsElectoral({
                ...isElectoral,
                RC: false,
                RG: false,
                Abogado: false,
                BUNKER: false,
                Municipio: false,
                CoordEstaElectoral: false,
                SupEstatalElectoral: false,
                CoordMpalElectoral: false,
                CoordRegElectoral: false,
                Observador: false,
                CapturistaMpal: false
            });
            setOCR('');
            setVigenciaINE('');
            setSeccion('')
            setMpioSeccion('')
            setTieneAuto(1)
            setTieneExperiencia(1)
            setAceptaCuidarOtra(1)

            setIsPromocion({
                Regional: false,
                Municipal: false,
                Poligonal: false,
                Seccional: false,
                Manzanero: false
            });


            setSeccionMpio('')

            setManzanera({
                Seccion: '',
                Manzana: '',
                idSeccion: 0,
                idManzana: 0,
                ManzanaSeleccionada: 0
            })

            setPromocion({
                idMunicipio: 0,
                idRegion: 0,
                idPoligono: 0,
                idPoligonoMunicipio: 0,
                idSecional: ''
            })

            setIsSocial({
                ...isSocial,
                CoorGralSocial: false,
                CoorEstrSocial: false,
                CoorRegSocial: false,
                CoorMpalSocial: false,
                CoorEstatalSocial: false,
                CoordeEstructura: false
            });
        }


    }

    const validaRCs = () => {
        var svota = menu.Compromiso.SeccionVota;
        var ceros = '';
        if (svota.length < 4) {
            for (var x = 0; x < svota - 4; x++) {
                ceros = ceros + '0';
            }
            svota = ceros + svota
        }

        // if (OCR.length < 2) {

        //     notify.show(`Debe de ingresar OCR`, 'custom', 5000, {
        //         background: '#0857B6',
        //         fontSize: 16,
        //         text: '#FFFFFF'
        //     });
        //     return true;
        // }
        if (VigenciaINE < 2019) {
            setLoading(false);
            notify.show("Error: La vigencia de la INE debe de ser mayor al año 2019. Favor de renovar la INE", "error", 5000, {
                background: "#0857B6",
                fontSize: 16,
                text: "#FFFFFF",
            });
            return true;
        }
        if (Seccion.length === 0) {
            notify.show(`Error:El campo Sección es requerido`, 'custom', 5000, {
                background: '#0857B6',
                fontSize: 16,
                text: '#FFFFFF'
            });
            return true;
        }
    }
    const saveResponsabilidad = () => {
        var opcionTerritorial = false;
        var parametro = '';
        var Api = '';
        var data = '';
        if (menu.idCompromiso) {
            if (isElectoral.CoordRegElectoral) {
                parametro = {

                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 17,
                    valor: Promocion.idRegion
                };
                Api = 'setResponsabilidadPropuestaPromocion';
                opcionTerritorial = true;
            }
            if (isPromocion.CoordEstatalTerritorial) {

                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 97
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.results) {
                            notify.show(res.message, "success", 5000, {
                                background: "#18c97d",
                                fontSize: 24,
                                text: "#FFFFFF",
                            });
                            setLoading(false);
                            btnCancelar();
                        } else {
                            setLoading(false);
                            notify.show(
                                `Error al crear el registro: ${res.message}!!`,
                                "custom",
                                5000,
                                {
                                    background: "#0857B6",
                                    fontSize: 24,
                                    text: "#FFFFFF",
                                }
                            );
                        }
                        return true;
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                    });
            }
            if (isElectoral.CoordMpalElectoral) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 18,
                    valor: Promocion.idMunicipio
                };
                Api = 'setResponsabilidadPropuestaPromocion';
                opcionTerritorial = true;
            }
            if (isElectoral.SupEstatalElectoral) {

                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 98
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.success) {
                            if (res.results) {

                                notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                                    background: "#18c97d",
                                    fontSize: 24,
                                    text: "#FFFFFF",
                                });
                                setLoading(false);
                                btnCancelar();
                            } else {
                                setLoading(false);
                                notify.show(
                                    `Error al crear el registro: ${res.message}!!`,
                                    "custom",
                                    5000,
                                    {
                                        background: "#0857B6",
                                        fontSize: 24,
                                        text: "#FFFFFF",
                                    }
                                );
                            }
                            return true;
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error al agregar la Responsabilidad: ${e.message}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                    });
            }
            if (isElectoral.CoordEstaElectoral) {

                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 16
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.success) {
                            if (res.results) {
                                notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                                    background: "#18c97d",
                                    fontSize: 24,
                                    text: "#FFFFFF",
                                });
                                setLoading(false);
                                btnCancelar();
                            } else {
                                setLoading(false);
                                notify.show(
                                    `Error al crear el registro: ${res.message}!!`,
                                    "custom",
                                    5000,
                                    {
                                        background: "#0857B6",
                                        fontSize: 24,
                                        text: "#FFFFFF",
                                    }
                                );
                            }
                            return true;
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                    });
            }
            if (isElectoral.RC) {
                validaRCs();
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 2,
                    Seccion: Seccion,
                    Casilla: Casilla.label,
                    Propietario: Propietario.value,
                    idPartido: 1,
                    OCR: OCR,
                    VigenciaINE: VigenciaINE,
                    tieneExperiencia: tieneExperiencia,
                    tieneAuto: tieneAuto,
                    AceptaCuidarOtra: AceptaCuidarOtra,
                    UserOwned: Responsable.value
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.results) {
                            notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                                background: "#18c97d",
                                fontSize: 24,
                                text: "#FFFFFF",
                            });
                            setLoading(false);
                            setOCR('');
                            setVigenciaINE('');
                            btnCancelar();
                        } else {
                            setLoading(false);
                            notify.show(
                                `Error al crear el registro: ${res.message}!!`,
                                "custom",
                                5000,
                                {
                                    background: "#b60808",
                                    fontSize: 24,
                                    text: "#FFFFFF",
                                }
                            );
                        }
                        return true;
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                            background: "#b60808",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                    });
            }
            if (isElectoral.RG) {
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 3,
                    idDistrito: Distrito.value,
                    tieneExperiencia: tieneExperiencia,
                    tieneAuto: tieneAuto,
                    OCR: OCR,
                    VigenciaINE: VigenciaINE,
                    UserOwned: Responsable.value
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.results) {
                            notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                                background: "#18c97d",
                                fontSize: 24,
                                text: "#FFFFFF",
                            });
                            setOCR('');
                            setVigenciaINE('');
                            setLoading(false);
                            btnCancelar();
                        } else {
                            setLoading(false);
                            notify.show(
                                `Error al crear el registro: ${res.message}!!`,
                                "custom",
                                5000,
                                {
                                    background: "#0857B6",
                                    fontSize: 24,
                                    text: "#FFFFFF",
                                }
                            );
                        }
                        return true;
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                    });
            }
            if (isElectoral.Abogado) {
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 8,
                    idDistrito: Distrito.value,
                    tieneExperiencia: tieneExperiencia,
                    tieneAuto: tieneAuto,
                    OCR: OCR,
                    VigenciaINE: VigenciaINE,
                    UserOwned: Responsable.value
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.results) {
                            notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                                background: "#18c97d",
                                fontSize: 24,
                                text: "#FFFFFF",
                            });

                            setLoading(false);
                            btnCancelar();
                        } else {
                            setLoading(false);
                            notify.show(
                                `Error al crear el registro: ${res.message}!!`,
                                "custom",
                                5000,
                                {
                                    background: "#0857B6",
                                    fontSize: 24,
                                    text: "#FFFFFF",
                                }
                            );
                        }
                        return true;
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                    });
            }
            if (isElectoral.CasaAzul) {
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 15,
                    Calle: Casa.Calle,
                    NumExt: Casa.NumExt,
                    NumInt: Casa.NumInt,
                    Colonia: Casa.Colonia.label,
                    Latitud: Casa.Latitud,
                    Longitud: Casa.Longitud,
                    TelCasa: Casa.TelCasa,
                    TieneInter: Casa.TieneInter,
                    VelocidadInter: Casa.VelocidadInter,
                    TotalEquipos: Casa.TotalEquipos,
                    NombreDueno: Casa.NombreDueno,
                    CelularDueno: Casa.CelularDueno,
                    Referencia: Casa.Referencia,
                    idMunicipio: Casa.idMunicipio.value,
                    idPoligono: Casa.idPoligono.value,
                    Seccion: Casa.Seccion.value,
                    UserOwned: Responsable.value
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.results) {
                            notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                                background: "#18c97d",
                                fontSize: 24,
                                text: "#FFFFFF",
                            });

                            setLoading(false);
                            btnCancelar();
                        } else {
                            setLoading(false);
                            notify.show(
                                `Error al crear el registro: ${res.message}!!`,
                                "custom",
                                5000,
                                {
                                    background: "#0857B6",
                                    fontSize: 24,
                                    text: "#FFFFFF",
                                }
                            );
                        }
                        return true;
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                    });
            }
            if (isElectoral.Observador) {
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 9,
                    idDistrito: Distrito.value,
                    tieneExperiencia: tieneExperiencia,
                    tieneAuto: tieneAuto,
                    OCR: OCR,
                    VigenciaINE: VigenciaINE,
                    UserOwned: Responsable.value
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.results) {
                            notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                                background: "#18c97d",
                                fontSize: 24,
                                text: "#FFFFFF",
                            });
                            setOCR('');
                            setVigenciaINE('');
                            setLoading(false);
                            btnCancelar();
                        } else {
                            setLoading(false);
                            notify.show(
                                `Error al crear el registro: ${res.message}!!`,
                                "custom",
                                5000,
                                {
                                    background: "#0857B6",
                                    fontSize: 24,
                                    text: "#FFFFFF",
                                }
                            );
                        }
                        return true;
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                    });
            }
            if (isElectoral.PrimeroFila) {
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 40,
                    Seccion: Seccion,
                    Casilla: Casilla.label,
                    tieneAuto: tieneAuto,
                    UserOwned: Responsable.value
                }
               
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.results) {
                            notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                                background: "#18c97d",
                                fontSize: 24,
                                text: "#FFFFFF",
                            });
                            setOCR('');
                            setVigenciaINE('');
                            setLoading(false);
                            btnCancelar();
                        } else {
                            setLoading(false);
                            notify.show(
                                `Error al crear el registro: ${res.message}!!`,
                                "custom",
                                5000,
                                {
                                    background: "#0857B6",
                                    fontSize: 24,
                                    text: "#FFFFFF",
                                }
                            );
                        }
                        return true;
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                    });
            }
            if (isPromocion.Regional) {
                parametro = {

                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: idResponsabilidad,
                    valor: Promocion.idRegion
                };
                Api = 'setResponsabilidadPropuestaPromocion';
                opcionTerritorial = true;
            }
            if (isPromocion.Municipal) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: idResponsabilidad,
                    valor: Promocion.idMunicipio
                };
                Api = 'setResponsabilidadPropuestaPromocion';
                opcionTerritorial = true;
            }
            if (isPromocion.Poligonal) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: idResponsabilidad,
                    valor: Promocion.idPoligono,
                };
                Api = 'setResponsabilidadPropuestaPromocion';
                opcionTerritorial = true;
            }
            if (isPromocion.Seccional) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: idResponsabilidad,
                    valor: Promocion.idSecional,
                };
                Api = 'setResponsabilidadPropuestaPromocion';
                opcionTerritorial = true;
            }
            if (isPromocion.Manzanero) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 14,//Promocion.idResponsabilidad,
                    valor: Manzanera.ManzanaSeleccionada,
                    ClaveElector: menu.Compromiso.ClaveElector
                };
                Api = 'setResponsabilidadPropuestaPromocion';
                opcionTerritorial = true;

            }
            if (isSocial.CoorGralSocial) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: Estructura.idResponsabilidad.value,
                    ClaveReferencia: Estructura.Codigo
                };
                Api = 'setResponsableEstructuraSocialWeb';//setEstructuraSocialWeb
                opcionTerritorial = true;
            }
            if (isSocial.CoorEstrSocial) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idEstructura: Estructura.idEstructura.value,
                    idResponsabilidad: Estructura.idResponsabilidad.value,
                    ClaveReferencia: Estructura.Codigo
                };
                Api = 'setResponsableEstructuraSocialWeb';
                opcionTerritorial = true;
            }
            if (isSocial.CoorRegSocial) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idEstructura: Estructura.idEstructura.value,
                    idResponsabilidad: Estructura.idResponsabilidad.value,
                    idRegion: Estructura.idRegion.value,
                    ClaveReferencia: Estructura.Codigo
                };
                Api = 'setResponsableEstructuraSocialWeb';
                opcionTerritorial = true;
            }
            if (isSocial.CoorMpalSocial) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idEstructura: Estructura.idEstructura.value,
                    idResponsabilidad: Estructura.idResponsabilidad.value,
                    idMunicipio: Estructura.idMunicipio.value,
                    ClaveReferencia: Estructura.Codigo
                };
                Api = 'setResponsableEstructuraSocialWeb';
                opcionTerritorial = true;
            }
            if (isSocial.CoorEstatalSocial) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idEstructura: Estructura.idEstructura.value,
                    idResponsabilidad: Estructura.idResponsabilidad.value,
                    ClaveReferencia: Estructura.Codigo
                };
                Api = 'setResponsableEstructuraSocialWeb';
                opcionTerritorial = true;
            }
            if (isSocial.CoordeEstructura) {
                parametro = {
                    idCompromiso: menu.idCompromiso,
                    idEstructura: Estructura.idEstructura.value,
                    idResponsabilidad: Estructura.idResponsabilidad.value,
                    ClaveReferencia: Estructura.Codigo
                };
                Api = 'setResponsableEstructuraSocialWeb';
                opcionTerritorial = true;
            }
        }
        if (opcionTerritorial) {

            EjecutarApi(parametro, Api, token.access_token)
                .then(function (res) {

                    return res;
                })
                .then((res) => {
                    if (res.results) {
                        if (res.usuarioExiste === 0) {
                            setShowMensaje(true);
                            //btnCancelar();
                        } else {
                            notify.show('El registro se agrego con éxito!!', 'custom', 5000, {
                                background: '#0857B6',
                                fontSize: 14,
                                text: '#FFFFFF'
                            });
                            if (isPromocion.Manzanero) {
                                setCompromisoManzanera([...compromisoManzanera, { value: Manzanera.ManzanaSeleccionada, label: Manzanera.Manzana }]);
                            }
                            else {
                                btnCancelar();
                            }

                        }

                    }
                    else {
                        notify.show(res.message, "error", 5000, {
                            background: "#d61818",
                            text: "#FFFFFF",
                        });
                    }
                })
                .catch((e) => {
                    notify.show(`Error usuario ya tiene una propuesta asignada${e.error}!!!`, "error", 5000, {
                        background: "#d61818",
                        text: "#FFFFFF",
                    });

                });
        }

    }
    const getInfoOCR=()=>{
        //menu.Compromiso.ClaveElector
        EjecutarApi({ClaveElector:menu.Compromiso.ClaveElector}, 'getInformacionPropuesta', token.access_token)
                .then(function (res) {
                    return res;
                })
                .then((res) => {
                    if (res.results) {
                        setOCR(res.data.OCR)
                        setVigenciaINE(res.data.VigenciaINE)
                        setCatManzanas(res.data)
                    }
                    
                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error en la busqueda: ${e.message}`, "error", 5000, {
                        background: "#d61818",
                        text: "#FFFFFF",
                    });
                });
    }
    const handleElectoral = (e) => {
        if (menu.idCompromiso !== 0) {
            switch (e.label) {
                case 'RCS':

                    setIsElectoral({
                        ...isElectoral,
                        RC: true,
                        RG: false,
                        Abogado: false,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: false,
                        Observador: false,
                        PrimeroFila:false
                    });
                    getInfoOCR();
                    break;
                case 'RGS':
                    setIsElectoral({
                        RC: false,
                        RG: true,
                        Abogado: false,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: false,
                        Observador: false,
                        PrimeroFila:false
                    });
                    llenadoCatDistrito();
                    getInfoOCR();
                    break;
                case 'ABOGADOS':
                    setIsElectoral({
                        RC: false,
                        RG: false,
                        Abogado: true,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: false,
                        Observador: false,
                        PrimeroFila:false
                    });
                    llenadoCatDistrito();
                    getInfoOCR();
                    break;
                case 'CASA AZUL':
                    setIsElectoral({
                        RC: false,
                        RG: false,
                        Abogado: false,
                        CasaAzul: true,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: false,
                        Observador: false,
                        PrimeroFila:false
                    });
                    llenadoCatMunicipio();
                    break;
                case 'OBSERVADOR':
                    setIsElectoral({
                        RC: false,
                        RG: false,
                        Abogado: false,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: false,
                        Observador: true,
                        PrimeroFila:false
                    });
                    llenadoCatDistrito();
                    break;
                case 'PRIMERO EN FILA':
                    setIsElectoral({
                        RC: false,
                        RG: false,
                        Abogado: false,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: false,
                        Observador: false,
                        PrimeroFila:true
                    });
                    llenadoCatDistrito();
                    break;
                case 'COORDINADOR ESTATAL ELECTORAL':
                    setIsElectoral({
                        RC: false,
                        RG: false,
                        Abogado: false,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: true,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: false,
                        PrimeroFila:false
                    });

                    break;
                case 'SUPERVISOR ESTATAL ELECTORAL':
                    setIsElectoral({
                        RC: false,
                        RG: false,
                        Abogado: false,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: true,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: false,
                        PrimeroFila:false
                    });

                    break;
                case 'COORDINADOR MUNICIPAL ELECTORAL':
                    setIsElectoral({
                        RC: false,
                        RG: false,
                        Abogado: false,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: true,
                        CoordRegElectoral: false
                    });
                    llenadoCatMunicipio();
                    break;
                case 'COORDINADOR REGIONAL ELECTORAL':
                    setIsElectoral({
                        RC: false,
                        RG: false,
                        Abogado: false,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: true,
                        PrimeroFila:false
                    });
                    llenadoCatRegion();
                    break;
                default:
                    setIsElectoral({
                        RC: false,
                        RG: false,
                        Abogado: false,
                        CasaAzul: false,
                        Municipio: false,
                        CoordEstaElectoral: false,
                        SupEstatalElectoral: false,
                        CoordMpalElectoral: false,
                        CoordRegElectoral: false,
                        PrimeroFila:false
                    });
                    break;
            }
        }
        else
            switch (e.label) {
                case 'RCs':
                    history.push("/EPropuestaRCs");
                    break;
                case 'RGs':
                    history.push("/EPropuestaRGs");
                    break;
                case 'Abogado':
                    history.push("/EPropuestaA");
                    break;
                case 'Casa Azul':
                    history.push("/EPropuestaCA");
                    break;
                case 'Abogados':
                    history.push("/EPropuestaA");
                    break;
                default:
                    break;
            }
        setShowButton(true);
    }
    const handleMpioCasaChanged = (e) => {
        var parametro = { "filtered": [{ "id": "cat_poligonos.idMunicipioReportes", "value": e.value }] }
        setCasa({ ...Casa, idMunicipio: e });
        EjecutarApi(parametro, 'getPoligonosByMunicipio', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setCatPoligonoCasa(res.data.map((item) => {
                        return {
                            label: item.Poligono,
                            value: item.id
                        }
                    }));
                }
            })
            .catch((e) => {

                notify.show(`Error en la búsqueda de Municipio Vive! ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });

        EjecutarApi({ Municipio: e.label }, `getLocalidad`, token.access_token, "POST")
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.result) {

                    setCatCasaLocalidad(res.data.localidades);
                    setCatCasaColonia(res.data.colonias);
                }
            })
            .catch((e) => {

                notify.show(`Error en la búsqueda de Municipio Vive! ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }
    const LlenadoPoligonoByMpio = (e) => {
        //idPoligonoMunicipio
        setPromocion({ ...Promocion, idPoligonoMunicipio: e.value });
        var parametro = { "filtered": [{ "id": "cat_poligonos.idMunicipioReportes", "value": e.value }] }
        EjecutarApi(parametro, `getPoligonosByMunicipio`, token.access_token, "POST")
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setCatPoligonoCasa(res.data.map((item) => {
                        return {
                            label: item.Poligono,
                            value: item.id
                        }
                    }));
                }
            })
            .catch((e) => {
                notify.show(`Error en la búsqueda del Polígono! ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }

    const handleRegion = (e) => {
        setPromocion({ ...Promocion, idRegion: e.value })
        if (menu.idCompromiso === 0) {
            setMenuR({ ...MenuR, NvoPromocion: true });
            //setResPromocion('Regional');
        }
    }

    const onHide = () => {
        setShowResponsable(false);
        setisModal(false);
    }
    const handResponsable = (param) => {
        //setResponsable({value:param.id,label:`${param.Cargo}${'-'}${param.NombrePropone}`})
    }
    const handleShowSocial = () => {
        cleanCampos("Social")
        setMenuR({ ...MenuR, AddResponsabilidad: false });
        setShowElectoral(false);
        setShowPromocion(false);
        setShowSocial(true);

    }
    const llenadoSocial = () => {

        EjecutarApi({}, "getEstructuras", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    if (res.data) {
                        setCatEstructura(res.data.map((prop) => {
                            return {
                                value: prop.value,
                                label: prop.label
                            };
                        })
                        );
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(
                    `Error: ${e.error}`,
                    "custom",
                    5000,
                    {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    }
                );


            });
    }
    const handleSelectSocial = (e) => {
        if (menu.idCompromiso !== 0) {
            switch (e.value) {
                case 30:
                    setIsSocial({
                        ...isSocial,
                        CoorGralSocial: true,
                        CoorEstrSocial: false,
                        CoorRegSocial: false,
                        CoorMpalSocial: false,
                        CoorEstatalSocial: false,
                        CoordeEstructura: false
                    });
                    setEstructura({ ...Estructura, idResponsabilidad: e, Codigo: codigoAleatorio(6) })
                    break;
                case 31:
                    setIsSocial({
                        ...isSocial,
                        CoorGralSocial: false,
                        CoorEstrSocial: true,
                        CoorRegSocial: false,
                        CoorMpalSocial: false,
                        CoorEstatalSocial: false,
                        CoordeEstructura: false
                    });
                    llenadoSocial();
                    setEstructura({ ...Estructura, idResponsabilidad: e, Codigo: codigoAleatorio(6) });
                    break;
                case 32:
                    setIsSocial({
                        ...isSocial,
                        CoorGralSocial: false,
                        CoorEstrSocial: false,
                        CoorRegSocial: true,
                        CoorMpalSocial: false,
                        CoorEstatalSocial: false,
                        CoordeEstructura: false
                    });
                    llenadoSocial();
                    llenadoCatRegion();
                    setEstructura({ ...Estructura, idResponsabilidad: e, Codigo: codigoAleatorio(6) });
                    break;
                case 33:
                    setIsSocial({
                        ...isSocial,
                        CoorGralSocial: false,
                        CoorEstrSocial: false,
                        CoorRegSocial: false,
                        CoorMpalSocial: true,
                        CoorEstatalSocial: false,
                        CoordeEstructura: false
                    });
                    llenadoSocial();
                    llenadoCatMunicipio();
                    setEstructura({ ...Estructura, idResponsabilidad: e, Codigo: codigoAleatorio(6) });
                    break;
                case 34:
                    setIsSocial({
                        ...isSocial,
                        CoorGralSocial: false,
                        CoorEstrSocial: false,
                        CoorRegSocial: false,
                        CoorMpalSocial: false,
                        CoorEstatalSocial: true,
                        CoordeEstructura: false
                    });
                    llenadoSocial();
                    setEstructura({ ...Estructura, idResponsabilidad: e, Codigo: codigoAleatorio(6) });
                    break;
                case 35:
                    setIsSocial({
                        ...isSocial,
                        CoorGralSocial: false,
                        CoorEstrSocial: false,
                        CoorRegSocial: false,
                        CoorMpalSocial: false,
                        CoorEstatalSocial: false,
                        CoordeEstructura: true
                    });
                    llenadoSocial();
                    setEstructura({ ...Estructura, idResponsabilidad: e, Codigo: codigoAleatorio(6) });
                    break;
                default:
                    setIsSocial({
                        ...isSocial,
                        CoorGralSocial: false,
                        CoorEstrSocial: false,
                        CoorRegSocial: false,
                        CoorMpalSocial: false,
                        CoorEstatalSocial: false,
                        CoordeEstructura: false
                    });
                    break;
            }
        }
        setShowButton(true);
    }
    const codigoAleatorio = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    const MensajeAlerta = (
        <>
            <ReactBSAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="¡Usuario creado Correctamente!"
                onConfirm={() => btnCancelar()}
                onCancel={() => btnCancelar()}
                confirmBtnBsStyle="info"
            >
                Usuario: <b>{menu.Compromiso.Celular}</b><br />
                Password:<b>{menu.Compromiso.Celular}</b>{" "}

            </ReactBSAlert>
        </>

    );
    const EliminarResponsabilidad = (idUser, Accion) => {
        var json = {};
        var parametro = {}
        if (Accion) {
            parametro = { idCompromiso: menu.idCompromiso, idManzana: idUser.value };
        } else {
            parametro = { idCompromiso: menu.idCompromiso };
        }


        EjecutarApi(parametro, 'eliminarResponsablesWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    json = res
                    notify.show(res.message, "success", 5000, {
                        background: "#d61818",
                        text: "#FFFFFF",
                    });
                    if (Accion) {
                        var _man = compromisoManzanera.filter(item => {
                            if (item.value !== idUser.value) {
                                return (item)
                            }
                        });
                        if (_man.length > 0) {
                            setCompromisoManzanera(_man);
                        }
                        else {
                            setCompromisoManzanera([]);
                        }
                    } else {
                        setCompromisoResponsabilidad({ value: 1, label: 'Sin Responsabilidad', valor: 'Sin Responsabilidad' })

                    }

                }
                else {
                    notify.show(`Error:${res.message}`, "error", 5000, {
                        background: "#d61818",
                        text: "#FFFFFF",
                    });
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
        return json;
    }
    const btnValidarManzana = () => {
        //getManzanasPorSeccion
        var parametro = { "Seccion": Manzanera.Seccion, "Manzana": Manzanera.Manzana }
        if (Manzanera.Seccion.length !== 0) {
            EjecutarApi(parametro, 'getManzanasPorSeccion', token.access_token)
                .then(function (res) {
                    return res;
                })
                .then((res) => {
                    if (res.results) {
                        setCatManzanas(res.data)
                    }
                    else {
                        notify.show('Dato no encontrado', "error", 5000, {
                            background: "#d61818",
                            text: "#FFFFFF",
                        });
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error en la busqueda: ${e.message}`, "error", 5000, {
                        background: "#d61818",
                        text: "#FFFFFF",
                    });
                });
        }
        else {
            notify.show(`Debe Colocar una Sección`, "warning", 5000, {
                background: "yellow",
                text: "#FFFFFF",
            });
        }

    }

    const handleBusarResponsble = () => {
        setisModal(true)

    }
    const UserOwned = (e) => {
        setResponsable({ ...Responsable, label: e.NombreCompleto, value: e.id })
        onHide();
    }
   
    const ElectoraTipo =(e)=>{

        setCapElectoral({...CapElectoral, Tipo:{label:e.label,value:e.value}});

        if(e.value==3)
        {
            llenadoCatMunicipio();
            setCatRegion([]);
            setCapElectoral({...CapElectoral, Region:{value:-1,label:'Seleccionar...'}
            ,Tipo:{label:e.label,value:e.value}})
        }
        if(e.value==2)
        {
            llenadoCatRegion();
            setCapElectoral({...CapElectoral, Municipio:{value:-1,label:'Seleccionar...'},
            Tipo:{label:e.label,value:e.value}})
        }
        if(e.value==1)
        {
            
            setCapElectoral({...CapElectoral,  Tipo:{label:e.label,value:e.value}})
        }
        
    }
    const PromocionTipo =(e)=>{
        
        setCapTerritorial({...capTerritorial, Tipo:{label:e.label,value:e.value}});

        if(e.value==3)
        {
            llenadoCatMunicipio();
            setCatRegion([]);
            setCapTerritorial({...capTerritorial, Region:{value:-1,label:'Seleccionar...'}
            ,Tipo:{label:e.label,value:e.value}})
        }
        if(e.value==2)
        {
            llenadoCatRegion();
            setCapTerritorial({...capTerritorial, Municipio:{value:-1,label:'Seleccionar...'},
            Tipo:{label:e.label,value:e.value}})
        }
        if(e.value==1)
        {
            
            setCapTerritorial({...capTerritorial,  Tipo:{label:e.label,value:e.value}})
        }
        
    }
    
    const setCapturistaElectoral =()=>{
        //if(menu.)
        if(Responsable.value===0)
        {
            notify.show(`Debe seleccionar el responsable de captura`, 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF'
            });
            return;
        }
        if(CapElectoral.Tipo.value===0)
        {
            notify.show(`Debe seleccionar el tipo de responsabilidad para la captura`, 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF'
            });
            return;
        }

        if(menu.Compromiso.TieneUsuario>0)
        {
            var param ={
                "tipo": "Electoral",
                "ClaveElector": menu.Compromiso.ClaveElector,
                "idResponsabilidad": CapElectoral.Tipo.value===1?16:CapElectoral.Tipo.value===2?17:CapElectoral.Tipo.value===3?18:0,
                "Responsabilidades": CapElectoral.Responsabilidad,
                "ResponsabilidadDataID":CapElectoral.Tipo.value===3?CapElectoral.Municipio.value:CapElectoral.Tipo.value===2?CapElectoral.Region.value:'',
                "idResponsable":Responsable.value,
                "idAutoriza":token.user.id
            };
            saveCapturista(param);
        }
        else {
            notify.show(
               'Error: El voluntario no tiene cuenta de usuario.',
                "error",
                5000,
                {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                }
            );
        }
    }
    const setCapturistaPromocion=()=>{
        //if(menu.)
        if(Responsable.value===0)
        {
            notify.show(`Debe seleccionar el responsable de captura`, 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF'
            });
            return;
        }
        if(capTerritorial.Tipo.value===0)
        {
            notify.show(`Debe seleccionar el tipo de responsabilidad para la captura`, 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF'
            });
            return;
        }

        if(menu.Compromiso.TieneUsuario>0)
        {
            var param ={
                "tipo": "Promocion",
                "ClaveElector": menu.Compromiso.ClaveElector,
                "idResponsabilidad": capTerritorial.Tipo.value===1?97:capTerritorial.Tipo.value===2?10:capTerritorial.Tipo.value===3?11:0,
                "Responsabilidades": capTerritorial.Responsabilidad,
                "ResponsabilidadDataID":capTerritorial.Tipo.value===3?capTerritorial.Municipio.value:capTerritorial.Tipo.value===2?capTerritorial.Region.value:'',
                "idResponsable":Responsable.value,
                "idAutoriza":token.user.id
            };
            saveCapturista(param);
        }
        else {
            notify.show(
               'Error: El voluntario no tiene cuenta de usuario.',
                "error",
                5000,
                {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                }
            );
        }
    }
    const saveCapturista =(parametro)=>{
        EjecutarApi(parametro, "registrarCapturista", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    notify.show(
                        res.message,
                        "custom",
                        5000,
                        {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                        }
                    ); 
                }
                else{
                    notify.show(
                        res.message,
                        "error",
                        5000,
                        {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                        }
                    ); 
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(
                    `Error: ${e.error}`,
                    "custom",
                    5000,
                    {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    }
                );


            });  
    }
    const EliminarResponsablidad = (item, tipo,e) => {
		e.preventDefault();
		swal({
			title: "¿Está Seguro?",
			text: `Que desea eliminar la responsabilidad de ${menu.Compromiso.Nombre}, ${tipo?tipo:CompromisoResponsabilidad.label}  `,
			icon: "warning",
			buttons: true,
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancelar",
					value: null,
					visible: true,
					className: "btn-danger",
					closeModal: true,
				},
				confirm: {
					text: "Si, eliminar!",
					value: true,
					visible: true,
					className: "btn-success",
					closeModal: true
				}
			}
		})
			.then((willDelete) => {
				if (willDelete) {
					EliminarResponsabilidad(item,tipo);
				} else {

				}
			});
	}
    const handleShowCapturista=(e)=>{
        
        if(token.user.Responsabilidad.value==99)
        {
            setIsCapturista({
                Electoral:true,
                Promocion:true,
                Social:true,
            });
        }
        else if(token.user.Responsabilidad.value==16)
        {
            setIsCapturista({
                Electoral:true,
                Promocion:false,
                Social:false,
            });
        }
    }
    return (
        <>
            <Row>
                <Col sm='4'>
                    <Card>
                        <CardTitle >
                            <Row>
                                <Col sm='12'>
                                    <Button name="btnRegresar" size="sm" type="button" className="success" color='success' onClick={btnCancelar}>Regresar</Button>
                                </Col>
                            </Row>

                        </CardTitle>
                        <CardBody>
                            <Col sm="12" className="center text-left-side">
                                <Row className='text-center  text-align-center-center'>
                                    <Col sm='12'>
                                        <h6 >Datos de la Propuesta</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label id='idcnombre' className='text-muted'>Nombre: <strong>{menu.Compromiso ? menu.Compromiso.Nombre : ''}</strong></Label>
                                </Row>
                                <Row>
                                    <Label id='idccelular' className='text-muted'>Clave Elector: <strong>{menu.Compromiso ? menu.Compromiso.ClaveElector : ''}</strong></Label>
                                </Row>
                                
                                <Row>
                                    <Label id='idccelular' className='text-muted'>Celúlar: <strong>{menu.Compromiso ? menu.Compromiso.Celular : ''}</strong></Label>
                                </Row>

                                {IsInfoAdd && (<>
                                    <Row>
                                        <Label id='capturo' className='text-muted'>Asignó Responsabilidad: <strong>{menu.Compromiso ? menu.Compromiso.AsignoResponsabilidad : ''}</strong></Label>
                                    </Row>
                                    <Row>
                                        <Label id='capturo' className='text-muted'>Fecha Asignó Responsabilidad: <strong>{menu.Compromiso ? menu.Compromiso.FechaAsignoResponsabilidad : ''}</strong></Label>
                                    </Row>

                                </>)}
                                <Row>
                                    <Label id='idcregion' className='text-muted'>Región: <strong>{menu.Compromiso ? menu.Compromiso.Region : ''}</strong></Label>
                                </Row>
                                <Row>
                                    <Label id='idcmpio' className='text-muted'>Municipio: <strong>{menu.Compromiso ? menu.Compromiso.Municipio.label : ''}</strong></Label>
                                </Row>
                                <Row>
                                    <Label id='idcseccion' className='text-muted'>Polígono: <strong>{menu.Compromiso ? menu.Compromiso.Poligono.label : ''}</strong></Label>
                                </Row>
                                <Row>
                                    <Label id='idcseccion' className='text-muted'>Sección Vota: <strong>{menu.Compromiso ? menu.Compromiso.SeccionVota : ''}</strong></Label>
                                </Row>
                                <Row>
                                    <Label id='idcdireccion' className='text-muted'>Dirección: <strong>{menu.Compromiso ? menu.Compromiso.Domicilio : ''}</strong></Label>
                                </Row>
                                <Row>
                                    <Label id='idccolonia' className='text-muted'>Colonia: <strong>{menu.Compromiso ? menu.Compromiso.ColoniaVive : ''}</strong></Label>
                                </Row>
                               
                                
                                <Row>
                                    <Label id='idcregion' className='text-muted'>Responsabilidad:</Label>
                                </Row>
                                <Row className='text-sm-left'>

                                    {compromisoManzanera.length > 0 ? <>
                                        {compromisoManzanera.map((item) => (
                                            <>
                                                <Col sm='12'>
                                                    {menu.Menu.Eliminar === 1 &&
                                                        (<>
                                                            <Button className='btn-link'
                                                                name='tooltip476609793'
                                                                color="danger"
                                                                id='btnElimina'
                                                                size="sm"
                                                                onClick={(e) => EliminarResponsablidad(item, 'manzana',e)}
                                                            > <i class="fa fa-trash-o fa-lg"></i>
                                                                            </Button>
                                                        </>)}
                                                    <strong className='text-muted'>Manzana: {item.label}
                                                    </strong>

                                                </Col>
                                            </>
                                        ))}
                                    </> : <>

                                            {menu.Menu.Eliminar === 1 && CompromisoResponsabilidad.value > 1 ?
                                                (<>
                                                    <Col sm="12" xs="12">
                                                        <Button className='btn-link text-sm-left'
                                                            name='tooltip476609793'
                                                            color="danger"
                                                            id='btnElimina'
                                                            size="sm"
                                                            onClick={(e) => EliminarResponsablidad(null,null,e)}
                                                        > <i class="fa fa-trash-o fa-lg"></i>
                                                            </Button>
                                                        <Label className='text-muted'>
                                                            <strong>{CompromisoResponsabilidad ? CompromisoResponsabilidad.label : ''}</strong>
                                                        </Label>
                                                    </Col>
                                                </>) : (
                                                    <Col sm="12" xs="12">
                                                        <Label className='text-muted'>
                                                            <strong>{CompromisoResponsabilidad ? CompromisoResponsabilidad.label : ''}</strong>
                                                        </Label>
                                                    </Col>
                                                )}

                                        </>
                                    }
                                </Row>
                                {/*menu.Compromiso.Propuesta.value > 1 ? <>
                                    <Row className=' align-content-sm-center'>
                                        <Col>
                                            {menu.Menu.Eliminar === 1 && menu.Compromiso.Propuesta.value > 1 &&
                                                (<>
                                                    <Button className='btn-link text-sm-right'
                                                        name='tooltip476609793'
                                                        color="danger"
                                                        id='btnElimina'
                                                        size="sm"
                                                        onClick={{}}
                                                    > X
                                                                            </Button>
                                                </>)}
                                            <Label className='text-muted'>Propuesta: <strong>{menu.Compromiso.Propuesta.label}</strong></Label>
                                        </Col>
                                    </Row>
                                </> : <></>
                                */}
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm='8'>
                    <Card>
                        <CardTitle >
                            <Row className='text-align-bottom-center text-center' >
                                <Col>
                                    <h6>Agregar Propuestas de Responsabilidad</h6>
                                </Col>
                            </Row>
                        </CardTitle>
                        <CardBody>
                            {loading && <MetroSpinner size={30} color="#0000FF" loading={loading} />}

                            <Row md='12'>
                                <Col md="12" className="center text-center">
                                    Tipo de Responsabilidad<br />
                                    <ButtonGroup style={{ backgroundColor: '#FFF' }}>
                                        {menu.Menu.Electoral === 1 && (
                                            <Button
                                                className="btn-round"
                                                color='info'
                                                outline
                                                type="button"
                                                onClick={() => handleShowElectoral()}
                                            >
                                                Electoral
                                            </Button>)}
                                        {menu.Menu.Social === 1 && (
                                            <Button
                                                className="btn-round"
                                                color='info'
                                                outline
                                                type="button"
                                                onClick={() => handleShowSocial()}
                                            >
                                                Social
                                            </Button>)}
                                        {menu.Menu.Territorial === 1 && (
                                            <Button
                                                className="btn-round"
                                                color='info'
                                                outline
                                                type="button"
                                                onClick={() => handleShowPromocion()}
                                            >
                                                Promoción
                                            </Button>)}
                                        { isCapturaPermitida && (
                                            <Button
                                                className="btn-round"
                                                color='info'
                                                outline
                                                type="button"
                                                onClick={() => handleShowCapturista()}
                                            >
                                                Capturista
                                            </Button>)}
                                        
                                    </ButtonGroup>
                                </Col>
                            </Row>


                            {showElectoral ?
                                <>
                                    <Row>
                                        <br />
                                        <Col sm="6">
                                            <Label for="idRespon"> Función Electoral:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idRespon"
                                                placeholder="Seleccione una Responsabilidad..."
                                                options={catResponsabilidad}
                                                onChange={(e) => handleElectoral(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            />
                                        </Col>
                                    </Row>
                                </> : <></>
                            }
                            {showPromocion ?
                                <Row>
                                    <br />
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="idPromocion">Función Promoción:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idPromocion"
                                                placeholder="Seleccione..."
                                                options={catPromocion}
                                                onChange={(e) => { handlePromocion(e); }}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            >
                                            </Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                : <></>
                            }
                            {showSocial ?
                                <Row>
                                    <br />
                                    <Col sm="12">
                                        <FormGroup>
                                            <Label for="idPromocion">Función Social:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idSocial"
                                                placeholder="Seleccione..."
                                                options={catSocial}
                                                onChange={(e) => { handleSelectSocial(e); }}

                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            >
                                            </Select>
                                        </FormGroup>
                                    </Col>
                                </Row> : <></>
                            }
                            {isElectoral.RC ? <>

                                <Row><br /></Row>
                                {_menu.Capturista === 1 && (<>
                                    <Row>
                                        <FormGroup className="col-md-10 text-warning">
                                            <Label for="Responsable">
                                                <span className="text-danger">*</span>Responsable:
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    value={Responsable.label}
                                                    disabled
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        style={{ margin: '0px 1px' }}
                                                        color="warning"
                                                        onClick={() => handleBusarResponsble()}
                                                    ><i className="nc-icon nc-zoom-split" /></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Row>
                                </>)}
                                <Row>
                                    <Col className="col-md-3">
                                        <FormGroup >
                                            <Label for="ORC"><span className="text-danger">*</span>OCR</Label>
                                            <Input
                                                type="text"
                                                id="orc"
                                                value={OCR}
                                                onChange={(event) => setOCR(event.target.value)}
                                                maxLength="18"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-md-3">
                                        <FormGroup >
                                            <Label for="Fechacumple"><span className="text-danger">*</span>Vigencia Clave Elector:</Label>
                                            <ReactDatetime
                                                id="Fechacumple"
                                                name="Fechacumple"
                                                dateFormat="YYYY"
                                                timeFormat={false}
                                                closeOnSelect
                                                value={VigenciaINE}
                                                onChange={(e) =>
                                                    setVigenciaINE(moment(e._d).format("YYYY"))
                                                }
                                                inputProps={{ className: "form-control" }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <FormGroup className="col-md-2 text-warning" >
                                        <Label for="seccion"><span className="text-danger">*</span>Sección:</Label>
                                        <InputGroup>
                                            <Input id="seccion" maxLength='4' value={Seccion}
                                                onChange={(e) => { setSeccion(e.target.value) }} ></Input>
                                            <InputGroupAddon addonType="append">
                                                <Button id="btnSeccion" color='warning' style={{ margin: '0px 1px' }}
                                                    onClick={btnValidaSeccion} >
                                                    <i className="fa fa-search fa-lg"></i></Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className="col-md-3" >
                                        <Label for="Mpioseccion">Municipio:</Label>
                                        <Input id='Mpioseccion' value={MpioSeccion} disabled></Input>
                                    </FormGroup>

                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup sm="4">
                                            <Label for="casilla"><span className="text-danger">*</span>Casilla:</Label>
                                            <Select className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="casilla"
                                                placeholder="Debe validar la sección"
                                                options={catCasilla}
                                                onChange={(e) => setCasilla(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup sm="3">
                                            <Label for="propietario"><span className="text-danger">*</span>Propietario:</Label>
                                            <Select className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="propietario"
                                                placeholder="Debe validar la casilla"
                                                options={catPropietario}
                                                onChange={(e) => setPropietario(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="propietario"><span className="text-danger">*</span>¿Has sido funcionario o representante de casilla?</Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <br />
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={tieneExperiencia} onChange={() => setTieneExperiencia(1)} />
                                                <span className="form-check-sign" />Si
                                                </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={!tieneExperiencia} onClick={() => setTieneExperiencia(0)} />
                                                <span className="form-check-sign" />No
                                                </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup sm="3">
                                            <Label for="rauto"><span className="text-danger">*</span>¿Tiene Auto?</Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <br />
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={tieneAuto} onChange={() => setTieneAuto(1)} />
                                                <span className="form-check-sign" />Si
                                                </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={!tieneAuto} onChange={() => setTieneAuto(0)} />
                                                <span className="form-check-sign" />No
                                                </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup sm="3">
                                            <Label for="rCasilla"><span className="text-danger">*</span>¿Acepta cuidar otra casilla?</Label>
                                            <FormGroup check inline>
                                                <br />
                                                <Label check>
                                                    <Input type="checkbox" name='chek1' checked={AceptaCuidarOtra} onChange={() => setAceptaCuidarOtra(1)} />
                                                    <span className="form-check-sign" />Si
                                                </Label>
                                            </FormGroup>{" "}
                                            <FormGroup check inline>
                                                <Label check>
                                                    <Input type="checkbox" name='chek1' checked={!AceptaCuidarOtra} onChange={() => setAceptaCuidarOtra(0)} />
                                                    <span className="form-check-sign" />No
                                                    </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </> : <></>}
                            {isElectoral.RG ? <>
                                {_menu.Capturista === 1 && (<>
                                    <Row>
                                        <FormGroup className="col-md-10 text-warning">
                                            <Label for="Responsable">
                                                <span className="text-danger">*</span>Responsable:
                                                </Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    value={Responsable.label}
                                                    disabled
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        style={{ margin: '0px 1px' }}
                                                        color="warning"
                                                        onClick={() => handleBusarResponsble()}
                                                    ><i className="nc-icon nc-zoom-split" /></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Row>
                                </>)}
                                <Row>
                                    <Col md="4">
                                        <FormGroup sm="4">
                                            <Label for="ORC"><span className="text-danger">*</span>Distrito Federal: </Label>
                                            <Select className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="partido"
                                                placeholder="Seleccione..."
                                                options={catDistrito}
                                                onChange={(e) => setDistrito(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormGroup >
                                            <Label for="ORC"><span className="text-danger">*</span>OCR</Label>
                                            <Input
                                                type="text"
                                                id="orc"
                                                value={OCR}
                                                onChange={(event) => setOCR(event.target.value)}
                                                maxLength="18"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormGroup >
                                            <Label for="Fechacumple"><span className="text-danger">*</span>Vigencia Clave Elector:</Label>
                                            <ReactDatetime
                                                id="Fechacumple"
                                                name="Fechacumple"
                                                dateFormat="YYYY"
                                                timeFormat={false}
                                                closeOnSelect
                                                value={VigenciaINE}
                                                onChange={(e) =>
                                                    setVigenciaINE(moment(e._d).format("YYYY"))
                                                }
                                                inputProps={{ className: "form-control" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="propietario"><span className="text-danger">*</span>¿Has sido funcionario o representante de casilla?</Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <br />
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={tieneExperiencia} onChange={() => setTieneExperiencia(1)} />
                                                <span className="form-check-sign" />Si
                                                </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={!tieneExperiencia} onChange={() => setTieneExperiencia(0)} />
                                                <span className="form-check-sign" />No
                                                </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup sm="3">
                                            <Label for="rauto"><span className="text-danger">*</span>¿Tiene Auto?</Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <br />
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={tieneAuto} onChange={() => setTieneAuto(1)} />
                                                <span className="form-check-sign" />Si
                                                    </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={!tieneAuto} onChange={() => setTieneAuto(0)} />
                                                <span className="form-check-sign" />No
                                                    </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                            </> : <></>}
                            {isElectoral.Abogado ? <>
                                {_menu.Capturista === 1 && (<>
                                    <Row>
                                        <FormGroup className="col-md-10 text-warning">
                                            <Label for="Responsable">
                                                <span className="text-danger">*</span>Responsable:
                                                </Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    value={Responsable.label}
                                                    disabled
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        style={{ margin: '0px 1px' }}
                                                        color="warning"
                                                        onClick={() => handleBusarResponsble()}
                                                    ><i className="nc-icon nc-zoom-split" /></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Row>
                                </>)}
                                <Row>
                                    <Col md="4">
                                        <FormGroup sm="4">
                                            <Label for="ORC"><span className="text-danger">*</span>Distrito Federal: </Label>
                                            <Select className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="partido"
                                                placeholder="Seleccione..."
                                                options={catDistrito}
                                                onChange={(e) => setDistrito(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormGroup >
                                            <Label for="ORC"><span className="text-danger">*</span>OCR</Label>
                                            <Input
                                                type="text"
                                                id="orc"
                                                value={OCR}
                                                onChange={(event) => setOCR(event.target.value)}
                                                maxLength="18"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormGroup >
                                            <Label for="Fechacumple"><span className="text-danger">*</span>Vigencia Clave Elector:</Label>
                                            <ReactDatetime
                                                id="Fechacumple"
                                                name="Fechacumple"
                                                dateFormat="YYYY"
                                                timeFormat={false}
                                                closeOnSelect
                                                value={VigenciaINE}
                                                onChange={(e) =>
                                                    setVigenciaINE(moment(e._d).format("YYYY"))
                                                }
                                                inputProps={{ className: "form-control" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="propietario"><span className="text-danger">*</span>¿Has sido funcionario o representante de casilla?</Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <br />
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={tieneExperiencia} onChange={() => setTieneExperiencia(1)} />
                                                <span className="form-check-sign" />Si
                                                </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={!tieneExperiencia} onChange={() => setTieneExperiencia(0)} />
                                                <span className="form-check-sign" />No
                                                </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup sm="3">
                                            <Label for="rauto"><span className="text-danger">*</span>¿Tiene Auto?</Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <br />
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={tieneAuto} onChange={() => setTieneAuto(1)} />
                                                <span className="form-check-sign" />Si
                                                    </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={!tieneAuto} onChange={() => setTieneAuto(0)} />
                                                <span className="form-check-sign" />No
                                                    </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                            </> : <></>}
                            {isElectoral.CasaAzul ? <>
                                {_menu.Capturista === 1 && (<>
                                    <Row>
                                        <FormGroup className="col-md-10 text-warning">
                                            <Label for="Responsable">
                                                <span className="text-danger">*</span>Responsable:
                                                </Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    value={Responsable.label}
                                                    disabled
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        style={{ margin: '0px 1px' }}
                                                        color="warning"
                                                        onClick={() => handleBusarResponsble()}
                                                    ><i className="nc-icon nc-zoom-split" /></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Row>
                                </>)}
                                <Row>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="selecMpiocasa"><span className="text-danger">*</span>Municipio:</Label>
                                            <Select options={catMunicipioCasa}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={Casa.idMunicipio}
                                                onChange={(e) => handleMpioCasaChanged(e)}
                                                id="selecMpiocasa"
                                                placeholder="Municipio"
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."} />

                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="selecPolcasa"><span className="text-danger">*</span>Polígono:</Label>
                                            <Select options={catPoligonoCasa}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={Casa.idPoligono}
                                                onChange={(e) => handlePoligoCasaChanged(e)}
                                                id="selecPolcasa"
                                                placeholder="Polígono" isLoading={loading}
                                                loadingMessage={"...Filtrando..."} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="seccioncasa"><span className="text-danger">*</span>Sección:</Label>
                                            <Select options={catSeccionCasa}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={Casa.Seccion}
                                                onChange={(e) => setCasa({ ...Casa, Seccion: e })}
                                                id="seccioncasa"
                                                placeholder="Sección"
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="casaCalle"><span className="text-danger">*</span>Calle:</Label>
                                            <Input id="casaCalle" value={Casa.Calle} onChange={(e) => setCasa({ ...Casa, Calle: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Label for="casaExterior"><span className="text-danger">*</span>Número Exterior:</Label>
                                            <Input id="casaExterior" value={Casa.NunExt} onChange={(e) => setCasa({ ...Casa, NumExt: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Label for="casainterio"><span className="text-danger">*</span>Número Interior:</Label>
                                            <Input id="casainterio" value={Casa.NumInt} onChange={(e) => setCasa({ ...Casa, NumInt: e.target.value })} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="casaLocalidad"><span className="text-danger">*</span>Localidad:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                options={catCasaLocalidad}
                                                id="casaLocalidad"
                                                onChange={(event) => { setCasa({ ...Casa, Localidad: event }) }}
                                                placeholder="seleccione su localidad"
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="casaColonia"><span className="text-danger">*</span>Colonia:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                options={catCasaColonia}
                                                id="casaColonia"
                                                onChange={(event) => setCasa({ ...Casa, Colonia: event })}
                                                placeholder="seleccione su colonia"
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="casaRef"><span className="text-danger">*</span>Referencia:</Label>
                                            <Input id="casaRef" value={Casa.Referencia} onChange={(e) => setCasa({ ...Casa, Referencia: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="casanombredueno"><span className="text-danger">*</span>Nombre del Dueño:</Label>
                                            <Input id="casanombredueno" value={Casa.NombreDueno} onChange={(e) => setCasa({ ...Casa, NombreDueno: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="casateld"><span className="text-danger">*</span>Teléfono casa:</Label>
                                            <Input id="casateld" value={Casa.TelCasa} onChange={(e) => setCasa({ ...Casa, TelCasa: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="casaCeldeuno"><span className="text-danger">*</span>Célular Dueño:</Label>
                                            <Input id="casaCeldeuno" value={Casa.CelularDueno} onChange={(e) => setCasa({ ...Casa, CelularDueno: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Label for="casaLong"><span className="text-danger">*</span>Longitud:</Label>
                                            <Input id="casaLong" value={Casa.Longitud} onChange={(e) => setCasa({ ...Casa, Longitud: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Label for="casaLat"><span className="text-danger">*</span>Latitud:</Label>
                                            <Input id="casaLat" value={Casa.Latitud} onChange={(e) => setCasa({ ...Casa, Latitud: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='cinternet' checked={Casa.TieneInter} onChange={() => setCasa({ ...Casa, TieneInter: !Casa.TieneInter })} />
                                                <span className="form-check-sign" /> <i className="fa fa-wifi" aria-hidden="true"></i>Internet
                                                </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="casaVelocidad"><span className="text-danger">*</span>Velocidad Internet:</Label>
                                            <Input id="casaVelocidad" value={Casa.VelocidadInter} onChange={(e) => setCasa({ ...Casa, VelocidadInter: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label for="casaRef"><span className="text-danger">*</span>Total Equipos:</Label>
                                            <Input id="casaRef" value={Casa.TotalEquipos} onChange={(e) => setCasa({ ...Casa, TotalEquipos: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>



                                </Row>
                            </> : <></>}
                            {isElectoral.Observador ? <>
                                {_menu.Capturista === 1 && (<>
                                    <Row>
                                        <FormGroup className="col-md-10 text-warning">
                                            <Label for="Responsable">
                                                <span className="text-danger">*</span>Responsable:
                                                </Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    value={Responsable.label}
                                                    disabled
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        style={{ margin: '0px 1px' }}
                                                        color="warning"
                                                        onClick={() => handleBusarResponsble()}
                                                    ><i className="nc-icon nc-zoom-split" /></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Row>
                                </>)}
                                <Row>
                                    <Col md="4">
                                        <FormGroup sm="4">
                                            <Label for="ORC"><span className="text-danger">*</span>Distrito Federal: </Label>
                                            <Select className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="partido"
                                                placeholder="Seleccione..."
                                                options={catDistrito}
                                                onChange={(e) => setDistrito(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormGroup >
                                            <Label for="ORC"><span className="text-danger">*</span>OCR</Label>
                                            <Input
                                                type="text"
                                                id="orc"
                                                value={OCR}
                                                onChange={(event) => setOCR(event.target.value)}
                                                maxLength="18"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormGroup >
                                            <Label for="Fechacumple"><span className="text-danger">*</span>Vigencia Clave Elector:</Label>
                                            <ReactDatetime
                                                id="Fechacumple"
                                                name="Fechacumple"
                                                dateFormat="YYYY"
                                                timeFormat={false}
                                                closeOnSelect
                                                value={VigenciaINE}
                                                onChange={(e) =>
                                                    setVigenciaINE(moment(e._d).format("YYYY"))
                                                }
                                                inputProps={{ className: "form-control" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="propietario"><span className="text-danger">*</span>¿Has sido funcionario o representante de casilla?</Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <br />
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={tieneExperiencia} onChange={() => setTieneExperiencia(1)} />
                                                <span className="form-check-sign" />Si
                                                </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={!tieneExperiencia} onChange={() => setTieneExperiencia(0)} />
                                                <span className="form-check-sign" />No
                                                </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup sm="3">
                                            <Label for="rauto"><span className="text-danger">*</span>¿Tiene Auto?</Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <br />
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={tieneAuto} onChange={() => setTieneAuto(1)} />
                                                <span className="form-check-sign" />Si
                                                    </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={!tieneAuto} onChange={() => setTieneAuto(0)} />
                                                <span className="form-check-sign" />No
                                                    </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                            </> : <></>}
                            {isElectoral.PrimeroFila ? <>
                                <Row><br /></Row>
                                {_menu.Capturista === 1 && (<>
                                    <Row>
                                        <FormGroup className="col-md-10 text-warning">
                                            <Label for="Responsable">
                                                <span className="text-danger">*</span>Responsable:
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    value={Responsable.label}
                                                    disabled
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        style={{ margin: '0px 1px' }}
                                                        color="warning"
                                                        onClick={() => handleBusarResponsble()}
                                                    ><i className="nc-icon nc-zoom-split" /></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Row>
                                </>)}
                                <Row>
                                    <FormGroup className="col-md-2 text-warning" >
                                        <Label for="seccion"><span className="text-danger">*</span>Sección:</Label>
                                        <InputGroup>
                                            <Input id="seccion" maxLength='4' value={Seccion}
                                                onChange={(e) => { setSeccion(e.target.value) }} ></Input>
                                            <InputGroupAddon addonType="append">
                                                <Button id="btnSeccion" color='warning' style={{ margin: '0px 1px' }}
                                                    onClick={btnValidaSeccion} >
                                                    <i className="fa fa-search fa-lg"></i></Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className="col-md-3" >
                                        <Label for="Mpioseccion">Municipio:</Label>
                                        <Input id='Mpioseccion' value={MpioSeccion} disabled></Input>
                                    </FormGroup>

                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup sm="4">
                                            <Label for="casilla"><span className="text-danger">*</span>Casilla:</Label>
                                            <Select className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="casilla"
                                                placeholder="Debe validar la sección"
                                                options={catCasilla}
                                                onChange={(e) => setCasilla(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <FormGroup sm="3">
                                            <Label for="rauto"><span className="text-danger">*</span>¿Tiene Auto?</Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <br />
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={tieneAuto} onChange={() => setTieneAuto(1)} />
                                                <span className="form-check-sign" />Si
                                                </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={!tieneAuto} onChange={() => setTieneAuto(0)} />
                                                <span className="form-check-sign" />No
                                                </Label>
                                        </FormGroup>
                                    </Col>
                                    
                                </Row>

                            </> : <></>}
                            {isElectoral.CoordEstaElectoral
                            }
                            {isElectoral.CoordMpalElectoral ? <>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="idPMunicipio">Municipio:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idPMunicipio"
                                                placeholder="Seleccione un Municipio..."
                                                options={catMunicipioCasa}
                                                onChange={(e) => setPromocion({ ...Promocion, idMunicipio: e.value })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> : <></>
                            }
                            {isElectoral.CoordRegElectoral ? <>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="idPRegion2">Región:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idPRegion2"
                                                placeholder="Seleccione una Region..."
                                                options={catRegion}
                                                onChange={(e) => handleRegion(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> : <></>}
                            {isPromocion.SupEstatalElectoral ? <>

                            </> : <></>}
                            {isPromocion.CoordEstatalTerritorial ? <>

                            </> : <></>
                            }
                            {isPromocion.Municipal ? <>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="idPMunicipio">Municipio:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idPMunicipio"
                                                placeholder="Seleccione un Municipio..."
                                                options={catMunicipioCasa}
                                                onChange={(e) => setPromocion({ ...Promocion, idMunicipio: e.value })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> : <></>}
                            {isPromocion.Regional ? <>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="idPRegion">Región:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idPRegion"
                                                placeholder="Seleccione una Region..."
                                                options={catRegion}
                                                onChange={(e) => handleRegion(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> : <></>}
                            {isPromocion.Poligonal ? <>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="idPoligonoMunicipio">Municipio:</Label>

                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idPoligonoMunicipio"
                                                placeholder="Seleccione un Municipio..."
                                                options={catMunicipioCasa}
                                                onChange={(e) => LlenadoPoligonoByMpio(e)}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="idPPoligono">Polígono:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idPPoligono"
                                                placeholder="Seleccione un Polígono..."
                                                options={catPoligonoCasa}
                                                onChange={(e) => setPromocion({ ...Promocion, idPoligono: e.value })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> : <></>}
                            {isPromocion.Seccional ? <>
                                <Row>
                                    <Col md="4">
                                        <FormGroup sm="4">
                                            <Label for="Pseccion">Sección:</Label>
                                            <Input id='Pseccion' value={Promocion.idSecional} onChange={(e) => setPromocion({ ...Promocion, idSecional: e.target.value })} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup sm="4">

                                            <Button id='bPseccion' onClick={(e) => btnValidarSeccion(e)} color='warning' className='warning' >
                                                <i className="nc-icon nc-zoom-split" />Validar
                                                </Button>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup sm="4">
                                            <Label for="MPseccion">Municipio de la Sección:</Label>
                                            <Input id='MPseccion' value={SeccionMpio} disabled />
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </> : <></>}
                            {isPromocion.Manzanero ?
                                <>
                                    <Row>
                                        <Col sm="3">
                                            <Label for="MPseccion">Sección</Label>
                                            <FormGroup sm="3">
                                                <InputGroup className="no-border">
                                                    <Input id='MPseccion' value={Manzanera.Seccion} onChange={(e) => setManzanera({ ...Manzanera, Seccion: e.target.value })} />
                                                    <InputGroupAddon >
                                                        <Button id='bPseccion'
                                                            style={{ margin: '0px 1px' }}
                                                            onClick={(e) => btnValidarManzana(e)} color='warning' className='warning' >
                                                            <i className="nc-icon nc-zoom-split" />
                                                        </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col sm="3">
                                            <Label for="MPseccion">Manzana</Label>
                                            <FormGroup sm="3">
                                                <InputGroup className="no-border">
                                                    <Input id='MPseccion' value={Manzanera.Manzana} onChange={(e) => setManzanera({ ...Manzanera, Manzana: e.target.value })} />
                                                <InputGroupAddon >
                                                    <Button id='bPseccion'
                                                    style={{ margin: '0px 1px' }}
                                                    onClick={(e) => btnValidarManzana(e)} color='warning' className='warning' >
                                                        <i className="nc-icon nc-zoom-split" />
                                                    </Button>
                                                </InputGroupAddon>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col> */}
                                        <Col sm="4">
                                            <FormGroup sm="6">
                                                <Label for="MPseccion">Seleccionar Manzana</Label>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idManzana"
                                                    placeholder="Seleccione una Manzana..."
                                                    options={catManzanas}
                                                    onChange={(e) => setManzanera({ ...Manzanera, ManzanaSeleccionada: e.value, Manzana: e.label })}
                                                    isLoading={loading}
                                                    loadingMessage={"...Filtrando..."}
                                                ></Select>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </>
                                : <></>}
                            {isSocial.CoorGralSocial && (<>
                                <Row>
                                    <Col sm="6">
                                        <Label for="nombre">Código de Invitado:</Label>
                                        <Input id="nombre" value={Estructura.Codigo} maxLength='10' onChange={(e) => setEstructura({ ...Estructura, Codigo: e.target.value })} />
                                    </Col>
                                </Row>
                            </>)}
                            {isSocial.CoorEstrSocial && (<>
                                <Row>
                                    <Col md="12">
                                        <FormGroup sm="12">
                                            <Label for="idCoorEstrSocial">Estructura Social:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idCoorEstrSocial"
                                                placeholder="Seleccione una Estructura Social..."
                                                options={catEstructura}
                                                onChange={(e) => setEstructura({ ...Estructura, idEstructura: e })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <Label for="nombre">Código de Invitado:</Label>
                                        <Input id="nombre" value={Estructura.Codigo} maxLength='10' onChange={(e) => setEstructura({ ...Estructura, Codigo: e.target.value })} />
                                    </Col>
                                </Row>
                            </>)}
                            {isSocial.CoorRegSocial && (<>
                                <Row>
                                    <Col md="6">
                                        <FormGroup sm="6">
                                            <Label for="idPRegion">Región:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idPRegion"
                                                placeholder="Seleccione una Region..."
                                                options={catRegion}
                                                onChange={(e) => setEstructura({ ...Estructura, idRegion: e })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup sm="12">
                                            <Label for="idCoorEstrSocial">Estructura Social:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idCoorEstrSocial"
                                                placeholder="Seleccione una Estructura Social..."
                                                options={catEstructura}
                                                onChange={(e) => setEstructura({ ...Estructura, idEstructura: e })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <Label for="nombre">Código de Invitado:</Label>
                                        <Input id="nombre" value={Estructura.Codigo} maxLength='10' onChange={(e) => setEstructura({ ...Estructura, Codigo: e.target.value })} />
                                    </Col>
                                </Row>
                            </>)}
                            {isSocial.CoorMpalSocial && (<>
                                <Row>
                                    <Col md="12">
                                        <FormGroup sm="12">
                                            <Label for="idPMunicipio">Municipio:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idPMunicipio"
                                                placeholder="Seleccione un Municipio..."
                                                options={catMunicipioCasa}
                                                onChange={(e) => setEstructura({ ...Estructura, idMunicipio: e })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup sm="12">
                                            <Label for="idCoorEstrSocial">Estructura Social:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idCoorEstrSocial"
                                                placeholder="Seleccione una Estructura Social..."
                                                options={catEstructura}
                                                onChange={(e) => setEstructura({ ...Estructura, idEstructura: e })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <Label for="nombre">Código de Invitado:</Label>
                                        <Input id="nombre" value={Estructura.Codigo} maxLength='10' onChange={(e) => setEstructura({ ...Estructura, Codigo: e.target.value })} />
                                    </Col>
                                </Row>
                            </>)}
                            {isSocial.CoorEstatalSocial && (<>
                                <Row>
                                    <Col md="12">
                                        <FormGroup sm="12">
                                            <Label for="idCoorEstrSocial">Estructura Social:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idCoorEstrSocial"
                                                placeholder="Seleccione una Estructura Social..."
                                                options={catEstructura}
                                                onChange={(e) => setEstructura({ ...Estructura, idEstructura: e })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <Label for="nombre">Código de Invitado:</Label>
                                        <Input id="nombre" value={Estructura.Codigo} maxLength='10' onChange={(e) => setEstructura({ ...Estructura, Codigo: e.target.value })} />
                                    </Col>
                                </Row>
                            </>)}
                            {isSocial.CoordeEstructura && (<>
                                <Row>
                                    <Col md="12">
                                        <FormGroup sm="12">
                                            <Label for="idCoorEstrSocial">Estructura Social:</Label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idCoorEstrSocial"
                                                placeholder="Seleccione una Estructura Social..."
                                                options={catEstructura}
                                                onChange={(e) => setEstructura({ ...Estructura, idEstructura: e })}
                                                isLoading={loading}
                                                loadingMessage={"...Filtrando..."}
                                            ></Select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <Label for="nombre">Código de Invitado:</Label>
                                        <Input id="nombre" value={Estructura.Codigo} maxLength='10' onChange={(e) => setEstructura({ ...Estructura, Codigo: e.target.value })} />
                                    </Col>
                                </Row>
                            </>)}
                                <Row>
                                    <Col sm="6">

                                        {isCapturista.Electoral&&(
                                            <>
                                                <h6 className='text-center text-muted'>Electoral</h6><hr/>
                                                <span className="text-danger">*</span> Tipo:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idCap"
                                                    value={CapElectoral.Tipo}
                                                    options={tipoCapturista}
                                                    onChange={(e) => ElectoraTipo(e)}
                                                    isLoading={loading}
                                                    loadingMessage={"...Filtrando..."}
                                                />
                                                Región:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idCap"
                                                    placeholder="Seleccione un Tipo..."
                                                    options={catRegion}
                                                    onChange={(e) => setCapElectoral({...CapElectoral,Region:e})}
                                                    isLoading={loading}
                                                    loadingMessage={"...Filtrando..."}
                                                    value={CapElectoral.Region}
                                                />
                                                Municipio:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idCap"
                                                    placeholder="Seleccione un Tipo..."
                                                    options={catMunicipioCasa}
                                                    onChange={(e) => setCapElectoral({...CapElectoral,Municipio:e})}
                                                    isLoading={loading}
                                                    loadingMessage={"...Filtrando..."}
                                                    value={CapElectoral.Municipio}
                                                />
                                                <span className="text-danger">*</span> Responsabilidad:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idCap"
                                                    placeholder="Seleccionar Responsabilidad..."
                                                    options={catResponsabilidad}
                                                    isLoading={loading}
                                                    loadingMessage={"...Filtrando..."}
                                                    isMulti
                                                    onChange={(event, i) => {
                                                        if(i.action === "clear"){
                                                        setCapElectoral({...CapElectoral.Responsabilidad, Responsabilidad:[{value:-1,label:'Seleccionar...'}]})
                                                            return true;
                                                        }
                                                        else if(i.action === "select-option")
                                                        {
                                                            const array_temp = event.map(dato => { return dato.value })
                                                            setCapElectoral({...CapElectoral, Responsabilidad:array_temp})
                                                        }
                                                        else if(i.action === "remove-value"){
                                                            if (i.removedValue !== undefined) {
                                                        setCapElectoral({...CapElectoral.Responsabilidad, Responsabilidad:[]})
                                                                const array_temp = CapElectoral.Responsabilidad.map(dato => {
                                                                    if (i.removedValue.value !== dato) {
                                                                        return dato.value
                                                                    }
                                
                                                                })
                                                                if(!array_temp.includes(undefined)){
                                                                    setCapElectoral({...CapElectoral, Responsabilidad:array_temp})
                                
                                                                }
                                                                else{
                                                                    setCapElectoral({...CapElectoral, Responsabilidad:[{value:-1,label:'Seleccionar...'}]})
                                                                    
                                                                }
                                                                    
                                                            }
                                                            
                                                        }
                                                    }}
                                                ></Select>
                                            
                                                <FormGroup className="col-md-10 text-warning">
                                                        <Label for="Responsable">
                                                            <span className="text-danger">*</span>Responsable:
                                                            </Label>
                                                        <InputGroup>
                                                            <Input
                                                                type="text"
                                                                value={Responsable.label}
                                                                disabled
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <Button
                                                                    style={{ margin: '0px 1px' }}
                                                                    color="warning"
                                                                    onClick={() => handleBusarResponsble()}
                                                                ><i className="nc-icon nc-zoom-split" /></Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                </FormGroup>
                                                <Button className='btn-default' onClick={()=>setCapturistaElectoral()}>Guardar</Button>
                                            </>
                                        )}
                                    </Col>
                                    <Col sm="6">
                                        {isCapturista.Promocion&&(
                                            <>
                                                <h6 className='text-center text-muted'>Promoción</h6><hr/>
                                                <span className="text-danger">*</span> Tipo:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idCap"
                                                    value={capTerritorial.Tipo}
                                                    options={tipoCapturista}
                                                    onChange={(e) => PromocionTipo(e)}
                                                    isLoading={loading}
                                                    loadingMessage={"...Filtrando..."}
                                                />
                                                Región:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idCap"
                                                    placeholder="Seleccione un Tipo..."
                                                    options={catRegion}
                                                    onChange={(e) => setCapTerritorial({...capTerritorial,Region:e})}
                                                    isLoading={loading}
                                                    loadingMessage={"...Filtrando..."}
                                                    value={capTerritorial.Region}
                                                />
                                                Municipio:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idCap"
                                                    placeholder="Seleccione un Tipo..."
                                                    options={catMunicipioCasa}
                                                    onChange={(e) => setCapTerritorial({...capTerritorial,Municipio:e})}
                                                    isLoading={loading}
                                                    loadingMessage={"...Filtrando..."}
                                                    value={capTerritorial.Municipio}
                                                />
                                                <span className="text-danger">*</span> Responsabilidad:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idCap"
                                                    placeholder="Seleccionar Responsabilidad..."
                                                    options={catPromocion}
                                                    isLoading={loading}
                                                    loadingMessage={"...Filtrando..."}
                                                    isMulti
                                                    onChange={(event, i) => {
                                                        if(i.action === "clear"){
                                                        setCapTerritorial({...capTerritorial.Responsabilidad, Responsabilidad:[{value:-1,label:'Seleccionar...'}]})
                                                            return true;
                                                        }
                                                        else if(i.action === "select-option")
                                                        {
                                                            const array_temp = event.map(dato => { return dato.value })
                                                            setCapTerritorial({...capTerritorial, Responsabilidad:array_temp})
                                                        }
                                                        else if(i.action === "remove-value"){
                                                            if (i.removedValue !== undefined) {
                                                        setCapTerritorial({...capTerritorial.Responsabilidad, Responsabilidad:[]})
                                                                const array_temp = capTerritorial.Responsabilidad.map(dato => {
                                                                    if (i.removedValue.value !== dato) {
                                                                        return dato.value
                                                                    }
                                
                                                                })
                                                                if(!array_temp.includes(undefined)){
                                                                    setCapTerritorial({...capTerritorial, Responsabilidad:array_temp})
                                
                                                                }
                                                                else{
                                                                    setCapTerritorial({...capTerritorial, Responsabilidad:[{value:-1,label:'Seleccionar...'}]})
                                                                    
                                                                }
                                                                    
                                                            }
                                                            
                                                        }
                                                    }}
                                                ></Select>
                                            
                                                <FormGroup className="col-md-10 text-warning">
                                                        <Label for="Responsable">
                                                            <span className="text-danger">*</span>Responsable:
                                                            </Label>
                                                        <InputGroup>
                                                            <Input
                                                                type="text"
                                                                value={Responsable.label}
                                                                disabled
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <Button
                                                                    style={{ margin: '0px 1px' }}
                                                                    color="warning"
                                                                    onClick={() => handleBusarResponsble()}
                                                                ><i className="nc-icon nc-zoom-split" /></Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                </FormGroup>
                                                <Button className='btn-default' onClick={()=>setCapturistaPromocion()}>Guardar</Button>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                        </CardBody>
                        <CardFooter>
                            <Row>
                                <Col sm='6' className='text-center'>
                                    {showButton ? <>
                                        <Button size='sm' color='primary' className='primary' onClick={(e) => saveResponsabilidad(e)} >Guardar</Button>
                                        <Button size='sm' color='danger' className='danger' onClick={btnCancelar} >Cancelar</Button>
                                    </> : <>

                                        </>}
                                </Col>
                                <Col sm='6' className='text-center'>

                                </Col>
                            </Row>
                            {showMensaje ? MensajeAlerta : ''}
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            {showResponsable ? <BuscaResponsable onHide={onHide} datos={(e) => handResponsable(e)} /> : <></>}
            {isModal ? <BuscarOwned onHide={() => onHide()} Owned={(e) => UserOwned(e)} RespElectoral="true" /> : <></>}
        </>
    );
};
export default VoluntariosSetResponsabilidad;