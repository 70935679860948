import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col, Collapse, Input } from 'reactstrap';

import { CircleSpinner } from "react-spinners-kit";

function FiltroUsuario({ Menu, Datos }) {
    const [loading, setLoading] = useState(false);
    const [filtro, setFiltro] = useState({
        Celular: '',
        Nombre: '',
    })
    const [collapse, setCollapse] = useState({
        horizontalTabs: "home",
        verticalTabs: "info",
        pageTabs: "homePages",
        openedCollapses: ["collapseOne", "collapse1"]
    });

    useEffect(() => {

    }, [])

    const collapsesToggle = (colapse) => {
        let openedCollapses = collapse.openedCollapses;
        if (openedCollapses.includes(colapse)) {
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses.filter(item => item !== colapse)
            });
        } else {
            openedCollapses.push(colapse);
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses
            });
        }
    };
    
    const removeWhiteSpaces=(text) =>{
        return (text || '').replace(/\s/g, '')
    }
    const setFilter = () => {
        var filtrar = {
            Celular: removeWhiteSpaces(filtro.Celular),
            Nombre: filtro.Nombre,
        }
        Datos(filtrar);
    }

    const ClearFilter = () => {
        setLoading(true);
        setFiltro({
            Celular: '',
            Nombre: '',
        })
        setLoading(false);

    }

    return (
        <Card className="card-plain">
            <CardBody>
                <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                >
                    <Card className="card-plain">
                        <CardHeader role="tab">
                            <a
                                aria-expanded={collapse.openedCollapses.includes(
                                    "collapse3"
                                )}
                                data-parent="#accordion"
                                data-toggle="collapse"
                                onClick={() => collapsesToggle("collapse3")}
                                className="text-left"
                            >
                                <Button
                                    className="btn-icon btn-neutral"
                                    outline
                                    size="sm"
                                    id="EditUser"
                                >
                                    <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                                </Button>
                                Filtro
                            </a>
                        </CardHeader>
                        <Collapse
                            role="tabpanel"
                            isOpen={collapse.openedCollapses.includes(
                                "collapse3"
                            )}
                        >
                            <CardBody>
                                {Menu.Ver && (<>
                                    <Row>
                                        {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                        )}
                                        <Col sm="2" className="text-muted">Celular:<Input id="icveElector" value={filtro.Celular} onChange={(e) => setFiltro({ ...filtro, Celular: e.target.value })} /></Col>
                                        <Col sm="4" className="text-muted">Nombre: <Input id="inombre" value={filtro.Nombre} onChange={(e) => setFiltro({ ...filtro, Nombre: e.target.value })} /></Col>
                                    </Row>
                                </>
                                )}
                            </CardBody>
                            <CardFooter>
                                <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e) => setFilter()}>
                                    <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                    Filtrar
                        </Button>{' '}
                                <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e) => ClearFilter()}>
                                    <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                                    Limpiar
                        </Button>
                            </CardFooter>
                        </Collapse>
                    </Card>
                </div>
            </CardBody>
        </Card>
    );
};
export default FiltroUsuario;