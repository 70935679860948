import React, { useState, } from "react";
import {
	Row,
	Col,
} from "reactstrap";
import Notifications, { notify } from 'react-notify-toast';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';
import DiaDInstalacion from 'components/Sije/DiaDInstalacion';
import EjecutarApi from "../../data/getPOST";
import { getVars } from "GlobalStorage";
import { CircleSpinner } from "react-spinners-kit";

const SijeDiaDCierreCasilla = (props) => {
	const token = getVars("Token");
	const [loading, setLoading] = useState(false);

	const saveCierreCasilla = (parametro) => {

		var cierreCasilla = '';

		if (parametro.idRG.value !== 0) {
			cierreCasilla = {
				idUser: parametro.idRG.value,
				Seccion: parametro.Seccion,
				NombreCasilla: parametro.NombreCasilla.label,
				FechaHora: `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.segundos}`,
				tipoBitacora: 3,
				UserReporto: parametro.reporto
			}
		} else if (parametro.reporto !== "") {
			cierreCasilla = {
				idUser: parametro.idRG.value,
				Seccion: parametro.Seccion,
				NombreCasilla: parametro.NombreCasilla.label,
				FechaHora: `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.segundos}`,
				tipoBitacora: 3,
				UserReporto: parametro.reporto
			}
		} else {
			setLoading(false);
			notify.show(`Hace Falta registrar un Responsable`, 'warning', 5000);

			return true;
		}

		setBitacoraSije(cierreCasilla);


	}
	const saveCierreVotacion = (parametro) => {
		var CierreVotacion = '';
		if (parametro.idRG.value !== 0) {
			CierreVotacion = {
				idUser: parametro.idRG.value,
				Seccion: parametro.Seccion,
				NombreCasilla: parametro.NombreCasilla.label,
				FechaHora: `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.segundos}`,
				tipoBitacora: 4,
				UserReporto: parametro.reporto
			}
		} else if (parametro.reporto !== "") {
			CierreVotacion = {
				idUser: parametro.idRG.value,
				Seccion: parametro.Seccion,
				NombreCasilla: parametro.NombreCasilla.label,
				FechaHora: `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.segundos}`,
				tipoBitacora: 4,
				UserReporto: parametro.reporto
			}
		} else {
			setLoading(false);
			notify.show(`Hace Falta registrar un Responsable`, 'warning', 5000);
			return true;
		}
		setBitacoraSije(CierreVotacion);

	}
	const setBitacoraSije = (parametro) => {
		setLoading(true);
		EjecutarApi(parametro, "setBitacoraSije", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.success) {
					if (res.results) {
						notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
							background: "#18c97d",
							fontSize: 24,
							text: "#FFFFFF",
						});
						setLoading(false);
					} else {
						setLoading(false);
						notify.show(
							`Error al agregar registro: ${res.message}!!`,
							"custom",
							5000,
							{
								background: "#0857B6",
								fontSize: 24,
								text: "#FFFFFF",
							}
						);
						return true;
					}
				} else {
					setLoading(false);
					notify.show(
						`Error al insertar el registro: ${res.message}!!`,
						"custom",
						5000,
						{
							background: "#0857B6",
							fontSize: 24,
							text: "#FFFFFF",
						}
					);
				}
				return true;
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error al agregar el registro: ${e.error}`, "custom", 5000, {
					background: "#0857B6",
					fontSize: 24,
					text: "#FFFFFF",
				});
			});
	}
	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel">
				<Navbar {...props} />
				<Notifications />
				<div className="content">
					<Row>
						<Col sm="12">
							{/* <Row>
						<Col lg="4" md="6" sm="6">
							<Card className="card-stats">
								<CardBody>
									<Row>
										<Col md="4" xs="5">
											<div className="icon-big text-center icon-warning">
												<i className="fa fa-users text-primary" />
											</div>
										</Col>
										<Col md="8" xs="7">
											<div className="numbers">
												<p className="card-category">Casillas</p>
												<CardTitle tag="p">0</CardTitle>
												<p />
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-muted text-center' >
											No Asignados
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											Aceptados
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											Propuestos
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-center' >
											<h6>0</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											<h6>0</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											<h6>0</h6>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<hr />
									<div className="stats">
										<i className="fa fa-clock-o" />
										Actual
										</div>
								</CardFooter>
							</Card>
						</Col>
						<Col lg="4" md="6" sm="6">
							<Card className="card-stats">
								<CardBody>
									<Row>
										<Col md="4" xs="5">
											<div className="icon-big text-center icon-warning">
												<i className="fa fa-sitemap text-warning" />
											</div>
										</Col>
										<Col md="8" xs="7">
											<div className="numbers">
												<p className="card-category">Estructura Electoral</p>
												    <CardTitle tag="p">0</CardTitle>
												<p />
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-muted text-center' >
											Avance
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											Aceptados
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											Propuestos
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-center' >
											<h6>0%</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											<h6>0</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											<h6>0</h6>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<hr />
									<div className="stats">
										<i className="fa fa-clock-o" />
										Actual
										</div>
								</CardFooter>
							</Card>
						</Col>
						<Col lg="4" md="6" sm="6">
							<Card className="card-stats">
								<CardBody>
									<Row>
										<Col md="4" xs="5">
											<div className="icon-big text-center icon-warning">
												<i className="fa fa-line-chart text-warning" />
											</div>
										</Col>
										<Col md="8" xs="7">
											<div className="numbers">
												<p className="card-category"></p>
												<CardTitle tag="p">0</CardTitle>
												<p />
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-muted text-center' >
											Avance
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											Aceptados
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											Propuestos
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-center' >
											<h6>0%</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											<h6>0</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											<h6>0</h6>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<hr />
									<div className="stats">
										<i className="fa fa-clock-o" />
										Actual
										</div>
								</CardFooter>
							</Card>
						</Col>
					</Row> */}
							<Row>
								{loading && (
									<CircleSpinner
										className="text-center"
										size={30}
										color="#686769"
										loading={loading}
									/>
								)}
								<Col sm="4">
									<DiaDInstalacion
										Hora="Hora de Cierre de Casilla (Formato: 24 hrs, ejemplo: 13:30)"
										Titulo="Cierre de Casillas"
										btnGuardar={saveCierreCasilla}
									/>
								</Col>
								<Col sm="4">
									{loading && (
										<CircleSpinner
											className="text-center"
											size={30}
											color="#686769"
											loading={loading}
										/>
									)}
									<DiaDInstalacion
										Hora="Hora de Cierre de Votación (Formato: 24 hrs, ejemplo: 13:30)"
										btnGuardar={saveCierreVotacion}
										Titulo="Cierre de Votación"
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<Footer />
			</div>
		</div>
	)
}
export default SijeDiaDCierreCasilla; 