import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import { getDataMenu } from 'data/menus.js';
import { getVars } from "GlobalStorage";
import GraficaSocial from 'components/Charts/SocialChart';

const SocialAvances = (props) => {
  const token = getVars("Token");
  const [menu, _setmenu] = useState({});

  useEffect(() => {
    _setmenu(getDataMenu(22, token.menus));
  }, []);
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <div className="content">
          <Row className='text-align-center-center'>
            <Col sm='12' className='text-align-center-center'>
              <Card >
                <CardTitle>

                </CardTitle>
                <CardBody>
                  <GraficaSocial />
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};


export default SocialAvances;
