import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import Rating from '@material-ui/lab/Rating';
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from "GlobalStorage";
import CompromisosEdit from "components/Compromisos/CompromisosEdit";
import ModalFiltro from 'components/Filtro/indexSocial';
import Box from '@material-ui/core/Box';
import bloqueado from 'data/bloqueado.json';
import NumeraliaGrf from 'components/Charts/CompromisosMetas/index';
import PerfectScrollbar from "perfect-scrollbar";
import SetResponsabilidad from '../Compromiso/VoluntariosSetResponsabilidad'
var ps;
const SocialCompromisoGrafica = (props) => {
  const mainPanel = useRef();
  const [isBloqueado, setIsBloqueado] = useState(false);
  const [statTotales, setStatTotales] = useState({});
  const [compromisos, setCompromisos] = useState([]);
  const [total, setTotal] = useState({ hoy: 0, total: 0, voluntarios: 0 });
  const [totalCompromisos, setTotalCompromisos] = useState(0);
  const [isEditing, setEditing] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [compromisoEdit, setCompromisoEdit] = useState({});
  const [Filter, setFiltro] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
  const [MenuModal] = useState({
    Compromiso: true,
    Voluntarios: false,
    RCs: false,
    RGs: false,
    Abogado: false,
    CasaAzul: false,
    Municipio: false
  });
  const [loadingChart, setLoadingChart] = useState(true);
  const [series, setSeries] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [Datos, setDatos] = useState([]);
  const btnEditar = (item) => {

    setCompromisoEdit(item);
    setEditing(true);
  };

  const btnCancelarEditar = (props) => {

    setEditing(false);
  };
  const btnActualizar = (props) => {

    setEditing(false);
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    if (props.history.action === "PUSH") {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    _setmenu(getDataMenu(9, token.menus));
    getDataChart({ filtered: [] });
  }, [])

  const getDataChart = (data) => {
    setLoadingChart(true);
    EjecutarApi(data, "chartSocial", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setDataChart(res.data.data);
          setSeries(res.data.series);

        }
        setLoadingChart(false);
      })
      .catch((e) => {
        notify.show(`Error al obtener datos de la gráfica`, "error", 5000);
        setLoadingChart(false);
      });
  }

  const [isChartView, setIsChartView] = useState(true);
  const [idResponsabilidad] = useState([1,
    2,
    3,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    40,
    50,
    51,
    52,
    53,
    54,
    55,
    97,
    98,
    100,
    101,])
  useEffect(() => {

    let encontrado = bloqueado.data.filter(item => item.value === token.user.id);
    let notSee = idResponsabilidad.filter(item => item === token.user.Responsabilidad.value);
    cardTotal(Filter);
    if (encontrado.length > 0) {
      setIsBloqueado(true)
    }
    if (notSee.length > 0) {
      setIsChartView(false);
    }

  }, []);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const Export = () => {

    const data = {
      page: 0,
      pageSize: 10,
      filtered: Filter,
      Export: true
    };

    var datos = JSON.stringify(data)
    var url = new URL(
      `${URL_PUBLICA}getCompromisosByListado?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");

  }


  const handleFiltro = (filter) => {
    setLoading(true);
    var _filtro = [];
    if (filter.Region.value > 0) {
      _filtro.push({ id: 'idRegionVota', value: filter.Region.value })
    }
    if (filter.Municipio.value > 0) {
      _filtro.push({ id: 'idMunicipioVota', value: filter.Municipio.value })
    }
    if (filter.Poligono.value > 0) {
      _filtro.push({ id: 'idPoligonoVota', value: filter.Poligono.value })
    }
    if (filter.Seccion.value > 0) {
      _filtro.push({ id: "SeccionVota", value: filter.Seccion.value })
    }
    if (filter.Celular.length > 0) {
      _filtro.push({ id: "Telefono", value: filter.Celular })
    }
    if (filter.NombreCompleto.length > 0) {
      _filtro.push({ id: "NombreCompleto", value: filter.NombreCompleto })
    }
    if (filter.DireccionVive.length > 0) {
      _filtro.push({ id: "DireccionVive", value: filter.DireccionVive })
    }
    if (filter.RangoEdad.value > 0) {
      var cadena = []
      cadena = cadena.push(parseInt(filter.RangoEdad.label.split(",")))
      _filtro.push({ id: "Edad", value: filter.RangoEdad.label })
    }
    if (filter.ClaveElector.length > 0) {
      _filtro.push({ id: "ClaveElector", value: filter.ClaveElector })
    }
    if (filter.Propietario.length > 0) {
      _filtro.push({ id: "Propietario", value: filter.Propietario })
    }
    if (filter.Ranking.value > 0) {
      _filtro.push({ id: "Ranking", value: filter.Ranking.value })
    }
    if (filter.Segmento.value > 0) {
      _filtro.push({ id: "idEstructura", value: filter.Segmento.value })
    } //Capturista
    if (filter.Etiqueta.value > 0) {
      _filtro.push({ id: "Etiqueta", value: filter.Etiqueta.value })
    } //Capturista
    if (filter.Capturista.value > 0) {
      _filtro.push({ id: "UserCreate", value: filter.Capturista.value })
    }
    if (filter.Propietario.value > 0) {
      _filtro.push({ id: "UserOwned", value: filter.Propietario.value })
    }

    setFiltro(_filtro);
    //Ejecutando Filtro

    let _menuStorage = getDataMenu(8, token.menus);
    let _menuVoluntarios = getDataMenu(7, token.menus);
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [{ id: "LastUpdate", desc: true }],
      filtered: _filtro,
    };

    EjecutarApi(data, "getSocialByListado", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {

        setLoading(false);
        setTotalCompromisos(res.total);
        setCompromisos(
          res.data.map((prop, key) => {

            return {
              id: prop.id,
              Municipio: prop.MunicipioVota ? prop.MunicipioVota.label : "Sin Municipio",
              Region: prop.RegionVota ? prop.RegionVota.label : "Sin Region",
              NombreCompleto:<div> {_menuStorage.Editar === 1 && (<>
                                <Button
                                      className="btn-link"
                                      color="primary"
                                      onClick={() => {
                                        btnEditar(prop);
                                      }}
                              ><i class="fa fa-pencil fa-lg" aria-hidden="true"></i></Button></>)}
                              {_menuVoluntarios.Editar === 1 ? (<>  <Button
                                className="btn-link"
                                color="primary"
                                onClick={() => {
                                  asignarResponsabilidad(prop);
                                }}
                              >{`${prop["Nombres"]} ${prop["Paterno"]} `}</Button></>) : (`${prop["Nombres"]} ${prop["Paterno"]}`)}</div>,

              SeccionVota: prop.SeccionVota ? prop.SeccionVota : 'Sin Sección',
              isVoluntario:
                prop["isVoluntario"] === 1 ? "Si" : "No",
              Responsabiliad: prop.Responsabilidad ? prop.Responsabilidad.label : "Sin Responsabilidad",
              Ranking: <>
                <Box component="fieldset" mb={3} borderColor="transparent">
                  <Rating name="read-only" value={prop.Ranking} max={10} readOnly />
                </Box>
              </>,
              Etiquetas: prop.Segmentos.length > 0 ? prop.Segmentos.map(item => {
                return <>{item.label}</>
              }) : '',
              Colonia: prop.Colonia,
              data: prop,
            };
          })
        );
      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener Compromisos: ${e.error}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });

    cardTotal(_filtro);
    getDataChart({ filtered: _filtro });
  }
  const cardTotal = (_filtered) => {
    //getStatsSocialCompromisos 
    EjecutarApi({ filtered: _filtered }, 'getStatsSocialCompromisos', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setStatTotales(res.data);
        } else {
          notify.show(res.message, "error", 5000);
          setLoading(false)
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en el listado: ${e.message}`, "error", 5000);
      });
  }
  const [isEditingVoluntario, setEditingVoluntario] = useState(false);
  const asignarResponsabilidad =(item)=>{
      let permiso = getDataMenu(7, token.menus)
      EjecutarApi({
        filterBy: "c.id",
        filterValue: item.id
      }, "getVoluntarioByParams", token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success) {
            if (res.results) {
              setDatos({ Menu: permiso, idCompromiso: item.id, Compromiso: res.data });
              setEditingVoluntario(true);
            } else {
              setEditingVoluntario(false);
              notify.show(res.message, "warning", 5000);
            }
          }
        }).catch((e) => {
          notify.show(`Error al obtener los datos del compromiso: ${e.error}`, "error", 5000);
          setEditingVoluntario(true);
        })
      // setDatos({ Menu: permiso, idCompromiso: item.id, Compromiso: item });
      // setEditing(true);
  }
  const btnCancelarVoluntario = (item) => {
		setEditingVoluntario(false);
	};
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {!isBloqueado && (<>
            {isEditingVoluntario ? (
								<SetResponsabilidad
									btnCancelar={btnCancelarVoluntario}
									menu={Datos}

								/>
							) :(<> {!isEditing && (<>
                  <Row>
                    <Col lg="4" md="4" sm="4">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div name='d1' className="icon-big text-center icon-warning">
                                <i className="fa fa-cogs text-warning" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div name='d2' className="numbers">
                                <p className="card-category">Total Estructuras</p>
                                <CardTitle tag="p">{statTotales.totalEstructuras ? statTotales.totalEstructuras.total : 0}</CardTitle>
                                <p />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" xs="5" className='text-muted text-center' >
                              Estatales
                            </Col>
                            <Col md="6" xs="5" className='text-muted text-center' >
                              Municiales
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" xs="5" className='text-center' >
                              <h6>{statTotales.totalEstructuras ? statTotales.totalEstructuras.estructuras[0] : 0}</h6>
                            </Col>
                            <Col md="6" xs="5" className='text-center' >
                              <h6>{statTotales.totalEstructuras ? statTotales.totalEstructuras.estructuras[1] : 0}</h6>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <hr />
                          <div name='d3' className="stats" onClick={() => cardTotal(Filter)}>
                            <i className="fa fa-refresh" />
                            Actualizar
                        </div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div name='d4' className="icon-big text-center icon-warning">
                                <i className="fa fa-users text-warning" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div name='d5' className="numbers">
                                <p className="card-category">Total de Compromisos</p>
                                <CardTitle tag="p">{statTotales.totalCompromisos ? statTotales.totalCompromisos.total : 0}</CardTitle>
                                <p />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" xs="5" className='text-muted text-center' >
                              Mujeres
                              </Col>
                            <Col md="6" xs="5" className='text-muted text-center' >
                              Hombres
                              </Col>
                          </Row>
                          <Row>
                            <Col md="6" xs="5" className='text-center' >
                              <h6>{statTotales.totalCompromisos ? statTotales.totalCompromisos.compromisos[1] : 0}</h6>
                            </Col>
                            <Col md="6" xs="5" className='text-center' >
                              <h6>{statTotales.totalCompromisos ? statTotales.totalCompromisos.compromisos[0] : 0}</h6>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <hr />
                          <div name='d6' className="stats">
                            <i className="fa fa-refresh" />
                            Actualizar
                          </div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div name='d7' className="icon-big text-center icon-warning">
                                <i className="fa fa-user text-warning" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <p className="card-category">Responsables</p>
                                <CardTitle tag="p">{statTotales.totalEnlaces ? statTotales.totalEnlaces.total : 0}</CardTitle>
                                <p />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" xs="5" className='text-muted text-center' >
                              Mujeres
                            </Col>
                            <Col md="6" xs="5" className='text-muted text-center' >
                              Hombres
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" xs="5" className='text-center' >
                              <h6>{statTotales.totalEnlaces ? statTotales.totalEnlaces.enlaces[1] : 0}</h6>
                            </Col>
                            <Col md="6" xs="5" className='text-center' >
                              <h6>{statTotales.totalEnlaces ? statTotales.totalEnlaces.enlaces[0] : 0}</h6>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <hr />
                          <div name='d8' className="stats">
                            <i className="fa fa-clock-o" />
                            Actual
                        </div>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">{isChartView && (
                      !loadingChart ? <NumeraliaGrf datos={series} Titulos={dataChart} /> :
                        (
                          <Row>
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#686769"
                              loading={loadingChart}
                            /></Row>))}
                    </Col>
                  </Row>
                </>)}
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>

                      </CardHeader>
                      <CardBody>
                        {isEditing && (
                          <CompromisosEdit
                            btnCancelar={btnCancelarEditar}
                            btnActualizar={btnActualizar}
                            compromisoID={compromisoEdit}
                          />
                        )}
                        {!isEditing && (<>
                          <Row>
                            {/* {_menu.Agregar === 1 ? (
                              <Link to="compromisos_cap">
                                <Button color="primary" size="sm">
                                  <span className="btn-label">
                                    <i className="fa fa-user-plus fa-lg" />
                                  </span>
                                  Capturar
                                        </Button>
                              </Link>
                            ) : (<></>)} */}
                            {_menu.Exportar === 1 ? (

                              <Button color="primary" size="sm" onClick={() => Export()}>
                                <span className="btn-label">
                                  <i className="fa fa-file-excel-o fa-lg" />
                                </span>
                                Exportar
                                        </Button>

                            ) : (<></>)}
                          </Row>
                          <Row>
                            <Col sm="12">
                              <ModalFiltro Menu={MenuModal} Datos={(e) => handleFiltro(e)} />
                            </Col>
                          </Row>
                          <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                            <ReactTable
                              style={{
                                width: "110%",
                                height: "100%"
                              }}
                              data={compromisos}
                              noDataText="Sin información"
                              manual
                              columns={[
                                {
                                  Header: "Región",
                                  minWidth: 30,
                                  id: "Region",
                                  accessor: (d) => d.Region,
                                  filterAll: false,
                                },
                                {
                                  Header: "Municipio",
                                  minWidth: 70,
                                  id: "Municipio",
                                  accessor: (d) => d.Municipio,
                                  filterAll: false,
                                },
                                {
                                  Header: "Ranking",
                                  minWidth: 50,
                                  id: "Ranking",
                                  accessor: (d) => d.Ranking,
                                  filterAll: false,
                                },
                                {
                                  Header: "Etiquetas",
                                  minWidth: 50,
                                  id: "Etiquetas",
                                  accessor: (d) => d.Etiquetas,
                                  filterAll: false,
                                },
                                {
                                  Header: "Nombre",
                                  minWidth: 110,
                                  id: "NombreCompleto",
                                  accessor: (d) => d.NombreCompleto,
                                  filterAll: false,
                                },
                                {
                                  Header: "Responsabilidad",
                                  accessor: (d) => d.Responsabiliad,
                                  minWidth: 40,
                                  id: "Responsabilidad",
                                  filterable: false,
                                },
                                {
                                  Header: "Voluntario",
                                  accessor: (d) => d.isVoluntario,
                                  minWidth: 40,
                                  id: "isVoluntario",
                                  filterable: false,
                                },
                                {
                                  Header: "Colonia",
                                  minWidth: 80,
                                  id: "Colonia",
                                  accessor: (d) => d.Colonia,
                                  filterAll: false,
                                },
                                {
                                  Header: "Sección Vota",
                                  minWidth: 40,
                                  id: "SeccionVota",
                                  accessor: (d) => d.SeccionVota,
                                  filterAll: false,
                                },
                                
                                
                              ]}
                              className="-striped -highlight primary-pagination"
                              loading={loading}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={10}
                              total={totalCompromisos}
                              pages={Math.floor(totalCompromisos / 10)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={(stateComp, instance) => {
                                setLoading(true);
                                let _menuStorage = getDataMenu(8, token.menus);
                                let _menuVoluntarios = getDataMenu(7, token.menus);
                                const data = {
                                  page: stateComp.page,
                                  pageSize: stateComp.pageSize,
                                  sorted: stateComp.sorted,
                                  filtered: Filter,
                                };
                                EjecutarApi(data, "getSocialByListado", token.access_token)
                                  .then(function (res) {
                                    return res;
                                  })
                                  .then((res) => {
                                    setLoading(false);
                                    setTotalCompromisos(res.total);
                                    setCompromisos(
                                      res.data.map((prop, key) => {

                                        return {
                                          id: prop.id,
                                          Municipio: prop.MunicipioVota ? prop.MunicipioVota.label : "Sin Municipio",
                                          Region: prop.RegionVota ? prop.RegionVota.label : "Sin Region",
                                          NombreCompleto:<div> {_menuStorage.Editar === 1 && (<>
                                                            <Button
                                                                  className="btn-link"
                                                                  color="primary"
                                                                  onClick={() => {
                                                                    btnEditar(prop);
                                                                  }}
                                                          ><i class="fa fa-pencil fa-lg" aria-hidden="true"></i></Button></>)}
                                                          {_menuVoluntarios.Editar === 1 ? (<>  <Button
                                                            className="btn-link"
                                                            color="primary"
                                                            onClick={() => {
                                                              asignarResponsabilidad(prop);
                                                            }}
                                                          >{`${prop["Nombres"]} ${prop["Paterno"]} `}</Button></>) : (`${prop["Nombres"]} ${prop["Paterno"]}`)}</div>,

                                          SeccionVota: prop.SeccionVota ? prop.SeccionVota : 'Sin Sección',
                                          isVoluntario:
                                            prop["isVoluntario"] === 1 ? "Si" : "No",
                                          Responsabiliad: prop.Responsabilidad ? prop.Responsabilidad.label : "Sin Responsabilidad",
                                          Ranking: <>
                                            <Box component="fieldset" mb={3} borderColor="transparent">
                                              <Rating name="read-only" value={prop.Ranking} max={10} readOnly />
                                            </Box>
                                          </>,
                                          Etiquetas: prop.Segmentos.length > 0 ? prop.Segmentos.map(item => {
                                            return <>{item.label}</>
                                          }) : '',
                                          Colonia: prop.Colonia,
                                          data: prop,
                                        };
                                      })
                                    );

                                  })
                                  .catch((e) => {
                                    setLoading(false);
                                    notify.show(
                                      `Error al obtener Compromisos: ${e.error}`,
                                      "custom",
                                      5000,
                                      {
                                        background: "#0857B6",
                                        fontSize: 14,
                                        text: "#FFFFFF",
                                      }
                                    );


                                  });
                              }}
                            />
                          </div>
                        </>)}
                      </CardBody>
                      <CardFooter>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
            </>)}
          </>)

          }
        </div>

        <Footer fluid />
      </div>
    </div>
  );
};

export default SocialCompromisoGrafica
  ;  