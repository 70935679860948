import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { RotateSpinner } from "react-spinners-kit";
import PerfectScrollbar from "perfect-scrollbar";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from "GlobalStorage";
import CompromisosEdit from "components/Compromisos/CompromisosEdit";
import SetResponsabilidad from 'pages/Compromiso/VoluntariosSetResponsabilidad';
import FiltroUsr from "components/Filtro/indexUsuario";

var ps;
const HistorialCapturas = (props) => {
    const mainPanel = useRef();
    const token = getVars("Token");
    const [_menu, _setmenu] = useState({});
    const [loading, setLoading] = useState(false);
    const [TotalRegistros, setTotalRegistros] = useState(0);
    const [Registros, setRegistros] = useState([]);
    const [Filtro, setFiltro] = useState([]);
    const [isEditingRecord, setEditingRecord] = useState(false);
    const [compromisoEdit, setCompromisoEdit] = useState({});
    const [Datos, setDatos] = useState([]);
    const [isEditingRespon, setEditingRespon] = useState(false);
    const [showList, setShowList] = useState(true);

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }

        if (props.history.action === "PUSH") {
            mainPanel.current.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }

        let menu = getDataMenu(140, token.menus);
        if (Object.keys(menu).length > 0) {
            _setmenu(menu);
        } else {
            //El usuario no tiene el menú asignado 
        }

    }, []);

    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };

    const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
        const data = {
            page: page,
            pageSize: pageSize,
            filtered: params,
            sorted: sorted,
            tipo: "and"
        };
        getRegistros(data);
    }, []);

    const getRegistros = (data) => {
        setLoading(true);
        EjecutarApi(data, "getHistorialCapturasPropias", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setRegistros(res.data);
                    setTotalRegistros(res.total);
                } else {
                    notify.show(res.message, "error", 5000);
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                notify.show("Error al obtener registros", "error", 5000);
            })
    }

    const columns = [
        {
            Header: "Fecha",
            id: "c.FechaCreate",
            accessor: "FechaCreate"
        },
        {
            Header: "Nombre Completo",
            id: "c.Nombres",
            accessor: (item) => {
                return (
                    item.flagEdita === 1 ? (
                        <Button
                            className="btn-link"
                            color="primary"
                            onClick={() => {
                                btnEditar(item);
                            }}
                        ><i class="fa fa-pencil" aria-hidden="true"></i>{item.NombreCompleto}</Button>)
                        : item.NombreCompleto
                )
            }
        },
        {
            Header: "Seccion Vota",
            id: "c.SeccionVota",
            accessor: (item) => {
                return (
                    item.invalidSeccion === 1 ? (item.SeccionVota === null ? <><i class="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i><strong> No especificada</strong></> : <><i class="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i><strong> {item.SeccionVota}</strong></>)
                        : item.SeccionVota
                )
            }
        },
        {
            Header: "Municipio",
            id: "cm.Municipio",
            accessor: (item) => {
                return (
                    item.invalidMpio === 1 ? (item.Municipio === null ? <><i class="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i><strong> No especificado</strong></> : <><i class="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i><strong> {item.Municipio}</strong></>)
                        : item.Municipio
                )
            }
        },
        {
            Header: "Voluntario",
            id: "c.isVoluntario",
            accessor: "isVoluntarioLabel"
        },
        {
            Header: "Responsabilidad",
            id: "c.ResponsabilidadData",
            accessor: (item) => {
                return (
                    item.flagEditaRespon === 1 ? (
                        <Button
                            className="btn-link"
                            color="primary"
                            onClick={() => {
                                setEditarResponsabilidad(item);
                            }}
                        ><i class="fa fa-pencil" aria-hidden="true"></i>{item.ResponsabilidadData}</Button>
                    )
                        : item.ResponsabilidadData
                )
            }
        },
        {
            Header: "Tiene Usuario",
            id: "idUsuario",
            accessor: "TieneUsuario"
        },
        {
            Header: "Colonia",
            id: "c.ColoniaVive",
            accessor: "ColoniaVive"
        },
    ];

    const getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            let invalidSeccion = rowInfo.row._original.invalidSeccion;
            let invalidMpio = rowInfo.row._original.invalidMpio;
            return {
                style: {
                    background: (invalidSeccion || invalidMpio) ? '#FFEBE7' : '',
                    color: (invalidSeccion || invalidMpio) ? 'red' : 'black'
                }
            }
        }
        return {};
    }

    const btnEditar = (item) => {
        setCompromisoEdit(item);
        setEditingRecord(true);
        setShowList(false);
    }

    const btnCancelarEditar = (props) => {
        setEditingRecord(false);
        setEditingRespon(false);
        setShowList(true);
    };
    const btnActualizar = (props) => {
        setEditingRecord(false);
        setEditingRespon(false);
        setShowList(true);
    };

    const setEditarResponsabilidad = (item) => {
        let permiso = getDataMenu(7, token.menus);
        let parameters = {
            filterBy: "c.id",
            filterValue: item.id
        };
        EjecutarApi(parameters, "getVoluntarioByParams", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setDatos({ Menu: permiso, idCompromiso: item.id, Compromiso: res.data });
                    setEditingRespon(true);
                    setShowList(false);
                } else {
                    setShowList(true);
                }
            }).catch((e) => {
                notify.show(`Error al obtener los datos del compromiso: ${e.error}`, "error", 5000);
                setShowList(true);
            })
    }

    const handleFiltro = (filter) => {
        var _filtro = [];
        if (filter.Nombre.length > 0) {
            _filtro.push({ id: "NombreCompleto", value: filter.Nombre })
        }
        if (filter.Celular.length > 0) {
            _filtro.push({ id: "c.Celular", value: filter.Celular })
        }
        setFiltro(_filtro);
        let params = {
            page: 0,
            pageSize: 10,
            filtered: _filtro,
            sorted: [],
            tipo: "and"
        };;
        getRegistros(params)
    }

    const Exportar = () => {
        const data = {
            UserOwned: token.user.id
          };
          var datos = JSON.stringify(data)
          var url = new URL(
            `${URL_PUBLICA}exportCompromisosRCOSocial?token=${token.access_token}&serializar=${datos}`
          );
          window.open(url, "_blank");
    }

    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel ps ps--active-y" ref={mainPanel}>
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    {_menu.Social == 1 && (
                    <Row>
                        <Col>
                            <Button
                              name="btnExportar"
                              size="sm"
                              type="button"
                              className="primary float-right"
                              color="primary"
                              onClick={() => Exportar()}
                            >
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-lg" />
                              </span>
                              Exportar
                            </Button>
                        </Col>
                    </Row>
                    )}
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm="12" lg="12" md="12">
                                            <FiltroUsr Menu={_menu} Datos={(e) => handleFiltro(e)} />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {isEditingRecord && (
                                        <CompromisosEdit
                                            btnCancelar={btnCancelarEditar}
                                            btnActualizar={btnActualizar}
                                            compromisoID={compromisoEdit}
                                        />
                                    )}
                                    {isEditingRespon && (
                                        <SetResponsabilidad
                                            btnCancelar={btnCancelarEditar}
                                            menu={Datos}
                                        />
                                    )}
                                    {showList && (
                                        <>
                                            < RotateSpinner color={"#1261ff"} size={30} loading={loading} />
                                            <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                                                <ReactTable
                                                    style={{
                                                        width: "110%",
                                                        height: "100%"
                                                    }}
                                                    size='sm'
                                                    data={Registros}
                                                    noDataText="Sin información"
                                                    manual
                                                    columns={columns}
                                                    className="-striped -highlight primary-pagination"
                                                    loading={loading}
                                                    showPagination={true}
                                                    showPaginationBottom={true}
                                                    showTotalPages={true}
                                                    showPageJump={false}
                                                    canNextFromData={true}
                                                    defaultPageSize={10}
                                                    total={TotalRegistros}
                                                    pages={Math.floor(TotalRegistros / 10)}
                                                    previousText="Anterior"
                                                    nextText="Siguiente"
                                                    ofText="de"
                                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                                    PaginationComponent={Pagination}
                                                    onFetchData={fetchData}
                                                    params={Filtro}
                                                    getTrProps={getTrProps}
                                                />
                                            </div>
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Footer fluid />
            </div>
        </div >);
};

export default HistorialCapturas;