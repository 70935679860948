import React, { useEffect, useState } from "react";
// Import Highcharts
import { render } from "react-dom";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsReact from "highcharts-react-official";

drilldown(Highcharts);

const DrillDown = ({data}) => {

  const state  = {
    options: {
      chart: {
        type: "column",
        events: {
          drilldown: function(e) {
            if (!e.seriesOptions) {
              eval(data.SerieOption);
            }
          }
        }
      },

      title: {
        text: data.title || '',
        style: {
          fontSize: "15px",
          fontWeight: "bold",
          color: "#123E69"
        }
      },
      subtitle: {
        text: data.subtitle || ''
      },
      accessibility: {
        announceNewData: {
            enabled: true
        }
    },
      xAxis: {
        type: "category"
      },
      yAxis: {
       // min: 0, // Lowest value to show on the yAxis
        title: {
          text: data.yAxis // Title for the yAxis
        }
      },
      legend: {
        enabled: true // Enable/Disable the legend
      },

      tooltip: {
        shared: false // If you have multiple series then all points in each category will show up on one tooltip
      },

      series: data.serie
    }
  }
 

  // componentDidMount() {
  //   // const chart = refs.chartComponent.chart;
  // }

  // categoryClicked() {
  //   allowChartUpdate = true;

  // }

    return (
      <HighchartsReact
        // allowChartUpdate={allowChartUpdate}
        // ref={'chartComponent'}
        highcharts={Highcharts}
        options={state.options}
      />
    );
}

export default DrillDown;
