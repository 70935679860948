import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import { getVars } from "GlobalStorage";
import Enlace from 'components/Social/Enlace';
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import Notifications, { notify } from "react-notify-toast";

const SocialResponsabilidad = (props) => {
  const token = getVars("Token");
  const [menu, _setmenu] = useState({});
  const [statTotales, setStatTotales] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _setmenu(getDataMenu(22, token.menus));
    cardTotal();
  }, []); //loadingTotal, loadingCompromisos
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };
  const cardTotal = () => {
    //getStatsSocialPorEstructuraWeb
    EjecutarApi({}, 'getStatsSocialPorEstructuraWeb', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setStatTotales(res.data)
        } else {
          //showMessage("No Info: catCompromisosWeb")
          setLoading(false)
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en el listado: ${e.message}`, "error", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>
            <Col lg="4" md="4" sm="4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div name='d1' className="icon-big text-center icon-primary">
                        <i className="fa fa-cogs text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div name='d2' className="numbers">
                        <p className="card-category">Total Estructuras</p>
                        <CardTitle tag="p">{statTotales.totalEstructuras ? statTotales.totalEstructuras.total : 0}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Estatales
											</Col>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Municiales
											</Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalEstructuras ? statTotales.totalEstructuras.estructuras[0].total : 0}</h6>
                    </Col>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalEstructuras ? statTotales.totalEstructuras.estructuras[1].total : 0}</h6>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div name='d3' className="stats" onClick={() => cardTotal()}>
                    <i className="fa fa-refresh" />
                    Actualizar
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div name='d4' className="icon-big text-center icon-primary">
                        <i className="fa fa-users text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div name='d5' className="numbers">
                        <p className="card-category">Total de Compromisos</p>
                        <CardTitle tag="p">{statTotales.totalCompromisos ? statTotales.totalCompromisos.total : 0}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Mujeres
											</Col>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Hombres
											</Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalCompromisos ? statTotales.totalCompromisos.compromisos[1].total : 0}</h6>
                    </Col>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalCompromisos ? statTotales.totalCompromisos.compromisos[0].total : 0}</h6>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div name='d6' className="stats">
                    <i className="fa fa-refresh" />
                    Actualizar
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div name='d7' className="icon-big text-center icon-primary">
                        <i className="fa fa-user text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Responsables</p>
                        <CardTitle tag="p">{statTotales.totalEnlaces ? statTotales.totalEnlaces.total : 0}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Mujeres
											</Col>
                    <Col md="6" xs="5" className='text-muted text-center' >
                      Hombres
											</Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalEnlaces ? statTotales.totalEnlaces.enlaces[1].total : 0}</h6>
                    </Col>
                    <Col md="6" xs="5" className='text-center' >
                      <h6>{statTotales.totalEnlaces ? statTotales.totalEnlaces.enlaces[0].total : 0}</h6>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div name='d8' className="stats">
                    <i className="fa fa-clock-o" />
                    Actual
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row className='text-align-center-center'>
            <Col sm='12' className='text-align-center-center'>
              <Card >
                <CardTitle>

                </CardTitle>
                <CardBody>
                  <Enlace key={props} />
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};


export default SocialResponsabilidad;
