import React, { useState } from 'react';
import { Row,Col, Button,Modal,ModalHeader,ModalBody, ModalFooter, Label } from 'reactstrap';
import { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';
import ReactDatetime from "react-datetime";
import moment from "moment";
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";

const EditarMetaEstructura =({onHide,OnEdit}) =>{
    const token = getVars("Token");
    const [loading, setLoading] = useState(false);
    const meta = ({
       fechaInicio: OnEdit?moment(OnEdit.fechaInicio ).format('YYYY-MM-DD'):"",
       fechaFin: OnEdit?moment(OnEdit.fechaFin ).format('YYYY-MM-DD'):'',
    });
    const [newMeta,setNewMeta]= useState({
        fechaInicio:meta.fechaInicio?meta.fechaInicio:"",
        fechaFin:meta.fechaFin?meta.fechaFin:""
    });

    const setMetaUpdate=()=>{
        setLoading(true);
      

        const fm = moment(meta.fechaInicio);
        const fn = moment(newMeta.fechaInicio);
        const di = fm.diff(fn,'days');
  
        const fmf = moment(meta.fechaFin);
        const fnf = moment(newMeta.fechaFin);
        const dif = fmf.diff(fnf,'days');
  
        var fi=moment(meta.fechaInicio);
        var ff=moment(newMeta.fechaFin);
        var difMetas=ff.diff(fi, 'days');
  
      if(difMetas > 1){
          if(di !==0 || dif !==0){
        var parametros={
            "FechaInicioCompromiso": newMeta.fechaInicio,
            "FechaMetaCompromiso":newMeta.fechaFin
         }
         
  
          EjecutarApi(parametros, "setFechaMetaCompromisos", token.access_token, "POST")
          .then(res => {
            if (res.results) {
            setLoading(false);
              showMessage("Cambio de fecha exitoso!!","#1BD350")}
              onHide();
          })
          .catch((e) => {
            setLoading(false);
            showMessage("Error: set fecha")
          }).then(() => {
            setLoading(false);
          })
        }
      }
      else{
        showMessage("La fecha final es menor a la inicial","#F1370E")
      }
  
      }
  
 const showMessage = (message,background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }
    return(
        <Modal
            isOpen={true}
            toggle={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <ModalHeader toggle={onHide}>
            "Editar Fecha Compromisos"
        </ModalHeader>
        <ModalBody>
        {loading && <MetroSpinner size={30} color="#686769" loading={loading} />}
        <Row className="justify-content-md-center">
                            <Col >
                            <Label for="fechaInicio">Fecha de Inicio</Label>
                            
                                <ReactDatetime
                                  align="center"
                                  size="sm"
                                  id="fechaInicio"
                                  name="fechaInicio"
                                  dateFormat="YYYY-MM-DD"
                                  timeFormat={false}
                                  closeOnSelect
                                  defaultValue={meta.fechaInicio}
                                  value={newMeta.fechaInicio}
                                  onChange={(e) =>
                                    setNewMeta({
                                      ...newMeta,
                                      fechaInicio: moment(e._d).format("YYYY-MM-DD"),
                                    })
                                  }
                                  inputProps={{ className: "form-control" }}
                                />
                              </Col>
                              <Col>
                                  <Label for="fechaFin">Fecha Final</Label>
                                  <ReactDatetime
                                  id="fechaFin"
                                  name="fechaFin"
                                  dateFormat="YYYY-MM-DD"
                                  timeFormat={false}
                                  closeOnSelect
                                  defaultValue={meta.fechaFin}
                                  value={newMeta.fechaFin}
                                  onChange={
                                    (e) =>
                                    {
                                      var fi=moment(meta.fechaInicio);
                                      var ff=moment(meta.fechaFin);
                                      var dif=ff.diff(fi, 'days');
                                    if(dif > 1){
                                      setNewMeta({
                                      ...newMeta,
                                      fechaFin: moment(e._d).format("YYYY-MM-DD"),
                                    })}
                                    else{
                                      showMessage("La fecha final es menor a la inicial","#F1370E")
                                    }

                                  }
                                  }
                                />
                              </Col>
                      </Row>
                </ModalBody>
        <ModalFooter className="justify-content-md-center">
            <Row >
                <Col>
                    <Button color='primary' className='primary' size='sm' onClick={(e)=> setMetaUpdate(e)} >Guardar</Button>               
                </Col>
                <Col>
                    <Button color='danger' className='danger' size='sm' onClick={(e)=> onHide()} >Cancelar</Button>               
                </Col>
            </Row>
        </ModalFooter>
    </Modal>
  
);
}
export default EditarMetaEstructura;