import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const SexoChart = (props) => {
  let data = props.data

    const options = {
      credits: {
         enabled: false
      },
      chart: {
          
              plotBackgroundColor: null,
              plotBorderWidth: 0,
              plotShadow: false,
              height: '200px'
          },
          title: {
              text: '',
              align: 'center',
              verticalAlign: 'middle',
              y: 0
          },
          tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage}%</b>'
          },
          accessibility: {
              point: {
                  valueSuffix: '%'
              }
          },
          plotOptions: {
              pie: {
                  dataLabels: {
                      enabled: true,
                      distance: -50,
                      style: {
                          fontWeight: 'bold',
                          color: 'white'
                      }
                  },
                  startAngle: -0,
                  endAngle: 0,
                  center: ['50%', '50%'],
                  size: '100%'
              }
          },
          series: [{
              type: 'pie',
              name: '',
              innerSize: '50%',
              data: data
          }]
  }


  return (
      <HighchartsReact
       highcharts={Highcharts}
       options={options}
     />
  )
}

export default SexoChart
