import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Card, Row, Col, Label, CardHeader,CardBody,CardTitle,CardFooter } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import { CircleSpinner } from 'react-spinners-kit';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Pagination from 'components/Pagination/pagination';
import { URL_GENERAL } from 'data/Consts';
import EjecutarApi from 'data/getPOST';
import ModalFiltro from 'components/Filtro/indexMovilizacionPromocion';
import { getDataMenu } from 'data/menus.js';
import _ from 'lodash';
import { getVars, URL_PUBLICA_REPORTE, tiempoSije, INTERVAL } from 'GlobalStorage';
import DobleBarra from 'components/Sije/ChartInicioDobleBarra';
import UnaBarra from 'components/Sije/ChartInicioUnaBarra';
import GraficaMetas from 'components/Charts/NumeraliaMetas/index';
import LineaTiempo from 'components/Charts/Tablero/ChartRezagoCaptura';

const OperacionMovilizacion = (props) => {
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Filtro, setFiltro] = useState([]);
  const [FiltroGrafica, setFiltroGrafica] = useState({});
  const [vista, setVista] = useState(6);
  const [sorted, setSortered] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [data, setData] = useState(0);
  const [isLoadingGrafica, setIsLoadingGrafica] = useState(false);
  const [counter, setCounter] = useState(0);
  const [dash, setDash] = useState(0);
  const [dashFilter, setDashFilter] = useState([]);
  const [Flag, setFlag] = useState(1);
  const [loadingChart, setLoadingChart] = useState(false);
  const [Titulos,setTitulos] = useState([]);
  const [infoGraficasPaseLista, setInfoGraficasPaseLista] = useState({
    Seccionale: 0,
    Manzaneros: 0,
    meta_seccionales:0,
    meta_manzaneros:0,

  });
  const [getStatRGRCs,setStatRGRCs] = useState({
    RCs:0,
    RGs:0,
    MetaRC:0,
    MetaRg:0
});
  const [isViewChart, setisViewChart] = useState(false);
  
  const [infoGraficas, setInfoGraficas] = useState({
    series: [],
    categories: [],
  });
  const [infoGraficasHora, setInfoGraficasHora] = useState([]);
  const styleHeader = {
    textAlign: "center",
    overflow: 'visible',
    fontSize: '9px',
    color: '#363636',
    lineHeight: 'normal !important',
  };

  const [isLineaChart,setIsLineaChart] = useState(false);
  const [categoriaLineaTiempo,setCategoriaLineaTiempo] = useState([]);
  const [TituloLineaTiempo,setTituloLineaTiempo] = useState("");
  const [serieLineaTiempo,setSerieLineaTiempo] = useState([]);

  useEffect(() => {
    _setmenu(getDataMenu(181, token.menus));
    getDatosGrafica();
    DatosChartBingo();
    ChartMovilizacionAcumulativa();
    getStatRgsRCs();
  }, [dashFilter])


  const getDatosGrafica = () => {
    setIsLoadingGrafica(true);

    const data = {
      tipoVista: vista,
      page: 0,
      pageSize: 40000,
      filtered: Filtro,
      tipo: 'and',
    };
    EjecutarApi(data, 'movilizacion/getDashboardMovilizacion', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          let totalCasilla = _.sumBy(res.data, o => o.TotalCasillas);
          let Instaladas = _.sumBy(res.data, o => o.Instaladas);
          let Aperturadas = _.sumBy(res.data, o => o.Aperturadas);
          let CCierre = _.sumBy(res.data, o => o.CasillasCerradas);
          let Vcierre = _.sumBy(res.data, o => o.VotacionCerradas);


          let seccionales = _.sumBy(res.data, o => o.seccionales);
          let manzaneros = _.sumBy(res.data, o => o.manzaneros);
          let meta_seccionales = _.sumBy(res.data, o => o.MetaSeccionales);
          let meta_manzaneros = _.sumBy(res.data, o => o.MetaManzanera);

          let nueve = _.sumBy(res.data, o => o.nueve);
          let diez = _.sumBy(res.data, o => o.diez);
          let once = _.sumBy(res.data, o => o.once);
          let doce = _.sumBy(res.data, o => o.doce);
          let una = _.sumBy(res.data, o => o.una);
          let dos = _.sumBy(res.data, o => o.dos);
          let tres = _.sumBy(res.data, o => o.tres);
          let cuatro = _.sumBy(res.data, o => o.cuatro);
          let cinco = _.sumBy(res.data, o => o.cinco);
          let seis = _.sumBy(res.data, o => o.seis);

          setInfoGraficasPaseLista({
            Seccionale: seccionales,
            Manzaneros: manzaneros,
            meta_seccionales:meta_seccionales,
            meta_manzaneros:meta_manzaneros
          })
          setInfoGraficas(
            {
              "categories": ["Instaladas", "Aperturadas", "Casillas Cerradas", "Votación Cerrada"],
              "series": [Instaladas, Aperturadas, CCierre, Vcierre],
              "Meta": [totalCasilla, totalCasilla, totalCasilla, totalCasilla],
            });

            setInfoGraficasHora(
              {
                "categories": ["9am", "10am", "11am", "12am","1pm","2pm","3pm","4pm","5pm","6pm"],
                "series": [nueve, diez, once, doce,una,dos,tres,cuatro,cinco,seis],
                "Meta":[],
              });
        }
        else {

          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });


  };
  const getStatRgsRCs = ()=>{
    //movilizacion/getDashboardMovilizacion
    let Paramtro={"filtered":[]};
    setLoading(true);
    setLoadingChart(true);
    EjecutarApi(Paramtro, "getTotalesPaseLista", token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if(res.results)
          {
                setStatRGRCs({
                  RCs:res.data.PaseLista,
                  RGs:res.data.PaseListaRG,
                  MetaRg:res.data.MetaRG,
                  MetaRC:res.data.MetaRC,
              })
            
          }
        })
        .catch((e) => {
          notify.show(`Alerta: ${e.error}`, "error", 5000);
          setLoading(false);
          setLoadingChart(false);
        })

  }
  const header = (text) => {
    return <h6 style={styleHeader}>{text}</h6>;
  };
  const Exportar = () => {


    const data = {
      tipoVista: vista,
      page: page,
      pageSize: pageSize,
      filtered: Filtro,
      tipo: 'and',
      Export:true
    };

    var datos = JSON.stringify(data)
    var url = new URL(
      `${URL_PUBLICA_REPORTE}movilizacion/ExportDashboardMovilizacion?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");

  }

  const columns = [
    
    
    {
      Header: header('DF'),
      minWidth: 35,
      id: 'cat_df.DistritoFederal',
      accessor: 'DF',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('DL'),
      minWidth: 35,
      id: 'cat_dl.DistritoLocal',
      accessor: 'DL',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },

    {
      Header: header('Municipios'),
      minWidth: 150,
      id: 'cat_municipios_reportes.Municipio',
      accessor: 'Municipio',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Polígono'),
      minWidth: 60,
      id: 'poligono.Poligono',
      accessor: 'Poligono',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Sección'),
      minWidth: 65,
      id: 'secciones.Seccion',
      accessor: 'Seccion',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Casilla'),
      minWidth: 65,
      id: 'TotalCasillas',
      accessor: 'TotalCasillas',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Instalación'),

      minWidth: 65,
      id: 'Instaladas',
      accessor: 'Instaladas',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Apertura'),
      minWidth: 65,
      id: 'AperturaCasilla',
      accessor: 'Aperturadas',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },

    {
      Header: header('Cierre Casilla'),
      minWidth: 65,
      id: 'CierreCasilla',
      accessor: 'CasillasCerradas',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Cierre Votación'),
      id: 'CierreVotación',
      accessor: 'VotacionCerradas',
      filterAll: false,
      headerStyle: { fontSize: '2px' }
    },
    {
      Header: header("Pase de Lista"),
      headerStyle: { fontSize: '2px' },
      columns: [
        {
          Header: header('Seccionales'),
          minWidth: 35,
          id: 'LabelMetaSeccion',
          accessor: 'LabelMetaSeccion',
          filterAll: false,
          lassName: 'text-center',
          headerStyle: { fontSize: '2px' },
          
        },
        {
          Header: header('Manzaneros'),
          minWidth: 35,
          id: 'LabelMetaManzanera',
          accessor: 'LabelMetaManzanera',
          filterAll: false,
          lassName: 'text-center',
          headerStyle: { fontSize: '2px' },
          
        },
        // {
        //   Header: header('Seccionales'),
        //   accessor: "seccionales",
        //   className: 'text-center',
        //   headerStyle: { fontSize: '2px' }

        // },
        // {
        //   Header: header('Manzaneros'),
        //   accessor: "manzaneros",
        //   className: 'text-center',
        //   headerStyle: { fontSize: '2px' }
        // },
      ]
    },
    {
      Header: header("Compromisos Movilizados por Hora"),
      headerStyle: { fontSize: '2px' },
      columns: [
        {
          Header: header('9 am'),
          accessor: "nueve",
          className: 'text-center',
          headerStyle: { fontSize: '2px' }
        },
        {
          Header: header('10 am'),
          accessor: "diez",
          className: 'text-center',
          headerStyle: { fontSize: '2px' },
        },
        {
          Header: header('11 am'),
          accessor: "once",
          className: 'text-center',
          headerStyle: { fontSize: '2px' },
        },
        {
          Header: header('12 pm'),
          accessor: "doce",
          className: 'text-center',
          headerStyle: { fontSize: '2px' },
        },
        {
          Header: header('1 pm'),
          accessor: "una",
          className: 'text-center',
          headerStyle: { fontSize: '2px' },
        },
        {
          Header: header('2 pm'),
          accessor: "dos",
          className: 'text-center',
          headerStyle: { fontSize: '2px' },
        },
        {
          Header: header('3 pm'),
          accessor: "tres",
          className: 'text-center',
          headerStyle: { fontSize: '2px' },
        },
        {
          Header: header('4 pm'),
          accessor: "cuatro",
          className: 'text-center',
          headerStyle: { fontSize: '2px' },
        },
        {
          Header: header('5 pm'),
          accessor: "cinco",
          className: 'text-center',
          headerStyle: { fontSize: '2px' },
        },
        {
          Header: header('6 pm'),
          accessor: "seis",
          className: 'text-center',
          headerStyle: { fontSize: '2px' },
        },

      ]
    }
  ];

  const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
    const data = {
      tipoVista: vista,
      page: page,
      pageSize: pageSize,
      filtered: params,
      tipo: 'and',
    };
    setPageSize(pageSize);
    setPage(pageSize);
    setSortered(sorted);


    getRegistros(data)

  }, []);

  const getRegistros = (data) => {
    setLoading(true);

    EjecutarApi(data, 'movilizacion/getDashboardMovilizacion', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setLoading(false)
          setDash(res.total);
          setDashFilter(res.data);
        }
        else {

          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });

  };

  const handleFiltro = (filter) => {
    var _filtro = [];
    var vista_ = "";
    let idRegion= null;
    let idDL= null;
    let idDF= null;
    let idMunicipio= null;
    let idPoligono= null;
    let Seccion= null;
    let tipoVista= 'Estatal';
    if (filter.vista.value > 0) {
      vista_ = filter.vista.value;
      setVista(filter.vista.value)
    }
    if (filter.DF.value > 0) {
      _filtro.push({
        id: "movilizacion_dashboard.DFED", value: filter.DF.value
      })
      tipoVista='Distrito Federal';
      idDF= filter.DF.value;
    }
    if (filter.DL.value > 0) {
      _filtro.push({
        id: "movilizacion_dashboard.idDL", value: filter.DL.value
      })
      tipoVista='Distrito Local';
      idDL=filter.DL.value;
    }
    if (filter.Region.value > 0) {
      _filtro.push({
        id: "movilizacion_dashboard.idRegion", value: filter.Region.value
      })
      idRegion=filter.Region.value;
    }
    if (filter.Municipio.value > 0) {
      _filtro.push({
        id: "movilizacion_dashboard.idMunicipioReportes", value: filter.Municipio.value
      });
      idMunicipio=filter.Municipio.value;
    }
    if (filter.Poligono.value > 0) {
      _filtro.push({
        id: 'movilizacion_dashboard.idPoligono',
        value: filter.Poligono.value,
      });
      idPoligono=filter.Poligono.label
    }
    if (filter.Seccion.value > 0) {
      _filtro.push({
        id: "movilizacion_dashboard.Seccion", value: filter.Seccion.value
      })
      Seccion=filter.Seccion.value;
    }

    if (filter.Casilla.value !== 0 ) {
      _filtro.push({
        id: 'casillas_sije.NombreCasilla',
        value: filter.Casilla.label,
      });
    }

    if (filter.Instalacion.value === 0 || filter.Instalacion.value === 1) {
      _filtro.push({
        id: 'casillas_sije.isInicioInstalacion',
        value: filter.Instalacion.value,
      });
    }

    if (filter.Apertura.value === 0 || filter.Apertura.value === 1) {
      _filtro.push({
        id: 'casillas_sije.isAperturaCasilla',
        value: filter.Apertura.value,
      });
    }

    if (filter.CierreCasilla.value === 0 || filter.CierreCasilla.value === 1) {
      _filtro.push({
        id: 'casillas_sije.isCierreCasilla',
        value: filter.CierreCasilla.value,
      });
    }

    if (filter.CierreVotacion.value === 0 || filter.CierreVotacion.value === 1) {
      _filtro.push({
        id: 'casillas_sije.isCierreVotacion',
        value: filter.CierreVotacion.value,
      });
    }
    if (filter.isPaseListaP1.value === 0 || filter.isPaseListaP1.value === 1) {
      _filtro.push({
        id: 'Seccional',
        value: filter.isPaseListaP1.value,
      });
    }

    if (filter.isPaseListaP2.value === 0 || filter.isPaseListaP2.value === 1) {
      _filtro.push({
        id: 'Manzanero',
        value: filter.isPaseListaP2.value,
      });
    }
    setFiltro(_filtro);
    setFiltroGrafica({
      idRegion: idRegion,
      idDL: idDL,
      idDF: idDF,
      idMunicipio: idMunicipio,
      idPoligono: idPoligono,
      Seccion: Seccion,
      tipoVista: 'Estatal'
    })
    const data = {
      tipoVista: vista_,
      page: 0,
      pageSize: 10,
      filtered: _filtro,
      tipo: 'and',
    };

    getRegistros(data);
  };
  const DatosChartBingo=()=>{
    setLoading(true);
    setLoadingChart(true);
    var Titulo={};
    var series =[];
    var result = {};
  
    EjecutarApi(FiltroGrafica, "movilizacion/getInfoMovilizacionCompromisos", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if(res.results)
        {
            Titulo= {
                
              title: 'Bingo',//`${res.data.data.title}`,
              xAxis: {
                categories:res.data.Categories, 
                title: {
                  text:``
                }
              },
              yAxis: [{
                min: 0,
                title: {
                  text: '',//res.data.Categories,//res.data.Titulo, //`${res.data.data.yAxis.title.text}`
                }
              }],
            };
    
            series = [
              {
                name: 'Meta',
                color: 'rgba(147,220,236,.9)',
                data: res.data.Meta,
                pointPadding: 0.3,
                pointPlacement: -0.2
              }, {
                name: 'Avance',
                color: '#0041A0',
                data: res.data.Avance,
                pointPadding: 0.4,
                pointPlacement: -0.2
              }];
            setTitulos(Titulo);
            setSeries(series);
            setisViewChart(true);
            setLoading(false);
            setLoadingChart(false);
        }
      })
      .catch((e) => {
        setLoadingChart(false);
        setLoading(false);
        notify.show(`Alerta: ${e.error}`, "error", 5000);
      })
      
  }

  const ChartMovilizacionAcumulativa=()=>{
    //movilizacion/getInfoMovilizacionAcumulativa
    setLoading(true);
    setLoadingChart(true);
    EjecutarApi(FiltroGrafica, "movilizacion/getInfoMovilizacionAcumulativa", token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if(res.results)
          {
              setSerieLineaTiempo(res.data.Series);
              setCategoriaLineaTiempo(res.data.Categories);
              setTituloLineaTiempo(res.data.Titulo);
              setIsLineaChart(true);
              setLoading(false);
              setLoadingChart(false);
          }
        })
        .catch((e) => {
          notify.show(`Alerta: ${e.error}`, "error", 5000);
          setLoading(false);
          setLoadingChart(false);
        })
 
    
  }
  
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Seccionales</p>
                        <CardTitle tag="p"><strong>{new Intl.NumberFormat('en-EN').format(infoGraficasPaseLista.Seccionale)} 
                          </strong> de <strong>{new Intl.NumberFormat('en-EN').format(infoGraficasPaseLista.meta_seccionales)} </strong>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                    
                  </Row>
                </CardBody>
                <CardFooter>
                <hr />
                <div className="stats">
                <i className="fa fa-clock-o" />Pase de Lista
                </div>
            </CardFooter>
              </Card>
            </Col>

            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Manzaneros</p>
                        <CardTitle tag="p"><strong>{new Intl.NumberFormat('en-EN').format(infoGraficasPaseLista.Manzaneros)} 
                          </strong> de <strong>{new Intl.NumberFormat('en-EN').format(infoGraficasPaseLista.meta_manzaneros)} </strong>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                <hr />
                <div className="stats">
                    <i className="fa fa-clock-o" />Pase de Lista
                </div>
            </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-users text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">RGs</p>
                      <CardTitle tag="p"><strong>{new Intl.NumberFormat('en-EN').format(getStatRGRCs.RGs)} 
                        </strong> de <strong>{new Intl.NumberFormat('en-EN').format(getStatRGRCs.MetaRg)} </strong>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
              <hr />
              <div className="stats">
                  <i className="fa fa-clock-o" />Pase de Lista
              </div>
          </CardFooter>
            </Card>
          </Col>
            <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-users text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">RCs</p>
                      <CardTitle tag="p"><strong>{new Intl.NumberFormat('en-EN').format(getStatRGRCs.RCs)} 
                        </strong> de <strong>{new Intl.NumberFormat('en-EN').format(getStatRGRCs.MetaRC)} </strong>
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
              <hr />
              <div className="stats">
                  <i className="fa fa-clock-o" />Pase de Lista
              </div>
          </CardFooter>
            </Card>
          </Col>
          {/* <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-users text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">RGs</p>
                      <CardTitle tag="p">{`${infoGraficasPaseLista.Manzaneros}`}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
              <hr />
              <div className="stats">
                  <i className="fa fa-clock-o" />
              </div>
          </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-users text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">RCs</p>
                      <CardTitle tag="p">{`${infoGraficasPaseLista.Manzaneros}`}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
              <hr />
              <div className="stats">
                  <i className="fa fa-clock-o" />
              </div>
          </CardFooter>
            </Card>
          </Col> */}
          </Row>
          <Row>
            <Col sm="12">
            <Card>
                    <CardHeader>
                      <Col sm="12">
                        <ModalFiltro Datos={(e) => handleFiltro(e)} />
                      </Col>          
                    </CardHeader></Card>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Row>
                <Col lg="6" md="6" sm="6">
                  <Card>
                    <CardBody>
                      {isLineaChart && <div style={{ width: '100%' }}>
                          <UnaBarra
                                    Title="Compromisos Movilizados"
                                    Subtitle={""}
                                    Categories={infoGraficasHora.categories}
                                    Series={infoGraficasHora.series}
                                    Meta={infoGraficasHora.Meta}
                                    yAxisTitle="Movilizados"
                            ></UnaBarra>
                      </div>}
                      {loadingChart && (
                          <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#686769"
                              loading={true}
                          />
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" md="6" sm="6">
                  <Card>
                    <CardBody>
                      {isLineaChart && <div style={{ width: '100%' }}>
                       <GraficaMetas datos={series} Titulos={Titulos}  ></GraficaMetas> 
                      </div>}
                      {loadingChart && (
                          <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#686769"
                              loading={true}
                          />
                      )}
                    </CardBody>
                  </Card>
                  
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <Card>
                    <CardBody>
                        {isLineaChart && <div style={{ width: '100%' }}>
                          <DobleBarra
                            Title="Operación de Casillas"
                            Subtitle={""}
                            Categories={infoGraficas.categories}
                            Series={infoGraficas.series}
                            Meta={infoGraficas.Meta}
                            yAxisTitle="Operaciones"
                          ></DobleBarra>
                        </div>}
                        {loadingChart && (
                            <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={true}
                            />
                        )}
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card>
                    <CardBody>
                        {isLineaChart && <div style={{ width: '100%' }}>
                            <LineaTiempo Categorias={categoriaLineaTiempo} 
                                          Series={serieLineaTiempo} 
                                          TituloMpio={TituloLineaTiempo} 
                                          Titulo="Línea de Tiempo Movilización Acumulativa" />
                        </div>}
                        {loadingChart && (
                            <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={true}
                            />
                        )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Card>
                    <CardHeader>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {_menu.Exportar === 1 && !loading ? (
                          <Col sm="8">
                            <Button
                              name="btnExportar"
                              size="sm"
                              type="button"
                              className="primary float-left"
                              color="primary"
                              onClick={() => Exportar()}
                            >
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-lg" />
                              </span>
                              Exportar
                            </Button>
                          </Col>
                        ) : (
                            <></>
                          )}
                      </Row>
                      <Row>
                          {loading && (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#686769"
                              loading={loading}
                            />
                          )}
                          <Col lg="12" md="12">
                              <div style={{ width: '100%', overflow: 'auto', margin: '0px 1px', zIndex: 0,  textAlign: 'center'}} >
                                <ReactTable
                                  data={dashFilter}
                                  className="-striped -highlight"
                                  manual
                                  columns={columns}
                                  className="-striped -highlight primary-pagination"
                                  loading={loading}
                                  showPagination={true}
                                  showPaginationBottom={true}
                                  showTotalPages={true}
                                  showPageJump={false}
                                  canNextFromData={true}
                                  defaultPageSize={10}
                                  total={dash}
                                  previousText="Anterior"
                                  nextText="Siguiente"
                                  ofText="de"
                                  pageSizeOptions={[10, 20, 25, 50, 100]}
                                  PaginationComponent={Pagination}
                                  onFetchData={fetchData}
                                  params={Filtro}
                                />
                              </div>
                            </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
               
              </Row>
              
            </Col>
          </Row>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};
export default OperacionMovilizacion;
