import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

Highcharts.setOptions({
	lang: {
  	thousandsSep: ','
  }
})

const chartInicio = (props) => {
    const options = {
        chart: {
            zoomType: 'xy'
        },
        title: {
            text: props.Title
        },
        subtitle: {
            text: props.Subtitle
        },
        xAxis: [{
            categories:props.Categories,
            crosshair: true
        }],
        yAxis: [{ // Primary yAxis
            labels: {
                //format: '{value}',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            },
           title: {
                text: 'Asistencia',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            } 
        }, /* { // Secondary yAxis
           title: {
                text: 'Casillas',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            }, 
            labels: {
               // format: '{value}',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            opposite: true
        } */],
        tooltip: {
            shared: true
        },plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: { fontSize: "8px" }
                }
            },
            spline:{
                grouping: false,
                shadow: false,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: { fontSize: "8px" }
                }
            }
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'down',
           /*  x: 120,
            y: 100, 
            floating: true,*/
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        series: [{
            name: 'Casillas',
            type: 'column',
            //yAxis: 1,
            data: props.Series,
           /*  tooltip: {
                valueSuffix: ' mm'
            } */
    
        }, {
            name: 'Meta',
            type: 'spline',
            data: props.Meta,
           /*  tooltip: {
                valueSuffix: '°C'
            } */
        }]
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            type='column'
        />
    )
}

export default chartInicio;