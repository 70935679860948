import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Row,
	Col,

} from "reactstrap";
import { useHistory } from "react-router-dom";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Notifications, { notify } from "react-notify-toast";

import { RotateSpinner } from "react-spinners-kit";
import ReactTable from 'react-table';

import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import Pagination from "components/Pagination/pagination";
import { getVars, URL_PUBLICA } from "GlobalStorage";
import ModalFiltro from 'components/Filtro/indexListado';

function ElectoralListado(props) {
	const token = getVars("Token");
	const [_menu, _setmenu] = useState({});
	const [loading, setLoading] = useState(false);
	const [TotalRegistros, setTotalRegistros] = useState(0);
	const [Registros, setRegistros] = useState([]);
	const [Filtro, setFiltro] = useState([]);
	const [MenuModal] = useState({
		Compromiso: false,
		Voluntarios: false,
		RCs: false,
		RGs: true,
		Abogado: false,
		CasaAzul: false,
		Municipio: false
	});
	let history = useHistory();
	useEffect(() => {
		_setmenu(getDataMenu(53, token.menus))
	}, [])

	const handleMiniClick = () => {
		document.body.classList.toggle("sidebar-mini");
	};

	const handleBtnSetResposabilidad = () => {
		history.push("/voluntarios");

	};
	const Exportar = () => {
		var url = new URL(URL_PUBLICA +
			`getReporteListadoElectoral?token=${token.access_token}`
			//` http://127.0.0.1:8000/api/getReporteListadoElectoral?token=${token.access_token}`

		);
		window.open(url, "_blank");
	}
	var auxfiltro = {}
	const handleFiltro = (filter) => {
		var _filtro = [];


		var NombreCompletoFiltro = "";
		if (filter.Nombre.length > 0) {
			NombreCompletoFiltro = filter.Nombre;
			//auxfiltro={id:"u.Celular",value:filter.Nombre}
			//_filtro.push({id:"u.Celular",value:filter.Nombre})
		}
		if (filter.Celular.length > 0) {
			auxfiltro = { id: "u.Celular", value: filter.Celular }
			_filtro.push({ id: "u.Celular", value: filter.Celular })
		}
		if (filter.Responsabilidad.length > 0) {
			auxfiltro = { id: "cr.Responsabilidad", value: filter.Responsabilidad }
			_filtro.push({ id: "cr.Responsabilidad", value: filter.Responsabilidad })
		}

		setFiltro(_filtro);
		let _menuStorage = getDataMenu(53, token.menus);
		const data =
		{
			page: 0,
			pageSize: 10,
			filtered: _filtro,
			tipo: 'and',
			NombreCompleto: NombreCompletoFiltro
		};
		EjecutarApi(data, "getUsuariosElectorales", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				setLoading(false);
				setTotalRegistros(res.total);
				setRegistros(
					res.data.map((item) => {
						return {
							id: item.id,
							Nombre: item.Nombre,
							SeccionVota: item.SeccionVota,
							Responsabilidad: item.Responsabilidad.label,
							valor: item.Responsabilidad.valor,
						};
					})
				);

			})
			.catch((e) => {
				setLoading(false);
				notify.show(
					`Error al obtener Compromisos: ${e.error}`,
					"custom",
					5000,
					{
						background: "#0857B6",
						fontSize: 14,
						text: "#FFFFFF",
					}
				);


			});

	}
	const columns = [

		{
			Header: "Nombre",
			minWidth: 110,
			id: "Nombre",
			accessor: (d) => d.Nombre,
			filterAll: false,
		},

		{
			Header: "Sección Vota",
			minWidth: 30,
			id: "SeccionVota",
			accessor: (d) => d.SeccionVota,
			filterAll: false,
		},
		{
			Header: "Responsabilidad",
			minWidth: 100,
			id: "Responsabilidad",
			accessor: (d) => d.Responsabilidad,
			filterAll: false,
		},

		{
			Header: "",
			minWidth: 100,
			id: "valor",
			accessor: (d) => d.valor,
			filterAll: false,
		},
		/*
		{
		  Header: "Fecha Propuesta",
		  accessor: (d) => d.FechaPropuesta,
		  minWidth: 40,
		  id: "FechaPropuesta",
		  filterable: false,
		},
		{
		  Header: "Quién Propone",
		  accessor: (d) => d.QuienPropone,
		  minWidth: 100,
		  id: "QuienPropone",
		  filterable: false,
		},*/
	];
	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel">
				<Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
				<div className="content">
					<Row>
						<Col sm="12">
							<Card>
								<CardHeader>
									<h5 className="title">Listado de Responsabilidad</h5>
									<Row>
										{_menu.Agregar === 1 ? (<><Button color="success" size="sm" onClick={() => handleBtnSetResposabilidad()} >
											<span className="btn-label">
												<i className="fa fa-user-plus fa-lg" />
											</span>
											Capturar
												</Button></>) : <></>}
										{_menu.Exportar === 1 ? (
											<Button size='sm' className='primary left-align' color='primary' onClick={() => Exportar()}>
												<span className="btn-label">
													<i className="fa fa-file-excel-o fa-lg" />
												</span>
												Exportar
												</Button>
										) : <></>}
									</Row>
									<Row>
										<Col sm="12">
											<ModalFiltro Menu={MenuModal} Datos={(e) => handleFiltro(e)} />
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									{<RotateSpinner color={"#1261ff"} size={30} loading={loading} />}
									<div style={{ 'width': '100%', 'overflow': 'auto' }}>
										<ReactTable
											style={{
												width: "110%",
												height: "100%"
											}}
											size='sm'
											data={Registros}
											noDataText="Sin información"
											manual
											columns={columns}
											className="-striped -highlight primary-pagination"
											loading={loading}
											showPagination={true}
											showPaginationBottom={true}
											showTotalPages={true}
											showPageJump={false}
											canNextFromData={true}
											defaultPageSize={10}
											total={TotalRegistros}
											pages={Math.floor(TotalRegistros / 10)}
											previousText="Anterior"
											nextText="Siguiente"
											ofText="de"
											pageSizeOptions={[10, 20, 25, 50, 100]}
											PaginationComponent={Pagination}
											onFetchData={(stateComp, instance) => {
												setLoading(true);
												let _menuStorage = getDataMenu(53, token.menus);
												const data =
												{
													page: stateComp.page,
													pageSize: stateComp.pageSize,
													filtered: Filtro,
													NombreCompleto: auxfiltro,
													tipo: 'and'
												};
												EjecutarApi(data, "getUsuariosElectorales", token.access_token)
													.then(function (res) {
														return res;
													})
													.then((res) => {
														setLoading(false);
														setTotalRegistros(res.total);
														setRegistros(
															res.data.map((item) => {
																return {
																	id: item.id,
																	Nombre: item.Nombre,
																	SeccionVota: item.SeccionVota,
																	Responsabilidad: item.Responsabilidad.label,
																	valor: item.Responsabilidad.valor,

																};
															})
														);

													})
													.catch((e) => {
														setLoading(false);
														notify.show(
															`Error al obtener Compromisos: ${e.error}`,
															"custom",
															5000,
															{
																background: "#0857B6",
																fontSize: 14,
																text: "#FFFFFF",
															}
														);


													});
											}}

										/></div>
								</CardBody>
							</Card>
						</Col>
					</Row>

				</div>
				<Footer fluid />
			</div>
		</div>
	);
}
export default ElectoralListado;