import React, { useEffect, useState } from "react";


//Zincri imports
import ChoropethMap from "components/Maps/Promocion/ChoropethMapV5CompromisosWebLibre.jsx";
import { getVars } from "GlobalStorage";
import {
  Button
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { URL_GENERAL } from "data/Consts";
import SeccionZoom from 'data/CallCenter/seccion_zoom.json'
import { getDataMenu } from "data/menus";

const Mapas = (props) => {
  const token = getVars("Token");
  //const [myData, setMyData] = useState('');
  const [dataGeoJson, setDataGeoJson] = useState(
    {
      "type": "FeatureCollection",
      "features": [
      ]
    }
  );

  const [tipoMapa, setTipoMapa] = useState(
    { value: "seccion", label: "Seccional" }

  );
  //const [textoInput, setTextoInput] = useState("");
  const [zoom, setZoom] = useState(9);
  //const [derechoMapa, setDerechoMapa] = useState([]);
  //const [catTipoMapa, setCatTipoMapa] = useState([]);

  const [rangos, setRangos] = useState([]);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState("");
  const hostserver = URL_GENERAL;
  const [menu, _setmenu] = useState({});
  /* const position = {
    latitude: 20.678416,
    longitude: -101.354231,
  }; */
  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  useEffect(() => {
    //const token = getVars("Token").access_token;
    _setmenu(getDataMenu(87, token.menus));
    //AQUI VA IR LA RESPONSABILIDAD PARA MARCAR EL MAPA POR DEFECTO 
    //depende su responsabilidad setearemos el estado para mandarselo al hook del mapa
    //selectDerecho();



  }, [dataGeoJson]);

  const basicAlert = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );
  async function getRangos() {
    let config = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };
    try {
      let res = await fetch(hostserver + "getRangosCompromisosGtoML", config);
      let data = await res.json();
      setRangos(data);
    } catch (error) {
      setMensajeAlert("Favor de renovar sesión, si el error persiste no cuenta con permisos para ver este mapa.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  /* function selectDerecho(){
    //let derecho_mapa = getVars("Token").user.Responsabilidad.label; 
    setDerechoMapa(
      derecho_mapa === 'Coordinador General' ||
      derecho_mapa === 'Supervisor Estatal Electoral' ||
      derecho_mapa === 'Coordinador Estatal Territorial'?[1,2,3,4]:
      derecho_mapa === 'Regional'?[1,2,3,4]:
      derecho_mapa === 'Municipal'?[1,2,3,]:
      derecho_mapa === 'Poligonal'?[1,2]:
      derecho_mapa === 'Seccional'?[1]:
      []
    );
    if(derecho_mapa === 'Coordinador General' ||
    derecho_mapa === 'Supervisor Estatal Electoral' ||
    derecho_mapa === 'Coordinador Estatal Territorial' ||
    derecho_mapa === 'Regional'){
      getRegiones("","GET");
    }
    if(derecho_mapa === 'Municipal'){
      getMunicipios("","GET");
    }
    if(derecho_mapa === 'Poligonal'){
      getPoligonos("","GET");
    }
    if(derecho_mapa === 'Seccional'){
      getSecciones("","GET");
    }

  } */

  /* function setValue(item){
    setMyData(item);
    alert("Value:  " + item);
  } */

  function btnBuscar(seccion) {
    const Seccion = seccion;

    if (Seccion.length > 0) {
      const InfoSeccion = SeccionZoom.data.filter(item => item.seccion === Seccion);

      if (InfoSeccion.length) {
        setZoom(InfoSeccion[0].zoom);
      }

      btnSearchSeccion(Seccion);
    } else {
      setMensajeAlert("¡Favor de especificar una sección!");
      setFlagAlert(true);
    }


  }

  async function btnSearchSeccion(Seccion) {
    getRangos();
    setDataGeoJson({});
    const token = getVars("Token").access_token;
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ clave: Seccion }),
      };


      let res = await fetch(
        hostserver + "getSeccionesDFCompromisosGtoBusquedaWeb",
        config
      );
      let data = await res.json();
      var json_parser_secciones = JSON.parse(data.seccion);
      var json_parser_manzanas = JSON.parse(data.manzanas);


      if (json_parser_secciones.features === null) {
        throw new Error("features null");
      }
      if (json_parser_manzanas.features === null) {
        json_parser_manzanas = {
          "type": "FeatureCollection",
          "features": [
          ]
        }
      }
      //json_parser_manzanas
      json_parser_manzanas.features.forEach(element => json_parser_secciones.features.push(element));

      setDataGeoJson(json_parser_secciones);
      //setDataGeoJson(json_parser_secciones);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };




  async function getSecciones(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + "getSeccionesCompromisosGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Favor de renovar sesión, si el problema persiste es probable que no cuente con permisos para ver este mapa.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }



  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapaparametros(e);
  }

  const handleSearhSeccion = (e) => {
    btnBuscar(e);
  }
  const handleTipoMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapa(e);
  }
  function selectmapa(tipomapa) {

    if (tipomapa.value === 'seccion') {
      getSecciones("", "GET");
    }
  }

  function selectmapaparametros(tipomapa) {

    if (tipomapa.value === 'seccion') {
      getSecciones(tipomapa.clave, "POST");
    }
  }

  const clickDownloadFile = (e) => {
    if (menu.Exportar === 0) {
      setMensajeAlert("¡No cuenta con permisos para exportar!");
      setFlagAlert(true);
    }
    var url = new URL(
      hostserver + `getArchivoMapaWeb?token=${token.access_token}&HashCode=${e.feature.properties.HashCode}`
    );
    window.open(url, "_blank");
  }
  return (
    <div className="wrapper">
      <div hidden id="dataVars" name="dataVars" ></div>
      <div hidden id="idUser" name="idUser" ></div>
      <div hidden id="DeviceID" name="DeviceID" ></div>
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <div className="content">
          <Button hidden id="btnS" name="btnS" onClick={() => btnBuscar()}> Buscar</Button>
          <>
            {FlagAlert ? basicAlert : <></>}
            <ChoropethMap
              key={"ChoropethMapV4"}
              center={[position.latitude, position.longitude]}
              zoom={zoom}
              data={dataGeoJson}
              rangos={rangos}
              tipo={tipoMapa.value}
              label_tipo={tipoMapa.label}
              handleTipoMapa={handleTipoMapa}
              handleDetalleMapa={handleDetalleMapa}
              handleSearhSeccion={handleSearhSeccion}
              menuExportar={menu}
              MenuPermisos={menu}
              clickDownloadFile={clickDownloadFile}
            ></ChoropethMap>

          </>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
