import React, { useState,useEffect } from 'react';
import {Row,Col, Button} from 'reactstrap';
import { notify } from "react-notify-toast";
import ReactTable from 'react-table';
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA } from "../../GlobalStorage";
import Pagination from '../Pagination/pagination';
import Filtro from 'components/Filtro/indexSocialEnlace';
import {getDataMenu} from '../../data/menus.js';

const ListadoEnlaces = ({Data,onHide}) => {
    const [loading, setLoading] = useState(false);
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    const [Estructura, setEstructura] = useState([]);
    const [totalEstructura, setTotalEstructura] = useState(0);
    useEffect(()=>{
        _setmenu(getDataMenu(23,token.menus));
    },[])
 
    const Exportar = (idEstructura) => {
      var url = new URL(URL_PUBLICA+
        `getReporteEstructuraSocialDetalle?token=${token.access_token}`
      );
      window.open(url, '_blank');
    };
    const handleFiltro = (filter) => {   
      
      var parametro = { 
          "idEstructura":Data.EstructuraId,
          "page":0,
          "pageSize":10, 
          "tipo":"and",
          "filtered":filter
      }
      EjecutarApi(parametro,'getListadoEnlacesWeb', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            if (res.data) {
              setLoading(false);
              setTotalEstructura(res.total)
              setEstructura(
                res.data.map((item) => {
                  return {
                    id: item.id,
                    Nombre:item.Nombre.toString().toUpperCase(),
                    Celular:item.Celular?item.Celular:'Sin Celular',
                    Municipio:item.Municipio?item.Municipio.toString().toUpperCase():'Sin Municipio',
                    Estructura:item.Estructura?item.Estructura.toString().toUpperCase():'Sin Estructura',
                    nickName:item.nickName?item.nickName.toString().toUpperCase():'Sin NickName',
                    Compromisos:item.total,
                    Segmentos:item.totalSegmentos
                  };
                })
              );
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          notify.show(
            `Error: ${e.error}`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        })
      
    }
    return(
        <> 
            <Col md="12">
                <Row>
                    <Col  md="12" >
                        <Button size="sm" color='primary' onClick={()=> onHide()}>Regresar</Button>
                        {_menu.Exportar===1&&(<Button size="sm" color='success' onClick={()=> Exportar()}>Exportar</Button>)}
                    </Col>
                    
                </Row>
                <Row>
                    <Col  md='12' className="text-center" >
                        <h4>Listado de Enlaces de la Estructura {Data.NombreEstructura}</h4>
                    </Col>
                </Row>
                <Row>
                  <Col>
                    <Filtro Menu={_menu}
                        Datos={(e) => handleFiltro(e)} />
                  </Col>
                </Row>
                <Row>
                    <Col sm="12" >
                    <div style={{ width: '100%', overflow: 'auto' }}>
                                  <ReactTable
                                    style={{
                                      width: '110%',
                                      height: '100%',
                                    }}
                                    name="racttab"
                                    data={Estructura}
                                    noDataText="Sin información"
                                    manual
                                    columns={[
                                      {
                                        Header: 'Nombre',
                                        minWidth: 110,
                                        id: 'Nombre',
                                        accessor: (d) => d.Nombre,
                                        filterAll: false,
                                      },
                                      
                                      // {
                                      //   Header: 'Celular',
                                      //   minWidth: 80,
                                      //   id: 'Celular',
                                      //   accessor: (d) => d.Celular,
                                      //   filterAll: false,
                                      // }
                                      // ,
                                      {
                                        Header: 'Municipio',
                                        minWidth: 80,
                                        id: 'Municipio',
                                        accessor: (d) => d.Municipio,
                                        filterAll: false,
                                      },
                                      {
                                        Header: 'nickName',
                                        minWidth: 40,
                                        id: 'nickName',
                                        accessor: (d) => d.nickName,
                                        filterAll: false,
                                      },
                                      {
                                        Header: 'Segmentos',
                                        minWidth: 40,
                                        id: 'Segmentos',
                                        accessor: (d) => d.Segmentos,
                                        filterAll: false,
                                      },
                                      {
                                        Header: 'Compromisos',
                                        minWidth: 40,
                                        id: 'Compromisos',
                                        accessor: (d) => d.Compromisos,
                                        filterAll: false,
                                      }
                                    ]}
                                    className="-striped -highlight primary-pagination"
                                    loading={loading}
                                    showPagination={true}
                                    showPaginationBottom={true}
                                    showTotalPages={true}
                                    showPageJump={false}
                                    canNextFromData={true}
                                    defaultPageSize={10}
                                    total={totalEstructura}
                                    pages={Math.floor(totalEstructura / 10)}
                                    previousText="Anterior"
                                    nextText="Siguiente"
                                    ofText="de"
                                    pageSizeOptions={[10, 20, 25, 50, 100]}
                                    PaginationComponent={Pagination}
                                    onFetchData={(stateComp) => {
                                      setLoading(true);
                                      var parametro={"idEstructura":Data.EstructuraId,
                                                      "page":stateComp.page,
                                                      "pageSize":stateComp.pageSize,
                                                      "Exportar":"Enlace"}
                                      EjecutarApi(
                                        parametro,
                                        'getListadoEnlacesWeb',
                                        token.access_token
                                      )
                                        .then(function (res) {
                                          return res;
                                        })
                                        .then((res) => {
                                          if (res.results) {
                                            if (res.data) {
                                              setLoading(false);
                                              setTotalEstructura(res.total)
                                              setEstructura(
                                                res.data.map((item) => {
                                                  return {
                                                    id: item.id,
                                                    Nombre:item.Nombre.toString().toUpperCase(),
                                                    Celular:item.Celular?item.Celular:'Sin Celular',
                                                    Municipio:item.Municipio?item.Municipio.toString().toUpperCase():'Sin Municipio',
                                                    Estructura:item.Estructura?item.Estructura.toString().toUpperCase():'Sin Estructura',
                                                    nickName:item.nickName?item.nickName.toString().toUpperCase():'Sin NickName',
                                                    Compromisos:item.total,
                                                    Segmentos:item.totalSegmentos
                                                  };
                                                })
                                              );
                                            }
                                          }
                                        })
                                        .catch((e) => {
                                          setLoading(false);
                                          notify.show(
                                            `Error: ${e.error}`,
                                            'custom',
                                            5000,
                                            {
                                              background: '#0857B6',
                                              fontSize: 14,
                                              text: '#FFFFFF',
                                            }
                                          );
                                        });
                                    }}
                                  />
                                </div>
                    </Col>
                </Row>
            </Col>
        </>  
    );
};
export default ListadoEnlaces;