import React, { useState } from 'react';
import { Row,Col, Input, Button,Modal,ModalHeader,ModalBody, ModalFooter, Label } from 'reactstrap';
import { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";

const EditarMetaEstructura =({onHide,OnEdit}) =>{
    const token = getVars("Token");
    const [Mensaje,setMensaje] = useState('')
    const [loading, setLoading] = useState(false);
    const meta=({
       id: OnEdit?OnEdit.id:'',
       seccion: OnEdit?OnEdit.label:'',
       meta: OnEdit?OnEdit.meta:'',
       tabla: OnEdit?OnEdit.tabla:''

    });
    const [newMeta,setNewMeta]= useState(meta.meta?meta.meta:"");

    const setMetaUpdate=()=>{
        setLoading(true);
        setMensaje('')
        const dif = newMeta - meta.meta;
  
        if(dif !== 0 ){
            if(newMeta === ""){
                setMensaje('Error: El campo meta esta vacio!!!')
                setLoading(false);
                return;
            }

        var parametros={
        "meta": newMeta ,
        "id":meta.id,
        "tabla":meta.tabla
        }
  
          EjecutarApi(parametros, "setMetaEstructuras", token.access_token, "POST")
          .then(res => {
            if (res.results) {
              showMessage("Configuración de la meta exitosa!","#1BD350")}
              onHide(true);
          })
          .catch((e) => {
            setLoading(false);
            showMessage("Error: setMetaCompromisos")
          }).then(() => {
            setLoading(false);
          })
        
        }
  
  
      }
  
 const showMessage = (message,background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }
    return(
        <Modal
            isOpen={true}
            toggle={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <ModalHeader toggle={onHide}>
           { "Editar Meta: "+ meta.seccion}
           
        </ModalHeader>
        <ModalBody>
        {loading && <MetroSpinner size={30} color="#686769" loading={loading} />}
            <Row md='12'  className="justify-content-md-center">
                        <Col sm="5" >
                        <Label>Ingresa Nueva Meta</Label>
                        <Input
                        style={{ textAlign: "center" } }
                        //size="xm"
                        id="MetaNuevaPromocion"
                        min={0}
                        value={newMeta}
                        type="number"
                        onChange={(e) =>
                            setNewMeta(
                                e.target.value
                            )
                        }
                        />
                        </Col>
                    </Row>
                    <Row>
                    <Col sm="12">
                        <Label id='mensaje'className='text-danger' >{Mensaje}</Label>
                    </Col>
                </Row>
                </ModalBody>
        <ModalFooter className="justify-content-md-center">
            <Row >
                <Col>
                    <Button color='primary' className='primary' size='sm' onClick={(e)=> setMetaUpdate(e)} >Guardar</Button>               
                </Col>
                <Col>
                    <Button color='danger' className='danger' size='sm' onClick={(e)=> onHide(false)} >Cancelar</Button>               
                </Col>
            </Row>
        </ModalFooter>
    </Modal>
  
);
}
export default EditarMetaEstructura;