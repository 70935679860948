
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardHeader,  CardBody } from "reactstrap";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";

import Notifications, { notify } from "react-notify-toast";
import { CircleSpinner } from "react-spinners-kit";
import Select from "react-select";
import EjecutarApi from '../../data/getPOST';
import { getVars } from "../../GlobalStorage";
import ChartProductividad from 'components/Charts/Rentabilidad/BarraChart';
import datosRentabilidad from './DatosRentabilidad.json';

const Rentabilidad = props => {
    const mainPanel = useRef();
    const token = getVars("Token").access_token;
    const [loading, setLoading] = useState(false);

    const [loadingChart, setLoadingChart] = useState(true);

    const [state, setState] = useState({ idRegion: { value: null, label: 'Seleccione' },
            idMunicipio: { label: 'Seleccione', value: 0 },
            dMunicipio: { label: 'Seleccione', value: 0 },
            Tipo: { label: 'Seccional', value: 2 } });

    

    const [catRegionesFilter, setCatRegionesFilter] = useState([]);
    const [catMunicipios, setCatMunicipios] = useState([]);
    const [catMunicipiosFilter, setCatMunicipiosFilter] = useState([]);
    const [catRegiones, setCatRegiones] = useState([]);

    const [dataChart, setDataChart] = useState([]);
    const [series, setSeries] = useState([]);

    const [dataChart1, setDataChart1] = useState([]);
    const [series1, setSeries1] = useState([]);

    const [dataChart2, setDataChart2] = useState([]);
    const [series2, setSeries2] = useState([]);

    const [dataChart3, setDataChart3] = useState([]);
    const [series3, setSeries3] = useState([]);

    const [dataChart4, setDataChart4] = useState([]);
    const [series4, setSeries4] = useState([]);

    const [dataChart5, setDataChart5] = useState([]);
    const [series5, setSeries5] = useState([]);



    useEffect(() =>{
        let region ={};

        EjecutarApi({}, "getCatalogosFiltrosPromocion", token, "POST")
        .then(res => {
            if (res.results) {

                if (res.data) {
                    let _mpio=[{value:0,label:'Todos'}];
                    setCatRegiones(res.data.regiones);
                    region = res.data.regiones[0];
                    var fmunicipios = res.data.municipios.filter(fil => (fil.idRegion === region.value ));
                    var mpios= _mpio.concat(fmunicipios);
                    setCatRegionesFilter(_mpio.concat(region));
                    setCatMunicipiosFilter(mpios);
                    setState({ ...state, idRegion: region, dMunicipio:mpios, idMunicipio:mpios});
                    setCatMunicipios(res.data.municipios);
                    setCatRegionesFilter(res.data.regiones);
                    
                }
            }
        }).catch((e) => {
            setLoading(false);
        })
        .finally(x => {
            setLoading(false);
        })
        

    },[])



    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };
    const changeMunicipio = (idMunicipio) => {
        setLoading(true)
        if (idMunicipio.value > 1) {
            setState({ ...state, idRegion:state.idRegion, dMunicipio: idMunicipio, idMunicipio: idMunicipio });
            getDataChartResultado(idMunicipio.idRegion,idMunicipio.value)
            setLoading(false)
        } else {
            setState({ ...state, idRegion: state.idRegion , dMunicipio: state.dMunicipio ,idMunicipio: state.idMunicipio });
            setCatMunicipiosFilter(catMunicipiosFilter);
            setLoading(false)
        }
    }
    const changeRegion = (idRegion) => {
        if (idRegion.value > 1) {
            setState({ ...state, idRegion, dMunicipio: { label: 'Todos', value: 0 },idMunicipio: { label: 'Todos', value: 0 } });
            const misMunis = catMunicipios.filter(data => data.idRegion === idRegion.value);
            //setState({ ...state, idRegion, dMunicipio: misMunis[0] , idMunicipio: misMunis[0] });
            setCatMunicipiosFilter(misMunis);

            //getDataChartResultado(idRegion.value)

        } else {
            const misMunis = catMunicipios.filter(data => data.idRegion === state.idRegion);
            setState({ ...state, idRegion: state.idRegion , dMunicipio: { label: 'Todos', value: 0 }  ,idMunicipio: { label: 'Todos', value: 0 }  });
            setCatMunicipiosFilter(misMunis);
            //getDataChartResultado(idRegion.value)
        }
    }
    
    const getDataChartResultado = ( idRegion,idMunicipio) => {
        console.log(idRegion,idMunicipio);
        setLoadingChart(true);
        console.log(datosRentabilidad.series);
        var Series=datosRentabilidad.series.filter(fil =>
                (   fil.idRegion === idRegion.toString() 
                    && fil.idMunicipioReportes === idMunicipio.toString()
                    && fil.RentabilidadTipoPred === "Dura"
                    && fil.TipoTendencia === "Alta" ) ).map(item => {item.SECCION=parseInt(item.SECCION);
                    return item}).sort((a,b) => (a.SECCION > b.SECCION) ? 1 : ((b.SECCION > a.SECCION) ? -1 : 0));
        
        console.log(Series);
        var Series1 = Series.map(item => {
            return parseInt(item.MontoAccion)
          
        });
        var Series2 = Series.map(item => {
            return parseInt(item.MontoObra)
          
        });

        let categorua=Series.map(item =>{
            return item.SECCION
        });
        console.log(Series1);
        console.log(Series2);
        console.log(categorua);



            var series = [
                {
                    name: 'Acciones',
                    color: 'rgba(147,220,236,.9)',
                    data:Series1,
                    pointPadding: 0.3,
                    pointPlacement: -0.2
                }, {
                    name: 'Obra',
                    color: '#0041A0',
                    data:Series2,
                    pointPadding: 0.4,
                    pointPlacement: -0.2
                }];
              setDataChart(categorua);
              setSeries(series);



        //Dura baja
        Series=datosRentabilidad.series.filter(fil =>
            (   fil.idRegion === idRegion.toString() 
                && fil.idMunicipioReportes === idMunicipio.toString()
                && fil.RentabilidadTipoPred === "Dura"
                && fil.TipoTendencia === "Baja" ) ).map(item => {item.SECCION=parseInt(item.SECCION);
                return item}).sort((a,b) => (a.SECCION > b.SECCION) ? 1 : ((b.SECCION > a.SECCION) ? -1 : 0));
        Series1 = Series.map(item => {
        return parseInt(item.MontoAccion)
      
        });
        Series2 = Series.map(item => {
            return parseInt(item.MontoObra)
        
        });
        categorua=Series.map(item =>{
            return item.SECCION
        });
         series = [
            {
                name: 'Acciones',
                color: 'rgba(147,220,236,.9)',
                data:Series1,
                pointPadding: 0.3,
                pointPlacement: -0.2
            }, {
                name: 'Obra',
                color: '#0041A0',
                data:Series2,
                pointPadding: 0.4,
                pointPlacement: -0.2
            }];
          setDataChart1(categorua);
          setSeries1(series);
        
        // Alta - Switch
     
        Series=datosRentabilidad.series.filter(fil =>
            (   fil.idRegion === idRegion.toString() 
                && fil.idMunicipioReportes === idMunicipio.toString()
                && fil.RentabilidadTipoPred === "Switch"
                && fil.TipoTendencia === "Alta" ) ).map(item => {item.SECCION=parseInt(item.SECCION);
                return item}).sort((a,b) => (a.SECCION > b.SECCION) ? 1 : ((b.SECCION > a.SECCION) ? -1 : 0));
        Series1 = Series.map(item => {
        return parseInt(item.MontoAccion)
      
        });
        Series2 = Series.map(item => {
            return parseInt(item.MontoObra)
        
        });
        categorua=Series.map(item =>{
            return item.SECCION
        });
         series = [
            {
                name: 'Acciones',
                color: 'rgba(147,220,236,.9)',
                data:Series1,
                pointPadding: 0.3,
                pointPlacement: -0.2
            }, {
                name: 'Obra',
                color: '#0041A0',
                data:Series2,
                pointPadding: 0.4,
                pointPlacement: -0.2
            }];
          setDataChart2(categorua);
          setSeries2(series);

          // alta - Perdida
     
        Series=datosRentabilidad.series.filter(fil =>
            (   fil.idRegion === idRegion.toString() 
                && fil.idMunicipioReportes === idMunicipio.toString()
                && fil.RentabilidadTipoPred === "Perdida"
                && fil.TipoTendencia === "Alta" ) ).map(item => {item.SECCION=parseInt(item.SECCION);
                return item}).sort((a,b) => (a.SECCION > b.SECCION) ? 1 : ((b.SECCION > a.SECCION) ? -1 : 0));
        Series1 = Series.map(item => {
        return parseInt(item.MontoAccion)
      
        });
        Series2 = Series.map(item => {
            return parseInt(item.MontoObra)
        
        });
        categorua=Series.map(item =>{
            return item.SECCION
        });
         series = [
            {
                name: 'Acciones',
                color: 'rgba(147,220,236,.9)',
                data:Series1,
                pointPadding: 0.3,
                pointPlacement: -0.2
            }, {
                name: 'Obra',
                color: '#0041A0',
                data:Series2,
                pointPadding: 0.4,
                pointPlacement: -0.2
            }];
          setDataChart4(categorua);
          setSeries4(series);


            // BAJA - Perdida
     
        Series=datosRentabilidad.series.filter(fil =>
            (   fil.idRegion === idRegion.toString() 
                && fil.idMunicipioReportes === idMunicipio.toString()
                && fil.RentabilidadTipoPred === "Perdida"
                && fil.TipoTendencia === "Baja" ) ).map(item => {item.SECCION=parseInt(item.SECCION);
                return item}).sort((a,b) => (a.SECCION > b.SECCION) ? 1 : ((b.SECCION > a.SECCION) ? -1 : 0));
        Series1 = Series.map(item => {
        return parseInt(item.MontoAccion)
      
        });
        Series2 = Series.map(item => {
            return parseInt(item.MontoObra)
        
        });
        categorua=Series.map(item =>{
            return item.SECCION
        });
         series = [
            {
                name: 'Acciones',
                color: 'rgba(147,220,236,.9)',
                data:Series1,
                pointPadding: 0.3,
                pointPlacement: -0.2
            }, {
                name: 'Obra',
                color: '#0041A0',
                data:Series2,
                pointPadding: 0.4,
                pointPlacement: -0.2
            }];
          setDataChart5(categorua);
          setSeries5(series);

              setLoadingChart(false);
            
            setLoadingChart(false);
     
    }
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Col sm="12" md="12" lg="12">
                            <Row>

                                <Col sm="12" md="12" lg="12" className="text-muted">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col sm="4" className="text-muted">Región:
                                                    {loading && (
                                                    <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                    />
                                                )}            {!loading && (<Select
                                                    options={catRegionesFilter}
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    value={state.idRegion}
                                                    onChange={(e) => changeRegion(e)}
                                                    id="viewTypeM"
                                                    placeholder="Filtrar por"
                                                />)}
                                                </Col>
                                                <Col sm="4" className="text-muted">Municipio:
                                                    {loading && (
                                                <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catMunicipiosFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={state.idMunicipio}
                                                onChange={(e) => changeMunicipio(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                            </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <Card>
                                        <CardBody>
                                            <h5 className="title"></h5>
                                            {state.idMunicipio.value>0?<>
                                            <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                    <ChartProductividad Categorias={dataChart} Series={series} Titulo={state.dMunicipio.label + " Duro" } TituloMpio='Tendencia Alta' />
                                                </Col>
                                            </Row>
                                            <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                    <ChartProductividad Categorias={dataChart1} Series={series1} Titulo={state.dMunicipio.label + " Duro" } TituloMpio='Tendencia Baja' />
                                                </Col>
                                            </Row>
                                            <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                    <ChartProductividad Categorias={dataChart2} Series={series2} Titulo={state.dMunicipio.label + " Switch" } TituloMpio='Tendencia Alta' />
                                                </Col>
                                            </Row>
                                            <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                    <ChartProductividad Categorias={dataChart3} Series={series3} Titulo={state.dMunicipio.label + " Switch"} TituloMpio='Tendencia Baja' />
                                                </Col>
                                            </Row>
                                            <Row id="container">
                                                <Col sm="12" md="12" lg="12">
                                                    <ChartProductividad Categorias={dataChart4} Series={series4} Titulo={state.dMunicipio.label + " Perdida"} TituloMpio='Tendencia Alta' />
                                                </Col>
                                            </Row>
                                            <Row id="container">
                                            <Col sm="12" md="12" lg="12">
                                                <ChartProductividad Categorias={dataChart5} Series={series5} Titulo={state.dMunicipio.label+ " Perdida"}  TituloMpio='Tendencia Baja' />
                                            </Col>
                                            </Row>
                                            </>:(<></>)}
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                        </Col>
                </div>
                <Footer fluid />
            </div>
        </div>
    )
}
export default Rentabilidad;