import React, { useState, useEffect } from 'react';
import {
    InputGroup, InputGroupAddon, Button, FormGroup,
    Row, Col, Input, InputGroupText
} from 'reactstrap';
import { notify } from "react-notify-toast";
import { RotateSpinner } from "react-spinners-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import EjecutarApi from "../../data/getPOST";
import { getVars } from "../../GlobalStorage";
import Select from "react-select";
import { getDataMenu } from '../../data/menus.js';

const TreeAsigEnlace = (props) => {
    const { idEstructura } = props;
    const [loading, setLoading] = useState(true);
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    const [HasMore, setHasMore] = useState(true);
    const [Enlace, setEnlace] = useState({
        idEnlace: [idEstructura],
        Enlaces: [],
        Responsable: ''
    });
    const [isEtiquetable, setisEtiquetable] = useState(false);
    const [nextFetch, setnextFetch] = useState(1);
    const [DatosResponsable, setDatosResponsable] = useState([]);
    const [total, setTotal] = useState(0);
    const [Estructura] = useState({
        idEstructura: idEstructura,
        idRegion: {},
        idMunicipio: {},
        idResponsabilidad: { value: 0, label: 'Seleccione...' },
        Responsable: '',
        idResponsable: 0
    });
    const [catSubEstructuras, setCatSubEstructuras] = useState([]);
    const [user, setUser] = useState({
        id: 0,
        FullName: '',
        Codigo: '',
        Cargo: '',
        NombreSocial: '',
        Responsabilidad: []
    });

    useEffect(() => {
        _setmenu(getDataMenu(134, token.menus));
        setLoading(true);
        EjecutarApi({idEstructura:token.user.EstructuraSocial.value,estructura:idEstructura}, 'getSubestructuraSocialWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success) {
                    let arr = [{ value: 0, label: 'Seleccionar...' }];
                    res.data.forEach(item => {

                        arr.push({
                            value: item.id,
                            label: item.NombreSocial
                        })

                    })

                    setCatSubEstructuras(arr)
                    setLoading(false);
                }

            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                    background: '#0857B6',
                    fontSize: 14,
                    text: '#FFFFFF'
                });
            });
    }, [idEstructura]);

    useEffect(() => {
        hadleData();
    }, []);
 
    const hadleData = () => {
        setLoading(true);
        var parametro = {
            idEstructura: [idEstructura],
            page: nextFetch,
            pageSize: 10
        };
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(res.data);
                    setnextFetch(1);
                    setTotal(res.totalRegistros);
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de enlaces: ${e.error}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }

    const fetchHasMore = () => {
        setLoading(true);
        if (nextFetch * 10 >= total) {
            setHasMore(false);
            return;
        }
        var parametro = {
            page: nextFetch,
            pageSize: 10,
            filtered: [{ id: "FullSearch", value: Estructura.Responsable }],
            tipo: "and",
            sorted: [{ "id": "LastUpdate", "desc": true }]
        };
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(DatosResponsable.concat(res.data));
                    setnextFetch(nextFetch + 1);
                    setTotal(res.totalRegistros);
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    };

    const BuscarEnlace = (valor) => {
        var parametro = {
            page: 0,
            pageSize: 10,
            filtered: [{ id: "FullSearch", value: valor }],
            tipo: "and",
            sorted: [{ "id": "LastUpdate", "desc": true }]
        };

        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(res.data);
                    setTotal(res.total)
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });

    }

    const saveEnlace = (e) => {
        var parametros = '';
        parametros = { data: Enlace.Enlaces };
        EjecutarApi(parametros, 'setUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    notify.show(`¡Registro Actualizado Correctamente!`, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",

                    });
                    setLoading(false);
                    setEnlace({ Estructura, Responsable: '', idEnlace: [], Enlaces: [] })
                    BuscarEnlace('');
                    //onHide();
                }
                else {
                    notify.show(`Error:${res.message}`, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                    setLoading(false);
                    //onHide();
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });
    }

    const handleSetEnlace = (e) => {
        setisEtiquetable(true);
        setUser({
            ...user, FullName: e.Nombre,
            id: e.id
        })
        setEnlace({
            ...Enlace, Responsable: e.Nombre,
            id: e.id
        })

    }

    const showMessage = (message) => {
        notify.show(message, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
    }

    const EliminarEnlace = (user, idEstructura) => {
        setLoading(true);
        if (user)
            if (user === 0) {
                notify.show('Debe seleccionar un usuario, para asignar etiquetas!', "error", 5000, {
                    background: "#0857B6",
                    text: "#FFFFFF",
                });
                return;
            }
        if (idEstructura)
            if (idEstructura === 0) {
                notify.show('Debe seleccionar una etiqueta, para asignar!', "error", 5000, {
                    background: "#0857B6",
                    text: "#FFFFFF",
                });
                return;
            }
        var parametro = {
            idUser: user,
            idEstructura: idEstructura
        }
        EjecutarApi(parametro, 'eliminarEtiquetaIndividualUsuarioWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    notify.show(res.message, "error", 5000, {
                        background: "#0857B6",
                        text: "#FFFFFF",
                    });
                    setLoading(false);
                    BuscarEnlace(Enlace.Responsable);

                }
                else {
                    notify.show(`Error: No se puede eliminar el enlace`, "error", 5000, {
                        background: "#d61818",
                        text: "#FFFFFF",
                    });
                    setLoading(false);
                    BuscarEnlace('')
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }

    return (
        <>
            <Col md="12">
                <Row>
                </Row>
                <Row>
                    <Col md="8">
                        <InputGroup className="no-border">
                            <Input value={Enlace.Responsable}
                                onChange={(e) => setEnlace({ ...Enlace, Responsable: e.target.value })}
                                placeholder="Buscar..." type="text" />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <i className="nc-icon nc-zoom-split" />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col md="4" className='text-align-bottom-left' >
                        <Button size='sm' color='warning' onClick={() => BuscarEnlace(Enlace.Responsable)}>
                            <i className="nc-icon nc-zoom-split" /></Button>
                        <Button size='sm' color='primary' onClick={() => BuscarEnlace('')} className='link-button'>
                            <i className="fa fa-eraser" aria-hidden="true" /></Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Select id="example-multiple-selected" isMulti="true" isSearchable options={catSubEstructuras}
                                onChange={(event, i) => {

                                    if (event) {
                                        setEnlace({
                                            ...Enlace, idEnlace: event.map(dato => { return dato.value })
                                            , Enlaces: event.map(dato => { return { idUser: user.id, idEstructura: dato.value } })
                                        })

                                    }
                                    else {
                                        setEnlace({
                                            ...Enlace, idEnlace: Enlace.idEnlace.map(dato => {
                                                if (i.removedValue.value !== dato) {
                                                    return dato.value
                                                }

                                            }),
                                            Enlaces: Enlace.Enlaces.map(dato => {
                                                if (i.removedValue.value !== dato.idEstructura) {
                                                    return dato
                                                }

                                            })
                                        })
                                    }


                                }}
                                defaultValue={Enlace.Enlaces}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false} />

                        </FormGroup>
                    </Col>
                </Row>
                {isEtiquetable && (<>
                    <Row>
                        <Col>

                            <Button id='btnsave' color='primary' size='sm' onClick={() => saveEnlace()}>Guardar</Button>
                            {/**<Button id='btncancela' color='danger' size='sm' onClick={onHide}>Cancelar</Button> */}
                        </Col>
                    </Row>
                </>)}
                <Row>
                    <Col sm="12" className='text-muted'>Mostrando: {DatosResponsable.length} de {total} Registros</Col>
                </Row>
                <Row>
                    <Col sm="12">
                        {<RotateSpinner color={"#1261ff"} size={30} loading={loading} />}
                        {<InfiniteScroll
                            dataLength={total}
                            next={fetchHasMore}
                            hasMore={HasMore}
                            loader={<h5>cargando...</h5>}
                            height={550}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>¡Final de la lista de registros!</b>
                                </p>}
                        >
                            <Col md="12">

                            </Col>
                            {
                                DatosResponsable.map((item, id) =>
                                (<div key={'div' + id.toString()} className="divv text-muted" >
                                    Nombre:<strong>{<Button id={"btn" + id} color="primary" size="sm" onClick={() => handleSetEnlace(item)} >{item.Nombre}</Button>}</strong>
                                    <br />
                                    Celular:{item.email}
                                    <br />
                                    Municipio:{item.MunicipioVive.label}
                                    <br />
                                    Responsabilidad:{item.Responsabilidad.label}
                                    <br />
                                    Enlaces:
                                    <br />
                                    {item.Estructuras.map((enlace, id) => {
                                        return <>

                                            {_menu.Eliminar === 1 && <Button id={"btn2" + id} className="btn-link" color="danger" size="sm" onClick={() => EliminarEnlace(item.id, enlace.value)}>X</Button>}
                                            {enlace.nickName}
                                            <br />
                                        </>
                                    })}
                                    <hr />
                                </div>)
                                )
                            }

                        </InfiniteScroll>}
                    </Col>
                </Row>
            </Col>
        </>
    );
};
export default TreeAsigEnlace;