import React, { useEffect, useState } from "react";

import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";

//Zincri imports
import ChoropethMap from "components/Maps/Promocion/ChoropethMapV5Estructura.jsx";
import { getVars } from "GlobalStorage";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { URL_GENERAL } from "data/Consts";
import { getDataMenu } from 'data/menus';

let PRESIDENTE_DE_LA_REPUBLICA = 50;
let GOBERNADOR = 51;
let SENADOR = 52;
//let DIPUTADO_FEDERAL = 53;
//let DIPUTADO_LOCAL = 54;
let PRESIDENTE_MUNICIPAL = 55;
let SUPERVISOR_ESTATAL_ELECTORAL = 98;
let COORDINADOR_ESTATAL_TERRTORIAL = 97;
let COORDINADOR_GENERAL = 99;
let COORDINADOR_GENERAL_SOCIAL = 30;
let CONST_REGIONAL = 10;
let CONST_MUNICIPAL = 11;
let CONST_POLIGONAL = 12;
let CONST_SECCIONAL = 13;
const Mapas = (props) => {

  const token = getVars("Token");
  const [menu, _setmenu] = useState({});
  const [dataGeoJson, setDataGeoJson] = useState({});
  /* const [saveTipo, setSaveTipo] = useState(
    getVars("Token").user.Responsabilidad.value === CONST_SECCIONAL ?
      { value: "seccion", label: "Seccional" } :
      getVars("Token").user.Responsabilidad.value === CONST_POLIGONAL ?
        { value: "poligono", label: "Poligonal" } :
        getVars("Token").user.Responsabilidad.value === CONST_MUNICIPAL
          || getVars("Token").user.Responsabilidad.value === PRESIDENTE_MUNICIPAL ?
          { value: "municipio", label: "Municipal" } :
          { value: "region", label: "Regional" }
  ); */
  const [tipoMapa, setTipoMapa] = useState(
    getVars("Token").user.Responsabilidad.value === CONST_SECCIONAL ?
      { value: "seccion", label: "Seccional" } :
      getVars("Token").user.Responsabilidad.value === CONST_POLIGONAL ?
        { value: "poligono", label: "Poligonal" } :
        getVars("Token").user.Responsabilidad.value === CONST_MUNICIPAL
          || getVars("Token").user.Responsabilidad.value === PRESIDENTE_MUNICIPAL ?
          { value: "municipio", label: "Municipal" } :
          { value: "region", label: "Regional" }
  );
  const zoom =
    getVars("Token").user.Responsabilidad.value === CONST_SECCIONAL ?
      12 :
      getVars("Token").user.Responsabilidad.value === CONST_POLIGONAL ?
        12 :
        getVars("Token").user.Responsabilidad.value === CONST_MUNICIPAL
          || getVars("Token").user.Responsabilidad.value === PRESIDENTE_MUNICIPAL ?
          10 :
          9;
  const [MensajeAlert, setMensajeAlert] = useState("");
  const [derechoMapa, setDerechoMapa] = useState([]);
  const [rangos, setRangos] = useState([]);
  const [FlagAlert, setFlagAlert] = useState(false);
  const hostserver = URL_GENERAL;


  /* const position = {
    latitude: 20.678416,
    longitude: -101.354231,
  }; */
  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  /* useEffect(() => {
    //if(!dataGeoJson )//Object.keys(dataGeoJson).length === 0)
    //{
    
      if(saveTipo){
      //setTipoMapa(saveTipo);
      selectmapa(saveTipo);
      }
  },[dataGeoJson]) */

  useEffect(() => {
    // const token = getVars("Token").access_token;
    _setmenu(getDataMenu(25, token.menus));

    selectDerecho()
    async function getRangos() {
      let config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      };
      try {
        let res = await fetch(hostserver + "getRangosEstructuraGto", config);
        let data = await res.json();
        setRangos(data);
      } catch (error) {
        setFlagAlert(true);
        setDataGeoJson({
          "type": "FeatureCollection",
          "features": [
          ]
        });
      }
    }
    getRangos();
  }, []);


  const basicAlert = (
    <ReactBSAlert
      style={{
        display: "block",
        marginTop: "-100px",
        padding: '2px 4px',
        font: '10px/12px Arial, Helvetica, sans-serif'
      }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );

  function selectDerecho() {
    let derecho_mapa = getVars("Token").user.Responsabilidad.value;
    setDerechoMapa(
      derecho_mapa === COORDINADOR_GENERAL ||
        derecho_mapa === PRESIDENTE_DE_LA_REPUBLICA ||
        derecho_mapa === GOBERNADOR ||
        derecho_mapa === SENADOR ||
        derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
        derecho_mapa === COORDINADOR_GENERAL_SOCIAL ||
        derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL ? [1, 2, 3, 4] :
        derecho_mapa === CONST_REGIONAL ? [1, 2, 3, 4] :
          derecho_mapa === CONST_MUNICIPAL ||
            derecho_mapa === PRESIDENTE_MUNICIPAL ? [1, 2, 3,] :
            derecho_mapa === CONST_POLIGONAL ? [1, 2] :
              derecho_mapa === CONST_SECCIONAL ? [1] :
                []
    );
    if (derecho_mapa === COORDINADOR_GENERAL ||
      derecho_mapa === PRESIDENTE_DE_LA_REPUBLICA ||
      derecho_mapa === GOBERNADOR ||
      derecho_mapa === SENADOR ||
      derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
      derecho_mapa === COORDINADOR_GENERAL_SOCIAL ||
      derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL ||
      derecho_mapa === CONST_REGIONAL) {
      getRegiones("", "GET");
    }
    if (derecho_mapa === CONST_MUNICIPAL || derecho_mapa === PRESIDENTE_MUNICIPAL) {
      getMunicipios("", "GET");
    }
    if (derecho_mapa === CONST_POLIGONAL) {
      getPoligonos("", "GET");
    }
    if (derecho_mapa === CONST_SECCIONAL) {
      getSecciones("", "GET");
    }

  }

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };



  async function getRegiones(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }

      let res = await fetch(
        hostserver + "getRegionesEstructuraGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function getMunicipios(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + "getMunicipiosEstructuraGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  
  async function getMapaVacio() {
    //const token = getVars("Token").access_token;
    try {
      let res = await  setTimeout(function(){ return true }, 2000);
      if(res){
        setDataGeoJson({
          "type": "FeatureCollection",
          "features": [
          ]
        });
      }
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function getSecciones(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + "getSeccionesEstructuraGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function getPoligonos(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + "getPoligonosEstructuraGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }


  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapaparametros(e);
  }

  const handleTipoMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapa(e);
  }

  function selectmapa(tipomapa) {
    if (tipomapa.value === 'region') {
      getRegiones("", "GET");
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios("", "GET");
    }
    if (tipomapa.value === 'poligono') {
      getPoligonos("", "GET");
    }
    if (tipomapa.value === 'seccion') {
      getSecciones("", "GET");
    }
    if (tipomapa.value === 'manzana') {
      getMapaVacio();
    }
  }

  function selectmapaparametros(tipomapa) {
    if (tipomapa.value === 'region') {
      getRegiones(tipomapa.clave, "POST");
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios(tipomapa.clave, "POST");
    }
    if (tipomapa.value === 'poligono') {
      getPoligonos(tipomapa.clave, "POST");
    }
    if (tipomapa.value === 'seccion') {
      getSecciones(tipomapa.clave, "POST");
    }
  }

  const handleExport = (e) => {

    //polligonos= filter
    var dato = [];
    e.features.forEach(element => {

      if (tipoMapa.value === 'region') {
        dato.push(element.properties['clave'])
      }
      else if (tipoMapa.value === 'municipio') {
        dato.push(element.properties['cve_mun'])
      }
      else if (tipoMapa.value === 'poligono') {
        dato.push(element.properties['poligono'])
      }
      else if (tipoMapa.value === 'seccion') {
        dato.push(element.properties['seccion'])
      }
    });
    const json = {
      data: dato,
      tabla: tipoMapa.value
    }

    const serializado = JSON.stringify(json)
    var url = new URL(
      hostserver + `getReporteMapasEstructura?token=${token.access_token}&datos=${serializado}`
    );
    window.open(url, "_blank");
  }
  const handleSearh = (e,tipo_prioridad) => {
    btnBuscar(e,tipo_prioridad);
  }
  function btnBuscar(dato,tipo_prioridad) {
    setDataGeoJson({});
    const Poligono = dato;
    if (Poligono.length > 0) {
      btnSearch(Poligono,tipo_prioridad);
    } else {
      setMensajeAlert("¡Favor de especificar una sección!");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function btnSearch(Poligono,tipo_prioridad) {
    
    const token = getVars("Token").access_token;
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Region: [],
          Municipio: [],
          Poligono: [Poligono],
          Prioridad:tipo_prioridad,
          OneByOne: true,
          Seccion: []
        }),
      };

      let res = await fetch(
        hostserver + "getPoligonoManzanaGtoWeb",
        config
      );
      let data = await res.json();
      if (data.success && data.results) {
        var json_parser_principal = JSON.parse(data.poligono);
        var json_parser_secundario = JSON.parse(data.seccion);

        if (json_parser_principal.features === null) {
          throw new Error(data.message);
        }
        if (json_parser_secundario.features === null) {
          json_parser_secundario = {
            "type": "FeatureCollection",
            "features": [
            ]
          }
        }
      }
      else {
        throw new Error(data.message);
      }

      /* json_parser_principal.features.forEach(item => {
        json_parser_secundario.features.push(item);
      });
      setDataGeoJson(json_parser_secundario); */
      const new_data = {};
      new_data.type = "FeatureCollection";
      new_data.features =[];
      json_parser_principal.features.forEach(item => {
        new_data.features.push(item);
      });
      json_parser_secundario.features.forEach(item => {
        new_data.features.push(item);
      });
      setDataGeoJson(new_data);

    } catch (error) {
      if (error.message === 'Token Signature could not be verified.'
        || error.message === 'Sin autorizacion. El token ha expirado'
        || error.message === 'El token esta en la lista negra') {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      }
      else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <div className="content">
          <>
            {FlagAlert ? basicAlert : <></>}
            <ChoropethMap
              key={"ChoropethMapV4"}
              center={[position.latitude, position.longitude]}
              zoom={zoom}
              data={dataGeoJson}
              rangos={rangos}
              tipo={tipoMapa.value}
              label_tipo={tipoMapa.label}
              handleTipoMapa={handleTipoMapa}
              handleDetalleMapa={handleDetalleMapa}
              handleSearh={handleSearh}
              derecho_mapa={derechoMapa}
              Exportar={menu.Exportar}
              MenuPermisos={menu}
              setExport={handleExport}
            ></ChoropethMap>

          </>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
