
import React, { useState,useEffect } from 'react';

import { Row,Col, Button,Label,FormGroup, ButtonGroup, Card,CardTitle, CardBody , CardFooter,Badge} from 'reactstrap';

import { MetroSpinner } from 'react-spinners-kit';
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import Notifications, { notify } from "react-notify-toast";

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";
import {getDataMenu} from '../../data/menus';


const AceptarPropuestas = (props) => {
    const [loading, setLoading] = useState(false);
    const [MenuR, setMenuR] = useState();
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    let history = useHistory();
    const [showElectoral,setShowElectoral]=useState(false);
    const [showPromocion,setShowPromocion]=useState(false);
    const [catPromocion,setcatPromocion]=useState([{value:11,label:'No Definido'}]);
    
    const [showButton,setShowButton] = useState(false);
   
    const [isElectoral,setIsElectoral]=useState({
        RC:false,
        RG:false,
        Abogado:false,
        CasaAzul:false,
        isRC:{
            Partido:false
        },
        isRG:{
            Partido:false
        },
        Observador:false
    });
    const [isPromocion,setIsPromocion]=useState({
        Regional:false,
        Municipal:false,
        Poligonal:false,
        Seccional:false,
        Manzanero:false
    })
    const [isSocial,setIsSocial]=useState({
        CoorGralSocial:false,
        CoorEstrSocial:false,
        CoorRegSocial:false,
        CoorMpalSocial:false,
        CoorEstatalSocial:false,
        CoordeEstructura:false
    })
    const [catSocial,setCatSocial] = useState([
        { value:30,label:'Coordinador General Social'},
        { value:31,label:'Coordinador Estructura Social'},
        { value:32,label:'Coordinador Regional Social'},
        { value:33,label:'Coordinador Municipal Social'}])
    const [showSocial,setShowSocial]=useState(false);
 
    const [catResponsabilidad,setCatResponsabilidad]=useState([
        {label:'RCs',value:1},
        {label:'RGs',value:2},
        {label:'Casa Azul',value:3},
        {label:'Abogado',value:4}
    ]);

    useEffect(() => {
        setLoading(true);
        _setmenu(getDataMenu(44,token.menus));
        EjecutarApi({}, 'getCatalogosPorResponsabilidadWeb', token.access_token)
			.then(function(res) {
				return res;
			})
			.then((res) => {
                var promocion = []
                var social = []
                var Territorial = []

                if(res.success)
                {
                   res.data.forEach(item=>{
                        if(item.Tipo==='Electoral')
                        {
                            promocion.push({
                                label:item.label,
                                value:item.value
                            })
                        }
                    })
                    res.data.forEach(item=>{
                        if(item.Tipo==='Social')
                        {
                            social.push({
                                label:item.label,
                                value:item.value})
                        }
                    })
                    res.data.forEach(item=>{
                        if(item.Tipo==='Promoción')
                        {
                            Territorial.push({
                                label:item.label,
                                value:item.value
                            })
                        }
                    })
                    setCatResponsabilidad(promocion);
                    setcatPromocion(Territorial);
                    setCatSocial(social)
                    setLoading(false);
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
			});

    }, [])
   
    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
      };


    const handleShowPromocion =(e)=>{
     
        setMenuR({...MenuR, AddResponsabilidad:false  });
        setShowElectoral(false);
        setShowPromocion(true);
        setShowSocial(false);

        //setMenuR({...MenuR, AddResponsabilidad:false });
        //setShowElectoral(false);
        //setShowPromocion(true);
        /*setIsElectoral({RC:false,
            RG:false,
            Abogado:false,
            CasaAzul:false,
            Municipio:false,
            isRC:{
                Partido:false
            },
            Observador:false
            });*/

    }
    

    const handleShowElectoral =()=>{

        setMenuR({...MenuR, AddResponsabilidad:false  });
        setShowElectoral(true);
        setShowPromocion(false);
        setShowSocial(false);

      
    }
    const handlePromocion = (e) =>{
   
        switch(e.label)
        {
            case'RGS':
            setIsElectoral({RC:false,
                RG:true,
                Abogado:false,
                CasaAzul:false,
                Municipio:false,
                isRC:{
                    Partido:false
                },
                Observador:false
                });
                break;
            case 'RCS':
                setIsElectoral({RC:true,
                    RG:false,
                    Abogado:false,
                    CasaAzul:false,
                    Municipio:false,
                    Observador:false});
                 
                break;
            case 'ABOGADOS':
                setIsElectoral({RC:false,
                    RG:false,
                    Abogado:true,
                    CasaAzul:false,
                    Municipio:false,
                    Observador:true});
                
                break;
            case 'CASA AZUL':
                setIsElectoral({RC:false,
                    RG:false,
                    Abogado:false,
                    CasaAzul:true,
                    Municipio:false,
                    Observador:false});
                break;   
            case 'MUNICIPIO':
                setIsElectoral({RC:false,
                    RG:false,
                    Abogado:false,
                    CasaAzul:false,
                    Municipio:true,
                    Observador:false});
                break;   
            case 'REGIONAL':
                setIsPromocion({Regional:true,
                    Municipal:false,
                    Poligonal:false,
                    Seccional:false,
                    Manzanero:false});
            
                break;  
            case 'MUNICIPAL':
                setIsPromocion({Regional:false,
                    Municipal:true,
                    Poligonal:false,
                    Seccional:false,
                    Manzanero:false});
                
                break;   
            case 'POLIGONAL':
                setIsPromocion({Regional:false,
                    Municipal:false,
                    Poligonal:true,
                    Seccional:false,
                    Manzanero:false});
               
            break; 
            case 'SECCIONAL':
                setIsPromocion({Regional:false,
                    Municipal:false,
                    Poligonal:false,
                    Seccional:true,
                    Manzanero:false
                });
            break;  
            case 'MANZANERA':
                setIsPromocion({Regional:false,
                    Municipal:false,
                    Poligonal:false,
                    Seccional:false,
                    Manzanero:true
                });
            break;     
            case 'COORDINADOR ESTATAL TERRITORIAL':
                setIsPromocion({Regional:false,
                    Municipal:false,
                    Poligonal:false,
                    Seccional:false,
                    Manzanero:false,
                    CoordEstatalTerritorial:true,   
                });
            break;              
            default:
                setIsElectoral({RC:false,
                    RG:false,
                    Abogado:false,
                    CasaAzul:false,
                    Municipio:false});
                break;
        }
        setShowButton(true);
    }
  
    const handleElectoral=(e)=>{
        if (_menu.idCompromiso !== 0)
        {
            switch(e.label)
            {
                case 'RCS':
                    
                    setIsElectoral({...isElectoral,
                        RC:true,
                        RG:false,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false,
                        CoordEstaElectoral:false,
                        SupEstatalElectoral:false,
                        CoordMpalElectoral:false,
                        CoordRegElectoral:false,
                        Observador:false});
                    //llenadoCatalogos();
                    break;
                case 'RGS':
                    setIsElectoral({RC:false,
                        RG:true,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false,
                        CoordEstaElectoral:false,
                        SupEstatalElectoral:false,
                        CoordMpalElectoral:false,
                        CoordRegElectoral:false,
                        Observador:false});
                
                    break;
                case 'ABOGADOS':
                        setIsElectoral({RC:false,
                            RG:false,
                            Abogado:true,
                            CasaAzul:false,
                            Municipio:false,
                            CoordEstaElectoral:false,
                            SupEstatalElectoral:false,
                            CoordMpalElectoral:false,
                            CoordRegElectoral:false,
                            Observador:false});
                          
                        break;
                case 'CASA AZUL':
                    setIsElectoral({RC:false,
                        RG:false,
                        Abogado:false,
                        CasaAzul:true,
                        Municipio:false,
                        CoordEstaElectoral:false,
                        SupEstatalElectoral:false,
                        CoordMpalElectoral:false,
                        CoordRegElectoral:false,
                        Observador:false});
              
                break;
                case 'OBSERVADOR':
                    setIsElectoral({RC:false,
                        RG:false,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false,
                        CoordEstaElectoral:false,
                        SupEstatalElectoral:false,
                        CoordMpalElectoral:false,
                        CoordRegElectoral:false,
                        Observador:true});
                  
                    break;
                case 'COORDINADOR ESTATAL ELECTORAL':
                    setIsElectoral({RC:false,
                        RG:false,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false,
                        CoordEstaElectoral:true,
                        SupEstatalElectoral:false,
                        CoordMpalElectoral:false,
                        CoordRegElectoral:false});
                    
                break;
                case 'SUPERVISOR ESTATAL ELECTORAL':
                    setIsElectoral({RC:false,
                        RG:false,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false,
                        CoordEstaElectoral:false,
                        SupEstatalElectoral:true,
                        CoordMpalElectoral:false,
                        CoordRegElectoral:false});
                    
                break;
                case 'COORDINADOR MUNICIPAL ELECTORAL':
                    setIsElectoral({RC:false,
                        RG:false,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false,
                        CoordEstaElectoral:false,
                        SupEstatalElectoral:false,
                        CoordMpalElectoral:true,
                        CoordRegElectoral:false});
                    
                break;
                case 'COORDINADOR REGIONAL ELECTORAL':
                    setIsElectoral({RC:false,
                        RG:false,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false,
                        CoordEstaElectoral:false,
                        SupEstatalElectoral:false,
                        CoordMpalElectoral:false,
                        CoordRegElectoral:true});
                 
                break;
                default:
                    setIsElectoral({RC:false,
                        RG:false,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false,
                        CoordEstaElectoral:false,
                        SupEstatalElectoral:false,
                        CoordMpalElectoral:false,
                        CoordRegElectoral:false});
                break;
            }
        }
        else
        switch(e.label)
        {
            case 'RCs':
                history.push("/EPropuestaRCs");
            break;
            case 'RGs':
                history.push("/EPropuestaRGs");
            break;
            case 'Abogado':
                history.push("/EPropuestaA"); 
            break;
            case 'Casa Azul':
                history.push("/EPropuestaCA");     
            break;
            case 'Abogados':
                history.push("/EPropuestaA");     
            break;
            default:
            break;
        }
        setShowButton(true);
    }
  
    const handleShowSocial=()=>{
        setMenuR({...MenuR, AddResponsabilidad:false  });
        setShowElectoral(false);
        setShowPromocion(false);
        setShowSocial(true);
       
    }
   
    const handleSelectSocial=(e)=>{
        if (_menu.idCompromiso !== 0)
        {
            switch(e.value)
            {
                case 30:
                    setIsSocial({...isSocial,
                        CoorGralSocial:true,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:false});
                    
                    break;
                case 31:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:true,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:false});
                      
                       
                    break;
                case 32:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:true,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:false});
                      
                     
                    break;
                case 33:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:true,
                        CoorEstatalSocial:false,
                        CoordeEstructura:false});
                     
                       
                    break;
                case 34:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:true,
                        CoordeEstructura:false});
                       
                    break;
                case 35:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:true});
                       
                   
                    break;
                default:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:false});
                    break;
            }
        }
        setShowButton(true);
    }
   
    return(
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col sm='12'>
                            <Card>
                                <CardTitle >
                                    <Row className='text-align-bottom-center text-center' >
                                        <Col>
                                            <h6>Aceptar Propuestas de Responsabilidad</h6>
                                        </Col>
                                    </Row>
                                </CardTitle>
                                <CardBody>
                                    {loading && <MetroSpinner size={30} color="#0000FF" loading={loading} />}
                                    
                                        <Row md='12'>
                                            <Col md="12" className="center text-center">
                                             
                                            <ButtonGroup style={{ backgroundColor:'#FFF'}}>
                                                {_menu.Electoral===1 &&(
                                                    <Button
                                                    className="btn-round"
                                                    color='info'
                                                    outline
                                                    type="button"
                                                    onClick={()=>handleShowElectoral()}
                                                    >
                                                    Electoral
                                                </Button>)}
                                                {_menu.Social===1 &&(
                                                    <Button
                                                        className="btn-round"
                                                        color='info'
                                                        outline
                                                        type="button"
                                                        onClick={()=>handleShowSocial()}
                                                        >
                                                        Social
                                                    </Button>)}
                                                {_menu.Territorial===1 &&(
                                                    <Button
                                                    className="btn-round"
                                                    color='info'
                                                    outline
                                                    type="button"
                                                    onClick={()=>handleShowPromocion()}
                                                    >
                                                    Promoción
                                                </Button>)}
                                            </ButtonGroup>
                                        </Col>
                                        </Row>
                                
                                    
                                    {showElectoral&&
                                    <>
                                        <Row>
                                            {
                                                catResponsabilidad.map((item,i)=>{
                                                     return <div>
                                                                <Badge key={i} color="success" pill>
                                                                    {item.label}
                                                                </Badge> 
                                                            </div>
                                                })}
                                        </Row>
                                    </>
                                    }
                                    {showPromocion &&
                                        <>
                                        <Row>
                                            {
                                                catPromocion.map((item,i)=>{
                                                     return <div>
                                                                <Badge key={i} color="success" pill>
                                                                    {item.label}
                                                                </Badge> 
                                                            </div>
                                                })}
                                            </Row>
                                        </>
                                       
                                    }
                                    {showSocial&&
                                        <>
                                        <Row>
                                            {
                                                catSocial.map((item,i)=>{
                                                    return <div>
                                                                <Badge key={i} color="success" pill>
                                                                    {item.label}
                                                                </Badge> 
                                                            </div>
                                                })}
                                            </Row>
                                        </>
                                    }
                                    {isElectoral.RC?<>    
                                    
                                        <Row><br /></Row>
                                    
                                    </>:<></>}
                                    {isElectoral.RG?<>   

                                    </>:<></>}
                                    {isElectoral.Abogado?<>  
                    
                                    </>:<></>}
                                    {isElectoral.CasaAzul?<> 
                        
                                    </>:<></>}
                                    {isElectoral.Observador?<>   
                            
                                    </>:<></>}
                                    {isElectoral.CoordEstaElectoral
                                    }
                                    {isElectoral.CoordMpalElectoral?<>
                                    
                                    </>:<></>
                                    }
                                    {isElectoral.CoordRegElectoral?<>    
                                        
                                    </>:<></>}
                                    {isPromocion.SupEstatalElectoral?<>    
                                        
                                    </>:<></>}
                                    {isPromocion.CoordEstatalTerritorial?<>    
                                        
                                        </>:<></>
                                    }
                                    {isPromocion.Municipal?<>    
                                        
                                    </>:<></>}
                                    {isPromocion.Regional?<>    
                                    
                                    </>:<></>}
                                    {isPromocion.Poligonal?<>    
                                
                                    </>:<></>}
                                    {isPromocion.Seccional?<>    
                            
                                    
                                    </>:<></>}
                                    {isPromocion.Manzanero?
                                        <>    
                            
                                        </>
                                    :<></>}
                                    {isSocial.CoorGralSocial&&(<>
                                    
                                    </>)}
                                    {isSocial.CoorEstrSocial&&(<>
                                
                                    </>)}
                                    {isSocial.CoorRegSocial&&(<>
                                
                                    </>)}
                                    {isSocial.CoorMpalSocial&&(<>
                            
                                    </>)}
                                    {isSocial.CoorEstatalSocial&&(<>
                        
                                    </>)}
                                    {isSocial.CoordeEstructura&&(<>
                                
                                    </>)}
                            
                            </CardBody>
                            <CardFooter>
                               
                            </CardFooter>
                        </Card>
                    </Col>
                    </Row>
                  
                </div>
                <Footer fluid />
            </div>
        </div>

    );
};
export default AceptarPropuestas;
