import React, { useEffect, useState,useRef } from 'react';
import { Button, Card, CardHeader, CardBody, Row,Col } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import { useHistory } from "react-router-dom";
import { NavLink, Link } from 'react-router-dom';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';

import EjecutarApi from '../../data/getPOST';
import { URL_GENERAL } from "../../data/Consts";
import { getDataMenu } from 'data/menus';
import { getVars } from '../../GlobalStorage';
import Pagination from "../../components/Pagination/pagination";
import ModalFiltro from '../../components/Filtro/index';

function Auditorias (props){
	let history = useHistory();
	const token = getVars('Token');
	const [setsidebarMini] = useState(true);
	const [ compromisos, setCompromisos ] = useState([]);
	const [totalCompromisos, setTotalCompromisos] = useState(0);
	const [_menu, _setmenu] = useState({Agregar: 0,
		Capturista: 0,
		Editar: 0,
		Electoral: 0,
		Eliminar: 0,
		Exportar: 0,
		Propuesta: 0,
		Seguimiento: 0,
		Social: 0,
		Territorial: 0,
		Ver: 0,
		ViewAll: 0});
	const [ loading, setLoading ] = useState(false);
	
	useEffect(() => {
		// const miMenu =getDataMenu(102, token.menus);
		// _setmenu(miMenu);
		const idMenu = 102;
		token.menus.forEach(item => {
			if(idMenu === item.idMenu)
			{

				_setmenu(item);
				return true;
			}else
			{
				if(item.views != null)
				{
					item.views.forEach(view => {
						if(idMenu === view.idMenu)
						{
							_setmenu(view);
							return true;
						}
					})	
				}
				


			}


		})
	},[])


	useEffect(() => { 
	},[_menu])

	const handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			setsidebarMini(false);
		} else {
			setsidebarMini(true);
		}
		document.body.classList.toggle('sidebar-mini');
  };

	const btnExportExcel = (item) => {

		// if(_menu.Exportar !== 1)
		// {

		// 	notify.show(
		// 		`Error no tiene permisos para Exportar`,
		// 		"custom",
		// 		5000,
		// 		{
		// 			background: "#F72200",
		// 			fontSize: 14,
		// 			text: "#FFFFFF",
		// 		}
		// 	);
		// }

		var url = new URL(

      `${URL_GENERAL}getAuditoriaMuestraExcel?i=${item.id}&token=${token.access_token}`
    );
    window.open(url, '_blank');


	}

  
	
	return (
		<div className="wrapper">
      		<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel">
				<Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
					<div className="content">
					
						<Row>
							<Col>
							
								<Card>
									<CardHeader>
										<Row> 
											<Col sm="4">
												
                        <NavLink exact to="/auditorias_add">
                        Capturar 
                        </NavLink>
												
											
												<Button size='sm' className='primary left-align' color='primary' >
													<span className="btn-label">
														<i className="fa fa-file-excel-o fa-lg" />
													</span>
														Exportar
												</Button>
											
											</Col>
										</Row>
										<Row>
												<Col sm="12">
													<ModalFiltro  Menu={_menu} />
												</Col>			
											</Row>
									</CardHeader>
									<CardBody>
										<div style={{ 'width': '100%', 'overflow': 'auto'}}>
											<ReactTable 
												style={{
												width: "100%",
												height: "100%"
											}}
												size='sm'
												data={compromisos}
												noDataText="Sin información"
												manual
												columns={[
												{
													Header: "Tipo",
													minWidth: 30,
													id: "TipoAuditoria",
													accessor: (d) => d.TipoAuditoria,
													filterAll: false,
												},
												{
													Header: "Auditoria",
													minWidth: 70,
													id: "NombreAuditoria",
													accessor: (d) => d.NombreAuditoria,
													filterAll: false,
												},
												{
													Header: "Amplitud",
													minWidth: 50,
													id: "AmplitudAuditoria",
													accessor: (d) => d.AmplitudAuditoria,
													filterAll: false,
												},
												{
													Header: "Tamaño",
													minWidth: 110,
													id: "Tamanio",
													accessor: (d) => d.Tamanio,
													filterAll: false,
												},
												{
													Header: "FechaInicio",
													minWidth: 40,
													id: "FechaInicio",
													accessor: (d) => d.FechaInicio,
													filterAll: false,
												},
												{
													Header: "FechaFin",
													accessor: (d) => d.FechaFin,
													minWidth: 50,
													id: "FechaFin",
													filterable: false,
												},
												{
													accessor: (d) => d.Acciones,
													minWidth: 50,
													id: "Acciones",
													filterable: false,
												}
												]}
												className="-striped -highlight primary-pagination -scrollbar"
												loading={loading}
												showPagination={true}
												showPaginationBottom={true}
												showTotalPages={true}
												showPageJump={false}
												canNextFromData={true}
												defaultPageSize={10}
												total={totalCompromisos}
												pages={Math.floor(totalCompromisos / 10)}
												previousText="Anterior"
												nextText="Siguiente"
												ofText="de"
												pageSizeOptions={[10, 20, 25, 50, 100]}
												PaginationComponent={Pagination}
												onFetchData={(stateComp, instance) => {
													setLoading(true);
													let _menuStorage = getDataMenu(6, token.menus);
													const data = {
														page: stateComp.page,
														pageSize: stateComp.pageSize,
														sorted: [{ id: "C.created_at", desc: true }],
														filtered: stateComp.filtered,
													};

													EjecutarApi(data, "getAuditorias", token.access_token)
														.then(function (res) {
															return res;
														})
														.then((res) => {
															setLoading(false);
															setTotalCompromisos(res.total);
															setCompromisos(
																res.data.map((prop, key) => {
	
																	return {
																		id: prop.id,
																		TipoAuditoria: prop.TipoAuditoria,
																		NombreAuditoria: prop.NombreAuditoria,
																		AmplitudAuditoria: prop.AmplitudAuditoria,
																		Tamanio: prop.Tamanio,
																		FechaInicio: prop.FechaInicio,
																		FechaFin: prop.FechaFin,
																		idStatus: prop.idStatus,
																		Acciones: (<>
																			<Button className="btn-link" color="success" onClick={() =>btnExportExcel(prop)}><i className="fa fa-file-excel-o fa-2x" aria-hidden="true" /></Button></>),
																		data: prop,
																	};
																})
															);
	
														})
														.catch((e) => {
															setLoading(false);
															notify.show(
																`Error al obtener Compromisos: ${e.error}`,
																"custom",
																5000,
																{
																	background: "#0857B6",
																	fontSize: 14,
																	text: "#FFFFFF",
																}
															);
	
	
														});
												}}
												/>
										</div>
									</CardBody>
								</Card>	
							
							</Col>
						</Row>
					</div>
				<Footer fluid />
			</div>
		</div>
	);
};

export default Auditorias;
