import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col, Collapse, Input } from 'reactstrap';
import Select from "react-select";

import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from '../../data/getPOST';
import { getVars, URL_PUBLICA } from "../../GlobalStorage";

function Filtro({ Menu, Datos }) {
    const [loading, setLoading] = useState(false);
    const token = getVars("Token").access_token;
    const [filtro, setFiltro] = useState({
        Region: { label: 'Filtrar', value: 0 },
        Municipio: { label: 'Filtrar', value: 0 },
        Poligono: { label: 'Filtrar', value: 0 },
        Seccion: '',
        Casilla: '',
        Celular: '',
        ClaveElector: '',
        Nombre: '',
        Paterno: '',
        Materno: '',
        Propuesta: { label: 'Filtrar', value: 0 },
        DireccionVive: '',
        ColoniaVive: '',
        LocalidadVive: '',
        MunicipioVive: '',
        DireccionVota: '',
        ColoniaVota: '',
        LocalidadVota: '',
        MunicipioVota: '',
        Responsabilidad: { label: 'Filtrar', value: 0 },
        TieneUsuario: { label: 'Filtrar', value: -1 }
    })
    const [collapse, setCollapse] = useState({
        horizontalTabs: "home",
        verticalTabs: "info",
        pageTabs: "homePages",
        openedCollapses: ["collapseOne", "collapse1"]
    });
    const [catRegionestFilter, setCatRegionestFilter] = useState([])
    const [catRegionest, setCatRegionest] = useState([])
    const [catPoligonost, setCatPoligonost] = useState([])
    const [catPoligonostFilter, setCatPoligonostFilter] = useState([])
    const [dataCombos, setDataCombo] = useState({
        dRegion: { label: 'Filtrar', value: 0 },
        dMunicipio: { label: 'Filtrar', value: 0 },
        dPoligono: { label: 'Filtrar', value: 0 },
        dSeccion: { label: 'Filtrar', value: 0 },
        dPropuesta: { label: 'Filtrar', value: 0 },
        dResponsabilidad: { label: 'Filtrar', value: 0 }
    })
    const [catMunicipiotFilter, setCatMunicipiotFilter] = useState([])
    const [catMunicipiot, setCatMunicipiot] = useState([])
    const [catSecciont, setCatSecciont] = useState([])
    const [catSecciontFilter, setCatSecciontFilter] = useState([])
    const [catResponsabilidad, setCatResponsabilidad] = useState([])
    const [catUsuaer] = useState([{ value: 1, label: 'Si' }, { value: 0, label: 'No' }])

    useEffect(() => {
        setLoading(true);
        //getCatalogosFiltrosPromocion  getCatRegion
        EjecutarApi({}, "getCatalogosFiltrosPromocion", token, "POST")
            .then(function (res) {
                return res;
            })
            .then(res => {
                if (res.results) {
                    if (res.data) {
                        let regiones = [{ value: 0, label: 'Todos' }]
                        let regionesFormated = res.data.regiones.map(item => {
                            return {
                                value: item.value,
                                label: item.label
                            }
                        })
                        setCatRegionest(regiones.concat(regionesFormated));
                        setCatRegionestFilter(regiones.concat(regionesFormated));

                        let municipios = [{ value: 0, label: 'Seleccione' }]
                        let municipiosFormated = res.data.municipios.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                            }
                        })
                        setCatMunicipiot(municipios.concat(municipiosFormated));
                        setCatMunicipiotFilter(municipios.concat(municipiosFormated));

                        let poligonos = [{ value: 0, label: 'Seleccione' }]
                        let poligonosFormated = res.data.poligonos.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idMunicipio: item.idMunicipio,
                                idRegion: item.idRegion,
                            }
                        })
                        setCatPoligonost(poligonos.concat(poligonosFormated));
                        setCatPoligonostFilter(poligonos.concat(poligonosFormated));

                        let seccion = [{ value: 0, label: 'Seleccione' }]
                        let seccionFormated = res.data.secciones.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                                idMunicipio: item.idMunicipio,
                                idPoligono: item.idPoligono
                            }
                        })
                        setCatSecciont(seccion.concat(seccionFormated));
                        setCatSecciontFilter(seccion.concat(seccionFormated));
                    }
                }

            })
            .catch((e) => {
                setLoading(false);
            })
        //Obtener catalogos Responsablidad
        EjecutarApi({}, 'getCatResponsabilidadAgrupadas', token, "POST")
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    var catalogo = [{ label: 'Seleccionar', value: -1 }];
                    catalogo = [{ label: 'Sin Responsabilidad', value: 1 }];
                    catalogo = (catalogo.concat(res.data.Electoral));
                    catalogo = (catalogo.concat(res.data.Promocion));
                    catalogo = (catalogo.concat(res.data.Social));
                    setCatResponsabilidad(catalogo);
                    setLoading(false);
                }
            })
            .catch((e) => {
                setLoading(false);
            });

    }, []);

    useEffect(() => {

    }, [catPoligonost, catMunicipiot, catRegionest])

    const collapsesToggle = (colapse) => {
        let openedCollapses = collapse.openedCollapses;
        if (openedCollapses.includes(colapse)) {
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses.filter(item => item !== colapse)
            });
        } else {
            openedCollapses.push(colapse);
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses
            });
        }
    };
    const changeRegionX = (dRegion) => {

        setLoading(true);

        if (dRegion.value > 1) {
            setDataCombo({ ...dataCombos, dRegion, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion: { label: 'Seleccione', value: 0 } });
            setCatMunicipiotFilter(catMunicipiot.filter(data => data.idRegion === dRegion.value));
            setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idRegion) === dRegion.value));
            setCatSecciontFilter(catSecciont.filter(seccion => seccion.idRegion === dRegion.value))
        } else {
            setDataCombo({ ...dataCombos, dRegion: { label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });
            setCatMunicipiotFilter(catMunicipiot);
            setCatPoligonostFilter(catPoligonost);
            setCatPoligonostFilter(catPoligonost);
        }
        setLoading(false);
    }
    const changeMunicipioX = (dMunicipio) => {
        setLoading(true);
        if (dMunicipio.value > 0) {
            setDataCombo({ ...dataCombos, dMunicipio: dMunicipio, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion: { label: 'Seleccione', value: 0 } });
            setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idMunicipio) === dMunicipio.value));
            setCatSecciontFilter(catSecciont.filter(seccion => seccion.idMunicipio === dMunicipio.value))
        } else {
            setDataCombo({ ...dataCombos, dMunicipio: dMunicipio, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion: { label: 'Seleccione', value: 0 } });
            setCatSecciontFilter(catSecciont);
            setCatPoligonostFilter(catPoligonost);
        }
        setLoading(false);
    }

    const changePoligonoX = (dPoligono) => {
        setLoading(true);
        setDataCombo({ ...dataCombos, dPoligono });
        if (dPoligono.value > 0) {
            setCatSecciontFilter(catSecciont.filter(seccion => seccion.idPoligono === dPoligono.value))
        } else {
            setCatSecciontFilter(catSecciont);
        }
        setLoading(false);
    }
    const changeSeccionX = (dSeccion) => {
        setLoading(true);
        setDataCombo({ ...dataCombos, dSeccion });
        setLoading(false);
    }
    const setFilter = (event) => {
        var filtrar = {
            Region: dataCombos.dRegion,
            Municipio: dataCombos.dMunicipio,
            Poligono: dataCombos.dPoligono,
            Seccion: dataCombos.dSeccion,
            Celular: filtro.Celular,
            ClaveElector: filtro.ClaveElector,
            Nombre: filtro.Nombre,
            Paterno: filtro.Paterno,
            Materno: filtro.Materno,
            Propuesta: dataCombos.dPropuesta,
            FechaCaptura: filtro.Fecha,
            DireccionVive: filtro.DireccionVive,
            Responsabilidad: filtro.Responsabilidad,
            TieneUsuario: filtro.TieneUsuario
        }
        Datos(filtrar);
    }

    const ClearFilter = () => {
        setDataCombo({
            ...dataCombos, dRegion: { label: 'Seleccione', value: 0 },
            dMunicipio: { label: 'Seleccione', value: 0 },
            dPoligono: { label: 'Seleccione', value: 0 },
            dSeccion: { label: 'Seleccione', value: 0 }
        });
        let _filtro = {
            Region: {},
            Municipio: {},
            Poligono: {},
            Seccion: '',
            Casilla: '',
            Celular: '',
            ClaveElector: '',
            Nombre: '',
            Paterno: '',
            Materno: '',
            Propuesta: {},
            DireccionVive: '',
            ColoniaVive: '',
            LocalidadVive: '',
            MunicipioVive: '',
            DireccionVota: '',
            ColoniaVota: '',
            LocalidadVota: '',
            MunicipioVota: '',
            Responsabilidad: {},
            TieneUsuario: { label: 'Filtrar', value: -1 }
        };
        setFiltro(_filtro);
        Datos(_filtro);
    }

    return (
        <Card className="card-plain">
            <CardBody>
                <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                >
                    <Card className="card-plain">
                        <CardHeader role="tab">
                            <a
                                aria-expanded={collapse.openedCollapses.includes(
                                    "collapse3"
                                )}
                                data-parent="#accordion"
                                data-toggle="collapse"
                                onClick={() => collapsesToggle("collapse3")}
                                className="text-left"
                            >
                                <Button
                                    className="btn-icon btn-neutral"
                                    outline
                                    size="sm"
                                    id="EditUser"
                                >
                                    <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                                </Button>
                                Filtro
                    </a>
                        </CardHeader>
                        <Collapse
                            role="tabpanel"
                            isOpen={collapse.openedCollapses.includes(
                                "collapse3"
                            )}
                        >
                            <CardBody>
                                {Menu.Ver && (<>
                                    <Row>
                                        <Col sm="3" className="text-muted">Región:
                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catRegionestFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dRegion}
                                                onChange={(e) => changeRegionX(e)}
                                                id="viewTypeR"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="3" className="text-muted">Municipio:
                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catMunicipiotFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dMunicipio}
                                                onChange={(e) => changeMunicipioX(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="3" className="text-muted">Polígono:
                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catPoligonostFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dPoligono}
                                                onChange={(e) => changePoligonoX(e)}
                                                id="viewTypeP"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="3" className="text-muted">Sección:
                                {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catSecciontFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dSeccion}
                                                onChange={(e) => changeSeccionX(e)}
                                                id="viewTypeS"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        {/* <Col sm="3" className="text-muted">¿Tiene Usuario?:
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                    )}            {!loading && (<Select
                                    options={catUsuaer}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={filtro.TieneUsuario}
                                    onChange={(e) => {setFiltro({...filtro, TieneUsuario:e})}}
                                    id="tusuario"
                                    placeholder="Filtrar por"
                                />)}
                            </Col> */}
                                        <Col sm="3" className="text-muted">Responsabilidad:
                                {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catResponsabilidad}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dResponsabilidad}
                                                onChange={(e) => { setFiltro({ ...filtro, Responsabilidad: e }); setDataCombo({ ...dataCombos, dResponsabilidad: e }) }}
                                                id="viewTypeS"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="3" className="text-muted">Celular:<Input id="icveElector" value={filtro.Celular} onChange={(e) => setFiltro({ ...filtro, Celular: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">Clave Elector:<Input id="icveElector" value={filtro.ClaveElector} onChange={(e) => setFiltro({ ...filtro, ClaveElector: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">Nombre: <Input id="inombre" value={filtro.Nombre} onChange={(e) => setFiltro({ ...filtro, Nombre: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">Paterno:<Input id="ipaterno" value={filtro.Paterno} onChange={(e) => setFiltro({ ...filtro, Paterno: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">Materno:<Input id="" value={filtro.Materno} onChange={(e) => setFiltro({ ...filtro, Materno: e.target.value })} /></Col>
                                        {/* <Col sm="5" className="text-muted">Dirección:<Input id="" value={filtro.DireccionVive} onChange={(e)=>setFiltro({...filtro,DireccionVive:e.target.value})} /></Col>	 */}
                                    </Row>
                                </>
                                )}
                            </CardBody>
                            <CardFooter>
                                <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e) => setFilter()}>
                                    <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                    Filtrar
                        </Button>{' '}
                                <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e) => ClearFilter()}>
                                    <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                                    Limpiar
                        </Button>
                            </CardFooter>
                        </Collapse>
                    </Card>
                </div>
            </CardBody>
        </Card>
    );
};
export default Filtro;