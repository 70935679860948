import React, { useEffect, useState } from "react";

//Zincri imports
import ChoropethMap from "../../../../components/Maps/Electoral/Distritos/ChoropethMapV5MovilDistritoLocalEstructura.jsx";
import { URL_GENERAL } from "../../../../data/Consts";
import {
  Button
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";



const Mapas = (props) => {

  const [dataGeoJson, setDataGeoJson] = useState(
    {
      "type": "FeatureCollection",
      "features": [
      ]
    }
  );
  //const [DeviceID, setDeviceID] = useState("");
  const [Responsabilidad, setResponsabilidad] = useState("0");
  const [dataTablaActual, setDataTablaActual] = useState([]);
  const [tipoMapa, setTipoMapa] = useState(
    Responsabilidad === "13"? 
    { value: "seccion", label: "Seccional" }  :
    Responsabilidad === "12"?
     { value: "poligono", label: "Poligonal" } :
    Responsabilidad === "11"? 
    { value: "municipio", label: "Municipal" } :
    { value: "distrito", label: "Distrital" }
    ); 
  const zoom =
    Responsabilidad === "13"? 
    8  :
    Responsabilidad === "12"?
    8 :
    Responsabilidad === "11"? 
    7.5 :
    7.5; 
  const [derechoMapa, setDerechoMapa] = useState([]);

  

  const [rangos, setRangos] = useState([]);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState("");

  const hostserver = URL_GENERAL;

  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  useEffect(() => {
    
    
    
  }, [Responsabilidad,tipoMapa]);
  const basicAlert =  (
    <ReactBSAlert
      style={{
         display: "block",
        marginTop: "-100px",
        padding: '2px 4px',
        font: '10px/12px Arial, Helvetica, sans-serif' }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
    );
  async function getRangos() {
    let config = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };
    try {
      let res = await fetch(hostserver + "getRangosEstructuraGtoML", config);
      let data = await res.json();
      setRangos(data);
    } catch (error) {
      
      setMensajeAlert("Error 001: Favor de renovar sesión");
      setFlagAlert(true);
        setDataGeoJson({
          "type": "FeatureCollection",
          "features": [
          ]
        });
    }
  }

  function selectDerecho(){
    //Setear el DEVICE ID y el USER ID y La responsabilidad para poder pintarle el mapa que le corresponde ver 

    let Responsabilidad =document.getElementById("idResponsabilidad").innerHTML;
    let idUser =document.getElementById("idUser").innerHTML;
    let DeviceID =document.getElementById("DeviceID").innerHTML;
    
    setTipoMapa(
      Responsabilidad === "13"? 
      { value: "seccion", label: "Seccional" }  :
      Responsabilidad === "12"?
       { value: "poligono", label: "Poligonal" } :
       Responsabilidad === "11"? 
      { value: "municipio", label: "Municipal" } :
      { value: "distrito", label: "Distrital" }
      ); 
    //setDeviceID(DeviceID);
    setResponsabilidad(Responsabilidad);
    //setIdUser(idUser);

    
    
    getRangos();
    setDataGeoJson({});
    let derecho_mapa = document.getElementById("idResponsabilidad").innerHTML;
    if(derecho_mapa === "" || idUser ===0 || DeviceID ==="") {
      setMensajeAlert("Datos Incorrectos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
      return true;
    }
    setDerechoMapa(
      derecho_mapa === "99" ||
      derecho_mapa === "50" ||
      derecho_mapa === "51" ||
      derecho_mapa === "52" ||
      derecho_mapa === "54" ||
      derecho_mapa === '30' ||
      derecho_mapa === '35' ||
      derecho_mapa === "97"?[1,2,3,4]:
      derecho_mapa === "10"?[1,2,3,4]:
      derecho_mapa === "11"?[1,2,3]:
      derecho_mapa === "12"?[1,2]:
      derecho_mapa === "13"?[1]:
      []
    );
    if(derecho_mapa === "99" ||
    derecho_mapa === "97" ||
    derecho_mapa === "50" ||
    derecho_mapa === "51" ||
    derecho_mapa === "52" ||
    derecho_mapa === "54" ||
    derecho_mapa === '30' ||
    derecho_mapa === '35' ||
    derecho_mapa === "10"){
      getDistritos("",false);
    }
    if(derecho_mapa === "11"){
      getMunicipios("",false);
    }
    if(derecho_mapa === "12"){
      getPoligonos("",false);
    }
    if(derecho_mapa === "13"){
      getSecciones("",false);
    }
    //Si no es ninguno de estos numeros Mandale un mensaje que alerta

  }



  async function getDistritos(clave_parametro,bandera_parametro) {
    
    try {
      var config = {};
      if (bandera_parametro){
        config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({DeviceID:document.getElementById("DeviceID").innerHTML,idUser:document.getElementById("idUser").innerHTML, clave: clave_parametro }),
        };
      }
      else{
        config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({DeviceID:document.getElementById("DeviceID").innerHTML,idUser:document.getElementById("idUser").innerHTML}),
        };
      }
      
      let res = await fetch(
        hostserver + (bandera_parametro ? "getDistritoDLEstructuraGtoMovil" :"getDistritoDLEstructuraGtoMovil"),
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
        setDataGeoJson({
          "type": "FeatureCollection",
          "features": [
          ]
        });
    }
  }
  async function getMunicipios(clave_parametro,bandera_parametro) {
    
    try {
      var config = {};
      if (bandera_parametro){
        config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({DeviceID:document.getElementById("DeviceID").innerHTML,idUser:document.getElementById("idUser").innerHTML, clave: clave_parametro.clave, distrito: clave_parametro.distrito }),
        };
      }
      else{
        config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({DeviceID:document.getElementById("DeviceID").innerHTML,idUser:document.getElementById("idUser").innerHTML}),
        };
      }
      let res = await fetch(
        hostserver + (bandera_parametro ? "getMunicipiosDistritoDLEstructuraParametroGtoMovil" :"getMunicipiosDistritoDLEstructuraGtoMovil"),
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
        setDataGeoJson({
          "type": "FeatureCollection",
          "features": [
          ]
        });
    }
  }
  async function getPoligonos(clave_parametro,bandera_parametro) {
    
    try {
      var config = {};
      if (bandera_parametro){
        config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({DeviceID:document.getElementById("DeviceID").innerHTML,idUser:document.getElementById("idUser").innerHTML, clave: clave_parametro.clave, distrito: clave_parametro.distrito }),
        };
      }
      else{
        config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({DeviceID:document.getElementById("DeviceID").innerHTML,idUser:document.getElementById("idUser").innerHTML}),
        };
      }
      let res = await fetch(
        hostserver + (bandera_parametro ? "getPoligonosDLEstructuraParametroGtoMovil" :"getPoligonosDLEstructuraGtoMovil"),
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
        setDataGeoJson({
          "type": "FeatureCollection",
          "features": [
          ]
        });
    }
  }
  async function getSecciones(clave_parametro,bandera_parametro) {
    
    try {
      var config = {};
      if (bandera_parametro){
        config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({DeviceID:document.getElementById("DeviceID").innerHTML,idUser:document.getElementById("idUser").innerHTML, clave: clave_parametro.clave, distrito: clave_parametro.distrito }),
        };
      }
      else{
        config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({DeviceID:document.getElementById("DeviceID").innerHTML,idUser:document.getElementById("idUser").innerHTML}),
        };
      }
      let res = await fetch(
        hostserver + (bandera_parametro ? "getSeccionesDLEstructuraParametroGtoMovil" :"getSeccionesDLEstructuraGtoMovil"),
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      if (json_parser.features === null) {
        throw new Error("features null");
      }
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
        setDataGeoJson({
          "type": "FeatureCollection",
          "features": [
          ]
        });
    }
  }
  


  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapaparametros(e);
  }

  const handleTipoMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapa(e);
  }
  const hadleDataTablaActual = (e) => {
    setDataTablaActual(e);
  }

  

  function selectmapa(tipomapa) {
    if (tipomapa.value === 'distrito') {
      getDistritos("",false);
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios("",false);
    }
    if (tipomapa.value === 'poligono') {
      getPoligonos("",false);
    }
    if (tipomapa.value === 'seccion') {
      getSecciones("",false);
    }
  }

  function selectmapaparametros(tipomapa) {
    if (tipomapa.value === 'distrito') {
      getDistritos(tipomapa,true);
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios(tipomapa,true);
    }
    if (tipomapa.value === 'poligono') {
      getPoligonos(tipomapa,true);
    }
    if (tipomapa.value === 'seccion') {
      getSecciones(tipomapa,true);
    }
  }

  return (
    <div className="wrapper">
      
        <div className="content">
          <>
          <div hidden id="idResponsabilidad" name="idResponsabilidad" ></div>
          <div hidden id="DeviceID" name="DeviceID" ></div>
          <div hidden id="idUser" name="idUser" ></div>
          <div hidden id="tabla" name="tabla" >{JSON.stringify(dataTablaActual)}</div>
          <Button hidden id="btnS" name="btnS" onClick={()=> selectDerecho()}> Buscar</Button>
          {FlagAlert ? basicAlert : <></>}
            <ChoropethMap
              key={"ChoropethMapV4"}
              center={[position.latitude, position.longitude]}
              zoom={zoom}
              data={dataGeoJson}
              rangos={rangos}
              tipo={tipoMapa.value}
              label_tipo = {tipoMapa.label}
              handleTipoMapa = {handleTipoMapa}
              handleDetalleMapa = {handleDetalleMapa}
              derecho_mapa= {derechoMapa}
              hadleDataTabla={hadleDataTablaActual}
            ></ChoropethMap>

          </>
        </div>
        </div>
      );
};

export default Mapas;
