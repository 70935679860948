import { required, minLength, maxLength, minValue, sizeLength, requiredSelect } from "data/validations";

const UsuarioForm = {
    id: '',
    email: '',
    INE: '',
    CURP: '',
    Nombre: '',
    Paterno: '',
    Materno: '',
    FechaNacimiento: '',
    Sexo: { value: 1, label: 'No definido' },
    EstadoNacimiento: { value: 12, label: "GUANAJUATO" },
    MunicipioVive: { value: 1, label: 'No definido' },
    CalleVive: '',
    NumExtVive: '',
    NumIntVive: '',
    LocalidadVive: { value: 1, label: 'No definido' },
    ColoniaVive: '',
    CPVive: '',
    SeccionVota: '',
    MismaAddress: 1,
    MunicipioVota: { value: 1, label: 'No definido' },
    CalleVota: '',
    NumExtVota: '',
    NumIntVota: '',
    LocalidadVota: { value: 1, label: 'No definido' },
    ColoniaVota: '',
    CPVota: '',
    Celular: '',
    TelMensajes: '',
    Telefono: '',
    Facebook: '',
    Instagram: '',
    Twitter: '',
    idResponsabilidad: 1,
    ResponsabilidadData: '',
    ResponsabilidadDataID: '',
    isVoluntario: 1,
    Voluntario: { value: "", label: "" },
    ExperienciaElectoral: "",
    ExperienciaDonde: "",
    ExperienciaAnio: "",
    AcercaDe: "",
    NivelEstudios: "", //value,label
    TituloObtenido: "",
    SituacionLaboral: "",
    EmpresaLabora: "",
    ClaveReferencia: '',
    Partido: { value: 1, label: "PAN" },
    UserOwned: ''
};

const UsuarioParamsForm = (params) => {
    let data = {
        email: params.email,
        INE: params.INE,
        CURP: params.CURP,
        Nombre: params.Nombre,
        Paterno: params.Paterno,
        Materno: params.Materno,
        FechaNacimiento: params.FechaNacimiento,
        idSexo: params.Sexo.value,
        idEstadoNacimiento: params.EstadoNacimiento.value,
        idMunicipioVive: params.MunicipioVive.value,
        CalleVive: params.CalleVive,
        NumExtVive: params.NumExtVive,
        NumIntVive: params.NumIntVive,
        LocalidadVive: params.LocalidadVive.value,
        ColoniaVive: params.ColoniaVive,
        CPVive: params.CPVive,
        SeccionVota: params.SeccionVota,
        MismaAddress: params.MismaAddress,
        MunicipioVota: params.MunicipioVota.value,
        CalleVota: params.CalleVota,
        NumExtVota: params.NumExtVota,
        NumIntVota: params.NumIntVota,
        LocalidadVota: params.LocalidadVota.value,
        ColoniaVota: params.ColoniaVota,
        CPVota: params.CPVota,
        Celular: params.Celular,
        TelMensajes: params.TelMensajes,
        Telefono: params.Telefono,
        Facebook: params.Facebook,
        Instagram: params.Instagram,
        Twitter: params.Twitter,
        idResponsabilidad: params.Responsabilidad.value,
        ResponsabilidadData: params.ResponsabilidadData,
        ResponsabilidadDataID: params.ResponsabilidadDataID,
        isVoluntario: params.isVoluntario,
        Voluntario: params.Voluntario.value,
        ExperienciaElectoral: "",
        ExperienciaDonde: "",
        ExperienciaAnio: "",
        AcercaDe: "",
        NivelEstudios: "", //value,label
        TituloObtenido: "",
        SituacionLaboral: "",
        EmpresaLabora: "",
        ClaveReferencia: params.ClaveReferencia,
        UserOwned: ''
    };

    return data;
};

const UsuarioFormErrors = {
    id: '',
    email: '',
    INE: '',
    CURP: '',
    Nombre: '',
    Paterno: '',
    Materno: '',
    FechaNacimiento: '',
    Sexo: '',
    EstadoNacimiento: '',
    MunicipioVive: '',
    CalleVive: '',
    NumExtVive: '',
    NumIntVive: '',
    LocalidadVive: '',
    ColoniaVive: '',
    CPVive: '',
    SeccionVota: '',
    MismaAddress: '',
    MunicipioVota: '',
    CalleVota: '',
    NumExtVota: '',
    NumIntVota: '',
    LocalidadVota: '',
    ColoniaVota: '',
    CPVota: '',
    Celular: '',
    TelMensajes: '',
    Telefono: '',
    Facebook: '',
    Instagram: '',
    Twitter: '',
    idResponsabilidad: '',
    ResponsabilidadData: '',
    ResponsabilidadDataID: '',
    isVoluntario: '',
    Voluntario: '',
    ExperienciaElectoral: '',
    ExperienciaDonde: '',
    ExperienciaAnio: '',
    AcercaDe: '',
    NivelEstudios: '', //value,label
    TituloObtenido: '',
    SituacionLaboral: '',
    EmpresaLabora: '',
    ClaveReferencia: '',
    UserOwned: ''
};

const UsuarioRules = {
    INE: { valid: false, rule: [sizeLength, required], params: { size: 18 } },
    email: { valid: false, rule: [sizeLength, required], params: { size: 10 } },
    Nombre: { valid: false, rule: [minLength, required], params: { minValue: 2 } },
    Paterno: { valid: false, rule: [minLength, required], params: { minValue: 2 } },
    Materno: { valid: false, rule: [minLength, required], params: { minValue: 2 } },
    FechaNacimiento: { valid: false, rule: [required], params: {} },
    Sexo: { valid: false, rule: [requiredSelect], params: {} },
    EstadoNacimiento: { valid: false, rule: [requiredSelect], params: {} },
    CalleVive: { valid: false, rule: [minLength, required], params: { minValue: 2 } },
    NumExtVive: { valid: false, rule: [required], params: {} },
    MunicipioVive: { valid: false, rule: [requiredSelect], params: {} },
    LocalidadVive: { valid: false, rule: [requiredSelect], params: {} },
    ColoniaVive: { valid: false, rule: [minLength, required], params: { minValue: 2 } },
    CPVive: { valid: false, rule: [required], params: {} },
    SeccionVota: { valid: false, rule: [required], params: {} },
    Celular: { valid: false, rule: [required, sizeLength], params: { size: 10 } },
    Partido: { valid: false, rule: [requiredSelect], params: {} },
};

const UsuarioCandidato = (params) => {

};


export { UsuarioForm, UsuarioParamsForm, UsuarioFormErrors, UsuarioRules, UsuarioCandidato };
// export { RCForm, RCParamsForm, RCFormErrors, RCRules, RCPropuestaForm };