import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import {RotateSpinner} from "react-spinners-kit";
import TagsInput from 'react-tagsinput'
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Notifications, { notify } from "react-notify-toast";
import moment from "moment";

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';
import EjecutarApi from '../../data/getPOST';
import { getVars } from '../../GlobalStorage';



import catDL from '../../data/CallCenter/cat_dl.json';
import catDF from '../../data/CallCenter/cat_df.json';
import catMunicipio from '../../data/CallCenter/cat_municipio.json';
import catRegion from '../../data/CallCenter/cat_region.json';
import catPoligono from '../../data/CallCenter/cat_poligono.json';

import 'react-tagsinput/react-tagsinput.css'


const AuditoriasAdd = (props) => {
  // const { dispatch } = useContext(Context);
  const token = getVars("Token");
  const [catalogos, setCatalogos] = useState({Tamanio: [], Amplitud:[], Tipo:[]});

  const [auditoriaData, setAuditoriaData] = useState(
    { NombreAuditoria: '', 
    Descripcion: '', 
    idTipoAuditoria: {value: 1, label: 'Call Center'},
    idAmplitudAuditoria: {value: null, label: 'Seleccione'},
    idDL: {value: null, label: 'Seleccione'},
    idDF: {value: null, label: 'Seleccione'},
    idRegion: {value: null, label: 'Seleccione'},
    idMunicipio: {value: null, label: 'Seleccione'},
    idPoligono: {value: null, label: 'Seleccione'},
    Seccion: '',
    Secciones: [],
    Responsable: '',
    Responsables: [],
    FechaInicio: new Date(),
    FechaFinal: new Date(),
    Tamanio: {value: null, label: 'Seleccione'}});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCatalogos();
  },[])

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const getCatalogos = () => {

    setLoading(true);

    const data = { };
    EjecutarApi(data, "getCatalogoAuditoria", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((dataRS) => {
       
        if(dataRS.results)
        {
          setCatalogos(dataRS.data)
        }else
        {
          notify.show(
            `Error ${dataRS.message}`,
            "custom",
            5000,
            {
              background: "#F72200",
              fontSize: 14,
              text: "#FFFFFF",
            }
          );
        }
      })
      .catch((e) => {
        notify.show(
          `Error al obtener catálogos: ${e.error}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );


      })
      .finally(x => {
        setLoading(false);
      })

  }

  
  const frmCaptura = () => {
    setLoading(true);
    setLoading(false);


    setLoading(true);

    var data = {}

    switch(auditoriaData.idAmplitudAuditoria.value)
    {
      case 1:  //Estatal
        data = { 
          NombreAuditoria: auditoriaData.NombreAuditoria,
          idTipoAuditoria: auditoriaData.idTipoAuditoria.value,
          Descripcion: '',
          idAmplitudAuditoria: auditoriaData.idAmplitudAuditoria.value,
          idAmplitudAuditoriaData: '',
          Tamanio: auditoriaData.Tamanio.value,
          idCuestionario: 1,
          FechaInicio: auditoriaData.FechaInicio,
          FechaFin: auditoriaData.FechaFinal,
          idStatus: 1
        };
      break;

      case 2:  //Distrito Federal
          data = { 
            NombreAuditoria: auditoriaData.NombreAuditoria,
            idTipoAuditoria: auditoriaData.idTipoAuditoria.value,
            Descripcion: '',
            idAmplitudAuditoria: auditoriaData.idAmplitudAuditoria.value,
            idAmplitudAuditoriaData: auditoriaData.idDF.value,
            Tamanio: auditoriaData.Tamanio.value,
            idCuestionario: 1,
            FechaInicio: auditoriaData.FechaInicio,
            FechaFin: auditoriaData.FechaFinal,
            idStatus: 1
          };
      break;

      case 3:  //Distrito Local
          data = { 
            NombreAuditoria: auditoriaData.NombreAuditoria,
            idTipoAuditoria: auditoriaData.idTipoAuditoria.value,
            Descripcion: '',
            idAmplitudAuditoria: auditoriaData.idAmplitudAuditoria.value,
            idAmplitudAuditoriaData: auditoriaData.idDL.value,
            Tamanio: auditoriaData.Tamanio.value,
            idCuestionario: 1,
            FechaInicio: auditoriaData.FechaInicio,
            FechaFin: auditoriaData.FechaFinal,
            idStatus: 1
          };
      break;

      case 4:  //REgion
          data = { 
            NombreAuditoria: auditoriaData.NombreAuditoria,
            idTipoAuditoria: auditoriaData.idTipoAuditoria.value,
            Descripcion: '',
            idAmplitudAuditoria: auditoriaData.idAmplitudAuditoria.value,
            idAmplitudAuditoriaData: auditoriaData.idRegion.value,
            Tamanio: auditoriaData.Tamanio.value,
            idCuestionario: 1,
            FechaInicio: auditoriaData.FechaInicio,
            FechaFin: auditoriaData.FechaFinal,
            idStatus: 1
          };
      break;

      case 5:  //Municipio
          data = { 
            NombreAuditoria: auditoriaData.NombreAuditoria,
            idTipoAuditoria: auditoriaData.idTipoAuditoria.value,
            Descripcion: '',
            idAmplitudAuditoria: auditoriaData.idAmplitudAuditoria.value,
            idAmplitudAuditoriaData: auditoriaData.idMunicipio.value,
            Tamanio: auditoriaData.Tamanio.value,
            idCuestionario: 1,
            FechaInicio: auditoriaData.FechaInicio,
            FechaFin: auditoriaData.FechaFinal,
            idStatus: 1
          };
      break;

      case 6:  //Poligono
          data = { 
            NombreAuditoria: auditoriaData.NombreAuditoria,
            idTipoAuditoria: auditoriaData.idTipoAuditoria.value,
            Descripcion: '',
            idAmplitudAuditoria: auditoriaData.idAmplitudAuditoria.value,
            idAmplitudAuditoriaData: auditoriaData.idPoligono.value,
            Tamanio: auditoriaData.Tamanio.value,
            idCuestionario: 1,
            FechaInicio: auditoriaData.FechaInicio,
            FechaFin: auditoriaData.FechaFinal,
            idStatus: 1
          };
      break;

      case 7:  //Seccion
          data = { 
            NombreAuditoria: auditoriaData.NombreAuditoria,
            idTipoAuditoria: auditoriaData.idTipoAuditoria.value,
            Descripcion: '',
            idAmplitudAuditoria: auditoriaData.idAmplitudAuditoria.value,
            idAmplitudAuditoriaData: auditoriaData.Seccion,
            Tamanio: auditoriaData.Tamanio.value,
            idCuestionario: 1,
            FechaInicio: auditoriaData.FechaInicio,
            FechaFin: auditoriaData.FechaFinal,
            idStatus: 1
          };
      break;
    }

    EjecutarApi(data, "setAuditoria", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((dataRS) => {

        if(dataRS.results)
        {
          notify.show(
            `Generación completa!`,
            "custom",
            5000,
            {
              background: "#0857B6",
              fontSize: 16,
              text: "#FFFFFF",
            }
          );
          
        }else
        {
          notify.show(
            `Error ${dataRS.message}`,
            "custom",
            5000,
            {
              background: "#F72200",
              fontSize: 14,
              text: "#FFFFFF",
            }
          );
        }
      })
      .catch((e) => {
        notify.show(
          `Error al obtener catálogos: ${e.error}`,
          "custom",
          5000,
          {
            background: "#F72200",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );


      })
      .finally(x => {
        setLoading(false);
      })
  }

  
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>
          <Card className="col-md-12">
              <CardHeader>
                <h5 className="title">CAPTURA DE AUDITORÍA</h5>
              </CardHeader>
              <CardBody>
              <Form className="form" onSubmit={(e) => {frmCaptura(); e.preventDefault();}}>
              <Row>
                  <FormGroup className={"col-md-4"}>
                    <Label for="ssexo">
                      <span className="text-danger">*</span>Tipo
                    </Label>
                    <Select
                      options={catalogos.Tipo}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={auditoriaData.idTipoAuditoria}
                      onChange={(e) =>setAuditoriaData({...auditoriaData, idTipoAuditoria: e}) }
                      id="idTipoAuditoria"
                      placeholder="Seleccione"
                    />
                    
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className={"col-md-4"}>
                    <Label for="ssexo">
                      <span className="text-danger">*</span>Alcance
                    </Label>
                    <Select
                      options={catalogos.Amplitud}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={auditoriaData.idAmplitudAuditoria}
                      onChange={(e) =>setAuditoriaData({...auditoriaData, idAmplitudAuditoria: e}) }
                      id="idTipoAuditoria"
                      placeholder="Seleccione"
                    />
                    
                  </FormGroup>
                  {auditoriaData.idAmplitudAuditoria.value === 2 &&<FormGroup className={"col-md-4"}>
                    <Label for="ssexo">
                      <span className="text-danger">*</span>Distrito Federal
                    </Label>
                    <Select
                      options={catDF.data}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={auditoriaData.idDF}
                      onChange={(e) =>setAuditoriaData({...auditoriaData, idDF: e}) }
                      id="idTipoAuditoria"
                      placeholder="Seleccione"
                    />
                    
                  </FormGroup>}
                  {auditoriaData.idAmplitudAuditoria.value === 3 &&<FormGroup className={"col-md-4"}>
                    <Label for="ssexo">
                      <span className="text-danger">*</span>Distrito Local
                    </Label>
                    <Select
                      options={catDL.data}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={auditoriaData.idDL}
                      onChange={(e) =>setAuditoriaData({...auditoriaData, idDL: e}) }
                      id="idTipoAuditoria"
                      placeholder="Seleccione"
                    />
                    
                  </FormGroup>}
                  {auditoriaData.idAmplitudAuditoria.value === 4 &&<FormGroup className={"col-md-4"}>
                    <Label for="ssexo">
                      <span className="text-danger">*</span>Región
                    </Label>
                    <Select
                      options={catRegion.data}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={auditoriaData.idRegion}
                      onChange={(e) =>setAuditoriaData({...auditoriaData, idRegion: e}) }
                      id="idRegion"
                      placeholder="Seleccione"
                    />
                    
                  </FormGroup>}
                  {auditoriaData.idAmplitudAuditoria.value === 5 &&<FormGroup className={"col-md-4"}>
                    <Label for="ssexo">
                      <span className="text-danger">*</span>Municipio
                    </Label>
                    <Select
                      options={catMunicipio.data}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={auditoriaData.idMunicipio}
                      onChange={(e) =>setAuditoriaData({...auditoriaData, idMunicipio: e}) }
                      id="idTipoAuditoria"
                      placeholder="Seleccione"
                    />
                    
                  </FormGroup>}
                  {auditoriaData.idAmplitudAuditoria.value === 6 &&<FormGroup className={"col-md-4"}>
                    <Label for="ssexo">
                      <span className="text-danger">*</span>Poligono
                    </Label>
                    <Select
                      options={catPoligono.data}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={auditoriaData.idPoligono}
                      onChange={(e) =>setAuditoriaData({...auditoriaData, idPoligono: e}) }
                      id="idTipoAuditoria"
                      placeholder="Seleccione"
                    />
                    
                  </FormGroup>}
                  {auditoriaData.idAmplitudAuditoria.value === 7 &&<FormGroup className={"col-md-4"}>
                    <Label for="Seccion">
                      <span className="text-danger">*</span>Sección
                    </Label>
                    <Input
                          id="Seccion"
                          value={auditoriaData.Seccion}
                          pattern="[0-9]*"
                          maxLength={5}
                          type="text"
                          onChange={(event) =>
                            setAuditoriaData({
                              ...auditoriaData,
                              Seccion: event.target.value.replace(/\D/, "")
                            })
                          }
                        />
                    
                  </FormGroup>}

                  {/* {auditoriaData.idAmplitudAuditoria.value === 6 &&<FormGroup className={"col-md-4"}>
                    
                    <TagsInput 
                    inputProps={{className:"col-md-4", placeholder: 'Agregar Sección'}}
                      placeholder="Sección"
                      value={auditoriaData.Secciones} 
                      onChange={(e) => setAuditoriaData({...auditoriaData, Secciones: e})} />
                    
                  </FormGroup>} */}

                  {auditoriaData.idAmplitudAuditoria.value === 8 &&<FormGroup className={"col-md-3"}>
                  <Label for="Responsable">
                    {" "}
                    <span className="text-danger">*</span>Responsable 
                  </Label>
                  <Input
                    type="text"
                    id="Responsable"
                    value={auditoriaData.Responsable.toUpperCase()}
                    onChange={(event) => {
                      setAuditoriaData({
                        ...auditoriaData,
                        Responsable: event.target.value.toUpperCase(),
                      });
                    }}
                    maxLength={120}
                    
                  />
                </FormGroup>}
                </Row>
                
              <Row>
                
                  <FormGroup className={"col-md-3"}>
                    <Label for="Nombre">
                      {" "}
                      <span className="text-danger">*</span>Nombre Auditoría
                    </Label>
                    <Input
                      type="text"
                      id="NombreAuditoria"
                      value={auditoriaData.NombreAuditoria.toUpperCase()}
                      onChange={(event) => {
                        setAuditoriaData({
                          ...auditoriaData,
                          NombreAuditoria: event.target.value.toUpperCase(),
                        });
                      }}
                      maxLength={120}
                      
                    />
                  </FormGroup>
                  <FormGroup className={"col-md-3"}>
                    <Label for="Nombre">
                      {" "}
                      <span className="text-danger">*</span>Nivel de Confianza
                    </Label>
                    <Select
                      options={catalogos.Tamanio}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={auditoriaData.Tamanio}
                      onChange={(e) =>setAuditoriaData({...auditoriaData, Tamanio: e}) }
                      id="Tamanio"
                      placeholder="Seleccione"
                    />
                  </FormGroup>
                </Row>
                
                <Row>
                  <FormGroup className={"col-md-4"}>
                        <Label for="FechaInicio">
                          <span className="text-danger">*</span>Fecha Inicio
                        </Label>
                        <ReactDatetime
                          id="FechaInicio"
                          name="FechaInicio"
                          dateFormat="YYYY-MM-DD"
                          timeFormat={false}
                          closeOnSelect
                          value={auditoriaData.FechaInicio}
                          onChange={(e) =>
                            setAuditoriaData({
                              ...auditoriaData,
                              FechaInicio: moment(e._d).format("YYYY-MM-DD"),
                            })
                          }
                          inputProps={{ className: "form-control" }}
                        />
                      </FormGroup>
                      <FormGroup className={"col-md-4"}>
                        <Label for="Fechafinal">
                          <span className="text-danger">*</span>Fecha Inicio
                        </Label>
                        <ReactDatetime
                          id="Fechafinal"
                          name="Fechafinal"
                          dateFormat="YYYY-MM-DD"
                          timeFormat={false}
                          closeOnSelect
                          value={auditoriaData.FechaFinal}
                          onChange={(e) =>
                            setAuditoriaData({
                              ...auditoriaData,
                              Fechafinal: moment(e._d).format("YYYY-MM-DD"),
                            })
                          }
                          inputProps={{ className: "form-control" }}
                        />
                      </FormGroup>
                </Row>
                </Form>
              </CardBody>
              <CardFooter>
               <Row>
                  <FormGroup className="col-md-12 pr-2 text-center">
 
                    {!loading &&<Button color="primary" onClick={() => frmCaptura()}>                    
                      Generar
                    </Button>}
                    <RotateSpinner color={"#1261ff"}size={30} loading={loading}/>
                  </FormGroup>
                </Row>
              </CardFooter>
            </Card>
          </Row>
          
        </div>
       
        <Footer fluid />
      </div>
    </div>
  );
};

export default AuditoriasAdd;
