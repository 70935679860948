import React, { useState } from 'react';
import { Row, Col, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";

const ModalNuevo = ({ onHide, OnEdit }) => {
    const token = getVars("Token");
    const [Mensaje, setMensaje] = useState('')
    const [loading, setLoading] = useState(false);
    const papel = ({
        id: OnEdit ? OnEdit.id : '',
        papel: OnEdit ? OnEdit.papel : '0',
        dato: OnEdit ? OnEdit.dato : '',
        titulo: OnEdit ? OnEdit.titulo : ''
    });
    const [newPapel, setNewPapel] = useState(papel.dato ? papel.dato : "");



    const setMetaUpdate = (e) => {
        setLoading(true);
        setMensaje('');

        if (papel.papel) {
            setLoading(false);
            onHide();
        }

        var parametros = {
            id: papel.id,
            dato: newPapel,
            papel: papel.papel
        }

        EjecutarApi(parametros, "registroNuevo", token.access_token, "POST")
            .then(res => {
                if (res.data == 1) {
                    showMessage("Cambio de Registro Papel Correcto" + res.data.Seccion, "#1BD350")
                }
                setLoading(false);
                onHide(true);

            })
            .catch((e) => {
                setLoading(false);
                showMessage("Error: setRegistro_papel")
            })
    }

    const showMessage = (message, background) => {
        notify.show(message, "custom", 5000, {
            background: background,
            fontSize: 14,
            text: "#FFFFFF",
        });
    }


    return (
        <Modal
            isOpen={true}
            toggle={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader toggle={onHide}>
                {"Avance Papel: " + papel.titulo}

            </ModalHeader>
            <ModalBody>
                {loading && <MetroSpinner size={30} color="#686769" loading={loading} />}
                <Row md='12' className="justify-content-md-center">
                    <Col sm="5" >
                        <Label> Ingrese Cuantos Nuevos Registros</Label>
                        <Input
                            style={{ textAlign: "center" }}
                            //size="xm"
                            id="Nuevo"
                            min={0}
                            value={newPapel}
                            type="number"
                            onChange={(e) =>
                                setNewPapel(
                                    e.target.value
                                )
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Label id='mensaje' className='text-danger' >{Mensaje}</Label>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="justify-content-md-center">
                <Row >
                    <Col>
                        <Button color='primary' className='primary' size='sm' onClick={(e) => setMetaUpdate(e)} >Guardar</Button>
                    </Col>
                    <Col>
                        <Button color='danger' className='danger' size='sm' onClick={(e) => onHide(false)} >Cancelar</Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>

    );
}
export default ModalNuevo;