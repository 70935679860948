import React, { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  NavLink,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  FormGroup,
  Input,


} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Notifications, { notify } from "react-notify-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faInfoCircle,
  faLock,

} from "@fortawesome/free-solid-svg-icons";
import { CircleSpinner } from "react-spinners-kit";

import Navbar from "../../components/Navbar";

import Sidebar from "../../components/Sidebar";

import PictureUpload from "../../components/Perfil/PictureUpload.jsx";

import UsuarioEdit from "../../components/Perfil/DetalleUsuarioPerfil";
import UsuarioMenu from "../../components/Perfil/UsuarioMenu";
import UsuarioLog from "../../components/Perfil/UsuarioLog";

//import ModalCoordinador from "../components/Catalogos/ModalCoordinador";
import EjecutarApi from "../../data/getPOST";

import { getVars,URL_PUBLICA} from "../../GlobalStorage";


function PerfilUsuario(props) {
  const user = getVars("Token");
  const [passwordCollapsed, setPasswordCollapsed] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  const [password, setPaswoord] = useState("");
  const [pin, setPin] = useState("");
  const [pinCollapsed, setPinCollapsed] = useState(false);
  const [UserID, setUserID] = useState({});
  const [isdesvincular1, setisDesvincular1] = useState(
    user.user.DeviceID !== null ? true : false
  );
  const [isdesvincular2, setisDesvincular2] = useState(
    user.user.DeviceID2 !== null ? true : false
  );
  const [desvincularCollapsed, setDesvincularCollapsed] = useState(false);
  const [coordinador] = useState(user.user.Coordinador.label);
  const [coordinadorCollapsed, setCoordinadorCollapsed] = useState(false);

  const [catalogos, setCatalogos] = useState([]);
  const [loaded, setLoaded] = useState(false);

  //Banderas
  const [loading, setLoading] = useState(false);
  const [AlertaCoordinador, setAlertaCoordinador] = useState(false);
  const [classPassword, setClassPaswoord] = useState({
    pass: "",
    passConfirm: "",
  });
  const [state, setState] = useState({
    horizontalTabs: "Datos",
    verticalTabs: "Permisos",
    pageTabs: "Accesos",
    openedCollapses: ["collapseOne", "collapse1"],
  });


  const isCandidato = () => {
    return [50, 51, 52, , 53, 54, 55].includes(user.user.Responsabilidad.value)
  }

  useEffect(() => {
    EjecutarApi({}, "catCompromisosWeb", user.access_token, "GET")
      .then(function (res) {
        return res;
      })
      .then((items) => {
        if (items.results) {
          setCatalogos(items.data);
          setLoaded(true);
        } else {
          notify.show("No Info: catCompromisosWeb", "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        notify.show("Error: catCompromisosWeb", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  }, []);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const collapsesToggle = (tab) => {
    switch (tab) {
      case "password":
        if (passwordCollapsed) {
          setPasswordCollapsed(false);
        } else {
          setPasswordCollapsed(true);
        }
        break;
      case "pin":
        if (pinCollapsed) {
          setPinCollapsed(false);
        } else {
          setPinCollapsed(true);
        }
        break;
      case "desvincular":
        if (desvincularCollapsed) {
          setDesvincularCollapsed(false);
        } else {
          setDesvincularCollapsed(true);
        }
        break;
      case "coordinador":
        if (coordinadorCollapsed) {
          setCoordinadorCollapsed(false);
        } else {
          setCoordinadorCollapsed(true);
        }
        break;
      default:
        break;
    }
  };
  const verificaPassword = (event) => {
    if (password === event) {

      setClassPaswoord({ classPassword, pass: "has-success" });
      setClassPaswoord({ classPassword, passConfirm: "has-success" });
      return true;
    } else {

      setClassPaswoord({ classPassword, pass: "has-danger" });
      setClassPaswoord({ classPassword, passConfirm: "has-danger" });
      return false;
    }
  };
  const handlleCambiarContrasenia = () => {
    setLoading(true);
    var data = {
      idUser: user.user.id,
      password: password,
    };
    EjecutarApi(data, "updPassword", user.access_token)
      .then(function (res) {
        if (res.success) {
          notify.show(`El registro se agrego con éxito!!`, "custom", 5000, {
            background: "#18c97d",
            fontSize: 14,
            text: "#FFFFFF",
          });
          setLoading(false);
          setIsPassword(true);
        } else {
          setLoading(false);
          notify.show(`Error: ${res.message}!!`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        return true;
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };
  const handlleCambiarPIN = () => {
    setLoading(true);
    var data = {
      idUser: user.user.id,
      pin: pin,
    };
    EjecutarApi(data, "updPin", user.access_token)
      .then(function (res) {
        if (res.success) {
          notify.show(`El registro se agrego con éxito!!`, "custom", 5000, {
            background: "#18c97d",
            fontSize: 14,
            text: "#FFFFFF",
          });
          setPin("");
          setLoading(false);
        } else {
          setLoading(false);
          notify.show(`Error: ${res.message}!!`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        return true;
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };
  const handlleDesvincularCel = (device) => {
    setLoading(true);
    var data = { email: user.user.Email, "device": device };
    EjecutarApi(data, "restoreDeviceAndToken", user.access_token, "POST")
      .then(function (res) {
        if (res.success) {
          notify.show(`El registro se actualizó con éxito!!`, "success", 5000);
          if (device == "1") {
            setisDesvincular1(false);
          }
          if (device == "2") {
            setisDesvincular2(false);
          }

          setLoading(false);
          setDesvincularCollapsed(false);
        } else {
          if (device == "1") {
            setisDesvincular1(false);
          }
          if (device == "2") {
            setisDesvincular2(false);
          }
          setLoading(false);
          notify.show(`Error: ${res.message}!!`, "error", 5000);
        }
        return true;
      })
      .catch((e) => {
        if (device == "1") {
          setisDesvincular1(false);
        }
        if (device == "2") {
          setisDesvincular2(false);
        }
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "error", 5000);
      });
  };
  // const handlleDesvincularCel = () => {
  //   setLoading(true);
  //   var data = { email: user.user.Email };
  //   EjecutarApi(data, "restoreDeviceAndToken", user.access_token, "POST")
  //     .then(function (res) {
  //       if (res.success) {
  //         notify.show(`El registro se actualizó con éxito!!`, "custom", 5000, {
  //           background: "#18c97d",
  //           fontSize: 14,
  //           text: "#FFFFFF",
  //         });
  //         setisDesvincular(false);
  //         setLoading(false);
  //         setDesvincularCollapsed(false);
  //       } else {
  //         setisDesvincular(true);
  //         setLoading(false);
  //         notify.show(`Error: ${res.message}!!`, "custom", 5000, {
  //           background: "#0857B6",
  //           fontSize: 14,
  //           text: "#FFFFFF",
  //         });
  //       }
  //       return true;
  //     })
  //     .catch((e) => {
  //       setisDesvincular(true);
  //       setLoading(false);
  //       notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
  //         background: "#0857B6",
  //         fontSize: 14,
  //         text: "#FFFFFF",
  //       });
  //     });
  // };

  const mensajeCoordinador = (
    <>
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="¿Está seguro que desea cambiar al coordinador?"
        onConfirm={() => {

          setAlertaCoordinador(false);
        }}
        onCancel={() => setAlertaCoordinador(false)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Si, cambiar!"
        cancelBtnText="Cancel"
        showCancel
      >
        !{coordinador}!
      </ReactBSAlert>
    </>
  );

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>
            <br />
            {loading && (
              <CircleSpinner
                className="text-center"
                size={30}
                color="#686769"
                loading={loading}
              />
            )}
            <br />
            <br />
          </Row>
          <Row>
            <Col sm="4">
              <Card className="card-user">
                <CardHeader>
                  {user.user.foto64 === null ? (
                    <>
                      <div className="image">
                        <PictureUpload />
                      </div>
                    </>
                  ) : (
                      <>
                        <div className="author">
                          <a href="!#" >
                            <img

                              alt="..."
                              className="avatar border-gray"
                              src={user.user.foto64}
                            />
                          </a>
                        </div>
                      </>
                    )}
                </CardHeader>
                <CardBody>
                  <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                  >
                    <CardTitle tag="h4" className="text-center">
                      {" "}
                      {user.user.Nombre} {user.user.Paterno} {user.user.Materno}{" "}
                    </CardTitle>
                    <CardTitle tag="h5" className="text-center">
                      {" "}
                      {user.user.Responsabilidad.label}{" "}
                    </CardTitle>
                    <CardTitle tag="h5" className="text-center">
                      {" "}
                      {coordinador}{" "}
                    </CardTitle>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={passwordCollapsed}
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("password")}
                        >
                          <Button
                            className="btn-round btn-icon"
                            color="danger"
                            outline
                            size="sm"
                            onClick={() => collapsesToggle("password")}
                            id="EditUser"
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </Button>
                          {"  "}Contraseña y Seguridad{" "}
                        </a>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={passwordCollapsed}>
                        <CardBody sm="3">
                          <FormGroup
                            className={`has-label ${classPassword.pass}`}
                          >
                            <br />
                            <Col md="5">
                              <label>Contraseña: </label>
                              <Button
                                size="sm"
                                color="default"
                                onClick={() => {
                                  setIsPassword(false);
                                }}
                              >
                                <span className="btn-label">
                                  <i className="nc-icon nc-key-25" />
                                </span>
                                Cambiar...
                              </Button>
                              <br />
                              <label>Password *</label>
                              <Input
                                id="registerPassword"
                                name="password"
                                type="password"
                                autoComplete="off"
                                onChange={(e) => setPaswoord(e.target.value)}
                                disabled={isPassword}
                              />
                              {classPassword.pass === "has-danger" ? (
                                <label className="error">
                                  La contraseña no coincide
                                </label>
                              ) : null}
                            </Col>
                          </FormGroup>
                          <FormGroup
                            className={`has-label ${classPassword.passConfirm}`}
                          >
                            <Col md="5">
                              <label>Confirmar Password *</label>
                              <Input
                                //equalto="#registerPassword"
                                id="registerPasswordConfirmation"
                                name="password_confirmation"
                                type="password"
                                autoComplete="off"
                                onChange={(e) => {
                                  //setPaswoord({password, passConfirm:e.target.value});
                                  verificaPassword(e.target.value);
                                }}
                                disabled={isPassword}
                              />
                              {classPassword.passConfirm === "has-danger" ? (
                                <label className="error">
                                  La contraseña no coincide.
                                </label>
                              ) : null}
                            </Col>
                          </FormGroup>

                          <FormGroup>
                            <Button
                              size="sm"
                              color="success"
                              disabled={isPassword}
                              onClick={() => handlleCambiarContrasenia()}
                            >
                              Guardar
                            </Button>
                          </FormGroup>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={pinCollapsed}
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("pin")}
                        >
                          <Button
                            className="btn-round btn-icon"
                            color="success"
                            outline
                            size="sm"
                            onClick={() => collapsesToggle("pin")}
                            id="EditPin"
                          >
                            <FontAwesomeIcon icon={faLock} />
                          </Button>
                          {"  "}Seguridad PIN{" "}
                        </a>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={pinCollapsed}>
                        <CardBody sm="3">
                          <FormGroup>
                            <label>PIN Actual:</label>
                            <Input
                              id="pin"
                              name="pin"
                              value={user.user.PinCode}
                              maxLength="5"
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>PIN *</label>
                            <Input
                              id="pin"
                              name="pin"
                              onChange={(e) => setPin(e.target.value)}
                              value={pin}
                              maxLength="5"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Button
                              size="sm"
                              color="success"
                              onClick={() => handlleCambiarPIN()}
                            >
                              Guardar
                            </Button>
                          </FormGroup>
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={desvincularCollapsed}
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("desvincular")}
                        >
                          <Button
                            className="btn-round btn-icon"
                            color="info"
                            outline
                            size="sm"
                            onClick={() => collapsesToggle("desvincular")}
                            id="EditUser"
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </Button>
                          {"  "}
                          Desvincular Celular{" "}
                        </a>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={desvincularCollapsed}>
                        <CardBody sm="3">
                          <FormGroup>
                            {(isdesvincular1 || isdesvincular2) ? (
                              <>
                                {isdesvincular1 && (
                                  <>
                                    <label>Desvincular Dispositivo 1</label>
                                    <br />
                                    <Button
                                      size="sm"
                                      className="btn-round"
                                      color="primary"
                                      outline
                                      onClick={() => handlleDesvincularCel("1")}
                                    >
                                      <span className="btn-label">
                                        <i className="nc-icon nc-lock-circle-open" />
                                      </span>
                                      Desvincular
                                    </Button>
                                  </>
                                )}
                                {isdesvincular2 && (
                                  <>
                                    <label>Desvincular Dispositivo 2</label>
                                    <br />
                                    <Button
                                      size="sm"
                                      className="btn-round"
                                      color="primary"
                                      outline
                                      onClick={() => handlleDesvincularCel("2")}
                                    >
                                      <span className="btn-label">
                                        <i className="nc-icon nc-lock-circle-open" />
                                      </span>
                                      Desvincular
                                    </Button>
                                  </>
                                )}
                              </>
                            ) : (
                                <>
                                  <label>No tiene dispositovos vinculados</label>
                                </>
                              )}
                            {/* <Label id='txt1' className='text-muted'>Dispositivo 1 Vinculado </Label>
                            <br />
                            <div className="togglebutton switch-sidebar-mini">
                              <Switch id='Dip1'
                                onChange={() => handlleDesvincularCel("1")}
                                value={UserID.DeviceID ? true : false}
                                onColor="info"
                                offColor="info"
                              />
                            </div>
                            <br />
                            <Label id='txt2' className='text-muted'>Dispositivo 2 Vinculado </Label>
                            <br />
                            <div className="togglebutton switch-sidebar-mini">
                              <Switch id='Dip2'
                                onChange={() => handlleDesvincularCel("2")}
                                value={UserID.DeviceID2 ? true : false}
                                onColor="info"
                                offColor="info"
                              />
                            </div> */}
                          </FormGroup>
                        </CardBody>
                      </Collapse>
                    </Card>
                    {/*<Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={coordinadorCollapsed}
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => collapsesToggle("coordinador")}
                        >
                          <Button
                            className="btn-round btn-icon"
                            color="warning"
                            outline
                            size="sm"
                            onClick={() => collapsesToggle("coordinador")}
                            id="EditUser"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          {"  "}
                          Coordinador{" "}
                        </a>
                      </CardHeader>
                      <Collapse role="tabpanel" isOpen={coordinadorCollapsed}>
                        <CardBody sm="3">
                          <Row>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  id="coordinador"
                                  type="text"
                                  value={coordinador}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="4">
                              <FormGroup>
                                <Button
                                  size="sm"
                                  color="primary"
                                  onClick={(e) =>{ setAlertaCoordinador(true); updateCoordinador(e)}}
                                >
                                  Cambiar
                                </Button>
                              </FormGroup>

                              {flagModalCoordinador ? (
                                <ModalCoordinador datos={getModalCoordinador} />
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Collapse>
                    </Card>*/}
                  </div>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row className="text-center">
                    <Col className="col-md-4">
                      <small>Total Compromisos</small>
                      <div className="icon-big text-center icon-warning">
                        {user.totalCompromisos}
                      </div>
                    </Col>
                    <Col className="col-md-4">
                      <small>Total Voluntarios</small>
                      <div className="icon-big text-center icon-warning">
                        {" "}
                        {user.totalVoluntarios}
                      </div>
                    </Col>
                    <Col className="col-md-4">
                      <small>Total Responsabilidades</small>
                      <div className="icon-big text-center icon-warning">
                        {user.totalResponsabilidades}
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
            <Col sm="8">
              <Card className="card-stats">
                <CardHeader>Mi Perfil</CardHeader>
                <CardBody>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            aria-expanded={state.horizontalTabs === "Datos"}
                            data-toggle="tab"
                            //href="#pablo"
                            role="tab"
                            className={
                              state.horizontalTabs === "Datos" ? "active" : ""
                            }
                            onClick={() =>
                              setState({ horizontalTabs: "Datos" })
                            }
                          >
                            Datos
                          </NavLink>
                        </NavItem>
                        {
                          !isCandidato() && (
                            <NavItem>
                              <NavLink
                                aria-expanded={state.horizontalTabs === "Permisos"}
                                data-toggle="tab"
                                //href="#pablo"
                                role="tab"
                                className={
                                  state.horizontalTabs === "Permisos"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  setState({ horizontalTabs: "Permisos" })
                                }
                              >
                                Permisos
                          </NavLink>
                            </NavItem>)
                        }
                        <NavItem>
                          <NavLink
                            aria-expanded={state.horizontalTabs === "Accesos"}
                            data-toggle="tab"
                            // href="#pablo"
                            role="tab"
                            className={
                              state.horizontalTabs === "Accesos" ? "active" : ""
                            }
                            onClick={() =>
                              setState({ horizontalTabs: "Accesos" })
                            }
                          >
                            Accesos
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={state.horizontalTabs}
                  >
                    <TabPane tabId="Datos" role="tabpanel">
                      <UsuarioEdit data={user.user} catalogos={catalogos} loaded={loaded}></UsuarioEdit>
                    </TabPane>
                    {!isCandidato() && (
                      <TabPane tabId="Permisos" role="tabpanel">
                        <p>Tabla de Permisos Web</p>
                        <UsuarioMenu idUser={user.user.id} isMovil={false} />
                        <p>Tabla de Permisos Movil</p>
                        <UsuarioMenu idUser={user.user.id} isMovil={true} />
                      </TabPane>)
                    }
                    <TabPane tabId="Accesos" role="tabpanel">
                      <p>Log de Accesos</p>
                      <UsuarioLog idUser={user.user.id} />
                    </TabPane>
                  </TabContent>
                </CardBody>

                <CardFooter>
                  {AlertaCoordinador ? mensajeCoordinador : <></>}
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
export default PerfilUsuario;
