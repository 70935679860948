import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Button, Card, CardHeader, CardBody, CardFooter, Input, FormGroup, Label, InputGroup, InputGroupAddon, CustomInput, Spinner } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import PerfectScrollbar from 'perfect-scrollbar';
import ReactTable from 'react-table';
import Select from 'react-select';
import swal from "sweetalert";

import { getVars, INTERVAL } from 'GlobalStorage';
import EjecutarApi from 'data/getPOST';
import { getDataMenu } from 'data/menus';
import Pagination from "components/Pagination/pagination";
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import useWindowDimensions from 'components/useWindowDimensions';
import moment from "moment";

var ps;

let People = new Array();
let checkedArray = new Array();

const CotejoBingo = props => {
    const mainPanel = useRef();
    const token = getVars('Token');
    const [_menu, _setmenu] = useState({});
    const { height, width } = useWindowDimensions();
    const [catCasillas, setCatCasillas] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [loading1, setLoading1] = useState(false);
    const [TotalRegistros, setTotalRegistros] = useState(0);
    const [Registros, setRegistros] = useState([]);
    // const [TotalRegistrosSeccion, setTotalRegistrosSeccion] = useState(0);
    // const [RegistrosSeccion, setRegistrosSeccion] = useState([]);
    // const [Filtro, setFiltro] = useState([]);
    const [isLoadingCasillas, setIsLoadingCasillas] = useState(false);
    const [DefaultPageSize, setDefaultPageSize] = useState(500);
    const [filter, setFilter] = useState({
        Seccion: "",
        NombreCasilla: { value: '0', label: 'Seleccione una Casilla' }
    });
    // const [filtered, setFiltered] = useState([]);
    // const [flagSearch, setFlagSearch] = useState(false);
    // const [reviewed, setReviewed] = useState(true);
    // const [position, setPosition] = useState("");
    // const [index, setIndex] = useState(0);
    const [Bingo, setBingos] = useState({
        Seccion: null,
        Bingo: null,
        Casilla: { value: null, label: '' },
        Hoja: 1,
    })

    useEffect(() => {
        let _menuStorage = getDataMenu(191, token.menus);
        if (!_menuStorage.Ver) {
            _menuStorage = getDataMenu(192, token.menus);
        }
        _setmenu(_menuStorage)

        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle('perfect-scrollbar-on');
        }

        if (props.history.action === 'PUSH') {
            mainPanel.current.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        setTotalRegistros(Registros.length);
    }, [Registros])

    // useEffect(() => {
    //     setTotalRegistrosSeccion(RegistrosSeccion.length);
    // }, [RegistrosSeccion])

    const handleMiniClick = () => {
        document.body.classList.toggle('sidebar-mini');
    };

    const onChangeSeccion = (Seccion) => {
        // setFlagSearch(false);
        setRegistros([]);
        if (Seccion.length > 0) {
            setCatCasillas([]);
            setIsLoadingCasillas(true);
            EjecutarApi({ "seccion": Seccion }, 'validarSeccionSije', token.access_token)
                .then(function (res) {
                    return res;
                })
                .then((res) => {
                    let color = 'success';
                    if (res.results) {
                        setLoading(false);
                    }
                    else {
                        color = "error";
                    }
                    notify.show(res.message, color, 5000);
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                });

            EjecutarApi({ "seccion": Seccion }, 'getCasillasBySeccion', token.access_token)
                .then(function (res) {
                    return res;
                })
                .then((res) => {
                    if (res.success && res.results) {
                        setCatCasillas(res.data.map(item => {
                            return {
                                value: item.label,
                                label: item.label
                            }
                        }));
                    }
                    setIsLoadingCasillas(false);
                })
                .catch((e) => {
                    notify.show(`Error en: ${e.error}`, 'error', 5000);
                    setIsLoadingCasillas(false);
                });

            setBingos({ ...Bingo, Bingo: '', Casilla: { value: null, label: '' }, Seccion: Seccion })
        }
    }

    const onChangeCasilla = (casilla) => {
        setFilter({ ...filter, NombreCasilla: casilla });
        setBingos({ ...Bingo, Casilla: casilla });
        // setReviewed(true);
        let searchFilters = [];
        let flag = 0;
        if (filter.Seccion !== "") {
            searchFilters.push({ id: 'b.Seccion', value: filter.Seccion });
            flag += 1;
        }
        if (casilla.label !== "") {
            searchFilters.push({ id: 'b.NombreCasilla', value: casilla.label });
            flag += 1;
        }

        // setFiltro(searchFilters);
        if (flag > 1) {
            getRegistros({
                page: 0,
                pageSize: DefaultPageSize,
                filtered: searchFilters,
                sorted: [],
            });
        }
    }

    const getRegistros = (data) => {
        // setLoading(true);
        // EjecutarApi(data, 'movilizacion/getCuadernillos', token.access_token)
        //     .then(function (res) {
        //         return res;
        //     })
        //     .then((res) => {
        //         if (res.success && res.results) {
        //             setTotalRegistros(res.total);

        //             let tmpArray = res.data.map((item, k) => {
        //                 item.idBingo = k + 1;
        //                 return item;
        //             });
        //             setRegistros(tmpArray);
        //             if (res.data.length > 0) {
        //                 setReviewed(res.data[0].status === 1 ? true : false);
        //             }

        //         }
        //         setLoading(false);
        //     })
        //     .catch((e) => {
        //         notify.show(`Error en: ${e.error}`, 'error', 5000);
        //         setLoading(false);
        //     });
        setLoading(true);
        EjecutarApi(data, 'movilizacion/getBingosWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                setTotalRegistros(res.total);
                setRegistros(res.data);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show(
                    `Error al obtener setDataBingos: ${e.error}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF',
                    }
                );
            });
    }

    // const getRegistrosSeccion = (data) => {
    //     setLoading1(true);
    //     setFlagSearch(false);
    //     EjecutarApi(data, 'movilizacion/getCuadernillos', token.access_token)
    //         .then(function (res) {
    //             return res;
    //         })
    //         .then((res) => {
    //             if (res.success && res.results) {
    //                 if (res.total === 0) {
    //                     notify.show(`La Sección: ${filter.Seccion} no cuenta con casillas extraordinarias o ya han sido finalizadas`, 'warning', 5000);
    //                     setRegistrosSeccion([]);
    //                     setTotalRegistrosSeccion(0);
    //                 } else {
    //                     setTotalRegistrosSeccion(res.total);
    //                     setRegistrosSeccion(res.data);
    //                     setFlagSearch(true);
    //                 }
    //             }
    //             setLoading1(false);
    //         })
    //         .catch((e) => {
    //             notify.show(`Error en: ${e.error}`, 'error', 5000);
    //             setLoading1(false);
    //         });
    // }

    // const columns = [
    //     {
    //         id: "action",
    //         Cell: (item) => {
    //             let k = item.index;
    //             return (
    //                 <CustomInput
    //                     key={k + 1}
    //                     id={`check_${k + 1}`}
    //                     checked={checkedArray[k]}
    //                     onChange={(e) =>
    //                         onChangeCheck(e, item, k)
    //                     }
    //                     type="checkbox"
    //                 />
    //             )
    //         },
    //         sortable: false,
    //         width: 50,
    //         style: { padding: '0px 5px' }
    //     },
    //     {
    //         id: "idBingo",
    //         Header: "Folio",
    //         accessor: 'idBingo',
    //         style: { padding: '0px 5px' },
    //         headerStyle: {textAlign: 'center'},
    //         // filterable: true,
    //         // filterMethod: (filter, row) => customFilter(filter, row),
    //     }
    // ];

    const columns2 = [
        {
            Header: "Acción",
            id: "editing",
            Cell: (item, k) => {
                return (
                    item.original.status === 1 ? <strong><span style={{ color: "#005CB9" }}>Revisado</span></strong> :
                        <>
                            <Button
                                className="btn-link btn btn-sm"
                                color="danger"
                                onClick={() => { deleteItem(item, item.index); }}
                                style={{ margin: "0px 0px" }}
                            ><i className="fa fa-trash" aria-hidden="true"></i>
                            </Button>
                        </>
                )
            },
            sortable: false,
            minWidth: 150,
            width: 150,
            maxWidth: 200,
            style: { padding: '0px 5px' }
        },
        {
            Header: "Sección",
            accessor: "Seccion",
            minWidth: 50,
            width: 120,
            maxWidth: 200,
            style: { padding: '0px 5px' }
        },
        {
            Header: "Casilla",
            accessor: "NombreCasilla",
            style: { padding: '0px 5px' }
        },
        {
            Header: "Hora",
            accessor: "Hora",
            style: { padding: '0px 5px' }
        },
        {
            Header: "Bingo",
            id: "Bingo",
            accessor: "Bingo",
            style: { padding: '0px 5px' }
        },
        {
            Header: "Usuario",
            id: "Usuario",
            accessor: "Usuario",
            style: { padding: '0px 5px' },
            headerStyle: { textAlign: 'center' },
        },
    ];


    // const addNew = (e, pos, item) => {
    //     e.preventDefault();
    //     setPosition(pos);
    //     setIndex(item.index);
    // }

    // const saveNewItem = (item) => {
    //     if (position === 'up') {
    //         addUp(item);
    //     } else if (position === 'down') {
    //         addDown(item);
    //     }
    // }

    // const addDown = (newItem) => {
    //     setLoading(true);
    //     let prevData = [];
    //     let nextData = [];
    //     if (index === 0) {
    //         prevData.push(Registros[index]);
    //         nextData = Registros.filter(item => item.idBingo > 1);
    //     }
    //     else if ((index + 1) === TotalRegistros) {
    //         prevData = Registros;
    //     } else {
    //         let k = index + 1;
    //         let idBingo = Registros[index];
    //         prevData = Registros.filter(item => item.idBingo <= k);
    //         nextData = Registros.filter(item => item.idBingo > k);
    //     }

    //     let k = prevData.length;
    //     let idBingo = k + 1;
    //     let tmpData = prevData;
    //     tmpData[k] = newItem;
    //     tmpData[k].idBingo = idBingo;
    //     tmpData[k].Seccion = filter.Seccion;
    //     tmpData[k].NombreCasilla = filter.NombreCasilla.label;

    //     nextData.forEach((item, k) => {
    //         idBingo += 1;
    //         item.idBingo = idBingo;
    //         tmpData.push(item);
    //     });

    //     autoSave(tmpData);
    //     setLoading(false);
    // }

    // const addUp = (newItem) => {
    //     setLoading(true);
    //     let nextData = [];
    //     let prevData = Registros.filter(item => item.idBingo < (index + 1));
    //     if (index < 1) {
    //         nextData = Registros.filter(item => item.idBingo >= index);
    //     } else {
    //         nextData = Registros.filter(item => item.idBingo > index);
    //     }

    //     let tmpData = prevData;
    //     let k = prevData.length;
    //     let idBingo = k + 1;
    //     tmpData[index] = newItem;
    //     tmpData[index].idBingo = idBingo;
    //     tmpData[index].Seccion = filter.Seccion;
    //     tmpData[index].NombreCasilla = filter.NombreCasilla.label;

    //     nextData.forEach((item, k) => {
    //         idBingo += 1;
    //         item.idBingo = idBingo;
    //         tmpData.push(item);
    //     });

    //     autoSave(tmpData);
    //     setLoading(false);
    // }

    const deleteItem = (row, k) => {
        swal({
            title: "¿Confirma que desea eliminar?",
            // text: `Una vez confirmado no podrán hacerse las modificaciones`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: {
                cancel: {
                    text: "Cancelar",
                    value: null,
                    visible: true,
                    className: "btn-danger",
                    closeModal: true,
                },
                confirm: {
                    text: "Si, confirmar!",
                    value: true,
                    visible: true,
                    className: "btn-success",
                    closeModal: true
                }
            }
        })
            .then((willDelete) => {
                if (willDelete) {
                    deleteFromServer({
                        Seccion: row.original.Seccion,
                        NombreCasilla: row.original.NombreCasilla,
                        idBingo: row.original.Bingo
                    });
                } else {

                }
            });
    }

    const deleteFromServer = (data) => {
        EjecutarApi(data, 'movilizacion/deleteBingoWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success && res.results) {
                    notify.show(res.message, 'success', 5000);
                    getRegistros({
                        page: 0,
                        pageSize: DefaultPageSize,
                        filtered: [{ id: 'b.Seccion', value: filter.Seccion }, { id: 'b.NombreCasilla', value: filter.NombreCasilla.label }],
                        sorted: [],
                    });
                } else {
                    notify.show(res.message, 'warning', 5000);
                }
                setLoading(false);
            })
            .catch((e) => {
                notify.show(`Error en: ${e.error}`, 'error', 5000);
                setLoading(false);
            });
    }

    // const autoSave = (data) => {
    //     setLoading(true);
    //     EjecutarApi({
    //         Seccion: filter.Seccion,
    //         NombreCasilla: filter.NombreCasilla.label,
    //         data: data
    //     }, 'movilizacion/setCuadernilloAvance', token.access_token)
    //         .then(function (res) {
    //             return res;
    //         })
    //         .then((res) => {
    //             if (res.success) {
    //                 if (res.results) {
    //                     notify.show(res.message, 'success', 5000);
    //                     getRegistros({
    //                         page: 0,
    //                         pageSize: DefaultPageSize,
    //                         filtered: [{ id: 'section', value: filter.Seccion }, { id: 'NombreCasilla', value: filter.NombreCasilla.label }],
    //                         sorted: [],
    //                     });
    //                 } else {
    //                     notify.show(res.message, 'warning', 5000);
    //                 }
    //             } else {
    //                 notify.show(res.message, 'error', 5000);
    //             }
    //             setLoading(false);
    //         })
    //         .catch((e) => {
    //             notify.show(`Error al guardar`, 'error', 5000);
    //             setLoading(false);
    //         });
    // }

    // const getSectionTrProps = (state, rowInfo, instance) => {
    //     if (rowInfo) {
    //         let k = rowInfo.index;
    //         return {
    //             onClick: (e) => {
    //                 if (checkedArray[k]) {
    //                     checkedArray[k] = false;
    //                 } else {
    //                     checkedArray[k] = true;
    //                 }
    //             },
    //             style: {
    //                 height: '30px',
    //                 fontSize: '13px',
    //             },
    //         }
    //     }
    //     return {};
    // }

    const getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            return {
                onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                },
                style: {
                    height: '30px',
                    fontSize: '13px',
                },
            }
        }
        return {};
    }

    // const onChangeCheck = (e, item, k) => {
    //     if (e.target.checked) {
    //         checkedArray[k] = true;
    //         People.push(item.original);
    //     } else {
    //         let copy = People.filter((row) => { return (row.id !== item.original.id) });
    //         checkedArray[k] = false;
    //         People = copy;
    //     }
    // }

    // const addToCasilla = (e) => {
    //     if (filter.NombreCasilla.label !== "") {
    //         let idBingo = TotalRegistros + 1;
    //         let data = People.sort(function (a, b) {
    //             if (a.id > b.id) {
    //                 return 1;
    //             }
    //             if (a.id < b.id) {
    //                 return -1;
    //             }
    //             // a must be equal to b
    //             return 0;
    //         }).map((item, k) => {
    //             return {
    //                 id: item.id,
    //                 NombreCasilla: filter.NombreCasilla.label,
    //                 idBingo: idBingo + k,
    //             }
    //         });

    //         swal({
    //             title: "¿Confirma que desea agregar?",
    //             text: `Revise los elementos seleccionados`,
    //             icon: "warning",
    //             buttons: true,
    //             dangerMode: true,
    //             buttons: {
    //                 cancel: {
    //                     text: "Cancelar",
    //                     value: null,
    //                     visible: true,
    //                     className: "btn-danger",
    //                     closeModal: true,
    //                 },
    //                 confirm: {
    //                     text: "Si, confirmar!",
    //                     value: true,
    //                     visible: true,
    //                     className: "btn-success",
    //                     closeModal: true
    //                 }
    //             }
    //         })
    //             .then((success) => {
    //                 if (success) {
    //                     updateCasilla(data);
    //                 } else {

    //                 }
    //             });
    //     } else {
    //         notify.show(`No se ha seleccionado ninguna Casilla`, 'warning', 5000);
    //     }
    // }

    // const updateCasilla = (data) => {
    //     EjecutarApi({
    //         Seccion: filter.Seccion,
    //         NombreCasilla: filter.NombreCasilla.label,
    //         data: data
    //     }, 'movilizacion/updateNombreCasilla', token.access_token)
    //         .then(function (res) {
    //             return res;
    //         })
    //         .then((res) => {
    //             if (res.success) {
    //                 if (res.results) {
    //                     notify.show(res.message, 'success', 5000);
    //                     getRegistros({
    //                         page: 0,
    //                         pageSize: DefaultPageSize,
    //                         filtered: [{ id: 'section', value: filter.Seccion }, { id: 'NombreCasilla', value: filter.NombreCasilla.label }],
    //                         sorted: [],
    //                     });
    //                     deleteToSeccion(data);
    //                 } else {
    //                     notify.show(res.message, 'warning', 5000);
    //                 }
    //             } else {
    //                 notify.show(res.message, 'error', 5000);
    //             }
    //             setLoading(false);
    //         })
    //         .catch((e) => {
    //             notify.show(`Error al guardar`, 'error', 5000);
    //             setLoading(false);
    //         });
    // }

    // const deleteToSeccion = (data) => {
    //     setLoading1(true);
    //     let ids_to_delete = data.map((item) => { return item.id; });
    //     let filteredArray = RegistrosSeccion.filter((item) => {
    //         return !ids_to_delete.includes(item.id);
    //     });
    //     setRegistrosSeccion(filteredArray);
    //     setLoading1(false);
    //     checkedArray = [];
    //     People = [];
    // }

    // const handleFilteredChanged = (filterList) => {
    //     setFiltered(filterList);
    // };

    // const handleResetFilters = () => {
    //     setFiltered([]);
    // };

    // const customFilter = (filter, row) => {
    //     if (filter.value === '') { return true }
    //     let x = row._original;
    //     let name = `${x.Paterno} ${x.Materno} ${x.Nombre}`;
    //     return name.toUpperCase().includes(filter.value.toUpperCase());
    // }

    // const save = (e) => {
    //     // return;
    //     setLoading(true);
    //     EjecutarApi({
    //         Seccion: filter.Seccion,
    //         NombreCasilla: filter.NombreCasilla.label,
    //         data: Registros
    //     }, 'movilizacion/setCuadernilloAvance', token.access_token)
    //         .then(function (res) {
    //             return res;
    //         })
    //         .then((res) => {
    //             if (res.success) {
    //                 if (res.results) {
    //                     notify.show(res.message, 'success', 5000);
    //                     getRegistros({
    //                         page: 0,
    //                         pageSize: DefaultPageSize,
    //                         filtered: [{ id: 'section', value: filter.Seccion }, { id: 'NombreCasilla', value: filter.NombreCasilla.label }],
    //                         sorted: [],
    //                     });
    //                 } else {
    //                     notify.show(res.message, 'warning', 5000);
    //                 }
    //             } else {
    //                 notify.show(res.message, 'error', 5000);
    //             }
    //             setLoading(false);
    //         })
    //         .catch((e) => {
    //             notify.show(`Error al guardar`, 'error', 5000);
    //             setLoading(false);
    //         });
    // }

    // const finish = (e) => {
    //     e.preventDefault();
    //     if (filter.Seccion !== "" && filter.NombreCasilla.label !== "") {
    //         if (reviewed) {
    //             notify.show(`El cuadernillo ya ha sido revisado`, 'warning', 5000);
    //         } else {
    //             swal({
    //                 title: "¿Confirma de finalizado?",
    //                 text: `Una vez confirmado no podrán hacerse modificaciones`,
    //                 icon: "warning",
    //                 buttons: true,
    //                 dangerMode: true,
    //                 buttons: {
    //                     cancel: {
    //                         text: "Cancelar",
    //                         value: null,
    //                         visible: true,
    //                         className: "btn-danger",
    //                         closeModal: true,
    //                     },
    //                     confirm: {
    //                         text: "Si, confirmar!",
    //                         value: true,
    //                         visible: true,
    //                         className: "btn-success",
    //                         closeModal: true
    //                     }
    //                 }
    //             })
    //                 .then((res) => {
    //                     if (res) {
    //                         setFinalizado();
    //                     } else {

    //                     }
    //                 });
    //         }
    //     } else {
    //         notify.show(`No se ha seleccionado Sección y Casilla`, 'warning', 5000);
    //     }
    // }

    // const reset = () => {
    //     setLoading(true);
    //     EjecutarApi({
    //         Seccion: filter.Seccion,
    //         NombreCasilla: filter.NombreCasilla.label,
    //     }, 'movilizacion/resetRevision', token.access_token)
    //         .then(function (res) {
    //             return res;
    //         })
    //         .then((res) => {
    //             if (res.success) {
    //                 if (res.results) {
    //                     notify.show(res.message, 'success', 5000);
    //                     setReviewed(false);
    //                     getRegistros({
    //                         page: 0,
    //                         pageSize: DefaultPageSize,
    //                         filtered: [{ id: 'section', value: filter.Seccion }, { id: 'NombreCasilla', value: filter.NombreCasilla.label }],
    //                         sorted: [],
    //                     });
    //                 } else {
    //                     notify.show(res.message, 'warning', 5000);
    //                 }
    //             } else {
    //                 notify.show(res.message, 'error', 5000);
    //             }
    //             setLoading(false);
    //         })
    //         .catch((e) => {
    //             notify.show(`Error al guardar`, 'error', 5000);
    //             setLoading(false);
    //         });
    // }

    // const setFinalizado = () => {
    //     setLoading(true);
    //     EjecutarApi({
    //         Seccion: filter.Seccion,
    //         NombreCasilla: filter.NombreCasilla.label,
    //         data: Registros
    //     }, 'movilizacion/setCuadernilloFinalizado', token.access_token)
    //         .then(function (res) {
    //             return res;
    //         })
    //         .then((res) => {
    //             if (res.success) {
    //                 if (res.results) {
    //                     notify.show(res.message, 'success', 5000);
    //                     setRegistros(Registros.map((item) => {
    //                         item.status = 1;
    //                         return item;
    //                     }));
    //                     setReviewed(true);
    //                 } else {
    //                     notify.show(res.message, 'warning', 5000);
    //                 }
    //             } else {
    //                 setReviewed(false);
    //                 notify.show(res.message, 'error', 5000);
    //             }
    //             setLoading(false);
    //         })
    //         .catch((e) => {
    //             setReviewed(true);
    //             notify.show(`Error en: ${e.error}`, 'error', 5000);
    //             setLoading(false);
    //         });
    // }

    const clearForm = () => {
        setBingos({
            ...Bingo,
            Bingo: ''
        });
    }

    const btnSaveBingo = (e) => {
        e.preventDefault();

        if (Bingo.Seccion === null || Bingo.Seccion === '') {
            notify.show("Hace Falta Campo Sección", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
            return true;
        }
        if (Bingo.Casilla.value === null || Bingo.Casilla.value === '') {
            notify.show("Hace Falta Campo Casilla", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
            return true;
        }
        // if (Bingo.Hoja === null) {
        //   notify.show("Hace Falta campo Hoja", "warning", 5000, {
        //     background: "#0857B6",
        //     fontSize: 14,
        //     text: "#FFFFFF",
        //   });
        //   return true;

        // }
        if (Bingo.Bingo === null || Bingo.Bingo === '') {
            notify.show("Hace Falta el Campo Bingo", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
            return true;
        }
        const datos = {
            Seccion: Bingo.Seccion,
            Bingo: Bingo.Bingo,
            Casilla: Bingo.Casilla ? Bingo.Casilla.label : '',
            Hoja: 1,
        };
        DatosBingo(datos);
    }

    const DatosBingo = (datos) => {
        setLoading(true);
        EjecutarApi(datos, "movilizacion/setMovilizadoWeb", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success) {
                    if (res.results) {
                        notify.show(res.message, "success", 5000, {
                            background: "#18c97d",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                        const data = {
                            page: 0,
                            pageSize: DefaultPageSize,
                            filtered: [
                                { id: 'b.Seccion', value: filter.Seccion },
                                { id: 'b.NombreCasilla', value: filter.NombreCasilla.label }
                            ]
                        };
                        // getRegistros(data);
                        updateWitoutRequest(datos);
                    } else {
                        notify.show(
                            `Error al agregar registro: ${res.message}!!`,
                            "custom",
                            5000,
                            {
                                background: "#0857B6",
                                fontSize: 24,
                                text: "#FFFFFF",
                            }
                        );
                    }
                }
            })
            .catch((e) => {
                notify.show(`Error al agregar el registro: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 24,
                    text: "#FFFFFF",
                });
            })
            .finally(e => {
                setLoading(false);
                clearForm();
            })
    };

    const updateWitoutRequest = (datos) => {
        const datosArray = { ...datos, ...{ Hora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), NombreCasilla: datos.Casilla, Usuario: `${token.user.Nombre} ${token.user.Paterno} ${token.user.Materno}` } };
        let items = new Array();
        items = Registros;
        items.push(datosArray);

        setRegistros(items.sort(function (a, b) {
            if (parseInt(a.Bingo, 10) > parseInt(b.Bingo, 10)) {
                return 1;
            }
            if (parseInt(a.Bingo, 10) < parseInt(b.Bingo, 10)) {
                return -1;
            }
            return 0;
        }));
        setTotalRegistros(items.length);
    }


    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel ps ps--active-y" ref={mainPanel}>
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    {_menu.Ver === 1 ? (
                        <>
                            {/* <Card>
                        <CardHeader>
                            <h6 className="title">
                                <i className="fa fa-book fa-lg"></i>&nbsp;Captura Folio Bingo */}
                            {/* <Button className="btn btn-sm btn-info pull-right" onClick={(e) => save(e)} disabled={reviewed}><i className="fa fa-save"></i> Guardar avance</Button>
                                <Button className="btn btn-sm btn-success pull-right" onClick={(e) => finish(e)} disabled={reviewed}><i className="fa fa-check"></i> Finalizar</Button>
                                {token.user.Responsabilidad.value === 99 && (
                                    <Button className="btn btn-sm btn-warning pull-right" onClick={(e) => reset(e)}><i className="fa fa-rotate-left"></i> Restablecer revisión</Button>
                                )} */}
                            {/* </h6>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm="3" className="text-center"></Col>
                                <Col sm="3">
                                    <FormGroup>
                                        <Label for="seccion"><strong>Sección:</strong></Label>
                                        <Input
                                            id="seccion"
                                            value={filter.Seccion}
                                            onChange={(e) => {
                                                setFilter({ ...filter, Seccion: e.target.value.replace(/\D/, ""), NombreCasilla: [] });
                                            }}
                                            onBlur={(e) => onChangeSeccion(e.target.value)}
                                            autoFocus
                                            pattern="[0-9]*"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup>
                                        <Label for="casilla"><strong>Casilla:</strong></Label>
                                        <Select className="react-select primary"
                                            classNamePrefix="react-select"
                                            id="casilla"
                                            placeholder="Debe validar la sección"
                                            options={catCasillas}
                                            isLoading={isLoadingCasillas}
                                            value={filter.NombreCasilla}
                                            onChange={(e) => onChangeCasilla(e)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="3" className="text-center"></Col>
                            </Row>
                        </CardBody>
                    </Card> */}
                            <Row>
                                <Col md="3" lg="3" sm="12">
                                    <Card>
                                        <CardHeader>
                                            {/* <Row>
                                        <Col md="7" lg="7" sm="12" className="text-center"></Col>
                                        <Col md="5">
                                            <Button className="btn btn-sm btn-info pull-right" onClick={(e) => addToCasilla(e)}><i className="fa fa-plus"></i> Agregar</Button>
                                        </Col>
                                    </Row> */}
                                            <center><strong className="title text-center" style={{ color: "#0041A0" }}>Captura Folio Bingo</strong></center>
                                        </CardHeader>
                                        <CardBody>
                                            <Row style={{
                                                height: height - 200,
                                                overflow: 'scroll',
                                                display: 'block',
                                            }}>
                                                <Col md="12" sm="12" lg="12">
                                                    <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                                                        {/* <ReactTable
                                                    style={{ width: "100%" }}
                                                    size='sm'
                                                    data={RegistrosSeccion}
                                                    noDataText="Sin información"
                                                    // manual
                                                    columns={columns}
                                                    className="-highlight primary-pagination"
                                                    loading={loading1}
                                                    showPagination={true}
                                                    showPaginationBottom={true}
                                                    showTotalPages={true}
                                                    showPageJump={false}
                                                    canNextFromData={true}
                                                    defaultPageSize={DefaultPageSize}
                                                    total={TotalRegistrosSeccion}
                                                    pages={Math.floor(TotalRegistrosSeccion / 10)}
                                                    previousText="Anterior"
                                                    nextText="Siguiente"
                                                    ofText="de"
                                                    pageSizeOptions={[10, 20, 25, 50, 100, 200, 500]}
                                                    PaginationComponent={Pagination}
                                                    // onFetchData={fetchData}
                                                    // params={Filtro}
                                                    getTrProps={getSectionTrProps}
                                                // filtered={filtered}
                                                // filterable
                                                // onFilteredChange={handleFilteredChanged}
                                                /> */}
                                                        <form id="myForm" onSubmit={btnSaveBingo}>
                                                            <FormGroup>
                                                                <Label for="seccion"><strong>Sección:</strong></Label>
                                                                <Input
                                                                    id="seccion"
                                                                    value={filter.Seccion}
                                                                    onChange={(e) => {
                                                                        setFilter({ ...filter, Seccion: e.target.value.replace(/\D/, ""), NombreCasilla: [] });
                                                                    }}
                                                                    onBlur={(e) => onChangeSeccion(e.target.value)}
                                                                    autoFocus
                                                                    pattern="[0-9]*"
                                                                />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label for="casilla"><strong>Casilla:</strong></Label>
                                                                <Select className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    id="casilla"
                                                                    placeholder="Debe validar la sección"
                                                                    options={catCasillas}
                                                                    isLoading={isLoadingCasillas}
                                                                    value={filter.NombreCasilla}
                                                                    onChange={(e) => onChangeCasilla(e)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup >
                                                                <Label for="Bingo"><span className="text-danger">*</span>Bingo:</Label>
                                                                <InputGroup>
                                                                    <Input id="Bingo"
                                                                        maxLength='4' type="number" value={Bingo.Bingo}
                                                                        onChange={(e) => { setBingos({ ...Bingo, Bingo: e.target.value.replace(/\D/, "") }) }}
                                                                    ></Input>
                                                                    <InputGroupAddon addonType="append">
                                                                        <Button
                                                                            style={{ margin: '0px 1px', zIndex: 0 }}
                                                                            color="primary"
                                                                            type="submit"
                                                                        >
                                                                            <i className="fa fa-paper-plane-o fa-sm" />
                                                                        </Button>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                                {loading && <Spinner />}
                                                                <div className="errorFields">
                                                                </div>
                                                            </FormGroup>
                                                        </form>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                        <CardFooter></CardFooter>
                                    </Card>
                                </Col>
                                <Col md="9" lg="9" sm="12">
                                    <Card>
                                        <CardHeader>
                                            {/* <h6>Electores de la Casilla</h6> */}
                                        </CardHeader>
                                        <CardBody>
                                            <Row style={{
                                                height: height - 200,
                                                overflow: 'scroll',
                                                display: 'block',
                                            }}>
                                                <Col md="12" sm="12" lg="12">
                                                    <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                                                        <ReactTable
                                                            style={{ width: "100%" }}
                                                            size='sm'
                                                            data={Registros}
                                                            noDataText="Sin información"
                                                            // manual
                                                            columns={columns2}
                                                            className="-highlight primary-pagination"
                                                            loading={loading}
                                                            showPagination={true}
                                                            showPaginationBottom={true}
                                                            showTotalPages={true}
                                                            showPageJump={false}
                                                            canNextFromData={true}
                                                            defaultPageSize={DefaultPageSize}
                                                            total={TotalRegistros}
                                                            pages={Math.floor(TotalRegistros / 10)}
                                                            previousText="Anterior"
                                                            nextText="Siguiente"
                                                            ofText="de"
                                                            pageSizeOptions={[10, 20, 25, 50, 100, 200, 500]}
                                                            PaginationComponent={Pagination}
                                                            // onFetchData={fetchData}
                                                            // params={Filtro}
                                                            getTrProps={getTrProps}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter></CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    ) : (
                            <>
                                <Label>No tiene permisos para ver</Label>
                            </>
                        )}
                </div>
                <Footer fluid />
            </div>
        </div>
    );
}

export default CotejoBingo;