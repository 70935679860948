import React, { useState, useEffect } from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Table,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';
import { notify } from 'react-notify-toast';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import { getDataMenu } from 'data/menus';
import { getVars } from "GlobalStorage";
import BuscaResponsable from 'components/Usuarios/BuscaResponsableElectoral'
import BuscarOwned from "components/Usuarios/BuscarUserOwned"
import {
  RCFormCasilla,
  RCParamsFormCasilla,
  RCFormCasillaErrors,
  RCRulesCasilla,
} from '../../data/interfaces/_RC';

const AsignacionResponsable = (props) => {
  const {
    voluntarios,
    tipoResponsable,
    casilla,
    isOpen,
    setIsOpen,
    isLoading,
    isLoadingAsignacion,
    asignarRC,
    errorsForm,
    setErrorsForm,
  } = props;
  const style = {
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      maxHeight: '10rem',
      left: '50%',
      padding: '2rem',
      position: 'fixed',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '20rem',
      width: '90%',
      maxWidth: '60rem',
    },
  };
  const [rc, setRC] = useState(RCFormCasilla);
  const [isModal, setisModal] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [Errors, setErrors] = useState(RCFormCasillaErrors);
  const [flagValidations, setFlagValidations] = useState(RCRulesCasilla);
  const [voluntarioVacio, setVoluntarioVacio] = useState(true);
  const [showResponsable, setShowResponsable] = useState(false);
  const [voluntarioSeleccionado, setVoluntarioSeleccionado] = useState({
    Nombres: '',
    Paterno: '',
  });
  const [voluntariosList, setVoluntariosList] = useState([]);
  const token = getVars("Token");
  const [Responsable, setResponsable] = useState({
    value: 0,
    label: ''
});
  useEffect(() => {
    _setmenu(getDataMenu(9, token.menus));
    Object.keys(RCRulesCasilla).forEach(function (key) {
      handleValidation(
        key,
        rc[key],
        RCRulesCasilla[key].rule,
        RCRulesCasilla[key].params
      );
    });
  }, [rc]);

  const handleValidation = function (name, value, validators, params) {
    validators.forEach((validation) => {
      value = value === null ? '' : value;
      value = typeof value === 'string' ? value.replace(/\s+/, '') : value;
      const result = validation(value, params);
      setFlagValidations((prevstate) => ({
        ...prevstate,
        [name]: { valid: result.valid, rule: validators, params: params },
      }));
      if (!result.valid) {
      }
      if (!result.valid) {
        setErrors((prevstate) => ({ ...prevstate, [name]: result.message }));
      } else {
        setErrors((prevstate) => ({ ...prevstate, [name]: '' }));
      }
    });
  };

  useEffect(() => {
    setVoluntariosList(voluntarios);
  }, [voluntarios]);

  const handleSeleccionVoluntario = (voluntario) => {
    setVoluntarioSeleccionado(voluntario);
    setVoluntarioVacio(false);
    setRC(RCFormCasilla);
    setErrorsForm("")
  };

  const handleBusquedaVoluntario = (value) => {
    const copyList = JSON.parse(JSON.stringify(voluntarios));
    const filteredList = copyList.filter((item) => {
      const nombreCompleto =
        item.Nombres.toLowerCase() + ' ' + item.Paterno.toLowerCase();
      return nombreCompleto.includes(value.toLowerCase());
    });
    setVoluntariosList(filteredList);
  };

  const handleAsignacion = () => {
    if (voluntarioVacio) {
      setErrorsForm('Debe seleccionar un voluntario');
    } else {
      setErrorsForm('');
      let formularioValido = true;
      Object.keys(flagValidations).forEach(function (key) {
        formularioValido = formularioValido && flagValidations[key].valid;
      });
      if (formularioValido) {
        setErrorsForm('');
        const data = {
          idCompromiso: voluntarioSeleccionado.id,
          idResponsabilidad: 2,
          Seccion: casilla.Seccion,
          Casilla: casilla.NombreCasilla,
          Propietario: tipoResponsable.value,
          idPartido: 1,
          OCR: rc.ocr,
          VigenciaINE: rc.vigenciaClave,
          tieneExperiencia: rc.haSidoFuncionario,
          tieneAuto: rc.tieneAuto,
          AceptaCuidarOtra: rc.otraCasilla,
          UserOwned: Responsable.value>0?Responsable.value:token.user.id,
        };
        asignarRC(data);
      } else {
        setErrorsForm('Verifique la información del formulario');
      }
    }
  };
  const handleBusarResponsble = () => {
    setisModal(true)

  }
  const onHide = () => {
    setShowResponsable(false);
    setisModal(false);
}
const UserOwned = (e) => {
    setResponsable({ ...Responsable, label: e.NombreCompleto, value: e.id })
    onHide();
}
  return (
    <>
      <Modal
        isOpen={isOpen}
        style={style}
        toggle={setIsOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={setIsOpen}>
          Asignación de <strong>{tipoResponsable.label}</strong> para la casilla{' '}
          <strong>
            {casilla.NombreCasilla} {casilla.Seccion}
          </strong>{' '}
          del Municipio <strong>{casilla.Municipio}</strong>
        </ModalHeader>
        <ModalBody>
          {isLoading && <Spinner color="primary" />}
          {!isLoading && (
            <>
              <Row>
                <Col md="8">
                  <Card>
                    <CardHeader>
                      <FormGroup>
                        <Label for="search">Buscar voluntario</Label>
                        <Input
                          id="search"
                          onChange={(e) =>
                            handleBusquedaVoluntario(e.target.value)
                          }
                        />
                      </FormGroup>
                    </CardHeader>
                    <CardBody>
                      {!voluntariosList && (
                        <h2 style={{ textAlign: 'center' }}>
                          No existen voluntarios
                        </h2>
                      )}
                      {voluntariosList && (
                        <InfiniteScroll
                          height={400}
                          dataLength={voluntariosList.length}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th width="60%">Nombre</th>
                                <th width="5%"></th>
                                <th width="35%">Seleccionar</th>
                              </tr>
                            </thead>
                            <tbody>
                              {voluntariosList.map((voluntario, index) => {
                                return (
                                  <tr key={index}>
                                    <td width="60%">
                                      {voluntario.Nombres} {voluntario.Paterno}
                                    </td>
                                    <td></td>
                                    <th width="35%">
                                      {voluntarioSeleccionado &&
                                      voluntarioSeleccionado.id !==
                                        voluntario.id ? (
                                        <Button
                                          className="btn btn-primary btn-sm"
                                          color="warning"
                                          size="sm"
                                          onClick={() =>
                                            handleSeleccionVoluntario(
                                              voluntario
                                            )
                                          }
                                        >
                                          Seleccionar
                                        </Button>
                                      ) : (
                                        <Label>Seleccionado</Label>
                                      )}
                                    </th>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardHeader>
                      {errorsForm !== '' && (
                        <strong style={{ color: 'red' }}>{errorsForm}</strong>
                      )}
                      {voluntarioVacio && (
                        <Label>Seleccione un voluntario</Label>
                      )}
                      {!voluntarioVacio && (
                        <Label>
                          Datos para{' '}
                          <strong>
                            {voluntarioSeleccionado.Nombres}{' '}
                            {voluntarioSeleccionado.Paterno}
                          </strong>
                        </Label>
                      )}
                    </CardHeader>
                    <CardBody>
                      <Row>
                      {_menu.Capturista === 1 && (<>
                                    <Row>
                                        <FormGroup className="col-md-10 text-warning">
                                            <Label for="Responsable">
                                                <span className="text-danger">*</span>Responsable:
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    value={Responsable.label}
                                                    disabled
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        style={{ margin: '0px 1px' }}
                                                        color="warning"
                                                        onClick={() => handleBusarResponsble()}
                                                    ><i className="nc-icon nc-zoom-split" /></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Row>
                                </>)}
                        <Col className="text-muted">
                          <FormGroup>
                            <Label for="ORC">
                              <span className="text-danger">*</span>OCR
                            </Label>
                            <Input
                              id="ORC"
                              // disabled={voluntarioVacio}
                              value={rc.ocr}
                              onChange={(e) =>
                                setRC({
                                  ...rc,
                                  ocr: e.target.value,
                                })
                              }
                            />
                            <div className="errorFields">
                              <small>{Errors.ocr}</small>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-muted">
                          <FormGroup>
                            <Label for="Vigencia">
                              <span className="text-danger">*</span>Vigencia
                              Clave Elector:
                            </Label>
                            <ReactDatetime
                              id="Vigencia"
                              inputProps={{ disabled: true }}
                              name="Vigencia"
                              dateFormat="YYYY"
                              timeFormat={false}
                              closeOnSelect
                              value={rc.vigenciaClave}
                              onChange={(e) =>
                                setRC({
                                  ...rc,
                                  vigenciaClave: moment(e._d).format('YYYY'),
                                })
                              }
                              inputProps={{ className: 'form-control' }}
                            />
                            <div className="errorFields">
                              <small>{Errors.vigenciaClave}</small>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="propietario">
                              <span className="text-danger">*</span>
                              ¿Ha sido funcionario o representante de casilla?
                            </Label>
                            <FormGroup check inline>
                              <br />
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="chek1"
                                  disabled={voluntarioVacio}
                                  checked={rc.tieneExperiencia}
                                  onChange={() =>
                                    setRC({
                                      ...rc,
                                      tieneExperiencia: 1,
                                    })
                                  }
                                />
                                <span className="form-check-sign" />
                                Si
                              </Label>
                            </FormGroup>{' '}
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="chek1"
                                  disabled={voluntarioVacio}
                                  checked={!rc.tieneExperiencia}
                                  onChange={() =>
                                    setRC({
                                      ...rc,
                                      tieneExperiencia: 0,
                                    })
                                  }
                                />
                                <span className="form-check-sign" />
                                No
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="propietario">
                              <span className="text-danger">*</span>
                              ¿Tiene Auto?
                            </Label>
                            <br />
                            <FormGroup check inline>
                              <br />
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="chek1"
                                  disabled={voluntarioVacio}
                                  checked={rc.tieneAuto}
                                  onChange={() =>
                                    setRC({
                                      ...rc,
                                      tieneAuto: 1,
                                    })
                                  }
                                />
                                <span className="form-check-sign" />
                                Si
                              </Label>
                            </FormGroup>{' '}
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="chek1"
                                  disabled={voluntarioVacio}
                                  checked={!rc.tieneAuto}
                                  onChange={() =>
                                    setRC({
                                      ...rc,
                                      tieneAuto: 0,
                                    })
                                  }
                                />
                                <span className="form-check-sign" />
                                No
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="propietario">
                              <span className="text-danger">*</span>
                              ¿Acepta cuidar otra casilla?
                            </Label>
                            <FormGroup check inline>
                              <br />
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="chek1"
                                  disabled={voluntarioVacio}
                                  checked={rc.otraCasilla}
                                  onChange={() =>
                                    setRC({
                                      ...rc,
                                      otraCasilla: 1,
                                    })
                                  }
                                />
                                <span className="form-check-sign" />
                                Si
                              </Label>
                            </FormGroup>{' '}
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="chek1"
                                  disabled={voluntarioVacio}
                                  checked={!rc.otraCasilla}
                                  onChange={() =>
                                    setRC({
                                      ...rc,
                                      otraCasilla: 0,
                                    })
                                  }
                                />
                                <span className="form-check-sign" />
                                No
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            color="primary"
                            onClick={handleAsignacion}
                            disabled={isLoadingAsignacion}
                          >
                            Guardar
                          </Button>
                          {isLoadingAsignacion && (
                            <Spinner className="pull-left" color="primary" />
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
        {isModal ? <BuscarOwned onHide={() => onHide()} Owned={(e) => UserOwned(e)} RespElectoral="true" /> : <></>}
          <Button color="danger" onClick={setIsOpen}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AsignacionResponsable;
