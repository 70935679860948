import React from "react";
import { Row, Col } from "reactstrap";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const CasillasChart = ({ Title, Subtitle, yAxisTitle,Categories, Series }) => {
    const options = {
        chart: {
            type: "spline"
        },
        title: {
            text: Title
        },
        subtitle: {
            text: Subtitle
        },
        yAxis: {
            title: {
                text: yAxisTitle //'Total de casillas'
            }
        },
        xAxis: {
            categories: Categories,
            accessibility: {
                rangeDescription: 'Range: 2010 to 2017'
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                // pointStart: 2010
            }
        },
        credits: {
            enabled: false
        },
        series: Series,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    }

    return (
        <Col md="12" lg="12" sm="12">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </Col>
    )
}

export default CasillasChart;