import React,{useState,useEffect} from "react";
import style from './style.css';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import networkgraph from "highcharts/modules/networkgraph";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../../../data/getPOST";
import { getVars } from "../../../GlobalStorage";
networkgraph(Highcharts);
const SocialChart = (props)=>{
    const token = getVars("Token");
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: "networkgraph",
            height: '100%'
        },
        title: {
            text: `${token.user.Nombre} ${token.user.Paterno} ${token.user.Materno}`
        },
        subtitle: {
            text: `${token.user.Responsabilidad.label}`
        },
        plotOptions: {
            networkgraph: {
                keys: ['from', 'to'],
                layoutAlgorithm: {
                    enableSimulation: true,
                    //friction: -0.9
                },
                point:{
                    events:{
                        click(e){
                            handlePointClick(e.point);
                        }
                    }
                }
            }
        },
        series: 
        [{
            dataLabels: {
                enabled: true
                ,linkFormat: ''
            },
            id: 'lang-tree',
            marker: {
                radius: 10
              },
            color: 'orange',
            data: []
        }]
    })
    const [loading,setLoading]= useState(false);
    const [Data,setData]= useState([]);
    useEffect(() => {
       
        var param={
            idEstructura:props?props.props:''
        }
      EjecutarApi(param, "getNetworkUsersWeb", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results){
            if (res.data){
                setLoading(false);
                setData([res.data.Estructuras.map((item) => {
                    return [item.NombreSocial,item.NombreEstructuraPadre]
                    })
                 ]);
                setChartOptions({...chartOptions,series:
                    [{...chartOptions.series[0], data:res.data.Estructuras.map((item) => {
                        return [item.NombreSocial,item.NombreEstructuraPadre]}),
                        //return [item.NombreEstructuraPadre,item.NombreSocial]})],
                        
                    }]
                })
       
        }}
      })
      .catch((e) => {
        setLoading(false);
        
      });
  
    }, [])
    const handlePointClick = e => {
      };
    return (
        <div>
            {loading && (
                <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={loading}
                />
            )} 
            <HighchartsReact highcharts={Highcharts} options={chartOptions} classname={style} />
        </div>
    )
}
export default SocialChart