import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Form, Card, CardHeader, CardBody, CardFooter, CardTitle, InputGroup, InputGroupText, InputGroupAddon, Input } from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import PerfectScrollbar from "perfect-scrollbar";

import { getVars } from "GlobalStorage";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from "data/menus";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import ModalFiltro from 'components/Filtro/indexCandidatos';
import CandidatosListado from "components/Candidatos/CandidatosListado";
import CandidatosAdd from "components/Candidatos/CandidatosAdd";
import CandidatosDetalle from "components/Candidatos/CandidatosDetalle";
import useWindowDimensions from "components/useWindowDimensions";

var ps;

const Candidatos = props => {
    const mainPanel = useRef();
    const token = getVars("Token");
    const [_menu, _setmenu] = useState({});
    const [loading, setLoading] = useState(true);
    const { height, width } = useWindowDimensions();
    const [isForm, setisForm] = useState(false);
    const [catalogs, setCatalogs] = useState([]);

    useEffect(() => {

        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }

        if (props.history.action === "PUSH") {
            mainPanel.current.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }

        let menu = getDataMenu(97, token.menus);
        if (Object.keys(menu).length > 0) {
            _setmenu(menu);

            if (menu.Agregar === 1) {
                EjecutarApi({}, "catCompromisosWeb", token.access_token, "GET")
                    .then(function (res) {
                        return res;
                    })
                    .then((items) => {
                        if (items.results) {
                            let _catalogs = items.data;
                            EjecutarApi({ option: 'partidos' }, "catalogos", token.access_token, "GET")
                                .then(function (res) {
                                    if (res.status === 'success') {
                                        _catalogs.Partidos = res.items;
                                        setCatalogs(_catalogs);
                                    } else {
                                        notify.show("Error al cargar catálogo de partidos", "error", 5000);
                                    }
                                }).catch((e) => {
                                    setLoading(false);
                                    _catalogs.Partidos = [];
                                    setCatalogs(_catalogs);
                                    notify.show("Error al cargar catálogo de partidos", "error", 5000);
                                });
                        } else {
                            notify.show("Error al cargar catálogos", "error", 5000);
                        }
                    }).catch((e) => {
                        setLoading(false);
                        notify.show("Error al cargar catálogos", "error", 5000);
                    });
            }
        } else {
            //El usuario no tiene el menú asignado 
        }

    }, []);

    useEffect(() => {
        if (!isForm) {
            // getRegistros();
        }
    }, [isForm]);

    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };

    const handleFiltro = (filter) => {

    }

    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel ps ps--active-y" ref={mainPanel}>
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col sm="12" md="12" lg="12">
                            <Card>
                                <CardHeader>
                                    <h5 className="title">Candidatos</h5>
                                    {!isForm && (
                                        <Col sm="12" md="12" lg="12">
                                            <Row>
                                                {_menu.Agregar === 1 ? (<><Button color="success" size="sm" onClick={() => { setisForm(true) }} >
                                                    <span className="btn-label">
                                                        <i className="fa fa-user-plus fa-lg" />
                                                    </span>
                                                    Capturar
                                            </Button></>) : <></>}
                                                {_menu.Exportar === 1 ? (
                                                    <Button size='sm' className='primary left-align' color='primary' onClick={() => { alert("Exportar") }}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-file-excel-o fa-lg" />
                                                        </span>
                                                        Exportar
                                            </Button>
                                                ) : <></>}
                                            </Row>
                                            <Row>
                                                <Col sm="12">
                                                    <ModalFiltro Menu={_menu} Datos={(e) => handleFiltro(e)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                </CardHeader>
                                <CardBody>
                                    {!isForm && (
                                        <Row>
                                            <Col md="4">
                                                <CandidatosListado></CandidatosListado>
                                            </Col>
                                            <Col md="8">
                                                <CandidatosDetalle></CandidatosDetalle>
                                            </Col>
                                        </Row>)
                                    }
                                    {isForm && (
                                        <Row>
                                            <Col md="12" sm="12" lg="12">
                                                <CandidatosAdd catalogs={catalogs}></CandidatosAdd>
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Footer fluid />
            </div>
        </div>
    );
}

export default Candidatos;