import React, { useEffect, useState } from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

import Pagination from "components/Pagination/pagination";

//Zincri imports

import {
  Badge,
  Button,
  CardFooter,
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap';
import { getVars, setVars } from 'GlobalStorage';
import ReactTable from 'react-table';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import EjecutarApi from 'data/getPOST';
import Notifications, { notify } from 'react-notify-toast';
import RutaModalEditar from 'components/Maps/Electoral/RutasFiles/ModalSeccionRutasEditar';
import RutaModalRGAsignar from 'components/Maps/Electoral/RutasFiles/ModalRG';
import Filtro from 'components/Filtro/Rutas';
import { CircleSpinner } from 'react-spinners-kit';
import { URL_GENERAL } from 'data/Consts';
import { getDataMenu } from 'data/menus.js';

let COORDINADOR_GENERAL = 99;
let SUPERVISOR_ESTATAL_ELECTORAL = 98;
let COORDINADOR_ESTATAL_TERRTORIAL = 97;
let COORDINADOR_ESTATAL_ELECTORAL = 16;
let COORDINADOR_REGIONAL_ELECTORAL = 17;
let COORDINADOR_MUNICIPAL_ELECTORAL = 18;

const Mapas = (props) => {
  const token = getVars('Token');
  const [menu, _setmenu] = useState({});
  const [dataGeoJson, setDataGeoJson] = useState({});
  const [flagFinish, setFlagFinish] = useState(false);
  const [filteredSaved, setFilteredSaved] = useState([]);
  const [municipioSaved, setMunicipioSaved] = useState({});
  const [OpenModal, setOpenModal] = useState(false);
  const [OpenModalRG, setOpenModalRG] = useState(false);

  /* const [dataGeoJsonDivisiones, setDataGeoJsonDivisiones] = useState({
    "type": "FeatureCollection",
    "features": []}); */
  const [loadingTabla, setLoadingTabla] = useState(false);
  const [rutas, setRutas] = useState([]);
  const [RutaModal, setRutaModal] = useState({
    Seccion: [],
    idRuta: 0,
    created_at: '',
    updated_at: '',
    id: 0,
    NombreRuta: '',
    ClaveRuta: '',
    Comentarios: '',
    idRGAsignado: '',
    RG: '',
    UserAsigno: '',
    FechaAsigno: '',
    UserCreate: 0,
  });
  const [FilterRuta, setFilterRuta] = useState([]);
  const [total, setTotal] = useState(0);
  const [RGsRutaParameter, setRGsRutaParameter] = useState([]);
  const [isLoadingModalRGS, setIsLoadingModalRGS] = useState(false);

  const [_position, setPosition] = useState([20.816157, -101.021312]);
  const [tipoMapa, setTipoMapa] = useState(
    getVars('Token').user.Responsabilidad.value === COORDINADOR_GENERAL ||
      getVars('Token').user.Responsabilidad.value ===
      SUPERVISOR_ESTATAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_REGIONAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_ESTATAL_ELECTORAL
      ? { value: 'region', label: 'Regional' }
      : getVars('Token').user.Responsabilidad.value ===
        COORDINADOR_MUNICIPAL_ELECTORAL
        ? { value: 'municipio', label: 'Muncipal' }
        :
        (getVars('Token').user.Capturista.Responsabilidad ===
        COORDINADOR_REGIONAL_ELECTORAL
        || getVars('Token').user.Capturista.Responsabilidad ===
        COORDINADOR_ESTATAL_ELECTORAL) &&
        getVars('Token').user.Capturista.value === 1?
        { value: 'region', label: 'Regional' }
        :
        getVars('Token').user.Capturista.Responsabilidad ===
        COORDINADOR_MUNICIPAL_ELECTORAL
         &&
        getVars('Token').user.Capturista.value === 1?
        { value: 'municipio', label: 'Muncipal' }
        : { value: 'seccion', label: 'Seccion' }
  );
  const [zoom, setZoom] = useState(
    getVars('Token').user.Responsabilidad.value === COORDINADOR_GENERAL ||
      getVars('Token').user.Responsabilidad.value ===
      SUPERVISOR_ESTATAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_REGIONAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_ESTATAL_ELECTORAL
      ? 9
      : getVars('Token').user.Responsabilidad.value ===
        COORDINADOR_MUNICIPAL_ELECTORAL
        ? 11
        : 12
  );
  const [derechoMapa, setDerechoMapa] = useState([]);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState('');
  const [totales, setTotales] = useState({
    rutas: {
      totales: 0,
      asignadas: 0,
      sinAsignar: 0,
      porcentaje: 0,
    },
    rgs: {
      totales: 0,
      asignadas: 0,
      sinAsignar: 0,
      porcentaje: 0,
    },
    secciones: {
      totales: 0,
      asignadas: 0,
      sinAsignar: 0,
      porcentaje: 0,
    },
  });
  const [flagEliminar, setFlagEliminar] = useState(false);
  const [rutaEliminar, setRutaEliminar] = useState(null);
  const [catalogos, setCatalogos] = useState({});
  const [isLoadingCatalogos, setIsLoadingCatalogos] = useState(true);

  const hostserver = URL_GENERAL;

  useEffect(() => {
    _setmenu(getDataMenu(79, token.menus));
    const handleGetTotales = () => {
      EjecutarApi({}, 'getRutasGto/getInfoGlobal', token.access_token)
        .then((response) => {
          if (response.success) {
            setTotales(response.data);
          } else {
            setMensajeAlert('No se pudo obtener los totales');
            setFlagAlert(true);
          }
        })
        .catch((error) => {
          setMensajeAlert(
            'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
          );
          setFlagAlert(true);
        });
    };
    handleGetTotales();

    const handleGetCatalogos = () => {
      EjecutarApi({}, 'getCatalogosRutas', token.access_token)
        .then((response) => {
          if (response.success) {
            setCatalogos(response.data);
          } else {
            setMensajeAlert('No se pudo obtener los catalogos para filtros');
            setFlagAlert(true);
          }

          setIsLoadingCatalogos(false);
        })
        .catch((error) => {
          setIsLoadingCatalogos(false);
          setMensajeAlert(
            'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
          );
          setFlagAlert(true);
        });
    };

    handleGetCatalogos();
  }, []);

  const GetRGs = (all_in = true, edit_rg = null) => {
    let data = {
      filtered: [],
      page: 0,
      pageSize: 10000,
      sort: [],
      tipo: 'and',
      all_in: all_in,
      edit_rg: edit_rg,
    };
    EjecutarApi(data, 'getRGRutasGto', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            let rgs;
            rgs = {
              data: res.data.map((item) => {
                return {
                  value: item.id,
                  label: (
                    item.Nombre +
                    ' ' +
                    item.Paterno +
                    ' ' +
                    item.Materno
                  ).toUpperCase(),
                };
              }),
              total: res.total,
            };
            setRGsRutaParameter(rgs);
            if (edit_rg === null) setOpenModalRG(true);
          }
        }
      })
      .catch((e) => {
        //setLoadingTabla(false);
        notify.show(`Error al obtener RGs: ${e.message}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  };

  const handleAsignRG = (rg) => {
    const rutaEdit = RutaModal;
    rutaEdit.idRGAsignado = rg.value;
    rutaEdit.secciones = RutaModal.Seccion;
    handleUpdateRuta(rutaEdit);
  };

  const handleUpdateRuta = (ruta) => {
    let rutaJsSON = JSON.parse(JSON.stringify(ruta))
    rutaJsSON.secciones = ruta.Seccion;
    rutaJsSON.idRGAsignado = ruta.idRGAsignado == 0 ? null : ruta.idRGAsignado
    rutaJsSON.UserOwned = ruta.UserOwnedChanged ? ruta.UserOwnedChanged : null
    EjecutarApi(rutaJsSON, 'updateRutasGto', token.access_token)
      .then((response) => {
        setMensajeAlert(response.message);
        setFlagAlert(true);
        if (response.success) {
          const data_ = {
            page: 0,
            pageSize: 10,
            filtered: [],
            sort: [{ id: 'rutas.NombreRuta', desc: false }],
            tipo: 'or',
          };
          SolicitudApi(data_, 'getRutasGto', token);
          setOpenModalRG(false);
          setOpenModal(false);
        }
      })
      .catch((error) => {
        setMensajeAlert(
          'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
        );
        setFlagAlert(true);
      });
  };

  const handleDeleteRuta = (ruta) => {
    setMensajeAlert(`¿Esta seguro de eliminar ${ruta.NombreRuta}?`);
    setFlagEliminar(true);
    setRutaEliminar(ruta);
  };

  const handleSearchRuta = (e, textInput) => {
    setLoadingTabla(true);
    setRutas([]);
    e.preventDefault();
    var array_filter = [];
    if (textInput.length !== 0) {
      if (parseInt(textInput, 10) !== NaN) {
        array_filter.push({ id: 'rutas.NombreRuta', value: textInput });
        array_filter.push({ id: 'seccion_rutas.Seccion', value: textInput });
        array_filter.push({ id: 'rgs.NombreCompleto', value: textInput });
        array_filter.push({ id: 'municipios.Municipio', value: textInput });
      } else {
        array_filter.push({ id: 'rutas.NombreRuta', value: textInput });
        array_filter.push({ id: 'rgs.NombreCompleto', value: textInput });
        array_filter.push({ id: 'municipios.Municipio', value: textInput });
      }
    }

    setFilterRuta(array_filter);
    const data_ = {
      page: 0,
      pageSize: 10,
      filtered: array_filter,
      sort: [{ id: 'rutas.NombreRuta', desc: false }],
      tipo: 'or',
    };
    SolicitudApi(data_, 'getRutasGto', token);
  };
  const SolicitudApi = (data, api, token) => {
    setLoadingTabla(true);
    let permisos = getDataMenu(79, token.menus);
    EjecutarApi(data, api, token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setTotal(res.total);
            setRutas(
              res.data.map((item) => {
                return {
                  Region: item.Region,
                  Municipio: item.Municipio.toUpperCase(),
                  NombreRuta:
                    permisos.Editar === 1 ? (
                      <>
                        <Button
                          className="btn-link"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            GetRGs(false, item.idRGAsignado);
                            setRutaModal(item);
                            setOpenModal(true);
                          }}
                        >
                          {(item.NombreRuta + ' ').toUpperCase()}
                          <i className="fa fa-pencil" />
                        </Button>
                      </>
                    ) : (
                        item.NombreRuta.toUpperCase()
                      ),
                  ClaveRuta: item.ClaveRuta.toUpperCase(),
                  Secciones: item.Seccion.map((i) => {
                    return (
                      <Badge key={i} color="success" pill>
                        {i}
                      </Badge>
                    );
                  }),
                  RG: (
                    <>
                      {item.RG !== null &&
                        item.RG !== undefined &&
                        item.RG !== '' ? (
                          item.RG.toUpperCase()
                        ) : permisos.Propuesta === 0 ? (
                          <Button
                            className="btn-link"
                            color="primary"
                            onClick={() => {
                              GetRGs();
                              setRutaModal(item);
                            }}
                          >
                            {'Asignar RG '}
                            <i className="fa fa-pencil" />
                          </Button>
                        ) : (
                            <span>Sin permisos para asignar</span>
                          )}
                    </>
                  ),
                  data: item,
                };
              })
            );
            setLoadingTabla(false);
          }
        }
      })
      .catch((e) => {
        setLoadingTabla(false);
        notify.show(
          `Error al buscar rutas: ${/* e.message */ 'Favor de renovar sesión'}`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };
  const basicAlert = (
    <ReactBSAlert
      style={{
        display: 'block',
        marginTop: '-100px',
        padding: '2px 4px',
        font: '10px/12px Arial, Helvetica, sans-serif',
      }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );

  const deleteAlert = (
    <ReactBSAlert
      style={{
        display: 'block',
        marginTop: '-100px',
        padding: '2px 4px',
        font: '10px/12px Arial, Helvetica, sans-serif',
      }}
      title={MensajeAlert}
      onConfirm={() => setDeleteRuta(rutaEliminar)}
      onCancel={() => {
        setFlagEliminar(false);
        setRutaEliminar(null);
      }}
      confirmBtnBsStyle="info"
      closeBtnStyle="danger"
      showCancel
    />
  );

  function selectDerecho() {
    let derecho_mapa = getVars('Token').user.Responsabilidad.value;

    setDerechoMapa(
      derecho_mapa === COORDINADOR_GENERAL ||
        derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
        derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL ||
        derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL
        ? [1, 3, 4]
        : derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL
          ? [1, 3, 4]
          : derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL
            ? [1, 3]
            : derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL
              ? [1, 3, 4]
              : []
    );
    if (
      derecho_mapa === COORDINADOR_GENERAL ||
      derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
      derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL ||
      derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL
    ) {
      //getDistritos("","GET");
      getRegiones('', 'GET');
    }
    if (derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL) {
      //getMunicipios("","GET");
      getRegiones('', 'GET');
    }
    if (derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL) {
      getMunicipios('', 'GET');
      //getSecciones("","GET");
    }
  }

  const handleFiltro = (filtered, tipo_mapa) => {
    setMunicipioSaved({});
    setFilteredSaved(filtered);
    setFlagFinish(false);
    //setZoom(9);
    setPosition([20.816157, -101.021312]);

    var idMunicipio = filtered.filter((dato) => dato.id === 'idMunicipio');
    var idRegion = filtered.filter((dato) => dato.id === 'idRegion');
    var idSeccion = filtered.filter((dato) => dato.id === 'idSeccion');
    var TieneActas = filtered.filter((dato) => dato.id === 'TieneActas');

    idMunicipio = idMunicipio[0].value;
    idRegion = idRegion[0].value;
    idSeccion = idSeccion[0].value;
    TieneActas = TieneActas[0].value;

    var filtered_plugin = [];
    var puntos = false;
    if (TieneActas !== 9999) {
      puntos = true;
      if (TieneActas !== 3)
        filtered_plugin.push({
          id: 'cat_casillasgto.TieneActas',
          value: TieneActas,
        });
    }
    if (
      idRegion.length !== 0 &&
      idMunicipio.length === 0 &&
      idSeccion.length === 0
    ) {
      setZoom(9);
      setDataGeoJson({});
      setTipoMapa({ value: 'region', label: 'Regional' });
      filtered_plugin.push({ id: 'secciones.idRegion', value: idRegion });
      getRegiones(idRegion, 'POST');
    }

    if (idMunicipio.length !== 0 && idSeccion.length === 0) {
      setZoom(10);
      setDataGeoJson({});
      setTipoMapa({ value: 'municipio', label: 'Municipal' });
      filtered_plugin.push({
        id: 'secciones.idMunicipioReportes',
        value: idMunicipio,
      });
      getMunicipios(idMunicipio, 'POST');
    }
    if (idSeccion.length !== 0) {
      setZoom(13);
      setDataGeoJson({});
      setTipoMapa({ value: 'seccion', label: 'Seccional' });
      filtered_plugin.push({ id: 'secciones.Seccion', value: idSeccion });
      getSecciones(idSeccion, 'POST');
    }
    if (
      idSeccion.length === 0 &&
      idMunicipio.length === 0 &&
      idRegion.length === 0
    ) {
      if (
        getVars('Token').user.Responsabilidad.value ===
        COORDINADOR_MUNICIPAL_ELECTORAL
      ) {
        getMunicipios('', 'GET');
      } else {
        getRegiones('', 'GET');
      }
    }
  };

  function selectmapaparametros(tipomapa) {
    if (tipomapa.value === 'municipio') {
      getMunicipiosRegion([tipomapa.clave], 'POST');
    }
    if (tipomapa.value === 'seccion') {
      getSeccionesMunicipios([tipomapa.clave], 'POST');
    }
  }

  async function getRegiones(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }

      let res = await fetch(hostserver + 'getRegionesElectoralGto', config);
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);

      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setRegiones(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setRegiones({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
    }
  }
  async function getMunicipios(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(hostserver + 'getMunicipiosElectoralGto', config);
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setMunicipios(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setMunicipios({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
    }
  }
  async function getSecciones(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + 'getSeccionesRutasGto', //"getSeccionesElectoralGto", //getSeccionesRutas
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setSecciones(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setSecciones({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
    }
  }
  async function getMunicipiosRegion(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + 'getMunicipiosElectoralGtoRegion',
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setMunicipios(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setMunicipios({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
    }
  }
  async function getSeccionesMunicipios(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + 'getSeccionesRutasGtoMunicipio',
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setSecciones(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setSecciones({
            "type": "FeatureCollection",
            "features": [
            ]
        }); */
    }
  }

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    if (e.value === 'region') {
      setZoom(9);
    }
    if (e.value === 'municipio') {
      setMunicipioSaved(e);
      setZoom(10);
    }
    if (e.value === 'seccion') {
      setMunicipioSaved(e);
      setZoom(11);
    }
    setTipoMapa(e);
    selectmapaparametros(e);
  };
  /* const handleTipoMapa = (tipo) => {
    //setDataGeoJson({});
    //setTipoMapa(e);
    //selectmapa(e);
    setSeccionesSelected([]);
    setFlagFinish(false);

    setDataGeoJson({});
    setTipoMapa(tipo);
    setPosition([20.816157, -101.021312]);
    if (tipo.value === 'region') {
      setZoom(9);
      getRegiones('', 'GET');
      setFlagFinish(true);
    }
    if (tipo.value === 'municipio') {
      if (getVars('Token').user.Responsabilidad.value !== COORDINADOR_MUNICIPAL_ELECTORAL) {
        setZoom(10);
        getMunicipios('', 'GET');
        setFlagFinish(true);
      }
      else {
        setZoom(11);
        getMunicipios('', 'GET');
        setFlagFinish(true);
      }

    }
    if (tipo.value === 'seccion') {
      if (getVars('Token').user.Responsabilidad.value !== COORDINADOR_MUNICIPAL_ELECTORAL) {
        setZoom(10);
        getSecciones('', 'GET');
        setFlagFinish(true);
      }
      else {
        setZoom(11);
        getSecciones('', 'GET');
        setFlagFinish(true);
      }

    }
  }; */

  //Comentada antes de subir 8 enero
  /* function selectmapa(tipomapa) {
    if (tipomapa.value === 'distrito') {
      //getDistritos("","GET");
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios('', 'GET');
    }
    if (tipomapa.value === 'poligono') {
      //getPoligonos("","GET");
    }
    if (tipomapa.value === 'seccion') {
      getSecciones('', 'GET');
    }
  } */

  const handleExport = (e) => {
    var dato = [];
    e.features.forEach((element) => {
      if (tipoMapa.value === 'distrito') {
        dato.push(element.properties['clave']);
      } else if (tipoMapa.value === 'municipio') {
        dato.push(element.properties['cve_mun']);
      } else if (tipoMapa.value === 'poligono') {
        dato.push(element.properties['poligono']);
      } else if (tipoMapa.value === 'seccion') {
        dato.push(element.properties['seccion']);
      }
    });
    const json = {
      data: dato,
      tabla: tipoMapa.value,
    };

    const serializado = JSON.stringify(json);

    var url = new URL(
      hostserver +
      `getReporteMapasCompromisos?token=${token.access_token}&datos=${serializado}`
    );
    //window.open(url, "_blank");
  };

  const handleSaveRoute = (secciones, data_ruta) => {
    //setSeccionesSelected(secciones);
    if (data_ruta.NombreRuta.length < 3 || data_ruta.NombreRuta.length > 40) {
      setMensajeAlert(
        '¡El nombre de la ruta debe contener al menos 3 letras y no exceder 50 letras!'
      );
      setFlagAlert(true);
      return true;
    }
    if (data_ruta.ClaveRuta.length > 10) {
      setMensajeAlert('¡La clave de la ruta debe ser de maximo 10 letras!');
      setFlagAlert(true);
      return true;
    }
    if (data_ruta.Comentarios.length > 1000) {
      setMensajeAlert('¡Los comentarios tienen limite de 1000 letras!');
      setFlagAlert(true);
      return true;
    }
    if (secciones.length === 0) {
      setMensajeAlert(
        'La ruta debe contener al menos una seccion, ¡verifique que existan secciones seleccionadas!'
      );
      setFlagAlert(true);
      return true;
    }

    setRuta(secciones, data_ruta);
  };
  const handleSaveEditRoute = (data_ruta) => {
    //setSeccionesSelected(secciones);
    if (data_ruta.NombreRuta.length < 3 || data_ruta.NombreRuta.length > 40) {
      setMensajeAlert(
        '¡El nombre de la ruta debe contener al menos 3 letras y no exceder 50 letras!'
      );
      setFlagAlert(true);
      return true;
    }
    if (data_ruta.ClaveRuta.length > 10) {
      setMensajeAlert('¡La clave de la ruta debe ser de maximo 10 letras!');
      setFlagAlert(true);
      return true;
    }
    if (data_ruta.Comentarios !== null && data_ruta.Comentarios !== undefined) {
      if (data_ruta.Comentarios.length > 1000) {
        setMensajeAlert('¡Los comentarios tienen limite de 1000 letras!');
        setFlagAlert(true);
        return true;
      }
    }
    if (data_ruta.Seccion.length === 0) {
      setMensajeAlert(
        'La ruta debe contener al menos una seccion, ¡verifique que existan secciones seleccionadas!'
      );
      setFlagAlert(true);
      return true;
    }
    // handleUpdateRuta(data_ruta);
  };

  async function setDeleteRuta(data_ruta) {
    setFlagEliminar(false);
    setDataGeoJson({});
    const token = getVars('Token').access_token;
    try {
      let config = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: data_ruta.id,
        }),
      };
      let res = await fetch(hostserver + 'deleteRutasGto', config);
      let data = await res.json();
      setMensajeAlert(data.message);
      setFlagAlert(true);
      if (data.success === true && data.results === true) {
        setVars('FlagEdit', false);
        setVars('rutaModal', {
          id: '',
          NombreRuta: '',
          ClaveRuta: '',
          Comentarios: '',
        });
        setVars('SeccionesSelected', []);
        //setSeccionesSelected([]);
        setVars('TAB', 'CR');
      } else {
        setVars('FlagEdit', true);
      }
      if (Object.keys(municipioSaved).length !== 0) {
        handleDetalleMapa(municipioSaved);
      } else if (filteredSaved.length !== 0) {
        setDataGeoJson({});
        handleFiltro(filteredSaved, 'NADA');
      } else {
        getSecciones('', 'GET');
      }
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
    }
  }
  async function setRuta(secciones, data_ruta) {
    setDataGeoJson({});
    const token = getVars('Token').access_token;
    try {
      let config = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          secciones: secciones,
          NombreRuta: data_ruta.NombreRuta,
          ClaveRuta: data_ruta.ClaveRuta,
          Comentarios: data_ruta.Comentarios,
          idRGAsignado:
            data_ruta.RG !== null && data_ruta.RG !== undefined
              ? data_ruta.RG
              : null,
        }),
      };
      let res = await fetch(hostserver + 'setRutasGto', config);
      let data = await res.json();
      setMensajeAlert(data.message);
      setFlagAlert(true);
      if (data.success === true && data.results === true) {
        //setSeccionesSelected([]);
        setVars('SeccionesSelected', []);
        /* if(Object.keys(municipioSaved).length !== 0){
          handleDetalleMapa(municipioSaved);
        }
        else {
          setDataGeoJson({});
          handleFiltro(filteredSaved,"NADA");
        } */
      }
      if (Object.keys(municipioSaved).length !== 0) {
        handleDetalleMapa(municipioSaved);
      } else if (filteredSaved.length !== 0) {
        setDataGeoJson({});
        handleFiltro(filteredSaved, 'NADA');
      } else {
        getSecciones('', 'GET');
      }
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
    }
  }
  async function setEditRuta(secciones, data_ruta) {
    setDataGeoJson({});
    const token = getVars('Token').access_token;
    try {
      let config = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: data_ruta.id,
          secciones: secciones,
          NombreRuta: data_ruta.NombreRuta,
          ClaveRuta: data_ruta.ClaveRuta,
          Comentarios: data_ruta.Comentarios,
        }),
      };
      let res = await fetch(hostserver + 'updateRutasGto', config);
      let data = await res.json();
      setMensajeAlert(data.message);
      setFlagAlert(true);
      if (data.success === true && data.results === true) {
        setVars('FlagEdit', true);
      } else {
        setVars('FlagEdit', true);
      }
      if (Object.keys(municipioSaved).length !== 0) {
        handleDetalleMapa(municipioSaved);
      } else if (filteredSaved.length !== 0) {
        setDataGeoJson({});
        handleFiltro(filteredSaved, 'NADA');
      } else {
        getSecciones('', 'GET');
      }
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
    }
  }

  const searchFilter = (data) => {
    setFilterRuta(data.filtered)
    SolicitudApi(data, 'getRutasGto', token)
  }
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <Notifications />
        <div className="content">
          <>
            {FlagAlert ? basicAlert : <></>}
            {flagEliminar ? deleteAlert : <></>}
            {menu.Ver == 0 && <Row></Row>}
            {menu.Ver == 1 && (
              <>
                <Row>
                  <Col lg="4" md="6" sm="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="fa fa-road text-info" />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <p className="card-category">Rutas</p>
                              <CardTitle tag="p">
                                {totales.rutas.totales}
                              </CardTitle>
                              <p />
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md="4" xs="5" className="text-muted text-center">
                            Asignadas
                          </Col>
                          <Col md="4" xs="5" className="text-muted text-center">
                            Sin asignar
                          </Col>
                          <Col md="4" xs="5" className="text-muted text-center">
                            Porcentaje
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: '2%' }}>
                          <Col md="4" xs="5" className="text-center">
                            {totales.rutas.asignadas}
                          </Col>
                          <Col md="4" xs="5" className="text-center">
                            {totales.rutas.sinAsignar}
                          </Col>
                          <Col md="4" xs="5" className="text-center">
                            {totales.rutas.porcentaje} %
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4" md="6" sm="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="fa fa-users text-warning" />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <p className="card-category">RGs</p>
                              <CardTitle tag="p">
                                {totales.rgs.totales}
                              </CardTitle>
                              <p />
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md="4" xs="5" className="text-muted text-center">
                            Asignadas
                          </Col>
                          <Col md="4" xs="5" className="text-muted text-center">
                            Sin asignar
                          </Col>
                          <Col md="4" xs="5" className="text-muted text-center">
                            Porcentaje
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: '2%' }}>
                          <Col md="4" xs="5" className="text-center">
                            {totales.rgs.asignadas}
                          </Col>
                          <Col md="4" xs="5" className="text-center">
                            {totales.rgs.sinAsignar}
                          </Col>
                          <Col md="4" xs="5" className="text-center">
                            {totales.rgs.porcentaje} %
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4" md="6" sm="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="fa fa-university text-success" />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <p className="card-category">Secciones</p>
                              <CardTitle tag="p">
                                {totales.secciones.totales}
                              </CardTitle>
                              <p />
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md="4" xs="5" className="text-muted text-center">
                            Asignadas
                          </Col>
                          <Col md="4" xs="5" className="text-muted text-center">
                            Sin asignar
                          </Col>
                          <Col md="4" xs="5" className="text-muted text-center">
                            Porcentaje
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: '2%' }}>
                          <Col md="4" xs="5" className="text-center">
                            {totales.secciones.asignadas}
                          </Col>
                          <Col md="4" xs="5" className="text-center">
                            {totales.secciones.sinAsignar}
                          </Col>
                          <Col md="4" xs="5" className="text-center">
                            {totales.secciones.porcentaje} %
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Card>
                  <CardHeader>
                    <p align="center">
                      <strong>RUTAS ELECTORALES</strong>
                    </p>
                  </CardHeader>
                  <CardBody>
                    {isLoadingCatalogos ? (
                      <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={isLoadingCatalogos}
                      />
                    ) : (
                        <Filtro
                          data={catalogos}
                          searchFilter={(e) =>
                            searchFilter(e)
                          }
                          handleSearchRuta={handleSearchRuta}
                          token={token}
                        />
                      )}
                    <Row>
                      <Col>
                        {true ? (
                          <>
                            <Row>
                              <Col
                                style={{
                                  height: '100%',
                                  width: '100%',
                                }}
                              >
                                <ReactTable
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                  }}
                                  size="sm"
                                  data={rutas}
                                  noDataText="Sin información"
                                  manual
                                  columns={[
                                    {
                                      Header: 'Región',
                                      //Header: () => (<div style={{ textAlign: "center" }}>ClaveRuta</div>),
                                      minWidth: 6,
                                      id: 'Region',
                                      accessor: (d) => d.Region,
                                      filterAll: false,
                                    },
                                    {
                                      Header: 'Municipio',
                                      //Header: () => (<div style={{ textAlign: "center" }}>ClaveRuta</div>),
                                      minWidth: 8,
                                      id: 'Municipio',
                                      accessor: (d) => d.Municipio,
                                      filterAll: false,
                                    },
                                    {
                                      Header: 'Clave Ruta',
                                      //Header: () => (<div style={{ textAlign: "center" }}>ClaveRuta</div>),
                                      minWidth: 10,
                                      id: 'ClaveRuta',
                                      accessor: (d) => d.ClaveRuta,
                                      filterAll: false,
                                    },
                                    {
                                      Header: 'Nombre',
                                      //Header: () => (<div style={{ textAlign: "center" }}>Nombre</div>),
                                      minWidth: 15,
                                      id: 'NombreRuta',
                                      accessor: (d) => d.NombreRuta,
                                      filterAll: false,
                                    },
                                    {
                                      Header: 'Secciones',
                                      //Header: () => (<div style={{ textAlign: "center" }}>Secciones</div>),
                                      minWidth: 20,
                                      id: 'Secciones',
                                      accessor: (d) => d.Secciones,
                                      /* {
                                  let output = [];
                                  d.Secciones.map(item => {
                                      output.push(item);
                                  });
                                  return output.join(', ');
      
                                }, */
                                      style: { whiteSpace: 'unset' },
                                      filterAll: false,
                                    },
                                    {
                                      Header: 'RG Asignado',
                                      //Header: () => (<div style={{ textAlign: "center" }}>Nombre</div>),
                                      minWidth: 20,
                                      id: 'RG',
                                      accessor: (d) => d.RG,
                                      filterAll: false,
                                    },
                                  ]}
                                  className="-striped -highlight primary-pagination"
                                  loading={loadingTabla}
                                  showPagination={true}
                                  showPaginationBottom={true}
                                  showTotalPages={true}
                                  showPageJump={false}
                                  canNextFromData={true}
                                  defaultPageSize={10}
                                  total={total}
                                  pages={Math.ceil(total / 10)}
                                  previousText="Anterior"
                                  nextText="Siguiente"
                                  ofText="de"
                                  pageSizeOptions={[10, 20, 25, 50, 100]}
                                  PaginationComponent={Pagination}
                                  onFetchData={(stateComp, instance) => {
                                    setLoadingTabla(true);
                                    var array_sorted = [];
                                    if (stateComp.sorted.length !== 0) {
                                      stateComp.sorted.forEach((element) => {
                                        if (element.id === 'NombreRuta') {
                                          array_sorted.push({
                                            id: 'rutas.NombreRuta',
                                            desc: element.desc,
                                          });
                                        }
                                      });
                                    }
                                    const data = {
                                      page: stateComp.page,
                                      pageSize: stateComp.pageSize,
                                      filtered:FilterRuta,
                                      sort:
                                        array_sorted.length !== 0
                                          ? array_sorted
                                          : [
                                            {
                                              id: 'rutas.NombreRuta',
                                              desc: false,
                                            },
                                          ],
                                      tipo: 'and',
                                    };
                                    SolicitudApi(data, 'getRutasGto', token);
                                  }}
                                />
                              </Col>
                            </Row>
                          </>
                        ) : (
                            <></>
                          )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
                {OpenModal ? (
                  <RutaModalEditar
                    ruta={RutaModal}
                    handleModal={(e) => {
                      setOpenModal(e);
                    }}
                    rgs={RGsRutaParameter}
                    handleUpdateRuta={handleUpdateRuta}
                    handleDeleteRuta={handleDeleteRuta}
                    isOpen={OpenModal}
                    isCapturista={menu.Capturista == 1}
                  ></RutaModalEditar>
                ) : (
                    <></>
                  )}
                {OpenModalRG ? (
                  <RutaModalRGAsignar
                    ruta={RutaModal}
                    rgs={RGsRutaParameter}
                    handleModal={(e) => {
                      setOpenModalRG(e);
                    }}
                    handleAsignRG={handleAsignRG}
                    isOpen={OpenModalRG}
                  ></RutaModalRGAsignar>
                ) : (
                    <></>
                  )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
