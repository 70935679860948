import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { Button, Col, ButtonGroup } from 'reactstrap';
import './style_map.css';

import EjecutarApi from '../../../../data/getPOST';
import { getVars } from '../../../../GlobalStorage';

let style_button_radiogroup = {
    'font': '4px/6px ',
    'margin': '2px'
}
function PluginFilter(props) {

    const token = getVars('Token').access_token;

    const [classes, setclasses] = useState('dropdown');
    const [MultiValueRegion, setMultiValueRegion] = useState([]);
    const [MultiValueRegionValue, setMultiValueRegionValue] = useState([]);
    const [MultiValueMunicipio, setMultiValueMunicipio] = useState([]);
    const [MultiValueMunicipioSafe, setMultiValueMunicipioSafe] = useState([]);
    const [MultiValueMunicipioValue, setMultiValueMunicipioValue] = useState([]);
    const [MultiValueSeccion, setMultiValueSeccion] = useState([]);
    const [MultiValueSeccionSafe, setMultiValueSeccionSafe] = useState([]);
    const [MultiValueSeccionValue, setMultiValueSeccionValue] = useState([]);
    const [buttonSelected, setButtonSelected] = useState([]);


    useEffect(() => {
        EjecutarApi({}, "getCatalogosFiltrosElectoralClonIdMunicipioReportes", token, "POST")
            .then(function (res) {
                return res;
            })
            .then(res => {
                if (res.results) {
                    if (res.data) {
                        //let regiones = [{ value: 0, label: 'TODAS' }]
                        let regionesFormated = [];
                        res.data.regiones.forEach(item => {
                            if(item.value !== 1){
                                regionesFormated.push({
                                    value: item.value,
                                    label: item.label
                                })
                            }
                        })
                        setMultiValueRegion(regionesFormated);

                        //let municipios = [{ value: 0, label: 'TODOS' }]
                        let municipiosFormated = res.data.municipios.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                            }
                        })
                        setMultiValueMunicipio(municipiosFormated);
                        setMultiValueMunicipioSafe(municipiosFormated);


                        //let seccion = [{ value: 0, label: 'TODAS' }]
                        let seccionFormated = res.data.secciones.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                                idMunicipio: item.idMunicipio,
                                idPoligono: item.idPoligono
                            }
                        })
                        setMultiValueSeccion(seccionFormated);
                        setMultiValueSeccionSafe(seccionFormated);
                        /* setMultiValueSeccion(seccion.concat(seccionFormated));
                        setMultiValueSeccionSafe(seccion.concat(seccionFormated)); */
                    }
                }

            })
            .catch((e) => {
            })
    }, []);

    const changeRegion = (idRegiones) => {

        
        if (idRegiones.includes(0)) {
            setMultiValueMunicipio(MultiValueMunicipioSafe);
            if(MultiValueMunicipioValue.length !== 0){
                changeMunicipios(MultiValueMunicipioValue);
            }
            else{
                setMultiValueSeccion(MultiValueSeccionSafe);
            }
            return true;
        }
        //setLoading(true);

        if (idRegiones.length > 0) {
            var array_municipios = [];
            idRegiones.forEach((item) => {
                let array_temp = MultiValueMunicipioSafe.filter(data => data.idRegion === item);
                array_municipios = array_municipios.concat(array_temp);
            });
            setMultiValueMunicipio(array_municipios);

            if(MultiValueMunicipioValue.length !== 0){
                changeMunicipios(MultiValueMunicipioValue);
            }
            else{
                var array_secciones = [];
                idRegiones.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => data.idRegion === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);
            }

            
            

        }
        else{
            changeMunicipios([MultiValueMunicipioValue]);
        }
    }

    const changeMunicipios = (idMunicipios) => {

        if(idMunicipios.includes(0)){
            if(MultiValueRegionValue.length !== 0){
                var array_secciones = [];
                MultiValueRegionValue.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => data.idRegion === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);
            }
            else{
                setMultiValueSeccion(MultiValueSeccionSafe);
            }
            return true;
        }
        if (idMunicipios.length > 0) {
            var array_secciones = [];
            idMunicipios.forEach((item) => {
                let array_temp = MultiValueSeccionSafe.filter(data => data.idMunicipio === item);
                array_secciones = array_secciones.concat(array_temp);
            });
            setMultiValueSeccion(array_secciones);
        }
    }

    const setFilter = (event) => {
        event.preventDefault();
        const myFiltro = [];
        
        //MultiValueRegion
        var x = 0;
        if (MultiValueRegionValue !== null && MultiValueRegionValue.length !== 0/*  && !MultiValueRegionValue.MultiValueRegionValue */)
            myFiltro.push({ id: 'idRegion', value: MultiValueRegionValue });
        else
            myFiltro.push({ id: 'idRegion', value: [] });

        //MultiValueMunicipio
        if (MultiValueMunicipioValue !== null && MultiValueMunicipioValue.length !== 0 /* && !MultiValueMunicipioValue.MultiValueMunicipioValue */)
            myFiltro.push({ id: 'idMunicipio', value: MultiValueMunicipioValue });
        else
            myFiltro.push({ id: 'idMunicipio', value: [] });
        
        //Seccion
        if (MultiValueSeccionValue !== null && MultiValueSeccionValue.length !== 0/*  && !MultiValueSeccionValue.MultiValueSeccionValue */)
            myFiltro.push({ id: 'idSeccion', value: MultiValueSeccionValue });
        else
            myFiltro.push({ id: 'idSeccion', value: [] });

        //Sin Actas / Con actas
        if(buttonSelected.includes(3)){
        if(buttonSelected.includes(1) || buttonSelected.includes(2)){
            if(buttonSelected.includes(1) && !buttonSelected.includes(2)){
                myFiltro.push({ id: 'TieneActas', value: 0 });
            }
            else if(buttonSelected.includes(2) && !buttonSelected.includes(1)){
                myFiltro.push({ id: 'TieneActas', value: 1 });
            }
            else {
                myFiltro.push({ id: 'TieneActas', value: 3 });
            }
        }
        else {
            myFiltro.push({ id: 'TieneActas', value: 3 });
        }
        }
        else {
            myFiltro.push({ id: 'TieneActas', value: 9999 });
        }
        

        //setFiltered(myFiltro);
        props.filtrar(myFiltro);
    };

    const handleClick = () => {
        if (classes === 'dropdown') {
            setclasses('dropdown show');
        } else {
            setclasses('dropdown');
        }
    };

    const handleClickButton = (e) =>  { if(e){e.preventDefault()}; }

    const onCheckboxBtnClick = (selected) => {
        const index = buttonSelected.indexOf(selected);
        if (index === -1) {
        buttonSelected.push(selected);
        } else {
        buttonSelected.splice(index, 1);
        }
        setButtonSelected([...buttonSelected]);
    }
    return (
        <div className="fixed-plugin">

            <form onSubmit={setFilter}>
                <div className={classes}>
                    <div onClick={handleClick}>
                        <i className="fa fa-cog fa-2x" />
                    </div>
                    <ul className="dropdown-menu show ul">
                        <li className="header-title"><h5>Filtrar Resultados</h5></li>
                        <li className="adjustments-line" />
                        
                        <Col>

                        <ButtonGroup >
                            <Button style={style_button_radiogroup} 
                                    size ="lg"
                                    onMouseDown={handleClickButton} 
                                    color={buttonSelected.includes(3)? "primary":"muted"} 
                                    onClick={() => { 
                                        onCheckboxBtnClick(3)
                                        }
                                    } 
                                    active={buttonSelected.includes(3)}>Mostrar Puntos</Button>
                        </ButtonGroup>
                        
                        </Col>
                        <Col>
                        {buttonSelected.includes(3)?
                        <ButtonGroup size="sm">
                            
                            <Button style={style_button_radiogroup} size ="sm" 
                                         onMouseDown={handleClickButton} color={buttonSelected.includes(1)? "primary":"muted"} onClick={() => onCheckboxBtnClick(1)} active={buttonSelected.includes(1)}>Casillas sin Actas</Button>
                            <Button style={style_button_radiogroup} size ="sm" 
                                         onMouseDown={handleClickButton} color={buttonSelected.includes(2)? "primary":"muted"} onClick={() => onCheckboxBtnClick(2)} active={buttonSelected.includes(2)}>Casillas con Actas</Button>
                            
                        </ButtonGroup>
                        :<></>
                        }   
                        </Col>
                        <li className="adjustments-line" />

                        {
                            props.Responsabilidad === 99 ||
                            props.Responsabilidad === 98 ||
                            props.Responsabilidad === 16 ||
                            props.Responsabilidad === 17
                             ? 
                        <>
                        <li className="li">
                            <Select
                                className="SelectSize"
                                name="Region"
                                placeholder="Región"
                                options={MultiValueRegion}
                                onChange={(event, i) => {

                                    if (event) {
                                        const array_temp = event.map(dato => { return dato.value })
                                        setMultiValueRegionValue(
                                            array_temp
                                        )
                                        changeRegion(array_temp);

                                    }
                                    else {
                                        if (i.removedValue !== undefined) {
                                            const array_temp = MultiValueRegionValue.map(dato => {
                                                if (i.removedValue.value !== dato) {
                                                    return dato.value
                                                }

                                            })
                                            if(!array_temp.includes(undefined)){
                                                changeRegion(array_temp);
                                                setMultiValueRegionValue(array_temp);

                                            }
                                            else{
                                                setMultiValueRegionValue([]);
                                                setMultiValueMunicipio(MultiValueMunicipioSafe);
                                                changeRegion([0]);
                                                
                                            }
                                                
                                        }
                                        else {
                                            setMultiValueMunicipio(MultiValueMunicipioSafe);
                                        }
                                    }
                                }}

                                isMulti
                            />
                        </li>
                        
                        <li className="adjustments-line" />
                        </>
                        :
                        <></>
                        }

                        {
                            props.Responsabilidad === 99 ||
                            props.Responsabilidad === 98 ||
                            props.Responsabilidad === 16 ||
                            props.Responsabilidad === 17 ||
                            props.Responsabilidad === 18
                             ? 
                        <>
                        <li className="li">
                            <Select
                                className="SelectSize"
                                name="Municipio"
                                placeholder="Municipio"
                                options={MultiValueMunicipio}
                                onChange={(event, i) => {

                                    if (event) {
                                        const array_temp = event.map(dato => { return dato.value })
                                        setMultiValueMunicipioValue(

                                            array_temp
                                        )
                                        changeMunicipios(array_temp);

                                    }
                                    else {
                                        if (i.removedValue !== undefined) {
                                            const array_temp = MultiValueMunicipioValue.map(dato => {
                                                if (i.removedValue.value !== dato) {
                                                    return dato.value
                                                }
                                            })
                                            if(!array_temp.includes(undefined)){
                                                changeMunicipios(array_temp);
                                                setMultiValueMunicipioValue(array_temp);
                                            }
                                            else{
                                                setMultiValueMunicipioValue([]);
                                                changeMunicipios([0]);   
                                            }
                                        }
                                        else{
                                            //changeRegion(MultiValueRegionValue);
                                        }

                                    }
                                }}

                                isMulti
                            />
                        </li>

                        <li className="adjustments-line" />

                        </>
                        :
                        <>
                        </>
                        }
                        {
                            props.Responsabilidad === 99 ||
                            props.Responsabilidad === 98 ||
                            props.Responsabilidad === 16 ||
                            props.Responsabilidad === 17 ||
                            props.Responsabilidad === 18 
                             ? 
                        <li className="li">
                            <Select
                                name="Seccion"
                                className="SelectSize"
                                placeholder="Seccion"
                                options={MultiValueSeccion}
                                onChange={(event, i) => {
                                    if (event) {
                                        const array_temp = event.map(dato => { return dato.value })
                                        setMultiValueSeccionValue(
                                            array_temp
                                        )

                                    }
                                    else {
                                        if (i.removedValue !== undefined) {
                                            const array_temp = MultiValueSeccionValue.map(dato => {
                                                if (i.removedValue.value !== dato) {
                                                    return dato.value
                                                }
                                            });

                                            if(!array_temp.includes(undefined)){
                                                setMultiValueSeccionValue(array_temp);
                                            }
                                            else{
                                                setMultiValueSeccionValue([]);  
                                            }
                                        }
                                    }
                                }}
                                isMulti
                            />
                        </li>
                        :
                        <>
                        </>
                        }
                        <li className="adjustments-line" />
                        <Button color="info" block className="btn-round" type="submit">
                            Filtrar
                        </Button>
                         
                    </ul>
                </div>
            </form>
        </div>
    );
}
export default PluginFilter;