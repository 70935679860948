import React, { useState } from 'react';

import { getVars, setVars } from 'GlobalStorage';
import EjecutarApi from 'data/getPOST';

import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';

import Notifications, { notify } from 'react-notify-toast';

import ActaEscrutinioForm from './ActaEscrutinioForm';

const ActaEscrutinioJuridico = (props) => {
  const token = getVars('Token');
  const [menu, _setmenu] = useState({});

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  return (
    <div className="wrapper">
      {' '}
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <Notifications />
        <div className="content">
          <ActaEscrutinioForm token={token} isJuridico={true} />
        </div>
      </div>
    </div>
  );
};

export default ActaEscrutinioJuridico;
