import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
//import Filter from "../components/compromisoFiltro/compromisoFiltro";
import { URL_GENERAL } from "data/Consts";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from "GlobalStorage";

const AvanceGeneral = (props) => {
  // const { dispatch } = useContext(Context);
  const [estatalDetalle, setEstatalDetalle] = useState([]);
  const [estatal, setEstatal] = useState({ Regional: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Municipios: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Poligonos: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Secciones: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 } });
  const [loading, setLoading] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [total, setTotal] = useState(0);
  const [meta, setMeta] = useState([]);
  const token = getVars("Token");


  const responsibilityID = token.user.Responsabilidad.value;

  var ubicaciónRegion = null;
  var ubicaciónMunicipio = null;

  if (responsibilityID === 10) { token.users_region.forEach((prop) => { ubicaciónRegion = prop.idRegion }); }
  if (responsibilityID === 11) { token.users_municipios.forEach((prop) => { ubicaciónRegion = 1 }); }


  useEffect(() => {
    setLoading(true)
    _setmenu(getDataMenu(80, token.menus));

    EjecutarApi({}, "getResumenEstructuraEstatal", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false)
        setEstatal(res.data);
      })
      .catch((e) => {
        setLoading(false)
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });


  }, []); //loadingTotal, loadingCompromisos

  const Exportar = () => {
    setLoading(true)
    var url = new URL(
      `${URL_GENERAL}getReporteAvanceGeneralRegional?token=${token.access_token}`
    );
    window.open(url, "_blank");
    setLoading(false)
  }

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Regionales</p>
                        <CardTitle tag="p">{`${estatal.Regional.AvanceEstructura} de ${estatal.Regional.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-line-chart" />
                    {`Avance ${new Intl.NumberFormat().format(estatal.Regional.porcentaje)} %`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Municipales</p>
                        <CardTitle tag="p">{`${estatal.Municipios.AvanceEstructura} de ${estatal.Municipios.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-line-chart" />
                    {`Avance ${new Intl.NumberFormat().format(estatal.Municipios.porcentaje)} %`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Poligonales</p>
                        <CardTitle tag="p">{`${estatal.Poligonos.AvanceEstructura} de ${estatal.Poligonos.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-line-chart" />
                    {`Avance ${new Intl.NumberFormat().format(estatal.Poligonos.porcentaje)} %`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Seccionales</p>
                        <CardTitle tag="p">{`${estatal.Secciones.AvanceEstructura} de ${estatal.Secciones.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-line-chart" />
                    {`Avance ${new Intl.NumberFormat().format(estatal.Secciones.porcentaje)} %`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Compromisos</p>
                        <CardTitle tag="p">{`${estatal.Regional.AvanceGeneral} de ${estatal.Regional.MetaCompromisosPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-line-chart" />
                    {`Avance ${new Intl.NumberFormat().format(estatal.Regional.PorcentajeGeneral)} %`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col><Card>
              <Row>
                <Col Col sm="12">
                  {_menu.Exportar === 1 ? (
                    <Button color="success" size="sm" onClick={() => Exportar()}>
                      <span className="btn-label">
                        <i className="fa fa-file-excel-o fa-lg" />
                      </span>
                      Exportar
                            </Button>
                  ) : <></>}
                </Col>
              </Row>
              {loading && (
                <CircleSpinner
                  className="text-center"
                  size={30}
                  color="#686769"
                  loading={loading}
                />
              )}
              <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                <ReactTable
                  style={{
                    width: "110%",
                    height: "100%"
                  }}
                  data={estatalDetalle}
                  noDataText="Sin información"
                  manual
                  columns={[
                    {
                      Header: "Region",
                      minWidth: 10,
                      id: "Region",
                      accessor: (d) => d.Region,
                      filterAll: false,
                    },
                    {
                      Header: "Municipios",
                      minWidth: 20,
                      id: "Municipios",
                      accessor: (d) => d.Municipio,
                      filterAll: false,
                    },
                    {
                      Header: "Meta",
                      minWidth: 20,
                      id: "meta",
                      accessor: (d) => d.meta,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Regional.MetaCompromisosPartido}
                        </span>
                      )
                    },
                    {
                      Header: "Avance Compromisos",
                      minWidth: 20,
                      id: "General",
                      accessor: (d) => d.general,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Regional.AvanceGeneral}
                        </span>
                      )
                    },
                    {
                      Header: "% Avance Compromisos",
                      minWidth: 20,
                      id: "Avance",
                      accessor: (d) => d.porcentaje,
                      Cell: props => <div>{props.value + " %"}</div>,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Regional.PorcentajeGeneral + " %"}
                        </span>
                      )
                    },
                    {
                      Header: "Meta Estructura",
                      minWidth: 20,
                      id: "metaEstructura",
                      accessor: (d) => d.metaEstructura,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Regional.MetaEstructuraPartido}
                        </span>
                      )
                    },
                    {
                      Header: "Avance Estructura",
                      minWidth: 20,
                      id: "GeneralEstructura",
                      accessor: (d) => d.AvanceEstructura,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Regional.AvanceEstructura}
                        </span>
                      )
                    },
                    {
                      Header: "% Avance Estructura",
                      minWidth: 20,
                      id: "AvanceEstructura",
                      accessor: (d) => d.porcentajeEstructura,
                      Cell: props => <div>{props.value + " %"}</div>,
                      filterAll: false,
                      Footer: (
                        <span>
                          <strong>Total:</strong>{" "}
                          {estatal.Regional.porcentaje + " %"}
                        </span>
                      )
                    }, {
                      Header: "Coordinadores Sociales",
                      minWidth: 20,
                      id: "Coordinadores",
                      accessor: (d) => d.Coordinadores,
                      filterAll: false
                    },
                    {
                      Header: "Promoción",
                      minWidth: 20,
                      id: "Promocion",
                      accessor: (d) => d.promocion,
                      filterAll: false,
                    }, {
                      Header: "Electoral",
                      minWidth: 20,
                      id: "electoral",
                      accessor: (d) => d.electoral,
                      filterAll: false,
                    }, {
                      Header: "Social",
                      minWidth: 20,
                      id: "Social",
                      accessor: (d) => d.social,
                      filterAll: false,
                    }, {
                      Header: "Compromiso",
                      minWidth: 20,
                      id: "compromiso",
                      accessor: (d) => d.compromiso,
                      filterAll: false,
                    }
                  ]}
                  className="-striped -highlight primary-pagination"
                  loading={loading}
                  showPagination={false}
                  showPaginationBottom={false}
                  showTotalPages={true}
                  showPageJump={false}
                  canNextFromData={true}
                  defaultPageSize={10}
                  total={estatalDetalle.lenght}
                  previousText="Anterior"
                  nextText="Siguiente"
                  ofText="de"
                  pageSizeOptions={[10, 20, 25, 50, 100]}
                  PaginationComponent={Pagination}
                  onFetchData={(stateComp, instance) => {
                    setLoading(true);
                    const data = {
                      page: stateComp.page,
                      pageSize: stateComp.pageSize,
                      Region: ubicaciónRegion ? ubicaciónRegion : [],
                      sorted: [{ id: "region.Region", desc: false }],
                      tipo: 'and',
                      /* filtered: Filtro?Filtro:[], */
                    };
                    EjecutarApi(data, "getReporteAvanceCompromisos", token.access_token)
                      .then(function (res) {
                        return res;
                      })
                      .then((res) => {
                        if (res.success) {
                          if (res.results) {
                            //setTotal(res.total);
                            setEstatalDetalle(
                              res.data.map((prop, key) => {
                                return {
                                  Region: prop.Region ? prop.Region : "Sin Región",
                                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                  meta: prop.meta,
                                  porcentaje: new Intl.NumberFormat('es-ES').format(prop.porcentaje),
                                  data: prop,
                                  general: prop.general ? prop.general : "0",
                                  metaEstructura: prop.MetaEstructuraPartido ? prop.MetaEstructuraPartido : "0",
                                  AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : "0",
                                  porcentajeEstructura: new Intl.NumberFormat('es-ES').format(prop.porcentajeEstructura),
                                  promocion: prop.promocion ? prop.promocion : "0",
                                  social: prop.social ? prop.social : "0",
                                  Coordinadores: prop.Coordinadores ? prop.Coordinadores : "0",
                                  compromiso: prop.compromiso ? prop.compromiso : "0",
                                  electoral: prop.electoral ? prop.electoral : "0",
                                };
                              })
                            );
                            setLoading(false);
                          }
                        }

                      })
                      .catch((e) => {
                        setLoading(false);
                        notify.show(
                          `Error al obtener tabla metas estructura region: ${e.message}`,
                          "custom",
                          5000,
                          {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                          }
                        );
                      });
                  }}
                />
              </div>
            </Card>
            </Col>
          </Row>


        </div>
        <Footer fluid />
      </div>
    </div >
  );
};

export default AvanceGeneral;
