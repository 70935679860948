import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col, Collapse, Input, FormGroup, Label, } from 'reactstrap';
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";


function IndexTablero({ Menu, Datos }) {
    const [loading, setLoading] = useState(true);
    const token = getVars("Token");
    const [catRegiones, setCatRegiones] = useState([]);
    const [catMunicipios, setCatMunicipios] = useState([]);
    const [catRegionesFilter, setCatRegionesFilter] = useState([]);
    const [catMunicipiosFilter, setCatMunicipiosFilter] = useState([]);
    const [collapse, setCollapse] = useState({
        horizontalTabs: "home",
        verticalTabs: "info",
        pageTabs: "homePages",
        openedCollapses: ["collapseOne", "collapse1"]
    });
    const [filtro, setFiltro] = useState({
        Region: {},
        Municipio: {}
    });
    const [dataCombos, setDataCombo] = useState({
        dRegion: { label: 'Seleccione', value: 0 },
        dMunicipio: { label: 'Seleccione', value: 0 }
    });

    useEffect(() => {
        EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token, "POST")
            .then(res => {
                if (res.results) {
                    if (res.data) {
                        setCatRegiones(res.data.regiones);
                        setCatMunicipios(res.data.municipios);
                        setCatRegionesFilter(res.data.regiones);
                        setCatMunicipiosFilter(res.data.municipios);
                    }
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
            })
    }, []);

    const collapsesToggle = (colapse) => {
        let openedCollapses = collapse.openedCollapses;
        if (openedCollapses.includes(colapse)) {
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses.filter(item => item !== colapse)
            });
        } else {
            openedCollapses.push(colapse);
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses
            });
        }
    };

    const changeRegion = (dRegion) => {
        if (dRegion.value > 1) {
            setDataCombo({ ...dataCombos, dRegion, dMunicipio: { label: 'Seleccione', value: 0 } });
            setCatMunicipiosFilter(catMunicipios.filter(data => data.idRegion === dRegion.value));
        } else {
            setDataCombo({ ...dataCombos, dRegion: { label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 } });
            setCatMunicipiosFilter(catMunicipios);
        }
    }

    const changeMunicipio = (dMunicipio) => {
        if (dMunicipio.value > 1) {
            setDataCombo({ ...dataCombos, dMunicipio });
        } else {
            setDataCombo({ ...dataCombos, dMunicipio: { label: 'Seleccione', value: 0 } });
        }
    }

    const setFilter = (event) => {
        var filtrar = {
            Region: dataCombos.dRegion,
            Municipio: dataCombos.dMunicipio
        }
        Datos(filtrar);
    };

    const ClearFilter = () => {
        setDataCombo({ ...dataCombos, dRegion: { label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 } });
        setCatRegionesFilter(catRegiones);
        setCatMunicipiosFilter(catMunicipios);
        setFiltro({ Region: {}, Municipio: {} });
    };

    return (
        <>
            <Card className="card-plain">
                <CardBody>
                    <div
                        aria-multiselectable={true}
                        className="card-collapse"
                        id="accordion"
                        role="tablist"
                    >
                        <Card className="card-plain">
                            <CardHeader role="tab">
                                <a
                                    aria-expanded={collapse.openedCollapses.includes("collapse1")}
                                    data-parent="#accordion"
                                    data-toggle="collapse"
                                    onClick={() => collapsesToggle("collapse1")}
                                    className="text-right"
                                >
                                    <i className="fa fa-filter fa-lg" aria-hidden="true"></i>{" "}
                                    Filtro
                                </a>
                            </CardHeader>
                            <Collapse
                                role="tabpanel"
                                isOpen={collapse.openedCollapses.includes("collapse1")}
                            >
                                <CardBody>
                                    <Row>
                                        <Col sm="4" className="text-muted">Región:
                                        {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catRegionesFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dRegion}
                                                onChange={(e) => changeRegion(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="4" className="text-muted">Municipio:
                                        {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catMunicipiosFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dMunicipio}
                                                onChange={(e) => changeMunicipio(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e) => setFilter()}>
                                        <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                        Filtrar
                                    </Button>{' '}
                                    <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e) => ClearFilter()}>
                                        <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                                        Limpiar
                                    </Button>
                                </CardFooter>
                            </Collapse>
                        </Card>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default IndexTablero;