import React, { useEffect, useState } from 'react';
import GlobalMunicipiosTable from 'components/PREP/GlobalMunicipiosTable';
import ReactTable from 'react-table';
import { notify } from 'react-notify-toast';
import { Button, Spinner } from 'reactstrap';

import EjecutarApi from 'data/getPOST';
import { getDataMenu } from 'data/menus';
import Pagination from 'components/Pagination/pagination';
import { getVars, URL_IMAGENES } from 'GlobalStorage';

const PrepTable = ({ tipoEleccion, tipoAgrupador, ambito, tipoVista, seccion, isMuestra }) => {
    const token = getVars("Token");
    const [loading, setLoading] = useState(true);
    const [TotalRegistros, setTotalRegistros] = useState(0);
    const [Registros, setRegistros] = useState([]);
    // const [params, setParams] = useState({
    //     tipoEleccion: 4,
    //     tipoAgrupador: 1,
    //     ambito: 0,
    //     tipoVista: 1
    // });

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        getRegistros({
            tipoEleccion: tipoEleccion,
            tipoAgrupador: tipoAgrupador,
            ambito: ambito,
            tipoVista: tipoVista,
            seccion: seccion,
            isMuestra: isMuestra
        })
    }, [tipoEleccion, tipoAgrupador, ambito, tipoVista, seccion])

    const getRegistros = data => {
        if (data.ambito > 0) {
            setLoading(true);
            EjecutarApi(
                {
                    tipoEleccion: data.tipoEleccion,
                    ambito: data.ambito,
                    tipoAgrupador: data.tipoAgrupador,
                    tipoVista: data.tipoVista,
                    seccion: data.seccion,
                    isMuestra: data.isMuestra
                },
                'prep/getTableData',
                token.access_token
            )
                .then(function (res) {
                    return res;
                })
                .then(res => {
                    if (res.success) {
                        if (res.results) {
                            setColumns(res.data.columns)
                            if (tipoAgrupador === 4) {
                                let dataFormated = [];
                                res.data.data.map(result => {
                                    if (result.rutaFoto) {
                                        dataFormated.push({
                                            ...result, rutaFoto: (
                                                <Button
                                                    outline
                                                    color="primary"
                                                    onClick={() => window.open(`${URL_IMAGENES}${result.rutaFoto}`, "_blank")}
                                                >
                                                    <i className='fa fa-file'></i>
                                                </Button>)
                                        })
                                    } else {
                                        dataFormated.push(result)
                                    }
                                })
                                setRegistros(dataFormated);
                            } else {
                                setRegistros(res.data.data);
                            }
                            setTotalRegistros(res.data.total);
                        }
                        else {
                            notify.show(res.message, 'warning', 5000);
                        }
                    } else {
                        notify.show(res.message, 'warning', 5000);
                    }
                    setLoading(false);
                })
                .catch(e => {
                    notify.show(`Error al cargar : ${e.message}}`, 'error', 5000);
                    setLoading(false);
                });
        }
    };
    return (
        <>
            <div align="center" style={{width: '100%'}}>
                {loading && <Spinner  />}
            </div>
            <div style={{ width: '100%', overflow: 'auto' }}>
                {!loading && (
                    <ReactTable
                        style={{
                            width: "110%",
                            height: "100%"
                        }}
                        size='sm'
                        data={Registros}
                        noDataText="Sin información"
                        // manual
                        columns={columns}
                        className="-striped -highlight primary-pagination"
                        loading={loading}
                        showPagination={true}
                        showPaginationBottom={true}
                        showTotalPages={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={10}
                        total={TotalRegistros}
                        pages={Math.floor(TotalRegistros / 10)}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        PaginationComponent={Pagination}
                    // onFetchData={getRegistros}
                    // params={params}
                    />
                )}
            </div>
        </>

    );
};

export default PrepTable;
