import React, {  useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer"
import Sidebar from "../../components/Sidebar";
import { getVars } from '../../GlobalStorage';
import ReactTable from 'react-table';

import {
	Button, Card, CardHeader, CardBody, Row, Col, TabContent,
	TabPane, NavItem,Label,FormGroup,
	NavLink,
	Nav,
} from 'reactstrap';
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "../../data/getPOST";
import { URL_GENERAL } from "../../data/Consts";
import { CircleSpinner } from "react-spinners-kit";
import Pagination from "../../components/Pagination/pagination";
import {getDataMenu} from '../../data/menus.js';

const ElectoralDF = (props) =>{

    // Configuracion
 const [setsidebarMini] = useState(true);
 const [backgroundColor] = useState("black");
 const [activeColor] = useState("info");
 const [loading, setLoading] = useState(false);
 const [loadingTabla, setLoadingTabla] = useState(false);
 const token = getVars('Token');
 const [horizontalTabs, setHorizontalTabs] = useState({
  horizontalTabs: "RCS",
  openedCollapses: ["collapseOne", "collapse1"]
})

var Responsabilidad = token.user.Responsabilidad.value;
 
  const [Filtro,setFiltro]=useState([]);
  const [total, setTotal] = useState(0);
  const [meta, setMeta ] = useState([]);
  const [regionSelected, setRegionSelected] = useState({value: 0,label: 'Todos'})
  const [tabla,setTabla]= useState("RCS");
 
  // Catalogos

  const [showMeta,setShowMeta] = useState(false);
  const [col,setCol]=useState("12");
  

  //catalogos

  const [catRegiones, setCatRegiones] = useState([])

 
  const [menu,_setmenu]= useState({});

  useEffect(() => { 
    setLoading(true);
    _setmenu(getDataMenu(106,token.menus));
    let regionesFormated =[];
    EjecutarApi({}, "getCatDFResponsabilidad", token.access_token, "POST")
      .then(res => {
        if(res.result){
        if (res.data) {
          let regiones = [{value: 0,label: 'Todos'}]

          regionesFormated = res.data.map(item => {
            return {
              value: item.id,
              label: item.DistritoFederal
            }
          })
          setCatRegiones(regiones.concat(regionesFormated))

        } else {
          showMessage("No Info: getCatalogosFiltrosElectoral",'#0E7CF1')
        }
      }
      })
      .catch((e) => {
        /* showMessage("Error: getCatalogosFiltrosElectoral","#F1370E") */
      }).then(() => {
        setLoading(false);
      })
 
  }, [tabla]);

  const showMessage = (message,background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
        setsidebarMini(false);
    } else {
        setsidebarMini(true);
    }
      document.body.classList.toggle("sidebar-mini");
  };


    // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA
  
    const handleRegiones = (e) => {
      setLoadingTabla(true)
      setLoading(true)
      setRegionSelected(e)
      
      if(e.value>1)
      {
        fitroSend(e,"region");
        
      }else{
        fitroSend("","clear");
      }
      setLoading(false);
    }
  
    const fitroSend =(e,handle,t=false)=>{
      var filtro=[];
      if(handle !== "clear"){
      if(e.value > 0 && handle ==="region"){filtro.push({"id":"df.id","value":e.value})}
      if(regionSelected.value >0 && handle !=="region"){filtro.push({"id":"df.id","value":regionSelected.value})}
    }
    
  
       else if(e === 0 && handle===""){setFiltro(Filtro)}
       else{setFiltro(filtro);}
        const dato=(t)?t=t:t=tabla;
        const data = {
          page: 0,
          pageSize: 10,
          sorted:  [{ id: "df.id", desc: false }],
          tipo:'and',
          filtered: filtro,
          Electoral:dato
        };	
        EjecutarApi(data, "getAvanceDFElectoral", token.access_token)
          .then(function (res) {
          return res;
          })
          .then((res) => {
          if(res.success){
            if(res.results){
          setLoadingTabla(false);
          setTotal(res.total);
          if(t!== "RCS"){
            setMeta(res.data.map((prop, key) => {
            var porcentaje=parseFloat(prop.Porcentaje)
            var ddporcentaje=porcentaje.toFixed(2)
          return {
            id: prop.id,
            DistritoFederal: prop.DistritoFederal?prop.DistritoFederal:"Sin Región",
            Municipio: prop.Municipio?prop.Municipio:"Sin Municipio",
            meta:prop.Meta,
            AvanceEstructura:prop.ElectoralDia?prop.ElectoralDia:0,
            AvanceRGs:prop.Total?prop.Total:0,
            porcentaje:ddporcentaje?ddporcentaje:0,
            data: prop,
          };
        })
        );
        }
        else if(t=== "RCS"){
          setMeta( res.data.map((prop, key) => {
            var porcentaje1=parseFloat(prop.PorcentajePropietario1)
            var ddporcentaje1=porcentaje1.toFixed(2)
            var porcentaje2=parseFloat(prop.PorcentajePropietario2)
            var ddporcentaje2=porcentaje2.toFixed(2)
            var porcentaje3=parseFloat(prop.PorcentajeSuplente1)
            var ddporcentaje3=porcentaje3.toFixed(2)
            var porcentaje4=parseFloat(prop.PorcentajeSuplente2)
            var ddporcentaje4=porcentaje4.toFixed(2)
          return {
            id: prop.id,
            DistritoFederal: prop.DistritoFederal?prop.DistritoFederal:"Sin Región",
            Municipio: prop.Municipio?prop.Municipio:"Sin Municipio",
            meta:prop.Meta?prop.Meta:0,
            AvanceEstructura:prop.ElectoralDia?prop.ElectoralDia:0,
            AvancePro1:prop.AvancePro1?prop.AvancePro1:0,
            AvancePro2:prop.AvancePro2?prop.AvancePro2:0,
            AvanceSup1:prop.AvanceSup1?prop.AvanceSup1:0,
            AvanceSup2:prop.AvanceSup2?prop.AvanceSup2:0,
            PorcentajePropietario1:ddporcentaje1?ddporcentaje1:0,
            PorcentajePropietario2:ddporcentaje2?ddporcentaje2:0,
            PorcentajeSuplente1:ddporcentaje3?ddporcentaje3:0,
            PorcentajeSuplente2:ddporcentaje4?ddporcentaje4:0,
            data: prop,
            };
          })
          );
        }
         
          }}
          })
          .catch((e) => {
          setLoadingTabla(false);
          notify.show(
            `Error al obtener getMetaEstructurasGeneralFiltro: ${e.message}`,
            "custom",
            5000,
            {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
            }
          );
          });
    }
  
    const LimpiarFiltro=()=>{
      setRegionSelected({value: 0,label: 'Todos'})
  
      fitroSend("","clear");
    }
  
    const changeTap=(e)=>{
      setLoadingTabla(true);
      setHorizontalTabs({ ...horizontalTabs, horizontalTabs: e })

      
      setTabla(e)
      setCol("12")
      
      setRegionSelected({value: 0,label: 'Todos'})

      setShowMeta(false)
      fitroSend("","clear",e);
    }
  
 
    
    const Exportar =()=>{
      var url = new URL(

       `${URL_GENERAL}getReporteAvanceDFElectoral?token=${token.access_token}`

      );
      window.open(url, "_blank");
    }


//Estructura

  const showRegion= Responsabilidad === 99 || Responsabilidad === 98 || Responsabilidad === 16 || Responsabilidad === 53 || Responsabilidad === 54

  const showMunicipio= Responsabilidad === 99 || Responsabilidad === 98 || Responsabilidad === 16 || Responsabilidad === 17 || Responsabilidad === 53
 

  return(
    <div className="wrapper">
      <Notifications />
        <Sidebar
          {...props}
          menus={token.menus}
          bgColor={backgroundColor}
          activeColor={activeColor}
        />
          <div className="main-panel">
            <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
              <div className="content">

                <div className="content">
                  {/* Configuracón Estructura Metas */}
             
              <Row>
				<Col sm={col}>
				<Card>
				<CardHeader>
                  <Row >
                       {showRegion && 
                         <Col lg="4" md="4" sm="4">
                            <FormGroup>
                              <Label for="Estado">
                                Distrito Federal
                              </Label>
                              <Select
                                options={catRegiones}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                defaultValue={catRegiones}
                                value={regionSelected}
                                onChange={(e) => handleRegiones(e)} 
                                id="regiones"
                                placeholder="DF"
                              />
                            </FormGroup>
                          </Col>}
                    </Row>
                    <Row>
                       <Col sm='4'>
                          <Button name="btnLimpiar" size="sm" type="button" className="success float-left" color='warning' onClick={()=>{LimpiarFiltro()}}>Limpiar</Button>
                      </Col>
                      {(menu.Exportar === 1 && !loadingTabla ) ? (
                        <Col sm='8'> 
							<Button name="btnExportar" size='sm' type="button"  className='primary float-right' color='primary' onClick={()=> Exportar()}>
								<span className="btn-label">
									<i className="fa fa-file-excel-o fa-lg" />
								</span>
								Exportar
							</Button>
                        </Col>
					):<></>}
                      </Row>

                    </CardHeader>
                    <CardBody>
                    <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper">
                            <Nav id="tabs" role="tablist" tabs>
                                <NavItem>
                                    <NavLink
                                        aria-expanded={horizontalTabs.horizontalTabs === "RCS"}
                                        data-toggle="tab"
                                        role="tab"
                                        className={horizontalTabs.horizontalTabs === "RCS"
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() => {changeTap("RCS") }}
                                        >
                                        RCs
                            </NavLink>
						              	</NavItem>
                            <NavItem>
                                <NavLink
                                    aria-expanded={horizontalTabs.horizontalTabs === "RGS"
                                    }
                                    data-toggle="tab"
                                    //href="#pablo"
                                    role="tab"
                                    className={horizontalTabs.horizontalTabs === "RGS"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => changeTap("RGS")}
                                >
                                    RGs
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    aria-expanded={horizontalTabs.horizontalTabs === "Abogados"}
                                    data-toggle="tab"
                                    //href="#pablo"
                                    role="tab"
                                    className={horizontalTabs.horizontalTabs === "Abogados"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() =>
                                        changeTap("Abogados")}
                                >
                                    Abogados
                            </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    aria-expanded={horizontalTabs.horizontalTabs === "Observador"}
                                    data-toggle="tab"
                                    //href="#pablo"
                                    role="tab"
                                    className={horizontalTabs.horizontalTabs === "Observador"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() =>
                                        changeTap("Observador")}
                                >
                                    Observadores
                        </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    aria-expanded={horizontalTabs.horizontalTabs === "CasaAmiga"}
                                    data-toggle="tab"
                                    //href="#pablo"
                                    role="tab"
                                    className={horizontalTabs.horizontalTabs === "CasaAmiga"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() =>
                                        changeTap("CasaAmiga")}
                                >
                                    Casa Azul
                    </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
            <TabContent
                className="text-center"
                id="my-tab-content"
                activeTab={horizontalTabs.horizontalTabs}
            >
                <TabPane tabId="RCS" role="tabpanel">
                     
                      <ReactTable
                    size='sm'
                    data={meta}
                    noDataText="Sin información"
                    manual
                    columns={[
                      {
                        Header: "Distrito Federal",
                        minWidth: 70,
                        id: "federal",
                        accessor: (d) => d.DistritoFederal,
                        filterAll: false,
                      },
                    {
                        Header: "Municipio",
                        minWidth: 30,
                        id: "Municipio",
                        accessor: (d) => d.Municipio,
                        filterAll: false,
                    },
					          {
                    Header: "Meta",
                    accessor: (d) => d.meta,
                    minWidth: 40,
                    id: "meta",
                    filterable: false,
                      },
                      {
                    Header: "Avance Esperado",
                    accessor: (d) => d.AvanceEstructura,
                    minWidth: 40,
                    id: "AvanceEstructura",
                    filterable: false,
                    },
                    {
                    Header: "Avance Prop 1",
                    accessor: (d) => d.AvancePro1,
                    minWidth: 40,
                    id: "AvancePro1",
                    filterable: false,
                    },
                    {
                        Header: "Avance Prop 2",
                        accessor: (d) => d.AvancePro2,
                        minWidth: 40,
                        id: "AvancePro2",
                        filterable: false,
                    },
                    {
                      Header: "Avance Supl 1",
                      accessor: (d) => d.AvanceSup1,
                      minWidth: 40,
                      id: "AvanceSub1",
                      filterable: false,
                  },
                  {
                    Header: "Avance Supl 2",
                    accessor: (d) => d.AvanceSup2,
                    minWidth: 40,
                    id: "AvanceSub2",
                        filterable: false,
                    },
                    {
                      Header: "% Avance Prop 1",
                      accessor: (d) => d.PorcentajePropietario1,
                      Cell: props => <div>{props.value + " %"}</div>,
                      minWidth: 40,
                      id: "porcentajePro1",
                      filterable: false,
                    },
                    {
                      Header: "% Avance Prop 2",
                      accessor: (d) => d.PorcentajePropietario2,
                      Cell: props => <div>{props.value + " %"}</div>,
                      minWidth: 40,
                      id: "porcentajePro2",
                      filterable: false,
                    },
                    {
                      Header: "% Avance Supl 1",
                      accessor: (d) => d.PorcentajeSuplente1,
                      Cell: props => <div>{props.value + " %"}</div>,
                      minWidth: 40,
                      id: "porcentajeSu1",
                      filterable: false,
                    },
                    {
                      Header: "% Avance Supl 2",
                      accessor: (d) => d.PorcentajeSuplente2,
                      Cell: props => <div>{props.value + " %"}</div>,
                      minWidth: 40,
                      id: "porcentajeSu2",
                      filterable: false,
                    },
											]}
                        className="-striped -highlight primary-pagination"
                        loading={loadingTabla}
                        showPagination={true}
                        showPaginationBottom={true}
                        showTotalPages={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={10}
                        total={total}
                        pages={Math.floor(total / 10)}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        PaginationComponent={Pagination}
						            onFetchData={(stateComp, instance) => {
                        setLoadingTabla(true);
                        
                        let _menuStorage = getDataMenu(106,token.menus);	
                        const data = {
                        page: stateComp.page,
                        pageSize: stateComp.pageSize,
                        sorted: [{ id: "df.id", desc: false }],
                        tipo:'and',
                        filtered: Filtro?Filtro:[],
                        Electoral:"RCS"
                      };	
                        EjecutarApi(data, "getAvanceDFElectoral", token.access_token)
                        .then(function (res) {
                        return res;
                        })
                        .then((res) => {
                        if(res.success)
						{
                        if(res.results){	
                        setTotal(res.total);
						      setMeta(
							              res.data.map((prop, key) => {
                            var porcentaje1=parseFloat(prop.PorcentajePropietario1)
                            var ddporcentaje1=porcentaje1.toFixed(2)
                            var porcentaje2=parseFloat(prop.PorcentajePropietario2)
                            var ddporcentaje2=porcentaje2.toFixed(2)
                            var porcentaje3=parseFloat(prop.PorcentajeSuplente1)
                            var ddporcentaje3=porcentaje3.toFixed(2)
                            var porcentaje4=parseFloat(prop.PorcentajeSuplente2)
                            var ddporcentaje4=porcentaje4.toFixed(2)
						        	return {
                        DistritoFederal: prop.DistritoFederal?prop.DistritoFederal:"Sin DistritoFederal",
                            Municipio: prop.Municipio?prop.Municipio:"Sin Municipio",
							              meta:prop.Meta?prop.Meta:0,
                            AvanceEstructura:prop.ElectoralDia?prop.ElectoralDia:0,
                            AvancePro1:prop.AvancePro1?prop.AvancePro1:0,
                            AvancePro2:prop.AvancePro2?prop.AvancePro2:0,
                            AvanceSup1:prop.AvanceSup1?prop.AvanceSup1:0,
                            AvanceSup2:prop.AvanceSup2?prop.AvanceSup2:0,
                            PorcentajePropietario1:ddporcentaje1?ddporcentaje1:0,
                            PorcentajePropietario2:ddporcentaje2?ddporcentaje2:0,
                            PorcentajeSuplente1:ddporcentaje3?ddporcentaje3:0,
                            PorcentajeSuplente2:ddporcentaje4?ddporcentaje4:0,
                            data:prop,
                            };
							          })
                        );
                        setLoadingTabla(false);}}
												
                        })
                        .catch((e) => {

                        setLoadingTabla(false);
                        notify.show(
                            `Error al obtener tabla metas estructura region: ${e.message}`,
                            "custom",
                            5000,
                            {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                            }
                        );
                        });
                    }}
					/>
            </TabPane>
                <TabPane tabId="RGS" role="tabpanel">
                {horizontalTabs.horizontalTabs === "RGS" ?
                    <ReactTable
                          size='sm'
                          data={meta}
                          noDataText="Sin información"
                          manual
                          columns={[
                            {
                              Header: "Distrito Federal",
                              minWidth: 70,
                              id: "Región",
                              accessor: (d) => d.DistritoFederal,
                              filterAll: false,
                            },
                          {
                            Header: "Municipio",
                            minWidth: 70,
                            id: "Municipio",
                            accessor: (d) => d.Municipio,
                            filterAll: false,
                          },
                          {
                            Header: "Meta",
                            accessor: (d) => d.meta,
                            minWidth: 40,
                            id: "meta",
                            filterable: false,
                          },
                          {
                            Header: "Avance Esperado",
                            accessor: (d) => d.AvanceEstructura,
                            minWidth: 40,
                            id: "AvanceEstructura",
                            filterable: false,
                          },
                          {
                            Header: "Avance RGs",
                            accessor: (d) => d.AvanceRGs,
                            minWidth: 40,
                            id: "AvanceRGS",
                            filterable: false,
                          },
                          {
                            Header: "% Avance",
                            accessor: (d) => d.porcentaje,
                            Cell: props => <div>{props.value + " %"}</div>,
                            minWidth: 40,
                            id: "porcentaje",
                            filterable: false,
                          },
                          
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loadingTabla}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.floor(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                            setLoadingTabla(true);
                          let _menuStorage = getDataMenu(106,token.menus);
                          
                          const data = {
                            page: stateComp.page,
                            pageSize: stateComp.pageSize,
                            sorted:  [{ id: "df.id", desc: false }],
                            tipo:'and',
                            filtered: Filtro?Filtro:[],
                            Electoral:"RGS"
                          };	
                          EjecutarApi(data, "getAvanceDFElectoral", token.access_token)
                            .then(function (res) {
                            return res;
                            })
                            .then((res) => {
                            setLoadingTabla(false);
                            setTotal(res.total);
                            setMeta(
                              res.data.map((prop, key) => {
                                var porcentaje=parseFloat(prop.Porcentaje)
                                var ddporcentaje=porcentaje.toFixed(2)
                              return {
                                id: prop.id,
                                DistritoFederal: prop.DistritoFederal?prop.DistritoFederal:"Sin DistritoFederal",
                                Municipio: prop.Municipio?prop.Municipio:"Sin Municipio",
                                meta:prop.Meta?prop.Meta:0,
                                AvanceEstructura:prop.ElectoralDia?prop.ElectoralDia:0,
                                AvanceRGs:prop.Total?prop.Total:0,
                                porcentaje:ddporcentaje?ddporcentaje:0,
                                data: prop,
                              };
                              })
                            );
                            
                            })
                            .catch((e) => {

                            setLoadingTabla(false);
                            notify.show(
                              `Error al obtener tabla metas: ${e.message}`,
                              "custom",
                              5000,
                              {
                              background: "#0857B6",
                              fontSize: 14,
                              text: "#FFFFFF",
                              }
                            );
                            });
                          }}
                          />: <></>
						}

                </TabPane>
                <TabPane tabId="Abogados" role="tabpanel">
                    {horizontalTabs.horizontalTabs === "Abogados" ?
                    <ReactTable
                          size='sm'
                          data={meta}
                          noDataText="Sin información"
                          manual
                          columns={[
                            {
                              Header: "Distrito Federal",
                              minWidth: 70,
                              id: "Región",
                              accessor: (d) => d.DistritoFederal,
                              filterAll: false,
                            },
                          {
                            Header: "Municipio",
                            minWidth: 70,
                            id: "Municipio",
                            accessor: (d) => d.Municipio,
                            filterAll: false,
                          },
                          {
                            Header: "Meta",
                            accessor: (d) => d.meta,
                            minWidth: 40,
                            id: "meta",
                            filterable: false,
                          },
                          {
                            Header: "Avance Esperado",
                            accessor: (d) => d.AvanceEstructura,
                            minWidth: 40,
                            id: "AvanceEstructura",
                            filterable: false,
                          },
                          {
                            Header: "Avance Abogados",
                            accessor: (d) => d.AvanceRGs,
                            minWidth: 40,
                            id: "AvanceRGS",
                            filterable: false,
                          },
                          {
                            Header: "% Avance",
                            accessor: (d) => d.porcentaje,
                            Cell: props => <div>{props.value + " %"}</div>,
                            minWidth: 40,
                            id: "porcentaje",
                            filterable: false,
                          },
                          
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loadingTabla}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.floor(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                            setLoadingTabla(true);
                          let _menuStorage = getDataMenu(106,token.menus);
                          const data = {
                            page: stateComp.page,
                            pageSize: stateComp.pageSize,
                            sorted:  [{ id: "df.id", desc: false }],
                            tipo:'and',
                            filtered: Filtro?Filtro:[],
                            Electoral:"Abogados"
                          };	
                          EjecutarApi(data, "getAvanceDFElectoral", token.access_token)
                            .then(function (res) {
                            return res;
                            })
                            .then((res) => {
                            setLoadingTabla(false);
                            setTotal(res.total);
                            setMeta(
                              res.data.map((prop, key) => {
                                var porcentaje=parseFloat(prop.Porcentaje)
                                var ddporcentaje=porcentaje.toFixed(2)
                              return {
                                id: prop.id,
                                DistritoFederal: prop.DistritoFederal?prop.DistritoFederal:"Sin Región",
                                Municipio: prop.Municipio?prop.Municipio:"Sin Municipio",
                                meta:prop.Meta?prop.Meta:0,
                                AvanceEstructura:prop.ElectoralDia?prop.ElectoralDia:0,
                                AvanceRGs:prop.Total?prop.Total:0,
                                porcentaje:ddporcentaje?ddporcentaje:0,
                                data: prop,
                              };
                              })
                            );
                            
                            })
                            .catch((e) => {
                            setLoadingTabla(false);
                            notify.show(
                              `Error al obtener tabla metas: ${e.message}`,
                              "custom",
                              5000,
                              {
                              background: "#0857B6",
                              fontSize: 14,
                              text: "#FFFFFF",
                              }
                            );
                            });
                          }}
                          />: <></>
						}

                </TabPane>
                <TabPane tabId="Observador" role="tabpanel">
                    {horizontalTabs.horizontalTabs === "Observador" ?
                        <ReactTable
                        size='sm'
                        data={meta}
                        noDataText="Sin información"
                        manual
                        columns={[
                          {
                            Header: "Distrito Federal",
                            minWidth: 70,
                            id: "Región",
                            accessor: (d) => d.DistritoFederal,
                            filterAll: false,
                          },
                        {
                          Header: "Municipio",
                          minWidth: 70,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                        },
                        {
                          Header: "Meta",
                          accessor: (d) => d.meta,
                          minWidth: 40,
                          id: "meta",
                          filterable: false,
                        },
                        {
                          Header: "Avance Esperado",
                          accessor: (d) => d.AvanceEstructura,
                          minWidth: 40,
                          id: "AvanceEstructura",
                          filterable: false,
                        },
                        {
                          Header: "Avance Observador",
                          accessor: (d) => d.AvanceRGs,
                          minWidth: 40,
                          id: "AvanceRGS",
                          filterable: false,
                        },
                        {
                          Header: "% Avance",
                          accessor: (d) => d.porcentaje,
                          Cell: props => <div>{props.value + " %"}</div>,
                          minWidth: 40,
                          id: "porcentaje",
                          filterable: false,
                        },
                        
                        ]}
                        className="-striped -highlight primary-pagination"
                        loading={loadingTabla}
                        showPagination={true}
                        showPaginationBottom={true}
                        showTotalPages={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={10}
                        total={total}
                        pages={Math.floor(total / 10)}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        PaginationComponent={Pagination}
                        onFetchData={(stateComp, instance) => {
                          setLoadingTabla(true);
                        let _menuStorage = getDataMenu(106,token.menus);
                        const data = {
                          page: stateComp.page,
                          pageSize: stateComp.pageSize,
                          sorted:  [{ id: "df.id", desc: false }],
                          tipo:'and',
                          filtered: Filtro?Filtro:[],
                          Electoral:"Observador"
                        };	
                        EjecutarApi(data, "getAvanceDFElectoral", token.access_token)
                          .then(function (res) {
                          return res;
                          })
                          .then((res) => {
                          setLoadingTabla(false);
                          setTotal(res.total);
                          setMeta(
                            res.data.map((prop, key) => {
                              var porcentaje=parseFloat(prop.Porcentaje)
                              var ddporcentaje=porcentaje.toFixed(2)
                            return {
                              id: prop.id,
                              DistritoFederal: prop.DistritoFederal?prop.DistritoFederal:"Sin DistritoFederal",
                              Municipio: prop.Municipio?prop.Municipio:"Sin Municipio",
                              meta:prop.Meta?prop.Meta:0,
                              AvanceEstructura:prop.ElectoralDia?prop.ElectoralDia:0,
                              AvanceRGs:prop.Total?prop.Total:0,
                              porcentaje:ddporcentaje?ddporcentaje:0,
                              data: prop,
                            };
                            })
                          );
                          
                          })
                          .catch((e) => {
                          setLoadingTabla(false);
                          notify.show(
                            `Error al obtener tabla metas: ${e.message}`,
                            "custom",
                            5000,
                            {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                            }
                          );
                          });
                        }}
                        />: <></>
                      }

				</TabPane>
                <TabPane tabId="CasaAmiga" role="tabpanel">
                    {horizontalTabs.horizontalTabs === "CasaAmiga" ?
                        <ReactTable
                        size='sm'
                        data={meta}
                        noDataText="Sin información"
                        manual
                        columns={[
                          {
                            Header: "Distrito Federal",
                            minWidth: 70,
                            id: "Región",
                            accessor: (d) => d.DistritoFederal,
                            filterAll: false,
                          },
                        {
                          Header: "Municipio",
                          minWidth: 70,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                        },
                        {
                          Header: "Meta",
                          accessor: (d) => d.meta,
                          minWidth: 40,
                          id: "meta",
                          filterable: false,
                        },
                        {
                          Header: "Avance Esperado",
                          accessor: (d) => d.AvanceEstructura,
                          minWidth: 40,
                          id: "AvanceEstructura",
                          filterable: false,
                        },
                        {
                          Header: "Avance Casa Azul",
                          accessor: (d) => d.AvanceRGs,
                          minWidth: 40,
                          id: "AvanceRGS",
                          filterable: false,
                        },
                        {
                          Header: "% Avance",
                          accessor: (d) => d.porcentaje,
                          Cell: props => <div>{props.value + " %"}</div>,
                          minWidth: 40,
                          id: "porcentaje",
                          filterable: false,
                        },
                        
                        ]}
                        className="-striped -highlight primary-pagination"
                        loading={loadingTabla}
                        showPagination={true}
                        showPaginationBottom={true}
                        showTotalPages={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={10}
                        total={total}
                        pages={Math.floor(total / 10)}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        PaginationComponent={Pagination}
                        onFetchData={(stateComp, instance) => {
                          setLoadingTabla(true);
                        let _menuStorage = getDataMenu(106,token.menus);
                        const data = {
                          page: stateComp.page,
                          pageSize: stateComp.pageSize,
                          sorted:  [{ id: "df.id", desc: false }],
                          tipo:'and',
                          filtered: Filtro?Filtro:[],
                          Electoral:"CasaAmiga"
                        };	
                        EjecutarApi(data, "getAvanceDFElectoral", token.access_token)
                          .then(function (res) {
                          return res;
                          })
                          .then((res) => {
                          setLoadingTabla(false);
                          setTotal(res.total);
                          setMeta(
                            res.data.map((prop, key) => {
                              var porcentaje=parseFloat(prop.Porcentaje)
                              var ddporcentaje=porcentaje.toFixed(2)
                            return {
                              id: prop.id,
                              DistritoFederal: prop.DistritoFederal?prop.DistritoFederal:"Sin DistritoFederal",
                              Municipio: prop.Municipio?prop.Municipio:"Sin Municipio",
                              meta:prop.Meta?prop.Meta:0,
                              AvanceEstructura:prop.ElectoralDia?prop.ElectoralDia:0,
                              AvanceRGs:prop.Total?prop.Total:0,
                              porcentaje:ddporcentaje?ddporcentaje:0,
                              data: prop,
                            };
                            })
                          );
                          
                          })
                          .catch((e) => {
                          setLoadingTabla(false);
                          notify.show(
                            `Error al obtener tabla metas: ${e.message}`,
                            "custom",
                            5000,
                            {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                            }
                          );
                          });
                        }}
                        />: <></>
                      }

				</TabPane>
			</TabContent>
									</CardBody>
								</Card>
							</Col>
						</Row>
                </div>
              </div>
            <Footer fluid />
            {loading && (
              <CircleSpinner
                className="text-center"
                size={30}
                color="#686769"
                loading={loading}
              />
            )}
          </div>
        </div>
    );

}
export default ElectoralDF;
