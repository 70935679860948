import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import EjecutarApi from "data/getPOST";
import { getVars, URL_PUBLICA } from "GlobalStorage";
import bloqueado from 'data/bloqueado.json';
import PerfectScrollbar from "perfect-scrollbar";

var ps;
const RCO = (props) => {
  const mainPanel = useRef();
  const [state, setState] = useState({ Region: { value: null, label: 'Seleccione' }, Municipio: { value: null, label: 'Seleccione' }, Poligono: { value: null, label: 'Seleccione' } });
  const [isBloqueado, setIsBloqueado] = useState(false);
  const [cmbRegion, setCmbRegion] = useState([{ value: null, label: 'Seleccione' }]);
  const [cmbMunicipio, setCmbMunicipio] = useState([{ value: null, label: 'Seleccione' }]);
  const [cmbPoligono, setCmbPoligono] = useState([{ value: null, label: 'Seleccione' }]);
  const [infoPoligono, setInfoPoligono] = useState(null);
  const [_menu, _setmenu] = useState({});

  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
  const [catPoligonoMunicipio,setcatPoligonoMunicipio] = useState([]);
  const [catMunicipioReportes,setcatMunicipioReportes] = useState([]);

  useEffect(() => {

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    if (props.history.action === "PUSH") {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    ///
    let encontrado = bloqueado.data.filter(item => item.value === token.user.id);

    if (encontrado.length > 0) {
      setIsBloqueado(true)
    }

    EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setCmbRegion(res.data.regiones);
          setCmbMunicipio(res.data.municipios);
          setcatMunicipioReportes(res.data.municipios);
          setCmbPoligono(res.data.poligonos);
          setcatPoligonoMunicipio(res.data.poligonos);
        }
      })
      .catch((e) => {
        notify.show(`Error al obtener catálogos: ${e.error}`, "error", 5000);
      });

  }, []);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const Export = () => {
    var url = new URL(URL_PUBLICA +
      `getFCO_01_PoligonalPDF?token=${token.access_token}&Tipo=Poligono&data=${infoPoligono.Poligono.Poligono}`
    );
    window.open(url, "_blank");
  }

  const changeRegion = (Region) => {

    setState({ ...state, Region, Municipio: { value: null, label: 'Seleccione' }, Poligono: { value: null, label: 'Seleccione' } });

    const itemfiltroM = catMunicipioReportes.filter(item => parseInt(item.idRegion) === parseInt(Region.value));
    const itemfiltroP = catPoligonoMunicipio.filter(item => parseInt(item.idRegion) === parseInt(Region.value));

    setCmbMunicipio(itemfiltroM);
    setCmbPoligono(itemfiltroP);


  }

  const changeMunicipio = (Municipio) => {

    setState({ ...state, Municipio, Poligono: { value: null, label: 'Seleccione' } });

    const itemfiltro = catPoligonoMunicipio.filter(item => parseInt(item.idMunicipio) === parseInt(Municipio.value));

    setCmbPoligono(itemfiltro);

  }

  const changePoligono = (Poligono) => {
    setState({ ...state, Poligono });

    const data = {
      Tipo: 'Poligono',
      data: Poligono.label,
    };


    setLoading(true);


    EjecutarApi(data, "getFCO_01_Poligonal", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {

        setInfoPoligono(res.data);
      })
      .catch((e) => {
        notify.show(`Error al obtener RCO: ${e.error}`, "error", 5000);
      }).
      finally(x => {
        setLoading(false);
      })



  }



  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {!isBloqueado && (<>

            <Row>
              <Col>
                <Card>
                  <CardHeader>

                  </CardHeader>
                  <CardBody>


                    <Row>

                      <Col sm="3" className="text-muted">Región:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbRegion}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Region}
                          onChange={(e) => changeRegion(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      <Col sm="3" className="text-muted">Municipio:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbMunicipio}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Municipio}
                          onChange={(e) => changeMunicipio(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      <Col sm="3" className="text-muted">Polígono:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbPoligono}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Poligono}
                          onChange={(e) => changePoligono(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>


                    </Row>

                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
                <Card>
                  <CardBody>
                    {infoPoligono && <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                      <Button color="primary" size="sm" onClick={() => Export()}>
                        <span className="btn-label">
                          <i className="fa fa-file-excel-o fa-lg" />
                        </span>
                        Exportar
                                    </Button>
                      <Table bordered style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>{`Región: ${infoPoligono.Poligono.Region}`}</th>
                            <th>{`Municipio: ${infoPoligono.Poligono.Municipio}`}</th>
                            <th>{`Polígono: ${infoPoligono.Poligono.Poligono}`}</th>
                          </tr>
                        </thead>
                      </Table>
                      <Table bordered style={{ width: '50%' }}>
                        <tbody>
                          {infoPoligono.Poligonales.map(item => {
                            return (<tr>
                              <th>POLIGONAL</th>
                              <td>{`${item.Poligonal}`}</td>
                            </tr>)
                          })}
                        </tbody>
                      </Table>
                      <Table bordered style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>SECCIÓN</th>
                            <th>META</th>
                            <th>AVANCE</th>
                            <th>NOMBRE</th>
                            <th>META MZANA P1</th>
                            <th>AVANCE MZANA P1</th>
                            <th>META MZANA P2</th>
                            <th>AVANCE MZANA P2</th>
                            <th>META MZANA P3</th>
                            <th>AVANCE MZANA P3</th>
                            <th>META COMPROMISOS</th>
                            <th>AVANCE COMPROMISOS</th>
                            <th>OBSERVACIONES</th>
                          </tr>
                        </thead>
                        <tbody>
                          {infoPoligono.Seccionales.map(item => {
                            return (<tr>
                              <td>{`${item.Seccion}`}</td>
                              <td>{`${item.MetaEstructuraPartido}`}</td>
                              <td style={{ color: item.AvanceEstructura>=item.MetaEstructuraPartido ? '#4BB543':'#FF0105', fontSize: 12}}>{`${item.AvanceEstructura}`}</td>
                              <td>{`${item.Seccional}`}</td>
                              <td>{`${item.ManzanaP1}`}</td>
                              <td style={{ color: item.AvanceManzanaP1>=item.ManzanaP1 ? '#4BB543':'#FF0105', fontSize: 12}}><b>{`${item.AvanceManzanaP1}`}</b></td>
                              <td >{`${item.ManzanaP2}`}</td>
                              <td style={{ color: item.AvanceManzanaP2>=item.ManzanaP2 ? '#4BB543':'#FF0105', fontSize: 12}}><b>{`${item.AvanceManzanaP2}`}</b></td>
                              <td>{`${item.ManzanaP3}`}</td>
                              <td style={{ color: item.AvanceManzanaP3>=item.ManzanaP3 ? '#4BB543':'#FF0105', fontSize: 12}}><b> {`${item.AvanceManzanaP3}`}</b></td>
                              <td>{`${item.MetaCompromisosPartido}`}</td>
                              <td>{`${item.AvanceCompromisos}`}</td>
                              <td></td>
                            </tr>)
                          })}
                        </tbody>
                      </Table>
                    </div>}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>)
          }
        </div>

        <Footer fluid />
      </div>
    </div>
  );
};

export default RCO;  