import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row,Collapse,Input,FormGroup,Label, } from 'reactstrap';
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";

function IndexObservador ({Datos}){
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
	const [filtro,setFiltro] = useState({
		Partido:{ label: 'Seleccione', value: 0 },
		DF:{ label: 'Seleccione', value: 0 },
		Nombre:'',
        Materno:'',
        Paterno:'',
		Celular:'',
        TieneExperiencia:0,
        TieneAuto:0
	})
    const [collapse, setCollapse] = useState({
		horizontalTabs: "home",
		verticalTabs: "info",
		pageTabs: "homePages",
		openedCollapses: ["collapseOne", "collapse1"]
    });
    const [Partidos,setPartidos]= useState([])
    const [catDF,setCatDF]=useState([])
    useEffect(()=>{
        setLoading(true);
        EjecutarApi({}, "catCompromisos", token.access_token, "GET")
        .then(function (res) {
            return res;
        })
        .then((res) => {
            if (res.results) {
                setPartidos(res.data.Partidos);
                setLoading(false);
            } 
        })
        .catch((e) => {
            setLoading(false);
        });
        EjecutarApi({"option": "cat_dl"}, "catalogos", token.access_token, "GET")
        .then(function (res) {
            return res;
        })
        .then((res) => {
            
            if (res.status==='success') {
                setCatDF(res.items);
                setLoading(false);
            } 
        })
        .catch((e) => {
            setLoading(false);
        });
    },[])
     
    const collapsesToggle = (colapse) => {
		let openedCollapses = collapse.openedCollapses;
		if (openedCollapses.includes(colapse)) {
		  setCollapse({...collapse,
			openedCollapses: openedCollapses.filter(item => item !== colapse)
		  });
		} else {
		  openedCollapses.push(colapse);
		  setCollapse({...collapse, 
			openedCollapses: openedCollapses
		  });
		}
    };

    const setFilter=(event)=>{
        Datos(filtro);
    }
    const ClearFilter=()=>{
        let _setFiltro={
            Partido:{ label: 'Seleccione', value: 0 },
            DF:{ label: 'Seleccione', value: 0 },
            Nombre:'',
            Materno:'',
            Paterno:'',
            Celular:'',
            TieneExperiencia:0,
            TieneAuto:0
        };
        setFiltro(_setFiltro)
        Datos(_setFiltro);
    }
    return (
        <Card className="card-plain">
            <CardBody>
            <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                role="tablist"
            >
                
                <Card className="card-plain">
                <CardHeader role="tab">
                    <a
                        aria-expanded={collapse.openedCollapses.includes(
                            "collapse3"
                          )}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle("collapse3")}
                        className="text-left"
                    >
                        <Button
                            className="btn-icon btn-neutral"
                            outline
                            size="sm"
                            id="EditUser"
                        >
                            <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                        </Button>
                        Filtro
                    </a>
                </CardHeader>
                <Collapse
                    role="tabpanel"
                    isOpen={collapse.openedCollapses.includes(
                        "collapse3"
                      )}
                >
                    <CardBody>
                       <Row>
                            {/* <FormGroup className={"col-md-4 text-muted"}>
                                Partido: 
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                    )} 
                                <Select
                                    options={Partidos}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={filtro.Partido}
                                    onChange={(e) => setFiltro({...filtro, Partido: e})}
                                    id="viewTypeM"
                                    placeholder="Filtrar por"
                                />
                            </FormGroup> */}
                            <FormGroup className={"col-md-4 text-muted"}>
                                    Distrito Federal:
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                    )} <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={catDF}
                                        value={filtro.DF}
                                        id="sPartido"
                                        onChange={(e) =>  setFiltro({...filtro, DF: e})}
                                        placeholder="Filtrar por..."
                                    />
                            </FormGroup> 
                            <FormGroup className={"col-md-4 text-muted"}>Nombre: <Input id="inombre" value={filtro.Nombre} onChange={(e)=>setFiltro({...filtro,Nombre:e.target.value})} /></FormGroup>
                            <FormGroup className={"col-md-4 text-muted"}>Paterno:<Input id="ipaterno" value={filtro.Paterno} onChange={(e)=>setFiltro({...filtro, Paterno:e.target.value})} /></FormGroup>
                            <FormGroup className={"col-md-4 text-muted"}>Materno:<Input id="iMaterno" value={filtro.Materno} onChange={(e)=>setFiltro({...filtro,Materno:e.target.value})} /></FormGroup>	
                            <FormGroup check inline>
                                <br/>
                                <Label check>
                                    <Input  type="checkbox" name='chek1' checked={filtro.TieneExperiencia} onChange={(e)=>setFiltro({...filtro, TieneExperiencia:e.target.checked})} />
                                    <span className="form-check-sign" />¿Ha sido funcionario?
                                </Label>
                            </FormGroup>{" "}
                            <FormGroup check inline>
                                <Label check>
                                    <Input type="checkbox" name='chek1' checked={filtro.TieneAuto} onChange={(e)=>setFiltro({...filtro, TieneAuto:e.target.checked})} />
                                    <span className="form-check-sign" />¿Tiene Auto?
                                </Label>
                            </FormGroup>
                         
                        </Row>
                    </CardBody>
                    <CardFooter>
                    <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e)=>setFilter()}>
                            <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                            Filtrar
                        </Button>{' '}
                        <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e)=> ClearFilter()}>
                            <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                            Limpiar
                        </Button>
                    </CardFooter>
                </Collapse>
                </Card>
            </div>
            </CardBody>
        </Card>
    );
};
export default IndexObservador;