import React, { useState, useEffect } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label,
    InputGroup,
    InputGroupAddon,
    toggleModal,
    TextField
} from "reactstrap";
import Select from "react-select";
import { RotateSpinner } from 'react-spinners-kit';
import Notifications, { notify } from 'react-notify-toast';
import { getDataMenu } from 'data/menus';
import { getVars } from "GlobalStorage";
import EjecutarApi from "data/getPOST";

const IncidenciaAdd = ({ onHide }) => {
    const token = getVars("Token");
    const [loading, setLoading] = useState(false);
    const [catIncidencia, setCatIncidencia] = useState([{ value: 0, label: "Seleccione" }]);
    const [incidencia, setIncidencia] = useState(
        {
            seccion: 0,
            casilla: "",
            incidencia: { value: 0, label: "Selecciona" },
            asentado: 0,
            horaIncidencia: 0,
            minutosIncidencia: 0,
            anioIncidencia: '',
            mesIncidencia: '',
            segundosIncidencia: '',
            diaIncidencia: '',
            horaSolucion: 0,
            minutosSolucion: 0,
            anioSolucion: '',
            mesSolucion: '',
            segundosSolucion: '',
            diaSolucion: '',
            descripcionIncidente: "",
            resuelto: 0,
            descripcionSolucion: "",
            status: 1
        });
    const [isModal, setisModal] = useState(false);
    const [menu, _setmenu] = useState({});
    const toggleModal = () => setisModal(true)
    const [catSeccionCasa, setCatSeccionCasa] = useState([]);
    const [MpioSeccion, setMpioSeccion] = useState('');
    const [Seccion, setSeccion] = useState('');
    const [Casilla, setCasilla] = useState([]);

    const [catCasilla, setcatCasilla] = useState([{ value: 0, label: 'Debe validar la sección' }]);

    useEffect(() => {
        _setmenu(getDataMenu(165, token.menus));
    }, [])

    useEffect(() => {
        var d = new Date();

        var horas = d.getHours();
        var minutos = d.getMinutes();
        var segundos = d.getSeconds();

        if(horas < 10) { horas = '0' + horas; }
        if(minutos < 10) { minutos = '0' + minutos; }
        if(segundos < 10) { segundos = '0' + segundos; }

        setIncidencia({
            ...incidencia,
            horaIncidencia: `${horas}:${minutos}`,
            minutosIncidencia: d.getMinutes(),
            anioIncidencia: d.getFullYear(),
            mesIncidencia: d.getMonth() + 1,
            segundosIncidencia: d.getSeconds(),
            diaIncidencia: d.getDate(),
            horaSolucion:`${horas}:${minutos}`,
            minutosSolucion: d.getMinutes(),
            anioSolucion: d.getFullYear(),
            mesSolucion: d.getMonth() + 1,
            segundosSolucion: d.getSeconds(),
            diaSolucion: d.getDate()
        });
        //"2021-05-01 07:30:00",
    }, [Seccion])


    useEffect(() => {
        EjecutarApi({
            "catalogos": [
                "secciones"
            ]
        }, "getCatalogosSije", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.results) {
                    if (items.data) {
                        setMpioSeccion(
                            items.data.secciones.map((item) => {
                                return {
                                    value: item.value,
                                    label: item.label,
                                };
                            }));
                        setLoading(false);
                    }
                } else {
                    notify.show("No Info: getCatalogosFiltrosPromocion", "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show("Error: getCatalogosFiltrosPromocion", e.message, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });
        let dat = {
            page: 0,
            pageSize: 1000000,
            tipo: "and",
            filtered: [],
            sort: []
        };
        EjecutarApi(dat, "getCatalogoIncidencia", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.results) {
                    if (items.data) {
                        setCatIncidencia(items.data.map((prop, key) => {
                            return {
                                value: prop.id,
                                label: prop.Incidencia
                            }
                        }))
                    }
                } else {
                    notify.show("No Info: getCatalogosFiltrosPromocion", "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show("Error: getCatalogosFiltrosPromocion", e.message, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });




    }, [])

    const btnValidaSeccion = () => {
        setcatCasilla([{ value: 0, label: 'Debe validar la sección' }])
        setIncidencia({ ...incidencia, casilla: [] })
        if (Seccion) {
            EjecutarApi({ "seccion": Seccion }, 'validarSeccionSije', token.access_token)
                .then(function (res) {
                    return res;
                })
                .then((res) => {
                    if (res.results) {
                        setLoading(false);
                        notify.show("Sección válida", 'custom', 5000, {
                            background: '#0857B6',
                            fontSize: 14,
                            text: '#FFFFFF'
                        });
                        setIncidencia({ ...incidencia, seccion: Seccion })
                        //getCasillasBySeccion 
                        EjecutarApi({ "seccion": Seccion }, 'getCasillasBySeccion', token.access_token)
                            .then(function (res) {

                                return res;
                            })
                            .then((res) => {
                                if (res.success) {
                                    setcatCasilla(res.data);
                                    setLoading(false);
                                }

                            })
                            .catch((e) => {
                                setLoading(false);
                                notify.show(`${e.error}`, 'warning', 5000, {
                                    background: '#0857B6',
                                    fontSize: 14,
                                    text: '#FFFFFF'
                                });
                            });
                    }
                    else {
                        notify.show(`Sección No válida`, 'custom', 5000, {
                            background: '#0857B6',
                            fontSize: 14,
                            text: '#FFFFFF'
                        });
                        setLoading(false);
                    }

                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                });
        }
        else {
            notify.show("Ingrese una Sección", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
        }
    }

    const clearForm = () => {
        var d = new Date();

        var horas = d.getHours();
        var minutos = d.getMinutes();
        var segundos = d.getSeconds();

        if(horas < 10) { horas = '0' + horas; }
        if(minutos < 10) { minutos = '0' + minutos; }
        if(segundos < 10) { segundos = '0' + segundos; }
        setSeccion('')
        setIncidencia({
            seccion: 0,
            casilla: "",
            incidencia: { value: 0, label: "Selecciona" },
            asentado: 0,
            horaIncidencia: `${horas}:${minutos}`,
            minutosIncidencia: d.getMinutes(),
            anioIncidencia: d.getFullYear(),
            mesIncidencia: d.getMonth() + 1,
            segundosIncidencia: d.getSeconds(),
            diaIncidencia: d.getDate(),
            horaSolucion:`${horas}:${minutos}`,
            minutosSolucion: d.getMinutes(),
            anioSolucion: d.getFullYear(),
            mesSolucion: d.getMonth() + 1,
            segundosSolucion: d.getSeconds(),
            diaSolucion: d.getDate(),
            descripcionIncidente: "",
            resuelto: 0,
            descripcionSolucion: "",
            status: 1
        });
    }

    const save = () => {

        if (incidencia.seccion === 0) {
            notify.show("Hace Falta la Sección", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
            return true;

        }
        if (!incidencia.casilla.value) {
            if (incidencia.casilla.value === 0) {
                notify.show("Hace Falta la Casilla", "warning", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            }

            return true;
        }
        if (!incidencia.incidencia.value) {
            if (incidencia.incidencia.value === 0) {
                notify.show("Hace Falta Indicar la Incidencia", "warning", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            }

            return true;
        }
        if (incidencia.descripcionIncidente.length < 5) {
            notify.show("Realice una descripción de la incidencia", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });

            return true;
        }
        if ((incidencia.horaIncidencia.length < 5 && incidencia.horaSolucion.length > 0) ||  incidencia.horaIncidencia === '' ) {
            notify.show("Hace falta la hora de incidencia", "warning", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });

            return true;
        }
        if (incidencia.resuelto === 1) {
            if (incidencia.descripcionSolucion.length < 5) {
                notify.show("Hace Falta Indicar la Solución", "warning", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
                return true;
            }
            if ((incidencia.horaSolucion.length < 5  && incidencia.horaSolucion.length > 0) ||  incidencia.horaSolucion === '') {
                notify.show("Hace falta la hora de solución", "warning", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
                return true;
            }
        }
        let datos = {
            Seccion: (incidencia.seccion) ? incidencia.seccion : 0,
            NombreCasilla: (incidencia.casilla.label) ? incidencia.casilla.label : 0,
            idIncidencia: (incidencia.incidencia) ? incidencia.incidencia.value : 0,
            DescripcionIncidencia: (incidencia.descripcionIncidente) ? incidencia.descripcionIncidente : "",
            AsentadoEnActa: (incidencia.asentado) ? incidencia.asentado : 0,
            HoraIncidencia: `${incidencia.anioIncidencia}-${incidencia.mesIncidencia}-${incidencia.diaIncidencia} ${incidencia.horaIncidencia}:${incidencia.segundosIncidencia}`,
            isResuelto: (incidencia.resuelto) ? incidencia.resuelto : 0,
            ComoSeResolvio: (incidencia.descripcionSolucion) ? incidencia.descripcionSolucion : null,
            HoraSolucion: incidencia.resuelto?( `${incidencia.anioSolucion}-${incidencia.mesSolucion}-${incidencia.diaSolucion} ${incidencia.horaSolucion}:${incidencia.segundosSolucion}`):null,
            idStatus: incidencia.status
        };

        EjecutarApi(datos, "setIncidenciaCasilla", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.results) {
                    notify.show(`${items.message}`, "success", 5000, {
                        background: "#0857B6",
                        fontSize: 24,
                        text: "#FFFFFF",
                    });

                    clearForm()
                } else {
                    notify.show(`${items.message}`, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show("Error: setIncidenciaCasilla", e.message, "danger", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });

    }
    return (
        <>
            <div className="content">
                <Row>
                    <Notifications />
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <Button color="success" size="sm" onClick={() => onHide()} >
                                    <span className="btn-label">
                                    </span>
                                 Regresar
                                 </Button>
                                <h5 className="title text-center">Captura de Incidencia</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <FormGroup className="col-md-2 text-warning" >
                                            <Label for="seccion"><span className="text-danger">*</span>Sección:</Label>
                                            <InputGroup>
                                                <Input id="seccion" maxLength='4' type='number' value={Seccion}
                                                    onChange={(e) => { setSeccion(e.target.value) }}
                                                    onBlur={(e) => btnValidaSeccion()} ></Input>
                                                <InputGroupAddon addonType="append">
                                                    <Button id="btnSeccion" color='warning' style={{ margin: '0px 1px' }}
                                                        onClick={btnValidaSeccion} >
                                                        <i className="fa fa-search fa-lg"></i></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        <Col md="4">
                                            <FormGroup sm="4">
                                                <Label for="casilla"><span className="text-danger">*</span>Casilla:</Label>
                                                <Select className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="casilla"
                                                    placeholder="Debe validar la sección"
                                                    options={catCasilla}
                                                    onChange={(e) => setIncidencia({ ...incidencia, casilla: e })}
                                                    isLoading={loading}
                                                    value={incidencia.casilla}
                                                    loadingMessage={"...Filtrando..."}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" md="6" sm="6">
                                            <FormGroup>
                                                <Label for="incidencia">
                                                    <span className="text-danger">*</span> Incidencia
                                                </Label>
                                                <Select
                                                    options={catIncidencia}
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    //value={incidencia.incidencia}
                                                    onChange={(e) => setIncidencia({ ...incidencia, incidencia: e })}
                                                    id="incidencia"
                                                    placeholder=""
                                                    value={incidencia.incidencia}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6" md="6" sm="6">
                                            <FormGroup className="col-md-8">
                                                <label style={{ "with": "290px", "marginLeft": "-14px" }} > <span className="text-danger">*</span> Fue Asentado en el Acta de la Jornada Electoral</label>

                                            </FormGroup>
                                            <div className="d-flex" style={{ "marginLeft": "-10px" }}>
                                                <FormGroup check className="col-md-4 text-center">
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={incidencia.asentado === 1}
                                                            value={incidencia.asentado}
                                                            onChange={() =>
                                                                setIncidencia({
                                                                    ...incidencia,
                                                                    asentado: 1,
                                                                })
                                                            }
                                                        />{" "} Si fue Asentado
                                                <span className="form-check-sign">
                                                            <span className="check"></span>
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check className="col-md-4 text-center" >
                                                    <Label check /* style={{"margin-rigth": "-140px"}} */>
                                                        <Input
                                                            color="info"
                                                            type="checkbox"
                                                            checked={incidencia.asentado === 0}
                                                            value={incidencia.asentado}
                                                            onChange={() =>
                                                                setIncidencia({
                                                                    ...incidencia,
                                                                    asentado: 0,
                                                                })
                                                            }
                                                        />{" "}No fue Asentado
                                                <span className="form-check-sign ">
                                                            <span className="check"></span>
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <label> <span className="text-danger">*</span>Descripción Incidencia</label>
                                                <Input
                                                    style={{ height: "45pt" }}
                                                    onChange={(e) => { setIncidencia({ ...incidencia, descripcionIncidente: e.target.value }) }}
                                                    className="textarea"
                                                    type="textarea"
                                                    cols="80"
                                                    rows="2"
                                                    value={incidencia.descripcionIncidente}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" md="3" lg="3">
                                            <Label className="text-muted"> <span className="text-danger">*</span>Hora Incidencia (Formato: 24 hrs) </Label>
                                            <Input id='btn' className='text-center' value={incidencia.horaIncidencia} style={{ width: "100pt" }}
                                                onChange={(e) => setIncidencia({ ...incidencia, horaIncidencia: e.target.value })}/*  */
                                                type="time" />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col lg="6" md="6" sm="6">

                                            <FormGroup className="col-md-6 ">
                                                <label  > <span className="text-danger">*</span>¿Fue Resuelto el Incidente?</label>

                                            </FormGroup>
                                            <div className="d-flex" >
                                                <FormGroup check className="col-md-3 text-center">
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={incidencia.resuelto === 1}
                                                            value={incidencia.resuelto}
                                                            onChange={() =>
                                                                setIncidencia({
                                                                    ...incidencia,
                                                                    resuelto: 1,
                                                                    status: 3
                                                                })
                                                            }
                                                        />{" "} Si
                                                <span className="form-check-sign">
                                                            <span className="check"></span>
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check className="col-md-3 text-center" >
                                                    <Label check /* style={{"margin-rigth": "-140px"}} */>
                                                        <Input
                                                            color="info"
                                                            type="checkbox"
                                                            checked={incidencia.resuelto === 0}
                                                            value={incidencia.resuelto}
                                                            onChange={() =>
                                                                setIncidencia({
                                                                    ...incidencia,
                                                                    resuelto: 0,
                                                                })
                                                            }
                                                        />{" "}No
                                                <span className="form-check-sign ">
                                                            <span className="check"></span>
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {incidencia.resuelto ?
                                            <>
                                                <Col md="8">
                                                    <FormGroup>
                                                        <label> <span className="text-danger">*</span>Descrición de Como se Soluciono el Incidente</label>
                                                        <Input
                                                            style={{ height: "45pt" }}
                                                            onChange={(e) => { setIncidencia({ ...incidencia, descripcionSolucion: e.target.value }) }}
                                                            className="textarea"
                                                            type="textarea"
                                                            cols="80"
                                                            rows="2"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="3" md="3" lg="3">
                                                    <Label className="text-muted"> <span className="text-danger">*</span>Hora Solución (Formato: 24 hrs) </Label>
                                                    <Input id='btn' className='text-center' value={incidencia.horaSolucion} style={{ width: "100pt" }}
                                                        onChange={(e) => setIncidencia({ ...incidencia, horaSolucion: e.target.value })}/*  */
                                                        type="time" />
                                                </Col>
                                            </> : <></>
                                        }
                                    </Row>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                {menu.Agregar ?
                                    <>
                                        <FormGroup>
                                            <Button
                                                color="danger"
                                                onClick={() => clearForm()}
                                                size="sm"
                                            >
                                                Limpiar
                                             </Button>{' '}
                                            <Button
                                                size="sm"
                                                color="primary"
                                                onClick={(e) => save(e)}
                                            >
                                                Guardar
                                            </Button>
                                            {
                                                <Col>
                                                    <RotateSpinner
                                                        color={'#1261ff'}
                                                        size={30}
                                                        loading={loading}
                                                    />
                                                </Col>
                                            }
                                        </FormGroup>
                                    </> :
                                    <>
                                    </>
                                }
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default IncidenciaAdd;
