import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import Pagination from "../../components/Pagination/pagination";
//import Filter from "../components/compromisoFiltro/compromisoFiltro";

import EjecutarApi from "../../data/getPOST";
import { URL_GENERAL } from "../../data/Consts";
import { getVars, URL_PUBLICA } from "../../GlobalStorage";
import { getDataMenu } from "data/menus";

const AvancesCompromisosRegion = (props) => {
  // const { dispatch } = useContext(Context);
  const [estatalDetalle, setEstatalDetalle] = useState({ LastUpdate: '', data: [] });
  const [estatal, setEstatal] = useState({ Regional: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Municipios: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Poligonos: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Secciones: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 } });
  const [loading, setLoading] = useState(false);
  const [_menu, _setmenu] = useState({});

  const token = getVars("Token");

  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(29, token.menus));

    EjecutarApi({}, "getResumenEstructuraEstatal", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false)
        setEstatal(res.data);
      })
      .catch((e) => {
        setLoading(false)
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });

    EjecutarApi({}, "getAvancesCompromisosRegion", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false)
        setEstatalDetalle({ data: res.data });
      })
      .catch((e) => {
        setLoading(false)
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });


  }, []); //loadingTotal, loadingCompromisos

  const Exportar = () => {
    setLoading(true)
    var url = new URL(
      `${URL_GENERAL}getReporteAvanceRegional?token=${token.access_token}`
    );
    window.open(url, "_blank");
    setLoading(false)
  }
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Regionales</p>
                        <CardTitle tag="p">{`${estatal.Regional.AvanceEstructura} de ${estatal.Regional.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Regional.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Municipales</p>
                        <CardTitle tag="p">{`${estatal.Municipios.AvanceEstructura} de ${estatal.Municipios.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Municipios.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Poligonales</p>
                        <CardTitle tag="p">{`${estatal.Poligonos.AvanceEstructura} de ${estatal.Poligonos.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Poligonos.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Seccionales</p>
                        <CardTitle tag="p">{`${estatal.Secciones.AvanceEstructura} de ${estatal.Secciones.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Secciones.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <div style={{ 'width': '100%', 'overflow': 'auto' }}>
              <Col lg="12" md="12">
                <Card>
                  <Row>
                    <Col>
                      {_menu.Exportar === 1 ? (
                        <Button color="success" size="sm" onClick={() => Exportar()}>
                          <span className="btn-label">
                            <i className="fa fa-file-excel-o fa-lg" />
                          </span>
                          Exportar
                            </Button>
                      ) : <></>}
                    </Col>
                  </Row>
                  {loading && (
                    <CircleSpinner
                      className="text-center"
                      size={30}
                      color="#686769"
                      loading={loading}
                    />
                  )}

                  <ReactTable
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                    data={estatalDetalle.data}
                    noDataText="Sin información"
                    manual
                    columns={[
                      {
                        Header: "Region",
                        minWidth: 5,
                        id: "Region",
                        accessor: (d) => d.Region,
                        filterAll: false,
                      },
                      {
                        Header: "Municipios",
                        minWidth: 8,
                        id: "Municipios",
                        accessor: (d) => d.Municipios,
                        filterAll: false,
                      },
                      {
                        Header: "Meta",
                        minWidth: 10,
                        id: "meta",
                        accessor: (d) => d.Meta,
                        filterAll: false,
                      },
                      {
                        Header: "Av Esperado",
                        minWidth: 10,
                        id: "AvanceEsperado",
                        accessor: (d) => d.AvanceEsperado,
                        filterAll: false,
                      },

                      {
                        Header: "Av Compromisos",
                        minWidth: 10,
                        id: "CompromisosUnicos",
                        accessor: (d) => d.CompromisosUnicos,
                        filterAll: false,
                      },
                      {
                        Header: "% de Avance",
                        minWidth: 10,
                        id: "PorcentajeAvance",
                        accessor: (d) => d.PorcentajeAvance,
                        Cell: props => <div>{props.value + " %"}</div>,
                        filterAll: false,
                      },
                      /* {
                        Header: "Compromisos",
                        minWidth: 40,
                        id: "Compromisos",
                        accessor: (d) => d.Compromisos,
                        filterAll: false,
                      },
    
                      {
                        Header: "Voluntarios",
                        minWidth: 40,
                        id: "Voluntarios",
                        accessor: (d) => d.Voluntarios,
                        filterAll: false,
                      }, */
                    ]}
                    className="-striped -highlight primary-pagination"
                    loading={loading}
                    showPagination={false}
                    showPaginationBottom={false}
                    showTotalPages={true}
                    showPageJump={false}
                    canNextFromData={true}
                    defaultPageSize={10}
                    total={estatalDetalle.data.lenght}
                    previousText="Anterior"
                    nextText="Siguiente"
                    ofText="de"
                    pageSizeOptions={[10, 20, 25, 50, 100]}
                    PaginationComponent={Pagination}

                  />

                </Card>
              </Col>
            </div>
          </Row>


        </div>
        <Footer fluid />
      </div>
    </div >
  );
};

export default AvancesCompromisosRegion;
