import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import PerfectScrollbar from "perfect-scrollbar";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
import ModalPapel from "components/Tablero/ModalPapel";
import ModalCampo from "components/Tablero/ModalCampo";
import ModalNuevo from "components/Tablero/ModalNuevo";
import ModalReset from "components/Tablero/ModalReset";
import ModalFiltro from 'components/Filtro/indexTablero';

import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { getVars } from "GlobalStorage";
import { URL_GENERAL } from "data/Consts";

var ps;

const AvancePapel = (props) => {
    const mainPanel = useRef();
    const token = getVars("Token");
    const [table_data, setTableData] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [_menu, _setmenu] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const [sorted, setSorted] = useState([]);
    const [flagExport,setFlagExport]= useState(true)
    const [flagUpdate, setUpdate] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [resetValues, setResetValues] = useState(false);
    const [avancePapel, setAvancePapel] = useState({
        id: "",
        papel: "",
        dato: "",
    });
    const [isEditingCampo, setEditingCampo] = useState(false);
    const [isEditingNuevo, setEditingNuevo] = useState(false);
    const [campo, setCampo] = useState({
        id: "",
        papel: "",
        dato: "",
    });

    const [nuevo, setNuevo] = useState({
        id: "",
        papel: "",
        dato: "",
    });
    const [Filtro, setFiltro] = useState([]);

    useEffect(() => {
        _setmenu(getDataMenu(99, token.menus));

        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }

        if (props.history.action === "PUSH") {
            mainPanel.current.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        if (flagUpdate) {
            const data = {
                page: 0,
                pageSize: pageSize,
                filtered: [],
                sorted: [],
                tipo: "and"
            };
            getRegistros(data);
        }
    }, [flagUpdate]);

    const columns = [
        {
            id: 'Region',
            Header: 'Región',
            accessor: 'Region'
        },
        {
            id: 'Municipio',
            Header: 'Municipio',
            accessor: 'Municipio'
        },
        {
            Header: 'Municipal',
            columns: [
                {
                    id: 'MetaMunicipal',
                    Header: 'Meta',
                    accessor: 'MetaMunicipal',
                    className: 'text-center',
                },
                {
                    id: 'AvanceMpalSistema',
                    Header: 'Avance Sistema',
                    accessor: 'AvanceMpalSistema',
                    className: 'text-center',
                },
                {
                    id: 'PAvanceMpalSistema',
                    Header: '% Sistema',
                    accessor: 'PAvanceMpalSistema',
                    className: 'text-center',
                },
                {
                    id: 'EnCampoMpal',
                    Header: 'Trabajaron',
                    accessor: 'EnCampoMpal',
                    className: 'text-center',
                },
                {
                    id: 'AvanceMpalPapel',
                    Header: 'Avance Papel',
                    accessor: 'AvanceMpalPapel',
                    className: 'text-center',
                },
                {
                    id: 'PAvanceMpalTotal',
                    Header: '% Total',
                    accessor: 'PAvanceMpalTotal',
                    className: 'text-center',
                },
                {
                    id: 'DiffMpal',
                    Header: 'Diferencia',
                    accessor: 'DiffMpal',
                    className: 'text-center',
                }
            ]
        },
        {
            Header: "Poligonal",
            columns: [
                {
                    Header: 'Meta',
                    accessor: "MetaPoligonal",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Sistema',
                    accessor: "AvancePoligonalSistema",
                    className: 'text-center',
                },
                {
                    Header: '% Sistema',
                    accessor: "PAvancePoligonalSistema",
                    className: 'text-center',
                }, 
                {
                    Header: 'Trabajaron',
                    accessor: "EnCampoPoligono",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Papel',
                    accessor: "AvancePoligonalPapel",
                    className: 'text-center',
                },
                {
                    Header: '% Total',
                    accessor: "PAvancePoligonalTotal",
                    className: 'text-center',
                },
                {
                    Header: 'Diferencia',
                    accessor: "DiffPoligonal",
                    className: 'text-center',
                }
            ]
        },
        {
            Header: "Seccional",
            columns: [
                {
                    Header: 'Meta',
                    accessor: "MetaSeccional",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Sistema',
                    accessor: "AvanceSeccionalSistema",
                    className: 'text-center',
                },
                {
                    Header: '% Sistema',
                    accessor: "PAvanceSeccionalSistema",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Sistema Anterior',
                    accessor: "AvanceSeccionalSistema",
                    className: 'text-center',
                },
                {
                    Header: 'Falta',
                    accessor: "SeccionalFaltan",
                    className: 'text-center',
                },
                {
                    Header: 'Trabajaron',
                    accessor: "EnCampoSeccion",
                    className: 'text-center',
                },
                {
                    Header: 'Nuevas Seccionales',
                    accessor: "NuevaSeccional",
                    className: 'text-center',
                },
                {
                    Header: 'Resago',
                    accessor: "SeccionResago",
                    className: 'text-center',
                },
                {
                    Header: 'Falta de Control',
                    accessor: "SeccionFaltaInfo",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Papel',
                    accessor: "AvanceSeccionalPapel",
                    className: 'text-center',
                },
                {
                    Header: '% Total',
                    accessor: "PAvanceSeccionalTotal",
                    className: 'text-center',
                },
                {
                    Header: 'Diferencia',
                    accessor: "DiffSeccional",
                    className: 'text-center',
                }
            ]
        },
        {
            Header: "Jefes de Manzana 1",
            columns: [
                {
                    Header: 'Meta',
                    accessor: "MetaMzaP1",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Sistema',
                    accessor: "AvanceMzaP1Sistema",
                    className: 'text-center',
                },
                {
                    Header: '% Sistema',
                    accessor: "PAvanceMzaP1Sistema",
                    className: 'text-center',
                },
                {
                    Header: 'Falta',
                    accessor: "FaltanMNZA1",
                    className: 'text-center',
                },
                {
                    Header: 'Trabajado',
                    accessor: "EnCampoMzaP1",
                    className: 'text-center',
                },
                {
                    Header: 'Nuevas Jefas MNZ1',
                    accessor: "NuevaMzaP1",
                    className: 'text-center',
                },
                {
                    Header: 'Resago',
                    accessor: "MNZ1Resago",
                    className: 'text-center',
                },
                {
                    Header: 'Falta de Control',
                    accessor: "MNZ1FaltaControl",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Papel',
                    accessor: "AvanceMzaP1Papel",
                    className: 'text-center',
                },
                {
                    Header: '% Total',
                    accessor: "PAvanceMzaP1Total",
                    className: 'text-center',
                },
                {
                    Header: 'Diferencia',
                    accessor: "DiffMzaP1",
                    className: 'text-center',
                }
            ]
        },
        {
            Header: "Jefes de Manzana 2",
            columns: [
                {
                    Header: 'Meta',
                    accessor: "MetaMzaP2",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Sistema',
                    accessor: "AvanceMzaP2Sistema"
                },
                {
                    Header: '% Sistema',
                    accessor: "PAvanceMzaP2Sistema",
                    className: 'text-center',
                },
                {
                    Header: 'Trabajado',
                    accessor: "EnCampoMzaP2",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Papel',
                    accessor: "AvanceMzaP2Papel",
                    className: 'text-center',
                },
                {
                    Header: '% Total',
                    accessor: "PAvanceMzaP2Total",
                    className: 'text-center',
                },
                {
                    Header: 'Diferencia',
                    accessor: "DiffMzaP2",
                    className: 'text-center',
                }
            ]
        },
        {
            Header: "Jefes de Manzana 3",
            columns: [
                {
                    Header: 'Meta',
                    accessor: "MetaMzaP3"
                },
                {
                    Header: 'Avance Sistema',
                    accessor: "AvanceMzaP3Sistema"
                },
                {
                    Header: '% Sistema',
                    accessor: "PAvanceMzaP3Sistema"
                },
                {
                    Header: 'Trabajado',
                    accessor: "EnCampoMzaP3",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Papel',
                    accessor: "AvanceMzaP3Papel",
                    className: 'text-center',
                },
                {
                    Header: '% Total',
                    accessor: "PAvanceMzaP3Total",
                    className: 'text-center',
                },
                {
                    Header: 'Diferencia',
                    accessor: "DiffMzaP3",
                    className: 'text-center',
                }
            ]
        },
        {
            Header: "Compromisos",
            columns: [
                {
                    Header: 'Meta',
                    accessor: "MetaCompMunicipal",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Sistema',
                    accessor: "AvanceCompMpalSistema",
                    className: 'text-center',
                },
                {
                    Header: '% Sistema',
                    accessor: "PAvanceCompMpalSistema",
                    className: 'text-center',
                },
                {
                    Header: 'Falta',
                    accessor: "CompromisoFaltan",
                    className: 'text-center',
                },
                {
                    Header: 'Nuevos Compromisos',
                    accessor: "NuevaCompromisos",
                    className: 'text-center',
                },
                {
                    Header: 'Resago',
                    accessor: "CompromisosResago",
                    className: 'text-center',
                },
                {
                    Header: 'Falta de Control',
                    accessor: "CompromisosFaltaControl",
                    className: 'text-center',
                },
                {
                    Header: 'Avance Papel',
                    accessor: "AvancePapelCompromisos",
                    className: 'text-center',
                },
                {
                    Header: '% Total',
                    accessor: "PAvanceTotalCompromisos",
                    className: 'text-center',
                },
                {
                    Header: 'Diferencia',
                    accessor: "DiffCompromisos",
                    className: 'text-center',
                }
            ]
        }
    ];

    const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
        const data = {
            page: page,
            pageSize: pageSize,
            filtered: params,
            sorted: sorted,
            tipo: "and"
        };
        setPageSize(pageSize);
        setPage(pageSize);
        getRegistros(data);
    }, []);

    const handleFiltro = (filter) => {
        var _filtro = [];

        if (filter.Region.value > 0) {
            _filtro.push({ id: 'idRegion', value: filter.Region.value })
        }
        if (filter.Municipio.value > 0) {
            _filtro.push({ id: 'idMunicipioReportes', value: filter.Municipio.value })
        }

        setFiltro(_filtro);

        const data = {
            page: 0,
            pageSize: pageSize,
            filtered: _filtro,
            sorted: [],
            tipo: "and"
        };

        getRegistros(data);
    };

    const getRegistros = (data) => {
        setLoading(true);
        EjecutarApi(data, "tableroPromocion", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setTableData(
                        res.data.data.map((prop, key) => {
                            prop.PAvanceMpalTotal = <>
                                {prop.PAvanceMpalTotal >= 100 && (<strong style={{ color: "#4BB543" }}>{prop.PAvanceMpalTotal}</strong>)}
                                {(prop.PAvanceMpalTotal >= 75 && prop.PAvanceMpalTotal < 100) && (<strong style={{ color: "#FA9B06" }}>{prop.PAvanceMpalTotal}</strong>)}
                                {prop.PAvanceMpalTotal < 75 && (<strong style={{ color: "#FF0001" }}>{prop.PAvanceMpalTotal}</strong>)}
                            </>
                            prop.PAvancePoligonalTotal = <>
                                {prop.PAvancePoligonalTotal >= 100 && (<strong style={{ color: "#4BB543" }}>{prop.PAvancePoligonalTotal}</strong>)}
                                {(prop.PAvancePoligonalTotal >= 75 && prop.PAvancePoligonalTotal < 100) && (<strong style={{ color: "#FA9B06" }}>{prop.PAvancePoligonalTotal}</strong>)}
                                {prop.PAvancePoligonalTotal < 75 && (<strong style={{ color: "#FF0001" }}>{prop.PAvancePoligonalTotal}</strong>)}
                            </>
                            prop.PAvanceSeccionalTotal = <>
                                {prop.PAvanceSeccionalTotal >= 100 && (<strong style={{ color: "#4BB543" }}>{prop.PAvanceSeccionalTotal}</strong>)}
                                {(prop.PAvanceSeccionalTotal >= 75 && prop.PAvanceSeccionalTotal < 100) && (<strong style={{ color: "#FA9B06" }}>{prop.PAvanceSeccionalTotal}</strong>)}
                                {prop.PAvanceSeccionalTotal < 75 && (<strong style={{ color: "#FF0001" }}>{prop.PAvanceSeccionalTotal}</strong>)}
                            </>
                            prop.PAvanceMzaP1Total = <>
                                {prop.PAvanceMzaP1Total >= 100 && (<strong style={{ color: "#4BB543" }}>{prop.PAvanceMzaP1Total}</strong>)}
                                {(prop.PAvanceMzaP1Total >= 75 && prop.PAvanceMzaP1Total < 100) && (<strong style={{ color: "#FA9B06" }}>{prop.PAvanceMzaP1Total}</strong>)}
                                {prop.PAvanceMzaP1Total < 75 && (<strong style={{ color: "#FF0001" }}>{prop.PAvanceMzaP1Total}</strong>)}
                            </>
                            prop.PAvanceMzaP2Total = <>
                                {prop.PAvanceMzaP2Total >= 100 && (<strong style={{ color: "#4BB543" }}>{prop.PAvanceMzaP2Total}</strong>)}
                                {(prop.PAvanceMzaP2Total >= 75 && prop.PAvanceMzaP2Total < 100) && (<strong style={{ color: "#FA9B06" }}>{prop.PAvanceMzaP2Total}</strong>)}
                                {prop.PAvanceMzaP2Total < 75 && (<strong style={{ color: "#FF0001" }}>{prop.PAvanceMzaP2Total}</strong>)}
                            </>
                            prop.PAvanceMzaP3Total = <>
                                {prop.PAvanceMzaP3Total >= 100 && (<strong style={{ color: "#4BB543" }}>{prop.PAvanceMzaP3Total}</strong>)}
                                {(prop.PAvanceMzaP3Total >= 75 && prop.PAvanceMzaP3Total < 100) && (<strong style={{ color: "#FA9B06" }}>{prop.PAvanceMzaP3Total}</strong>)}
                                {prop.PAvanceMzaP3Total < 75 && (<strong style={{ color: "#FF0001" }}>{prop.PAvanceMzaP3Total}</strong>)}
                            </>
                            prop.PAvanceTotalCompromisos = <>
                                {prop.PAvanceTotalCompromisos >= 100 && (<strong style={{ color: "#4BB543" }}>{prop.PAvanceTotalCompromisos}</strong>)}
                                {(prop.PAvanceTotalCompromisos >= 75 && prop.PAvanceTotalCompromisos < 100) && (<strong style={{ color: "#FA9B06" }}>{prop.PAvanceTotalCompromisos}</strong>)}
                                {prop.PAvanceTotalCompromisos < 75 && (<strong style={{ color: "#FF0001" }}>{prop.PAvanceTotalCompromisos}</strong>)}
                            </>
                            prop.AvanceMpalPapel = <><Button
                                className="btn-link"
                                id="municipio"
                                color="primary"
                                onClick={() => { btnRegistroPapel("Municipio", prop.idMunicipio, prop.AvanceMpalPapel, "Municipio"); }}
                            >{prop.AvanceMpalPapel}</Button></>
                            prop.EnCampoMpal = <><Button
                                className="btn-link"
                                id="municipio"
                                color="primary"
                                onClick={() => { btnRegistroCampo("Municipio", prop.idMunicipio, prop.EnCampoMpal, "Municipio"); }}
                            >{prop.EnCampoMpal}</Button></>
                            prop.AvancePoligonalPapel = <><Button
                                className="btn-link"
                                id="poligono"
                                color="primary"
                                onClick={() => { btnRegistroPapel("poligono", prop.idMunicipio, prop.AvancePoligonalPapel, "Polígono"); }}
                            >{prop.AvancePoligonalPapel}</Button></>
                            prop.EnCampoPoligono = <><Button
                                className="btn-link"
                                id="poligono"
                                color="primary"
                                onClick={() => { btnRegistroCampo("poligono", prop.idMunicipio, prop.EnCampoPoligono, "Polígono"); }}
                            >{prop.EnCampoPoligono}</Button></>
                            prop.AvanceSeccionalPapel = <><Button
                                className="btn-link"
                                color="primary"
                                id="secciones"
                                onClick={() => { btnRegistroPapel("seccion", prop.idMunicipio, prop.AvanceSeccionalPapel, "Sección"); }}
                            >{prop.AvanceSeccionalPapel}</Button></>
                            prop.EnCampoSeccion = <><Button
                                className="btn-link"
                                color="primary"
                                id="secciones"
                                onClick={() => { btnRegistroCampo("seccion", prop.idMunicipio, prop.EnCampoSeccion, "Sección"); }}
                            >{prop.EnCampoSeccion}</Button></>
                            prop.NuevaSeccional = <><Button
                                className="btn-link"
                                color="primary"
                                id="secciones"
                                onClick={() => { btnRegistroNuevo("seccion", prop.idMunicipio, prop.NuevaSeccional, "Sección"); }}
                            >{prop.NuevaSeccional}</Button></>
                            prop.AvanceMzaP1Papel = <><Button
                                className="btn-link"
                                color="primary"
                                id="manz1"
                                onClick={() => { btnRegistroPapel("Manzana1", prop.idMunicipio, prop.AvanceMzaP1Papel, "Manzana Prioridad 1"); }}
                            >{prop.AvanceMzaP1Papel}</Button></>
                            prop.EnCampoMzaP1 = <><Button
                                className="btn-link"
                                color="primary"
                                id="manz1"
                                onClick={() => { btnRegistroCampo("Manzana1", prop.idMunicipio, prop.EnCampoMzaP1, "Manzana Prioridad 1"); }}
                            >{prop.EnCampoMzaP1}</Button></>
                            prop.NuevaMzaP1 = <><Button
                                className="btn-link"
                                color="primary"
                                id="manz1"
                                onClick={() => { btnRegistroNuevo("Manzana1", prop.idMunicipio, prop.NuevaMzaP1, "Manzana Prioridad 1"); }}
                            >{prop.NuevaMzaP1}</Button></>
                            prop.AvanceMzaP2Papel = <><Button
                                className="btn-link"
                                color="primary"
                                id="manz2"
                                onClick={() => { btnRegistroPapel("Manzana2", prop.idMunicipio, prop.AvanceMzaP2Papel, "Manzana Prioridad 2"); }}
                            >{prop.AvanceMzaP2Papel}</Button></>
                            prop.EnCampoMzaP2 = <><Button
                                className="btn-link"
                                color="primary"
                                id="manz2"
                            onClick={() => { btnRegistroCampo("Manzana2", prop.idMunicipio, prop.EnCampoMzaP2, "Manzana Prioridad 2"); }}
                        >{prop.EnCampoMzaP2}</Button></>
                            prop.AvanceMzaP3Papel = <><Button
                                className="btn-link"
                                color="primary"
                                id="manz3"
                                onClick={() => { btnRegistroPapel("Manzana3", prop.idMunicipio, prop.AvanceMzaP3Papel, "Manzana Prioridad 3"); }}
                            >{prop.AvanceMzaP3Papel}</Button></>
                            prop.EnCampoMzaP3 = <><Button
                                className="btn-link"
                                color="primary"
                                id="manz3"
                            onClick={() => { btnRegistroCampo("Manzana3", prop.idMunicipio, prop.EnCampoMzaP3, "Manzana Prioridad 3"); }}
                            >{prop.EnCampoMzaP3}</Button></>
                            prop.AvancePapelCompromisos = <><Button
                                className="btn-link"
                                color="primary"
                                id="compromisos"
                                onClick={() => { btnRegistroPapel("Compromisos", prop.idMunicipio, prop.AvancePapelCompromisos, "Compromisos"); }}
                            >{prop.AvancePapelCompromisos}</Button></>
                            prop.AvancePapelCompromisos = <><Button
                                className="btn-link"
                                color="primary"
                                id="compromisos"
                                onClick={() => { btnRegistroPapel("Compromisos", prop.idMunicipio, prop.AvancePapelCompromisos, "Compromisos"); }}
                            >{prop.AvancePapelCompromisos}</Button></>
                            prop.NuevaCompromisos = <><Button
                                className="btn-link"
                                color="primary"
                                id="compromisos"
                                onClick={() => { btnRegistroNuevo("Compromisos", prop.idMunicipio, prop.NuevaCompromisos, "Compromisos"); }}
                            >{prop.NuevaCompromisos}</Button></>
                            return prop;
                        })
                    );

                    setTotal(res.data.total);
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
            });
    }

    const btnRegistroPapel = (tabla, id, dato, titulo) => {
        setEditing(true)

        setAvancePapel({
            ...avancePapel,
            id: id,
            papel: tabla,
            dato: dato,
            titulo: titulo
        })

    }

    const btnRegistroCampo = (tabla, id, dato, titulo) => {
        setEditingCampo(true)

        setCampo({
            ...campo,
            id: id,
            papel: tabla,
            dato: dato,
            titulo: titulo
        })

    }

    const btnRegistroNuevo = (tabla, id, dato, titulo) => {
        setEditingNuevo(true)

        setNuevo({
            ...nuevo,
            id: id,
            papel: tabla,
            dato: dato,
            titulo: titulo
        })

    }

    const btnCancelarEditar = (e) => {

        if (e === true) {
            const data = {
                page: 0,
                pageSize: pageSize,
                filtered: [],
                sorted: [],
                tipo: "and"
            };
            getRegistros(data);
        }
        else {
            setEditing(false);
            setEditingCampo(false);
            setEditingNuevo(false);
        }
    };

    const setModalReset = (e) => {
        setResetValues(e);
    }

    const getIsUpdated = (res) => {
        setUpdate(res);
    }

    const Exportar = () => {

        setFlagExport(false)

        EjecutarApi({}, "SetLogTablero", token.access_token)
        .then(function (res) {
            return res;
        })
        .then((res) => {
            notify.show(`: ${res.data.message}`, "success", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
        })
        .catch((e) => {
            setLoading(false)
            notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
        });

        const data = {
            page: page,
            pageSize: pageSize,
            filtered: Filtro,
            sorted: sorted,
            Export: true,
            tipo: "and"
        };

        var datos = JSON.stringify(data)
        var url = new URL(
            `${URL_GENERAL}tableroPromocion?token=${token.access_token}&serializar=${datos}`
        );
        window.open(url, "_blank");

        setFlagExport(true)

    }

    return (
        <>
            <div className="wrapper">
                <Sidebar {...props} bgColor="black" activeColor="info" />
                <div className="main-panel ps ps--active-y" ref={mainPanel}>
                    <Navbar {...props} />
                    <Notifications />
                    <div className="content">
                        {/* <Row>
                            <Col lg="3" md="3" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="12" sm="12" lg="12">
                                                <p>Municipales</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Meta</strong>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <small><strong>Sistema</strong></small>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Papel</strong>
                                                    <CardTitle tag="p">10</CardTitle>
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <Row>
                                                <Col md="8" sm="8">
                                                    x de y
                                                </Col>
                                                <Col md="4" sm="4">
                                                    10 %
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="3" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="12" sm="12" lg="12">
                                                <p>Poligonales</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Meta</strong>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <small><strong>Sistema</strong></small>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Papel</strong>
                                                    <CardTitle tag="p">10</CardTitle>
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <Row>
                                                <Col md="8" sm="8">
                                                    x de y
                                                </Col>
                                                <Col md="4" sm="4">
                                                    10 %
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="3" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="12" sm="12" lg="12">
                                                <p>Seccionales</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Meta</strong>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <small><strong>Sistema</strong></small>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Papel</strong>
                                                    <CardTitle tag="p">10</CardTitle>
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <Row>
                                                <Col md="8" sm="8">
                                                    x de y
                                                </Col>
                                                <Col md="4" sm="4">
                                                    10 %
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="3" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="12" sm="12" lg="12">
                                                <p>Compromisos</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Meta</strong>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <small><strong>Sistema</strong></small>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Papel</strong>
                                                    <CardTitle tag="p">10</CardTitle>
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <Row>
                                                <Col md="8" sm="8">
                                                    x de y
                                                </Col>
                                                <Col md="4" sm="4">
                                                    10 %
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="3" md="3" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="12" sm="12" lg="12">
                                                <p>Manzaneras P1</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Meta</strong>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <small><strong>Sistema</strong></small>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Papel</strong>
                                                    <CardTitle tag="p">10</CardTitle>
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <Row>
                                                <Col md="8" sm="8">
                                                    x de y
                                                </Col>
                                                <Col md="4" sm="4">
                                                    10 %
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="3" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="12" sm="12" lg="12">
                                                <p>Manzaneras P2</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Meta</strong>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <small><strong>Sistema</strong></small>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Papel</strong>
                                                    <CardTitle tag="p">10</CardTitle>
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <Row>
                                                <Col md="8" sm="8">
                                                    x de y
                                                </Col>
                                                <Col md="4" sm="4">
                                                    10 %
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="3" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="12" sm="12" lg="12">
                                                <p>Manzaneras P3</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Meta</strong>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <small><strong>Sistema</strong></small>
                                                </p>
                                            </Col>
                                            <Col md="4" sm="4">
                                                <p>
                                                    <strong>Papel</strong>
                                                    <CardTitle tag="p">10</CardTitle>
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <Row>
                                                <Col md="8" sm="8">
                                                    x de y
                                                </Col>
                                                <Col md="4" sm="4">
                                                    10 %
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <Card className="card-stats">
                                    <CardHeader>
                                        <h5 className="title">Tablero de control diario</h5>
                                        <Row>
                                            <Col sm="12">
                                                <ModalFiltro Datos={(e) => handleFiltro(e)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4">
                                                <Button
                                                    className="btn-sm"
                                                    color="warning"
                                                    onClick={(e) => { setUpdate(false); setResetValues(true); }}
                                                >Reestablecer valores de papel</Button>
                                            </Col>
                                            <Col sm="8">
                                                { flagExport ? (_menu.Exportar === 1) ? (<Button size="sm" color='success' className='primary float-right' onClick={() => Exportar()} >Exportar</Button>) : <></> :
                                                 loading && (
                                                    <CircleSpinner
                                                        className="text-center"
                                                        size={30}
                                                        color="#686769"
                                                        loading={loading}
                                                    />
                                                )
                                                }
                                              </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}
                                            <Col lg="12" md="12" sm="12">
                                                <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                                                    <ReactTable
                                                        style={{
                                                            width: "100%",
                                                            height: "100%"
                                                        }}
                                                        data={table_data}
                                                        noDataText="Sin información"
                                                        manual
                                                        columns={columns}
                                                        className="-striped -highlight primary-pagination"
                                                        loading={loading}
                                                        showPagination={true}
                                                        showPaginationBottom={true}
                                                        showTotalPages={true}
                                                        showPageJump={false}
                                                        canNextFromData={true}
                                                        defaultPageSize={pageSize}
                                                        total={total}
                                                        pages={Math.floor(total / pageSize)}
                                                        previousText="Anterior"
                                                        nextText="Siguiente"
                                                        ofText="de"
                                                        pageSizeOptions={[10, 20, 25, 50, 100]}
                                                        PaginationComponent={Pagination}
                                                        onFetchData={fetchData}
                                                        params={Filtro}
                                                    ></ReactTable>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Footer fluid />
                </div>
                {isEditing ? <ModalPapel onHide={btnCancelarEditar} OnEdit={avancePapel} /> : <></>}
                {isEditingCampo ? <ModalCampo onHide={btnCancelarEditar} OnEdit={campo} /> : <></>}
                {isEditingNuevo ? <ModalNuevo onHide={btnCancelarEditar} OnEdit={nuevo} /> : <></>}
                {resetValues ? <ModalReset onHide={setModalReset} isUpdated={getIsUpdated} /> : <></>}
            </div>
        </>
    );
};

export default AvancePapel;