import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row,Collapse,Input,FormGroup,Label, } from 'reactstrap';
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../../data/getPOST";
import { getVars } from "../../GlobalStorage";
import { Filter } from '@material-ui/icons';

function Filtro ({Menu,Datos, getActas, isMuestra}){
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
	const [filtro,setFiltro] = useState({
        DF:{ label: 'Seleccione', value: 0 },
        DL:{ label: 'Seleccione', value: 0 },
        Region:{ label: 'Seleccione', value: 0 },
        Municipio:{ label: 'Seleccione', value: 0 },
        Poligono:{ label: 'Seleccione', value: 0 },
        Seccion:{ label: 'Seleccione', value: 0 },
        AsistenciaRG:{ label: 'Cualquiera', value: null },
        AsistenciaRC:{ label: 'Cualquiera', value: null },
        Instalacion:{ label: 'Cualquiera', value: null },
        Apertura:{ label: 'Cualquiera', value: null },
        CierreCasilla:{ label: 'Cualquiera', value: null },
        CierreVotacion:{ label: 'Cualquiera', value: null },
        isPaseListaP1:{ label: 'Cualquiera', value: null },
        isPaseListaP2:{ label: 'Cualquiera', value: null },
        isPaseListaS1:{ label: 'Cualquiera', value: null },
        isPaseListaS2:{ label: 'Cualquiera', value: null },
        Casilla:{ label: 'Seleccione', value: 0 },
        TipoCasilla:{ label: 'Seleccione', value: 0 },
        TipoEleccion:{ label: 'Seleccione', value: 0}
	})

    const [catDF,setCatDF]=useState([]);
    const [catDL,setCatDL]=useState([]);
    const [catRegionestFilter, setCatRegionestFilter] = useState([])
    const [catPoligonost, setCatPoligonost] = useState([])
    const [catPoligonostFilter, setCatPoligonostFilter] = useState([])
    const [dataCombos, setDataCombo] = useState({ 
        dRegion: { label: 'Seleccione', value: 0 }, 
        dMunicipio: { label: 'Seleccione', value: 0 }, 
        dPoligono: { label: 'Seleccione', value: 0 },
        dSeccion: { label: 'Seleccione', value: 0 } ,
        dPropuesta: { label: 'Seleccione', value: 0 }} )
    const [catMunicipiotFilter, setCatMunicipiotFilter] = useState([]);
    const [catMunicipiot, setCatMunicipiot] = useState([]);
    const [catSecciontFilter,setCatSecciontFilter] = useState([]);
    const [catSecciont,setCatSecciont]=useState([]);
    const [catCasilla,setcatCasilla]=useState([{value:0,label:'Debe validar la sección'}]);
    const [catPropietario,setcatPropietario]=useState([{value:0,label:'Debe validar la sección'}]);
    const [catTiposEleccion, setCatTiposEleccion] = useState([]);

    const [collapse, setCollapse] = useState({
		horizontalTabs: "home",
		verticalTabs: "info",
		pageTabs: "homePages",
		openedCollapses: ["collapseOne", "collapse1"]
    });
 
    useEffect(()=>{
        setLoading(true);
   
        EjecutarApi({
            "catalogos": [
            "regiones",
              "municipios_reportes",
              "dfs",
              "dls",
              "secciones",
              "tipos_eleccion"
            ]
          }, 'getCatalogosSije', token.access_token, "POST")
        .then(res => {
          if(res.results)
            if (res.data) {
                setCatTiposEleccion(res.data.tipos_eleccion);
                // setFilter({...filtro, TipoEleccion: res.data.tipos_eleccion[0]})
                if(!isMuestra){
                    handleActasRecibidas(res.data.tipos_eleccion[0])
                }

                let df = [{ value: 0, label: 'Todos' }]
                let dfFormated = res.data.dfs.map(item => {
                    return {
                    value: item.value,
                    label: item.label
                    }
                })
                //setCatRegionest(regiones.concat(regionesFormated));
                setCatDF(df.concat(dfFormated)); 

                let dl = [{ value: 0, label: 'Todos' }]
                let dlFormated = res.data.dls.map(item => {
                    return {
                    value: item.value,
                    label: item.label
                    }
                })
                //setCatRegionest(regiones.concat(regionesFormated));
                setCatDL(dl.concat(dlFormated)); 

                let regiones = [{ value: 0, label: 'Todos' }]
                let regionesFormated = res.data.regiones.map(item => {
                    return {
                    value: item.value,
                    label: item.label
                    }
                })
                //setCatRegionest(regiones.concat(regionesFormated));
                setCatRegionestFilter(regiones.concat(regionesFormated));

                let municipios = [{ value: 0, label: 'Seleccione' }]
                let municipiosFormated = res.data.municipios_reportes.map(item => {
                    return {
                    value: item.value,
                    label: item.label,
                    idRegion: item.idRegion,
                    }
                })
                setCatMunicipiot(municipios.concat(municipiosFormated));
                setCatMunicipiotFilter(municipios.concat(municipiosFormated));

              /*   let poligonos = [{ value: 0, label: 'Seleccione' }]
                let poligonosFormated = res.data.poligonos.map(item => {
                    return {
                        value: item.value,
                        label: item.label,
                        idMunicipio: item.idMunicipio,
                        idRegion: item.idRegion,
                    }
                })
                setCatPoligonost(poligonos.concat(poligonosFormated));
                setCatPoligonostFilter(poligonos.concat(poligonosFormated)); */

                let seccion = [{ value: 0, label: 'Seleccione' }]
                let seccionFormated = res.data.secciones.map(item => {
                    return {
                        value: item.value,
                        label: item.label,
                        idRegion: item.idRegion,
                        idMunicipio: item.idMunicipioReportes,
                        idPoligono: item.idPoligono
                    }
                })
                setCatSecciont(seccion.concat(seccionFormated));
                setCatSecciontFilter(seccion.concat(seccionFormated));
            }
        })
        .catch((e) => {
        }).then(() => {
          setLoading(false);
        })
    },[])
     
    const collapsesToggle = (colapse) => {
		let openedCollapses = collapse.openedCollapses;
		if (openedCollapses.includes(colapse)) {
		  setCollapse({...collapse,
			openedCollapses: openedCollapses.filter(item => item !== colapse)
		  });
		} else {
		  openedCollapses.push(colapse);
		  setCollapse({...collapse, 
			openedCollapses: openedCollapses
		  });
		}
    };
   
    const changeRegionX = (dRegion) => {
    
        setLoading(true);
    
        if(dRegion.value>1)
        {
          setFiltro({ ...filtro, Region:dRegion, Municipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });
    
    
          setCatMunicipiotFilter(catMunicipiot.filter(data => parseInt(data.idRegion) === parseInt(dRegion.value)));
      
          setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idRegion) === parseInt(dRegion.value)));
          setCatSecciontFilter(catSecciont.filter(data => parseInt(data.idRegion) === parseInt(dRegion.value)));
        }else{
          setFiltro({ ...filtro, Region:{ label: 'Seleccione', value: 0 }, Municipio: { label: 'Seleccione', value: 0 }, Seccion: { label: 'Seleccione', value: 0 } });
    
    
          setCatMunicipiotFilter(catMunicipiot);
      
          setCatPoligonostFilter(catPoligonost);
        }
    
        
    
        setLoading(false);
    
    }
    
    const changeMunicipioX = (dMunicipio) => {
        setLoading(true);
        if(dMunicipio.value>=1)
        {
            setFiltro({ ...filtro, Municipio:dMunicipio, Seccion:{ label: 'Seleccione', value: 0 }});
            setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idMunicipio) === parseInt(dMunicipio.value)));
            setCatSecciontFilter(catSecciont.filter(data => parseInt(data.idMunicipio) === parseInt(dMunicipio.value)));

        }else
        {
            setFiltro({ ...filtro,  Municipio:{ label: 'Seleccione', value: 0 },  Seccion:{ label: 'Seleccione', value: 0 } });
            setCatSecciontFilter(catSecciont);
            setCatPoligonostFilter(catPoligonost);
        }
         setLoading(false);
      }

      const changePoligonoX = (dPoligono) => {
        setLoading(true);
        if(dPoligono.value>=1)
        {
            setFiltro({ ...filtro, Poligono:dPoligono, Seccion:{ label: 'Seleccione', value: 0 }});
            setCatSecciontFilter(catSecciont.filter(data => parseInt(data.idPoligono) === parseInt(dPoligono.value)));

        }else
        {
            setFiltro({ ...filtro,  Poligono:{ label: 'Seleccione', value: 0 },  Seccion:{ label: 'Seleccione', value: 0 } });
            setCatSecciontFilter(catSecciont);
        }
        
            setLoading(false);
      }

    const changeSeccionX =(dSeccion)=>{
        setLoading(true);
        setFiltro({ ...filtro, Seccion:dSeccion });
        setLoading(false);
        EjecutarApi({"seccion":dSeccion.value}, 'getCasillasBySeccion', token.access_token)
                .then(function(res) {
                
                    return res;
                })
                .then((res) => {
                    if(res.success)
                    {
                        setcatCasilla(res.data);
                        setLoading(false);
                    }
                    
                })
                .catch((e) => {
                    setLoading(false);
                    
                });
                //getCasillaPropietario 
                EjecutarApi({"seccion":dSeccion.value}, 'getCasillaPropietario', token.access_token)
                .then(function(res) {
                    return res;
                })
                .then((res) => {
                    if(res.success)
                    {
                        setcatPropietario(res.data);
                        setLoading(false);
                    }
                    
                })
                .catch((e) => {
                    setLoading(false);
                    
                });
  }

    const setFilter=(event)=>{
        Datos(filtro);
    }
    const ClearFilter=()=>{
     
        setFiltro({
            DF:{ label: 'Seleccione', value: 0 },
            DL:{ label: 'Seleccione', value: 0 },
            Region:{ label: 'Seleccione', value: 0 },
            Municipio:{ label: 'Seleccione', value: 0 },
            Poligono:{ label: 'Seleccione', value: 0 },
            Seccion:{ label: 'Seleccione', value: 0 },
            AsistenciaRG:{ label: 'Cualquiera', value: null },
            AsistenciaRC:{ label: 'Cualquiera', value: null },
            Instalacion:{ label: 'Cualquiera', value: null },
            Apertura:{ label: 'Cualquiera', value: null },
            CierreCasilla:{ label: 'Cualquiera', value: null },
            CierreVotacion:{ label: 'Cualquiera', value: null },
            isPaseListaP1:{ label: 'Cualquiera', value: null },
            isPaseListaP2:{ label: 'Cualquiera', value: null },
            isPaseListaS1:{ label: 'Cualquiera', value: null },
            isPaseListaS2:{ label: 'Cualquiera', value: null },
            Casilla:{ label: 'Seleccione', value: 0 },
            TipoCasilla:{ label: 'Seleccione', value: 0 }
        })

        const cleanFiltro={
            DF:{ label: 'Seleccione', value: 0 },
            DL:{ label: 'Seleccione', value: 0 },
            Region:{ label: 'Seleccione', value: 0 },
            Municipio:{ label: 'Seleccione', value: 0 },
            Poligono:{ label: 'Seleccione', value: 0 },
            Seccion:{ label: 'Seleccione', value: 0 },
            AsistenciaRG:{ label: 'Cualquiera', value: null },
            AsistenciaRC:{ label: 'Cualquiera', value: null },
            Instalacion:{ label: 'Cualquiera', value: null },
            Apertura:{ label: 'Cualquiera', value: null },
            CierreCasilla:{ label: 'Cualquiera', value: null },
            CierreVotacion:{ label: 'Cualquiera', value: null },
            isPaseListaP1:{ label: 'Cualquiera', value: null },
            isPaseListaP2:{ label: 'Cualquiera', value: null },
            isPaseListaS1:{ label: 'Cualquiera', value: null },
            isPaseListaS2:{ label: 'Cualquiera', value: null },
            Casilla:{ label: 'Seleccione', value: 0 },
            TipoCasilla:{ label: 'Seleccione', value: 0 }
        }
        
        Datos(cleanFiltro);

    }

    const handleActasRecibidas = (tipoEleccion) => {
        setFiltro({...filtro, TipoEleccion: tipoEleccion})
        getActas(tipoEleccion)
    }

    return (
          <Card className="card-plain">
            <CardBody>
            <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                role="tablist"
            >
                
                <Card className="card-plain">
                {!loading?
                <CardHeader role="tab">
                    <a
                        aria-expanded={collapse.openedCollapses.includes(
                            "collapse3"
                          )}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle("collapse3")}
                        className="text-left"
                    >
                        <Button
                            className="btn-icon btn-neutral"
                            outline
                            size="sm"
                            id="EditUser"
                        >
                            <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                        </Button>
                        <b>Filtro</b>
                    </a>
                </CardHeader>:<></>
                }
                <Collapse
                    role="tabpanel"
                    isOpen={collapse.openedCollapses.includes(
                        "collapse3"
                      )}
                >
                    <CardBody>
                        <Row>
                        <FormGroup className={"col-md-3 text-muted"}>
                                Distrito Local: 
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                    )} 
                                <Select
                                    options={catDL}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={filtro.DL}
                                    onChange={(e) => setFiltro({...filtro, DL: e})}
                                    id="DL"
                                    placeholder="Filtrar por"
                                />
                            </FormGroup>
                            <FormGroup className={"col-md-3 text-muted"}>
                                    Distrito Federal:
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                    )} <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={catDF}
                                        value={filtro.DF}
                                        id="DF"
                                        onChange={(e) =>  setFiltro({...filtro, DF: e})}
                                        placeholder="Filtrar por..."
                                    />
                            </FormGroup> 
                        
                        <FormGroup className={"col-md-3 text-muted"}>
                                Región: 
                            {loading && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                />
                                )}{!loading && (<Select
                                options={catRegionestFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={filtro.Region}
                                onChange={(e) => changeRegionX(e)}
                                id="viewTypeR"
                                placeholder="Filtrar por..."
                                />)}
                            </FormGroup> 
                            <FormGroup className={"col-md-3 text-muted"}>
                                    Municipio:
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                    )} <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        options={catMunicipiotFilter}
                                        value={filtro.Municipio}
                                        id="Municipio"
                                        onChange={(e) =>  changeMunicipioX(e)}
                                        placeholder="Filtrar por..."
                                    />
                            </FormGroup> 
                        
                        </Row>
                        <Row>
                        <FormGroup className={"col-md-2 text-muted"}>
                                <Label for="viewTypeP">Sección:</Label>
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                    )}            {!loading && (<Select
                                    options={catSecciontFilter}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={filtro.Seccion}
                                    onChange={(e) => changeSeccionX(e)}
                                    id="viewTypeS"
                                    placeholder="Filtrar por"
                                />)}
                            </FormGroup>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="casilla">Casilla:</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="casilla"                                                        
                                    placeholder="Debe validar la sección"
                                    options={catCasilla}
                                    value={filtro.Casilla}
                                    onChange = {(e) => setFiltro({...filtro, Casilla:e})}
                                    />
                            </FormGroup>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="AsistenciaRG">Asistencia RG</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="AsistenciaRG"                                                        
                                    placeholder="Debe validar la sección"
                                    options={[{label: 'Cualquiera', value: null}, {label: 'Asistencia RGs', value: 1}, {label: 'No Asistencia RGs', value: 0}]}
                                    value={filtro.AsistenciaRG}
                                    onChange = {(e) => setFiltro({...filtro, AsistenciaRG:e})}
                                    />
                            </FormGroup>
                            
                        </Row>
                        <Row>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="Instalacion">Instalación Casilla</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="Instalacion"                                                        
                                    placeholder="Debe validar la sección"
                                    options={[{label: 'Cualquiera', value: null}, {label: 'Casillas Instaladas', value: 1}, {label: 'No Instaladas', value: 0}]}
                                    value={filtro.Instalacion}
                                    onChange = {(e) => setFiltro({...filtro, Instalacion:e})}
                                    />
                            </FormGroup>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="Apertura">Apertura Casilla:</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="Apertura"                                                        
                                    placeholder="Debe validar la sección"
                                    options={[{label: 'Cualquiera', value: null}, {label: 'Aperturadas ', value: 1}, {label: 'No Aperturadas', value: 0}]}
                                    value={filtro.Apertura}
                                    onChange = {(e) => setFiltro({...filtro, Apertura:e})}
                                    />
                            </FormGroup>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="CierreCasilla">Cierre Casilla</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="CierreCasilla"                                                        
                                    placeholder="Debe validar la sección"
                                    options={[{label: 'Cualquiera', value: null}, {label: 'Casillas Cerradas', value: 1}, {label: 'No Cerradas', value: 0}]}
                                    value={filtro.CierreCasilla}
                                    onChange = {(e) => setFiltro({...filtro, CierreCasilla:e})}
                                    />
                            </FormGroup>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="CierreVotacion">Cierre Votación</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="CierreVotacion"                                                        
                                    placeholder="Debe validar la sección"
                                    options={[{label: 'Cualquiera', value: null}, {label: 'Votación Cerradas', value: 1}, {label: 'No Cerradas', value: 0}]}
                                    value={filtro.CierreVotacion}
                                    onChange = {(e) => setFiltro({...filtro, CierreVotacion:e})}
                                    />
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="AsistenciaRC">PaseLista P1</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="isPaseListaP1"                                                        
                                    placeholder="Debe validar la sección"
                                    options={[{label: 'Cualquiera', value: null}, {label: 'PaseLista RCP1', value: 1}, {label: 'No PaseLista RCP1', value: 0}]}
                                    value={filtro.isPaseListaP1}
                                    onChange = {(e) => setFiltro({...filtro, isPaseListaP1:e})}
                                    />
                            </FormGroup>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="PaseListaRC">PaseLista P2</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="isPaseListaP2"                                                        
                                    placeholder="Debe validar la sección"
                                    options={[{label: 'Cualquiera', value: null}, {label: 'PaseLista RCP2', value: 1}, {label: 'No PaseLista RCP2', value: 0}]}
                                    value={filtro.isPaseListaP2}
                                    onChange = {(e) => setFiltro({...filtro, isPaseListaP2:e})}
                                    />
                            </FormGroup>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="PaseListaRC">PaseLista S1</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="isPaseListaS1"                                                        
                                    placeholder="Debe validar la sección"
                                    options={[{label: 'Cualquiera', value: null}, {label: 'PaseLista RCS1', value: 1}, {label: 'No PaseLista RCS1', value: 0}]}
                                    value={filtro.isPaseListaS1}
                                    onChange = {(e) => setFiltro({...filtro, isPaseListaS1:e})}
                                    />
                            </FormGroup>
                            <FormGroup className={"col-md-2 text-muted"}>
                                    <Label for="PaseListaRC">PaseLista S2</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="isPaseListaS2"                                                        
                                    placeholder="Debe validar la sección"
                                    options={[{label: 'Cualquiera', value: null}, {label: 'PaseLista RCS2', value: 1}, {label: 'No PaseLista RCS2', value: 0}]}
                                    value={filtro.isPaseListaS2}
                                    onChange = {(e) => setFiltro({...filtro, isPaseListaS2:e})}
                                    />
                            </FormGroup>
                        </Row>
                       {!isMuestra && (
                           <Row>
                                <FormGroup className={"col-md-4 text-muted"}>
                                <Label for="PaseListaRC">Tipo de elección</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="isPaseListaS2"                                                        
                                    placeholder="Debe validar la sección"
                                    value={filtro.TipoEleccion}
                                    options={catTiposEleccion}
                                    onChange = {(e) => handleActasRecibidas(e)}
                                    />
                                </FormGroup>
                            </Row>
                       )} 
                     </CardBody>
                    <CardFooter>
                    <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e)=>setFilter()}>
                            <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                            Filtrar
                        </Button>{' '}
                        <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e)=> ClearFilter()}>
                            <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                            Limpiar
                        </Button>
                    </CardFooter>
                </Collapse>
                </Card>
            </div>
            </CardBody>
        </Card>
    );
};
export default Filtro;