import React, { useEffect, useState } from "react";


//Zincri imports
import ChoropethMap from "components/Maps/Impresion/ChoropethMapV5WebPoligono.jsx";
import { getVars } from "GlobalStorage";
import {
  Button
} from "reactstrap";
import { URL_GENERAL } from "data/Consts";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { getDataMenu } from "data/menus";

const Mapas = (props) => {
  const token = getVars("Token");
  //const [myData, setMyData] = useState('');
  const [dataGeoJson, setDataGeoJson] = useState(
    {
      "type": "FeatureCollection",
      "features": [
      ]
    }
  );

  const [tipoMapa] = useState(
    { value: "poligono", label: "Poligonal" }

  );
  //const [textoInput, setTextoInput] = useState("");
  //const zoom = 9; 
  const [zoom, setZoom] = useState(9);
  const [menu, _setmenu] = useState({});
  const [flagFinish, setFlagFinish] = useState(true);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState("");
  const hostserver = URL_GENERAL;


  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  useEffect(() => {
    _setmenu(getDataMenu(90, token.menus));
  }, [dataGeoJson]);
  const basicAlert = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );


  const clickDownloadFile = (e) => {
    if (menu.Exportar === 0) {
      setMensajeAlert("¡No cuenta con permisos para exportar!");
      setFlagAlert(true);
    }
    var url = new URL(
      hostserver + `getArchivoMapaPoligonoWeb?token=${token.access_token}&HashCode=${e.feature.properties.HashCode}`
    );
    window.open(url, "_blank");
  }


  function btnBuscar(busqueda) {
    const Array_Busqueda = busqueda;

    if (Array_Busqueda.length > 0) {
      /* if(Seccion <= 0 || Seccion > 313 ){
          setMensajeAlert("Poligono incorrecto!");
          setFlagAlert(true);
        }
      else{ */
      btnSearch(Array_Busqueda);
      //}

    } else {
      setMensajeAlert("¡Favor de especificar un poligono!");
      setFlagAlert(true);
    }


  }

  async function FiltroSearch(body) {
    setZoom(12);
    setDataGeoJson({});
    const token = getVars("Token").access_token;
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };


      let res = await fetch(
        hostserver + "getPoligonoSeccionImpresionGtoWeb",
        config
      );
      let data = await res.json();
      var json_parser_principal = JSON.parse(data.poligono);
      var json_parser_secundario = JSON.parse(data.seccion);

      if (data.success && data.results) {
        if (json_parser_principal.features === null) {
          throw new Error(data.message);
        }
        if (json_parser_secundario.features === null) {
          json_parser_secundario = {
            "type": "FeatureCollection",
            "features": [
            ]
          }
        }
      }
      else {
        throw new Error(data.message);
      }

      json_parser_principal.features.forEach(item => {
        json_parser_secundario.features.push(item);
      });
      setDataGeoJson(json_parser_secundario);
    } catch (error) {
      if (error.message === 'Token Signature could not be verified.'
        || error.message === 'Sin autorizacion. El token ha expirado'
        || error.message === 'El token esta en la lista negra') {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      }
      else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }


  async function btnSearch(Poligono) {
    setZoom(12);
    setDataGeoJson({});
    const token = getVars("Token").access_token;
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Region: [],
          Municipio: [],
          Poligono: [Poligono],
          OneByOne: true,
          Seccion: []
        }),
      };

      let res = await fetch(
        hostserver + "getPoligonoSeccionImpresionGtoWeb",
        config
      );
      let data = await res.json();
      if (data.success && data.results) {
        var json_parser_principal = JSON.parse(data.poligono);
        var json_parser_secundario = JSON.parse(data.seccion);

        if (json_parser_principal.features === null) {
          throw new Error(data.message);
        }
        if (json_parser_secundario.features === null) {
          json_parser_secundario = {
            "type": "FeatureCollection",
            "features": [
            ]
          }
        }
      }
      else {
        throw new Error(data.message);
      }

      json_parser_principal.features.forEach(item => {
        json_parser_secundario.features.push(item);
      });
      setDataGeoJson(json_parser_secundario);
    } catch (error) {
      if (error.message === 'Token Signature could not be verified.'
        || error.message === 'Sin autorizacion. El token ha expirado'
        || error.message === 'El token esta en la lista negra') {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      }
      else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const handleSearh = (e) => {
    btnBuscar(e);
  }

  const handleFiltro = (filtered, tipo_mapa) => {
    setZoom(10);
    setFlagFinish(false);
    setDataGeoJson({});
    var idMunicipio = filtered.filter((dato) => dato.id === 'idMunicipio');
    var idRegion = filtered.filter((dato) => dato.id === 'idRegion');
    var idPoligono = filtered.filter((dato) => dato.id === 'idPoligono');
    var idSeccion = filtered.filter((dato) => dato.id === 'idSeccion');
    var TieneActas = filtered.filter((dato) => dato.id === 'TieneActas');

    idMunicipio = idMunicipio[0].value;
    idRegion = idRegion[0].value;
    idPoligono = idPoligono[0].value;
    idSeccion = idSeccion[0].value;
    TieneActas = TieneActas[0].value;

    var data_filtro_impresion = {};

    if (
      idRegion.length !== 0 &&
      idMunicipio.length === 0 &&
      idSeccion.length === 0
    ) {
      setDataGeoJson({});
      data_filtro_impresion = {
        "Region": idRegion,
        "Municipio": [],
        "Poligono": [],
        "Seccion": []
      };
    }

    if (idMunicipio.length !== 0 && idSeccion.length === 0) {
      setDataGeoJson({});
      data_filtro_impresion = {
        "Region": [],
        "Municipio": idMunicipio,
        "Poligono": [],
        "Seccion": []
      };
    }
    if (idPoligono.length !== 0) {
      setDataGeoJson({});
      data_filtro_impresion = {
        "Region": [],
        "Municipio": [],
        "Poligono": idPoligono,
        "Seccion": []

      };

    }
    else {
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
      setFlagFinish(true);
      setMensajeAlert("¡Debe especificar el (los) poligono(s) a buscar!")
      setFlagAlert(true);
      return true;
    }
    FiltroSearch(data_filtro_impresion);
    setFlagFinish(true);
    /* if (
      idSeccion.length === 0 &&
      idMunicipio.length === 0 &&
      idRegion.length === 0
    ) {
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    } */
  };

  return (
    <div className="wrapper">
      <div hidden id="dataVars" name="dataVars" ></div>
      <div hidden id="idUser" name="idUser" ></div>
      <div hidden id="DeviceID" name="DeviceID" ></div>
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <div className="content">
          <Button hidden id="btnS" name="btnS" onClick={() => btnBuscar()}> Buscar</Button>
          <>
            {FlagAlert ? basicAlert : <></>}
            <ChoropethMap
              key={"ChoropethMapV4"}
              center={[position.latitude, position.longitude]}
              zoom={zoom}
              data={dataGeoJson}
              rangos={[]}
              tipo={tipoMapa.value}
              label_tipo={tipoMapa.label}
              //handleTipoMapa = {handleTipoMapa}
              //handleDetalleMapa = {handleDetalleMapa}
              handleSearh={handleSearh}
              clickDownloadFile={clickDownloadFile}
              menuExportar={menu}
              MenuPermisos={menu}
              Responsabilidad={getVars('Token').user.Responsabilidad.value}
              _flagFinish={flagFinish}
              handleFiltro={handleFiltro}
            ></ChoropethMap>

          </>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
