import React, { useState, useEffect,useCallback } from "react";
import {
  Row,
  Col,
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  ButtonGroup,
  Input, Label,
  Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faKey,
  faInfoCircle,
  faLock,
  faEdit, faSignInAlt, faMobile
} from "@fortawesome/free-solid-svg-icons";
import { RotateSpinner } from "react-spinners-kit";
import { MetroSpinner } from 'react-spinners-kit';
import classnames from "classnames";
import Navbar from "components/Navbar";
import Footer from "components/Footer"
import Sidebar from "components/Sidebar";
import { getVars } from 'GlobalStorage';
import { CircleSpinner } from "react-spinners-kit";

import ReactTable from 'react-table';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import Pagination from "components/Pagination/pagination";
import imagen from "assets/img/default-avatar.png";
import useWindowDimensions from 'components/useWindowDimensions';
import { UsuarioForm } from "data/interfaces/Usuario";
import UsuarioEdit from "components/Perfil/capturaUsuariosDiaD";
import FiltroUsr from "components/Filtro/indexUsuario";

function UsuariosDiaD(props) {

    const header = (text) => {
        return <h6 style={styleHeader}>{text}</h6>;
    };
    
    const styleHeader = {
        textAlign: "center",
        overflow: 'visible',
        fontSize: '12px',
        color: '#363636',
        lineHeight: 'normal !important',
    };

    const [setsidebarMini] = useState(true);
    const [backgroundColor] = useState("black");
    const [activeColor] = useState("info");
    const [loading, setLoading] = useState(false);
    const token = getVars('Token');
    const [totalCompromisos, setTotalCompromisos] = useState(0);
    const [Filtro, setFiltro] = useState([]);
    const [UserID, setUserID] = useState({});
    const [isLoding,setidLoding]=useState(false);
    const [catResponsabilidad, setCatResponsabilidad] = useState([]);
    
    const [_menu, _setmenu] = useState({});
    const [activeTab, setActiveTab] = useState("1");

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    
    const [isVisible,setisVisible]= useState(false);
    const [compromiso, setCompromiso] = useState({
      idUser: null,
      NombreUser: '',
      Paterno: '',
      Materno: '',
      INE:  '',
      Celular: '',
      Tipo:{value:"",label:"Seleccionar"},
      Data:{value:0,label:"Seleccionar"},
      Responsabilidad:{value:0,label:"Seleccionar"},
    });
    
    const [catTipo,setTipo]=useState([
          {value:0,label:"Seleccionar"},
          //{value:1,label:"Estatal"},
          {value:2,label:"Municipal"},
          {value:3,label:"Distrito Federal"},
          {value:4,label:"Distrito Local"}
    ]);
   
    const [catIsTipo,setCatIsTipo]=useState(
      {
        isMunicipal:false,
        isDF:false,
        isDL:false
      }
    );
    const [catMpio,setcatMpio] = useState([]);
    const [catDF,setcatDF] = useState([]);
    const [catDL,setcatDL] = useState([]);
    const [Data,setData]=useState([]);
    const [lectura,setLectura]=useState(true)
  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(186, token.menus));
    if (token.user.Responsabilidad.value===99)
    {
      setTipo([
        {value:0,label:"Seleccionar"},
        {value:1,label:"Estatal"},
        {value:2,label:"Municipal"},
        {value:3,label:"Distrito Federal"},
        {value:4,label:"Distrito Local"}
      ]);
    }
    getCatalogos();
  }, []);
  
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
    } else {
      setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };
  const handleSetEditing = (item) => {
    console.log(item)
    setisVisible(true);
    setLectura(true);
    setUserID(item);
    setCompromiso({...compromiso,
      idUser: item.id,
      NombreUser: item.Nombre,
      Paterno: item.Paterno,
      Materno: item.Materno,
      INE:  item.INE,
      Celular: item.Celular,
      Tipo:{value:"",label:"Seleccionar"},
      Data:{value:0,label:"Seleccionar"},
      Responsabilidad:{value:0,label:"Seleccionar"}
    })
    setisVisible(true);
  };

  const mapMenus = data => {
    let menus = [];
    if (data.length < 1) return menus;
    data.forEach(element => {
      let children = element.children;
      delete element.children;
      menus.push(element);
      children.forEach(item => {
        menus.push(item);
      });
    });
    return menus;
  };

  const handleFiltro = (filter) => {
    setLoading(true)
    var _filtro = [];
    if (filter.Nombre.length > 0) {
      _filtro.push({ id: "concat_ws(' ',u.Nombre,u.Paterno,u.Materno)", value: filter.Nombre })
    }

    if (filter.Celular.length > 0) {
      _filtro.push({ id: "u.email", value: filter.Celular })
    }
    setFiltro(_filtro);
    //Ejecutando Filtro
    let _menuStorage = getDataMenu(186, token.menus);
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      tipo: 'and',
      filtered: _filtro
    };
		getRegistros(data);
    
  }

  const fetchData = useCallback(({ pageSize, page, sorted,params }) => {

    const data = {
      page: page,
      pageSize: pageSize,
      sorted: sorted,
      tipo: 'and',
      filtered: params
    };
    getRegistros(data);
  }, []);

  const getRegistros = (data) => {
    setLoading(true);
    let _menuStorage = getDataMenu(186, token.menus);
    EjecutarApi(data, "getListadoUsuarios", token.access_token)
    .then(function (res) {
      return res;
    })
    .then((res) => {
      console.log("=>",res);
      console.log("MENUS,",_menuStorage);
      setLoading(false);
      setTotalCompromisos(res.total);
      setData(
        res.data.map((prop, key) => {
          return {
            id: prop.id,
            NombreCompleto:
              _menuStorage.Editar === 1 ?
                (<><Button
                  className="btn-link"
                  color="primary"
                  onClick={() => { handleSetEditing(prop); }}
                >{prop.Nombre}</Button></>)
                : prop.Nombre,

            Celular: prop.Celular ? prop.Celular : 'Sin Celular',
            data: prop,
          };
        })
      );

    })
    .catch((e) => {
      setLoading(false);
      notify.show(
        `Error al obtener Usuarios: ${e.error}`,
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
    });
  };
  const getCatalogos =()=>{
    EjecutarApi({}, 'getCatalogoResponsabilidadSije', token.access_token)
				.then(function (res) {
          
					return res;
				})
				.then((res) => {
          setCatResponsabilidad(res.data);
				})
				.catch((e) => {
					notify.show(`Error: ${e.error}`, 'custom', 5000, {
						background: '#0857B6',
						fontSize: 14,
						text: '#FFFFFF',
					});
				});
        EjecutarApi({
          "catalogos": [
          "regiones",
            "municipios_reportes",
            "dfs",
            "dls"
          ]
        }, 'getCatalogosSije', token.access_token, "POST")
      .then(res => {
        if(res.results)
          if (res.data) {
              setcatDF(res.data.dfs);
              setcatDL(res.data.dls);
              setcatMpio(res.data.municipios_reportes);
          }
      })
      .catch((e) => {
      }).then(() => {
        setLoading(false);
      })
  }
  const handleEvento = (e) => {
    console.log(e)
    let val=e;
    
    setCompromiso({ ...compromiso, Tipo: val});
    console.log(e)
    switch(val.value)
    {
      case 2:
        setCatIsTipo({
          isMunicipal:true,
          isDF:false,
          isDL:false
        })

        break;
      case 3:
        setCatIsTipo({
          isMunicipal:false,
          isDF:true,
          isDL:false
        })
        break;
      case 4:
        setCatIsTipo({
          isMunicipal:false,
          isDF:false,
          isDL:true
        })
        break;
      default:
        setCatIsTipo({
          isMunicipal:false,
          isDF:false,
          isDL:false
        })
        break;
    }
  }
  
  const btnSaveUsers = () => {
      /**
       * Obligatorias -> Tipo, Data, idResponsabilidad
opcional -> idUser
 si no va idUser: email, Nombre, Paterno, Materno se envian como obligatorios
       */
      setidLoding(true);
      var _tipo='';
      if (compromiso.Responsabilidad.value===0)
        {
          notify.show('El campo responsabilidad es requerido', 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        }
      switch(compromiso.Tipo.value)
      {
          case 1:
              _tipo='Estatal';
            break;
          case 2:
              _tipo='Municipal';
            break;
          case 3:
              _tipo='Distrito federal';
            break;
          case 4:
              _tipo='Distrito local';
          break;
          default:
            notify.show('El campo tipo es requerido', 'custom', 5000, {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            });
          break;

      }
      if (compromiso.Data.value<0)
        {
          notify.show('El campo valor es requerido', 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        }
        
      var data={};
      if (compromiso.idUser)
      {
        
        data= {
          Tipo:_tipo,
          Data:compromiso.Tipo.value==="Estatal"?0:compromiso.Data.value,
          idUser: compromiso.idUser,
          idResponsabilidad:compromiso.Responsabilidad.value
        }
      }else
      {
        if (compromiso.Celular.length<1)
        {
          notify.show('El campo cuenta de usuario es requerido', 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        }
        if (compromiso.NombreUser.length<1)
        {
          notify.show('El campo nombre es requerido', 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        }
        if (compromiso.Paterno.length<1)
        {
          notify.show('El campo paterno de usuario es requerido', 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        }
        if (compromiso.Materno.length<1)
        {
          notify.show('El campo paterno de usuario es requerido', 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        }
        data= {
          Nombre: compromiso.NombreUser,
          Paterno: compromiso.Paterno,
          Materno: compromiso.Materno,
          email:compromiso.Celular,
          Tipo:_tipo,
          idResponsabilidad:compromiso.Responsabilidad.value,
          Data:compromiso.Tipo.value==="Estatal"?0:compromiso.Data.value
        }
      }
       
      
      EjecutarApi(data, 'createResponsabilidadSije', token.access_token,"POST")
      .then(function (res) {
        
        return res;
      })
      .then((res) => {
          if(res.results)
          {
            notify.show(res.message, 'custom', 5000, {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            });
          }else
          {
            notify.show(res.message, 'danger', 5000, {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            });
          }
          setidLoding(false);
      })
      .catch((e) => {
        notify.show(`Error: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
        setidLoding(false);
      });
  };
  const columns = [
    {
      Header: "Nombre",
      minWidth: 110,
      id: "NombreCompleto",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
    },
    {
      Header: "Celular",
      minWidth: 40,
      id: "Celular",
      accessor: (d) => d.Celular,
      filterAll: false,
      headerStyle: { fontSize: '12px', align: "center" },
      style: { fontSize: '12px' },
    },
  ];
  const setAddUsuario=()=>{
    setCompromiso({...compromiso,
      idUser: null,
      NombreUser: '',
      Paterno:'',
      Materno: '',
      INE:  '',
      Celular:'',
      Tipo:{value:"",label:"Seleccionar"},
      Data:{value:0,label:"Seleccionar"},
      Responsabilidad:{value:0,label:"Seleccionar"}
    })
    setisVisible(true);
    setLectura(false);
    setCatIsTipo({
      isMunicipal:false,
      isDF:false,
      isDL:false
    })
  }
  return (
    
    <div className="wrapper">
      <Notifications />
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
        <div className="content">

          <div className="content">
            <Card>
              <CardHeader><h6>Administración de Usuarios Dia D</h6></CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12" lg="12" md="12">
                    <FiltroUsr Menu={_menu} Datos={(e) => handleFiltro(e)} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <Col md="4">
                <Card>
                  <CardHeader>
                 
                    <Button size='sm' color='success' onClick={()=>setAddUsuario()} >Agregar</Button>
                   
                  </CardHeader>
                  <CardBody>
                    {loading && (<RotateSpinner color={"#1261ff"} size={30} loading={loading} />)}
                    <div style={{ 'width': '100%', 'overflow': 'auto', }}>
                      <ReactTable
                        style={{
                          width: "99%",
                          height: "100%",
                        }}
                        size='sm'
                        data={Data}
                        noDataText="Sin información"
                        manual
                        columns={columns}
                        className="-striped -highlight primary-pagination"
                        loading={loading}
                        showPagination={true}
                        showPaginationBottom={true}
                        showTotalPages={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={10}
                        total={totalCompromisos}
                        pages={Math.floor(totalCompromisos / 10)}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        PaginationComponent={Pagination}
                        onFetchData={fetchData}
                        params={Filtro}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm='8'>
                {isVisible &&( 
                  <Card>
                  <CardHeader>
                    <center><strong className="title text-center" style={{ color: "#0041A0"}}>Agregar Responsabilidad</strong></center>
                
                  </CardHeader>
           
                   <CardBody>
                    {loading && <MetroSpinner size={30} color="#0000FF" loading={loading} />}
            
                    <Row>
                      <FormGroup className={"col-md-3"}>
                        <Label for="Nombre">
                            <span className="text-danger">*</span>Nombre(s)
                        </Label>
                        <Input
                            id="Nombre"
                            value={compromiso.NombreUser}
                            disabled={lectura}
                            type="text"
                            onChange={(e) =>
                            setCompromiso({
                                ...compromiso,
                                NombreUser: e.target.value,
                            })
                            }
                        />
                        </FormGroup>
        
                        <FormGroup className={"col-md-3"}>
                        <Label for="inputApellido_1">
                            <span className="text-danger">*</span>Apellido Paterno
                        </Label>
                        <Input
                            id="Paterno"
                            value={compromiso.Paterno}
                            disabled={lectura}
                            type="text"
                            onChange={(e) =>
                            setCompromiso({
                                ...compromiso,
                                Paterno: e.target.value,
                            })
                            }
                        />
                        </FormGroup>
        
                        <FormGroup className={"col-md-3"}>
                        <Label for="Materno">
                            <span className="text-danger">*</span>Apellido Materno
                        </Label>
                        <Input
                            id="Materno"
                            value={compromiso.Materno}
                            disabled={lectura}
                            type="text"
                            onChange={(e) =>
                            setCompromiso({
                                ...compromiso,
                                Materno: e.target.value,
                            })
                            }
                        />
                        </FormGroup>
                        <FormGroup className="col-md-3">
                        <Label for="Tel"><span className="text-danger">*</span>Cuenta Usuario:</Label>
                        <Input
                            id="Tel"
                            value={compromiso.Celular}
                            disabled={lectura}
                            pattern="[0-9]*"
                            //maxLength={10}
                            type="text"
                            onChange={(e) =>
                            setCompromiso({
                                ...compromiso,
                                Celular: e.target.value,
                            })
                            }
                        />
                        </FormGroup>
                    </Row>
                    <Row>
                      <Col md="12" >
                      <FormGroup className={"col-md-6"}>
                        <Label for="Responsabilidad">
                          <span className="text-danger">*</span> Selecciona el tipo de Responsabilidad
                        </Label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="Responsabilidad"
                          placeholder="Seleccion Responsabilidad"
                          value={compromiso.Responsabilidad}
                          options={catResponsabilidad}
                          onChange={(e) => setCompromiso({...compromiso,Responsabilidad:e})}
                        />
                      </FormGroup>
                      <FormGroup className={"col-md-6"}>
                        <Label for="Responsabilidad">
                          <span className="text-danger">*</span> Tipo
                        </Label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="Responsabilidad"
                          value={compromiso.Tipo}
                          options={catTipo}
                          onChange={(e) => handleEvento(e)}
                        />
                      </FormGroup>
                      {catIsTipo.isMunicipal&&(
                        <FormGroup className={"col-md-6"}>
                        <Label for="Responsabilidad">
                          <span className="text-danger">*</span> Municipal
                        </Label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="Responsabilidad"
                          placeholder="Seleccion Responsabilidad"
                          value={compromiso.Data}
                          options={catMpio}
                          onChange={(e) => setCompromiso({...compromiso,Data:e})}
                        />
                      </FormGroup>
                      )}
                      {catIsTipo.isDF&&(
                        <FormGroup className={"col-md-6"}>
                        <Label for="Responsabilidad">
                          <span className="text-danger">*</span> Distrito Federal
                        </Label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="Responsabilidad"
                          placeholder="Seleccion Responsabilidad"
                          value={compromiso.Data}
                          options={catDF}
                          onChange={(e) => setCompromiso({...compromiso,Data:e})}
                        />
                      </FormGroup>
                      )}
                      {catIsTipo.isDL&&(
                        <FormGroup className={"col-md-6"}>
                        <Label for="Responsabilidad">
                          <span className="text-danger">*</span> Distrito Local
                        </Label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="Responsabilidad"
                          placeholder="Seleccion Responsabilidad"
                          value={compromiso.Data}
                          options={catDL}
                          onChange={(e) => setCompromiso({...compromiso,Data:e})}
                        />
                      </FormGroup>
                      )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col sm='6' className='text-center'>
                        {!isLoding?
                        <Button onClick={() => btnSaveUsers()} size='sm' color='primary' className='primary'>Guardar </Button>
                        :<><RotateSpinner color={"#1261ff"} size={30} loading={isLoding} /></>}
                        <Button size='sm' color='danger' className='danger' onClick={()=>setAddUsuario()}>Cancelar</Button>
                      </Col>
                      <Col sm='6' className='text-center'>
        
                      </Col>
                    </Row>
                  </CardFooter>
                 
                  
                </Card>
                 
                )}
              </Col>
            </Row>
          </div>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
}
export default UsuariosDiaD;