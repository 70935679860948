import React, { useState, useEffect } from 'react';

import EjecutarApi from 'data/getPOST';
import { isEmptyOrInvalidString } from '../../../data/validations';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  Spinner,
  Form,
} from 'reactstrap';
import Select from 'react-select';
import { notify } from 'react-notify-toast';

// import { PanZoom } from 'react-easy-panzoom';

import './ActaEscrutinio.css';
import ListaPartidos from 'components/PREP/ListaPartidos';
import ImagenActa from 'components/PREP/ImagenActa';
import FormJuridicos from 'components/PREP/FormJuridicos';
import moment from 'moment';

const ActaEscrutinioForm = (props) => {
  const { guardar, idEdit, token, isJuridico = false } = props;
  const [acta, setActa] = useState({
    Seccion: '',
    TipoCasilla: { value: 0, label: 'Sin seleccionar' },
    TipoEleccion: { value: 0, label: 'Sin seleccionar' },
    TipoIncidente: { value: 1, label: 'Sin incidente' },
    Nulos: '',
    NoReg: '',
    image: { archivo: null, url: '' },
    VotosPartidos: [],
  });
  const [formJuridicos, setFormJuridicos] = useState({
    Fecha: '',
    Apertura: '',
    Cierre: '',
    UbicacionCorrecta: true,
    DomicilioEncarte: '',
    DomicilioActa: '',
    Presidente: '',
    Secretario: '',
    Escrutador: '',
    Protesta: false,
    BoletasRecibidas: '',
    BoletasSobrantes: '',
    BoletasUrna: '',
    RCSVotantes: '',
    CiudadanosInscritos: '',
    CiudadanosVotaron: '',
    VotacionTotalActas: '',
    Incidentes: false,
  });
  const [tiposEleccion, setTiposEleccion] = useState([]);
  const [tiposCasilla, setTiposCasilla] = useState([]);
  const [candidatos, setCandidatos] = useState([]);
  const [errors, setErrors] = useState({
    TipoEleccion: '',
    TipoCasilla: '',
    Seccion: '',
    Votos: '',
    Nulos: '',
    NoReg: '',
    // TipoIncidente: '',
  });
  const [errorsJuridico, setErrorsJuridico] = useState({
    Fecha: '',
    Apertura: '',
    Cierre: '',
    DomicilioEncarte: '',
    DomicilioActa: '',
    Presidente: '',
    Secretario: '',
    Escrutador: '',
    BoletasRecibidas: '',
    BoletasSobrantes: '',
    BoletasUrna: '',
    RCSVotantes: '',
    CiudadanosInscritos: '',
    CiudadanosVotaron: '',
    VotacionTotalActas: '',
  });
  const [isLoadingCasillas, setIsLoadingCasillas] = useState(false);
  const [isLoadingTiposEleccion, setIsLoadingTiposEleccion] = useState(true);
  const [isLoadingPartidos, setIsLoadingPartidos] = useState(false);
  const [imagen, setImagen] = useState({
    archivo: null,
    url: '',
  });
  const [totalVotos, setTotalVotos] = useState(0);
  useEffect(() => {
    EjecutarApi({}, 'getTiposEleccion', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setIsLoadingTiposEleccion(false);
          setTiposEleccion(
            res.data.map((tipo) => {
              return {
                value: tipo.idTipoEleccion,
                label: tipo.TipoEleccion,
              };
            })
          );
        } else {
          setIsLoadingTiposEleccion(false);
          notify.show(
            `Error al obtener los tipos de elección ${res.message}`,
            'danger',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        }
      })
      .catch((e) => {
        setIsLoadingTiposEleccion(false);
        notify.show(
          `Error al buscar los tipos de elección: ${e.message} 'Favor de renovar sesión'
              }`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  }, []);

  const handleValidarSeccion = (seccion) => {
    if (acta.VotosPartidos.length > 0) {
      setActa({ ...acta, VotosPartidos: [] });
    }
    if (seccion !== '') {
      setIsLoadingCasillas(true);
      EjecutarApi({ seccion }, 'validarSeccionSije', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          let tipo = 'custom';
          let mensaje = '';
          if (res.success && res.results) {
            mensaje = 'Sección valida';
            handleObtenerCasillas(seccion);
          } else {
            mensaje = res.message;
            tipo = 'error';
          }
          notify.show(mensaje, tipo, 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        })
        .catch((e) => {
          setIsLoadingCasillas(false);
          notify.show(
            `Error al buscar secciones: ${e.message} 'Favor de renovar sesión'
              }`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        });
    }
  };

  const handleObtenerCasillas = (seccion) => {
    EjecutarApi({ seccion }, 'getCasillasBySeccion', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setTiposCasilla(res.data);
          setIsLoadingCasillas(false);
        } else {
          setIsLoadingCasillas(false);
          notify.show(
            `Error al obtener las secciones ${res.message}`,
            'danger',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        }
      })
      .catch((e) => {
        setIsLoadingCasillas(false);
        notify.show(
          `Error al buscar secciones: ${e.message} 'Favor de renovar sesión'
            }`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const handleValidarDatosBusqueda = (e) => {
    e.preventDefault();
    let erroresForm = {
      TipoCasilla: '',
      TipoEleccion: '',
      Seccion: '',
    };
    let contadorErrores = 0;
    if (acta.TipoCasilla.value === 0) {
      erroresForm.TipoCasilla = 'Debe elegir un tipo de casilla';
      contadorErrores++;
    }
    if (acta.TipoEleccion.value === 0) {
      erroresForm.TipoEleccion = 'Debe elegir un tipo de elección';
      contadorErrores++;
    }
    if (acta.Seccion.length === 0) {
      erroresForm.Seccion = 'Debe escribir una sección valida';
      contadorErrores++;
    }
    setErrors(erroresForm);
    if (contadorErrores === 0) {
      let data = {
        idTipoEleccion: acta.TipoEleccion.value,
        seccion: acta.Seccion,
      };
      handleObtenerPartidos(data);
    }
  };

  const handleObtenerPartidos = (data) => {
    setIsLoadingPartidos(true);
    EjecutarApi(data, 'getPlanillas', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let partidos = res.data.partidos.map((partido) => ({
            ...partido,
            votos: '',
          }));
          partidos.push({ id: 'NoReg', votos: '', Logo: 'partidos/noreg.png', Partido: 'No reg' });
          partidos.push({ id: 'Nulos', votos: '', Logo: 'partidos/nulos.png', Partido: 'Nulos' });
          setActa({
            ...acta,
            VotosPartidos: partidos,
          });
          setIsLoadingPartidos(false);
        } else {
          setIsLoadingPartidos(false);
          notify.show(
            `Error al obtener los partidos ${res.message}`,
            'danger',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        }
      })
      .catch((e) => {
        setIsLoadingPartidos(false);
        notify.show(
          `Error al buscar partidos: ${e.message} 'Favor de renovar sesión'
            }`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const handleVotosPartido = (value, index) => {
    let votos = JSON.parse(JSON.stringify(acta.VotosPartidos));
    votos[index].votos = value;
    setTotalVotos(getTotalVotos(votos));
    setActa({ ...acta, VotosPartidos: votos });
  };

  const getTotalVotos = (votos) => {
    let total = 0;
    votos.map((voto) => {
      const cantidad = parseInt(voto.votos);
      if (!isNaN(cantidad) && cantidad !== null && cantidad >= 0) {
        total += cantidad;
      }
    });
    const noReg = parseInt(acta.NoReg);
    if (!isNaN(noReg) && noReg !== null && noReg > 0) {
      total += noReg;
    }
    const nulo = parseInt(acta.Nulos);
    if (!isNaN(nulo) && nulo !== null && nulo > 0) {
      total += nulo;
    }
    return total;
  };

  const handleSelectImagen = (imagen) => {
    setActa({ ...acta, image: imagen });
  };

  const formatResultados = (resultados) => {
    let votosFormated = [];
    resultados.map((resultado) => {
      if (resultado.id !== 'NoReg' && resultado.id !== 'Nulos') {
        let data = {};
        data[`Dato${resultado.id}`] = resultado.votos;
        votosFormated.push(data);
      } else if (resultado.id !== 'NoReg') {
        votosFormated.push({ NoReg: resultado.votos });
      } else {
        votosFormated.push({ Nulos: resultado.votos });
      }
    });
    return votosFormated;
  };

  const handleValidacionForm = () => {
    let errores = {
      TipoEleccion: '',
      TipoCasilla: '',
      Seccion: '',
      Votos: '',
      Nulos: '',
      NoReg: '',
      // TipoIncidente: '',
    };
    let hayErrores = false;

    if (acta.TipoCasilla.value === 0) {
      errores.TipoCasilla = 'Debe elegir un tipo de casilla';
      hayErrores = true;
    }
    if (acta.TipoEleccion.value === 0) {
      errores.TipoEleccion = 'Debe elegir un tipo de elección';
      hayErrores = true;
    }
    if (acta.Seccion.length === 0) {
      errores.Seccion = 'Debe escribir una sección valida';
      hayErrores = true;
    }
    // if (acta.Nulos < 0 || acta.Nulos === '') {
    //   errores.Votos = 'Debe escribir un número mayor o igual a 0';
    //   hayErrores = true;
    // }
    // if (acta.NoReg < 0 || acta.NoReg === '') {
    //   errores.Votos = 'Debe escribir un número mayor o igual a 0';
    //   hayErrores = true;
    // }
    let votosVacios = acta.VotosPartidos.filter(
      (voto) => voto.votos < 0 || voto.votos === ''
    );
    if (votosVacios.length > 0) {
      errores.Votos = 'Debe poner la cantidad de votos para todos los partidos';
      hayErrores = true;
    }

    setErrors(errores);

    return hayErrores;
  };

  const handleValidationJuridico = () => {
    let errores = {
      Fecha: '',
      Apertura: '',
      Cierre: '',
      DomicilioEncarte: '',
      DomicilioActa: '',
      Presidente: '',
      Secretario: '',
      Escrutador: '',
      BoletasRecibidas: '',
      BoletasSobrantes: '',
      BoletasUrna: '',
      RCSVotantes: '',
      CiudadanosInscritos: '',
      CiudadanosVotaron: '',
      VotacionTotalActas: '',
    };
    let hayErrores = false;
    if (isEmptyOrInvalidString(formJuridicos.Fecha)) {
      errores.Fecha = 'Debe seleccionar una fecha valida';
      hayErrores = true;
    }
    if (isEmptyOrInvalidString(formJuridicos.Apertura)) {
      errores.Apertura = 'Debe seleccionar una hora valida';
      hayErrores = true;
    }
    if (isEmptyOrInvalidString(formJuridicos.Cierre)) {
      errores.Cierre = 'Debe seleccionar una hora valida';
      hayErrores = true;
    }
    if (
      !formJuridicos.UbicacionCorrecta &&
      isEmptyOrInvalidString(formJuridicos.DomicilioEncarte)
    ) {
      errores.DomicilioEncarte = 'Debe ingresar un domicilio valido';
      hayErrores = true;
    }
    if (
      !formJuridicos.UbicacionCorrecta &&
      isEmptyOrInvalidString(formJuridicos.DomicilioActa)
    ) {
      errores.DomicilioActa = 'Debe ingresar un domicilio valido';
      hayErrores = true;
    }
    if (isEmptyOrInvalidString(formJuridicos.Presidente)) {
      errores.Presidente = 'Debe ingresar un nombre valido';
      hayErrores = true;
    }
    if (isEmptyOrInvalidString(formJuridicos.Secretario)) {
      errores.Secretario = 'Debe ingresar un nombre valido';
      hayErrores = true;
    }
    if (isEmptyOrInvalidString(formJuridicos.Escrutador)) {
      errores.Escrutador = 'Debe ingresar un nombre valido';
      hayErrores = true;
    }
    if (
      isEmptyOrInvalidString(formJuridicos.BoletasRecibidas) ||
      formJuridicos.BoletasRecibidas < 0
    ) {
      errores.BoletasRecibidas = 'Debe ingresar un número valido';
      hayErrores = true;
    }
    if (
      isEmptyOrInvalidString(formJuridicos.BoletasSobrantes) ||
      formJuridicos.BoletasSobrantes < 0
    ) {
      errores.BoletasSobrantes = 'Debe ingresar un número valido';
      hayErrores = true;
    }
    if (
      isEmptyOrInvalidString(formJuridicos.BoletasUrna) ||
      formJuridicos.BoletasUrna < 0
    ) {
      errores.BoletasUrna = 'Debe ingresar un número valido';
      hayErrores = true;
    }
    if (
      isEmptyOrInvalidString(formJuridicos.RCSVotantes) ||
      formJuridicos.RCSVotantes < 0
    ) {
      errores.RCSVotantes = 'Debe ingresar un número valido';
      hayErrores = true;
    }
    if (
      isEmptyOrInvalidString(formJuridicos.CiudadanosInscritos) ||
      formJuridicos.CiudadanosInscritos < 0
    ) {
      errores.CiudadanosInscritos = 'Debe ingresar un número valido';
      hayErrores = true;
    }
    if (
      isEmptyOrInvalidString(formJuridicos.CiudadanosVotaron) ||
      formJuridicos.CiudadanosVotaron < 0
    ) {
      errores.CiudadanosVotaron = 'Debe ingresar un número valido';
      hayErrores = true;
    }
    if (
      isEmptyOrInvalidString(formJuridicos.VotacionTotalActas) ||
      formJuridicos.VotacionTotalActas < 0
    ) {
      errores.VotacionTotalActas = 'Debe ingresar un número valido';
      hayErrores = true;
    }

    setErrorsJuridico(errores);

    return hayErrores;
  };

  const handleFormat = (e) => {
    e.preventDefault();
    let hayErrores = handleValidacionForm();
    if (isJuridico) {
      hayErrores = hayErrores * handleValidationJuridico() === 1;
    }
    if (!hayErrores) {
      let results = formatResultados(acta.VotosPartidos);
      let data = {
        idTipoEleccion: acta.TipoEleccion.value,
        Seccion: acta.Seccion,
        NombreCasilla: acta.TipoCasilla.label,
        Resultados: results,
        // idIncidente: acta.TipoIncidente.value,
        image: acta.image.archivo ? acta.image.url : null,
      };
      if (isJuridico) {
        data = {...data, ...formJuridicos}
        data.isJuridico = true;
        data.Apertura = moment(data.Fecha + " " + data.Apertura).format("YYYY-MM-DD HH:mm:ss")
        data.Cierre = moment(data.Fecha + " " + data.Cierre).format("YYYY-MM-DD HH:mm:ss")
      }
      createActaEscrutinio(data);
    }
  };

  const createActaEscrutinio = (acta) => {
    setIsLoadingPartidos(true);
    EjecutarApi(acta, 'setResultadosPlanillas', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        let tipo = '';
        if (res.success && res.results) {
          tipo = 'custom';
          setActa({
            Seccion: '',
            TipoCasilla: { value: 0, label: 'Sin seleccionar' },
            TipoEleccion: { value: 0, label: 'Sin seleccionar' },
            // TipoIncidente: { value: 1, label: 'Sin incidente' },
            Nulos: '',
            NoReg: '',
            image: { archivo: null, url: '' },
            VotosPartidos: [],
          });
          setTiposCasilla([]);
          setTotalVotos(0);
        } else {
          tipo = 'danger';
        }
        setIsLoadingPartidos(false);
        notify.show(res.message, tipo, 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      })
      .catch((e) => {
        setIsLoadingPartidos(false);
        notify.show(
          `Error al buscar partidos: ${e.message} 'Favor de renovar sesión'
            }`,
          'danger',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const handleChangeExtras = (value, tipo) => {
    let valueInt = parseInt(value);
    switch (tipo) {
      case 'Nulos':
        setActa({ ...acta, Nulos: value });
        break;
      case 'NoReg':
        setActa({ ...acta, NoReg: value });
        break;
    }
    let votosActuales = getTotalVotos(acta.VotosPartidos);
    if (!isNaN(valueInt) && valueInt !== null && valueInt >= 0) {
      votosActuales += valueInt;
    }
    setTotalVotos(votosActuales);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle align="center">
          <strong>ACTA DE ESCRUTINIO Y CÓMPUTO DE CASILLA</strong>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={(e) => handleValidarDatosBusqueda(e)}>
          <Row>
            <Col md="3">
              <span className="text-danger">*</span>Tipo de elección
              <Select
                options={tiposEleccion}
                isLoading={isLoadingTiposEleccion}
                value={acta.TipoEleccion}
                onChange={(e) => {
                  setActa({
                    ...acta,
                    TipoEleccion: e,
                    VotosPartidos: [],
                    TipoCasilla: { value: 0, label: 'Sin seleccionar' },
                  });
                  setTiposCasilla([]);
                }}
              />
              <span className="form-check-sign" style={{ color: 'red' }}>
                {errors.TipoEleccion}
              </span>
            </Col>
            <Col md="3">
              <span className="text-danger">*</span>Sección
              <Input
                value={acta.Seccion}
                onChange={(e) => {
                  setActa({
                    ...acta,
                    Seccion: e.target.value,
                    TipoCasilla: { value: 0, label: 'Sin seleccionar' },
                  });
                  setTiposCasilla([]);
                }}
                onBlur={(e) => handleValidarSeccion(e.target.value)}
                placeholder="Ingrese una Sección"
              />
              <span className="form-check-sign" style={{ color: 'red' }}>
                {errors.Seccion}
              </span>
            </Col>
            <Col md="3">
              <span className="text-danger">*</span>Casilla
              <Select
                options={tiposCasilla}
                value={acta.TipoCasilla}
                isLoading={isLoadingCasillas}
                isDisabled={acta.Seccion === '' && !isLoadingCasillas}
                onChange={(e) => setActa({ ...acta, TipoCasilla: e })}
              />
              <span className="form-check-sign" style={{ color: 'red' }}>
                {errors.TipoCasilla}
              </span>
            </Col>
            <Col md="3" className="center-holder">
              <Button color="warning" size="sm">
                Obtener partidos
              </Button>
            </Col>
          </Row>
        </Form>
        <hr />
        <Row>
          <Col md="12"></Col>
        </Row>
        {isLoadingPartidos && (
          <Col align="center">
            <Spinner />
          </Col>
        )}
        {!isLoadingPartidos && acta.VotosPartidos.length > 0 && (
          <>
            <Row>
              <Col md="6" className="col-acta">
                <ListaPartidos
                  partidos={acta.VotosPartidos}
                  total={acta.VotosPartidos.length}
                  isLoadingPartidos={isLoadingPartidos}
                  handleVotosPartido={handleVotosPartido}
                  totalVotos={totalVotos}
                  errorVotos={errors.Votos}
                  nulos={acta.Nulos}
                  noReg={acta.NoReg}
                  handleExtraValues={handleChangeExtras}
                />
                {/* <Row style={{marginLeft: '1%'}}>
                  
                </Row> */}
                {/* <Row>
                  <Col>
                    <span className="text-danger">*</span>Votos nulos
                    <Input
                      value={acta.Nulos}
                      type="number"
                      onChange={(e) =>
                        setActa({ ...acta, Nulos: e.target.value })
                      }
                      placeholder="Cantidad de votos nulos"
                    />
                    <span className="form-check-sign" style={{ color: 'red' }}>
                      {errors.Nulos}
                    </span>
                  </Col>
                  <Col>
                    <span className="text-danger">*</span>No registrado
                    <Input
                      value={acta.NoReg}
                      type="number"
                      onChange={(e) =>
                        setActa({ ...acta, NoReg: e.target.value })
                      }
                      placeholder="Cantidad de votos no registrados"
                    />
                    <span className="form-check-sign" style={{ color: 'red' }}>
                      {errors.NoReg}
                    </span>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col>
                    <span className="text-danger">*</span>Incidente
                    <Select
                      options={[]}
                      value={acta.TipoIncidente}
                      isLoading={isLoadingPartidos}
                      onChange={(e) => setActa({ ...acta, TipoIncidente: e })}
                    />
                    <span className="form-check-sign" style={{ color: 'red' }}>
                      {errors.TipoIncidente}
                    </span>
                  </Col>
                </Row> */}
              </Col>
              {!isJuridico && (
                <Col md="6" className="col-acta">
                  {/* <PanZoom style={{ heigth: '100%', width: '100%' }}> */}
                  <ImagenActa
                    imagen={imagen}
                    returnImagen={handleSelectImagen}
                  />
                  {/* </PanZoom> */}
                </Col>
              )}
              {isJuridico && (
                <Col md="6" className="col-acta">
                  <FormJuridicos
                    form={formJuridicos}
                    setForm={setFormJuridicos}
                    formErrors={errorsJuridico}
                    sm="6"
                    smCheck="6"
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col align="center">
                <Form onSubmit={(e) => handleFormat(e)}>
                  <Button color="primary" size="sm">
                    Guardar acta
                  </Button>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ActaEscrutinioForm;
