import React, { useState,useEffect } from 'react';
import { Row,Col, Input, Button,Modal,ModalHeader,ModalBody, ModalFooter} from 'reactstrap';
import { notify } from "react-notify-toast";
import ReactTable from 'react-table';
import {RotateSpinner} from "react-spinners-kit";

import EjecutarApi from "../../data/getPOST";
import Pagination from "../Pagination/pagination";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";


const BuscaResponsable = ({onHide}) => {
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
    const [DatosResponsable,setDatosResponsable] = useState([]);
    const [user, setUser]= useState({
        id:0,
        Nombre:'',
        Paterno:'',
        Materno:'',
        FullName:'',
        Responsabilidad:'',
        Municipio:''
    })
    

    useEffect(() => {
       
       
       
    }, [])
    const handleBuscar =(stateComp)=>{
        setLoading(true);
        var parametro={
            page:0, 
            pageSize:5,
            filtered:[
                {id:"Nombre",value:user.Nombre},
                {id:"Paterno",value:user.Paterno},
                {id:"Materno",value:user.Materno}
            ]
        };
        EjecutarApi(parametro, 'getUsersByResponsabilidad', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                if(res.total>0)
                {
                    setLoading(false);
                    setDatosResponsable(res.data.map((item)=>{
                        return{
                            Municipio:item.MunicipioVive.label,
                            Nombre:item.Nombre,
                            Paterno:item.Paterno,
                            Materno: item.Materno,
                            Cargo:item.Cargo
                        }
                    }));
                }
                else{
                    setLoading(false);
                }
            }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }
    
    return(
            <Modal
                isOpen={true}
                toggle={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
            <ModalHeader toggle={onHide} >
                Agregar Responsabilidad
            </ModalHeader>
            <ModalBody>
               <Col sm="12">
                {<RotateSpinner color={"#1261ff"}size={30} loading={loading}/>}
                    <Row>
                        <Col sm="3">
                            Nombre:
                            <Input id="nombre" value={user.Nombre} onChange={(e)=> setUser({...user , Nombre:e.target.value})} />
                        </Col>
                        <Col sm="3">
                            Paterno:
                            <Input id="nombre" value={user.Paterno} onChange={(e)=> setUser({...user , Paterno:e.target.value})} />
                        </Col>
                        <Col sm="3">
                            Materno:
                            <Input id="nombre" value={user.Materno} onChange={(e)=> setUser({...user , Materno:e.target.value})} />
                        </Col>
                        <Col sm="3">
                            <Button id="bbuscar" size='sm' className='warning' color='warning' onClick={(e) => handleBuscar(e)} ><i className="fa fa-search-fa-lg" aria-hidden="true"></i>Búscar</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                        <ReactTable className="-striped -highlight primary-pagination"
 										size='sm'
										data={DatosResponsable}
										noDataText="Sin información"
										manual
										columns={[
                                          {
                                                Header: "Municipio",
                                                minWidth: 40,
                                                id: "Municipio",
                                                accessor: (d) => d.Municipio,
                                                filterAll: false,
                                          },
										  {
											Header: "Nombre",
											minWidth: 30,
											id: "Nombre",
											accessor: (d) => d.Nombre,
											filterAll: false,
										  },
										  {
											Header: "Paterno",
											minWidth: 30,
											id: "Paterno",
											accessor: (d) => d.Paterno,
											filterAll: false,
										  },
										  {
											Header: "Materno",
											minWidth: 100,
											id: "Materno",
											accessor: (d) => d.Materno,
											filterAll: false,
										  },
										  {
											Header: "Cargo",
											minWidth: 30,
											id: "Cargo",
											accessor: (d) => d.Cargo,
											filterAll: false,
										  },
										]}
										loading={loading}
										showPagination={true}
										showPaginationBottom={true}
										showTotalPages={true}
										showPageJump={false}
										canNextFromData={true}
										defaultPageSize={5}
										total={DatosResponsable.length}
										pages={Math.floor(DatosResponsable.length / 5)}
										previousText="Anterior"
										nextText="Siguiente"
										ofText="de"
										pageSizeOptions={[5,10]}
										PaginationComponent={Pagination}
										getTrProps={(state, rowInfo, column, instance) => {
                                            return {

                                            }
                                        }}
										onFetchData={(stateComp)=>{
                                          //  handleBuscar(stateComp);
                                        }}
									  />  
                        </Col>
                    </Row>
               </Col>
            </ModalBody>
            <ModalFooter>
                
            </ModalFooter>
        </Modal>
        
    );
};
export default BuscaResponsable;
