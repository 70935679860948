import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import LineaTiempo from 'components/Charts/Tablero/ChartRezagoCaptura';
import { Button, Card, Row, Col, Label, CardHeader,CardBody,FormGroup} from 'reactstrap';
import { CircleSpinner } from 'react-spinners-kit';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import { getDataMenu } from 'data/menus.js';
import EjecutarApi from 'data/getPOST';
import { getVars, URL_PUBLICA_REPORTE, tiempoSije, INTERVAL } from 'GlobalStorage';
import Notifications, { notify } from 'react-notify-toast';

const AvanceSocial = (props) => {
  const token = getVars('Token');
  const [isLineaChart,setIsLineaChart] = useState(false);
  const [categoriaLineaTiempo,setCategoriaLineaTiempo] = useState([]);
  const [categoriaLineaTiempo2,setCategoriaLineaTiempo2] = useState([]);
  const [TituloLineaTiempo,setTituloLineaTiempo] = useState("");
  const [serieLineaTiempo,setSerieLineaTiempo] = useState([]);
  const [serieLineaTiempo2,setSerieLineaTiempo2] = useState([]);

  const [catEstructura, setCatEstructura] = useState([]);
  const [Datos, setDatos] = useState({
    idEstructura: '',
  })

  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingChart2, setLoadingChart2] = useState(false);
  
  const handleEstructura = (e) => {
    setDatos({ ...Datos, idEstructura: e });
    setTituloLineaTiempo(e.label);
    getGrafica(e.value);
    getGraficaAcumulativa(e.value);
  }

  useEffect(() =>{
    EjecutarApi({}, 'getCatalogoEstructuraSocial', token.access_token)
    .then(function (res) {
      return res;
    })
    .then((res) => {
      console.log("=>Res", res)
      if (res.success) {
        if (res.results) {
          setCatEstructura(res.data.estructuras.map(item => {
            return { label: item.label, value: item.value }
          }))
        }
      }
    })
    .catch((e) => {
      notify.show(`Error: ${e.error}`, 'custom', 5000, {
        background: '#0857B6',
        fontSize: 14,
        text: '#FFFFFF',
      });
    });
    getGrafica(2);
    setTituloLineaTiempo("Comités");
    getGraficaAcumulativa(2);
  },[])

  const getGrafica=(id)=>{
    //getAvanceSocialGraficas
    setLoadingChart(true);
    EjecutarApi({"idEstructura":id}, 'getAvanceSocialGraficas', token.access_token)
    .then(function (res) {
      return res;
    })
    .then((res) => {
      console.log("=>Res", res)
      if (res.success) {
        if (res.results) {
            setSerieLineaTiempo(res.data.Series);
            setCategoriaLineaTiempo(res.data.categoria);
            
            setIsLineaChart(true);
            setLoadingChart(false);
        }
      }
    })
    .catch((e) => {
      setLoadingChart(false);
      notify.show(`Error: ${e.error}`, 'custom', 5000, {
        background: '#0857B6',
        fontSize: 14,
        text: '#FFFFFF',
      });
    });
  }
  const getGraficaAcumulativa=(id)=>{
    //getAvanceSocialGraficas
    setLoadingChart2(true);
    EjecutarApi({"idEstructura":id}, 'getAvanceSocialGraficasAcumulativa', token.access_token)
    .then(function (res) {
      return res;
    })
    .then((res) => {
      console.log("=>Res", res)
      if (res.success) {
        if (res.results) {
            setSerieLineaTiempo2(res.data.Series);
            setCategoriaLineaTiempo2(res.data.categoria);
            setLoadingChart2(false);
        }
      }
    })
    .catch((e) => {
      setLoadingChart2(false);
      notify.show(`Error: ${e.error}`, 'custom', 5000, {
        background: '#0857B6',
        fontSize: 14,
        text: '#FFFFFF',
      });
    });
  }

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          {/* {_menu.Ver === 1 ? (
            <> */}
              <Card>
                <Col lg="4" md="4" sm="12">
                  <CardHeader>
                    <FormGroup>
                        <Label className="labelForm">Estructura</Label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="idCapacitacion"
                          placeholder="Selecciona.."
                          value={Datos.idEstructura}
                          options={catEstructura}
                          onChange={(e) => handleEstructura(e)}
                        />
                      </FormGroup>
                  </CardHeader>
                </Col>
                <CardBody>
                  <Row>
                      {isLineaChart && <div style={{ width: '100%' }}>
                            <LineaTiempo Categorias={categoriaLineaTiempo} 
                                          Series={serieLineaTiempo} 
                                          Titulo={TituloLineaTiempo} 
                                          TituloMpio="Línea de Tiempo Social" />
                        </div>}
                        {loadingChart && (
                            <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={true}
                            />
                        )}
                  </Row>
                  <Row>
                      {isLineaChart && <div style={{ width: '100%' }}>
                            <LineaTiempo Categorias={categoriaLineaTiempo2} 
                                          Series={serieLineaTiempo2} 
                                          Titulo={TituloLineaTiempo} 
                                          TituloMpio="Línea de Tiempo Acumulativa Social" />
                        </div>}
                        {loadingChart2 && (
                            <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={true}
                            />
                        )}
                  </Row>
                    
                </CardBody>
              </Card>
            {/* </>
            ) : (
              <>
                <Label>No tiene permisos para ver</Label>
              </>
          )}  */}
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};
export default AvanceSocial;