import { setVars, URL_PUBLICA } from "GlobalStorage";
import swal from "sweetalert";

const getPOST = (data, api, token, method) => {

	return new Promise((resolve, reject) => {
		var requestInfo = {
			method: 'POST',
			body: JSON.stringify(data),
			headers: new Headers({
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json',
			}),
		};

		if (method === 'GET') {
			var url = new URL(URL_PUBLICA + api);
			requestInfo = {
				method: 'GET',
				headers: new Headers({
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				}),
			};
			if (data !== '{}') {

				Object.keys(data).forEach(key => {
					const Valor = data[key];
					if (Valor !== '') {
						url.searchParams.append(key, data[key]);
					}
				});
			}

			fetch(url, requestInfo)
				.then((response) => {
					switch (response.status) {
						case 200:
							break;

						case 401:
							swal({
								title: "Su sesión ha expirado!",
								text: "Inicie sesión nuevamente",
								icon: "warning",
								dangerMode: true,
								allowOutsideClick: false,
								closeOnEsc: false,
								button: {
									text: "Iniciar sesión",
									value: true,
									visible: true,
									className: "text-center",
									closeModal: true
								}
							})
								.then(willDelete => {
									if (willDelete) {
										setVars('Token', { access_token: null });
										window.location.href = "/";
									}
								});
							break;
						default:
							break;
					}

					if (response.ok) {
						return response.json();
					}
					throw new Error('Error=>[url]: ', response.statusText);
				})
				.then((dataRS) => {
					if (!dataRS.status === 'success') {
						const rs = {
							results: false,
							data: {},
							error: dataRS.error,
						};
						if (dataRS.message === "El token esta en la lista negra") {
							swal({
								title: "Su sesión ha expirado!",
								text: "Inicie sesión nuevamente",
								icon: "warning",
								dangerMode: true,
								allowOutsideClick: false,
								closeOnEsc: false,
								button: {
									text: "Iniciar sesión",
									value: true,
									visible: true,
									className: "text-center",
									closeModal: true
								}
							})
								.then(willDelete => {
									if (willDelete) {
										setVars('Token', { access_token: null });
										window.location.href = "/";
									}
								});
						}
						reject(rs);
					} else {
						const rs = {
							results: true,
							success: true,
							data: dataRS

						};
						resolve(dataRS);
					}
				})
				.catch((e) => {
					const rs = {
						results: false,
						data: {},
						message: e.message,
					};
					reject(rs);
				});
		}
		else {
			fetch(URL_PUBLICA + api, requestInfo)
				.then((response) => {
					if (response.ok) {
						return response.json();
					}
					throw new Error(`Ocurrio un error: ${api}: ${response.statusText}`);
				})
				.then((dataRS) => {
					if (!dataRS.success) {
						const rs = {
							results: false,
							data: {},
							error: dataRS.error,
						};
						if (dataRS.message === "El token esta en la lista negra") {
							swal({
								title: "Su sesión ha expirado!",
								text: "Inicie sesión nuevamente",
								icon: "warning",
								dangerMode: true,
								allowOutsideClick: false,
								closeOnEsc: false,
								button: {
									text: "Iniciar sesión",
									value: true,
									visible: true,
									className: "text-center",
									closeModal: true
								}
							})
								.then(willDelete => {
									if (willDelete) {
										setVars('Token', { access_token: null });
										window.location.href = "/";
									}
								});
						}
						reject(rs);
					} else {
						if (!dataRS.success && !dataRS.results) {
							if (dataRS.code === '00') {

							}
						}
						else {
							resolve(dataRS);
						}

					}
				})
				.catch((e) => {
					const rs = {
						results: false,
						data: {},
						message: e.message,
					};
					reject(rs);
				});
		}
	});
};

export default getPOST;