import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

Highcharts.setOptions({
	lang: {
  	thousandsSep: ','
  }
})


const CompromisosMetas = ({ datos, Titulos }) => {
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: Titulos.title
        },
        xAxis: Titulos.xAxis,
        yAxis: Titulos.yAxis,
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        credits: {
            enabled: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>'
           
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },
        series: datos
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            type='column'
        />
    )
}

export default CompromisosMetas;