import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { RotateSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer"
import Sidebar from "components/Sidebar";
import { getVars } from 'GlobalStorage';


import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "data/getPOST";
import Tree from '../../components/Social/Tree';
import DataEstructura from "../../components/Social/DataEstructura";
import CardAvances from "../../components/Social/CardAvances";
import CardHeader from "reactstrap/lib/CardHeader";
import Button from "reactstrap/lib/Button";
import ModalMensaje from '../../components/Social/MensajeModal';
import ModalSocialAdd from '../../components/Social/SocialAdd';
import { getDataMenu } from '../../data/menus.js';

function ThreeEstructura(props) {
  const token = getVars('Token');
  // Configuracion
  const [setsidebarMini] = useState(true);
  const [backgroundColor] = useState("black");
  const [activeColor] = useState("info");
  const [loading, setLoading] = useState(false);
  const [idEstructura, setIdEstructura] = useState(0);
  const [isModalEliminarEstructura, setModalEliminarEstructura] = useState(false);
  const [Message, setMessage] = useState(false);
  const [isModalSocialAdd, setModalSocialAdd] = useState(false);
  const [idEstructuraCargo] = useState(token.user.EstructuraSocial.value === 1 ? 1 : token.user.EstructuraSocial.value);

  const [Editar, setEditar] = useState({
    NombreSocial: '',

  });


  const [totales, setTotales] = useState({
    TotalCompromisos: 0,
    TotalCoordinadores: 0,
    TotalEstructuras: 0
  })
  //Menu
  const [_menu, _setmenu] = useState({});
  const [data, setData] = useState([]);
  const [estructura, setEstructura] = useState({
    NombreSocial: ""
  });
  useEffect(() => {
    let menus = getDataMenu(134, token.menus);
    _setmenu(getDataMenu(134, token.menus));
    if (menus.Ver) handleEstructuraData();
  }, []);

  const handleEstructuraData = () => {
    setLoading(true);
    EjecutarApi({ idEstructuraSocial: 1 }, 'getChildrenSocialStructuresWeb', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (res.data.structures.length > 0) handleEstructura(res.data.structures[0])
          setData(res.data.structures);
        }
      })
      .catch((e) => {
        notify.show(`Error en el listado de estructuras`, "error", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
    } else {
      setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  const onHideElimina = () => {
    // setModalSocialAdd(false);
    setModalEliminarEstructura(false);
    handleEstructuraData();
  }

  const handleEstructura = (data) => {
    setIdEstructura(data.id);
    setEstructura(data);
    setTotales({
      totalCompromisos: data.TotalCompromisos,
      totalEnlaces: data.TotalCoordinadores,
      totalEstructuras: data.TotalEstructuras
    })
  };

  const btnAceptar = (idEliminar) => {

    if (idEliminar === 0) {
      resultado = eliminarEstructura(estructura.NombreSocial, idEstructura)
      if (resultado.success) {
        notify.show(` ${resultado.message}`, "custom", 5000, {
          background: "#0857B6",
          text: "#FFFFFF",
        });

      }
    }

  }

  const onHideEnlace = () => {
    setModalSocialAdd(false);
    setModalEliminarEstructura(false);
    handleEstructuraData();

  }

  var resultado = {}
  const eliminarEstructura = (idEstructur, delet) => {
    //eliminarEstructuraSocialWeb eliminarEstructuraSocialWeb
    resultado = {
      delete: 0,
      message: 0,
      results: 0,
      success: 0
    };
    var parametro = {
      idEstructura: idEstructur,
      delete: delet
    }
    EjecutarApi(parametro, 'eliminarEstructuraSocialWeb', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        resultado = {
          delete: res.delete,
          message: res.message,
          results: res.results,
          success: res.success
        }
        return resultado;
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
    return resultado;
  }
  const onClickEliminar = () => {
    setMessage({ Mensaje: `¿Desea eliminar la estructura ${estructura.NombreSocial}?`, NombreEstructura: estructura.NombreSocial, idEstructura: idEstructura });
    setModalEliminarEstructura(true);
  }

  const handleAgregar = () => {
    setEditar({ idSocial: idEstructura, Tipo: 'Add', Data: estructura });
    setModalSocialAdd(true);
  };

  const handleEditar = () => {
    setEditar({ idSocial: idEstructura, Tipo: 'Edit', Data: estructura });
    setModalSocialAdd(true);
  };

  return (
    <div className="wrapper">
      <Notifications />
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
        <div className="content">
          {isModalSocialAdd && (
            <ModalSocialAdd onHide={onHideEnlace} OnEdit={Editar} onData={estructura.Nivel} />
          )}
          {isModalEliminarEstructura && (
            <ModalMensaje onHide={onHideElimina} Datos={Message} btnAceptar={(e) => btnAceptar(e)} />
          )}
          <div className="content">
            {
              _menu.Ver === 1 ?
                <>
                  {/* fILTROS */}
                  <CardAvances statTotales={totales} />
                  {/* inpust */}
                  <Row>
                    <Row>
                      <Col sm="12" lg="12" md="12">
                        {/* fILTRO */}
                      </Col>
                    </Row>
                    <Col md="4">
                      <Card style={{ 'height': '800px', 'overflow': 'auto' }}>
                        <CardHeader>
                          <Row>
                            <Col sm="8" lg="8" md="8">
                              <h5 className="title">{estructura.NombreSocial}</h5>
                            </Col>
                            <Col sm="4" lg="4" md="4">
                              {(_menu.Eliminar === 1 && idEstructura !== idEstructuraCargo) && (
                                <Button onClick={() => onClickEliminar()} size='sm' color='danger'>
                                  <i className="fa fa-trash-o danger"></i>
                                </Button>
                              )}
                              {(_menu.Editar === 1 && idEstructura !== idEstructuraCargo) && (
                                <Button onClick={() => handleEditar()} size='sm' color='warning'>
                                  <i className="fa fa-pencil warning"></i>
                                </Button>
                              )}
                              {(_menu.Agregar === 1) && (
                                <Button onClick={() => handleAgregar()} size='sm' color='info'>
                                  <i className="fa fa-plus info"></i>
                                </Button>
                              )}
                            </Col>
                          </Row>

                        </CardHeader>
                        <CardBody>
                          {loading && (<RotateSpinner color={"#1261ff"} size={30} loading={loading} />)}
                          <Tree data={data} handleEstructura={handleEstructura} />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="8">
                      <Card>
                        <CardBody>
                          {loading && (<RotateSpinner color={"#1261ff"} size={30} loading={loading} />)}
                          <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                            <DataEstructura idEstructura={idEstructura} />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </> :
                <h5>No tiene permisos para ver este menu</h5>
            }

          </div>
        </div>
        <Footer fluid />

      </div>
    </div>
  );

}
export default ThreeEstructura;