import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Form, Card, CardHeader, CardBody, CardFooter, CardTitle, InputGroup, InputGroupText, InputGroupAddon, Input, FormGroup, Label, Tooltip } from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Autosuggest from "react-autosuggest";
import moment from "moment";
import 'moment/locale/es';
import { CircleSpinner } from "react-spinners-kit";

import { getVars } from "GlobalStorage";
import EjecutarApi from "data/getPOST";
import { validateINE, updateDataINE } from "data/validacion_ine";
import { UsuarioForm, UsuarioParamsForm, UsuarioFormErrors, UsuarioRules, UsuarioCandidato } from "data/interfaces/Usuario";

const CandidatosAdd = ({ catalogs }) => {
    const token = getVars("Token");
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(UsuarioForm);
    const [Errors, setErrors] = useState(UsuarioFormErrors);
    const [catEntidad, setCatEntidad] = useState([]);
    const [catSexo, setCatSexo] = useState([]);
    const [catLocalidad, setCatLocalidad] = useState([]);
    const [catLocalidadVota, setCatLocalidadVota] = useState([]);
    const [catColonias, setCatColonias] = useState([]);
    const [catColoniaVive, setCatColoniaVive] = useState([]);
    const [catColoniaVota, setCatColoniaVota] = useState([]);
    const [catMunicipioFilter, setCatMunicipioFilter] = useState([]);
    const [catLocalidadFilter, setCatLocalidadFilter] = useState([]);
    const [catMunicipioVotaFilter, setCatMunicipioVotaFilter] = useState([]);
    const [catLocalidadVotaFilter, setCatLocalidadVotaFilter] = useState([]);
    const [catColoniasViveFilter, setCatColoniaViveFilter] = useState([]);
    const [catColoniasVotaFilter, setCatColoniaVotaFilter] = useState([]);
    const [catPartidos, setCatPartidos] = useState([]);
    const [flagValidations, setFlagValidations] = useState(UsuarioRules);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);

    const [dataCombosVive, setDataComboVive] = useState({
        dMunicipio: { label: 'Seleccionar...', value: 0 },
        dLocalidad: { label: 'Seleccionar...', value: 0 }
    });

    const [dataCombosVota, setDataComboVota] = useState({
        dMunicipio: { label: 'Seleccionar...', value: 0 },
        dLocalidad: { label: 'Seleccionar...', value: 0 }
    });

    const toggleToolTip1 = () => setTooltipOpen1(() => {
        return !tooltipOpen1;
    });

    const toggleToolTip2 = () => setTooltipOpen2(() => {
        return !tooltipOpen2;
    });

    useEffect(() => {
        setCatEntidad(catalogs.Entidad);
        setCatSexo(catalogs.Sexo);
        setCatLocalidad(catalogs.Localidad);
        setCatLocalidadVota(catalogs.Localidad);
        setCatMunicipioFilter(catalogs.Municipio);
        setCatMunicipioVotaFilter(catalogs.Municipio);
        setCatColonias(catalogs.Colonias);
        setCatColoniaVive(catalogs.Colonias);
        setCatColoniaVota(catalogs.Colonias);
        setCatPartidos(catalogs.Partidos);
        // if (catalogs.Municipio.length === 1) {
        //     setDataComboVive(prevState => ({ ...prevState, dMunicipio: catalogs.Municipio[0] }));
        //     setDataComboVota(prevState => ({ ...prevState, dMunicipio: catalogs.Municipio[0] }));
        //     setUserData({ ...userData, MunicipioVive: catalogs.Municipio[0], MunicipioVota: catalogs.Municipio[0] });
        //     setCatLocalidadFilter(catalogs.Localidad);
        //     setCatLocalidadVotaFilter(catalogs.Localidad);
        // }
        setLoading(false);
    }, [catalogs]);

    useEffect(() => {
        Object.keys(UsuarioRules).forEach(function (key) {
            handleValidation(
                key,
                userData[key],
                UsuarioRules[key].rule,
                UsuarioRules[key].params
            );
        });
    }, [userData]);

    const changeMpioVive = (dMunicipio) => {
        setUserData({ ...userData, MunicipioVive: dMunicipio, LocalidadVive: { label: '', value: '' }, CPVive: '', ColoniaVive: '' })
        if (dMunicipio.value > 0) {
            setDataComboVive({ ...dataCombosVive, dMunicipio: dMunicipio, dLocalidad: { label: 'Seleccione...', value: 0 } })
            setCatColoniaVive(catColonias.filter(data => data.value === dMunicipio.value));
            if (catLocalidad.length < 1) {
                EjecutarApi({ idMpio: dMunicipio.value }, "getLocalidadByMpio", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then(response => {
                        if (response.results) {
                            setCatLocalidadFilter(response.data);
                        } else {
                            setCatLocalidadFilter([]);
                        }
                    }).catch((e) => {
                        notify.show("Error al cargar catálogo de localidades", "error", 5000);
                    });
            } else {
                setCatLocalidadFilter(catLocalidad.filter(data => parseInt(data.cve_mun) === dMunicipio.Clave));
            }
        } else {
            setDataComboVive({ ...dataCombosVive, dMunicipio: { label: 'Seleccione...', value: 0 }, dLocalidad: { label: 'Seleccione...', value: 0 } })
            setCatLocalidadFilter(catLocalidad)
        }
    };

    const changeLocalidadVive = (dLocalidad) => {
        setUserData({ ...userData, LocalidadVive: dLocalidad });
        if (dLocalidad.value > 0) {
            setDataComboVive({ ...dataCombosVive, dLocalidad: dLocalidad })
        }
        else {
            setDataComboVive({ ...dataCombosVive, dLocalidad: dLocalidad })
            setCatLocalidadFilter(catLocalidad);
        }
    };

    const ChangeMpioVota = (dMunicipio) => {
        if (dMunicipio.value > 0) {
            setDataComboVota({ ...dataCombosVota, dMunicipio, dLocalidad: { label: 'Seleccione...', value: 0 } })
            setCatColoniaVota(catColonias.filter(data => data.value === dMunicipio.value));
            if (catLocalidadVota.length < 1) {
                EjecutarApi({ idMpio: dMunicipio.value }, "getLocalidadByMpio", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then(response => {
                        if (response.results) {
                            setCatLocalidadVotaFilter(response.data);
                        } else {
                            setCatLocalidadVotaFilter([]);
                        }
                    }).catch((e) => {
                        notify.show("Error al cargar catálogo de localidades", "error", 5000);
                    });
            } else {
                setCatLocalidadVotaFilter(catLocalidadVota.filter(data => parseInt(data.cve_mun) === dMunicipio.Clave));
            }
        }
        else {
            setDataComboVota({ ...dataCombosVota, dMunicipio: { label: 'Seleccione...', value: 0 }, dLocalidad: { label: 'Seleccione...', value: 0 } })
            setCatLocalidadVotaFilter(catLocalidad)
        }
    };

    const changeLocalidadVota = (dLocalidad) => {
        if (dLocalidad.value > 0) {
            setDataComboVota({ ...dataCombosVota, dLocalidad: dLocalidad })
        }
        else {
            setDataComboVota({ ...dataCombosVota, dLocalidad: dLocalidad })
            setCatLocalidadVotaFilter(catLocalidad)
        }
    };

    const onBlur = () => {
        document.getElementById("react-autowhatever-1").style.height = "0px";
    };

    const getColoniaViveFilter = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength < 1 || catColoniaVive.length < 1) {
            setUserData({ ...userData, ColoniaVive: "", CPVive: "" });
            document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.height = "0px";
            return [];
        } else {
            const filtered = catColoniaVive.filter(item => item.label.toLowerCase().slice(0, inputLength) === inputValue);
            if (filtered.length > 0) {
                document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.height = "100px";
                document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.overflow = "auto";
                return filtered;
            } else {
                document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.height = "0px";
                return [];
            }
        }
    }

    const onChangeColoniaVive = (event, { newValue }) => {
        setUserData({ ...userData, ColoniaVive: newValue });
    };

    const onColoniaViveSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.height = "0px";
        setUserData({ ...userData, ColoniaVive: suggestionValue, CPVive: suggestion.d_codigo });
    };

    const inputProps = {
        placeholder: "Colonia",
        className: "form-control",
        value: userData.ColoniaVive,
        onChange: onChangeColoniaVive,
        onBlur: onBlur
    }

    const getColoniaVotaFilter = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength < 1 || catColoniaVota.length < 1) {
            setUserData({ ...userData, ColoniaVota: "", CPVota: "" });
            document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.height = "0px";
            return [];
        } else {
            const filtered = catColoniaVota.filter(item => item.label.toLowerCase().slice(0, inputLength) === inputValue);
            if (filtered.length > 0) {
                document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.height = "100px";
                document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.overflow = "auto";
                return filtered;
            } else {
                document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.height = "0px";
                return [];
            }
        }
    }

    const onChangeColoniaVota = (event, { newValue }) => {
        setUserData({ ...userData, ColoniaVota: newValue })
    };

    const onColoniaVotaSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.height = "0px";
        setUserData({ ...userData, ColoniaVota: suggestionValue, CPVota: suggestion.d_codigo })
    };

    const inputProps2 = {
        id: "autoSuggest2",
        placeholder: "Colonia",
        className: "form-control",
        value: userData.ColoniaVota,
        onChange: onChangeColoniaVota,
        onBlur: onBlur
    };

    const changeTipoEleccion = (event) => {

    };

    const searchSimpatizante = (params) => {
        if (params.INE) {
            if (params.INE.length !== 18) {
                return false;
            } else {
                searchInApi(params);
            }
        }
        if (params.email) {
            if (params.email.length !== 10) {
                return false;
            } else {
                searchInApi(params);
            }
        }
    };

    const searchInApi = (params) => {
        setLoading(true);
        EjecutarApi(params, "getUserById", token.access_token)
            .then(function (res) {
                return res;
            }).then((response) => {
                if (response.results) {
                    setUserData(UsuarioForm);
                    setUserData({
                        ...userData,
                        id: response.data.id,
                        email: response.data.email,
                        INE: response.data.INE,
                        Nombre: response.data.Nombre,
                        Paterno: response.data.Paterno,
                        Materno: response.data.Materno,
                        FechaNacimiento: response.data.FechaNacimiento,
                        Sexo: response.data.Sexo,
                        EstadoNacimiento: response.data.EstadoNacimiento,
                        CalleVive: response.data.CalleVive,
                        NumExtVive: response.data.NumExtVive !== null ? response.data.NumExtVive.toString() : '',
                        NumIntVive: response.data.NumIntVive !== null ? response.data.NumIntVive.toString() : '',
                        MunicipioVive: response.data.MunicipioVive,
                        LocalidadVive: response.data.LocalidadVive,
                        ColoniaVive: response.data.ColoniaVive !== null ? response.data.ColoniaVive : '',
                        CPVive: response.data.CPVive !== null ? response.data.CPVive : '',
                        SeccionVota: response.data.SeccionVota !== null ? response.data.SeccionVota.toString() : '',
                        MismaAddress: response.data.MismaAddress,
                        CalleVota: response.data.CalleVota !== null ? response.data.CalleVota : '',
                        NumExtVota: response.data.NumExtVota !== null ? response.data.NumExtVota.toString() : '',
                        NumIntVota: response.data.NumIntVota !== null ? response.data.NumIntVota.toString() : '',
                        MunicipioVota: response.data.MunicipioVota,
                        LocalidadVota: response.data.LocalidadVota,
                        ColoniaVota: response.data.ColoniaVota !== null ? response.data.ColoniaVota : '',
                        CPVota: response.data.CPVota !== null ? response.data.CPVota : '',
                        Celular: response.data.Celular ? response.data.Celular : '',
                        Telefono: response.data.Telefono ? response.data.Telefono : '',
                        TelMensajes: response.data.TelMensajes ? response.data.TelMensajes : '',
                        Facebook: response.data.Facebook ? response.data.Facebook : '',
                        Twitter: response.data.Twitter ? response.data.Twitter : '',
                        Instagram: response.data.Instagram ? response.data.Instagram : '',
                    });

                    setDataComboVive({ ...dataCombosVive, dMunicipio: response.data.MunicipioVive, dLocalidad: response.data.LocalidadVive })
                    setDataComboVota({ ...dataCombosVota, dMunicipio: response.data.MunicipioVota, dLocalidad: response.data.LocalidadVota })
                } else {
                    setUserData(UsuarioForm);
                    // let data = updateDataINE(INE.toUpperCase(), catEntidad);
                    // setUserData({ ...userData, FechaNacimiento: data.FechaNacimiento, Sexo: data.Sexo, EstadoNacimiento: data.EstadoNacimiento });
                    setDataComboVive({ ...dataCombosVive, dMunicipio: { value: 0, label: '' }, dLocalidad: { value: 0, label: '' } })
                }
                setLoading(false);
            }).catch((e) => {
                notify.show("Error al consultar", "error", 5000);
                setLoading(false);
            });
    }

    const clearForm = () => {
        setUserData(UsuarioForm);
    };

    const handleValidation = function (name, value, validators, params) {
        validators.forEach(validation => {
            value = value === null ? "" : value;
            value = typeof value === "string" ? value.replace(/\s+/, "") : value;
            const result = validation(value, params);
            setFlagValidations(prevstate => ({ ...prevstate, [name]: { valid: result.valid, rule: validators, params: params } }));

            if (!result.valid) {
                setErrors(prevstate => ({ ...prevstate, [name]: result.message }));
            } else {
                setErrors(prevstate => ({ ...prevstate, [name]: "" }));
            }
        });
    };

    const save = () => {
        let flag = true;

        Object.keys(flagValidations).forEach(function (key) {
            if (!flagValidations[key].valid) {
            }
            flag = flag && flagValidations[key].valid;
        });
    };

    const btnCancelar = () => {

    }

    return (
        <Form>
            {/* <Row>
                <Col lg="12" md="12" sm="12">
                    <Button name="btnRegresar" size="sm" type="button" className="success" onClick={btnCancelar}>Regresar</Button>
                </Col>
            </Row> */}
            <Row>
                {loading && (
                    <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={loading}
                    />
                )}
                <FormGroup className="col-md-4">
                    <Label for="email">
                        <span className="text-danger">*</span>Usuario
                    </Label>
                    <InputGroup>
                        <Input
                            type="text"
                            value={userData.email === null ? "" : userData.email}
                            onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                            onBlur={(e) => { searchSimpatizante({ email: e.target.value }) }}
                        />
                        <InputGroupAddon addonType="append">
                            <Button
                                style={{ margin: '0px 1px' }}
                                color="info"
                                id="searchByEmail"
                                onClick={e => { e.preventDefault(); }}><i className="fa fa-info" /></Button>
                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen2}
                                autohide={false}
                                target="searchByEmail"
                                toggle={toggleToolTip2}
                            >
                                Ingrese los 10 dígitos del teléfono celular (cuenta de usuario) para su búsqueda
                            </Tooltip>
                        </InputGroupAddon>
                    </InputGroup>
                    <div className="text-danger">
                        <small>{Errors.email}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="ClaveElector">
                        <span className="text-danger">*</span>INE
                    </Label>
                    <InputGroup>
                        <Input
                            type="text"
                            value={userData.INE === null ? "" : userData.INE}
                            onChange={(e) => setUserData({ ...userData, INE: e.target.value.toUpperCase() })}
                            onBlur={(e) => { searchSimpatizante({ INE: e.target.value.toUpperCase() }) }}
                        />
                        <InputGroupAddon addonType="append">
                            <Button
                                style={{ margin: '0px 1px' }}
                                color="info"
                                id="searchByINE"
                                onClick={e => { e.preventDefault(); }}><i className="fa fa-info" /></Button>
                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen1}
                                autohide={false}
                                target="searchByINE"
                                toggle={toggleToolTip1}
                            >
                                Ingrese los 18 caracteres de la INE para su búsqueda
                            </Tooltip>
                        </InputGroupAddon>
                    </InputGroup>
                    <div className="text-danger">
                        <small>{Errors.INE}</small>
                    </div>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-4">
                    <Label for="TipoEleccion">
                        <span className="text-danger">*</span>Tipo de elección
                    </Label>
                    <Select
                        // options={catTipoEleccion}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        id="TipoEleccion"
                        placeholder="TipoEleccion"
                        // value={TipoEleccion}
                        onChange={e => { changeTipoEleccion(e) }}
                        autoComplete="off"
                    />
                    <div className="text-danger">
                        <small>{Errors.TipoEleccion}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="TipoEleccion">
                        <span className="text-danger">*</span>Partido
                    </Label>
                    <Select
                        options={catPartidos}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        id="Partido"
                        placeholder="Partido"
                        value={userData.Partido === null ? "" : userData.Partido}
                        onChange={e => setUserData({ ...userData, Partido: e })}
                        autoComplete="off"
                    />
                    <div className="text-danger">
                        <small>{Errors.Partido}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="TipoEleccion">
                        <span className="text-danger">*</span>Ámbito
                    </Label>
                    <Select
                        options={catPartidos}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        id="Partido"
                        placeholder="Partido"
                        value={userData.Partido === null ? "" : userData.Partido}
                        onChange={e => setUserData({ ...userData, Partido: e })}
                        autoComplete="off"
                    />
                    <div className="text-danger">
                        <small>{Errors.TipoEleccion}</small>
                    </div>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-4">
                    <Label for="Nombre">
                        <span className="text-danger">*</span>Nombre(s)
                        </Label>
                    <Input
                        id="Nombre"
                        value={userData.Nombre === null ? "" : userData.Nombre}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, Nombre: e.target.value.toUpperCase() })}
                    />
                    <div className="text-danger">
                        <small>{Errors.Nombre}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="Paterno">
                        <span className="text-danger">*</span>Apellido Paterno
                        </Label>
                    <Input
                        id="Paterno"
                        value={userData.Paterno === null ? "" : userData.Paterno}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, Paterno: e.target.value.toUpperCase() })}
                    />
                    <div className="text-danger">
                        <small>{Errors.Paterno}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="Materno">
                        <span className="text-danger">*</span>Apellido Materno
                        </Label>
                    <Input
                        id="Materno"
                        value={userData.Materno === null ? "" : userData.Materno}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, Materno: e.target.value.toUpperCase() })}
                    />
                    <div className="text-danger">
                        <small>{Errors.Materno}</small>
                    </div>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-4">
                    <Label for="FechaNacimiento">
                        <span className="text-danger">*</span>Fecha de nacimiento
                    </Label>
                    <ReactDatetime
                        id="FechaNacimiento"
                        name="FechaNacimiento"
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        locale="es"
                        closeOnSelect
                        value={userData.FechaNacimiento === null ? "" : userData.FechaNacimiento}
                        onChange={(e) => setUserData({ ...userData, FechaNacimiento: moment(e._d).format("YYYY-MM-DD") })}
                        inputProps={{ className: "form-control" }}
                    />
                    <div className="text-danger">
                        <small>{Errors.FechaNacimiento}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="Sexo">
                        <span className="text-danger">*</span>Sexo
                    </Label>
                    <Select
                        options={catSexo}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={userData.Sexo}
                        onChange={(e) => setUserData({ ...userData, Sexo: e })}
                        id="Sexo"
                        placeholder="Sexo"
                    />
                    <div className="text-danger">
                        <small>{Errors.Sexo}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="EstadoNacimiento">
                        <span className="text-danger">*</span>Estado de nacimiento
                    </Label>
                    <Select
                        options={catEntidad}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={userData.EstadoNacimiento}
                        onChange={(e) => setUserData({ ...userData, EstadoNacimiento: e })}
                        id="EstadoNacimiento"
                        placeholder="EstadoNacimiento"
                    />
                    <div className="text-danger">
                        <small>{Errors.EstadoNacimiento}</small>
                    </div>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-12 text-center text-primary">
                    <strong>Domicilio donde vive</strong>
                    <br />
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-4">
                    <Label for="MunicipioVive">
                        <span className="text-danger">*</span>Municipio Vive
                    </Label>
                    <Select
                        options={catMunicipioFilter}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        id="MunicipioVive"
                        value={dataCombosVive.dMunicipio}
                        onChange={(e) => changeMpioVive(e)}
                        placeholder="Filtrar ..."
                    />
                    <div className="text-danger">
                        <small>{Errors.MunicipioVive}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="CalleVive">
                        <span className="text-danger">*</span>Calle
                    </Label>
                    <Input
                        id="CalleVive"
                        value={userData.CalleVive === null ? "" : userData.CalleVive}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, CalleVive: e.target.value })}
                    />
                    <div className="text-danger">
                        <small>{Errors.CalleVive}</small>
                    </div>
                </FormGroup>

                <FormGroup className="col-md-2">
                    <Label for="NumExtVive">
                        <span className="text-danger">*</span>Número Ext.
                    </Label>
                    <Input
                        id="NumExtVive"
                        value={userData.NumExtVive === null ? "" : userData.NumExtVive}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, NumExtVive: e.target.value })}
                    />
                    <div className="text-danger">
                        <small>{Errors.NumExtVive}</small>
                    </div>
                </FormGroup>

                <FormGroup className="col-md-2">
                    <Label for="NumIntVive">Número Int.</Label>
                    <Input
                        id="NumIntVive"
                        value={userData.NumIntVive === null ? "" : userData.NumIntVive}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, NumIntVive: e.target.value })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-4">
                    <Label for="LocalidadVive">
                        Localidad:<span className="text-danger">*</span>
                    </Label>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        options={catLocalidadFilter}
                        id="Localidad"
                        onChange={(event) => changeLocalidadVive(event)}
                        value={dataCombosVive.dLocalidad}
                        placeholder="seleccione su localidad"
                    />
                    <div className="text-danger">
                        <small>{Errors.LocalidadVive}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="ColoniaVive">
                        <span className="text-danger">*</span>Colonia
                                                </Label>
                    <Autosuggest
                        suggestions={catColoniasViveFilter}
                        onSuggestionsFetchRequested={e => setCatColoniaViveFilter(getColoniaViveFilter(e.value))}
                        onSuggestionsClearRequested={() => setCatColoniaViveFilter([])}
                        getSuggestionValue={(item) => item.label}
                        renderSuggestion={(item) => (<div>{item.label}</div>)}
                        onSuggestionSelected={onColoniaViveSelected}
                        inputProps={inputProps}
                    />
                    <div className="text-danger">
                        <small>{Errors.ColoniaVive}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-2">
                    <Label for="CPVive">
                        <span className="text-danger">*</span>CP
                    </Label>
                    <Input
                        id="CPVive"
                        value={userData.CPVive}
                        type="text"
                        maxLength={5}
                        onChange={(event) => { setUserData({ ...userData, CPVive: event.target.value }); }}
                    />
                    <div className="text-danger">
                        <small>{Errors.CPVive}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-2">
                    <Label for="SeccionVota">
                        <span className="text-danger">*</span>Sección vota
                                                    </Label>
                    <Input
                        id="SeccionVota"
                        value={userData.SeccionVota}
                        pattern="[0-9]*"
                        maxLength={5}
                        type="text"
                        onChange={(event) => setUserData({ ...userData, SeccionVota: event.target.value })}
                    />
                    <div className="text-danger">
                        <small>{Errors.SeccionVota}</small>
                    </div>
                </FormGroup>
            </Row>

            <Row>
                <FormGroup className="col-md-12 text-center text-primary">
                    <strong>¿La dirección de ID Único y donde vive son la misma?</strong>
                    <br />
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-12 text-center text-primary">
                    <div className="form-check-radio form-check-inline">
                        <Label check>
                            <Input
                                type="radio"
                                name="exampleRadios1"
                                id="exampleRadios11"
                                value="option1"
                                defaultChecked={userData.MismaAddress ? true : false}
                                onChange={() => setUserData({ ...userData, MismaAddress: true })}
                            />
                            Si <span className="form-check-sign"></span>
                        </Label>
                    </div>
                    <div className="form-check-radio form-check-inline">
                        <Label check>
                            <Input
                                type="radio"
                                name="exampleRadios1"
                                id="exampleRadios12"
                                value="option2"
                                defaultChecked={!userData.MismaAddress ? true : false}
                                onChange={() => setUserData({ ...userData, MismaAddress: false })}
                            />
                            No <span className="form-check-sign"></span>
                        </Label>
                    </div>
                </FormGroup>
            </Row>
            {!userData.MismaAddress && (
                <div>
                    <Row>
                        <FormGroup className="col-md-12 text-center text-primary">
                            <strong>Capture información de la credencial INE</strong>
                            <br />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="col-md-4">
                            <Label for="MunicipioVota">
                                <span className="text-danger">*</span>Municipio Vota
                            </Label>
                            <Select
                                options={catMunicipioVotaFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                id="MunicipioVota"
                                name="MunicipioVota"
                                value={dataCombosVota.dMunicipio}
                                onChange={(e) => ChangeMpioVota(e)}
                                placeholder="Municipio Vota"
                            />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="CalleINE">
                                <span className="text-danger">*</span>Calle
                            </Label>
                            <Input
                                id="CalleINE"
                                value={userData.CalleVota !== null ? userData.CalleVota : ''}
                                type="text"
                                onChange={(e) => setUserData({ ...userData, CalleVota: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-2">
                            <Label for="NumExtINE">
                                <span className="text-danger">*</span>Número Ext.
                            </Label>
                            <Input
                                id="NumExtINE"
                                value={userData.NumExtVota !== null ? userData.NumExtVota : ''}
                                type="text"
                                onChange={(e) => setUserData({ ...userData, NumExtVota: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-2">
                            <Label for="NumIntINE">Número Int.</Label>
                            <Input
                                id="NumIntINE"
                                value={userData.NumIntVota !== null ? userData.NumIntVota : ''}
                                type="text"
                                onChange={(e) => setUserData({ ...userData, NumIntVota: e.target.value })}
                            />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup className="col-md-4">
                            <Label for="LocalidadINE">
                                Localidad:<span className="text-danger">*</span>
                            </Label>
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                options={catLocalidadVotaFilter}
                                value={dataCombosVota.dLocalidad}
                                id="LocalidadINE"
                                onChange={(event) => changeLocalidadVota(event)}
                                placeholder="seleccione su localidad"
                            />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <Label for="ColoniaINE">
                                <span className="text-danger">*</span>Colonia
                            </Label>
                            <Autosuggest
                                suggestions={catColoniasVotaFilter}
                                onSuggestionsFetchRequested={e => setCatColoniaVotaFilter(getColoniaVotaFilter(e.value))}
                                onSuggestionsClearRequested={() => setCatColoniaVotaFilter([])}
                                getSuggestionValue={(item) => item.label}
                                renderSuggestion={(item) => { return (<div>{item.label}</div>) }}
                                onSuggestionSelected={onColoniaVotaSelected}
                                inputProps={inputProps2}
                            />
                        </FormGroup>
                        <FormGroup className="col-md-2">
                            <Label for="CPINE">
                                <span className="text-danger">*</span>CP
                            </Label>
                            <Input
                                id="CPINE"
                                value={userData.CPVota !== null ? userData.CPVota : ''}
                                type="text"
                                maxLength={5}
                                onChange={(event) => { setUserData({ ...userData, CPVota: event.target.value.replace(/\D/, "") }); }}
                            />
                        </FormGroup>
                    </Row>
                </div>
            )}

            <Row>
                <FormGroup className="col-md-12 text-center text-warning">
                    <strong>Datos de Contacto</strong>
                    <br />
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-4">
                    <Label for="Tel">
                        <span className="text-danger">*</span>Teléfono celular
                    </Label>
                    <Input
                        id="Tel"
                        value={userData.Celular}
                        pattern="[0-9]*"
                        maxLength={10}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, Celular: e.target.value })}
                    />
                    <div className="text-danger">
                        <small>{Errors.Celular}</small>
                    </div>
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="Celular">Teléfono fijo</Label>
                    <Input
                        id="Celular"
                        value={userData.Telefono}
                        pattern="[0-9]*"
                        maxLength={10}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, Telefono: e.target.value })}
                    />
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="Celular_1">Teléfono mensajes</Label>
                    <Input
                        id="TelMSG"
                        value={userData.TelMensajes}
                        pattern="[0-9]*"
                        maxLength={10}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, TelMensajes: e.target.value })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-4">
                    <Label for="Facebook">Facebook</Label>
                    <Input
                        id="Facebook"
                        value={userData.Facebook}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, Facebook: e.target.value })}
                    />
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="Instagram">Instagram</Label>
                    <Input
                        id="Instagram"
                        value={userData.Instagram}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, Instagram: e.target.value })}
                    />
                </FormGroup>
                <FormGroup className="col-md-4">
                    <Label for="Twitter">Twitter</Label>
                    <Input
                        id="Twitter"
                        value={userData.Twitter}
                        type="text"
                        onChange={(e) => setUserData({ ...userData, Twitter: e.target.value })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-md-12 pr-2 text-center">
                    <Button
                        color="danger"
                        onClick={() => clearForm()}
                    >
                        Limpiar
                        </Button>{" "}
                    <Button color="primary" onClick={() => save()} disabled={loading}>
                        Guardar
                        </Button>
                </FormGroup>
            </Row>
        </Form >
    )
}

export default CandidatosAdd;