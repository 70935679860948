import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Card,
  CardTitle,
  CardFooter,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Table,
} from 'reactstrap';
import { CircleSpinner } from "react-spinners-kit";
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Notifications, { notify } from 'react-notify-toast';
import Select from 'react-select';

import { RotateSpinner, MetroSpinner } from 'react-spinners-kit';
import { getDataMenu } from 'data/menus';
import EjecutarApi from 'data/getPOST';
import { getVars, URL_PUBLICA } from 'GlobalStorage';
import useWindowDimensions from 'components/useWindowDimensions';
import ListSeccionesComponent from 'components/MovilizacionPromocion/listSeccion';
import ReactBSAlert from "react-bootstrap-sweetalert";

let _Movilizado = new Array();
let checkedArray = new Array();

function MovilizacionPromocion(props) {

  const mainPanel = useRef();
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  const [isMovilizados, setIsMovilizados] = useState(false);
  const [isCompromisos, setIsCompromisos] = useState(false);
  const [seccionesFiltered, setSeccionesFiltered] = useState([]);
  const [seccionesShow, setSeccionesShow] = useState([{label:"Seleccione una hora",value:0}]);
  const [seccionesOpcion, setSeccionesOpcion] = useState([{label:"Seleccione una hora",value:0}]);
  const [seccionesShowFiltered, setSeccionesShowFiltered] = useState([]);
  const [poligonoFiltered, setPoligonoFiltered] = useState([]);
  const [catalogos, setCatalogos] = useState({
    Municipios: [{value:0,label:"Seleccione"}],
    secciones: [{value:0,label:"Seleccione"}],
    poligono: [{value:0,label:"Seleccione"}]
  });
  const [flagCeros, setFlagCero] = useState(false)
  const [flagNules, setFlagNules] = useState(false)
  const [seccionesCero, setSeccionesCero] = useState([])
  const [dataTiempo, setDataTiempo] = useState({label:"Seleccione un polígono",value:0})
  //const [tiempos, setTiempos] = useState([{label:"Seleccione un polígono",value:null}]);
  const [tiempos, setTiempos] = useState([]);
  const [Datos, setDatos] = useState({Municipios:0, Poligono: 0, SeccionVota: 0 })

  useEffect(() => {
    _setmenu(getDataMenu(182, token.menus));
    getCatalogos();
  }, [])

  useEffect(() => {

  }, [dataTiempo, seccionesShowFiltered])

  /*  useEffect(() => {
     if(Datos.Poligono !==0 && Datos.SeccionVota !==0) getMovilizado()
 
   }, [Movilizados]) */

  const getCatalogos = () => {
    setLoading(true);
    EjecutarApi(
      {}, 'getCatalogosFiltrosPromocionClonIdMunicipioReportes', token.access_token)
      .then((res) => {
        setLoading(false);
        if (res.success && res.results) {
          let municipio = [{ label: "Seleccione", value: 0 }];
          let _poligono = [{ label: "Seleccione", value: 0 }];
          let _seccion = [{ label: "Seleccione", value: 0 }];
          setCatalogos({
            ...catalogos,
            Municipios: municipio.concat(res.data.municipios),
            poligono: _poligono.concat(res.data.poligonos),
            secciones: _seccion.concat(res.data.secciones),
          });
          setSeccionesFiltered(_seccion.concat(res.data.secciones));
          setPoligonoFiltered(_poligono.concat(res.data.poligonos));
        } else {
          notify.show(res.message, 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al cargar catalogos: ${e.message} Favor de renovar sesión`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });

  };


  const getSeccinesHora = () => {
    setLoading2(true)
    if (dataTiempo.value !== 0) {
      let Variable = {
        hora: dataTiempo.value !== 0 ? dataTiempo.hora : null,
        Seccion: Datos.SeccionVota.value
      }

      EjecutarApi(Variable, 'movilizacion/getDetalleMovilizadosHora', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success) {
            if (res.results) {
              if (res.data.length > 0) {
                let flags = [];
                res.data.forEach(element => {
                  let fdata = (element.Movilizados === null)? false : true;
                  flags.push(
                    {
                      ...element,
                      flag: fdata,
                      movilizados:element.Movilizados
                    }
                  )
                });
                console.log(flags)
                setSeccionesShow(flags)
                setSeccionesShowFiltered(flags)
                setLoading2(false)
              }
            }
            else {
              let message = "";
              if (res.message === "No se recibió el campo idUser") {
                message = "Renueve Sesión";
              }
              else message = res.message
              notify.show(` ${message}`, 'warning', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
              });
            }
          }
        })
        .catch((e) => {
          notify.show(`Error: ${e.message}`, 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        });
    } else {

    }


  }

  const getSeccineActualizar = (h) => {
    setLoading2(true)
    
    if (h.value !== 0) {
      let Variable = {
        hora: dataTiempo.hora, 
        Seccion: h
      }

      EjecutarApi(Variable, 'movilizacion/getDetalleMovilizadosHora', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success) {
            if (res.results) {
              if (res.data.length > 0) {
                let flags = [];
                res.data.forEach(element => {
                  let fdata = element.Movilizados === null? false : true;
                  flags.push(
                    {
                      ...element,
                      flag: fdata
                    }
                  )
                });
                setSeccionesShow(flags)
                setSeccionesShowFiltered(flags)
                setLoading2(false)
              }
            }
            else {
              let message = "";
              if (res.message === "No se recibió el campo idUser") {
                message = "Renueve Sesión";
              }
              else message = res.message
              notify.show(` ${message}`, 'warning', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
              });
            }
          }
        })
        .catch((e) => {
          notify.show(`Error: ${e.message}`, 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        });
    }

  }


  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const getHoras = (idPoli) => {

       let Variable = {
         idPoligono: idPoli
       }
     EjecutarApi(Variable, 'movilizacion/getHorasDisponiblesMovilizacion', token.access_token)
       .then((res) => {
         setLoading(false);
           let newArray = [];
           res.data.forEach((element,key) => {
             newArray.push(
               {
                 ...element,
                 value:(key+1)
               }
             )
           });
           
           setTiempos(newArray)
       })
       .catch((e) => {
         setLoading(false);
         notify.show(
           `Error al cargar catalogos hora: ${e.message} Favor de renovar sesión`,
           'custom',
           5000,
           {
             background: '#0857B6',
             fontSize: 14,
             text: '#FFFFFF',
           }
         );
       }); 
  }

  const onChangeMunicipio = (idMun) => {
    setIsMovilizados(false)

    if (idMun > 0) {
      let secciones = catalogos.secciones.filter((item) => { return parseInt(item.idMunicipio) === parseInt(idMun) });
      let poligono = catalogos.poligono.filter((item) => { return parseInt(item.idMunicipio) === parseInt(idMun) });
      setPoligonoFiltered(poligono);
      setSeccionesFiltered(secciones)
      setDataTiempo({label:"Seleccione",value:0});
      setSeccionesShowFiltered([]);
    }
    else {
      setPoligonoFiltered(catalogos.poligono);
      setSeccionesFiltered(catalogos.secciones);
      setDataTiempo({label:"Seleccione",value:0});
      setTiempos([{ label: "Seleccione un polígono", value: null }])
      setSeccionesShowFiltered([]);
    }
  }

  async function onChangePoligono(idPoli) {
    setLoading2(true)
    setSeccionesShowFiltered(null)
    let res = await setTimeout(function () { return true }, 1000);
    if (res) {
      //onChangePase(idPoli)
    }
  }


  function onChangePoligonoNormal(idPoli) {

    if (idPoli > 0) {
      getHoras(idPoli)
      let secciones = catalogos.secciones.filter((item) => { return parseInt(item.idPoligono) === parseInt(idPoli) });
      setSeccionesFiltered(secciones)
      setSeccionesShowFiltered([]);
      setDataTiempo({label:"Seleccione",value:0});
      setLoading2(false)

    } else {
      setLoading2(false)
      setSeccionesShowFiltered([]);
      setSeccionesFiltered(catalogos.secciones)
      setTiempos([{ label: "Seleccione un polígono", value: null }])
      setDataTiempo({label:"Seleccione",value:0});
    }
  }

  function onChangeSeccion(seccion) {

    if (seccion > 0) {
      getSeccineActualizar(seccion)
      setSeccionesShowFiltered([]);
      setLoading2(false)

    } else {
      setLoading2(false)
      setSeccionesShowFiltered([]);
    }
  }


  const setvalue = (item, key, value) => {
    setIsCompromisos(false)
    value.split(" ").join("");
    if (value.length !== 0) {
      var arrayC = seccionesShowFiltered;
      if (seccionesShowFiltered.length !== 0) {
        var arrayF = [];
        var flag_paso = false;
        arrayC.forEach(element => {
          if (element.idUser === item) {
            element.Movilizados = parseInt(value);
            flag_paso = true;
          }
        });
        if (!flag_paso) {
          arrayC.push({
            idUser: parseInt(item, 10),
            Movilizados: parseInt(value, 10)
          })
        }
      } else {
        arrayC.push({
          idUser: parseInt(item, 10),
          Movilizados: parseInt(value, 10)
        })
      }

      setSeccionesShowFiltered(arrayC)
    }

    //listado[key].push(arr);
  }
//recuerde acumularlos al siguiente reporte
  const alertaActualizacion = (
    <>
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Tiene secciones con 0 movilizados desea continuar?"
        onConfirm={() => btnCompromisosMovilizado(seccionesCero)}
        onCancel={() => setFlagCero(false)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Si, Guardar!"
        cancelBtnText="Cancel"
        showCancel
      ></ReactBSAlert>
    </>
  );

  const alertaNules = (
    <>
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Hay Secciones sin Movilizados coloque ceros en los que no ha recibido la información!"
        onConfirm={() => setFlagNules(false)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Corregir!"
      ></ReactBSAlert>
    </>
  );

  const verificar = () => {
    console.log(seccionesShowFiltered)
    let arraySeccion = seccionesShowFiltered.filter((item) => item.Movilizados !==null  && item.flag === false);
    let sendArray=[];
    arraySeccion.forEach(item=>{
      sendArray.push({
        idUser:item.idUser,
        movilizados:item.Movilizados
      })
    });
    let arraySeccionnules = seccionesShowFiltered.filter((item) => item.Movilizados===null && item.flag === false);

    let arrayCeros = seccionesShowFiltered.filter((item) => item.Movilizados === 0 && item.flag === false);

    if (arraySeccionnules.length > 0) {

      setFlagNules(true)
    }
    else if (arrayCeros.length > 0) {

      setSeccionesCero(sendArray)
      setFlagCero(true)
    }
    else {
      btnCompromisosMovilizado(sendArray)
    }
  }

  const btnCompromisosMovilizado = (arraySeccion) => {
    setFlagCero(false)
    if (arraySeccion.length > 0) {

      setLoading2(true);
      EjecutarApi({ hora: dataTiempo.hora, Seccion: Datos.SeccionVota.value,usuarios:arraySeccion }, "movilizacion/setDetalleMovilizadosHora", token.access_token)
        .then(function (res) {

          return res;
        })
        .then((res) => {
          if (res.success) {
            if (res.results) {
              notify.show(`Registros actualizados con éxito!!`, "success", 5000, {
                background: "#18c97d",
                fontSize: 24,
                text: "#FFFFFF",
              });
              setSeccionesShow([]);
              setSeccionesShowFiltered([]);
              getSeccinesHora()

            } else {
              setSeccionesShow([]);
              setSeccionesShowFiltered([]);
              getSeccinesHora()
              notify.show(
                `Error al agregar registro: ${res.message}!!`,
                "custom",
                5000,
                {
                  background: "#0857B6",
                  fontSize: 24,
                  text: "#FFFFFF",
                }
              );
              return true;
            }
          }
        })
        .catch((e) => {
          notify.show(`Error al agregar el registro: ${e.error}`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 24,
            text: "#FFFFFF",
          });
        })
        .finally(e => {
          setLoading2(false);
        })
    }
    else {
      notify.show(`No hay Seciones por actualizar!`, "custom", 5000, {
        background: "#0857B6",
        fontSize: 24,
        text: "#FFFFFF",
      });
    }
  }

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {flagNules ? alertaNules : <></>}
          {flagCeros ? alertaActualizacion : <></>}
          {_menu.Ver === 1 ? (
            <>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5 className="title">Captura Movilización</h5>
                    <Col md="12">
                      {loading3 && (
                        <RotateSpinner
                          color={'#1261ff'}
                          size={30}
                          loading={loading3}
                        />
                      )}
                    </Col>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="4" md="4" sm="6">
                        <Card style={{ height: height - 299 }}>
                          <CardBody>
                            <FormGroup>
                              <Label className="labelForm">Municipio</Label>
                              {
                                loading ?
                                
                                  <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                :
                              <Select
                                options={catalogos.Municipios}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={Datos.Municipios}
                                onChange={(e) => {
                                  setDatos({ ...Datos, Municipios: e, Poligono: 0, SeccionVota: 0 });
                                  onChangeMunicipio(e.value)
                                }
                                }
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                isLoading={loading}
                                disabled={loading}
                                loadingMessage="Cargando filtro"
                              />
                                }
                              <div className="errorFields">
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <Label className="labelForm">Polígono</Label>
                              {
                                loading ?
                                
                                  <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                :
                                <Select
                                options={poligonoFiltered}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={Datos.Poligono}
                                onChange={(e) => {
                                  setDatos({ ...Datos, Poligono: e, SeccionVota: [] });
                                  setIsMovilizados(false)

                                  onChangePoligonoNormal(e.value)
                                }}
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                isLoading={loading}
                                loadingMessage="Cargando filtro"
                              />

                              }
                              
                              <div className="errorFields">
                              </div>
                              <FormGroup>
                                <Row>
                                  <Col sm={10}>
                                    <Label className="labelForm">Pase</Label>
                                    {
                                loading ?
                                
                                  <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                :
                                    <Select
                                      options={tiempos}
                                      className="react-select primary"
                                      classNamePrefix="react-select"
                                      value={dataTiempo}
                                      isOptionDisabled={(option) => !option.disponible}
                                      onChange={(e) => {
                                        setDataTiempo(e);
                                        setIsMovilizados(false)
                                      }
                                      }
                                      id="viewTypeM"
                                      placeholder="Filtrar por"
                                      isLoading={loading}
                                      loadingMessage="Cargando filtro"
                                    />
}
                                  </Col>
                                  <Col sm={2}>
                                    <InputGroupAddon addonType="append" style={{ margin: '15pt 1pt -6pt 0pt', zIndex: 0 }}>
                                      <Button
                                        style={{ margin: '0px 1px', zIndex: 0 }}
                                        id="butomRefresh"
                                        color="primary"
                                        disabled={!Datos.Poligono.value}
                                        onClick={e => {
                                          getHoras(Datos.Poligono.value)
                                        }}>
                                        <i className="fa fa-refresh fa-lg" />
                                      </Button>
                                    </InputGroupAddon>
                                  </Col>
                                </Row>
                              </FormGroup>
                              <Label className="labelForm">Sección</Label>
                              {
                                loading ?
                                
                                  <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                :
                              <Select
                                options={(dataTiempo.value !==0)?seccionesFiltered:seccionesOpcion}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={Datos.SeccionVota}
                                onChange={(e) => {
                                  setDatos({ ...Datos, SeccionVota:e});
                                  setIsMovilizados(false)

                                  onChangeSeccion(e.value)
                                }}
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                isLoading={loading}
                                loadingMessage="Cargando filtro"
                              />
                              }
                              <div className="errorFields">
                              </div>
                            </FormGroup>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="8" md="8" sm="8">
                        {
                          <Col md="12">
                            <MetroSpinner
                              color={'#1261ff'}
                              size={40}
                              loading={loading2}
                            />
                          </Col>
                        }
                        {
                          <Card outline color="primary" style={{ height: height - 300 }}>
                            <CardHeader >
                              <Table >
                                <thead>
                                  <tr>
                                    <th className="text-center" style={{ width: "15%" }}>Sección</th>
                                    <th className="text-center" style={{ width: "15%" }}>Responsable</th>
                                    <th className="text-center" style={{ width: "15%" }}>Responsabilidad</th>
                                    <th className="text" style={{ width: "10%" }}>Compromisos Movilizados</th>
                                  </tr>
                                </thead>
                              </Table>
                            </CardHeader>
                            <CardBody height={300}>
                              <Table
                                width="100%"
                                style={{
                                  height: height - 500,
                                  overflow: 'scroll',
                                  display: 'block',
                                }}
                              >
                                {
                                  <ListSeccionesComponent seccionesShow={seccionesShowFiltered} Data={(e) => setvalue(e[0], e[1], e[2])} change={Datos.Poligono !== 0 ? true : false} ></ListSeccionesComponent>

                                }
                              </Table>
                            </CardBody>
                            <CardFooter>
                              <Row>
                                {_menu.Agregar === 1 && (
                                  <Col sm="12" md="12" lg="12" className='text-center text-center'>
                                    <>
                                      <FormGroup className="text-center">
                                        {/*  <Button
                                          style={{ marginTop: '30px' }}
                                          color="danger"
                                          onClick={() => ClearFilter()}
                                          size="sm"
                                        >
                                          Limpiar
                                  </Button> */}{' '}
                                        <Button
                                          style={{ marginTop: '30px' }}
                                          size="ml"
                                          color="primary"
                                          onClick={(e) => verificar()}
                                        //disabled={isDisabled}
                                        >
                                          Enviar
                                  </Button>
                                        {
                                          <Col>
                                            <RotateSpinner
                                              color={'#1261ff'}
                                              size={30}
                                              loading={loading1}
                                            />
                                          </Col>
                                        }
                                      </FormGroup>
                                    </>
                                  </Col>
                                )}
                              </Row>
                            </CardFooter>
                          </Card>
                        } </Col>
                    </Row>
                  </CardBody>

                </Card>
              </Col>
            </>
          ) : (
              <>
                <Label>No tiene permisos para ver</Label>
              </>
            )}
        </div>
        <Footer fluid />
      </div>
    </div>
  );
}

export default MovilizacionPromocion;
