import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getVars, setVars } from 'GlobalStorage';
import EjecutarApi from 'data/getPOST';
import Notifications, { notify } from 'react-notify-toast';

import { Card, CardHeader, Spinner } from 'reactstrap';

import PartidoForm from './PartidosForm';

const PartidosEdit = (props) => {
  const { id, setIdEdit } = props;
  let history = useHistory();
  const token = getVars('Token');
  const [isLoadingGuardado, setIsLoadingGuardado] = useState(false);
  const [isLoadingPartido, setIsLoadingPartido] = useState(false);
  const [partido, setPartido] = useState({});

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const guardarPartido = (partidoJSON) => {
    setIsLoadingGuardado(true);
    partidoJSON.logo = partidoJSON.Logo;
    EjecutarApi(partidoJSON, 'updatePartido', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          notify.show(`${res.message}`, 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
          setIdEdit(0)
        } else {
          notify.show(
            `Error al crear partido: ${res.message} Favor de renovar sesión`,
            'danger',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        }
      })
      .catch((e) => {
        setIsLoadingGuardado(false);
        notify.show(
          `Error al crear partido: ${e.message} Favor de renovar sesión`,
          'danger',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  useEffect(() => {
    setIsLoadingPartido(true);
    const data = {
      id,
    };
    EjecutarApi(data, 'getPartidoById', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setIsLoadingPartido(false);
        if (res.success && res.results) {
          setPartido(res.data);
        } else {
          notify.show(
            `Error al buscar rutas: ${res.message} 'Favor de renovar sesión'
                }`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        }
      })
      .catch((e) => {
        setIsLoadingPartido(false);
        notify.show(
          `Error al buscar rutas: ${e.message} 'Favor de renovar sesión'
          }`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  }, []);

  return (
    // <div className="wrapper">
    //   {' '}
    //   {/* <Sidebar
    //     {...props}
    //     menus={token.menus}
    //     bgColor="black"
    //     activeColor="info"
    //   /> */}
    //   <div className="main-panel">
    //     {/* <Navbar
    //       {...props}
    //       handleMiniClick={handleMiniClick}
    //       menus={token.menus}
    //     /> */}
    //     <Notifications />
    //     <div className="content">
    <Card>
      <CardHeader>
        <h5 className="title">Editar partido</h5>
      </CardHeader>
      {isLoadingPartido && <Spinner color="primary" />}
      {!isLoadingPartido && (
        <PartidoForm
          token={token}
          savePartido={guardarPartido}
          isLoadingGuardado={isLoadingGuardado}
          partidoEdit={partido}
          handleCancelar={() => setIdEdit(0)}
        />
      )}
    </Card>
    //     </div>
    //   </div>
    // </div>
  );
};

export default PartidosEdit;
