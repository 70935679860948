import React, { useState ,useEffect} from 'react';
import { Col,  Button,Modal , Row} from 'reactstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import { notify } from "react-notify-toast";
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA } from "../../GlobalStorage";
import { RotateSpinner } from "react-spinners-kit";
import {getDataMenu} from '../../data/menus.js';

const ListadoCoordinador=({onHide,Datos,Actualizar})=>{
    const [loading, setLoading] = useState(false);
    const [_menu, _setmenu] = useState({});
    const [HasMore,setHasMore]=useState(true);
    const [nextFetch,setnextFetch]=useState(1);
    const [total,setTotal] = useState(0)  
    const token = getVars("Token");
    const [catEstructuras,setCatEstructuras]=useState([]);
    useEffect(()=>{
        handleBuscar();
        _setmenu(getDataMenu(23,token.menus));
    },[])
    const handleBuscar =()=>{
        setLoading(true);
        var parametro={
            EstructuraSocial:Datos.Estructura,
            page:0, 
            pageSize:10,
            Exportar:Datos.Tipo
        };
        
        EjecutarApi(parametro, 'getStatsSocialesDetalle', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
                if (res.results) {
                    if (Datos.Tipo==='Coordinadores')
                    {
                        setCatEstructuras(res.data.coordinadores.map(item => {
                                
                            return {
                                value: item.id,
                                Nombre: item.Nombre,
                                Celular: item.Celular,
                                Estructura: item.nickName
                            
                            }
                            }))
                        setLoading(false)
                        
                    }else if (Datos.Tipo==='Enlaces'){
                        setCatEstructuras(res.data.enlaces.map(item => {
                            return {
                                value: item.idUser,
                                Nombre: item.NombreCompleto,
                                Celular: item.Celular,
                                Estructura: item.nickName
                            }
                            }))
                        setLoading(false)
                    }else if (Datos.Tipo==='Compromisos'){
                        setCatEstructuras(res.data.compromisosUnicos.map(item => {
                            return {
                                value: item.id,
                                Nombre: item.NombreCompleto,
                                Celular: item.Celular ,
                                Estructura: item.NombreEstructura
                            }
                            }))
                        setLoading(false)
                    }  else if (Datos.Tipo==='CTotal'){
                        setCatEstructuras(res.data.total.map(item => {
                            return {
                                id: item.idCompromiso,
                                Nombre: item.NombreCompleto,
                                Celular: item.Celular,
                                Estructura: item.NombreEstructura
                            
                            }
                            }))
                        setLoading(false)
                    } else {
                        showMessage("No Info: catCompromisosWeb")
                        setLoading(false)
                    }   
                }})
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en el listado: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
        
        
    }
    
    const fetchHasMore = () => {
        if (nextFetch * 10 >= total) {
          setHasMore (false);
         return;
        }
        setHasMore(true);
        setnextFetch(nextFetch + 1);
        var parametro={
            page:nextFetch+1, 
            pageSize:10,
            EstructuraSocial:Datos.Estructura
        };
      
        EjecutarApi(parametro, 'getStatsSocialesDetalle', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
                if (res.results) {
                    setTotal(res.data.coordinadores.length)
                    setCatEstructuras(res.data.coordinadores.map(item => {
                            
                        return {
                            value: item.id,
                            Nombre: item.Nombre,
                            Celular: item.Celular
                        
                        }
                        }))
                    setLoading(false)
                    } else {
                        showMessage("No Info: catCompromisosWeb")
                        setLoading(false)
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    };
    const showMessage = (message) => {
        notify.show(message, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
    }
    const Exportar = () => {
        var url = new URL( URL_PUBLICA+
          `getReporteEstructuraSocialDetalle?token=${token.access_token}`
        );
        window.open(url, '_blank');
      };
    
    return(<>
        <Modal
            className="modal-sm"
            modalclassName="modal-primary"
            isOpen={true}
            toggle={onHide}
        >
           
            <div className="modal-body">
                <strong><p className='text-muted text-center'>Listado</p></strong>
                {loading&&(<RotateSpinner color={"#1261ff"} size={30} loading={loading} />)}
                {<InfiniteScroll
                    dataLength={total}
                    next={fetchHasMore}
                    hasMore={HasMore}
                    loader={<h5>cargando...</h5>}
                    height={400}
                    endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>¡Final de la lista de registros!</b>
                                </p>}
                >
                    <Col md="12">
                            
                    </Col>
                    {
                        catEstructuras.map((item,key)=>
                            <div key={'key'+key.toString()} className="divv text-muted" > 
                                {_menu.Editar===1 && Datos.Tipo==='CTotal'?(<>
                                    <Button size='sm'color='primary' className='btn-link' onClick={()=>Actualizar(item)}>
                                        <i className="fa fa-pencil fa-lg" />{item.Nombre}
                                    </Button>
                                    <strong></strong></>)
                                
                                :<strong>{item.Nombre}</strong>}
                                
                                <br/>
                                Celular:{item.Celular}
                                <br/>
                                Estructura:{item.Estructura}
                                <hr />
                            </div>
                        )
                    }
            
                </InfiniteScroll>}
                <Row>
                    <Col>
                        <Button color='primary' className="btn-link">Ir al Listado</Button>
                        
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
            <div className="left-side">
                <Button size='sm'
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={()=>Exportar()}
                >
                    Exportar
                </Button>
                </div>
                <div className="right-side">
                <Button size='sm'
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={()=>onHide()}
                >
                    Cerrar
                </Button>
                </div>
            </div>
        </Modal>
        </>
    )
}
export default ListadoCoordinador;