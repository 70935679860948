import React, { useState, useEffect } from 'react';
// react plugin used to create switch buttons
import Select from 'react-select';
import { Button, Col,Label,Card,CardBody, CardHeader, Row,Collapse,Input  } from 'reactstrap';

import { getVars } from '../../GlobalStorage';

function FiltroDetalleCompromisos(props){


    const [filtro,setFiltro] = useState({
      Nombre:"",
      Celular:"",
    })    

    const [collapse, setCollapse] = useState({
      horizontalTabs: "home",
      verticalTabs: "info",
      pageTabs: "homePages",
      openedCollapses: ["collapseOne", "collapse1"]
      });

    useEffect(() => {

    
    }, []);


    const setFilter = (event) => {
        event.preventDefault();
        const myFiltro = [];
        
        
        if (filtro !== null && filtro.Nombre !== "")
            myFiltro.push({ id: "Nombre", value: filtro.Nombre });
        else
            myFiltro.push({ id: "Nombre", value: filtro.Nombre});
        
        if (filtro.Celular !== null && filtro.Celular !== "")
            {myFiltro.push({ id: 'compromisos.Celular', value: filtro.Celular });}
        else
            myFiltro.push({ id: 'compromisos.Celular', value: filtro.Celular });
            

        //setFiltered(myFiltro);
        props.filtrar(myFiltro);
    };

    const  Limpiar= () => {
        const myFiltro = [];

        setFiltro({
            Nombre:"",
            Celular:"",
          })

          props.limpiar(myFiltro);
        
          
    };

    const collapsesToggle = (colapse) => {
      let openedCollapses = collapse.openedCollapses;
      if (openedCollapses.includes(colapse)) {
        setCollapse({...collapse,
        openedCollapses: openedCollapses.filter(item => item !== colapse)
        });
      } else {
        openedCollapses.push(colapse);
        setCollapse({...collapse, 
        openedCollapses: openedCollapses
        });
      }
      };

    return (
      <Card className="card-plain">
        <CardBody>
        <div
            aria-multiselectable={true}
            className="card-collapse"
            id="accordion"
            role="tablist"
        >
           <Card className="card-plain">
                <CardHeader role="tab">
                    <a
                        aria-expanded={collapse.openedCollapses.includes(
                            "collapse3"
                          )}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle("collapse3")}
                        className="text-left"
                    >
                        <Button
                            className="btn-icon btn-neutral"
                            outline
                            size="sm"
                            id="EditUser"
                        >
                            <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                        </Button>
                        Filtro
                    </a>
                </CardHeader>
                <Collapse
                    role="tabpanel"
                    isOpen={collapse.openedCollapses.includes(
                        "collapse3"
                      )}
                >
          <CardBody>
                <Row>
                <Col sm="4" className="text-muted">Nombre:<Input id="nombre" value={filtro.Nombre} onChange={(e)=>setFiltro({...filtro,Nombre:e.target.value})} /></Col>	
                     
                <Col sm="4" className="text-muted">Celular: <Input id="celular" type="number" value={filtro.Celular} onChange={(e)=>setFiltro({...filtro,Celular:e.target.value})} /></Col>   
                </Row>
                </CardBody>
                <Row>
                <Col sm='2'> 
                    <Button type="button"  className='primary float-right'  color="info" block className="btn-round" type="submit" id="filtrar" onClick={setFilter}>
                    Filtrar
                    </Button>
                </Col>
                <Col sm='2'> 
                    <Button type="button"  className='primary float-right'  color="warning" block className="btn-round" type="submit"  id="limpiar" onClick={Limpiar}>
                    Limpiar
                    </Button>
                </Col>
                
                </Row>
                </Collapse>
                </Card>
                </div>
            </CardBody>
        </Card>
                        
    );
}
export default FiltroDetalleCompromisos;