import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  //ModalFooter,
  Row,
  Col,
  //Badge,
  Input,
  Table,
} from 'reactstrap';

import InfiniteScroll from 'react-infinite-scroll-component';

//import EjecutarApi from '../../../../data/getPOST';
//import { getVars } from '../../../../GlobalStorage';
const ModalRuta = ({ isOpen, handleModal, rgs, ruta, handleAsignRG }) => {
  const [modal, setModal] = useState(isOpen);
  const [listaRgs, setListaRgs] = useState(rgs.data);
  const [searchInput, setSearchInput] = useState('');

  // const [rutaModal, setRutaModal] = useState(
  //   Object.keys(ruta).length !== 0 ? ruta : {}
  // );
  // //const hostserver = "https://api.siangto.com/api/";
  // const hostserver = 'http://127.0.0.1:8000/api/';
  const toggle = () => {
    setModal(!modal);
    handleModal(!modal);
  };

  const style = {
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      maxHeight: '10rem',
      left: '50%',
      padding: '2rem',
      position: 'fixed',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '20rem',
      width: '90%',
      maxWidth: '60rem',
    },
  };


  const handleSearch = (value) => {
    setSearchInput(value);
    const copyList = JSON.parse(JSON.stringify(rgs.data));
    const filteredList = copyList.filter((item) => {
      return item.label.toLowerCase().includes(value.toLowerCase());
    });
    setListaRgs(filteredList);
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        style={style}
        toggle={toggle}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={toggle}>
          Asignar RG - Mostrando "{ruta.NombreRuta}"
        </ModalHeader>
        <ModalBody>
          {!listaRgs && <h2 style={{ textAlign: 'center' }}>No existen RGs</h2>}
          {listaRgs && (
            <>
              <Row>
                <Col sm="6" className="text-muted">
                  <Input
                    placeholder="Filtrar por nombre"
                    value={searchInput}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InfiniteScroll height={400} dataLength={rgs.total}>
                    <Table>
                      <thead>
                        <tr>
                          <th width="60%">Nombre</th>
                          <th width="5%"></th>
                          <th width="35%">Seleccionar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listaRgs.map((rg) => {
                          return (
                            <tr key={rg.value}>
                              <td width="60%">{rg.label}</td>
                              <td></td>
                              <th width="35%">
                                <Button
                                  className="btn btn-primary btn-sm"
                                  color="primary"
                                  size="sm"
                                  onClick={() => handleAsignRG(rg)}
                                >
                                  Seleccionar
                                </Button>
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>

        {/* <ModalHeader toggle={toggle}>Asignar RG - Mostrando "{ruta.NombreRuta}"</ModalHeader>
        <ModalBody>
          <Row>
            <Col
              style={
                {
                  //'height':'300pt',
                  //'width': '200pt',
                }
              }
            >
              <div style={{ padding: '5pt 0pt' }}>
                <p>Nombre de la ruta:</p>
                <Input
                  type="text"
                  id="NombreRuta"
                  value={rutaModal.NombreRuta ? rutaModal.NombreRuta : ''}
                  onChange={(e) => {
                    setRutaModal({ ...rutaModal, NombreRuta: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ padding: '5pt 0pt' }}>
                <p>Clave de la ruta:</p>
                <Input
                  type="text"
                  id="ClaveRuta"
                  value={rutaModal.ClaveRuta ? rutaModal.ClaveRuta : ''}
                  onChange={(e) => {
                    setRutaModal({ ...rutaModal, ClaveRuta: e.target.value });
                  }}
                ></Input>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ padding: '5pt 0pt' }}>
                <p>Comentarios:</p>
                <Input
                  type="textarea"
                  id="Comentarios"
                  value={rutaModal.Comentarios ? rutaModal.Comentarios : ''}
                  onChange={(e) => {
                    setRutaModal({ ...rutaModal, Comentarios: e.target.value });
                  }}
                ></Input>
              </div>
              <div>
                <p>Secciones:</p>
                <div>
                  {rutaModal.Seccion.length === 0 ? (
                    <div>Sin secciones</div>
                  ) : (
                    <div>
                      {rutaModal.Seccion.map((item) => {
                        return (
                          <Badge key={item} color="success" pill>
                            {item}
                          </Badge>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=>{handleSaveRoute(rutaModal)}}>
            Guardar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter> */}
      </Modal>
    </div>
  );
};
export default ModalRuta;
