import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, Progress, Badge } from "reactstrap";

import CasillasResumenChart from "components/Sije/CasillasResumenChart";
import useWindowDimensions from "components/useWindowDimensions";

const AsistenciaResumen = ({ data }) => {
    const { height, width } = useWindowDimensions();
    const [total, setTotal] = useState({
        PaseListaP1: 0,
        PaseListaP2: 0,
        PaseListaS1: 0,
        PaseListaS2: 0,
    });

    const [dataChart, setDataChart] = useState({
        data: [0, 0, 0, 0],
        height: height * 0.15,
    })

    useEffect(() => {
        setTotal(data);
        setDataChart({ ...dataChart, data: [{y:data.PaseListaP1,color:"#43AED2"}, {y:data.PaseListaP2,color:"#092D8E"}, {y:data.PaseListaS1,color:"#4CA2E2"}, {y:data.PaseListaS2,color:"#006BBA"}] });
    }, [data])

    return (
        <Row>
            <Col>
                <Card className="card-stats">
                    <CardHeader style={{ backgroundColor: "#00A0DF" }}>
                        {/* <div className="pull-right text-white" style={{ fontSize: "30px" }}>16</div> */}
                        <h5 className="text-white text-medium margin-none strong"><i className="fa fa-users"></i> Asistencia</h5>
                        {/* <h7 class="text-white">Casillas Incidentadas 2.36%</h7> */}
                        {/* <Progress max={100} animated value={20} className="bg-info-dark" color="white">
                        </Progress> */}
                        {/* <br /> */}
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="8" lg="8" sm="12">
                                <CasillasResumenChart data={dataChart}></CasillasResumenChart>
                            </Col>
                            <Col md="4" lg="4" sm="12">
                                <div className="innerAll">
                                    <ul className="list-unstyled">
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-info"></i> <strong>{total.PaseListaP1}</strong> RCP1</li>
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-success"></i> <strong>{total.PaseListaP2}</strong> RCP2</li>
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square" style={{color:"#4CA2E2"}}></i> <strong>{total.PaseListaS1}</strong> RCS1</li>
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square" style={{color:"#006BBA"}}></i> <strong>{total.PaseListaS2}</strong>RCS2</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default AsistenciaResumen;