import React, { useState,useEffect } from 'react';
import { Row,Col, Input, Button,Modal,ModalHeader,ModalBody, ModalFooter,FormGroup, Label} from 'reactstrap';
import { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';
import Select from "react-select";
import { useHistory } from "react-router-dom";

import EjecutarApi from "../../data/getPOST";
import { getVars} from "../../GlobalStorage";
import {getDataMenu} from '../../data/menus.js';

const AddResponsabilidad = ({props,menu,onHide}) => {
    //const [close, setClose] = useState(true);
    const [loading, setLoading] = useState(false);
    const [MenuR, setMenuR] = useState(menu);
    
    const token = getVars("Token");
    let history = useHistory();
    const [catPromocion,setcatPromocion]=useState([{value:11,label:'No Definido'}]);
    const [catCasilla,setcatCasilla]=useState([{value:0,label:'Debe validar la sección'}]);
    const [catPropietario,setcatPropietario]=useState([{value:0,label:'Debe validar la sección'}]);
    const [catDistrito,setcatDistrito]=useState([{value:0,label:'Debe validar la sección'}]);
    const [catCasaColonia, setCatCasaColonia] = useState([]);
    const [catCasaLocalidad, setCatCasaLocalidad] = useState([]);
    const [catMunicipioCasa, setCatMuinipioCasa] = useState([]);
    const [catPoligonoCasa, setCatPoligonoCasa] = useState([]);
    const [catSeccionCasa, setCatSeccionCasa] = useState([]);
    const [catRegion, setCatRegion] = useState([]);

    const [Distrito,setDistrito]=useState([{value:0,label:'Debe validar la sección'}]);
  
    const [isElectoral,setIsElectoral]=useState({
        RC:false,
        RG:false,
        Abogado:false,
        CasaAzul:false
        
    });
    const [isPromocion,setIsPromocion]=useState({
        Regional:false,
        Municipal:false,
        Poligonal:false,
        Seccional:false,
        Manzanero:false
    })
    const [catPartido,setCatPartido]=useState([]);
    const [Partido,setPartido]=useState([]);
    const [Seccion,setSeccion]=useState('');
    const [Casilla,setCasilla]=useState([]);
    const [Propietario,setPropietario]=useState([]);
    const [catResponsabilidad,setCatResponsabilidad]=useState([
        {label:'RCs',value:1},
        {label:'RGs',value:2},
        {label:'Casa Azul',value:3},
        {label:'Abogado',value:4}
    ]);
    const [idResponsabilidad,setidResponsabilidad]=useState(0); 
    const [SeccionMpio,setSeccionMpio]=useState('');
    const [Promocion,setPromocion]=useState({
        idMunicipio:0,
        idRegion:0,
        idPoligono:0,
        idPoligonoMunicipio:0,
        idSecional:''
    })
   // const [ResPromocion,setResPromocion] = useState('');
    const [Casa,setCasa] = useState({
        Calle: "",
        NumExt: "",
        NumInt: "",
        Colonia: [],
        Latitud: "",
        Longitud: "",
        TieneInter:  false,
        VelocidadInter: "",
        TotalEquipos: "",
        NombreDueno: "",
        CelularDueno: "",
        TelCasa:'',
        Referencia: "",
        idMunicipio: [],
        idPoligono: [],
        Seccion: [],
        Localidad:[]
    
    });
    const [_menu, _setmenu] = useState({});

    useEffect(() => {
        setLoading(true);
        _setmenu(getDataMenu(7,token.menus));
        EjecutarApi({}, 'getCatResponsabilidadAgrupadas', token.access_token)
			.then(function(res) {
				return res;
			})
			.then((res) => {
                if(res.success)
                {
                    setCatResponsabilidad(res.data.Electoral);
                    setcatPromocion(res.data.Promocion);
                    setLoading(false);
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
			});

    }, [])

    const handlePoligoCasaChanged=(e)=>{
        setCasa({...Casa, idPoligono:e});
        var parametro={tipo: "and", filtered: [{id: "s.idPoligono", value: e.value}]}
        EjecutarApi(parametro, 'getSeccionesByPoligono', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
            if (res.results) {
                setCatSeccionCasa(res.data.map((item)=>{
                    return{
                        label:item.Seccion,
                        value:item.Seccion
                    }
                }));
            }
            })
            .catch((e) => {
            
                notify.show(`Error en la búsqueda de Municipio Vive! ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }
    const handleShowPromocion =(e)=>{
        setMenuR({...MenuR, AddResponsabilidad:false,
            AddPromocion:true    });
        //setShowElectoral(false);
        //setShowPromocion(true);


    }
    const llenadoCatalogos=()=>{
        setLoading(true);
        //Partido
        EjecutarApi({"option":"partidos"}, 'catalogos', token.access_token,'GET')
			.then(function(res) {
				return res;
			})
			.then((res) => {
                
                if(res.items)
                {
                    setLoading(false);
                    setCatPartido(res.items.map((item)=>{
                        
                        return{
                            label:item.label,
                            value:item.value
                        }
                    }));
                    
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
            });
        /*var parametroPromocion={};
        var apiPromocion='';
        switch (isPromocion)
        {
            case isPromocion.Regional:
                parametroPromocion={};
                apiPromocion='getNumeraliaRegion';
                break;
            case isPromocion.Municipal:
                parametroPromocion={"region": Promocion.idRegion};
                apiPromocion='getNumeraliaMunicipiosByRegion';
                break;
            case isPromocion.Poligonal:
                parametroPromocion={"region": Promocion.idMunicipio};
                apiPromocion='getNumeraliaPoligonosByMunicipio';
                break;
            case isPromocion.Seccional:
                parametroPromocion={"poligono": Promocion.idPoligono};
                apiPromocion='getNumeraliaSeccionesByPoligono';
                break;
            default: break;
        }*/
    }
    const llenadoCatDistrito=()=>{
        setLoading(true);
        //Partido
        EjecutarApi({}, 'getListadoDF', token.access_token)
        .then(function(res) {
            return res;
        })
        .then((res) => {
            if(res.results)
            {
                setLoading(false);
                setcatDistrito(res.data);
            }
            
        })
        .catch((e) => {
            setLoading(false);
            notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF'
            });
        });
        
    }
    const llenadoCatMunicipio=()=>{
        EjecutarApi({}, "catCompromisosWeb", token.access_token, "GET")
          .then(function (res) {
            return res;
          })
          .then((items) => {
            if (items.results) {
              
              setCatMuinipioCasa(
                items.data.Municipio.map((item) => {
                  return {
                    value: item["value"],
                    label: item["label"],
                  };
                })
              );  
             
              setLoading(false);
            } else {
              notify.show("No Info: catCompromisosWeb", "custom", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
              });
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            notify.show("Error: catCompromisosWeb", "custom", 5000, {
              background: "#0857B6",
              fontSize: 14,
              text: "#FFFFFF",
            });
          });
    }
    const llenadoCatRegion=()=>{
        EjecutarApi({option:'region'}, "catalogos", token.access_token, "GET")
          .then(function (res) {
            return res;
          })
          .then((items) => {
            if (items.status) {
              setCatRegion(
                items.items.map((item) => {
                  return {
                    value: item["value"],
                    label: item["label"],
                  };
                })
              );  
              setLoading(false);
            } else {
              notify.show("No Info: catCompromisosWeb", "custom", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
              });
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            notify.show("Error: catCompromisosWeb", "custom", 5000, {
              background: "#0857B6",
              fontSize: 14,
              text: "#FFFFFF",
            });
          });
    }
    const btnValidaSeccion=()=>{
        EjecutarApi({"seccion":Seccion}, 'validarSeccion', token.access_token)
			.then(function(res) {
				return res;
			})
			.then((res) => {
                if(res.results)
                {
                    setLoading(false);
                    notify.show(`Sección válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    //getCasillasBySeccion 
                    EjecutarApi({"seccion":Seccion}, 'getCasillasBySeccion', token.access_token)
                    .then(function(res) {
                    
                        return res;
                    })
                    .then((res) => {
                        if(res.success)
                        {
                            setcatCasilla(res.data);
                            setLoading(false);
                        }
                        
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                            background: '#0857B6',
                            fontSize: 14,
                            text: '#FFFFFF'
                        });
                    });
                    //getCasillaPropietario 
                    EjecutarApi({"seccion":Seccion}, 'getCasillaPropietario', token.access_token)
                    .then(function(res) {
                        return res;
                    })
                    .then((res) => {
                        if(res.success)
                        {
                            setcatPropietario(res.data);
                            setLoading(false);
                        }
                        
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                            background: '#0857B6',
                            fontSize: 14,
                            text: '#FFFFFF'
                        });
                    });
                }
                else
                {
                    notify.show(`Sección No válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    setLoading(false);
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
            });
          
    }
    const btnValidarSeccion=()=>{
        EjecutarApi({"seccion":Promocion.idSecional}, 'validarSeccion', token.access_token)
			.then(function(res) {
				return res;
			})
			.then((res) => {
                if(res.results)
                {
                    setLoading(false);
                    setSeccionMpio(res.data.map((item)=>{
                        return item.MunicipioReporte;
                    }))
                    notify.show(`Sección válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    
                }
                else
                {
                    notify.show(`Sección No válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    setLoading(false);
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
            });
          
    }
    const handleShowElectoral =()=>{
        setMenuR({...MenuR, AddResponsabilidad:true,
            AddPromocion:false    });
       
    }
    const handleSetElectoral = (e) =>{
        setidResponsabilidad(e.value);
        switch(e.label)
        {
            case'RGs':
            setIsElectoral({RC:false,
                RG:true,
                Abogado:false,
                CasaAzul:false,
                Municipio:false});
                break;
            case 'RCs':
                setIsElectoral({RC:true,
                    RG:false,
                    Abogado:false,
                    CasaAzul:false,
                    Municipio:false});
                    llenadoCatalogos();
                break;
            case 'Abogado':
                setIsElectoral({RC:false,
                    RG:false,
                    Abogado:true,
                    CasaAzul:false,
                    Municipio:false});
                
                break;
            case 'CasaAzul':
                setIsElectoral({RC:false,
                    RG:false,
                    Abogado:false,
                    CasaAzul:true,
                    Municipio:false});
                break;   
            case 'Municipio':
                setIsElectoral({RC:false,
                    RG:false,
                    Abogado:false,
                    CasaAzul:false,
                    Municipio:true});
                break;   
            case 'Regional':
                setIsPromocion({Regional:true,
                    Municipal:false,
                    Poligonal:false,
                    Seccional:false,
                    Manzanero:false});
                llenadoCatRegion();
                break;  
            case 'Municipal':
                setIsPromocion({Regional:false,
                    Municipal:true,
                    Poligonal:false,
                    Seccional:false,
                    Manzanero:false});
                llenadoCatMunicipio();
                break;   
            case 'Poligonal':
                setIsPromocion({Regional:false,
                    Municipal:false,
                    Poligonal:true,
                    Seccional:false,
                    Manzanero:false});
                    llenadoCatMunicipio();
            break; 
            case 'Seccional':
                setIsPromocion({Regional:false,
                    Municipal:false,
                    Poligonal:false,
                    Seccional:true,
                    Manzanero:false
                });
            break;  
            case 'Manzanera':
                setIsPromocion({Regional:false,
                    Municipal:false,
                    Poligonal:false,
                    Seccional:false,
                    Manzanero:true
                });
            break;                      
            default:
                setIsElectoral({RC:false,
                    RG:false,
                    Abogado:false,
                    CasaAzul:false,
                    Municipio:false});
                break;
        }
    }
    const saveResponsabilidad=()=>{
        var opcionTerritorial=false;
        var parametro={};
        var Api = {};
        var data ="";
        if (menu.idCompromiso)
        {
            if(isElectoral.RC){
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 2,
                    Seccion: Seccion,
                    Casilla: Casilla.label,
                    Propietario: Propietario.value,
                    idPartido:Partido.value,

                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                .then(function (res) {
                    return res;
                  })
                .then((res) => {
                if (res.results) {
                        notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                            background: "#18c97d",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                        
                        setLoading(false);
                        
                    } else {
                        setLoading(false);
                        notify.show(
                            `Error al crear el registro: ${res.message}!!`,
                            "custom",
                            5000,
                            {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                            }
                        );
                    }
                    return true;
                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 24,
                    text: "#FFFFFF",
                    });
                });
            }
            if(isElectoral.RG){
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 3,
                    idDistrito:Distrito.value

                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                .then(function (res) {
                    return res;
                  })
                .then((res) => {
                if (res.results) {
                        notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                            background: "#18c97d",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                        
                        setLoading(false);
                        
                    } else {
                        setLoading(false);
                        notify.show(
                            `Error al crear el registro: ${res.message}!!`,
                            "custom",
                            5000,
                            {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                            }
                        );
                    }
                    return true;
                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 24,
                    text: "#FFFFFF",
                    });
                });
            }
            if(isElectoral.Abogado)
            {
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 8
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                .then(function (res) {
                    return res;
                  })
                .then((res) => {
                if (res.results) {
                        notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                            background: "#18c97d",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                        
                        setLoading(false);
                        
                    } else {
                        setLoading(false);
                        notify.show(
                            `Error al crear el registro: ${res.message}!!`,
                            "custom",
                            5000,
                            {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                            }
                        );
                    }
                    return true;
                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 24,
                    text: "#FFFFFF",
                    });
                });
            }
            if(isElectoral.CasaAzul)
            {
                data = {
                    idCompromiso: menu.idCompromiso,
                    idResponsabilidad: 10,
                    Calle: Casa.Calle,
                    NumExt:Casa.NumExt,
                    NumInt: Casa.NumInt,
                    Colonia: Casa.Colonia.label,
                    Latitud: Casa.Latitud,
                    Longitud: Casa.Longitud,
                    TelCasa: Casa.TelCasa,
                    TieneInter:  Casa.TieneInter,
                    VelocidadInter: Casa.VelocidadInter,
                    TotalEquipos: Casa.TotalEquipos,
                    NombreDueno: Casa.NombreDueno,
                    CelularDueno: Casa.CelularDueno,
                    Referencia: Casa.Referencia,
                    idMunicipio: Casa.idMunicipio.value,
                    idPoligono: Casa.idPoligono.value,
                    Seccion: Casa.Seccion.value

                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                .then(function (res) {
                    return res;
                  })
                .then((res) => {
                if (res.results) {
                        notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                            background: "#18c97d",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                        
                        setLoading(false);
                        
                    } else {
                        setLoading(false);
                        notify.show(
                            `Error al crear el registro: ${res.message}!!`,
                            "custom",
                            5000,
                            {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                            }
                        );
                    }
                    return true;
                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 24,
                    text: "#FFFFFF",
                    });
                });
            }
            if(isPromocion.Regional)
            {
                parametro={
                   
                    idCompromiso:MenuR.idCompromiso,
                    idResponsabilidad:idResponsabilidad,
                    valor:Promocion.idRegion
                };
                Api='setResponsabilidadPropuestaPromocion';
                opcionTerritorial=true;
            }
            if(isPromocion.Municipal)
            {
                parametro={
                    idCompromiso:MenuR.idCompromiso,
                    idResponsabilidad:idResponsabilidad,
                    valor:Promocion.idMunicipio
                };
                Api='setResponsabilidadPropuestaPromocion';
                opcionTerritorial=true;
            }
            if(isPromocion.Poligonal)
            {
                parametro={
                    idCompromiso:MenuR.idCompromiso,
                    idResponsabilidad:idResponsabilidad,
                    valor:Promocion.idPoligono,
                };
                Api='setResponsabilidadPropuestaPromocion';
                opcionTerritorial=true;
            }
            if(isPromocion.Seccional)
            {
                parametro={
                    idCompromiso:MenuR.idCompromiso,
                    idResponsabilidad:idResponsabilidad,
                    valor:Promocion.idSecional,
                };
                Api='setResponsabilidadPropuestaPromocion';
                opcionTerritorial=true;
            }
            if(isPromocion.Manzanero)
            {
                parametro={
                    idCompromiso:MenuR.idCompromiso,
                    idResponsabilidad:Promocion.idResponsabilidad,
                    valor:Promocion.Manzanero,
                };
                Api='setResponsabilidadPropuestaPromocion';
                opcionTerritorial=true;
            }
        }
        
        
        if(opcionTerritorial)
        {
            EjecutarApi(parametro, Api, token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    notify.show('El registro fue almanacenado!!', 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    onHide();
                }
                else{
                    notify.show(`El usuario ya tiene una responsabilidad asignada!!`, "error", 5000, {
                        background: "#d61818",
                        text: "#FFFFFF",
                    });
                }
            })
            .catch((e) => {
                notify.show(`Error usuario ya tiene una propuesta asignada!!!`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
                onHide();
            });
        }
    }
    
    const handleResponsabilidad=(e)=>{
        if (menu.idCompromiso !== 0)
        {
            switch(e.label)
            {
                case 'RCs':
                    //setShowPromocion(true);
                    setIsElectoral({...isElectoral,
                        RC:true,
                        RG:false,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false});
                    llenadoCatalogos();
                    break;
                case 'RGs':
                    setIsElectoral({RC:false,
                        RG:true,
                        Abogado:false,
                        CasaAzul:false,
                        Municipio:false});
                    llenadoCatDistrito();
                    break;
                case 'Abogados':
                        setIsElectoral({RC:false,
                            RG:false,
                            Abogado:true,
                            CasaAzul:false,
                            Municipio:false});
                       
                        break;
                case 'Casa Azul':
                    setIsElectoral({RC:false,
                        RG:false,
                        Abogado:false,
                        CasaAzul:true,
                        Municipio:false});
                    llenadoCatMunicipio();
                break;
                default: break;
            }
        }
        else
        switch(e.label)
        {
            case 'RCs':
                history.push("/EPropuestaRCs");
                break;
                case 'RGs':
                    history.push("/EPropuestaRGs");
                    break;
                case 'Abogado':
                    history.push("/EPropuestaA"); 
                        break;
                case 'Casa Azul':
                history.push("/EPropuestaCA");     
                break;
                case 'Abogados':
                history.push("/EPropuestaA");     
                break;
                default: break;
        }

        if (isPromocion.Municipal)
        {

        }
    }
    const handleMpioCasaChanged=(e)=>{
        setCasa({...Casa, idMunicipio:e});
        var parametro= {"filtered":[{"id":"cat_poligonos.idMunicipio","value":e.value}]}
        EjecutarApi(parametro, 'getPoligonosByMunicipio', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
            if (res.results) {
                setCatPoligonoCasa(res.data.map((item)=>{
                    return{
                        label:item.Poligono,
                        value:item.id
                    }
                }));
            }
            })
            .catch((e) => {
            
            notify.show(`Error en la búsqueda de Municipio Vive! ${e.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
            });
       
        EjecutarApi({Municipio: e.label}, `getLocalidad`, token.access_token, "POST")
            .then(function (res) {
                return res;
            })
            .then((res) => {
            if (res.result) {
            
                setCatCasaLocalidad(res.data.localidades);
                setCatCasaColonia(res.data.colonias);
            }
            })
            .catch((e) => {
            
            notify.show(`Error en la búsqueda de Municipio Vive! ${e.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
            });
    }
    const LlenadoPoligonoByMpio=(e)=>{
        //idPoligonoMunicipio
        setPromocion({...Promocion, idPoligonoMunicipio:e.value});
        var parametro= {"filtered":[{"id":"cat_poligonos.idMunicipio","value":e.value}]}
        EjecutarApi(parametro, `getPoligonosByMunicipio`, token.access_token, "POST")
        .then(function (res) {
            return res;
        })
        .then((res) => {
        if (res.results) {
            setCatPoligonoCasa(res.data.map((item)=>{
                return{
                    label:item.Poligono,
                    value:item.id
                }
            }));
        }
        })
        .catch((e) => {
        notify.show(`Error en la búsqueda del Poligono! ${e.message}`, "error", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
        });
        });
    }
    /*const AgregarRPromocion =()=>{
        switch (ResPromocion)
        {
            case 'Regional':
                history.push("/NvoRegional");
                break;
            case 'Municipal':
                history.push("/NvoMunicipal");
                break;
            case 'Poligonal':
                    break;
            case 'Seccional':
                break;
        }
    }*/

    const handleRegion=(e)=>{
        setPromocion({...Promocion, idRegion:e.value})
        if(menu.idCompromiso===0)
        {
            //setMenuR({...MenuR, NvoPromocion:true,NvoPromocion:e.label });
            //setResPromocion('Regional');
        }
    }
    /*const handleMunicipal=(e)=>{
        setPromocion({...Promocion, idRegion:e.value})
        if(menu.idCompromiso===0)
        {
            //setMenuR({...MenuR, NvoPromocion:true,NvoPromocion:e.label });
            setResPromocion('Municipal');
        }
    }*/
    return(
            <Modal
                isOpen={true}
                toggle={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
            <ModalHeader toggle={onHide} >
                <Label><h6>Agregar Propuestas de Responsabilidad</h6></Label>
                <h7 className='text-muted'>{menu.Compromiso?menu.Compromiso:''}</h7>
            </ModalHeader>
            <ModalBody>
                {loading && <MetroSpinner size={30} color="#0000FF" loading={loading} />}
                {MenuR.menu?<Row md='12'>
                    <Col md="12" className="center text-center">
                        Tipo de Responsabilidad
                        <FormGroup sm="12">
                            <div className="styleRadiodiv">
                                    <label className="styleRadio">
                                        <input
                                            defaultValue="1"
                                            name="radiodomicilio"
                                            type="radio"
                                            onChange={()=>{handleShowElectoral()}}
                                         />  {' '}Electoral {' '}
                                    </label>
                                <label className="styleRadio">
                                    <input
                                        defaultValue="2"
                                        name="radiodomicilio"
                                        type="radio"
                                        onChange={(e)=>{handleShowPromocion()}}
                                        />{' '} Promoción{' '}
                                    </label>
                            </div>
                        </FormGroup>
                    </Col>
                    
                </Row>
                :<></>
                    
                }
                {MenuR.AddResponsabilidad?<>
                    <Col sm="12">
                        Responsabilidad:
                        <Select 
                            className="react-select primary"
                            classNamePrefix="react-select"
                            id="idRespon"                                                        
                            placeholder="Seleccione una Responsabilidad..."
                            options={catResponsabilidad}
                            onChange = {(e) => handleResponsabilidad(e)}
                        />
                    </Col>
                </>:<>
                
                </>}
                {MenuR.AddPromocion?<Row>
                    <Col md="8">
                        
                        <FormGroup>
                            Función Promoción:
                            <Select 
                                className="react-select primary"
                                classNamePrefix="react-select"
                                id="idPromocion"                                                        
                                placeholder="Seleccione..."
                                options={catPromocion}
                                //value = {Electoral}
                                onChange = {(e) => {handleSetElectoral(e);}}
                            >
                            </Select>
                        </FormGroup> 
                    </Col>
                </Row>:<></>}
                
                {isElectoral.RC?<>    
                    <Row>
                        <Col md="6">
                            <FormGroup sm="6">
                                Partido 
                                <Select className="react-select primary"
                                classNamePrefix="react-select"
                                id="partido"                                                        
                                placeholder="Seleccione..."
                                options={catPartido}
                                onChange = {(e) => setPartido(e)}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup sm="6">
                                Sección:
                                <Input id="seccion" value={Seccion} onChange={(e) =>{setSeccion(e.target.value)}} ></Input>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup sm="6">
                                <Button id="btnSeccion" color='primary' className='primary' onClick={btnValidaSeccion} >Validar</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup sm="6">
                                Casilla:
                                <Select className="react-select primary"
                                classNamePrefix="react-select"
                                id="casilla"                                                        
                                placeholder="Seleccione..."
                                options={catCasilla}
                                onChange = {(e) => setCasilla(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup sm="6">
                                Propietario:
                                <Select className="react-select primary"
                                classNamePrefix="react-select"
                                id="propietario"                                                        
                                placeholder="Seleccione..."
                                options={catPropietario}
                                onChange = {(e) => setPropietario(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </>:<></>}
                {isElectoral.RG?<>    
                    <Row>
                        <Col md="12">
                             <FormGroup sm="6">
                                                Distrito Federal: 
                                                <Select className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="partido"                                                        
                                                placeholder="Seleccione..."
                                                options={catDistrito}
                                                onChange = {(e) => setDistrito(e)}
                                            />
                                            </FormGroup>
                        </Col>
                    </Row>
                </>:<></>}
                {isElectoral.CasaAzul?<>    
                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                Municipio:
                                                <Select options={catMunicipioCasa}
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        value={Casa.idMunicipio}
                                                        onChange={(e) =>handleMpioCasaChanged(e) }
                                                        id="selecMpiocasa"
                                                        placeholder="Municipio"/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                Poligono:
                                                <Select options={catPoligonoCasa}
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        value={Casa.idPoligono}
                                                        onChange={(e) => handlePoligoCasaChanged(e) }
                                                        id="selecPolcasa"
                                                        placeholder="Poligono"/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                Sección:
                                                <Select options={catSeccionCasa}
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        value={Casa.Seccion}
                                                        onChange={(e) => setCasa({...Casa, Seccion:e}) }
                                                        id="seccioncasa"
                                                        placeholder="Sección"/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                Calle:
                                                <Input id="casaCalle" value={Casa.Calle} onChange={(e)=>setCasa({...Casa, Calle:e.target.value})} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup>
                                                Número Exterior:
                                                <Input id="casaExterior" value={Casa.NunExt} onChange={(e)=> setCasa({...Casa, NumExt:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup>
                                                Número Interior:
                                                <Input id="casainterio" value={Casa.NumInt} onChange={(e)=>setCasa({...Casa, NumInt:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <FormGroup>
                                                Localidad:
                                                <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        options={catCasaLocalidad}
                                                        id="casaLocalidad"
                                                        onChange={(event) => {setCasa({...Casa,Localidad:event})}}
                                                        placeholder="seleccione su localidad"
                                                    />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                Colonia:
                                                <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        options={catCasaColonia}
                                                        id="casaColonia"
                                                        onChange={(event) => setCasa({...Casa,Colonia:event})}
                                                        placeholder="seleccione su colonia"
                                                    />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                Referencia:
                                                <Input id="casaRef" value={Casa.Referencia} onChange={(e)=> setCasa({...Casa, Referencia:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        
                                        <Col sm="4">
                                            <FormGroup>
                                                Nombre del Dueño:
                                                <Input id="casanombredueno" value={Casa.NombreDueno} onChange={(e)=> setCasa({...Casa, NombreDueno:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                Teléfono casa:
                                                <Input id="casateld" value={Casa.TelCasa} onChange={(e)=> setCasa({...Casa, TelCasa:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                Celular Dueño:
                                                <Input id="casaCeldeuno" value={Casa.CelularDueno} onChange={(e)=> setCasa({...Casa, CelularDueno:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col sm="2">
                                            <FormGroup>
                                                Longitud:
                                                <Input id="casaLong" value={Casa.Longitud} onChange={(e)=> setCasa({...Casa, Longitud:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="2">
                                            <FormGroup>
                                                Latitud:
                                                <Input id="casaLat" value={Casa.Latitud} onChange={(e)=> setCasa({...Casa, Latitud:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                <Button className="btn-link" color="default">
                                                    <i class="fa fa-wifi" aria-hidden="true"></i>Internet
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                Velocidad Internet:
                                                <Input id="casaVelocidad" value={Casa.VelocidadInter} onChange={(e)=> setCasa({...Casa, VelocidadInter:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup>
                                                Total Equipos:
                                                <Input id="casaRef" value={Casa.TotalEquipos} onChange={(e)=> setCasa({...Casa, TotalEquipos:e.target.value})}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                       
                                        
                                        
                                    </Row>
                </>:<></>}
                {isPromocion.Municipal?<>    
                    <Row>
                        <Col md="12">
                            <FormGroup sm="6">
                                Municipio
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="idPMunicipio"                                                        
                                    placeholder="Seleccione un Municipio..."
                                    options={catMunicipioCasa}
                                    onChange = {(e) => setPromocion({...Promocion, idMunicipio:e.value})}
                                ></Select>
                            </FormGroup>
                        </Col>
                    </Row>
                </>:<></>}
                {isPromocion.Regional?<>    
                    <Row>
                        <Col md="12">
                            <FormGroup sm="6">
                                Región:
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="idPRegion"                                                        
                                    placeholder="Seleccione una Region..."
                                    options={catRegion}
                                    onChange = {(e) => handleRegion(e)}
                                ></Select>
                            </FormGroup>
                        </Col>
                    </Row>
                </>:<></>}
                {isPromocion.Poligonal?<>    
                    <Row>
                        <Col md="12">
                        <FormGroup sm="6">
                                Municipio
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="idPoligonoMunicipio"                                                        
                                    placeholder="Seleccione un Municipio..."
                                    options={catMunicipioCasa}
                                    onChange = {(e) => LlenadoPoligonoByMpio(e)}
                                ></Select>
                            </FormGroup>
                        </Col>
                    </Row>                                       
                    <Row>
                        <Col md="12">
                            <FormGroup sm="6">
                                Poligono: 
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="idPPoligono"                                                        
                                    placeholder="Seleccione un Poligono..."
                                    options={catPoligonoCasa}
                                    onChange = {(e) => setPromocion({...Promocion, idPoligono:e.value})}
                                ></Select>
                            </FormGroup>
                        </Col>
                    </Row>
                </>:<></>}
                {isPromocion.Seccional?<>    
                    <Row>
                        <Col md="4">
                            <FormGroup sm="6">
                                Sección:
                                <Input id='Pseccion' value={Promocion.idSecional} onChange={(e) => setPromocion({...Promocion, idSecional:e.target.value})} />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup sm="6">
                                
                                <Button id='bPseccion' onClick={(e) => btnValidarSeccion(e)} color='warning' className='warning' >
                                    <i className="nc-icon nc-zoom-split" />Validar
                                </Button>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup sm="6">
                                Municipio de la Sección:
                                <Input id='MPseccion' value={SeccionMpio} disabled/>
                            </FormGroup>
                        </Col>
                    </Row>
                
                </>:<></>}
                {isPromocion.Manzanero?<>    
                    <Row>
                        <Col md="12">
                            <FormGroup sm="6">
                                Manzanero En Construcción....
                               
                            </FormGroup>
                        </Col>
                    </Row>
                    
              </>:<></>}
             
                
            </ModalBody>
            <ModalFooter>
             
                    <Button color='primary' className='primary' size='sm' onClick={(e)=> saveResponsabilidad(e)} >Guardar</Button>
             
            </ModalFooter>
        </Modal>
        
    );
};
export default AddResponsabilidad;