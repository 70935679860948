import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardHeader, Card } from 'reactstrap';
import { notify } from "react-notify-toast";
import ReactTable from 'react-table';
import EjecutarApi from "../../data/getPOST";
import { getVars, URL_PUBLICA } from "../../GlobalStorage";
import Pagination from '../Pagination/pagination';
import { URL_GENERAL } from "../../data/Consts";
import FiltroDetalle from 'components/Estructura/FiltroResponsables';
import { getDataMenu } from 'data/menus.js';

const ListadoResponsables = ({ props, onHide }) => {
  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
  const [compromisos, setCompromisos] = useState([]);
  const [totalCompromisos, setTotalCompromisos] = useState(0);
  const [filtro, setFiltro] = useState([]);
  const [nombre, setNombre] = useState("");
  const [menu, setearMenu] = useState({});
  var titulo = props.value.toUpperCase() + " " + props.label;


  useEffect(() => {
    setearMenu(getDataMenu(132, token.menus));
  }, []);

  const Exportar = () => {


    const data = {
      page: 0,
      pageSize: 10,
      tipo: 'and',
      tabla: props.zona,
      filtered: filtro,
      filtered: filtro ? filtro : [],
      Region: props.zona === "region" ? props.id : 0,
      Municipio: props.zona === "municipio" ? props.id : 0,
      Poligono: props.zona === "poligono" ? props.id : 0,
      Seccion: props.zona === "seccion" ? props.id : 0,
      Manzana: props.zona === "manzana" ? props.id : 0,
      Nombre: nombre ? nombre : " ",
      Export: true
    };

    var datos = JSON.stringify(data)
    var url = new URL(
        `${URL_GENERAL}exportAvanceCompromisosResponsable?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");
  };



  const setFilter = (filtered) => {

    var sorted = [];
 
    if (filtered.length !== 0) {

      var nombre = filtered[0]['id'] === "Nombre" ? (filtered[0]['value']) : "";

      if (filtered[1]['id'] === "Celular" && filtered[1]['value'] !== "") {
        filtro.push(filtered[1])
      }

      setFiltro(filtro)
      setNombre(nombre)
    }


    const data = {
      page: 0,
      pageSize: 10,
      sorted: sorted,
      tipo: 'and',
      tabla: props.zona,
      filtered: filtro,
      filtered: filtro ? filtro : [],
      Region: props.zona == "region" ? props.id : 0,
      Municipio: props.zona === "municipio" ? props.id : 0,
      Poligono: props.zona === "poligono" ? props.id : 0,
      Seccion: props.zona === "seccion" ? props.id : 0,
      Manzana: props.zona === "manzana" ? props.id : 0,
      Nombre: nombre ? nombre : " ",
    };

    EjecutarApi(
      data,
      'getAvanceCompromisosResponsable',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (res.data) {
            setLoading(false);
            setTotalCompromisos(res.total)
            setCompromisos(
              res.data.map((item) => {
                return {
                  Nombre: item.Responsable,
                  Celular: item.ResponsableCel ? item.ResponsableCel : 'Sin Celular',
                  ResponsabilidadData: item.ResponsabilidadData ? item.ResponsabilidadData : 'Sin Responsabilidad',
                  Voluntarios: item.Voluntarios ? item.Voluntarios : 0,
                  Compromisos: item.Compromisos ? item.Compromisos : 0
                };
              })
            );
          }
        }
      })
      .catch((e) => {

        setLoading(false);
        notify.show(
          `Error: ${e.error}`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });

  }

  const Limpiar = (filtered) => {
    setFilter(filtered);
  }

  return (
    <>
      <Card>
        <Col md="12">
          <CardHeader>
            <Row>
              <Col md="12" >
                <Button size="sm" color='primary' onClick={() => onHide()}>Regresar</Button>
                {menu.Exportar === 1 && (<Button size="sm" color='success' onClick={() => Exportar()}>Exportar</Button>)}
              </Col>

            </Row>
            <Row>
              <Col md='12' className="text-center" >
                <h4>Listado Responsables <br></br>
                  {titulo}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <FiltroDetalle
                  filtrar={setFilter} limpiar={Limpiar} />
              </Col>
            </Row>
          </CardHeader>
          <Row>
            <Col sm="12" >
              <div style={{ width: '100%', overflow: 'auto' }}>
                <ReactTable
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  name="compromisos"
                  data={compromisos}
                  noDataText="Sin información"
                  manual
                  columns={[
                    {
                      Header: 'Nombre',
                      minWidth: 110,
                      id: 'Nombre',
                      accessor: (d) => d.Nombre,
                      filterAll: false,
                    },
                    {
                      Header: 'Celular',
                      minWidth: 50,
                      id: 'Celular',
                      show: props.responsibilityID === 99 ? true : false,
                      accessor: (d) => d.Celular,
                      filterAll: false,
                    },
                    {
                      Header: 'Compromisos',
                      minWidth: 30,
                      id: 'Compromisos',
                      accessor: (d) => d.Compromisos,
                      filterAll: false,
                    },
                    {
                      Header: 'Voluntarios',
                      minWidth: 30,
                      id: 'Voluntarios',
                      accessor: (d) => d.Voluntarios,
                      filterAll: false,
                    }
                  ]}
                  className="-striped -highlight primary-pagination"
                  loading={loading}
                  showPagination={true}
                  showPaginationBottom={true}
                  showTotalPages={true}
                  showPageJump={false}
                  canNextFromData={true}
                  defaultPageSize={10}
                  total={totalCompromisos}
                  pages={Math.ceil(totalCompromisos / 10)}
                  previousText="Anterior"
                  nextText="Siguiente"
                  ofText="de"
                  pageSizeOptions={[10, 20, 25, 50, 100]}
                  PaginationComponent={Pagination}
                  onFetchData={(stateComp) => {
                    setLoading(true);
                    var parametro = {
                      tabla: props.zona,
                      filtered: filtro ? filtro : [],
                      Region: props.zona === "region" ? props.id : 0,
                      Municipio: props.zona === "municipio" ? props.id : 0,
                      Poligono: props.zona === "poligono" ? props.id : 0,
                      Seccion: props.zona === "seccion" ? props.id : 0,
                      Manzana: props.zona === "manzana" ? props.id : 0,
                      Nombre: nombre ? nombre : " ",
                      page: stateComp.page,
                      pageSize: stateComp.pageSize,
                      tipo: "and"
                    }
                    EjecutarApi(
                      parametro,
                      'getAvanceCompromisosResponsable',
                      token.access_token
                    )
                      .then(function (res) {
                        return res;
                      })
                      .then((res) => {
                        if (res.results) {
                          if (res.data) {
                            setLoading(false);
                            setTotalCompromisos(res.total)
                            setCompromisos(
                              res.data.map((item) => {
                                return {
                                  Nombre: item.Responsable,
                                  Celular: item.ResponsableCel ? item.ResponsableCel : 'Sin Celular',
                                  ResponsabilidadData: item.ResponsabilidadData ? item.ResponsabilidadData : 'Sin Responsabilidad',
                                  Voluntarios: item.Voluntarios ? item.Voluntarios : 0,
                                  Compromisos: item.Compromisos ? item.Compromisos : 0
                                };
                              })
                            );
                          }
                        }
                      })
                      .catch((e) => {

                        setLoading(false);
                        notify.show(
                          `Error: ${e.error}`,
                          'custom',
                          5000,
                          {
                            background: '#0857B6',
                            fontSize: 14,
                            text: '#FFFFFF',
                          }
                        );
                      });
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Card>
    </>
  );
};
export default ListadoResponsables;