import React, { useState, useEffect } from "react";
import { Map, TileLayer, Popup, Marker, GeoJSON } from 'react-leaflet';
import L, { layerGroup } from 'leaflet';
import { MagicSpinner } from "react-spinners-kit";
import MarkerClusterGroup from "react-leaflet-markercluster";
import 'react-leaflet-markercluster/dist/styles.min.css';

import Notifications from 'react-notify-toast';
import { CardBody, Card, CardHeader, Row,Button, ButtonGroup } from "reactstrap";
import PluginFilterMap from "./PluginFilterMapAvanceElectoral.jsx";
import {SinPermisos} from "../../ComponentesAuxiliares/SinPermisos";
import {centerOnOneShape} from "../../ComponentesAuxiliares/AuxiliarFunctions";
let style_square_superior_left = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
    //'width':'200px'
}

let style_square_superior_left_tipo_avance = {
    'padding': '6px 8px',
    'font': '12px/14px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
    'position': 'absolute',
    'left': '270pt'
    //'top': '57pt'
}
let style_square_superior_left_tipo_avance_rcs = {
    'padding': '6px 8px',
    'font': '12px/14px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
    'position': 'absolute',
    'left': '270pt',
    //'top': '122pt'
    'top': '65pt'
}

let style_b = {
    'color': '#000000',
}
let style_button_radiogroup = {
    'padding': '2px 4px',
    'font': '10px/12px ',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
}
let style_square_superior = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
}

const SimpleMap = ({ center,handleTipoAvance,handleTipoRCAvance, _derecho_tipo_rcs,TipoAvanceRC,TipoAvance,zoom, derecho, derecho_tipo_avance, data,_flagFinish,handleFiltro,MenuPermisos, handleTipoMapa, Shape, TipoMapa,handleDetalleMapa, Responsabilidad }) => {


    const [_zoom, setZoom] = useState(zoom ? zoom : 5);
    const [_center, setCenter] = useState(center ? center : [20.816157, -101.021312]);
    const puntos = data;
    //const [resumen] = useState([]);
    //const [leyendaResumen] = useState('GENERAL');
    const _data = Shape ? Shape : null;
    const flag = Object.keys(_data).length === 0 ? false : true;
    //const DataFiltroGeoJson 
    const [tipoMapa, setTipoMapa] = useState({ value: TipoMapa.value, label: TipoMapa.label});
    const [tipoAvance, setTipoAvance] = useState(TipoAvance);
    const [tipoRCAvance, setTipoRCAvance] = useState(TipoAvanceRC);
    
    const flagFinish = _flagFinish;
    const _derecho_mapa = derecho;
    const _derecho_tipo_avance = derecho_tipo_avance;
    const _tipo = TipoMapa.value ? TipoMapa.value : 'seccion';
    const [buttonDetalle, setButonDetalle] = useState(false);
    const [flagData] = useState(true);
    const [prop, setProp] = useState(
        TipoMapa.value === 'municipio' ?
            { 'nom_mun': '', 'cve_mun': '' }
            : TipoMapa.value === 'region' ?
                { 'region': '' } : TipoMapa.value === 'seccion' ? { 'seccion': '' } : { 'poligono': '' }
    );
    function roundTo(value, places) {
        var power = Math.pow(10, places);
        return Math.round(value * power) / power;
    }
    let style_spinner = {
        'height': '500pt',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center'
    }
    const green_icon = new L.Icon({

        iconUrl: require('./green_icon.png'),
        iconSize: [40, 45], // tamaño del icono
        //shadowSize:   [50, 64], // tamaño de la sombra
        iconAnchor: [20, 31] // punto del icono que corresponde a la posición del marcador
        //popupAnchor:  [0, -40] // punto relativo al marcador desde donde se deberá abrir el popup
    });
    const red_icon = new L.Icon({

        iconUrl: require('./red_icon.png'),
        iconSize: [40, 45], // tamaño del icono
        //shadowSize:   [50, 64], // tamaño de la sombra
        iconAnchor: [20, 31] // punto del icono que corresponde a la posición del marcador
        //popupAnchor:  [0, -40] // punto relativo al marcador desde donde se deberá abrir el popup
    });
    useEffect(() => {
        setProp({});
        setButonDetalle(false);
        setTipoMapa(TipoMapa);
        setTipoAvance(TipoAvance);
        setTipoRCAvance(TipoAvanceRC);
        if (Object.keys(_data).length !== 0) {
            if(_data.features.length !== 0){
                const {zoom, center} = centerOnOneShape(flagData,_data);
                setCenter(center);
                setZoom(zoom);
            }
        }

    }, [_flagFinish,data,Shape,TipoMapa,TipoAvance,TipoAvanceRC])

    /* FUNCIONES DEL CHOROPETH MAP*/
    function style(feature, flag) {
        let style;
        if (flag === undefined || flag !== true) {
            if(TipoAvance === 'RCs'){
                style = {
                    fillColor: 
                    TipoAvanceRC === 'P1'? feature.properties.color_p1:
                    TipoAvanceRC === 'P2'? feature.properties.color_p2:
                    TipoAvanceRC === 'S1'? feature.properties.color_s1:
                    TipoAvanceRC === 'S2'? feature.properties.color_s2:
                    feature.properties.color_p1,
                    weight: 2,
                    opacity: 1,
                    color: 'white',
                    dashArray: '3',
                    fillOpacity: 0.3
                };
            }
            else if(TipoAvance === 'PRIMERO FILA'){
                style = {
                    fillColor: 
                    feature.properties.color_pf,
                    weight: 2,
                    opacity: 1,
                    color: 'white',
                    dashArray: '3',
                    fillOpacity: 0.3
                };
            }
            else{
                style = {
                    fillColor: 
                    TipoAvance === 'RGs'? feature.properties.color_rgs:
                    TipoAvance === 'ABOGADOS'? feature.properties.color_abogados:
                    TipoAvance === 'OBSERVADORES'? feature.properties.color_observadores:
                    TipoAvance === 'CASA AZUL'? feature.properties.color_cuarteles:
                    feature.properties.color_rgs,
                    weight: 2,
                    opacity: 1,
                    color: 'white',
                    dashArray: '3',
                    fillOpacity: 0.3
                };
            }
            
        }
        else {
            style = {
                weight: 5,
                color: '#666',
                dashArray: '',
                fillOpacity: 0.5
            };

        }
        return style;
    }

    

    const highlightFeature = (e) => {
        //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.

        var layer = e.target;
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
        var text;
        if(tipoAvance === 'RCs' || tipoAvance === 'PRIMERO FILA' ){
            if(layer.feature.properties.region !== undefined){
                text = "REGIÓN " + layer.feature.properties.region;
                setProp({
                    'region': e.target.feature.properties.region,
                    'avance_p1': e.target.feature.properties.avance_p1,
                    'avance_p2': e.target.feature.properties.avance_p2,
                    'avance_s1': e.target.feature.properties.avance_s1,
                    'avance_s2': e.target.feature.properties.avance_s2,
                    'avance_pf': e.target.feature.properties.avance_pf,
                    'color_p1': e.target.feature.properties.color_p1,
                    'color_p2': e.target.feature.properties.color_p2,
                    'color_s1': e.target.feature.properties.color_s1,
                    'color_s2': e.target.feature.properties.color_s2,
                    'color_pf': e.target.feature.properties.color_pf,
                    'total_sp1': e.target.feature.properties.totalcasillas_sin_propietario1,
                    'total_cp1': e.target.feature.properties.totalcasillas_con_propietario1,
                    'total_sp2': e.target.feature.properties.totalcasillas_sin_propietario2,
                    'total_cp2': e.target.feature.properties.totalcasillas_con_propietario2,
                    'total_ss1': e.target.feature.properties.totalcasillas_sin_suplente1,
                    'total_cs1': e.target.feature.properties.totalcasillas_con_suplente1,
                    'total_ss2': e.target.feature.properties.totalcasillas_sin_suplente2,
                    'total_cs2': e.target.feature.properties.totalcasillas_con_suplente2,
                    'total_spf': e.target.feature.properties.totalcasillas_sin_primerofila,
                    'total_cpf': e.target.feature.properties.totalcasillas_con_primerofila,
                    'total': e.target.feature.properties.totalcasillas,
                    'lnom': e.target.feature.properties.lnom,
                    'prop_save': layer
                });
            }
            else if(layer.feature.properties.nom_mun !== undefined){
                text = "" + layer.feature.properties.nom_mun;
                setProp({
                    'nom_mun': e.target.feature.properties.nom_mun,
                    'cve_mun': e.target.feature.properties.cve_mun,
                    'avance_p1': e.target.feature.properties.avance_p1,
                    'avance_p2': e.target.feature.properties.avance_p2,
                    'avance_s1': e.target.feature.properties.avance_s1,
                    'avance_s2': e.target.feature.properties.avance_s2,
                    'avance_pf': e.target.feature.properties.avance_pf,
                    'color_p1': e.target.feature.properties.color_p1,
                    'color_p2': e.target.feature.properties.color_p2,
                    'color_s1': e.target.feature.properties.color_s1,
                    'color_s2': e.target.feature.properties.color_s2,
                    'color_pf': e.target.feature.properties.color_pf,
                    'total_sp1': e.target.feature.properties.totalcasillas_sin_propietario1,
                    'total_cp1': e.target.feature.properties.totalcasillas_con_propietario1,
                    'total_sp2': e.target.feature.properties.totalcasillas_sin_propietario2,
                    'total_cp2': e.target.feature.properties.totalcasillas_con_propietario2,
                    'total_ss1': e.target.feature.properties.totalcasillas_sin_suplente1,
                    'total_cs1': e.target.feature.properties.totalcasillas_con_suplente1,
                    'total_ss2': e.target.feature.properties.totalcasillas_sin_suplente2,
                    'total_cs2': e.target.feature.properties.totalcasillas_con_suplente2,
                    'total_spf': e.target.feature.properties.totalcasillas_sin_primerofila,
                    'total_cpf': e.target.feature.properties.totalcasillas_con_primerofila,
                    'total': e.target.feature.properties.totalcasillas,
                    'lnom': e.target.feature.properties.lnom,
                    'prop_save': layer
                });
            }
            else{
                text = "SECCIÓN " + layer.feature.properties.seccion;
                setProp({
                    'seccion': e.target.feature.properties.seccion,
                    'avance_p1': e.target.feature.properties.avance_p1,
                    'avance_p2': e.target.feature.properties.avance_p2,
                    'avance_s1': e.target.feature.properties.avance_s1,
                    'avance_s2': e.target.feature.properties.avance_s2,
                    'avance_pf': e.target.feature.properties.avance_pf,
                    'color_p1': e.target.feature.properties.color_p1,
                    'color_p2': e.target.feature.properties.color_p2,
                    'color_s1': e.target.feature.properties.color_s1,
                    'color_s2': e.target.feature.properties.color_s2,
                    'color_pf': e.target.feature.properties.color_pf,
                    'total_sp1': e.target.feature.properties.totalcasillas_sin_propietario1,
                    'total_cp1': e.target.feature.properties.totalcasillas_con_propietario1,
                    'total_sp2': e.target.feature.properties.totalcasillas_sin_propietario2,
                    'total_cp2': e.target.feature.properties.totalcasillas_con_propietario2,
                    'total_ss1': e.target.feature.properties.totalcasillas_sin_suplente1,
                    'total_cs1': e.target.feature.properties.totalcasillas_con_suplente1,
                    'total_ss2': e.target.feature.properties.totalcasillas_sin_suplente2,
                    'total_cs2': e.target.feature.properties.totalcasillas_con_suplente2,
                    'total_spf': e.target.feature.properties.totalcasillas_sin_primerofila,
                    'total_cpf': e.target.feature.properties.totalcasillas_con_primerofila,
                    'total': e.target.feature.properties.totalcasillas,
                    'lnom': e.target.feature.properties.lnom,
                    'prop_save': layer
                });
            }
        }
        else{
            if(layer.feature.properties.region !== undefined){
                text = "REGIÓN " + layer.feature.properties.region;
                setProp({
                    'region': e.target.feature.properties.region,
                    'avance_rgs': e.target.feature.properties.avance_rgs,
                    'avance_abogados': e.target.feature.properties.avance_abogados,
                    'avance_observadores': e.target.feature.properties.avance_observadores,
                    'avance_cuarteles': e.target.feature.properties.avance_cuarteles,
                    'color_rgs': e.target.feature.properties.color_rgs,
                    'color_abogados': e.target.feature.properties.color_abogados,
                    'color_observadores': e.target.feature.properties.color_observadores,
                    'color_cuarteles': e.target.feature.properties.color_cuarteles,
                    'meta_total_rgs': e.target.feature.properties.meta_total_rgs,
                    'meta_total_abogados': e.target.feature.properties.meta_total_abogados,
                    'meta_total_observadores': e.target.feature.properties.meta_total_observadores,
                    'meta_total_cuarteles': e.target.feature.properties.meta_total_cuarteles,
                    'avance_porcentual_rgs': e.target.feature.properties.avance_porcentual_rgs,
                    'avance_porcentual_abogados': e.target.feature.properties.avance_porcentual_abogados,
                    'avance_porcentual_observadores': e.target.feature.properties.avance_porcentual_observadores,
                    'avance_porcentual_cuarteles': e.target.feature.properties.avance_porcentual_cuarteles,
                    'meta_hoy_rgs': e.target.feature.properties.meta_hoy_rgs,
                    'meta_hoy_abogados': e.target.feature.properties.meta_hoy_abogados,
                    'meta_hoy_observadores': e.target.feature.properties.meta_hoy_observadores,
                    'meta_hoy_cuarteles': e.target.feature.properties.meta_hoy_cuarteles,
                    'lnom': e.target.feature.properties.lnom,
                    'prop_save': layer
                });
            }
            else if(layer.feature.properties.nom_mun !== undefined){
                text = "" + layer.feature.properties.nom_mun;
                setProp({
                    'nom_mun': e.target.feature.properties.nom_mun,
                    'cve_mun': e.target.feature.properties.cve_mun,
                    'avance_rgs': e.target.feature.properties.avance_rgs,
                    'avance_abogados': e.target.feature.properties.avance_abogados,
                    'avance_observadores': e.target.feature.properties.avance_observadores,
                    'avance_cuarteles': e.target.feature.properties.avance_cuarteles,
                    'color_rgs': e.target.feature.properties.color_rgs,
                    'color_abogados': e.target.feature.properties.color_abogados,
                    'color_observadores': e.target.feature.properties.color_observadores,
                    'color_cuarteles': e.target.feature.properties.color_cuarteles,
                    'meta_total_rgs': e.target.feature.properties.meta_total_rgs,
                    'meta_total_abogados': e.target.feature.properties.meta_total_abogados,
                    'meta_total_observadores': e.target.feature.properties.meta_total_observadores,
                    'meta_total_cuarteles': e.target.feature.properties.meta_total_cuarteles,
                    'avance_porcentual_rgs': e.target.feature.properties.avance_porcentual_rgs,
                    'avance_porcentual_abogados': e.target.feature.properties.avance_porcentual_abogados,
                    'avance_porcentual_observadores': e.target.feature.properties.avance_porcentual_observadores,
                    'avance_porcentual_cuarteles': e.target.feature.properties.avance_porcentual_cuarteles,
                    'meta_hoy_rgs': e.target.feature.properties.meta_hoy_rgs,
                    'meta_hoy_abogados': e.target.feature.properties.meta_hoy_abogados,
                    'meta_hoy_observadores': e.target.feature.properties.meta_hoy_observadores,
                    'meta_hoy_cuarteles': e.target.feature.properties.meta_hoy_cuarteles,
                    'lnom': e.target.feature.properties.lnom,
                    'prop_save': layer
                });
            }
            else{
                text = "SECCIÓN " + layer.feature.properties.seccion;
                setProp({
                    'seccion': e.target.feature.properties.seccion,
                    'avance_rgs': e.target.feature.properties.avance_rgs,
                    'avance_abogados': e.target.feature.properties.avance_abogados,
                    'avance_observadores': e.target.feature.properties.avance_observadores,
                    'avance_cuarteles': e.target.feature.properties.avance_cuarteles,
                    'color_rgs': e.target.feature.properties.color_rgs,
                    'color_abogados': e.target.feature.properties.color_abogados,
                    'color_observadores': e.target.feature.properties.color_observadores,
                    'color_cuarteles': e.target.feature.properties.color_cuarteles,
                    'meta_total_rgs': e.target.feature.properties.meta_total_rgs,
                    'meta_total_abogados': e.target.feature.properties.meta_total_abogados,
                    'meta_total_observadores': e.target.feature.properties.meta_total_observadores,
                    'meta_total_cuarteles': e.target.feature.properties.meta_total_cuarteles,
                    'avance_porcentual_rgs': e.target.feature.properties.avance_porcentual_rgs,
                    'avance_porcentual_abogados': e.target.feature.properties.avance_porcentual_abogados,
                    'avance_porcentual_observadores': e.target.feature.properties.avance_porcentual_observadores,
                    'avance_porcentual_cuarteles': e.target.feature.properties.avance_porcentual_cuarteles,
                    'meta_hoy_rgs': e.target.feature.properties.meta_hoy_rgs,
                    'meta_hoy_abogados': e.target.feature.properties.meta_hoy_abogados,
                    'meta_hoy_observadores': e.target.feature.properties.meta_hoy_observadores,
                    'meta_hoy_cuarteles': e.target.feature.properties.meta_hoy_cuarteles,
                    'lnom': e.target.feature.properties.lnom,
                    'prop_save': layer
                });
            }
        }
        
        
        layer.bindTooltip(text + "", { permanent: false, direction: "center" });
        layer.setStyle(style(layer.feature, true));
    }

    const clickFeature = (e) => {
        if(tipoMapa.value !== 'seccion'){
            setButonDetalle(true);
        }
        highlightFeature(e);
       
        //Aqui configuramos lo que queremos que haga un feature cuando le den click.
        
    }

    const clickDetalle = (e) => {
        setButonDetalle(false);
        if (e.latlng) {
            setCenter([e.latlng.lat, e.latlng.lng]);
        }


        var siguiente_nivel = "region";
        var siguiente_nivel_label = "Región";
        var siguiente_nivel_clave = "";
        if (tipoMapa.value === "region") {
            //setZoom(_zoom + 1);
            siguiente_nivel = "municipio";
            siguiente_nivel_label = "Municipio";
            siguiente_nivel_clave = e.feature.properties.region;
        }
        if (tipoMapa.value === "municipio") {
            //setZoom(_zoom + 1);
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Sección";
            siguiente_nivel_clave = e.feature.properties.cve_mun;
        }
        if (tipoMapa.value === "seccion") {
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Sección";
            //siguiente_nivel = "manzana";
            //siguiente_nivel_label = "Manzanas";
            //siguiente_nivel_clave = e.target.feature.properties.seccion; 
            return; //CLICK NO PROGRAMADO
        }
        handleDetalleMapa({ value: siguiente_nivel, label: siguiente_nivel_label, clave: siguiente_nivel_clave });
        setTipoMapa({ value: siguiente_nivel, label: siguiente_nivel_label });
    }

    const resetHighlight = (e) => {
        //Con esto reseteamos el color en donde paso el mouse, para que no siga marcado.
        var layer = e.target;
        layer.setStyle(style(e.target.feature));
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
    }

    const onEachFeature = (feature, layer) => {
        //Organizamos los eventos del GeoJson.
        layer.on({
            //mouseout: resetHighlight,
            click: clickFeature
        });
        var text;
        if(layer.feature.properties.region !== undefined){
            text = "REGIÓN " + layer.feature.properties.region;
        }
        else if(layer.feature.properties.nom_mun !== undefined){
            text = "" + layer.feature.properties.nom_mun;
        }
        else{
            text = "SECCIÓN " + layer.feature.properties.seccion;
        }
        
        layer.bindTooltip(text + "", { permanent: false, direction: "center" });
    }

    var geojson = (
        <GeoJSON onEachFeature={onEachFeature} style={style} key={"geojson"} data={_data}></GeoJSON>
    )
    /* FUNCIONES DEL CHOROPETH MAP*/
    const setFilter = (filtered) => {
        handleFiltro(filtered,_tipo);
    }
    const miTipoMapaChange = (e) => {
        if (e.value !== 'poligono' && e.value !== 'seccion') {
            setCenter([20.917639, -101.091132]);
            //setZoom(9);
        }
        setButonDetalle(false);
        setProp({});
        setTipoMapa(e);
        handleTipoMapa(e);
    }
    const miTipoAvanceChange = (e) => {
        setButonDetalle(false);
        setProp({});
        setTipoAvance(e);
        handleTipoAvance(e);
    }
    const miTipoRCAvanceChange = (e) => {
        setButonDetalle(false);
        setProp({});
        setTipoRCAvance(e);
        handleTipoRCAvance(e);
    }
    

    return (
        <>
            <Notifications />
            {
                flagFinish && flag ?
                MenuPermisos.Ver === 1?
                <>
                    <Map
                        className="markercluster-map"
                        center={_center}
                        zoom={_zoom}
                        style={{ width: "100%", height: "90vh" }}>
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MarkerClusterGroup
                        >
                            {puntos.map((dato, key) => {

                                if (dato.Latitude !== null && dato.Longitude !== null && dato.Latitude.length > 5 && dato.Longitude.length > 5) {

                                    return (
                                        <Marker
                                            icon={dato.TieneActas === 1 ? green_icon : red_icon}
                                            style={{
                                                'background-color': '#1c9489 !important'
                                            }} 
                                            key={"Marker_" + dato.data.Seccion + "" + key} position={[dato.Latitude.replace(',', ''), dato.Longitude.replace(',', '')]}>{/* [dato.Latitude,dato.Longitude]}> */}
                                            <Popup>
                                                <Card>
                                                    <CardHeader>

                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <strong>Casilla: </strong>{dato.Casilla}
                                                        </Row>
                                                        <Row>
                                                            <strong>Tipo Casilla: </strong>{dato.TipoCasilla}
                                                        </Row>
                                                        <Row>
                                                            <strong>Tiene Actas: </strong>{dato.TieneActas === 1? "Si": "No"}
                                                        </Row>
                                                        <Row>
                                                            <strong>Propietario 1: </strong>{dato.idRCPropietario1 !== null && dato.idRCPropietario1 !== undefined ? dato.RCPropietario1: "No Asignado"}
                                                        </Row>
                                                        <Row>
                                                            <strong>Propietario 2: </strong>{dato.idRCPropietario2 !== null && dato.idRCPropietario2 !== undefined ? dato.RCPropietario2: "No Asignado"}
                                                        </Row>
                                                        <Row>
                                                            <strong>Suplente 1: </strong>{dato.idRCSuplente1 !== null && dato.idRCSuplente1 !== undefined ? dato.idRCSuplente1: "No Asignado"}
                                                        </Row>
                                                        <Row>
                                                            <strong>Suplente 2: </strong>{dato.idRCSuplente2 !== null && dato.idRCSuplente2 !== undefined ? dato.idRCSuplente2: "No Asignado"}
                                                        </Row>
                                                        <Row>
                                                            <strong>LNOM: </strong>{dato.LNOM}
                                                        </Row>
                                                        <Row>
                                                            <strong>Localida y Manzana: </strong>{dato.LocalidadManzana}
                                                        </Row>
                                                        <Row>
                                                            <strong>Seccion: </strong>{dato.Seccion}
                                                        </Row>
                                                        <Row>
                                                            <strong>Tipo Seccion: </strong>{dato.TipoSeccion}
                                                        </Row>
                                                        <Row>
                                                            <strong>Domicilio: </strong>{dato.Domicilio}
                                                        </Row>
                                                        <Row>
                                                            <strong>Tipo Domicilio: </strong>{dato.TipoDomicilio}
                                                        </Row>
                                                        <Row>
                                                            <strong>Municipio: </strong>{dato.Municipio}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Popup>
                                        </Marker>

                                    )
                                }
                            })
                            }
                        </MarkerClusterGroup>
                        {
                            geojson
                        }

                        {/* <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-left">
                                <div className="leaflet-control" style={style_square_superior_left}>


                                    <ButtonGroup style={{ backgroundColor: '#FFF' }}>
                                        {_derecho_mapa.includes(4) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'region' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "region", label: "Región" })}
                                            >
                                                Región
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(3) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'municipio' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "municipio", label: "Municipio" })}
                                            >
                                                Municipio
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(2) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'poligono' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "poligono", label: "Poligonal" })}
                                            >
                                                Polígono
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(1) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'seccion' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "seccion", label: "Sección" })}
                                            >
                                                Sección
                                            </Button>
                                            :
                                            <></>}
                                    </ButtonGroup>

                                </div>
                            </div>
                        </div> */}
                        
                        {/* <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-left">
                                <div className="leaflet-control" style={style_square_superior_left_tipo_avance}>


                                    <ButtonGroup style={{ backgroundColor: '#FFF' }}>
                                        {_derecho_tipo_avance.includes(1) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoAvance === 'RCs' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoAvanceChange('RCs')}
                                            >
                                                RCs
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_tipo_avance.includes(2) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoAvance === 'RGs' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoAvanceChange('RGs')}
                                            >
                                                RGs
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_tipo_avance.includes(3) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoAvance === 'ABOGADOS'? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoAvanceChange('ABOGADOS')}
                                            >
                                                ABOGADOS
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_tipo_avance.includes(4) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoAvance === 'OBSERVADORES' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoAvanceChange('OBSERVADORES')}
                                            >
                                                OBSERVADORES
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_tipo_avance.includes(5) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoAvance === 'CASA AZUL' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoAvanceChange('CASA AZUL')}
                                            >
                                                {"CASA AZUL"}
                                            </Button>
                                            :
                                            <></>}
                                            {_derecho_tipo_avance.includes(6) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoAvance === 'PRIMERO FILA' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoAvanceChange('PRIMERO FILA')}
                                            >
                                                {"PRIMERO FILA"}
                                            </Button>
                                            :
                                            <></>}
                                            
                                    </ButtonGroup>

                                </div>
                            </div>
                        </div>
                        {
                            (tipoAvance === 'RCs')?
                            <div className="leaflet-control-container">
                                <div className="leaflet-top leaflet-left">
                                    <div className="leaflet-control" style={style_square_superior_left_tipo_avance_rcs}>


                                        <ButtonGroup style={{ backgroundColor: '#FFF' }}>
                                            {_derecho_tipo_rcs.includes(1) ?
                                                <Button
                                                    className="btn-round"
                                                    color={tipoRCAvance === 'P1' ? 'success' : 'info'}
                                                    outline
                                                    type="button"
                                                    onClick={() => miTipoRCAvanceChange('P1')}
                                                >
                                                    P1
                                                </Button>
                                                :
                                                <></>}
                                            {_derecho_tipo_rcs.includes(2) ?
                                                <Button
                                                    className="btn-round"
                                                    color={tipoRCAvance === 'P2' ? 'success' : 'info'}
                                                    outline
                                                    type="button"
                                                    onClick={() => miTipoRCAvanceChange('P2')}
                                                >
                                                    P2
                                                </Button>
                                                :
                                                <></>}
                                            {_derecho_tipo_rcs.includes(3) ?
                                                <Button
                                                    className="btn-round"
                                                    color={tipoRCAvance === 'S1'? 'success' : 'info'}
                                                    outline
                                                    type="button"
                                                    onClick={() => miTipoRCAvanceChange('S1')}
                                                >
                                                    S1
                                                </Button>
                                                :
                                                <></>}
                                            {_derecho_tipo_rcs.includes(4) ?
                                                <Button
                                                    className="btn-round"
                                                    color={tipoRCAvance === 'S2' ? 'success' : 'info'}
                                                    outline
                                                    type="button"
                                                    onClick={() => miTipoRCAvanceChange('S2')}
                                                >
                                                    S2
                                                </Button>
                                                :
                                                <></>}
                                                
                                        </ButtonGroup>

                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                        } */}
                        

                        <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-right">
                                <div className="leaflet-control" style={style_square_superior}>
                                { 
                                (prop.prop_save !== undefined) ?
                                    (TipoAvance === 'RCs') ?
                                    <>
                                        <b style={style_b}>{TipoMapa.label}: </b>
                                            <b style={{ color: (TipoAvanceRC === 'P1') ? prop.color_p1: (TipoAvanceRC === 'P2') ? prop.color_p2 : (TipoAvanceRC === 'S1') ? prop.color_s1: prop.color_s2 }}>
                                                {_tipo === 'region'? prop.region : _tipo === 'municipio'? prop.nom_mun : _tipo === 'poligono'? prop.pg_poligon : prop.seccion}</b><br></br>
                                        <b style={style_b}>LNOM:</b>
                                            <b style={{ color: (TipoAvanceRC === 'P1') ? prop.color_p1: (TipoAvanceRC === 'P2') ? prop.color_p2 : (TipoAvanceRC === 'S1') ? prop.color_s1: prop.color_s2 }}>
                                                    {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                        <b style={style_b}>Total de Casillas:</b>
                                            <b style={{ color: (TipoAvanceRC === 'P1') ? prop.color_p1: (TipoAvanceRC === 'P2') ? prop.color_p2 : (TipoAvanceRC === 'S1') ? prop.color_s1: prop.color_s2 }}>
                                                    {new Intl.NumberFormat('en-EN').format(prop.total)}</b><br></br>
                                        <b style={style_b}>Total de Casillas con {TipoAvanceRC}:</b>
                                            <b style={{ color: (TipoAvanceRC === 'P1') ? prop.color_p1: (TipoAvanceRC === 'P2') ? prop.color_p2 : (TipoAvanceRC === 'S1') ? prop.color_s1: prop.color_s2 }}>
                                                    {new Intl.NumberFormat('en-EN').format((TipoAvanceRC === 'P1') ?prop.total_cp1:(TipoAvanceRC === 'P2') ?prop.total_cp2:(TipoAvanceRC === 'S1') ?prop.total_cs1:(TipoAvanceRC === 'S2') ?prop.total_cs2:prop.total_cp1)}</b><br></br>
                                        <b style={style_b}>Total de Casillas sin {TipoAvanceRC}:</b>
                                            <b style={{ color: (TipoAvanceRC === 'P1') ? prop.color_p1: (TipoAvanceRC === 'P2') ? prop.color_p2 : (TipoAvanceRC === 'S1') ? prop.color_s1: prop.color_s2 }}>
                                                    {new Intl.NumberFormat('en-EN').format((TipoAvanceRC === 'P1') ?prop.total_sp1:(TipoAvanceRC === 'P2') ?prop.total_sp2:(TipoAvanceRC === 'S1') ?prop.total_ss1:(TipoAvanceRC === 'S2') ?prop.total_ss2:prop.total_sp1)}</b><br></br>
                                        <b style={style_b}>Porcentaje de Avance {TipoAvanceRC}:</b>
                                            <b style={{ color: (TipoAvanceRC === 'P1') ? prop.color_p1: (TipoAvanceRC === 'P2') ? prop.color_p2 : (TipoAvanceRC === 'S1') ? prop.color_s1: prop.color_s2 }}>
                                                    {
                                                        //AQUI FALTA CODIGO PARA TODOS LOS P1,P2,S1,S2
                                                        prop.avance_p1 === 0 && prop.total === 0 ? "--" : roundTo((TipoAvanceRC === 'P1') ?prop.avance_p1:(TipoAvanceRC === 'P2') ?prop.avance_p2:(TipoAvanceRC === 'S1') ?prop.avance_s1:(TipoAvanceRC === 'S2') ?prop.avance_s2:prop.avance_p1, 2) + "%"}</b><br></br>
                                    </>
                                    :
                                        (TipoAvance === 'PRIMERO FILA') ?
                                        <>
                                            <b style={style_b}>{TipoMapa.label}: </b>
                                                <b style={{ color: prop.color_pf }}>
                                                    {_tipo === 'region'? prop.region : _tipo === 'municipio'? prop.nom_mun : _tipo === 'poligono'? prop.pg_poligon : prop.seccion}</b><br></br>
                                            <b style={style_b}>LNOM:</b>
                                                <b style={{ color: prop.color_pf }}>
                                                        {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                            <b style={style_b}>Total de Casillas:</b>
                                                <b style={{ color: prop.color_pf }}>
                                                        {new Intl.NumberFormat('en-EN').format(prop.total)}</b><br></br>
                                            <b style={style_b}>Total de Casillas con PF:</b>
                                                <b style={{ color: prop.color_pf }}>
                                                        {new Intl.NumberFormat('en-EN').format(prop.total_cpf)}</b><br></br>
                                            <b style={style_b}>Total de Casillas sin PF:</b>
                                                <b style={{ color: prop.color_pf }}>
                                                        {new Intl.NumberFormat('en-EN').format(prop.total_spf)}</b><br></br>
                                            <b style={style_b}>Porcentaje de Avance PF:</b>
                                                <b style={{ color: prop.color_pf }}>
                                                        {prop.avance_pf === 0 && prop.total === 0 ? "--" : roundTo(prop.avance_pf, 2) + "%"}</b><br></br>
                                        </>
                                        :
                                            <>
                                                <b style={style_b}>{TipoMapa.label}: </b>
                                                    <b style={{ color: (TipoAvance === 'RGs') ? prop.color_rgs: (TipoAvance === 'ABOGADOS') ? prop.color_abogados : (TipoAvance === 'OBSERVADORES') ? prop.color_observadores: prop.color_cuarteles }}>
                                                        {_tipo === 'region'? prop.region : _tipo === 'municipio'? prop.nom_mun : _tipo === 'poligono'? prop.pg_poligon : prop.seccion}</b><br></br>
                                                <b style={style_b}>LNOM:</b>
                                                    <b style={{ color: (TipoAvance === 'RGs') ? prop.color_rgs: (TipoAvance === 'ABOGADOS') ? prop.color_abogados : (TipoAvance === 'OBSERVADORES') ? prop.color_observadores: prop.color_cuarteles }}>
                                                            {new Intl.NumberFormat('en-EN').format(prop.lnom)}
                                                            
                                                            </b><br></br>
                                                <b style={style_b}>Meta:</b>
                                                    <b style={{ color: (TipoAvance === 'RGs') ? prop.color_rgs: (TipoAvance === 'ABOGADOS') ? prop.color_abogados : (TipoAvance === 'OBSERVADORES') ? prop.color_observadores: prop.color_cuarteles }}>
                                                    {
                                                    (TipoAvance === 'RGs') ?new Intl.NumberFormat('en-EN').format(prop.meta_total_rgs):
                                                    (TipoAvance === 'ABOGADOS') ?new Intl.NumberFormat('en-EN').format(prop.meta_total_abogados):
                                                    (TipoAvance === 'OBSERVADORES') ?new Intl.NumberFormat('en-EN').format(prop.meta_total_observadores):
                                                    new Intl.NumberFormat('en-EN').format(prop.meta_total_cuarteles)
                                                    }
                                                    </b><br></br>
                                                <b style={style_b}>Avance Esperado de {TipoAvance}:</b>
                                                    <b style={{ color: (TipoAvance === 'RGs') ? prop.color_rgs: (TipoAvance === 'ABOGADOS') ? prop.color_abogados : (TipoAvance === 'OBSERVADORES') ? prop.color_observadores: prop.color_cuarteles }}>
                                                            {new Intl.NumberFormat('en-EN').format((TipoAvance === 'RGs') ?prop.meta_hoy_rgs:(TipoAvance === 'ABOGADOS') ?prop.meta_hoy_abogados:(TipoAvance === 'OBSERVADORES') ?prop.meta_hoy_observadores:(TipoAvance === 'CASA AZUL') ?prop.meta_hoy_cuarteles:prop.meta_hoy_rgs)}</b><br></br>
                                                <b style={style_b}>Asignados de {TipoAvance}:</b>
                                                    <b style={{ color: (TipoAvance === 'RGs') ? prop.color_rgs: (TipoAvance === 'ABOGADOS') ? prop.color_abogados : (TipoAvance === 'OBSERVADORES') ? prop.color_observadores: prop.color_cuarteles }}>
                                                            {new Intl.NumberFormat('en-EN').format((TipoAvance === 'RGs') ?prop.avance_rgs:(TipoAvance === 'ABOGADOS') ?prop.avance_abogados:(TipoAvance === 'OBSERVADORES') ?prop.avance_observadores:(TipoAvance === 'CASA AZUL') ?prop.avance_cuarteles:prop.meta_hoy_rgs)}</b><br></br>
                                                <b style={style_b}>Porcentaje de Avance de {TipoAvance}:</b>
                                                    <b style={{ color: (TipoAvance === 'RGs') ? prop.color_rgs: (TipoAvance === 'ABOGADOS') ? prop.color_abogados : (TipoAvance === 'OBSERVADORES') ? prop.color_observadores: prop.color_cuarteles }}>
                                                            {
                                                                //AQUI FALTA CODIGO PARA TODOS LOS RGs,ABS,OBS,CRTLS
                                                                prop.avance_rgs === 0 && prop.meta_total_rgs === 0 ? "--" : roundTo((TipoAvance === 'RGs') ?prop.avance_porcentual_rgs:(TipoAvance === 'ABOGADOS') ?prop.avance_porcentual_abogados:(TipoAvance === 'OBSERVADORES') ?prop.avance_porcentual_observadores:(TipoAvance === 'CASA AZUL') ?prop.avance_porcentual_cuarteles:prop.avance_porcentual_cuarteles, 2) + "%"}</b><br></br>
                                            </>
                                    :
                                    
                                    <>
                                    <b>¡Pulse sobre una figura!</b>
                                    </>
                                } 
                                
                                    {
                                    buttonDetalle & prop.seccion === undefined? 
                                    <ButtonGroup>
                                        <Button
                                        style={style_button_radiogroup}
                                        size ="sm"
                                        className="btn btn-sm btn-round"
                                        color={'success'}
                                        outline
                                        type="button" 
                                        onClick={(e)=>{clickDetalle(prop.prop_save)}}>Ver Detalle</Button>
                                    </ButtonGroup>:<></>
                                    }
                                </div>
                            </div>
                        </div>

                    </Map>
                    </>
                    :
                    <SinPermisos></SinPermisos>
                    :

                    <div style={style_spinner}>
                        <MagicSpinner size={70} color={"#008000"} loading={true}></MagicSpinner>
                    </div>
            }
            <PluginFilterMap
                /* isLoading={isLoading} */
                flagFinish={_flagFinish}
                filtrar={setFilter}
                clasess="dropdowns"
                TipoAvance = {TipoAvance}
                Responsabilidad={Responsabilidad}
                derechoCombo={[1,2,4]}
            />
        </>

    );
};
export default SimpleMap;