import React, { useEffect, useState } from "react";
import {ExcelRenderer} from 'react-excel-renderer';
import { Row,Col,Input, Button,Modal,ModalHeader,ModalBody, ModalFooter, Label } from 'reactstrap';
import { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";
import ReactTable from "react-table";
import Pagination from "../../components/Pagination/pagination";

const ImportNumeralia =({onHide}) =>{
    const token = getVars("Token");
    const [Mensaje,setMensaje] = useState('')
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showTableError, setShowTableError] = useState(false);
    const [excel, setExcel] = useState({
        Seccion:"",
        Edad18:"",
        Edad19:"",
        Edad20_24:"",
        Edad25_29:"",
        Edad30_34:"",
        Edad35_39:"",
        Edad40_44:"",
        Edad45_49:"",
        Edad50_54:"",
        Edad55_59:"",
        Edad60_64:"",
        Edad65_mas:"",
        Hombres:"",
        Mujeres:"",
        EdadPromedio:"",
        casillas:"",
        Meta:""
    });
    const [numeralia,setnumeralia]=useState([]);
    const [numeraliaTabla,setnumeraliaTabla]=useState([]);
    const [total,setTotal]=useState(0);
    var $rowsE=[];


useEffect(()=>{

 }, [numeraliaTabla]);

    const setnumeraliaUpdate=()=>{
        setLoading(true);
        setMensaje('')

        var parametros={
          "data": excel
      }
      if(numeralia.length !== 0){
          EjecutarApi(parametros, "setNumeralia", token.access_token, "POST")
          .then(res => {
            if (res.results) {
            if(res.data['No Actualizados'].length === 0){
                showMessage("Cambio de numeralias exitoso!!!","#1BD350")
                setLoading(false);
                onHide(false);
              }
            else{
            var dato=[];
            const data=[];
            res.data['No Actualizados'].forEach((element,key )=> {
              
                 const temp = {
                    Seccion:element[0].Seccion,
                    Error:element[1],
                  }
                  data.push(temp);
                  if(key <= 11){
                      dato.push(temp);
                  }
                
              });
            setnumeraliaTabla(dato)
            setnumeralia(data)
            setTotal(data.length)
            setShowTable(false)
            setShowTableError(true)
            setLoading(false);
            onHide(true);
            }
              }
              
          })
          .catch((e) => {
            setLoading(false);
            showMessage("Error: setnumeraliaCompromisos","#F01C18")
          })
        }
        else{
          setMensaje("No se reconocio ningun archivo verifique de nuevo")
          setLoading(false);
        }
    
        
      }
  
    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
    
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            showMessage(`Error: setnumeraliaCompromisos ${err}`,"#F01C18")         
          }
          else{
            if(resp.rows[0][0] === "Numeralia" && resp.rows[1][0] === "Región"  && resp.rows[1][1] === "Municipio" && resp.rows[1][2] === "Polígono" && resp.rows[1][3] === "Sección" && resp.rows[1][15] === "Edad  60 a 64"){
            $rowsE=resp.rows;
            setShowTable(true)
            setLoading(false)
            var dato=[];
            const data=[];
            $rowsE.forEach((element,key )=> {
                if(key >1){
                 const temp = {
                    Seccion:element[3],
                    Edad18:element[6],
                    Edad19:element[7],
                    Edad20_24:element[8],
                    Edad25_29:element[9],
                    Edad30_34:element[10],
                    Edad35_39:element[11],
                    Edad40_44:element[12],
                    Edad45_49:element[13],
                    Edad50_54:element[14],
                    Edad55_59:element[15],
                    Edad60_64:element[16],
                    Edad65_mas:element[17],
                    Hombres:element[18],
                    Mujeres:element[19],
                    EdadPromedio:element[20],
                    casillas:element[21],
                    Meta:element[22]
                  }
                  data.push(temp);
                  if(key <= 11){
                      dato.push(temp);
                  }
                } 
              });
            setnumeraliaTabla(dato)
            setnumeralia(data)
            setExcel(data)
            setTotal(data.length)
          }
          else{
            setMensaje('Error: El archivo no tiene un formato compatible!!!')
            setLoading(false);
            return;
          }

        }
        });               
    
      }
    

 const showMessage = (message,background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }

    return(
        <Modal
            isOpen={true}
            toggle={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <ModalHeader toggle={onHide}>
           { "Importar Excel numeralia"}
           
        </ModalHeader>
        <ModalBody>
        {loading && <MetroSpinner size={30} color="#686769" loading={loading} />}
            <Row md='12'  className="justify-content-md-center">
                        <Col sm="5" >
                        <Input type="file" onChange={fileHandler.bind(this)} style={{"padding":"10px"}} />
                        </Col>
                    </Row>
                    <Row>
                    <Col sm="12">
                        <Label id='mensaje'className='text-danger' >{Mensaje}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                  {showTable &&
                    <div style={{ 'width': '100%', 'overflow': 'auto'}}>
                      <ReactTable 
                        style={{
                          width: "110%",
                            height: "100%"
                          }}
                          size='sm'
                          data={numeraliaTabla}
                          noDataText="Sin información"
                          manual
                          columns={[
                            /* {
                              Header: "Region",
                              minWidth: 30,
                              id: "Region",
                              accessor: (d) => d.Region,
                              filterAll: false,
                            },
                            {
                              Header: "Municipios",
                              minWidth: 50,
                              id: "Municipios",
                              accessor: (d) => d.Municipio,
                              filterAll: false,
                            },
                            {
                              Header: "Polígono",
                              minWidth: 40,
                              id: "Poligono",
                              accessor: (d) => d.Poligono,
                              filterAll: false,
                            }, */
                            {
                              Header: "Sección",
                              minWidth: 40,
                              id: "Seccion",
                              accessor: (d) => d.Seccion,
                              filterAll: false,
                            },
                            {
                                Header: "Edad18",
                                minWidth: 40,
                                id: "Edad18",
                                accessor: (d) => d.Edad18,
                                filterAll: false,
                              },
                              {
                                Header: "Edad19",
                                minWidth: 40,
                                id: "Edad19",
                                accessor: (d) => d.Edad19,
                                filterAll: false,
                              },
                              {
                                Header: "Edad20_24",
                                minWidth: 40,
                                id: "Edad20_24",
                                accessor: (d) => d.Edad20_24,
                                filterAll: false,
                              },
                              {
                                Header: "Edad25_29",
                                minWidth: 40,
                                id: "Edad25_29",
                                accessor: (d) => d.Edad25_29,
                                filterAll: false,
                              },
                              {
                                Header: "Edad30_34",
                                minWidth: 40,
                                id: "Edad30_34",
                                accessor: (d) => d.Edad30_34,
                                filterAll: false,
                              },
                              {
                                Header: "Edad35_39",
                                minWidth: 40,
                                id: "Edad35_39",
                                accessor: (d) => d.Edad35_39,
                                filterAll: false,
                              },
                              {
                                Header: "Edad40_44",
                                minWidth: 40,
                                id: "Edad40_44",
                                accessor: (d) => d.Edad40_44,
                                filterAll: false,
                              },
                              {
                                Header: "Edad45_49",
                                minWidth: 40,
                                id: "Edad45_49",
                                accessor: (d) => d.Edad45_49,
                                filterAll: false,
                              },
                              {
                                Header: "Edad50_54",
                                minWidth: 40,
                                id: "Edad50_54",
                                accessor: (d) => d.Edad50_54,
                                filterAll: false,
                              },
                              {
                                Header: "Edad55_59",
                                minWidth: 40,
                                id: "Edad55_59",
                                accessor: (d) => d.Edad55_59,
                                filterAll: false,
                              },
                              {
                                Header: "Edad60_64",
                                minWidth: 40,
                                id: "Edad60_64",
                                accessor: (d) => d.Edad60_64,
                                filterAll: false,
                              },
                              {
                                Header: "Edad65_mas",
                                minWidth: 40,
                                id: "Edad65_mas",
                                accessor: (d) => d.Edad65_mas,
                                filterAll: false,
                              },
                              {
                                Header: "Hombres",
                                minWidth: 40,
                                id: "Hombres",
                                accessor: (d) => d.Hombres,
                                filterAll: false,
                              },
                              {
                                Header: "Mujeres",
                                minWidth: 40,
                                id: "Mujeres",
                                accessor: (d) => d.Mujeres,
                                filterAll: false,
                              },
                              {
                                Header: "casillas",
                                minWidth: 40,
                                id: "casillas",
                                accessor: (d) => d.casillas,
                                filterAll: false,
                              },
                              {
                                Header: "Meta",
                                minWidth: 40,
                                id: "Meta",
                                accessor: (d) => d.Meta,
                                filterAll: false,
                              }
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loading}
                          showPageSizeOptions={false}
                          pageSizeOptions={[10]}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.floor(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                              const elementInicio = stateComp.page * stateComp.pageSize;
                              let elementFin = (stateComp.page + 1) * stateComp.pageSize;

                              if(elementFin > total){

                                elementFin =elementFin - total;
                              }
                              
                              var dato=[];
                            if(numeralia.length !== 0 ){
                              for (let i = elementInicio; i < elementFin; i++) {
                                dato.push(numeralia[i]);
                              }
                              setnumeraliaTabla(dato);
                            }
                          }}
                      />
                    </div>
                  }
                   {showTableError &&
                    <div style={{ 'width': '100%', 'overflow': 'auto', 'color': 'red',}}>
                      <ReactTable 
                        style={{
                          width: "110%",
                            height: "100%"
                          }}
                          size='sm'
                          data={numeraliaTabla}
                          noDataText="Sin información"
                          manual
                          columns={[
                            {
                              Header: "Sección",
                              minWidth: 40,
                              id: "Seccion",
                              accessor: (d) => d.Seccion,
                              filterAll: false,
                            },
                            {
                                Header: "Error",
                                minWidth: 40,
                                id: "Error",
                                accessor: (d) => d.Error,
                                filterAll: false,
                              }
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loading}
                          showPageSizeOptions={false}
                          pageSizeOptions={[10]}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.floor(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                              const elementInicio = stateComp.page * stateComp.pageSize;
                              let elementFin = (stateComp.page + 1) * stateComp.pageSize;

                              if(elementFin > total){

                                elementFin =elementFin - total;
                              }
                              
                              var dato=[];
                            if(numeralia.length !== 0 ){
                              for (let i = elementInicio; i < elementFin; i++) {
                                dato.push(numeralia[i]);
                              }
                              setnumeraliaTabla(dato);
                            }
                          }}
                      />
                    </div>
                  }
              </Col>
                </Row>
                </ModalBody>
        <ModalFooter className="justify-content-md-center">
            <Row >
                <Col>
                    <Button color='primary' className='primary' size='sm' onClick={(e)=> setnumeraliaUpdate(e)} >Guardar</Button>               
                </Col>
                <Col>
                    <Button color='danger' className='danger' size='sm' onClick={(e)=> onHide(false)} >Cancelar</Button>               
                </Col>
            </Row>
        </ModalFooter>
    </Modal>
  
);
}
export default ImportNumeralia;