import React, { useEffect, useState } from "react";


//Zincri imports
import ChoropethMap from "components/Maps/Promocion/ChoropethMapV5CompromisosWebLibre.jsx";
import { getVars } from "GlobalStorage";
import {
  Button
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { URL_GENERAL } from "data/Consts";
import { getDataMenu } from "data/menus";

const Mapas = (props) => {
  const token = getVars("Token");
  const [dataGeoJson, setDataGeoJson] = useState(
    {
      "type": "FeatureCollection",
      "features": [
      ]
    }
  );

  const [tipoMapa, setTipoMapa] = useState(
    { value: "seccion", label: "Seccional" }

  );
  const [zoom] = useState(9);
  const [menu, _setmenu] = useState({});

  const [rangos, setRangos] = useState([]);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState("");
  const hostserver = URL_GENERAL;



  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  useEffect(() => {
    _setmenu(getDataMenu(52, token.menus));
  }, [dataGeoJson]);
  const basicAlert = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );
  async function getRangos() {
    let config = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };
    try {
      let res = await fetch(hostserver + "getRangosCompromisosGtoML", config);
      let data = await res.json();
      setRangos(data);
    } catch (error) {
      setMensajeAlert("Favor de renovar sesión, si el error persiste no cuenta con permisos para ver este mapa.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }

  const clickDownloadFile = (e) => {
    if (menu.Exportar === 0) {
      setMensajeAlert("¡No cuenta con permisos para exportar!");
      setFlagAlert(true);
    }
    var url = new URL(
      hostserver + `getArchivoMapaWeb?token=${token.access_token}&HashCode=${e.feature.properties.HashCode}`
    );
    window.open(url, "_blank");
  }


  function btnBuscar(seccion) {
    const Seccion = seccion;

    if (Seccion.length > 0) {
      btnSearchSeccion(Seccion);
    } else {
      setMensajeAlert("¡Favor de especificar una sección!");
      setFlagAlert(true);
    }
  }

  async function btnSearchSeccion(Seccion) {
    getRangos();
    setDataGeoJson({});
    const token = getVars("Token").access_token;
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ clave: Seccion }),
      };


      let res = await fetch(
        hostserver + "getSeccionesCompromisosGtoBusquedaWeb",
        config
      );
      let data = await res.json();
      var json_parser_secciones = JSON.parse(data.seccion);
      var json_parser_manzanas = JSON.parse(data.manzanas);


      if (json_parser_secciones.features === null) {
        throw new Error("features null");
      }
      if (json_parser_manzanas.features === null) {
        json_parser_manzanas = {
          "type": "FeatureCollection",
          "features": [
          ]
        }
      }
      json_parser_manzanas.features.forEach(element => json_parser_secciones.features.push(element));

      setDataGeoJson(json_parser_secciones);
    } catch (error) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }


  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };




  async function getSecciones(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + "getSeccionesCompromisosGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Favor de renovar sesión, si el problema persiste es probable que no cuente con permisos para ver este mapa.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }



  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapaparametros(e);
  }

  const handleSearhSeccion = (e) => {
    btnBuscar(e);
  }
  const handleTipoMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapa(e);
  }
  function selectmapa(tipomapa) {

    if (tipomapa.value === 'seccion') {
      getSecciones("", "GET");
    }
  }

  function selectmapaparametros(tipomapa) {

    if (tipomapa.value === 'seccion') {
      getSecciones(tipomapa.clave, "POST");
    }
  }

  return (
    <div className="wrapper">
      <div hidden id="dataVars" name="dataVars" ></div>
      <div hidden id="idUser" name="idUser" ></div>
      <div hidden id="DeviceID" name="DeviceID" ></div>
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <div className="content">
          <Button hidden id="btnS" name="btnS" onClick={() => btnBuscar()}> Buscar</Button>
          <>
            {FlagAlert ? basicAlert : <></>}
            <ChoropethMap
              key={"ChoropethMapV4"}
              center={[position.latitude, position.longitude]}
              zoom={zoom}
              data={dataGeoJson}
              rangos={rangos}
              tipo={tipoMapa.value}
              label_tipo={tipoMapa.label}
              handleTipoMapa={handleTipoMapa}
              handleDetalleMapa={handleDetalleMapa}
              handleSearhSeccion={handleSearhSeccion}
              clickDownloadFile={clickDownloadFile}
              menuExportar={menu}
              MenuPermisos={menu}
            ></ChoropethMap>

          </>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
