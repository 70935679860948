import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row,Col,Collapse,Input } from 'reactstrap';
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";


function IndexListado ({Menu,Datos}){

    const token = getVars("Token");
	const [filtro,setFiltro] = useState({
		Nombre:"",
		Celular:"",
		Responsabilidad:"",
		
	})
	
    const [collapse, setCollapse] = useState({
		horizontalTabs: "home",
		verticalTabs: "info",
		pageTabs: "homePages",
		openedCollapses: ["collapseOne", "collapse1"]
    });
   
    //const [catSexo, setCatSexo] = useState([]);
    //const [catVoluntario, setCatVoluntario] = useState([]);
    //const [catSituacionLaboral, setSituacionLaboral] = useState([]);
    //const [catNivelEstudios, setNivelEstudios] = useState([]);
    
    //const [catRegionest, setCatRegionest] = useState([]);
    //const [catRegionestFilter, setCatRegionestFilter] = useState([])
    //const [catPoligonost, setCatPoligonost] = useState([])
    //const [catPoligonostFilter, setCatPoligonostFilter] = useState([])
    const [dataCombos, setDataCombo] = useState({ 
        dRegion: { label: 'Seleccione', value: 0 }, 
        dMunicipio: { label: 'Seleccione', value: 0 }, 
        dPoligono: { label: 'Seleccione', value: 0 },
        dSeccion: { label: 'Seleccione', value: 0 } ,
        dPropuesta: { label: 'Seleccione', value: 0 }} )
    //const [catMunicipiotFilter, setCatMunicipiotFilter] = useState([])
    //const [catMunicipiot, setCatMunicipiot] = useState([])
    //const [catSecciont,setCatSecciont]=useState([])
    //const [catSecciontFilter,setCatSecciontFilter] = useState([])
    //const [catCasilla,setcatCasilla]=useState([{value:0,label:'Debe validar la sección'}]);
    //const [catPropietario,setcatPropietario]=useState([{value:0,label:'Debe validar la sección'}]);
    useEffect(()=>{
      
        EjecutarApi({}, "catCompromisosWeb", token.access_token, "GET")
        .then(function (res) {
            return res;
        })
        .then((items) => {
            if (items.results) {
         
            } 
          
        })
        .catch((e) => {
        
          
        });
        //getCatalogosFiltrosPromocion  getCatRegion
        
      //getCatalogosPorResponsabilidad
        EjecutarApi({}, "getCatalogosPorResponsabilidad", token.access_token, "POST")
        .then(res => {
          if(res.results)
            if (res.data) {
                //setCatVoluntario(res.data)
            }
        })
        .catch((e) => {
        }).then(() => {
      
        })
    },[])
     
    const collapsesToggle = (colapse) => {
		let openedCollapses = collapse.openedCollapses;
		if (openedCollapses.includes(colapse)) {
		  setCollapse({...collapse,
			openedCollapses: openedCollapses.filter(item => item !== colapse)
		  });
		} else {
		  openedCollapses.push(colapse);
		  setCollapse({...collapse, 
			openedCollapses: openedCollapses
		  });
		}
    };
    
    const setFilter=(event)=>{
        
        var filtrar = {
            Nombre:filtro.Nombre,
            Celular:filtro.Celular,
            Responsabilidad:filtro.Responsabilidad,

        }
        Datos(filtrar);
    }
    const ClearFilter=()=>{
        setDataCombo({ ...dataCombos, dRegion:{ label: 'Seleccione', value: 0 },
        dMunicipio: { label: 'Seleccione', value: 0 },
        dPoligono: { label: 'Seleccione', value: 0 }, 
        dSeccion:{ label: 'Seleccione', value: 0 }  });
        
        setFiltro({
            Nombre:"",
            Celular:"",
            Responsabilidad:"",
        })
        

    }
    return (
        <Card className="card-plain">
            <CardBody>
            <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                role="tablist"
            >
                
                <Card className="card-plain">
                <CardHeader role="tab">
                    <a
                        aria-expanded={collapse.openedCollapses.includes(
                            "collapse3"
                          )}
                        //href="#pablo"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle("collapse3")}
                        className="text-right"
                    >
                    <i class="fa fa-filter fa-lg" aria-hidden="true"></i>{" "}
                    Filtro
                    </a>
                </CardHeader>
                <Collapse
                    role="tabpanel"
                    isOpen={collapse.openedCollapses.includes(
                        "collapse3"
                      )}
                >
                    <CardBody>
                  
                      {Menu.RGs && (<> 
                        <Row>
                           {/* <Col sm="3" className="text-muted">Región: 
                            {loading && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                />
                                )}            {!loading && (<Select
                                options={catRegionestFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={dataCombos.dRegion}
                                onChange={(e) => changeRegionX(e)}
                                id="viewTypeR"
                                placeholder="Filtrar por"
                                />)}
                            </Col>
                            <Col sm="3" className="text-muted">Municipio: 
                            {loading && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                />
                                )}            {!loading && (<Select
                                options={catMunicipiotFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={dataCombos.dMunicipio}
                                onChange={(e) => changeMunicipioX(e)}
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                />)}
                            </Col>
                            <Col sm="3" className="text-muted">Polígono:
                            {loading && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                />
                                )}            {!loading && (<Select
                                options={catPoligonostFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={dataCombos.dPoligono}
                                onChange={(e) => changePoligonoX(e)}
                                id="viewTypeP"
                                placeholder="Filtrar por"
                                />)}
                            </Col>
                            <Col sm="3" className="text-muted">Sección:
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                    )}            {!loading && (<Select
                                    options={catSecciontFilter}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={dataCombos.dSeccion}
                                    onChange={(e) => changeSeccionX(e)}
                                    id="viewTypeS"
                                    placeholder="Filtrar por"
                                />)}
                            </Col>
                             <Col sm="3" className="text-muted">
                                <FormGroup sm="4">
                                    <Label for="casilla">Casilla:</Label>
                                    <Select className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="casilla"                                                        
                                    placeholder="Debe validar la sección"
                                    options={catCasilla}
                                    onChange = {(e) => setFiltro({...filtro, Casilla:e})}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="3" className="text-muted">
                            <FormGroup sm="3">
                                <Label for="propietario">Tipo Casilla:</Label>
                                <Select className="react-select primary"
                                classNamePrefix="react-select"
                                id="propietario"                                                        
                                placeholder="Debe validar la casilla"
                                options={catPropietario}
                                onChange = {(e)=> setFiltro({...filtro, TipoCasilla:e})}
                                />
                            </FormGroup>
                            </Col> 
                            <Col sm="4" className="text-muted">Nombre:<Input id="ipaterno" value={filtro.Nombre} onChange={(e)=>setFiltro({...filtro, Nombre:e.target.value})} /></Col>
                            <Col sm="3" className="text-muted">INE:<Input id="icveElector" value={filtro.ClaveElector} onChange={(e)=>setFiltro({...filtro, ClaveElector:e.target.value})} /></Col>
                            */}<Col sm="4" className="text-muted">Celular: <Input id="inombre" value={filtro.Celular} onChange={(e)=>setFiltro({...filtro,Celular:e.target.value})} /></Col>
                            
                            <Col sm="4" className="text-muted">Responsabilidad:<Input id="" value={filtro.Responsabilidad} onChange={(e)=>setFiltro({...filtro,Responsabilidad:e.target.value})} /></Col>	
                        
                        </Row>
                      </>)}  
                     </CardBody>
                    <CardFooter>
                    <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e)=>setFilter()}>
                            <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                            Filtrar
                        </Button>{' '}
                        <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e)=> ClearFilter()}>
                            <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                            Limpiar
                        </Button>
                    </CardFooter>
                </Collapse>
                </Card>
            </div>
            </CardBody>
        </Card>
    );
};
export default IndexListado;