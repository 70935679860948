var _ = require('lodash');
export const centerOnOneShape = (flagData, _data) => {
  if (flagData) {
    var center = [2];
    var count_media = 0;
    if (Object.keys(_data).length !== 0) {
      
        if (_data.features.length !== 0) {
          var figuras_zoom = [];
          center[0] = 0;
          center[1] = 0;
          _data.features.forEach(function (f) {
            center[0] = center[0] + f.properties.latitud; //lat
            center[1] = center[1] + f.properties.longitud; //long
            figuras_zoom.push(f.properties.zoom);
            count_media = count_media + 1;
          });
          let zoom = _.min(figuras_zoom);

          if (
            _data.features.length >= 1500 &&
            _data.features[0].properties.seccion !== undefined
          ) {
            zoom = 9;
          }
          if (
            _data.features.length >= 100 &&
            _data.features[0].properties.poligono !== undefined
          ) {
            zoom = 9;
          }
          if (
            _data.features.length > 20 && 
            _data.features[0].properties.cve_mun !== undefined ) {
            zoom = 9;
          }
          
          center[1] = ((center[1] * 1.0) / count_media) * 1.0;
          center[0] = ((center[0] * 1.0) / count_media) * 1.0;
          return { zoom: zoom, center: center };
      }
    }
  }
};

export const centerOnOneShapeSecciones = (flagData, _data) => {
  if (flagData) {
    var center = [2];
    if (Object.keys(_data).length !== 0) {
      if (_data.features.length === 1) {
        center = [
          _data.features[0].properties.latitud,
          _data.features[0].properties.longitud,
        ];
        let _center = center;
        let zoom = _data.features[0].properties.zoom;
        //let zoom = _.min(figuras_zoom);
        return { zoom: zoom, center: _center };
      } else {
        if (_data.features.length !== 0) {
          let array_secciones = _data.features.filter(
            (item) => item.properties.seccion !== undefined
          );
          var figuras_zoom = [];
          center[1] = 0;
          center[0] = 0;

          array_secciones.forEach((item) => {
            center[1] = center[1] + parseFloat(item.properties.longitud);
            center[0] = center[0] + parseFloat(item.properties.latitud);
            figuras_zoom.push(item.properties.zoom);
          });
          center[1] = ((center[1] * 1.0) / array_secciones.length) * 1.0;
          center[0] = ((center[0] * 1.0) / array_secciones.length) * 1.0;
          let zoom = _.min(figuras_zoom);
          if (array_secciones.length >= 1500) {
            zoom = 12;
          }
          return { zoom: zoom, center: center };
        }
      }
    }
  }
};
