import React, { useState } from 'react';
import { Button, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import { useHistory } from "react-router-dom";
import { NavLink, Link } from 'react-router-dom';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';

import EjecutarApi from '../../data/getPOST';
import { getVars } from '../../GlobalStorage';
import Pagination from "../../components/Pagination/pagination";
import ModalFiltro from '../../components/Filtro/index';
import { getDataMenu } from 'data/menus';

function Cuestionarios(props) {
	let history = useHistory();
	const token = getVars('Token');
	const [setsidebarMini] = useState(true);
	const [compromisos, setCompromisos] = useState([]);
	const [totalCompromisos, setTotalCompromisos] = useState(0);
	const [_menu, _setmenu] = useState({});
	const [loading, setLoading] = useState(false);



	const handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			setsidebarMini(false);
		} else {
			setsidebarMini(true);
		}
		document.body.classList.toggle('sidebar-mini');
	};

	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel">
				<Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
				<div className="content">

					<Row>
						<Col>

							<Card>
								<CardHeader>
									<Row>
										<Col sm="4">

											<NavLink exact to="/cuestionarios_add">
												Capturar
                        </NavLink>


											<Button size='sm' className='primary left-align' color='primary' >
												<span className="btn-label">
													<i className="fa fa-file-excel-o fa-lg" />
												</span>
												Exportar
												</Button>

										</Col>
									</Row>
									<Row>
										<Col sm="12">
											<ModalFiltro Menu={_menu} />
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<div style={{ 'width': '100%', 'overflow': 'auto' }}>
										<ReactTable
											style={{
												width: "110%",
												height: "100%"
											}}
											size='sm'
											data={compromisos}
											noDataText="Sin información"
											manual
											columns={[
												{
													Header: "ID",
													headerStyle: { fontSize: '12px' },
													minWidth: 20,
													id: "id",
													accessor: (d) => d.id,
													filterAll: false,
													style: { fontSize: '12px', textAlign: 'center' },

												},

												{
													Header: "Nombre",
													headerStyle: { fontSize: '12px' },
													minWidth: 100,
													id: "NombreCompleto",
													accessor: (d) => d.NombreCompleto,
													filterAll: false,
													style: { fontSize: '12px' },
												},
												{
													Header: "Descripcion",
													headerStyle: { fontSize: '12px' },
													minWidth: 100,
													id: "Descripcion",
													accessor: (d) => d.Descripcion,
													filterAll: false,
													style: { fontSize: '12px' },
												},
												{
													Header: "Preguntas",
													headerStyle: { fontSize: '12px' },
													minWidth: 40,
													id: "Preguntas",
													accessor: (d) => d.Preguntas,
													filterAll: false,
													style: { fontSize: '12px', textAlign: 'center' },
												},
												{
													Header: "Creo",
													headerStyle: { fontSize: '12px' },
													accessor: (d) => d.UserCapturo,
													minWidth: 80,
													id: "UserCapturo",
													filterAll: false,
													style: { fontSize: '12px', textAlign: 'left' },
												},
												{
													Header: "Actualizó",
													headerStyle: { fontSize: '12px' },
													accessor: (d) => d.UserActualizo,
													minWidth: 80,
													id: "UserActualizo",
													filterAll: false,
													style: { fontSize: '12px', textAlign: 'left' },
												}
											]}
											className="-striped -highlight primary-pagination -scrollbar"
											loading={loading}
											showPagination={true}
											showPaginationBottom={true}
											showTotalPages={true}
											showPageJump={false}
											canNextFromData={true}
											defaultPageSize={10}
											total={totalCompromisos}
											pages={Math.floor(totalCompromisos / 10)}
											previousText="Anterior"
											nextText="Siguiente"
											ofText="de"
											pageSizeOptions={[10, 20, 25, 50, 100]}
											PaginationComponent={Pagination}
											onFetchData={(stateComp, instance) => {
												setLoading(true);
												let _menuStorage = getDataMenu(6, token.menus);
												const data = {
													page: stateComp.page,
													pageSize: stateComp.pageSize,
													sorted: [{ id: "C.updated_at", desc: true }],
													filtered: stateComp.filtered,
												};
												EjecutarApi(data, "getCuestionario", token.access_token)
													.then(function (res) {
														return res;
													})
													.then((res) => {
														setLoading(false);
														setTotalCompromisos(res.total);
														setCompromisos(
															res.data.map((prop, key) => {

																return {
																	id: prop.id,
																	Nombre: prop.Nombre,
																	Descripcion: prop.Descripcion,
																	UserCapturo: prop.UsuarioCreo,
																	UserActualizo: prop.UsuarioActualizo,
																	Preguntas: prop.Preguntas,
																	NombreCompleto: _menuStorage.Editar === 1 ? (<>
																		<Link to={`/cuestionarios_edit?id=${prop["id"]}`}>{`${prop["Nombre"]}`}</Link></>) : (<>{`${prop["Nombre"]}`}</>),
																	data: prop,
																};
															})
														);

													})
													.catch((e) => {
														setLoading(false);
														notify.show(
															`Error al obtener Compromisos: ${e.error}`,
															"custom",
															5000,
															{
																background: "#0857B6",
																fontSize: 14,
																text: "#FFFFFF",
															}
														);


													});
											}}
										/>
									</div>
								</CardBody>
							</Card>

						</Col>
					</Row>
				</div>
				<Footer fluid />
			</div>
		</div>
	);
};

export default Cuestionarios;
