import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardHeader, Card } from 'reactstrap';
import { notify } from "react-notify-toast";
import ReactTable from 'react-table';
import EjecutarApi from "../../data/getPOST";
import { getVars, URL_PUBLICA } from "../../GlobalStorage";
import Pagination from '../Pagination/pagination';
import { URL_GENERAL } from "../../data/Consts";
import FiltroDetalle from 'components/Estructura/FiltroResponsables';

const ListadoResponsables = ({ props, onHide }) => {
  const [loading, setLoading] = useState(false);
  const [_menu, _setmenu] = useState({});
  const token = getVars("Token");
  const [compromisos, setCompromisos] = useState([]);
  const [totalCompromisos, setTotalCompromisos] = useState(0);
  const [filtro, setFiltro] = useState([{ "id": "municipio.id", "value": props.id }]);
  const [filtroRC, setFiltroRC] = useState([{ "id": "municipio.id", "value": props.id },{"id":"electoral.idPropietario","value": props.rc}]);
  const [nombre, setNombre] = useState("");
  const [celular, setCelular] = useState("");
  var titulo = props.value.toUpperCase() + " " + props.label;

  



  const Exportar = () => {
    var url = new URL(

      `${URL_GENERAL}getReporteResponsablesDetalle?token=${token.access_token}`
    );
    window.open(url, '_blank');
  };



  const setFilter = (filtered) => {

    var sorted = [];

    if (filtered.length !== 0) {

      var nombre = filtered[0]['id'] === "Nombre" ? (filtered[0]['value']) : "";
      var celular = filtered[1]['id'] === "Celular" ? (filtered[1]['value']) : "";


      setNombre(nombre)
      setCelular(celular)
    }


    const data = {
      page: 0,
      pageSize: 10,
      sorted: sorted ? sorted : [],
      tipo: 'and',
      Electoral: props.zona,
      filtered: props.rc ? filtroRC : filtro,
      Nombre: nombre ? nombre : " ",
      Celular: celular?celular: " "
    };

    EjecutarApi(
      data,
      'getResponsableMunicipalElectoral',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (res.data) {
            setLoading(false);
            setTotalCompromisos(res.total)
            setCompromisos(
              res.data.map((item) => {
                return {
                  Nombre: item.Responsable,
                  Celular: item.Celular ? item.Celular : 'Sin Celular',
                  Municipio: item.Municipio ? item.Municipio : 'Sin Municipio'
                };
              })
            );
          }
        }
      })
      .catch((e) => {

        setLoading(false);
        notify.show(
          `Error: ${e.error}`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });

  }

  const Limpiar = (filtered) => {
    setFilter(filtered);
  }

  return (
    <>
      <Card>
        <Col md="12">
          <CardHeader>
            <Row>
              <Col md="12" >
                <Button size="sm" color='primary' onClick={() => onHide()}>Regresar</Button>
                {/* {_menu.Exportar === 1 && (<Button size="sm" color='success' onClick={() => Exportar()}>Exportar</Button>)} */}
              </Col>

            </Row>
            <Row>
              <Col md='12' className="text-center" >
                <h4>Listado Responsables <br></br>
                  {titulo}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <FiltroDetalle
                  filtrar={setFilter} limpiar={Limpiar} />
              </Col>
            </Row>
          </CardHeader>
          <Row>
            <Col sm="12" >
              <div style={{ width: '100%', overflow: 'auto' }}>
                <ReactTable
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  name="compromisos"
                  data={compromisos}
                  noDataText="Sin información"
                  manual
                  columns={[
                    {
                      Header: 'Nombre',
                      minWidth: 110,
                      id: 'Nombre',
                      accessor: (d) => d.Nombre,
                      filterAll: false,
                    },
                    {
                      Header: 'Celular',
                      minWidth: 50,
                      id: 'Celular',
                      show: props.responsibilityID === 99 ? true : false,
                      accessor: (d) => d.Celular,
                      filterAll: false,
                    }
                  ]}
                  className="-striped -highlight primary-pagination"
                  loading={loading}
                  showPagination={true}
                  showPaginationBottom={true}
                  showTotalPages={true}
                  showPageJump={false}
                  canNextFromData={true}
                  defaultPageSize={10}
                  total={totalCompromisos}
                  pages={Math.ceil(totalCompromisos / 10)}
                  previousText="Anterior"
                  nextText="Siguiente"
                  ofText="de"
                  pageSizeOptions={[10, 20, 25, 50, 100]}
                  PaginationComponent={Pagination}
                  onFetchData={(stateComp) => {
                    setLoading(true);
                    var parametro = {
                      Electoral: props.zona,
                      filtered: props.rc ? filtroRC : filtro,
                      page: stateComp.page,
                      pageSize: stateComp.pageSize,
                      Nombre: nombre ? nombre : " ",
                      Celular: celular?celular: " ",
                      tipo: "and"
                    }
                    EjecutarApi(
                      parametro,
                      'getResponsableMunicipalElectoral',
                      token.access_token
                    )
                      .then(function (res) {
                        return res;
                      })
                      .then((res) => {
                        if (res.results) {
                          if (res.data) {
                            setLoading(false);
                            setTotalCompromisos(res.total)
                            setCompromisos(
                              res.data.map((item) => {
                                return {
                                  Nombre: item.Responsable,
                                  Celular: item.Celular ? item.Celular : 'Sin Celular',
                                  Municipio: item.Municipio ? item.Municipio : 'Sin Municipio'
                                };
                              })
                            );
                          }
                        }
                      })
                      .catch((e) => {

                        setLoading(false);
                        notify.show(
                          `Error: ${e.error}`,
                          'custom',
                          5000,
                          {
                            background: '#0857B6',
                            fontSize: 14,
                            text: '#FFFFFF',
                          }
                        );
                      });
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Card>
    </>
  );
};
export default ListadoResponsables;