import React, { useState, useEffect } from "react";
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { MagicSpinner } from "react-spinners-kit";
import PluginFilterMap from "./PluginFilterMapImpresion";
import {SinPermisos} from "../ComponentesAuxiliares/SinPermisos";
import {centerOnOneShape} from "../ComponentesAuxiliares/AuxiliarFunctions";
import {
    Button,
    ButtonGroup,
    Table
} from "reactstrap";

let style_b = {
    'color': '#000000',
}
let style_square_superior_left = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
}
let style_square_superior = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
}
let style_square_inferior_tabla = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.9)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
}

//
let style_spinner = {
    'height': '500pt',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center'
}

function ChoropethMap({ 
    center,
    MenuPermisos,
    clickDownloadFile,
    zoom,
    data,
    tipo,
    handleSearh,
    Responsabilidad,
    _flagFinish,
    handleFiltro
 }) {

    const [_center, setCenter] = useState(center ? center : [20.678416, -101.354231]);
    const [_zoom, setZoom] = useState(zoom ? zoom : 10);
    var _data = data ? data : null;
    const flag = Object.keys(_data).length === 0 ? false : true;
    const _tipo = tipo ? tipo : 'municipio';
    const [textInput, setTextInput] = useState("");
    const [flagData/* , setFlagData */] = useState(true);
    const [buttonDetalle, setButonDetalle] = useState(false);
    const [flagTabla, setFlagTabla] = useState(false);


    const [prop, setProp] = useState(
        tipo === 'municipio' ?
            { 'nom_mun': '', 'cve_mun': '' }
            : tipo === 'region' ?
                { 'region': '' } : tipo === 'seccion' ? { 'seccion': '' } : { 'poligono': '' }
    );
    
    /* function roundTo(value, places) {
        var power = Math.pow(10, places);
        return Math.round(value * power) / power;
    } */
    const setFilter = (filtered) => {
        setButonDetalle(false);
        handleFiltro(filtered);
    }

    function style(feature, flag) {
        let style;
        if (flag === undefined || flag !== true) {
            if (feature.properties.poligono) {
                
                style = {
                    fillColor: '#0066FF',
                    weight: 2,
                    opacity: 1,
                    color: '#666',
                    dashArray: '3',
                    fillOpacity: 0.2
                };
            }
            else {
                style = {
                    fillColor: 'white',
                    weight: 2,
                    opacity: 0.5,
                    color:'#666',//'white',
                    dashArray: '3',
                    fillOpacity: 0.5
                };
            }

        }
        else {
            style = {
                weight: 5,
                color: '#666',
                dashArray: '',
                fillOpacity: 0.3
            };

        }
        return style;
    }

    


    const highlightFeature = (e) => {
        //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.
        var layer = e.target;
        
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
        if (_tipo === 'municipio')
            setProp({
                'nom_mun': e.target.feature.properties.nom_mun,
                'cve_mun': e.target.feature.properties.cve_mun,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'HashCode': layer.feature.properties.HashCode,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': layer
            });
        else if (_tipo === 'region')
            setProp({
                'region': e.target.feature.properties.region,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'HashCode': layer.feature.properties.HashCode,
                'prop_save': layer
            });
        else if (_tipo === 'seccion')
            setProp({
                'seccion': e.target.feature.properties.seccion,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'HashCode': layer.feature.properties.HashCode,
                'prop_save': layer
            });
        else if (_tipo === 'poligono')
            setProp({
                'pg_poligon': e.target.feature.properties.pg_poligon,
                'poligono': e.target.feature.properties.poligono,
                'avance_porcentual': e.target.feature.properties.avance_porcentual,
                'color': e.target.feature.properties.color,
                'avance': e.target.feature.properties.avance,
                'meta': e.target.feature.properties.meta,
                'meta_total': e.target.feature.properties.meta_total,
                'lnom': e.target.feature.properties.lnom,
                'HashCode': layer.feature.properties.HashCode,
                'prop_save': layer
            });

        setButonDetalle(true);
        layer.setStyle(style(layer.feature, true));
        
    }

    
    const clickFeature = (e) => {
        highlightFeature(e);
        //setButonDetalle(true);
        //Aqui configuramos lo que queremos que haga un feature cuando le den click.
        //alert(e.target.feature.properties.nom_mun);
    }

    /* const resetHighlight = (e) => {
        //Con esto reseteamos el color en donde paso el mouse, para que no siga marcado.
        var layer = e.target;
        //layer.feature.unbindToolTip();

        layer.setStyle(style(e.target.feature));
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
    } */



    const onEachFeature = (feature, layer) => {
        //Organizamos los eventos del GeoJson.
        if (layer.feature.properties.poligono !== undefined) {
            layer.on({
                //mouseover: highlightFeature,
                //mouseout: resetHighlight,
                click: clickFeature
            });

        }
        else {
            //Para poner etiquetas en las manzanas
            let text = layer.feature.properties.seccion;
            layer.bindTooltip(""+ text + "", { permanent: true, direction: "center" }); 
        }

    }

    var geojson = (
        <>
            <GeoJSON onEachFeature={onEachFeature} style={style} key={"geojson"} data={_data}></GeoJSON>
        </>
    )
    useEffect(() => {
        setButonDetalle(false);
        setProp({});
        if (Object.keys(_data).length !== 0) {
            if(_data.features.length !== 0){
                const new_data = {}
                new_data.properties =  _data.properties;
                new_data.type = "FeatureCollection";
                new_data.features = _data.features.filter(item => item.properties.poligono !== undefined)
                const {zoom, center} = centerOnOneShape(flagData,new_data);
                setCenter(center);
                setZoom(zoom);
            }
        }

    }, [data]);


    const eventhandleSearh = (e) => {
        setButonDetalle(false);
        handleSearh(textInput);

    }

    return (
        <>
            {
                flag ?
                MenuPermisos.Ver === 1 ?
                    <Map setView={true} zoom={_zoom} zoomControl={false} center={_center} style={{ width: '100%', height: '90vh' }}>

                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-left">
                                <div className="leaflet-control" style={style_square_superior_left}>
                                    <form onSubmit={eventhandleSearh}>
                                    <input
                                        className="btn-sm btn-round"
                                        color={'success'}
                                        style={{ height: '31px' }}
                                        outline="true"
                                        size="sm"
                                        type="text"
                                        value={textInput}
                                        onChange={(e) => { setTextInput(e.target.value) }}
                                        placeholder="POLÍGONO"></input>
                                    <ButtonGroup className={"btn-group btn-group-sm"} style={{ backgroundColor: "FFF" }}>

                                        <Button
                                            className="btn btn-sm btn-round"
                                            color={'success'}
                                            outline
                                            type="submit"
                                            onClick={eventhandleSearh}>Buscar Polígono</Button>
                                    </ButtonGroup>
                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-right">
                                <div className="leaflet-control" style={style_square_superior}>
                                    {
                                        (_tipo === 'municipio') ?
                                            <>
                                                <h6>INFORMACIÓN:</h6>
                                                {(prop.nom_mun !== '') ?
                                                    <>
                                                        <b style={style_b}>Municipio:</b><b style={{ color: prop.color }}> {prop.nom_mun}</b><br></br>
                                                        <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                        {/* <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                        <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                        <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                        <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta === 0 ? "--" : roundTo(prop.avance_porcentual, 2) + "%"}</b><br></br> */}
                                                    </>
                                                    : <b> Pulse sobre un municipio</b>}
                                            </>
                                            :
                                            (_tipo === 'region') ?
                                                <>
                                                    <h6>INFORMACIÓN:</h6>
                                                    {(prop.region !== '' && prop.region !== undefined) ?
                                                        <>
                                                            <b style={style_b}>Región:</b><b style={{ color: prop.color }}> {prop.region}</b><br></br>
                                                            <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                            {/* <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                            <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                            <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                            <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta === 0 ? "--" : roundTo(prop.avance_porcentual, 2) + "%"}</b><br></br> */}

                                                        </>
                                                        : <b> Pulse sobre una región</b>}
                                                </>
                                                :
                                                (_tipo === 'seccion') ?
                                                    <>
                                                        <h6>INFORMACIÓN:</h6>
                                                        {(prop.seccion !== '' && prop.seccion !== undefined) ?
                                                            <>
                                                                <b style={style_b}>Sección:</b><b style={{ color: prop.color }}> {prop.seccion}</b><br></br>
                                                                <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                {/* <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                                <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                                <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                                <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta === 0 ? "--" : roundTo(prop.avance_porcentual, 2) + "%"}</b><br></br> */}

                                                            </>
                                                            : <b> Pulse sobre una sección</b>}
                                                    </>
                                                    :
                                                    <>
                                                        <h6>INFORMACIÓN:</h6>
                                                        {(prop.poligono !== '' && prop.poligono !== undefined) ?
                                                            <>
                                                                <b style={style_b}>Polígono:</b><b style={{ color: prop.color }}> {prop.pg_poligon}</b><br></br>
                                                                <b style={style_b}>LNOM:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                {/* <b style={style_b}>Meta:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta_total)}</b><br></br>
                                                                <b style={style_b}>Avance Esperado:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.meta)}</b><br></br>
                                                                <b style={style_b}>Compromisos:</b><b style={{ color: prop.color }}> {new Intl.NumberFormat('en-EN').format(prop.avance)}</b><br></br>
                                                                <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color }}>{prop.avance === 0 && prop.meta === 0 ? "--" : roundTo(prop.avance_porcentual, 2) + "%"}</b><br></br> */}

                                                            </>
                                                            : <b> Pulse sobre un polígono</b>}
                                                    </>

                                    }
                                    {/* <div className="leaflet-control-container">
                                        <div className="leaflet-top leaflet-right">
                                            <div className="leaflet-control" style={_style_square_superior_left_manzanas}>
                                                {
                                                    <>
                                                        <h6>INFORMACIÓN DE LA MANZANA:</h6>
                                                        {(propManzana.MANZANA !== '' && propManzana.MANZANA !== undefined) ?
                                                            <>
                                                                <b style={style_b}>Manzana:</b><b style={{ color: propManzana.color }}> {propManzana.MANZANA}</b><br></br>
                                                                <b style={style_b}>Seccion:</b><b style={{ color: propManzana.color }}> {propManzana.SECCION}</b><br></br>
                                                                <b style={style_b}>Prioridad:</b><b style={{ color: propManzana.color }}> {propManzana.PrioridadLabel}</b><br></br>


                                                            </>
                                                            : <b> Pulse sobre una manzana</b>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                                    {
                                        buttonDetalle  && MenuPermisos.Exportar ===1?
                                            <ButtonGroup>
                                                <Button
                                                    className="btn btn-sm btn-round"
                                                    color={'success'}
                                                    outline
                                                    type="button"
                                                    onClick={(e) => { clickDownloadFile(prop.prop_save) }}>Descargar PDF</Button>
                                            </ButtonGroup> : <></>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="leaflet-control-container">
                            <div className="leaflet-bottom leaflet-left">
                                <div className="leaflet-control" style={style_square_inferior_tabla}>
                                    <Button
                                        className="btn-round btn-sm"
                                        color={'success'}
                                        outline
                                        size="sm"
                                        type="button"
                                        onClick={() => setFlagTabla(!flagTabla)}
                                    >
                                        {(flagTabla) ? "Esconder" : "Mostrar"}
                                    </Button>
                                    {

                                        (flagTabla) ?
                                            <>
                                                {/* <Button
                                                    className="btn-round btn-sm"
                                                    color={'success'}
                                                    outline
                                                    size="sm"
                                                    type="button"
                                                >
                                                    Exportar
                                                </Button> */}
                                                <Table responsive striped border="2px" style={{ 'height': '200px', 'overflow': 'scroll', 'display': 'block' }}>
                                                <thead className="text-primary">
                                                        <tr>
                                                            {(_tipo === 'region') ?
                                                                <th>Región</th>
                                                                :
                                                                (_tipo === 'municipio') ?
                                                                    <th>Municipio</th>
                                                                    :
                                                                    (_tipo === 'poligono') ?
                                                                        <th>Polígono</th>
                                                                        :
                                                                        (_tipo === 'seccion') ?
                                                                            <th>Sección</th>
                                                                            :
                                                                            <th>Sin especificar</th>
                                                            }
                                                            <th>LNOM</th>
                                                            {/* <th>Meta </th>
                                                            <th>Avance Esperado</th>
                                                            <th>Compromisos</th>
                                                            <th>Porcentaje de Avance</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {

                                                            _data.features.map(function (item, i) {
                                                                if (item.properties.poligono !== undefined) {
                                                                    return (
                                                                        <tr key={i}>
                                                                            {
                                                                                (_tipo === 'region') ?
                                                                                    <td><div style={{ color: item.properties.color }} >{item.properties.region}</div></td>
                                                                                    :
                                                                                    (_tipo === 'municipio') ?
                                                                                        <td><div style={{ color: item.properties.color }} >{item.properties.nom_mun}</div></td>
                                                                                        :
                                                                                        (_tipo === 'poligono') ?
                                                                                            <td><div style={{ color: item.properties.color }} >{item.properties.pg_poligon}</div></td>
                                                                                            :
                                                                                            (_tipo === 'seccion') ?
                                                                                                <td><div style={{ color: item.properties.color }} >{item.properties.seccion}</div></td>
                                                                                                :
                                                                                                <th>Sin especificar</th>
                                                                            }
                                                                            <td><div style={{ color: item.properties.color }} >{new Intl.NumberFormat('en-EN').format(item.properties.lnom)}</div></td>
                                                                            {/* <td><div style={{ color: item.properties.color }} >{new Intl.NumberFormat('en-EN').format(item.properties.meta_total)}</div></td>
                                                                            <td><div style={{ color: item.properties.color }} >{new Intl.NumberFormat('en-EN').format(item.properties.meta)}</div></td>
                                                                            <td><div style={{ color: item.properties.color }} >{new Intl.NumberFormat('en-EN').format(item.properties.avance)}</div></td>
                                                                            <td><div style={{ color: item.properties.color }} >{(item.properties.avance === 0 && item.properties.meta === 0) ? "--" : roundTo(item.properties.avance_porcentual, 2) + "%"}</div></td> */}
                                                                        </tr>
                                                                    )

                                                                }
                                                            })

                                                        }
                                                    </tbody>
                                                </Table>
                                            </>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                       {/*  <div className="leaflet-control-container">
                            <div className="leaflet-bottom leaflet-right">
                                <div className="legend leaflet-control" style={style_square_inferior_rangos}>
                                    <h6>Avance Compromisos:</h6>
                                    {(_rangos !== null && _rangos.length !== 0 && _rangos.length !== undefined) ?
                                        <>
                                            {
                                                _rangos.map((rango, i) => {
                                                    return <div key={(rango.id).toString()}><i style={style_rangos(rango.Color, true)}></i><b>{`${rango.Label} - Cantidad ${countRango[i]}`}</b><br></br></div>
                                                })
                                            }
                                        </>
                                        : <b> Cargando...</b>}
                                </div>
                            </div>
                        </div> */}
                        {geojson}
                        

                    </Map>
                    :
                    <>
                        <SinPermisos></SinPermisos>
                    </>
                    :

                    <div style={style_spinner}>
                        <MagicSpinner size={70} color={"#008000"} loading={true}></MagicSpinner>
                    </div>
            }
            <PluginFilterMap
                        flagFinish={_flagFinish}
                        filtrar={setFilter}
                        clasess="dropdowns"
                        Responsabilidad={Responsabilidad}
                        derechoCombo={Responsabilidad === 53 ||Responsabilidad === 54 ? [0,1,2,3]:[1,2,3]}
                        />
        </>
    );
}

export default ChoropethMap;
