import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";
import { RotateSpinner } from "react-spinners-kit";
import ReactTable from 'react-table';
import PerfectScrollbar from "perfect-scrollbar";
import swal from "sweetalert";
import { School } from '@material-ui/icons';

import { getVars, URL_PUBLICA } from "GlobalStorage";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from "data/menus";


import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
import ModalFiltro from 'components/Filtro/indexRG';
import ModalCapacitados from 'components/Capacitacion/ModalCapacitadosRG';

var ps;

function ElectoralRGs(props) {
	const mainPanel = useRef();
	const token = getVars("Token");
	let history = useHistory();
	const [_menu, _setmenu] = useState({});
	const [menu, setearMenu] = useState({});
	const [loading, setLoading] = useState(false);
	const [TotalRegistros, setTotalRegistros] = useState(0);
	const [Registros, setRegistros] = useState([]);
	const [Filtro, setFiltro] = useState([]);
	const [FiltroTxt, setFiltroTxt] = useState([]);
	const [data, setData] = useState();
	const [catCapacitaciones, setCatCapacitaciones] = useState([]);
	const [isCapacitacionSave, setCapacitacionSave] = useState(false);
	const [isLoadingDatos, setIsLoadingDatos] = useState(false);
	const [itemData, setItemData] = useState(null);
	const [MenuModal] = useState({
		Compromiso: false,
		Voluntarios: false,
		RCs: false,
		RGs: true,
		Abogado: false,
		CasaAzul: false,
		Municipio: false
	});
	const [
		ModalCapacitado,
		setModalCapacitado,
	  ] = useState(false);

	useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current);
			document.body.classList.toggle("perfect-scrollbar-on");
		}

		if (props.history.action === "PUSH") {
			mainPanel.current.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
		}

		let menuCapacitado = getDataMenu(139, token.menus);
		if (Object.keys(menuCapacitado).length > 0) {
			setearMenu(menuCapacitado);
		} 
		let menu = getDataMenu(32, token.menus);
		if (Object.keys(menu).length > 0) {
			_setmenu(menu);
		}  else {
			//El usuario no tiene el menú asignado 
		}

		if(menuCapacitado.Agregar === 1)
		{EjecutarApi({}, 'getCapacitacionesWeb', token.access_token)
			.then(function (res) {
			return res;
			})
			.then((res) => {
				if(res.success)
				{
					if(res.results)
					{
						if(res.data.length)
						{
							setCatCapacitaciones(res.data.map(item => {
								return {label: item.Nombre, value: item.id}
							}))
							
						}
					}
				}
			setData(res.data);
			})
			.catch((e) => {
			notify.show(`Error: ${e.error}`, 'custom', 5000, {
				background: '#0857B6',
				fontSize: 14,
				text: '#FFFFFF',
			});
		});}
	}, [])

	const handleMiniClick = () => {
		document.body.classList.toggle("sidebar-mini");
	};

	const handleRowAction = (item) => {

	}

	const handleBtnSetResposabilidad = () => {
		history.push("/rgs_cap");
	};

	const Exportar = () => {
		
		const data = {

			tipo: 'and',
			filtered: Filtro,
			idResponsabilidad: 3,
			Export:true
		};
	  
		  var datos = JSON.stringify(data)
		  var url = new URL(
			  `${URL_PUBLICA}ExportPropuestasElectorales?token=${token.access_token}&serializar=${datos}`
		  );
		  window.open(url, "_blank");
	}

	const handleFiltro = (filter) => {
		var _filtro = [];
		var filtroTxt =[]
		if (filter.DF.value > 0) {
			_filtro.push({ id: 'df.id', value: filter.DF.value })
			filtroTxt.push(`DF=${filter.DF.value}`)
		}
		if (filter.Region.value > 0) {
			_filtro.push({ id: 'creg.idRegion', value: filter.Region.value })
		}
		if (filter.Municipio.value > 0) {
			_filtro.push({ id: 'M.id', value: filter.Municipio.value })
			filtroTxt.push(`municipios=${filter.Municipio.value}`)
		}
		// if (filter.Poligono.value > 0) {
		// 	_filtro.push({ id: "s.idPoligono", value: filter.Poligono.value })
		// }
		// if (filter.Seccion.value > 0) {
		// 	_filtro.push({ id: "s.Seccion", value: filter.Seccion.value })
		// }
		if (filter.ClaveElector.length > 0) {
			_filtro.push({ id: "rgs.ClaveElector", value: filter.ClaveElector })
		}
		if (filter.Nombre.length > 0) {
			_filtro.push({ id: "rgs.Nombre", value: filter.Nombre })
		}
		if (filter.Paterno.length > 0) {
			_filtro.push({ id: "rgs.Paterno", value: filter.Paterno })
		}
		if (filter.Materno.length > 0) {
			_filtro.push({ id: "rgs.Materno", value: filter.Materno })
		}
		// if (filter.Casilla.value > 0) {
		// 	_filtro.push({ id: "rcs.NombreCasilla", value: filter.Casilla.value })
		// }
		// if (filter.TipoCasilla.value > 0) {
		// 	_filtro.push({ id: "rcs.tipoCasilla", value: filter.TipoCasilla.value })
		// }
		if (filter.Funcionario > 0) {
			_filtro.push({ id: "rgs.TieneExperiencia", value: filter.Funcionario ? 1 : 0 })
		}
		if (filter.Auto > 0) {
			_filtro.push({ id: "rgs.TieneAuto", value: filter.Auto ? 1 : 0 })
		}

		setFiltro(_filtro);
		setFiltroTxt(filtroTxt);
		const data = {
			page: 0,
			pageSize: 10,
			filtered: _filtro,
			idResponsabilidad: 3,
			tipo: 'and'
		};

		getRegistros(data);
	}

	const columns = [
		{
			Header: "Distrito",
			id: "df.DistritoFederal",
			accessor: "Distrito",
			filterAll: false,
		},
		{
			Header: "RG",
			id: "rgs.Nombre",
			accessor: "RG",
			filterAll: false,
		},
		{
			Header: 'Capacitado',
			id: 'capacitado',
			show: menu.Agregar === 1 ?true:false ,
			accessor: "capacitado",
			filterAll: false,
		},
		{
			Header: "ColoniaVive",
			id: "rgs.ColoniaVive",
			accessor: "ColoniaVive",
			filterAll: false,
		},
		{
			Header: "MunicipioVive",
			id: "M.Municipio",
			accessor: "MunicipioVive",
			filterAll: false,
		}
		,
		{
			Header: "Ruta",
			id: "r.NombreRuta",
			accessor: "NombreRuta",
			filterAll: false,
		},
		{
			Header: "Tiene Auto",
			id: "TieneAuto",
			accessor: "TieneAuto",
			filterAll: false,
		},
		{
			Header: "Tiene Experiencia",
			id: "TieneExperiencia",
			accessor: "TieneExperiencia",
			filterAll: false,
		},

	];

	const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
		const data = {
			page: page,
			pageSize: pageSize,
			filtered: params,
			sorted: sorted,
			idResponsabilidad: 3,
			tipo: "and"
		};
		getRegistros(data);
	}, []);

	const getRegistros = (data) => {
		setLoading(true);
		let _menuStorage = getDataMenu(32, token.menus);

		EjecutarApi(data, "getCompromisosResponsabilidadPropuesta", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				setLoading(false);
				setTotalRegistros(res.total);
				setRegistros(
					res.data.map((item,capacitado) => {
						return {
							id: item.id,
							//Partido: item.siglas ? item.siglas : '',
							RG: _menuStorage.Eliminar === 1 ? (
								<div className="text-center"><Button className="btn-link"
									type="button"
									color="danger"
									onClick={(e) => EliminarResponsablidad(item, e)}
								>
									<i class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
								</Button>{item.Fullname}
								</div>) : (<>{item.Fullname}</>),
							FechaPropuesta: item.FechaAsignoResponsabilidad,
							//QuienPropone: item.ResponsablePropuesta,
							TieneExperiencia: item.TieneExperiencia === 1 ? 'Si' : 'No',
							TieneAuto: item.TieneAuto === 1 ? 'Si' : 'No',
							Distrito: item.Distrito.label,
							MunicipioVive: item.Municipio.label,
							ColoniaVive: item.Colonia,
							NombreRuta: item.NombreRuta ? item.NombreRuta : "Sin Ruta",
						    capacitado: <><Button className="btn-round" size="sm" color="primary" onClick={() =>
								getCapacitados(item)
							  }><span className="btn-label">
							<School style={{ color: '#FFF' }} />
						  </span></Button> </>
						};
					})
				);
			})
			.catch((e) => {
				setLoading(false);
				notify.show("Error al cargar registros", "error", 5000);
			});
	}
	const getCapacitados = (item) => {
		setItemData(item);
		setModalCapacitado(true)

	  };

	const EliminarResponsablidad = (item, e) => {
		e.preventDefault();
		swal({
			title: "¿Está Seguro?",
			text: `Que desea eliminar la responsabilidad de ${item.Fullname}, como RC`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancelar",
					value: null,
					visible: true,
					className: "btn-danger",
					closeModal: true,
				},
				confirm: {
					text: "Si, eliminar!",
					value: true,
					visible: true,
					className: "btn-success",
					closeModal: true
				}
			}
		})
			.then((willDelete) => {
				if (willDelete) {
					removeResponsablidad(item);
				} else {

				}
			});
	}
	const removeResponsablidad = (item) => {
		EjecutarApi({ idCompromiso: item.idCompromiso }, 'eliminarResponsablesWeb', token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.results) {
					notify.show(res.message, "success", 5000, {
						background: "#d61818",
						text: "#FFFFFF",
					});
					let data = {
						page: 0,
						pageSize: 10,
						filtered: Filtro,
						sorted: [],
						idResponsabilidad: 9,
						tipo: "and"
					}
					getRegistros(data)
				}
				else {
					notify.show(res.message, "error", 5000, {
						background: "#d61818",
						text: "#FFFFFF",
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
					background: "#d61818",
					text: "#FFFFFF",
				});
			});
	};
	const DatosCapacitacion= (state) => {
		
		if (!state.idCapacitacion) {
			setLoading(false);
			notify.show("Error: Evento es requerido", "error", 5000, {
			  background: "#0857B6",
			  fontSize: 14,
			  text: "#FFFFFF",
			});
			return true;
		}
		
		setIsLoadingDatos(true);

		EjecutarApi(state, "setInvitadosSingleWeb", token.access_token)
		.then(function (res){
	
			return res;
		})
		.then((res)=>{
			if (res.success) {
				if (res.results) {
						notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
							background: "#18c97d",
							fontSize: 24,
							text: "#FFFFFF",
						});
						setLoading(false);
					}else
					{
						setLoading(false);
						notify.show(
							`Error al agregar registro: ${res.message}!!`,
							"custom",
							5000,
							{
							background: "#0857B6",
							fontSize: 24,
							text: "#FFFFFF",
							}
						);
						return true;
					}
			} else {
			setLoading(false);
			notify.show(
				`Error al insertar el registro: ${res.message}!!`,
				"custom",
				5000,
				{
				background: "#0857B6",
				fontSize: 24,
				text: "#FFFFFF",
				}
			);}
			return true;
		})
		.catch((e) => {
			setLoading(false);
			notify.show(`Error al agregar el registro: ${e.error}`, "custom", 5000, {
			background: "#0857B6",
			fontSize: 24,
			text: "#FFFFFF",
			});
		});
		
	};
	const ExportarGral = () =>{
		let filtro="";
		if(FiltroTxt.length > 1){
			FiltroTxt.forEach(element => {
				filtro=filtro+`&${element}`
			});
		}else if(FiltroTxt.length == 1){
			filtro=`&${FiltroTxt[0]}`
		}

		var url = new URL(URL_PUBLICA +
			`getTxtEstructuraRepreGral?token=${token.access_token}${filtro}`
		);
		window.open(url, "_blank");
	}
	
	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel ps ps--active-y" ref={mainPanel}>
				<Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
				<div className="content">
					<Row>
						{ModalCapacitado && itemData !== null && (
							<ModalCapacitados
							isOpen={ModalCapacitado}
							item={itemData}
							token={token}
							catCapacitaciones={catCapacitaciones}
							DatosCapacitacion={DatosCapacitacion}
							setIsOpen={() =>
								setModalCapacitado(false)
							}
							/>
						)}
						<Col sm="12">
							<Card>
								<CardHeader>
									<h5 className="title">Listado de Responsabilidad RGs</h5>
									<Col sm="12" md="12" lg="12">
										<Row>
											{/*_menu.Agregar === 1 ? (<><Button color="success" size="sm" onClick={() => handleBtnSetResposabilidad()} >
												<span className="btn-label">
													<i className="fa fa-user-plus fa-lg" />
												</span>
												Capturar
												</Button></>) : <></>*/}
											{_menu.Exportar === 1 ? (
												<Button size='sm' className='primary left-align' color='primary' onClick={() => Exportar()}>
													<span className="btn-label">
														<i className="fa fa-file-excel-o fa-lg" />
													</span>
													Exportar
												</Button>
											) : <></>}
											{_menu.Seguimiento === 1 ? (
												<>
												<Button size='sm' className='primary left-align' color='primary' onClick={() => ExportarGral()}>
													<span className="btn-label">
														<i className="fa fa-file-excel-o fa-lg" />
													</span>
													Exportar txt
												</Button>
											</>
											) : <></>}
										</Row>
										<Row>
											<Col sm="12">
												<ModalFiltro Menu={MenuModal} Datos={(e) => handleFiltro(e)} />
											</Col>
										</Row>
									</Col>
								</CardHeader>
								<CardBody>
									{<RotateSpinner color={"#1261ff"} size={30} loading={loading} />}
									<div style={{ 'width': '100%', 'overflow': 'auto' }}>
										<ReactTable
											style={{
												width: "100%",
												height: "100%"
											}}
											size='sm'
											data={Registros}
											noDataText="Sin información"
											manual
											columns={columns}
											className="-striped -highlight primary-pagination"
											loading={loading}
											showPagination={true}
											showPaginationBottom={true}
											showTotalPages={true}
											showPageJump={false}
											canNextFromData={true}
											defaultPageSize={10}
											total={TotalRegistros}
											pages={Math.floor(TotalRegistros / 10)}
											previousText="Anterior"
											nextText="Siguiente"
											ofText="de"
											pageSizeOptions={[10, 20, 25, 50, 100]}
											PaginationComponent={Pagination}
											onFetchData={fetchData}
											params={Filtro}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
				<Footer fluid />
			</div>
		</div>
	);
}
export default ElectoralRGs;