import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer"
import Sidebar from "components/Sidebar";
import { getVars } from 'GlobalStorage';
import ReactTable from 'react-table';

import {
  Button, Card, CardHeader, CardBody, Row, Col, TabContent,
  TabPane, NavItem, Label, FormGroup, Jumbotron,
  NavLink,
  Nav,
} from 'reactstrap';
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { CircleSpinner } from "react-spinners-kit";
import { URL_GENERAL } from "data/Consts";
import Pagination from "components/Pagination/pagination";
import RCsChart from 'components/Charts/RCsChart/index';
import NumeraliaGrf from 'components/Charts/NumeraliaMetas/index';
import ListadoResponsables from "../../components/Estructura/ListadoResponsablesElectoral";

const EstructuraElectoralMunicipal = (props) => {

  // Configuracion
  const [setsidebarMini] = useState(true);
  const [backgroundColor] = useState("black");
  const [activeColor] = useState("info");
  const [loading, setLoading] = useState(false);
  const [loadingTabla, setLoadingTabla] = useState(false);
  const token = getVars('Token');
  const [horizontalTabs, setHorizontalTabs] = useState({
    horizontalTabs: "RCS",
    openedCollapses: ["collapseOne", "collapse1"]
  })
  var Responsabilidad = token.user.Responsabilidad.value;

  const [Filtro, setFiltro] = useState([]);
  const [total, setTotal] = useState(0);
  const [meta, setMeta] = useState([]);
  const [municipioSelected, setMunicipioSelected] = useState({ value: 0, label: 'Todos' });
  const [regionSelected, setRegionSelected] = useState({ value: 0, label: 'Todos' })
  const [tabla, setTabla] = useState("RCS");
  const [vista, setVista] = useState(true);
  const [message, setMessage] = useState(" ");
  const [showResponsable, setShowResponsable] = useState(false);
  const [reponsables, setResponsables] = useState(false);

  // Catalogos

  const [showMeta, setShowMeta] = useState(1);
  const [col, setCol] = useState("12");


  //catalogos
  const [catMunicipioFilter, setCatMunicipioFilter] = useState([])

  const [catRegiones, setCatRegiones] = useState([{ value: 0, label: 'Todos' }])
  const [catMunicipio, setCatMunicipio] = useState([{ value: 0, label: 'Todos' }])
  const [titulo, setTitulo] = useState("Región");
  const [grafica, setGrafica] = useState(null);



  const [menu, _setmenu] = useState({});
  const responsibilityID = token.user.Responsabilidad.value;

  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(133, token.menus));

    let regionesFormated = [];
    let municipioFormated = [];
    EjecutarApi({}, "getCatalogosFiltrosElectoralClonIdMunicipioReportes", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {
            let regiones = [{ value: 0, label: 'Todos' }]
            let municipios = [{ value: 0, label: 'Todos' }]

            regionesFormated = res.data.regiones.map(item => {
              return {
                value: item.value,
                label: item.label
              }
            })
            const result = regionesFormated.filter(reg => reg.label !== 'NO DEFINIDO');
            setCatRegiones(regiones.concat(result))
            //CatMunicipio llenar

            municipioFormated = res.data.municipios.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
              }
            })
            setCatMunicipio(municipios.concat(municipioFormated))

            setCatMunicipioFilter(municipios.concat(municipioFormated))

          } else {
            showMessage("No Info: getCatalogosFiltrosElectoral", '#0E7CF1')
          }
        }
      })
      .catch((e) => {
        showMessage("Error: getCatalogosFiltrosElectoral", "#F1370E")
      }).then(() => {
        setLoading(false);
      })

  }, []);

    useEffect(() => {
      if (horizontalTabs.horizontalTabs === "RCS") {
          setShowMeta(1)
      }
      if (horizontalTabs.horizontalTabs === "RGS") {
          setShowMeta(2)
      }
      if (horizontalTabs.horizontalTabs === "Abogados") {
          setShowMeta(3)
      }
      if (horizontalTabs.horizontalTabs === "Observador") {
          setShowMeta(4)
      }
      if (horizontalTabs.horizontalTabs === "CasaAmiga") {
          setShowMeta(5)
      }
      if (horizontalTabs.horizontalTabs === "PrimeroFila") {
        setShowMeta(6)
    }
  }, [horizontalTabs])

  const showMessage = (message) => {
    setVista(false)
    setMessage(message)
  }

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
    } else {
      setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };


  // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA

  const handleRegiones = (e) => {
    setLoadingTabla(true)
    setLoading(true)
    setRegionSelected(e)

    if (e.value >= 1) {
      let munici = [{ value: 0, label: 'Todos' }];
      let filterMunicipio = catMunicipio.filter(data => data.idRegion === e.value);
      
      setCatMunicipioFilter(munici.concat(filterMunicipio));
      fitroSend(e, "region");

    } else {
      setCatMunicipioFilter(catMunicipio);
      fitroSend(e, "region");
    }
    setLoading(false);
  }


  const fitroSend = (e, handle, t = false) => {

    var filtro = [];
    if (handle !== "clear") {
      if (e.value > 0 && handle === "region") { filtro.push({ "id": "municipio.idRegion", "value": e.value }) }
      if (e.value > 0 && handle === "municipio") { filtro.push({ "id": "municipio.id", "value": e.value }) }
      if (regionSelected.value > 0 && handle !== "region") { filtro.push({ "id": "municipio.idRegion", "value": regionSelected.value }) }
      if (municipioSelected.value > 0 && handle !== "municipio") { 
        if(responsibilityID !== 53){
        filtro.push({ "id": "municipio.id", "value": municipioSelected.value }) 
        }else{
          filtro.push({ "id": "municipio.idMunicipio", "value": municipioSelected.value }) 
        }
      }
    }


  
   setFiltro(filtro); 
    const dato = (t) ? t = t : t = tabla;
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      tipo: 'and',
      filtered: filtro,
      Electoral: dato
    };
    EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setLoadingTabla(false);
            setTotal(res.total);
            if (t !== "RCS") {
              setMeta(res.data.map((prop, key) => {
                var porcentaje = parseFloat(prop.Porcentaje)
                var ddporcentaje = porcentaje.toFixed(2)
                return {
                  id: prop.id,
                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                  meta: prop.Meta,
                  AvanceEstructura: prop.Meta ? prop.Meta : 0,
                  AvanceRGs: prop.Total > 0 ? (<><h6>{prop.Total}</h6><Button onClick={() => onClickShowResponsables(prop, "Observador")} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.Total ? prop.Total : 0),
                  porcentaje: ddporcentaje ? ddporcentaje : 0,
                  data: prop,
                };
              })
              );

              var categorias = res.grafica.map((prop, key) => {
                return prop.Municipio;
            })
     
            var Meta = res.grafica.map((prop, key) => {
                 return prop.Meta;
             })
     
             var Avance = res.grafica.map((prop, key) => {
                 return prop.Total;
             })
     
             const data= {
         
                 title: `Avance ${horizontalTabs.horizontalTabs}` ,
                 xAxis: {
                     categories: categorias,
                     title: {
                        text: "Municipios"
                    }
                 },
                 yAxis: [{
                     min: 0,
                     title: {
                         text:`${titulo}`
                     }
                 }],
             };
     
             const  series= [
                 {
                      name: 'Meta',
                      color: 'rgba(147,220,236,.9)',
                      data: Meta,
                      pointPadding: 0.3,
                      pointPlacement: -0.2
                  }, {
                      name: 'Avance',
                      color: '#0041A0',
                      data: Avance,
                      pointPadding: 0.4,
                      pointPlacement: -0.2
             } ];
            setGrafica({
                data:data,
                series:series
            })
            }
            else if (t === "RCS") {
              setMeta(res.data.map((prop, key) => {
                return {
                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                  meta: prop.Meta,
                  metaGeneral: prop.MetaGeneral,
                  totalGeneral: prop.TotalGeneral ? prop.TotalGeneral:0,
                  porcentajeGeneral: prop.porcentajeGeneral ? prop.porcentajeGeneral:0 ,
                  AvanceEstructuraGeneral: prop.ElectoralDiaGeneral ? prop.ElectoralDiaGeneral : 0,
                  AvanceEstructura: prop.ElectoralDia ? prop.ElectoralDia : 0,
                  AvancePro1: prop.AvanceRCP > 0 ? (<><h6>{prop.AvanceRCP}</h6><Button onClick={() => onClickShowResponsables(prop, "Propietario 1", 1)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceRCP ? prop.AvanceRCP : 0),
                  AvancePro2: prop.AvanceRCPP > 0 ? (<><h6>{prop.AvanceRCPP}</h6><Button onClick={() => onClickShowResponsables(prop, "Propietario 2", 2)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceRCPP ? prop.AvanceRCPP : 0),
                  AvanceSup1: prop.AvanceRCS > 0 ? (<><h6>{prop.AvanceRCS}</h6><Button onClick={() => onClickShowResponsables(prop, "Suplente 1", 3)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceRCS ? prop.AvanceRCS : 0),
                  AvanceSup2: prop.AvanceRCSS > 0 ? (<><h6>{prop.AvanceRCSS}</h6><Button onClick={() => onClickShowResponsables(prop, "Suplente 2", 4)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceRCSS ? prop.AvanceRCSS : 0),
                  PorcentajePropietario1: prop.porcentaje1 ? prop.porcentaje1 : 0,
                  PorcentajePropietario2: prop.porcentaje2 ? prop.porcentaje2 : 0,
                  PorcentajeSuplente1: prop.porcentaje3 ? prop.porcentaje3 : 0,
                  PorcentajeSuplente2: prop.porcentaje4 ? prop.porcentaje4 : 0,
                  data: prop,
                };
              })
              );

              var categorias = res.grafica.map((prop, key) => {
                return {
                  name: `${prop.Municipio}`,
                  color: "#0041AC",
                  y:(prop.AvanceRCP+prop.AvanceRCPP+prop.AvanceRCS+prop.AvanceRCSS),
                  drilldown:  `${prop.Municipio}`,
                };
              })
              var drilldownSerie = '';
              res.grafica.forEach( item => {
                
                var  prop1= `["Propietario 1", ${item.AvanceRCP}]`;
                var prop2= prop1 + `,["Propietario 2", ${item.AvanceRCPP}]`;
                var sup1 = prop2 + `,["Suplente 1", ${item.AvanceRCS}]`;
                var sup2 = sup1 + `,["Suplente 2", ${item.AvanceRCSS}]`;

                
                drilldownSerie = drilldownSerie +`if (e.point.name === "${item.Municipio}") { chart.addSingleSeriesAsDrilldown(e.point, { name: "${item.Municipio}", color: "#2A71E5", data: [${sup2}] }); } `;
                
                })
                drilldownSerie = `var chart = this; ` + drilldownSerie + ' chart.applyDrilldown();';

              const data = {
                title: "RCs",
                subtitle: "Avance",
                yAxis: "RCs",
                serie: [{
                  name: "Municipio",
                  data: categorias,
                  color: "#0041AC"
                }],
                SerieOption: drilldownSerie
              }

              setGrafica({
                data: data
              })
            }

          }
        }
      })
      .catch((e) => {
        setLoadingTabla(false);
        notify.show(
          `Error: ${e.message}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });

  }

  const LimpiarFiltro = () => {
    setMunicipioSelected({ value: 0, label: 'Todos' })
    setRegionSelected({ value: 0, label: 'Todos' })

    fitroSend("", "clear");
  }

  const changeTap = (e) => {
    setLoadingTabla(true);
    setHorizontalTabs({ ...horizontalTabs, horizontalTabs: e })


    setTabla(e)
    setCol("12")

    setTitulo(e)
  
    setShowMeta(-1)
  }

  const handleMunicipio = (e) => {
    setLoadingTabla(true)
    setLoading(true)
    setMunicipioSelected(e)

    if (e.value > 1) {
      fitroSend(e, "municipio");

    } else {
      setCatMunicipioFilter(catMunicipio);
      fitroSend(e, "municipio");
    }
    setLoading(false);
  }


  const Exportar = () => {
   
    
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      tipo: 'and',
      filtered: Filtro,
      Electoral: tabla,
      Export:true
  };

    var datos = JSON.stringify(data)
    var url = new URL(
        `${URL_GENERAL}ExportEstructuraMunicipalElectoral?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");

  }

  const onHide = () => {

    setShowResponsable(false);

  };
  const onClickShowResponsables = (props, value, rc) => {
    setShowResponsable(true);

    if (rc) {
      setResponsables({
        responsibilityID: Responsabilidad,
        value: value,
        id: props.id,
        label: props.Municipio,
        zona: tabla,
        rc: rc
      });
    } else {
      setResponsables({
        responsibilityID: Responsabilidad,
        value: value,
        id: props.id,
        label: props.Municipio,
        zona: tabla
      });
    }
  }

  //Estructura

  const showRegion = Responsabilidad === 99 || Responsabilidad === 98 || Responsabilidad === 16

  const showMunicipio = (Responsabilidad === 99 || Responsabilidad === 98 || Responsabilidad === 16 || Responsabilidad === 17 || Responsabilidad === 53 || Responsabilidad === 54 || Responsabilidad === 55)
  
  const styleHeader = {
    textAlign: "center",
    overflow: "visible",
    fontSize: "11px",
    color: "#363636",
    lineHeight: "normal !important"
  }

  const header = (text) => {
    return (<Label style={styleHeader}>{text}</Label>)
  }


  return (
    <div className="wrapper">
      <Notifications />
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
        <div className="content">
          {vista &&
            <div className="content">
              {/* Configuracón Estructura Metas */}
              {showResponsable !== true &&
                <Row>
                  <Col sm={col}>
                    <Card>
                    {grafica && horizontalTabs.horizontalTabs === "RCS" &&
                    <Col sm="12">
                      <RCsChart data={grafica.data} /> 
                    </Col>
                    }
                    {grafica && horizontalTabs.horizontalTabs !== "RCS" &&
                    <Col sm="12">
                      <NumeraliaGrf datos={grafica.series} Titulos={grafica.data} /> 
                    </Col>
                    }
                      <CardHeader>
                        <Row >
                          {showRegion &&
                            <Col lg="4" md="4" sm="4">
                              <FormGroup>
                                <Label for="Estado">
                                  Región
                              </Label>
                                <Select
                                  options={catRegiones}
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  defaultValue={catRegiones[0]}
                                  value={regionSelected}
                                  onChange={(e) => handleRegiones(e)}
                                  id="regiones"
                                  placeholder="regiones"
                                />
                              </FormGroup>
                            </Col>}
                          {showMunicipio &&
                            <Col lg="4" md="4" sm="4">
                              <FormGroup>
                                <Label for="Municipio">
                                  Municipios
                              </Label>
                                <Select
                                  options={catMunicipioFilter}
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  value={municipioSelected}
                                  onChange={(e) => handleMunicipio(e)}
                                  id="municipio"
                                  placeholder="Municipio"
                                />
                              </FormGroup>
                            </Col>
                          }
                        </Row>
                        <Row>
                          <Col sm='4'>
                            <Button name="btnLimpiar" size="sm" type="button" className="success float-left" color='warning' onClick={() => { LimpiarFiltro() }}>Limpiar</Button>
                          </Col>
                          {(menu.Exportar === 1 && !loadingTabla) ? (
                            <Col sm='8'>
                              <Button name="btnExportar" size='sm' type="button" className='primary float-right' color='primary' onClick={() => Exportar()}>
                                <span className="btn-label">
                                  <i className="fa fa-file-excel-o fa-lg" />
                                </span>
                            Exportar
							</Button>
                            </Col>
                          ) : <></>}
                        </Row>

                      </CardHeader>
                      <CardBody>
                        <div className="nav-tabs-navigation">
                          <div className="nav-tabs-wrapper">
                            <Nav id="tabs" role="tablist" tabs>
                              <NavItem>
                                <NavLink
                                  aria-expanded={horizontalTabs.horizontalTabs === "RCS"}
                                  data-toggle="tab"
                                  role="tab"
                                  className={horizontalTabs.horizontalTabs === "RCS"
                                    ? "active"
                                    : ""
                                  }
                                  onClick={() => { changeTap("RCS") }}
                                >
                                  RCs
                            </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  aria-expanded={horizontalTabs.horizontalTabs === "RGS"
                                  }
                                  data-toggle="tab"
                                  //href="#pablo"
                                  role="tab"
                                  className={horizontalTabs.horizontalTabs === "RGS"
                                    ? "active"
                                    : ""
                                  }
                                  onClick={() => changeTap("RGS")}
                                >
                                  RGs
                            </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  aria-expanded={horizontalTabs.horizontalTabs === "Abogados"}
                                  data-toggle="tab"
                                  //href="#pablo"
                                  role="tab"
                                  className={horizontalTabs.horizontalTabs === "Abogados"
                                    ? "active"
                                    : ""
                                  }
                                  onClick={() =>
                                    changeTap("Abogados")}
                                >
                                  Abogados
                            </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  aria-expanded={horizontalTabs.horizontalTabs === "Observador"}
                                  data-toggle="tab"
                                  //href="#pablo"
                                  role="tab"
                                  className={horizontalTabs.horizontalTabs === "Observador"
                                    ? "active"
                                    : ""
                                  }
                                  onClick={() =>
                                    changeTap("Observador")}
                                >
                                  Observadores
                        </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  aria-expanded={horizontalTabs.horizontalTabs === "PrimeroFila"}
                                  data-toggle="tab"
                                  //href="#pablo"
                                  role="tab"
                                  className={horizontalTabs.horizontalTabs === "PrimeroFila"
                                    ? "active"
                                    : ""
                                  }
                                  onClick={() =>
                                    changeTap("PrimeroFila")}
                                >
                                  Primero En Fila
                    </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  aria-expanded={horizontalTabs.horizontalTabs === "CasaAmiga"}
                                  data-toggle="tab"
                                  //href="#pablo"
                                  role="tab"
                                  className={horizontalTabs.horizontalTabs === "CasaAmiga"
                                    ? "active"
                                    : ""
                                  }
                                  onClick={() =>
                                    changeTap("CasaAmiga")}
                                >
                                  Casa Azul
                    </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </div>
                        <TabContent
                          className="text-center"
                          id="my-tab-content"
                          activeTab={horizontalTabs.horizontalTabs}
                        >
                          <TabPane tabId="RCS" role="tabpanel">
                          {showMeta === 1?
                            <ReactTable
                            style={{
                              width: "100%",
                              height: "100%"
                            }}
                              size='sm'
                              data={meta}
                              noDataText="Sin información"
                              manual
                              columns={[
                                {
                                  Header: header( "Municipio"),
                                  minWidth: 40,
                                  id: "Municipio",
                                  accessor: (d) => d.Municipio,
                                  filterAll: false,
                                },
                                {
                                  Header: header("Meta General"),
                                  accessor: (d) => d.metaGeneral,
                                  minWidth: 30,
                                  id: "MetaGeneral",
                                  filterable: false,
                                },
                                {
                                  Header: header("A.Esperado General"),
                                  accessor: (d) => d.AvanceEstructuraGeneral,
                                  minWidth: 40,
                                  id: "ElectoralDiaGeneral",
                                  filterable: false,
                                },
                                {
                                  Header: header("A.General"),
                                  accessor: (d) => d.totalGeneral,
                                  minWidth: 40,
                                  id: "TotalGeneral",
                                  filterable: false,
                                },
                                {
                                  Header: header("% A.General"),
                                  accessor: (d) => d.porcentajeGeneral,
                                  minWidth: 40,
                                  Cell: props => <>{(props.value >= 0 && props.value < 80) ? (<strong style={{ color: "#FF0001" }}>{props.value} %</strong>) : ((props.value >= 80 && props.value < 99) ? <strong style={{ color: "#FA9B06" }}>{props.value} %</strong> : (<strong style={{ color: "#4BB543" }}>{props.value} %</strong>))}</>,
                                  id: "porcentajeGeneral",
                                  filterable: false,
                                },
                                {
                                  Header:header("Meta"),
                                  accessor: (d) => d.meta,
                                  minWidth: 40,
                                  id: "municipio.Meta",
                                  filterable: false,
                                },
                                {
                                  Header: header("A. Esperado"),
                                  accessor: (d) => d.AvanceEstructura,
                                  minWidth: 40,
                                  id: "AvanceEstructura",
                                  filterable: false,
                                },
                                {
                                  Header: header("A. P1"),
                                  accessor: (d) => d.AvancePro1,
                                  minWidth: 40,
                                  id: "AvancePro1",
                                  filterable: false,
                                },
                                {
                                  Header: header("A. P2"),
                                  accessor: (d) => d.AvancePro2,
                                  minWidth: 40,
                                  id: "AvancePro2",
                                  filterable: false,
                                },
                                {
                                  Header: header("A. S1"),
                                  accessor: (d) => d.AvanceSup1,
                                  minWidth: 40,
                                  id: "AvanceSub1",
                                  filterable: false,
                                },
                                {
                                  Header: header("A. S2"),
                                  accessor: (d) => d.AvanceSup2,
                                  minWidth: 40,
                                  id: "AvanceSub2",
                                  filterable: false,
                                },
                                {
                                  Header: header("% A.P1"),
                                  accessor: (d) => d.PorcentajePropietario1,
                                  Cell: props => <div>{props.value + " %"}</div>,
                                  minWidth: 40,
                                  id: "porcentajePro1",
                                  filterable: false,
                                },
                                {
                                  Header: header("% A. P2"),
                                  accessor: (d) => d.PorcentajePropietario2,
                                  Cell: props => <div>{props.value + " %"}</div>,
                                  minWidth: 40,
                                  id: "porcentajePro2",
                                  filterable: false,
                                },
                                {
                                  Header: header("% A. S1"),
                                  accessor: (d) => d.PorcentajeSuplente1,
                                  Cell: props => <div>{props.value + " %"}</div>,
                                  minWidth: 40,
                                  id: "porcentajeSu1",
                                  filterable: false,
                                },
                                {
                                  Header: header("% A. S2"),
                                  accessor: (d) => d.PorcentajeSuplente2,
                                  Cell: props => <div>{props.value + " %"}</div>,
                                  minWidth: 40,
                                  id: "porcentajeSu2",
                                  filterable: false,
                                },
                              ]}
                              className="-striped -highlight primary-pagination"
                              loading={loadingTabla}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={10}
                              total={total}
                              pages={Math.floor(total / 10)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={(stateComp, instance) => {
                                setLoadingTabla(true);
                                let _menuStorage = getDataMenu(72, token.menus);
                                const data = {
                                  page: stateComp.page,
                                  pageSize: stateComp.pageSize,
                                  sorted: [{ id: "municipio.Municipio", desc: false }],
                                  tipo: 'and',
                                  filtered: Filtro ? Filtro : [],
                                  Electoral: "RCS"
                                };
                                EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                  .then(function (res) {
                                    return res;
                                  })
                                  .then((res) => {
                                    if (res.success) {
                                      if (res.results) {
                                        setTotal(res.total);
                                        setMeta(
                                          res.data.map((prop, key) => {
                                            return {
                                              Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                              meta: prop.Meta,
                                              metaGeneral: prop.MetaGeneral,
                                              totalGeneral: prop.TotalGeneral ? prop.TotalGeneral:0,
                                              porcentajeGeneral: prop.porcentajeGeneral ? prop.porcentajeGeneral:0 ,
                                              AvanceEstructuraGeneral: prop.ElectoralDiaGeneral ? prop.ElectoralDiaGeneral : 0,
                                              AvanceEstructura: prop.ElectoralDia ? prop.ElectoralDia : 0,
                                              AvancePro1: prop.AvanceRCP > 0 ? (<><h6>{prop.AvanceRCP}</h6><Button onClick={() => onClickShowResponsables(prop, "Propietario 1", 1)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceRCP ? prop.AvanceRCP : 0),
                                              AvancePro2: prop.AvanceRCPP > 0 ? (<><h6>{prop.AvanceRCPP}</h6><Button onClick={() => onClickShowResponsables(prop, "Propietario 2", 2)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceRCPP ? prop.AvanceRCPP : 0),
                                              AvanceSup1: prop.AvanceRCS > 0 ? (<><h6>{prop.AvanceRCS}</h6><Button onClick={() => onClickShowResponsables(prop, "Suplente 1", 3)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceRCS ? prop.AvanceRCS : 0),
                                              AvanceSup2: prop.AvanceRCSS > 0 ? (<><h6>{prop.AvanceRCSS}</h6><Button onClick={() => onClickShowResponsables(prop, "Suplente 2", 4)} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.AvanceRCSS ? prop.AvanceRCSS : 0),
                                              PorcentajePropietario1: prop.porcentaje1 ? prop.porcentaje1 : 0,
                                              PorcentajePropietario2: prop.porcentaje2 ? prop.porcentaje2 : 0,
                                              PorcentajeSuplente1: prop.porcentaje3 ? prop.porcentaje3 : 0,
                                              PorcentajeSuplente2: prop.porcentaje4 ? prop.porcentaje4 : 0,
                                              data: prop,
                                            };
                                          })
                                        );
                                        setLoadingTabla(false);
                                      }
                                    }

                                    var categorias = res.grafica.map((prop, key) => {
                                      return {
                                        name: prop.Municipio,
                                        color: "#0041AC",
                                        y:(prop.AvanceRCP+prop.AvanceRCPP+prop.AvanceRCS+prop.AvanceRCSS),
                                        drilldown: prop.Municipio,
                                      };
                                    })

                                    var drilldownSerie = '';
                                    res.grafica.forEach( item => {
                                      
                                      var  prop1= `["Propietario 1", ${item.AvanceRCP}]`;
                                      var prop2= prop1 + `,["Propietario 2", ${item.AvanceRCPP}]`;
                                      var sup1 = prop2 + `,["Suplente 1", ${item.AvanceRCS}]`;
                                      var sup2 = sup1 + `,["Suplente 2", ${item.AvanceRCSS}]`;

                                      
                                      drilldownSerie = drilldownSerie +`if (e.point.name === "${item.Municipio}") { chart.addSingleSeriesAsDrilldown(e.point, { name: "${item.Municipio}", id:"${item.Municipio}" ,color: "#2A71E5", data: [${sup2}] }); } `;
                                      
                                      })
                                    
                                    drilldownSerie = `var chart = this; ` + drilldownSerie + ' chart.applyDrilldown();';

                                    const data = {
                                      title: "RCs",
                                      subtitle: "Avance",
                                      yAxis: "RCs",
                                      serie: [{
                                        name: "Municipios",
                                        data: categorias,
                                        color: "#0041AC"
                                      }],
                                      SerieOption: drilldownSerie
                                    }

                                    
   
                                    setGrafica({
                                      data: data
                                    })
                                  })
                                  .catch((e) => {
                                    setLoading(false);
                                    notify.show(
                                      `Error al obtener tabla metas estructura region: ${e.message}`,
                                      "custom",
                                      5000,
                                      {
                                        background: "#0857B6",
                                        fontSize: 14,
                                        text: "#FFFFFF",
                                      }
                                    );
                                  });
                              }}
                            />:
                            <></>
                            }
                          </TabPane>
                          <TabPane tabId="RGS" role="tabpanel">
                            {showMeta === 2 ?
                              <ReactTable
                                size='sm'
                                data={meta}
                                noDataText="Sin información"
                                manual
                                columns={[
                                  {
                                    Header: "Municipio",
                                    minWidth: 70,
                                    id: "Municipio",
                                    accessor: (d) => d.Municipio,
                                    filterAll: false,
                                  },
                                  {
                                    Header: "Meta",
                                    accessor: (d) => d.meta,
                                    minWidth: 40,
                                    id: "meta",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance Esperado",
                                    accessor: (d) => d.AvanceEstructura,
                                    minWidth: 40,
                                    id: "AvanceEstructura",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance RGs",
                                    accessor: (d) => d.AvanceRGs,
                                    minWidth: 40,
                                    id: "AvanceRGS",
                                    filterable: false,
                                  },
                                  {
                                    Header: "% Avance",
                                    accessor: (d) => d.porcentaje,
                                    Cell: props => <div>{props.value + " %"}</div>,
                                    minWidth: 40,
                                    id: "porcentaje",
                                    filterable: false,
                                  },

                                ]}
                                className="-striped -highlight primary-pagination"
                                loading={loadingTabla}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={10}
                                total={total}
                                pages={Math.floor(total / 10)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                PaginationComponent={Pagination}
                                onFetchData={(stateComp, instance) => {
                                  setLoadingTabla(true);
                                  let _menuStorage = getDataMenu(72, token.menus);
                                  const data = {
                                    page: stateComp.page,
                                    pageSize: stateComp.pageSize,
                                    sorted: [{ id: "municipio.Municipio", desc: false }],
                                    tipo: 'and',
                                    filtered: Filtro ? Filtro : [],
                                    Electoral: "RGS"
                                  };
                                  EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                    .then(function (res) {
                                      return res;
                                    })
                                    .then((res) => {
                                      setLoadingTabla(false);
                                      setTotal(res.total);
                                      setMeta(
                                        res.data.map((prop, key) => {
                                          var porcentaje = parseFloat(prop.Porcentaje)
                                          var ddporcentaje = porcentaje.toFixed(2)
                                          return {
                                            id: prop.id,
                                            Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                            meta: prop.Meta,
                                            AvanceEstructura: prop.Meta ? prop.Meta : 0,
                                            AvanceRGs: prop.Total > 0 ? (<><h6>{prop.Total}</h6><Button onClick={() => onClickShowResponsables(prop, "RGS")} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.Total ? prop.Total : 0),
                                            porcentaje: ddporcentaje ? ddporcentaje : 0,
                                            data: prop,
                                          };
                                        })
                                      );

                                      var categorias = res.grafica.map((prop, key) => {
                                        return prop.Municipio;
                                    })
                             
                                    var Meta = res.grafica.map((prop, key) => {
                                         return prop.Meta;
                                     })
                             
                                     var Avance = res.grafica.map((prop, key) => {
                                         return prop.Total;
                                     })
                             
                                     const data= {
                                 
                                         title: `Avance ${horizontalTabs.horizontalTabs}` ,
                                         xAxis: {
                                             categories: categorias,
                                             title: {
                                                text:  "Municipios"
                                            }
                                         },
                                         yAxis: [{
                                             min: 0,
                                             title: {
                                                 text:`${titulo}`
                                             }
                                         }],
                                     };
                             
                                     const  series= [
                                         {
                                              name: 'Meta',
                                              color: 'rgba(147,220,236,.9)',
                                              data: Meta,
                                              pointPadding: 0.3,
                                              pointPlacement: -0.2
                                          }, {
                                              name: 'Avance',
                                              color: '#0041A0',
                                              data: Avance,
                                              pointPadding: 0.4,
                                              pointPlacement: -0.2
                                     } ];
                                    setGrafica({
                                        data:data,
                                        series:series
                                    })
                                    })
                                    .catch((e) => {
                                      setLoadingTabla(false);
                                      notify.show(
                                        `Error al obtener tabla metas: ${e.message}`,
                                        "custom",
                                        5000,
                                        {
                                          background: "#0857B6",
                                          fontSize: 14,
                                          text: "#FFFFFF",
                                        }
                                      );
                                    });
                                }}
                              /> : <></>
                            }

                          </TabPane>
                          <TabPane tabId="Abogados" role="tabpanel">
                            {showMeta === 3 ?
                              <ReactTable
                                size='sm'
                                data={meta}
                                noDataText="Sin información"
                                manual
                                columns={[
                                  {
                                    Header: "Municipio",
                                    minWidth: 70,
                                    id: "Municipio",
                                    accessor: (d) => d.Municipio,
                                    filterAll: false,
                                  },
                                  {
                                    Header: "Meta",
                                    accessor: (d) => d.meta,
                                    minWidth: 40,
                                    id: "meta",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance Esperado",
                                    accessor: (d) => d.AvanceEstructura,
                                    minWidth: 40,
                                    id: "AvanceEstructura",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance Abogados",
                                    accessor: (d) => d.AvanceRGs,
                                    minWidth: 40,
                                    id: "AvanceRGS",
                                    filterable: false,
                                  },
                                  {
                                    Header: "% Avance",
                                    accessor: (d) => d.porcentaje,
                                    Cell: props => <div>{props.value + " %"}</div>,
                                    minWidth: 40,
                                    id: "porcentaje",
                                    filterable: false,
                                  },

                                ]}
                                className="-striped -highlight primary-pagination"
                                loading={loadingTabla}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={10}
                                total={total}
                                pages={Math.floor(total / 10)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                PaginationComponent={Pagination}
                                onFetchData={(stateComp, instance) => {
                                  setLoadingTabla(true);
                                  let _menuStorage = getDataMenu(72, token.menus);
                                  const data = {
                                    page: stateComp.page,
                                    pageSize: stateComp.pageSize,
                                    sorted: [{ id: "municipio.Municipio", desc: false }],
                                    tipo: 'and',
                                    filtered: Filtro ? Filtro : [],
                                    Electoral: "Abogados"
                                  };
                                  EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                    .then(function (res) {
                                      return res;
                                    })
                                    .then((res) => {
                                      setLoadingTabla(false);
                                      setTotal(res.total);
                                      setMeta(
                                        res.data.map((prop, key) => {
                                          return {
                                            id: prop.id,
                                            Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                            meta: prop.Meta,
                                            AvanceEstructura: prop.Meta ? prop.Meta : 0,
                                            AvanceRGs: prop.Total > 0 ? (<><h6>{prop.Total}</h6><Button onClick={() => onClickShowResponsables(prop, "Abogados")} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.Total ? prop.Total : 0),
                                            porcentaje: prop.Porcentaje ? prop.Porcentaje : 0,
                                            data: prop,
                                          };
                                        })
                                      );

                                      var categorias = res.grafica.map((prop, key) => {
                                        return prop.Municipio;
                                    })
                             
                                    var Meta = res.grafica.map((prop, key) => {
                                         return prop.Meta;
                                     })
                             
                                     var Avance = res.grafica.map((prop, key) => {
                                         return prop.Total;
                                     })
                             
                                     const data= {
                                 
                                         title: `Avance ${horizontalTabs.horizontalTabs}` ,
                                         xAxis: {
                                             categories: categorias,
                                             title: {
                                                text: "Municipios"
                                            }
                                         },
                                         yAxis: [{
                                             min: 0,
                                             title: {
                                                 text:`${titulo}`
                                             }
                                         }],
                                     };
                             
                                     const  series= [
                                         {
                                              name: 'Meta',
                                              color: 'rgba(147,220,236,.9)',
                                              data: Meta,
                                              pointPadding: 0.3,
                                              pointPlacement: -0.2
                                          }, {
                                              name: 'Avance',
                                              color: '#0041A0',
                                              data: Avance,
                                              pointPadding: 0.4,
                                              pointPlacement: -0.2
                                     } ];
                                    setGrafica({
                                        data:data,
                                        series:series
                                    })
                                    })
                                    .catch((e) => {
                                      setLoadingTabla(false);
                                      notify.show(
                                        `Error al obtener tabla metas: ${e.message}`,
                                        "custom",
                                        5000,
                                        {
                                          background: "#0857B6",
                                          fontSize: 14,
                                          text: "#FFFFFF",
                                        }
                                      );
                                    });
                                }}
                              /> : <></>
                            }

                          </TabPane>
                          <TabPane tabId="Observador" role="tabpanel">
                            {showMeta === 4?
                              <ReactTable
                                size='sm'
                                data={meta}
                                noDataText="Sin información"
                                manual
                                columns={[
                                  {
                                    Header: "Municipio",
                                    minWidth: 70,
                                    id: "Municipio",
                                    accessor: (d) => d.Municipio,
                                    filterAll: false,
                                  },
                                  {
                                    Header: "Meta",
                                    accessor: (d) => d.meta,
                                    minWidth: 40,
                                    id: "meta",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance Esperado",
                                    accessor: (d) => d.AvanceEstructura,
                                    minWidth: 40,
                                    id: "AvanceEstructura",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance Observador",
                                    accessor: (d) => d.AvanceRGs,
                                    minWidth: 40,
                                    id: "AvanceRGS",
                                    filterable: false,
                                  },
                                  {
                                    Header: "% Avance",
                                    accessor: (d) => d.porcentaje,
                                    Cell: props => <div>{props.value + " %"}</div>,
                                    minWidth: 40,
                                    id: "porcentaje",
                                    filterable: false,
                                  },

                                ]}
                                className="-striped -highlight primary-pagination"
                                loading={loadingTabla}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={10}
                                total={total}
                                pages={Math.floor(total / 10)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                PaginationComponent={Pagination}
                                onFetchData={(stateComp, instance) => {
                                  setLoadingTabla(true);
                                  let _menuStorage = getDataMenu(72, token.menus);
                                  const data = {
                                    page: stateComp.page,
                                    pageSize: stateComp.pageSize,
                                    sorted: [{ id: "municipio.Municipio", desc: false }],
                                    tipo: 'and',
                                    filtered: Filtro ? Filtro : [],
                                    Electoral: "Observador"
                                  };
                                  EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                    .then(function (res) {
                                      return res;
                                    })
                                    .then((res) => {
                                      setLoadingTabla(false);
                                      setTotal(res.total);
                                      setMeta(
                                        res.data.map((prop, key) => {
                                          var porcentaje = parseFloat(prop.Porcentaje)
                                          var ddporcentaje = porcentaje.toFixed(2)
                                          return {
                                            id: prop.id,
                                            Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                            meta: prop.Meta,
                                            AvanceEstructura: prop.Meta ? prop.Meta : 0,
                                            AvanceRGs: prop.Total > 0 ? (<><h6>{prop.Total}</h6><Button onClick={() => onClickShowResponsables(prop, "Observador")} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.Total ? prop.Total : 0),
                                            porcentaje: ddporcentaje ? ddporcentaje : 0,
                                            data: prop,
                                          };
                                        })
                                      );

                                      var categorias = res.grafica.map((prop, key) => {
                                        return prop.Municipio;
                                    })
                             
                                    var Meta = res.grafica.map((prop, key) => {
                                         return prop.Meta;
                                     })
                             
                                     var Avance = res.grafica.map((prop, key) => {
                                         return prop.Total;
                                     })
                             
                                     const data= {
                                 
                                         title: `Avance ${horizontalTabs.horizontalTabs}` ,
                                         xAxis: {
                                             categories: categorias,
                                             title: {
                                                text: "Municipios"
                                            }
                                         },
                                         yAxis: [{
                                             min: 0,
                                             title: {
                                                 text:`${titulo}`
                                             }
                                         }],
                                     };
                             
                                     const  series= [
                                         {
                                              name: 'Meta',
                                              color: 'rgba(147,220,236,.9)',
                                              data: Meta,
                                              pointPadding: 0.3,
                                              pointPlacement: -0.2
                                          }, {
                                              name: 'Avance',
                                              color: '#0041A0',
                                              data: Avance,
                                              pointPadding: 0.4,
                                              pointPlacement: -0.2
                                     } ];
                                    setGrafica({
                                        data:data,
                                        series:series
                                    })

                                    })
                                    .catch((e) => {
                                      setLoadingTabla(false);
                                      notify.show(
                                        `Error al obtener tabla metas: ${e.message}`,
                                        "custom",
                                        5000,
                                        {
                                          background: "#0857B6",
                                          fontSize: 14,
                                          text: "#FFFFFF",
                                        }
                                      );
                                    });
                                }}
                              /> : <></>
                            }

                          </TabPane>
                          <TabPane tabId="PrimeroFila" role="tabpanel">
                            {showMeta === 6 ?
                              <ReactTable
                                size='sm'
                                data={meta}
                                noDataText="Sin información"
                                manual
                                columns={[
                                  {
                                    Header: "Municipio",
                                    minWidth: 70,
                                    id: "Municipio",
                                    accessor: (d) => d.Municipio,
                                    filterAll: false,
                                  },
                                  {
                                    Header: "Meta",
                                    accessor: (d) => d.meta,
                                    minWidth: 40,
                                    id: "meta",
                                    filterable: false,
                                  },
                                  {
                                    Header: "A.Esperado",
                                    accessor: (d) => d.AvanceEstructura,
                                    minWidth: 40,
                                    id: "AvanceEstructura",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance Primero en Fila",
                                    accessor: (d) => d.AvanceRGs,
                                    minWidth: 40,
                                    id: "AvanceRGS",
                                    filterable: false,
                                  },
                                  {
                                    Header: "% Avance",
                                    accessor: (d) => d.porcentaje,
                                    Cell: props => <div>{props.value + " %"}</div>,
                                    minWidth: 40,
                                    id: "porcentaje",
                                    filterable: false,
                                  },

                                ]}
                                className="-striped -highlight primary-pagination"
                                loading={loadingTabla}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={10}
                                total={total}
                                pages={Math.floor(total / 10)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                PaginationComponent={Pagination}
                                onFetchData={(stateComp, instance) => {
                                  setLoadingTabla(true);
                                  let _menuStorage = getDataMenu(72, token.menus);
                                  const data = {
                                    page: stateComp.page,
                                    pageSize: stateComp.pageSize,
                                    sorted: [{ id: "municipio.Municipio", desc: false }],
                                    tipo: 'and',
                                    filtered: Filtro ? Filtro : [],
                                    Electoral: "PrimeroFila"
                                  };
                                  EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                    .then(function (res) {
                                      return res;
                                    })
                                    .then((res) => {
                                      setLoadingTabla(false);
                                      setTotal(res.total);
                                      setMeta(
                                        res.data.map((prop, key) => {
                                          var porcentaje = parseFloat(prop.Porcentaje)
                                          var ddporcentaje = porcentaje.toFixed(2)
                                          return {
                                            id: prop.id,
                                            Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                            meta: prop.Meta,
                                            AvanceEstructura: prop.Meta ? prop.Meta : 0,
                                            AvanceRGs: prop.Total > 0 ? (<><h6>{prop.Total}</h6><Button onClick={() => onClickShowResponsables(prop, "RGS")} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.Total ? prop.Total : 0),
                                            porcentaje: ddporcentaje ? ddporcentaje : 0,
                                            data: prop,
                                          };
                                        })
                                      );

                                      var categorias = res.grafica.map((prop, key) => {
                                        return prop.Municipio;
                                    })
                             
                                    var Meta = res.grafica.map((prop, key) => {
                                         return prop.Meta;
                                     })
                             
                                     var Avance = res.grafica.map((prop, key) => {
                                         return prop.Total;
                                     })
                             
                                     const data= {
                                 
                                         title: `Avance ${horizontalTabs.horizontalTabs}` ,
                                         xAxis: {
                                             categories: categorias,
                                             title: {
                                                text:  "Municipios"
                                            }
                                         },
                                         yAxis: [{
                                             min: 0,
                                             title: {
                                                 text:`${titulo}`
                                             }
                                         }],
                                     };
                             
                                     const  series= [
                                         {
                                              name: 'Meta',
                                              color: 'rgba(147,220,236,.9)',
                                              data: Meta,
                                              pointPadding: 0.3,
                                              pointPlacement: -0.2
                                          }, {
                                              name: 'Avance',
                                              color: '#0041A0',
                                              data: Avance,
                                              pointPadding: 0.4,
                                              pointPlacement: -0.2
                                     } ];
                                    setGrafica({
                                        data:data,
                                        series:series
                                    })
                                    })
                                    .catch((e) => {
                                      setLoadingTabla(false);
                                      notify.show(
                                        `Error al obtener tabla metas: ${e.message}`,
                                        "custom",
                                        5000,
                                        {
                                          background: "#0857B6",
                                          fontSize: 14,
                                          text: "#FFFFFF",
                                        }
                                      );
                                    });
                                }}
                              /> : <></>
                            }

                          </TabPane>
 
                          <TabPane tabId="CasaAmiga" role="tabpanel">
                            {showMeta === 5 ?
                              <ReactTable
                                size='sm'
                                data={meta}
                                noDataText="Sin información"
                                manual
                                columns={[
                                  {
                                    Header: "Municipio",
                                    minWidth: 70,
                                    id: "Municipio",
                                    accessor: (d) => d.Municipio,
                                    filterAll: false,
                                  },
                                  {
                                    Header: "Meta",
                                    accessor: (d) => d.meta,
                                    minWidth: 40,
                                    id: "meta",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance Esperado",
                                    accessor: (d) => d.AvanceEstructura,
                                    minWidth: 40,
                                    id: "AvanceEstructura",
                                    filterable: false,
                                  },
                                  {
                                    Header: "Avance Casa Azul",
                                    accessor: (d) => d.AvanceRGs,
                                    minWidth: 40,
                                    id: "AvanceRGS",
                                    filterable: false,
                                  },
                                  {
                                    Header: "% Avance",
                                    accessor: (d) => d.porcentaje,
                                    Cell: props => <div>{props.value + " %"}</div>,
                                    minWidth: 40,
                                    id: "porcentaje",
                                    filterable: false,
                                  },

                                ]}
                                className="-striped -highlight primary-pagination"
                                loading={loadingTabla}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={10}
                                total={total}
                                pages={Math.floor(total / 10)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                PaginationComponent={Pagination}
                                onFetchData={(stateComp, instance) => {
                                  setLoadingTabla(true);
                                  let _menuStorage = getDataMenu(72, token.menus);
                                  const data = {
                                    page: stateComp.page,
                                    pageSize: stateComp.pageSize,
                                    sorted: [{ id: "municipio.Municipio", desc: false }],
                                    tipo: 'and',
                                    filtered: Filtro ? Filtro : [],
                                    Electoral: "CasaAmiga"
                                  };
                                  EjecutarApi(data, "getEstructuraMunicipalElectoral", token.access_token)
                                    .then(function (res) {
                                      return res;
                                    })
                                    .then((res) => {
                                      setLoadingTabla(false);
                                      setTotal(res.total);
                                      setMeta(
                                        res.data.map((prop, key) => {
                                          var porcentaje = parseFloat(prop.Porcentaje)
                                          var ddporcentaje = porcentaje.toFixed(2)
                                          return {
                                            id: prop.id,
                                            Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                            meta: prop.Meta,
                                            AvanceEstructura: prop.Meta ? prop.Meta : 0,
                                            AvanceRGs: prop.Total > 0 ? (<><h6>{prop.Total}</h6><Button onClick={() => onClickShowResponsables(prop, "Casa Amiga")} className="btn-round" size="sm" color="primary"> Ver </Button></>) : (prop.Total ? prop.Total : 0),
                                            porcentaje: ddporcentaje ? ddporcentaje : 0,
                                            data: prop,
                                          };
                                        })
                                      );
                                    var categorias = res.grafica.map((prop, key) => {
                                        return prop.Municipio;
                                    })
                             
                                    var Meta = res.grafica.map((prop, key) => {
                                         return prop.Meta;
                                     })
                             
                                     var Avance = res.grafica.map((prop, key) => {
                                         return prop.Total;
                                     })
                             
                                     const data= {
                                 
                                         title: `Avance ${horizontalTabs.horizontalTabs}` ,
                                         xAxis: {
                                             categories: categorias,
                                             title: {
                                                text: "Municipios"
                                            }
                                         },
                                         yAxis: [{
                                             min: 0,
                                             title: {
                                                 text:`${titulo}`
                                             }
                                         }],
                                     };
                             
                                     const  series= [
                                         {
                                              name: 'Meta',
                                              color: 'rgba(147,220,236,.9)',
                                              data: Meta,
                                              pointPadding: 0.3,
                                              pointPlacement: -0.2
                                          }, {
                                              name: 'Avance',
                                              color: '#0041A0',
                                              data: Avance,
                                              pointPadding: 0.4,
                                              pointPlacement: -0.2
                                     } ];
                                    setGrafica({
                                        data:data,
                                        series:series
                                    })
                                    })
                                    .catch((e) => {
                                      setLoadingTabla(false);
                                      showMessage(e.message)
                                    });
                                }}
                              /> : <></>
                            }

                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
              {
                showResponsable &&
                <ListadoResponsables
                  props={reponsables}
                  onHide={() => onHide()}>
                </ListadoResponsables>
              }
            </div>
          }{vista === false &&
            <Card>
              <Jumbotron style={{ "background-color": "transparent" }} className=' text-sm-center'>
                <h1 className="display-3">{message}</h1>
              </Jumbotron>
            </Card>
          }
        </div>
        <Footer fluid />
      </div>
    </div>
  );

}
export default EstructuraElectoralMunicipal;
