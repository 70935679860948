import React, { useEffect, useState } from "react";

import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";

//Zincri imports
import SimpleMap from "components/Maps/Electoral/SimpleMapCluster.jsx";
//import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col/* , InputGroup, Input, InputGroupAddon, Label  */ } from 'reactstrap';
import { getVars } from "GlobalStorage";
import { URL_GENERAL } from "data/Consts";
import { getDataMenu } from "data/menus";
import Notifications, { notify } from 'react-notify-toast';
import EjecutarApi from "data/getPOST";

let SUPERVISOR_ESTATAL_ELECTORAL = 98;
let COORDINADOR_GENERAL = 99;
let COORDINADOR_ESTATAL_ELECTORAL = 16;
let COORDINADOR_REGIONAL_ELECTORAL = 17;
let COORDINADOR_MUNICIPAL_ELECTORAL = 18;
let PRIMERO_EN_FILA = 40;
let CASA_AZUL = 15;
let OBSERVADOR = 9;
let ABOGADOS = 8;
let RGs = 3;
let RCs = 2;
const Mapas = (props) => {
    const token = getVars("Token");

    const [derechoMapa, setDerechoMapa] = useState([]);
    const [flagFinish, setFlagFinish] = useState(false);
    const [_data, set_data] = useState([]);
    const [_position, setPosition] = useState([20.816157, -101.021312]);
    const [dataGeoJson, setDataGeoJson] = useState({});
    const [menu, _setmenu] = useState({});
    const [FlagAlert, setFlagAlert] = useState(false);
    const [tipoMapa, setTipoMapa] = useState(
        getVars("Token").user.Responsabilidad.value === COORDINADOR_GENERAL ||
            getVars("Token").user.Responsabilidad.value === SUPERVISOR_ESTATAL_ELECTORAL ||
            getVars("Token").user.Responsabilidad.value === COORDINADOR_REGIONAL_ELECTORAL ||
            getVars("Token").user.Responsabilidad.value === COORDINADOR_ESTATAL_ELECTORAL
            ?
            { value: 'region', label: "Regional" }
            :
            getVars("Token").user.Responsabilidad.value === COORDINADOR_MUNICIPAL_ELECTORAL
                ?
                { value: 'municipio', label: "Muncipal" }
                :
                { value: 'seccion', label: "Seccion" }
    );

    const [zoom, setZoom] = useState(

        getVars("Token").user.Responsabilidad.value === COORDINADOR_GENERAL ||
            getVars("Token").user.Responsabilidad.value === SUPERVISOR_ESTATAL_ELECTORAL ||
            getVars("Token").user.Responsabilidad.value === COORDINADOR_REGIONAL_ELECTORAL ||
            getVars("Token").user.Responsabilidad.value === COORDINADOR_ESTATAL_ELECTORAL ?
            9 :
            getVars("Token").user.Responsabilidad.value === COORDINADOR_MUNICIPAL_ELECTORAL ?
                10 :
                11);
    const hostserver = URL_GENERAL;
    useEffect(() => {

        _setmenu(getDataMenu(104, token.menus));
        selectDerecho();
        setFlagFinish(true);

    },
        []);

    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };

    function selectDerecho() {
        let derecho_mapa = getVars("Token").user.Responsabilidad.value;
        setDerechoMapa(
            derecho_mapa === COORDINADOR_GENERAL ||
                derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
                derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL ? [1, 3, 4] :
                derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL ? [1, 3, 4] :
                    derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL ? [1, 3] :
                        derecho_mapa === PRIMERO_EN_FILA ||
                            derecho_mapa === CASA_AZUL ||
                            derecho_mapa === OBSERVADOR ||
                            derecho_mapa === ABOGADOS ||
                            derecho_mapa === RGs ||
                            derecho_mapa === RCs
                            ? [1] :
                            []
        );
        if (
            derecho_mapa === COORDINADOR_GENERAL ||
            derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
            derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL ||
            derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL
        ) {
            setTipoMapa({ value: 'region', label: "Regional" });
            getRegiones("", "GET");
            //setDataGeoJson(Regiones);
        }
        if (derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL) {
            setTipoMapa({ value: 'municipio', label: "Municipal" });
            getMunicipios("", "GET");
            //setDataGeoJson(Municipios);
        }
        if (
            derecho_mapa === PRIMERO_EN_FILA ||
            derecho_mapa === CASA_AZUL ||
            derecho_mapa === OBSERVADOR ||
            derecho_mapa === ABOGADOS ||
            derecho_mapa === RGs ||
            derecho_mapa === RCs
        ) {
            setTipoMapa({ value: 'seccion', label: "Seccional" });
            getSecciones("", "GET");
            //setDataGeoJson(Secciones);
        }
    }


    const handleTipoMapa = (tipo) => {
        setFlagFinish(false);
        setZoom(9);
        //setDataFiltroGeoJson([]); //Aqui se puede buscar solo el que esta en el filtro actualmente (Mejora/Problema)
        setDataGeoJson({});
        setTipoMapa(tipo);
        setPosition([20.816157, -101.021312]);
        if (tipo.value === 'region') {
            getRegiones("", "GET");
            setFlagFinish(true);
        }
        if (tipo.value === 'municipio') {
            getMunicipios("", "GET");
            setFlagFinish(true);
        }
        if (tipo.value === 'seccion') {
            getSecciones("", "GET");
            setFlagFinish(true);
        }
    }

    const handleFiltro = (filtered, tipo_mapa) => {
        setFlagFinish(false);
        setZoom(9);
        setPosition([20.816157, -101.021312]);

        var idMunicipio = filtered.filter(dato => dato.id === "idMunicipio");
        var idRegion = filtered.filter(dato => dato.id === "idRegion");
        var idSeccion = filtered.filter(dato => dato.id === "idSeccion");
        var TieneActas = filtered.filter(dato => dato.id === "TieneActas");




        idMunicipio = idMunicipio[0].value;
        idRegion = idRegion[0].value;
        idSeccion = idSeccion[0].value;
        TieneActas = TieneActas[0].value;

        var filtered_plugin = [];
        var puntos = false;
        if (TieneActas !== 9999) {
            puntos = true;
            if (TieneActas !== 3)
                filtered_plugin.push({ id: "cat_casillasgto.TieneActas", value: TieneActas });
        }
        if (idRegion.length !== 0 && idMunicipio.length === 0 && idSeccion.length === 0) {
            setDataGeoJson({});
            setTipoMapa({ value: 'region', label: "Regional" });
            filtered_plugin.push({ id: "secciones.idRegion", value: idRegion });
            getRegiones(idRegion, 'POST');
        }

        if (idMunicipio.length !== 0 && idSeccion.length === 0) {
            setDataGeoJson({});
            setTipoMapa({ value: 'municipio', label: "Municipal" });
            filtered_plugin.push({ id: "secciones.idMunicipioReportes", value: idMunicipio });
            getMunicipios(idMunicipio, 'POST');
        }
        if (idSeccion.length !== 0) {
            setDataGeoJson({});
            setTipoMapa({ value: 'seccion', label: "Seccional" });
            filtered_plugin.push({ id: "secciones.Seccion", value: idSeccion });
            getSecciones(idSeccion, 'POST');
        }
        if (idSeccion.length === 0 && idMunicipio.length === 0 && idRegion.length === 0) {
            if (getVars("Token").user.Responsabilidad.value === COORDINADOR_MUNICIPAL_ELECTORAL) {
                getMunicipios("", "GET");
            }
            else {
                getRegiones("", "GET");
            }

        }
        let _token = token.access_token;
        const datos = {
            page: 0,
            tipo: 'and',
            pageSize: 10000,
            sorted: [],
            filtered: filtered_plugin
        };
        EjecutarApi(datos, 'getPuntosCasillasGto', _token, 'POST')
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    if (puntos) {
                        set_data(res.data.map((prop, key) => {

                            return {
                                Latitude: prop.Latitude,
                                Longitude: prop.Longitude,
                                idRCPropietario1: prop.idRCPropietario1,
                                idRCPropietario2: prop.idRCPropietario2,
                                idRCSuplente1: prop.idRCSuplente1,
                                idRCSuplente2: prop.idRCSuplente2,
                                RCPropietario1: prop.RCPropietario1,
                                RCPropietario2: prop.RCPropietario2,
                                RCSuplente1: prop.RCSuplente1,
                                RCSuplente2: prop.RCSuplente2,
                                TipoDomicilio: prop.TipoDomicilio,
                                Referencia: prop.Referencia,
                                Ubicacion: prop.Ubicacion,
                                LocalidadManzana: prop.LocalidadManzana,
                                Domicilio: prop.Domicilio,
                                TipoCasilla: prop.TipoCasilla,
                                Casilla: prop.Casilla,
                                TieneActas: prop.TieneActas,
                                LNOM: prop.LNOM,
                                PadronElecoral: prop.PadronElecoral,
                                TipoSeccion: prop.TipoSeccion,
                                Seccion: prop.Seccion,
                                Municipio: prop.Municipio,
                                data: prop
                            };

                        }

                        ));
                        setFlagFinish(true);
                    }
                    else {
                        set_data([]);
                        setFlagFinish(true);
                    }


                } else {
                    //Mandar un mensaje de que no hay puntos, no hay informacion

                }
            })
            .catch((e) => {
                notify.show(`Error en la busqueda! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });


    }

    const handleDetalleMapa = (e) => {
        setDataGeoJson({});
        setTipoMapa(e);
        selectmapaparametros(e);
    }
    function selectmapaparametros(tipomapa) {
        if (tipomapa.value === 'municipio') {
            getMunicipiosRegion([tipomapa.clave], true);
        }
        if (tipomapa.value === 'seccion') {
            getSeccionesMunicipios([tipomapa.clave], true);
        }
    }

    async function getRegiones(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }

            let res = await fetch(
                hostserver + "getRegionesElectoralGto",
                config
            );
            let data = await res.json();
            var json_parser = JSON.parse(data);
            setDataGeoJson(json_parser);
            //setRegiones(json_parser);
        } catch (error) {
            setFlagAlert(true);
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
            /* setRegiones({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
        }
    }
    async function getMunicipios(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getMunicipiosElectoralGto",
                config
            );
            let data = await res.json();
            var json_parser = JSON.parse(data);
            setDataGeoJson(json_parser);
            //setMunicipios(json_parser);
        } catch (error) {
            setFlagAlert(true);
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
            /* setMunicipios({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
        }
    }
    async function getSecciones(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getSeccionesElectoralGto",
                config
            );
            let data = await res.json();
            var json_parser = JSON.parse(data);
            setDataGeoJson(json_parser);
            //setSecciones(json_parser);
        } catch (error) {
            setFlagAlert(true);
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
            /* setSecciones({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
        }
    }
    async function getMunicipiosRegion(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getMunicipiosElectoralGtoRegion",
                config
            );
            let data = await res.json();
            var json_parser = JSON.parse(data);
            setDataGeoJson(json_parser);
            //setMunicipios(json_parser);
        } catch (error) {
            setFlagAlert(true);
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
            /* setMunicipios({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
        }
    }
    async function getSeccionesMunicipios(clave_parametro, method) {
        const token = getVars("Token").access_token;
        try {
            var config = {};
            if (method === "GET") {
                config = {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
            }
            else {
                config = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ clave: clave_parametro }),
                };
            }
            let res = await fetch(
                hostserver + "getSeccionesElectoralGtoMunicipio",
                config
            );
            let data = await res.json();
            var json_parser = JSON.parse(data);
            setDataGeoJson(json_parser);
            //setSecciones(json_parser);
        } catch (error) {
            setFlagAlert(true);
            setDataGeoJson({
                "type": "FeatureCollection",
                "features": [
                ]
            });
            /* setSecciones({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
        }
    }
    return (
        <>
            <Notifications />
            <div className="wrapper">
                <Sidebar
                    {...props}
                    menus={token.menus}
                    bgColor="black"
                    activeColor="info"
                />
                <div className="main-panel">
                    <Navbar
                        {...props}
                        handleMiniClick={handleMiniClick}
                        menus={token.menus}
                    />
                    <div className="content">
                        <>
                            <SimpleMap
                                position_center={_position}
                                zoom={zoom}
                                derecho={derechoMapa}
                                _flagFinish={flagFinish}
                                data={_data}
                                Shape={dataGeoJson}
                                handleFiltro={handleFiltro}
                                handleTipoMapa={handleTipoMapa}
                                handleDetalleMapa={handleDetalleMapa}
                                MenuPermisos={menu}
                                TipoMapa={tipoMapa}
                                Responsabilidad={getVars("Token").user.Responsabilidad.value}
                            >

                            </SimpleMap>

                        </>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Mapas;