import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Card, CardBody, CardFooter, Row, Col } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import PerfectScrollbar from "perfect-scrollbar";

import { getVars, URL_PUBLICA } from 'GlobalStorage';
import { getDataMenu } from "data/menus";
import EjecutarApi from 'data/getPOST';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';

import Pagination from 'components/Pagination/pagination';
import ModalSocialAdd from 'components/Social/SocialAdd';
import ListadoEnlaces from 'components/Social/ListadoEnlaces';
import FiltroSocial from 'components/Filtro/indexSocial';
import ListadoModal from 'components/Social/ListadoCoordinador.jsx';
import ListadoEstructuras from "components/Social/Estructuras";
import Coordinadores from 'components/Social/Coordinadores';
import AvanceSocial from 'components/Social/AvanceSocial';

var ps;

const Social = (props) => {
  const mainPanel = useRef();
  const token = getVars('Token');
  const [Estructura, setEstructura] = useState([]);
  const [totalEstructura, setTotalEstructura] = useState(0);
  const [Editar, setEditar] = useState({
    NombreSocial: '',
    Responsable: [],
  });
  const [Editando, setEditando] = useState(false)
  const [isEditing, setIsEditing] = useState({
    Estructura: false,
    Subestructuras: false,
    Responsable: false,
    Enlaces: false,
    Compromisos: false
  });
  const [compromisoEdit, setCompromisoEdit] = useState(0);
  const [bResponsable, setBResponsable] = useState([]);
  const [_menu, _setmenu] = useState({});
  const [isModalSocialAdd, setModalSocialAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ShowListado, setShowListado] = useState(false);
  const [Listado, setListado] = useState({});
  const [SocialParametro, setSocialParametro] = useState({
    sorted: [{ id: 'LastUpdate', desc: true }],
    NickName: null,
    EstructuraSocial: null,
    Responsable: null,
    ParenID: 0,
    page: 0,
    pageSize: 10
  })

  const onHide = () => {
    setShowListado(false);
    setModalSocialAdd(false);
    setEditando(false)
    setIsEditing({
      ...isEditing, Estructura: false,
      Subestructuras: false,
      Coordinadores: false,
      Enlaces: false,
      Compromisos: false
    })
    setLoading(true);
    EjecutarApi(SocialParametro, 'getStatsEstructuras', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (res.data) {
            setLoading(false);
            setTotalEstructura(res.total);
            setEstructura(
              res.data.map((prop) => {
                return {
                  id: prop.id,
                  NombreSocial: (
                    <>
                      <Button className='btn-link'
                        name="bAddSubStructura"
                        color="primary"
                        size="sm"
                        onClick={() =>
                          handleEditar(prop, 'Edit')
                        }
                      >
                        <span className="btn-label">
                          <i className="fa fa-pencil fa-lg"></i>
                          {prop.NombreEstructura}

                        </span>
                      </Button>
                      <br />
                      <p className='text-muted'>{prop.nickName} </p>
                    </>
                  ),
                  NickName: prop.nickName,
                  Subestructuras: prop.totalEstructuras > 0 ? (<>
                    <Button onClick={() => onClickSubEstructuras(prop.id, 'ID')} className="btn-link"
                      color="primary">{prop.totalEstructuras}</Button>
                    <br />
                    {prop.parentId > 0 && (
                      <>
                        <Button close onClick={() => onClickRegresar(prop.parentId)} >
                          <i className="fa fa-reply-all" aria-hidden="true"></i>
                        </Button>
                      </>
                    )}
                  </>) : (<>{prop.totalEstructuras}<br />
                    {prop.parentId > 0 && (
                      <>
                        <Button close onClick={() => onClickRegresar(prop.parentId)} >
                          <i className="fa fa-reply-all" aria-hidden="true"></i>
                        </Button>
                      </>
                    )}</>),
                  Coordinadores: prop.coordinadores > 0 ? (<><Button onClick={() => onClickCoordinadores(prop.nickName, prop, 'Coordinadores')}
                    className="btn-link" color="primary">{prop.coordinadores}</Button></>) : prop.coordinadores,
                  Enlaces: prop.enlaces > 0 ? (<><Button onClick={() => onClickShowEnlaces(prop)} className="btn-link" color="primary">{prop.enlaces}</Button></>) : prop.enlaces,
                  TotalCompromisosUnicos: prop.compromisosUnicos,
                  TotalCompromiso: (<><Button onClick={() => onClickShowCompromisos(prop.NombreEstructura, 'CTotal')} className="btn-link" color="primary">{prop.total}</Button></>),
                  Acciones: (<><Button onClick={() => ExportarDetalle(prop.id)}
                    className="btn-link" color="success"><i className="fa fa-file-excel-o" aria-hidden="true" /></Button>
                    <Button onClick={() => onClickShowArbol(prop)}
                      className="btn-link" color="success"><i className="fa fa-cogs" aria-hidden="true" /></Button></>),
                  data: prop,
                };
              })
            );
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error: ${e.error}`, 'error', 5000);
      });
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    if (props.history.action === "PUSH") {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    let menu = getDataMenu(23, token.menus);
    if (Object.keys(menu).length > 0) {
      _setmenu(menu);
    }
  }, []);

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const ExportarDetalle = (idEstructura) => {
    var url = new URL(URL_PUBLICA +
      `getReporteEstructuraSocialExport?token=${token.access_token}&EstructuraSocial=` + idEstructura
    );
    window.open(url, '_blank');
  };

  const Exportar = () => {
    var url = new URL(URL_PUBLICA +
      `getReporteEstructuraSocial?token=${token.access_token}`
    );
    window.open(url, '_blank');
  }

  const handleEditar = (e, tipo) => {
    if (tipo === 'Edit') {
      setEditar({ idSocial: e.id, Data: e, Tipo: 'Edit' });
      setModalSocialAdd(true);
    } else if (tipo === 'Add') {
      setEditar({ idSocial: e.id, Data: e, Tipo: 'Add' });
      setIsEditing(true);
      setModalSocialAdd(true)
    }
  };
  const handleAgregar = () => {
    setEditar({ idSocial: 0, Data: [] });
    setModalSocialAdd(true);
  };
  const handleFiltro = (filter) => {
    var _NickName = "";
    var _EstructuraSocial = "";
    var Celular = "";
    var  NombreCompleto = "";
    var  Responsabilidad = "";
    
    if (filter.NombreSocial) {
      _EstructuraSocial = filter.NombreSocial
    }
    if (filter.Nickname) {
      _NickName = filter.Nickname
    }
    if (filter.NombreCompleto ) {
      
        NombreCompleto= filter.NombreCompleto;
      
      
    }
    if (filter.Celular ) {
    
        Celular= filter.Celular;
        
    }
    if (filter.Responsable.Responsabilidad) {
     
        Responsabilidad = filter.Responsable.Responsabilidad ? filter.Responsable.Responsabilidad.value : null;
     
    }

   //setLoading(true);
    var _SocialParametro = {
      sorted: [{ id: 'LastUpdate', desc: true }],
      filtered:[
              {"id": "Nombre", "value": NombreCompleto},
              {"id": "Estructura", "value": _EstructuraSocial},
              {"id": "NickName", "value": _NickName},
              {"id": "Celular", "value":Celular }
      ],
      page: 0,
      pageSize: 10
    };
    //setSocialParametro(_SocialParametro);
    //getRegistros(_SocialParametro);
   
  };
  const onClickCoordinadores = (Estructura, item, Tipo) => {
    setListado({ Estructura: Estructura, Tipo: Tipo, Data: item })
    setEditando(true)
    setIsEditing({
      ...isEditing, Estructura: false,
      Subestructuras: false,
      Coordinadores: true,
      Enlaces: false,
      Compromisos: false
    })

  }
  const onClickEditarCompromiso = (idCompromiso) => {
    setCompromisoEdit(idCompromiso);
  }

  const onClickSubEstructuras = (ID, ReturnID) => {
    setLoading(true);
    setIsEditing({
      ...isEditing, Estructura: true,
      Subestructuras: false,
      Responsable: false,
      Enlaces: false,
      Compromisos: false
    })
    var _SocialParametro = {}
    if (ReturnID === 'Return') {
      _SocialParametro = {
        "page": SocialParametro.page,
        "pageSize": SocialParametro.pageSize,
        "sorted": [
          { "id": "LastUpdate", "desc": true }
        ],
        "ReturnID": ID
      }
    } else {

      _SocialParametro = {
        "page": SocialParametro.page,
        "pageSize": SocialParametro.pageSize,
        "sorted": [
          { "id": "LastUpdate", "desc": true }
        ],
        "ParenID": ID
      }
    }
    setSocialParametro(_SocialParametro)
    EjecutarApi(_SocialParametro, 'getStatsEstructuras', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (res.data) {
            setLoading(false);
            setTotalEstructura(res.total);
            setEstructura(
              res.data.map((prop) => {
                return {
                  id: prop.id,
                  NombreSocial: (
                    <>
                      <Button className='btn-link'
                        name="bAddSubStructura"
                        color="primary"
                        size="sm"
                        onClick={() =>
                          handleEditar(prop, 'Edit')
                        }
                      >
                        <span className="btn-label">
                          <i className="fa fa-pencil fa-lg"></i>
                          {prop.NombreEstructura}

                        </span>
                      </Button>
                      <br />
                      <p className='text-muted'>{prop.nickName} </p>
                    </>
                  ),
                  NickName: prop.nickName,
                  Subestructuras: prop.totalEstructuras > 0 ? (<>
                    <Button onClick={() => onClickSubEstructuras(prop.id, 'ID')} className="btn-link"
                      color="primary">{prop.totalEstructuras}</Button>
                    <br />
                    {prop.parentId > 0 && (
                      <>
                        <Button close onClick={() => onClickRegresar(prop.parentId)} >
                          <i className="fa fa-reply-all" aria-hidden="true"></i>
                        </Button>
                      </>
                    )}
                  </>) : (<>{prop.totalEstructuras}<br />
                    {prop.parentId > 0 && (
                      <>
                        <Button close onClick={() => onClickRegresar(prop.parentId)} >
                          <i className="fa fa-reply-all" aria-hidden="true"></i>
                        </Button>
                      </>
                    )}</>),
                  Coordinadores: prop.coordinadores > 0 ? (<><Button onClick={() => onClickCoordinadores(prop.nickName, prop, 'Coordinadores')}
                    className="btn-link" color="primary">{prop.coordinadores}</Button></>) : prop.coordinadores,
                  Enlaces: prop.enlaces > 0 ? (<><Button onClick={() => onClickShowEnlaces(prop)} className="btn-link" color="primary">{prop.enlaces}</Button></>) : prop.enlaces,
                  TotalCompromisosUnicos: prop.compromisosUnicos,
                  TotalCompromiso: (<><Button onClick={() => onClickShowCompromisos(prop.NombreEstructura, 'CTotal')} className="btn-link" color="primary">{prop.total}</Button></>),
                  Acciones: (<><Button onClick={() => ExportarDetalle(prop.id)}
                    className="btn-link" color="success"><i className="fa fa-file-excel-o" aria-hidden="true" /></Button>
                    <Button onClick={() => onClickShowArbol(prop)}
                      className="btn-link" color="success"><i className="fa fa-cogs" aria-hidden="true" /></Button></>),
                  data: prop,
                };
              })
            );
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error: ${e.error}`, 'error', 5000);
      });
  }
  const onClickRegresar = (ID) => {
    setLoading(true);
    setEditando(false)
    setIsEditing({
      ...isEditing, Estructura: false,
      Subestructuras: false,
      Coordinadores: false,
      Enlaces: false,
      Compromisos: false
    })
    var _SocialParametro = {
      "page": SocialParametro.page,
      "pageSize": SocialParametro.pageSize,
      "sorted": [
        { "id": "LastUpdate", "desc": true }
      ],
      "ReturnID": ID
    }
    setSocialParametro(_SocialParametro)
    getRegistros(_SocialParametro);
  }
  const onClickShowCompromisos = (id) => {
    setIsEditing({
      ...isEditing, Estructura: false,
      Subestructuras: false,
      Responsable: false,
      Enlaces: false,
      Compromisos: true
    })
    setBResponsable(id)
  }
  const onClickShowEnlaces = (id) => {
    setEditando(true)
    setIsEditing({
      ...isEditing, Estructura: false,
      Subestructuras: false,
      Responsable: false,
      Enlaces: true,
      Compromisos: false
    })
    setBResponsable(id)
  }
  const onClickShowArbol = (id) => {
    setEditando(true)
    setIsEditing({
      ...isEditing, Estructura: false,
      Subestructuras: false,
      Responsable: false,
      Enlaces: false,
      Compromisos: false,
      Arbol: true
    })
    setBResponsable(id)
  }

  const columns = [
    {
      Header: 'Estructura',
      minWidth: 110,
      id: 'NombreSocial',
      accessor: "NombreSocial",
      filterAll: false,
    },
    {
      Header: 'Compromisos Únicos',
      minWidth: 40,
      id: 'TotalCompromisosUnicos',
      accessor: "TotalCompromisosUnicos",
      filterAll: false,
    },
    {
      Header: 'Compromisos ',
      minWidth: 40,
      id: 'TotalCompromiso',
      accessor: "TotalCompromiso",
      filterAll: false,
    },
    {
      Header: 'Subestructuras',
      minWidth: 80,
      id: 'Subestructuras',
      accessor: "Subestructuras",
      filterAll: false,
    }
    ,
    {
      Header: 'Coordinadores',
      minWidth: 80,
      id: 'Coordinadores',
      accessor: "Coordinadores",
      filterAll: false,
    },
    {
      Header: 'Enlaces',
      minWidth: 40,
      id: 'Enlaces',
      accessor: "Enlaces",
      filterAll: false,
    },

    {
      Header: '',
      minWidth: 40,
      id: 'Acciones',
      accessor: "Acciones",
      filterAll: false,
    },
  ];

  const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
   
    var data = {
      sorted: sorted,
      Nombre: params.Nombre,
      NickName: params.NickName,
      Estructura: params.Estructura,
      Celular:params.Celular,
      ParenID: params.ParenID,
      page: page,
      pageSize: pageSize
    }
    getRegistros(data);
  }, []);

  const getRegistros = (data) => {
    setLoading(true);
    EjecutarApi(data, 'getStatsEstructuras', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (res.data) {
            setTotalEstructura(res.total);
            setEstructura(
              res.data.map((prop) => {
                return {
                  id: prop.id,
                  NombreSocial: (
                    <>
                      <Button className='btn-link'
                        name="bAddSubStructura"
                        color="primary"
                        size="sm"
                        onClick={() =>
                          handleEditar(prop, 'Edit')
                        }
                      >
                        <span className="btn-label">
                          <i className="fa fa-pencil fa-lg"></i>
                          {prop.NombreEstructura}

                        </span>
                      </Button>
                      <br />
                      <p className='text-muted'>{prop.nickName} </p>
                    </>
                  ),
                  NickName: prop.nickName,
                  Subestructuras: prop.totalEstructuras > 0 ? (<>
                    <Button onClick={() => onClickSubEstructuras(prop.id, 'ID')} className="btn-link"
                      color="primary">{prop.totalEstructuras}</Button>
                    <br />
                    {prop.parentId > 0 && (
                      <>
                        <Button close onClick={() => onClickRegresar(prop.parentId)} >
                          <i className="fa fa-reply-all" aria-hidden="true"></i>
                        </Button>
                      </>
                    )}
                  </>) : (<>{prop.totalEstructuras}<br />
                    {prop.parentId > 0 && (
                      <>
                        <Button close onClick={() => onClickRegresar(prop.parentId)} >
                          <i className="fa fa-reply-all" aria-hidden="true"></i>
                        </Button>
                      </>
                    )}</>),
                  Coordinadores: prop.coordinadores > 0 ? (<><Button onClick={() => onClickCoordinadores(prop.id, prop, 'Coordinadores')}
                    className="btn-link" color="primary">{prop.coordinadores}</Button></>) : prop.coordinadores,
                  Enlaces: prop.enlaces > 0 ? (<><Button onClick={() => onClickShowEnlaces(prop)} className="btn-link" color="primary">{prop.enlaces}</Button></>) : prop.enlaces,
                  TotalCompromisosUnicos: prop.compromisosDependientesEstructuras.unicos,
                  TotalCompromiso: prop.compromisosDependientesEstructuras.noUnicos,
                  Acciones: (<><Button onClick={() => ExportarDetalle(prop.id)}
                    className="btn-link" color="success"><i className="fa fa-file-excel-o" aria-hidden="true" /></Button>
                    <Button onClick={() => onClickShowArbol(prop.id)}
                      className="btn-link" color="success"><i className="fa fa-cogs" aria-hidden="true" /></Button></>),
                  data: prop,
                };
              })
            );
          }
        }
        setLoading(false);
      }).catch((e) => {
        setLoading(false);
        notify.show(`Error: ${e.error}`, 'error', 5000);
      });
  }

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {Editando ?
            isEditing.Estructura && (<><Row>
              <ListadoEstructuras Data={bResponsable} onHide={() => onHide()} />
            </Row></>) ||
            isEditing.Subestructuras && (<></>) ||
            isEditing.Coordinadores && (<><Row>
              <Col>
                <Card>
                  <CardBody>
                    <Coordinadores
                      onHide={() => onHide()}
                      Data={Listado}
                    />
                  </CardBody>

                </Card>

              </Col>
            </Row></>) ||
            isEditing.Enlaces && (<>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <ListadoEnlaces
                        onHide={() => onHide()}
                        Data={bResponsable}
                      />
                    </CardBody>

                  </Card>

                </Col>
              </Row>
            </>) ||
            isEditing.Arbol && (<>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <AvanceSocial
                        onHide={() => onHide()}
                        Data={bResponsable}
                      />
                    </CardBody>

                  </Card>

                </Col>
              </Row>
            </>)
            : (
              <>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row>
                          {_menu.Agregar === 1 ? (
                            <Button
                              name="btncd"
                              color="success"
                              size="sm"
                              onClick={() => handleAgregar()}
                            >
                              <span className="btn-label">
                                <i className="fa fa-plus fa-lg" />
                              </span>
                              Estructura
                                </Button>
                          ) : (
                              <></>
                            )}
                          {_menu.Exportar === 1 ? (
                            <Button
                              name="btnexport"
                              color="success"
                              size="sm"
                              onClick={() => Exportar()}
                            >
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-lg" />
                              </span>
                              Exportar
                                </Button>
                          ) : (
                              <></>
                            )}
                        </Row>

                        <Row>
                          <Col sm="12">
                            <FiltroSocial
                              Menu={_menu}
                              Datos={(e) => handleFiltro(e)}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="12">
                            <div style={{ width: '100%', overflow: 'auto' }}>
                              <ReactTable
                                style={{
                                  width: '110%',
                                  height: '100%',
                                }}
                                name="racttab"
                                data={Estructura}
                                noDataText="Sin información"
                                manual
                                columns={columns}
                                className="-striped -highlight primary-pagination"
                                loading={loading}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={10}
                                total={totalEstructura}
                                pages={Math.floor(totalEstructura / 10)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                PaginationComponent={Pagination}
                                onFetchData={fetchData}
                                params={SocialParametro}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter></CardFooter>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          {ShowListado && (<ListadoModal onHide={onHide} Datos={Listado} Actualizar={(e) => onClickEditarCompromiso(e)} />)}
        </div>
        {isModalSocialAdd ? (<ModalSocialAdd onHide={onHide} OnEdit={Editar} />) : (<></>)}
        <Footer fluid />
      </div>
    </div>
  );
};

export default Social;
