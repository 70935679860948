import React, { useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Row,
  Col,
  Input,
  Button,
  CardFooter,
} from 'reactstrap';

import Select from 'react-select';

const FiltroPlanillas = (props) => {
  const { catalogos, getData, tiposEleccion, setFilter } = props;
  const [collapse, setCollapse] = useState({
    horizontalTabs: 'home',
    verticalTabs: 'info',
    pageTabs: 'homePages',
    openedCollapses: ['collapse1'],
  });
  const [catalogosFiltros, setCatalogosFiltros] = useState({
    municipios: [{ value: 0, label: 'Sin seleccionar' }],
    dls: [{ value: 0, label: 'Sin seleccionar' }],
    dfs: [{ value: 0, label: 'Sin seleccionar' }],
    tipos: [{ value: 0, label: 'Todos' }],
  });
  const [filtros, setFiltros] = useState({
    Nombre: '',
    Partido: '',
    Tipo: { value: 0, label: 'Todos' },
    Municipio: { value: 0, label: 'Sin seleccionar' },
    DL: { value: 0, label: 'Sin seleccionar' },
    DF: { value: 0, label: 'Sin seleccionar' },
  });

  useEffect(() => {
    if (catalogos.municipios.length > 0) {
      setCatalogosFiltros({
        ...catalogosFiltros,
        municipios: [{ value: 0, label: 'Sin seleccionar' }].concat(catalogos.municipios),
        dls: [{ value: 0, label: 'Sin seleccionar' }].concat(catalogos.dls),
        dfs: [{ value: 0, label: 'Sin seleccionar' }].concat(catalogos.dfs),
      });
    }
  }, [catalogos]);

//   useEffect(() => {
//     if (tiposEleccion.length > 0) {
//         setCatalogosFiltros({
//           ...catalogosFiltros,
//           tipos: ,
//         });
//       }
//   }, [tiposEleccion])

  const collapsesToggle = (colapse) => {
    let openedCollapses = collapse.openedCollapses;
    if (openedCollapses.includes(colapse)) {
      setCollapse({
        ...collapse,
        openedCollapses: openedCollapses.filter((item) => item !== colapse),
      });
    } else {
      openedCollapses.push(colapse);
      setCollapse({ ...collapse, openedCollapses: openedCollapses });
    }
  };

  const clearFiltro = () => {
    setFiltros({
      Nombre: '',
      Partido: '',
      Tipo: { value: 0, label: 'Todos' },
      Municipio: { value: 0, label: 'Sin seleccionar' },
      DL: { value: 0, label: 'Sin seleccionar' },
      DF: { value: 0, label: 'Sin seleccionar' },
    });

    getData({ page: 0, pageSize: 10, filtered: [] });
  };

  const formatFiltro = () => {
    let filtered = [];
    if (filtros.Nombre.trim().length > 0) {
      filtered.push({
        id: 'planillas.Candidato',
        value: filtros.Nombre.trim(),
      });
    }
    if (filtros.Partido.trim().length > 0) {
      filtered.push({ id: 'partidos.Partido', value: filtros.Partido.trim() });
    }
    if (filtros.Municipio.value > 0) {
      filtered.push({
        id: 'planillas.idMunicipio',
        value: filtros.Municipio.value,
      });
    }
    if (filtros.DL.value > 0) {
      filtered.push({ id: 'planillas.idDL', value: filtros.DL.value });
    }
    if (filtros.DF.value > 0) {
      filtered.push({ id: 'planillas.idDF', value: filtros.DF.value });
    }
    if (filtros.Tipo.value > 0) {
      filtered.push({
        id: 'planillas.idTipoEleccion',
        value: filtros.Tipo.value,
      });
    }
    setFilter(filtered)
    getData({ page: 0, pageSize: 10, filtered });
  };

  return (
    <Card className="card-plain">
      <CardBody>
        <div
          aria-multiselectable={true}
          className="card-collapse"
          id="accordion"
          role="tablist"
        >
          <Card className="card-plain">
            <CardHeader role="tab" className="text-left">
              <a
                aria-expanded={collapse.openedCollapses.includes('collapse3')}
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={() => collapsesToggle('collapse3')}
                className="text-left"
              >
                <Button
                  className="btn-icon btn-neutral"
                  outline
                  size="sm"
                  id="EditUser"
                >
                  <i
                    className="fa fa-filter fa-3x"
                    color="danger"
                    aria-hidden="true"
                  ></i>
                </Button>
                Filtro
              </a>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={collapse.openedCollapses.includes('collapse3')}
            >
              <CardBody>
                <Row>
                  <Col sm="4">
                    Candidato
                    <Input
                      value={filtros.Nombre}
                      onChange={(e) =>
                        setFiltros({ ...filtros, Nombre: e.target.value })
                      }
                      placeholder="Ingrese un candidato para buscar"
                    />
                  </Col>
                  <Col md="4">
                    Partido:
                    <Input
                      value={filtros.Partido}
                      onChange={(e) =>
                        setFiltros({ ...filtros, Partido: e.target.value })
                      }
                      placeholder="Ingrese un partido para buscar"
                    />
                  </Col>
                  <Col md="4">
                    Tipo de candidato
                    <Select
                      options={[{ value: 0, label: 'Todos' }].concat(tiposEleccion)}
                      value={filtros.Tipo}
                      onChange={(e) => setFiltros({ ...filtros, Tipo: e })}
                    />
                  </Col>
                  <Col md="4">
                    Municipio
                    <Select
                      options={catalogosFiltros.municipios}
                      value={filtros.Municipio}
                      onChange={(e) => setFiltros({ ...filtros, Municipio: e })}
                    />
                  </Col>
                  <Col md="4">
                    Distrito federal
                    <Select
                      options={catalogosFiltros.dfs}
                      value={filtros.DF}
                      onChange={(e) => setFiltros({ ...filtros, DF: e })}
                    />
                  </Col>
                  <Col md="4">
                    Distrito local
                    <Select
                      options={catalogosFiltros.dls}
                      value={filtros.DL}
                      onChange={(e) => setFiltros({ ...filtros, DL: e })}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  size="sm"
                  id="btnfiltro"
                  color="primary"
                  className="primary"
                  onClick={formatFiltro}
                >
                  {' '}
                  <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                  Filtrar
                </Button>
                <Button
                  size="sm"
                  id="btnfiltro"
                  color="danger"
                  className="danger"
                  onClick={clearFiltro}
                >
                  <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                  Limpiar
                </Button>
              </CardFooter>
            </Collapse>
          </Card>
        </div>
      </CardBody>
    </Card>
  );
};

export default FiltroPlanillas;
