import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';
import Select from "react-select";

import EjecutarApi from "../../data/getPOST";
import { getVars} from "../../GlobalStorage";

const SocialAdd = ({ onHide, OnEdit }) => {
    const [EstructuraNombre, setEstructuraNombre] = useState(OnEdit.Tipo === 'Edit' ? OnEdit.Data.NombreEstructura : ''); //useState(''); //
    const [Nickname, setNickname] = useState(OnEdit.Tipo === 'Edit' ? OnEdit.Data.nickName : ''); //useState(''); //
    const [auxAmbito] = useState(OnEdit.Data.AmbitoEstructura);
    const [Ambito, setAmbito] = useState({ value: 0, label: 'Seleccionar...' });
    const [catAmbito, setCatAmbito] = useState([]);
    const [loading, setLoading] = useState(false);
    const [catMunicipio, setCatMunicipio] = useState([]);
    const [Municipio, setMunicipio] = useState({ value: 0, label: 'Seleccionar...' }); //useState([]) //
    const [auxMunicipio] = useState(OnEdit.Data.MunicipioEstructura);
    const token = getVars("Token");
    const [Mensaje, setMensaje] = useState('')
    const [isMunicipal, setIsMunicipal] = useState(false);

    useEffect(() => {
        //getCatalogosFiltrosPromocion  getCatRegion

        EjecutarApi({}, "getCatMunicipios", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.result) {
                    let auxCatMpio = {}
                    auxCatMpio = (items.data.map((item) => {
                        return {
                            value: item.id,
                            label: item.Municipio

                        }
                    }))
                    setCatMunicipio(auxCatMpio)
                    if (auxMunicipio.length > 0) {
                        let aux = auxCatMpio.filter(Data => Data.label === auxMunicipio)
                        setMunicipio(aux)
                        setIsMunicipal(true);
                    }
                }

                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);

            });
        //getCatAmbito
        EjecutarApi({}, "getCatAmbito", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.result) {
                    let auxCatAmbito = {}
                    auxCatAmbito = items.data.map((item) => {
                        return {
                            value: item.value,
                            label: item.label

                        }
                    })
                    setCatAmbito(auxCatAmbito)

                    let aux_amb = auxCatAmbito.filter(Data => Data.label === auxAmbito)
                    if (aux_amb.length > 0) { setAmbito(aux_amb[0]) }
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);

            });
    }, [])

    const setSocial = () => {
        setLoading(true);
        setMensaje('')
        if (EstructuraNombre.length < 3) {
            setMensaje('Error: el campo Nombre es requerido!!!')
            setLoading(false);
            return;
        }
        if (Ambito.length < 0) {
            setMensaje('Error: el campo Ambito es requerido!!!')
            setLoading(false);
            return;
        }
        if (Ambito.label === 'MUNICIPAL') {
            if (Municipio.length < 1) {
                setMensaje('Error: el campo Municipio es requerido!!!')
                setLoading(false);
                return;
            }
        }
        var parametros = {
            "nickName": Nickname,
            "NombreSocial": EstructuraNombre,
            "idAmbito": Ambito.value,
            "parentid": OnEdit.parentId
        }
        if (Ambito.label === 'MUNICIPAL') {
            parametros = {
                "NombreSocial": EstructuraNombre,
                "nickName": Nickname,
                "idAmbito": Ambito.value,
                "idMunicipio": Municipio.value,
                "parentid": OnEdit.parentId
            }
        }

        if (OnEdit.idSocial > 0) {
            if (OnEdit.Tipo === 'Add') {
                parametros = {
                    "NombreSocial": EstructuraNombre,
                    "nickName": Nickname,
                    "idAmbito": Ambito.value,
                    "parentid": OnEdit.idSocial
                }
                if (Ambito.label === 'MUNICIPAL') {
                    parametros = {
                        "NombreSocial": EstructuraNombre,
                        "nickName": Nickname,
                        "idAmbito": Ambito.value,
                        "idMunicipio": Municipio.value,
                        "parentid": OnEdit.idSocial
                    }
                }
                EjecutarApi(parametros, "setSubestructuraSocialWeb", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.results) {
                            setEstructuraNombre('')
                            setAmbito({})
                            setMunicipio({})
                            notify.show(`¡Registro Agregado Correctamente!`, "custom", 5000, {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                            });
                            setLoading(false);
                            onHide();
                        }
                        else {
                            setEstructuraNombre('')
                            setAmbito({})
                            setMunicipio({})
                            notify.show(`Error:${res.message}`, "custom", 5000, {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                            });
                            setLoading(false);
                            //onHide();
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error: ${e.error}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                        });
                    });
            }
            else if (OnEdit.Tipo === 'Edit') {

                parametros = {
                    "idEstructura": OnEdit.idSocial,
                    "nickName": Nickname,
                    "NombreSocial": EstructuraNombre,
                    "idAmbito": Ambito.value,
                    "parentid": OnEdit.parentId
                }
                if (Ambito.label === 'MUNICIPAL') {
                    parametros = {
                        "idEstructura": OnEdit.idSocial,
                        "nickName": Nickname,
                        "NombreSocial": EstructuraNombre,
                        "idAmbito": Ambito.value,
                        "idMunicipio": Municipio.value
                    }
                }
                EjecutarApi(parametros, "updEstructuraSocial", token.access_token)
                    .then(function (res) {
                        return res;
                    })
                    .then((res) => {
                        if (res.results) {
                            setEstructuraNombre('')
                            setAmbito({})
                            setMunicipio({})
                            notify.show(`¡Registro Actualizado Correctamente!`, "custom", 5000, {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                            });
                            setLoading(false);
                            onHide();
                        }
                        else {
                            setEstructuraNombre('')
                            setAmbito({})
                            setMunicipio({})
                            notify.show(`Error:${res.message}`, "custom", 5000, {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                            });
                            setLoading(false);
                            //onHide();
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error: ${e.error}`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                        });
                    });
            }


        }
        else {
            parametros = {
                "nickName": Nickname,
                "NombreSocial": EstructuraNombre,
                "idAmbito": Ambito.value,
                "parentid": OnEdit.id
            }
            if (Ambito.label === 'MUNICIPAL') {
                parametros = {
                    "NombreSocial": EstructuraNombre,
                    "nickName": Nickname,
                    "idAmbito": Ambito.value,
                    "idMunicipio": Municipio.value,
                    "parentid": OnEdit.id
                }
            }
            EjecutarApi(parametros, "setEstructuraSocialWeb", token.access_token)
                .then(function (res) {
                    return res;
                })
                .then((res) => {
                    if (res.results) {
                        setEstructuraNombre('')
                        setAmbito({})
                        setMunicipio({})
                        notify.show(`¡La Estructura se ha Guardado correctamente!`, "custom", 5000, {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                        });
                        setLoading(false);
                        onHide();
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error: ${e.error}`, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                });

        }


    }
    const handleAmbito = (e) => {
        setAmbito(e)
        if (e.label === 'MUNICIPAL')
            setIsMunicipal(true)
    }
    return (
        <Modal
            isOpen={true}
            toggle={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader toggle={onHide}>
                Datos de la Estructura
                <br />
                {OnEdit.Tipo === 'Add'? OnEdit.Data.NombreEstructura:''}
                {OnEdit.Data ? OnEdit.Data.NombreSocial : ''}
            </ModalHeader>
            <ModalBody>
                {loading && <MetroSpinner size={30} color="#686769" loading={loading} />}
                <Row md='12'>
                    <Col md="6">
                        <span className="text-danger">*</span>Subestructura:
                        <Input id='estructuraSocial'
                            placeholder='Nombre Social'
                            value={EstructuraNombre}
                            onChange={e => setEstructuraNombre(e.target.value)} />
                    </Col>
                    <Col md="6">
                        <span className="text-danger">*</span>Nickname o Etiqueta:
                        <Input id='estructuraSocial'
                            placeholder='Nombre Social'
                            value={Nickname}
                            onChange={e => setNickname(e.target.value)} />
                    </Col>
                    <Col md="4">
                        <span className="text-danger">*</span>Ambito:
                        <Select
                            options={catAmbito}
                            className="react-select primary"
                            classNamePrefix="react-select"
                            defaultValue={Ambito}
                            value={Ambito}
                            onChange={(e) => handleAmbito(e)}
                            id="ambito"
                            placeholder="Seleccionar..."
                        />
                    </Col>

                    {isMunicipal && (
                        <Col sm="4">
                            <span className="text-danger">*</span>Municipio:
                        <Select
                                options={catMunicipio}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                defaultValue={Municipio}
                                value={Municipio}
                                onChange={(e) => setMunicipio(e)}
                                id="viewTypeM"
                                placeholder="Seleccionar..."
                            />
                        </Col>

                    )}
                </Row>
                <Row>
                    <Col sm="12">
                        <Label id='mensaje' className='text-danger' >{Mensaje}</Label>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter >
                <Col>
                    <Row className="pull-right">
                        <Col sm="12" md="12" lg="12">
                            <Button color='primary' className='primary' size='sm' onClick={(e) => setSocial(e)} >Guardar</Button>
                            <Button color='danger' className='danger' size='sm' onClick={(e) => onHide()} >Cancelar</Button>
                        </Col>
                    </Row>
                </Col>
            </ModalFooter>
        </Modal>

    );
}
export default SocialAdd;