import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer"
import Sidebar from "components/Sidebar";
import { getVars, URL_PUBLICA } from 'GlobalStorage';
import {getDataMenu} from 'data/menus';
import {
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Row,
  Button,
  Col,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "data/getPOST";
import { CircleSpinner } from "react-spinners-kit";

import SexoChart from 'components/Charts/SexoChart'
import RangoEdadChart from 'components/Charts/RangoEdadChart'
import TipoAreaChart from 'components/Charts/TipoAreaChart'
import ElectoresTipoAreaChart from 'components/Charts/ElectoresTipoAreaChart'
import PrioridadAreaChart from 'components/Charts/ManzanasChartPrioridad'
import PesoElectoralChart from 'components/Charts/PesoElectoralChart'
import _ from 'lodash';
import ModalImportExcel from 'components/Numeralia/ImportNumeralia';
import MultiFilterRegion from 'components/Numeralia/MultiFilterRegion';
import MultiFilterDF from 'components/Numeralia/MultiFilterDF';
import MultiFilterDL from 'components/Numeralia/MultiFilterDL';

const Numeralia = (props) => {

  // Configuracion
  const [setsidebarMini] = useState(true);
  const [backgroundColor] = useState("black");
  const [activeColor] = useState("info");
  const [loading, setLoading] = useState(false);
  const [viewTypeSelected, setViewTypeSelected] = useState()
  const token = getVars('Token');
  //const user = getVars('Token', { user: { Nombre: '', Paterno: '' } }).user
  const [viewsType, setViewsType] = useState([])
  const responsibilityID = token.user.Responsabilidad.value;

  var ubicaciónResponsabilidad = "";

  if (responsibilityID === 10) { token.users_region.forEach((prop) => { ubicaciónResponsabilidad = prop.idRegion }); }
  if (responsibilityID === 11) {
    token.users_municipios.forEach((prop) => { ubicaciónResponsabilidad = prop.idMunicipio });
  }
  if (responsibilityID === 12) {
    token.users_poligonos.forEach((prop) => { ubicaciónResponsabilidad = prop.idPoligono });
  }
  if (responsibilityID === 13) {
    token.users_secciones.forEach((prop) => { ubicaciónResponsabilidad = prop.idMunicipio });
  }
  if (responsibilityID === 53 || responsibilityID === 54 || responsibilityID === 55 || responsibilityID === 50 || responsibilityID === 51 || responsibilityID === 52) {
    ubicaciónResponsabilidad = token.user.Responsabilidad.ResponsabilidadDataID;
  }

  const VIEW_ESTATAL = 1
  const VIEW_DF = 2
  const VIEW_DL = 3

  const ESTATAL = 4
  const MUNICIPAL = 5
  const DF = 6
  const DL = 7
  const REGION = 8
  const POLIGONO = 9
  const SECCION = 10

  // Otros
  //const [numeralia, setNumeralia] = useState([])


  // Selected
  const [refresh, setRefresh] = useState(false);
  const [isImport, setImport] = useState(false);
  // Catalogos
  const [menu, _setmenu] = useState({});

  // Datos de los charts
  //const [titleCardChart, setTitleCardChart] = useState('Region')
  const [dataChartAgeRange, setDataChartAgeRange] = useState([])
  const [dataChartGender, setChartGender] = useState({
    labels: ['Hombres', 'Mujeres'],
    data: [0, 0],
    percentage: [0, 0]
  })

  const [listaNominal, setListaNominal] = useState([])
  const [pesoElectoral, setPesoElectoral] = useState({
    categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    data: [23, 12, 98, 23, 23, 12, 45]
  })

  const [sectionType, setSectionType] = useState({
    categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    data: [23, 12, 98, 23, 23, 12, 45]
  })

  const [manzanasType, setManzanasType] = useState({
    categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    data: [23, 12, 98, 23, 23, 12, 45]
  })

  const [manzanasElectores, setmanzanasElectores] = useState({
    categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    data: [23, 12, 98, 23, 23, 12, 45]
  })
  const [electoralBySectionType, setElectoralBySectionType] = useState({
    categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    data: [23, 12, 98, 23, 23, 12, 45]
  })


  const [structure, setStructure] = useState({ title: 'Sin especificar', value: 0 })

  //"Numeralia"
  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(1, token.menus))

    if (responsibilityID === 99 || responsibilityID === 98 || responsibilityID === 97 || responsibilityID === 16 || responsibilityID === 52 || responsibilityID === 50 || responsibilityID === 51) {

      setViewsType([{
        value: VIEW_ESTATAL,
        label: 'Estatal'
      },
      {
        value: VIEW_DF,
        label: 'Distrito Federal'
      },
      {
        value: VIEW_DL,
        label: 'Distrito Local'
      }])

      setViewTypeSelected(VIEW_ESTATAL)
      EjecutarApi({}, `getNumeraliaRegion`, token.access_token)
        .then(res => {
          let data = res.data
          formatDataChart(data, VIEW_ESTATAL)
        })
        .catch((e) => {
          showMessage("Error: catCompromisosWeb")
        }).then(() => {
          setLoading(false);
        })
    }
    if (responsibilityID === 10) {
      setViewsType([{
        value: VIEW_ESTATAL,
        label: 'Estatal'
      }])
      setViewTypeSelected(VIEW_ESTATAL)
      EjecutarApi({ region: [ubicaciónResponsabilidad] }, `getNumeraliaMunicipiosByRegion`, token.access_token)
        .then(res => {
          if (res.success) {
            if (res.results) {
              let data = res.data
              formatDataChart(data, REGION)
            }
          }

        })
        .catch((e) => {
          showMessage(`Error: cat municipio ${e.message}`)
        }).then(() => {
          setLoading(false);
        })

    }
    if (responsibilityID === 11 || responsibilityID === 55) {
      setViewsType([{
        value: VIEW_ESTATAL,
        label: 'Estatal'
      }])
      setViewTypeSelected(VIEW_ESTATAL)
      EjecutarApi({ municipio: [ubicaciónResponsabilidad] }, `getNumeraliaPoligonosByMunicipio`, token.access_token)
        .then(res => {
          let data = res.data
          setViewTypeSelected(VIEW_ESTATAL)
          formatDataChart(data, MUNICIPAL)
        })
        .catch((e) => {
          showMessage("Error: catCompromisosWeb")
        }).then(() => {
          setLoading(false);
        })

    }
    if (responsibilityID === 12) {
      setViewsType([{
        value: VIEW_ESTATAL,
        label: 'Estatal'
      }])
      setViewTypeSelected(VIEW_ESTATAL)
      EjecutarApi({ poligono: [ubicaciónResponsabilidad] }, `getNumeraliaSeccionesByPoligono`, token.access_token)
        .then(res => {
          let data = res.data
          formatDataChart(data, POLIGONO)
        })
        .catch((e) => {
          showMessage("Error: catCompromisosWeb")
        }).then(() => {
          setLoading(false);
        })
    }
    if (responsibilityID === 13) {

      setViewsType([{
        value: VIEW_ESTATAL,
        label: 'Estatal'
      }])
      setViewTypeSelected(VIEW_ESTATAL)
      EjecutarApi({ seccion: [ubicaciónResponsabilidad] }, `getNumeraliaSeccion`, token.access_token)
        .then(res => {
          let data = res.data
          formatDataChart(data, SECCION)
        })
        .catch((e) => {
          showMessage("Error: catCompromisosWeb")
        }).then(() => {
          setLoading(false);
        })
    }
    if (responsibilityID === 53) {
      setViewsType([{
        value: VIEW_DF,
        label: 'Distrito Federal'
      }])
      setViewTypeSelected(VIEW_DF)
      EjecutarApi({ df: [ubicaciónResponsabilidad] }, `getNumeraliaMunicipiosByDF`, token.access_token)
        .then(res => {
          let data = res.data
          formatDataChart(data, DF)
        })
        .catch((e) => {
          showMessage("Error: getNumeraliaMunicipiosByDF")
        }).then(() => {
          setLoading(false);
        })
    }
    if (responsibilityID === 54) {

      setViewsType([{
        value: VIEW_DL,
        label: 'Distrito Local'
      }])
      setViewTypeSelected(VIEW_DL)


      EjecutarApi({ dl: [ubicaciónResponsabilidad] }, `getNumeraliaMunicipiosByDL`, token.access_token)
        .then(res => {
          let data = res.data
          formatDataChart(data, DL)
        })
        .catch((e) => {
          showMessage("Error: getNumeraliaMunicipiosByDL")
        }).then(() => {
          setLoading(false);
        })
    }


  }, [refresh]);

  const formatDataChart = (numeralias, structure) => {
    let numeralia = numeralias.data;
    let numeraliaType = numeralias.Tipo;
    let numeraliaPrio = numeralias.prioridad;

    let totalListaNominal = _.sumBy(numeralia, o => o.LISTA_NOMINAL);


    let listaNominalEdad18 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_18);
    let listaNominalEdad19 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_19);

    let listaNominalEdad2024 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_20_24);
    let listaNominalEdad2529 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_25_29);
    let listaNominalEdad3034 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_30_34);
    let listaNominalEdad3539 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_35_39);

    let listaNominalEdad4044 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_40_44);
    let listaNominalEdad4549 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_45_49);
    let listaNominalEdad5054 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_50_54);
    let listaNominalEdad5559 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_55_59);

    let listaNominalEdad6064 = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_60_64);
    let listaNominalEdad65mas = _.sumBy(numeralia, o => o.LISTA_NOMINAL_EDAD_65mas);

    let listaNominaHombres = _.sumBy(numeralia, o => o.LISTA_NOMINAL_HOMBRES);
    let listaNominaMujeres = _.sumBy(numeralia, o => o.LISTA_NOMINAL_MUJERES);


    setListaNominal(totalListaNominal)


    setDataChartAgeRange([
      listaNominalEdad18,
      listaNominalEdad19,
      listaNominalEdad2024,
      listaNominalEdad2529,
      listaNominalEdad3034,
      listaNominalEdad3539,
      listaNominalEdad4044,
      listaNominalEdad4549,
      listaNominalEdad5054,
      listaNominalEdad5559,
      listaNominalEdad6064,
      listaNominalEdad65mas
    ].reverse())



    const chartGender = dataChartGender

    chartGender.data = [
      listaNominaHombres,
      listaNominaMujeres
    ]

    chartGender.percentage = [0, 0]

    if (totalListaNominal > 0) {
      let percentageMan = (listaNominaHombres * 100) / totalListaNominal
      let percentageWoman = (listaNominaMujeres * 100) / totalListaNominal

      chartGender.percentage = [Math.round(percentageMan), Math.round(percentageWoman)]
    }

    setChartGender(chartGender)

    let categoriesPesoElectoral = []

    for (let item of numeralia) {
      if (structure === VIEW_ESTATAL) {
        categoriesPesoElectoral.push(item.REGION)
      } else if (structure === VIEW_DF) {
        categoriesPesoElectoral.push(item.NOMBRE_MUNICIPIO)
      } else if (structure === VIEW_DL) {
        categoriesPesoElectoral.push(item.NOMBRE_MUNICIPIO)
      } else if (structure === REGION) {
        categoriesPesoElectoral.push(item.NOMBRE_MUNICIPIO)
      } else if (structure === DF) {
        categoriesPesoElectoral.push(item.NOMBRE_MUNICIPIO)
      }
      else if (structure === DL) {
        categoriesPesoElectoral.push(item.NOMBRE_MUNICIPIO)
      } else if (structure === MUNICIPAL) {
        categoriesPesoElectoral.push(item.POLIGONO)
      } else if (structure === POLIGONO) {
        categoriesPesoElectoral.push(item.SECCION)
      } else if (structure === SECCION) {
        categoriesPesoElectoral.push(item.SECCION)
      } else if (structure === DF) {
        categoriesPesoElectoral.push(item.SECCION)
      } else if (structure === DL) {
        categoriesPesoElectoral.push(item.SECCION)
      }
    }

    let dataPesoElectoral = []

    for (let item of numeralia) {
      let peso = 0
      if (/* item.Participacion === null || item.Participacion === 0 ||  */item.LISTA_NOMINAL === null || item.LISTA_NOMINAL === 0) {
        peso = 0
      } else {
        peso = /* parseFloat(item.Participacion)  */parseFloat(item.LISTA_NOMINAL) //* 100
      }

      dataPesoElectoral.push(peso)
    }

    setPesoElectoral({
      categories: categoriesPesoElectoral,
      data: dataPesoElectoral
    })

    let tiposSeccion = _.chain(numeraliaType).map(item => item).uniq().map(item => {
      let label = 'desconocido'
      if (parseInt(item.tipo, 10) === 1) {

        label = 'Mixto'
      }
      if (parseInt(item.tipo, 10) === 4) {
        label = 'Rural'
      }
      if (parseInt(item.tipo, 10) === 2) {
        label = 'Urbano'
      }
      if (parseInt(item.tipo, 10) === 3) {
        label = 'Mixto'
      }
      return {
        id: item.total,
        label: label,
        electores: item.ln
      }
    }).value()

    let tiposSeccionData = []

    let listaNominalSeccionData = []

    let ManzanasLabels = []
    for (let index = 1; index < 4; index++) {

      let label = 'desconocido'
      let p = 0;
      let color = '#44444'
      if (index === 1) {
        label = 'Prioridad 1'
        p = _.map(numeraliaPrio, item => parseInt(item.p1))
        color = '#78cf42'
      }
      if (index === 2) {
        label = 'Prioridad 2'
        p = _.map(numeraliaPrio, item => parseInt(item.p2))
        color = '#42bfcf'
      }
      if (index === 3) {
        label = 'Prioridad 3'
        p = _.map(numeraliaPrio, item => parseInt(item.p3))
        color = '#4292cf'
      }

      let temp = {
        name: label,
        data: p,
        color: color

      }
      ManzanasLabels.push(temp)

    }

    let ManzanasElectores = []
    for (let index = 1; index < 4; index++) {

      let label = 'desconocido'
      let p = 0;
      let color = '#44444'
      if (index === 1) {
        label = 'Prioridad 1'
        p = _.map(numeraliaPrio, item => parseInt(item.Ln1))
        color = '#78cf42'
      }
      if (index === 2) {
        label = 'Prioridad 2'
        p = _.map(numeraliaPrio, item => parseInt(item.Ln2))
        color = '#42bfcf'
      }
      if (index === 3) {
        label = 'Prioridad 3'
        p = _.map(numeraliaPrio, item => parseInt(item.Ln3))
        color = '#4292cf'
      }

      let temp = {
        name: label,
        data: p,
        color: color

      }
      ManzanasElectores.push(temp)

    }



    let sectionLabels = _.map(tiposSeccion, item => item.label)
    tiposSeccionData = _.map(tiposSeccion, item => item.id)
    listaNominalSeccionData = _.map(tiposSeccion, item => parseInt(item.electores))

    setManzanasType({
      categories: categoriesPesoElectoral,
      serie: ManzanasLabels
    })

    setmanzanasElectores({
      categories: categoriesPesoElectoral,
      serie: ManzanasElectores
    })

    setElectoralBySectionType({
      categories: sectionLabels,
      data: listaNominalSeccionData
    })

    setSectionType({
      categories: sectionLabels,
      data: tiposSeccionData
    })

    // Agregamos los detalles de la numeralia
    let titlosNumeralia = 'Desconocido'
    let titleCard = 'Desconocido'

    if (structure === VIEW_ESTATAL) {
      titlosNumeralia = 'REGIÓN'
      titleCard = 'REGIÓN'
    } else if (structure === VIEW_DF) {
      titlosNumeralia = 'DISTRITO FEDERAL'
      titleCard = 'DISTRITO FEDERAL'
    } else if (structure === VIEW_DL) {
      titlosNumeralia = 'DISTRITO LOCAL'
      titleCard = 'DISTRITO LOCAL'
    } else if (structure === REGION) {
      titlosNumeralia = 'MUNICIPIO LOCAL'
      titleCard = 'MUNICIPIO LOCAL'
    } else if (structure === MUNICIPAL) {
      titlosNumeralia = 'POLÍGONO'
      titleCard = 'POLÍGONO'
    } else if (structure === POLIGONO) {
      titlosNumeralia = 'SECCIÓN'
      titleCard = 'SECCIÓN'
    } else if (structure === SECCION) {
      titlosNumeralia = 'SECCIÓN'
      titleCard = 'SECCIÓN'
    } else if (structure === DF) {
      titlosNumeralia = 'ML'
      titleCard = 'ML'
    } else if (structure === DL) {
      titlosNumeralia = 'ML'
      titleCard = 'ML'
    }

    setStructure({
      title: titlosNumeralia,
      value: numeralia.length,
      titleCardChart: titleCard
    })
  }

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
    } else {
      setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  // HANDLER DE TODOS LOS SELECT DEL FILTRO

  const setFilter = (filtered) => {

    if (viewTypeSelected === VIEW_ESTATAL) {

      if (filtered[0].id === "idRegion" && filtered[0].value.length === 0 && filtered[3].value.length === 0 && filtered[4].value.length === 0 && filtered[5].value.length === 0) {
        getNumeralia(VIEW_ESTATAL, `getNumeraliaRegion`)
      }
      if (filtered[0].id === "idRegion" && filtered[0].value.length !== 0 && filtered[3].value.length === 0 && filtered[4].value.length === 0 && filtered[5].value.length === 0) {
        getNumeralia(REGION, `getNumeraliaMunicipiosByRegion`, { "region": filtered[0].value })
      }
      if (filtered[3].id === "idMunicipio" && filtered[3].value.length !== 0 && filtered[4].value.length === 0 && filtered[5].value.length === 0) {
        getNumeralia(MUNICIPAL, `getNumeraliaPoligonosByMunicipio`, { municipio: filtered[3].value })
      }
      if (filtered[5].id === "idPoligono" && filtered[5].value.length !== 0 && filtered[4].value.length === 0) {
        getNumeralia(POLIGONO, `getNumeraliaSeccionesByPoligono`, { poligono: filtered[5].value })
      }
      if (filtered[4].id === "idSeccion" && filtered[4].value.length !== 0) {
        getNumeralia(SECCION, `getNumeraliaSeccion`, { seccion: filtered[4].value })
      }
    }
    else if (viewTypeSelected === VIEW_DF) {

      if (filtered[1].id === "DF" && filtered[1].value.length === 0 && filtered[3].value.length === 0 && filtered[4].value.length === 0 && filtered[5].value.length === 0) {
        getNumeralia(VIEW_DF, `getNumeraliaDF`)
      }
      if (filtered[1].id === "DF" && filtered[1].value.length !== 0 && filtered[3].value.length === 0 && filtered[4].value.length === 0 && filtered[5].value.length === 0) {
        getNumeralia(DF, `getNumeraliaMunicipiosByDF`, { df: filtered[1].value })
      }
      if (filtered[3].id === "idMunicipio" && filtered[3].value.length !== 0 && filtered[4].value.length === 0 && filtered[5].value.length === 0) {
        let data = {
          df: filtered[1].value,
          municipio: filtered[3].value
        }
        getNumeralia(MUNICIPAL, `getNumeraliaPoligonoByDF`, data)
      }
      if (filtered[5].id === "idPoligono" && filtered[5].value.length !== 0 && filtered[4].value.length === 0) {

        getNumeralia(POLIGONO, `getNumeraliaSeccionesByPoligono`, { poligono: filtered[5].value })
      }
      if (filtered[4].id === "idSeccion" && filtered[4].value.length !== 0) {
        getNumeralia(SECCION, `getNumeraliaSeccion`, { seccion: filtered[4].value })
      }

    }
    else if (viewTypeSelected === VIEW_DL) {

      if (filtered[2].id === "DL" && filtered[2].value.length === 0 && filtered[3].value.length === 0 && filtered[4].value.length === 0 && filtered[5].value.length === 0) {
        getNumeralia(VIEW_DL, `getNumeraliaDL`)
      }
      if (filtered[2].id === "DL" && filtered[2].value.length !== 0 && filtered[3].value.length === 0 && filtered[4].value.length === 0 && filtered[5].value.length === 0) {
        getNumeralia(DL, `getNumeraliaMunicipiosByDL`, { dl: filtered[2].value })
      }
      if (filtered[3].id === "idMunicipio" && filtered[3].value.length !== 0 && filtered[4].value.length === 0 && filtered[5].value.length === 0) {
        let data = {
          dl: filtered[2].value,
          municipio: filtered[3].value
        }
        getNumeralia(MUNICIPAL, `getNumeraliaPoligonoByDL`, data)
      }
      if (filtered[5].id === "idPoligono" && filtered[5].value.length !== 0 && filtered[4].value.length === 0) {
        getNumeralia(POLIGONO, `getNumeraliaSeccionesByPoligono`, { poligono: filtered[5].value })
      }
      if (filtered[4].id === "idSeccion" && filtered[4].value.length !== 0) {
        getNumeralia(SECCION, `getNumeraliaSeccion`, { seccion: filtered[4].value })
      }

    }


  }

  const handleViewType = (e) => {
    setViewTypeSelected(e.value)

    let typeView = e.value

    let responsePromise = null

    if (typeView === VIEW_ESTATAL) {
      responsePromise = EjecutarApi({}, `getNumeraliaRegion`, token.access_token)

    } else if (typeView === VIEW_DF) {
      responsePromise = EjecutarApi({}, `getNumeraliaDF`, token.access_token)

    } else if (typeView === VIEW_DL) {
      responsePromise = EjecutarApi({}, `getNumeraliaDL`, token.access_token)
    }

    responsePromise.then(res => {
      let data = res.data

      formatDataChart(data, typeView)
    })
      .catch((e) => {
        showMessage("Error: catCompromisosWeb")
      }).then(() => {
        setLoading(false);
      })


  }


  const btnCancelarImportar = (e) => {

    setImport(e)
    setRefresh(e);

  };

  const Exportar = () => {
    var url = new URL(
      URL_PUBLICA +
      `getReporteNumeralia?token=${token.access_token}`
    );
    window.open(url, "_blank");
  }


  //Condiciones por cargo
  const showVista = responsibilityID === 99 || responsibilityID === 98 || responsibilityID === 50 || responsibilityID === 51 || responsibilityID === 52

  //FUNCIONES QUE DAN FORMATOD A LOS DATOS PARA LOS DIAGRAMAS

  const showChartGender = dataChartGender.data[0] > 0 && dataChartGender.data[1] > 0

  const formatDataChartGender = () => {
    let data = [
      {
        name: dataChartGender.labels[0],
        color: '#36A2EB',
        y: dataChartGender.data[0],
        dataLabels: {
          enabled: false
        }
      },
      {
        name: dataChartGender.labels[1],
        color: '#FF6384',
        y: dataChartGender.data[1],
        dataLabels: {
          enabled: false
        }
      }
    ]

    return data
  }

  const formatDataAgeRange = () => {
    return dataChartAgeRange
  }

  const formatDataAreaType = () => {
    let data = {
      categories: sectionType.categories,
      data: sectionType.data
    }
    return data
  }

  const formatDataElectoresTipoArea = () => {

    let data = {
      categories: electoralBySectionType.categories,
      data: electoralBySectionType.data
    }
    return data
  }

  const formatManzanas = () => {

    let data = {
      categories: manzanasType.categories,
      serie: manzanasType.serie
    }
    return data
  }

  const formatManzanasElectores = () => {

    let data = {
      categories: manzanasType.categories,
      serie: manzanasElectores.serie
    }
    return data

  }

  const formatDataPesoElectoral = () => {
    let data = {
      categories: pesoElectoral.categories,
      data: pesoElectoral.data
    }
    return data
  }


  // CONEXIONES A LA API

  const getNumeralia = (structure = ESTATAL, query = '', data = '') => {
    EjecutarApi(data, query, token.access_token)
      .then(res => {
        let data = res.data

        //if (res.items) {
        //setNumeralia(data)

        formatDataChart(data, structure)
        /*  } else {
            showMessage("No Info: catCompromisosWeb")
          }*/
      })
      .catch((e) => {
        showMessage("Error: catCompromisosWeb")
      }).then(() => {
        setLoading(false);
      })
  }

  const showMessage = (message) => {
    notify.show(message, "custom", 5000, {
      background: "#0857B6",
      fontSize: 14,
      text: "#FFFFFF",
    });
  }

  return (
    <div className="wrapper">
      <Notifications />
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
        <div className="content">

          <div className="content">
            {/* fILTROS */}
            <Card>
              <CardBody>
                <Row>
                  {showVista ? (
                    <Col lg="2" md="2" sm="2">
                      <FormGroup>
                        <Label for="viewType">
                          VISTA
                          </Label>
                        <Select
                          options={viewsType}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          defaultValue={viewTypeSelected}
                          onChange={(e) => { handleViewType(e) }}
                          id="viewType"
                          placeholder="FILTRAR POR..."
                        />
                      </FormGroup>
                    </Col>)
                    : " "}
                  {viewTypeSelected === VIEW_ESTATAL ?
                    (<MultiFilterRegion filtrar={setFilter} viewTypeSelected={viewTypeSelected} responsibilityID={responsibilityID} flagFinish={true} ubicaciónResponsabilidad={ubicaciónResponsabilidad}>
                    </MultiFilterRegion>) :
                    (viewTypeSelected === VIEW_DF ? (<MultiFilterDF filtrar={setFilter} viewTypeSelected={viewTypeSelected} responsibilityID={responsibilityID} flagFinish={true} ubicaciónResponsabilidad={ubicaciónResponsabilidad}>
                    </MultiFilterDF>) :
                      (viewTypeSelected === VIEW_DL ? (<MultiFilterDL filtrar={setFilter} viewTypeSelected={viewTypeSelected} responsibilityID={responsibilityID} flagFinish={true} ubicaciónResponsabilidad={ubicaciónResponsabilidad}>
                      </MultiFilterDL>) : (<></>))
                    )
                  }

                </Row>
              </CardBody>
              <CardFooter>
                {menu.Exportar === 1 ? (
                  <Col>
                    <Button name="btnImport" size="sm" type="button" className="info float-right" color='info' onClick={() => { setImport(true) }}>Importar</Button>
                    <Button name="btnExportar" size='sm' type="button" className='primary float-right' color='primary' onClick={() => Exportar()}>
                      <span className="btn-label">
                        <i className="fa fa-file-excel-o fa-lg" />
                      </span>
                      Exportar
												</Button>
                  </Col>
                ) : <></>}
              </CardFooter>
            </Card>
            {/* chARTS */}
            <Row>
              <Col lg="3" md="3" sm="3">
                <Card className="card-stats" style={{ height: "236px" }}>
                  <CardBody>
                    <Row>
                      <Col md="12" xs="12">
                        <div className="numbers">
                          <p className="card-category">LNOM/{structure.titleCardChart}</p>
                          LNOM<CardTitle tag="p">{new Intl.NumberFormat('en-EN').format(listaNominal)}</CardTitle>
                          {structure.title}<CardTitle tag="p">{new Intl.NumberFormat('en-EN').format(structure.value)}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {/*
                        <CardFooter>
                          <hr />
                          <div className="stats">
                            <i className="fa fa-calendar-o" />
                            Last day
                          </div>
                        </CardFooter>
                        */}
                </Card>
              </Col>
              <Col lg="9" md="9" sm="9">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="12" xs="12">
                        <div className="numbers">
                          <p className="card-category">PESO ELECTORAL POR {structure.titleCardChart}</p>
                          <PesoElectoralChart data={formatDataPesoElectoral()} />
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" md="4" sm="4">
                <Card className="card-stats" style={{ height: "300px" }}>
                  <CardBody>
                    <div className="numbers">
                      <p className="card-category">Sexo</p>
                    </div>
                    <Row>
                      <Col md="6" xs="6" style={{ textAlign: 'right' }}>
                        <span style={{ color: "#FF6384" }}>% {dataChartGender.percentage[1]}</span>
                      </Col>
                      <Col md="6" xs="6">
                        <span style={{ color: "#36A2EB" }}>% {dataChartGender.percentage[0]}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" xs="12">
                        {showChartGender &&
                          <SexoChart data={formatDataChartGender()} />
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" xs="6" style={{ textAlign: 'right' }}>
                        <span style={{ color: "#FF6384" }}>Mujeres {new Intl.NumberFormat('en-EN').format(dataChartGender.data[1])}</span>
                      </Col>
                      <Col md="6" xs="6">
                        <p style={{ color: "#36A2EB" }}>Hombres {new Intl.NumberFormat('en-EN').format(dataChartGender.data[0])}</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="4" md="4" sm="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="12" xs="12">
                        <div className="numbers">
                          <p className="card-category">TIPO DE SECCIÓN/{structure.titleCardChart}</p>
                          <TipoAreaChart data={formatDataAreaType()} />
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" md="4" sm="4">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="12" xs="12">
                        <div className="numbers">
                          <p className="card-category">SECCIÓN ALCANCES/{structure.titleCardChart}</p>
                          <ElectoresTipoAreaChart data={formatDataElectoresTipoArea()} />
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="12" xs="12">
                        <div className="numbers">
                          <p className="card-category">P. ML/ {structure.titleCardChart}</p>
                          <PrioridadAreaChart data={formatManzanas()} />
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="12" xs="12">
                        <div className="numbers">
                          <p className="card-category">ML LNOM/ {structure.titleCardChart}</p>
                          <PrioridadAreaChart data={formatManzanasElectores()} />
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="9" md="9" sm="9">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="12" xs="12">
                        <div className="numbers">
                          <p className="card-category">RANGO POR EDAD</p>
                          <RangoEdadChart data={formatDataAgeRange()} />
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Footer fluid />
        {loading && (
          <CircleSpinner
            className="text-center"
            size={30}
            color="#686769"
            loading={loading}
          />
        )}
      </div>
      {isImport ? <ModalImportExcel onHide={btnCancelarImportar} /> : <></>}
    </div>
  );
};

export default Numeralia;
