import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const RangoEdadChart = (props) => {
  let data = props.data
    const options = {
      credits: {
         enabled: false
      },
      chart: {
        type: 'bar',
        height: '260px'
    },
    title: {
        text: ''
    },
    xAxis: {

        categories: ['65mas', '60_64', '55_59', '50_54', '45_49', '40_44', '35_39', '30_34', '25_29', '20-24', '19', '18'],
        title: {
            text: null
        }
    },
    yAxis: {
      visible: true,
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        },
    },
    tooltip: {
      formatter: function() {
        if (this.y > 1000000) {
          return this.x+":"+new Intl.NumberFormat('en-EN').format(this.y)+"M"
        } else if (this.y > 1000) {
          return this.x+":"+new Intl.NumberFormat('en-EN').format(this.y) ;
        } else {
          return this.x+":"+this.y
        }
      },
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true,
                formatter: function() {
                  if (this.y > 1000000) {
                    return new Intl.NumberFormat('en-EN').format(this.y)+"M"
                  } else if (this.y > 1000) {
                    return new Intl.NumberFormat('en-EN').format(this.y) ;
                  } else {
                    return this.y
                  }
                }
            }
        }
    },
    credits: {
        enabled: false
    },
    series: [{
      showInLegend: false,
        name: '',
        data: data
    }]
  }


  return (
      <HighchartsReact
       highcharts={Highcharts}
       options={options}
     />
  )
}

export default RangoEdadChart
