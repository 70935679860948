import React from 'react';
import Login from './Login';
import { Redirect } from 'react-router-dom';
import { getVars } from '../GlobalStorage';

const Splash = () => {
	return getVars('Token').access_token ? <Redirect to="/" /> : <Login />;
};

export default Splash;
