import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import classnames from "classnames";
import { RotateSpinner } from "react-spinners-kit";
import TreeCoordinadores from "../../components/Social/TreeCoordinadores";
import TreeEnlaces from "../../components/Social/TreeEnlaces";
import TreeAsigCoordinadores from "../../components/Social/TreeAsigCoordinadores";
import TreeAsigEnlace from "../../components/Social/TreeAsigEnlace";
import { BsPersonFill, BsFillDiagram3Fill, BsPersonLinesFill } from "react-icons/bs";
import { getVars } from 'GlobalStorage';
import { getDataMenu } from '../../data/menus.js';

function DataEstructura(props) {
    const { idEstructura } = props;
    // Configuracion
    //Menu
    const token = getVars('Token');
    const [_menu, _setmenu] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const toggle = tab => {
        setLoading(true);
        if (activeTab !== tab) setActiveTab(tab);
        setLoading(false);
    };

    useEffect(() => {
        _setmenu(getDataMenu(134, token.menus));
        //handleBuscarCoordinadores(idEstructura)
    }, [idEstructura]);


    /*  const handleBuscarCoordinadores = (idEstructura) =>{
         setLoading(true);
         var parametro = {
             idEstructura: [idEstructura],
             page: 0,
             pageSize: 10
         };
 
         EjecutarApi(parametro, 'getUsersSocialStructuresWeb', token.access_token)
             .then(function (res) {
                 return res;
             })
             .then((res) => {
                 if (res.results) {
                     setDatosResponsable(res.data.Coordinadores);
                     setTotalCoordinadores(res.data.TotalCoordinadores)
                 }
             })
             .catch((e) => {
                 notify.show(`Error en el listado: ${e.message}`, "error", 5000, {
                     background: "#d61818",
                     text: "#FFFFFF",
                 });
             })
             .finally(()=>setLoading(false));
     }; */




    return (
        <Card>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                            toggle("1");
                        }}
                    >
                        <BsPersonFill /> Coordinadores
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                            toggle("2");
                        }}
                    >
                        <BsFillDiagram3Fill /> Enlaces
                    </NavLink>
                </NavItem>
                {
                    _menu.Agregar === 1 &&
                    <>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "3" })}
                                onClick={() => {
                                    toggle("3");
                                }}
                            >
                                <BsPersonLinesFill /> Asignar Responsabilidad
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "4" })}
                                onClick={() => {
                                    toggle("4");
                                }}
                            >
                                <BsPersonLinesFill /> Asignar Enlaces
                            </NavLink>
                        </NavItem>
                    </>
                }

            </Nav>
            <RotateSpinner color={"#1261ff"} size={30} loading={loading} />
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Col>
                        <TreeCoordinadores estructura={idEstructura} />
                    </Col>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col>
                            <TreeEnlaces estructura={idEstructura} />
                        </Col>
                    </Row>
                </TabPane>
                {
                    _menu.Agregar === 1 &&
                    <>
                        <TabPane tabId="3">
                            <Col>
                                {activeTab === "3" &&
                                    <TreeAsigCoordinadores idEstructura={idEstructura} />
                                }

                            </Col>
                        </TabPane>
                        <TabPane tabId="4">
                            <Col>
                                {activeTab === "4" &&
                                    <TreeAsigEnlace idEstructura={idEstructura} />
                                }
                            </Col>
                        </TabPane>
                    </>
                }
            </TabContent>
        </Card>
    );

}
export default DataEstructura;