import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from "GlobalStorage";
import bloqueado from 'data/bloqueado.json';
import ChartPorSeccionByMunicipio from 'components/Charts/Tablero/ChartPorSeccionByMunicipio';
import ChartPorSeccion from 'components/Charts/Tablero/ChartPorSeccion';
import ChartPorManzana from 'components/Charts/Tablero/ChartPorManzana';
import ChartPorPoligono from 'components/Charts/Tablero/ChartPorPoligono';
import PerfectScrollbar from "perfect-scrollbar";

import ModalFiltro from '../../components/Filtro/indexTablero';
import { ContactSupportOutlined } from "@material-ui/icons";
var ps;

const PorSeccion = (props) => {
  const mainPanel = useRef();
  const [state, setState] = useState({ idRegion: { value: null, label: 'Seleccione' }, idMunicipio: { label: 'Seleccione', value: 0 }, Tipo: { label: 'Seccional', value: 2 } });
  const [isBloqueado, setIsBloqueado] = useState(false);
  const [compromisos, setCompromisos] = useState([]);
  const [total, setTotal] = useState({ hoy: 0, total: 0, voluntarios: 0 });
  const [totalCompromisos, setTotalCompromisos] = useState(0);
  const [isEditing, setEditing] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [compromisoEdit, setCompromisoEdit] = useState({});
  const [Filter, setFiltro] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
  const [MenuModal] = useState({
    Compromiso: true,
    Voluntarios: false,
    RCs: false,
    RGs: false,
    Abogado: false,
    CasaAzul: false,
    Municipio: false
  });
  const [loadingChart, setLoadingChart] = useState(true);
  const [series, setSeries] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [charCategorias, setChartCategorias] = useState(null);
  const [charAvance, setChartAvance] = useState(null);
  const [charMeta, setChartMeta] = useState(null);
  const [charSubtitle, setChartSubtitle] = useState(null);
  const [charMetaLine, setChartMetaLine] = useState(null);
  const [catRegiones, setCatRegiones] = useState([]);
  const [catMunicipios, setCatMunicipios] = useState([]);
  const [catRegionesFilter, setCatRegionesFilter] = useState([]);
  const [catMunicipiosFilter, setCatMunicipiosFilter] = useState([]);

  const [misAvancesChart, setMisAvancesChart] = useState([]);
  const [misAvancesChartItems, setMisAvancesChartItems] = useState([]);
  const [MetaLine, setMetaLine] = useState(null);


  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    if (props.history.action === "PUSH") {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    _setmenu(getDataMenu(9, token.menus));

    setLoading(true);

    EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {

            setCatRegiones(res.data.regiones);
            setCatMunicipios(res.data.municipios);
            setCatRegionesFilter(res.data.regiones);
            setCatMunicipiosFilter(res.data.municipios);
          }
        }
      }).catch((e) => {

      })
      .finally(x => {
        setLoading(false);
      })

  }, [])

  useEffect(() => {
    let tipo = state.Tipo;
    let idMunicipio = state.idMunicipio.value;
    let apiName = "getSeccionalesByMunicipioDia";
    if (tipo.value == 1) {
      apiName = "getPoligonalesByMunicipioDia";
    }
    if (tipo.value == 2) {
      apiName = "getSeccionalesByMunicipioDia";
    }
    if (tipo.value == 3) {
      apiName = "getManzanerasByMunicipioDia";
    }

    if (tipo.value > 0 && idMunicipio > 0) {
      getDataChart({ idMunicipio: idMunicipio }, apiName);
    }
  }, [state])

  useEffect(() => {
  }, [misAvancesChartItems])

  const changeRegion = (idRegion) => {
    if (idRegion.value > 1) {

      setState({ ...state, idRegion, dMunicipio: { label: 'Seleccione', value: 0 } });
      const misMunis = catMunicipios.filter(data => data.idRegion === idRegion.value);
      setCatMunicipiosFilter(misMunis);


      const DataItems = [];

      const tmpX = misMunis.map(item => {
        EjecutarApi({ idMunicipio: item.value }, "getSeccionalesByMunicipioDia", token.access_token)
          .then(function (res) {
            return res;
          })
          .then((res) => {
            if (res.success) {
              if (res.results) {


                const Categorias = [];
                const Avance = [];
                const Meta = [];
                const Subtitle = `Seccionales de ${item.Municipio}`;
                var MetaLine = 0;

                res.data.forEach(item => {
                  Categorias.push(`${item.Dia}`);
                  Avance.push(item.Acumulado);
                  Meta.push(parseInt(item.MetaEstructuraPartido));
                  MetaLine = parseInt(item.MetaEstructuraPartido);
                })

                const objData = [{
                  Categorias,
                  Avance,
                  Meta,
                  Subtitle,
                  MetaLine
                }];

                const newArray = DataItems.concat(objData);
                setMisAvancesChartItems(newArray);
              }

            }
          })
          .catch((e) => {

          })
      });
    } else {
      setState({ ...state, idRegion: { label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 } });
      setCatMunicipiosFilter(catMunicipios);
    }
  }

  const changeMunicipio = (idMunicipio) => {
    if (idMunicipio.value > 1) {
      setState({ ...state, idMunicipio });
    } else {
      setState({ ...state, idMunicipio: { label: 'Seleccione', value: 0 } });
    }
  }

  const getDataChart = (data, apiName) => {
    setLoadingChart(true);
    EjecutarApi(data, apiName, token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            const Categorias = [];
            const Avance = [];
            const Meta = [];
            res.data.forEach(item => {
              Categorias.push(`${item.Dia}`);
              Avance.push(item.Acumulado);
              Meta.push(parseInt(item.MetaEstructuraPartido));
              setMetaLine(item.MetaEstructuraPartido);
              setChartSubtitle(`${state.Tipo.label} de ${item.Municipio}`);
            })
            setChartCategorias(Categorias);
            setChartAvance(Avance);
            setChartMeta(Meta);

            setDataChart(res.data.data);
            setSeries(res.data.series);
          }
        }
      })
      .catch((e) => {
        notify.show(`Error al obtener datos de la gráfica`, "error", 5000);

      })
      .finally(x => {
        setLoadingChart(false);
      })
  }

  const [isChartView, setIsChartView] = useState(true);
  const [idResponsabilidad] = useState([1, 2, 3, 9, 8, 12, 13, 14, 15, 16, 17, 18, 31, 34, 35, 40, 56, 98, 100, 101])

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };



  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">

          <Card>
            <CardHeader>
              <h5 className="title">Tablero de control diario</h5>
              <Row>
                <Col sm="4" className="text-muted">Tipo:
                        {loading && (
                    <CircleSpinner
                      className="text-center"
                      size={30}
                      color="#686769"
                      loading={loading}
                    />
                  )}            {!loading && (<Select
                    options={[{ label: 'Poligonal', value: 1 }, { label: 'Seccional', value: 2 }, { label: 'Manzanero', value: 3 }]}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={state.Tipo}
                    onChange={(e) => setState({ ...state, Tipo: e })}
                    id="viewTypeM"
                    placeholder="Tipo"
                  />)}
                </Col>
                <Col sm="4" className="text-muted">Región:
                      {loading && (
                    <CircleSpinner
                      className="text-center"
                      size={30}
                      color="#686769"
                      loading={loading}
                    />
                  )}            {!loading && (<Select
                    options={catRegionesFilter}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={setState.idRegion}
                    onChange={(e) => changeRegion(e)}
                    id="viewTypeM"
                    placeholder="Filtrar por"
                  />)}
                </Col>
                <Col sm="4" className="text-muted">Municipio:
                        {loading && (
                    <CircleSpinner
                      className="text-center"
                      size={30}
                      color="#686769"
                      loading={loading}
                    />
                  )}            {!loading && (<Select
                    options={catMunicipiosFilter}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={state.idMunicipio}
                    onChange={(e) => changeMunicipio(e)}
                    id="viewTypeM"
                    placeholder="Filtrar por"
                  />)}
                </Col>
              </Row>

            </CardHeader>
            <CardBody>
              {misAvancesChart}
              <Row>
                {state.Tipo.value === 1 && <Col sm="12">{isChartView && (
                  !loadingChart ? <ChartPorPoligono
                    yAxisText="Poligonales"
                    MetaLine={MetaLine}
                    Categorias={charCategorias}
                    Subtitle={charSubtitle}
                    Avance={charAvance}
                    Meta={charMeta}
                  /> :
                    (
                      <Row>
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loadingChart}
                        /></Row>))}
                </Col>}
                {state.Tipo.value === 2 && <Col sm="12">{isChartView && (
                  !loadingChart ? <ChartPorSeccion
                    yAxisText="Seccionales"
                    MetaLine={MetaLine}
                    Categorias={charCategorias}
                    Subtitle={charSubtitle}
                    Avance={charAvance}
                    Meta={charMeta}
                  /> :
                    (
                      <Row>
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loadingChart}
                        /></Row>))}
                </Col>}
                {state.Tipo.value === 3 && <Col sm="12">{isChartView && (
                  !loadingChart ? <ChartPorManzana
                    yAxisText="Manzaneras"
                    MetaLine={MetaLine}
                    Categorias={charCategorias}
                    Subtitle={charSubtitle}
                    Avance={charAvance}
                    Meta={charMeta}
                  /> :
                    (
                      <Row>
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loadingChart}
                        /></Row>))}
                </Col>}
              </Row>
            </CardBody>
          </Card>

        </div>

        <Footer fluid />
      </div>
    </div>
  );
};

export default PorSeccion;  