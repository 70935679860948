import React, { useState, useEffect } from 'react';
import { Row, Col, CustomInput, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from 'reactstrap';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { notify } from "react-notify-toast";
import EjecutarApi from "data/getPOST";
import { getVars } from "GlobalStorage";

const ModalReset = ({ onHide, isUpdated }) => {
    const token = getVars("Token");
    const [confirm, setConfirm] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [dataForm, setDataForm] = useState({
        AvancePapel: false,
        AvancePapelPoligonal: false,
        AvancePapelCompromisos: false,
        AvancePapelSeccional: false,
        AvancePapelMzaP1: false,
        AvancePapelMzaP2: false,
        AvancePapelMzaP3: false
    });

    useEffect(() => {
        setIsDisabled(true);
        Object.keys(dataForm).forEach(function (key) {
            if (dataForm[key]) {
                setIsDisabled(false);
            }
        });
    }, [dataForm]);

    const changeChekedValues = (e) => {
        let name = e.target.name;
        if (e.target.checked) {
            setDataForm(dataForm => ({ ...dataForm, [name]: true }))
        } else {
            setDataForm(dataForm => ({ ...dataForm, [name]: false }))
        }
    }

    const setResetPaperValues = () => {
        EjecutarApi(dataForm, "resetPapel", token.access_token, "POST")
            .then(res => {
                if (res.results) {
                    isUpdated(true);
                    notify.show("Valores restablecidos con éxito!", "success", 5000);
                } else {
                    notify.show("Error al restablecer valores en papel", "error", 5000);
                    isUpdated(false);
                }
            }).catch((e) => {
                isUpdated(false);
                notify.show("Error al restablecer valores en papel", "error", 5000);
            });
        onHide(false);
    };

    const confirmMessage = (
        <ReactBSAlert
            warning
            title="¿Está seguro que desea restablecer los valores en papel?"
            onConfirm={() => setResetPaperValues()}
            onCancel={() => setConfirm(false)}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Si, restablecer!"
            cancelBtnText="No"
            showCancel
        >
        </ReactBSAlert>
    );

    return (
        <Modal
            isOpen={true}
            toggle={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader> Restablecer valores en papel </ModalHeader>
            <ModalBody>
                <Row md='12' className="justify-content-md-center">
                    <FormGroup check className="col-md-4">
                        <Label check>
                            <CustomInput
                                id="AvancePapel"
                                name="AvancePapel"
                                type="checkbox"
                                label="Municipales"
                                onChange={e => changeChekedValues(e)}
                            />
                            <CustomInput
                                id="AvancePapelPoligonal"
                                name="AvancePapelPoligonal"
                                type="checkbox"
                                label="Poligonales"
                                onChange={e => changeChekedValues(e)}
                            />
                            <CustomInput
                                id="AvancePapelCompromisos"
                                name="AvancePapelCompromisos"
                                type="checkbox"
                                label="Compromisos"
                                onChange={e => changeChekedValues(e)}
                            />
                        </Label>
                    </FormGroup>
                    <FormGroup check className="col-md-4">
                        <Label check>
                            <CustomInput
                                id="AvancePapelSeccional"
                                name="AvancePapelSeccional"
                                type="checkbox"
                                label="Seccionales"
                                onChange={e => changeChekedValues(e)}
                            />
                            <CustomInput
                                id="AvancePapelMzaP1"
                                name="AvancePapelMzaP1"
                                type="checkbox"
                                label="Manzaneras Prioridad 1"
                                onChange={e => changeChekedValues(e)}
                            />
                        </Label>
                    </FormGroup>
                    <FormGroup check className="col-md-4">
                        <Label check>
                            <CustomInput
                                id="AvancePapelMzaP2"
                                name="AvancePapelMzaP2"
                                type="checkbox"
                                label="Manzaneras Prioridad 2"
                                onChange={e => changeChekedValues(e)}
                            />
                            <CustomInput
                                id="AvancePapelMzaP3"
                                name="AvancePapelMzaP3"
                                type="checkbox"
                                label="Manzaneras Prioridad 3"
                                onChange={e => changeChekedValues(e)}
                            />
                        </Label>
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter className="justify-content-md-center">
                {confirm ? confirmMessage : ''}
                <Row >
                    <Col>
                        <Button color='primary' className='primary' size='sm' onClick={(e) => setConfirm(true)} disabled={isDisabled} >Restablecer</Button>
                    </Col>
                    <Col>
                        <Button color='danger' className='danger' size='sm' onClick={(e) => { onHide(false); isUpdated(false); }} >Cancelar</Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    );
};


export default ModalReset;