import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input, Label,
  Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import Switch from "react-bootstrap-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faKey,
  faInfoCircle,
  faLock,
  faEdit, faSignInAlt, faMobile
} from "@fortawesome/free-solid-svg-icons";
import { RotateSpinner } from "react-spinners-kit";
import classnames from "classnames";
import Navbar from "components/Navbar";
import Footer from "components/Footer"
import Sidebar from "components/Sidebar";
import { getVars } from 'GlobalStorage';

import ReactTable from 'react-table';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { CircleSpinner } from "react-spinners-kit";
import Pagination from "components/Pagination/pagination";
import FiltroUsr from "components/Filtro/indexUsuario";
import imagen from "assets/img/default-avatar.png";
import UpdMenus from "components/Usuarios/UpdMenus";
import UsuarioLog from "components/Perfil/UsuarioLog";
import UsuarioEdit from "components/Perfil/DetalleUsuario";
import { UsuarioForm } from "data/interfaces/Usuario";

function UsuariosList(props) {

  // Configuracion
  const [setsidebarMini] = useState(true);
  const [backgroundColor] = useState("black");
  const [activeColor] = useState("info");
  const [loading, setLoading] = useState(false);
  const token = getVars('Token');
  const [compromisos, setCompromisos] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [totalCompromisos, setTotalCompromisos] = useState(0);
  const [Filtro, setFiltro] = useState([]);
  const [UserID, setUserID] = useState({});
  const [userData, setUserData] = useState(UsuarioForm);
  const [passwordCollapsed, setPasswordCollapsed] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  const [isPassword2, setIsPassword2] = useState(false);
  const [password, setPaswoord] = useState("");
  const [pin, setPin] = useState("");
  const [pinCollapsed, setPinCollapsed] = useState(false);
  const [desvincularCollapsed, setDesvincularCollapsed] = useState(false);
  const [emailCollapsed, setEmailCollapsed] = useState(false);
  const [classPassword, setClassPaswoord] = useState({
    pass: "",
    passConfirm: "",
  });
  //Menu
  const [_menu, _setmenu] = useState({});
  const [activeTab, setActiveTab] = useState("1");

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [webMenus, setWebMenus] = useState({ menus: [], selected: [], others: [], othersUser: [] });
  const [mobileMenus, setMobileMenus] = useState({ menus: [], selected: [], others: [], othersUser: [] });
  const [flagLoading, setFlagLoading] = useState(true);
  const [avatar, setAvatar] = useState(imagen);
  const [idUser, setIdUser] = useState(null);
  const [catalogos, setCatalogos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [Email, setEmail] = useState('');

  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(15, token.menus));
    EjecutarApi({}, "catCompromisosWeb", token.access_token, "GET")
      .then(function (res) {
        return res;
      })
      .then((items) => {
        if (items.results) {
          setCatalogos(items.data);
          setLoaded(true);
        } else {
          notify.show("No Info: catCompromisosWeb", "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        notify.show("Error: catCompromisosWeb", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  }, []);

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
    } else {
      setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };
  const handleSetEditing = (item) => {
    setAvatar(imagen)
    setFlagLoading(true);
    setWebMenus({ menus: [], selected: [], others: [], othersUser: [] });
    setMobileMenus({ menus: [], selected: [], others: [], othersUser: [] });

    setEditing(false);
    setLoading(true);
    const data = { id: item.id };
    EjecutarApi(data, "getUsuarioDetalleById", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setLoading(false);
          setUserID(res.data);
          setIdUser(res.data.id);
          if (res.data.foto64 !== "") {
            setAvatar(res.data.foto64)
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener Usuario: ${e.error}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
    setEditing(true);

    EjecutarApi({ idUser: item.id }, "getUserMenus", token.access_token)
      .then(function (res) {
        return res;
      })
      .then(res => {
        if (res.success) {
          setWebMenus({
            menus: mapMenus(res.data.web.menus),
            selected: mapMenus(res.data.web.user),
            others: mapMenus(res.data.web.others),
            othersUser: mapMenus(res.data.web.othersUser)
          });
          setMobileMenus({
            menus: mapMenus(res.data.mobile.menus),
            selected: mapMenus(res.data.mobile.user),
            others: mapMenus(res.data.mobile.others),
            othersUser: mapMenus(res.data.mobile.othersUser)
          });
          setFlagLoading(false);
        }
      }).catch((e) => {
        setFlagLoading(false);
      });

    setFlagLoading(true);
    EjecutarApi({ id: item.id }, "getUserById", token.access_token)
      .then(function (res) {
        return res;
      })
      .then(res => {
        setFlagLoading(false);
        if (res.results) {
          setUserData(res.data);
        }
      }).catch((e) => {
        setFlagLoading(false);
      });
  };

  const mapMenus = data => {
    let menus = [];
    if (data.length < 1) return menus;
    data.forEach(element => {
      let children = element.children;
      delete element.children;
      menus.push(element);
      children.forEach(item => {
        menus.push(item);
      });
    });
    return menus;
  };

  const collapsesToggle = (tab) => {
    switch (tab) {
      case "password":
        if (passwordCollapsed) {
          setPasswordCollapsed(false);
        } else {
          setPasswordCollapsed(true);
        }
        break;
      case "pin":
        if (pinCollapsed) {
          setPinCollapsed(false);
        } else {
          setPinCollapsed(true);
        }
        break;
      case "desvincular":
        if (desvincularCollapsed) {
          setDesvincularCollapsed(false);
        } else {
          setDesvincularCollapsed(true);
        }
        break;
      case "email":
        if (emailCollapsed) {
          setEmailCollapsed(false);
        } else {
          setEmailCollapsed(true);
        }
        break;
      default:
        break;
    }
  };
  const verificaPassword = (event) => {
    if (password === event) {

      setClassPaswoord({ classPassword, pass: "has-success" });
      setClassPaswoord({ classPassword, passConfirm: "has-success" });
      return true;
    } else {

      setClassPaswoord({ classPassword, pass: "has-danger" });
      setClassPaswoord({ classPassword, passConfirm: "has-danger" });
      return false;
    }
  };
  const handlleCambiarContrasenia = () => {
    setLoading(true);
    var data = {
      idUser: UserID.id,
      password: password,
    };
    EjecutarApi(data, "updPassword", token.access_token)
      .then(function (res) {
        if (res.success) {
          notify.show(`Contraseña actualizada con exito`, "custom", 5000, {
            background: "#18c97d",
            fontSize: 14,
            text: "#FFFFFF",
          });
          setLoading(false);
          setIsPassword(true);
        } else {
          setLoading(false);
          notify.show(`Error: ${res.message}!!`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        return true;
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };
  const handlleCambiarPIN = () => {
    setLoading(true);
    var data = {
      idUser: UserID.id,
      pin: pin,
    };
    EjecutarApi(data, "updPin", token.access_token)
      .then(function (res) {
        if (res.success) {
          notify.show(`El registro se agrego con éxito!!`, "custom", 5000, {
            background: "#18c97d",
            fontSize: 14,
            text: "#FFFFFF",
          });
          setUserID({ ...UserID, PinCode: pin })
          setPin("");
          setLoading(false);
        } else {
          setLoading(false);
          notify.show(`Error: ${res.message}!!`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        return true;
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };
  const handlleDesvincularCel = (device) => {
    setLoading(true);
    var data = { email: UserID.Celular, "device": device };
    EjecutarApi(data, "restoreDeviceAndToken", token.access_token)
      .then(function (res) {
        if (res.results) {
          notify.show(`El registro se actualizó con éxito!!`, "custom", 5000, {
            background: "#18c97d",
            fontSize: 14,
            text: "#FFFFFF",
          });
          if (device === "1") { setUserID({ ...UserID, DeviceID: null }) }
          else { setUserID({ ...UserID, DeviceID2: null }) }

          setLoading(false);
          setDesvincularCollapsed(false);
        } else {

          setLoading(false);
          notify.show(`Error: ${res.message}!!`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        return true;
      })
      .catch((e) => {

        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };
  const handleFiltro = (filter) => {
    setLoading(true)
    var _filtro = [];
    if (filter.Nombre.length > 0) {
      _filtro.push({ id: "concat_ws(' ',u.Nombre,u.Paterno,u.Materno)", value: filter.Nombre })
    }

    if (filter.Celular.length > 0) {
      _filtro.push({ id: "u.email", value: filter.Celular })
    }
    setFiltro(_filtro);
    //Ejecutando Filtro
    let _menuStorage = getDataMenu(15, token.menus);
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      tipo: 'and',
      filtered: _filtro
    };
    EjecutarApi(data, "getListadoUsuarios", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false);
        setTotalCompromisos(res.total);
        setCompromisos(
          res.data.map((prop, key) => {
            return {
              id: prop.id,
              NombreCompleto:
                _menuStorage.Editar === 1 ?
                  (<><Button
                    className="btn-link"
                    color="primary"
                    onClick={() => { handleSetEditing(prop); }}
                  >{prop.Nombre}</Button></>)
                  : prop.Nombre,

              Celular: prop.Celular ? prop.Celular : 'Sin Celular',
              data: prop,
            };
          })
        );

      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener Usuarios: ${e.error}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
  }
  const mensajePassword = (
    <>
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="¿Está seguro que desea cambiar la contraseña?"
        onConfirm={() => {
          setIsPassword(false);
          setIsPassword2(false)
        }}
        onCancel={() => { setIsPassword(true); setIsPassword2(false) }}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Si, cambiar!"
        cancelBtnText="No"
        showCancel
      >
      </ReactBSAlert>
    </>

  );
  const udpEmail = () => {
    setLoading(true);
    var data = {
      idUser: UserID.id,
      email: Email,
    };
    EjecutarApi(data, "updEmail", token.access_token)
      .then(function (res) {
        if (res.success) {
          notify.show(res.message, "custom", 5000, {
            background: "#18c97d",
            fontSize: 14,
            text: "#FFFFFF",
          });
          setLoading(false);
          setEmail('');
          handleFiltro(Filtro);
        } else {
          setLoading(false);
          notify.show(`Error: ${res.message}!!`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        return true;
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  }
  return (
    <div className="wrapper">
      <Notifications />
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
        <div className="content">

          <div className="content">
            {/* fILTROS */}
            <Card>
              <CardHeader><h6>Administración de Usuarios</h6></CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12" lg="12" md="12">
                    <FiltroUsr Menu={_menu} Datos={(e) => handleFiltro(e)} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* inpust */}
            <Row>
              <Col md="4">
                <Card>
                  <CardBody>
                    {loading && (<RotateSpinner color={"#1261ff"} size={30} loading={loading} />)}
                    <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                      <ReactTable
                        style={{
                          width: "99%",
                          height: "100%"
                        }}
                        size='sm'
                        data={compromisos}
                        noDataText="Sin información"
                        manual
                        columns={[
                          {
                            Header: "Nombre",
                            minWidth: 110,
                            id: "NombreCompleto",
                            accessor: (d) => d.NombreCompleto,
                            filterAll: false,
                          },
                          {
                            Header: "Celular",
                            minWidth: 40,
                            id: "Celular",
                            accessor: (d) => d.Celular,
                            filterAll: false,
                          },
                        ]}
                        className="-striped -highlight primary-pagination"
                        loading={loading}
                        showPagination={true}
                        showPaginationBottom={true}
                        showTotalPages={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={10}
                        total={totalCompromisos}
                        pages={Math.floor(totalCompromisos / 10)}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        PaginationComponent={Pagination}
                        onFetchData={(stateComp, instance) => {
                          setLoading(true);
                          let _menuStorage = getDataMenu(15, token.menus);
                          const data = {
                            page: stateComp.page,
                            pageSize: stateComp.pageSize,
                            sorted: [],
                            tipo: 'and',
                            filtered: Filtro ? Filtro : []
                          };
                          EjecutarApi(data, "getListadoUsuarios", token.access_token)
                            .then(function (res) {
                              return res;
                            })
                            .then((res) => {
                              setLoading(false);
                              setTotalCompromisos(res.total);
                              setCompromisos(
                                res.data.map((prop, key) => {
                                  return {
                                    id: prop.id,
                                    NombreCompleto:
                                      _menuStorage.Editar === 1 ?
                                        (<><Button
                                          className="btn-link"
                                          color="primary"
                                          onClick={() => { handleSetEditing(prop); }}
                                        >{prop.Nombre}</Button></>)
                                        : prop.Nombre,

                                    Celular: prop.Celular ? prop.Celular : 'Sin Celular',
                                    data: prop,
                                  };
                                })
                              );

                            })
                            .catch((e) => {
                              setLoading(false);
                              notify.show(
                                `Error al obtener Usuarios: ${e.error}`,
                                "custom",
                                5000,
                                {
                                  background: "#0857B6",
                                  fontSize: 14,
                                  text: "#FFFFFF",
                                }
                              );
                            });
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="8">
                <Card>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} /> Información general
                        </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "5" })}
                        onClick={() => {
                          toggle("5");
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} /> Datos de acceso
                        </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        <FontAwesomeIcon icon={faLock} /> Privilegios web
                        </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        <FontAwesomeIcon icon={faLock} /> Privilegios móvil
                        </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          toggle("4");
                        }}
                      >
                        <FontAwesomeIcon icon={faSignInAlt} /> Accesos
                        </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "6" })}
                        onClick={() => {
                          toggle("6");
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} /> Estadística de avance
                        </NavLink>
                    </NavItem> */}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Col>
                        <UsuarioEdit data={userData} catalogos={catalogos} loaded={loaded}></UsuarioEdit>
                      </Col>
                    </TabPane>
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          {loading && (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#686769"
                              loading={loading}
                            />
                          )}
                          <Card className="card-user">
                            <CardHeader className="text-center">
                              <img
                                alt="..."
                                width="100%"
                                className="avatar border-gray"
                                src={avatar}
                              />
                            </CardHeader>
                            <CardBody>
                              <div
                                aria-multiselectable={true}
                                className="card-collapse"
                                id="accordion"
                                role="tablist"
                              >
                                <CardTitle tag="h4" className="text-center">
                                  {" "}
                                  {UserID.Nombre}{" "}
                                </CardTitle>
                                <CardTitle tag="h5" className="text-center">
                                  {" "}
                                  {UserID.Responsabilidad ? UserID.Responsabilidad.label : ''}{" "}
                                </CardTitle>
                                <CardTitle tag="h5" className="text-center">
                                  {" "}
                                  {UserID.Coordinador ? UserID.Coordinador.label : ''}{" "}
                                </CardTitle>
                                <Card className="card-plain">
                                  <CardHeader role="tab">
                                    <a
                                      aria-expanded={passwordCollapsed}
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() => collapsesToggle("password")}
                                    >
                                      <Button
                                        className="btn-round btn-icon"
                                        color="danger"
                                        outline
                                        size="sm"
                                        onClick={() => collapsesToggle("password")}
                                        id="EditUser"
                                      >
                                        <FontAwesomeIcon icon={faKey} />
                                      </Button>
                                      {"  "}Contraseña y Seguridad{" "}
                                    </a>
                                  </CardHeader>
                                  <Collapse role="tabpanel" isOpen={passwordCollapsed}>
                                    <CardBody sm="12">
                                      <FormGroup
                                        className={`has-label ${classPassword.pass}`}
                                      >
                                        <br />
                                        <Col md="12">
                                          <label>Contraseña: </label>
                                          <Button color='primary' className='primary'
                                            size="sm"
                                            onClick={() => setIsPassword2(true)}
                                          >
                                            <span className="btn-label">
                                              <i className="nc-icon nc-key-25" />
                                            </span>
                                            Cambiar...
                                          </Button>
                                          <br />
                                          <label>Password *</label>
                                          <Input
                                            id="registerPassword"
                                            name="password"
                                            type="password"
                                            autoComplete="off"
                                            onChange={(e) => setPaswoord(e.target.value)}
                                            disabled={isPassword}
                                          />
                                          {classPassword.pass === "has-danger" ? (
                                            <label className="error">
                                              La contraseña no coincide
                                            </label>
                                          ) : null}
                                        </Col>
                                      </FormGroup>
                                      <FormGroup
                                        className={`has-label ${classPassword.passConfirm}`}
                                      >
                                        <Col md="12">
                                          <label>Confirmar Password *</label>
                                          <Input
                                            //equalto="#registerPassword"
                                            id="registerPasswordConfirmation"
                                            name="password_confirmation"
                                            type="password"
                                            autoComplete="off"
                                            onChange={(e) => {
                                              verificaPassword(e.target.value);
                                            }}
                                            disabled={isPassword}
                                          />
                                          {classPassword.passConfirm === "has-danger" ? (
                                            <label className="error">
                                              La contraseña no coincide.
                                            </label>
                                          ) : null}
                                        </Col>
                                      </FormGroup>

                                      <FormGroup>
                                        <Button
                                          size="sm"
                                          color="success"
                                          disabled={isPassword}
                                          onClick={() => handlleCambiarContrasenia()}
                                        >
                                          Guardar
                                        </Button>
                                      </FormGroup>
                                    </CardBody>
                                  </Collapse>
                                </Card>
                                <Card className="card-plain">
                                  <CardHeader role="tab">
                                    <a
                                      aria-expanded={pinCollapsed}
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() => collapsesToggle("pin")}
                                    >
                                      <Button
                                        className="btn-round btn-icon"
                                        color="success"
                                        outline
                                        size="sm"
                                        onClick={() => collapsesToggle("pin")}
                                        id="EditPin"
                                      >
                                        <FontAwesomeIcon icon={faLock} />
                                      </Button>
                                      {"  "}Seguridad PIN{" "}
                                    </a>
                                  </CardHeader>
                                  <Collapse role="tabpanel" isOpen={pinCollapsed}>
                                    <CardBody sm="3">
                                      <FormGroup>
                                        <label>PIN Actual:</label>
                                        <Input
                                          id="pin"
                                          name="pin"
                                          value={UserID.PinCode}
                                          maxLength="5"
                                          disabled
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <label>PIN *</label>
                                        <Input
                                          id="pin2"
                                          name="pin2"
                                          onChange={(e) => setPin(e.target.value)}
                                          value={pin}
                                          maxLength="5"
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Button
                                          size="sm"
                                          color="success"
                                          onClick={() => handlleCambiarPIN()}
                                        >
                                          Guardar
                                    </Button>
                                      </FormGroup>
                                    </CardBody>
                                  </Collapse>
                                </Card>
                                <Card className="card-plain">
                                  <CardHeader role="tab">
                                    <a
                                      aria-expanded={desvincularCollapsed}
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() => collapsesToggle("desvincular")}
                                    >
                                      <Button
                                        className="btn-round btn-icon"
                                        color="info"
                                        outline
                                        size="sm"
                                        onClick={() => collapsesToggle("desvincular")}
                                        id="EditUser"
                                      >
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                      </Button>
                                      {"  "}
                                      Desvincular Celular{" "}
                                    </a>
                                  </CardHeader>
                                  <Collapse role="tabpanel" isOpen={desvincularCollapsed}>
                                    <CardBody sm="3">
                                      <FormGroup>
                                        <Label id='txt1' className='text-muted'>Dispositivo 1 Vinculado </Label>
                                        <br />
                                        <div className="togglebutton switch-sidebar-mini">
                                          <Switch id='Dip1'
                                            onChange={() => handlleDesvincularCel("1")}
                                            value={UserID.DeviceID ? true : false}
                                            onColor="info"
                                            offColor="info"
                                          />
                                        </div>
                                        <br />
                                        <Label id='txt2' className='text-muted'>Dispositivo 2 Vinculado </Label>
                                        <br />
                                        <div className="togglebutton switch-sidebar-mini">
                                          <Switch id='Dip2'
                                            onChange={() => handlleDesvincularCel("2")}
                                            value={UserID.DeviceID2 ? true : false}
                                            onColor="info"
                                            offColor="info"
                                          />
                                        </div>
                                      </FormGroup>
                                    </CardBody>
                                  </Collapse>
                                </Card>
                                <Card className="card-plain">
                                  <CardHeader role="tab">
                                    <a
                                      aria-expanded={emailCollapsed}
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() => collapsesToggle("email")}
                                    >
                                      <Button
                                        className="btn-round btn-icon"
                                        color="info"
                                        outline
                                        size="sm"
                                        onClick={() => collapsesToggle("email")}
                                        id="EditUser"
                                      >
                                        <FontAwesomeIcon icon={faMobile} />
                                      </Button>
                                      {"  "}
                                      Cambio Celular{" "}
                                    </a>
                                  </CardHeader>
                                  <Collapse role="tabpanel" isOpen={emailCollapsed}>
                                    <CardBody sm="3">
                                      <FormGroup className='col-3'>
                                        <Label id='txt1' className='text-muted'>Número</Label>
                                        <br />
                                        <Input sm='3' name='numero' maxLength='10' value={Email} onChange={(e) => setEmail(e.target.value)} ></Input>
                                      </FormGroup>
                                      <FormGroup className='col-3'>

                                        <Button sm='3' name='btnumero' className='success' onClick={(e) => udpEmail()} >Modificar</Button>
                                      </FormGroup>
                                    </CardBody>
                                  </Collapse>
                                </Card>
                              </div>
                            </CardBody>
                            <CardFooter>
                              {isPassword2 ? mensajePassword : ''}
                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Col>
                        <UpdMenus
                          token={token.access_token}
                          idUser={UserID.id}
                          data={webMenus}
                          isMovil={0}
                          flagLoading={flagLoading}
                        ></UpdMenus>
                      </Col>
                    </TabPane>
                    <TabPane tabId="3">
                      <Col>
                        <UpdMenus
                          token={token.access_token}
                          idUser={UserID.id}
                          data={mobileMenus}
                          isMovil={1}
                          flagLoading={flagLoading}
                        ></UpdMenus>
                      </Col>
                    </TabPane>
                    <TabPane tabId="4">
                      <Col>
                        {idUser !== null ? (<UsuarioLog idUser={UserID.id} />) : (<Card></Card>)}
                      </Col>
                    </TabPane>
                  </TabContent>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Footer fluid />

      </div>
    </div>
  );

}
export default UsuariosList;