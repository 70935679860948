import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Button,
  Card,
  CardTitle,
  CardFooter,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Form,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Table,
} from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import useWindowDimensions from 'components/useWindowDimensions';
import ReactTable from 'react-table';
import Select from 'react-select';
import { CircleSpinner } from 'react-spinners-kit';
import { RotateSpinner } from 'react-spinners-kit';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Pagination from 'components/Pagination/pagination';
import { URL_GENERAL } from 'data/Consts';
import EjecutarApi from 'data/getPOST';
import ModalFiltro from 'components/casillasOperacion/Filtro';
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA_REPORTE, tiempoSije, INTERVAL } from 'GlobalStorage';
let _Movilizado = new Array();
let checkedArray = new Array();

const MovilizacionEstructura = (props) => {
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingGuardar, setLoadingLoadingGuardar] = useState(false);
  const { height, width } = useWindowDimensions();
  const [ShowButton, setShowButton] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [seccionesFiltered, setSeccionesFiltered] = useState([]);
  const [loadingFiltro, setLoadingFiltro] = useState(false);
  const [casillas, setCasillas] = useState([]);
  const [Estructura, setEstructura] = useState([]);
  const [responsable, setResponsable] = useState([]);
  const [Movilizados, setMovilizados] = useState([]);
  const [totalListado, setTotalListado] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [Total, setTotal] = useState({ Movilizados: 0, MetaMovilizacion: 0, Porcentaje: 0, Movilizados: 0, NoReportados: 0 });
  const [selectedMovilizado, setSelectedMovilizado] = useState(0);
  const [catalogos, setCatalogos] = useState({
    Municipios: [],
    secciones: [],
  });
  const [Datos, setDatos] = useState({
    MunicipioVota: '',
    idEstructura: '',
    UserOwned: '',
    SeccionVota: '',
    Nombre: '',
    isMovilizado: 0,
    UserMovilizo: Movilizados.UserMovilizo,
    idMovilizado: Movilizados.id,
    hora: '',
    minutos: '',
    anio: '',
    mes: '',
    dia: '',
    segundos: ''
  })
  const [ListadoMovilizados, setListadoMovilizados] = useState({
    id: null,
    UserMovilizo: '',
    idMovilizado: '',
    NMovilizados: 0,
    _Movilizado: [],
  });
  var NMovilizados = 0;

  const styleHeader = {
    textAlign: "center",
    overflow: 'visible',
    fontSize: '11px',
    color: '#363636',
    lineHeight: 'normal !important',
  };

  const header = (text) => {
    return <h6 style={styleHeader}>{text}</h6>;
  };

  useEffect(() => {
     _setmenu(getDataMenu(190, token.menus));

    var d = new Date();
    var horas = d.getHours();
    var minutos = d.getMinutes();
    var segundos = d.getSeconds();

    if (horas < 10) { horas = '0' + horas; }
    if (minutos < 10) { minutos = '0' + minutos; }
    if (segundos < 10) { segundos = '0' + segundos; }

    setDatos({
      ...Datos,
      hora: `${horas}:${minutos}`,
      minutos: d.getMinutes(),
      anio: d.getFullYear(),
      mes: d.getMonth() + 1,
      segundos: d.getSeconds(),
      dia: d.getDate(),
    });

    EjecutarApi({}, 'movilizacion/getTotalesCard', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setTotal(res.data)

          }
        }
      })
      .catch((e) => {
        notify.show(`Error en totales: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
    getCatalogos();
  }, [])

  const getCatalogos = () => {
    setLoadingFiltro(true);
    EjecutarApi(
      {}, 'getCatalogosFiltrosElectoralClonIdMunicipioReportes', token.access_token)
      .then((res) => {
        setLoadingFiltro(false);
        if (res.success && res.results) {
          setCatalogos({
            ...catalogos,
            Municipios: res.data.municipios,
            secciones: res.data.secciones,
          });
          setSeccionesFiltered(res.data.secciones);
        } else {
          notify.show(res.message, 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        }
      })
      .catch((e) => {
        setLoadingFiltro(false);
        notify.show(
          `Error al cargar catalogos: ${e.message} Favor de renovar sesión`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });

    EjecutarApi({}, 'getCatalogoRCOSocial', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        console.log("=>Res", res)
        if (res.success) {
          if (res.results) {
            setEstructura(res.data.estructuras.map(item => {
              return { label: item.label, value: item.value }
            }))
            setResponsable(res.data.responsables.map(item => {
              return { label: item.label, value: item.value }
            }))
          }
        }
      })
      .catch((e) => {
        notify.show(`Error: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  };

  const onChangeSeccion = (Seccion) => {
    EjecutarApi({ "seccion": Seccion }, 'getCasillasBySeccion', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          setCasillas(res.data);
        }
      })
      .catch((e) => {
        notify.show(`Error en: ${e.error}`, 'error', 5000);
      });
  }


  const columns = [
    {
      Header: header('Select'),
      minWidth: 8,
      id: 'isMovilizado',
      accessor: (d) => d.isMovilizado,
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Nombre completo'),
      minWidth: 8,
      id: 'Nombre',
      accessor: (d) => d.Nombre,
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Nombre de estructura'),
      minWidth: 8,
      id: 'NombreEstructura',
      accessor: (d) => d.NombreEstructura,
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Domicilio'),
      minWidth: 8,
      id: 'domicilio',
      accessor: (d) => d.domicilio,
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Localidad'),
      minWidth: 8,
      id: 'LocalidadVive',
      accessor: (d) => d.LocalidadVive,
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Municipio'),
      minWidth: 8,
      id: 'MunicipioVota',
      accessor: (d) => d.MunicipioVota,
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Sección'),
      minWidth: 8,
      id: 'SeccionVota',
      accessor: (d) => d.SeccionVota,
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Celular'),
      minWidth: 8,
      id: 'Celular',
      accessor: (d) => d.Celular,
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Responsable'),
      minWidth: 8,
      id: 'Responsable',
      accessor: (d) => d.Responsable,
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    }
  ];

  const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
    const dato = {
      page: page,
      pageSize: pageSize
    };
    getRegistros(dato);
    
  }, []);


  const getRegistros = (data) => {
    setLoading(true);
    EjecutarApi(data, 'movilizacion/getListados4Movilizador', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          if (res.results) {
            console.log(res.Total)
            setTotalListado(res.Total);
            setMovilizados(
              res.simpatias.map((prop, key) => {
            
                return {
                  isMovilizado: prop.isMovilizado ===1 ? (<CustomInput type="checkbox" name="1" disabled checked={true}/>):
                  (<CustomInput 
                    key={key + 1}
                    id={`check_${key + 1}`}
                    checked={prop.isMovilizado}
                    onChange={(e) =>
                      onChangeCheck(e,prop )
                    }
                    type="checkbox"
                  />),
                  Nombre: prop.Nombre,
                  NombreEstructura: prop.NombreEstructura,
                  domicilio: prop.domicilio,
                  LocalidadVive: prop.LocalidadVive,
                  MunicipioVota: prop.MunicipioVota,
                  SeccionVota: prop.SeccionVota,
                  Celular: prop.Celular,
                  Responsable: prop.Responsable
                };
                
              })
            );
          }
        }
      })
      .catch((e) => {
        setLoading(false)
        notify.show(`Error: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
      EjecutarApi({}, 'movilizacion/getTotalesCard', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setTotal(res.data)

          }
        }
      })
      .catch((e) => {
        notify.show(`Error en totales: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  };

  const btnSaveMovilizado = () => {
    setSelectedMovilizado(0)

    let arrayMovilizado =
      _Movilizado.map(item => {
        return {
          idMovilizado: item.idMovilizado,
          UserOwned: item.UserOwned,
          FechaHora: `${Datos.anio}-${Datos.mes}-${Datos.dia} ${Datos.hora}:${Datos.minutos}:${Datos.segundos}`,
        }
      });

    DatosMovilizado(arrayMovilizado);
  }

  const DatosMovilizado = (Datos) => {
    setLoadingLoadingGuardar(true);
    EjecutarApi({ data: Datos }, "movilizacion/setMasivoMovilizado", token.access_token)
      .then(function (res) {

        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            notify.show(`Registros actualizados con éxito!!`, "success", 5000, {
              background: "#18c97d",
              fontSize: 24,
              text: "#FFFFFF",
            });
            setMovilizados([]);
            setShowButton(true);
            const dato = {
              page: 0,
              pageSize: 10
            };
            getRegistros(dato);
          } else {
            notify.show(
              `Error al agregar registro: ${res.message}!!`,
              "custom",
              5000,
              {
                background: "#0857B6",
                fontSize: 24,
                text: "#FFFFFF",
              }
            );
            return true;
          }
        }
      })
      .catch((e) => {
        notify.show(`Error al agregar el registro: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 24,
          text: "#FFFFFF",
        });
      })
      .finally(e => {
        setLoadingLoadingGuardar(false);
      })
  };

  const onChangeMunicipio = (idMun) => {
    let secciones = catalogos.secciones.filter((item) => { return item.idMunicipio === parseInt(idMun) });
    setSeccionesFiltered(secciones);
  }

  const handleResponsable = (e) => {
    setDatos({ ...Datos, UserOwned: e });
  }
  const handleEstructura = (e) => {
    setDatos({ ...Datos, idEstructura: e });
  }

  const ClearFilter = () => {

    setDatos({
      ...Datos,
      Nombre: '',
      MunicipioVota: '',
      SeccionVota: '',
      idEstructura: '',
      UserOwned: '',
    });
    setListadoMovilizados({
      id: 0,
      NMovilizados: 0,
      _Movilizado: [],
    });

    getMovilizados('clear');
    setSelectedMovilizado(0)
    //setSeccionesFiltered(catalogos.secciones)
  };

  const getMovilizados = (e) => {

    setLoading(true);
    let Variable = {}
    if (e === 'clear') {
      Variable = {
        page:0,
        pageSize:10,
        idUser: token.user.id
      }
    } else {
      Variable = {
        // idUser: token.user.id,
        page:0,
        pageSize:10,
        Nombre: Datos.Nombre ? Datos.Nombre : '',
        MunicipioVota: Datos.MunicipioVota ? Datos.MunicipioVota.value : '',
        SeccionVota: Datos.SeccionVota ? Datos.SeccionVota.value : '',
        UserOwned: Datos.UserOwned ? Datos.UserOwned.value : '',
        idEstructura: Datos.idEstructura ? Datos.idEstructura.value : '',
      }
    }
    EjecutarApi(Variable, 'movilizacion/getListados4Movilizador', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            console.log(res.Total)
            setTotalListado(res.Total);
            setMovilizados(
              res.simpatias.map((prop, key) => {
            
                return {
                  isMovilizado: prop.isMovilizado ===1 ? (<CustomInput type="checkbox" name="1" disabled checked={true}/>):
                  (<CustomInput 
                    key={key + 1}
                    id={`check_${key + 1}`}
                    checked={prop.isMovilizado}
                    onChange={(e) =>
                      onChangeCheck(e,prop )
                    }
                    type="checkbox"
                  />),
                  Nombre: prop.Nombre,
                  NombreEstructura: prop.NombreEstructura,
                  domicilio: prop.domicilio,
                  LocalidadVive: prop.LocalidadVive,
                  MunicipioVota: prop.MunicipioVota,
                  SeccionVota: prop.SeccionVota,
                  Celular: prop.Celular,
                  Responsable: prop.Responsable
                };
                
              })
            );
          }
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        notify.show(`Error: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });

    EjecutarApi({}, 'movilizacion/getTotalesCard', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setTotal(res.data)

          }
        }
      })
      .catch((e) => {
        notify.show(`Error en totales: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  };

  const onChangeCheck = (e,prop) => {
    setShowButton(false);
    if (e.target.checked) {
      console.log(prop)
      prop.isMovilizado = true;
      _Movilizado.push(prop);
      console.log(_Movilizado)
      let nmovilizados = ListadoMovilizados.NMovilizados + _Movilizado.length;
      setSelectedMovilizado(nmovilizados);
      NMovilizados = ListadoMovilizados.nmovilizados + _Movilizado.length;

    } else {
      prop.isMovilizado = false;
      let copy = _Movilizado.filter((x) => {
        return (x.idMovilizado !== prop.idMovilizado)
      });
      let nmovilizados = ListadoMovilizados.NMovilizados + copy.length;
      setSelectedMovilizado(nmovilizados);
      NMovilizados = ListadoMovilizados.nmovilizados + copy.length;
      if (nmovilizados < 1) {
        setIsDisabled(true);
        notify.show(
          'No se ha seleccionado a ningun Movilizado',
          'error',
          5000
        );
      } else {
        setIsDisabled(false);
      }
      _Movilizado = copy;
    }
  };


  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          {_menu.Ver === 1 ? (
            <>
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-line-chart text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Movilizacion de Estructuras</p>
                        <CardTitle tag="p"><strong>{new Intl.NumberFormat('en-EN').format(Total.Movilizados)}
                        </strong> de <strong>{new Intl.NumberFormat('en-EN').format(Total.MetaMovilizacion)} </strong>
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" xs="5" className='text-muted text-center' >
                      Avance
                    </Col>
                    <Col md="4" xs="5" className='text-muted text-center' >
                      Reportados
                    </Col>
                    <Col md="4" xs="5" className='text-muted text-center'>
                      No reportados
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" xs="5" className='text-center' >
                      <h6>{`${Total.Porcentaje}`}%</h6>
                    </Col>
                    <Col md="4" xs="5" className='text-center' >
                      <h6>{new Intl.NumberFormat('en-EN').format(Total.Movilizados)}</h6>
                    </Col>
                    <Col md="4" xs="5" className='text-center' >
                      <h6>{new Intl.NumberFormat('en-EN').format(Total.NoReportados)}</h6>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-clock-o" />
                      Actual
                    </div>
                </CardFooter>
              </Card>
            </Col>
            
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="3" md="3" sm="12">
                      <Card style={{ height: height - 600 }}>
                        <CardBody>
                          <center><strong className="title text-center" style={{ color: "#0041A0" }}>Busqueda de Estructura</strong></center>
                          <hr />
                          <FormGroup >
                            <Label for="Nombre">Nombre</Label>
                            <Input id="Nombre" value={Datos.Nombre}
                              onChange={(e) => {
                                setDatos({ ...Datos, Nombre: e.target.value })
                              }}
                            ></Input>
                            <div className="errorFields">
                            </div>
                          </FormGroup>
                          <FormGroup >
                            <Label className="labelForm">Responsable</Label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              id="idCapacitacion"
                              placeholder="Selecciona..."
                              value={Datos.UserOwned}
                              options={responsable}
                              onChange={(e) => handleResponsable(e)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="labelForm">Nombre Estructura</Label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              id="idCapacitacion"
                              placeholder="Selecciona.."
                              value={Datos.idEstructura}
                              options={Estructura}
                              onChange={(e) => handleEstructura(e)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="labelForm">Municipio</Label>
                            <Select
                              options={catalogos.Municipios}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={Datos.MunicipioVota}
                              onChange={(e) => {
                                setDatos({ ...Datos, MunicipioVota: e, SeccionVota: [] });
                                onChangeMunicipio(e.value)
                              }
                              }
                              id="viewTypeM"
                              placeholder="Filtrar por"
                              isLoading={loadingFiltro}
                              loadingMessage="Cargando filtro"
                            />
                            <div className="errorFields">
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="labelForm">Seccion</Label>
                            <Select
                              options={seccionesFiltered}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={Datos.SeccionVota}
                              onChange={(e) => {
                                setDatos({ ...Datos, SeccionVota: e });
                                onChangeSeccion(e.value);
                              }}
                              id="viewTypeM"
                              placeholder="Filtrar por"
                              isLoading={loadingFiltro}
                              loadingMessage="Cargando filtro"
                            />
                            <div className="errorFields">
                            </div>
                          </FormGroup>
                          <FormGroup>

                            <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e) => getMovilizados(e)}>
                              <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                Filtrar
                            </Button>{' '}
                            <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e) => ClearFilter()}>
                              <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                                    Limpiar
                            </Button>
                          </FormGroup>

                          {/*  </form> */}

                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="9" md="9" sm="12">
                      <Card style={{ height: height - 300 }}>
                        <CardBody height={500}>
                          <Row>
                            <Col sm="4" md="4" lg="4">
                              <Label className="text-muted"> <span className="text-danger" style={{ width: "100pt", marginLeft: '80px'}}>*</span>Hora (Formato: 24 hrs, ejemplo: 13:30)</Label>
                              <Input id='btn' className='text-center' value={Datos.hora} style={{ width: "100pt", marginLeft: '130px'}}
                                onChange={(e) => setDatos({ ...Datos, hora: e.target.value })}
                                type="time" />
                            </Col>
                          </Row>
                            <Row>
                            {_menu.Agregar === 1 && (
                            <Col sm="4" md="4" lg="4" className='text-center text-center'>
                              <>
                                <FormGroup className="text-center">
                                  <Button
                                    style={{ marginTop: '30px' }}
                                    color="danger"
                                    onClick={() => ClearFilter()}
                                    size="sm"
                                  >
                                    Cancelar
                                    </Button>{' '}
                                  <Button
                                    style={{ marginTop: '30px' }}
                                    size="sm"
                                    color="primary"
                                    disabled={ShowButton}
                                    onClick={(e) => btnSaveMovilizado()}
                                  //disabled={isDisabled}
                                  >
                                    Reportar
                                    </Button>
                                  {
                                    <Col>
                                      <RotateSpinner
                                        color={'#1261ff'}
                                        size={30}
                                        loading={loadingGuardar}
                                      />
                                    </Col>
                                  }
                                </FormGroup>
                              </>
                            </Col>
                            )}
                          </Row>
                          <Row>
                            <Col sm="12">
                                <CardBody>
                                  
                                <Col lg="12" md="12">
                                  <div style={{ width: '100%', overflow: 'auto', margin: '0px 1px', zIndex: 0, textAlign: "center" }} >
                                    <ReactTable
                                      data={Movilizados}
                                      className="-striped -highlight"
                                      manual
                                      columns={columns}
                                      className="-striped -highlight primary-pagination"
                                      loading={loading}
                                      showPagination={true}
                                      showPaginationBottom={true}
                                      showTotalPages={true}
                                      showPageJump={false}
                                      canNextFromData={true}
                                      defaultPageSize={10}
                                      total={totalListado}
                                      pages={Math.floor(totalListado / 10)}
                                      previousText="Anterior"
                                      nextText="Siguiente"
                                      ofText="de"
                                      pageSizeOptions={[10, 20, 25, 50, 100]}
                                      PaginationComponent={Pagination}
                                      onFetchData={fetchData}
                                      //params={Filtro}
                                    />
                                  </div>
                                </Col>
                                </CardBody>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            </>
          ) : (
              <>
                <Label>No tiene permisos para ver</Label>
              </>
            )} 
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};
export default MovilizacionEstructura;
