import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Card, Row, Col, Label, CardHeader } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import { CircleSpinner } from 'react-spinners-kit';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Pagination from 'components/Pagination/pagination';
import { URL_GENERAL } from 'data/Consts';
import EjecutarApi from 'data/getPOST';
import ModalFiltro from 'components/casillasOperacion/Filtro';
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA_REPORTE, tiempoSije, INTERVAL } from 'GlobalStorage';
import CardBody from 'reactstrap/lib/CardBody';

const CasillasOperacionMuestra = (props) => {
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  const [loading, setLoading] = useState(false);
  const [Filtro, setFiltro] = useState([]);
  const [sorted, setSortered] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [filteredTotales, setFilteredTotales] = useState([])

  const styleHeader = {
    textAlign: "center",
    overflow: 'visible',
    fontSize: '11px',
    color: '#363636',
    lineHeight: 'normal !important',
  };

  useEffect(() => {
    _setmenu(getDataMenu(164, token.menus))
  }, [])

  const header = (text) => {
    return <h6 style={styleHeader}>{text}</h6>;
  };

  const columns = [
    {
      Header: header('DF'),
      minWidth: 35,
      id: 'cat_df.DistritoFederal',
      accessor: 'DFED',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('DL'),
      minWidth: 35,
      id: 'cat_dl.DistritoLocal',
      accessor: 'DLOC',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },

    {
      Header: header('Municipios'),
      minWidth: 150,
      id: 'cat_municipios_reportes.Municipio',
      accessor: 'Municipio',
      filterAll: false,
      style: { fontSize: '11px', textAlign: 'left' },
         headerStyle: { fontSize: '2px'  }
    },
    {
      Header: header('Sección'),
      minWidth: 60,
      id: 'secciones.Seccion',
      accessor: 'Seccion',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Casilla'),
      minWidth: 150,
      id: 'casillas_sije.NombreCasilla',
      accessor: 'NombreCasilla',
      filterAll: false,
      style: { fontSize: '11px', textAlign: 'left' },
         headerStyle: { fontSize: '2px'  }
    },
    {
      Header: header('Asist.RG'),
      minWidth: 60,
      id: 'PaseListaRG',
      accessor: 'PaseListaRG',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('RCP1'),
      minWidth: 60,
      id: 'PaseListaP1',
      accessor: 'PaseListaP1',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('RCP2'),
      minWidth: 60,
      id: 'PaseListaP2',
      accessor: 'PaseListaP2',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('RCS1'),
      minWidth: 60,
      id: 'PaseListaS1',
      accessor: 'PaseListaS1',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('RCS2'),
      minWidth: 60,
      id: 'PaseListaS2',
      accessor: 'PaseListaS2',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Instalación'),

      minWidth: 60,
      id: 'InicioInstalacion',
      accessor: 'InicioInstalacion',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Apertura'),
      minWidth: 60,
      id: 'AperturaCasilla',
      accessor: 'AperturaCasilla',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },

    {
      Header: header('CierreCasilla'),
      minWidth: 60,
      id: 'CierreCasilla',
      accessor: 'CierreCasilla',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('CierreVotación'),
      id: 'CierreVotación',
      accessor: 'CierreVotacion',
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Incidencia'),
      id: 'Incidencia',
      accessor: 'Incidencia',
      filterAll: false,
      //headerStyle: { fontSize: '9px' }
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Muestra Municipal'),
      id: 'MuestraMpal',
      accessor: 'MuestraMpal',
      Cell: props => <> {(props.value === 1) ? (<i className="nc-icon nc-check-2 text-primary" />) : 
                        ((<i className="nc-icon nc-simple-remove text-danger" />))}</>,                                                                         
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    },
    {
      Header: header('Muestra DL'),
      id: 'Muestra',
      accessor: 'Muestra',
      Cell: props => <> {(props.value === 1) ? (<i className="nc-icon nc-check-2 text-primary" />) : 
                        ((<i className="nc-icon nc-simple-remove text-danger" />))}</>, 
      filterAll: false,
      style: { fontSize: '11px' },
        headerStyle: { fontSize: '2px' }
    }
  ];

  const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
    const dato = {
      page: page,
      pageSize: pageSize,
      filtered: params,
      sorted: sorted,
      tipo: 'and',
      isMuestra:true,
    };
    setPageSize(pageSize);
    setPage(pageSize);
    setSortered(sorted);
    getRegistros(dato);
  }, []);

  const getRegistros = (data) => {
    setLoading(true);

    EjecutarApi(data, 'getCasillasOperacion', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotal(res.total);
          setData(
            res.data.map((prop, key) => {
              return prop;
            })
          );
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleFiltro = (filter) => {
    var _filtro = [];
    var _filtroTotales = [];
    if (filter.DF.value > 0) {
      _filtro.push({ id: 'casillas_sije.idDF', value: filter.DF.value });
      _filtroTotales.push({id: 'idDF', value: filter.DF.value })
    }
    if (filter.DL.value > 0) {
      _filtro.push({ id: 'casillas_sije.idDL', value: filter.DL.value });
      _filtroTotales.push({ id: 'idDL', value: filter.DL.value });
    }
    if (filter.Region.value > 0) {
      _filtroTotales.push({ id: 'idRegion', value: filter.Region.value });
    }
    if (filter.Municipio.value > 0) {
      _filtro.push({
        id: 'casillas_sije.idMunicipioReportes',
        value: filter.Municipio.value,
      });
      _filtroTotales.push({
        id: 'idMunicipioReportes',
        value: filter.Municipio.value,
      });
    }
    if (filter.Poligono.value > 0) {
      _filtro.push({
        id: 'casillas_sije.idPoligono',
        value: filter.Poligono.value,
      });
      _filtroTotales.push({
        id: 'idPoligono',
        value: filter.Poligono.value,
      });
    }
    if (filter.Seccion.value > 0) {
      _filtro.push({ id: 'casillas_sije.Seccion', value: filter.Seccion.value });
      _filtroTotales.push({ id: 'Seccion', value: filter.Seccion.value });
    }
    if (filter.Casilla.value > 0) {
      _filtro.push({
        id: 'casillas_sije.NombreCasilla',
        value: filter.Casilla.label,
      });
    }

    if (filter.AsistenciaRC.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseLista',
        value: filter.AsistenciaRC.value,
      });
    }

    if (filter.AsistenciaRG.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaRG',
        value: filter.AsistenciaRG.value,
      });
    }

    if (filter.Instalacion.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isInicioInstalacion',
        value: filter.Instalacion.value,
      });
    }

    if (filter.Apertura.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isAperturaCasilla',
        value: filter.Apertura.value,
      });
    }

    if (filter.CierreCasilla.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isCierreCasilla',
        value: filter.CierreCasilla.value,
      });
    }

    if (filter.CierreVotacion.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isCierreVotacion',
        value: filter.CierreVotacion.value,
      });
    }
    if (filter.isPaseListaP1.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaP1',
        value: filter.isPaseListaP1.value,
      });
    }

    if (filter.isPaseListaP2.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaP2',
        value: filter.isPaseListaP2.value,
      });
    }

    if (filter.isPaseListaS1.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaS1',
        value: filter.isPaseListaS1.value,
      });
    }

    if (filter.isPaseListaS2.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaS2',
        value: filter.isPaseListaS2.value,
      });
    }

    setFiltro(_filtro);
    setFilteredTotales(_filtroTotales)

    const data = {
      page: 0,
      pageSize: 10,
      filtered: _filtro,
      tipo: 'and',
    };

    getRegistros(data);
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          <Row>
            <Col sm="12" md="12" lg="12">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <ModalFiltro Datos={(e) => handleFiltro(e)} isMuestra={true} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Card>
                    <CardHeader className="text-center text-sm-center">
                      <h6 className="text-center">Operación Casilla Capturistas</h6>
                    </CardHeader>
                    <CardBody>
                      {loading && (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loading}
                        />
                      )}
                      <Col lg="12" md="12">
                        <div style={{ width: '100%', overflow: 'auto', margin: '0px 1px', zIndex: 0, textAlign: "center" }} >
                          <ReactTable
                            data={data}
                            className="-striped -highlight"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={loading}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={fetchData}
                            params={Filtro}
                          />
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};
export default CasillasOperacionMuestra;
