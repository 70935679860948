import React, { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { MetroSpinner } from 'react-spinners-kit';

const SendNotifications = () => {
  const [Para, setPara] = useState('');
  const [Mensaje, setMensaje] = useState('');
  const [loading, setLoading] = useState(false);


  const btnSend = () => {
    setLoading(true);
    if (Para.length < 10) {
      notify.show('Error: Token requerido.', 'custom', 5000, {
        background: '#0857B6',
        fontSize: 14,
        text: '#FFFFFF'
      });
      setLoading(false);
      return true;
    }

    const data = {
      "to": Para,
      "title": "sianGto Notificacion",
      "body": Mensaje
    };

    const requestInfo = {
      method: 'POST',
      mode: 'no-cors',
      body: JSON.stringify(data),
      headers: {
        "Accept": "application/json",
        "Accept-Encoding": "gzip, deflate",
        "Content-Type": "application / json",
        "cache-control": "no-cache",
        "host": "exp.host"
      },
    };


    fetch('https://exp.host/--/api/v2/push/send', requestInfo)

      .then((response) => {
        return response.text()
      })
      .then((data) => {
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error Message! ${e.message}`, 'custom', 5000, {
          background: '#0E1717',
          text: '#FFFFFF'
        });
      });
  };

  return (
    <div className="wrapper wrapper-full-page">
      <div className="full-page section-image">
        <Notifications />
        <div className="login-page">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form className="form" onSubmit={btnSend}>
                  <Card className="card-login">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">Enviar Mensaje</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Token"
                          id="Token"
                          type="text"
                          onChange={(e) => setPara(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              btnSend();
                            }
                          }}
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Mensaje"
                          id="Mensaje"
                          type="text"
                          onChange={(e) => setMensaje(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              btnSend();
                            }
                          }}
                        />
                      </InputGroup>

                      <br />

                    </CardBody>
                    <CardFooter>
                      {!loading && (
                        <Button
                          block
                          className="btn-round mb-3"
                          color="warning"
                          onClick={btnSend}
                        >
                          enviar
												</Button>
                      )}

                      {loading && <MetroSpinner size={30} color="#686769" loading={loading} />}
                      <Label>Ver 1.4</Label>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SendNotifications;
