import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col, Collapse, Input } from 'reactstrap';
import { CircleSpinner } from "react-spinners-kit";
import Select from "react-select";

function FiltroCandidato({ Menu, Datos }) {
    const [loading, setLoading] = useState(false);
    const [collapse, setCollapse] = useState({
        horizontalTabs: "home",
        verticalTabs: "info",
        pageTabs: "homePages",
        openedCollapses: ["collapse1"]
    });

    const collapsesToggle = (colapse) => {
        let openedCollapses = collapse.openedCollapses;
        if (openedCollapses.includes(colapse)) {
            setCollapse({ ...collapse, openedCollapses: openedCollapses.filter(item => item !== colapse) });
        } else {
            openedCollapses.push(colapse);
            setCollapse({ ...collapse, openedCollapses: openedCollapses });
        }
    };

    const setFilter = () => {

    };

    const clearFilter = () => {
        setLoading(true);

        setLoading(false);
    }

    return (
        <Card className="card-plain">
            <CardBody>
                <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                >
                    <Card className="card-plain">
                        <CardHeader role="tab">
                            <a
                                aria-expanded={collapse.openedCollapses.includes("collapse1")}
                                data-parent="#accordion"
                                data-toggle="collapse"
                                onClick={() => collapsesToggle("collapse1")}
                                className="text-right"
                            >
                                <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                Filtro
                            </a>
                        </CardHeader>
                        <Collapse
                            role="tabpanel"
                            isOpen={collapse.openedCollapses.includes("collapse1")}
                        >
                            <CardBody>
                                {Menu.Ver && (
                                    <>
                                        <Row>
                                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}
                                            <Col md="4" className="text-muted">
                                                Tipo de elección
                                                <Select
                                                    // options={catRegionesFilter}
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    // value={dataCombos.dRegion}
                                                    // onChange={(e) => changeRegion(e)}
                                                    id="TipoEleccion"
                                                    placeholder="Seleccione"
                                                />
                                            </Col>
                                            <Col md="3" className="text-muted">
                                                Celular
                                                <Input
                                                    placeholder="Ingrese un teléfono para buscar"
                                                    name="input_search"
                                                // onKeyUp={e => search(e.target.value)}
                                                // onKeyDown={onKeyDown}
                                                />
                                            </Col>
                                            <Col md="5" className="text-muted">
                                                Nombre
                                                <Input
                                                    placeholder="Ingrese un nombre para buscar"
                                                    name="input_search"
                                                // onKeyUp={e => search(e.target.value)}
                                                // onKeyDown={onKeyDown}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" className="text-muted">
                                                Ámbito
                                                <Select
                                                    // options={catRegionesFilter}
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    // value={dataCombos.dRegion}
                                                    // onChange={(e) => changeRegion(e)}
                                                    id="TipoEleccion"
                                                    placeholder="Seleccione"
                                                />
                                            </Col>
                                        </Row>
                                    </>)
                                }
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e) => setFilter()}>
                                            <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                            Filtrar
                                        </Button>{" "}
                                        <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e) => clearFilter()}>
                                            <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                                            Limpiar
                                        </Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Collapse>
                    </Card>
                </div>
            </CardBody>
        </Card>
    );
};
export default FiltroCandidato;