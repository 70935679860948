import React, { useEffect, useState } from 'react';

import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';

//Zincri imports
import ChoropethMap from 'components/Maps/Electoral/ChoropethMapV5Rutas.jsx';
import { getVars, setVars } from "GlobalStorage";
import ReactBSAlert from 'react-bootstrap-sweetalert';
import EjecutarApi from 'data/getPOST';
import Notifications, { notify } from 'react-notify-toast';
import { URL_GENERAL } from "data/Consts";
import { getDataMenu } from 'data/menus.js';
let COORDINADOR_GENERAL = 99;
let SUPERVISOR_ESTATAL_ELECTORAL = 98;
let COORDINADOR_ESTATAL_TERRTORIAL = 97;
let COORDINADOR_ESTATAL_ELECTORAL = 16;
let COORDINADOR_REGIONAL_ELECTORAL = 17;
let COORDINADOR_MUNICIPAL_ELECTORAL = 18;

const Mapas = (props) => {
  const token = getVars('Token');
  const [menu, _setmenu] = useState({});
  const [dataGeoJson, setDataGeoJson] = useState({});
  const [flagFinish, setFlagFinish] = useState(false);
  const [filteredSaved, setFilteredSaved] = useState([]);
  const [municipioSaved, setMunicipioSaved] = useState({});
  const [SeccionesSelected, setSeccionesSelected] = useState([]);
  const [RGsRutaParameter, setRGsRutaParameter] = useState([]);


  const [_position, setPosition] = useState([20.816157, -101.021312]);
  /* const [tipoMapa, setTipoMapa] = useState(
    getVars('Token').user.Responsabilidad.value === COORDINADOR_GENERAL ||
      getVars('Token').user.Responsabilidad.value ===
      SUPERVISOR_ESTATAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_REGIONAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_ESTATAL_ELECTORAL
      ? { value: 'region', label: 'Regional' }
      : getVars('Token').user.Responsabilidad.value ===
        COORDINADOR_MUNICIPAL_ELECTORAL
        ? { value: 'municipio', label: 'Muncipal' }
        : { value: 'seccion', label: 'Seccion' }
  ); */
  const [tipoMapa, setTipoMapa] = useState(
    getVars('Token').user.Responsabilidad.value === COORDINADOR_GENERAL ||
      getVars('Token').user.Responsabilidad.value ===
      SUPERVISOR_ESTATAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_REGIONAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_ESTATAL_ELECTORAL
      ? { value: 'region', label: 'Regional' }
      : getVars('Token').user.Responsabilidad.value ===
        COORDINADOR_MUNICIPAL_ELECTORAL
        ? { value: 'municipio', label: 'Muncipal' }
        :
        (getVars('Token').user.Capturista.Responsabilidad ===
        COORDINADOR_REGIONAL_ELECTORAL
        || getVars('Token').user.Capturista.Responsabilidad ===
        COORDINADOR_ESTATAL_ELECTORAL) &&
        getVars('Token').user.Capturista.value === 1?
        { value: 'region', label: 'Regional' }
        :
        getVars('Token').user.Capturista.Responsabilidad ===
        COORDINADOR_MUNICIPAL_ELECTORAL
         &&
        getVars('Token').user.Capturista.value === 1?
        { value: 'municipio', label: 'Muncipal' }
        : { value: 'seccion', label: 'Seccion' }
  );
  const [zoom, setZoom] = useState(
    getVars('Token').user.Responsabilidad.value === COORDINADOR_GENERAL ||
      getVars('Token').user.Responsabilidad.value ===
      SUPERVISOR_ESTATAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_REGIONAL_ELECTORAL ||
      getVars('Token').user.Responsabilidad.value ===
      COORDINADOR_ESTATAL_ELECTORAL
      ? 9
      : getVars('Token').user.Responsabilidad.value ===
        COORDINADOR_MUNICIPAL_ELECTORAL
        ? 11
        : 11
  );
  const [derechoMapa, setDerechoMapa] = useState([]);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState('');

  const [rangos, setRangos] = useState([]);
  const hostserver = URL_GENERAL;
  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  useEffect(() => {
    GetRGs();
    setVars('TAB', 'LR');
    setVars('SeccionesSelected', []);
    _setmenu(getDataMenu(78, token.menus));

    setVars('FlagEdit', false);
    setVars('rutaModal', {
      id: '',
      NombreRuta: '',
      ClaveRuta: '',
      Comentarios: ''
    });
    // const token = getVars('Token').access_token;
    //AQUI VA IR LA RESPONSABILIDAD PARA MARCAR EL MAPA POR DEFECTO
    //depende su responsabilidad setearemos el estado para mandarselo al hook del mapa
    selectDerecho();

    async function getRangos() {
      let config = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      try {
        let res = await fetch(hostserver + 'getRangosCompromisosGto', config);
        let data = await res.json();
        setRangos(data);
      } catch (error) {
        setMensajeAlert(
          'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
        );
        setFlagAlert(true);
        setDataGeoJson({
          type: 'FeatureCollection',
          features: [],
        });
      }
    }
    getRangos();
  }, []);

  const GetRGs = () => {
    let data = {
      "filtered": [],
      "page": 0,
      "pageSize": 10000,
      "sort": [],
      "tipo": "and",
      "all_in": true
    };
    EjecutarApi(data, 'getRGRutasGto', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {

            setRGsRutaParameter(
              res.data.map(item => {
                return {
                  value: item.id,
                  label: (item.Nombre + " " + item.Paterno + " " + item.Materno).toUpperCase()
                }
              })
            );
            //setLoadingTabla(false);
          }
        }
      })
      .catch((e) => {
        //setLoadingTabla(false);
        notify.show(
          `Error al obtener RGs: ${e.message}`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  }
  const basicAlert = (
    <ReactBSAlert
      style={{
        display: 'block',
        marginTop: '-100px',
        padding: '2px 4px',
        font: '10px/12px Arial, Helvetica, sans-serif',
      }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );

  function selectDerecho() {
    let derecho_mapa = getVars('Token').user.Responsabilidad.value;

    setDerechoMapa(
      derecho_mapa === COORDINADOR_GENERAL ||
        derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
        derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL ||
        derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL
        ? [1, 3, 4]
        : derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL
          ? [1, 3, 4]
          : derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL
            ? [1, 3]
            : derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL
              ? [1, 3, 4]
              : []
    );
    
    let flag_entro = false;
    if (
      derecho_mapa === COORDINADOR_GENERAL ||
      derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
      derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL ||
      derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL
    ) {
      //getDistritos("","GET");
      flag_entro = true;
      getRegiones('', 'GET');
    }
    if (derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL) {
      //getMunicipios("","GET");
      flag_entro = true;
      getRegiones('', 'GET');
    }
    if (derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL) {
      flag_entro = true;
      getMunicipios('', 'GET');
      //getSecciones("","GET");
    }
    if(!flag_entro){
      let derecho_mapa_capturista = getVars('Token').user.Capturista.value;
      if(derecho_mapa_capturista === 1){
        derecho_mapa = getVars('Token').user.Capturista.Responsabilidad;
        setDerechoMapa(
          derecho_mapa === COORDINADOR_GENERAL ||
            derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
            derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL ||
            derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL
            ? [1, 3, 4]
            : derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL
              ? [1, 3, 4]
              : derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL
                ? [1, 3]
                : derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL
                  ? [1, 3, 4]
                  : []
        );
        if (
          derecho_mapa === COORDINADOR_GENERAL ||
          derecho_mapa === SUPERVISOR_ESTATAL_ELECTORAL ||
          derecho_mapa === COORDINADOR_ESTATAL_ELECTORAL ||
          derecho_mapa === COORDINADOR_ESTATAL_TERRTORIAL
        ) {
          getRegiones('', 'GET');
        }
        else if (derecho_mapa === COORDINADOR_REGIONAL_ELECTORAL) {
          getRegiones('', 'GET');
        }
        else if (derecho_mapa === COORDINADOR_MUNICIPAL_ELECTORAL) {
          getMunicipios('', 'GET');
        }
        else{
          setMensajeAlert(
            'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
          );
          setFlagAlert(true);
          setDataGeoJson({
            type: 'FeatureCollection',
            features: [],
          });
        }
      }
      
    }
  }

  const handleFiltro = (filtered, tipo_mapa) => {
    setMunicipioSaved({});
    setFilteredSaved(filtered);
    setFlagFinish(false);
    //setZoom(9);
    setPosition([20.816157, -101.021312]);

    var idMunicipio = filtered.filter((dato) => dato.id === 'idMunicipio');
    var idRegion = filtered.filter((dato) => dato.id === 'idRegion');
    var idSeccion = filtered.filter((dato) => dato.id === 'idSeccion');
    var TieneActas = filtered.filter((dato) => dato.id === 'TieneActas');

    idMunicipio = idMunicipio[0].value;
    idRegion = idRegion[0].value;
    idSeccion = idSeccion[0].value;
    TieneActas = TieneActas[0].value;

    var filtered_plugin = [];
    var puntos = false;
    if (TieneActas !== 9999) {
      puntos = true;
      if (TieneActas !== 3)
        filtered_plugin.push({
          id: 'cat_casillasgto.TieneActas',
          value: TieneActas,
        });
    }
    if (
      idRegion.length !== 0 &&
      idMunicipio.length === 0 &&
      idSeccion.length === 0
    ) {
      setZoom(9);
      setDataGeoJson({});
      setTipoMapa({ value: 'region', label: 'Regional' });
      filtered_plugin.push({ id: 'secciones.idRegion', value: idRegion });
      getRegiones(idRegion, 'POST');
    }

    if (idMunicipio.length !== 0 && idSeccion.length === 0) {
      setZoom(10);
      setDataGeoJson({});
      setTipoMapa({ value: 'municipio', label: 'Municipal' });
      filtered_plugin.push({
        id: 'secciones.idMunicipioReportes',
        value: idMunicipio,
      });
      getMunicipios(idMunicipio, 'POST');
    }
    if (idSeccion.length !== 0) {
      setZoom(13);
      setDataGeoJson({});
      setTipoMapa({ value: 'seccion', label: 'Seccional' });
      filtered_plugin.push({ id: 'secciones.Seccion', value: idSeccion });
      getSecciones(idSeccion, 'POST');
    }
    if (
      idSeccion.length === 0 &&
      idMunicipio.length === 0 &&
      idRegion.length === 0
    ) {
      if (
        getVars('Token').user.Responsabilidad.value ===
        COORDINADOR_MUNICIPAL_ELECTORAL
      ) {
        getMunicipios('', 'GET');
      } else {
        getRegiones('', 'GET');
      }
    }
  };

  function selectmapaparametros(tipomapa) {
    if (tipomapa.value === 'municipio') {
      getMunicipiosRegion([tipomapa.clave], 'POST');
    }
    if (tipomapa.value === 'seccion') {
      getSeccionesMunicipios([tipomapa.clave], 'POST');
    }
  }

  async function getRegiones(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }

      let res = await fetch(hostserver + 'getRegionesElectoralGto', config);
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);

      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setRegiones(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setRegiones({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
    }
  }
  async function getMunicipios(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(hostserver + 'getMunicipiosElectoralGto', config);
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setMunicipios(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setMunicipios({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
    }
  }
  async function getSecciones(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + 'getSeccionesRutasGto', //"getSeccionesElectoralGto", //getSeccionesRutas
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setSecciones(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setSecciones({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
    }
  }
  async function getMunicipiosRegion(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + 'getMunicipiosElectoralGtoRegion',
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setMunicipios(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setMunicipios({
                "type": "FeatureCollection",
                "features": [
                ]
            }); */
    }
  }
  async function getSeccionesMunicipios(clave_parametro, method) {
    const token = getVars('Token').access_token;
    try {
      var config = {};
      if (method === 'GET') {
        config = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        };
      } else {
        config = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + 'getSeccionesRutasGtoMunicipio',
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
      setFlagFinish(true);
      if (json_parser.features === null) {
        throw new Error('features null');
      }
      //setSecciones(json_parser);
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
      setDataGeoJson({
        type: 'FeatureCollection',
        features: [],
      });
      /* setSecciones({
            "type": "FeatureCollection",
            "features": [
            ]
        }); */
    }
  }

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    if (e.value === 'region') {
      setZoom(9);
    }
    if (e.value === 'municipio') {
      setMunicipioSaved(e);
      setZoom(10);
    }
    if (e.value === 'seccion') {
      setMunicipioSaved(e);
      setZoom(11);
    }
    setTipoMapa(e);
    selectmapaparametros(e);
  };
  const handleTipoMapa = (tipo) => {
    //setDataGeoJson({});
    //setTipoMapa(e);
    //selectmapa(e);
    setSeccionesSelected([]);
    setFlagFinish(false);

    setDataGeoJson({});
    setTipoMapa(tipo);
    setPosition([20.816157, -101.021312]);
    if (tipo.value === 'region') {
      setZoom(9);
      getRegiones('', 'GET');
      setFlagFinish(true);
    }
    if (tipo.value === 'municipio') {
      if (getVars('Token').user.Responsabilidad.value !== COORDINADOR_MUNICIPAL_ELECTORAL) {
        setZoom(10);
        getMunicipios('', 'GET');
        setFlagFinish(true);
      }
      else {
        setZoom(11);
        getMunicipios('', 'GET');
        setFlagFinish(true);
      }

    }
    if (tipo.value === 'seccion') {
      if (getVars('Token').user.Responsabilidad.value !== COORDINADOR_MUNICIPAL_ELECTORAL) {
        setZoom(10);
        getSecciones('', 'GET');
        setFlagFinish(true);
      }
      else {
        setZoom(11);
        getSecciones('', 'GET');
        setFlagFinish(true);
      }

    }
  };

  //Comentada antes de subir 8 enero
  /* function selectmapa(tipomapa) {
    if (tipomapa.value === 'distrito') {
      //getDistritos("","GET");
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios('', 'GET');
    }
    if (tipomapa.value === 'poligono') {
      //getPoligonos("","GET");
    }
    if (tipomapa.value === 'seccion') {
      getSecciones('', 'GET');
    }
  } */

  const handleExport = (e) => {
    var dato = [];
    e.features.forEach((element) => {
      if (tipoMapa.value === 'distrito') {
        dato.push(element.properties['clave']);
      } else if (tipoMapa.value === 'municipio') {
        dato.push(element.properties['cve_mun']);
      } else if (tipoMapa.value === 'poligono') {
        dato.push(element.properties['poligono']);
      } else if (tipoMapa.value === 'seccion') {
        dato.push(element.properties['seccion']);
      }
    });
    const json = {
      data: dato,
      tabla: tipoMapa.value,
    };

    const serializado = JSON.stringify(json);

    var url = new URL(
      hostserver +
      `getReporteMapasCompromisos?token=${token.access_token}&datos=${serializado}`
    );
    //window.open(url, "_blank");
  };

  const handleSaveRoute = (secciones, data_ruta) => {
    setSeccionesSelected(secciones);
    if (data_ruta.NombreRuta.length < 3 || data_ruta.NombreRuta.length > 40) {
      setMensajeAlert(
        '¡El nombre de la ruta debe contener al menos 3 letras y no exceder 50 letras!'
      );
      setFlagAlert(true);
      return true;
    }
    if (data_ruta.ClaveRuta.length < 3 || data_ruta.ClaveRuta.length > 40) {
      setMensajeAlert('¡La clave de la ruta debe ser de minimo 3 y maximo 40 letras!');
      setFlagAlert(true);
      return true;
    }
    if (data_ruta.Comentarios.length > 1000) {
      setMensajeAlert('¡Los comentarios tienen limite de 1000 letras!');
      setFlagAlert(true);
      return true;
    }
    if (secciones.length === 0) {
      setMensajeAlert(
        'La ruta debe contener al menos una sección, ¡verifique que existan secciones seleccionadas!'
      );
      setFlagAlert(true);
      return true;
    }

    setRuta(secciones, data_ruta);
  };
  const handleSaveEditRoute = (secciones, data_ruta) => {
    setSeccionesSelected(secciones);
    if (data_ruta.NombreRuta.length < 3 || data_ruta.NombreRuta.length > 40) {
      setMensajeAlert(
        '¡El nombre de la ruta debe contener al menos 3 letras y no exceder 50 letras!'
      );
      setFlagAlert(true);
      return true;
    }
    if (data_ruta.ClaveRuta.length < 3 || data_ruta.ClaveRuta.length > 40) {
      setMensajeAlert('¡La clave de la ruta debe ser de maximo 10 letras!');
      setFlagAlert(true);
      return true;
    }
    if (data_ruta.Comentarios !== null && data_ruta.Comentarios !== undefined) {
      if (data_ruta.Comentarios.length > 1000) {
        setMensajeAlert('¡Los comentarios tienen limite de 1000 letras!');
        setFlagAlert(true);
        return true;
      }
    }
    if (secciones.length === 0) {
      setMensajeAlert(
        'La ruta debe contener al menos una seccion, ¡verifique que existan secciones seleccionadas!'
      );
      setFlagAlert(true);
      return true;
    }
    setEditRuta(secciones, data_ruta);
  };
  const handleDeletePropRuta = (data_ruta) => {
    setDeleteRuta(data_ruta);
  };

  async function setDeleteRuta(data_ruta) {
    setDataGeoJson({});
    const token = getVars('Token').access_token;
    try {
      let config = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: data_ruta.id
        }),
      };
      let res = await fetch(hostserver + 'deleteRutasGto', config);
      let data = await res.json();
      setMensajeAlert(data.message);
      setFlagAlert(true);
      if (data.success === true && data.results === true) {
        setVars('FlagEdit', false);
        setVars('rutaModal', {
          id: '',
          NombreRuta: '',
          ClaveRuta: '',
          Comentarios: '',
        });
        setVars('SeccionesSelected', []);
        setSeccionesSelected([]);
        setVars('TAB', 'CR');
      }
      else {
        setVars('FlagEdit', true);
      }
      if (Object.keys(municipioSaved).length !== 0) {
        handleDetalleMapa(municipioSaved);
      } else if (filteredSaved.length !== 0) {
        setDataGeoJson({});
        handleFiltro(filteredSaved, 'NADA');
      }
      else {
        getSecciones("", "GET");
      }


    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
    }
  }
  async function setRuta(secciones, data_ruta) {
    if(data_ruta.RG === 0){
      data_ruta.RG = null;
    }
    setDataGeoJson({});
    const token = getVars('Token').access_token;
    try {
      let config = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          secciones: secciones,
          NombreRuta: data_ruta.NombreRuta,
          ClaveRuta: data_ruta.ClaveRuta,
          Comentarios: data_ruta.Comentarios,
          idRGAsignado: data_ruta.RG !== null && data_ruta.RG !== undefined ? data_ruta.RG : null,
          UserOwned:data_ruta.UserOwned.value
        }),
      };
      let res = await fetch(hostserver + 'setRutasGto', config);
      let data = await res.json();
      setMensajeAlert(data.message);
      setFlagAlert(true);
      if (data.success === true && data.results === true) {
        setSeccionesSelected([]);
        setVars('SeccionesSelected', []);
        /* if(Object.keys(municipioSaved).length !== 0){
          handleDetalleMapa(municipioSaved);
        }
        else {
          setDataGeoJson({});
          handleFiltro(filteredSaved,"NADA");
        } */
      }
      if (Object.keys(municipioSaved).length !== 0) {
        handleDetalleMapa(municipioSaved);
      } else if (filteredSaved.length !== 0) {
        setDataGeoJson({});
        handleFiltro(filteredSaved, 'NADA');
      }
      else {
        getSecciones("", "GET");
      }
    } catch (error) {
      setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true);
    }
  }
  async function setEditRuta(secciones, data_ruta) {
    if(data_ruta.idRGAsignado === 0){
      data_ruta.idRGAsignado = null;
    }
    setDataGeoJson({});
    const token = getVars('Token').access_token;
    try {
      let config = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: data_ruta.id,
          secciones: secciones,
          idRGAsignado: data_ruta.idRGAsignado,
          quitoRG: data_ruta.idRGAsignado ? false : true,
          NombreRuta: data_ruta.NombreRuta,
          ClaveRuta: data_ruta.ClaveRuta,
          Comentarios: data_ruta.Comentarios,
          UserOwned:data_ruta.UserOwned?data_ruta.UserOwned.value:null
        }),
      };
      let res = await fetch(hostserver + 'updateRutasGto', config);
      let data = await res.json();
      setMensajeAlert(data.message);
      setFlagAlert(true);
      if (data.success === true && data.results === true) {
        setVars('FlagEdit', true);
      }
      else {
        setVars('FlagEdit', true);
      }
      if (Object.keys(municipioSaved).length !== 0) {
        handleDetalleMapa(municipioSaved);
      } else if (filteredSaved.length !== 0) {
        setDataGeoJson({});
        handleFiltro(filteredSaved, 'NADA');
      }
      else {
        getSecciones("", "GET");
      }


    } catch (error) {
      setMensajeAlert(
        'Error 001: ¡Ocurrio un error, Contacte al administrador!'
      );
      setFlagAlert(true);
    }
  }
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <Notifications />
        <div className="content">
          <>
            {FlagAlert ? basicAlert : <></>}

            <ChoropethMap
              key={'ChoropethMapV4'}
              center={[position.latitude, position.longitude]}
              position_center={_position}
              zoom={zoom}
              RGsRutaParameter={RGsRutaParameter}
              data={dataGeoJson}
              rangos={rangos}
              tipo={tipoMapa.value}
              label_tipo={tipoMapa.label}
              _flagFinish={flagFinish}
              derecho_mapa={derechoMapa}
              MenuPermisos={menu}
              setExport={handleExport}
              SeccionesSelectedParameter={SeccionesSelected}
              ParameterSeccionesSelected={() => { setSeccionesSelected([]); }}
              handleSaveRoute={handleSaveRoute}
              handleSaveEditRoute={handleSaveEditRoute}
              handleFiltro={handleFiltro}
              handleTipoMapa={handleTipoMapa}
              handleDetalleMapa={handleDetalleMapa}
              handleDeletePropRuta={handleDeletePropRuta}
              TipoMapa={tipoMapa}
              Responsabilidad={getVars('Token').user.Responsabilidad.value}
            ></ChoropethMap>

          </>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
