import React, { useEffect, useState } from 'react';

import EjecutarApi from 'data/getPOST';
import { notify } from 'react-notify-toast';
import {URL_IMAGENES} from 'GlobalStorage';

import {
  Button,
  CardFooter,
  Input,
  Col,
  Row,
  Spinner,
  Label,
  CardBody,
  FormGroup,
} from 'reactstrap';
import { SwatchesPicker } from 'react-color';

import PictureUpload from 'components/Perfil/PictureUpload';

const PartidosForm = (props) => {
  const {
    token,
    savePartido,
    isLoadingGuardado,
    partidoEdit,
    handleCancelar,
  } = props;
  const [partido, setPartido] = useState({
    isCoalicion: 0,
    partido: '',
    isIndependiente: 0,
    siglas: '',
    color: '',
    extension: '',
    totalPartidos: 1,
    idPartidoPrincipal: 0,
    ordenPrelacion: 0,
    detallePartido: [],
  });
  const [color, setColor] = useState('#b71c1c');
  const [colorSelector, setColorSelector] = useState(false);
  const [imagen, setImagen] = useState({
    archivo: null,
    url: '',
  });

  const [errors, setErros] = useState({
    Partido: '',
    Siglas: '',
    Detalle: '',
  });

  useEffect(() => {
    if (partidoEdit) {
      partidoEdit.partido = partidoEdit.Partido ? partidoEdit.Partido : '';
      partidoEdit.siglas = partidoEdit.siglas ? partidoEdit.siglas : '';
      partidoEdit.isCoalicion = partidoEdit.isCoalicion
        ? partidoEdit.isCoalicion
        : 0;
      partidoEdit.isIndependiente = partidoEdit.isIndependiente
        ? partidoEdit.isIndependiente
        : 0;
      partidoEdit.logo = partidoEdit.Logo ? `${URL_IMAGENES}/${partidoEdit.Logo}` : '';
      setPartido(partidoEdit);
      setColor(partidoEdit.colorC27);
      if (partidoEdit.logo !== null && partidoEdit.logo != '') {
        setImagen({ archivo: null, url: partidoEdit.logo });
      }
      // if (partidoEdit.isCoalicion) {
      //   setPartidosSeleccionados(partidoEdit.detalle);
      //   setPartidosSeleccionadosString(partidoEdit.partidosCoalicion)
      // }
    }
  }, [partidoEdit]);

  const handleCoalicion = (isCoalicion) => {
    let isIndependiente = isCoalicion === 0 ? 0 : partido.isIndependiente;
    setPartido({
      ...partido,
      isCoalicion: isCoalicion,
      isIndependiente: isIndependiente,
    });
  };

  const handleIndependiente = (value) => {
    let isCoalicion = value === 0 ? 0 : partido.isCoalicion;
    setPartido({
      ...partido,
      isIndependiente: value,
      isCoalicion: isCoalicion,
    });
  };

  const handleSelectColor = (color) => {
    setColor(color.hex);
    setColorSelector(false);
  };

  const handleImageChange = (image) => {
    let imageSave = {
      archivo: image.file,
      url: image.imagePreviewUrl
    }
    setImagen(imageSave);
  };

  const validatePartido = () => {
    let numeroErrores = 0;
    let errores = {
      Partido: '',
      Siglas: '',
    };
    let partidoJSON = JSON.parse(JSON.stringify(partido));
    // partidoJSON.detallePartido = formatDetallePartido();
    // if (
    //   partidoJSON.isCoalicion == 1 &&
    //   partidoJSON.detallePartido.length <= 1
    // ) {
    //   errores.Detalle = 'Debe seleccionar al menos dos partidos';
    //   numeroErrores++;
    // } else {
    //   errores.Detalle = '';
    // }
    if (partidoJSON.partido.trim().length === 0) {
      errores.Partido = 'Campo obligatorio';
      numeroErrores++;
    } else {
      errores.Partido = '';
    }
    if (partidoJSON.siglas.trim().length === 0) {
      errores.Siglas = 'Campo obligatorio';
      numeroErrores++;
    } else {
      errores.Siglas = '';
    }
    setErros(errores);
    if (numeroErrores === 0) {
      if (!partidoEdit && partidoJSON.detallePartido.length === 0) {
        partidoJSON.detallePartido = [{ id: 0 }];
      }

      partidoJSON.image = imagen.archivo ? imagen.url : null;
      partidoJSON.extension = imagen.archivo ? imagen.archivo.type : null;
      partidoJSON.color = color;
      // const idPrincipal = getIdPartidoPrincipal(partidosSeleccionados);
      partidoJSON.ordenPrelacion = partido.OrdenPrelacion
        ? partido.OrdenPrelacion
        : 0;
      partidoJSON.idPartidoPrincipal = partido.idPartidoPrincipal
        ? partido.idPartidoPrincipal
        : 0;
      partidoJSON.totalPartidos = partido.totalPartidos
        ? partido.totalPartidos
        : 1;
      savePartido(partidoJSON);
    }
  };

  const getIdPartidoPrincipal = (partidos) => {
    let numeroMenor = 0;
    if (partidos.length > 0) {
      numeroMenor = partidos.reduce((prev, curr) =>
        prev.OrdenPrelacion < curr.OrdenPrelacion ? prev : curr
      );
    }
    return numeroMenor;
  };

  return (
    <>
      <CardBody>
        <Row>
          <Col md="3">
            <Label for="partido">
              <span className="text-danger">*</span>Nombre del partido
            </Label>
            <Input
              value={partido.partido}
              id={'partido'}
              onChange={(e) =>
                setPartido({ ...partido, partido: e.target.value })
              }
            />
            <span className="form-check-sign" style={{ color: 'red' }}>
              {errors.Partido}
            </span>
          </Col>
          <Col md="3">
            <Label for="siglas">
              <span className="text-danger">*</span>Siglas
            </Label>
            <Input
              value={partido.siglas}
              id="siglas"
              onChange={(e) =>
                setPartido({ ...partido, siglas: e.target.value })
              }
            />
            <span className="form-check-sign" style={{ color: 'red' }}>
              {errors.Siglas}
            </span>
          </Col>
          <Col md="3">
            <FormGroup className="text-center text-primary">
              <strong>¿Es una Coalición?</strong>
              <br />
            </FormGroup>
            <FormGroup className="text-center text-primary">
              <div className="form-check-radio form-check-inline">
                <Label check>
                  <Input
                    type="radio"
                    name="si"
                    id="si"
                    checked={partido.isCoalicion}
                    onChange={() => handleCoalicion(1)}
                  />
                  Si
                  <span className="form-check-sign"></span>
                </Label>
              </div>
              <div className="form-check-radio form-check-inline">
                <Label check>
                  <Input
                    type="radio"
                    name="no"
                    id="no"
                    checked={!partido.isCoalicion}
                    onChange={() => handleCoalicion(0)}
                  />
                  No
                  <span className="form-check-sign"></span>
                </Label>
              </div>
            </FormGroup>
          </Col>
          {/* {partido.isCoalicion === 1 && (
            <Col md="3">
              <Row>
                <Col>
                  <Label for="siglas">
                    <span className="text-danger">*</span>Partidos
                  </Label>{' '}
                </Col>
                <Button onClick={() => setIsOpenModal(true)}>+</Button>
              </Row>
              <Row>
                <Col>
                  {partidosSeleccionadosString.length > 0
                    ? partidosSeleccionadosString
                    : 'Sin partidos seleccionados'}
                </Col>
              </Row>
              <span className="form-check-sign" style={{ color: 'red' }}>
                {errors.Detalle}
              </span>
            </Col>
          )} */}
          {partido.isCoalicion === 0 && (
            <Col md="3">
              <FormGroup className="text-center text-primary">
                <strong>¿Es un partido independiente?</strong>
                <br />
              </FormGroup>
              <FormGroup className="text-center text-primary">
                <div className="form-check-radio form-check-inline">
                  <Label check>
                    <Input
                      type="radio"
                      name="independienteSi"
                      id="independienteSi"
                      checked={partido.isIndependiente}
                      onChange={() => handleIndependiente(1)}
                    />
                    Si
                    <span className="form-check-sign"></span>
                  </Label>
                </div>
                <div className="form-check-radio form-check-inline">
                  <Label check>
                    <Input
                      type="radio"
                      name="independienteNo"
                      id="independienteNo"
                      checked={!partido.isIndependiente}
                      onChange={() => handleIndependiente(0)}
                    />
                    No
                    <span className="form-check-sign"></span>
                  </Label>
                </div>
              </FormGroup>
            </Col>
          )}
          <Col md="4">
            <Label for="siglas">
              <span className="text-danger">*</span>Color
            </Label>
            {/* <FormGroup>
              <Row>
                <Col>
                  <div
                    style={{
                      height: '100%',
                      width: '30%',
                      border: color + ' 1px solid',
                      marginRight: '0px'
                    }}
                  >
                    {color}
                  </div>
                  {!colorSelector && (
                    <Button onClick={(e) => setColorSelector(true)}>
                      Cambiar
                    </Button>
                  )}
                </Col>
              </Row>
            </FormGroup> */}
            {/* {colorSelector && ( */}
            <SwatchesPicker
              color={color}
              onChangeComplete={(e) => handleSelectColor(e)}
            />
            {/* )} */}
          </Col>
          <Col md="4">
            <Label for="siglas">
              <span className="text-danger">*</span>Logo
            </Label>
            <FormGroup>
              <PictureUpload
                regresarImagen={handleImageChange}
                imagenEdit={imagen.url}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Row>
          <Col
            md="12"
            sm="12"
            lg="12"
            className="align-center"
            onClick={handleCancelar}
          >
            <Button color="danger" size="sm">
              Cancelar
            </Button>
            <Button color="primary" size="sm" onClick={validatePartido}>
              Guardar
            </Button>
            {isLoadingGuardado && <Spinner color="primary" />}
          </Col>
        </Row>
      </CardFooter>
    </>
  );
};

export default PartidosForm;
