import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer"
import Sidebar from "../../components/Sidebar";
import { getVars, URL_PUBLICA } from '../../GlobalStorage';
import ReactTable from 'react-table';

import {
  Button, Card, CardHeader, CardBody, Row, Col, TabContent,
  TabPane, NavItem, Label, FormGroup,
  NavLink, CardTitle, CardFooter,
  Nav,
} from 'reactstrap';
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "../../data/getPOST";
import { CircleSpinner } from "react-spinners-kit";
import Pagination from "../../components/Pagination/pagination";
import ModalEditarMeta from '../../components/ConfiguracionMetas/EditarMetaEstructura';
import ModalImportExcel from '../../components/ConfiguracionMetas/ImportMetaEstructura';
import { getDataMenu } from "data/menus";

const ConfiguracionMeta = (props) => {

  // Configuracion
  const [setsidebarMini] = useState(true);
  const [backgroundColor] = useState("black");
  const [activeColor] = useState("info");
  const [loading, setLoading] = useState(false);
  const [loadingTabla, setLoadingTabla] = useState(false);
  const token = getVars('Token');
  const [isEditing, setEditing] = useState(false);
  const [horizontalTabs, setHorizontalTabs] = useState({
    horizontalTabs: "Regional",
    openedCollapses: ["collapseOne", "collapse1"]
  })

  const [isImport, setImport] = useState(false);
  const [Filtro, setFiltro] = useState([]);
  const [total, setTotal] = useState(0);
  const [meta, setMeta] = useState([]);
  const [municipioSelected, setMunicipioSelected] = useState({ value: 0, label: 'Todos' });
  const [regionSelected, setRegionSelected] = useState({ value: 0, label: 'Todos' })
  const [poligonoSelected, setPoligonoSelected] = useState({ value: 0, label: 'Todos' })
  const [seccioneSelected, setSeccioneSelected] = useState({ value: 0, label: 'Todos' })
  const [tabla, setTabla] = useState("region");

  // Catalogos
  const [metaPromocion, setMetaPromocion] = useState({
    id: "",
    label: "",
    meta: "",
    tabla: ""
  });

  const [showMeta, setShowMeta] = useState(false);
  const [metaShow, setMetaShow] = useState({
    label: "",
    meta: ""
  });
  const [col, setCol] = useState("4");


  //catalogos
  const [catMunicipioFilter, setCatMunicipioFilter] = useState([])
  const [catPoligonosFilter, setCatPoligonosFilter] = useState([])
  const [catSeccionesFilter, setCatSeccionFilter] = useState([])

  const [catRegiones, setCatRegiones] = useState([{ value: 0, label: 'Todos' }])
  const [catMunicipio, setCatMunicipio] = useState([{ value: 0, label: 'Todos' }])
  const [catPoligonos, setCatPoligonos] = useState([{ value: 0, label: 'Todos' }])
  const [catSecciones, setCatSecciones] = useState([{ value: 0, label: 'Todos' }])


  const [menu, _setmenu] = useState({});

  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(36, token.menus))

    let regionesFormated = [];
    let municipioFormated = [];
    let poligonoFormated = [];
    let seccionFormated = [];
    EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {
            let regiones = [{ value: 0, label: 'Todos' }]
            let municipios = [{ value: 0, label: 'Todos' }]
            let poligonos = [{ value: 0, label: 'Todos' }]
            let secciones = [{ value: 0, label: 'Todos' }]

            regionesFormated = res.data.regiones.map(item => {
              return {
                value: item.value,
                label: item.label
              }
            })
            const result = regionesFormated.filter(reg => reg.label !== 'NO DEFINIDO');
            setCatRegiones(regiones.concat(result))
            //CatMunicipio llenar

            municipioFormated = res.data.municipios.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
              }
            })
            setCatMunicipio(municipios.concat(municipioFormated))

            //CatPoligono llenar

            poligonoFormated = res.data.poligonos.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
                idMunicipio: item.idMunicipio,
              }
            })

            const polg = poligonoFormated.filter(pol => pol.value !== 1);
            setCatPoligonos(poligonos.concat(polg))

            //CatSeccion llenar

            seccionFormated = res.data.secciones.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
                idMunicipio: item.idMunicipio,
                idPoligono: item.idPoligono
              }
            })
            setCatSecciones(secciones.concat(seccionFormated))


            setCatMunicipioFilter(municipios.concat(municipioFormated))
            setCatPoligonosFilter(poligonos.concat(polg))
            setCatSeccionFilter(secciones.concat(seccionFormated))

          } else {
            showMessage("No Info: getCatalogosFiltrosPromocion", '#0E7CF1')
          }
        }
      })
      .catch((e) => {
        showMessage("Error: getCatalogosFiltrosPromocion", "#F1370E")
      }).then(() => {
        setLoading(false);
      })

  }, [tabla]);

  const showMessage = (message, background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
    } else {
      setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };


  // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA

  const handleRegiones = (e) => {
    setLoadingTabla(true)
    setLoading(true)
    setRegionSelected(e)

    var parametros = {
      tipo: "and",
      filtered: [
        { "id": "id", "value": e.value }
      ],
      tabla: "region"
    }
    EjecutarApi(parametros, "getMetaEstructuras", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {
            var porcentaje = parseFloat(res.data.porcentaje)
            var ddporcentaje = porcentaje.toFixed(2)
            setShowMeta(true)
            setMetaShow(
              {
                ...metaShow,
                label: e.label,
                meta: res.data.MetaEstructuraPartido,
                avance: res.data.AvanceEstructura,
                porcentaje: ddporcentaje
              }
            )
          } else {
            showMessage("No Info: metaReión")
          }
        }
        else { showMessage("Error:", res.message) }
      })
      .catch((e) => {
        setLoading(false);
        showMessage("Error: metaReión")
      }).then(() => {
        setLoading(false);
      })

    if (e.value > 1) {
      let municipios = [{ value: 0, label: 'Todos' }]
      let poligonos = [{ value: 0, label: 'Todos' }]
      let secciones = [{ value: 0, label: 'Todos' }]

      setCatMunicipioFilter(municipios.concat(catMunicipio.filter(data => data.idRegion === e.value)));
      const poligono = []
      catPoligonos.forEach(item => {
        var number = parseInt(item.idRegion);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          poligono.push(temp);
        }
      });
      setCatPoligonosFilter(poligonos.concat(poligono));
      const seccion = []
      catSecciones.forEach(item => {
        var number = parseInt(item.idRegion);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idPoligono: item.idPoligono,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          seccion.push(temp);
        }
      });
      setCatSeccionFilter(secciones.concat(seccion));

      fitroSend(e, "region");

    } else {
      setCatMunicipioFilter(catMunicipio);
      setCatPoligonosFilter(catPoligonos);
      setCatSeccionFilter(catPoligonos);
      fitroSend(e, "region");
    }
    setLoading(false);
  }

  const fitroSend = (e, handle, t = false) => {
    var filtro = [];
    if (handle !== "clear") {
      if (e.value > 0 && handle === "region") { filtro.push({ "id": "cat_region.id", "value": e.value }) }
      if (e.value > 0 && handle === "municipio") { filtro.push({ "id": "cat_municipios_reportes.id", "value": e.value }) }
      if (e.value > 0 && handle === "poligono") { filtro.push({ "id": "cat_poligonos.id", "value": e.value }) }
      if (e.value > 0 && handle === "seccion") { filtro.push({ "id": "secciones.Seccion", "value": e.value }) }

      if (regionSelected.value > 0 && handle !== "region") { filtro.push({ "id": "cat_region.id", "value": regionSelected.value }) }
      if (municipioSelected.value > 0 && handle !== "municipio") { filtro.push({ "id": "cat_municipios_reportes.id", "value": municipioSelected.value }) }
      if (poligonoSelected.value > 0 && handle !== "poligono") { filtro.push({ "id": "cat_poligonos.id", "value": poligonoSelected.value }) }
      if (seccioneSelected.value > 0 && handle !== "seccion") { filtro.push({ "id": "secciones.Seccion", "value": seccioneSelected.value }) }
    }


    else if (e === 0 && handle === "") { setFiltro(Filtro) }
    else { setFiltro(filtro); }

    let _menuStorage = getDataMenu(15, token.menus);
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      tipo: 'and',
      filtered: filtro,
      tabla: (t) ? t : tabla
    };
    EjecutarApi(data, "getMetaEstructurasGeneral", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setLoadingTabla(false);
            setTotal(res.total);
            setMeta(
              res.data.map((prop, key) => {
                var porcentaje = parseFloat(prop.porcentaje)
                var ddporcentaje = porcentaje.toFixed(2)
                return {
                  id: prop.id,
                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                  Region: prop.Region ? prop.Region : "Sin Region",
                  Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                  Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                  meta: _menuStorage.Editar === 1 ?
                    (<><Button
                      className="btn-link"
                      color="primary"
                      onClick={() => {
                        btnActualizarMetaEstructura(
                          {
                            id: prop.id ? prop.id : prop.Seccion,
                            label: (tabla === "region") ? prop.Region : (tabla === "municipio") ? prop.Municipio : (tabla === "poligono") ? prop.Poligono : (tabla === "seccion") ? prop.Seccion : "Sin Dato",
                            meta: prop.MetaEstructuraPartido,
                            tabla: tabla
                          }
                        );
                      }}
                    >{prop.MetaEstructuraPartido}</Button></>)
                    : prop.MetaEstructuraPartido,
                  AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                  porcentaje: ddporcentaje ? ddporcentaje : 0,
                  data: prop,
                };
              })
            );
          }
        }
      })
      .catch((e) => {
        setLoadingTabla(false);
        notify.show(
          `Error al obtener getMetaEstructurasGeneralFiltro: ${e.message}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
    setEditing(false);
    setImport(false);
  }

  const LimpiarFiltro = () => {
    setMunicipioSelected({ value: 0, label: 'Todos' })
    setPoligonoSelected({ value: 0, label: 'Todos' })
    setSeccioneSelected({ value: 0, label: 'Todos' })
    setRegionSelected({ value: 0, label: 'Todos' })

    fitroSend("", "clear");
  }

  const changeTap = (e) => {
    setLoadingTabla(true);
    setHorizontalTabs({ ...horizontalTabs, horizontalTabs: e })


    const tbla = (e === "Regional") ? "region" : (e === "Municipal") ? "municipio" : (e === "Poligonal") ? "poligono" : (e === "Seccional") ? "seccion" : ""
    setTabla(tbla)
    setCol((e === "Regional") ? "4" : (e === "Municipal") ? "6" : (e === "Poligonal") ? "9" : (e === "Seccional") ? "12" : "")


    setCatMunicipioFilter(catMunicipio);
    setCatPoligonosFilter(catPoligonos);
    setCatSeccionFilter(catSecciones);

    setMunicipioSelected({ value: 0, label: 'Todos' })
    setPoligonoSelected({ value: 0, label: 'Todos' })
    setSeccioneSelected({ value: 0, label: 'Todos' })
    setRegionSelected({ value: 0, label: 'Todos' })
    setMetaShow({
      label: "",
      meta: ""
    })

    setShowMeta(false)
    fitroSend("", "clear", tbla);
  }

  const handleMunicipio = (e) => {
    setLoadingTabla(true)
    setLoading(true)
    setMunicipioSelected(e)

    var parametros = {
      tipo: "and",
      filtered: [
        { "id": "id", "value": e.value }
      ],
      tabla: "municipio"
    }

    EjecutarApi(parametros, "getMetaEstructuras", token.access_token, "POST")
      .then(res => {
        if (res.data) {
          var porcentaje = parseFloat(res.data.porcentaje)
          var ddporcentaje = porcentaje.toFixed(2)
          setShowMeta(true)
          setMetaShow(
            {
              ...metaShow,
              label: e.label,
              meta: res.data.MetaEstructuraPartido,
              avance: res.data.AvanceEstructura,
              porcentaje: ddporcentaje
            }
          )
        } else {
          showMessage("No Info: metaReión")
        }
      })
      .catch((e) => {
        setLoading(false);
        showMessage("Error: meta Municipio")
      }).then(() => {
        setLoading(false);
      })

    if (e.value > 1) {
      let poligonos = [{ value: 0, label: 'Todos' }]
      let secciones = [{ value: 0, label: 'Todos' }]

      const poligono = []
      catPoligonos.forEach(item => {
        var number = parseInt(item.idMunicipio);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          poligono.push(temp);
        }
      });
      setCatPoligonosFilter(poligonos.concat(poligono));
      const seccion = []
      catSecciones.forEach(item => {
        var number = parseInt(item.idMunicipio);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idPoligono: item.idPoligono,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          seccion.push(temp);
        }
      });
      setCatSeccionFilter(secciones.concat(seccion));


      fitroSend(e, "municipio");

    } else {
      setCatMunicipioFilter(catMunicipio);
      setCatPoligonosFilter(catPoligonos);
      setCatSeccionFilter(catSecciones);
      fitroSend(e, "municipio");
    }
    setLoading(false);
  }

  const handlePoligonos = (e) => {
    setLoadingTabla(true)
    setLoading(true)
    setPoligonoSelected(e)

    var parametros = {
      tipo: "and",
      filtered: [
        { "id": "id", "value": e.value }
      ],
      tabla: "poligono"
    }

    EjecutarApi(parametros, "getMetaEstructuras", token.access_token, "POST")
      .then(res => {
        if (res.data) {
          var porcentaje = parseFloat(res.data.porcentaje)
          var ddporcentaje = porcentaje.toFixed(2)
          setShowMeta(true)
          setMetaShow(
            {
              ...metaShow,
              label: e.label,
              meta: res.data.MetaEstructuraPartido,
              avance: res.data.AvanceEstructura,
              porcentaje: ddporcentaje

            }
          )
        } else {
          showMessage("No Info: meta Polígono")
        }
      })
      .catch((e) => {
        setLoading(false);
        showMessage("Error: meta Polígono")
      }).then(() => {
        setLoading(false);
      })

    if (e.value > 1) {
      let secciones = [{ value: 0, label: 'Todos' }]
      const seccion = []
      catSecciones.forEach(item => {
        var number = parseInt(item.idPoligono);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idPoligono: item.idPoligono,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          seccion.push(temp);
        }
      });
      setCatSeccionFilter(secciones.concat(seccion));

      fitroSend(e, "poligono");

    } else {
      setCatMunicipioFilter(catMunicipio);
      setCatPoligonosFilter(catPoligonos);
      setCatSeccionFilter(catPoligonos);
      fitroSend(e, "poligono");
    }
    setLoading(false);
  }

  const handleSecciones = (e) => {
    setLoadingTabla(true)
    setSeccioneSelected(e)

    var parametros = {
      tipo: "and",
      filtered: [
        { "id": "seccion", "value": e.value }
      ],
      tabla: "seccion"
    }

    EjecutarApi(parametros, "getMetaEstructuras", token.access_token, "POST")
      .then(res => {
        if (res.data) {
          var porcentaje = parseFloat(res.data.porcentaje)
          var ddporcentaje = porcentaje.toFixed(2)
          setShowMeta(true)
          setMetaShow(
            {
              ...metaShow,
              label: e.label,
              meta: res.data.MetaEstructuraPartido,
              avance: res.data.AvanceEstructura,
              porcentaje: ddporcentaje
            }
          )
        } else {
          showMessage("No Info: meta Sección")
        }
      })
      .catch((e) => {
        setLoading(false);
        showMessage("Error: meta Sección")
      }).then(() => {
        setLoading(false);
      })

    setLoading(false)
    fitroSend(e, "seccion");

  }

  const btnCancelarImportar = (e) => {

    if (e === true) {
      fitroSend(0, "");
    }
    else { setImport(false); }

  };

  //Actualizar


  const btnActualizarMetaEstructura = (e) => {
    setEditing(true)
    setMetaPromocion({
      ...metaPromocion,
      id: e.id,
      label: e.label,
      meta: e.meta,
      tabla: tabla
    })

  }

  const btnCancelarEditar = (e) => {
    if (e === true) {
      fitroSend(0, "");
    }
    else { setEditing(false); }
  };


  const Exportar = () => {
    var url = new URL(URL_PUBLICA +
      `getReporteMetasEstructuras?token=${token.access_token}`
    );
    window.open(url, "_blank");
  }


  //Estructura

  const showMunicipioEstructura = (horizontalTabs.horizontalTabs === "Municipal" || horizontalTabs.horizontalTabs === "Poligonal" || horizontalTabs.horizontalTabs === "Seccional")

  const showPoligonosEstructura = (horizontalTabs.horizontalTabs === "Poligonal" || horizontalTabs.horizontalTabs === "Seccional")

  const showSeccionesEstructura = (horizontalTabs.horizontalTabs === "Seccional")


  return (
    <div className="wrapper">
      <Notifications />
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
        <div className="content">

          <div className="content">
            {/* Fechas Compromiso*/}
            <Row>
              {showMeta &&
                <>
                  <Col sm="3">
                    <Card body>
                      <CardTitle>{metaShow.label}</CardTitle>
                      <Row >
                        <Col>
                          <legend className="text-center">{metaShow.meta}</legend>
                        </Col>
                      </Row>
                      <CardFooter>
                        <hr />
                        <i className="fa fa-flag" />
                        Meta
                        </CardFooter>
                    </Card>
                  </Col>
                  <Col sm="3">
                    <Card body>
                      <CardTitle>{metaShow.label}</CardTitle>
                      <Row >
                        <Col>
                          <legend className="text-center">{metaShow.avance}</legend>
                        </Col>
                      </Row>
                      <CardFooter>
                        <hr />
                        <i className="fa fa-line-chart" />
                        Avance Estructura
                        </CardFooter>
                    </Card>
                  </Col>
                  <Col sm="3">
                    <Card body>
                      <CardTitle>{metaShow.label}</CardTitle>
                      <Row >
                        <Col>
                          <legend className="text-center">{metaShow.porcentaje}</legend>
                        </Col>
                      </Row>
                      <CardFooter>
                        <hr />
                        <i className="fa fa-percent" />
                        Porcentaje de Avance
                        </CardFooter>
                    </Card>
                  </Col>
                </>
              }
            </Row>
            {/* Configuracón Estructura Metas */}

            <Row>
              <Col sm={col}>
                <Card>
                  <CardHeader>
                    <Row >
                      <Col lg="4" md="4" sm="4">
                        <FormGroup>
                          <Label for="Estado">
                            Región
                              </Label>
                          <Select
                            options={catRegiones}
                            className="react-select primary"
                            classNamePrefix="react-select"
                            defaultValue={catRegiones[0]}
                            value={regionSelected}
                            onChange={(e) => handleRegiones(e)}
                            id="regiones"
                            placeholder="regiones"
                          />
                        </FormGroup>
                      </Col>
                      {showMunicipioEstructura &&
                        <Col lg="4" md="4" sm="4">
                          <FormGroup>
                            <Label for="Municipio">
                              Municipios
                              </Label>
                            <Select
                              options={catMunicipioFilter}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={municipioSelected}
                              onChange={(e) => handleMunicipio(e)}
                              id="municipio"
                              placeholder="Municipio"
                            />
                          </FormGroup>
                        </Col>
                      }
                      {showPoligonosEstructura &&
                        <Col lg="2" md="2" sm="2">
                          <FormGroup>
                            <Label for="Estado">
                              Polígonos
                              </Label>
                            <Select
                              options={catPoligonosFilter}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={poligonoSelected}
                              onChange={(e) => handlePoligonos(e)}
                              id="poligonos"
                              placeholder="poligonos"
                            />
                          </FormGroup>
                        </Col>
                      }
                      {showSeccionesEstructura &&
                        <Col lg="2" md="2" sm="2">
                          <FormGroup>
                            <Label for="Estado">
                              Secciones
                              </Label>
                            <Select
                              options={catSeccionesFilter}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={seccioneSelected}
                              onChange={(e) => handleSecciones(e)}
                              id="secciones"
                              placeholder="secciones"
                            />
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col sm='4'>
                        <Button name="btnLimpiar" size="sm" type="button" className="success float-left" color='warning' onClick={() => { LimpiarFiltro() }}>Limpiar</Button>
                      </Col>
                      {(menu.Exportar === 1 && !loadingTabla) ? (
                        <Col sm='8'>
                          <Button name="btnImport" size="sm" type="button" className="info float-right" color='info' onClick={() => { setImport(true) }}>Importar</Button>
                          <Button name="btnExportar" size='sm' type="button" className='primary float-right' color='primary' onClick={() => Exportar()}>
                            <span className="btn-label">
                              <i className="fa fa-file-excel-o fa-lg" />
                            </span>
                            Exportar
												</Button>
                        </Col>
                      ) : <></>}
                    </Row>

                  </CardHeader>
                  <CardBody>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav id="tabs" role="tablist" tabs>
                          <NavItem>
                            <NavLink
                              aria-expanded={horizontalTabs.horizontalTabs === "Regional"}
                              data-toggle="tab"
                              //href="#pablo"
                              role="tab"
                              className={horizontalTabs.horizontalTabs === "Regional"
                                ? "active"
                                : ""
                              }
                              onClick={() => { changeTap("Regional") }}
                            >
                              Regional
											</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-expanded={horizontalTabs.horizontalTabs === "Municipal"
                              }
                              data-toggle="tab"
                              //href="#pablo"
                              role="tab"
                              className={horizontalTabs.horizontalTabs === "Municipal"
                                ? "active"
                                : ""
                              }
                              onClick={() => changeTap("Municipal")}
                            >
                              Municipal
											</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-expanded={horizontalTabs.horizontalTabs === "Poligonal"}
                              data-toggle="tab"
                              //href="#pablo"
                              role="tab"
                              className={horizontalTabs.horizontalTabs === "Poligonal"
                                ? "active"
                                : ""
                              }
                              onClick={() =>
                                changeTap("Poligonal")}
                            >
                              Poligonal
											</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-expanded={horizontalTabs.horizontalTabs === "Seccional"}
                              data-toggle="tab"
                              //href="#pablo"
                              role="tab"
                              className={horizontalTabs.horizontalTabs === "Seccional"
                                ? "active"
                                : ""
                              }
                              onClick={() =>
                                changeTap("Seccional")}
                            >
                              Seccional
											</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                    <TabContent
                      className="text-center"
                      id="my-tab-content"
                      activeTab={horizontalTabs.horizontalTabs}
                    >
                      <TabPane tabId="Regional" role="tabpanel">

                        <ReactTable
                          size='sm'
                          data={meta}
                          noDataText="Sin información"
                          manual
                          columns={[
                            {
                              Header: "Region",
                              minWidth: 30,
                              id: "Region",
                              accessor: (d) => d.Region,
                              filterAll: false,
                            },
                            {
                              Header: "Meta",
                              accessor: (d) => d.meta,
                              minWidth: 40,
                              id: "meta",
                              filterable: false,
                            },
                            {
                              Header: "Avance Estructura",
                              accessor: (d) => d.AvanceEstructura,
                              minWidth: 40,
                              id: "AvanceEstructura",
                              filterable: false,
                            },
                            {
                              Header: "Porcentaje de Avance",
                              accessor: (d) => d.porcentaje,
                              Cell: props => <div>{props.value + " %"}</div>,
                              minWidth: 40,
                              id: "porcentaje",
                              filterable: false,
                            },
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loadingTabla}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.floor(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                            setLoadingTabla(true);
                            let _menuStorage = getDataMenu(15, token.menus);
                            const data = {
                              page: stateComp.page,
                              pageSize: stateComp.pageSize,
                              sorted: [{ id: "Region", desc: false }],
                              tipo: 'and',
                              filtered: Filtro ? Filtro : [],
                              tabla: "region"
                            };
                            EjecutarApi(data, "getMetaEstructurasGeneral", token.access_token)
                              .then(function (res) {
                                return res;
                              })
                              .then((res) => {
                                if (res.success) {
                                  if (res.results) {
                                    setTotal(res.total);
                                    setMeta(
                                      res.data.map((prop, key) => {
                                        var porcentaje = parseFloat(prop.porcentaje)
                                        var ddporcentaje = porcentaje.toFixed(2)
                                        return {
                                          id: prop.id,
                                          Region: prop.Region ? prop.Region : "Sin Region",
                                          meta:
                                            _menuStorage.Editar === 1 ?
                                              (<><Button
                                                className="btn-link"
                                                color="primary"
                                                onClick={() => {
                                                  btnActualizarMetaEstructura({
                                                    id: prop.id,
                                                    label: prop.Region ? prop.Region : "Sin Region",
                                                    meta: prop.MetaEstructuraPartido,
                                                  });
                                                }}
                                              >{prop.MetaEstructuraPartido}</Button></>)
                                              : prop.MetaEstructuraPartido,
                                          AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                                          porcentaje: ddporcentaje ? ddporcentaje : 0,
                                          data: prop,
                                        };
                                      })
                                    );
                                    setLoadingTabla(false);
                                  }
                                }

                              })
                              .catch((e) => {
                                setLoading(false);
                                notify.show(
                                  `Error al obtener tabla metas estructura region: ${e.message}`,
                                  "custom",
                                  5000,
                                  {
                                    background: "#0857B6",
                                    fontSize: 14,
                                    text: "#FFFFFF",
                                  }
                                );
                              });
                          }}
                        />
                      </TabPane>
                      <TabPane tabId="Municipal" role="tabpanel">
                        {horizontalTabs.horizontalTabs === "Municipal" ?
                          <ReactTable
                            size='sm'
                            data={meta}
                            noDataText="Sin información"
                            manual
                            columns={[
                              {
                                Header: "Region",
                                minWidth: 30,
                                id: "Region",
                                accessor: (d) => d.Region,
                                filterAll: false,
                              },
                              {
                                Header: "Municipio",
                                minWidth: 70,
                                id: "Municipio",
                                accessor: (d) => d.Municipio,
                                filterAll: false,
                              },
                              {
                                Header: "Meta",
                                accessor: (d) => d.meta,
                                minWidth: 40,
                                id: "meta",
                                filterable: false,
                              },
                              {
                                Header: "Avance Estructura",
                                accessor: (d) => d.AvanceEstructura,
                                minWidth: 40,
                                id: "AvanceEstructura",
                                filterable: false,
                              },
                              {
                                Header: "Porcentaje de Avance",
                                accessor: (d) => d.porcentaje,
                                Cell: props => <div>{props.value + " %"}</div>,
                                minWidth: 40,
                                id: "porcentaje",
                                filterable: false,
                              },

                            ]}
                            className="-striped -highlight primary-pagination"
                            loading={loadingTabla}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            pages={Math.floor(total / 10)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={(stateComp, instance) => {
                              setLoadingTabla(true);
                              let _menuStorage = getDataMenu(15, token.menus);
                              const data = {
                                page: stateComp.page,
                                pageSize: stateComp.pageSize,
                                sorted: [{ id: "cat_municipios_reportes.Municipio", desc: false }],
                                tipo: 'and',
                                filtered: Filtro ? Filtro : [],
                                tabla: "municipio"
                              };
                              EjecutarApi(data, "getMetaEstructurasGeneral", token.access_token)
                                .then(function (res) {
                                  return res;
                                })
                                .then((res) => {
                                  setLoadingTabla(false);
                                  setTotal(res.total);
                                  setMeta(
                                    res.data.map((prop, key) => {
                                      return {
                                        id: prop.id,
                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                        Region: prop.Region ? prop.Region : "Sin Region",
                                        meta:
                                          _menuStorage.Editar === 1 ?
                                            (<><Button
                                              className="btn-link"
                                              color="primary"
                                              onClick={() => {
                                                btnActualizarMetaEstructura({
                                                  id: prop.id,
                                                  label: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                                  meta: prop.MetaEstructuraPartido
                                                });
                                              }}
                                            >{prop.MetaEstructuraPartido}</Button></>)
                                            : prop.MetaEstructuraPartido,
                                        AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                                        porcentaje: prop.porcentaje ? prop.porcentaje : 0,
                                        data: prop,
                                      };
                                    })
                                  );

                                })
                                .catch((e) => {
                                  setLoadingTabla(false);
                                  notify.show(
                                    `Error al obtener tabla metas: ${e.message}`,
                                    "custom",
                                    5000,
                                    {
                                      background: "#0857B6",
                                      fontSize: 14,
                                      text: "#FFFFFF",
                                    }
                                  );
                                });
                            }}
                          /> : <></>
                        }

                      </TabPane>
                      <TabPane tabId="Poligonal" role="tabpanel">
                        {horizontalTabs.horizontalTabs === "Poligonal" ?
                          <ReactTable
                            size='sm'
                            data={meta}
                            noDataText="Sin información"
                            manual
                            columns={[
                              {
                                Header: "Region",
                                minWidth: 30,
                                id: "Region",
                                accessor: (d) => d.Region,
                                filterAll: false,
                              },
                              {
                                Header: "Municipio",
                                minWidth: 70,
                                id: "Municipio",
                                accessor: (d) => d.Municipio,
                                filterAll: false,
                              },
                              {
                                Header: "Polígono",
                                minWidth: 30,
                                id: "Poligono",
                                accessor: (d) => d.Poligono,
                                filterAll: false,
                              },
                              {
                                Header: "Meta",
                                accessor: (d) => d.meta,
                                minWidth: 40,
                                id: "meta",
                                filterable: false,
                              },
                              {
                                Header: "Avance Estructura",
                                accessor: (d) => d.AvanceEstructura,
                                minWidth: 40,
                                id: "AvanceEstructura",
                                filterable: false,
                              },
                              {
                                Header: "Porcentaje de Avance",
                                accessor: (d) => d.porcentaje,
                                Cell: props => <div>{props.value + " %"}</div>,
                                minWidth: 40,
                                id: "porcentaje",
                                filterable: false,
                              },
                            ]}
                            className="-striped -highlight primary-pagination"
                            loading={loadingTabla}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            pages={Math.floor(total / 10)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={(stateComp, instance) => {
                              setLoadingTabla(true);
                              let _menuStorage = getDataMenu(15, token.menus);
                              const data = {
                                page: stateComp.page,
                                pageSize: stateComp.pageSize,
                                sorted: [],
                                tipo: 'and',
                                filtered: Filtro ? Filtro : [],
                                tabla: "poligono"
                              };
                              EjecutarApi(data, "getMetaEstructurasGeneral", token.access_token)
                                .then(function (res) {
                                  return res;
                                })
                                .then((res) => {
                                  setLoadingTabla(false);
                                  setTotal(res.total);
                                  setMeta(
                                    res.data.map((prop, key) => {
                                      return {
                                        id: prop.id,
                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                        Region: prop.Region ? prop.Region : "Sin Region",
                                        Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                        meta:
                                          _menuStorage.Editar === 1 ?
                                            (<><Button
                                              className="btn-link"
                                              color="primary"
                                              onClick={() => {
                                                btnActualizarMetaEstructura({
                                                  id: prop.id,
                                                  label: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                                  meta: prop.MetaEstructuraPartido
                                                });
                                              }}
                                            >{prop.MetaEstructuraPartido}</Button></>)
                                            : prop.MetaEstructuraPartido,
                                        AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                                        porcentaje: prop.porcentaje ? prop.porcentaje : 0,
                                        data: prop,
                                      };
                                    })
                                  );

                                })
                                .catch((e) => {
                                  setLoadingTabla(false);
                                  notify.show(
                                    `Error al obtener tabla metas: ${e.message}`,
                                    "custom",
                                    5000,
                                    {
                                      background: "#0857B6",
                                      fontSize: 14,
                                      text: "#FFFFFF",
                                    }
                                  );
                                });
                            }}
                          /> : <></>

                        }

                      </TabPane>
                      <TabPane tabId="Seccional" role="tabpanel">
                        {horizontalTabs.horizontalTabs === "Seccional" ?
                          <ReactTable
                            size='sm'
                            data={meta}
                            noDataText="Sin información"
                            manual
                            columns={[
                              {
                                Header: "Region",
                                minWidth: 30,
                                id: "Region",
                                accessor: (d) => d.Region,
                                filterAll: false,
                              },
                              {
                                Header: "Municipio",
                                minWidth: 70,
                                id: "Municipio",
                                accessor: (d) => d.Municipio,
                                filterAll: false,
                              },
                              {
                                Header: "Polígono",
                                minWidth: 30,
                                id: "Poligono",
                                accessor: (d) => d.Poligono,
                                filterAll: false,
                              },
                              {
                                Header: "Sección",
                                minWidth: 30,
                                id: "Seccion",
                                accessor: (d) => d.Seccion,
                                filterAll: false,
                              },
                              {
                                Header: "Meta",
                                accessor: (d) => d.meta,
                                minWidth: 40,
                                id: "meta",
                                filterable: false,
                              },
                              {
                                Header: "Avance Estructura",
                                accessor: (d) => d.AvanceEstructura,
                                minWidth: 40,
                                id: "AvanceEstructura",
                                filterable: false,
                              },
                              {
                                Header: "Porcentaje de Avance",
                                accessor: (d) => d.porcentaje,
                                Cell: props => <div>{props.value + " %"}</div>,
                                minWidth: 40,
                                id: "porcentaje",
                                filterable: false,
                              },
                            ]}
                            className="-striped -highlight primary-pagination"
                            loading={loadingTabla}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            pages={Math.floor(total / 10)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={(stateComp, instance) => {
                              setLoadingTabla(true);
                              let _menuStorage = getDataMenu(15, token.menus);
                              const data = {
                                page: stateComp.page,
                                pageSize: stateComp.pageSize,
                                sorted: [],
                                tipo: 'and',
                                filtered: Filtro ? Filtro : [],
                                tabla: "seccion"
                              };
                              EjecutarApi(data, "getMetaEstructurasGeneral", token.access_token)
                                .then(function (res) {
                                  return res;
                                })
                                .then((res) => {
                                  setLoadingTabla(false);
                                  setTotal(res.total);
                                  setMeta(
                                    res.data.map((prop, key) => {
                                      return {
                                        id: prop.id,
                                        Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                        Region: prop.Region ? prop.Region : "Sin Region",
                                        Poligono: prop.Poligono ? prop.Poligono : 'Sin Polígono',
                                        Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                                        meta:
                                          _menuStorage.Editar === 1 ?
                                            (<><Button
                                              className="btn-link"
                                              color="primary"
                                              onClick={() => {
                                                btnActualizarMetaEstructura(
                                                  {
                                                    id: prop.Seccion,
                                                    label: prop.Seccion ? prop.Seccion : 'Sin Sección',
                                                    meta: prop.MetaEstructuraPartido
                                                  }
                                                );
                                              }}
                                            >{prop.MetaEstructuraPartido}</Button></>)
                                            : prop.MetaEstructuraPartido,
                                        AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : 0,
                                        porcentaje: prop.porcentaje ? prop.porcentaje : 0,
                                        data: prop,
                                      };
                                    })
                                  );

                                })
                                .catch((e) => {
                                  setLoadingTabla(false);
                                  notify.show(
                                    `Error al obtener tabla metas: ${e.message}`,
                                    "custom",
                                    5000,
                                    {
                                      background: "#0857B6",
                                      fontSize: 14,
                                      text: "#FFFFFF",
                                    }
                                  );
                                });
                            }}
                          />
                          : <></>
                        }

                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {isEditing ? <ModalEditarMeta onHide={btnCancelarEditar} OnEdit={metaPromocion} /> : <></>}
          </div>
        </div>
        <Footer fluid />
        {loading && (
          <CircleSpinner
            className="text-center"
            size={30}
            color="#686769"
            loading={loading}
          />
        )}
      </div>
      {isImport ? <ModalImportExcel onHide={btnCancelarImportar} OnEdit={tabla} /> : <></>}
    </div>
  );

}
export default ConfiguracionMeta;
