import React, { useEffect, useState } from "react";
import {ExcelRenderer} from 'react-excel-renderer';
import { Row,Col,Input, Button,Modal,ModalHeader,ModalBody, ModalFooter, Label } from 'reactstrap';
import { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";
import ReactTable from "react-table";
import Pagination from "../../components/Pagination/pagination";

const ImportMetaCompromiso =({onHide}) =>{
    const token = getVars("Token");
    const [Mensaje,setMensaje] = useState('')
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [excel, setExcel] = useState({
        Region: "",
        Municipio:"",
        Poligono:"",
        seccion: "",
        meta:""
    });
    const [meta,setMeta]=useState([]);
    const [metaTabla,setMetaTabla]=useState([]);
    const [total,setTotal]=useState(0);
    var $rowsE=[];


useEffect(()=>{

 }, [metaTabla]);

    const setMetaUpdate=()=>{
        setLoading(true);
        setMensaje('')

        var parametros={
          "data": excel
      }
      if(meta.length !== 0){
          EjecutarApi(parametros, "setSeccionCompromisosMasivo", token.access_token, "POST")
          .then(res => {
            if (res.results) {
              showMessage("Cambio de metas exitoso!!!","#1BD350")}
              setLoading(false);
              onHide(true);
          })
          .catch((e) => {
            setLoading(false);
            showMessage("Error: setMetaCompromisos","#F01C18")
          }).then(() => {
            setLoading(false);
          })
        }
        else{
          setMensaje("No se reconocio ningun archivo verifique de nuevo")
          setLoading(false);
        }
    
        
      }
  
    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
    
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
          }
          else{
            if(resp.rows[0][0] === "Edición de secciones" && resp.rows[1][0] === "Región"  && resp.rows[1][1] === "Municipio" && resp.rows[1][2] === "Polígono" && resp.rows[1][3] === "Sección" && resp.rows[1][4] === "Meta"){
            $rowsE=resp.rows;
            setShowTable(true)
            setLoading(false)
            var dato=[];
            const data=[];
            $rowsE.forEach((element,key )=> {
                if(key >1){
                 const temp = {
                    Region: element[0],
                    Municipio:element[1],
                    Poligono:element[2],
                    seccion: element[3],
                    meta:element[4]
                  }
                  data.push(temp);
                  if(key <= 11){
                      dato.push(temp);
                  }
                } 
              });
            setMetaTabla(dato)
            setMeta(data)
            setExcel(data)
            setTotal(data.length)
          }
          else{
            setMensaje('Error: El archivo no tiene un formato compatible!!!')
            setLoading(false);
            return;
          }

        }
        });               
    
      }
    

 const showMessage = (message,background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }
    return(
        <Modal
            isOpen={true}
            toggle={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <ModalHeader toggle={onHide}>
           { "Importar Excel Meta Sección: "}
           
        </ModalHeader>
        <ModalBody>
        {loading && <MetroSpinner size={30} color="#686769" loading={loading} />}
            <Row md='12'  className="justify-content-md-center">
                        <Col sm="5" >
                        <Input type="file" onChange={fileHandler.bind(this)} style={{"padding":"10px"}} />
                        </Col>
                    </Row>
                    <Row>
                    <Col sm="12">
                        <Label id='mensaje'className='text-danger' >{Mensaje}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                  {showTable &&
                    <div style={{ 'width': '100%', 'overflow': 'auto'}}>
                      <ReactTable 
                        style={{
                          width: "110%",
                            height: "100%"
                          }}
                          size='sm'
                          data={metaTabla}
                          noDataText="Sin información"
                          manual
                          columns={[
                            {
                              Header: "Region",
                              minWidth: 30,
                              id: "Region",
                              accessor: (d) => d.Region,
                              filterAll: false,
                            },
                            {
                              Header: "Municipios",
                              minWidth: 50,
                              id: "Municipios",
                              accessor: (d) => d.Municipio,
                              filterAll: false,
                            },
                            {
                              Header: "Polígono",
                              minWidth: 40,
                              id: "Poligono",
                              accessor: (d) => d.Poligono,
                              filterAll: false,
                            },
                            {
                              Header: "Sección",
                              minWidth: 40,
                              id: "seccion",
                              accessor: (d) => d.seccion,
                              filterAll: false,
                            },
                            {
                              Header: "Meta",
                              minWidth: 40,
                              id: "meta",
                              accessor: (d) => d.meta,
                              filterAll: false,
                            }
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loading}
                          showPageSizeOptions={false}
                          pageSizeOptions={[10]}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.floor(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                              const elementInicio = stateComp.page * stateComp.pageSize;
                              let elementFin = (stateComp.page + 1) * stateComp.pageSize;

                              if(elementFin > total){

                                elementFin =elementFin - total;
                              }
                              
                              var dato=[];
                            if(meta.length !== 0 ){
                              for (let i = elementInicio; i < elementFin; i++) {
                                dato.push(meta[i]);
                              }
                              setMetaTabla(dato);
                            }
                          }}
                      />
                    </div>
                  }
              </Col>
                </Row>
                </ModalBody>
        <ModalFooter className="justify-content-md-center">
            <Row >
                <Col>
                    <Button color='primary' className='primary' size='sm' onClick={(e)=> setMetaUpdate(e)} >Guardar</Button>               
                </Col>
                <Col>
                    <Button color='danger' className='danger' size='sm' onClick={(e)=> onHide(false)} >Cancelar</Button>               
                </Col>
            </Row>
        </ModalFooter>
    </Modal>
  
);
}
export default ImportMetaCompromiso;