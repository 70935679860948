import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official';

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    },
});

//HC_exporting(Highcharts);


const ChartMultipleColums = ({ Categories, Series, Title, Subtitle, Colors, isScrollable }) => {
    const [_isScrollable, setIsScrollable] = useState(false);
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: Title
        },
        colors: Colors,
        credits: {
            enabled: false
        },
        subtitle: {
            text: Subtitle
        },
        xAxis: {
            categories: Categories,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: { fontSize: "1.1em" }
                }
            },
        },
        series: Series
    };

    if (isScrollable === true) {
        options.chart.scrollablePlotArea = {
            minWidth: 2048,
            scrollPositionX: -1
        };
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            type='column'
        />
    )
}

export default ChartMultipleColums;