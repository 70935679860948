import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official';
import { URL_IMAGENES } from 'GlobalStorage'

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  },
});

//HC_exporting(Highcharts);

const ChartColumnComparison = ({ Series, Title, Subtitle, tipoVista }) => {

  const getData = (data) => {
    return data.map(function (item, i) {
      return {
        name: item.name,
        y: item.y,
        color: item.color
      };
    });
  }

  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: Title
    },
    subtitle: {
      text: Subtitle
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        grouping: false,
        borderWidth: 0
      }
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> <b>{point.y}</b><br/>'
    },
    xAxis: {
      type: 'category',
      categories: Series,
      labels: {
        useHTML: true,
        animate: true,
        formatter: function () {
          // let output = `<div style="font-size: 0.75em; text-align: center;"><span style="color: black;">${this.value.name}</span></div>`;
          let output = '<div style="font-size: 0.75em; text-align: center;">';
          if (this.value.Logo !== null || this.value.Logo !== '') {
            output += `<br><img src="${URL_IMAGENES}${this.value.Logo}" style="width: 35px; height: 35px;"/><br>`;
          }
          if (tipoVista === 1) {
            output += `<span style="color: black;">${this.value.name}</span></div>`
          } else {
            output += `<span style="color: white;">--------</span></div>`
          }

          return output;
        }
      }
    },
    yAxis: [{
      title: {
        text: ''
      },
      showFirstLabel: false
    }],
    series: [{
      dataLabels: [{
        enabled: true,
        inside: false,
        style: {
          fontSize: '1.2em'
        }
      }],
      data: getData(Series).slice()
    }],
    exporting: {
      allowHTML: true
    }
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      type='column'
    />
  )
}

export default ChartColumnComparison