import React, { useState, useEffect } from 'react';
import {
    InputGroup, InputGroupAddon, Button, FormGroup,
    Row, Col, Input
} from 'reactstrap';
import { notify } from "react-notify-toast";
import { RotateSpinner } from "react-spinners-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import EjecutarApi from "../../data/getPOST";
import { getVars } from "../../GlobalStorage";
import Select from "react-select";
import { getDataMenu } from '../../data/menus.js';

const TreeAsigCoordinadores = (props) => {
    const { idEstructura } = props;
    const [loading, setLoading] = useState(true);
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    const [HasMore, setHasMore] = useState(true);
    const [isSeleccionable, setIsSeleccionable] = useState(false);
    const [nextFetch, setnextFetch] = useState(1);
    const [DatosResponsable, setDatosResponsable] = useState([]);
    const [total, setTotal] = useState(0);
    const [Estructura, setEstructura] = useState({
        idEstructura: idEstructura,
        idRegion: {},
        idMunicipio: {},
        idResponsabilidad: { value: 0, label: 'Seleccione...' },
        Responsable: '',
        idResponsable: 0
    });
    const [catSocial, setCatSocial] = useState([]);
    const [loadingSocial, setLoadingSocial] = useState(false);
    const [isSocial, setIsSocial] = useState({
        CoorGralSocial: false,
        CoorEstrSocial: false,
        CoorRegSocial: false,
        CoorMpalSocial: false,
        CoorEstatalSocial: false,
        CoordeEstructura: false
    });

    useEffect(() => {
        _setmenu(getDataMenu(134, token.menus));
        setLoadingSocial(true);
        EjecutarApi({}, 'getCatalogosPorResponsabilidadSocialWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success) {
                    let arr = [{ value: 0, label: 'Seleccionar...' }];
                    res.data.forEach(item => {

                        arr.push(item)

                    })

                    setCatSocial(arr)
                    setLoadingSocial(false);
                }

            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                    background: '#0857B6',
                    fontSize: 14,
                    text: '#FFFFFF'
                });
            });
    }, []);

    useEffect(() => {
        hadleData();
    }, [idEstructura])

    const hadleData = () => {
        setLoading(true);
        var parametro = {
            idEstructura: [idEstructura],
            page: nextFetch,
            pageSize: 10
        };
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    
                    setDatosResponsable(res.data);
                    setnextFetch(1);
                    setTotal(res.totalRegistros);
                }
            })
            .catch((e) => {
                
                notify.show(`Error en la búsqueda de enlaces: ${e.error}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            })
            .finally(() => {setLoading(false);});
    }

    const BuscarResponsable = (value) => {
        var parametro = {
            page: 0,
            pageSize: 10,
            filtered: [{ id: "FullSearch", value: value }],
            tipo: "and",
            sorted: [{ "id": "LastUpdate", "desc": true }]
        };
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(res.data);
                    setTotal(res.totalRegistros)
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }

    const fetchHasMore = () => {
        setLoading(true);
        if (nextFetch * 10 >= total) {
            setHasMore(false);
            return;
        }
        var parametro = {
            page: nextFetch,
            pageSize: 10,
            filtered: [{ id: "FullSearch", value: Estructura.Responsable }],
            tipo: "and",
            sorted: [{ "id": "LastUpdate", "desc": true }]
        };
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(DatosResponsable.concat(res.data));
                    setnextFetch(nextFetch + 1);
                    setTotal(res.totalRegistros);
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    };

    const handleSelectSocial = (e) => {

        switch (e.value) {
            case 30:
                setIsSocial({
                    ...isSocial,
                    CoorGralSocial: false,
                    CoorEstrSocial: false,
                    CoorRegSocial: false,
                    CoorMpalSocial: false,
                    CoorEstatalSocial: false,
                    CoordeEstructura: false
                });

                setEstructura({ ...Estructura, idResponsabilidad: e });
                break;
            case 31:
                setIsSocial({
                    ...isSocial,
                    CoorGralSocial: false,
                    CoorEstrSocial: true,
                    CoorRegSocial: false,
                    CoorMpalSocial: false,
                    CoorEstatalSocial: false,
                    CoordeEstructura: false
                });

                setEstructura({ ...Estructura, idResponsabilidad: e });
                break;
            case 34:
                setIsSocial({
                    ...isSocial,
                    CoorGralSocial: false,
                    CoorEstrSocial: false,
                    CoorRegSocial: false,
                    CoorMpalSocial: false,
                    CoorEstatalSocial: true,
                    CoordeEstructura: false
                });

                setEstructura({ ...Estructura, idResponsabilidad: e });
                break;
            case 35:
                setIsSocial({
                    ...isSocial,
                    CoorGralSocial: false,
                    CoorEstrSocial: false,
                    CoorRegSocial: false,
                    CoorMpalSocial: false,
                    CoorEstatalSocial: false,
                    CoordeEstructura: true
                });
                setEstructura({ ...Estructura, idResponsabilidad: e });
                break;
            default:
                setIsSocial({
                    ...isSocial,
                    CoorGralSocial: false,
                    CoorEstrSocial: false,
                    CoorRegSocial: false,
                    CoorMpalSocial: false,
                    CoorEstatalSocial: false,
                    CoordeEstructura: false
                });
                break;
        }
    }

    const setResponsable = (e) => {
        setLoading(true)
        var parametros = '';
        var API = '';
        parametros = {
            idEstructura: idEstructura,
            idUser: Estructura.idResponsable,
            ClaveReferencia: Estructura.Codigo,
            idResponsabilidad: Estructura.idResponsabilidad.value
        }

        switch (Estructura.idResponsabilidad.value) {
            case 30:
                parametros = {
                    idEstructura: idEstructura,
                    idUser: Estructura.idResponsable,
                    // ClaveReferencia:Estructura.Codigo,
                    idResponsabilidad: Estructura.idResponsabilidad.value
                }
                API = 'setResponsableEstructuraSocialWeb'
                //setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                break;
            case 31:
                parametros = {
                    idEstructura: idEstructura,
                    idUser: Estructura.idResponsable,
                    // ClaveReferencia:Estructura.Codigo,
                    idResponsabilidad: Estructura.idResponsabilidad.value
                }
                API = 'setResponsableEstructuraSocialWeb'
                //setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                break;
            case 34:
                parametros = {
                    idEstructura: idEstructura,
                    idUser: Estructura.idResponsable,
                    //  ClaveReferencia:Estructura.Codigo,
                    idResponsabilidad: Estructura.idResponsabilidad.value
                }
                API = 'setResponsableEstructuraSocialWeb'
                //setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                break;
            case 35:
                parametros = {
                    idEstructura: idEstructura,
                    idUser: Estructura.idResponsable,
                    //    ClaveReferencia:Estructura.Codigo,
                    idResponsabilidad: Estructura.idResponsabilidad.value
                }
                API = 'setReponsableSubEstructuraSocialWeb'

                //setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                break;
            default:
                setIsSocial({
                    ...isSocial,
                    CoorGralSocial: false,
                    CoorEstrSocial: false,
                    CoorRegSocial: false,
                    CoorMpalSocial: false,
                    CoorEstatalSocial: false,
                    CoordeEstructura: false
                });
                break;
        }
        EjecutarApi(parametros, API, token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setEstructura({
                        idEstructura: idEstructura,
                        idRegion: {},
                        idMunicipio: {},
                        idResponsabilidad: { value: 0, label: 'Seleccione...' },
                        Responsable: '',
                        idResponsable: 0
                    })
                    setIsSeleccionable(false)
                    setIsSocial({
                        CoorGralSocial: false,
                        CoorEstrSocial: false,
                        CoorRegSocial: false,
                        CoorMpalSocial: false,
                        CoorEstatalSocial: false,
                        CoordeEstructura: false
                    });
                    BuscarResponsable('')
                    notify.show(res.message, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                    setLoading(false);
                }
                else {
                    notify.show(`Error:${res.message}`, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    });
                    setLoading(false);
                    //onHide();
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });
    }

    const onClickEliminarResponsabilidad=(item)=>{
        let result=EliminarResponsabilidad (item,0);
        if(result.delete===1)
        {
            notify.show(`Error: ${result.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
            result=EliminarResponsabilidad (item,1);
            if (result===2)
            {
                notify.show(`Error: ${result.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
                result=EliminarResponsabilidad (item,2);
                notify.show(`Error: ${result.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            }
        }else
        {
            notify.show(`La responsabilidad ha sido eliminada`, "error", 5000, {
                background: "#0857B6",
                text: "#FFFFFF",
            });
            BuscarResponsable('');
            setEstructura({...Estructura,Responsable:''})
        }
    }

    const EliminarResponsabilidad=(idUser)=>{
        var parametro={
            idUser:idUser
        };
        EjecutarApi(parametro, 'eliminarResponsablesWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                notify.show(`Responsabilidad eliminada`, "success", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
                fetchHasMore();
            }
            else{
                notify.show(`Error: No se puede eliminar la responsabilidad`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }

    const handleSetResponsable=(e)=>{
        setEstructura({...Estructura,Responsable:e.Nombre,idResponsable:e.id});
        setIsSeleccionable(true);
    }

    return (
        <>
            <Col md="12">
                <Row>
                    <FormGroup className="col-sm-12">
                        <InputGroup className="no-border">
                            <Input value={Estructura.Responsable}
                                onChange={(e) => setEstructura({ ...Estructura, Responsable: e.target.value })}
                                placeholder="Buscar..."
                                type="text" />
                            <InputGroupAddon addonType="append">
                                <Button color='warning'
                                    style={{ margin: '0px 1px' }}
                                    onClick={() => BuscarResponsable(Estructura.Responsable)}>
                                    <i className="nc-icon nc-zoom-split" />
                                </Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Button color='primary' style={{ margin: '0px 1px' }}
                                    onClick={() => BuscarResponsable('')} className='link-button'>
                                    <i className="fa fa-eraser" aria-hidden="true" />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Row>

                <Row>
                    <Col sm="12">
                        <FormGroup>
                            {isSeleccionable && (<>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    id="idSocial"
                                    placeholder="Seleccione..."
                                    options={catSocial}
                                    onChange={(e) => { handleSelectSocial(e); }}
                                    value={Estructura.idResponsabilidad}
                                >
                                </Select></>)}
                        </FormGroup>
                    </Col>
                </Row>
                {isSeleccionable && (<>
                    <Row>
                        <Col>
                            <Button id='btnsave' color='primary' size='sm' onClick={() => setResponsable()}>Guardar</Button>
                        </Col>
                    </Row>
                </>)}
                <Row sm="12">
                    {loading && (<RotateSpinner color={"#1261ff"} size={30} loading={loading} />)}
                </Row>
                <Row>
                    <Col sm="12" className='text-muted'> Mostrando: <strong>{DatosResponsable.length}</strong>  de <strong>{total}</strong> Registros </Col>
                </Row>
                <br />
                <Row>

                    <Col sm="12">

                        {<InfiniteScroll
                            dataLength={total}
                            next={fetchHasMore}
                            hasMore={HasMore}
                            loader={<h5>...Cargando...</h5>}
                            height={650}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>¡Final de la lista de registros!</b>
                                </p>}
                        >
                            {
                                DatosResponsable.map((item, key) =>
                                    <div key={'key' + key.toString()} className="divv text-muted" >
                                        <strong>{item.Responsabilidad.value === 30 || item.Responsabilidad.value === 31 || item.Responsabilidad.value === 34 ||
                                            item.Responsabilidad.value === 35 ? item.Nombre :
                                            <Button name={key.toString() + "btn"} color="primary" size="sm"
                                                onClick={() => handleSetResponsable(item)} >
                                                {item.Nombre}</Button>
                                        }

                                        </strong>
                                        <br />
                                        Celular:{item.email}
                                        <br />
                                        Municipio:{item.MunicipioVive.label}
                                        <br />
                                        Estructura:{item.EstructuraSocial.label}
                                        <br />
                                        Responsabilidad:
                                        { _menu.Eliminar === 1? <>
                                            <Button className='btn-link'
                                                name='tooltip476609793'
                                                color="danger"
                                                id={key.toString()}
                                                size="sm"
                                                type="button"
                                                onClick={(e) => onClickEliminarResponsabilidad(item.id)}
                                            > x
                                            </Button>
                                        </>
                                            : ''}<strong>{item.Responsabilidad.label}</strong>

                                        <hr />
                                    </div>
                                )
                            }

                        </InfiniteScroll>}
                    </Col>
                </Row>
            </Col>
        </>
    );
};
export default TreeAsigCoordinadores;