import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import Pagination from 'components/Pagination/pagination';

import { getVars, setVars } from 'GlobalStorage';
import EjecutarApi from 'data/getPOST';

import { Button, Col, Card, Row, CardHeader, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import Notifications, { notify } from 'react-notify-toast';

import Filtro from 'components/Filtro/indexPartido';
import PartidoEdit from './PartidosEdit';
import PartidosCoalicion from 'components/Partidos/PartidosCoalicion';

const usersPermitidos = { CoordinadorGeneral: 99 };

const Partidos = (props) => {
  let history = useHistory();
  const token = getVars('Token');
  const [menu, _setmenu] = useState({});
  const [partidos, setPartidos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState([]);
  const [idEdit, setIdEdit] = useState(0);
  const [isLoadingPartidosCoalicion, setIsLoadingPartidosCoalicion] = useState(
    false
  );
  const [partidosCoalicion, setPartidosCoalicion] = useState([]);
  const [partidosSeleccionados, setPartidosSeleccionados] = useState([]);
  const [
    partidosSeleccionadosString,
    setPartidosSeleccionadosString,
  ] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idPartidoDetalle, setIdPartidoDetalle] = useState(0);
  const [isLoadingAsignacion, setIsLoadingAsignacion] = useState(false);

  useEffect(() => {
    const getPartidos = () => {
      const data = {
        page: 0,
        pageSize: 10,
      };
      EjecutarApi(data, 'getPartidos', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success) {
            if (res.results) {
              setIsLoading(false);
              setTotal(res.total);
              setPartidos(
                res.data.map((partido) => {
                  return {
                    Partido: (
                      <Button
                        className="btn-link"
                        color="primary"
                        size="sm"
                        onClick={() => setIdEdit(partido.id)}
                      >
                        {partido.Partido.toUpperCase() + ' '}
                        <i className="fa fa-pencil" />
                      </Button>
                    ),
                    Siglas: partido.siglas,
                    Coalicion: partido.isCoalicion == 1 ? 'Sí' : 'No',
                    Independiente: partido.isIndependiente == 1 ? 'Sí' : 'No',
                    PartidosCoalicion: partido.partidosCoalicion,
                    Accion:
                      partido.isCoalicion == 1 ? (
                        <Button onClick={() => handleOpenModal(partido)}>
                          Partidos
                        </Button>
                      ) : (
                        ''
                      ),
                  };
                })
              );
            }
          }
        })
        .catch((e) => {
          setIsLoading(false);
          notify.show(
            `Error al buscar partidos: ${e.message} 'Favor de renovar sesión'
            }`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        });
    };

    getPartidos();
  }, []);

  const getPartidos = (data) => {
    setIsLoading(true);
    EjecutarApi(data, 'getPartidos', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setTotal(res.total);
            setIsLoading(false);
            setPartidos(
              res.data.map((partido) => {
                return {
                  Partido: (
                    <Button
                      className="btn-link"
                      color="primary"
                      size="sm"
                      onClick={() => setIdEdit(partido.id)}
                    >
                      {partido.Partido.toUpperCase() + ' '}
                      <i className="fa fa-pencil" />
                    </Button>
                  ),
                  Siglas: partido.siglas,
                  Coalicion: partido.isCoalicion == 1 ? 'Sí' : 'No',
                  Independiente: partido.isIndependiente == 1 ? 'Sí' : 'No',
                  PartidosCoalicion: partido.partidosCoalicion,
                  Accion:
                    partido.isCoalicion == 1 ? (
                      <Button onClick={() => handleOpenModal(partido)}>
                        Partidos
                      </Button>
                    ) : (
                      ''
                    ),
                };
              })
            );
          }
        }
      })
      .catch((e) => {
        setIsLoading(false);
        notify.show(
          `Error al buscar rutas: ${/* e.message */ 'Favor de renovar sesión'}`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const handleOpenModal = (partido) => {
    setPartidosSeleccionados(partido.detalle);
    setIdPartidoDetalle(partido.id);
    setIsOpenModal(true);
    getPartidosSeleccion(partido.id);
  };

  const getPartidosSeleccion = (idPartido) => {
    setIsLoadingPartidosCoalicion(true);
    const data = {
      filtered: [{ id: 'NotId', value: idPartido }],
    };
    EjecutarApi(data, 'getPartidos', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setIsLoadingPartidosCoalicion(false);
            setPartidosCoalicion(res.data);
          }
        }
      })
      .catch((e) => {
        setIsLoadingPartidosCoalicion(false);
        notify.show(
          `Error al buscar rutas: ${e.message} 'Favor de renovar sesión'
          }`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const handleFiltro = (filtro) => {
    setFilter(filtro);
    const data = {
      page: 0,
      pageSize: 10,
      filtered: filtro,
    };
    getPartidos(data);
  };

  const guardarCoalision = () => {
    const partidosFormated = formatDetallePartido();
    partidosFormated.push({ id: idPartidoDetalle });
    setIsLoadingAsignacion(true);
    const data = {
      idPartido: idPartidoDetalle,
      partidos: partidosFormated,
    };
    EjecutarApi(data, 'createDetallePartido', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setIsOpenModal(false);
            setIsLoadingAsignacion(false);
            notify.show(`${res.message}`, 'custom', 5000, {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            });
            getPartidos({
              page: 0,
              pageSize: 10,
              filtered: filter,
            });
          }
        }
      })
      .catch((e) => {
        setIsLoadingAsignacion(false);
        notify.show(
          `Error al buscar rutas: ${e.message} 'Favor de renovar sesión'
          }`,
          'error',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const formatDetallePartido = (partidos) => {
    let arrayDetalle = [];
    partidosSeleccionados.map((partido) => {
      arrayDetalle.push({ id: partido.id });
    });
    return arrayDetalle;
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <Notifications />
        <div className="content">
          {token.user.Responsabilidad.value ===
            usersPermitidos.CoordinadorGeneral ? (
            <>
              <PartidosCoalicion
                partidos={partidosCoalicion}
                partidosSeleccionados={partidosSeleccionados}
                setPartidosSeleccionados={setPartidosSeleccionados}
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                isLoading={isLoadingPartidosCoalicion}
                partidosSeleccionadosString={partidosSeleccionadosString}
                setPartidosSeleccionadosString={setPartidosSeleccionadosString}
                isLoadingAsignacion={isLoadingAsignacion}
                guardarCoalision={guardarCoalision}
              />
              {idEdit !== 0 && <PartidoEdit id={idEdit} setIdEdit={setIdEdit} />}
              {idEdit === 0 && (
                <Card>
                  <CardHeader>
                    <p align="center">
                      <strong>Partidos</strong>
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => history.push('/PartidosAdd')}
                      >
                        <span className="btn-label">
                          <i className="fa fa-user-plus fa-lg" />
                        </span>
                        Capturar
                      </Button>
                    </Row>
                    <Filtro filtrar={handleFiltro} />
                    <Row>
                      <Col
                        style={{
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <ReactTable
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                          size="sm"
                          data={partidos}
                          noDataText="Sin información"
                          manual
                          columns={[
                            {
                              Header: 'Partido',
                              minWidth: 8,
                              id: 'Partido',
                              accessor: (d) => d.Partido,
                              filterAll: false,
                            },
                            {
                              Header: 'Siglas',
                              minWidth: 4,
                              id: 'Siglas',
                              accessor: (d) => d.Siglas,
                              filterAll: false,
                            },
                            {
                              Header: 'Coalición',
                              minWidth: 4,
                              id: 'Coalición',
                              accessor: (d) => d.Coalicion,
                              filterAll: false,
                            },
                            {
                              Header: 'Independiente',
                              minWidth: 4,
                              id: 'Independiente',
                              accessor: (d) => d.Independiente,
                              filterAll: false,
                            },
                            {
                              Header: 'Partidos coalicionados',
                              minWidth: 8,
                              id: 'PartidosCoalicion',
                              accessor: (d) => d.PartidosCoalicion,
                              filterAll: false,
                            },
                            {
                              Header: 'Acción',
                              minWidth: 4,
                              id: 'Accion',
                              accessor: (d) => d.Accion,
                              filterAll: false,
                            },
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={isLoading}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.ceil(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp) => {
                            const data = {
                              page: stateComp.page,
                              pageSize: stateComp.pageSize,
                              filtered: filter,
                            };
                            getPartidos(data);
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </>
          ) : (
            <Card>
              <CardBody>
                <h2 className='text-center'>Sin permisos, consulte al administrador</h2>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default Partidos;
