import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Collapse,
  Input,
} from 'reactstrap';
import Select from 'react-select';
import { CircleSpinner } from 'react-spinners-kit';
import Notifications, { notify } from 'react-notify-toast';
import EjecutarApi from '../../data/getPOST';
import { getVars, URL_PUBLICA } from '../../GlobalStorage';

function IndexCasillasIncidencias({ Datos }) {
  const [loading, setLoading] = useState(false);
  const token = getVars('Token');
  const [filtro, setFiltro] = useState({
    Municipio: {},
    DF: {},
    DL: {},
    Poligono: {},
    Seccion: '',
    Casilla: '',
    Estatus: { value: 0, label: 'Seleccionar...' },
    RG: '',
    TelRG: '',
    isResuelto: { value: 0, label: 'No' },
    Clasificación: '',
  });
  const [collapse, setCollapse] = useState({
    horizontalTabs: 'home',
    verticalTabs: 'info',
    pageTabs: 'homePages',
    openedCollapses: ['collapseOne', 'collapse1'],
  });
  const [dataCombos, setDataCombo] = useState({
      dRegion: { label: 'Seleccione', value: 0 },
      dMunicipio: { label: 'Seleccione', value: 0 },
      dPoligono: { label: 'Seleccione', value: 0 },
      dSeccion: { label: 'Seleccione', value: 0 },
      dDF: { label: 'Seleccione', value: 0 },
      dDL: { label: 'Seleccione', value: 0 },
      Casilla: '',
      Estatus: { value: 0, label: 'Seleccionar...' },
      RG: '',
      TelRG: '',
      isResuelto: {label:"tipo",value:3},
      idIncidencia: { label: 'Seleccione', value: 0 },
  });

  const [CatEstatus] = useState([
    { value: 0, label: 'Seleccionar...' },
    { value: 1, label: 'Nueva' },
    { value: 2, label: 'En proceso' },
    { value: 3, label: 'Atendido' },
  ]);

  const [CatSolucionado] = useState([
    { value: 3, label: 'Todos' },
    { value: 0, label: 'No' },
    { value: 1, label: 'Si' }
  ]);
  const [catMunicipiotFilter, setCatMunicipiotFilter] = useState([]);
  const [catMunicipiot, setCatMunicipiot] = useState([]);
  const [catDFFilter, setCatDFFilter] = useState([]);
  const [catDFt, setCatDFt] = useState([]);
  const [catDLFilter, setCatDLFilter] = useState([]);
  const [catDLt, setCatDLt] = useState([]);
  const [catPoligonost, setCatPoligonost] = useState([]);
  const [catPoligonostFilter, setCatPoligonostFilter] = useState([]);
  const [catSecciont, setCatSecciont] = useState([]);
  const [catSecciontFilter, setCatSecciontFilter] = useState([]);
  const [catIncidencia, setCatIncidencia] = useState([{ value: 0, label: "Seleccione" }]);

  useEffect(() => {
    setLoading(true);
    EjecutarApi({
      "catalogos": [
        "municipios_reportes",
        "dfs",
        "dls",
        "secciones"
      ]
    }, 'getCatalogosSije', token.access_token)
      .then((res) => {
        if (res.results) {
          if (res.data) {
            setLoading(false);

            let df = [{ value: 0, label: 'Seleccionar...' }];
            let dfFormated = res.data.dfs.map((item) => {
              return {
                value: item.value,
                label: item.label,
              };
            });
            setCatDFt(df.concat(dfFormated));
            setCatDFFilter(df.concat(dfFormated));

            let dl = [{ value: 0, label: 'Seleccionar...' }];
            let dlFormated = res.data.dfs.map((item) => {
              return {
                value: item.value,
                label: item.label,
              };
            });
            setCatDLt(dl.concat(dlFormated));
            setCatDLFilter(dl.concat(dlFormated));

            let municipios = [{ value: 0, label: 'Seleccionar...' }];
            let municipiosFormated = res.data.municipios_reportes.map((item) => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
              };
            });
            setCatMunicipiot(municipios.concat(municipiosFormated));
            setCatMunicipiotFilter(municipios.concat(municipiosFormated));

            let seccion = [{ value: 0, label: 'Seleccione' }];
            let seccionFormated = res.data.secciones.map((item) => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
                idMunicipio: item.idMunicipio,
                idPoligono: item.idPoligono,
              };
            });
            setCatSecciont(seccion.concat(seccionFormated));
            setCatSecciontFilter(seccion.concat(seccionFormated));

          }
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al cargar catalogos: ${e.message} Favor de renovar sesión`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });

      let dat = {
        page: 0,
        pageSize: 1000000,
        tipo: "and",
        filtered: [],
        sort: []
    };
      EjecutarApi(dat, 'getCatalogoIncidencia', token.access_token)
      .then((items) => {
        if (items.results) {
            if (items.data) {
                setCatIncidencia(items.data.map((prop, key) => {
                    return {
                        value: prop.id,
                        label: prop.Incidencia
                    }
                }))
            }
        } else {
            notify.show("No Info: getCatalogosFiltrosPromocion", "custom", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
        }
        setLoading(false);
    }).catch((e) => {
          setLoading(false);
          notify.show(
            `Error al cargar catalogos: ${e.message} Favor de renovar sesión`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        });
  

  }, []);
  const collapsesToggle = (colapse) => {
    let openedCollapses = collapse.openedCollapses;
    if (openedCollapses.includes(colapse)) {
      setCollapse({
        ...collapse,
        openedCollapses: openedCollapses.filter((item) => item !== colapse),
      });
    } else {
      openedCollapses.push(colapse);
      setCollapse({ ...collapse, openedCollapses: openedCollapses });
    }
  };

  const changeDF = (df) => {
    setDataCombo({
      ...dataCombos,
      dDF: df,
    });
  };

  const changeDL = (dl) => {
    setDataCombo({
      ...dataCombos,
      dDL: dl,
    });
  };

  const changeMunicipioX = (dMunicipio) => {
    setLoading(true);
    if (dMunicipio.value > 1) {
      setDataCombo({
        ...dataCombos,
        dMunicipio: dMunicipio,
        dPoligono: { label: 'Seleccione', value: 0 },
        dSeccion: { label: 'Seleccione', value: 0 },
      });
      setCatPoligonostFilter(
        catPoligonost.filter(
          (data) => parseInt(data.idMunicipio) === dMunicipio.value
        )
      );
      setCatSecciontFilter(
        catSecciont.filter(
          (data) => parseInt(data.idMunicipio) === dMunicipio.value
        )
      );
    } else {
      setDataCombo({
        ...dataCombos,
        dMunicipio: dMunicipio,
        dPoligono: { label: 'Seleccione', value: 0 },
        dSeccion: { label: 'Seleccione', value: 0 },
      });
      setCatSecciontFilter(catSecciont);
      setCatPoligonostFilter(catPoligonost);
    }
    setLoading(false);
  };
/*   const changePoligonoX = (dPoligono) => {
    setLoading(true);
    setDataCombo({ ...dataCombos, dPoligono });
    if (dPoligono.value > 1) {
      setCatSecciontFilter(
        catSecciont.filter(
          (data) => parseInt(data.idPoligono) === dPoligono.value
        )
      );
    } else {
      setCatSecciontFilter(catSecciont);
    }

    setLoading(false);
  }; */
  const changeSeccionX = (dSeccion) => {
    setLoading(true);
    setDataCombo({ ...dataCombos, dSeccion });
    setLoading(false);
  };

  const setFilter = (event) => {
    var filtrar = {
      Municipio: dataCombos.dMunicipio,
      Poligono: dataCombos.dPoligono,
      DF: dataCombos.dDF,
      DL: dataCombos.dDL,
      Seccion: dataCombos.dSeccion,
      Casilla: filtro.Casilla,
      Estatus: filtro.Estatus,
      RG: filtro.RG,
      TelRG: filtro.TelRG,
      idIncidencia: dataCombos.idIncidencia,
      isResuelto: filtro.isResuelto,
      Clasificación: filtro.Clasificación,
    };

    Datos(filtrar);
  };

  const ClearFilter = () => {
    setDataCombo({
      ...dataCombos,
      dRegion: { label: 'Seleccione', value: 0 },
      dMunicipio: { label: 'Seleccione', value: 0 },
      dPoligono: { label: 'Seleccione', value: 0 },
      dSeccion: { label: 'Seleccione', value: 0 },
      dDF: { label: 'Seleccione', value: 0 },
      dDL: { label: 'Seleccione', value: 0 },
      Casilla: '',
      Estatus: { value: 0, label: 'Seleccionar...' },
      RG: '',
      TelRG: '',
      isResuelto: {label:"tipo",value:3},
      idIncidencia: { label: 'Seleccione', value: 0 },
    });
    let _filtro = {
      Municipio: {label: 'Seleccione', value: 0 },
      Poligono: {label: 'Seleccione', value: 0 },
      DF: {label: 'Seleccione', value: 0 },
      DL: {label: 'Seleccione', value: 0 },
      Seccion: { label: 'Seleccione', value: 0 },
      Casilla: '',
      Estatus: { value: 0, label: 'Seleccionar...' },
      RG: '',
      TelRG: '',
      idIncidencia: { label: 'Seleccione', value: 0 },
      isResuelto: {label:"tipo",value:3},
      Clasificación: '',
    };
    setFiltro(_filtro);
    Datos(_filtro);
  };

  return (
    <Card className="card-plain">
      <CardBody>
        <div
          aria-multiselectable={true}
          className="card-collapse"
          id="accordion"
          role="tablist"
        >
          <Card className="card-plain">
            {!loading?
            <CardHeader role="tab" className="text-left">
              <a
                aria-expanded={collapse.openedCollapses.includes('collapse3')}
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={() => collapsesToggle('collapse3')}
                className="text-left"
              >
                <Button
                  className="btn-icon btn-neutral"
                  outline
                  size="sm"
                  id="EditUser"
                >
                  <i
                    className="fa fa-filter fa-3x"
                    color="danger"
                    aria-hidden="true"
                  ></i>
                </Button>
                Filtro
              </a>
            </CardHeader>: 
            	<CircleSpinner
											className="text-center"
											size={30}
											color="#686769"
											loading={loading}
									/>}
            <Collapse
              role="tabpanel"
              isOpen={collapse.openedCollapses.includes('collapse3')}
            >
              <CardBody>
                <Row>
                  <Col sm="3" className="text-muted">
                    Distrito federal:
                    <Select
                      options={catDFFilter}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={dataCombos.dDF}
                      onChange={(e) => changeDF(e)}
                      id="viewTypeM"
                      placeholder="Filtrar por"
                      isLoading={loading}
                      loadingMessage="Cargando filtro"
                    />
                  </Col>
                  <Col sm="3" className="text-muted">
                    Distrito local:
                    <Select
                      options={catDLFilter}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={dataCombos.dDL}
                      onChange={(e) => changeDL(e)}
                      id="viewTypeM"
                      placeholder="Filtrar por"
                      isLoading={loading}
                      loadingMessage="Cargando filtro"
                    />
                  </Col>
                  <Col sm="3" className="text-muted">
                    Municipio:
                    <Select
                      options={catMunicipiotFilter}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={dataCombos.dMunicipio}
                      onChange={(e) => changeMunicipioX(e)}
                      id="viewTypeM"
                      placeholder="Filtrar por"
                      isLoading={loading}
                      loadingMessage="Cargando filtro"
                    />
                  </Col>{/* 
                  <Col sm="3" className="text-muted">
                    Poligono:
                    <Select
                      options={catPoligonostFilter}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={dataCombos.dPoligono}
                      onChange={(e) => changePoligonoX(e)}
                      id="viewTypeS"
                      placeholder="Filtrar por"
                      isLoading={loading}
                      loadingMessage="Cargando filtro"
                    />
                  </Col> */}
                  <Col sm="3" className="text-muted">
                    Sección:
                    <Select
                      options={catSecciontFilter}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={dataCombos.dSeccion}
                      onChange={(e) => changeSeccionX(e)}
                      id="viewTypeS"
                      placeholder="Filtrar por"
                      isLoading={loading}
                      loadingMessage="Cargando filtro"
                    />
                  </Col>
                  <Col sm="3" className="text-muted">
                    Tel RG:
                    <Input
                      id="TelRG"
                      value={filtro.TelRG}
                      onChange={(e) =>
                        setFiltro({ ...filtro, TelRG: e.target.value })
                      }
                    />
                  </Col>
                  <Col sm="6" className="text-muted">
                    Casilla:{' '}
                    <Input
                      id="Casilla"
                      value={filtro.Casilla}
                      onChange={(e) =>
                        setFiltro({ ...filtro, Casilla: e.target.value })
                      }
                    />
                  </Col>
                  <Col sm="5" className="text-muted">
                    RG:
                    <Input
                      id="RG"
                      value={filtro.RG}
                      onChange={(e) =>
                        setFiltro({ ...filtro, RG: e.target.value })
                      }
                    />
                  </Col>

                  <Col sm="3" className="text-muted">
                    Estatus:
                    <Select
                      options={CatEstatus}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={filtro.Estatus}
                      onChange={(e) => setFiltro({ ...filtro, Estatus: e })}
                      id="viewTypeS"
                      placeholder="Filtrar por"
                      isLoading={loading}
                      loadingMessage="Cargando filtro"
                    />
                  </Col>
                  <Col sm="3" className="text-muted">
                    Solucionado:
                    <Select
                      options={CatSolucionado}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={filtro.isResuelto}
                      onChange={(e) => setFiltro({ ...filtro, isResuelto: e })}
                      id="viewTypeS"
                      placeholder="Filtrar por"
                      isLoading={loading}
                      loadingMessage="Cargando filtro"
                    />
                  </Col>
                </Row>
                <Row>
                <Col sm="3" className="text-muted">
                    Incidencia:
                    <Select
                      options={catIncidencia}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={filtro.idIncidencia}
                      onChange={(e) => setDataCombo({ ...filtro, idIncidencia: e })}
                      id="viewTypeS"
                      placeholder="Filtrar por"
                      isLoading={loading}
                      loadingMessage="Cargando filtro"
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  size="sm"
                  id="btnfiltro"
                  color="primary"
                  className="primary"
                  onClick={(e) => setFilter()}
                >
                  <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                  Filtrar
                </Button>{' '}
                <Button
                  size="sm"
                  id="btnfiltro"
                  color="danger"
                  className="danger"
                  onClick={(e) => ClearFilter()}
                >
                  <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                  Limpiar
                </Button>
              </CardFooter>
            </Collapse>
          </Card>
        </div>
      </CardBody>
    </Card>
  );
}
export default IndexCasillasIncidencias;
