import React, { useEffect, useState } from 'react';
import {
  Button,
  CardFooter,
  Input,
  Col,
  Card,
  Spinner,
  Row,
  CardHeader,
  CardTitle,
  Label,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Table,
} from 'reactstrap';

import InfiniteScroll from 'react-infinite-scroll-component';

const Partidos = (props) => {
  const {
    partidos,
    partidosSeleccionados,
    setPartidosSeleccionados,
    isOpen,
    setIsOpen,
    isLoading,
    partidosSeleccionadosString,
    setPartidosSeleccionadosString,
    guardarCoalision,
    isLoadingAsignacion
  } = props;
  const [partidosList, setPartidosList] = useState([]);
  const style = {
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      maxHeight: '10rem',
      left: '50%',
      padding: '2rem',
      position: 'fixed',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '20rem',
      width: '90%',
      maxWidth: '60rem',
    },
  };

  useEffect(() => {
    setPartidosList(partidos);
  }, [partidos]);

  const handleBusquedaPartido = (value) => {
    setPartidosList(
      partidos.filter((partido) => {
        return partido.Partido.toLowerCase().includes(value.toLowerCase());
      })
    );
  };

  const handleSeleccionPartido = (partido) => {
    // let partidosSorted = JSON.parse(JSON.stringify(partidosSeleccionados));
    setPartidosSeleccionados([...partidosSeleccionados, partido])
    // partidosSorted.push(partido);
    // setPartidosSeleccionados(
    //   partidosSorted.sort((a, b) =>
    //     a.OrdenPrelacion.toString().localeCompare(b.OrdenPrelacion.toString())
    //   )
    // );
  };

  const handleQuitarPartido = (partido) => {
    let listaTemporal = partidosSeleccionados.filter(
      (partidoSelected) => partidoSelected !== partido
    );
    setPartidosSeleccionados(listaTemporal);
  };

  const handleAceptarSeleccion = () => {
    let partidosString = "";
    partidosSeleccionados.map(partido => {
        partidosString = `${partidosString} ${partido.Partido},`
    })
    setPartidosSeleccionadosString(partidosString)
    guardarCoalision()
    // setIsOpen(false)
  }

  const handleCancelar = () => {
    setPartidosSeleccionados([])
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      style={style}
      toggle={(e) => setIsOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader toggle={(e) => setIsOpen(false)}>
        Asignación de <strong>Partidos para la coalición</strong>
      </ModalHeader>
      <ModalBody>
        {isLoading && <Spinner color="primary" />}
        {!isLoading && (
          <>
            <Row>
              <Col md="8">
                <Card>
                  <CardHeader>
                    <FormGroup>
                      <Label for="search">Buscar partido</Label>
                      <Input
                        id="search"
                        onChange={(e) => handleBusquedaPartido(e.target.value)}
                      />
                    </FormGroup>
                  </CardHeader>
                  <CardBody>
                    {!partidosList && (
                      <h2 style={{ textAlign: 'center' }}>
                        No existen partidos
                      </h2>
                    )}
                    {partidosList && (
                      <InfiniteScroll
                        height={400}
                        dataLength={partidosList.length}
                      >
                        <Table>
                          <thead>
                            <tr>
                              <th width="60%">Partido</th>
                              <th width="5%"></th>
                              <th width="35%">Seleccionar</th>
                            </tr>
                          </thead>
                          <tbody>
                            {partidosList.map((partido, index) => {
                              return (
                                <tr key={index}>
                                  <td width="60%">{partido.Partido}</td>
                                  <td width="35%">
                                    {partidosSeleccionados.length > 0 &&
                                    partidosSeleccionados.find(
                                      (partidoSelected) =>
                                        partidoSelected.id === partido.id
                                    ) ? (
                                      <Label>Seleccionado</Label>
                                    ) : (
                                      <Button
                                        className="btn btn-primary btn-sm"
                                        color="warning"
                                        size="sm"
                                        onClick={() =>
                                          handleSeleccionPartido(partido)
                                        }
                                      >
                                        Seleccionar
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardHeader>
                    <Label>Partidos seleccionados</Label>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      {partidosSeleccionados.map((partido, index) => {
                        return (
                          <li key={index}>
                            {partido.Partido}{' '}
                            <Button
                              className="btn btn-primary btn-sm"
                              color="danger"
                              size="sm"
                              onClick={() => handleQuitarPartido(partido)}
                            >
                              x
                            </Button>
                          </li>
                        );
                      })}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary btn-sm align-center"
          color="danger"
          size="sm"
          onClick={handleCancelar}
        >
          Cancelar
        </Button>
        <Button
          className="btn btn-primary btn-sm align-center"
          color="primary"
          size="sm"
          onClick={handleAceptarSeleccion}
          disabled={partidosSeleccionados.length === 0}
        >
          Aceptar
        </Button>
        {isLoadingAsignacion && <Spinner color="primary" />}
      </ModalFooter>
    </Modal>
  );
};

export default Partidos;
