import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row,Col,Collapse,Input,FormGroup,Label, } from 'reactstrap';
import Select from "react-select";

import { CircleSpinner } from "react-spinners-kit";

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";

function IndexCasaAzul ({Menu,Datos}){
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
	const [filtro,setFiltro] = useState({
		Region:{},
		Municipio:{},
		Poligono:{},
		Seccion:{},
        Direccion:'',
        Localidad:'',
        Colonia:'',
        Dueno:'',
        CelularDueno:'',
        Internet:0
	})
	
    const [collapse, setCollapse] = useState({
		horizontalTabs: "home",
		verticalTabs: "info",
		pageTabs: "homePages",
		openedCollapses: ["collapseOne", "collapse1"]
    });
    
    //const [catSexo, setCatSexo] = useState([]);
    //const [catVoluntario, setCatVoluntario] = useState([]);
    //const [catSituacionLaboral, setSituacionLaboral] = useState([]);
    //const [catNivelEstudios, setNivelEstudios] = useState([]);
    
    //const [catRegionest, setCatRegionest] = useState([]);
    const [catRegionestFilter, setCatRegionestFilter] = useState([])
    const [catPoligonost, setCatPoligonost] = useState([])
    const [catPoligonostFilter, setCatPoligonostFilter] = useState([])
    const [dataCombos, setDataCombo] = useState({ 
        dRegion: { label: 'Seleccione', value: 0 }, 
        dMunicipio: { label: 'Seleccione', value: 0 }, 
        dPoligono: { label: 'Seleccione', value: 0 },
        dSeccion: { label: 'Seleccione', value: 0 } ,
        dPropuesta: { label: 'Seleccione', value: 0 }} )
    const [catMunicipiotFilter, setCatMunicipiotFilter] = useState([])
    const [catMunicipiot, setCatMunicipiot] = useState([])
    const [catSecciont,setCatSecciont]=useState([])
    const [catSecciontFilter,setCatSecciontFilter] = useState([])
    //const [catCasilla,setcatCasilla]=useState([{value:0,label:'Debe validar la sección'}]);
    //const [catPropietario,setcatPropietario]=useState([{value:0,label:'Debe validar la sección'}]);
    useEffect(()=>{
        setLoading(true);
        EjecutarApi({}, "catCompromisosWeb", token.access_token, "GET")
        .then(function (res) {
            return res;
        })
        .then((items) => {
            if (items.results) {
          
            setLoading(false);
            } 
            setLoading(false);
        })
        .catch((e) => {
            setLoading(false);
          
        });
        EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token, "POST")
        .then(res => {
          if(res.results)
            if (res.data) {
                let regiones = [{ value: 0, label: 'Todos' }]
                let regionesFormated = res.data.regiones.map(item => {
                    return {
                    value: item.value,
                    label: item.label
                    }
                })
                setCatRegionestFilter(regiones.concat(regionesFormated));

                let municipios = [{ value: 0, label: 'Seleccione' }]
                let municipiosFormated = res.data.municipios.map(item => {
                    return {
                    value: item.value,
                    label: item.label,
                    idRegion: item.idRegion,
                    }
                })
                setCatMunicipiot(municipios.concat(municipiosFormated));
                setCatMunicipiotFilter(municipios.concat(municipiosFormated));

                let poligonos = [{ value: 0, label: 'Seleccione' }]
                let poligonosFormated = res.data.poligonos.map(item => {
                    return {
                        value: item.value,
                        label: item.label,
                        idMunicipio: item.idMunicipio,
                        idRegion: item.idRegion,
                    }
                })
                setCatPoligonost(poligonos.concat(poligonosFormated));
                setCatPoligonostFilter(poligonos.concat(poligonosFormated));

                let seccion = [{ value: 0, label: 'Seleccione' }]
                let seccionFormated = res.data.secciones.map(item => {
                    return {
                        value: item.value,
                        label: item.label,
                        idRegion: item.idRegion,
                        idMunicipio: item.idMunicipio,
                        idPoligono: item.idPoligono
                    }
                })
                setCatSecciont(seccion.concat(seccionFormated));
                setCatSecciontFilter(seccion.concat(seccionFormated));
            }
        })
        .catch((e) => {
        }).then(() => {
          setLoading(false);
        })
        EjecutarApi({}, "getCatalogosPorResponsabilidad", token.access_token, "POST")
        .then(res => {
          if(res.results)
            if (res.data) {
            }
        })
        .catch((e) => {
        }).then(() => {
          setLoading(false);
        })
    },[])
     
    const collapsesToggle = (colapse) => {
		let openedCollapses = collapse.openedCollapses;
		if (openedCollapses.includes(colapse)) {
		  setCollapse({...collapse,
			openedCollapses: openedCollapses.filter(item => item !== colapse)
		  });
		} else {
		  openedCollapses.push(colapse);
		  setCollapse({...collapse, 
			openedCollapses: openedCollapses
		  });
		}
    };
    const changeRegionX = (dRegion) => {
    
        setLoading(true);
    
        if(dRegion.value>1)
        {
          setDataCombo({ ...dataCombos, dRegion, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });
    
    
          setCatMunicipiotFilter(catMunicipiot.filter(data => data.idRegion === dRegion.value));
      
          setCatPoligonostFilter(catPoligonost.filter(data => data.idRegion === dRegion.value));
        }else{
          setDataCombo({ ...dataCombos, dRegion:{ label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });
    
    
          setCatMunicipiotFilter(catMunicipiot);
      
          setCatPoligonostFilter(catPoligonost);
        }
    
        
    
        setLoading(false);
    
    }
    const changeMunicipioX = (dMunicipio) => {
        setLoading(true);
        if(dMunicipio>1)
        {
            setDataCombo({ ...dataCombos, dMunicipio:dMunicipio, dPoligono: { label: 'Seleccione', value: 0 } , dSeccion:{ label: 'Seleccione', value: 0 } });
            setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idMunicipio) === dMunicipio.value));
        }else
        {
            setDataCombo({ ...dataCombos,  dMunicipio:dMunicipio, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion:{ label: 'Seleccione', value: 0 } });
            setCatSecciontFilter(catSecciont);
            setCatPoligonostFilter(catPoligonost);
        }
         setLoading(false);
      }
    
      const changePoligonoX = (dPoligono) => {
        setLoading(true);
        setDataCombo({ ...dataCombos, dPoligono });
        setLoading(false);
      }
      const changeSeccionX =(dSeccion)=>{
            setLoading(true);
            setDataCombo({ ...dataCombos, dSeccion });
            setLoading(false);
            EjecutarApi({"seccion":dSeccion.value}, 'getCasillasBySeccion', token.access_token)
                    .then(function(res) {
                    
                        return res;
                    })
                    .then((res) => {
                        if(res.success)
                        {
                           // setcatCasilla(res.data);
                            setLoading(false);
                        }
                        
                    })
                    .catch((e) => {
                        setLoading(false);
                        
                    });
                    //getCasillaPropietario 
                    EjecutarApi({"seccion":dSeccion.value}, 'getCasillaPropietario', token.access_token)
                    .then(function(res) {
                        return res;
                    })
                    .then((res) => {
                        if(res.success)
                        {
                            //setcatPropietario(res.data);
                            setLoading(false);
                        }
                        
                    })
                    .catch((e) => {
                        setLoading(false);
                        
                    });
      }
    const setFilter=(event)=>{
        
        var filtrar = {
            Region:dataCombos.dRegion,
            Municipio:dataCombos.dMunicipio,
            Poligono:dataCombos.dPoligono,
            Seccion:dataCombos.dSeccion,
            Direccion:filtro.Direccion,
            
            Colonia:filtro.Colonia,
            Dueno:filtro.Dueno,
            CelularDueno:filtro.CelularDueno,
            Internet:filtro.Internet
            
        }
        Datos(filtrar);
    }
    const ClearFilter=()=>{
        setDataCombo({ ...dataCombos, dRegion:{ label: 'Seleccione', value: 0 },
        dMunicipio: { label: 'Seleccione', value: 0 },
        dPoligono: { label: 'Seleccione', value: 0 }, 
        dSeccion:{ label: 'Seleccione', value: 0 }  });
        
        setFiltro({
            Region:{},
            Municipio:{},
            Poligono:{},
            Seccion:{},
            Direccion:'',
            Localidad:'',
            Colonia:'',
            Dueno:'',
            CelularDueno:'',
            Internet:0
        })
        

    }
    return (
        <Card className="card-plain">
            <CardBody>
            <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                role="tablist"
            >
                
                <Card className="card-plain">
                <CardHeader role="tab">
                    <a
                        aria-expanded={collapse.openedCollapses.includes(
                            "collapse3"
                          )}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle("collapse3")}
                        className="text-right"
                    >
                    <i class="fa fa-filter fa-lg" aria-hidden="true"></i>{" "}
                    Filtro
                    </a>
                </CardHeader>
                <Collapse
                    role="tabpanel"
                    isOpen={collapse.openedCollapses.includes(
                        "collapse3"
                      )}
                >
                    <CardBody>
                  
                      {Menu.CasaAzul && (<> 
                        <Row>
                            <Col sm="3" className="text-muted">Región: 
                            {loading && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                />
                                )}            {!loading && (<Select
                                options={catRegionestFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={dataCombos.dRegion}
                                onChange={(e) => changeRegionX(e)}
                                id="viewTypeR"
                                placeholder="Filtrar por"
                                />)}
                            </Col>
                            <Col sm="3" className="text-muted">Municipio: 
                            {loading && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                />
                                )}            {!loading && (<Select
                                options={catMunicipiotFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={dataCombos.dMunicipio}
                                onChange={(e) => changeMunicipioX(e)}
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                />)}
                            </Col>
                            <Col sm="3" className="text-muted">Polígono:
                            {loading && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                />
                                )}            {!loading && (<Select
                                options={catPoligonostFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={dataCombos.dPoligono}
                                onChange={(e) => changePoligonoX(e)}
                                id="viewTypeP"
                                placeholder="Filtrar por"
                                />)}
                            </Col>
                            <Col sm="3" className="text-muted">Sección:
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                    )}            {!loading && (<Select
                                    options={catSecciontFilter}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={dataCombos.dSeccion}
                                    onChange={(e) => changeSeccionX(e)}
                                    id="viewTypeS"
                                    placeholder="Filtrar por"
                                />)}
                            </Col>
                            <Col sm="3" className="text-muted">Dirección:<Input id="icveElector" value={filtro.Direccion} onChange={(e)=>setFiltro({...filtro, Direccion:e.target.value})} /></Col>
                            <Col sm="3" className="text-muted">Colonia:<Input id="ipaterno" value={filtro.Colonia} onChange={(e)=>setFiltro({...filtro, Colonia:e.target.value})} /></Col>
                            <Col sm="3" className="text-muted">Nombre Dueño:<Input id="" value={filtro.Dueno} onChange={(e)=>setFiltro({...filtro,Dueno:e.target.value})} /></Col>	
                            <Col sm="3" className="text-muted">Celular Dueño:<Input id="" value={filtro.CelularDueno} onChange={(e)=>setFiltro({...filtro,CelularDueno:e.target.value})} /></Col>	
                        
                            <FormGroup check inline>
                                <br/>
                                <Label check>
                                    <Input  type="checkbox" name='chek1' checked={filtro.Funcionario} onClick={(e)=>setFiltro({...filtro, Funcionario:e.target.checked})} />
                                    <span className="form-check-sign" />Internet
                                </Label>
                            </FormGroup>{" "}
                            
                          
                        </Row>
                      </>)}  
                     </CardBody>
                    <CardFooter>
                        <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e)=>setFilter()}>
                            <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                            Filtrar
                        </Button>{' '}
                        <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e)=> ClearFilter()}>
                            <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                            Limpiar
                        </Button>
                    </CardFooter>
                </Collapse>
                </Card>
            </div>
            </CardBody>
        </Card>
    );
};
export default IndexCasaAzul;