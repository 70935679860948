import React, { useState,useEffect } from 'react';
import { InputGroup,InputGroupAddon,InputGroupText,Row,Col, Input, Button,  CardBody,Card, CardTitle, CardHeader,Table} from 'reactstrap';
import { notify } from "react-notify-toast";
import {RotateSpinner} from "react-spinners-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA } from "../../GlobalStorage";
import {getDataMenu} from '../../data/menus.js';

const Coordinadores = ({Data,onHide}) => {
    const [loading, setLoading] = useState(false);
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    const [total,setTotal] = useState(0) 
    const [HasMore,setHasMore]=useState(true)
    const [nextFetch,setnextFetch]=useState(1); 
    const [DatosResponsable,setDatosResponsable] = useState([]);
    const [DetalleResponsable,setDetalleResponsable] = useState({data:{user:[],totalCompromisosSegmentos:[],totalCompromisos:[],enlaces:[]}});
    const [Responsable,setResponsable] = useState({Nombre:'',Responsabilidad:'',ResponsabilidadPrincipal:''});
    const [Busqueda,setBusqueda]=useState("")
    
    useEffect(()=>{
        _setmenu(getDataMenu(23,token.menus));
        handleBuscar()
    },[])
  
    const fetchHasMore = () => {
      if (nextFetch * 10 >= total) {
        setHasMore (false);
       return;
      }
      setHasMore(true);
      
      
      var parametro={
        idEstructura:Data.Estructura,
        page:nextFetch, 
        pageSize:10,
        Exportar:Data.Tipo
      };
      EjecutarApi(parametro, 'getListadoCoordinadoresPorEstructuraWeb', token.access_token)
          .then(function (res) {
          return res;
          })
          .then((res) => {
              if (res.results) {
                      setDatosResponsable(DatosResponsable.concat(res.data))
                      setTotal(res.total)
                      setLoading(false)
                      setnextFetch(nextFetch+1);
                      if ((nextFetch+1) * 10 >= total) {
                        setHasMore (false);
                      }
              }else {
                      //showMessage("No Info: catCompromisos")
                      setLoading(false)
              }})
          .catch((e) => {
            setLoading(false);
            notify.show(`Error en el listado: ${e.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
        });
    
    };
    const handleBuscar =()=>{
    setLoading(true);
    var parametro={
        idEstructura:Data.Estructura,
        page:0, 
        pageSize:10,
        Exportar:Data.Tipo,
        fullSearch:Busqueda?Busqueda:""
    };

    EjecutarApi(parametro, 'getListadoCoordinadoresPorEstructuraWeb', token.access_token)
        .then(function (res) {
        return res;
        })
        .then((res) => {
            if (res.results) {
                    setDatosResponsable(res.data)
                    setTotal(res.totales)
                    setLoading(false)
               
            }})
        .catch((e) => {
            setLoading(false);
            notify.show(`Error en el listado: ${e.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
        });
    
    
    }
    var DetalleUsuario = (item)=>{
          var parametro ={
            "idUser":item.id,"idEstructura":Data.Estructura,
          }
          setResponsable({Nombre:item.Nombre,Responsabilidad:item.ResponsabilidadData})
          EjecutarApi(parametro, 'getStatsDetalleCoordinador', token.access_token)
          .then(function (res) {
          return res;
          })
          .then((res) => {
              if (res.results) {
                  setDetalleResponsable(res)
                  
                  setLoading(false)
              }else {
                notify.show(`No existe información para mostrar!!!!`, "error", 5000, {
                  background: "#d61818",
                  text: "#FFFFFF",
              });
                      setLoading(false)
              }})
          .catch((e) => {
            setLoading(false);
            notify.show(`Error en el listado: ${e.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
        });
    }
    const Exportar = (idEstructura) => {
          var url = new URL(URL_PUBLICA+
            `getReporteEstructuraSocialDetalle?token=${token.access_token}`
          );
          window.open(url, '_blank');
    };
    return(
        <> 
            <Col md="12">
                <Row>
                    <Col  md="12" >
                        <Button size="sm" color='primary' onClick={()=> onHide()}>Regresar</Button>
                        <Button size="sm" color='success' onClick={()=> Exportar()}>Exportar</Button>
                    </Col>
                    
                </Row>
                <Row>
                    <Col  md='12' className="text-center" >
    <h4>Listado de Coordinadores de la Estructura {Data.Data.NombreEstructura}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <InputGroup className="no-border">
                        <Input value={Busqueda} onChange={(e)=>setBusqueda(e.target.value)} placeholder="Buscar..." type="text" />
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                            <i className="nc-icon nc-zoom-split" />
                            </InputGroupText>
                        </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col sm="2" className='text-align-bottom-left' ><Button size='sm' color='warning' onClick={()=>handleBuscar()}><i className="nc-icon nc-zoom-split" /></Button></Col>
                </Row>
                <Row>
                        <Col sm="6">
                          Listado : {DatosResponsable.length} de {total}
                        </Col>
                    </Row>
                <Row>{total>0&&(<>
                    
                    <Col sm="6">
                            <RotateSpinner color={"#1261ff"}size={30} loading={loading}/>
                            <InfiniteScroll
                                  dataLength={total}
                                  next={fetchHasMore}
                                  hasMore={HasMore}
                                  loader={<h5>cargando...</h5>}
                                  height={500}
                                  endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b>¡Final de la lista de registros!</b>
                                        </p>}
                              >
                             
                                {DatosResponsable.map((item,key)=>
                                    
                                    <div key={'key'+key.toString()} className="divv text-muted" onClick={()=>DetalleUsuario(item)} > 
                                      <Card>
                                        <CardBody className="card-body">
                                            
                                            <strong>{item.Nombre}</strong>
                                            {/* <br/>
                                            Celular:{item.email} */}
                                            <br/>
                                            Municipio:{item.Municipio}
                                            <br/>
                                            Estructura:{item.nickName}
                                            <br/>
                                            <strong>{item.ResponsabilidadData}</strong>
                                            <br/>
                                        </CardBody>
                                      </Card>
                                      <hr />
                                    </div>
                                )}  
                            </InfiniteScroll>             
                    </Col>
                    <Col sm="6">
                        <Card>
                            <CardBody className="card-body">
                                <Row>
                                    <Col sm="12" className='text-center'>
                                        <strong>{Responsable.Nombre}</strong>
                                        <p className="card-category">
                                            {Responsable.Responsabilidad}
                                        </p>
                                        <p className="card-category">
                                          {DetalleResponsable.data.user?DetalleResponsable.data.user.ResponsabilidadSecundaria:''}
                                        </p>
                                       
                                    </Col>
                                </Row>
                                
                                <hr/>
                                <Row>
                                    <Col sm="12" className='text-center text-muted'>
                                        <h6>Total Compromisos</h6> 
                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6" className='text-center card-category'>{DetalleResponsable.data.totalCompromisosSegmentos?DetalleResponsable.data.totalCompromisosSegmentos.total:''}</Col>
                                    <Col sm="6" className='text-center card-category'>{DetalleResponsable.data.totalCompromisos?DetalleResponsable.data.totalCompromisos.total:''}</Col>
                                </Row>
                                <Row>
                                    <Col sm="6" className='text-center card-category'><strong>Segmento</strong></Col>
                                    <Col sm="6"className='text-center card-category' ><strong>General</strong></Col>
                                </Row>
                                <Row>
                                    <Col sm="12" className='text-center text-muted'>
                                  
                                        <CardHeader>
                                          <CardTitle tag="h6">Enlaces Asignados</CardTitle>
                                        </CardHeader>
                                      
                                          <Row>
                                            <Col md="12">
                                              <Table responsive>
                                                <tbody>
                                                <tr>
                                                    <td className="text-center">Celular</td>
                                                    <td className="text-center">Nombre</td>
                                                    <td className="text-center">Compromisos</td>
                                                    <td className="text-center">Segmento</td>
                                                  </tr>
                                                  {DetalleResponsable.data.enlaces.length>0?(<>
                                                    {DetalleResponsable.data.enlaces.map((item)=>
                                                      <tr>
                                                        <td>{item.Celular}</td>
                                                        <td>{item.Nombre}</td>
                                                        <td className="text-center">{item.Compromisos}</td>
                                                        <td className="text-center">{item.Segmento}</td>
                                                      </tr>
                                                      )}
                                                  </>):(<></>)

                                                  }
                                                </tbody>
                                              </Table>
                                            </Col>
                                           
                                          </Row>
                                     
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </>)}
                </Row>
            </Col>
        </>  
    );
};
export default Coordinadores;