import React, { useEffect, useState, useRef,useCallback } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  Label,
  Badge
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import EjecutarApi from "data/getPOST";
import { getVars,URL_PUBLICA_REPORTE,numberWithCommas } from "GlobalStorage";
import { getDataMenu } from "data/menus";
import bloqueado from 'data/bloqueado.json';
import PerfectScrollbar from "perfect-scrollbar";
import catMunicipioReportes from '../../data/CallCenter/catMunicipioReportes.json';
import catPoligonoMunicipio from '../../data/CallCenter/catPoligonoMunicipio.json';
import ReactTable from "react-table";
import Pagination from "components/Pagination/pagination";
import ChartMultipleColums from 'components/Charts/Sije/ChartMultipleColums';
import LineaTiempo from 'components/Charts/Tablero/ChartRezagoCaptura';
import Filter from 'components/Filtro/indexMovilizacionDashboard.';

var ps;
const MovilizacionDashboard = (props) => {
  const mainPanel = useRef();
  const [state, setState] = useState({ Region: { value: null, label: 'Todas' }, 
                                        Municipio: { value: null, label: 'Todas' }, 
                                        Poligono: { value: null, label: 'Todas' } ,
                                        Seccion: { value: null, label: 'Todas' },
                                        Manzana: { value: null, label: 'Todas' },
                                        DistritoLocal: { value: null, label: 'Todas' },
                                        DistritoFederal: { value: null, label: 'Todas' }});
  const [isBloqueado, setIsBloqueado] = useState(false);
  const [cmbVista] = useState([{ value: 1, label: 'Estatal' }
                              ,{ value: 2, label: 'Distrito Local'}
                              ,{ value: 3, label: 'Distrito Federal' } ]);
  const [valuVista, setvalueVista] = useState({ value: 1, label: 'Estatal' });
  const [cmbRegion, setCmbRegion] = useState([{ value: null, label: 'Todas' }]);
  const [cmbMunicipio, setCmbMunicipio] = useState([{ value: null, label: 'Seleccione' }]);
  const [cmbPoligono, setCmbPoligono] = useState([{ value: null, label: 'Seleccione' }]);
  const [cmbSeccion, setCmbSeccion] = useState([{ value: null, label: 'Seleccione' }]);
  const [cmbTipo] = useState([{ value: null, label: 'Seleccione' },{ value: 'SWITCH', label: 'AMARILLO' },{ value: 'PERDIBLE', label: 'ROJO' },{ value: 'DURO', label: 'VERDE' }]);
  const [cmbDL, setCmbDL] = useState([{ value: null, label: 'Seleccione' }]);
  const [cmbDF, setCmbDF] = useState([{ value: null, label: 'Seleccione' }]);
  const [catSeccion, setCatSeccion] = useState([]);
  const [catManzanas, setCatSManzanas] = useState([]);
  const [catDL, setCatDL] = useState([]);
  const [catDF, setCatDF] = useState([]);
  const [_menu, _setmenu] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingC2, setLoadingC2] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const token = getVars("Token");

  const [pages,setPage]=useState(0);
  const [pageSize,setPageSize]=useState(10);

  const [series, setSeries] = useState([]);
  const [Titulos,setTitulos] = useState([]);
  const [isViewChart, setisViewChart] = useState(false);
  const [serieLineaTiempo,setSerieLineaTiempo] = useState([]);
  const [categoriaLineaTiempo,setCategoriaLineaTiempo] = useState([]);
  const [TituloLineaTiempo,setTituloLineaTiempo] = useState("");
  const [isLineaChart,setIsLineaChart] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(true)
  const [Filtro, setFiltro] = useState([]);
  const [Parametros, setParametros] = useState({
    idRegion: null,
    idDL: null,
    idDF: null,
    idMunicipio: null,
    idPoligono: null,
    Seccion: null,
    tipoVista: 'Estatal'
  });
  const [serieMovilizacionDispersa,setSerieMovilizacionDispersa] = useState([]);
  const [categoriaMovilizacionDispersa,setCategoriaMovilizacionDispersa] = useState([]);
  const [TituloMovilizacionDispersa,setTituloMovilizacionDispersa] = useState("");
  const [isDispersaChart,setIsDispersaChart] = useState(false);
  const [total, setTotal] = useState(0);
  const [vista,setVista]=useState({
        Region:false,
        Municipio:false,
        Poligono:false,
        Seccion:false,
        Tipo:false,
        DistritoFederal:false,
        DistritoLocal:false
  });
  const styleHeader = {
    textAlign: "center",
    overflow: 'visible',
    fontSize: '11px',
    color: '#363636',
    lineHeight: 'normal !important',
  };
  const [dataSerie, setDataSerie] = useState([]);
  const [categorias, setCategorias] = useState([]);
const [Colors, setColors] = useState([
  '#00A0DF',
  '#FFB600',
  '#005CB9',
  '#00df98',
  '#d40f75',
  
]);
  const [optionsChart, setOptionsChart] = useState({
    Title: 'Bingo',
    //Subtitle: 'Vista por Municipios',
  });
  const [hora,setHora] = useState("");
  const [tabla,setTabla]=useState([]);

  useEffect(() => {
    _setmenu(getDataMenu(178, token.menus));
    setLoading(true);
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    if (props.history.action === "PUSH") {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    ///
    let encontrado = bloqueado.data.filter(item => item.value === token.user.id);

    if (encontrado.length > 0) {
      setIsBloqueado(true)
    }
    var  today = new Date();
    setHora(today.getHours()+":"+today.getMinutes()+":"+today.getSeconds());

    // console.log('=getCatalogosFiltrosPromocionClonIdMunicipioReportes: ', JSON.stringify({}));
    
      let parametro = {
          idRegion: null,
          idDL: null,
          idDF: null,
          idMunicipio: null,
          idPoligono: null,
          Seccion: null,
          tipoVista: 'Estatal'
      }
      const data = {
        page:0,pageSize:10,filtered:[]
      };
      DatosChart(data);
      getChartData(parametro);
      ChartMovilizacionAcumulativa(parametro);
  }, []);

  
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };


  const changeRegion = (Region) => {
      setLoading(true);
      setState({ ...state, Region, 
        Municipio: { value: null, label: 'Todos' }, 
        Poligono: { value: null, label: 'Todos' },
        Seccion: { value: null, label: 'Todos' }, 
        Manzana: { value: null, label: 'Todos' } });
      const itemfiltroM = catMunicipioReportes.data.filter(item => parseInt(item.idRegion) === parseInt(Region.value));
      const itemfiltroP = catPoligonoMunicipio.data.filter(item => parseInt(item.idRegion) === parseInt(Region.value));
      const itemfiltroS = catSeccion.filter(item => parseInt(item.idRegion) === parseInt(Region.value));
      setCmbMunicipio(itemfiltroM);
      setCmbPoligono(itemfiltroP);
      setCmbSeccion(itemfiltroS);
      let parametro = {
          idRegion: Region.value,
          idDL: null,
          idDF: null,
          idMunicipio: null,
          idPoligono: null,
          Seccion: null,
          idManzana:null,
          tipoVista: valuVista.label
      }
      
      DatosChart(parametro);
      getChartData(parametro);
      ChartMovilizacionAcumulativa(parametro);
      const data = {
        page:pages,pageSize:pageSize,filtered:[{"id":"a.idRegion", "value":Region.value}]
      };
      //setFiltro([...Filtro, data.filtered]);
      getRegistros(data);

      setLoading(false);
  }

  const changeMunicipio = (Municipio) => {
      setLoading(true);
      setState({ ...state, Municipio
                ,Poligono: { value: null, label: 'Todos' }
                ,Seccion: { value: null, label: 'Todos' }
             });
      const itemfiltroP = catPoligonoMunicipio.data.filter(item => parseInt(item.idMunicipioReportes) === parseInt(Municipio.value));
      const itemfiltroS = catSeccion.filter(item => parseInt(item.idMunicipioReportes) === parseInt(Municipio.value));
      setCmbPoligono(itemfiltroP);
      setCmbSeccion(itemfiltroS);
      let parametro = {
          idRegion: state.Region.value,
          idDL: null,
          idDF: null,
          idMunicipio: Municipio.value,
          idPoligono: null,
          Seccion: null,
          idManzana:null,
          tipoVista: valuVista.label
      }
      DatosChart(parametro);
      getChartData(parametro);
      ChartMovilizacionAcumulativa(parametro);
      const data = {
        page:pages,pageSize:pageSize,filtered:[{"id":"a.idMunicipioReportes", "value":Municipio.value}]
      };
      //setFiltro([...Filtro, data.filtered]);
      getRegistros(data);
      setLoading(false);
  }
  const changePoligono = (Poligono) => {
    setLoading(true);
    setState({ ...state, Poligono });
    const itemfiltroS = catSeccion.filter(item => parseInt(item.idPoligono) === parseInt(Poligono.value));
    setCmbSeccion(itemfiltroS);
    let parametro = {
        idRegion: state.Region.value,
        idDL: null,
        idDF: null,
        idMunicipio: state.Municipio.value,
        idPoligono: Poligono.value,
        Seccion: null,
        idManzana:null,
        tipoVista: valuVista.label
    }
    DatosChart(parametro);
    getChartData(parametro);
    ChartMovilizacionAcumulativa(parametro);
    const data = {
      page:pages,pageSize:pageSize,filtered:[{"id":"a.idPoligono", "value":Poligono.value}]
    };
    //setFiltro([...Filtro, data.filtered]);
    getRegistros(data);
    setLoading(false);
    
  }
  const changeSecciones = (Seccion) => {
    setLoading(true);
    setState({ ...state, Seccion });
    const itemfiltroMz = catManzanas.filter(item => parseInt(item.Seccion) === parseInt(Seccion.value));
    
    let parametro = {
        idRegion: state.Region.value,
        idDL: null,
        idDF: null,
        idMunicipio: state.Municipio.value,
        idPoligono: state.Poligono.value,
        Seccion: Seccion.value,
        idManzana:state.Manzana.value,
        tipoVista: valuVista.label
    }
    DatosChart(parametro);
    getChartData(parametro);
    ChartMovilizacionAcumulativa(parametro);

        const data = {
          page:pages,pageSize:pageSize,filtered:[{"id":"a.Seccion", "value":Seccion.value}]
      };
      //setFiltro([...Filtro, data.filtered]);
        getRegistros(data);

    setLoading(false);
  }
 
  const changeDistritoFederal = (DistritoFederal) => {
    setLoading(true);
    setState({ ...state, DistritoFederal, 
                          Region: { value: null, label: 'Todos' }, 
                          Municipio: { value: null, label: 'Todos' }, 
                          Poligono: { value: null, label: 'Todos' },
                          Seccion: { value: null, label: 'Todos' }, 
                          Manzana: { value: null, label: 'Todos' },
                          DistritoLocal: { value: null, label: 'Todos' } });
    const itemfiltroM = catMunicipioReportes.data.filter(item => parseInt(item.idDF) === parseInt(DistritoFederal.value));
    const itemfiltroP = catPoligonoMunicipio.data.filter(item => parseInt(item.idDF) === parseInt(DistritoFederal.value));
    const itemfiltroS = catSeccion.filter(item => parseInt(item.idDF) === parseInt(DistritoFederal.value));
    const itemfiltroMz = catManzanas.filter(item => parseInt(item.idDF) === parseInt(DistritoFederal.value));
    setCmbMunicipio(itemfiltroM);
    setCmbPoligono(itemfiltroP);
    setCmbSeccion(itemfiltroS);
  
    let parametro = {
        idRegion: state.Region.value,
        idDL: null,
        idDF: DistritoFederal.value,
        idMunicipio: state.Municipio.value,
        idPoligono: state.Poligono.value,
        Seccion: state.Seccion.value,
        idManzana:state.Manzana.value,
        tipoVista: valuVista.label
    }
    DatosChart(parametro);
    getChartData(parametro);
    ChartMovilizacionAcumulativa(parametro);
    const data = {
      page:pages,pageSize:pageSize, filtered:[{"id":"a.idDF", "value":DistritoFederal.value}]
    };
    //setFiltro([...Filtro, data.filtered]);
    getRegistros(data);

    setLoading(false);
    
  }
  const changeDistritoLocal = (DistritoLocal) => {
    setLoading(true);
    setState({ ...state, DistritoLocal, 
                          Region: { value: null, label: 'Todos' }, 
                          Municipio: { value: null, label: 'Todos' }, 
                          Poligono: { value: null, label: 'Todos' },
                          Seccion: { value: null, label: 'Todos' }, 
                          Manzana: { value: null, label: 'Todos' },
                          DistritoFederal: { value: null, label: 'Todos' } });
    
    let parametro = {
        idRegion: null,
        idDL: DistritoLocal.value,
        idDF: null,
        idMunicipio: null,
        idPoligono: null,
        Seccion: null,
        idManzana:null,
        tipoVista: valuVista.label
    }
    DatosChart(parametro);
    getChartData(parametro);
    ChartMovilizacionAcumulativa(parametro);
    const data = {
      page:pages,pageSize:pageSize, filtered:[{"id":"a.idDL", "value":DistritoLocal.value}]
    };
    //setFiltro([...Filtro, data.filtered]);
    getRegistros(data);
    setLoading(false);
    
  }
  const changeTipo = (_tipo)=>{
      setState({ ...state, Tipo:_tipo })
      const data = {
        "filtered":[{"id":"a.Tipo", "value":_tipo.value}]
      };
      getRegistros(data);
      setLoading(false);
  } 
  const handleFiltro = (filter) => {
    console.log(filter);
    var _parametro={idRegion: filter.idRegion,
      idDL: filter.idDL,
      idDF: filter.idDF,
      idMunicipio: filter.idMunicipio,
      idPoligono: filter.idPoligono,
      Seccion: filter.Seccion,
      tipoVista: filter.valuVista};
      setParametros(_parametro);
      setFiltro(filter.Filtro);
      
      const data = {
        page:pages,pageSize:pageSize,filtered:filter.Filtro
      };
      DatosChart(_parametro);
      getChartData(_parametro);
      ChartMovilizacionAcumulativa(_parametro);
      getRegistros(data);
  }
  const ChartMovilizacionDispersa=(parametro)=>{
    setLoadingChart(true);
    EjecutarApi(parametro, "movilizacion/getInfoMovilizacionDispersa", token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if(res.results)
          {
              setSerieMovilizacionDispersa(res.data.Series);
              setCategoriaMovilizacionDispersa(res.data.Categories);
              setTituloMovilizacionDispersa(res.data.Titulo)
              setIsDispersaChart(true);
          }
        })
        .catch((e) => {
          notify.show(`Alerta: ${e.error}`, "error", 5000);
          
        }).finally(() => {
          setLoadingChart(false);
        })
 
    
}
  const ChartMovilizacionAcumulativa=(parametro)=>{
      setLoadingC2(true);
      setLoadingChart(true);


      EjecutarApi(parametro, "movilizacion/getInfoMovilizacionAcumulativa", token.access_token)
          .then(function (res) {
            return res;
          })
          .then((res) => {

            if(res.results)
            {
                setSerieLineaTiempo(res.data.Series);
                setCategoriaLineaTiempo(res.data.Categories);
                setTituloLineaTiempo(res.data.Titulo);
                setIsLineaChart(true);
            }
          })
          .catch((e) => {
            notify.show(`Alerta: ${e.error}`, "error", 5000);
            
          }).finally(x => {
            setLoadingC2(false);
            setLoading(false);
            setLoadingChart(false);
          })
   
      
  }
  const DatosChart=(parametro)=>{
      setLoading(true);
      setLoadingChart(true);
      var Titulo={};
      var series =[];
      var result = {};
      
      EjecutarApi(parametro, "movilizacion/getInfoMovilizacionCompromisos", token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if(res.results)
          {
              Titulo= {
                  
                title: 'Bingo',//`${res.data.data.title}`,
                xAxis: {
                  categories:res.data.Categories, 
                  title: {
                    text:``
                  }
                },
                yAxis: [{
                  min: 0,
                  title: {
                    text: '',//res.data.Categories,//res.data.Titulo, //`${res.data.data.yAxis.title.text}`
                  }
                }],
              };
      
              series = [
                {
                  name: 'Meta',
                  color: 'rgba(147,220,236,.9)',
                  data: res.data.Meta,
                  pointPadding: 0.3,
                  pointPlacement: -0.2
                }, {
                  name: 'Avance',
                  color: '#0041A0',
                  data: res.data.Avance,
                  pointPadding: 0.4,
                  pointPlacement: -0.2
                }];
              setTitulos(Titulo);
              setSeries(series);
              setisViewChart(true);
              setLoading(false);
              setLoadingChart(false);
          }
        })
        .catch((e) => {
          setLoadingChart(false);
          notify.show(`Alerta: ${e.error}`, "error", 5000);
        })
        
  }
  const changeVista=(e)=>{
    
    setvalueVista({label:e.label,value:e.value});
    let valor=e.value;
      switch(valor)
      {
        case 1: //Estatal
            setVista({
                Region:true,
                Municipio:true,
                Poligono:true,
                Seccion:true,
                Tipo:true,
                DistritoFederal:false,
                DistritoLocal:false
            })
            
        break;
        case 2: //Local
            setVista({
                Region:false,
                Municipio:false,
                Poligono:false,
                Seccion:false,
                Tipo:true,
                DistritoFederal:false,
                DistritoLocal:true
            })
        break;
        case 3: //Federal
            setVista({
              Region:false,
              Municipio:true,
              Poligono:true,
              Seccion:true,
              Tipo:true,
              DistritoFederal:true,
              DistritoLocal:false
          })
        break;
        default:
            setVista({
              Region:true,
              Municipio:true,
              Poligono:true,
              Seccion:true,
              Tipo:true,
              DistritoFederal:false,
              DistritoLocal:false
          })
        break;
      }
      let parametro = {
          idRegion: null,
          idDL: null,
          idDF: null,
          idMunicipio: null,
          idPoligono: null,
          Seccion: null,
          idManzana:null,
          tipoVista: e.label
      }
      DatosChart(parametro);
      ChartMovilizacionAcumulativa(parametro);
      ChartMovilizacionDispersa(parametro);
      getChartData(parametro);
  }
  const Actualizar=()=>{
    var  today = new Date();
      if(token.user.id===99)
      {
            let parametro = {
              idRegion: state.Region.value,
              idDL: state.DistritoLocal.value,
              idDF: state.DistritoFederal.value,
              idMunicipio: state.Municipio.value,
              idPoligono: state.Poligono.value,
              Seccion: state.Seccion.value,
              tipoVista: valuVista.label
          }
          DatosChart(parametro);
          getChartData(parametro);
          getChartData(parametro);
          ChartMovilizacionAcumulativa(parametro);
          // ChartMovilizacionDispersa(parametro);
      }
  }
  const header = (text) => {
    return <h6 style={styleHeader}>{text}</h6>;
  };
  const columns=[{
          Header: header("DF"),
          minWidth: 20,
          id: "DistritoFederal",
          accessor: (d) => d.DFED,
          filterAll: false,
          style: { fontSize: '12px' },
         headerStyle: { fontSize: '2px', align: "center"  }
          
        },
        {
          Header: header("DL"),
          minWidth: 20,
          id: "DistritoLocal",
          accessor: (d) => d.DLOC,
          filterAll: false,
          style: { fontSize: '12px' },
         headerStyle: { fontSize: '2px', align: "center"  }
          
        },
        {
          Header: header("Región"),
          minWidth: 20,
          id: "Region",
          accessor: (d) => d.Region,
          filterAll: false,
          style: { fontSize: '12px' },
         headerStyle: { fontSize: '2px', align: "center"  }
          
        },
        {
          Header: header("Municipio"),
          minWidth: 65,
          id: "Municipio",
          accessor: (d) => d.Municipio,
          filterAll: false,
          style: { fontSize: '12px', textAlign: 'left' },
         headerStyle: { fontSize: '2px'  }
          
        },
        {
          Header: header("Polígono"),
          minWidth: 20,
          id: "Poligono",
          accessor: (d) => d.Poligono,
          filterAll: false,
          style: { fontSize: '12px' },
          headerStyle: { fontSize: '2px' }
        },{
          Header: header("Sección"),
          minWidth: 20,
          id: "Seccion",
          accessor: (d) => d.Seccion,
          //Cell:props => <div>{props.value + " %"}</div>,
          filterAll: false,
          style: { fontSize: '12px', },
          headerStyle: { fontSize: '2px' }
        },
        {
          Header: header("LNOM"),
          minWidth: 30,
          id: "LNOM",
          accessor: (d) => d.LNOM,
          filterAll: false,
          style: { fontSize: '12px' },
          headerStyle: { fontSize: '2px' }
        },
        {
          Header: header("Meta"),
          minWidth: 30,
          id: "Meta",
          accessor: (d) => d.Meta,
          filterAll: false,
          style: { fontSize: '12px' },
          headerStyle: { fontSize: '2px' }
        },
        {
          Header: header("Compromisos"),
          minWidth: 30,
          id: "SIAN",
          accessor: (d) => d.SIAN,
          filterAll: false,
          style: { fontSize: '12px' },
          headerStyle: { fontSize: '2px' }
        },
        {
          Header: header("Avance"),
          minWidth: 30,
          id: "Total",
          show: _menu.Seguimiento === 1 ? true : false,
          accessor: (d) => d.Total,
          filterAll: false,
          style: { fontSize: '12px' },
          headerStyle: { fontSize: '2px' }
        },
        {
          Header: header("Detectados"),
          minWidth: 30,
          id: "Avance",
          accessor: (d) => d.Avance,
          filterAll: false,
          style: { fontSize: '12px' },
          headerStyle: { fontSize: '2px' }
        },
        {
          Header: header("Otros"),
          minWidth: 30,
          show: _menu.Seguimiento === 1 ? true : false,
          id: "AvanceCiudadano",
          accessor: (d) => d.AvanceCiudadano,
          filterAll: false,
          headerStyle: { fontSize: '2px' }
        },
       
        {
          Header: header("Porcentaje"),
          minWidth: 30,
          id: "AvancePorcentaje",
          accessor: (d) => d.AvancePorcentaje,
          filterAll: false,
          style: { fontSize: '12px' },
          headerStyle: { fontSize: '2px' }
        },
        {
          Header: header("Efectividad"),
          minWidth: 30,
          show: _menu.Seguimiento === 1 ? true : false,
          id: "Efectividad",
          accessor: (d) => d.Efectividad, 
          //Cell: props => <>{(props.value >50) ? (<strong style={{ color: ColorEfectividad }}>{props.value} </strong>) :<div>{props.value}</div>}</>,
          filterAll: false,
          style: { fontSize: '12px' },
          headerStyle: { fontSize: '2px' }
        }
  ];
  const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
    console.log(params);
    console.log(sorted);
		const data = {
      page:page,
      pageSize:pageSize,
      filtered:params,
      sorted:sorted
    };
    setPage(page);
    setFiltro(params)
    setPageSize(pageSize);
		getRegistros(data);
	}, []);

	const getRegistros = (data) => {
    setIsLoadingTable(true)
    ///movilizacion/getListadoMovilizacionPromovidos
    EjecutarApi(data, "movilizacion/getListadoMovilizacionPromovidos", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
        // console.log('=getListadoMovilizacionPromovidos: ', res);
        setIsLoadingTable(false)
        if (res.results)
        {
          setTotal(res.total)
            setTabla(res.data.map(item=>{
              return{
                Avance: numberWithCommas(item.Avance),
                AvanceCiudadano: numberWithCommas(item.AvanceCiudadano),
                AvancePorcentaje: <strong style={{ color:item.ColorAvanceCompromisos  }}>{item.AvancePorcentaje} </strong> ,
                ColorAvanceCompromisos: item.ColorAvanceCompromisos,
                ColorEfectividad: item.ColorEfectividad,
                DistritoLocal: item.DistritoLocal,
                Efectividad: <strong style={{ color:item.ColorEfectividad  }}>{item.Efectividad ? parseFloat(item.Efectividad).toFixed(2):'0.00'} </strong> ,
                Hora: item.Hora,
                Meta: numberWithCommas(item.Meta),
                Poligono:item.Poligono,
                DLOC:item.DLOC,
                DFED:item.DFED,
                LNOM: item.LNOM,
                SIAN: item.SIAN,
                Municipio:item.Municipio,
                Region:item.Region,
                //Seccion:<div><i className="fa fa-circle fa-lg color-green-400 height:10rvh text-aling-left " color="red" aria-hidden="true">{"    "}{"    "}</i>{"    "}{item.Seccion}</div>,
                //Seccion:<><strong><Badge size='md' style={{ color:'green' }} >{item.Seccion}</Badge></strong> </>,
                //Seccion:<><div></div><div >{"    "}{item.Seccion}</div></>,
                //Seccion:<tr><td className="color color-green-100 text-align-bottom-left"style={{color:item.Tipo==='switch'?'yellow':item.Tipo==='Duro'?"green":item.Tipo==='Perdible'?'red':''}} ><i className="fa fa-circle fa-lg color-green-400 height:10rvh text-aling-left " aria-hidden="true">{"    "}{"    "}</i></td><td className="color color-black text-align-bottom-right">{"    "}{item.Seccion}</td></tr>,
                //Seccion:item.Seccion,
                Seccion:<tr><td className="color color-yellow-100 text-align-top-left"   
                                  style={{color:item.Tipo==='SWITCH'?'#FFC300':item.Tipo==='DURO'?'#1E8449':item.Tipo==='PERDIBLE'?'#BB1911':''}} ><i className="fa fa-circle fa-lg" aria-hidden="true">{"    "}{"    "}</i>
                                  </td><td className="color color-black text-align-top-right">{"    "}{item.Seccion}</td></tr>,

                Total: numberWithCommas(item.Total),
              }
            }));
            setLoading(false);
        }
        setLoading(false);
			
			})
			.catch((e) => {
				setLoading(false);
				notify.show("Error al cargar registros", "error", 5000);
			})
  }

  const getChartData = (parametro) => {
    setLoadingChart(true);
    EjecutarApi(parametro,
      'movilizacion/getInfoBingoChartV2',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then(res => {
        if (res.success) {
          if (res.results) {
            setDataSerie(res.data.series);
            setCategorias(res.data.categories);
            setisViewChart(true);
          }
          else {
            notify.show(res.message, 'warning', 5000);
          }
        } else {
          notify.show(res.message, 'warning', 5000);
        }
        setLoadingChart(false);
      })
      .catch(e => {
        notify.show(`Error al cargar : ${e.message}}`, 'error', 5000);
        setLoadingChart(false);
      });
  }

  const Exportar = () => {

    const data = {
      page:0,
      pageSize:10,
      filtered:Filtro,
      Export:true
    };

    var datos = JSON.stringify(data)
    var url = new URL(
      `${URL_PUBLICA_REPORTE}movilizacion/ExportListadoMovilizacionPromovidos?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");

  }
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {!isBloqueado && (<>
            <Row>
              <Col sm="12" md="12" lg="12">

              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                        <Row>
                              <br />
                              <Col sm="12" className="text-left text-align-bottom-left">
                                  <Label className=" text-right text-align-bottom-left"><strong>Última Actualización {hora}</strong> </Label> 
                                  <Button onClick={(e)=>Actualizar()} className='btn btn-link' color='primary'><i className="fa fa-refresh" aria-hidden="true"></i> Actualizar </Button>
                              </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <Filter Datos={(e) => handleFiltro(e)}></Filter>
                            </Col>
                         
                        </Row>
                  </CardHeader>
                  {((isLoadingTable===false && loadingChart===false && loadingC2===false)) &&
                  <CardBody>
{/*                     
                    <Row>
                      <Col sm="3" className="text-muted">Vista:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbVista}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={valuVista}
                          onChange={(e) => changeVista(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      {vista.Region && (
                          <Col sm="3" className="text-muted">Región:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbRegion}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Region}
                          onChange={(e) => changeRegion(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      )}
                      {vista.DistritoFederal&&(
                          <Col sm="3" className="text-muted">Distrito Federal:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbDF}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.DistritoFederal}
                          isDisabled= {(isLoadingTable && loadingChart && loadingC2)?loading:false}
                          onChange={(e) => changeDistritoFederal(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                          loading={loading}
                        />)}
                      </Col>
                      )}
                      {vista.DistritoLocal&&(
                          <Col sm="3" className="text-muted">Distrito Local:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbDL}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.DistritoLocal}
                          onChange={(e) => changeDistritoLocal(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      )}
                      {vista.Municipio&&(
                          <Col sm="3" className="text-muted">Municipio:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbMunicipio}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Municipio}
                          onChange={(e) => changeMunicipio(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      )}
                      {vista.Poligono&&(
                          <Col sm="3" className="text-muted">Polígono:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbPoligono}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Poligono}
                          onChange={(e) => changePoligono(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      )}
                      {vista.Seccion&&(
                          <Col sm="3" className="text-muted">Sección:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbSeccion}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Seccion}
                          onChange={(e) => changeSecciones(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      )}
                      {vista.Tipo&&(
                          <Col sm="3" className="text-muted">Filtro por Color:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbTipo}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Manzana}
                          onChange={(e) => changeTipo(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      )}
                    </Row>
                   */}
                  </CardBody>
                    }
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                  <Card>
                    <CardHeader>
                        
                    </CardHeader>
                    <CardBody>
                        <Row>
                          {/* {<Col sm="6" md="6" lg="6">
                            {isViewChart&&(
                                series&&(<GraficaMetas datos={series} Titulos={Titulos}  ></GraficaMetas>)
                            )}
                            {loadingChart && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={true}
                                />
                            )}
                          </Col>  */}
                          <Col sm="6" md="6" lg="6">
                            {isViewChart&&(
                                dataSerie&&(
                                  <ChartMultipleColums
                                  Categories={categorias}
                                  Series={dataSerie}
                                  Title={optionsChart.Title}
                                  Subtitle={optionsChart.Subtitle}
                                  Colors={Colors}
                                />)
                            )}
                            {loadingChart && (
                                <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={true}
                                />
                            )}
                          </Col>
                          <Col sm="6" md="6" lg="6">
                          {isLineaChart && !loadingC2 && <div style={{ width: '100%' }}>
                              <LineaTiempo Categorias={categoriaLineaTiempo} 
                                            Series={serieLineaTiempo} 
                                            TituloMpio={TituloLineaTiempo} 
                                            Titulo="Línea de Tiempo Movilización Acumulativa" /></div>}
                          {loadingC2 && (
                              <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#686769"
                                  loading={true}
                              />
                          )}
                          </Col>
                        </Row>
                    </CardBody>
                  </Card>
              </Col>

            </Row>
            {/* <Row>
              <Col>
                  <Card>
                    <CardHeader>
                      
                    </CardHeader>
                    <CardBody>
                      <Row id="container">
                          {isLineaChart && <div style={{ width: '100%' }}>
                              <LineaTiempo Categorias={categoriaLineaTiempo} 
                                            Series={serieLineaTiempo} 
                                            TituloMpio={TituloLineaTiempo} 
                                            Titulo="Línea de Tiempo Movilización Acumulativa" /></div>}
                          {loadingChart && (
                              <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#686769"
                                  loading={true}
                              />
                          )}
                      </Row>
                    </CardBody>
                  </Card>
              </Col>

            </Row> */}
            {/* <Row>
              <Col>
                  <Card>
                    <CardHeader>
                      
                    </CardHeader>
                    <CardBody>
                      <Row id="container">
                          {isDispersaChart && <div style={{ width: '100%' }}>
                              <LineaTiempo Categorias={categoriaMovilizacionDispersa} 
                                            Series={serieMovilizacionDispersa} 
                                            TituloMpio={TituloMovilizacionDispersa} 
                                            Titulo="Línea de Tiempo Movilización Dispersa" /></div>}
                          {loadingChart && (
                              <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#686769"
                                  loading={true}
                              />
                          )}
                      </Row>
                    </CardBody>
                  </Card>
              </Col>

            </Row> */}
            <Row>
              <Col>
                  <Card>
                    <CardHeader>
                    <Row>
                        {_menu.Exportar === 1 ?(
                          <Col sm="8">
                            <Button
                              name="btnExportar"
                              size="sm"
                              type="button"
                              className="primary float-left"
                              color="primary"
                              onClick={() => Exportar()}
                            >
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-lg" />
                              </span>
                              Exportar
                            </Button>
                          </Col>
                        ) : (
                            <></>
                          )}
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                      <Col lg="12" md="12">
                        
                        <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                          <ReactTable
                            className="text-align: center;"
                            size='sm'
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "center"
                            }}
                            data={tabla}
                            noDataText="Sin información"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={isLoadingTable}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            pages={Math.floor(total / 10)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={fetchData}
                            params={Filtro}
                          />
                        </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
              </Col>

            </Row>
                      </>)
          }
        </div>

        <Footer fluid />
      </div>
    </div>
  );
};

export default MovilizacionDashboard;  