import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";
import { RotateSpinner } from "react-spinners-kit";
import ReactTable from 'react-table';
import PerfectScrollbar from "perfect-scrollbar";
import swal from "sweetalert";

import { getVars, URL_PUBLICA } from "GlobalStorage";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from "data/menus";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import ModalFiltro from 'components/Filtro/indexObservador';
import Pagination from "components/Pagination/pagination";

var ps;

function ElectoralObservador(props) {
	const mainPanel = useRef();
	const token = getVars("Token");
	let history = useHistory();
	const [_menu, _setmenu] = useState({});
	const [loading, setLoading] = useState(false);
	const [TotalRegistros, setTotalRegistros] = useState(0);
	const [Registros, setRegistros] = useState([]);
	const [Filtro, setFiltro] = useState([]);
	const [MenuModal] = useState({
		Compromiso: false,
		Voluntarios: false,
		RCs: false,
		RGs: true,
		Abogado: false,
		CasaAzul: false,
		Municipio: false
	});

	useEffect(() => {

		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current);
			document.body.classList.toggle("perfect-scrollbar-on");
		}

		if (props.history.action === "PUSH") {
			mainPanel.current.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
		}

		let menu = getDataMenu(54, token.menus);
		if (Object.keys(menu).length > 0) {
			_setmenu(menu);
		} else {
			//El usuario no tiene el menú asignado 
		}

	}, [])

	const handleMiniClick = () => {
		document.body.classList.toggle("sidebar-mini");
	};

	const Exportar = () => {
		var url = new URL(URL_PUBLICA + `getReporteObservadores?token=${token.access_token}`
			// `http://127.0.0.1:8000/api/getReporteObservadores?token=${token.access_token}`

		);
		window.open(url, "_blank");
	};

	const handleFiltro = (filter) => {
		var _filtro = [];

		if (filter.Partido.value > 0) {
			_filtro.push({ id: 'par.id', value: filter.Partido.value })
		}
		if (filter.DF.value > 0) {
			_filtro.push({ id: 'df.id', value: filter.DF.value })
		}

		if (filter.Nombre.length > 0) {
			_filtro.push({ id: "observador.Nombre", value: filter.Nombre })
		}
		if (filter.Paterno.length > 0) {
			_filtro.push({ id: "observador.Paterno", value: filter.Paterno })
		}
		if (filter.Materno.length > 0) {
			_filtro.push({ id: "observador.Materno", value: filter.Materno })
		}
		if (filter.TieneExperiencia > 0) {
			_filtro.push({ id: "observador.TieneExperiencia", value: filter.TieneExperiencia ? 1 : 0 })
		}
		if (filter.TieneAuto > 0) {
			_filtro.push({ id: "observador.TieneAuto", value: filter.TieneAuto ? 1 : 0 })
		}

		setFiltro(_filtro);

		const data = {
			page: 0,
			pageSize: 10,
			filtered: _filtro,
			idResponsabilidad: 9,
			tipo: 'and'
		};

		getRegistros(data);
	}

	const columns = [
		{
			Header: "Distrito",
			minWidth: 50,
			id: "df.DistritoFederal",
			accessor: "Distrito",
			filterAll: false,
		},
		{
			Header: "Responsable",
			minWidth: 90,
			id: "observador.Nombre",
			accessor: "Responsable",
			filterAll: false,
		},
		// {
		// 	Header: "Celular",
		// 	minWidth: 40,
		// 	id: "Celular",
		// 	accessor: "Celular",
		// 	filterAll: false,
		// },
		{
			Header: "Tiene Auto",
			minWidth: 40,
			id: "TieneAuto",
			accessor: "TieneAuto",
			filterAll: false,
		},
		{
			Header: "Tiene Experiencia",
			minWidth: 40,
			id: "TieneExperiencia",
			accessor: "TieneExperiencia",
			filterAll: false,
		}
	];

	const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
		const data = {
			page: page,
			pageSize: pageSize,
			filtered: params,
			sorted: sorted,
			idResponsabilidad: 9,
			tipo: "and"
		};
		getRegistros(data);
	}, []);

	const getRegistros = (data) => {
		setLoading(true);
		let _menuStorage = getDataMenu(54, token.menus)
		EjecutarApi(data, "getCompromisosResponsabilidadPropuesta", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				setLoading(false);
				setTotalRegistros(res.total);
				setRegistros(
					res.data.map((item) => {
						return {
							id: item.id,
							Partido: item.siglas,
							Responsable: _menuStorage.Eliminar === 1 ?
								<><Button className="btn-link"
									type="button"
									color="danger"
									onClick={(e) => EliminarResponsablidad(item, e)}
								>
									<i class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
								</Button>{item.Fullname}</>
								: item.Fullname,
							Celular: item.Celular,
							TieneExperiencia: item.TieneExperiencia === 1 ? 'Si' : 'No',
							TieneAuto: item.TieneAuto === 1 ? 'Si' : 'No',
							Distrito: item.Distrito.label
						};
					})
				);
			})
			.catch((e) => {
				setLoading(false);
				notify.show("Error al cargar registros", "error", 5000);
			});
	}
	const removeResponsablidad = (item) => {
		EjecutarApi({ ClaveElector: item.ClaveElector }, 'eliminarResponsablesWeb', token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.results) {
					notify.show(res.message, "success", 5000, {
						background: "#d61818",
						text: "#FFFFFF",
					});
					let data = {
						page: 0,
						pageSize: 10,
						filtered: Filtro,
						sorted: [],
						idResponsabilidad: 9,
						tipo: "and"
					}
					getRegistros(data)
				}
				else {
					notify.show(`Error: No se puede eliminar la responsabilidad`, "error", 5000, {
						background: "#d61818",
						text: "#FFFFFF",
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
					background: "#d61818",
					text: "#FFFFFF",
				});
			});
	};
	const EliminarResponsablidad = (item, e) => {
		e.preventDefault();
		swal({
			title: "¿Está Seguro?",
			text: `Que desea eliminar la responsabilidad de ${item.Fullname}, como Observador`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancelar",
					value: null,
					visible: true,
					className: "btn-danger",
					closeModal: true,
				},
				confirm: {
					text: "Si, eliminar!",
					value: true,
					visible: true,
					className: "btn-success",
					closeModal: true
				}
			}
		})
			.then((willDelete) => {
				if (willDelete) {
					removeResponsablidad(item);
				} else {

				}
			});
	}
	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel ps ps--active-y" ref={mainPanel}>
				<Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
				<div className="content">
					<Row>
						<Col sm="12">
							<Card>
								<CardHeader>
									<h5 className="title">Observadores de casilla</h5>
									<Col sm="12" md="12" lg="12">
										<Row>
											{/*_menu.Agregar === 1 ? (<><Button color="success" size="sm" onClick={() => handleBtnSetResposabilidad()} >
												<span className="btn-label">
													<i className="fa fa-user-plus fa-lg" />
												</span>
												Capturar
												</Button></>) : <></>*/}
											{_menu.Exportar === 1 ? (
												<Button size='sm' className='primary left-align' color='primary' onClick={() => Exportar()}>
													<span className="btn-label">
														<i className="fa fa-file-excel-o fa-lg" />
													</span>
													Exportar
												</Button>
											) : <></>}
										</Row>
										<Row>
											<Col sm="12">
												<ModalFiltro Datos={(e) => handleFiltro(e)} />
											</Col>
										</Row>
									</Col>
								</CardHeader>
								<CardBody>
									{<RotateSpinner color={"#1261ff"} size={30} loading={loading} />}
									<div style={{ 'width': '100%', 'overflow': 'auto' }}>
										<ReactTable
											style={{
												width: "110%",
												height: "100%"
											}}
											size='sm'
											data={Registros}
											noDataText="Sin información"
											manual
											columns={columns}
											className="-striped -highlight primary-pagination"
											loading={loading}
											showPagination={true}
											showPaginationBottom={true}
											showTotalPages={true}
											showPageJump={false}
											canNextFromData={true}
											defaultPageSize={10}
											total={TotalRegistros}
											pages={Math.floor(TotalRegistros / 10)}
											previousText="Anterior"
											nextText="Siguiente"
											ofText="de"
											pageSizeOptions={[10, 20, 25, 50, 100]}
											PaginationComponent={Pagination}
											onFetchData={fetchData}
											params={Filtro}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

				</div>
				<Footer fluid />
			</div>
		</div>
	);
}
export default ElectoralObservador;