import React, { useState, useCallback } from "react";
import { Col, Card, Table, Button } from "reactstrap";
import { RotateSpinner } from "react-spinners-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUser, faEdit } from "@fortawesome/free-solid-svg-icons";
import ReactTable from 'react-table';

import { getVars } from "GlobalStorage";
import EjecutarApi from "data/getPOST";
import Pagination from "components/Pagination/pagination";
import useWindowDimensions from "components/useWindowDimensions";

const CandidatosListado = props => {
    const [loading, setLoading] = useState(false);
    const { height, width } = useWindowDimensions();
    const [TotalRegistros, setTotalRegistros] = useState(0);
    const [Registros, setRegistros] = useState([]);
    const [Filtro, setFiltro] = useState([]);

    const columns = [
        {
            Header: "Tipo de elección",
            id: "TipoEleccion",
            accessor: "TipoEleccion",
            filterAll: false,
        },
        {
            Header: "Partido",
            id: "Partido",
            accessor: "Partido",
            filterAll: false,
        },
        {
            Header: "Nombre",
            id: "Nombre",
            accessor: "Nombre",
            filterAll: false,
        },
        {
            Header: "Sexo",
            id: "Sexo",
            accessor: "Sexo",
            filterAll: false,
        },
    ];

    const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
        const data = {
            page: page,
            pageSize: pageSize,
            filtered: params,
            sorted: sorted,
            idResponsabilidad: 2,
            tipo: "and"
        };
        getRegistros(data);
    }, []);

    const getRegistros = (data) => {

    }

    return (
        <>
            {<RotateSpinner color={"#1261ff"} size={30} loading={loading} />}
            <Card style={{ height: height - 118 }}>
                <div className="table-full-width table-responsive">
                    <Table>
                        <tbody>

                        </tbody>
                    </Table>
                </div>
            </Card>
            {/* <Col sm="12" md="12" lg="12">
                <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                    <ReactTable
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        size='sm'
                        data={Registros}
                        noDataText="Sin información"
                        manual
                        columns={columns}
                        className="-striped -highlight primary-pagination"
                        loading={loading}
                        showPagination={true}
                        showPaginationBottom={true}
                        showTotalPages={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={10}
                        total={TotalRegistros}
                        pages={Math.floor(TotalRegistros / 10)}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        PaginationComponent={Pagination}
                        onFetchData={fetchData}
                        params={Filtro}
                    />
                </div>
            </Col> */}
        </>
    );
}

export default CandidatosListado;