import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col, Collapse, Input, FormGroup, Label, } from 'reactstrap';
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { CircleSpinner } from "react-spinners-kit";
import moment from "moment";

import EjecutarApi from "../../data/getPOST";
import { getVars, URL_PUBLICA } from "../../GlobalStorage";

function IndexRC({ Menu, Datos }) {
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
    const [filtro, setFiltro] = useState({
        Region: {},
        Municipio: {},
        Poligono: {},
        Seccion: {},
        Casilla: {},
        TipoCasilla: {},
        ClaveElector: '',
        Nombre: '',
        Paterno: '',
        Materno: '',
        Funcionario: 0,
        Auto: 0,
        CuidarCasilla: 0,
        Celular: ''
    })

    const [collapse, setCollapse] = useState({
        horizontalTabs: "home",
        verticalTabs: "info",
        pageTabs: "homePages",
        openedCollapses: ["collapseOne", "collapse1"]
    });

    const [catRegionestFilter, setCatRegionestFilter] = useState([])
    const [catDFFilter, setCatDFFilter] = useState([])
    const [catDLFilter, setCatDLFilter] = useState([])
    const [catPoligonost, setCatPoligonost] = useState([])
    const [catPoligonostFilter, setCatPoligonostFilter] = useState([])
    const [dataCombos, setDataCombo] = useState({
        dRegion: { label: 'Seleccione', value: 0 },
        dDF: { label: 'Seleccione', value: 0 },
        dDL: { label: 'Seleccione', value: 0 },
        dMunicipio: { label: 'Seleccione', value: 0 },
        dPoligono: { label: 'Seleccione', value: 0 },
        dSeccion: { label: 'Seleccione', value: 0 },
        dPropuesta: { label: 'Seleccione', value: 0 }
    })
    const [catMunicipiotFilter, setCatMunicipiotFilter] = useState([])
    const [catMunicipiot, setCatMunicipiot] = useState([])
    const [catSecciontFilter, setCatSecciontFilter] = useState([])
    const [catSecciont, setCatSecciont] = useState([])
    const [catCasilla, setcatCasilla] = useState([{ value: 0, label: 'Debe validar la sección' }]);
    const [catPropietario, setcatPropietario] = useState([{ value: 0, label: 'Debe validar la sección' }]);

    useEffect(() => {
        setLoading(true);
        EjecutarApi({}, "catCompromisosWeb", token.access_token, "GET")
            .then(function (res) {
                return res;
            })
            .then((items) => {
                if (items.results) {

                    //setCatSexo(items.data.Sexo);
                    //setCatVoluntario(items.data.Voluntario);
                    //setSituacionLaboral(items.data.SituacionLaboral);
                    //setNivelEstudios(items.data.NivelEstudios);
                    setLoading(false);
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);

            });
        //getCatalogosFiltrosPromocion  getCatRegion
        EjecutarApi({}, "getCatalogosFiltrosElectoralClonIdMunicipioReportes", token.access_token, "POST")
            .then(res => {
                if (res.results)
                    if (res.data) {

                        let dl = [{ value: 0, label: 'Todos' }]
                        let dlFormated = res.data.distritosL.map(item => {
                            return {
                                value: item.value,
                                label: item.label
                            }
                        })
                        //setCatRegionest(regiones.concat(regionesFormated));
                        setCatDLFilter(dl.concat(dlFormated));

                        let df = [{ value: 0, label: 'Todos' }]
                        let dfFormated = res.data.distritos.map(item => {
                            return {
                                value: item.value,
                                label: item.label
                            }
                        })
                        //setCatRegionest(regiones.concat(regionesFormated));
                        setCatDFFilter(df.concat(dfFormated));

                        let regiones = [{ value: 0, label: 'Todos' }]
                        let regionesFormated = res.data.regiones.map(item => {
                            return {
                                value: item.value,
                                label: item.label
                            }
                        })
                        //setCatRegionest(regiones.concat(regionesFormated));
                        setCatRegionestFilter(regiones.concat(regionesFormated));

                        let municipios = [{ value: 0, label: 'Seleccione' }]
                        let municipiosFormated = res.data.municipios.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                                idDF:item.idDF,
                                idDL:item.idDL 
                            }
                        })
                        setCatMunicipiot(municipios.concat(municipiosFormated));
                        setCatMunicipiotFilter(municipios.concat(municipiosFormated));

                        let poligonos = [{ value: 0, label: 'Seleccione' }]
                        let poligonosFormated = res.data.poligonos.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idMunicipio: item.idMunicipio,
                                idRegion: item.idRegion,
                                idDF:item.idDF,
                                idDL:item.idDL
                            }
                        })
                        setCatPoligonost(poligonos.concat(poligonosFormated));
                        setCatPoligonostFilter(poligonos.concat(poligonosFormated));

                        let seccion = [{ value: 0, label: 'Seleccione' }]
                        let seccionFormated = res.data.secciones.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                                idMunicipio: item.idMunicipio,
                                idPoligono: item.idPoligono,
                                idDF:item.idDF,
                                idDL:item.idDL
                            }
                        })
                        setCatSecciont(seccion.concat(seccionFormated));
                        setCatSecciontFilter(seccion.concat(seccionFormated));
                    }
            })
            .catch((e) => {
            }).then(() => {
                setLoading(false);
            })
        //getCatalogosPorResponsabilidad
        EjecutarApi({}, "getCatalogosPorResponsabilidad", token.access_token, "POST")
            .then(res => {
                if (res.results)
                    if (res.data) {
                        //setCatVoluntario(res.data)
                    }
            })
            .catch((e) => {
            }).then(() => {
                setLoading(false);
            })

            EjecutarApi({}, 'getCasillaPropietario', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success) {
                    setcatPropietario(res.data);
                    setLoading(false);
                }

            })
            .catch((e) => {
                setLoading(false);

            });
    }, [])

    const collapsesToggle = (colapse) => {
        let openedCollapses = collapse.openedCollapses;
        if (openedCollapses.includes(colapse)) {
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses.filter(item => item !== colapse)
            });
        } else {
            openedCollapses.push(colapse);
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses
            });
        }
    };
    const changeRegionX = (dRegion) => {

        setLoading(true);

        if (dRegion.value > 1) {
            setDataCombo({ ...dataCombos, dRegion, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });


            setCatMunicipiotFilter(catMunicipiot.filter(data => data.idRegion === dRegion.value));

            setCatPoligonostFilter(catPoligonost.filter(data => data.idRegion === dRegion.value));
        } else {
            setDataCombo({ ...dataCombos, dRegion: { label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });
            setCatMunicipiotFilter(catMunicipiot);
            setCatPoligonostFilter(catPoligonost);
        }



        setLoading(false);

    }

    const changeDF = (dDF) => {

        setLoading(true);

        if (dDF.value > 1) {
            setDataCombo({ ...dataCombos, dDF:dDF, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } , dSeccion: { label: 'Seleccione', value: 0 }});
            setCatMunicipiotFilter(catMunicipiot.filter(data => data.idDF === dDF.value));
            setCatPoligonostFilter(catPoligonost.filter(data => data.idDF === dDF.value));
            setCatSecciontFilter(catSecciont.filter(data => data.idDF === dDF.value));
        } else {
            setDataCombo({ ...dataCombos, dDF: { label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });
            setCatMunicipiotFilter(catMunicipiot);
            setCatPoligonostFilter(catPoligonost);
            setCatSecciontFilter(catSecciont);
        }
        setLoading(false);

    }

    const changeDL = (dDL) => {

        setLoading(true);

        if (dDL.value > 1) {
            setDataCombo({ ...dataCombos, dDL:dDL, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } , dSeccion: { label: 'Seleccione', value: 0 }});
            setCatMunicipiotFilter(catMunicipiot.filter(data => data.idDL === dDL.value));
            setCatPoligonostFilter(catPoligonost.filter(data => data.idDL === dDL.value));
            setCatSecciontFilter(catSecciont.filter(data => data.idDL === dDL.value));
        } else {
            setDataCombo({ ...dataCombos, dDL: { label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });
            setCatMunicipiotFilter(catMunicipiot);
            setCatPoligonostFilter(catPoligonost);
            setCatSecciontFilter(catSecciont);
        }
        setLoading(false);

    }

    const changeMunicipioX = (dMunicipio) => {
        setLoading(true);
        if (dMunicipio > 1) {
            setDataCombo({ ...dataCombos, dMunicipio: dMunicipio, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion: { label: 'Seleccione', value: 0 } });
            setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idMunicipio) === dMunicipio.value));
        } else {
            setDataCombo({ ...dataCombos, dMunicipio: dMunicipio, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion: { label: 'Seleccione', value: 0 } });
            setCatSecciontFilter(catSecciont);
            setCatPoligonostFilter(catPoligonost);
        }
        setLoading(false);
    }

    const changePoligonoX = (dPoligono) => {
        setLoading(true);
        setDataCombo({ ...dataCombos, dPoligono });
        setLoading(false);
    }
    const changeSeccionX = (dSeccion) => {
        setLoading(true);
        setDataCombo({ ...dataCombos, dSeccion });
        setLoading(false);
        EjecutarApi({ "seccion": dSeccion.value }, 'getCasillasBySeccion', token.access_token)
            .then(function (res) {

                return res;
            })
            .then((res) => {
                if (res.success) {
                    setcatCasilla(res.data);
                    setLoading(false);
                }

            })
            .catch((e) => {
                setLoading(false);

            });
        //getCasillaPropietario 
        EjecutarApi({ "seccion": dSeccion.value }, 'getCasillaPropietario', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success) {
                    setcatPropietario(res.data);
                    setLoading(false);
                }

            })
            .catch((e) => {
                setLoading(false);

            });
    }
    const setFilter = (event) => {

        var filtrar = {
            Region: dataCombos.dRegion,
            DF: dataCombos.dDF,
            DL: dataCombos.dDL,
            Municipio: dataCombos.dMunicipio,
            Poligono: dataCombos.dPoligono,
            Seccion: dataCombos.dSeccion,
            Casilla: filtro.Casilla,
            TipoCasilla: filtro.TipoCasilla,
            ClaveElector: filtro.ClaveElector,
            Nombre: filtro.Nombre,
            Paterno: filtro.Paterno,
            Materno: filtro.Materno,
            Funcionario: filtro.Funcionario,
            Auto: filtro.Auto,
            CuidarCasilla: filtro.CuidarCasilla,
            Celular: filtro.Celular
        }
        Datos(filtrar);
    }
    const ClearFilter = () => {
        setDataCombo({
            ...dataCombos, dRegion: { label: 'Seleccione', value: 0 },
            dMunicipio: { label: 'Seleccione', value: 0 },
            dPoligono: { label: 'Seleccione', value: 0 },
            dSeccion: { label: 'Seleccione', value: 0 },
            dDL: { label: 'Seleccione', value: 0 },
            dDF: { label: 'Seleccione', value: 0 }
        });

        setFiltro({
            Region: {},
            DF:{},
            DL:{},
            Municipio: {},
            Poligono: {},
            Seccion: {},
            Casilla: {},
            TipoCasilla: {},
            ClaveElector: '',
            Nombre: '',
            Paterno: '',
            Materno: '',
            Funcionario: 0,
            Auto: 0,
            CuidarCasilla: 0,
            Celular: ''
        })


    }
    return (
        <Card className="card-plain">
            <CardBody>
                <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                >

                    <Card className="card-plain">
                        <CardHeader role="tab">
                            <a
                                aria-expanded={collapse.openedCollapses.includes(
                                    "collapse3"
                                )}
                                data-parent="#accordion"
                                data-toggle="collapse"
                                onClick={() => collapsesToggle("collapse3")}
                                className="text-left"
                            >
                                <Button
                                    className="btn-icon btn-neutral"
                                    outline
                                    size="sm"
                                    id="EditUser"
                                >
                                    <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                                </Button>
                                Filtro
                    </a>
                        </CardHeader>
                        <Collapse
                            role="tabpanel"
                            isOpen={collapse.openedCollapses.includes(
                                "collapse3"
                            )}
                        >
                            <CardBody>

                                {Menu.RCs && (<>
                                    <Row>
                                    <Col sm="3" className="text-muted">
                                            <Label for="viewTypeM">DF:</Label>
                                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catDFFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dDF}
                                                onChange={(e) => changeDF(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                    </Col>
                                    <Col sm="3" className="text-muted">
                                            <Label for="viewTypeM">DL:</Label>
                                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catDLFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dDL}
                                                onChange={(e) => changeDL(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                    </Col>
                                        <Col sm="3" className="text-muted">
                                            <Label for="viewTypeM">Municipio:</Label>
                                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catMunicipiotFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dMunicipio}
                                                onChange={(e) => changeMunicipioX(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="3" className="text-muted">
                                            <Label for="viewTypeS">Sección:</Label>
                                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catSecciontFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dSeccion}
                                                onChange={(e) => changeSeccionX(e)}
                                                id="viewTypeS"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="3" className="text-muted">
                                            <FormGroup sm="4">
                                                <Label for="casilla">Casilla:</Label>
                                                <Select className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="casilla"
                                                    placeholder="Debe validar la sección"
                                                    options={catCasilla}
                                                    value={filtro.Casilla}
                                                    onChange={(e) => setFiltro({ ...filtro, Casilla: e })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" className="text-muted">
                                            <FormGroup sm="3">
                                                <Label for="propietario">Tipo Propietario:</Label>
                                                <Select className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="propietario"
                                                    placeholder="Debe validar la casilla"
                                                    options={catPropietario}
                                                    value={filtro.TipoCasilla}
                                                    onChange={(e) => setFiltro({ ...filtro, TipoCasilla: e })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" className="text-muted">
                                            <Label for="icveElector">Clave Elector:</Label>
                                            <Input id="icveElector" value={filtro.ClaveElector} onChange={(e) => setFiltro({ ...filtro, ClaveElector: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">
                                            <Label for="iCelular">Celular:</Label>
                                            <Input id="iCelular" value={filtro.Celular} onChange={(e) => setFiltro({ ...filtro, Celular: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">
                                            <Label for="inombre">Nombre:</Label>
                                            <Input id="inombre" value={filtro.Nombre} onChange={(e) => setFiltro({ ...filtro, Nombre: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">
                                            <Label for="ipaterno">Paterno:</Label>
                                            <Input id="ipaterno" value={filtro.Paterno} onChange={(e) => setFiltro({ ...filtro, Paterno: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">
                                <Label for="materno">Materno:</Label>
                                <Input id="materno" value={filtro.Materno} onChange={(e)=>setFiltro({...filtro,Materno:e.target.value})} /></Col>	

                                        <FormGroup check inline>
                                            <br />
                                            <Label check className="text-center">
                                                <Input type="checkbox" name='chek1' checked={filtro.Funcionario} onClick={(e) => setFiltro({ ...filtro, Funcionario: e.target.checked })} />
                                                <span className="form-check-sign" />¿Ha sido funcionario?
                                </Label>
                                        </FormGroup>{" "}
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={filtro.Auto} onClick={(e) => setFiltro({ ...filtro, Auto: e.target.checked })} />
                                                <span className="form-check-sign" />¿Tiene Auto?
                                </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" name='chek1' checked={filtro.CuidarCasilla} onClick={(e) => setFiltro({ ...filtro, CuidarCasilla: e.target.checked })} />
                                                <span className="form-check-sign" />¿Puede cuidar otra casilla?
                                </Label>
                                        </FormGroup>
                                    </Row>
                                </>)}
                                {Menu.RGs && (<>
                                    <Row>
                                        <Col sm="3" className="text-muted">Región:
                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catRegionestFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dRegion}
                                                onChange={(e) => changeRegionX(e)}
                                                id="viewTypeR"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="3" className="text-muted">Municipio:
                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catMunicipiotFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dMunicipio}
                                                onChange={(e) => changeMunicipioX(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="3" className="text-muted">Polígono:
                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catPoligonostFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dPoligono}
                                                onChange={(e) => changePoligonoX(e)}
                                                id="viewTypeP"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        <Col sm="3" className="text-muted">Sección:
                                {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catSecciontFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={dataCombos.dSeccion}
                                                onChange={(e) => changeSeccionX(e)}
                                                id="viewTypeS"
                                                placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="3" className="text-muted">Clave Elector:<Input id="icveElector" value={filtro.ClaveElector} onChange={(e) => setFiltro({ ...filtro, ClaveElector: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">Nombre: <Input id="inombre" value={filtro.Nombre} onChange={(e) => setFiltro({ ...filtro, Nombre: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">Paterno:<Input id="ipaterno" value={filtro.Paterno} onChange={(e) => setFiltro({ ...filtro, Paterno: e.target.value })} /></Col>
                                        <Col sm="3" className="text-muted">Materno:<Input id="" value={filtro.Materno} onChange={(e) => setFiltro({ ...filtro, Materno: e.target.value })} /></Col>
                                    </Row>
                                    <Row>
                                        <Col sm="3" className="text-muted">Sexo:
                                <Select className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="idSexo"
                                                placeholder="Seleccione..."
                                            //options={catFiltros.Sexo}
                                            //value = {Sexo}
                                            // onChange = {(e) => {handleSetElectoral(e);}}
                                            />
                                        </Col>
                                        <Col sm="3" className="text-muted">Fecha:<ReactDatetime onChange={(e) => setFiltro({ ...filtro, Fecha: moment(e._d).format("YYYY-MM-DD") })} /></Col>
                                    </Row>
                                </>)}
                            </CardBody>
                            <CardFooter>
                                <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e) => setFilter()}>
                                    <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                    Filtrar
                        </Button>{' '}
                                <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e) => ClearFilter()}>
                                    <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                                    Limpiar
                        </Button>
                            </CardFooter>
                        </Collapse>
                    </Card>
                </div>
            </CardBody>
        </Card>
    );
};
export default IndexRC;