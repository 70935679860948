import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { CircleSpinner } from "react-spinners-kit";
import Notifications, { notify } from "react-notify-toast";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";

const ColmenaEstructuraCompromisos = (props) => {
  // const { dispatch } = useContext(Context);
  const [estatalDetalle, setEstatalDetalle] = useState({ Municipios: [], Poligonos: [], Regional: [], Secciones: [] });
  const [estatal, setEstatal] = useState({ Regional: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Municipios: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Poligonos: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Secciones: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 } });
  //const [estructura, setEstructura] = useState({ Municipios: [], Poligono: [], Secciones: [] });
  //const [total, setTotal] = useState({ hoy: 0, total: 0, voluntarios: 0 });
  const [loading, setLoading] = useState(false);
  //const [isEditing, setEditing] = useState(false);
  const [_menu, _setmenu] = useState({});
  //const [compromisoEdit, setCompromisoEdit] = useState({});

  const token = getVars("Token");



  const setearMenu = (dependecia) => {
    if (dependecia === "Estado") {
      token.menus.forEach((prop, key) => {
        if (prop.name === "Compromisos") {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,


          });

        }
      });
    } else {
      var json = {};

      token.menus.forEach((prop, key) => {
        if (prop.name === "Compromisos") {
          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
          };
        }
      });

      return json;
    }
  };

  useEffect(() => {
    setearMenu("Estado");
    setLoading(true);
    EjecutarApi({}, "getResumenEstructuraEstatal", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false);
        setEstatal(res.data);
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });

    EjecutarApi({}, "getEstructurabyEstatal", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false);
        setEstatalDetalle(res.data);
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });

    // var dataRegion = [];

    // const infoRegiones = dataRegional.map(item => {
    //   dataRegion.push(<boxRegion key={item.id} item={item} />);
    //   return <boxRegion key={item.id} item={item} />;
    // });

    // setRegiones(dataRegion);
    // setRegiones('Indo=?>', infoRegiones);




  }, []); //loadingTotal, loadingCompromisos

  /*const boxRegion = (data) => {
    data.map(item => {
      return (<div className="example">
        This is {item.Region}
      </div>)
    });
  }*/

  /*const getAvancesRegion = (idRegion) => {


    const data = {
      DeviceID: 'web',
      idRegion
    };

    EjecutarApi(data, 'getEstructurabyRegion', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((dataRS) => {
        setLoading(false);
        if (dataRS.results) {
          //setEstructura(dataRS.data);
        } else {
          //dropDownAlertRef.current.alertWithType('error', 'Error', dataRS.error);
        }
      })
      .catch((e) => {
        setLoading(false);
        //dropDownAlertRef.current.alertWithType('error', 'Error', e.message);
      });
  }*/



  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Regionales</p>
                        <CardTitle tag="p">{`${estatal.Regional.AvanceEstructura} de ${estatal.Regional.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Regional.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Municipales</p>
                        <CardTitle tag="p">{`${estatal.Municipios.AvanceEstructura} de ${estatal.Municipios.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Municipios.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Poligonales</p>
                        <CardTitle tag="p">{`${estatal.Poligonos.AvanceEstructura} de ${estatal.Poligonos.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Poligonos.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Seccionales</p>
                        <CardTitle tag="p">{`${estatal.Secciones.AvanceEstructura} de ${estatal.Secciones.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Secciones.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className="icon-big text-center icon-warning"><h4>Regionales</h4></div>
                </CardHeader>
                <CardBody>
                    {loading && (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loading}
                        />
                    )}
                  <Row>
                    {estatalDetalle.Regional.map(item => {
                      return (<Col key={item.Region} lg="3" md="6" sm="6">
                        <Card style={{ backgroundColor: item.AvanceEstructura === item.MetaEstructuraPartido ? '#FBD16B' : '#DFD9D9' }} className="card-stats">
                          <CardBody>
                            <Row >
                              <Col md="4" xs="5">
                                <div className="icon-big text-center ">
                                  <i className="fa fa-users text-white" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="text-small">
                                  <h5>{item.Region}</h5>
                                  <CardTitle tag="p">{`${item.AvanceEstructura} de ${item.MetaEstructuraPartido}`}</CardTitle>
                                  <p />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter>
                            <hr />
                            <div className="stats">
                              <i className="fa fa-calendar-o" />
                              {item.AvanceEstructura}
                            </div>
                          </CardFooter>
                        </Card>
                      </Col>);
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className="icon-big text-center icon-warning"><h4>Municipales</h4></div>
                </CardHeader>
                <CardBody>
                  <Row>
                    {estatalDetalle.Municipios.map(item => {
                      return (<Col key={item.Municipio} lg="3" md="6" sm="6">
                        <Card style={{ backgroundColor: item.AvanceEstructura === item.MetaEstructuraPartido ? '#FBD16B' : '#DFD9D9' }} className="card-stats">
                          <CardBody>
                            <Row >
                              <Col md="4" xs="5">
                                <div className="icon-big text-center ">
                                  <i className="fa fa-users text-white" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="text-small">
                                  <b>{item.Municipio}</b>
                                  <CardTitle tag="p">{`${item.AvanceEstructura} de ${item.MetaEstructuraPartido}`}</CardTitle>
                                  <p />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter>
                            <hr />
                            <div className="stats">
                              <i className="fa fa-calendar-o" />
                              {item.AvanceEstructura}
                            </div>
                          </CardFooter>
                        </Card>
                      </Col>);
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className="icon-big text-center icon-warning"><h4>Poligonales</h4></div>
                </CardHeader>
                <CardBody>
                  <Row>
                    {estatalDetalle.Poligonos.map(item => {
                      return (<Col key={item.Poligono} lg="3" md="6" sm="6">
                        <Card style={{ backgroundColor: item.AvanceEstructura === item.MetaEstructuraPartido ? '#FBD16B' : '#DFD9D9' }} className="card-stats">
                          <CardBody>
                            <Row >
                              <Col md="4" xs="5">
                                <div className="icon-big text-center ">
                                  <i className="fa fa-users text-white" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="text-small">
                                  <b>{item.Municipio}</b><br />
                                  <b>{item.Poligono}</b>
                                  <CardTitle tag="p">{`${item.AvanceEstructura} de ${item.MetaEstructuraPartido}`}</CardTitle>
                                  <p />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter>
                            <hr />
                            <div className="stats">
                              <i className="fa fa-calendar-o" />
                              {item.AvanceEstructura}
                            </div>
                          </CardFooter>
                        </Card>
                      </Col>);
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className="icon-big text-center icon-warning"><h4>Seccionales</h4></div>
                </CardHeader>
                <CardBody>
                  <Row>
                    {estatalDetalle.Secciones.map(item => {
                      return (<Col key={item.Seccion} lg="3" md="6" sm="6">
                        <Card style={{ backgroundColor: item.AvanceEstructura === item.MetaEstructuraPartido ? '#FBD16B' : '#DFD9D9' }} className="card-stats">
                          <CardBody>
                            <Row >
                              <Col md="4" xs="5">
                                <div className="icon-big text-center ">
                                  <i className="fa fa-users text-white" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="text-small">
                                  <b>{item.Municipio}</b><br />
                                  <b>{item.Seccion}</b>
                                  <CardTitle tag="p">{`${item.AvanceEstructura} de ${item.MetaEstructuraPartido}`}</CardTitle>
                                  <p />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter>
                            <hr />
                            <div className="stats">
                              <i className="fa fa-calendar-o" />
                              {item.AvanceEstructura}
                            </div>
                          </CardFooter>
                        </Card>
                      </Col>);
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
        <Footer fluid />
      </div>
    </div >
  );
};

export default ColmenaEstructuraCompromisos;
