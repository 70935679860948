import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row,Col,Collapse,Input ,FormGroup,Label} from 'reactstrap';
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";
import Autosuggest from "react-autosuggest";


function IndexCompromiso ({Datos}){
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
    const [catPropietarios,setCatPropietarios] = useState([
        {value:2,label:'Todos'},
        {value:0,label:'Sin Propietario'},
        {value:1,label:'Con Propietario'},
    ]);

    const [catCapturista,setCatCapturista] = useState([{value:0,label:'Seleccionar...'}]);
	const [filtro,setFiltro] = useState({
		Region:{},
		Municipio:{},
		Poligono:{},
		Seccion:'',
        P1:{value:2,label:'Todos'},
        P2:{value:2,label:'Todos'},
        S1:{value:2,label:'Todos'},
        S2:{value:2,label:'Todos'},
    })
    const [catRegionestFilter,setCatRegionestFilter]=useState([])
    const [catPoligonost, setCatPoligonost] = useState([])
    const [catPoligonostFilter,setCatPoligonostFilter] = useState([])
    const [catMunicipiotFilter, setCatMunicipiotFilter] = useState([])
    const [catMunicipiot,setCatMunicipiot] = useState([])
    const [catSecciont,setCatSecciont]=useState([])
    
    const [isModal, setisModal] = useState(false)
    const [catSecciontFilter,setCatSecciontFilter] = useState([])
    const [dataCombos, setDataCombo] = useState({ 
        dRegion: { label: 'Filtrar', value: 0 }, 
        dMunicipio: { label: 'Filtrar', value: 0 }, 
        dPoligono: { label: 'Filtrar', value: 0 },
        dSeccion: { label: 'Filtrar', value: 0 } ,
        dP1: { label: 'Filtrar', value: 2 },
        dP2: { label: 'Filtrar', value: 2 },
        dS1: { label: 'Filtrar', value: 2 },
        dS2: { label: 'Filtrar', value: 2 }
    })
    const [collapse, setCollapse] = useState({
		horizontalTabs: "home",
		verticalTabs: "info",
		pageTabs: "homePages",
		openedCollapses: ["collapseOne", "collapse1"]
    });
    const [Responsable, setResponsable] = useState({
        value: 0,
        label: ''
    });
    useEffect(()=>{
        setLoading(true);
        //getCatalogosFiltrosPromocion  getCatRegion
        EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token)
        .then(res => {
          if(res.results){
              if (res.data) {
                let regiones = [{ value: 0, label: 'Todos' }]
                let regionesFormated = [];
                res.data.regiones.forEach(item => {
                    if(item.value>1)
                    {
                        regionesFormated.push(
                            {value: item.value,
                            label: item.label})
                        }
                    }    
                )
                setCatRegionestFilter(regiones.concat(regionesFormated));

                let municipios = [{ value: 0, label: 'Seleccionar...' }]
                let municipiosFormated = res.data.municipios.map(item => {
                    return {
                    value: item.value,
                    label: item.label,
                    idRegion: item.idRegion,
                    }
                })
                setCatMunicipiot(municipios.concat(municipiosFormated));
                setCatMunicipiotFilter(municipios.concat(municipiosFormated));

                let poligonos = [{ value: 0, label: 'Seleccionar...' }]
                let poligonosFormated = [];
                res.data.poligonos.forEach(item => {
                    if(item.value>1)
                        poligonosFormated.push( {
                            value: item.value,
                            label: item.label,
                            idMunicipio: item.idMunicipio,
                            idRegion: item.idRegion,
                    })
                })
                setCatPoligonost(poligonos.concat(poligonosFormated));
                setCatPoligonostFilter(poligonos.concat(poligonosFormated));

                let seccion = [{ value: 0, label: 'Seleccione' }]
                let seccionFormated = res.data.secciones.map(item => {
                    return {
                        value: item.value,
                        label: item.label,
                        idRegion: item.idRegion,
                        idMunicipio: item.idMunicipio,
                        idPoligono: item.idPoligono
                    }
                })
                setCatSecciont(seccion.concat(seccionFormated));
                setCatSecciontFilter(seccion.concat(seccionFormated));
                setLoading(false);
            }
          }
        })
        .catch((e) => {
        })
    },[])

    const collapsesToggle = (colapse) => {
		let openedCollapses = collapse.openedCollapses;
		if (openedCollapses.includes(colapse)) {
		  setCollapse({...collapse,
			openedCollapses: openedCollapses.filter(item => item !== colapse)
		  });
		} else {
		  openedCollapses.push(colapse);
		  setCollapse({...collapse, 
			openedCollapses: openedCollapses
		  });
		}
    };
    const changeRegionX = (dRegion) => {
      
        setLoading(true);
    
        if(dRegion.value>1)
        {
          setDataCombo({ ...dataCombos, dRegion, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion:{ label: 'Seleccione', value: 0 }  });
          setCatMunicipiotFilter(catMunicipiot.filter(data => data.idRegion === dRegion.value));
          setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idRegion) === dRegion.value));
          setCatSecciontFilter(catSecciont.filter(data =>  data.idRegion === dRegion.value))
        }else{
          setDataCombo({ ...dataCombos, dRegion:{ label: 'Seleccione', value: 0 }, dMunicipio: { label: 'Seleccione', value: 0 }, dPoligono: { label: 'Seleccione', value: 0 } });
          setCatMunicipiotFilter(catMunicipiot);
          setCatPoligonostFilter(catPoligonost);
        }
        setLoading(false);
    }
    const changeMunicipioX = (dMunicipio) => {

        setLoading(true);
        if(dMunicipio.value>1)
        {
            setDataCombo({ ...dataCombos, dMunicipio:dMunicipio, dPoligono: { label: 'Seleccione', value: 0 } , dSeccion:{ label: 'Seleccione', value: 0 } });
            setCatPoligonostFilter(catPoligonost.filter(data => parseInt(data.idMunicipio) === dMunicipio.value));
            setCatSecciontFilter(catSecciont.filter(data =>  data.idMunicipio === dMunicipio.value))
        }else
        {
            setDataCombo({ ...dataCombos,  dMunicipio:dMunicipio, dPoligono: { label: 'Seleccione', value: 0 }, dSeccion:{ label: 'Seleccione', value: 0 } });
            setCatSecciontFilter(catSecciont);
            setCatPoligonostFilter(catPoligonost);
        }
         setLoading(false);
      }
    
      const changePoligonoX = (dPoligono) => {
        setLoading(true);
        if(dPoligono.value > 1){
            setDataCombo({
                ...dataCombos, 
                dPoligono,
                dSeccion:{ label: 'Seleccione', value: 0 }
            })
            setCatSecciontFilter(catSecciont.filter(data =>  data.idPoligono === dPoligono.value))
        }else{
            setDataCombo({ ...dataCombos, dPoligono });
            setCatSecciontFilter(catSecciont);
        }
        setLoading(false);
      }
      const changeSeccionX =(dSeccion)=>{
          
            setLoading(true);
            setDataCombo({ ...dataCombos, dSeccion });
            setLoading(false);
      }

    const setFilter=(event)=>{
        var filtrar = {
            Region:dataCombos.dRegion,
            Municipio:dataCombos.dMunicipio,
            Poligono:dataCombos.dPoligono,
            Seccion:dataCombos.dSeccion,
            P1:filtro.P1,
            P2:filtro.P2,
            S1:filtro.S1,
            S2:filtro.S2,
        }
       
        Datos(filtrar);
    }

    const ClearFilter=()=>{
        setDataCombo({ ...dataCombos, 
        dRegion:{ label: 'Seleccione', value: 0 },
        dMunicipio: { label: 'Seleccione', value: 0 },
        dPoligono: { label: 'Seleccione', value: 0 }, 
        dSeccion:{ label: 'Seleccione', value: 0 } ,
        dP1: { label: 'Filtrar', value: 0 },
        dP2: { label: 'Filtrar', value: 0 },
        dS1: { label: 'Filtrar', value: 0 },
        dS2: { label: 'Filtrar', value: 0 }
    });

        let _filtro =
        {
            Region:{},
            Municipio:{},
            Poligono:{},
            Seccion:'',
            P1:{value:0,label:'Seleccionar...'},
            P2:{value:0,label:'Seleccionar...'},
            S1:{value:0,label:'Seleccionar...'},
            S2:{value:0,label:'Seleccionar...'},
        }
        setFiltro(_filtro);
        Datos(_filtro);

    }
    
    return (
        <Card className="card-plain">
            <CardBody>
                <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                >
                <Card className="card-plain">
                    <CardHeader role="tab" className='text-left'>
                        <a aria-expanded={collapse.openedCollapses.includes( "collapse3")}
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={() => collapsesToggle("collapse3")}
                            className="text-left">
                            <Button className="btn-icon btn-neutral"outline size="sm" id="EditUser" >
                                <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                            </Button>Filtro
                        </a>
                    </CardHeader>
                    <Collapse role="tabpanel"
                        isOpen={collapse.openedCollapses.includes(
                            "collapse3"
                        )}
                    >
                    <CardBody>
                        <Row>
                            <Col sm="3" className="text-muted">Región: 
                                    <Select
                                    options={catRegionestFilter}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={dataCombos.dRegion}
                                    onChange={(e) => changeRegionX(e)}
                                    id="viewTypeR"
                                    placeholder="Filtrar por"
                                    isLoading={loading}
                                    loadingMessage={"Cargando Filtros..."}
                                    />
                            </Col>
                            <Col sm="3" className="text-muted">Municipio: 
                            <Select
                                options={catMunicipiotFilter}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={dataCombos.dMunicipio}
                                onChange={(e) => changeMunicipioX(e)}
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                isLoading={loading}
                                loadingMessage={"Cargando Filtros..."}
                                />
                            </Col>
                            <Col sm="3" className="text-muted">Seccion:
                              <Select
                                    options={catSecciontFilter}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={dataCombos.dSeccion}
                                    onChange={(e) => changeSeccionX(e)}
                                    id="viewTypeS"
                                    placeholder="Filtrar por"
                                    isLoading={loading}
                                    loadingMessage={"Cargando Filtros..."}
                                />
                            </Col>
                            <Col sm="2" className="text-muted">Propietario 1:
                                <Select
                                        options={catPropietarios}
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={filtro.P1}
                                        onChange={(e) => setFiltro({...filtro, P1:e})}
                                        id="TipoPropietario"
                                        placeholder="Filtrar por..."
                                    />         
                            </Col>
                            <Col sm="2" className="text-muted"> Propietario 2:
                                <Select
                                        options={catPropietarios}
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={filtro.P2}
                                        onChange={(e) => setFiltro({...filtro, P2:e})}
                                        id="Propietario"
                                        placeholder="Filtrar por..."
                                    />         
                            </Col>
                            <Col sm="2" className="text-muted">Suplente 1:
                                <Select
                                        options={catPropietarios}
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={filtro.S1}
                                        onChange={(e) => setFiltro({...filtro, S1:e})}
                                        id="TipoPropietario"
                                        placeholder="Filtrar por..."
                                    />         
                            </Col>
                            <Col sm="2" className="text-muted"> Suplente 2:
                                <Select
                                        options={catPropietarios}
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={filtro.S2}
                                        onChange={(e) => setFiltro({...filtro, S2:e})}
                                        id="Propietario"
                                        placeholder="Filtrar por..."
                                    />         
                            </Col>
                        </Row>  
                    </CardBody>
                    <CardFooter>
                        <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e)=>setFilter()}>
                            <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                            Filtrar
                        </Button>{' '}
                        <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e)=> ClearFilter()}>
                            <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                            Limpiar
                        </Button>
                        
                    </CardFooter>
                </Collapse>
                </Card>
            </div>
            </CardBody>
        </Card>
    );
};
export default IndexCompromiso;