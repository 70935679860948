import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";

import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
//import Filter from "../components/Filtro/compromisoFiltro";

import { URL_GENERAL } from "data/Consts";
import EjecutarApi from "data/getPOST";
import { getVars, URL_PUBLICA } from "GlobalStorage";
import { getDataMenu } from "data/menus";


const AvancesCompromisosMunicipio = (props) => {
  // const { dispatch } = useContext(Context);
  const [estatalDetalle, setEstatalDetalle] = useState([]);
  const [estatal, setEstatal] = useState({ Regional: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Municipios: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Poligonos: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Secciones: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 } });
  const [loading, setLoading] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [total, setTotal] = useState(0);
  const [Filtro, setFiltro] = useState([]);
  const [catMunicipio, setCatMunicipio] = useState([{ value: 0, label: 'Todos' }])
  const [catRegiones, setCatRegiones] = useState([{ value: 0, label: 'Todos' }])

  const [catMunicipioFilter, setCatMunicipioFilter] = useState([])
  const [municipioSelected, setMunicipioSelected] = useState({ value: 0, label: 'Todos' });
  const [regionSelected, setRegionSelected] = useState({ value: 0, label: 'Todos' })

  const token = getVars("Token");
  const responsibilityID = token.user.Responsabilidad.value;

  useEffect(() => {
    _setmenu(getDataMenu(40, token.menus))

    let regionesFormated = [];
    let municipioFormated = [];
    EjecutarApi({}, "getCatalogosFiltrosPromocionClonIdMunicipioReportes", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {
            let regiones = [{ value: 0, label: 'Todos' }]
            let municipios = [{ value: 0, label: 'Todos' }]

            regionesFormated = res.data.regiones.map(item => {
              return {
                value: item.value,
                label: item.label
              }
            })
            const result = regionesFormated.filter(reg => reg.label !== 'NO DEFINIDO');
            setCatRegiones(regiones.concat(result))
            //CatMunicipio llenar

            municipioFormated = res.data.municipios.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
              }
            })
            setCatMunicipio(municipios.concat(municipioFormated))
            setCatMunicipioFilter(municipios.concat(municipioFormated))

          } else {
            showMessage("No Info: getCatalogosFiltrosPromocion", '#0E7CF1')
          }
        }
      })
      .catch((e) => {
        showMessage("Error: getCatalogosFiltrosPromocion", "#F1370E")
      }).then(() => {
        setLoading(false);
      })

    EjecutarApi({}, "getResumenEstructuraEstatal", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false);
        setEstatal(res.data);
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });



  }, []); //loadingTotal, loadingCompromisos


  const showMessage = (message, background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }

  const fitroSend = (e, handle) => {
    var filtro = [];
    if (handle !== "clear") {
      if (e.value > 0 && handle === "region") { filtro.push({ "id": "r.id", "value": e.value }) }
      if (e.value > 0 && handle === "municipio") { filtro.push({ "id": "MA.id", "value": e.value }) }

      if (regionSelected.value > 0 && handle !== "region") { filtro.push({ "id": "r.id", "value": regionSelected.value }) }
      if (municipioSelected.value > 0 && handle !== "municipio") { filtro.push({ "id": "MA.id", "value": municipioSelected.value }) }
    }
    if (e === 0 && handle === "") { setFiltro(Filtro) }
    else { setFiltro(filtro); }

    const data = {
      page: 0,
      pageSize: 10,
      sorted: [{ "id": "MA.AvanceCompromisosUnicos", "desc": true }],
      tipo: 'and',
      filtered: filtro
    };
    setLoading(true);
    EjecutarApi(data, "getAvancesCompromisosMunicipio", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setLoading(false);
            setTotal(res.total);
            setEstatalDetalle(
              res.data.map((prop, key) => {
                return {
                  Region: prop.Region,
                  Municipio: prop.Municipio,
                  Poligono: prop.Poligono,
                  Meta: prop.Meta,
                  CompromisosUnicos: prop.CompromisosUnicos,
                  AvanceEsperado: prop.AvanceEsperado,
                  PorcentajeAvance: new Intl.NumberFormat().format(prop.PorcentajeAvance),
                  data: prop
                };
              })
            );
          }
        }

      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener getMetaCompromisosFiltro: ${e.error}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
  }

  const LimpiarFiltro = () => {
    setRegionSelected({ value: 0, label: 'Todos' })
    setMunicipioSelected({ value: 0, label: 'Todos' })

    fitroSend("", "clear");
  }

  const handleRegiones = (e) => {
    setRegionSelected(e)

    if (e.value > 1) {
      let municipios = [{ value: 0, label: 'Todos' }]
      setCatMunicipioFilter(municipios.concat(catMunicipio.filter(data => data.idRegion === e.value)));
      fitroSend(e, "region");

    } else {
      setCatMunicipioFilter(catMunicipio);
    }
  }

  const handleMunicipio = (e) => {
    setMunicipioSelected(e)

    if (e.value > 1) {

      fitroSend(e, "municipio");

    } else {

      setCatMunicipioFilter(catMunicipio);
      fitroSend("", "clear");

    }
  }

  const Exportar = () => {
    setLoading(true)
    var url = new URL(
      `${URL_GENERAL}getReporteAvanceMunicipal?token=${token.access_token}`
    );
    window.open(url, "_blank");
    setLoading(false)
  }

  const styleHeader = {
    textAlign: "center",
    overflow: "visible",
    fontSize: "11px",
    color: "#363636",
    lineHeight: "normal !important"
  }

  const header = (text) => {
    return (<Label style={styleHeader}>{text}</Label>)
  }



  const showMunicipio = (responsibilityID === 10) || (responsibilityID !== 12 && responsibilityID !== 13 && responsibilityID !== 53 && responsibilityID !== 54)


  const showRegion = responsibilityID !== 11 && responsibilityID !== 12 && responsibilityID !== 13 && responsibilityID !== 53 && responsibilityID !== 54 && responsibilityID !== 55

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Municipales</p>
                        <CardTitle tag="p">{`${estatal.Municipios.AvanceEstructura} de ${estatal.Municipios.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Municipios.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Poligonales</p>
                        <CardTitle tag="p">{`${estatal.Poligonos.AvanceEstructura} de ${estatal.Poligonos.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Poligonos.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Seccionales</p>
                        <CardTitle tag="p">{`${estatal.Secciones.AvanceEstructura} de ${estatal.Secciones.MetaEstructuraPartido}`}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    {`${new Intl.NumberFormat().format(estatal.Secciones.AvanceCompromisos)}`}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardTitle>
                  <Col>
                    <Row>
                      {
                        showRegion ? (
                          <Col lg="2" md="2" sm="2">
                            <FormGroup>
                              <Label for="Estado">
                                Región
                      </Label>
                              <Select
                                options={catRegiones}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                defaultValue={catRegiones[0]}
                                value={regionSelected}
                                onChange={(e) => handleRegiones(e)}
                                id="regiones"
                                placeholder="regiones"
                              />
                            </FormGroup>
                          </Col>
                        ) : (<></>)
                      }
                      {showMunicipio ? (
                        <Col lg="4" md="4" sm="4">
                          <FormGroup>
                            <Label for="Municipio">
                              Municipios
                      </Label>
                            <Select
                              options={catMunicipioFilter}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={municipioSelected}
                              onChange={(e) => handleMunicipio(e)}
                              id="municipio"
                              placeholder="Municipio"
                            />
                          </FormGroup>
                        </Col>
                      ) : (<></>)
                      }

                    </Row>
                    <Row>
                      <Col md="12" lg="12">
                        <Col sm='6'>
                          {(![53, 54, 55].includes(responsibilityID)) && (
                            <Button name="btnLimpiar" size="sm" type="button" className="success float-left" color='warning' onClick={() => { LimpiarFiltro() }}>Limpiar</Button>
                          )}
                        </Col>
                        <Col>
                          {_menu.Exportar === 1 ? (
                            <Button color="success" size="sm" className='primary float-right' onClick={() => Exportar()}>
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-lg" />
                              </span>
                              Exportar
                  </Button>
                          ) : <></>}
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </CardTitle>
                {loading && (
                  <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={loading}
                  />
                )}
                <Col lg="12" md="12">
                  <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                    <ReactTable
                      style={{
                        width: "100%",
                        height: "100%"
                      }}
                      data={estatalDetalle}
                      noDataText="Sin información"
                      manual
                      columns={[
                        {
                          Header: <>{responsibilityID === 53 ? ("Distrito Federal") : (responsibilityID === 54 ? ("Distrito Local") : ("Region"))}</>,
                          minWidth: 30,
                          id: "Region",
                          accessor: (d) => d.Region,
                          filterAll: false,
                        },
                        {
                          Header: header("Municipio"),
                          minWidth: 50,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                        },
                        {
                          Header: header("Meta"),
                          minWidth: 40,
                          id: "meta",
                          accessor: (d) => d.Meta,
                          filterAll: false,
                        },
                        {
                          Header: header("Avance Esperado"),
                          minWidth: 40,
                          id: "AvanceEsperado",
                          accessor: (d) => d.AvanceEsperado,
                          filterAll: false,
                        },
                        {
                          Header: header("Compromisos"),
                          minWidth: 40,
                          id: "CompromisosUnicos",
                          accessor: (d) => d.CompromisosUnicos,
                          filterAll: false,
                        },
                        {
                          Header: header("Porcentaje de Avance"),
                          minWidth: 40,
                          id: "PorcentajeAvance",
                          accessor: (d) => d.PorcentajeAvance,
                          Cell: props => <div>{props.value + " %"}</div>,
                          filterAll: false,
                        }
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={total}
                      pages={Math.floor(total / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      PaginationComponent={Pagination}
                      onFetchData={(stateComp, instance) => {
                        setLoading(true);
                        const data = {
                          page: stateComp.page,
                          pageSize: stateComp.pageSize,
                          sorted: [],
                          tipo: 'and',
                          filtered: Filtro ? Filtro : []
                        };
                        EjecutarApi(data, "getAvancesCompromisosMunicipio", token.access_token)
                          .then(function (res) {
                            return res;
                          })
                          .then((res) => {
                            if (res.success) {
                              if (res.results) {
                                setLoading(false);
                                setTotal(res.total);
                                setEstatalDetalle(
                                  res.data.map((prop, key) => {
                                    return {
                                      Region: prop.Region,
                                      Municipio: prop.Municipio,
                                      Poligono: prop.Poligono,
                                      Meta: prop.Meta,
                                      CompromisosUnicos: prop.CompromisosUnicos,
                                      AvanceEsperado: prop.AvanceEsperado,
                                      PorcentajeAvance: new Intl.NumberFormat().format(prop.PorcentajeAvance),
                                      data: prop
                                    };
                                  })
                                );
                              }
                            }

                          })
                          .catch((e) => {
                            notify.show(
                              `Error al obtener tabla metas: ${e.message}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );
                          });
                      }}

                    />
                  </div>
                </Col>
              </Card>
            </Col>
          </Row>


        </div>
        <Footer fluid />
      </div>
    </div >
  );
};

export default AvancesCompromisosMunicipio;
