import React from "react";
export const SinPermisos = () => {

    return (
        <>
            <div className="content">
                <h3>¡No cuentas con permisos para ver el mapa!</h3>
            </div>
        </>

    )

}
