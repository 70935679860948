import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Collapse,
  Input,
} from 'reactstrap';
import Select from 'react-select';

import { CircleSpinner } from 'react-spinners-kit';

const Filtro = ({ data, searchFilter, handleSearchRuta }) => {
  const [municipios, setMunicipios] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [poligonos, setPoligonos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [collapse, setCollapse] = useState({
    horizontalTabs: 'home',
    verticalTabs: 'info',
    pageTabs: 'homePages',
    openedCollapses: ['collapseOne', 'collapse1'],
  });

  const [filtros, setFiltros] = useState({
    municipio: null,
    seccion: null,
    poligono: null,
    region: null,
  });

  const [textInput, setTextInput] = useState('');

  useEffect(() => {
    setMunicipios([{ value: 0, label: 'Todos' }].concat(data.municipios));
    setRegiones([{ value: 0, label: 'Todos' }].concat(data.regiones));
    setPoligonos([{ value: 0, label: 'Todos' }].concat(data.poligonos));
    setSecciones([{ value: 0, label: 'Todos' }].concat(data.secciones));
    setIsLoading(false);
  }, []);

  const collapsesToggle = (colapse) => {
    let openedCollapses = collapse.openedCollapses;
    if (openedCollapses.includes(colapse)) {
      setCollapse({
        ...collapse,
        openedCollapses: openedCollapses.filter((item) => item !== colapse),
      });
    } else {
      openedCollapses.push(colapse);
      setCollapse({ ...collapse, openedCollapses: openedCollapses });
    }
  };

  const changeRegion = (region) => {
    setIsLoading(true);
    setFiltros({
      ...filtros,
      region: region,
      municipio: { label: 'Seleccione', value: 0 },
      poligono: { label: 'Seleccione', value: 0 },
      seccion: { label: 'Seleccione', value: 0 },
    });
    if (region.value > 1) {
      setMunicipios(
        municipios.filter((municipio) => municipio.idRegion === region.value)
      );
    } else {
      setMunicipios([{ value: 0, label: 'Todos' }].concat(data.municipios));
      setPoligonos([{ value: 0, label: 'Todos' }].concat(data.poligonos));
      setSecciones([{ value: 0, label: 'Todos' }].concat(data.secciones));
    }
    setIsLoading(false);
  };

  const changeMunicipio = (municipio) => {
    setIsLoading(true);
    setFiltros({
      ...filtros,
      municipio: municipio,
      poligono: { label: 'Seleccione', value: 0 },
      seccion: { label: 'Seleccione', value: 0 },
    });
    if (municipio.value > 1) {
      setPoligonos(
        poligonos.filter((poligono) => poligono.idMunicipio === municipio.value)
      );
    } else {
      setPoligonos([{ value: 0, label: 'Todos' }].concat(data.poligonos));
      setSecciones([{ value: 0, label: 'Todos' }].concat(data.secciones));
    }
    setIsLoading(false);
  };

  const changePoligono = (poligono) => {
    setIsLoading(true);
    setFiltros({
      ...filtros,
      poligono: poligono,
      seccion: { label: 'Seleccione', value: 0 },
    });
    if (poligono.value > 1) {
      setSecciones(
        secciones.filter((seccion) => seccion.idPoligono === poligono.value)
      );
    } else {
      setSecciones([{ value: 0, label: 'Todos' }].concat(data.secciones));
    }
    setIsLoading(false);
  };

  const changeSeccion = (seccion) => {
    setIsLoading(true);
    setFiltros({
      ...filtros,
      seccion: seccion,
    });
    setIsLoading(false);
  };

  const setFilter = () => {
    let tipo = 'and'
    const filtroSend = [];
    if (filtros.region && filtros.region.value > 0)
      filtroSend.push({ id: 'regiones.id', value: filtros.region.value });
    if (filtros.municipio && filtros.municipio.value > 0)
      filtroSend.push({ id: 'municipios.id', value: filtros.municipio.value });
    if (filtros.poligono && filtros.poligono.value > 0)
      filtroSend.push({ id: 'poligonos.id', value: filtros.poligono.value });
    if (filtros.seccion && filtros.seccion.value > 0)
      filtroSend.push({
        id: 'secciones.Seccion',
        value: filtros.seccion.value,
      });

    if (textInput.length !== 0) {
      if (parseInt(textInput, 10) !== NaN) {
        filtroSend.push({ id: 'rutas.NombreRuta', value: textInput });
        filtroSend.push({ id: 'seccion_rutas.Seccion', value: textInput });
      } else {
        filtroSend.push({ id: 'rutas.NombreRuta', value: textInput });
      }
      tipo = "or"
    }

    const data_ = {
      page: 0,
      pageSize: 10,
      filtered: filtroSend,
      sort: [{ id: 'rutas.NombreRuta', desc: false }],
      tipo: tipo,
    };
    searchFilter(data_);
  };

  const ClearFilter = () => {
    setFiltros({
      municipio: null,
      seccion: null,
      poligono: null,
      region: null,
    });
    setTextInput('');
    setMunicipios([{ value: 0, label: 'Todos' }].concat(data.municipios));
    setRegiones([{ value: 0, label: 'Todos' }].concat(data.regiones));
    setPoligonos([{ value: 0, label: 'Todos' }].concat(data.poligonos));
    setSecciones([{ value: 0, label: 'Todos' }].concat(data.secciones));
  };

  return (
    <Card className="card-plain">
      <CardBody>
        <div
          aria-multiselectable={true}
          className="card-collapse"
          id="accordion"
          role="tablist"
        >
          <Card className="card-plain">
            <CardHeader role="tab">
              <a
                aria-expanded={collapse.openedCollapses.includes('collapse3')}
                data-parent="#accordion"
                data-toggle="collapse"
                onClick={() => collapsesToggle('collapse3')}
                className="text-right"
              >
                <i className="fa fa-filter fa-lg" aria-hidden="true"></i> Filtro
              </a>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={collapse.openedCollapses.includes('collapse3')}
            >
              {!isLoading && data && (
                <>
                  <CardBody>
                    <Row>
                      <Col sm="3" className="text-muted">
                        Región:
                        <Select
                          options={regiones}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={filtros.region}
                          onChange={(e) => changeRegion(e)}
                          id="viewTypeS"
                          placeholder="Filtrar por"
                        />
                      </Col>
                      <Col sm="3" className="text-muted">
                        Municipio:
                        <Select
                          options={municipios}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={filtros.municipio}
                          onChange={(e) => changeMunicipio(e)}
                          id="viewTypeS"
                          placeholder="Filtrar por"
                        />
                      </Col>
                      <Col sm="3" className="text-muted">
                        Polígono:
                        <Select
                          options={poligonos}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={filtros.poligono}
                          onChange={(e) => changePoligono(e)}
                          id="viewTypeS"
                          placeholder="Filtrar por"
                        />
                      </Col>
                      <Col sm="3" className="text-muted">
                        Sección:
                        <Select
                          options={secciones}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={filtros.seccion}
                          onChange={(e) => changeSeccion(e)}
                          id="viewTypeS"
                          placeholder="Filtrar por"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3" className="text-muted">
                        <p>Filtro general</p>
                        <Input
                          type="text"
                          id="Input"
                          value={textInput}
                          onChange={(e) => {
                            setTextInput(e.target.value);
                          }}
                          placeholder="RUTA O SECCIÓN"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      size="sm"
                      id="btnfiltro"
                      color="primary"
                      className="primary"
                      onClick={(e) => setFilter()}
                    >
                      <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                      Filtrar
                    </Button>{' '}
                    <Button
                      size="sm"
                      id="btnfiltro"
                      color="danger"
                      className="danger"
                      onClick={(e) => ClearFilter()}
                    >
                      <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                      Limpiar
                    </Button>
                  </CardFooter>
                </>
              )}
              {isLoading && (
                <CircleSpinner
                  className="text-center"
                  size={30}
                  color="#686769"
                  loading={isLoading}
                />
              )}
            </Collapse>
          </Card>
        </div>
      </CardBody>
    </Card>
  );
};

export default Filtro;
