import React from "react";

import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official';

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
})

HC_exporting(Highcharts);


const ChartProductividad = ({ Categorias, Series, Titulo, TituloMpio }) => {
    const options = {
        chart: {
            type: 'line'
        },
        title: {
            text: Titulo
        },
        colors:['#6199D0','#013F9B'],
        credits: {
            enabled: false
        },
        subtitle: {
            text: TituloMpio
        },
        xAxis: {
            categories: Categorias
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
        },
        series: Series
    }
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            type='column'
        />
    )
}

export default ChartProductividad;