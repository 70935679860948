import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, Progress, Badge, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import CasillasResumenChart from "components/Sije/CasillasResumenChart";
import useWindowDimensions from "components/useWindowDimensions";

const Acta = ({ data, isLoading, tipoEleccion }) => {
    const { height, width } = useWindowDimensions();
    const [total, setTotal] = useState({
        Favorables: 0,
        Competidas: 0,
        Adversas: 0,
    });

    const [dataChart, setDataChart] = useState({
        data: [0, 0, 0],
        height: height * 0.15,
        names: ["P1", "S1", "S2"]
    })

    /* useEffect(() => {
       
        setTotal(data);
        setDataChart({ ...dataChart, data: [{y:data.Favorables,color:"#43AED2"}, {y:data.Competidas, color:"#FABB47"},{y:data.Adversas,color:"#A90011"}]});
    }, [data]) */

    return (
        <Row>
            <Col >
                <Card className="card-stats">
                    <CardHeader style={{ backgroundColor: "#001689" }}>
                        <Row>
                            <Col>
                                {/* <div className="pull-right text-white" style={{ fontSize: "30px" }}>16</div> */}
                                <h5 className="text-white text-medium margin-none strong"><i className="fa fa-file"></i> Actas Recibidas</h5>
                                {/* <h7 class="text-white">Casillas Incidentadas 2.36%</h7> */}
                                {/* <Progress max={100} animated value={20} className="bg-info-dark" color="white">
                        </Progress> */}
                                {/* <br /> */}
                            </Col>
                            {isLoading && (
                                <Col md="2">
                                    <Spinner
                                        className='text-white text-medium margin-none strong'
                                    />
                                </Col>
                            )}
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            {/* <Col md="8" lg="8" sm="12">
                                <CasillasResumenChart data={dataChart}></CasillasResumenChart>
                            </Col> */}
                            <Col md="12" lg="12" sm="12">
                                {tipoEleccion && <h6>{tipoEleccion}</h6>}
                                <br></br>
                                <br></br>
                                <center><h1><strong>{new Intl.NumberFormat('en-EN').format(data.ActaRecibida)}</strong> de <strong>{new Intl.NumberFormat('en-EN').format(data.TotalActas)}</strong></h1></center>
                                <br></br>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default Acta;