import React, { useState } from 'react';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Label,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Col,
	Row
} from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';
import { MetroSpinner } from 'react-spinners-kit';
import { setVars, URL_PUBLICA } from '../GlobalStorage';
import avatar from '../assets/SIAN.png'
//let URL_PUBLICA = (process.env.NODE_ENV !== 'production') ? process.env.REACT_APP_DEV_HOST : process.env.REACT_APP_PRO_HOST

const Login = () => {
	const [Username, setUsername] = useState('');
	const [Passwd, setPasswd] = useState('');
	const [loading, setLoading] = useState(false);

	let history = useHistory();

	const signIn = () => {
		setLoading(true);
		if (Username.length < 4 || Username.length > 25) {
			notify.show('Error: Usuario requerido y debe contener al menos 4 caracteres y máximo 22.', 'custom', 5000, {
				background: '#0857B6',
				fontSize: 14,
				text: '#FFFFFF'
			});
			setLoading(false);
			return true;
		}

		let numero = new RegExp('[0-9]{5,10}');
		let re = new RegExp('[a-z]\.[a-z]{2,20}');

		/* if ((!numero.test(Username))) {

			if (!re.test(Username)) {

				notify.show('¡Error: Usuario digitado de manera incorrecta!.', 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
				setLoading(false);
				return true;
			}
		} */
		// else if (Username.length <6) {

		// 	notify.show('Error: Usuario digitado de manera incorrecta!', 'custom', 5000, {
		// 		background: '#0857B6',
		// 		fontSize: 14,
		// 		text: '#FFFFFF'
		// 	});
		// 	setLoading(false);
		// 	return true;
		// }


		if (Passwd.length < 6) {
			notify.show('Error: Contraseña requerida.', 'custom', 5000, {
				background: '#0857B6',
				fontSize: 14,
				text: '#FFFFFF'
			});
			setLoading(false);
			return true;
		}

		const data = {
			email: Username,
			password: Passwd,
			message: ''
		};

		const requestInfo = {
			method: 'POST',
			body: JSON.stringify(data),
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		};

		fetch(`${URL_PUBLICA}login`, requestInfo)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Login inválido');
			})
			.then((token) => {
				if (token.status !== 'error') {
					setVars('Token', token);
					setLoading(false);
					history.push(token.user.DefaultPage);
				} else {
					setLoading(false);

					notify.show(`Error de acceso: ${token.message}`, 'error', 5000, '#FFFFFF');
				}
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error de acceso! ${e.message}`, 'custom', 5000, {
					background: '#0E1717',
					text: '#FFFFFF'
				});
			});
	};

	return (
		<div className="wrapper wrapper-full-page">
			<div className="full-page section-image">
				<Notifications />
				<div className="login-page">
					<Container>
						<Row>
							<Col className="ml-auto mr-auto text-white text-center" lg="4" md="6">
								{/* <h1 color="white">
									<strong>En Mantenimiento</strong>
								</h1>
								<div className="photo text-center">
													<img src={avatar} alt="Avatar" height={360} width={360} />
												</div> */}
								<Form className="form" onSubmit={signIn}>
									<Card className="card-login">
										<CardHeader>
											<CardHeader>
												<div className="photo text-center">
													<img src={avatar} alt="Avatar" height={120} width={120} />
												</div>
												<h3 className="header text-center">Iniciar Sesión</h3>
											</CardHeader>
										</CardHeader>
										<CardBody>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="nc-icon nc-single-02" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Usuario"
													id="Username"
													type="text"
													onChange={(e) => setUsername(e.target.value)}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="nc-icon nc-key-25" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Contraseña"
													id="Passwd"
													type="password"
													autoComplete="off"
													security="*"
													onChange={(e) => setPasswd(e.target.value)}
													onKeyPress={(event) => {
														if (event.key === 'Enter') {
															signIn();
														}
													}}
												/>
											</InputGroup>
											<br />
											<FormGroup>
												<FormGroup check>
													<Label check>Acceso a personal autorizado</Label>
												</FormGroup>
											</FormGroup>
										</CardBody>
										<CardFooter>
											{!loading && (
												<Button
													block
													className="btn-round mb-3"
													color="primary"
													onClick={signIn}
												>
													Iniciar Sesión
												</Button>
											)}

											{loading && <MetroSpinner size={30} color="#686769" loading={loading} />}
											<div className="text-center">

												<Label className="text-center"> Ver 2.0.1</Label>

												<br />
												<Label className="text-center"> <a href="https://panguanajuatomx.org/wp-content/uploads/2020/02/AVISO-DE-PRIVACIDAD-INTEGRAL1.pdf" target="_blank">Ver Aviso de Privacidad  </a></Label>
											</div>
										</CardFooter>
									</Card>
								</Form>
							</Col>
						</Row>
					</Container>
					<div
						className="full-page-background"
						style={{
							backgroundImage: `url(${require("assets/img/bg/back02.png")})`
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Login;
