import React, { useEffect, useState } from 'react';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import Pagination from 'components/Pagination/pagination';

import Notifications, { notify } from 'react-notify-toast';

import { getVars, setVars } from 'GlobalStorage';
import EjecutarApi from 'data/getPOST';
import {
  Button,
  Col,
  Card,
  Row,
  CardHeader,
  CardBody,
  Spinner,
} from 'reactstrap';
import ReactTable from 'react-table';

import Filtro from 'components/Filtro/indexPlanilla';
import PlanillaForm from './PlanillasForm';

const usersPermitidos = { CoordinadorGeneral: 99 };

const Planillas = (props) => {
  const token = getVars('Token');
  const [menu, _setmenu] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCatalogos, setIsLoadingCatalogos] = useState(true);
  const [idEdit, setIdEdit] = useState(-1);
  const [isNew, setIsNew] = useState(false);
  const [total, setTotal] = useState(0);
  const [candidatos, setCandidatos] = useState([]);
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState({
    filtered: [],
    page: 0,
    pageSize: 0,
  });
  const [catalogosFiltro, setCatalogosFiltros] = useState({
    municipios: [],
    dfs: [],
    dls: [],
  });
  const [tiposEleccion, setTiposEleccion] = useState([]);

  useEffect(() => {
    const getCatalogos = () => {
      EjecutarApi({catalogos:['dfs', 'dls', 'municipios']}, 'getCatalogosByResponsabilidad', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          setIsLoadingCatalogos(false);
          if (res.success && res.results) {
            setCatalogosFiltros({
              ...catalogosFiltro,
              municipios: res.data.municipios,
              dfs: res.data.dfs,
              dls: res.data.dls,
            });
          }
        })
        .catch((e) => {
          setIsLoadingCatalogos(false);
          notify.show(
            `Error al cargar filtros: ${e.message} 'Favor de renovar sesión'
              }`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        });
    };
    getCatalogos();

    const getPartidos = () => {
      EjecutarApi({}, 'getTiposEleccion', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          setIsLoadingCatalogos(false);
          if (res.success && res.results) {
            setTiposEleccion(
              res.data.map((tipo) => {
                return {
                  value: tipo.idTipoEleccion,
                  label: tipo.TipoEleccion,
                };
              })
            );
          }
        })
        .catch((e) => {
          setIsLoading(false);
          notify.show(
            `Error al cargar filtros: ${e.message} 'Favor de renovar sesión'
              }`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        });
    };
    getPartidos();
  }, []);

  const getPlanilla = (data) => {
    data = data
      ? data
      : {
          page: 0,
          pageSize: 10,
          filtered: filter,
        };
    setData(data);
    setIsLoading(true);
    EjecutarApi(data, 'getCandidatosPlanilla', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setIsLoading(false);
        if (res.success && res.results) {
          setTotal(res.total);
          setCandidatos(
            res.data.map((candidato) => {
              return {
                id: candidato.id,
                Candidato: (
                  <Button
                    className="btn-link"
                    color="primary"
                    size="sm"
                    onClick={() => setIdEdit(candidato.id)}
                  >
                    {candidato.Candidato.toUpperCase() + ' '}
                    <i className="fa fa-pencil" />
                  </Button>
                ),
                Tipo: candidato.TipoEleccion,
                Partido: candidato.Partido,
                Municipio: candidato.Municipio,
                DL: candidato.DL,
                DF: candidato.DF,
              };
            })
          );
        }
      })
      .catch((e) => {
        setIsLoading(false);
        notify.show(
          `Error al buscar planillas: ${e.message} 'Favor de renovar sesión'
            }`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const setIsOpen = (value) => {
    setIdEdit(value);
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <Notifications />
        <div className="content">
          {token.user.Responsabilidad.value ===
          usersPermitidos.CoordinadorGeneral ? (
            <>
              {idEdit >= 0 && (
                <PlanillaForm
                  catalogos={catalogosFiltro}
                  tiposEleccion={tiposEleccion}
                  idEdit={idEdit}
                  isOpen={idEdit >= 0}
                  setIsOpen={setIsOpen}
                  isLoading={isLoadingCatalogos}
                  setIsLoading={setIsLoadingCatalogos}
                  getPlanillas={getPlanilla}
                  setFilter={setFilter}
                />
              )}
              <Card>
                <CardHeader>
                  <p align="center">
                    <strong>Planillas</strong>
                  </p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => setIdEdit(0)}
                    >
                      <span className="btn-label">
                        <i className="fa fa-user-plus fa-lg" />
                      </span>
                      Capturar
                    </Button>
                  </Row>
                  <Row>
                    {isLoadingCatalogos && <Spinner />}
                    {!isLoadingCatalogos && (
                      <Filtro
                        catalogos={catalogosFiltro}
                        getData={getPlanilla}
                        tiposEleccion={tiposEleccion}
                        setFilter={e => setFilter(e)}
                      />
                    )}
                  </Row>
                  <Row>
                    <Col
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <ReactTable
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                        size="sm"
                        data={candidatos}
                        noDataText="Sin información"
                        manual
                        columns={[
                          {
                            Header: 'Candidato',
                            minWidth: 6,
                            id: 'Candidato',
                            accessor: (d) => d.Candidato,
                            filterAll: false,
                          },
                          {
                            Header: 'Tipo',
                            minWidth: 4,
                            id: 'Tipo',
                            accessor: (d) => d.Tipo,
                            filterAll: false,
                          },
                          {
                            Header: 'Partido',
                            minWidth: 4,
                            id: 'Partido',
                            accessor: (d) => d.Partido,
                            filterAll: false,
                          },
                          {
                            Header: 'Municipio',
                            minWidth: 4,
                            id: 'Municipio',
                            accessor: (d) => d.Municipio,
                            filterAll: false,
                          },
                          {
                            Header: 'DL',
                            minWidth: 4,
                            id: 'DL',
                            accessor: (d) => d.DL,
                            filterAll: false,
                          },
                          {
                            Header: 'DF',
                            minWidth: 4,
                            id: 'DF',
                            accessor: (d) => d.DF,
                            filterAll: false,
                          },
                        ]}
                        className="-striped -highlight primary-pagination"
                        loading={isLoading}
                        showPagination={true}
                        showPaginationBottom={true}
                        showTotalPages={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={10}
                        total={total}
                        pages={Math.ceil(total / 10)}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        PaginationComponent={Pagination}
                        onFetchData={(stateComp) => {
                          const data = {
                            page: stateComp.page,
                            pageSize: stateComp.pageSize,
                            filtered: filter,
                          };
                          getPlanilla(data);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          ) : (
            <Card>
              <CardBody>
                <h2 className="text-center">
                  Sin permisos, consulte al administrador
                </h2>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default Planillas;
