import React from 'react';
import { Row, Col, Input, Label } from 'reactstrap';

import ReactDatetime from 'react-datetime';
import FormGroup from 'reactstrap/lib/FormGroup';
import moment from 'moment';
import 'moment/locale/es';

const FormJuridicos = ({ form, setForm, formErrors, sm, smCheck }) => {
  return (
    <Row>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Fecha
        <ReactDatetime
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          locale="es"
          closeOnSelect
          value={form.Fecha}
          onChange={(e) =>
            setForm({
              ...form,
              Fecha: moment(e._d).format('YYYY-MM-DD'),
            })
          }
          inputProps={{ className: 'form-control' }}
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.Fecha}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Apertura
        <ReactDatetime
          dateFormat={false}
          value={form.Apertura}
          onChange={(e) =>
            setForm({
              ...form,
              Apertura: moment(e._d).format('H:mm'),
            })
          }
          inputProps={{ className: 'form-control' }}
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.Apertura}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Cierre
        <ReactDatetime
          dateFormat={false}
          value={form.Cierre}
          onChange={(e) =>
            setForm({
              ...form,
              Cierre: moment(e._d).format('H:mm'),
            })
          }
          inputProps={{ className: 'form-control' }}
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.Cierre}
        </span>
      </Col>
      <Col sm={smCheck} className="col-form-juridico">
        <span className="text-danger">*</span>Ubicación de casilla correcto
        <FormGroup align="center">
          <Label check>
            <Input
              type="checkbox"
              checked={form.UbicacionCorrecta}
              value={form.UbicacionCorrecta}
              onChange={() =>
                setForm({ ...form, UbicacionCorrecta: !form.UbicacionCorrecta })
              }
            />{' '}
            {form.UbicacionCorrecta ? 'Sí' : 'No'}
          </Label>
        </FormGroup>
      </Col>
      {!form.UbicacionCorrecta && (
        <Col sm={sm} className="col-form-juridico">
          <span className="text-danger">*</span>Domicilio de encarte
          <Input
            type="text"
            value={form.DomicilioEncarte}
            onChange={(e) =>
              setForm({ ...form, DomicilioEncarte: e.target.value })
            }
          />
          <span className="form-check-sign" style={{ color: 'red' }}>
            {formErrors.DomicilioEncarte}
          </span>
        </Col>
      )}
      {!form.UbicacionCorrecta && (
        <Col sm={sm} className="col-form-juridico">
          <span className="text-danger">*</span>Domicilio de acta
          <Input
            type="text"
            value={form.DomicilioActa}
            onChange={(e) =>
              setForm({ ...form, DomicilioActa: e.target.value })
            }
          />
          <span className="form-check-sign" style={{ color: 'red' }}>
            {formErrors.DomicilioActa}
          </span>
        </Col>
      )}
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Nombre del presidente
        <Input
          type="text"
          value={form.Presidente}
          onChange={(e) => setForm({ ...form, Presidente: e.target.value })}
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.Presidente}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Nombre del secretario
        <Input
          type="text"
          value={form.Secretario}
          onChange={(e) => setForm({ ...form, Secretario: e.target.value })}
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.Secretario}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Nombre del escrutador
        <Input
          type="text"
          value={form.Escrutador}
          onChange={(e) => setForm({ ...form, Escrutador: e.target.value })}
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.Escrutador}
        </span>
      </Col>
      <Col sm={smCheck} className="col-form-juridico">
        <span className="text-danger">*</span>Protesta
        <FormGroup align="center">
          <Label check>
            <Input
              type="checkbox"
              value={form.Protesta}
              onChange={() => setForm({ ...form, Protesta: !form.Protesta })}
            />{' '}
            {form.Protesta ? 'Sí' : 'No'}
          </Label>
        </FormGroup>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Actas recibidas
        <Input
          type="number"
          value={form.BoletasRecibidas}
          onChange={(e) =>
            setForm({ ...form, BoletasRecibidas: e.target.value })
          }
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.BoletasRecibidas}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Boletas sobrantes/inutilizadas
        <Input
          type="number"
          value={form.BoletasSobrantes}
          onChange={(e) =>
            setForm({ ...form, BoletasSobrantes: e.target.value })
          }
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.BoletasSobrantes}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Boletas extraidas de la urna
        <Input
          type="number"
          value={form.BoletasUrna}
          onChange={(e) => setForm({ ...form, BoletasUrna: e.target.value })}
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.BoletasUrna}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Total de representantes de partido
        politico y/o coaliciones que votaron en la casilla sin estar incluidos
        en la lista nominal
        <Input
          type="number"
          value={form.RCSVotantes}
          onChange={(e) => setForm({ ...form, RCSVotantes: e.target.value })}
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.RCSVotantes}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Total ciudadanos inscritos en
        lista nominal
        <Input
          type="number"
          value={form.CiudadanosInscritos}
          onChange={(e) =>
            setForm({ ...form, CiudadanosInscritos: e.target.value })
          }
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.CiudadanosInscritos}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Ciudadanos que votaron conforme a
        la lista nominal
        <Input
          type="number"
          value={form.CiudadanosVotaron}
          onChange={(e) =>
            setForm({ ...form, CiudadanosVotaron: e.target.value })
          }
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.CiudadanosVotaron}
        </span>
      </Col>
      <Col sm={sm} className="col-form-juridico">
        <span className="text-danger">*</span>Votacion total asentada en actas
        <Input
          type="number"
          value={form.VotacionTotalActas}
          onChange={(e) =>
            setForm({ ...form, VotacionTotalActas: e.target.value })
          }
        />
        <span className="form-check-sign" style={{ color: 'red' }}>
          {formErrors.VotacionTotalActas}
        </span>
      </Col>
      <Col sm={smCheck} className="col-form-juridico">
        <span className="text-danger">*</span>Incidentes
        <FormGroup align="center">
          <Label check>
            <Input
              type="checkbox"
              value={form.Incidentes}
              onChange={() =>
                setForm({ ...form, Incidentes: !form.Incidentes })
              }
            />{' '}
            {form.Incidentes ? 'Sí' : 'No'}
          </Label>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default FormJuridicos;
