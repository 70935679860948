import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import  { notify } from "react-notify-toast";
import { MetroSpinner } from 'react-spinners-kit';
import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";

function UsuarioMenu({ isMovil, idUser }) {
  const [_isMovil] = useState(isMovil ? 1 : 0);
  const [_idUser] = useState(idUser);
  const [Menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
  useEffect(() => {
   
    var dato = {
      id: _idUser,
    };
  
    EjecutarApi(dato, "getPermisos", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((items) => {
        if (items.results) {
          setMenus(items.data);
        } else {
          notify.show("Error al leer: getPermisos", "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        notify.show("Error: getPermisos", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  }, []);
  let stilo = {
    textDecoration:'line-through',
    color:'red'
  }
  return (
    <>
      <div>
      {loading && <MetroSpinner size={30} color="#686769" loading={loading} />}
        <Table responsive striped>
          <thead className="text-primary">
            <tr>
              <th className="text-center"> Menu </th>{" "}
              <th className="text-center"> </th>{" "}
              <th className="text-center"> Permisos </th>{" "}
              <th className="text-center"> </th>{" "}
              <th className="text-center"> </th>{" "}
              <th className="text-center"> </th>{" "}
              <th className="text-center"> </th>{" "}
            </tr>
          </thead>
          <tbody>
            {Menus ? (
              Menus.map((item, index) =>
                item.isMovil === _isMovil ? (
                  item.Ver === 0 &&
                  item.Agregar === 0 &&
                  item.Editar === 0 &&
                  item.Eliminar === 0 &&
                  item.Exportar === 0 ? (
                    <></>
                  ) : (
                    <>
                      <tr>
                        <td className="text-right"> {item.Menu} </td> <td> </td>
                        <td className={
                            item.Ver === 0 ? "line text-muted" : "text-center"
                          }
>
                          {
                            item.Ver === 0 ? (<strike>Ver</strike>) : "Ver"
                          }
                        </td>
                        <td className={
                            item.Agregar === 0
                              ? "line text-muted"
                              : "text-center"
                          }>
                          {
                            item.Agregar === 0 ? <strike>Agregar</strike> : "Agregar"
                          }
                         
                        </td>{" "}
                        <td className={
                            item.Editar === 0
                              ? "line text-muted"
                              : "text-center"
                          }>
                          {
                            item.Editar === 0
                              ? <strike>Editar</strike>
                              : "Editar"
                          }{" "}
                        </td>{" "}
                        <td className={
                            item.Eliminar === 0
                              ? "line text-muted"
                              : "text-center"
                          }>
                         {
                            item.Eliminar === 0
                              ? <strike>Eliminar</strike>
                              : "Eliminar"
                          } {" "}
                        </td>{" "}
                        <td className={
                            item.Exportar === 0
                              ? "line text-muted"
                              : "text-center"
                          }>
                          {
                            item.Exportar === 0
                              ? <strike>Exportar</strike>
                              : "Exportar"
                          }{" "}
                        </td>{" "}
                      </tr>{" "}
                    </>
                  )
                ) : (
                  <></>
                )
              )
            ) : (
              <></>
            )}{" "}
          </tbody>{" "}
        </Table>{" "}
      </div>{" "}
    </>
  );
}

export default UsuarioMenu;
