import React, { useState,useEffect } from 'react';
import { InputGroup,InputGroupAddon,
    InputGroupText,Row,Col, Input, Button, Label,FormGroup, Badge,CardHeader, 
    CardBody,Card, CardTitle, CardFooterTabContent,
    TabPane, NavItem, 
    NavLink, Nav,CardFooter,} from 'reactstrap';
import { notify } from "react-notify-toast";
import {RotateSpinner} from "react-spinners-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import EjecutarApi from "../../data/getPOST";
import { getVars} from "../../GlobalStorage";
import Select from "react-select";
import ModalSocialAdd from './SocialAdd';
import ModalMensaje from './MensajeModal';
import {getDataMenu} from '../../data/menus.js';


const Enlace = ({Data,onHide}) => {
    const [loading, setLoading] = useState(false);
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    const [DatosResponsable,setDatosResponsable] = useState([]);
    const [totalResponsable,setTotalResponsable] = useState(0) 
    const [DatosEnlace,setDatosEnlace] = useState([]);
    const [totalEnlace,setTotalEnlace] = useState(0) 
    const [HasMore,setHasMore]=useState(true)
    const [HasMoreEnlace,setHasMoreEnlace]=useState(true)
    const [nextFetch,setnextFetch]=useState(1); 
    const [nextFetchEnlace,setnextFetchEnlace]=useState(1); 
    const [catEstructuras,setCatEstructuras]=useState([]);
    const [catSubEstructuras,setCatSubEstructuras]=useState([]);
    const [FilterCatSubEstructuras,setFilterCatSubEstructuras]=useState([]);
    const [isSeleccionable,setIsSeleccionable]=useState(false);
    const [isEtiquetable,setisEtiquetable]=useState(false);
    const [Busqueda,setBusqueda]=useState("")
    const [user, setUser]= useState({
        id:0,
        FullName:'',
        Codigo:'',
        Cargo:'',
        NombreSocial:'',
        Responsabilidad:[]
    })
    const [catSocial,setCatSocial] = useState([
        { value:30,label:'Coordinador General Social'},
        { value:31,label:'Coordinador Estructura Social'},
        { value:32,label:'Coordinador Regional Social'},
        { value:33,label:'Coordinador Municipal Social'}])
    const [isSocial,setIsSocial]=useState({
        CoorGralSocial:false,
        CoorEstrSocial:false,
        CoorRegSocial:false,
        CoorMpalSocial:false,
        CoorEstatalSocial:false,
        CoordeEstructura:false
    })
    const [Estructura,setEstructura]=useState({
        idEstructura:token.user.EstructuraSocial.value,//{value:Data.id,label:''},
        Codigo:'',
        idRegion:{},
        idMunicipio:{},
        idResponsabilidad:{value:0,label:'Seleccione...'},
        Responsable:'',
        idResponsable:0
    })
    const [Enlace,setEnlace]=useState({
        idEnlace:[],
        Enlaces:[],
        Responsable:''
    })
    const [NombreEstructura,setNombreEstructura] = useState(token.user.EstructuraSocial.label);//token.user.EstructuraSocial.label;
    const [idSubEstructura,setIdSubEstructura]= useState(token.user.EstructuraSocial.value ===1?0:token.user.EstructuraSocial.value);
    var ubicacionResponsabilidad = (token.user.EstructuraSocial.value === 1)?0:token.user.EstructuraSocial.value;
    const [segmentos, setSegmentos] =useState([ubicacionResponsabilidad]);
    const [isBusquedaSegmento,setIsBusquedaSegmento]=useState(false);
    const [segmentosSelected, setSegmentosSelected] =useState(ubicacionResponsabilidad);
    const [Editar, setEditar] = useState({
        NombreSocial: '',
       
      });
    const [isModalSocialAdd, setModalSocialAdd] = useState(false);
    const [isModalEliminarEstructura, setModalEliminarEstructura] = useState(false);
    const [Message, setMessage] = useState(false);
    const [horizontalTabs, setHorizontalTabs] = useState({
        horizontalTabs: "RCS",
        openedCollapses: ["collapseOne", "collapse1"]
      })
    useEffect(()=>{
        setLoading(true)
        _setmenu(getDataMenu(76,token.menus));
        handleSubEstructuras();
        BuscarResponsable('')
        BuscarEnlace('')
        setLoading(false)
    },[])//Dm-04-01
    useEffect(()=>{
       
    },[catSubEstructuras])
   
    const handleSubEstructuras =(Busqueda)=>{
        setBusqueda('')
        setLoading(true);
        const data={
            "idEstructura":Busqueda?Busqueda:ubicacionResponsabilidad,
            }
        let estructurasFormated =[];
        EjecutarApi(data, 'getSubestructuraSocialWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
                if (res.results) {
                        setCatEstructuras(res.data.map(item => {
                       
                        return {
                            value: item.id,
                            label: item.nickName,
                        
                        }
                        }))
                    setLoading(false)
                    } else {
                        showMessage(res.message)
                        setLoading(false)
                }
                if (res.data) {
                    
                    estructurasFormated = res.data.map(item => {
                      var dato=0
                      var Color="#00000"
                      var icon ="fa fa"
                      var flag = 0
                      res.data.forEach(element => {
                        if(element.parentId.value === item.id){
                         dato++
                        }
                        else{}
                      })
                      if(dato !== 0){
                        Color="#36BADA"
                        icon ="fa fa-plus"
                        flag =1
                      }
                      else{ Color="#13728E"
                      icon =""
                      flag= 0
                    }
                        return {
                          value: item.id,
                          label: item.nickName,
                          paredId: item.parentId.value,
                          color: Color,
                          icon : icon,
                          activo: flag
                        }
                      })
                    setCatSubEstructuras(estructurasFormated)
                    setFilterCatSubEstructuras(estructurasFormated)
                    setLoading(false)
                  } else {
                    showMessage("No Info: catCompromisosWeb")
                    setLoading(false)
                  }
                
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
        
        
        //catalogoSocial
        setLoading(true);
        EjecutarApi({}, 'getCatalogosPorResponsabilidadSocialWeb', token.access_token)
			.then(function(res) {
				return res;
			})
			.then((res) => {
                if(res.success)
                {
                    let arr=[{value:0,label:'Seleccionar...'}];
                    res.data.forEach(item=>{
                        
                            arr.push(item)
                       
                    })
                    
                    setCatSocial(arr)
                    setLoading(false);
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
			});
    
    }
    const FilterSubEstructura=(Busqueda)=>{
        let auxSubEstructuras = [];
    
        auxSubEstructuras = FilterCatSubEstructuras.filter(data => data.label.toUpperCase()==Busqueda.toUpperCase())

        
        if (auxSubEstructuras.length>0)
        {
            setSegmentosSelected(auxSubEstructuras[0].paredId)
            setCatSubEstructuras(auxSubEstructuras)
            setNombreEstructura(auxSubEstructuras[0].label)
            if(auxSubEstructuras[0].value != segmentos)
            { setSegmentos([...segmentos,auxSubEstructuras[0].value])}
            setIsBusquedaSegmento(true)
            setIdSubEstructura(auxSubEstructuras[0].value)
        }
        else {
            setCatSubEstructuras(auxSubEstructuras)}
        
        
    }
    const CleanFilterSubEstructura=()=>{
        var _ubicacionResponsabilidad = (token.user.EstructuraSocial.value === 1)?0:token.user.EstructuraSocial.value;
        setIdSubEstructura(token.user.EstructuraSocial.value===1?0:token.user.EstructuraSocial.value)
        setSegmentosSelected(_ubicacionResponsabilidad)
        setSegmentos(_ubicacionResponsabilidad)
        setCatSubEstructuras(FilterCatSubEstructuras)
        setBusqueda("")
        setNombreEstructura(token.user.EstructuraSocial.label) 
    }
    const showMessage = (message) => {
        notify.show(message, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
    }
    const codigoAleatorio=(length)=> {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    const handleSetResponsable=(e)=>{
        setEstructura({...Estructura,Responsable:e.Nombre,idResponsable:e.id});
        setIsSeleccionable(true);
    }
    const handleSetEnlace=(e)=>{
        setisEtiquetable(true);
        setUser({...user, FullName:e.Nombre,
                id:e.id
        })
        setEnlace({...Enlace, Responsable:e.Nombre,
            id:e.id
    })
        
    }
    const fetchHasMore = () => {
        setLoading(true);
        if (nextFetch * 10 >= totalResponsable) {
          setHasMore (false);
         return;
        }
        setHasMore(true);
        
        var parametro={
            page:nextFetch, 
            pageSize:10,
            filtered:[{id:"FullSearch",value:Estructura.Responsable}],
            tipo:"and",
            sorted:[{"id":"LastUpdate","desc":true}]
        };
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                setLoading(false);
                setDatosResponsable(DatosResponsable.concat(res.data));
                setnextFetch(nextFetch + 1);
                setHasMore (false);
                setTotalResponsable(res.totalRegistros);
            }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    };
    const fetchHasMoreEnlace = () => {
        if (nextFetch * 10 >= totalEnlace) {
          setHasMoreEnlace (false);
         return;
        }
        setHasMore(true);
        
        var parametro={
            page:nextFetchEnlace+1, 
            pageSize:10,
            filtered:[{id:"FullSearch",value:Estructura.Responsable}],
            tipo:"and",
            sorted:[{"id":"LastUpdate","desc":true}]
        };
      
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                setLoading(false);
                setDatosEnlace(res.data);
                setnextFetchEnlace(nextFetchEnlace + 1);
                setTotalEnlace(res.totalRegistros)
            }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    };
    const setResponsable=(e)=>{
        setLoading(true)
        var parametros='';
        var API='';
            parametros={
                idEstructura:Estructura.idEstructura.value,
                idUser:Estructura.idResponsable,
                ClaveReferencia:Estructura.Codigo,
                idResponsabilidad:Estructura.idResponsabilidad.value
            }

            switch(Estructura.idResponsabilidad.value)
            {
                case 30:
                    parametros={
                        idEstructura:Estructura.idEstructura.value,
                        idUser:Estructura.idResponsable,
                        ClaveReferencia:Estructura.Codigo,
                        idResponsabilidad:Estructura.idResponsabilidad.value
                    }
                    API='setResponsableEstructuraSocialWeb'
                    //setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                break;
                case 31:
                        parametros={
                            idEstructura:Estructura.idEstructura.value,
                            idUser:Estructura.idResponsable,
                            ClaveReferencia:Estructura.Codigo,
                            idResponsabilidad:Estructura.idResponsabilidad.value
                        }
                        API='setResponsableEstructuraSocialWeb'
                        //setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                    break;
                case 34:
                        parametros={
                            idEstructura:Estructura.idEstructura.value,
                            idUser:Estructura.idResponsable,
                            ClaveReferencia:Estructura.Codigo,
                            idResponsabilidad:Estructura.idResponsabilidad.value
                        }
                        API='setResponsableEstructuraSocialWeb'
                        //setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                    break;
                case 35:
                        parametros={
                            idEstructura:Estructura.idEstructura.value,
                            idUser:Estructura.idResponsable,
                            ClaveReferencia:Estructura.Codigo,
                            idResponsabilidad:Estructura.idResponsabilidad.value
                        }
                        API='setReponsableSubEstructuraSocialWeb'
                        
                        //setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                    break;
                default:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:false});
                    break;
            }
            EjecutarApi(parametros, API, token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if(res.results)
                {
                    setEstructura({
                        idEstructura:idSubEstructura,//{value:Data.id,label:''},
                        Codigo:'',
                        idRegion:{},
                        idMunicipio:{},
                        idResponsabilidad:{value:0,label:'Seleccione...'},
                        Responsable:'',
                        idResponsable:0})
                        setIsSeleccionable(false)
                    setIsSocial({
                            CoorGralSocial:false,
                            CoorEstrSocial:false,
                            CoorRegSocial:false,
                            CoorMpalSocial:false,
                            CoorEstatalSocial:false,
                            CoordeEstructura:false});
                    BuscarResponsable('')
                    notify.show(res.message, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                        });
                    handleSubEstructuras();
                    setLoading(false);
                }
                else{
                    notify.show(`Error:${res.message}`, "custom", 5000, {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                        });
                    setLoading(false);
                    //onHide();
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error: ${e.error}`, "custom", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
                });
            });
    }
    const saveEnlace=(e)=>{
        //setResponsableEstructuraSocial
     
    
        var parametros='';
        parametros={data:Enlace.Enlaces};
        EjecutarApi(parametros, 'setUsersEtiquetasWeb', token.access_token)
        .then(function (res) {
            return res;
        })
        .then((res) => {
            if(res.results)
            {
                notify.show(`¡Registro Actualizado Correctamente!`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",

                    });
                setLoading(false);
                setEnlace({Estructura,Responsable:'',idEnlace:[],Enlaces:[]})
                BuscarEnlace('')
                handleSubEstructuras()
                //onHide();
            }
            else{
                notify.show(`Error:${res.message}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                    });
                setLoading(false);
                //onHide();
            }
        })
        .catch((e) => {
        setLoading(false);
            notify.show(`Error: ${e.error}`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
            });
        });
    }
    const handleSelectSocial=(e)=>{
       
            switch(e.value)
            {
                case 30:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:false});
                        
                        setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                    break;
                case 31:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:true,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:false});
                        
                        setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                    break;
                case 34:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:true,
                        CoordeEstructura:false});
                        
                        setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                    break;
                case 35:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:true});
                    setEstructura({...Estructura, idResponsabilidad:e , Codigo:codigoAleatorio(6)});
                    break;
                default:
                    setIsSocial({...isSocial,
                        CoorGralSocial:false,
                        CoorEstrSocial:false,
                        CoorRegSocial:false,
                        CoorMpalSocial:false,
                        CoorEstatalSocial:false,
                        CoordeEstructura:false});
                    break;
            }
     
        //setShowButton(true);
    }
    const BuscarResponsable=(value)=>{
        var parametro={
            page:0, 
            pageSize:10,
            filtered:[{id:"FullSearch",value:value}],
            tipo:"and",
            sorted:[{"id":"LastUpdate","desc":true}]
        };
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                setLoading(false);
                setDatosResponsable(res.data);
                setTotalResponsable(res.totalRegistros)
            }
            setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }
    const BuscarEnlace=(valor)=>{
        var parametro={
            page:0, 
            pageSize:10,
            filtered:[{id:"FullSearch",value:valor}],
            tipo:"and",
            sorted:[{"id":"LastUpdate","desc":true}]
        };
        
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                setLoading(false);
                setDatosEnlace(res.data);
                setTotalEnlace(res.total)
            }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }
    const EliminarResponsabilidad=(idUser,Accion)=>{
        var json = {};
        var parametro={
            idUser:idUser
        };
        EjecutarApi(parametro, 'eliminarResponsablesWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                json = res
            }
            else{
                notify.show(`Error: No se puede eliminar la responsabilidad`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
        return json;
    }
    const onClickEliminarResponsabilidad=(item)=>{
        let result=EliminarResponsabilidad (item,0);
        if(result.delete===1)
        {
            notify.show(`Error: ${result.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
            result=EliminarResponsabilidad (item,1);
            if (result===2)
            {
                notify.show(`Error: ${result.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
                result=EliminarResponsabilidad (item,2);
                notify.show(`Error: ${result.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            }
        }else
        {
            notify.show(`La responsabilidad ha sido eliminada`, "error", 5000, {
                background: "#0857B6",
                text: "#FFFFFF",
            });
            BuscarResponsable('');
            setEstructura({...Estructura,Responsable:''})
        }
        handleSubEstructuras()
    }
    const segmento = (item) =>{
        setNombreEstructura (item.label)
        setIdSubEstructura(item.value)
        setSegmentosSelected(item.value)
        setIdSubEstructura(item.value)
        
        if(segmentos != item.value)
        {
            setSegmentos([...segmentos,item.value])
        }
        
    }
    const AtrasSegmento = () =>{
       if(isBusquedaSegmento){
            setIsBusquedaSegmento(false)
            CleanFilterSubEstructura()
       }
       else{
            let ultimo=0
            if(segmentos.length > 1 ){
                ultimo=segmentos.pop()
                setSegmentosSelected(segmentos[segmentos.length-1])
                let obj = catSubEstructuras.filter(data => data.value === segmentos[segmentos.length-1])
                if(segmentos[segmentos.length-1]===0)
                {
                    setNombreEstructura(token.user.EstructuraSocial.label)  
                    setIdSubEstructura(token.user.EstructuraSocial.value===1?0:token.user.EstructuraSocial.value)   
                }
                else{
                    setNombreEstructura(obj[0].label)
                    setIdSubEstructura(obj[0].value)
                }
                
            }
            else{
                ultimo=segmentos[segmentos.length-1]
            }
      }
    
    }
    const handleAgregar = () => {
        setEditar({ idSocial:idSubEstructura, Tipo: 'Add', Data:{NombreEstructura:NombreEstructura}});
        setModalSocialAdd(true);
    };
    const onHideEnlace = () => {
        setModalSocialAdd(false)
        setModalEliminarEstructura(false)
        handleSubEstructuras()
        
    }
    const onHideElimina = () => {
        setModalSocialAdd(false)
        setModalEliminarEstructura(false)
        handleSubEstructuras()
        AtrasSegmento()
    }
    var resultado={}
    const eliminarEstructura = (idEstructur,delet)=>{
        //eliminarEstructuraSocialWeb eliminarEstructuraSocialWeb
        resultado={ delete: 0,
            message: 0,
            results: 0,
            success: 0};
        var parametro ={
            idEstructura:idEstructur,
            delete:delet
        }
        EjecutarApi(parametro, 'eliminarEstructuraSocialWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
                resultado={
                    delete: res.delete,
                    message: res.message,
                    results: res.results,
                    success: res.success
                }
                return resultado;
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
        return resultado;
    }
    const btnAceptar= (idEliminar)=>{

        if(idEliminar===0){
            resultado = eliminarEstructura(NombreEstructura, idEliminar)
            if (resultado.success) 
            {
                notify.show(` ${resultado.message}`, "custom", 5000, {
                    background: "#0857B6",
                    text: "#FFFFFF",
                });
                
            }
        }
        
    }
    const onClickEliminar = ()=>{
        setMessage({Mensaje:`¿Desea eliminar la estructura ${NombreEstructura}?`,NombreEstructura:NombreEstructura,idEstructura:idSubEstructura});
        setModalEliminarEstructura(true);
    }
    const EliminarEnlace = (user,idEstructura) =>
    {
        setLoading(true);
        if(user)
            if(user === 0 )
            {
                notify.show('Debe seleccionar un usuario, para asignar etiquetas!', "error", 5000, {
                    background: "#0857B6",
                    text: "#FFFFFF",
                });
                return;
            }
        if(idEstructura)
            if(idEstructura === 0)
            {
                notify.show('Debe seleccionar una etiqueta, para asignar!', "error", 5000, {
                    background: "#0857B6",
                    text: "#FFFFFF",
                });
                return;
            }
        var parametro ={
            idUser:user,
            idEstructura:idEstructura
        }
        EjecutarApi(parametro, 'eliminarEtiquetaIndividualUsuarioWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                notify.show(res.message, "error", 5000, {
                    background: "#0857B6",
                    text: "#FFFFFF",
                });
                setLoading(false);
                BuscarEnlace('')
                
            }
            else{
                notify.show(`Error: No se puede eliminar el enlace`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
                setLoading(false);
                BuscarEnlace('')
            }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }
    const changeTap = (e) => {
        setHorizontalTabs({ ...horizontalTabs, horizontalTabs: e })
    }
    return(
        <> 
            <Col sm='12'>
                <Row>
                    <Col  sm="6">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Estructura {NombreEstructura}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardTitle>
                                                <Button close onClick={()=>AtrasSegmento()} >
                                                    <i className="fa fa-reply-all" aria-hidden="true"></i>
                                                </Button>
                                                {_menu.Agregar===1&&(
                                                    <Button onClick={()=>handleAgregar()} size='sm' color='success'>
                                                        <i className="fa fa-plus">Subestructuras</i>
                                                    </Button>
                                                )}
                                                
                                                {(_menu.Eliminar===1 && idSubEstructura>0)&&(
                                                     <Button onClick={()=>onClickEliminar()} size='sm' color='danger'>
                                                        <i className="fa fa-times danger">Delete</i>
                                                    </Button>
                                                )}
                                            
                                            </CardTitle>{/*</Card>style={{ 'height': '240px', 'overflow': 'scroll', 'display': 'block' }}*/} 
                                            <CardBody>
                                                <Row>
                                                    <FormGroup className="col-sm-12">
                                                        <InputGroup className="no-border">
                                                            <Input value={Busqueda} 
                                                                onChange={(e)=>setBusqueda(e.target.value)} 
                                                                placeholder="Buscar..." type="text" />
                                                            <InputGroupAddon >
                                                                <Button color='warning' 
                                                                    style={{ margin: '0px 1px' }}
                                                                    onClick={()=>FilterSubEstructura(Busqueda)}>
                                                                    <i className="nc-icon nc-zoom-split" /></Button>
                                                            </InputGroupAddon>
                                                            <InputGroupAddon>
                                                                <Button  color='primary' 
                                                                    style={{ margin: '0px 1px' }}
                                                                    onClick={()=>CleanFilterSubEstructura()} 
                                                                    className='link-button'>
                                                                        <i className="fa fa-eraser" aria-hidden="true"/>
                                                                </Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                    <Col sm="4" className='text-align-bottom-left' >
                                                        
                                                        
                                                    </Col>
                                                </Row>
                                                <div style={{ 'height': '400px', 'overflow': 'scroll', 'display': 'block' }}  >
                                                { 
                                                    catSubEstructuras.filter(data => data.paredId === segmentosSelected).map((item,index )=> (
                                                    <><Badge key={index.toString()} style={{color: item.color}} 
                                                            tag="button" onClick={(e)=>segmento(item)}
                                                        >
                                                                {item.label} {"  "} <i className={item.icon}aria-hidden="true"></i>
                                                    </Badge>
                                                    </>
                                                    ))}
                                                </div>
                                            </CardBody>    
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            {isModalSocialAdd && (
                                <ModalSocialAdd onHide={onHideEnlace} OnEdit={Editar} />
                                )}
                            {isModalEliminarEstructura && (
                                <ModalMensaje onHide={onHideElimina} Datos={Message} btnAceptar={(e)=>btnAceptar(e)} />
                            )}
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col sm="6">
                        
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Asignar Responsabilidad de la Estructura {NombreEstructura}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <FormGroup className="col-sm-12">
                                        <InputGroup className="no-border">
                                            <Input value={Estructura.Responsable} 
                                                onChange={(e)=>setEstructura({...Estructura,Responsable:e.target.value})} 
                                                placeholder="Buscar..." 
                                                type="text" />
                                            <InputGroupAddon addonType="append">
                                                <Button color='warning' 
                                                    style={{ margin: '0px 1px' }}
                                                    onClick={()=>BuscarResponsable(Estructura.Responsable)}>
                                                        <i className="nc-icon nc-zoom-split" />
                                                </Button>
                                            </InputGroupAddon> 
                                            <InputGroupAddon addonType="append">
                                                <Button color='primary' style={{ margin: '0px 1px' }}
                                                    onClick={()=>BuscarResponsable('')} className='link-button'>
                                                    <i className="fa fa-eraser" aria-hidden="true"/>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Row>
                              
                                <Row>
                                    <Col sm="12">
                                    <FormGroup>
                                        {isSeleccionable&&(<>
                                        <Select 
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            id="idSocial"                                                        
                                            placeholder="Seleccione..."
                                            options={catSocial}
                                            onChange = {(e) => {handleSelectSocial(e);}}
                                            value={Estructura.idResponsabilidad}
                                        >
                                        </Select></>)}
                                    </FormGroup>
                                    </Col>
                                </Row>
                                {isSocial.CoorGralSocial&&(<>
                                    <Row>
                                        <Col sm="12">
                                            <Label for="nombre">Código de Invitado:</Label>
                                            <Input id="nombre" value={Estructura.Codigo} maxLength='10' onChange={(e)=> setEstructura({...Estructura,Codigo:e.target.value})} />
                                        </Col>
                                    </Row>
                                </>)}
                                {isSocial.CoorEstrSocial&&(<>
                                    <FormGroup sm="12">
                                        <Label for="idCoorEstrSocial">Estructura Social:</Label>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            id="idCoorEstrSocial"                                                        
                                            placeholder="Seleccione una Estructura Social..."
                                            options={catEstructuras}
                                            onChange = {(e) => setEstructura({...Estructura,idEstructura:e})}
                                        ></Select>
                                    </FormGroup>
                                    <Row>
                                        <Col sm="12">
                                            <Label for="nombre">Código de Invitado:</Label>
                                            <Input id="nombre" value={Estructura.Codigo} maxLength='10' onChange={(e)=> setEstructura({...Estructura,Codigo:e.target.value})} />
                                        </Col>
                                    </Row>
                                
                                </>)}
                                {isSocial.CoorEstatalSocial&&(<>
                                    <Row>
                                        <Col sm="12">
                                            <Label for="codigo">Código de Invitado:</Label>
                                            <Input id="codigo" value={Estructura.Codigo} maxLength='10' onChange={(e)=> setEstructura({...Estructura,Codigo:e.target.value})} />
                                        </Col>
                                        
                                    </Row>
                                    
                                </>)}
                                {isSocial.CoordeEstructura&&(<>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup sm="12">
                                                <Label for="idCoorEstrSocial">Estructura Social:</Label>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    id="idCoorEstrSocial"                                                        
                                                    placeholder="Seleccione una Estructura Social..."
                                                    options={catEstructuras}
                                                    onChange = {(e) => setEstructura({...Estructura,idEstructura:e})}
                                                ></Select>
                                            </FormGroup>
                                            <FormGroup sm="12">
                                                <Label for="nombre">Código de Invitado:</Label>
                                                <Input id="nombre" value={Estructura.Codigo} maxLength='10' onChange={(e)=> setEstructura({...Estructura,Codigo:e.target.value})} />
                                            </FormGroup>
                                            
                                        </Col>
                                    </Row>
                                    
                                </>)}
                                {isSeleccionable&&(<>
                                    <Row>
                                        <Col>
                                            <Button id='btnsave' color='primary' size='sm' onClick={()=>setResponsable()}>Guardar</Button>
                                        </Col>
                                    </Row>  
                                </>)} 
                                <Row sm="12">
                                {loading && (<RotateSpinner color={"#1261ff"}size={30} loading={loading}/>)}
                                </Row>
                                <Row>
                                    <Col sm="12" className='text-muted'> Mostrando: <strong>{DatosResponsable.length}</strong>  de <strong>{totalResponsable}</strong> Registros </Col>
                                </Row>
                                <br/>
                                <Row>
                                   
                                    <Col sm="12">
                                        
                                        {<InfiniteScroll
                                        dataLength={totalResponsable}
                                        next={fetchHasMore}
                                        hasMore={HasMore}
                                        loader={<h5>...Cargando...</h5>}
                                        height={500}
                                        endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                        <b>¡Final de la lista de registros!</b>
                                                    </p>}
                                    >
                                        <Col md="12">
                                                
                                        </Col>
                                        {
                                            DatosResponsable.map((item,key)=>
                                                <div key={'key'+key.toString()} className="divv text-muted" > 
                                                    <strong>{item.Responsabilidad.value===30 ||item.Responsabilidad.value===31 ||item.Responsabilidad.value===34 ||
                                                                        item.Responsabilidad.value===35?item.Nombre:
                                                                        <Button name={key.toString()+"btn"} color="primary" size="sm" 
                                                                        onClick={()=>handleSetResponsable(item)} >
                                                                                {item.Nombre}</Button>
                                                                        }
                                                            
                                                            </strong>
                                                    <br/>
                                                    Celular:{item.email}
                                                    <br/>
                                                    Municipio:{item.MunicipioVive.label}
                                                    <br/>
                                                    Estructura:{item.EstructuraSocial.label}
                                                    <br/>
                                                    Responsabilidad:
                                                                {item.Responsabilidad.value===30 ||item.Responsabilidad.value===31 ||item.Responsabilidad.value===34 ||
                                                                        item.Responsabilidad.value===35?<>
                                                                        <Button className='btn-link'
                                                                        name='tooltip476609793'
                                                                        color="danger"
                                                                        id={key.toString()}
                                                                        size="sm"
                                                                        type="button"
                                                                        onClick={(e)=>onClickEliminarResponsabilidad(item.id)}
                                                                    > x
                                                                    </Button>
                                                                    </>
                                                                    :''}<strong>{item.Responsabilidad.label}</strong>
                                                    
                                                    <hr />
                                                </div>
                                            )
                                        }
                                
                                    </InfiniteScroll>}              
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    
                    </Col>    
                    <Col sm="6">
                        <Card>
                            <CardHeader>

                            </CardHeader>
                                <CardTitle tag="h4">
                                    Asignar Enlaces de la Estructura {NombreEstructura}
                                </CardTitle>
                            <CardBody>
                                <Row>
                                </Row>
                                <Row>
                                    <Col md="8">
                                        <InputGroup className="no-border">
                                        <Input value={Enlace.Responsable} 
                                            onChange={(e)=>setEnlace({...Enlace,Responsable:e.target.value})} 
                                            placeholder="Buscar..." type="text" />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>
                                            <i className="nc-icon nc-zoom-split" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                    <Col md="4" className='text-align-bottom-left' >
                                        <Button size='sm' color='warning' onClick={()=>BuscarEnlace(Enlace.Responsable)}>
                                            <i className="nc-icon nc-zoom-split" /></Button>
                                        <Button size='sm' color='primary' onClick={()=>BuscarEnlace('')} className='link-button'>
                                                <i className="fa fa-eraser" aria-hidden="true"/></Button>
                                    </Col>
                                </Row>
                               
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Select id="example-multiple-selected" isMulti="true" isSearchable options={catSubEstructuras}
                                                onChange={(event, i) => {

                                                    if (event) {
                                                        setEnlace({ ...Enlace, idEnlace: event.map(dato => { return dato.value })
                                                                            , Enlaces:event.map(dato => { return {idUser:user.id,idEstructura:dato.value} }) })

                                                    }
                                                    else {
                                                        setEnlace({
                                                            ...Enlace, idEnlace: Enlace.idEnlace.map(dato => {
                                                                if (i.removedValue.value !== dato) {
                                                                    return dato.value
                                                                }

                                                            }),
                                                            Enlaces: Enlace.Enlaces.map(dato => {
                                                                if (i.removedValue.value !== dato.idEstructura) {
                                                                    return dato
                                                                }

                                                            })
                                                        })
                                                    }


                                                }}
                                                defaultValue={Enlace.Enlaces}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false} />
                                        
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {isEtiquetable&&(<>
                                <Row>
                                    <Col>

                                        <Button id='btnsave' color='primary' size='sm' onClick={()=>saveEnlace()}>Guardar</Button>
                                        {/**<Button id='btncancela' color='danger' size='sm' onClick={onHide}>Cancelar</Button> */}
                                    </Col>
                                </Row>  
                                </>)} 
                                <Row>
                                    <Col sm="12" className='text-muted'>Mostrando: {DatosEnlace.length} de {totalEnlace} Registros</Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        {<RotateSpinner color={"#1261ff"}size={30} loading={loading}/>}
                                        {<InfiniteScroll
                                        dataLength={totalEnlace}
                                        next={fetchHasMoreEnlace}
                                        hasMore={HasMoreEnlace}
                                        loader={<h5>cargando...</h5>}
                                        height={550}
                                        endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                        <b>¡Final de la lista de registros!</b>
                                                    </p>}
                                    >
                                        <Col md="12">
                                                
                                        </Col>
                                        {
                                            DatosEnlace.map((item,id)=>
                                                <div key={'div'+id.toString()}   className="divv text-muted" >
                                                    Nombre:<strong>{<Button id={"btn"+id} color="primary" size="sm" onClick={()=>handleSetEnlace(item)} >{item.Nombre}</Button>}</strong>
                                                    <br/>
                                                    Celular:{item.email}
                                                    <br/>
                                                    Municipio:{item.MunicipioVive.label}
                                                    <br/>
                                                    Responsabilidad:{item.Responsabilidad.label}
                                                    <br/>
                                                    Enlaces:
                                                    <br/>
                                                    {item.Estructuras.map((enlace,id)=> {return <>
                                                      
                                                            <Button id={"btn2"+id} className="btn-link" color="danger" size="sm" onClick={()=>EliminarEnlace(item.id,enlace.value)}>X</Button>
                                                            {enlace.nickName}
                                                            <br />
                                                        </>})}
                                                    <hr />
                                                </div>
                                            )
                                        }
                                
                                    </InfiniteScroll>}              
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* <Row>
                    <Nav id="tabs" role="tablist" tabs>
                        <NavItem>
                            <NavLink
                                aria-expanded={horizontalTabs.horizontalTabs === "Estructuras"}
                                data-toggle="tab"
                                role="tab"
                                className={horizontalTabs.horizontalTabs === "Estructuras"
                                ? "active"
                                : ""
                                }
                                onClick={() => { changeTap("Estructuras") }}
                            >
                                Estructuras
                            </NavLink>
                        </NavItem>    
                        <NavItem>
                            <NavLink
                                aria-expanded={horizontalTabs.horizontalTabs === "Responsabilidad"}
                                data-toggle="tab"
                                role="tab"
                                className={horizontalTabs.horizontalTabs === "Responsabilidad"
                                ? "active"
                                : ""
                                }
                                onClick={() => { changeTap("Responsabilidad") }}
                            >
                                Asignar Responsabilidad
                            </NavLink>
                        </NavItem>   
                        <NavItem>
                            <NavLink
                                aria-expanded={horizontalTabs.horizontalTabs === "Enlace"}
                                data-toggle="tab"
                                role="tab"
                                className={horizontalTabs.horizontalTabs === "Enlace"
                                ? "active"
                                : ""
                                }
                                onClick={() => { changeTap("Enlace") }}
                            >
                                Asignar Enlace
                            </NavLink>
                        </NavItem>     
                    </Nav>                  
                </Row>  */}
            </Col>
        </>  
    );
};
export default Enlace;
