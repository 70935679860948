import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { notify } from "react-notify-toast";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Autosuggest from "react-autosuggest";

import moment from 'moment';
import 'moment/locale/es';
import { CircleSpinner } from "react-spinners-kit";
import { RotateSpinner } from "react-spinners-kit";


import { getVars } from "GlobalStorage";
import { getDataMenu } from 'data/menus.js';
import EjecutarApi from "data/getPOST"
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import BuscarOwned from "../Usuarios/BuscarUserOwned"

const CompromisosEdit = (props) => {
  const btnCancelar = props.btnCancelar;
  const compromisoID = props.compromisoID;
  const [catManzanas, setCatManzanas] = useState([]);
  const [iscatManzanas, setIsCatManzanas] = useState(false);
  const [catEntidad, setCatEntidad] = useState([]);
  const [catSexo, setCatSexo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCompromisoSave, setisCompromisoSave] = useState(false);
  const token = getVars("Token");
  const segmentos = getVars("Token").users_segmentos;
  const [flagUpdatePerfil, setflagUpdatePerfil] = useState(false);
  const [isModal, setisModal] = useState(false);
  const [compromiso, setCompromiso] = useState(
    {
      id: 0,
      INE: "",
      Nombre: "",
      Paterno: "",
      Materno: "",
      FechaNacimiento: "",
      Sexo: { value: "", label: "" },
      EstadoNacimiento: { value: "", label: "" }, //value & label
      CalleVive: "",
      NumExtVive: "",
      NumIntVive: "",
      CPVive: "",
      ColoniaVive: "",
      MunicipioVive: { value: "", label: "" },
      LocalidadVive: { value: "", label: "" },
      Celular: "",
      Telefono: "",
      TelMensajes: "",
      SeccionVive: "",
      isVoluntario: false,
      Voluntario: { value: "", label: "" },
      SeccionVota: "",
      MunicipioVota: { value: "", label: "" },
      CalleVota: "",
      NumExtVota: "",
      NumIntVota: "",
      CPVota: "",
      ColoniaVota: "",
      LocalidadVota: { value: "", label: "" },
      MismaAddress: 0,
      Social: [],
      UserOwned: { value: "", label: "" },
      Manzana: { value: "", label: "" }
    }
  );
  const [FlagEscogerColonia, setFlagEscogerColonia] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [catMunicipio, setCatMunicipio] = useState([]);
  const [catMunicipioFilter, setCatMunicipioFilter] = useState([]);
  const [catLocalidad, setCatLocalidad] = useState([]);
  const [catLocalidadFilter, setCatLocalidadFilter] = useState([]);
  const [catColonia, setCatColonia] = useState([]);
  const [catColoniaFilter, setCatColoniaFilter] = useState([]);

  const [catMunicipioVota, setCatMunicipioVota] = useState([]);
  const [catMunicipioVotaFilter, setCatMunicipioVotaFilter] = useState([]);
  const [catLocalidadVota, setCatLocalidadVota] = useState([]);
  const [catLocalidadVotaFilter, setCatLocalidadVotaFilter] = useState([]);
  const [catColoniasVota, setCatColoniaVota] = useState([]);
  const [catColoniasVotaFilter, setCatColoniaVotaFilter] = useState([]);
  const [dataCombosVive, setDataComboVive] = useState({
    dMunicipio: { label: 'Seleccionar...', value: 0 },
    dLocalidad: { label: 'Seleccionar...', value: 0 },
    dColonia: ""
  })
  const [dataCombosVota, setDataComboVota] = useState({
    dMunicipio: { label: 'Seleccionar...', value: 0 },
    dLocalidad: { label: 'Seleccionar...', value: 0 },
    dColonia: ""
  })

  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(6, token.menus));
    //getCompromiso...
    var parametros =
    {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [],
      id: compromisoID ? compromisoID.id : -1 //idCompromiso

    };
    //var idLocalidadVive={};
    var segmentoAux = segmentos.length > 0 ? (
      segmentos.map((item) => {
        return {
          value: item.value,
          label: item.label,
          nickName: item.nickName,
          color: ''
        }
      })) : []
    EjecutarApi(parametros, "getCompromisos", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setLoading(false);
          var tieneSegmentos = res.data.Segmentos
          var auxR = []
          var SegEtiquetado
          if (tieneSegmentos.length > 0 && segmentos.length > 0) {
            SegEtiquetado = segmentos.map((item) => {
              auxR = tieneSegmentos.filter((data) => data.value === item.value)
              if (auxR.length > 0) {
                return {
                  value: item.value,
                  label: item.label,
                  nickName: item.nickName,
                  color: 'success'
                }
              }
              else {
                return {
                  value: item.value,
                  label: item.label,
                  nickName: item.nickName,
                  color: 'info'
                }
              }

            })
          }
          else if (segmentos.length > 0 && tieneSegmentos.length === 0) {
            SegEtiquetado = segmentos.map((item) => {


              return {
                value: item.value,
                label: item.label,
                nickName: item.nickName,
                color: 'info'
              }

            })
          }

          var aux = {
            id: res.data.id,
            INE: res.data.ClaveElector,
            Nombre: res.data.Nombres,
            Paterno: res.data.Paterno,
            Materno: res.data.Materno,
            FechaNacimiento: res.data.FechaNacimiento,
            Sexo: res.data.Sexo,
            EstadoNacimiento: res.data.EstadoNacimiento, //value & label
            CalleVive: res.data.CalleVive,
            NumExtVive: res.data.NumExtVive,
            NumIntVive: res.data.NumIntVive,
            CPVive: res.data.CPVive,
            ColoniaVive: res.data.ColoniaVive,
            MunicipioVive: res.data.MunicipioVive,
            LocalidadVive: res.data.LocalidadVive,
            Celular: res.data.Celular,
            Telefono: res.data.Telefono,
            TelMensajes: res.data.TelMensajes,
            SeccionVive: res.data.SeccionVive,
            isVoluntario: res.data.isVoluntario,
            Voluntario: res.data.Voluntario,
            SeccionVota: res.data.SeccionVota,
            MunicipioVota: res.data.MunicipioVota,
            CalleVota: res.data.CalleVota,
            NumExtVota: res.data.NumExtVota,
            NumIntVota: res.data.NumIntVota,
            CPVota: res.data.CPVota,
            ColoniaVota: res.data.ColoniaVota,
            LocalidadVota: res.data.LocalidadVota,
            MismaAddress: res.data.MismaAddress === 1 ? true : false, //1=MismaAddress
            Social: SegEtiquetado,
            UserOwned: res.data.UserOwned,
            Manzana:res.data.Manzana?res.data.Manzana:{value:-1,label:"Seleccionar..."}
          }
          setCompromiso(aux);
          setDataComboVive({
            ...dataCombosVive, dMunicipio: res.data.MunicipioVive,
            dLocalidad: res.data.LocalidadVive,
            dColonia: res.data.ColoniaVive
          });

          setDataComboVota({
            ...dataCombosVota, dMunicipio: res.data.MunicipioVota,
            dLocalidad: res.data.LocalidadVota,
            dColonia: res.data.ColoniaVota
          });
          getManzanas(res.data.UserOwned.value);
       
        }
        else {
          notify.show(`Error: No se pudo localizar el compromiso`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF"
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF"
        });
      });

    EjecutarApi({}, "catCompromisosWeb", token.access_token, "GET")
      .then(function (res) {
        return res;
      })
      .then((items) => {
        if (items.results) {
          setCatEntidad(
            items.data.Entidad.map((item) => {
              return {
                value: item["value"],
                label: item["label"],
              };
            })
          );
          setCatSexo(items.data.Sexo.map(item => {
            return {
              value: item.value,
              label: item.label
            }
          }));

          let mpio = [{ value: 0, label: 'Seleccionar...' }];
          let mpioFormated = items.data.Municipio.map((item) => {
            return {
              value: item.value,
              label: item.label,
            };
          })
          setCatMunicipio(mpio.concat(mpioFormated));
          setCatMunicipioFilter(mpio.concat(mpioFormated));
          setCatMunicipioVota(mpio.concat(mpioFormated));
          setCatMunicipioVotaFilter(mpio.concat(mpioFormated));
          let localidad = [{ value: 0, label: 'Seleccionar...' }]
          let localidadFormated = items.data.Localidad.map(item => {
            return {
              value: item.value,
              label: item.label,
              Municipio: item.Municipio,
            }
          })
          setCatLocalidad(localidad.concat(localidadFormated))
          setCatLocalidadFilter(localidad.concat(localidadFormated))
          setCatLocalidadVota(localidad.concat(localidadFormated))
          setCatLocalidadVotaFilter(localidad.concat(localidadFormated))
          let colonia = [{ value: 0, label: 'Seleccionar...' }]
          let coloniaFormated = items.data.Colonias.map(item => {
            return {

              value: item.value,
              label: item.label,
              Municipio: item.D_mnpio,
              CP: item.d_codigo
            }
          })
          setCatColonia(colonia.concat(coloniaFormated))
          setCatColoniaFilter(colonia.concat(coloniaFormated))
          setCatColoniaVota(colonia.concat(coloniaFormated))
          setCatColoniaVotaFilter(colonia.concat(coloniaFormated))
          setLoading(false);
        } else {
          notify.show("No Info: catCompromisosWeb", "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
          setLoading(false);
        }

      })
      .catch((e) => {
        setLoading(false);
        notify.show("Error: catCompromisosWeb", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
    //MpioVota

  }, []);


  const btnSaveCompromiso = () => {
    setLoading(true);
    let segmentosSelected = [];
    if (compromiso.Social && compromiso.Social.length > 0) {
      compromiso.Social.map((segmento, index) => {
        if (segmento.color === 'success') {

          segmentosSelected.push(segmentos[index])
        }
      })
    }
    var parametros = {
      id: compromiso.id,
      ClaveElector: compromiso.INE ? compromiso.INE : "",
      Nombres: compromiso.Nombre ? compromiso.Nombre : "",
      Paterno: compromiso.Paterno ? compromiso.Paterno : "",
      Materno: compromiso.Materno ? compromiso.Materno : "",
      FechaNacimiento: compromiso.FechaNacimiento ? compromiso.FechaNacimiento : "",
      idSexo: compromiso.Sexo ? compromiso.Sexo.value : "",
      idEstadoNacimiento: compromiso.EstadoNacimiento.value ? compromiso.EstadoNacimiento.value : "", //value & label
      CalleVive: compromiso.CalleVive ? compromiso.CalleVive : "",
      NumExtVive: compromiso.NumExtVive ? compromiso.NumExtVive : "",
      NumIntVive: compromiso.NumIntVive ? compromiso.NumIntVive : "",
      CPVive: compromiso.CPVive ? compromiso.CPVive : "",
      ColoniaVive: dataCombosVive.dColonia,
      idMunicipioVive: dataCombosVive.dMunicipio.value,//MunicipioVive,//compromiso.MunicipioVive.value?compromiso.MunicipioVive.value:"",
      idLocalidadVive: dataCombosVive.dLocalidad.value,//compromiso.LocalidadVive.value?compromiso.LocalidadVive.value:"",
      Celular: compromiso.Celular ? compromiso.Celular : "",
      Telefono: compromiso.Telefono ? compromiso.Telefono : "",
      TelMensajes: compromiso.TelMensajes ? compromiso.TelMensajes : "",
      MismaAddress: compromiso.MismaAddress ? 1 : 0,
      isVoluntario: compromiso.isVoluntario ? compromiso.isVoluntario : "",
      idVoluntario: compromiso.Voluntario ? compromiso.Voluntario.value : 0, //value,label
      SeccionVota: compromiso.SeccionVota ? compromiso.SeccionVota : "",
      CalleVota:
        compromiso.MismaAddress
          ? compromiso.CalleVive
          : compromiso.CalleVota,
      NumExtVota:
        compromiso.MismaAddress
          ? compromiso.NumExtVive
          : compromiso.NumExtVota,
      NumIntVota:
        compromiso.MismaAddress
          ? compromiso.NumIntVive
          : compromiso.NumIntVota,
      CPVota:
        compromiso.MismaAddress ? compromiso.CPVive : compromiso.CPVota,
      idMunicipioVota: dataCombosVota.dMunicipio.value > 0 ? dataCombosVota.dMunicipio.value : "",
      ColoniaVota: dataCombosVota.dColonia,
      idLocalidadVota: dataCombosVota.dLocalidad.value > 0 ? dataCombosVota.dLocalidad.value : "",
      segmentos: segmentosSelected,
      UserOwned: token.user.id === compromiso.UserOwned.value ? '' : compromiso.UserOwned.value,
      idManzana: compromiso.Manzana.value>0?compromiso.Manzana.value:""
    };

    var validacionINE = VerificarINE(parametros.ClaveElector, parametros.Nombres, parametros.Paterno, parametros.Materno, parametros.idSexo, parametros.FechaNacimiento, parametros.idEstadoNacimiento);
    if (!validacionINE.results) {
      setLoading(false);
      notify.show("Error:" + validacionINE.message, "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.Nombres.length < 1) {
      setLoading(false);
      notify.show("Error: Nombre es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (parametros.Paterno.length < 1) {
      setLoading(false);
      notify.show("Error: Apellido Paterno es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (parametros.Materno.length < 1) {
      setLoading(false);
      notify.show("Error: Apellido Materno es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (parametros.idSexo.length < 1) {
      setLoading(false);
      notify.show("Error: Sexo es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    const INE_FECHA = parametros.ClaveElector.substring(6, 12);
    const FN =
      INE_FECHA.substring(0, 2) + '-'
      + INE_FECHA.substring(2, 4) + '-'
      + INE_FECHA.substring(4, 6);
    const fechaSelected = moment(parametros.FechaNacimiento).format("YY-MM-DD");
    if (fechaSelected !== FN) {
      setLoading(false);
      notify.show("Error: Fecha de Nacimiento es requerida o no coincide con el Id Único", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (FN !== fechaSelected) {
      setLoading(false);
      notify.show("Error: Fecha de Nacimiento es requerida o no coincide con el Id Único", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.idEstadoNacimiento < 1) {
      setLoading(false);
      notify.show("Error: Entidad de Nacimiento es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (parametros.CalleVive.length < 1) {
      setLoading(false);
      notify.show("Error: Calle  es requerido", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.NumExtVive.length < 1) {
      setLoading(false);
      notify.show("Error: Número exterior  es requerido", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.idMunicipioVive < 1) {
      setLoading(false);
      notify.show("Error: Municipio Vive es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.idLocalidadVive < 1) {
      setLoading(false);
      notify.show("Error: Localidad Vive es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.ColoniaVive.length < 1) {
      setLoading(false);
      notify.show("Error: Colonia es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.CPVive.length < 5) {
      setLoading(false);
      notify.show("Error: Código Postal, es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.SeccionVota.length < 1) {
      setLoading(false);
      notify.show("Error: Sección Vota, es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.SeccionVota.length < 1) {
      setLoading(false);
      notify.show("Error: Sección vota, es requerida", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (parametros.Celular.length < 1) {
      setLoading(false);
      notify.show("Error: Celular  es requerido", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (!parametros.MismaAddress) {
      if (parametros.CalleVota.length < 1) {
        setLoading(false);
        notify.show("Error: Calle vota es requerido", "error", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
      if (parametros.NumExtVota.length < 1) {
        setLoading(false);
        notify.show("Error: Número exterior vota es requerido", "error", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
      if (parametros.idMunicipioVota < 1) {
        setLoading(false);
        notify.show("Error: Municipio Vota es requerida", "error", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
      if (parametros.idLocalidadVota < 1) {
        setLoading(false);
        notify.show("Error: Localidad Vota es requerida", "error", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
      if (parametros.ColoniaVota.length < 1) {
        setLoading(false);
        notify.show("Error: Colonia Vota es requerida", "error", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
      if (parametros.CPVota.length < 5) {
        setLoading(false);
        notify.show("Error: Código Postal Vota, es requerida", "error", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
    }


    EjecutarApi(parametros, "udtCompromisos", token.access_token)
      .then(function (res) {
        if (res.success) {
          notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
            background: "#18c97d",
            fontSize: 24,
            text: "#FFFFFF",
          });
          setisCompromisoSave(true);
          setLoading(false);
          setisCompromisoSave(false);
        } else {
          setLoading(false);
          setisCompromisoSave(false);
          notify.show(
            `Error al hacer el registro: ${res.message}!!`,
            "custom",
            5000,
            {
              background: "#0857B6",
              fontSize: 24,
              text: "#FFFFFF",
            }
          );
        }
        return true;
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error al agregar el compromiso: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 24,
          text: "#FFFFFF",
        });
      });
  };
  const updateDataINE = (param) => {
    if (param.length > 17) {

      const INE_FECHA = param.substring(6, 12);
      const INE_ENTIDAD = param.substring(12, 14);
      const INE_SEXO = param.substring(14, 15);

      const FN = {
        YY: INE_FECHA.substring(0, 2),
        MM: INE_FECHA.substring(2, 4),
        DD: INE_FECHA.substring(4, 6),
      }
      var F2N = FN.YY + '-' + FN.MM + '-' + FN.DD//moment(FN.YY+'-'+FN.MM+'-'+FN.DD, 'YYYY/MM/dd');
      if (parseInt(FN.YY) < 10)
        F2N = '20' + FN.YY + '-' + FN.MM + '-' + FN.DD//moment(FN.YY+'-'+FN.MM+'-'+FN.DD, 'YYYY/MM/dd');
      else
        F2N = '19' + FN.YY + '-' + FN.MM + '-' + FN.DD
      const nEdoNac =
        INE_ENTIDAD < 10
          ? parseInt(INE_ENTIDAD) + 1
          : parseInt(INE_ENTIDAD) + 1;

      var Mpio = catEntidad.filter(data => { if (nEdoNac === data.value) return { data } })

      var nSexo = INE_SEXO.toUpperCase() === 'H' ? { value: 2, label: "MASCULINO" } : INE_SEXO === 'M' ? { value: 3, label: "FEMENINO" } : "";
      let fech = moment(new Date(F2N + ' 00:00:00'));
      setCompromiso({ ...compromiso, FechaNacimiento: fech, Sexo: nSexo, EstadoNacimiento: Mpio[0] })
    }
  }
  const validaINE = (param) => {
    var ClaveElector = param.ClaveElector ? param.ClaveElector : "";
    var Materno = param.Materno ? param.Materno : "";
    var Nombres = param.Nombres ? param.Nombres : "";
    var Paterno = param.Paterno ? param.Paterno : "";
    var FechaNacimiento = param.FechaNacimiento ? param.FechaNacimiento : "";
    var idEstadoNacimiento = param.idEstadoNacimiento ? param.idEstadoNacimiento : "";
    var idSexo = param.idSexo ? param.idSexo : "";

    const INE_PATERNO = ClaveElector.substring(0, 2);
    const INE_MATERNO = ClaveElector.substring(2, 4);
    const INE_NOMBRE = ClaveElector.substring(4, 6);
    const INE_FECHA = ClaveElector.substring(6, 12);
    const INE_ENTIDAD = ClaveElector.substring(12, 14);
    const INE_SEXO = ClaveElector.substring(14, 15);

    var nNombre = "";
    var nPaterno = "";
    var nMaterno = "";

    function eliminarVocales(str) {
      let resultado = str.replace(/[aeiou]/g, "");
      return resultado;
    }

    function trim(valor) {
      return valor.replace(/^\s+|\s+$/g, "");
    }

    var ItemMaterno = Materno.split(" ");

    if (ItemMaterno.length === 1) {
      const tmpMaterno = Materno.replace(/[aeiou]/gi, "").substring(0, 2);
      if (tmpMaterno.substring(0, 1) !== Materno.substring(0, 1))
        nMaterno = Materno.substring(0, 1) + tmpMaterno.substring(0, 1);
      else nMaterno = Materno.replace(/[aeiou]/gi, "").substring(0, 2);
    } else {
      var MaternoLiso = ItemMaterno.filter(
        (item) => item.toUpperCase() !== "DE"
      );
      var MaternoLiso2 = MaternoLiso.filter(
        (item) => item.toUpperCase() !== "DEL"
      );
      var MaternoLiso3 = MaternoLiso2.filter(
        (item) => item.toUpperCase() !== "LAS"
      );
      var ItemMaternoX = MaternoLiso3.filter(
        (item) => item.toUpperCase() !== "LA"
      );

      if (ItemMaternoX.length > 0) {
        const tmpMaterno = ItemMaternoX[0]
          .replace(/[aeiou]/gi, "")
          .substring(0, 2);
        if (tmpMaterno.substring(0, 1) !== ItemMaternoX[0].substring(0, 1))
          nMaterno =
            ItemMaternoX[0].substring(0, 1) + tmpMaterno.substring(0, 1);
        else
          nMaterno = ItemMaternoX[0].replace(/[aeiou]/gi, "").substring(0, 2);
      } else {
        nMaterno = "";
      }


    }

    var ItemNombre = Nombres.replace(/^\s+|\s+$/g, "")
      .replace(/[\W\d_]/g, "")
      .split(" ");

    if (ItemNombre.length === 1) {
      const tmpNombre = Nombres.replace(/[aeiou]/gi, "").substring(0, 2);
      if (tmpNombre.substring(0, 1) !== Nombres.substring(0, 1))
        nNombre = Nombres.substring(0, 1) + tmpNombre.substring(0, 1);
      else nNombre = Nombres.replace(/[aeiou]/gi, "").substring(0, 2);
    } else {
      if (trim(ItemNombre[0].replace(/[\W\d_]/g, "")).length === 1) {
        nNombre = ItemNombre[0].replace(/[\W\d_]/g, "").toUpperCase() + "X";
      } else if (trim(ItemNombre[0].replace(/[\W\d_]/g, "")).length === 2) {
        nNombre = ItemNombre[0].replace(/[\W\d_]/g, "");
      } else if (trim(ItemNombre[0].replace(/[\W\d_]/g, "")).length === 3) {
        nNombre =
          ItemNombre[0].replace(/[\W\d_]/g, "")[0] +
          eliminarVocales(ItemNombre[0].substring(1, 10)).substring(0, 1);
      } else {
        var NombreLiso = ItemNombre.filter(
          (item) => item.toUpperCase() !== "DE"
        );
        var NombreLiso2 = NombreLiso.filter(
          (item) => item.toUpperCase() !== "DEL"
        );
        var NombreLiso3 = NombreLiso2.filter(
          (item) => item.toUpperCase() !== "LAS"
        );
        var NombreLiso4 = NombreLiso3.filter(
          (item) => item.toUpperCase() !== "MARIA"
        );
        var NombreLiso5 = NombreLiso4.filter(
          (item) => item.toUpperCase() !== "JOSE"
        );
        var ItemNombreX = NombreLiso5.filter(
          (item) => item.toUpperCase() !== "LA"
        );

        if (ItemNombreX.length > 0) {
          const tmpNombre = ItemNombreX[0]
            .replace(/[aeiou]/gi, "")
            .substring(0, 2);
          if (tmpNombre.substring(0, 1) !== ItemNombreX[0].substring(0, 1))
            nNombre =
              ItemNombreX[0].substring(0, 1) + tmpNombre.substring(0, 1);
          else
            nNombre = ItemNombreX[0].replace(/[aeiou]/gi, "").substring(0, 2);
        } else {
          nNombre = "";
        }
      }
    }

    var ItemPaterno = Paterno.split(" ");


    if (ItemPaterno.length === 1) {
      const tmpPaterno = Paterno.replace(/[aeiou]/gi, "").substring(0, 2);
      if (tmpPaterno.substring(0, 1) !== Paterno.substring(0, 1))
        nPaterno = Paterno.substring(0, 1) + tmpPaterno.substring(0, 1);
      else nPaterno = Paterno.replace(/[aeiou]/gi, "").substring(0, 2);
    } else {

      var PaternoLiso = ItemPaterno.filter(
        (item) => item.toUpperCase() !== "DE"
      );
      var PaternoLiso2 = PaternoLiso.filter(
        (item) => item.toUpperCase() !== "DEL"
      );
      var PaternoLiso3 = PaternoLiso2.filter(
        (item) => item.toUpperCase() !== "LAS"
      );
      var ItemPaternoX = PaternoLiso3.filter(
        (item) => item.toUpperCase() !== "LA"
      );

      if (ItemPaternoX.length > 0) {
        const tmpPaterno = ItemPaternoX[0]
          .replace(/[aeiou]/gi, "")
          .substring(0, 2);
        if (tmpPaterno.substring(0, 1) !== ItemPaternoX[0].substring(0, 1))
          nPaterno =
            ItemPaternoX[0].substring(0, 1) + tmpPaterno.substring(0, 1);
        else
          nPaterno = ItemPaternoX[0].replace(/[aeiou]/gi, "").substring(0, 2);
      } else {
        nPaterno = "";
      }
    }
    const FN = FechaNacimiento.replace(/[-]/gi, "").substring(2, 10);

    const nEdoNac =
      idEstadoNacimiento - 1 < 10
        ? "0" + (idEstadoNacimiento - 1)
        : idEstadoNacimiento - 1;
    const nSexo = idSexo === 2 ? "h" : idSexo === 3 ? "m" : "";

    if (ClaveElector.length < 18) {
      notify.show("Error: ID Único requerido.", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (Nombres.length < 1) {
      notify.show("Error: Nombre requerido.", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (Paterno.length < 1) {
      notify.show("Error: Apellido Paterno requerido.", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (Materno.length < 1) {
      notify.show("Error: Apellido Materno requerido.", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }


    if (FechaNacimiento.length < 10) {
      notify.show("Error: Fecha Cumple requerido.", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (idSexo === 1) {
      notify.show("Error: Sexo requerido.", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (idEstadoNacimiento === 1) {
      notify.show("Error: Entidad Nacimiento requerido.", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }


  };
  const VerificarINE = (INE, NombreINE, PaternoINE, MaternoINE, idSexo, FechaNacimiento, idEstadoNacimiento) => {
    const Nombres = removeDiacritics(NombreINE);
    const Paterno = PaternoINE.toUpperCase();
    const Materno = MaternoINE.toUpperCase();
    const ClaveElector = removeDiacritics(INE);

    const INE_PATERNO = ClaveElector.substring(0, 2);
    const INE_MATERNO = ClaveElector.substring(2, 4);
    const INE_NOMBRE = ClaveElector.substring(4, 6);
    const INE_FECHA = ClaveElector.substring(6, 12);
    const INE_ENTIDAD = ClaveElector.substring(12, 14);
    const INE_SEXO = ClaveElector.substring(14, 15);

    var nNombre = '';
    var nPaterno = '';
    var nMaterno = '';

    var ItemNombre = Nombres.split(' ');

    if (ItemNombre.length === 1) {
      if (Nombres.length === 1) {
        nNombre = Nombres + 'X';
      } else {
        const tmpNombreX = Nombres.substring(1, 10);
        const tmpNombre = tmpNombreX.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);

        if (tmpNombre.length > 0) {
          nNombre = Nombres[0] + tmpNombre[0];
        } else {
          nNombre = Nombres.substring(0, 2);
        }

      }
    } else {
      if (_trim(ItemNombre[0].replace(/[\W\d_]/g, '')).length === 1) {
        nNombre = ItemNombre[0].replace(/[\W\d_]/g, '').toUpperCase() + 'X';
      } else if (_trim(ItemNombre[0].replace(/[\W\d_]/g, '')).length === 2) {
        nNombre = ItemNombre[0].replace(/[\W\d_]/g, '');
      } else if (
        _trim(ItemNombre[0].replace(/[\W\d_]/g, '')).length === 3 &&
        removeDiacritics(ItemNombre[0].toUpperCase()) !== removeDiacritics('MARÍA')
      ) {
        const tmpNombreXUA = ItemNombre[0].substring(1, 10);
        const tmpNombreUA = tmpNombreXUA.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


        if (tmpNombreUA.length > 0) {
          nNombre = ItemNombre[0][0] + tmpNombreUA[0];
        } else {
          if (ItemNombre[0].length > 1)
            nNombre = ItemNombre[0].substring(0, 2);
          else
            nNombre = ItemNombre[0][0] + 'X';
        }
      } else {

        var NombreLiso = ItemNombre.filter((item) => item.toUpperCase() != 'DE');
        var NombreLiso2 = NombreLiso.filter((item) => item.toUpperCase() != 'DEL');
        var NombreLiso3 = NombreLiso2.filter((item) => item.toUpperCase() != 'LAS');
        var NombreLiso4 = NombreLiso3.filter((item) => item.toUpperCase() != 'LOS');
        var NombreLiso5 = NombreLiso4.filter((item) => item.toUpperCase() != 'LA');

        if (NombreLiso5.length === 2) {
          if (removeDiacritics(NombreLiso5[0].toUpperCase()) === removeDiacritics('JOSE')) {
            if (removeDiacritics(NombreLiso5[1].toUpperCase()) === removeDiacritics('MARIA')) {
              nNombre = 'JS';
            } else
              if (removeDiacritics(NombreLiso5[1].toUpperCase()) === removeDiacritics('JOSE')) {
                nNombre = 'JS';
              }
              else
                if (removeDiacritics(NombreLiso5[1].toUpperCase()) === removeDiacritics('JESUS')) {
                  if (removeDiacritics(Nombres.toUpperCase()) === removeDiacritics('JOSE DE JESUS')) {
                    nNombre = 'JS';
                  } else {
                    nNombre = 'JS';
                  }
                } else {

                  var NombreLiso6 = NombreLiso5.filter((item) => removeDiacritics(item.toUpperCase()) != removeDiacritics('MARÍA'));
                  var ItemNombreX = NombreLiso6.filter((item) => removeDiacritics(item.toUpperCase()) != removeDiacritics('JOSE'));



                  if (ItemNombreX.length > 0) {
                    if (ItemNombreX[0].length === 1) {
                      nNombre = ItemNombreX[0] + 'X';
                    } else {

                      const tmpNombreX = ItemNombreX[0].substring(1, 10);
                      const tmpNombre = tmpNombreX.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);

                      if (tmpNombre.length > 0) {
                        nNombre = ItemNombreX[0][0] + tmpNombre[0];
                      } else {
                        if (ItemNombreX[0].length > 1)
                          nNombre = ItemNombreX[0].substring(0, 2);
                        else
                          nNombre = ItemNombreX[0][0] + 'X';
                      }

                    }

                  } else {
                    nNombre = '';
                    const tmpNombreXU = ItemNombre[0].substring(1, 10);
                    const tmpNombreU = tmpNombreXU.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


                    if (tmpNombreU.length > 0) {
                      nNombre = ItemNombre[0][0] + tmpNombreU[0];
                    } else {
                      if (ItemNombre[0].length > 1)
                        nNombre = ItemNombre[0].substring(0, 2);
                      else
                        nNombre = ItemNombre[0][0] + 'X';
                    }
                  }
                }

          } else
            if (removeDiacritics(NombreLiso5[0].toUpperCase()) === removeDiacritics('MARIA')) {
              if (removeDiacritics(NombreLiso5[1].toUpperCase()) === removeDiacritics('JOSEFINA')) {
                nNombre = 'JS';
              } else
                if (removeDiacritics(NombreLiso5[1].toUpperCase()) === removeDiacritics('JOSE')) {
                  nNombre = 'MR';
                }
                else
                  if (removeDiacritics(NombreLiso5[1].toUpperCase()) === removeDiacritics('JESUS')) {
                    if (removeDiacritics(Nombres.toUpperCase()) === removeDiacritics('MARIA DE JESUS')) {
                      nNombre = 'JS';
                    } else {
                      nNombre = 'JS';
                    }

                  } else {

                    var NombreLiso6 = NombreLiso5.filter((item) => removeDiacritics(item.toUpperCase()) != removeDiacritics('MARÍA'));
                    var ItemNombreX = NombreLiso6.filter((item) => removeDiacritics(item.toUpperCase()) != removeDiacritics('JOSE'));



                    if (ItemNombreX.length > 0) {
                      if (ItemNombreX[0].length === 1) {
                        nNombre = ItemNombreX[0] + 'X';
                      } else {

                        const tmpNombreX = ItemNombreX[0].substring(1, 10);
                        const tmpNombre = tmpNombreX.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


                        if (tmpNombre.length > 0) {
                          nNombre = ItemNombreX[0][0] + tmpNombre[0];
                        } else {
                          if (ItemNombreX[0].length > 1)
                            nNombre = ItemNombreX[0].substring(0, 2);
                          else
                            nNombre = ItemNombreX[0][0] + 'X';
                        }

                      }

                    } else {
                      nNombre = '';
                      const tmpNombreXU = ItemNombre[0].substring(1, 10);
                      const tmpNombreU = tmpNombreXU.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


                      if (tmpNombreU.length > 0) {
                        nNombre = ItemNombre[0][0] + tmpNombreU[0];
                      } else {
                        if (ItemNombre[0].length > 1)
                          nNombre = ItemNombre[0].substring(0, 2);
                        else
                          nNombre = ItemNombre[0][0] + 'X';
                      }
                    }

                  }

            }
            else {
              var NombreLiso6 = NombreLiso5.filter((item) => removeDiacritics(item.toUpperCase()) != removeDiacritics('MARÍA'));
              var ItemNombreX = NombreLiso6.filter((item) => removeDiacritics(item.toUpperCase()) != removeDiacritics('JOSE'));


              if (ItemNombreX.length > 0) {
                if (ItemNombreX[0].length === 1) {
                  nNombre = ItemNombreX[0] + 'X';
                } else {

                  const tmpNombreX = ItemNombreX[0].substring(1, 10);
                  const tmpNombre = tmpNombreX.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


                  if (tmpNombre.length > 0) {
                    nNombre = ItemNombreX[0][0] + tmpNombre[0];
                  } else {
                    if (ItemNombreX[0].length > 1)
                      nNombre = ItemNombreX[0].substring(0, 2);
                    else
                      nNombre = ItemNombreX[0][0] + 'X';
                  }

                }

              } else {
                nNombre = '';
                const tmpNombreXU = ItemNombre[0].substring(1, 10);
                const tmpNombreU = tmpNombreXU.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


                if (tmpNombreU.length > 0) {
                  nNombre = ItemNombre[0][0] + tmpNombreU[0];
                } else {
                  if (ItemNombre[0].length > 1)
                    nNombre = ItemNombre[0].substring(0, 2);
                  else
                    nNombre = ItemNombre[0][0] + 'X';
                }
              }
            }
        } else {
          var NombreLiso6 = NombreLiso5.filter((item) => removeDiacritics(item.toUpperCase()) != removeDiacritics('MARÍA'));
          var ItemNombreX = NombreLiso6.filter((item) => removeDiacritics(item.toUpperCase()) != removeDiacritics('JOSE'));



          if (ItemNombreX.length > 0) {
            if (ItemNombreX[0].length === 1) {
              nNombre = ItemNombreX[0] + 'X';
            } else {

              const tmpNombreX = ItemNombreX[0].substring(1, 10);
              const tmpNombre = tmpNombreX.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


              if (tmpNombre.length > 0) {
                nNombre = ItemNombreX[0][0] + tmpNombre[0];
              } else {
                if (ItemNombreX[0].length > 1)
                  nNombre = ItemNombreX[0].substring(0, 2);
                else
                  nNombre = ItemNombreX[0][0] + 'X';
              }

            }

          } else {
            nNombre = '';
            const tmpNombreXU = ItemNombre[0].substring(1, 10);
            const tmpNombreU = tmpNombreXU.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


            if (tmpNombreU.length > 0) {
              nNombre = ItemNombre[0][0] + tmpNombreU[0];
            } else {
              if (ItemNombre[0].length > 1)
                nNombre = ItemNombre[0].substring(0, 2);
              else
                nNombre = ItemNombre[0][0] + 'X';
            }
          }
        }
      }




    }

    var ItemPaterno = Paterno.split(' ');

    if (ItemPaterno.length === 1) {
      if (Paterno.length === 1) {
        nPaterno = Paterno + 'X';
      } else {

        const tmpPaternoX = Paterno.substring(1, 10);
        const tmpPaterno = tmpPaternoX.replace(/[aeiouñáéíóú]/gi, '').substring(0, 6);


        if (tmpPaterno.length > 0) {
          nPaterno = Paterno[0] + tmpPaterno[0];
        } else {
          nPaterno = Paterno.substring(0, 2);
        }

      }
    } else {

      var PaternoLiso = ItemPaterno.filter((item) => item.toUpperCase() != 'DE');
      var PaternoLiso2 = PaternoLiso.filter((item) => item.toUpperCase() != 'DEL');
      var PaternoLiso3 = PaternoLiso2.filter((item) => item.toUpperCase() != 'LAS');
      var PaternoLiso4 = PaternoLiso3.filter((item) => item.toUpperCase() != 'LOS');
      var PaternoLiso5 = PaternoLiso4.filter((item) => item.toUpperCase() != 'LA');
      var ItemPaternoX = PaternoLiso5.filter((item) => item.toUpperCase() != 'Y');


      if (ItemPaternoX.length > 0) {
        if (ItemPaternoX[0].length === 1) {
          nPaterno = ItemPaternoX[0] + 'X';
        } else {

          const tmpPaternoX = ItemPaternoX[0].substring(1, 10);
          const tmpPaterno = tmpPaternoX.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


          if (tmpPaterno.length > 0) {
            nPaterno = ItemPaternoX[0][0] + tmpPaterno[0];
          } else {
            if (ItemPaternoX[0].length > 1)
              nPaterno = ItemPaternoX[0].substring(0, 2);
            else
              nPaterno = ItemPaternoX[0][0] + 'X';

            //nPaterno = ItemPaternoX[0].substring(0, 2);
          }

        }

      } else {
        nPaterno = '';
        const tmpNombreXU = ItemPaterno[0].substring(1, 10);
        const tmpNombreU = tmpNombreXU.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


        if (tmpNombreU.length > 0) {
          nPaterno = ItemPaterno[0][0] + tmpNombreU[0];
        } else {
          if (ItemPaterno[0].length > 1)
            nPaterno = ItemPaterno[0].substring(0, 2);
          else
            nPaterno = ItemPaterno[0][0] + 'X';
        }
      }
    }

    var ItemMaterno = Materno.split(' ');


    if (ItemMaterno.length === 1) {

      if (Materno.length === 1) {
        nMaterno = Materno + 'X';
      } else {

        const tmpMaternoX = Materno.substring(1, 10);
        const tmpMaterno = tmpMaternoX.replace(/[aeiouñáéíóú]/gi, '').substring(0, 6);

        if (tmpMaterno.length > 0) {
          nMaterno = Materno[0] + tmpMaterno[0];
        } else {
          nMaterno = Materno.substring(0, 2);
        }

      }

    } else {
      var MaternoLiso = ItemMaterno.filter((item) => item.toUpperCase() != 'DE');
      var MaternoLiso2 = MaternoLiso.filter((item) => item.toUpperCase() != 'DEL');
      var MaternoLiso3 = MaternoLiso2.filter((item) => item.toUpperCase() != 'LAS');
      var MaternoLiso4 = MaternoLiso3.filter((item) => item.toUpperCase() != 'LOS');
      var MaternoLiso5 = MaternoLiso4.filter((item) => item.toUpperCase() != 'LA');
      var ItemMaternoX = MaternoLiso5.filter((item) => item.toUpperCase() != 'Y');


      if (ItemMaternoX.length > 0) {
        if (ItemMaternoX[0].length === 1) {
          nMaterno = ItemMaternoX[0] + 'X';
        } else {

          const tmpMaternoX = ItemMaternoX[0].substring(1, 10);
          const tmpMaterno = tmpMaternoX.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);

          if (tmpMaterno.length > 0) {
            nMaterno = ItemMaternoX[0][0] + tmpMaterno[0];
          } else {
            if (ItemMaternoX[0].length > 1)
              nMaterno = ItemMaternoX[0].substring(0, 2);
            else
              nMaterno = ItemMaternoX[0][0] + 'X';
          }

        }

      } else {
        nMaterno = '';
        const tmpNombreXU = ItemMaterno[0].substring(1, 10);
        const tmpNombreU = tmpNombreXU.replace(/[aeiouñáéíóú]/gi, '').substring(0, 8);


        if (tmpNombreU.length > 0) {
          nMaterno = ItemMaterno[0][0] + tmpNombreU[0];
        } else {
          if (ItemMaterno[0].length > 1)
            nMaterno = ItemMaterno[0].substring(0, 2);
          else
            nMaterno = ItemMaterno[0][0] + 'X';
        }
      }
    }

    //const FN = FechaNacimiento.replace(/[-]/gi, '').substring(2, 10);
    const F2N = {
      YY: INE_FECHA.substring(0, 2),
      MM: INE_FECHA.substring(2, 4),
      DD: INE_FECHA.substring(4, 6),
    }
    var FN = F2N.YY + F2N.MM + F2N.DD
    const nSexo = idSexo === 2 ? 'h' : idSexo === 3 ? 'm' : '';

    if (INE_NOMBRE.toUpperCase() != nNombre.toUpperCase()) {
      return { results: false, message: 'Nombre no coincide con ID Único' };
    }

    if (INE_PATERNO.toUpperCase() != nPaterno.toUpperCase()) {
      return { results: false, message: 'Apellido Paterno no coincide con ID Único' };
    }

    if (INE_MATERNO.toUpperCase() != nMaterno.toUpperCase()) {
      return { results: false, message: 'Apellido Materno no coincide con ID Único' };
    }

    if (INE_FECHA != FN) {
      return { results: false, message: 'Fecha Nacimiento no coincide con ID Único' };
    }
    if (INE_SEXO.toUpperCase() != nSexo.toUpperCase()) {
      return { results: false, message: 'Sexo no coincide con ID Único' };
    }

    return { results: true, message: 'Datos ID Único válidos' };

  };
  const removeDiacritics = (str) => {
    var diacriticsMap = {
      A: /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g,
      AA: /[\uA732]/g,
      AE: /[\u00C6\u01FC\u01E2]/g,
      AO: /[\uA734]/g,
      AU: /[\uA736]/g,
      AV: /[\uA738\uA73A]/g,
      AY: /[\uA73C]/g,
      B: /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g,
      C: /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g,
      D: /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g,
      DZ: /[\u01F1\u01C4]/g,
      Dz: /[\u01F2\u01C5]/g,
      E: /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g,
      F: /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g,
      G: /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g,
      H: /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g,
      I: /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g,
      J: /[\u004A\u24BF\uFF2A\u0134\u0248]/g,
      K: /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g,
      L: /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g,
      LJ: /[\u01C7]/g,
      Lj: /[\u01C8]/g,
      M: /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g,
      N: /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g,
      NJ: /[\u01CA]/g,
      Nj: /[\u01CB]/g,
      O: /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g,
      OI: /[\u01A2]/g,
      OO: /[\uA74E]/g,
      OU: /[\u0222]/g,
      P: /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g,
      Q: /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g,
      R: /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g,
      S: /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g,
      T: /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g,
      TZ: /[\uA728]/g,
      U: /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g,
      V: /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g,
      VY: /[\uA760]/g,
      W: /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g,
      X: /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g,
      Y: /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g,
      Z: /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g,
      a: /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g,
      aa: /[\uA733]/g,
      ae: /[\u00E6\u01FD\u01E3]/g,
      ao: /[\uA735]/g,
      au: /[\uA737]/g,
      av: /[\uA739\uA73B]/g,
      ay: /[\uA73D]/g,
      b: /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g,
      c: /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g,
      d: /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g,
      dz: /[\u01F3\u01C6]/g,
      e: /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g,
      f: /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g,
      g: /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g,
      h: /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g,
      hv: /[\u0195]/g,
      i: /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g,
      j: /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g,
      k: /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g,
      l: /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g,
      lj: /[\u01C9]/g,
      m: /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g,
      n: /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g,
      nj: /[\u01CC]/g,
      o: /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g,
      oi: /[\u01A3]/g,
      ou: /[\u0223]/g,
      oo: /[\uA74F]/g,
      p: /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g,
      q: /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g,
      r: /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g,
      s: /[\u0073\u24E2\uFF53\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g,
      ss: /[\u00DF]/g,
      t: /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g,
      tz: /[\uA729]/g,
      u: /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g,
      v: /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g,
      vy: /[\uA761]/g,
      w: /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g,
      x: /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g,
      y: /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g,
      z: /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g
    };
    for (var x in diacriticsMap) {
      // Iterate through each keys in the above object and perform a replace
      str = str.replace(diacriticsMap[x], x);
    }
    return str;
  }
  const _trim = (valor) => {
    return valor.replace(/^\s+|\s+$/g, '');
  }
  const alertaActualizacion = (
    <>
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="¿Está seguro que desea actualizar los datos?"
        onConfirm={() => btnSaveCompromiso()}
        onCancel={() => setflagUpdatePerfil(false)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Si, actualizar!"
        cancelBtnText="Cancel"
        showCancel
      ></ReactBSAlert>
    </>
  );

  const handleEstadoNacimiento = (e) => {
    var param = e;
    setCompromiso({ ...compromiso, EstadoNacimiento: e });

  }



  const handleSexo = (e) => {
    setCompromiso({ ...compromiso, Sexo: e });

  }

  const changeMupioVive = (dMunicipio) => {
    setFlagEscogerColonia(true)
    if (dMunicipio.value > 0) {

      setDataComboVive({ ...dataCombosVive, dMunicipio: dMunicipio, dLocalidad: { label: 'Seleccione...', value: 0 }, dColonia: "" })
      setCatLocalidadFilter(catLocalidad.filter(data => data.Municipio === dMunicipio.label));
      setCatColoniaFilter(catColonia.filter(data => data.Municipio === dMunicipio.label));

    }
    else {
      setDataComboVive({ ...dataCombosVive, dMunicipio: { label: 'Seleccione...', value: 0 }, dLocalidad: { label: 'Seleccione...', value: 0 }, dColonia: "" })
      setCatLocalidadFilter(catLocalidad)
      setCatColoniaFilter(catColonia)
    }
  }
  const changeLocalidadVive = (dLocalidad) => {


    if (dLocalidad.value > 0) {
      setDataComboVive({ ...dataCombosVive, dLocalidad: dLocalidad, dColonia: "" })
      setCatColoniaFilter(catColonia.filter(data => data.Municipio === dLocalidad.Municipio));

    }
    else {
      setDataComboVive({ ...dataCombosVive, dLocalidad: dLocalidad, dColonia: "" })
      setCatLocalidadFilter(catLocalidad)
      setCatColoniaFilter(catColonia)
    }
  }
  const changeColoniaVive = (dColonia) => {
    setDataComboVive({ ...dataCombosVive, dColonia })
    setCompromiso({ ...compromiso, CPVive: dColonia.CP })
  }
  const ChangeMpioVota = (dMunicipio) => {
    setCompromiso({ ...compromiso, MunicipioVota: dMunicipio })
    if (dMunicipio.value > 0) {
      //limpiaComboVive();
      setDataComboVota({ ...dataCombosVota, dMunicipio, dLocalidad: { label: 'Seleccione...', value: 0 }, dColonia: "" })
      setCatLocalidadVotaFilter(catLocalidad.filter(data => data.Municipio === dMunicipio.label));
      setCatColoniaVotaFilter(catColoniasVota.filter(data => data.value === dMunicipio.value));

    }
    else {
      setDataComboVota({ ...dataCombosVota, dMunicipio: { label: 'Seleccione...', value: 0 }, dLocalidad: { label: 'Seleccione...', value: 0 }, dColonia: "" })
      setCatLocalidadVotaFilter(catLocalidadVota)
      setCatColoniaVotaFilter(catColoniasVota)
    }
  };
  const changeLocalidadVota = (dLocalidad) => {
    setCompromiso({ ...compromiso, LocalidadVota: dLocalidad })
    if (dLocalidad.value > 0) {
      setDataComboVota({ ...dataCombosVota, dLocalidad: dLocalidad, dColonia: "" })
      setCatColoniaVotaFilter(catColoniasVota.filter(data => data.Municipio === dLocalidad.Municipio));

    }
    else {
      setDataComboVota({ ...dataCombosVota, dLocalidad: dLocalidad, dColonia: "" })
      setCatLocalidadVotaFilter(catLocalidadVota)
      setCatColoniaVotaFilter(catColoniasVota)
    }
  }
  const changeColoniaVota = (dColonia) => {
    setDataComboVota({ ...dataCombosVota, dColonia })
    setCompromiso({ ...compromiso, CPVota: dColonia.CP, ColoniaVota: dColonia })
  }

  const changeSocial = (index) => {

    let compromisosSocilales = compromiso.Social
    compromisosSocilales[index] = {
      value: compromiso.Social[index].value,
      label: compromiso.Social[index].label,
      nickName: compromiso.Social[index].nickName,
      color: compromiso.Social[index].color === 'info' ? 'success' : compromiso.Social[index].color === 'success' ? 'info' : 'success'
    }
    setCompromiso({ ...compromiso, Social: compromisosSocilales })

  }

  const changeIsVoluntario = (value) => {
    setCompromiso({
      ...compromiso,
      isVoluntario: !compromiso.isVoluntario,
    })
    if (!value) {
      setCompromiso({ ...compromiso, Voluntario: { value: 0, label: '' }, Otro: "" })
    }
  }
  const onChangeMismaAdress = (bool) => {
    setCompromiso({
      ...compromiso,
      MismaAddress: bool,
    })
  }
  const handleClickButton = (e) => { if (e) { e.preventDefault() }; }
  const handleBusarResponsble = () => {
    setisModal(true)

  }
  const onHide = () => {
    setisModal(false)
  }
  const UserOwned = (e) => {
    setCompromiso({ ...compromiso, UserOwned: { label: e.NombreCompleto, value: e.id } })
    getManzanas(e.id );
  }

  const getColoniaVotaFilter = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength < 1 || catColoniasVota.length < 1) {
      setDataComboVota({ ...dataCombosVota, dColonia: "" });
      setCompromiso({ ...compromiso, CPVota: "" })
      document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.height = "0px";
      return [];
    } else {
      const filtered = catColoniasVota.filter(item => item.label.toLowerCase().slice(0, inputLength) === inputValue);
      if (filtered.length > 0) {
        document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.height = "100px";
        document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.overflow = "auto";
        return filtered;
      } else {
        document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.height = "0px";
        return [];
      }
    }
  };
  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const onBlur = () => {
    document.getElementById("react-autowhatever-1").style.height = "0px";
  };

  const onColoniaVotaSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    document.getElementsByClassName("react-autosuggest__suggestions-container")[1].style.height = "0px";
    setDataComboVota({ ...dataCombosVota, dColonia: suggestion.value })
    setCompromiso({ ...compromiso, CPVota: suggestion.CP })
  };
  const onChangeColoniaVota = (event, { newValue }) => {
    setDataComboVota({ ...dataCombosVota, dColonia: newValue })
  };

  const getColoniaViveFilter = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength < 1 || catColonia.length < 1) {
      setDataComboVive({ ...dataCombosVive, ColoniaVive: "" });
      setCompromiso({ ...compromiso, CPVive: "" })
      document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.height = "0px";
      return [];
    } else {
      const filtered = catColonia.filter(item => item.label.toLowerCase().slice(0, inputLength) === inputValue);
      if (filtered.length > 0) {
        document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.height = "100px";
        document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.overflow = "auto";
        return filtered;
      } else {
        document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.height = "0px";
        return [];
      }
    }

  }
  const onChangeColoniaVive = (event, { newValue }) => {
    setDataComboVive({ ...dataCombosVive, dColonia: newValue });
  };

  const onColoniaViveSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    document.getElementsByClassName("react-autosuggest__suggestions-container")[0].style.height = "0px";
    setDataComboVive({ ...dataCombosVive, dColonia: suggestion.value });
    setCompromiso({ ...compromiso, CPVive: suggestion.CP })
  };

  const inputProps = {
    placeholder: "Colonia",
    className: "form-control",
    value: dataCombosVive.dColonia,
    onChange: onChangeColoniaVive,
    onKeyDown: onKeyDown,
    onBlur: onBlur
  }

  const inputProps2 = {
    id: "autoSuggest2",
    placeholder: "Colonia",
    className: "form-control",
    value: dataCombosVota.dColonia,
    onChange: onChangeColoniaVota,
    onKeyDown: onKeyDown,
    onBlur: onBlur
  };
  const getManzanas =(id)=>{
    EjecutarApi({idUser:id}, "getManzanasPorUsuario", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((items) => {
        if (items.results) {
          setCatManzanas(
            items.data.map((item) => {
              return {
                value: item.value,
                label: item.label
              };
            })
          );
          setLoading(false);
          setIsCatManzanas(true);
        } 
        else{
          setIsCatManzanas(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        notify.show("Error: catCompromisosWeb", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col sm="12">
            <CardHeader>
              <h5 className="title">Datos Personales</h5>
              <Button name="btnRegresar" size="sm" type="button" className="success" onClick={btnCancelar}>Regresar</Button>
            </CardHeader>
            <CardBody>
              <Form className="form-horizontal" id="TypeValidation">
                {_menu.Capturista === 1 && (<>
                  <Row>
                    <FormGroup className="col-md-6 text-warning">
                      <Label for="Responsable">
                        <span className="text-danger">*</span>Responsable:
                              </Label>
                      <InputGroup>
                        <Input
                          type="text"
                          value={compromiso.UserOwned ? compromiso.UserOwned.label : ''}
                          disabled
                        />
                        <InputGroupAddon addonType="append">
                          <Button
                            style={{ margin: '0px 1px' }}
                            color="warning"
                            onClick={() => handleBusarResponsble()}
                          ><i className="nc-icon nc-zoom-split" /></Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                    {iscatManzanas&&(<FormGroup className="col-md-6 text-warning">
                            {loading && (
                              <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={loading}
                              />
                            )}

                            <Label for="Responsable">
                              <span className="text-danger">*</span>Manzana:
                                </Label>
                            <Select
                              options={catManzanas}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={compromiso.Manzana}
                              defaultValue={compromiso.Manzana}
                              onChange={(e) => setCompromiso({...compromiso, Manzana:e})}
                              id="mmanzana"
                              placeholder="Manzana"
                            />
                          </FormGroup>)
                        }
                  </Row>
                </>)}
                <Row>
                  {loading && (
                    <CircleSpinner
                      className="text-center"
                      size={30}
                      color="#686769"
                      loading={loading}
                    />
                  )}

                  <FormGroup className={"col-md-4"}>
                    <Label for="INE">
                      {" "}
                      <span className="text-danger">*</span>Clave de Elector
                      </Label>
                    <Input
                      type="text"
                      id="INE"
                      value={compromiso.INE}
                      onChange={(event) => {
                        setCompromiso({
                          ...compromiso,
                          INE: event.target.value.toUpperCase(),
                        });
                      }}
                      maxLength="18"
                      onBlur={(e) => updateDataINE(e.target.value.toUpperCase())}
                    />
                  </FormGroup>
                  <FormGroup className={"col-md-4"}>
                    <Label for="Nombre">
                      <span className="text-danger">*</span>Nombre(s)
                      </Label>
                    <Input
                      id="Nombre"
                      value={compromiso.Nombre}
                      type="text"
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          Nombre: e.target.value.toUpperCase(),
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className={"col-md-4"}>
                    <Label for="inputApellido_1">
                      <span className="text-danger">*</span>Apellido Paterno
                      </Label>
                    <Input
                      id="Paterno"
                      value={compromiso.Paterno}
                      type="text"
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          Paterno: e.target.value.toUpperCase(),
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className={"col-md-4"}>
                    <Label for="Materno">
                      <span className="text-danger">*</span>Apellido Materno
                      </Label>
                    <Input
                      id="Materno"
                      value={compromiso.Materno}
                      type="text"
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          Materno: e.target.value.toUpperCase(),
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className={"col-md-2"}>
                    <Label for="Fechacumple">
                      <span className="text-danger">*</span>Fecha de
                      nacimiento
                      </Label>
                    <ReactDatetime
                      id="Fechacumple"
                      name="Fechacumple"
                      dateFormat="YYYY-MM-DD"
                      locale="es"
                      timeFormat={false}
                      closeOnSelect
                      value={compromiso.FechaNacimiento}
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          FechaNacimiento: moment(e._d).format("YYYY-MM-DD"),//moment(e._d).format("YYYY-MM-DD"),
                        })
                      }
                      inputProps={{ className: "form-control" }}
                    />
                  </FormGroup>
                  <FormGroup className={"col-md-2"}>
                    <Label for="Sexo">
                      <span className="text-danger">*</span>Sexo
                      </Label>
                    <Select
                      options={catSexo}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={compromiso.Sexo}
                      //value={{value:compromiso.Sexo.value,label:compromiso.Sexo.label}}
                      onChange={(e) => handleSexo(e)}
                      name="Sexo"
                      placeholder="Sexo"
                    />
                  </FormGroup>
                  <FormGroup className="col-md-4">
                    <Label for="EntidadNacimiento">
                      <span className="text-danger">*</span>Estado de
                      nacimiento
                      </Label>
                    <Select
                      options={catEntidad}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="EntidadNacimiento"
                      value={compromiso.EstadoNacimiento}
                      //defaultValue={{value:compromiso.EstadoNacimiento.value,label:compromiso.EstadoNacimiento.label}}
                      onChange={(e) => handleEstadoNacimiento(e)}
                      placeholder="Entidad"
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className="col-md-12 text-center text-primary">
                    <strong>Domicilio donde vive</strong>
                    <br />
                  </FormGroup>
                  <FormGroup className="col-md-4">
                    <Label for="CalleVive">
                      <span className="text-danger">*</span>Calle
                      </Label>
                    <Input
                      id="CalleVive"
                      value={compromiso.CalleVive}
                      type="text"
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          CalleVive: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="col-md-2">
                    <Label for="NumExtVive">
                      <span className="text-danger">*</span>Número Ext.
                      </Label>
                    <Input
                      id="NumExtVive"
                      value={compromiso.NumExtVive}
                      type="text"
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          NumExtVive: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="col-md-2">
                    <Label for="NumIntVive">Número Int.</Label>
                    <Input
                      id="NumIntVive"
                      value={compromiso.NumIntVive}
                      type="text"
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          NumIntVive: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="col-md-4">
                    <Label for="MunicipioVive">
                      <span className="text-danger">*</span>Municipio Vive
                      </Label>
                    <Select
                      options={catMunicipio}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="MunicipioVive"
                      value={dataCombosVive.dMunicipio}
                      onChange={(e) => changeMupioVive(e)}
                      placeholder="Municipio Vive"
                    />
                  </FormGroup>
                  <FormGroup className="col-md-4">
                    <Label for="LocalidadVive">
                      Localidad:<span className="text-danger">*</span>
                    </Label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      options={catLocalidadFilter}
                      name="Localidad"
                      onChange={(event) => { changeLocalidadVive(event) }}
                      value={dataCombosVive.dLocalidad}
                      placeholder="seleccione su localidad"
                    />
                  </FormGroup>
                  <FormGroup className="col-md-3">
                    <Label for="ColoniaINE">
                      <span className="text-danger">*</span>Colonia
                                </Label>
                    <Autosuggest
                      suggestions={catColoniaFilter}
                      onSuggestionsFetchRequested={e => setCatColoniaFilter(getColoniaViveFilter(e.value))}
                      onSuggestionsClearRequested={() => setCatColoniaFilter([])}
                      getSuggestionValue={(item) => item.label}
                      renderSuggestion={(item) => (<div>{item.label}</div>)}
                      onSuggestionSelected={onColoniaViveSelected}
                      inputProps={inputProps}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-2">
                    <Label for="CPVive">
                      <span className="text-danger">*</span>CP
                      </Label>
                    <Input
                      id="CPVive"
                      value={compromiso.CPVive}
                      type="text"
                      maxLength={5}
                      onChange={(event) => {
                        setCompromiso({
                          ...compromiso,
                          CPVive: event.target.value.replace(/\D/, ""),
                        });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-2">
                    <Label for="SeccionVota">
                      <span className="text-danger">*</span>Sección Vota
                      </Label>
                    <Input
                      id="SeccionVota"
                      value={compromiso.SeccionVota}
                      pattern="[0-9]*"
                      maxLength={5}
                      type="text"
                      onChange={(event) =>
                        setCompromiso({
                          ...compromiso,
                          SeccionVota: event.target.value.replace(/\D/, ""),
                        })
                      }
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className="col-md-12 text-center text-primary">
                    <strong>Datos de Contacto</strong>
                    <br />
                  </FormGroup>
                  <FormGroup className="col-md-3">
                    <Label for="Tel">
                      <span className="text-danger">*</span>Teléfono celular
                      </Label>
                    <Input
                      id="Tel"
                      value={compromiso.Celular}
                      pattern="[0-9]*"
                      maxLength={10}
                      type="text"
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          Celular: e.target.value.replace(/\D/, ""),
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="col-md-3">
                    <Label for="Celular">Teléfono fijo</Label>
                    <Input
                      id="Celular"
                      value={compromiso.Telefono}
                      pattern="[0-9]*"
                      maxLength={10}
                      type="text"
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          Telefono: e.target.value.replace(/\D/, ""),
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="col-md-3">
                    <Label for="Celular_1">Teléfono mensajes</Label>
                    <Input
                      id="TelMSG"
                      value={compromiso.TelMensajes}
                      pattern="[0-9]*"
                      maxLength={10}
                      type="text"
                      onChange={(e) =>
                        setCompromiso({
                          ...compromiso,
                          TelMensajes: e.target.value.replace(/\D/, ""),
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup check className="col-md-3 text-center">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={compromiso.isVoluntario}
                        onChange={(e) => changeIsVoluntario(e.target.value)}
                      />{" "}
                      Desea ser voluntario
                        <span className="form-check-sign  text-center">
                        <span className="check"></span>
                      </span>
                    </Label>
                  </FormGroup>


                </Row>
                {compromiso.Social && (
                  <Row>
                    <FormGroup className="col-md-12 text-center text-primary">
                      <strong>Segmento</strong>
                      <br />
                    </FormGroup>

                    <FormGroup className="col-md-12 text-center">

                      {compromiso.Social.length > 0 ? compromiso.Social.map((segmento, index) => {
                        return (
                          <ButtonGroup>
                            <Button key={index}
                              color={segmento.color}
                              onMouseDown={handleClickButton}
                              onClick={() => changeSocial(index)} >
                              {segmento.label}
                            </Button>
                          </ButtonGroup>

                        )
                      }) : <Label >
                          No tiene Segmentos para Asignar
                              </Label>}
                    </FormGroup>
                  </Row>
                )}
                <Row>
                  <FormGroup className="col-md-12 text-center">
                    <label>
                      ¿La dirección de la credencial de elector y donde vive son la misma?
                      </label>
                    <br />
                    <div className="form-check-radio form-check-inline">
                      <Label check>
                        <Input
                          type="radio"
                          name="exampleRadios1"
                          id="exampleRadios11"

                          checked={compromiso.MismaAddress}
                          onChange={() => onChangeMismaAdress(true)}
                        />
                        Si
                          <span className="form-check-sign"></span>
                      </Label>
                    </div>
                    <div className="form-check-radio form-check-inline">
                      <Label check>
                        <Input
                          type="radio"
                          name="exampleRadios1"
                          id="exampleRadios12"
                          checked={!compromiso.MismaAddress}
                          onChange={() => onChangeMismaAdress(false)}
                        />
                        No
                          <span className="form-check-sign"></span>
                      </Label>
                    </div>
                  </FormGroup>
                </Row>
                {!compromiso.MismaAddress && (
                  <div>
                    <Row>
                      <FormGroup className="col-md-12 text-center text-info">
                        <strong>
                          Capture información de la credencial de elector
                          </strong>
                        <br />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="col-md-4">
                        <Label for="CalleINE">
                          <span className="text-danger">*</span>Calle
                          </Label>
                        <Input
                          id="CalleINE"
                          value={compromiso.CalleVota}
                          type="text"
                          onChange={(e) =>
                            setCompromiso({
                              ...compromiso,
                              CalleVota: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-md-2">
                        <Label for="NumExtINE">
                          <span className="text-danger">*</span>Número Ext.
                          </Label>
                        <Input
                          id="NumExtINE"
                          value={compromiso.NumExtVota}
                          type="text"
                          onChange={(e) =>
                            setCompromiso({
                              ...compromiso,
                              NumExtVota: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-md-2">
                        <Label for="NumIntINE">Número Int.</Label>
                        <Input
                          id="NumIntINE"
                          value={compromiso.NumIntVota}
                          type="text"
                          onChange={(e) =>
                            setCompromiso({
                              ...compromiso,
                              NumIntVota: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <Label for="MunicipioVota">
                          <span className="text-danger">*</span>Municipio Vota
                          </Label>
                        <Select
                          options={catMunicipioVotaFilter}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="MunicipioVota"
                          value={dataCombosVota.dMunicipio}
                          onChange={(e) => ChangeMpioVota(e)}
                          placeholder="Municipio Vota"
                        />
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <Label for="LocalidadINE">
                          Localidad:<span className="text-danger">*</span>
                        </Label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          options={catLocalidadVotaFilter}
                          value={dataCombosVota.dLocalidad}
                          id="LocalidadINE"
                          onChange={(event) => changeLocalidadVota(event)}
                          placeholder="seleccione una localidad"
                        />
                      </FormGroup>
                      <FormGroup className="col-md-3">
                        <Label for="ColoniaINE">
                          <span className="text-danger">*</span>Colonia
                          </Label>
                        <Autosuggest
                          suggestions={catColoniasVotaFilter}
                          onSuggestionsFetchRequested={e => setCatColoniaVotaFilter(getColoniaVotaFilter(e.value))}
                          onSuggestionsClearRequested={() => setCatColoniaVotaFilter([])}
                          getSuggestionValue={(item) => item.label}
                          renderSuggestion={(item) => (<div>{item.label}</div>)}
                          onSuggestionSelected={onColoniaVotaSelected}
                          inputProps={inputProps2}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-2">
                        <Label for="CPINE">
                          <span className="text-danger">*</span>CP
                          </Label>
                        <Input
                          id="CPINE"
                          value={compromiso.CPVota}
                          type="text"
                          maxLength={5}
                          onChange={(event) => {
                            setCompromiso({
                              ...compromiso,
                              CPVota: event.target.value.replace(/\D/, ""),
                            });
                          }}
                        />
                      </FormGroup>
                    </Row>
                  </div>
                )}
              </Form>
              {flagUpdatePerfil ? alertaActualizacion : <></>}
            </CardBody>
            <CardFooter style={{ width: '100%' }}>
              <Row>
                <FormGroup className="col-md-12 pr-2 text-center">
                  {isCompromisoSave ? (<></>) : (
                    <Button color="primary" onClick={() => btnSaveCompromiso()}>
                      {<RotateSpinner color={"#1261ff"} size={30} loading={loading} />}
                      Guardar
                      </Button>)}
                </FormGroup>
              </Row>
            </CardFooter>
          </Col>
        </Row>
        {isModal ? <BuscarOwned onHide={() => onHide()} Owned={(e) => UserOwned(e)} /> : <></>}
      </div>
    </>
  );
};
export default CompromisosEdit;