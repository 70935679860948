import React from "react";
import { Row, Col } from "reactstrap";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const CasillasResumenChart = (props) => {
    const options =
    {
        credits: {
            enabled: false
        },
        chart: {
            type: 'column',
            height: props.data.height,
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
           // categories: props.data.names,
            labels: {
                enabled: false
            },
            title: {
                text: null
            },
            startOnTick: false,
            endOnTick: false,
            tickPositions: []
        },
        yAxis: {
            endOnTick: false,
            startOnTick: false,
            labels: {
                enabled: false
            },
            title: {
                text: null
            },
            tickPositions: [0]
        },
        legend: {
            enabled: false
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<strong>{point.y}</strong>',
            backgroundColor: 'white',
            borderWidth: 1,
            hideDelay: 0,
            shared: true,
            padding: 8,
            borderColor: 'silver',
            borderRadius: 2,
            positioner: function (w, h, point) {
                return { x: point.plotX - w / 3, y: point.plotY - (h / 2) };
            }
        },
        plotOptions: {
            series: {
                animation: false,
                lineWidth: 1,
                shadow: false,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                marker: {
                    radius: 1,
                    states: {
                        hover: {
                            radius: 2
                        }
                    }
                },
                fillOpacity: 0
            },
            column: {
                negativeColor: '#910000',
                borderColor: 'silver'
            }
        },
        series: [{
            showInLegend: false,
            data: props.data.data,
            colorByPoint: true,
        }]
    }

    return (
        <Col md="12" lg="12" sm="12">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </Col>
    )
}

export default CasillasResumenChart;