import React, { useState,useEffect } from 'react';
import { Row,Col, CardBody,Card, CardTitle, CardHeader,Table} from 'reactstrap';
import { notify } from "react-notify-toast";

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";


const DetalleUserSocial = ({User}) => {
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
    const [total,setTotal] = useState(0) 
 
    const [DetalleResponsable,setDetalleResponsable] = useState([]);


    useEffect(()=>{
        DetalleUsuario(User);
    },[])
   
    var DetalleUsuario = (item)=>{
          var parametro ={
            "idUser":item
          }
      
          EjecutarApi(parametro, 'getListadoResposabilidadWeb', token.access_token)
          .then(function (res) {
          return res;
          })
          .then((res) => {
              if (res.results) {
                  setDetalleResponsable(res.data)
                  setTotal(res.total)
                  setLoading(false)
              }else {
                notify.show(`No existe información para mostrar!!!!`, "error", 5000, {
                  background: "#d61818",
                  text: "#FFFFFF",
              });
                      setLoading(false)
              }})
          .catch((e) => {
            setLoading(false);
            notify.show(`Error en el listado: ${e.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
        });
    }
    return(
        <> 
            <Col md="12">
              
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="card-body">
                                <Row>
                                    <Col sm="12" className='text-center text-muted'>
                                        <CardHeader>
                                          <CardTitle tag="h6">Resonsablidad Asignada</CardTitle>
                                        </CardHeader>
                                          <Row>
                                            <Col md="12">
                                              <Table responsive>
                                                <tbody>
                                                <tr>
                                                    <td className="text-center">Responsabilidad</td>
                                                    <td className="text-center">Nombre Social</td>
                                                    <td className="text-center">NickName</td>
                                                    <td className="text-center">Fecha Asignación</td>
                                                  </tr>
                                                  {total>0?(<>
                                                    {DetalleResponsable.map((item)=>
                                                      <tr>
                                                        <td>{item.NombreSocial}</td>
                                                        <td>{item.nickName}</td>
                                                        <td className="text-center">{item.created_at}</td>
                                                      </tr>
                                                      )}
                                                  </>):(<></>)
                                                  }
                                                </tbody>
                                              </Table>
                                            </Col>
                                           
                                          </Row>
                                     
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            
            </Col>
        </>  
    );
};
export default DetalleUserSocial;