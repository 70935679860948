import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const DobleBarra = (props) => {

    const data = {
        chart: {
            type: 'column'
        },
        title: {
            text: props.Title
        },
        subtitle: {
            text: props.Subtitle
        },
        xAxis: {
            categories: props.Categories,
            title: {
              text: ``
            }
          },
        yAxis: [{
            min: 0,
            title: {
              text: props.yAxisTitle
            }
          }],
        legend: {
            shadow: false
        },
        tooltip: {
            shared: true
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: { fontSize: "8px" }
                }
            }
        },
        series: [
            {
              name: 'Meta',
              color: 'rgba(147,220,236,.9)',
              data: props.Meta,
              pointPadding: 0.3,
              pointPlacement: 0
            }, {
              name: 'Avance',
              color: '#0041A0',
              data: props.Series,
              pointPadding: 0.4,
              pointPlacement: 0
            }]
    };
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={data}
            type='column'
        />
    )
}
export default DobleBarra;