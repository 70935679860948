/*eslint-disable*/
import React from 'react';
import { Container, Row } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';

const Footer = (props) => {
	return (
		<footer className={'footer' + (props.default ? ' footer-default' : '')}>
			<Container fluid={props.fluid ? true : false}>
				<Row>
					<nav className="footer-nav">
						<ul>
							<li>
								<a href="#" target="_blank">
									sianGTO ver 1.2
								</a>
							</li>
						</ul>
					</nav>
					<div className="credits ml-auto">
						<span className="copyright">
							&copy; {1900 + new Date().getYear()}
							<i className="fa award heart" /> by C27
						</span>
					</div>
				</Row>
			</Container>
		</footer>
	);
};

Footer.propTypes = {
	default: PropTypes.bool,
	fluid: PropTypes.bool,
};

export default Footer;
