import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";

import MenuCartografia from '../../assets/img/home/Menu_cartografia.svg';
import MenuCatalogos from '../../assets/img/home/Menu_catalogos.svg';
import MenuCompromisos from '../../assets/img/home/Menu_compromisos.svg';
import MenuConfiguracion from '../../assets/img/home/Menu_configuracion.svg';
import MenuElectoral from '../../assets/img/home/Menu_electoral.svg';
import MenuMapas from '../../assets/img/home/Menu_mapas.svg';
import MenuPromocion from '../../assets/img/home/Menu_promocion.svg';
import MenuSocial from '../../assets/img/home/Menu_social.svg';
import MenuNumeralia from '../../assets/img/home/Menu_numeralia.svg';
import Numeralia from '../Numeralia/Numeralia';
import Nav from "reactstrap/lib/Nav";

const Home = (props) => {
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  useEffect(() => {
  
    setearMenu("Estado","Home",'');
    
  }, [])

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };
  
  const setearMenu = (dependecia,Menu,SubMenu) => {
    if (dependecia === "Estado") {
      token.menus.forEach((prop) => {
        if (prop.name === Menu) {
          if(SubMenu.length>0)
          {
            prop.views.forEach((item) =>{
                if(item.name===SubMenu)
                {
                    _setmenu({
                      Agregar: item.Agregar,
                      Editar: item.Editar,
                      Electoral: item.Electoral,
                      Eliminar: item.Eliminar,
                      Exportar: item.Exportar,
                      Propuesta:item.Propuesta,
                      Seguimiento: item.Seguimiento,
                      Territorial: item.Territorial,
                      Ver: item.Ver,
                      ViewAll:item.ViewAll,    
                    })
                } 
            })
          }else
          {
              _setmenu({
                Agregar: prop.Agregar,
                Editar: prop.Editar,
                Electoral: prop.Electoral,
                Eliminar: prop.Eliminar,
                Exportar: prop.Exportar,
                Propuesta:prop.Propuesta,
                Seguimiento: prop.Seguimiento,
                Territorial: prop.Territorial,
                Ver: prop.Ver,
                ViewAll:prop.ViewAll,            
                
              });
          }
          
        
        }
        
      });
    } else {
      var json = {};

      token.menus.forEach((prop) => {
        if (prop.name === Menu) {
          if(SubMenu.length>0)
          {
            prop.views.forEach((item) =>{
              if(item.name===SubMenu)
              {
                  json = {
                    Agregar: prop.Agregar,
                    Editar: prop.Editar,
                    Electoral: prop.Electoral,
                    Eliminar: prop.Eliminar,
                    Exportar: prop.Exportar,
                    Propuesta:prop.Propuesta,
                    Seguimiento: prop.Seguimiento,
                    Territorial: prop.Territorial,
                    Ver: prop.Ver,
                    ViewAll:prop.ViewAll,   
                  };
              }
            });

          }else{
              json = {
                  Agregar: prop.Agregar,
                  Editar: prop.Editar,
                  Electoral: prop.Electoral,
                  Eliminar: prop.Eliminar,
                  Exportar: prop.Exportar,
                  Propuesta:prop.Propuesta,
                  Seguimiento: prop.Seguimiento,
                  Territorial: prop.Territorial,
                  Ver: prop.Ver,
                  ViewAll:prop.ViewAll,   
              };
          }
          
        }
      });

      return json;
    }
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel" 
							style={{
								backgroundImage: `url(${require('../../assets/img/home/Back.jpg')})`,
							}} >
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <br />
        <div>
          <Row>
            <Col sm="4" className=' text-sm-center'>
                    <NavLink to="/numeralia">
                        <img src={MenuNumeralia} width="250px" height="250px"></img>
                    </NavLink >  
                  </Col>
            <Col sm="4" className='text-sm-center'>
                    
                    <NavLink to="/numeralia">
                        <img src={MenuMapas} width="250px" height="250px"></img>
                    </NavLink>
            </Col>
            <Col sm="4" className=' text-sm-center'>
                    <NavLink to="/numeralia">
                        <img src={MenuCartografia} width="250px" height="250px"></img>
                    </NavLink>
                </Col>
                <Col sm="4" className=' text-sm-center'>
                    <NavLink to="/numeralia">
                        <img src={MenuPromocion} width="250px" height="250px"></img>
                    </NavLink>
                </Col>
                <Col sm="4" className=' text-sm-center'>
                    <NavLink to="/numeralia">
                        <img src={MenuElectoral} width="250px" height="250px"></img>
                    </NavLink>
                  </Col>
                  <Col sm="4" className=' text-sm-center'>
                    <NavLink to="/Social">
                        <img src={MenuSocial} width="250px" height="250px"></img>
                    </NavLink >  
                  </Col>
                  <Col sm="4" className=' text-sm-center'>
                    <NavLink to="/Social">
                        <img src={MenuCompromisos} width="250px" height="250px"></img>
                    </NavLink>  
                    </Col>
                    <Col sm="4" className=' text-sm-center'> 
                    <NavLink to="/Social">
                        <img src={MenuConfiguracion} width="250px" height="250px"></img>
                    </NavLink>
                    </Col>
                    <Col sm="4" className=' text-sm-center'>
                    <NavLink to="/numeralia">
                        <img src={MenuCatalogos} width="250px" height="250px"></img>
                    </NavLink>
                    </Col>
            
          </Row>
          <Footer fluid />
        </div>
      </div>
    </div>
  );
};

export default Home;
