import React, { useState, useEffect } from 'react';
// react plugin used to create switch buttons
import Select from 'react-select';
import { Button,Col, FormGroup,Label } from 'reactstrap';


import EjecutarApi from "../../data/getPOST";
import { getVars } from '../../GlobalStorage';


function MultiFilterDF(props) {

    const token = getVars('Token').access_token;

    const [MultiValueMunicipio, setMultiValueMunicipio] = useState([]);
    const [MultiValueMunicipioSafe, setMultiValueMunicipioSafe] = useState([]);
    const [MultiValueMunicipioValue, setMultiValueMunicipioValue] = useState([]);


    const [MultiValuePoligono, setMultiValuePoligono] = useState([]);
    const [MultiValuePoligonoSafe, setMultiValuePoligonoSafe] = useState([]);
    const [MultiValuePoligonoValue, setMultiValuePoligonoValue] = useState([]);
    const [MultiValueSeccion, setMultiValueSeccion] = useState([]);
    const [MultiValueSeccionSafe, setMultiValueSeccionSafe] = useState([]);
    const [MultiValueSeccionValue, setMultiValueSeccionValue] = useState([]);
    const [DFSelected, setDFSelected] = useState([]);
    const [catDF, setCatDF] = useState([]);

    const VIEW_ESTATAL = 1
    const VIEW_DF = 2
    const VIEW_DL = 3

    useEffect(() => {

        EjecutarApi({}, "getCatalogosFiltrosNumeralia", token, "POST")
            .then(function (res) {
                return res;
            })
            .then(res => {
                if (res.results) {
                    if (res.data) {

                           // Secciones
                           let seccionFormated = res.data.seccionesdf.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.df,
                                idMunicipio: item.idMunicipio,
                                idPoligono: item.idPoligono
                            }
                        })
                        setMultiValueSeccion(seccionFormated);
                        setMultiValueSeccionSafe(seccionFormated);

                        let poligonosFormated = [];
                        res.data.poligonosDF.forEach(item => {
                            if(item.value !== 1){
                                poligonosFormated.push({
                                    value: item.value,
                                    valuef: item.valuef,
                                    label: item.label,
                                    idRegion: item.DF,
                                    idMunicipio: item.idMunicipio
                                })
                            }
                        })
                        setMultiValuePoligono(poligonosFormated);
                        setMultiValuePoligonoSafe(poligonosFormated);


                        //Municipios
                        let municipiosFormated = res.data.municipiosDF.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.DF,
                            }
                        })
                        setMultiValueMunicipio(municipiosFormated);
                        setMultiValueMunicipioSafe(municipiosFormated);


                        // Poligonos
                     
                        /* setMultiValueSeccion(seccion.concat(seccionFormated));
                        setMultiValueSeccionSafe(seccion.concat(seccionFormated)); */
                    }
                }

                if(props.responsibilityID==53){
                    setDFSelected([props.ubicaciónResponsabilidad])
                }

            })
            .catch((e) => {
            })

            EjecutarApi({}, "getCatDFResponsabilidad", token, "POST")
            .then(res => {
              if (res.data) {
  
                let dfFormated = res.data.map(item => {
                  return {
                    valuef: item.DFED,
                    value:item.id,
                    label: item.DistritoFederal
                  }
                })
  
                setCatDF(dfFormated)
              } else {
              }
            })
            .catch((e) => {
            })
           
    }, [props.viewTypeSelected]);



    const changeDF = (idDF) => {
        
        if (idDF.includes(0)) {
            setMultiValueMunicipio(MultiValueMunicipioSafe);
            if(MultiValueMunicipioValue.length !== 0){
                changeMunicipios(MultiValueMunicipioValue);
            }
            else{
                setMultiValuePoligono(MultiValuePoligonoSafe);
                if(MultiValuePoligonoValue.length !== 0){
                    var array_secciones = [];
                    MultiValuePoligonoValue.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
                else{
                    
                    setMultiValueSeccion(MultiValueSeccionSafe);
                }
                
            }

            return true;
        }
        //setLoading(true);
        if (idDF.length > 0) {
            var array_municipios = [];
            idDF.forEach((item) => {
                let array_temp = MultiValueMunicipioSafe.filter(data => data.idRegion === item);
                array_municipios = array_municipios.concat(array_temp);

            });
            setMultiValueMunicipio(array_municipios);

            if(MultiValueMunicipioValue.length !== 0){
                changeMunicipios(MultiValueMunicipioValue);
            }
            else{
                
                var array_poligonos = [];
                idDF.forEach((item) => {
                    let array_temp = MultiValuePoligonoSafe.filter(data => data.idRegion === item);
                    array_poligonos = array_poligonos.concat(array_temp);
                });
                setMultiValuePoligono(array_poligonos);

                if(MultiValuePoligonoValue.length !== 0){
                    var array_secciones = [];
                    MultiValuePoligonoValue.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
                else{
                    var array_secciones = [];
                    idDF.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => data.idRegion === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
            }
        }
        else{
            changeMunicipios([MultiValueMunicipioValue]);
        }

    }



   
    const changeMunicipios = (idMunicipios) => {

        if(idMunicipios.includes(0)){
            if(DFSelected.length !== 0){
                let array_poligonos = [];
                DFSelected.forEach((item) => {
                    let array_temp = MultiValuePoligonoSafe.filter(data => parseInt(data.idRegion,10) === item);
                    array_poligonos = array_poligonos.concat(array_temp);
                });
                setMultiValuePoligono(array_poligonos);

                if(MultiValuePoligonoValue.length !== 0){
                    let array_secciones = [];
                    MultiValuePoligonoValue.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
                else{
                    let array_secciones = [];
                    DFSelected.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => data.idRegion === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
            }
            else{
                setMultiValuePoligono(MultiValuePoligonoSafe);
                if(MultiValuePoligonoValue.length !== 0){
                    let array_secciones = [];
                    MultiValuePoligonoValue.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
                else{
                    
                    setMultiValueSeccion(MultiValueSeccionSafe);
                }
            }
            return true;
        }
        if (idMunicipios.length > 0) {

            let array_poligonos = [];
            idMunicipios.forEach((item) => {
                let array_temp = MultiValuePoligonoSafe.filter(data => parseInt(data.idMunicipio,10) === item);
                array_poligonos = array_poligonos.concat(array_temp);
            });
            setMultiValuePoligono(array_poligonos);

            if(MultiValuePoligonoValue.length !== 0){
                let array_secciones = [];
                MultiValuePoligonoValue.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);
            }
            else{
                let array_secciones = [];
                idMunicipios.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => data.idMunicipio === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);
            }
        }
        else{
            if(catDF.length !== 0){
                changeDF(catDF);
            }
            else{
                setMultiValuePoligono(MultiValuePoligonoSafe);
            }
        }
    }
    const changePoligonos = (idPoligonos) => {
        if(idPoligonos.includes(0)){
            if(MultiValueMunicipioValue.length !== 0){
                var array_secciones = [];
                MultiValueMunicipioValue.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => data.idMunicipio === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);

            }
            else if(DFSelected.length !== 0){
                var array_secciones = [];
                DFSelected.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => data.idRegion === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);
            }
            else{
                setMultiValueSeccion(MultiValueSeccionSafe);
            }
            return true;
        }
        if (idPoligonos.length > 0) {
            var array_secciones = [];
            idPoligonos.forEach((item) => {
                let array_temp = MultiValueSeccionSafe.filter(data => data.idPoligono === item);
                array_secciones = array_secciones.concat(array_temp);
            });
            setMultiValueSeccion(array_secciones);
        }
        else{
            if(MultiValueMunicipio.length !== 0){
                changeMunicipios(MultiValueMunicipio);
            }
            else if(catDF.length !== 0) {
                changeDF(catDF);
            }
            else{
                setMultiValuePoligono(MultiValuePoligonoSafe);
            }
        }
    }

    const setFilter = (event) => {
        event.preventDefault();
        const myFiltro = [];


        
        myFiltro.push({ id: 'idRegion', value: [] });
        
        if (DFSelected !== null && DFSelected.length !== 0/*  && !DFSelected.DFSelected */)
            myFiltro.push({ id: 'DF', value: DFSelected });
        else
            myFiltro.push({ id: 'DF', value: [] });

        myFiltro.push({ id: 'DL', value: [] });    
        //MultiValueMunicipio
        if (MultiValueMunicipioValue !== null && MultiValueMunicipioValue.length !== 0 /* && !MultiValueMunicipioValue.MultiValueMunicipioValue */)
            myFiltro.push({ id: 'idMunicipio', value: MultiValueMunicipioValue });
        else
            myFiltro.push({ id: 'idMunicipio', value: [] });
        
        //Seccion
        if (MultiValueSeccionValue !== null && MultiValueSeccionValue.length !== 0/*  && !MultiValueSeccionValue.MultiValueSeccionValue */)
            myFiltro.push({ id: 'idSeccion', value: MultiValueSeccionValue });
        else
            myFiltro.push({ id: 'idSeccion', value: [] });

        if (MultiValuePoligonoValue !== null && MultiValuePoligonoValue.length !== 0/*  && !MultiValuePoligonoValue.MultiValuePoligonoValue */)
            myFiltro.push({ id: 'idPoligono', value: MultiValuePoligonoValue });
        else
            myFiltro.push({ id: 'idPoligono', value: [] });
        
     
        props.filtrar(myFiltro);
    };

    

    const showMunicipio =(props.viewTypeSelected === VIEW_DF && DFSelected.length !== 0) || 
    (props.responsibilityID ===53) || (props.responsibilityID !== 12 ) || (props.responsibilityID !== 13)
     
    const showPoligonos = (props.viewTypeSelected === VIEW_DF && DFSelected.length !== 0 ) || 
    (props.responsibilityID ===53) || (props.responsibilityID !== 12 ) || (props.responsibilityID !== 13)
  
    const showSecciones = (props.viewTypeSelected === VIEW_DF && DFSelected.length !== 0 ) ||
    props.responsibilityID ===53

  
    const showDF = (props.viewTypeSelected === VIEW_DF) && props.responsibilityID !==10 && props.responsibilityID !==11 && props.responsibilityID !==12 && props.responsibilityID !==13 && props.responsibilityID !==53
  
 

    return (
        <>
            {
            showDF
            ? 
            <>
            <Col>
            <FormGroup>
                <Label for="df">
                </Label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="DF"
                    placeholder="DFED"
                    options={catDF}
                    default={props.ubicaciónResponsabilidad}
                    onChange={(event, i) => {
                        if(i.action === "clear"){
                            setDFSelected([]);
                            changeDF([0]);
                            return true;
                        }
                        if (event) {
                            const array_temp = event.map(dato => { return dato.value })
                            setDFSelected(
                                array_temp
                            )
                            changeDF(array_temp);

                        }
                        else {
                            if (i.removedValue !== undefined) {
                                const array_temp = DFSelected.map(dato => {
                                    if (i.removedValue.value !== dato) {
                                        return dato.value
                                    }

                                })
                                if(!array_temp.includes(undefined)){
                                    changeDF(array_temp);
                                    setDFSelected(array_temp);

                                }
                                else{
                                    setDFSelected([]);
                                    setMultiValueMunicipio(MultiValueMunicipioSafe);
                                    changeDF([0]);
                                    
                                }
                                    
                            }
                            else {
                                setMultiValueMunicipio(MultiValueMunicipioSafe);
                            }
                        }
                    }}

                    isMulti
                />
             </FormGroup>
            </Col>
            </>
            :
            <></>
            }

            {
            showMunicipio
            ? 
            <Col>
            <Label for="municipio">
                </Label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Municipio"
                    placeholder="MUNICIPIO"
                    options={MultiValueMunicipio}
                    onChange={(event, i) => {
                        if(i.action === "clear"){
                            setMultiValueMunicipioValue([]);
                            changeMunicipios([0]);
                            return true;
                        }
                        if (event) {
                            const array_temp = event.map(dato => { return dato.value })
                            setMultiValueMunicipioValue(
                                array_temp
                            )
                            changeMunicipios(array_temp);

                        }
                        else {
                            if (i.removedValue !== undefined) {
                                const array_temp = MultiValueMunicipioValue.map(dato => {
                                    if (i.removedValue.value !== dato) {
                                        return dato.value
                                    }
                                })
                                if(!array_temp.includes(undefined)){
                                    changeMunicipios(array_temp);
                                    setMultiValueMunicipioValue(array_temp);
                                }
                                else{
                                    setMultiValueMunicipioValue([]);
                                    changeMunicipios([0]);   
                                }
                            }
                            else{
                                //Tacha por completo
                            }

                        }
                    }}

                    isMulti
                />
            </Col>
            :
            <>
            </>
            }
                        
            {
            showPoligonos
            ? 
                <Col>
                <Label for="poligono">
                </Label>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Poligono"
                        placeholder="POLÍGONO"
                        options={MultiValuePoligono}
                        onChange={(event, i) => {
                            if(i.action === "clear"){
                                setMultiValuePoligonoValue([]);
                                changePoligonos([0]);
                                return true;
                            }
                            if (event) {
                                const array_temp = event.map(dato => { return dato.value })
                                setMultiValuePoligonoValue(
                                    array_temp
                                )
                                changePoligonos(array_temp);

                            }
                            else {
                                if (i.removedValue !== undefined) {
                                    const array_temp = MultiValuePoligonoValue.map(dato => {
                                        if (i.removedValue.value !== dato) {
                                            return dato.value
                                        }
                                    })
                                    if(!array_temp.includes(undefined)){
                                        changePoligonos(array_temp);
                                        setMultiValuePoligonoValue(array_temp);
                                    }
                                    else{
                                        setMultiValuePoligonoValue([]);
                                        changePoligonos([0]);   
                                    }
                                }
                                else{
                                }

                            }
                        }}

                        isMulti
                    />
                </Col>
                :
                <>
                </>
                }
                {
                showSecciones
                ? 
                <Col>
                <Label for="seccion">
                </Label>
                    <Select
                        name="Seccion"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="SECCIÓN"
                        options={MultiValueSeccion}
                        onChange={(event, i) => {
                            if (event) {
                                const array_temp = event.map(dato => { return dato.value })
                                setMultiValueSeccionValue(
                                    array_temp
                                )

                            }
                            else {
                                if (i.removedValue !== undefined) {
                                    const array_temp = MultiValueSeccionValue.map(dato => {
                                        if (i.removedValue.value !== dato) {
                                            return dato.value
                                        }
                                    });

                                    if(!array_temp.includes(undefined)){
                                        setMultiValueSeccionValue(array_temp);
                                    }
                                    else{
                                        setMultiValueSeccionValue([]);  
                                    }
                                }
                            }
                        }}
                        isMulti
                    />
                </Col>
                :
                <>
                </>
                }
                <Col sm='1'> 
                    <Button type="button"  className='primary float-right'  color="info" block className="btn-round" type="submit" onClick={setFilter}>
                    Filtrar
                    </Button>
                </Col>
      
        </>
                        
    );
}
export default MultiFilterDF;