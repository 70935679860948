import React, { useState, useEffect, useRef } from 'react';
//import L from 'leaflet';
import {
  Badge,
  Button,
  ButtonGroup,
  CardFooter,
  Col,
  Card,
  Form,
  Row,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  Input,
  NavLink,
  Nav,
  InputGroup,
  FormGroup,
  Label,
  InputGroupAddon
} from 'reactstrap';
import Select from 'react-select';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import EjecutarApi from '../../../../data/getPOST';
import { getVars, setVars } from '../../../../GlobalStorage';
import BuscarOwned from '../../../Usuarios/BuscarUserOwned';
//import ModalRuta from './ModalSeccionRutas';

function SeccionesCard({
  token,
  secciones_array,
  HandleSeccionesSelectedReset,
  MenuPermisosParameter,
  handleSeccionesEdit,
  handleSaveEditRuta,
  handleDeleteRuta,
  distrito_actual,
  handleSaveRuta,
  Responsabilidad,
  /* RGRutaCrear */
}) {
  
  let _data = secciones_array.length !== 0 ? secciones_array : [];
  const observed = useRef(null);
  const [total, setTotal] = useState(0);
  const [FlagAlertDelete, setFlagAlertDelete] = useState(false);
  const [FlagAlertDeleteCanceled, setFlagAlertDeleteCanceled] = useState(false);
  const [FlagAlertDeleteSuccess, setFlagAlertDeleteSuccess] = useState(false);
  const [loadingTabla, setLoadingTabla] = useState(false);
  const nombre_generado = makeid(5);
  const [data, setData] = useState({
    NombreRuta: 'Ruta ' + nombre_generado,
    ClaveRuta: 'R-' + nombre_generado,
    Comentarios: '',
    UserOwned:{ value: token.user.id, NombreCompleto: `${token.user.Nombre} ${token.user.Paterno} ${token.user.Materno}` },
  });
  const [RGRutaEdit, setRGRutaEdit] = useState([]);
  const [RGRuta, setRGsRutaParameter] = useState([]);

  const [FilterRuta, setFilterRuta] = useState([]);
  //const [isOpenModalRutas, setisOpenModalRutas] = useState(false);
  const [rutas, setRutas] = useState([]);
  const [rutaModal, setRutaModal] = useState(getVars('rutaModal'));
  const [isModal, setisModal] = useState(false);
  const [horizontalTabs, setHorizontalTabs] = useState({
    horizontalTabs:
      getVars('TAB') !== null &&
      getVars('TAB') !== undefined &&
      getVars('TAB') !== '' &&
      Object.keys(getVars('TAB')).length !== 0
        ? getVars('TAB')
        : MenuPermisosParameter.Agregar === 1
        ? 'LR'
        : 'CR',
    openedCollapses: ['collapseOne', 'collapse1'],
  });
  const [textInput, setTextInput] = useState('');
  const SaveRuta = () => {
    handleSaveRuta(true, data);
  };
  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; //'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const SaveEditRuta = () => {
    setVars('rutaModal', rutaModal);
    handleSaveEditRuta(true, rutaModal);
  };

  const DeleteRutaAlert = (
    <ReactBSAlert
      warning
      style={{ display: 'block', marginTop: '-100px' }}
      title="Alerta"
      onConfirm={
        () => {
          handleDeleteRuta(rutaModal);
          setFlagAlertDeleteSuccess(true);
          setFlagAlertDelete(false);
        } /* this.successDelete() */
      }
      onCancel={
        () => {
          setFlagAlertDeleteCanceled(true);
          setFlagAlertDelete(false);
        } /* this.cancelDetele() */
      }
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Si, eliminar!"
      cancelBtnText="Cancelar"
      showCancel
    >
      ¿Esta seguro que desea eliminar la ruta?
    </ReactBSAlert>
  );
  const successDeleteRutaAlert = (
    <ReactBSAlert
      success
      style={{ display: 'block', marginTop: '-100px' }}
      title="Eliminado!"
      onConfirm={() => setFlagAlertDeleteSuccess(false)}
      onCancel={() => setFlagAlertDeleteSuccess(false)}
      confirmBtnBsStyle="info"
    >
      ¡La ruta se eliminó correctamente!
    </ReactBSAlert>
  );

  const cancelDeleteRutaAlert = (
    <ReactBSAlert
      danger
      style={{ display: 'block', marginTop: '-100px' }}
      title="Cancelado"
      onConfirm={() => setFlagAlertDeleteCanceled(false)}
      onCancel={() => setFlagAlertDeleteCanceled(false)}
      confirmBtnBsStyle="info"
    >
      ¡Eliminacion cancelada!
    </ReactBSAlert>
  );

  const DeleteRuta = () => {
    //setVars('rutaModal', rutaModal);
    setFlagAlertDelete(true);

    //handleSaveEditRuta(true, rutaModal);
  };
  const changeTap = (e) => {
    setHorizontalTabs({ ...horizontalTabs, horizontalTabs: e });
  };
  const GetRGs = () => {
    let data = {
      filtered: [],
      page: 0,
      pageSize: 10000,
      sort: [],
      tipo: 'and',
      all_in: true,
    };
    EjecutarApi(data, 'getRGRutasGto', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setRGsRutaParameter(
              res.data.map((item) => {
                return {
                  value: item.id,
                  label: (
                    item.Nombre +
                    ' ' +
                    item.Paterno +
                    ' ' +
                    item.Materno
                  ).toUpperCase(),
                };
              })
            );
            //setLoadingTabla(false);
          }
        }
      })
      .catch((e) => {
        //setLoadingTabla(false);
        notify.show(`Error al obtener RGs: ${e.message}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  };
  const onHide = () => {
    setisModal(false);
  };
  const UserOwned = (e) => {
    setData({...data,UserOwned:e});
    setRutaModal({...rutaModal,UserOwned:e});
  };
  useEffect(()=>{
    //llenar el combo de los RGS permitidos al crear.
    GetRGs();
  },[])
  useEffect(() => {
    
    // para que no aparezca algun rg asignado en la edicion
    let arr_storage =
      JSON.stringify(getVars('RGRutaEdit')) !== '{}' &&
      JSON.stringify(getVars('RGRutaEdit')) !== '[]'
        ? getVars('RGRutaEdit')
        : [];

    setRGRutaEdit(arr_storage);
    if (observed.current) {
    }
  }, [secciones_array, distrito_actual, MenuPermisosParameter]);

  const handleSetRuta = (item) => {
    setVars('SeccionesSelected', item.Seccion);
    setVars('FlagEdit', true);
    setRutaModal(item);
    handleSeccionesEdit(item.Seccion);
    //setisOpenModalRutas(true);
    setVars('TAB', 'EDR');
    changeTap('EDR');
  };
  const handleBusarResponsble = () => {
    setisModal(true)

  }
  const handleSearchRuta = (e) => {
    setLoadingTabla(true);
    setRutas([]);
    e.preventDefault();
    var array_filter = [];
    if (parseInt(textInput, 10) !== NaN) {
      array_filter.push({ id: 'rutas.NombreRuta', value: textInput });
      array_filter.push({ id: 'seccion_rutas.Seccion', value: textInput });
    } else {
      array_filter.push({ id: 'rutas.NombreRuta', value: textInput });
    }
    setFilterRuta(array_filter);
    const data_ = {
      page: 0,
      pageSize: 10,
      filtered: array_filter,
      sort: [{ id: 'rutas.NombreRuta', desc: false }],
      tipo: 'or',
    };
    SolicitudApi(data_, 'getRutasGto', token);
  };
  const SolicitudApi = (data, api, token) => {
    EjecutarApi(data, api, token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setTotal(res.total);
            setRutas(
              res.data.map((item) => {
                return {
                  NombreRuta:
                    MenuPermisosParameter.Editar === 1 ? (
                      <>
                        <Button
                          className="btn-link"
                          color="primary"
                          onClick={() => {
                            //changeTap('EDR')
                            //Aqui hare el llamado a la api del RG en caso de no
                            //tener idRGAsignado entonces se llenara la api sin el edit_rg
                            //Ni siquiera hace falta llamar a la api con los rgs que estan el la variable: RGRuta
                            if (item.idRGAsignado) {
                              GetRGsEdit(item.idRGAsignado);
                            } else {
                              GetRGsEdit('SRG');
                            }
                            handleSetRuta(item);
                          }}
                        >
                          <i className="fa fa-pencil" />
                          {item.NombreRuta}
                        </Button>
                        {item.idRGAsignado ? (
                          <Badge
                            key={item.idRGAsignado}
                            size="sm"
                            color="success"
                            pill
                          >
                            RG
                            {/* <Tooltip placement="right" isOpen={true} toggle={true}>RG Asginado</Tooltip> */}
                          </Badge>
                        ) : (
                          <Badge
                            key={item.idRGAsignado}
                            size="sm"
                            color="danger"
                            pill
                          >
                            RG
                            {/* <Tooltip placement="right" isOpen={true} toggle={true} >Falta por asignar RG</Tooltip> */}
                          </Badge>
                        )}
                      </>
                    ) : (
                      <>
                        item.NombreRuta
                        {item.idRGAsignado ? (
                          <Badge
                            key={item.idRGAsignado}
                            size="sm"
                            color="success"
                            pill
                          >
                            RG
                            {/* <Tooltip placement="right" isOpen={true} toggle={true}>RG Asginado</Tooltip> */}
                          </Badge>
                        ) : (
                          <Badge
                            key={item.idRGAsignado}
                            size="sm"
                            color="danger"
                            pill
                          >
                            RG
                            {/* <Tooltip placement="right" isOpen={true} toggle={true} >Falta por asignar RG</Tooltip> */}
                          </Badge>
                        )}
                      </>
                    ),
                  ClaveRuta: item.ClaveRuta,
                  Secciones: item.Seccion.map((i) => {
                    return (
                      <Badge key={i} color="success" pill>
                        {i}
                      </Badge>
                    );
                  }),
                  data: item,
                };
              })
            );
            setLoadingTabla(false);
          }
        }
      })
      .catch((e) => {
        setLoadingTabla(false);
        notify.show(`Error al buscar rutas: ${e.message}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  };
  const GetRGsEdit = (RG) => {
    let data = {
      filtered: [],
      page: 0,
      pageSize: 10000,
      sort: [],
      tipo: 'and',
      //"all_in": true,
      edit_rg: RG === 'SRG' ? '' : RG,
    };
    EjecutarApi(data, 'getRGRutasGto', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setRGRutaEdit(
              res.data.map((item) => {
                return {
                  value: item.id,
                  label: (
                    item.Nombre +
                    ' ' +
                    item.Paterno +
                    ' ' +
                    item.Materno
                  ).toUpperCase(),
                };
              })
            );
            let arr_storage_rg = res.data.map((item) => {
              return {
                value: item.id,
                label: (
                  item.Nombre +
                  ' ' +
                  item.Paterno +
                  ' ' +
                  item.Materno
                ).toUpperCase(),
              };
            });
            setVars('RGRutaEdit', arr_storage_rg);
            //setLoadingTabla(false);
          }
        }
      })
      .catch((e) => {
        //setLoadingTabla(false);
        notify.show(`Error al obtener RGs: ${e.message}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  };
  return (
    <>
      <div
        ref={(el) => {
          observed.current = el;
        }}
        style={{ zIndex: '100' }}
      >
        <Notifications />
        <Card>
          {/* <CardHeader>
            <p align="center">
              <strong>RUTAS ELECTORALES</strong>
            </p>
          </CardHeader> */}
          <CardBody>
            <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <Nav id="tabs" role="tablist" tabs>
                  {MenuPermisosParameter.Agregar === 1 ? (
                    <NavItem>
                      <NavLink
                        aria-expanded={horizontalTabs.horizontalTabs === 'LR'}
                        data-toggle="tab"
                        role="tab"
                        className={
                          horizontalTabs.horizontalTabs === 'LR' ? 'active' : ''
                        }
                        onClick={() => {
                          GetRGs();
                          setVars('RGRutaEdit', []);
                          setRGRutaEdit([]);
                          setFilterRuta([]);
                          setVars('FlagEdit', false);
                          HandleSeccionesSelectedReset([]);
                          setVars('SeccionesSelected', []);
                          setRutaModal({});
                          setVars('TAB', 'LR');
                          changeTap('LR');
                        }}
                      >
                        CREAR
                      </NavLink>
                    </NavItem>
                  ) : (
                    <></>
                  )}

                  <NavItem>
                    <NavLink
                      aria-expanded={horizontalTabs.horizontalTabs === 'CR'}
                      data-toggle="tab"
                      role="tab"
                      className={
                        horizontalTabs.horizontalTabs === 'CR' ? 'active' : ''
                      }
                      onClick={() => {
                        setVars('RGRutaEdit', []);
                        setRGRutaEdit([]);
                        setFilterRuta([]);
                        setRutaModal({
                          id: '',
                          NombreRuta: '',
                          ClaveRuta: '',
                          Comentarios: '',
                        });
                        HandleSeccionesSelectedReset([]);
                        setVars('SeccionesSelected', []);

                        setVars('TAB', 'CR');
                        setVars('FlagEdit', false);
                        changeTap('CR');
                      }}
                    >
                      BUSCAR
                    </NavLink>
                  </NavItem>
                  {MenuPermisosParameter.Editar === 1 ? (
                    rutaModal.id !== undefined && rutaModal.id !== '' ? (
                      <NavItem>
                        <NavLink
                          aria-expanded={
                            horizontalTabs.horizontalTabs === 'EDR'
                          }
                          data-toggle="tab"
                          role="tab"
                          className={
                            horizontalTabs.horizontalTabs === 'EDR'
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            setFilterRuta([]);
                            setVars('FlagEdit', true);
                            setVars('TAB', 'EDR');
                            changeTap('EDR');
                          }}
                        >
                          EDITAR
                        </NavLink>
                      </NavItem>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Nav>
              </div>
            </div>

            <TabContent
              className="text-center"
              id="my-tab-content"
              activeTab={horizontalTabs.horizontalTabs}
            >
              <TabPane tabId="LR" role="tabpanel">
                {horizontalTabs.horizontalTabs === 'LR' ? (
                  <>
                    <Row>
                      <Col
                        style={{
                          height: '400pt',
                          width: '300pt',
                        }}
                      >
                        <Row style={{ padding: '5pt 0pt' }}>
                          <Col>
                            <p>
                              DISTRITO SELECCIONADO:{' '}
                              {distrito_actual !== 0
                                ? distrito_actual + ''
                                : 'Sin Seleccionar'}
                            </p>
                          </Col>
                        </Row>
                        {MenuPermisosParameter.Capturista === 1 ? (
                          <Row>
                            <FormGroup className="col-md-12 text-warning">
                              <Label for="Responsable">
                                <span className="text-danger">*</span>
                                Responsable:
                              </Label>
                              <InputGroup>
                                <Input
                                  type="text"
                                  value={data.UserOwned.NombreCompleto}
                                  disabled
                                />
                                <InputGroupAddon addonType="append">
                                  <Button
                                    style={{ margin: '0px 1px' }}
                                    color="warning"
                                    onClick={() => handleBusarResponsble()}
                                  >
                                    <i className="nc-icon nc-zoom-split" />
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Row>
                        ) : (
                          <></>
                        )}

                        <Row>
                          <Col
                            style={{
                              //'height':'300pt',
                              width: '200pt',
                            }}
                          >
                            <div style={{ padding: '5pt 0pt' }}>
                              <p><span className="text-danger">*</span>Nombre de la ruta:</p>
                              <Input
                                type="text"
                                id="NombreRuta"
                                value={data.NombreRuta}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    NombreRuta: e.target.value,
                                  });
                                }}
                              ></Input>
                            </div>
                            <div style={{ padding: '5pt 0pt' }}>
                              <p><span className="text-danger">*</span>Clave de la ruta:</p>
                              <Input
                                type="text"
                                id="ClaveRuta"
                                value={data.ClaveRuta}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    ClaveRuta: e.target.value,
                                  });
                                }}
                              ></Input>
                            </div>

                            {/* (Responsabilidad === 99 ||
                              Responsabilidad === 98 ||
                              Responsabilidad === 16 ||
                              Responsabilidad === 17 ||
                              Responsabilidad === 18 ||
                              Responsabilidad === 1
                              ) && */
                            MenuPermisosParameter.Propuesta === 0 ? (
                              <>
                                <div style={{ padding: '5pt 0pt' }}>
                                  <p>Asignar RG:</p>
                                  <Select
                                    name="RG"
                                    placeholder="Sin asignar"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    //defaultValue={RGRuta[0]}
                                    isDisabled={false}
                                    //isLoading={isLoading}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={RGRuta}
                                    onChange={(event) => {
                                      if (event) {
                                        //setRGRutaValue(event);
                                        setData({ ...data, RG: event.value });
                                      } else {
                                        setData({ ...data, RG: null });
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div style={{ padding: '5pt 0pt' }}>
                              <p>Comentarios:</p>
                              <Input
                                type="textarea"
                                id="Comentarios"
                                value={data.Comentarios}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    Comentarios: e.target.value,
                                  });
                                }}
                              ></Input>
                            </div>
                            <div>
                              <p><span className="text-danger">*</span>Secciones:</p>
                              <div>
                                {_data.length === 0 ? (
                                  <div>Sin secciones</div>
                                ) : (
                                  <div>
                                    {_data.map((item) => {
                                      return (
                                        <Badge key={item} color="success" pill>
                                          {item}
                                        </Badge>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Button
                          color="info"
                          block
                          className="btn-round"
                          type="button"
                          onClick={SaveRuta}
                        >
                          Crear Ruta
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </TabPane>
              <TabPane tabId="CR" role="tabpanel">
                {horizontalTabs.horizontalTabs === 'CR' ? (
                  <>
                    <Row>
                      <Col
                        style={{
                          height: '400pt',
                          width: '300pt',
                        }}
                      >
                        <Form onSubmit={handleSearchRuta}>
                          <input
                            className="btn-sm btn-round"
                            color={'success'}
                            style={{ height: '31px' }}
                            outline="true"
                            size="sm"
                            type="text"
                            value={textInput}
                            onChange={(e) => {
                              setTextInput(e.target.value);
                            }}
                            placeholder="Ruta o Sección"
                          ></input>
                          <ButtonGroup
                            className={'btn-group btn-group-sm'}
                            style={{ backgroundColor: 'FFF' }}
                          >
                            <Button
                              className="btn btn-sm btn-round"
                              color={'success'}
                              outline
                              type="submit"
                              onClick={handleSearchRuta}
                            >
                              Buscar
                            </Button>
                          </ButtonGroup>
                        </Form>
                        <ReactTable
                          style={{
                            height: '380pt',
                          }}
                          size="sm"
                          data={rutas}
                          noDataText="Sin información"
                          manual
                          columns={[
                            {
                              Header: 'Nombre',
                              minWidth: 20,
                              maxWidth: 200,
                              id: 'NombreRuta',
                              accessor: (d) => d.NombreRuta,
                              filterAll: false,
                            },
                            {
                              Header: 'Secciones',
                              minWidth: 20,
                              maxWidth: 200,
                              id: 'Secciones',
                              accessor: (d) => d.Secciones,
                              /* {
                              let output = [];
                              d.Secciones.map(item => {
                                  output.push(item);
                              });
                              return output.join(', ');
  
                            }, */
                              style: { whiteSpace: 'unset' },
                              filterAll: false,
                            },
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loadingTabla}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.floor(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                          //PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                            setLoadingTabla(true);
                            var array_sorted = [];
                            if (stateComp.sorted.length !== 0) {
                              stateComp.sorted.forEach((element) => {
                                if (element.id === 'NombreRuta') {
                                  array_sorted.push({
                                    id: 'rutas.NombreRuta',
                                    desc: element.desc,
                                  });
                                }
                              });
                            }
                            const data = {
                              page: stateComp.page,
                              pageSize: stateComp.pageSize,
                              filtered:
                                FilterRuta.length !== 0 ? FilterRuta : [],
                              sort:
                                array_sorted.length !== 0
                                  ? array_sorted
                                  : [{ id: 'rutas.NombreRuta', desc: false }],
                              tipo: 'and',
                            };
                            SolicitudApi(data, 'getRutasGto', token);
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </TabPane>
              <TabPane tabId="EDR" role="tabpanel">
                {horizontalTabs.horizontalTabs === 'EDR' ? (
                  <>
                    <Row>
                      <Col
                        style={{
                          height: '400pt',
                          width: '300pt',
                        }}
                      >
                        <Row style={{ padding: '5pt 0pt' }}>
                          <Col>
                            <p>
                              DISTRITO SELECCIONADO:{' '}
                              {distrito_actual !== 0
                                ? distrito_actual + ''
                                : 'Sin Seleccionar'}
                            </p>
                          </Col>
                        </Row>
                        {MenuPermisosParameter.Capturista === 1 ? (
                          
                          <Row>
                            <FormGroup className="col-md-12 text-warning">
                              <Label for="Responsable">
                                <span className="text-danger">*</span>
                                Responsable:
                              </Label>
                              <InputGroup>
                                <Input
                                  type="text"
                                  value={rutaModal.UserOwned ? rutaModal.UserOwned.NombreCompleto : ''}
                                  disabled
                                />
                                <InputGroupAddon addonType="append">
                                  <Button
                                    style={{ margin: '0px 1px' }}
                                    color="warning"
                                    onClick={() => handleBusarResponsble()}
                                  >
                                    <i className="nc-icon nc-zoom-split" />
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Row>
                        ) : (
                          <></>
                        )}

                        <Row>
                          <Col
                            style={{
                              //'height':'300pt',
                              width: '200pt',
                            }}
                          >
                            <div style={{ padding: '5pt 0pt' }}>
                              <p><span className="text-danger">*</span>Nombre de la ruta:</p>
                              <Input
                                type="text"
                                id="NombreRuta"
                                value={
                                  rutaModal.NombreRuta
                                    ? rutaModal.NombreRuta
                                    : ''
                                }
                                onChange={(e) => {
                                  setRutaModal({
                                    ...rutaModal,
                                    NombreRuta: e.target.value,
                                  });
                                }}
                              ></Input>
                            </div>
                            <div style={{ padding: '5pt 0pt' }}>
                              <p><span className="text-danger">*</span>Clave de la ruta:</p>
                              <Input
                                type="text"
                                id="ClaveRuta"
                                value={
                                  rutaModal.ClaveRuta ? rutaModal.ClaveRuta : ''
                                }
                                onChange={(e) => {
                                  setRutaModal({
                                    ...rutaModal,
                                    ClaveRuta: e.target.value,
                                  });
                                }}
                              ></Input>
                            </div>
                            {/* (Responsabilidad === 99 ||
                              Responsabilidad === 98 ||
                              Responsabilidad === 16 ||
                              Responsabilidad === 17 ||
                              Responsabilidad === 18)  && */
                            MenuPermisosParameter.Propuesta === 0/*  &&
                            RGRutaEdit.length !== 0  */? (
                              <>
                                <div style={{ padding: '5pt 0pt' }}>
                                  <p>RG:</p>
                                  <Select
                                    name="RG"
                                    placeholder="Sin asignar"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultValue={
                                      rutaModal.idRGAsignado
                                        ? RGRutaEdit.filter(
                                            (item) =>
                                              item.value ===
                                              parseInt(rutaModal.idRGAsignado, 10)
                                          )[0]
                                        : { label: 'SIN ASIGNAR', value: 0 }
                                    }
                                    value={
                                      rutaModal.idRGAsignado
                                        ? RGRutaEdit.filter(
                                            (item) =>
                                              item.value ===
                                              parseInt(rutaModal.idRGAsignado, 10)
                                          )[0]
                                        : { label: 'SIN ASIGNAR', value: 0 }
                                    }
                                    isDisabled={false}
                                    //isLoading={isLoading}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={RGRutaEdit}
                                    onChange={(event) => {
                                      if (event) {
                                        //setRGRutaValue(event);
                                        setRutaModal({
                                          ...rutaModal,
                                          idRGAsignado: event.value,
                                        });
                                      } else {
                                        setRutaModal({
                                          ...rutaModal,
                                          idRGAsignado: null,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div style={{ padding: '5pt 0pt' }}>
                              <p>Comentarios:</p>
                              <Input
                                type="textarea"
                                id="Comentarios"
                                value={
                                  rutaModal.Comentarios
                                    ? rutaModal.Comentarios
                                    : ''
                                }
                                onChange={(e) => {
                                  setRutaModal({
                                    ...rutaModal,
                                    Comentarios: e.target.value,
                                  });
                                }}
                              ></Input>
                            </div>
                            <div>
                              <p><span className="text-danger">*</span>Secciones:</p>
                              <div>
                                {_data.length === 0 ? (
                                  <div>Sin secciones</div>
                                ) : (
                                  <div>
                                    {_data.map((item) => {
                                      return (
                                        <Badge key={item} color="success" pill>
                                          {item}
                                        </Badge>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {rutaModal.id !== undefined && rutaModal.id !== '' ? (
                          <>
                            <Button
                              color="info"
                              block
                              className="btn-round"
                              type="button"
                              onClick={SaveEditRuta}
                            >
                              Guardar Cambios
                            </Button>

                            {MenuPermisosParameter.Eliminar === 1 ? (
                              <>
                                <Button
                                  color="danger"
                                  block
                                  className="btn-round"
                                  type="button"
                                  onClick={DeleteRuta}
                                >
                                  Eliminar Ruta
                                </Button>
                                {FlagAlertDelete && DeleteRutaAlert}
                                {FlagAlertDeleteSuccess &&
                                  successDeleteRutaAlert}
                                {FlagAlertDeleteCanceled &&
                                  cancelDeleteRutaAlert}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </TabPane>
            </TabContent>
          </CardBody>
          <CardFooter></CardFooter>
        </Card>
        {isModal ? (
          <BuscarOwned onHide={() => onHide()} Owned={(e) => UserOwned(e)} />
        ) : (
          <></>
        )}
        {/* isOpenModalRutas && false ? (
        <ModalRuta
          isOpen={isOpenModalRutas}
          handleModal={(e) => setisOpenModalRutas(e)}
          ruta={rutaModal}
        ></ModalRuta>
      ) : (
        <></>
      ) */}
      </div>
    </>
  );
}
export default SeccionesCard;
