import React, { useState } from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Badge,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import Select from 'react-select';

import BuscarOwned from 'components/Usuarios/BuscarUserOwned';

//import EjecutarApi from '../../../data/getPOST';
import { getVars, URL_PUBLICA } from '../../../../GlobalStorage';

const ModalRuta = ({
  isOpen,
  handleModal,
  ruta,
  rgs,
  handleUpdateRuta,
  handleDeleteRuta,
  isCapturista,
}) => {
  const [modal, setModal] = useState(isOpen);
  const [rutaModal, setRutaModal] = useState(
    Object.keys(ruta).length !== 0 ? ruta : {}
  );
  const [valueRG, setValueRG] = useState({
    value: ruta.idRGAsignado,
    label: ruta.RG,
  });
  const hostserver = URL_PUBLICA;
  //const hostserver = 'http://127.0.0.1:8000/api/';
  const toggle = () => {
    setModal(!modal);
    handleModal(!modal);
  };

  const [isOpenOwned, setIsOpenOwned] = useState(false);

  const style = {
    content: {
      border: '0',
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      padding: '2rem',
      position: 'fixed',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '20rem',
      width: '90%',
      maxWidth: '60rem',
    },
  };

  const handleChangeRG = (rg) => {
    setRutaModal({ ...rutaModal, idRGAsignado: rg.value, RG: rg.label });
    setValueRG({ value: rg.value, label: rg.label });
  };

  async function setRuta(data_ruta) {
    //setDataGeoJson({});
    const token = getVars('Token').access_token;
    try {
      let config = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: data_ruta.id,
          secciones: data_ruta.Seccion,
          NombreRuta: data_ruta.NombreRuta,
          ClaveRuta: data_ruta.ClaveRuta,
          Comentarios: data_ruta.Comentarios,
        }),
      };
      let res = await fetch(hostserver + 'updateRutasGto', config);
      let data = await res.json();
      /* setMensajeAlert(data.message);
      setFlagAlert(true);
      if (data.success === true && data.results === true) {
        setSeccionesSelected([]);
      }
      if (Object.keys(municipioSaved).length !== 0) {
        handleDetalleMapa(municipioSaved);
      } else {
        setDataGeoJson({});
        handleFiltro(filteredSaved, 'NADA');
      } */
    } catch (error) {
      /* setMensajeAlert(
        'Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.'
      );
      setFlagAlert(true); */
    }
  }

  const handleDeleteRG = () => {
    setValueRG(null);
    setRutaModal({ ...rutaModal, quitoRG: true, idRGAsignado: 0 });
  };

  const handleSetUserOwned = (user) => {
    setRutaModal({
      ...rutaModal,
      UserOwned: { value: user.id, NombreCompleto: user.NombreCompleto },
      UserOwnedChanged: user.id,
    });
  };

  return (
    <div>
      {isOpenOwned ? (
        <BuscarOwned
          onHide={() => setIsOpenOwned(false)}
          Owned={(e) => handleSetUserOwned(e)}
        />
      ) : (
        <></>
      )}
      <Modal
        isOpen={modal}
        style={style}
        toggle={toggle}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={toggle}>Mostrando "{ruta.NombreRuta}"</ModalHeader>
        <ModalBody>
          <Row>
            <Col
              style={
                {
                  //'height':'300pt',
                  //'width': '200pt',
                }
              }
            >
              <div style={{ padding: '5pt 0pt' }}>
                <p>Nombre de la ruta:</p>
                <Input
                  type="text"
                  id="NombreRuta"
                  value={rutaModal.NombreRuta ? rutaModal.NombreRuta : ''}
                  onChange={(e) => {
                    setRutaModal({ ...rutaModal, NombreRuta: e.target.value });
                  }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ padding: '5pt 0pt' }}>
                <p>Clave de la ruta:</p>
                <Input
                  type="text"
                  id="ClaveRuta"
                  value={rutaModal.ClaveRuta ? rutaModal.ClaveRuta : ''}
                  onChange={(e) => {
                    setRutaModal({ ...rutaModal, ClaveRuta: e.target.value });
                  }}
                ></Input>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="8">
              <p>RG asignado:</p>
              <Row>
                <Col>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    id="viewType"
                    placeholder="Sin asignar..."
                    options={rgs.data || []}
                    value={valueRG}
                    onChange={(e) => handleChangeRG(e)}
                  />
                </Col>
                {rutaModal.idRGAsignado !== 0 &&
                  rutaModal.idRGAsignado !== null &&
                  rutaModal.idRGAsignado !== '' &&
                  !rutaModal.quitoRG && (
                    <Col xs="2">
                      <Button outline color="warning" onClick={handleDeleteRG}>
                        X
                      </Button>
                    </Col>
                  )}
              </Row>
            </Col>
          </Row>
          {isCapturista && (
            <Row>
              <Col>
                Responsable:
                <FormGroup className="col-md-8 text-warning">
                  <InputGroup>
                    <Input
                      type="text"
                      value={rutaModal.UserOwned.NombreCompleto}
                      disabled
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        style={{ margin: '0px 1px' }}
                        color="warning"
                        onClick={() => setIsOpenOwned(true)}
                      >
                        <i className="nc-icon nc-zoom-split" />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div style={{ padding: '5pt 0pt' }}>
                <p>Comentarios:</p>
                <Input
                  type="textarea"
                  id="Comentarios"
                  value={rutaModal.Comentarios ? rutaModal.Comentarios : ''}
                  onChange={(e) => {
                    setRutaModal({ ...rutaModal, Comentarios: e.target.value });
                  }}
                ></Input>
              </div>
              <div>
                <p>Secciones:</p>
                <div>
                  {rutaModal.Seccion.length === 0 ? (
                    <div>Sin secciones</div>
                  ) : (
                    <div>
                      {rutaModal.Seccion.map((item) => {
                        return (
                          <Badge key={item} color="success" pill>
                            {item}
                          </Badge>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              handleDeleteRuta(ruta);
            }}
          >
            Eliminar
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleUpdateRuta(rutaModal);
            }}
          >
            Guardar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ModalRuta;
