import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, Progress, Badge, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import CasillasResumenChart from "components/Sije/CasillasResumenChart";
import useWindowDimensions from "components/useWindowDimensions";

const IncidenciasResumen = ({ data, isLoading }) => {
    const { height, width } = useWindowDimensions();
    const [total, setTotal] = useState({
        Favorables: 0,
        Competidas: 0,
        Adversas: 0,
    });

    const [dataChart, setDataChart] = useState({
        data: [0, 0, 0],
        height: height * 0.15,
        names:["P1","S1","S2"]
    })

    useEffect(() => {
       
        setTotal(data);
        setDataChart({ ...dataChart, data: [{y:data.Favorables,color:"#43AED2"}, {y:data.Competidas, color:"#FABB47"},{y:data.Adversas,color:"#A90011"}]});
    }, [data])

    return (
        <Row>
            <Col >
                <Card className="card-stats">
                    <CardHeader style={{ backgroundColor: "#001689" }}>
                    <Row>
                            <Col>
                                    {/* <div className="pull-right text-white" style={{ fontSize: "30px" }}>16</div> */}
                                    <h5 className="text-white text-medium margin-none strong"><i className="fa fa-warning"></i> <Link className="text-white text-medium margin-none strong" to="incidencias">Incidencias</Link></h5>
                                    {/* <h7 class="text-white">Casillas Incidentadas 2.36%</h7> */}
                                    {/* <Progress max={100} animated value={20} className="bg-info-dark" color="white">
                                    </Progress> */}
                                    {/* <br /> */}
                            </Col>
                            {isLoading && (
                                <Col md="2">
                                    <Spinner
                                        className='text-white text-medium margin-none strong'
                                    />
                                </Col>
                            )}
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="8" lg="8" sm="12">
                                <CasillasResumenChart data={dataChart}></CasillasResumenChart>
                            </Col>
                            <Col md="4" lg="4" sm="12">
                                <div className="innerAll">
                                    <ul className="list-unstyled">
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-info"></i> <strong>{total.Favorables}</strong> Nuevas</li>
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-warning"></i> <strong>{total.Competidas}</strong> En proceso</li>
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-danger"></i> <strong>{total.Adversas}</strong> Atendidas</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default IncidenciasResumen;