import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Card, Row, Col, Label, CardHeader } from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import { CircleSpinner } from 'react-spinners-kit';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Pagination from 'components/Pagination/pagination';
import { URL_GENERAL } from 'data/Consts';
import EjecutarApi from 'data/getPOST';
import ModalFiltro from 'components/casillasOperacion/Filtro';
import { getDataMenu } from 'data/menus.js';
import { numberWithCommas } from '../../utils/funciones';
import CasillasChart from 'components/Sije/CasillasChart';
import ChartInicio from 'components/Sije/ChartInicio';
import DobleBarra from 'components/Sije/ChartInicioDobleBarra';
import { getVars, URL_PUBLICA_REPORTE, tiempoSije, INTERVAL } from 'GlobalStorage';
import CardBody from 'reactstrap/lib/CardBody';
import CasillasResumen from "components/Sije/CasillasResumen";
import IncidenciasResumen from "components/Sije/IncidenciasResumen";
import Acta from "components/Sije/ActasResumen";

const CasillasOperacion = (props) => {
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  const [loading, setLoading] = useState(false);
  const [Filtro, setFiltro] = useState([]);
  const [sorted, setSortered] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [loadingTotales, setLoadingTotales] = useState(false);
  const [loadingActas, setLoadingActas] = useState(false);
  const [totalesCasillas, setTotalesCasillas] = useState([]);
  const [totalAsistencias, setTotalAsistencias] = useState([]);
  const [totalIncidencias, setTotalIncidencias] = useState([]);
  const [totalActas, setTotalActas] = useState(
    { ActaRecibida: 0, TotalActas: 0 }
  );
  const [tipoEleccion, setTipoEleccion] = useState("");
  const [isLoadingGrafica, setIsLoadingGrafica] = useState(false);
  const [filteredTotales, setFilteredTotales] = useState([])
  const [infoGraficas, setInfoGraficas] = useState({
    series: [],
    categories: [],
  });
  const [counter, setCounter] = useState(0);

  const [infoGraficasCierre, setInfoGraficasCierre] = useState({
    series: [],
    categories: [],
  });


  const [infoGraficasRCs, setInfoGraficasRCs] = useState({
    series: [],
    categories: [],
  });
  const [infoGraficasRGs, setInfoGraficasRGs] = useState({
    series: [],
    categories: [],
  });
  const styleHeader = {
    textAlign: 'center',
    overflow: 'visible',
    fontSize: '11px',
    color: '#363636',
    lineHeight: 'normal !important',
  };

  useEffect(() => {
    _setmenu(getDataMenu(164, token.menus))
  }, [])


  useEffect(() => {

    getDatosGrafica();
    getTotales();
  }, [Filtro]);


  const getTotales = () => {
    setLoadingTotales(true);
    EjecutarApi(
      {
        ambito: 1,
        tipoVista: 0,
        totales: ["casillas", "incidencias"],
        filtered: filteredTotales
      },
      'sije/getTotales',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then(res => {
        if (res.success) {
          if (res.results) {
            setTotalesCasillas(res.data.casillas);
            setTotalAsistencias(res.data.asistencias);
            var inciden = {
              "Favorables": res.data.incidencias[0] ? res.data.incidencias[0].Total : 0,
              "Competidas": res.data.incidencias[1] ? res.data.incidencias[1].Total : 0,
              "Adversas": res.data.incidencias[2] ? res.data.incidencias[2].Total : 0
            };
            setTotalIncidencias(inciden)
            // setTotalActas({ "ActaRecibida": res.data.Actas.recibidas, "TotalActas": res.data.Actas.total })
          }
          else {
            notify.show(res.message, 'warning', 5000);
          }
        } else {
          notify.show(res.message, 'warning', 5000);
        }
        setLoadingTotales(false);
      })
      .catch(e => {
        notify.show(`Error al cargar : ${e.message}}`, 'error', 5000);
        setLoadingTotales(false);
      });
  }


  const getDatosGrafica = () => {

    setIsLoadingGrafica(true);

    EjecutarApi(
      {
        filtered: Filtro,
        tipo: "and"
      },
      'getGraficaAperturaCierreSije',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setInfoGraficas(
            {
              "categories": res.data.Apertura.Categorias,
              "series": [
                { "name": "Instalación", "data": res.data.Apertura.Instalacion },
                { "name": "Apertura", "data": res.data.Apertura.Apertura }
              ]
            }

          );
        }
        setIsLoadingGrafica(false);
      })
      .catch((e) => {
        setIsLoadingGrafica(false);
      });


    EjecutarApi(
      {
        filtered: Filtro,
        tipo: "and"
      },
      'getGraficaAperturaCierreSije',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setInfoGraficasCierre(
            {
              "categories": res.data.Cierre.Categorias,
              "series": [
                { "name": "Cierre Casilla", "data": res.data.Cierre.Casilla },
                { "name": "Cierre Votación", "data": res.data.Cierre.Votacion }
              ]
            }

          );
        }
        setIsLoadingGrafica(false);
      })
      .catch((e) => {
        setIsLoadingGrafica(false);
      });

    EjecutarApi(
      {
        filtered: Filtro,
        tipo: "and"
      },
      'getGraficaPaseLista',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          var Meta = res.data.Resumen.Categories.map(element => { return res.data.Resumen.totalCasillas });

          setInfoGraficasRCs(
            {
              "categories": res.data.Resumen.Categories,
              "series": res.data.Resumen.Serie,
              "Meta": Meta,
              "Actualizado": res.data.Actualizado
            });
        }
        setIsLoadingGrafica(false);
      })
      .catch((e) => {
        setIsLoadingGrafica(false);
      });

    EjecutarApi(
      {
        filtered: Filtro,
        tipo: "and"
      },
      'getGraficaPaseListaRG',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setInfoGraficasRGs(
            {
              "categories": ["RGs"],
              "series": [res.data.Avance],
              "Meta": [res.data.Meta]
            });
        }
        setIsLoadingGrafica(false);
      })
      .catch((e) => {
        setIsLoadingGrafica(false);
      });
  };

  const header = (text) => {
    return <Label style={styleHeader}>{text}</Label>;
  };
  const Exportar = () => {


    const data = {
      page: 1,
      pageSize: 10,
      filtered: Filtro,
      sorted: sorted,
      tipo: 'and',
      Export: true
    };

    var datos = JSON.stringify(data)
    var url = new URL(
      `${URL_PUBLICA_REPORTE}ExportCasillasOperacion?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");

  }

  const columns = [
    {
      Header: header('DF'),
      minWidth: 35,
      id: 'cat_df.DistritoFederal',
      accessor: 'DFED',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('DL'),
      minWidth: 35,
      id: 'cat_dl.DistritoLocal',
      accessor: 'DLOC',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },

    {
      Header: header('Municipios'),
      minWidth: 150,
      id: 'cat_municipios_reportes.Municipio',
      accessor: 'Municipio',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('Sección'),
      minWidth: 60,
      id: 'secciones.Seccion',
      accessor: 'Seccion',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('Casilla'),
      minWidth: 150,
      id: 'casillas_sije.NombreCasilla',
      accessor: 'NombreCasilla',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('Asist.RG'),
      minWidth: 60,
      id: 'PaseListaRG',
      accessor: 'PaseListaRG',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('RCP1'),
      minWidth: 60,
      id: 'PaseListaP1',
      accessor: 'PaseListaP1',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('RCP2'),
      minWidth: 60,
      id: 'PaseListaP2',
      accessor: 'PaseListaP2',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('RCS1'),
      minWidth: 60,
      id: 'PaseListaS1',
      accessor: 'PaseListaS1',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('RCS2'),
      minWidth: 60,
      id: 'PaseListaS2',
      accessor: 'PaseListaS2',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('Instalación'),

      minWidth: 60,
      id: 'InicioInstalacion',
      accessor: 'InicioInstalacion',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('Apertura'),
      minWidth: 60,
      id: 'AperturaCasilla',
      accessor: 'AperturaCasilla',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },

    {
      Header: header('CierreCasilla'),
      minWidth: 60,
      id: 'CierreCasilla',
      accessor: 'CierreCasilla',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('CierreVotación'),
      id: 'CierreVotación',
      accessor: 'CierreVotacion',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    },
    {
      Header: header('Incidencia'),
      id: 'Incidencia',
      accessor: 'Incidencia',
      filterAll: false,
      headerStyle: { fontSize: '9px' }
    }
  ];

  const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
    const dato = {
      page: page,
      pageSize: pageSize,
      filtered: params,
      sorted: sorted,
      tipo: 'and',
    };
    setPageSize(pageSize);
    setPage(pageSize);
    setSortered(sorted);
    getRegistros(dato);
  }, []);

  const getActas = (tipoEleccion) => {
    setLoadingActas(true);
    EjecutarApi(
      {
        ambito: 1,
        tipoVista: 0,
        totales: ["Actas"],
        idTipoEleccion: tipoEleccion.value,
        filtered: filteredTotales
      },
      'sije/getTotales',
      token.access_token
    )
      .then(function (res) {
        return res;
      }).then(res => {
        setTipoEleccion(tipoEleccion.label)
        setLoadingActas(false);
        setTotalActas({ "ActaRecibida": res.data.Actas.recibidas, "TotalActas": res.data.Actas.total })
      })
      .catch(e => {
        notify.show(`Error al cargar : ${e.message}}`, 'error', 5000);
        setLoadingActas(false);
      });

    setLoadingActas(true);
  }

  const getRegistros = (data) => {
    setLoading(true);

    EjecutarApi(data, 'getCasillasOperacion', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotal(res.total);
          setData(
            res.data.map((prop, key) => {
              return prop;
            })
          );
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleFiltro = (filter) => {
    var _filtro = [];
    var _filtroTotales = [];
    if (filter.DF.value > 0) {
      _filtro.push({ id: 'casillas_sije.idDF', value: filter.DF.value });
      _filtroTotales.push({id: 'idDF', value: filter.DF.value })
    }
    if (filter.DL.value > 0) {
      _filtro.push({ id: 'casillas_sije.idDL', value: filter.DL.value });
      _filtroTotales.push({ id: 'idDL', value: filter.DL.value });
    }
    if (filter.Region.value > 0) {
      _filtroTotales.push({ id: 'idRegion', value: filter.Region.value });
    }
    if (filter.Municipio.value > 0) {
      _filtro.push({
        id: 'casillas_sije.idMunicipioReportes',
        value: filter.Municipio.value,
      });
      _filtroTotales.push({
        id: 'idMunicipioReportes',
        value: filter.Municipio.value,
      });
    }
    if (filter.Poligono.value > 0) {
      _filtro.push({
        id: 'casillas_sije.idPoligono',
        value: filter.Poligono.value,
      });
      _filtroTotales.push({
        id: 'idPoligono',
        value: filter.Poligono.value,
      });
    }
    if (filter.Seccion.value > 0) {
      _filtro.push({ id: 'casillas_sije.Seccion', value: filter.Seccion.value });
      _filtroTotales.push({ id: 'Seccion', value: filter.Seccion.value });
    }
    if (filter.Casilla.value > 0) {
      _filtro.push({
        id: 'casillas_sije.NombreCasilla',
        value: filter.Casilla.label,
      });
    }

    if (filter.AsistenciaRC.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseLista',
        value: filter.AsistenciaRC.value,
      });
    }

    if (filter.AsistenciaRG.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaRG',
        value: filter.AsistenciaRG.value,
      });
    }

    if (filter.Instalacion.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isInicioInstalacion',
        value: filter.Instalacion.value,
      });
    }

    if (filter.Apertura.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isAperturaCasilla',
        value: filter.Apertura.value,
      });
    }

    if (filter.CierreCasilla.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isCierreCasilla',
        value: filter.CierreCasilla.value,
      });
    }

    if (filter.CierreVotacion.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isCierreVotacion',
        value: filter.CierreVotacion.value,
      });
    }
    if (filter.isPaseListaP1.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaP1',
        value: filter.isPaseListaP1.value,
      });
    }

    if (filter.isPaseListaP2.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaP2',
        value: filter.isPaseListaP2.value,
      });
    }

    if (filter.isPaseListaS1.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaS1',
        value: filter.isPaseListaS1.value,
      });
    }

    if (filter.isPaseListaS2.value !== null) {
      _filtro.push({
        id: 'casillas_sije.isPaseListaS2',
        value: filter.isPaseListaS2.value,
      });
    }

    setFiltro(_filtro);
    setFilteredTotales(_filtroTotales)

    const data = {
      page: 0,
      pageSize: 10,
      filtered: _filtro,
      tipo: 'and',
    };

    getRegistros(data);
    getDatosGrafica()
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
          <Row>
            <Col sm="12" md="12" lg="12">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <ModalFiltro Datos={(e) => handleFiltro(e)} getActas={(e) => getActas(e)}/>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" sm="12">
                  <CasillasResumen
                    isLoading={loadingTotales}
                    data={totalesCasillas}
                  ></CasillasResumen>
                </Col>
                <Col md="4" lg="4" sm="12">
                  <IncidenciasResumen
                    isLoading={loadingTotales}
                    data={totalIncidencias}
                  ></IncidenciasResumen>
                </Col>
                <Col md="4" lg="4" sm="12">
                  <Acta
                    isLoading={loadingActas}
                    data={totalActas}
                    tipoEleccion={tipoEleccion}
                  ></Acta>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Card>
                    <CardHeader className="text-center">
                      <h6>Pase de Lista RCs</h6>
                    </CardHeader>
                    <CardBody>
                      <DobleBarra
                        Title=""
                        Subtitle={infoGraficasRCs.Actualizado}
                        Categories={infoGraficasRCs.categories}
                        Series={infoGraficasRCs.series}
                        Meta={infoGraficasRCs.Meta}
                        yAxisTitle="Asistencia"
                      ></DobleBarra>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card>
                    <CardHeader className="text-center">
                      <h6>Pase de Lista RGs</h6>

                    </CardHeader>
                    <CardBody>
                      <DobleBarra
                        Title=""
                        Subtitle={new Date().toLocaleDateString()}
                        Categories={infoGraficasRGs.categories}
                        Series={infoGraficasRGs.series}
                        Meta={infoGraficasRGs.Meta}
                        yAxisTitle="Asistencia"
                      ></DobleBarra>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="6">
                  <Card>
                    <CardHeader className="text-center">
                      <h6>Inicio</h6>
                    </CardHeader>
                    <CardBody>
                      <CasillasChart
                        Title="Inicio"
                        Subtitle={new Date().toLocaleDateString()}
                        Categories={infoGraficas.categories}
                        Series={infoGraficas.series}
                        yAxisTitle="Total de casillas"
                      ></CasillasChart>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card>
                    <CardHeader className="text-center">
                      <h6>Cierre</h6>

                    </CardHeader>
                    <CardBody>
                      <CasillasChart
                        Title=""
                        Subtitle={new Date().toLocaleDateString()}
                        Categories={infoGraficasCierre.categories}
                        Series={infoGraficasCierre.series}
                        yAxisTitle="Total de casillas"
                      ></CasillasChart>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Card>
                    <CardHeader className="text-center text-sm-center">
                      <h6 className="text-center">Operación Casilla</h6>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {_menu.Exportar === 1 && !loading ? (
                          <Col sm="8">
                            <Button
                              name="btnExportar"
                              size="sm"
                              type="button"
                              className="primary float-left"
                              color="primary"
                              onClick={() => Exportar()}
                            >
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-lg" />
                              </span>
                                Exportar
                              </Button>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                      {loading && (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loading}
                        />
                      )}
                      <Col lg="12" md="12">
                        <div style={{ width: '100%', overflow: 'auto', margin: '0px 1px', zIndex: 0 }} >
                          <ReactTable
                            data={data}
                            className="-striped -highlight"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={loading}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={fetchData}
                            params={Filtro}
                          />
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>


              <Card>
                <CardHeader>


                  <div style={{ width: '100%', overflow: 'auto' }}>
                    <Row>

                    </Row>

                  </div>
                </CardHeader>

              </Card>
            </Col>
          </Row>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};
export default CasillasOperacion;
