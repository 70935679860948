import React, { useState,useEffect } from 'react';
import {Button,Col,Modal, Row } from 'reactstrap';
import { notify } from "react-notify-toast";
import { CircleSpinner } from "react-spinners-kit";

import EjecutarApi from "../../data/getPOST";
import { getVars} from "../../GlobalStorage";
import {getDataMenu} from '../../data/menus.js';

const MensajeModal=({onHide,Datos})=>{
    const token = getVars("Token");
    const [_menu, _setmenu] = useState({});
    const [loading, setLoading] = useState(false);
    const [Mensaje,setMensaje] = useState(Datos.Mensaje)
    const [Delete,setDelete] = useState({})
    
    useEffect(()=>{
        _setmenu(getDataMenu(23,token.menus));
    })

    const eliminarEstructura = (idEstructur,delet)=>{
        //eliminarEstructuraSocialWeb eliminarEstructuraSocialWeb
        
            var resultado={ delete: 0,
                message: 0,
                results: 0,
                success: 0};
            var parametro ={
                idEstructura:idEstructur,
                delete:delet
            }
            EjecutarApi(parametro, 'eliminarEstructuraSocialWeb', token.access_token)
                .then(function (res) {
                return res;
                })
                .then((res) => {
                    setDelete(res)
                    setMensaje(res.message)
    
                    resultado={
                        delete: res.delete,
                        message: res.message,
                        results: res.results,
                        success: res.success,
                       
                    }
                    return resultado;
                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                        background: "#d61818",
                        text: "#FFFFFF",
                    });
                });
        
        return resultado;
    }
    const btnAceptar= ()=>{
        setLoading(true)
        var resultado = eliminarEstructura(Datos.idEstructura, 0)
            
            if (Delete.success) 
                if(Delete.results)
                {
                    if(Delete.delete===1)
                    {
                        eliminarEstructura(Datos.idEstructura, 1)
                    }
                    else if(Delete.delete===2)
                    {
                        eliminarEstructura(Datos.idEstructura, 2)
                    }
                    else if(Delete.delete===3)
                   {
                       eliminarEstructura(Datos.idEstructura, 3)
                   }
                }
                else{
                   if(Delete.delete===0)
                   {
                      
                   }else if(Delete.delete===1)
                   {
                       eliminarEstructura(Datos.idEstructura, 1)
                   }
                   else if(Delete.delete===2)
                   {
                       eliminarEstructura(Datos.idEstructura, 2)
                   }
                   else if(Delete.delete===3)
                   {
                       eliminarEstructura(Datos.idEstructura, 3)
                   }
                }
        setLoading(false)
    }
   
    return(<>
        <Modal
            className="modal-sm"
            modalclassName="modal-primary"
            isOpen={true}
            toggle={onHide}
        >
           
            <div className="modal-body">
                {loading && (
                <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={loading}
                    />
                )}
                <Row>
                  <Col sm="12">
                    <strong><p className='text-muted text-center'>
                    {Mensaje}</p></strong>
                  </Col>
                </Row>
                
            </div>
            <div className="modal-footer">
                
            <div className="left-side">
                {Delete.delete===0?<></>:

                
                <Button size='sm'
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={()=>btnAceptar()}
                    
                >
                    Eliminar
                </Button>}
                </div>
                <div className="right-side">
                <Button size='sm'
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={()=>onHide()}
                >
                    Salir
                </Button>
                </div>
            </div>
        </Modal>
        </>
    )
}
export default MensajeModal;