import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const PesoElectoralChart = (props) => {
  let serie = props.data

    const options = {
      credits: {
         enabled: false
      },
      chart: {
         type: 'column',
         height: '260px'
     },
     title: {
         text: ''
     },
     subtitle: {
         text: ''
     },
     xAxis: {
       categories: serie.categories,
       crosshair: false,
     },
     yAxis: {
       visible: true,
         min: 0,
         title: {
             text: ""
         }
     },
     tooltip: {
      formatter: function() {
        if (this.y > 1000000) {
          return this.x+":"+new Intl.NumberFormat('en-EN').format(this.y)+"M"
        } else if (this.y > 1000) {
          return this.x+":"+new Intl.NumberFormat('en-EN').format(this.y);
        } else {
          return this.x+":"+this.y
        }
      },
    },
     plotOptions: {
         column: {
             pointPadding: 0.2,
             borderWidth: 0,
             dataLabels: {
                 enabled: true,
                 formatter: function() {
                  if (this.y > 1000000) {
                    return new Intl.NumberFormat('en-EN').format(this.y)+"M"
                  } else if (this.y > 1000) {
                    return new Intl.NumberFormat('en-EN').format(this.y) ;
                  } else {
                    return this.y
                  }
                }
             }
         }
     },
     series: [{
       showInLegend: false,
         data: serie.data
     }]
  }


  return (
      <HighchartsReact
       highcharts={Highcharts}
       options={options}
     />
  )
}

export default PesoElectoralChart
