import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer"
import Sidebar from "components/Sidebar";
import { getVars } from 'GlobalStorage';
import {
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Label,
  FormGroup,
  Row,
  Col,
  Button,
  Progress,
  Tooltip,
  Badge
} from "reactstrap";
import Pagination from "components/Pagination/pagination";
import ReactTable from 'react-table';
import Select from "react-select";
import { URL_PUBLICA } from "GlobalStorage";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "data/getPOST";
import { CircleSpinner } from "react-spinners-kit";
import ModalEditarMeta from 'components/ConfiguracionMetas/EditarMeta';
import ModalEditarFecha from 'components/ConfiguracionMetas/EditarFecha';
import ModalImportExcel from 'components/ConfiguracionMetas/ImportMetaCompromiso';
import { getDataMenu } from "data/menus";

const ConfiguracionMeta = (props) => {

  // Configuracion
  const [setsidebarMini] = useState(true);
  const [backgroundColor] = useState("black");
  const [activeColor] = useState("info");
  const [loading, setLoading] = useState(false);
  const [loadingTabla, setLoadingTabla] = useState(false);
  const token = getVars('Token');
  const [isEditing, setEditing] = useState(false);
  const [isImport, setImport] = useState(false);
  const [isEditingFecha, setEditingFecha] = useState(false);
  const [Filtro, setFiltro] = useState([]);
  const [total, setTotal] = useState(0);
  const [meta, setMeta] = useState([]);
  const [showMeta, setShowMeta] = useState(false);
  // Selected
  const [municipioSelected, setMunicipioSelected] = useState({ value: 0, label: 'Todos' });
  const [regionSelected, setRegionSelected] = useState({ value: 0, label: 'Todos' })
  const [poligonoSelected, setPoligonoSelected] = useState({ value: 0, label: 'Todos' })
  const [seccioneSelected, setSeccioneSelected] = useState({ value: 0, label: 'Todos' })


  // Catalogos
  const [catRegiones, setCatRegiones] = useState([{ value: 0, label: 'Todos' }])
  const [catMunicipio, setCatMunicipio] = useState([{ value: 0, label: 'Todos' }])
  const [catPoligonos, setCatPoligonos] = useState([{ value: 0, label: 'Todos' }])
  const [catSecciones, setCatSecciones] = useState([{ value: 0, label: 'Todos' }])

  const [catMunicipioFilter, setCatMunicipioFilter] = useState([])
  const [catPoligonosFilter, setCatPoligonosFilter] = useState([])
  const [catSeccionesFilter, setCatSeccionFilter] = useState([])

  const [metaPromocion, setMetaPromocion] = useState({
    id: "",
    seccion: "",
    meta: "",
  })

  const [metaShow, setMetaShow] = useState({
    label: "",
    meta: "",
  })

  const [fechaCompromisos, setFechaCompromisos] = useState({
    fechaInicio: "",
    fechaFin: "",
    rango: "",
    hoy: ""
  });

  var now = new Date();
  //Moouse Over
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);


  const [menu, _setmenu] = useState({});

  useEffect(() => {
    setLoading(true);
    _setmenu(getDataMenu(37, token.menus));

    let regionesFormated = [];
    let municipioFormated = [];
    let poligonoFormated = [];
    let seccionFormated = [];
    EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {
            let regiones = [{ value: 0, label: 'Todos' }]
            let municipios = [{ value: 0, label: 'Todos' }]
            let poligonos = [{ value: 0, label: 'Todos' }]
            let secciones = [{ value: 0, label: 'Todos' }]

            regionesFormated = res.data.regiones.map(item => {
              return {
                value: item.value,
                label: item.label
              }
            })
            const result = regionesFormated.filter(reg => reg.label !== 'NO DEFINIDO');
            setCatRegiones(regiones.concat(result))
            //CatMunicipio llenar

            municipioFormated = res.data.municipios.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
              }
            })
            setCatMunicipio(municipios.concat(municipioFormated))

            //CatPoligono llenar

            poligonoFormated = res.data.poligonos.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
                idMunicipio: item.idMunicipio,
              }
            })
            const polg = poligonoFormated.filter(pol => pol.label !== 'NO DEFINIDO');
            setCatPoligonos(poligonos.concat(polg))

            //CatSeccion llenar

            seccionFormated = res.data.secciones.map(item => {
              return {
                value: item.value,
                label: item.label,
                idRegion: item.idRegion,
                idMunicipio: item.idMunicipio,
                idPoligono: item.idPoligono
              }
            })
            setCatSecciones(secciones.concat(seccionFormated))


            setCatMunicipioFilter(municipios.concat(municipioFormated))
            setCatPoligonosFilter(poligonos.concat(polg))
            setCatSeccionFilter(secciones.concat(seccionFormated))

          } else {
            showMessage("No Info: getCatalogosFiltrosPromocion", '#0E7CF1')
          }
        }
      })
      .catch((e) => {
        showMessage("Error: getCatalogosFiltrosPromocion", "#F1370E")
      }).then(() => {
        setLoading(false);
      })

    EjecutarApi({}, "getFechaMetaCompromisos", token.access_token, "POST")
      .then(res => {
        if (res.results) {
          if (res.data) {
            setFechaCompromisos(
              {
                ...fechaCompromisos,
                fechaInicio: res.data.FechaInicioCompromiso,
                fechaFin: res.data.FechaMetaCompromiso,
                rango: res.data.DiasTotal,
                hoy: res.data.DiasHoy
              }
            )
          } else {
            showMessage("No Info: getFechaMetaCompromisos")
          }
        }
        else { showMessage("Error: getFechaMetaCompromisos", res.message) }
      })
      .catch((e) => {
        setLoading(false);
        showMessage("Error: getFechaMetaCompromisos")
      }).then(() => {
        setLoading(false);
      })

  }, [isEditingFecha, loadingTabla]);

  const showMessage = (message, background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setsidebarMini(false);
    } else {
      setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  // HANDLER DE TODOS LOS SELECT DEL FILTRO PROMOCIÓN


  const handleRegiones = (e) => {
    setLoadingTabla(true)
    setRegionSelected(e)

    var parametros = {
      tipo: "and",
      filtered: [
        { "id": "id", "value": e.value }
      ],
      tabla: "region"
    }
    EjecutarApi(parametros, "getMetaCompromisos", token.access_token, "POST")
      .then(res => {
        if (res.success) {
          if (res.results) {
            setShowMeta(true);
            setLoadingTabla(false)
            setMetaShow(
              {
                ...metaShow,
                label: e.label,
                meta: res.data.MetaCompromisosPartido
              }
            )
          } else {
            showMessage("No Info: metaReión")
          }
        }
        else { showMessage("Error:", res.message) }
      })
      .catch((e) => {
        showMessage("Error: metaReión")
      })

    if (e.value > 1) {
      let municipios = [{ value: 0, label: 'Todos' }]
      let poligonos = [{ value: 0, label: 'Todos' }]
      let secciones = [{ value: 0, label: 'Todos' }]

      setCatMunicipioFilter(municipios.concat(catMunicipio.filter(data => data.idRegion === e.value)));
      const poligono = []
      catPoligonos.forEach(item => {
        var number = parseInt(item.idRegion);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          poligono.push(temp);
        }
      });
      setCatPoligonosFilter(poligonos.concat(poligono));
      const seccion = []
      catSecciones.forEach(item => {
        var number = parseInt(item.idRegion);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idPoligono: item.idPoligono,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          seccion.push(temp);
        }
      });
      setCatSeccionFilter(secciones.concat(seccion));

      fitroSend(e, "region");

    } else {
      setCatMunicipioFilter(catMunicipio);
      setCatPoligonosFilter(catPoligonos);
      setCatSeccionFilter(catPoligonos);
    }
    setLoading(false);
  }

  const fitroSend = (e, handle) => {
    var filtro = [];
    if (handle !== "clear") {
      if (e.value > 0 && handle === "region") { filtro.push({ "id": "secciones.idRegion", "value": e.value }) }
      if (e.value > 0 && handle === "municipio") { filtro.push({ "id": "secciones.idMunicipio", "value": e.value }) }
      if (e.value > 0 && handle === "poligono") { filtro.push({ "id": "secciones.idPoligono", "value": e.value }) }
      if (e.value > 0 && handle === "seccion") { filtro.push({ "id": "secciones.Seccion", "value": e.value }) }

      if (regionSelected.value > 0 && handle !== "region") { filtro.push({ "id": "secciones.idRegion", "value": regionSelected.value }) }
      if (municipioSelected.value > 0 && handle !== "municipio") { filtro.push({ "id": "secciones.idMunicipio", "value": municipioSelected.value }) }
      if (poligonoSelected.value > 0 && handle !== "poligono") { filtro.push({ "id": "secciones.idPoligono", "value": poligonoSelected.value }) }
      if (seccioneSelected.value > 0 && handle !== "seccion") { filtro.push({ "id": "secciones.Seccion", "value": seccioneSelected.value }) }
    }
    if (e === 0 && handle === "") { setFiltro(Filtro) }
    else { setFiltro(filtro); }

    let _menuStorage = getDataMenu(37, token.menus);
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      tipo: 'and',
      filtered: filtro
    };
    EjecutarApi(data, "getMetaCompromisosGeneral", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setLoadingTabla(false);
            setTotal(res.total);
            setMeta(
              res.data.map((prop, key) => {
                return {
                  id: prop.id,
                  Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                  Region: prop.Region ? prop.Region : "Sin Region",
                  Poligono: prop.Poligono ? prop.Poligono : 'Sin Sección',
                  Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                  meta: _menuStorage.Editar === 1 ?
                    (<><Button
                      className="btn-link"
                      color="primary"
                      onClick={() => { btnActualizarMetaPromocion(prop); }}
                    >{prop.MetaCompromisosPartido}</Button></>)
                    : prop.MetaCompromisosPartido,
                  data: prop,
                };
              })
            );
          }
        }

      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener getMetaCompromisosGeneralFiltro: ${e.error}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });

    setEditing(false);
    setImport(false)
  }

  const LimpiarFiltro = () => {
    setMunicipioSelected({ value: 0, label: 'Todos' })
    setPoligonoSelected({ value: 0, label: 'Todos' })
    setSeccioneSelected({ value: 0, label: 'Todos' })
    setRegionSelected({ value: 0, label: 'Todos' })
    setMetaShow({ label: "", meta: "" })
    setShowMeta(false)

    fitroSend("", "clear");
  }


  const handleMunicipio = (e) => {
    setLoadingTabla(true)
    setMunicipioSelected(e)

    var parametros = {
      tipo: "and",
      filtered: [
        { "id": "id", "value": e.value }
      ],
      tabla: "municipio"
    }

    EjecutarApi(parametros, "getMetaCompromisos", token.access_token, "POST")
      .then(res => {
        if (res.data) {
          setShowMeta(true);
          setLoadingTabla(false);
          setMetaShow(
            {
              ...metaShow,
              label: e.label,
              meta: res.data.MetaCompromisosPartido
            }
          )
        } else {
          showMessage("No Info: metaReión")
        }
      })
      .catch((e) => {
        setLoadingTabla(false);
        showMessage("Error: meta Municipio")
      })

    if (e.value > 1) {
      let poligonos = [{ value: 0, label: 'Todos' }]
      let secciones = [{ value: 0, label: 'Todos' }]

      const poligono = []
      catPoligonos.forEach(item => {
        var number = parseInt(item.idMunicipio);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          poligono.push(temp);
        }
      });
      setCatPoligonosFilter(poligonos.concat(poligono));
      const seccion = []
      catSecciones.forEach(item => {
        var number = parseInt(item.idMunicipio);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idPoligono: item.idPoligono,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          seccion.push(temp);
        }
      });
      setCatSeccionFilter(secciones.concat(seccion));

      fitroSend(e, "municipio");

    } else {
      setCatMunicipioFilter(catMunicipio);
      setCatPoligonosFilter(catPoligonos);
      setCatSeccionFilter(catSecciones);
    }
    setLoading(false);
  }

  const handlePoligonos = (e) => {
    setLoadingTabla(true)
    setPoligonoSelected(e)

    var parametros = {
      tipo: "and",
      filtered: [
        { "id": "id", "value": e.value }
      ],
      tabla: "poligono"
    }

    EjecutarApi(parametros, "getMetaCompromisos", token.access_token, "POST")
      .then(res => {
        if (res.data) {
          setShowMeta(true);
          setLoadingTabla(false);
          setMetaShow(
            {
              ...metaShow,
              label: e.label,
              meta: res.data.MetaCompromisosPartido
            }
          )
        } else {
          showMessage("No Info: meta Polígono")
        }
      })
      .catch((e) => {
        showMessage("Error: meta Polígono")
      })

    if (e.value > 1) {
      let secciones = [{ value: 0, label: 'Todos' }]
      const seccion = []
      catSecciones.forEach(item => {
        var number = parseInt(item.idPoligono);
        if (number === e.value) {
          const temp = {
            value: item.value,
            label: item.label,
            idPoligono: item.idPoligono,
            idMunicipio: item.idMunicipio,
            idRegion: item.idRegion
          }
          seccion.push(temp);
        }
      });
      setCatSeccionFilter(secciones.concat(seccion));

      fitroSend(e, "poligono");

    } else {
      setCatPoligonosFilter(catPoligonos);
      setCatSeccionFilter(catSecciones);
    }
    setLoading(false);
  }

  const handleSecciones = (e) => {
    setLoadingTabla(true);
    setSeccioneSelected(e)

    var parametros = {
      tipo: "and",
      filtered: [
        { "id": "seccion", "value": e.value }
      ],
      tabla: "seccion"
    }

    EjecutarApi(parametros, "getMetaCompromisos", token.access_token, "POST")
      .then(res => {
        if (res.data) {
          setShowMeta(true);
          setLoadingTabla(false);
          setMetaShow(
            {
              ...metaShow,
              label: e.label,
              meta: res.data.MetaCompromisosPartido
            }
          )
        } else {
          showMessage("No Info: meta Sección")
        }
      })
      .catch((e) => {
        showMessage("Error: meta Sección")
      })

    fitroSend(e, "seccion");

  }

  // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA



  //Cancelar
  const btnCancelarEditar = (e) => {

    if (e === true) {
      fitroSend(0, "");
    }
    else { setEditing(false); }
  };

  const btnCancelarEditarFechas = () => {
    setEditingFecha(false);
  };

  const btnCancelarImportar = (e) => {

    if (e === true) {
      fitroSend(0, "");
    }
    else { setImport(false); }

  };

  //Actualizar

  const btnActualizarMetaPromocion = (e) => {
    setEditing(true)
    setMetaPromocion({
      ...metaPromocion,
      id: e.Seccion,
      seccion: e.Seccion,
      meta: e.MetaCompromisosPartido

    })

  }

  const Exportar = () => {
    var url = new URL(URL_PUBLICA +
      `getReportMetasCompromisos?token=${token.access_token}`
    );
    window.open(url, "_blank");
  }

  return (
    <div className="wrapper">
      <Notifications />
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} menus={token.menus} />
        <div className="content">

          <div className="content">
            {/* Fechas Compromiso*/}
            <Row>
              {showMeta &&
                <Col sm="3">
                  <Card body >
                    <CardTitle className="justify-content-md-center">
                      {metaShow.label}
                    </CardTitle>
                    <Row>
                      <Col>
                        <legend className="text-center">{metaShow.meta}</legend>
                      </Col>
                    </Row>
                    <CardFooter>
                      <hr />
                      <i className="fa fa-flag" />
                      Meta
                      </CardFooter>
                  </Card>
                </Col>
              }
              <Col sm={showMeta ? "9" : "12"}>
                <Card>
                  <CardBody>
                    <CardTitle><i className="fa fa-calendar-minus-o" aria-hidden="true"></i> Fechas Meta</CardTitle>
                    <Row >
                      <Col sm="9">
                        <Label className="right" >Fecha Inicio</Label>
                        <br />
                        <legend >{fechaCompromisos.fechaInicio}</legend>
                      </Col>
                      <Col sm="3">
                        <Label className="text-right">Fecha Final</Label>
                        <br />
                        <legend className="text-right">{fechaCompromisos.fechaFin}</legend>
                      </Col>
                    </Row>
                    <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="showFecha" toggle={toggle}>
                      {now.toLocaleDateString()}
                    </Tooltip>
                    <Progress max={fechaCompromisos.rango} animated value={fechaCompromisos.hoy} color="info">
                      <span className="text-right" ><Badge color="primary" id="showFecha" pill>..</Badge></span>
                    </Progress>
                  </CardBody>
                  <CardFooter>
                    {!isEditingFecha && menu.Editar === 1 &&
                      <Col sm='12'>
                        <Button name="btnRegresar" size="sm" type="button" className="success float-right" color='success' onClick={() => { setEditingFecha(true) }}>Editar Fecha</Button>
                      </Col>
                    }
                  </CardFooter>
                </Card>
              </Col>
              {isEditingFecha ? <ModalEditarFecha onHide={btnCancelarEditarFechas} OnEdit={fechaCompromisos} /> : <></>}
              {isImport ? <ModalImportExcel onHide={btnCancelarImportar} /> : <></>}
            </Row>
            {/* Configuración Promoción Metas */}
            <Row>
              <Col sm="12" >
                <Card border="success">
                  <CardBody >
                    <CardTitle >Configuración Meta Promoción</CardTitle>
                    <Row >
                      <Col lg="2" md="2" sm="2">
                        <FormGroup>
                          <Label for="Estado">
                            Región
                              </Label>
                          <Select
                            options={catRegiones}
                            className="react-select primary"
                            classNamePrefix="react-select"
                            defaultValue={catRegiones[0]}
                            value={regionSelected}
                            onChange={(e) => handleRegiones(e)}
                            id="regiones"
                            placeholder="regiones"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="4" md="4" sm="4">
                        <FormGroup>
                          <Label for="Municipio">
                            Municipios
                              </Label>
                          <Select
                            options={catMunicipioFilter}
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={municipioSelected}
                            onChange={(e) => handleMunicipio(e)}
                            id="municipio"
                            placeholder="Municipio"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="2" md="2" sm="2">
                        <FormGroup>
                          <Label for="Estado">
                            Polígonos
                              </Label>
                          <Select
                            options={catPoligonosFilter}
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={poligonoSelected}
                            onChange={(e) => handlePoligonos(e)}
                            id="poligonos"
                            placeholder="poligonos"
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="2" md="2" sm="2">
                        <FormGroup>
                          <Label for="Estado">
                            Secciones
                              </Label>
                          <Select
                            options={catSeccionesFilter}
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={seccioneSelected}
                            onChange={(e) => handleSecciones(e)}
                            id="secciones"
                            placeholder="secciones"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <Button name="btnLimpiar" size="sm" type="button" className="success float-left" color='warning' onClick={() => { LimpiarFiltro() }}>Limpiar</Button>
                      </Col>
                      {menu.Exportar === 1 ? (
                        <Col sm='6'>
                          <Button name="btnImport" size="sm" type="button" className="info float-right" color='info' onClick={() => { setImport(true) }}>Importar</Button>
                          <Button name="btnExportar" size='sm' type="button" className='primary float-right' color='primary' onClick={() => Exportar()}>
                            <span className="btn-label">
                              <i className="fa fa-file-excel-o fa-lg" />
                            </span>
                            Exportar
												</Button>
                        </Col>
                      ) : <></>}

                    </Row>
                    {/*Tabla de los regiones */}
                    <Row>
                      <Col sm="12" >
                        {(!loadingTabla) ?
                          <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                            <ReactTable
                              style={{
                                width: "110%",
                                height: "100%"
                              }}
                              size='sm'
                              data={meta}
                              noDataText="Sin información"
                              manual
                              columns={[
                                {
                                  Header: "Region",
                                  minWidth: 30,
                                  id: "Region",
                                  accessor: (d) => d.Region,
                                  filterAll: false,
                                },
                                {
                                  Header: "Municipio",
                                  minWidth: 70,
                                  id: "Municipio",
                                  accessor: (d) => d.Municipio,
                                  filterAll: false,
                                },
                                {
                                  Header: "Polígono",
                                  minWidth: 30,
                                  id: "Poligono",
                                  accessor: (d) => d.Poligono,
                                  filterAll: false,
                                },
                                {
                                  Header: "Sección",
                                  minWidth: 30,
                                  id: "Seccion",
                                  accessor: (d) => d.Seccion,
                                  filterAll: false,
                                },
                                {
                                  Header: "Meta",
                                  accessor: (d) => d.meta,
                                  minWidth: 40,
                                  id: "meta",
                                  filterable: false,
                                },
                              ]}
                              className="-striped -highlight primary-pagination"
                              loading={loadingTabla}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={10}
                              total={total}
                              pages={Math.floor(total / 10)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={(stateComp, instance) => {
                                setLoading(true);
                                let _menuStorage = getDataMenu(37, token.menus);
                                const data = {
                                  page: stateComp.page,
                                  pageSize: stateComp.pageSize,
                                  sorted: [{ id: "secciones.Seccion", desc: false }],
                                  tipo: 'and',
                                  filtered: Filtro ? Filtro : []
                                };
                                EjecutarApi(data, "getMetaCompromisosGeneral", token.access_token)
                                  .then(function (res) {
                                    return res;
                                  })
                                  .then((res) => {
                                    if (res.success) {
                                      if (res.results) {
                                        setLoadingTabla(false);
                                        setTotal(res.total);
                                        setMeta(
                                          res.data.map((prop, key) => {
                                            return {
                                              id: prop.Seccion,
                                              Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                              Region: prop.Region ? prop.Region : "Sin Region",
                                              Poligono: prop.Poligono ? prop.Poligono : 'Sin Sección',
                                              Seccion: prop.Seccion ? prop.Seccion : 'Sin Sección',
                                              meta:
                                                _menuStorage.Editar === 1 ?
                                                  (<><Button
                                                    className="btn-link"
                                                    color="primary"
                                                    onClick={() => { btnActualizarMetaPromocion(prop); }}
                                                  >{prop.MetaCompromisosPartido}</Button></>)
                                                  : prop.MetaCompromisosPartido,
                                              data: prop,
                                            };
                                          })
                                        );

                                      }
                                    }

                                  })
                                  .catch((e) => {
                                    notify.show(
                                      `Error al obtener tabla metas: ${e.message}`,
                                      "custom",
                                      5000,
                                      {
                                        background: "#0857B6",
                                        fontSize: 14,
                                        text: "#FFFFFF",
                                      }
                                    );
                                  });
                              }}
                            />
                          </div>
                          : <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={!loadingTabla}
                          />
                        }
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {/*Card para la modificaciòn */}
            </Row>
          </div>
        </div>
        <Footer fluid />

      </div>
      {isEditing ? <ModalEditarMeta onHide={btnCancelarEditar} OnEdit={metaPromocion} /> : <></>}
    </div>
  );

}
export default ConfiguracionMeta;
