export const makeid = (tamanio) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var bloque = 0; bloque < 4; bloque++) {
    for (var i = 0; i < tamanio; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    result += '-';
  }

  return result;
  
}

export const numberWithCommas =(x) =>{
  if(x){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  else{
    return x;
  }
}