import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from 'reactstrap';
import { notify } from 'react-notify-toast';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RotateSpinner } from 'react-spinners-kit';
import Select from 'react-select';

import { getVars } from '../../GlobalStorage';
import EjecutarApi from '../../data/getPOST';

const BuscarUserOwned = ({
  props,
  onHide,
  datos,
  Owned,
  Responsabilidades,
  RespElectoral,
  Casillas
}) => {
  const [loading, setLoading] = useState(false);
  const token = getVars('Token');
  const [DatosResponsable, setDatosResponsable] = useState([]);
  const [Busqueda, setBusqueda] = useState('');
  const [total, setTotal] = useState(0);
  const [HasMore, setHasMore] = useState(true);
  const [nextFetch, setnextFetch] = useState(true);
  const [loadingCatalogos, setLoadingCatalogos] = useState(true);
  const [filtro, setFiltro] = useState({
    municipio: null,
    seccion: null,
  });
  const [catalogos, setCatalogos] = useState({
    municipios: [],
    secciones: [],
    seccionesSinFiltro: []
  });
  // const [valoresCasillas, setValoresCasillas] = useState({
  //   municipios: [],
  //   secciones: []
  // })

  useEffect(() => {
    const valoresPorCasilla = () => {
      let municipios = []
      let secciones = []
      Casillas.map(casilla => {
        if (!municipios.find(municipio => municipio === casilla.idMunicipioReportes)) {
          municipios.push(casilla.idMunicipioReportes)
        }
        if (!secciones.find(seccion => seccion === casilla.Seccion)) {
          secciones.push(casilla.Seccion)
        }
      })

      return { municipios, secciones }
    }
    let filtro = []
    if (Casillas && Casillas.length > 0) {
      const valoresPorCasillaObtenidos = valoresPorCasilla()
      // setValoresCasillas(valoresPorCasillaObtenidos)
      filtro = [
        { id: "MunicipioVive", value: valoresPorCasillaObtenidos.municipios },
        { id: "SeccionVota", value: valoresPorCasillaObtenidos.secciones }
      ]
    }
    var parametro = {
      page: 0,
      pageSize: 10,
      filtered: filtro,
      sorted: [],
      tipo: 'and',
      Responsabilidades: Responsabilidades,
      RespElectoral: RespElectoral,
    };
    setLoading(true);
    EjecutarApi(parametro, 'getListadoCambioResponsables', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (res.total > 0) {
            setLoading(false);
            setTotal(res.total);
            setDatosResponsable(res.data);
          } else {
            setLoading(false);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error: ${e.message}`, 'error', 5000, {
          background: '#d61818',
          text: '#FFFFFF',
        });
      });


    const ponerInformacionCatalogosPorCasilla = (municipiosCatalogo, seccionesCatalogo) => {
      let municipios = []
      let secciones = []
      Casillas.map(casilla => {
        if (!municipios.find(municipio => municipio.value === casilla.idMunicipioReportes)) {
          municipios.push(municipiosCatalogo.find(municipio => municipio.value === casilla.idMunicipioReportes))
        }
        if (!secciones.find(seccion => seccion.value === casilla.Seccion)) {
          secciones.push(seccionesCatalogo.find(seccion => seccion.value === casilla.Seccion))
        }
      })

      setCatalogos({
        municipios,
        secciones,
        seccionesSinFiltro: secciones
      })

      setFiltro({
        municipio: municipios,
        seccion: secciones
      })

    }

    const getCatalogosElectoral = () => {
      EjecutarApi({}, 'getCatalogosFiltrosElectoral', token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            if (Casillas && Casillas.length > 0) {
              ponerInformacionCatalogosPorCasilla(res.data.municipios, res.data.secciones)
            } else {
              setCatalogos({
                municipios: res.data.municipios,
                secciones: res.data.secciones,
                seccionesSinFiltro: res.data.secciones
              });
            }

            setLoadingCatalogos(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          notify.show(`Error: ${e.message}`, 'error', 5000, {
            background: '#d61818',
            text: '#FFFFFF',
          });
        });
    };

    getCatalogosElectoral();
  }, []);

  const handleResponsable = (item) => {
    setDatosResponsable(item.Responsable);
    Owned(item);
    onHide();
  };
  const handleBuscar = () => {
    const filtros = formatearFiltro()
    setLoading(true);
    var parametro = {
      page: 0,
      pageSize: 5,
      filtered: filtros,
      sorted: [],
      tipo: 'and',
      Responsabilidades: Responsabilidades,
      RespElectoral: RespElectoral,
    };
    getGetResponsables(parametro);
  };

  const formatearFiltro = () => {
    let filtros = []
    if (filtro.municipio && filtro.municipio.length > 0) {
      const municipios = filtro.municipio.map(municipio => municipio.value)
      filtros.push({ id: "MunicipioVive", value: municipios })
    }
    if (filtro.seccion && filtro.seccion.length > 0) {
      const secciones = filtro.seccion.map(seccion => seccion.value)
      filtros.push({ id: "SeccionVota", value: secciones })
    }
    if (Busqueda && Busqueda !== "") {
      filtros.push({ id: "FullSearch", value: Busqueda })
    }

    return filtros;
  }

  const fetchHasMore = () => {
    setLoading(true);
    if (nextFetch * 10 >= total) {
      setHasMore(false);
      return;
    }
    setHasMore(true);
    const filtros = formatearFiltro()
    var parametro = {
      page: nextFetch,
      pageSize: 10,
      filtered: filtros,
      tipo: 'and',
      sorted: [],
      Responsabilidades: Responsabilidades,
      RespElectoral: RespElectoral,
    };

    getGetResponsables(parametro, true);
  };


  const getGetResponsables = (parametro, hasMore = false) => {
    EjecutarApi(parametro, 'getListadoCambioResponsables', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (hasMore) {
            setDatosResponsable(DatosResponsable.concat(res.data));
            setnextFetch(nextFetch + 1);
            setHasMore(false);
          } else {
            setDatosResponsable(res.data);
          }
          setLoading(false);
          setTotal(res.total);
        } else {
          setDatosResponsable([]);
          setTotal(0);
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error: ${e.message}`, 'error', 5000, {
          background: '#d61818',
          text: '#FFFFFF',
        });
      });
  };

  const seleccionarMunicipio = (municipios) => {
    let seccionesMunicipio = catalogos.seccionesSinFiltro
    if (municipios) {
      seccionesMunicipio = []
      municipios.map(municipio => {
        let seccionesCatalogo = catalogos.seccionesSinFiltro.filter((seccion) => seccion.idMunicipio === municipio.value)
        seccionesMunicipio = seccionesMunicipio.concat(seccionesMunicipio, seccionesCatalogo)
      })
    }
    setFiltro({ ...filtro, municipio: municipios });
    setCatalogos({ ...catalogos, secciones: seccionesMunicipio });
  };


  const limpiarFiltros = () => {
    setFiltro({
      municipio: null,
      seccion: null
    })
    setBusqueda("")
  }

  return (
    <Modal
      isOpen={true}
      toggle={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader toggle={onHide}>Busqueda de Responsable</ModalHeader>
      <ModalBody>
        <Col sm="12">
          <Row className="text-align-center-center">
            <Col md="8">
              <FormGroup className="col-ms-8">
                <Label className="labelForm">Responsable</Label>
                <Input
                  type="text"
                  id="nombre"
                  value={Busqueda}
                  onChange={(e) => setBusqueda(e.target.value)}
                />
              </FormGroup>
            </Col>
            <RotateSpinner
              color={'#1261ff'}
              size={30}
              loading={loadingCatalogos}
            />
            <Col md="4">
              <FormGroup className="col-ms-4">
                <Label className="labelForm">Municipio</Label>
                <Select
                  isDisabled={loadingCatalogos}
                  options={catalogos.municipios}
                  isMulti
                  value={filtro.municipio}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="Responsabilidades"
                  onChange={(municipio) => { seleccionarMunicipio(municipio) }}
                  placeholder="Seleccionar"
                />
              </FormGroup>
            </Col>
           
            
          </Row>
         
          <Row>
                  <Col md="6">
                  <FormGroup className="col-md-6">
                    <Label className="labelForm">Secciones</Label>
                    <Select
                      isDisabled={loadingCatalogos}
                      options={catalogos.secciones}
                      isMulti
                      value={filtro.seccion}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="Responsabilidades"
                      onChange={(secciones) => { setFiltro({ ...filtro, seccion: secciones }) }}
                      placeholder="Seleccionar"
                    />
                  </FormGroup>
                  
                </Col>
            </Row>
            <Row>
                <Col md="8">
                  <Button
                    color="info"
                    onClick={(e) => handleBuscar(e)}
                  >
                    Buscar
                  </Button>
                  <Button
                    color="danger"
                    onClick={limpiarFiltros}
                  >
                    Limpiar
                  </Button>
                </Col>
          </Row>
          <Row>
            <Col sm="12">
              {<RotateSpinner color={'#1261ff'} size={30} loading={loading} />}
              {
                <InfiniteScroll
                  dataLength={total}
                  next={fetchHasMore}
                  hasMore={HasMore}
                  loader={<h5>...Cargando...</h5>}
                  height={400}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>¡Final de la lista de registros!</b>
                    </p>
                  }
                >
                  <Col md="12"></Col>
                  {DatosResponsable.map((item, key) => (
                    <div
                      key={'key' + key.toString()}
                      className="divv text-muted"
                    >
                      Celular: {item.Celular}
                      <br />
                      Nombre:
                      <Button
                        className="btn-link"
                        color="primary"
                        id={key.toString()}
                        size="sm"
                        type="button"
                        onClick={(e) => handleResponsable(item)}
                      >
                        {' '}
                        {item.NombreCompleto}
                      </Button>
                      <br />
                      Responsabilidad: {item.Responsabilidad}
                      <br />
                      Municipio: {item.Municipio}
                      <br />
                      Sección: {item.Seccion}
                      <hr />
                    </div>
                  ))}
                </InfiniteScroll>
              }
            </Col>
          </Row>
        </Col>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};
export default BuscarUserOwned;
