import React, { useState, useEffect } from 'react';
// react plugin used to create switch buttons
import Select from 'react-select';
import { Button, Col,FormGroup,Label,Card,CardBody, CardHeader, Row,Collapse,Input  } from 'reactstrap';


import EjecutarApi from "../../data/getPOST";
import { getVars } from '../../GlobalStorage';

function Filtro(props){

    const token = getVars('Token').access_token;
    const [MultiValueRegion, setMultiValueRegion] = useState([]);
    const [MultiValueRegionValue, setMultiValueRegionValue] = useState([]);
    const [MultiValueMunicipio, setMultiValueMunicipio] = useState([]);
    const [MultiValueMunicipioSafe, setMultiValueMunicipioSafe] = useState([]);
    const [MultiValueMunicipioValue, setMultiValueMunicipioValue] = useState([]);

    const [MultiValuePoligono, setMultiValuePoligono] = useState([]);
    const [MultiValuePoligonoSafe, setMultiValuePoligonoSafe] = useState([]);
    const [MultiValuePoligonoValue, setMultiValuePoligonoValue] = useState([]);
    const [MultiValueSeccion, setMultiValueSeccion] = useState([]);
    const [MultiValueManzana, setMultiValueManzana] = useState([]);
    const [MultiValueManzanaSafe, setMultiValueManzanaSafe] = useState([]);
    const [MultiValueManzanaValue, setMultiValueManzanaValue] = useState([]);
    const [MultiValueSeccionSafe, setMultiValueSeccionSafe] = useState([]);
    const [MultiValueSeccionValue, setMultiValueSeccionValue] = useState([]);
    const [filtro,setFiltro] = useState({
      Nombre:"",
      Celular:"",
      Rentabilidad:"",
      Prioridad:"",
      Responsable:0
    })    
    const opcRentabilidad =[
        {value:"SWITCH",label:"SWITCH"},
        {value:"PERDIBLE",label:"PERDIBLE"},
        {value:"DURO",label:"DURO"}
    ];
    const opcPrioridad =[
        {value:1,label:1},
        {value:2,label:2},
        {value:3,label:3}
    ]

    const opcResponsable =[
        {value:1,label:"Sin Responsables"},
        {value:2,label:"Con Responsable"}
    ]

    const [collapse, setCollapse] = useState({
      horizontalTabs: "home",
      verticalTabs: "info",
      pageTabs: "homePages",
      openedCollapses: ["collapseOne", "collapse1"]
      });

    useEffect(() => {
        if(props.horizontalAnter=== "MAS" ){
            setFiltro({
                Nombre:"",
                Celular:"",
                Prioridad:"",
                Rentabilidad:"",
                Responsable:0
              });
            setMultiValueRegionValue([])
            setMultiValueMunicipioValue([])
            setMultiValuePoligonoValue([])
            setMultiValueSeccionValue([])
        }
        if((props.horizontalAnter=== "Municipal") || (props.horizontalAnter=== "Poligonal" )|| (props.horizontalAnter=== "Seccional" || (props.horizontalAnter=== "Manzanero")) && (props.horizontalTabs === "Regional") ){
            setMultiValueMunicipioValue([])
            setMultiValuePoligonoValue([])
            setMultiValueSeccionValue([])
            setMultiValueManzanaValue([])
            setFiltro({
                ...Filtro,
                Prioridad:"",
                Rentabilidad:"",
              });
        }
        if((props.horizontalAnter === "Poligonal") ||  (props.horizontalAnter === "Seccional") || (props.horizontalAnter=== "Manzanero") && (props.horizontalTabs === "Municipal")){
            setMultiValuePoligonoValue([])
            setMultiValueSeccionValue([])
            setMultiValueManzanaValue([])
            setFiltro({
                ...Filtro,
                Prioridad:"",
                Rentabilidad:"",
              });
        }
        if( (props.horizontalAnter === "Seccional" ) || (props.horizontalAnter=== "Manzanero") && (props.horizontalTabs === "Poligonal") ){
            setMultiValueSeccionValue([])
            setMultiValueManzanaValue([])
            setFiltro({
                ...Filtro,
                Rentabilidad:"",
              });
        }
        if( (props.horizontalAnter === "Manzanero") && (props.horizontalTabs === "Seccional") ){
            setMultiValueSeccionValue([])
            setMultiValueManzanaValue([])
        }
        EjecutarApi({}, "getCatalogosFiltrosPromocionClonIdMunicipioReportes", token, "POST")
            .then(function (res) {
                return res;
            })
            .then(res => {
                if (res.results) {
                    if (res.data) {

                         // Manzana
                         let manzanaFormated = res.data.Manzana.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                                idMunicipio: item.idMunicipio,
                                idPoligono: item.idPoligono,
                                Seccion:item.Seccion
                            }
                        })
                        setMultiValueManzana([{value:0,label:"Seleccione un Polígono o Sección"}]);
                        setMultiValueManzanaSafe(manzanaFormated);
                           // Secciones
                           let seccionFormated = res.data.secciones.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                                idMunicipio: item.idMunicipio,
                                idPoligono: item.idPoligono
                            }
                        })
                        setMultiValueSeccion(seccionFormated);
                        setMultiValueSeccionSafe(seccionFormated);

                        let poligonosFormated = [];
                        res.data.poligonos.forEach(item => {
                            if(item.value !== 1){
                                poligonosFormated.push({
                                    value: item.value,
                                    valuef: item.valuef,
                                    label: item.label,
                                    idRegion: item.idRegion,
                                    idMunicipio: item.idMunicipio
                                })
                            }
                        })
                        setMultiValuePoligono(poligonosFormated);
                        setMultiValuePoligonoSafe(poligonosFormated);

                        // Regiones
                        let regionesFormated = [];
                        res.data.regiones.forEach(item => {
                            if(item.value !== 1){
                                regionesFormated.push({
                                    value: item.value,
                                    label: item.label
                                })
                            }
                        })
                        setMultiValueRegion(regionesFormated);

                        //Municipios
                        let municipiosFormated = res.data.municipios.map(item => {
                            return {
                                value: item.value,
                                label: item.label,
                                idRegion: item.idRegion,
                            }
                        })
                        setMultiValueMunicipio(municipiosFormated);
                        setMultiValueMunicipioSafe(municipiosFormated);

                      
                        /* setMultiValueSeccion(seccion.concat(seccionFormated));
                        setMultiValueSeccionSafe(seccion.concat(seccionFormated)); */
                    }
                }

            })
            .catch((e) => {
            })

    }, [collapse.openedCollapses]);

    const changeRegion = (idRegiones) => {

        
        if (idRegiones.includes(0)) {
            setMultiValueMunicipio(MultiValueMunicipioSafe);
            if(MultiValueMunicipioValue.length !== 0){
                changeMunicipios(MultiValueMunicipioValue);
            }
            else{
                setMultiValuePoligono(MultiValuePoligonoSafe);
                if(MultiValuePoligonoValue.length !== 0){
                    var array_secciones = [];
                    MultiValuePoligonoValue.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
                else{
                    
                    setMultiValueSeccion(MultiValueSeccionSafe);
                }
                
            }
            return true;
        }
        //setLoading(true);
        if (idRegiones.length > 0) {
            var array_municipios = [];
            idRegiones.forEach((item) => {
                let array_temp = MultiValueMunicipioSafe.filter(data => data.idRegion === item);
                array_municipios = array_municipios.concat(array_temp);
            });
            setMultiValueMunicipio(array_municipios);

            if(MultiValueMunicipioValue.length !== 0){
                changeMunicipios(MultiValueMunicipioValue);
            }
            else{
                
                var array_poligonos = [];
                idRegiones.forEach((item) => {
                    let array_temp = MultiValuePoligonoSafe.filter(data => parseInt(data.idRegion, 10) === item);
                    array_poligonos = array_poligonos.concat(array_temp);
                });
                setMultiValuePoligono(array_poligonos);

                if(MultiValuePoligonoValue.length !== 0){
                    var array_secciones = [];
                    MultiValuePoligonoValue.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
                else{
                    var array_secciones = [];
                    idRegiones.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => data.idRegion === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
            }
        }
        else{
            changeMunicipios([MultiValueMunicipioValue]);
        }
    }

    const regionSelect = (event, i) => {
        if(i.action === "clear"){
            setMultiValueRegionValue([]);
            changeRegion([0]);
            return true;
        }
        if (event) {
            const array_temp = event.map(dato => { return dato.value })
            setMultiValueRegionValue(
                array_temp
            )
            changeRegion(array_temp);

        }
        else {
            if (i.removedValue !== undefined) {
                const array_temp = MultiValueRegionValue.map(dato => {
                    if (i.removedValue.value !== dato) {
                        return dato.value
                    }

                })
                if(!array_temp.includes(undefined)){
                    changeRegion(array_temp);
                    setMultiValueRegionValue(array_temp);

                }
                else{
                    setMultiValueRegionValue([]);
                    setMultiValueMunicipio(MultiValueMunicipioSafe);
                    changeRegion([0]);
                    
                }
                    
            }
            else {
                setMultiValueMunicipio(MultiValueMunicipioSafe);
            }
        }
    }

    const changeMunicipios = (idMunicipios) => {

        if(idMunicipios.includes(0)){
            if(MultiValueRegionValue.length !== 0){
                let array_poligonos = [];
                MultiValueRegionValue.forEach((item) => {
                    let array_temp = MultiValuePoligonoSafe.filter(data => parseInt(data.idRegion,10) === item);
                    array_poligonos = array_poligonos.concat(array_temp);
                });
                setMultiValuePoligono(array_poligonos);

                if(MultiValuePoligonoValue.length !== 0){
                    let array_secciones = [];
                    MultiValuePoligonoValue.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
                else{
                    let array_secciones = [];
                    MultiValueRegionValue.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => data.idRegion === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
            }
            else{
                setMultiValuePoligono(MultiValuePoligonoSafe);
                if(MultiValuePoligonoValue.length !== 0){
                    let array_secciones = [];
                    MultiValuePoligonoValue.forEach((item) => {
                        let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                        array_secciones = array_secciones.concat(array_temp);
                    });
                    setMultiValueSeccion(array_secciones);
                }
                else{
                    
                    setMultiValueSeccion(MultiValueSeccionSafe);
                }
            }
            return true;
        }
        if (idMunicipios.length > 0) {

            let array_poligonos = [];
            idMunicipios.forEach((item) => {
                let array_temp = MultiValuePoligonoSafe.filter(data => parseInt(data.idMunicipio,10) === item);
                array_poligonos = array_poligonos.concat(array_temp);
            });
            setMultiValuePoligono(array_poligonos);

            if(MultiValuePoligonoValue.length !== 0){
                let array_secciones = [];
                MultiValuePoligonoValue.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => parseInt(data.idPoligono,10) === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);
            }
            else{
                let array_secciones = [];
                idMunicipios.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => data.idMunicipio === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);
            }
        }
        else{
            if(MultiValueRegion.length !== 0){
                changeRegion(MultiValueRegion);
            }
            else{
                setMultiValuePoligono(MultiValuePoligonoSafe);
            }
        }
    }
    const changePoligonos = (idPoligonos) => {
        if(idPoligonos.includes(0)){
            if(MultiValueMunicipioValue.length !== 0){
                var array_secciones = [];
                MultiValueMunicipioValue.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => data.idMunicipio === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);

            }
            else if(MultiValueRegionValue.length !== 0){
                var array_secciones = [];
                MultiValueRegionValue.forEach((item) => {
                    let array_temp = MultiValueSeccionSafe.filter(data => data.idRegion === item);
                    array_secciones = array_secciones.concat(array_temp);
                });
                setMultiValueSeccion(array_secciones);
            }
            else{
                setMultiValueSeccion(MultiValueSeccionSafe);
                setMultiValueManzana([{value:0,label:"Seleccione un Polígono o Sección"}]);
            }
            return true;
        }
        if (idPoligonos.length > 0) {
            var array_secciones = [];
            idPoligonos.forEach((item) => {
                let array_temp = MultiValueSeccionSafe.filter(data => data.idPoligono === item);
                array_secciones = array_secciones.concat(array_temp);
            });
            setMultiValueSeccion(array_secciones);

            var array_manzanas = [];
            idPoligonos.forEach((item) => {
                let array_temp = MultiValueManzanaSafe.filter(data => data.idPoligono === item);
                array_manzanas = array_manzanas.concat(array_temp);
            });
            setMultiValueManzana(array_manzanas);
        }
        else{
            if(MultiValueMunicipio.length !== 0){
                changeMunicipios(MultiValueMunicipio);
            }
            else if(MultiValueRegion.length !== 0) {
                changeRegion(MultiValueRegion);
            }
            else{
                setMultiValuePoligono(MultiValuePoligonoSafe);
            }
        }
    }

    const changeSeccion = (seccion) => {
        if(seccion.includes(0)){
            if(MultiValueMunicipioValue.length !== 0){
                var array_manzanas = [];
                MultiValueMunicipioValue.forEach((item) => {
                    let array_temp = MultiValueManzanaSafe.filter(data => data.idMunicipio === item);
                    array_manzanas = array_manzanas.concat(array_temp);
                });
                setMultiValueManzana(array_manzanas);

            }
            else if(MultiValueRegionValue.length !== 0){
                var array_manzanas = [];
                MultiValueRegionValue.forEach((item) => {
                    let array_temp = MultiValueManzanaSafe.filter(data => data.idRegion === item);
                    array_manzanas = array_manzanas.concat(array_temp);
                });
                setMultiValueManzana(array_manzanas);
            }
            else if(MultiValuePoligonoValue.length !== 0){
                var array_manzanas = [];
                MultiValuePoligonoValue.forEach((item) => {
                    let array_temp = MultiValueManzanaSafe.filter(data => data.idPoligono === item);
                    array_manzanas = array_manzanas.concat(array_temp);
                });
                setMultiValueManzana(array_manzanas);
            }
            else{
                setMultiValueManzana([{value:0,label:"Seleccione un Polígono o Sección"}]);
            }
            return true;
        }
        if (seccion.length > 0) {
            var array_manzanas = [];
            seccion.forEach((item) => {
                let array_temp = MultiValueManzanaSafe.filter(data => data.Seccion === item);
               
                array_manzanas = array_manzanas.concat(array_temp);
            });
            setMultiValueManzana(array_manzanas);
        }
        else{
            if(MultiValueMunicipio.length !== 0){
                changeMunicipios(MultiValueMunicipio);
            }
            else if(MultiValueRegion.length !== 0) {
                changeRegion(MultiValueRegion);
            }
            else if(MultiValuePoligono.length !== 0) {
                setMultiValuePoligono(MultiValuePoligono);
            }
            else{
                setMultiValueSeccion(MultiValueSeccionSafe);
            }
        }
    }

    const setFilter = (event) => {
        event.preventDefault();
        const myFiltro = [];
        
        //MultiValueRegion
        var x = 0;
        if (MultiValueRegionValue !== null && MultiValueRegionValue.length !== 0/*  && !MultiValueRegionValue.MultiValueRegionValue */)
            myFiltro.push({ id: 'idRegion', value: MultiValueRegionValue });
        else
            myFiltro.push({ id: 'idRegion', value: [] });

        //MultiValueMunicipio
        if (MultiValueMunicipioValue !== null && MultiValueMunicipioValue.length !== 0 /* && !MultiValueMunicipioValue.MultiValueMunicipioValue */)
            myFiltro.push({ id: 'idMunicipio', value: MultiValueMunicipioValue });
        else
            myFiltro.push({ id: 'idMunicipio', value: [] });

        if (MultiValuePoligonoValue !== null && MultiValuePoligonoValue.length !== 0/*  && !MultiValuePoligonoValue.MultiValuePoligonoValue */)
            myFiltro.push({ id: 'idPoligono', value: MultiValuePoligonoValue });
        else
            myFiltro.push({ id: 'idPoligono', value: [] });

        if (MultiValueSeccionValue!== null && MultiValueSeccionValue.length !== 0/*  && !MultiValuePoligonoValue.MultiValuePoligonoValue */)
            myFiltro.push({ id: 'S.Seccion', value: MultiValueSeccionValue });
        else
            myFiltro.push({ id: 'S.Seccion', value: [] });
        
        //Seccion
        if (filtro !== null && filtro.Nombre !== ""/*  && !MultiValueSeccionValue.MultiValueSeccionValue */)
            myFiltro.push({ id: "Nombre", value: filtro.Nombre });
        else
            myFiltro.push({ id: "Nombre", value: filtro.Nombre});
        
        if (filtro.Celular !== null && filtro.Celular !== ""/*  && !MultiValueSeccionValue.MultiValueSeccionValue */)
            myFiltro.push({ id: 'u.email', value: filtro.Celular });
        else
            myFiltro.push({ id: 'u.email', value: filtro.Celular });

        if (filtro.Rentabilidad !== null && filtro.Rentabilidad !== ""/*  && !MultiValueSeccionValue.MultiValueSeccionValue */)
            myFiltro.push({ id: 'S.Rentabilidad', value: filtro.Rentabilidad });
        else
            myFiltro.push({ id: 'S.Rentabilidad', value: "" });
            
        if (filtro.Prioridad !== null && filtro.Prioridad !== ""/*  && !MultiValueSeccionValue.MultiValueSeccionValue */)
            myFiltro.push({ id: 'cm.PRIORIDAD', value: filtro.Prioridad });
        else
            myFiltro.push({ id: 'cm.PRIORIDAD', value: "" });
            
        if (MultiValueManzanaValue !== null && MultiValueManzanaValue.length !== ""/*  && !MultiValueSeccionValue.MultiValueSeccionValue */)
        {
            if(MultiValueManzanaValue[0] !== 0) myFiltro.push({ id: 'idManzana', value: MultiValueManzanaValue });
            else myFiltro.push({ id: 'idManzana', value: [] });
           
        }
        else
            myFiltro.push({ id: 'idManzana', value: MultiValueManzanaValue });
        
        if (filtro.Responsable !== null && filtro.Responsable !==  0/*  && !MultiValueSeccionValue.MultiValueSeccionValue */)
            myFiltro.push({ id: 'Responsable', value: filtro.Responsable });
        else
            myFiltro.push({ id: 'Responsable', value: 0 });
            

        //setFiltered(myFiltro);
        props.filtrar(myFiltro);
    };

    const  Limpiar= () => {
        
        const myFiltro = [];
        collapsesToggle("collapse3")
        regionSelect([],{action: "clear", name: "Region"});
        setMultiValueMunicipio([])
        setMultiValuePoligono([])
        setMultiValueSeccion([])
        setMultiValueManzana([])
        setFiltro({
            Nombre:"",
            Celular:"",
            Prioridad:"",
            Rentabilidad:"",
            Responsable:""
          })
        
          props.limpiar(myFiltro);
        
          
    };

    const collapsesToggle = (colapse) => {
      let openedCollapses = collapse.openedCollapses;
      if (openedCollapses.includes(colapse)) {
        setCollapse({...collapse,
        openedCollapses: openedCollapses.filter(item => item !== colapse)
        });
      } else {
        openedCollapses.push(colapse);
        setCollapse({...collapse, 
        openedCollapses: openedCollapses
        });
      }
      };

   
    const showMunicipio =  collapse.openedCollapses.length === 3 && props.responsibilityID !== 11

    const showRegion = collapse.openedCollapses.length === 3  && ( props.responsibilityID !== 53 && props.responsibilityID !== 54 && props.responsibilityID !== 55)

    const showPoligonos = collapse.openedCollapses.length === 3 

    const showSecciones = collapse.openedCollapses.length === 3  

    const showManzana = collapse.openedCollapses.length === 3  
    const filtrarButton = collapse.openedCollapses.length === 3  

    return (
      <Card className="card-plain">
        <CardBody>
        <div
            aria-multiselectable={true}
            className="card-collapse"
            id="accordion"
            role="tablist"
        >
           <Card className="card-plain">
                <CardHeader role="tab">
                    <a
                        aria-expanded={collapse.openedCollapses.includes(
                            "collapse3"
                          )}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => collapsesToggle("collapse3")}
                        className="text-left"
                    >
                        <Button
                            className="btn-icon btn-neutral"
                            outline
                            size="sm"
                            id="EditUser"
                        >
                            <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                        </Button>
                        Filtro
                    </a>
                    
                </CardHeader>
                <Collapse
                    role="tabpanel"
                    isOpen={collapse.openedCollapses.includes(
                        "collapse3"
                      )}
                >
          <CardBody>
            <Row>
            {
            showRegion
            ? 
            <>
            <Col sm={showMunicipio?"":"4"} >
            <FormGroup>
                <Label for="region">
                </Label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Region"
                    placeholder="Región"
                    options={MultiValueRegion}
                    onChange={(event, i) => {
                       regionSelect(event, i)
                    }}

                    isMulti
                />
             </FormGroup>
            </Col>
            </>
            :
            <></>
            }
            {
            showMunicipio
            ? 
            <Col>
            <Label for="municipio">
                </Label>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Municipio"
                    placeholder="Municipio"
                    options={MultiValueMunicipio}
                    onChange={(event, i) => {
                        if(i.action === "clear"){
                            setMultiValueMunicipioValue([]);
                            changeMunicipios([0]);
                            return true;
                        }
                        if (event) {
                            const array_temp = event.map(dato => { return dato.value })
                            setMultiValueMunicipioValue(
                                array_temp
                            )
                            changeMunicipios(array_temp);

                        }
                        else {
                            if (i.removedValue !== undefined) {
                                const array_temp = MultiValueMunicipioValue.map(dato => {
                                    if (i.removedValue.value !== dato) {
                                        return dato.value
                                    }
                                })
                                if(!array_temp.includes(undefined)){
                                    changeMunicipios(array_temp);
                                    setMultiValueMunicipioValue(array_temp);
                                }
                                else{
                                    setMultiValueMunicipioValue([]);
                                    changeMunicipios([0]);   
                                }
                            }
                            else{
                                //Tacha por completo
                            }

                        }
                    }}

                    isMulti
                />
            </Col>
            :
            <>
            </>
            }
                        
            {
            showPoligonos
            ? 
                <Col>
                <Label for="poligono">
                </Label>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Poligono"
                        placeholder="Poligono"
                        options={MultiValuePoligono}
                        onChange={(event, i) => {
                            if(i.action === "clear"){
                                setMultiValuePoligonoValue([]);
                                changePoligonos([0]);
                                return true;
                            }
                            if (event) {
                                const array_temp = event.map(dato => { return dato.value })
                                setMultiValuePoligonoValue(
                                    array_temp
                                )
                                changePoligonos(array_temp);

                            }
                            else {
                                if (i.removedValue !== undefined) {
                                    const array_temp = MultiValuePoligonoValue.map(dato => {
                                        if (i.removedValue.value !== dato) {
                                            return dato.value
                                        }
                                    })
                                    if(!array_temp.includes(undefined)){
                                        changePoligonos(array_temp);
                                        setMultiValuePoligonoValue(array_temp);
                                    }
                                    else{
                                        setMultiValuePoligonoValue([]);
                                        changePoligonos([0]);   
                                    }
                                }
                                else{
                                }

                            }
                        }}

                        isMulti
                    />
                </Col>
                :
                <>
                </>
                }
                {
                showSecciones
                ? 
                <Col>
                <Label for="seccion">
                </Label>
                    <Select
                        name="Seccion"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Seccion"
                        options={MultiValueSeccion}
                        onChange={(event, i) => {
                            if(i.action === "clear"){
                                setMultiValueSeccionValue([]);
                                changeSeccion([0]);
                                return true;
                            }
                            if (event) {
                                const array_temp = event.map(dato => { return dato.value })
                                setMultiValueSeccionValue(
                                    array_temp
                                )

                                changeSeccion(array_temp);
                            }
                            else {
                                if (i.removedValue !== undefined) {
                                    const array_temp = MultiValueSeccionValue.map(dato => {
                                        if (i.removedValue.value !== dato) {
                                            return dato.value
                                        }
                                    });

                                    if(!array_temp.includes(undefined)){
                                        changeSeccion(array_temp);
                                        setMultiValueSeccionValue(array_temp);
                                    }
                                    else{
                                        setMultiValueSeccionValue([]);
                                        changeSeccion([0]);
                                    }
                                }
                            }
                        }}
                        isMulti
                    />
                </Col>
                :
                <>
                </>
                }
                </Row>
                <Row>
                <Col sm="4" className="text-muted">Nombre Responsable:<Input id="inombre" value={filtro.Nombre} onChange={(e)=>setFiltro({...filtro,Nombre:e.target.value})} /></Col>	
                <Col sm="4" className="text-muted">Celular: <Input id="celular" type="number" value={filtro.Celular} onChange={(e)=>setFiltro({...filtro,Celular:e.target.value})} /></Col> 
                </Row>
                </CardBody>
                <Row>
                {filtrarButton &&
                <Col sm='2'> 
                    <Button type="button"  className='primary float-right'  color="info" block className="btn-round" type="submit" id="filtrar" onClick={setFilter}>
                    Filtrar
                    </Button>
                </Col>
                    }
                <Col sm='2'> 
                    <Button type="button"  className='primary float-right'  color="warning" block className="btn-round" type="submit"  id="limpiar" onClick={Limpiar}>
                    Limpiar
                    </Button>
                </Col>
                
                </Row>
                </Collapse>
                </Card>
                </div>
            </CardBody>
        </Card>
                        
    );
}
export default Filtro;