import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  
  Label,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {RotateSpinner} from "react-spinners-kit";

import EjecutarApi from "data/getPOST";
import { getVars } from "GlobalStorage";

function ElectoralRGs (props)
{
   
    const token = getVars("Token");
    const [loading, setLoading] = useState(false);
    //const [catPartido,setCatPartido]=useState([]);
    //const [Partido,setPartido]=useState([]);
    //const [Seccion,setSeccion]=useState('');
    //const [Casilla,setCasilla]=useState([]);
    //const [Propietario,setPropietario]=useState([]);
    const [Distrito,setDistrito]=useState([{value:0,label:'Debe validar la sección'}]);
    //const [catCasilla,setcatCasilla]=useState([{value:0,label:'Debe validar la sección'}]);
    const [catDistrito,setcatDistrito]=useState([{value:0,label:'Debe validar la sección'}]);
    //const [catPropietario,setcatPropietario]=useState([{value:0,label:'Debe validar la sección'}]);
    const [catEntidad, setCatEntidad] = useState([]);
    const [catSexo, setCatSexo] = useState([]);
    ///const [catVoluntario, setCatVoluntario] = useState([]);
    const [catSituacionLaboral, setSituacionLaboral] = useState([]);
    const [catNivelEstudios, setNivelEstudios] = useState([]);
    const [catMunicipio, setCatMunicipio] = useState([]);
    const [catLocalidad, setCatLocalidad] = useState([]);
    const [catColonias, setCatColonia] = useState([]);
    const [catMunicipioVota, setCatMunicipioVota] = useState([]);
    const [catLocalidadVota, setCatLocalidadVota] = useState([]);
    const [catColoniasVota, setCatColoniaVota] = useState([]);
    const [OCR,setOCR]=useState('');
    const [flagUpdatePerfil, setflagUpdatePerfil] = useState(false);
    const [isCompromisoSave,setisCompromisoSave] = useState(false);
    const [isVisible] = useState(false);
    const [isINEValida,setisINEValida] = useState(true);
    const [MunicipioVive,setMunicipiovive]= useState(0);
    const [MunicipioVota,setMunicipiovota]= useState(0);
    const [tieneExperiencia,setTieneExperiencia]=useState(0);
    const [tieneAuto,setTieneAuto]=useState(0);
    const [VigenciaINE,setVigenciaINE]=useState('');
    const [compromiso, setCompromiso] =useState({
      INE: "",
      CURP: "",
      Nombre: "",
      Paterno: "",
      Materno: "",
      FechaNacimiento: "",
      Sexo: { value: "", label: "" },
      EstadoNacimiento: { value: "", label: "" }, //value & label
      CalleVive: "",
      NumExtVive: "",
      NumIntVive: "",
      CPVive: "",
      ColoniaVive: "",
      MunicipioVive: { value: "", label: "" },
      LocalidadVive: { value: "", label: "" },
      Celular: "",
      Telefono: "",
      TelMensajes: "",
      SeccionVive: "",
      MismaAddress: true,

      isVoluntario: true,
      Voluntario: { value: "3", label: "RGs" },
      SeccionVota: "",
      MunicipioVota: { value: "", label: "" },
      CalleVota: "",
      NumExtVota: "",
      NumIntVota: "",
      CPVota: "",
      ColoniaVota: "",
      LocalidadVota: { value: "", label: "" },

      ExperienciaElectoral: "",
      ExperienciaDonde: "",
      ExperienciaAnio: "",
      AcercaDe: "",
      NivelEstudios: "", //value,label
      TituloObtenido: "",
      SituacionLaboral: "",
      EmpresaLabora: "",
    });
   

    useEffect(() => {
        setLoading(true);
        //localStorage.removeItem('TipoElectoral');  
        //sessionStorage.removeItem('TipoElectoral');
        //setVars('TipoElectoral',TipoMovi);
        EjecutarApi({}, "catCompromisos", token.access_token, "GET")
          .then(function (res) {
            return res;
          })
          .then((items) => {
            if (items.results) {
              setCatEntidad(
                items.data.Entidad.map((item) => {
                  return {
                    value: item["value"],
                    label: item["label"],
                  };
                })
              );
              setCatMunicipio(
                items.data.Municipio.map((item) => {
                  return {
                    value: item["value"],
                    label: item["label"],
                  };
                })
              );  
              setCatMunicipioVota(
                items.data.Municipio.map((item) => {
                  return {
                    value: item["value"],
                    label: item["label"],
                  };
                })
              );  
              setCatSexo(items.data.Sexo);
              //setCatVoluntario(items.data.Voluntario);
              setSituacionLaboral(items.data.SituacionLaboral);
              setNivelEstudios(items.data.NivelEstudios);
              setLoading(false);
            } else {
              notify.show("No Info: catCompromisos", "custom", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
              });
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            notify.show("Error: catCompromisos", "custom", 5000, {
              background: "#0857B6",
              fontSize: 14,
              text: "#FFFFFF",
            });
          });
    
        EjecutarApi({}, 'getListadoDF', token.access_token)
        .then(function(res) {
            return res;
        })
        .then((res) => {
            if(res.results)
            {
                setLoading(false);
                setcatDistrito(res.data);
                
            }
            
        })
        .catch((e) => {
            setLoading(false);
            notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF'
            });
        });



    }, [])
    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };
    /*const btnValidaSeccion=()=>{
        EjecutarApi({"seccion":Seccion}, 'validarSeccion', token.access_token)
			.then(function(res) {
				return res;
			})
			.then((res) => {
                if(res.results)
                {
                    setLoading(false);
                    notify.show(`Sección válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    //getCasillasBySeccion 
                    EjecutarApi({"seccion":Seccion}, 'getCasillasBySeccion', token.access_token)
                    .then(function(res) {
                    
                        return res;
                    })
                    .then((res) => {
                        if(res.success)
                        {
                            setcatCasilla(res.data);
                            setLoading(false);
                        }
                        
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                            background: '#0857B6',
                            fontSize: 14,
                            text: '#FFFFFF'
                        });
                    });
                    //getCasillaPropietario 
                    EjecutarApi({"seccion":Seccion}, 'getCasillaPropietario', token.access_token)
                    .then(function(res) {
                        return res;
                    })
                    .then((res) => {
                        if(res.success)
                        {
                            setcatPropietario(res.data);
                            setLoading(false);
                        }
                        
                    })
                    .catch((e) => {
                        setLoading(false);
                        notify.show(`Error en: ${e.error}`, 'custom', 5000, {
                            background: '#0857B6',
                            fontSize: 14,
                            text: '#FFFFFF'
                        });
                    });
                }
                else
                {
                    notify.show(`Sección No válida`, 'custom', 5000, {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF'
                    });
                    setLoading(false);
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
            });
          
    }*/

    const btnSaveCompromiso = () => {
        var idCompromiso =0;
        setLoading(true);
        var parametros = {
        ClaveElector: compromiso.INE?compromiso.INE:"",
        CURP: compromiso.CURP?compromiso.CURP:"",
        Nombres: compromiso.Nombre?compromiso.Nombre:"",
        Paterno: compromiso.Paterno?compromiso.Paterno:"",
        Materno: compromiso.Materno?compromiso.Materno:"",
        FechaNacimiento: compromiso.FechaNacimiento?compromiso.FechaNacimiento:"",
        idSexo: compromiso.Sexo?compromiso.Sexo.value:"",
        idEstadoNacimiento: compromiso.EstadoNacimiento.value?compromiso.EstadoNacimiento.value:"", //value & label
        CalleVive: compromiso.CalleVive?compromiso.CalleVive:"",
        NumExtVive: compromiso.NumExtVive?compromiso.NumExtVive:"",
        NumIntVive: compromiso.NumIntVive?compromiso.NumIntVive:"",
        CPVive: compromiso.CPVive?compromiso.CPVive:"",
        ColoniaVive: compromiso.ColoniaVive?compromiso.ColoniaVive.label:"",
        idMunicipioVive: MunicipioVive,//compromiso.MunicipioVive.value?compromiso.MunicipioVive.value:"",
        idLocalidadVive: compromiso.LocalidadVive.value?compromiso.LocalidadVive.value:"",
        SeccionVive: compromiso.SeccionVive?compromiso.SeccionVive:"",

        Celular: compromiso.Celular?compromiso.Celular:"",
        Telefono: compromiso.Telefono?compromiso.Telefono:"",
        TelMensajes: compromiso.TelMensajes?compromiso.TelMensajes:"",

        MismaAddress: compromiso.MismaAddress,

        isVoluntario: compromiso.isVoluntario?compromiso.isVoluntario:"",
        idVoluntario: compromiso.Voluntario?compromiso.Voluntario.value:0, //value,label
        SeccionVota: compromiso.SeccionVota?compromiso.SeccionVota:"",
        idMunicipioVota:
            compromiso.MismaAddress 
            ? MunicipioVive
            : MunicipioVota,
        //SeccionVota: compromiso.SeccionVota,
        CalleVota:
            compromiso.MismaAddress 
            ? compromiso.CalleVive
            : compromiso.CalleVota,
        NumExtVota:
            compromiso.MismaAddress
            ? compromiso.NumExtVive
            : compromiso.NumExtVota,
        NumIntVota:
            compromiso.MismaAddress 
            ? compromiso.NumIntVive
            : compromiso.NumIntVota,
        CPVota:
            compromiso.MismaAddress? compromiso.CPVive : compromiso.CPVota,
        ColoniaVota:
            compromiso.MismaAddress? compromiso.ColoniaVive.label : compromiso.ColoniaVota,
        idLocalidadVota:
            compromiso.MismaAddress? compromiso.LocalidadVive.value
            : compromiso.LocalidadVota.value,

        ExperienciaElectoral: compromiso.ExperienciaElectoral,
        ExperienciaAnio: compromiso.ExperienciaAnio,
        ExperienciaDonde: compromiso.ExperienciaDonde,
        Acercade: compromiso.AcercaDe,
        idNivelEstudios: compromiso.NivelEstudios.value, //value,label
        TituloObtenido: compromiso.TituloObtenido,
        idSituacionLaboral: compromiso.SituacionLaboral.value,
        EmpresaLabora: compromiso.EmpresaLabora,
        };

        validaINE(parametros);
        if(!isINEValida){
        setLoading(false);
        notify.show("Error: INE, no es valida", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if(OCR.substring(0,4)!==compromiso.SeccionVota)
        {
            setLoading(false);
            notify.show("Error: OCR y Sección Vota No coindicen....", "error", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
            return true;
        }
        if(VigenciaINE<=2021)
        {
            setLoading(false);
            notify.show("Error: La vigencia de la INE debe de ser mayor al año 2021. Favor de renovar la INE", "error", 5000, {
                background: "#0857B6",
                fontSize: 14,
                text: "#FFFFFF",
            });
            return true;
        }
        if (parametros.Nombres.length < 1) {
        setLoading(false);
        notify.show("Error: Nombre es requerida", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (parametros.Paterno.length < 1) {
        setLoading(false);
        notify.show("Error: Apellido Paterno es requerida", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (parametros.Materno.length < 1) {
        setLoading(false);
        notify.show("Error: Apellido Materno es requerida", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (parametros.idSexo.length < 1) {
        setLoading(false);
        notify.show("Error: Sexo es requerida", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (parametros.idEstadoNacimiento < 1) {
        setLoading(false);
        notify.show("Error: Entidad de Nacimiento es requerida", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (parametros.ColoniaVive.length < 1) {
        setLoading(false);
        notify.show("Error: Colonia es requerida", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (parametros.idMunicipioVive < 1) {
        setLoading(false);
        notify.show("Error: Municipio Vive es requerida", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }
        
        if (parametros.isVoluntario) {
        setLoading(false);
        if (parametros.idVoluntario.length < 1) {
            notify.show("Error: Tipo de Voluntario es requerida", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
            });
            return true;
        }

        
        }
        EjecutarApi(parametros, "setCompromisos", token.access_token)
        .then(function (res) {
            return res;
          })
        .then(function (res) {
            if (res.success) {
                notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                    background: "#18c97d",
                    fontSize: 24,
                    text: "#FFFFFF",
                });
                setisCompromisoSave(true);
                setLoading(false);
                idCompromiso=res.data.idCompromiso;
                var data = {
                    idCompromiso: idCompromiso,
                    idResponsabilidad: 3,
                    idDistrito:Distrito.value,
                    tieneExperiencia:tieneExperiencia, 
                    tieneAuto:tieneAuto,
                    OCR:OCR,
                    VigenciaINE:VigenciaINE,
                }
                EjecutarApi(data, "crearPropuestaElectoral", token.access_token)
                .then(function (res) {
                    return res;
                  })
                .then((res) => {
                if (res.success) {
                        notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
                            background: "#18c97d",
                            fontSize: 24,
                            text: "#FFFFFF",
                        });
                        
                        setLoading(false);
                        
                    } else {
                        setLoading(false);
                        notify.show(
                            `Error al crear el registro: ${res.message}!!`,
                            "custom",
                            5000,
                            {
                            background: "#0857B6",
                            fontSize: 24,
                            text: "#FFFFFF",
                            }
                        );
                    }
                    return true;
                })
                .catch((e) => {
                    setLoading(false);
                    notify.show(`Error al agregar la Responsabilidad: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 24,
                    text: "#FFFFFF",
                    });
                });
            } else {
                setLoading(false);
                notify.show(
                    `Error al hacer el registro: ${res.message}!!`,
                    "custom",
                    5000,
                    {
                    background: "#0857B6",
                    fontSize: 24,
                    text: "#FFFFFF",
                    }
                );
            }
            return true;
        })
        .catch((e) => {
            setLoading(false);
            notify.show(`Error al agregar el compromiso: ${e.error?e.error:'validar que los datos esten correctos!'}`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 24,
            text: "#FFFFFF",
            });
        });
        
    };

    const clearForm = () => {
        setisCompromisoSave(false);
        var datavacia = { value: "-1", label: "Seleccione..." };
        setCompromiso({
        INE: "",
        CURP: "",
        Nombre: "",
        Paterno: "",
        Materno: "",
        FechaNacimiento: "",
        Sexo: datavacia,
        EstadoNacimiento: datavacia, //value & label
        CalleVive: "",
        NumExtVive: "",
        NumIntVive: "",
        CPVive: "",
        ColoniaVive: "",
        MunicipioVive: datavacia,
        LocalidadVive: datavacia,
        Celular: "",
        Telefono: "",
        TelMensajes: "",
        SeccionVive: "",
        MismaAddress: true,

        isVoluntario: false,
        Voluntario: datavacia,
        SeccionVota: "",
        MunicipioVota: datavacia,
        CalleVota: "",
        NumExtVota: "",
        NumIntVota: "",
        CPVota: "",
        ColoniaVota: "",
        LocalidadVota: datavacia,

        ExperienciaElectoral: "",
        ExperienciaDonde: "",
        ExperienciaAnio: "",
        AcercaDe: "",
        NivelEstudios: "", //value,label
        TituloObtenido: "",
        SituacionLaboral: "",
        EmpresaLabora: "",
        });
        
    };

    /*const searchCURP = () => {
        setLoading(true);
        var _curp = compromiso.CURP;
        if (compromiso.CURP === "") {
        setLoading(false);
        notify.show(`Error: Ingresar una CURP valida!`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
        });
        return true;
        }

        if (compromiso.CURP.length !== 18) {
        setLoading(false);
        notify.show(`Error: CURP inválida!`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
        });
        return true;
        }

        //	const CURP = vale.CURP.toUpperCase();

        fetch(
        `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${_curp}`
        )
        .then((res) => res.json())
        .then((dataRS) => {
            setLoading(false);
            if (dataRS.Mensaje === "OK") {
            //aqui
            var FN = dataRS.Resultado.fechNac.split("/");
            setCompromiso({
                ...compromiso,
                Nombre: dataRS.Resultado.nombres,
                Paterno: dataRS.Resultado.apellido1,
                Materno: dataRS.Resultado.apellido2,
                Sexo:
                dataRS.Resultado.sexo === "H"
                    ? { value: "2", label: "Masculino" }
                    : { value: "3", label: "Femenino" },
                    FechaNacimiento: FN[2] + "-" + FN[1] + "-" + FN[0],
            });
            
            } else {
            setLoading(false);
            if (
                dataRS.Mensaje === "La CURP no se encuentra en la base de datos"
            ) {
                notify.show(
                `Error en CURP...! ${dataRS.Mensaje}`,
                "custom",
                5000,
                {
                    background: "#d61818",
                    text: "#FFFFFF",
                }
                );
            } else if (
                dataRS.Mensaje ===
                "El campo CURP: No cumple con el formato especificado."
            ) {
                "El campo CURP: No cumple con el formato especificado."
                );
                notify.show(
                `Error en CURP...! ${dataRS.Mensaje}`,
                "custom",
                5000,
                {
                    background: "#d61818",
                    text: "#FFFFFF",
                }
                );
            } else {
            }
            return true;
            }
        })
        .catch((err) => {
            setLoading(false);
        });
    };*/

    const searchCP = (e) => {
        var dato = { CP: compromiso.CPVive };
        EjecutarApi(dato, "getDataByCP", token.access_token, "POST")
        .then(function (res) {
            return res;
        })
        .then((res) => {
            if (res.results) {
            setCompromiso({ ...compromiso, MunicipioVive: res.data.municipio });
            setCatLocalidad(res.data.localidades);
            setCatColonia(res.data.colonias);
            }
        })
        .catch((e) => {
            notify.show(`Error en CP...! ${e.message}`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
            });
        });
    };

    const validaINE = (param) => {
        var ClaveElector = param.ClaveElector?param.ClaveElector:"";
        var Materno = param.Materno?param.Materno:"";
        var Nombres = param.Nombres?param.Nombres:"";
        var Paterno = param.Paterno?param.Paterno:"";
        var FechaNacimiento = param.FechaNacimiento?param.FechaNacimiento:"";
        var idEstadoNacimiento = param.idEstadoNacimiento?param.idEstadoNacimiento:"";
        var idSexo = param.idSexo?param.idSexo:"";
        //var Celular = param.Celular?param.Celular:"";

        const INE_PATERNO = ClaveElector.substring(0, 2);
        const INE_MATERNO = ClaveElector.substring(2, 4);
        const INE_NOMBRE = ClaveElector.substring(4, 6);
        const INE_FECHA = ClaveElector.substring(6, 12);
        const INE_ENTIDAD = ClaveElector.substring(12, 14);
        const INE_SEXO = ClaveElector.substring(14, 15);

        var nNombre = "";
        var nPaterno = "";
        var nMaterno = "";

        function eliminarVocales(str) {
        let resultado = str.replace(/[aeiou]/g, "");
        return resultado;
        }

        function trim(valor) {
        return valor.replace(/^\s+|\s+$/g, "");
        }

        var ItemMaterno = Materno.split(" ");

        if (ItemMaterno.length === 1) {
        const tmpMaterno = Materno.replace(/[aeiou]/gi, "").substring(0, 2);
        if (tmpMaterno.substring(0, 1) !== Materno.substring(0, 1))
            nMaterno = Materno.substring(0, 1) + tmpMaterno.substring(0, 1);
        else nMaterno = Materno.replace(/[aeiou]/gi, "").substring(0, 2);
        } else {
        var MaternoLiso = ItemMaterno.filter(
            (item) => item.toUpperCase() !== "DE"
        );
        var MaternoLiso2 = MaternoLiso.filter(
            (item) => item.toUpperCase() !== "DEL"
        );
        var MaternoLiso3 = MaternoLiso2.filter(
            (item) => item.toUpperCase() !== "LAS"
        );
        var ItemMaternoX = MaternoLiso3.filter(
            (item) => item.toUpperCase() !== "LA"
        );

        if (ItemMaternoX.length > 0) {
            const tmpMaterno = ItemMaternoX[0]
            .replace(/[aeiou]/gi, "")
            .substring(0, 2);
            if (tmpMaterno.substring(0, 1) !== ItemMaternoX[0].substring(0, 1))
            nMaterno =
                ItemMaternoX[0].substring(0, 1) + tmpMaterno.substring(0, 1);
            else
            nMaterno = ItemMaternoX[0].replace(/[aeiou]/gi, "").substring(0, 2);
        } else {
            nMaterno = "";
        }

        //nMaterno = MaternoLiso[0].replace(/[aeiou]/ig,'').substring(0,2)
        }

        var ItemNombre = Nombres.replace(/^\s+|\s+$/g, "")
        .replace(/[\W\d_]/g, "")
        .split(" ");

        if (ItemNombre.length === 1) {
        const tmpNombre = Nombres.replace(/[aeiou]/gi, "").substring(0, 2);
        if (tmpNombre.substring(0, 1) !== Nombres.substring(0, 1))
            nNombre = Nombres.substring(0, 1) + tmpNombre.substring(0, 1);
        else nNombre = Nombres.replace(/[aeiou]/gi, "").substring(0, 2);
        } else {
        if (trim(ItemNombre[0].replace(/[\W\d_]/g, "")).length === 1) {
            nNombre = ItemNombre[0].replace(/[\W\d_]/g, "").toUpperCase() + "X";
        } else if (trim(ItemNombre[0].replace(/[\W\d_]/g, "")).length === 2) {
            nNombre = ItemNombre[0].replace(/[\W\d_]/g, "");
        } else if (trim(ItemNombre[0].replace(/[\W\d_]/g, "")).length === 3) {
            nNombre =
            ItemNombre[0].replace(/[\W\d_]/g, "")[0] +
            eliminarVocales(ItemNombre[0].substring(1, 10)).substring(0, 1);
        } else {
            var NombreLiso = ItemNombre.filter(
            (item) => item.toUpperCase() !== "DE"
            );
            var NombreLiso2 = NombreLiso.filter(
            (item) => item.toUpperCase() !== "DEL"
            );
            var NombreLiso3 = NombreLiso2.filter(
            (item) => item.toUpperCase() !== "LAS"
            );
            var NombreLiso4 = NombreLiso3.filter(
            (item) => item.toUpperCase() !== "MARIA"
            );
            var NombreLiso5 = NombreLiso4.filter(
            (item) => item.toUpperCase() !== "JOSE"
            );
            var ItemNombreX = NombreLiso5.filter(
            (item) => item.toUpperCase() !== "LA"
            );

            if (ItemNombreX.length > 0) {
            const tmpNombre = ItemNombreX[0]
                .replace(/[aeiou]/gi, "")
                .substring(0, 2);
            if (tmpNombre.substring(0, 1) !== ItemNombreX[0].substring(0, 1))
                nNombre =
                ItemNombreX[0].substring(0, 1) + tmpNombre.substring(0, 1);
            else
                nNombre = ItemNombreX[0].replace(/[aeiou]/gi, "").substring(0, 2);
            } else {
            nNombre = "";
            }
        }
        }

        var ItemPaterno = Paterno.split(" ");

        if (ItemPaterno.length === 1) {
        const tmpPaterno = Paterno.replace(/[aeiou]/gi, "").substring(0, 2);
        if (tmpPaterno.substring(0, 1) !== Paterno.substring(0, 1))
            nPaterno = Paterno.substring(0, 1) + tmpPaterno.substring(0, 1);
        else nPaterno = Paterno.replace(/[aeiou]/gi, "").substring(0, 2);
        } else {
        var PaternoLiso = ItemPaterno.filter(
            (item) => item.toUpperCase() !== "DE"
        );
        var PaternoLiso2 = PaternoLiso.filter(
            (item) => item.toUpperCase() !== "DEL"
        );
        var PaternoLiso3 = PaternoLiso2.filter(
            (item) => item.toUpperCase() !== "LAS"
        );
        var ItemPaternoX = PaternoLiso3.filter(
            (item) => item.toUpperCase() !== "LA"
        );

        if (ItemPaternoX.length > 0) {
            const tmpPaterno = ItemPaternoX[0]
            .replace(/[aeiou]/gi, "")
            .substring(0, 2);
            if (tmpPaterno.substring(0, 1) !== ItemPaternoX[0].substring(0, 1))
            nPaterno =
                ItemPaternoX[0].substring(0, 1) + tmpPaterno.substring(0, 1);
            else
            nPaterno = ItemPaternoX[0].replace(/[aeiou]/gi, "").substring(0, 2);
        } else {
            nPaterno = "";
        }
        }
        const FN = FechaNacimiento.replace(/[-]/gi, "").substring(2, 10);

        const nEdoNac =
        idEstadoNacimiento - 1 < 10
            ? "0" + (idEstadoNacimiento - 1)
            : idEstadoNacimiento - 1;
        const nSexo = idSexo === 2 ? "h" : idSexo === 3 ? "m" : "";

        if (ClaveElector.length < 18) {
        setisINEValida(false);
        notify.show("Error: ID Único requerido.", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (Nombres.length < 1) {
        setisINEValida(false);
        notify.show("Error: Nombre requerido.", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (Paterno.length < 1) {
        setisINEValida(false);
        notify.show("Error: Apellido Paterno requerido.", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (Materno.length < 1) {
        setisINEValida(false);
        notify.show("Error: Apellido Materno requerido.", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }


        if (FechaNacimiento.length < 10) {
        setisINEValida(false);
        notify.show("Error: Fecha Cumple requerido.", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (idSexo === 1) {
        setisINEValida(false);
        notify.show("Error: Sexo requerido.", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }

        if (idEstadoNacimiento === 1) {
        setisINEValida(false);
        notify.show("Error: Entidad Nacimiento requerido.", "error", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
        });
        return true;
        }







    
    };

    const alertaActualizacion = (
        <>
        <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="¿Está seguro que desea actualizar los datos?"
            onConfirm={() => btnSaveCompromiso()}
            onCancel={() => setflagUpdatePerfil(false)}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Si, actualizar!"
            cancelBtnText="Cancel"
            showCancel
        ></ReactBSAlert>
        </>
    );
    /*const handleVoluntario=(e)=>
    {
        setCompromiso({...compromiso, Voluntario: e })
                                
    }*/

    const handleEstadoNacimiento =(e)=>{
        var param = e;
        setCompromiso({...compromiso, EstadoNacimiento: param });
    
    }

    const handleColoniaVive =(e)=>{
        setCompromiso({...compromiso,ColoniaVive: e});
    
    }

    const handleColoniaVota =(e)=>{
        setCompromiso({...compromiso,ColoniaVota: e});
    
    }

    const handleLocalidadVive=(e)=>{
        setCompromiso({...compromiso, LocalidadVive: e});
    
    }
    
    const handleSexo=(e)=>{
        setCompromiso({ ...compromiso, Sexo: e });
    
    }

    const limpiaComboVive = () => {
        var datavacia = [{ value: "-1", label: "Seleccione..." }];
        setCompromiso({
        ...compromiso,
        LocalidadVive: datavacia,
        ColoniaVive: datavacia,
        });
        setCatLocalidad(datavacia);
        setCatColonia(datavacia);
    };

    const limpiaComboVota = () => {
        var datavacia = [{ value: "-1", label: "Seleccione..." }];
        setCompromiso({
        ...compromiso,
        LocalidadVota: datavacia,
        ColoniaVota: datavacia,
        });
        setCatLocalidad(datavacia);
        setCatColonia(datavacia);
    };
    
    const handleLocalidadMpioVive = (param)=>{
        if (param) {
        limpiaComboVive();
        const datos_localidades = {
            Municipio: param,
        };
        EjecutarApi(datos_localidades, `getLocalidad`, token.access_token, "POST")
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.result) {
                
                setCatLocalidad(res.data.localidades);
                setCatColonia(res.data.colonias);
            }
            })
            .catch((e) => {
            notify.show(`Error en la búsqueda Municipio Vive! ${e.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
            });
        }
        else{
        notify.show(`Error en la búsqueda Municipio Vive!`, "error", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
        });
        }
    }
    

    const handlemunicipioChangeVota = (e) => {
        var param = e;
        setCompromiso({...compromiso, MunicipioVota: e });
        setMunicipiovota(e.value);

        if (param) {
        limpiaComboVota();
        const datos_localidades = {
            Municipio: param.label,
        };
        EjecutarApi(datos_localidades, `getLocalidad`, token.access_token, "POST")
            .then(function (res) {
            
            return res;
            })
            .then((res) => {
            if (res.result) {
            
                setCatLocalidadVota(res.data.localidades);
                setCatColoniaVota(res.data.colonias);
            }
            })
            .catch((e) => {
            
            notify.show(`Error en la búsqueda de Municipio Vive! ${e.message}`, "error", 5000, {
                background: "#d61818",
                text: "#FFFFFF",
            });
            });
        }
        else {
        notify.show(`Error en la búsqueda de Municipio Vive! ${e.message}`, "error", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
        });
        }
    };

    return(
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <h5 className="title">Responsabilidad RGs</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup sm="6">
                                                <Label for="partido"><span className="text-danger">*</span>Distrito Federal:</Label> 
                                                <Select className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="partido"                                                        
                                                placeholder="Seleccione un Distrito"
                                                options={catDistrito}
                                                onChange = {(e) => setDistrito(e)}
                                            />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col md="4">
                                            <FormGroup sm="4">
                                            <Label for="propietario"><span className="text-danger">*</span>¿Has sido funcionario o representante de casilla?</Label>
                                                
                                                <FormGroup sm="4">
                                                    <div className="styleRadiodiv">
                                                            <label className="styleRadio">
                                                                <input
                                                                    defaultValue="1"
                                                                    name="RFuncionario"
                                                                    type="radio"
                                                                    onChange={()=>{setTieneExperiencia(1)}}
                                                                /> Si 
                                                            </label>
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="2"
                                                                name="RFuncionario"
                                                                type="radio"
                                                                onChange={(e)=>{setTieneExperiencia(0)}}
                                                                /> No
                                                            </label>
                                                    </div>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup sm="3">
                                                <Label for="rauto"><span className="text-danger">*</span>¿Tiene Auto?</Label>
                                                <FormGroup sm="3">
                                                    <div className="styleRadiodiv">
                                                            <label className="styleRadio">
                                                                <input
                                                                    defaultValue="1"
                                                                    name="rauto"
                                                                    type="radio"
                                                                    onChange={()=>{setTieneAuto(1)}}
                                                                /> Si 
                                                            </label>
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="2"
                                                                name="rauto"
                                                                type="radio"
                                                                onChange={(e)=>{setTieneAuto(0)}}
                                                                /> No
                                                            </label>
                                                    </div>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>  
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <div >
                                        <Row>
                                            <Card>
                                            <CardHeader>
                                                <h5 className="title">Datos Personales</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Form className="form-horizontal" id="TypeValidation">
                                                <Row>
                                                    {loading && (
                                                    <CircleSpinner
                                                        className="text-center"
                                                        size={30}
                                                        color="#686769"
                                                        loading={loading}
                                                    />
                                                    )}
                                                    <FormGroup className={"col-md-4"}>
                                                    <Label for="INE">
                                                        {" "}
                                                        <span className="text-danger">*</span>Clave Elector
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="INE"
                                                        value={compromiso.INE.toUpperCase()}
                                                        onChange={(event) => {
                                                        setCompromiso({
                                                            ...compromiso,
                                                            INE: event.target.value.toUpperCase(),
                                                        });
                                                        }}
                                                        maxLength="18"
                                                    />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-3">
                                                        <Label for="ORC"><span className="text-danger">*</span>OCR</Label>
                                                        <Input
                                                            type="text"
                                                            id="orc"
                                                            value={OCR}
                                                            onChange={(event) => setOCR(event.target.value)}
                                                            maxLength="18"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-3">
                                                        <Label for="ORC"><span className="text-danger">*</span>Vigencia Clave Elector:</Label>
                                                        <ReactDatetime
                                                            id="Fechacumple"
                                                            name="Fechacumple"
                                                            dateFormat="YYYY"
                                                            timeFormat={false}
                                                            closeOnSelect
                                                            value={VigenciaINE}
                                                            onChange={(e) =>
                                                                setVigenciaINE(moment(e._d).format("YYYY"))
                                                            }
                                                            inputProps={{ className: "form-control" }}
                                                        />
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup className={"col-md-4"}>
                                                    <Label for="Nombre">
                                                        <span className="text-danger">*</span>Nombre(s)
                                                    </Label>
                                                    <Input
                                                        id="Nombre"
                                                        value={compromiso.Nombre}
                                                        type="text"
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            Nombre: e.target.value.toUpperCase(),
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>

                                                    <FormGroup className={"col-md-4"}>
                                                    <Label for="inputApellido_1">
                                                        <span className="text-danger">*</span>Apellido Paterno
                                                    </Label>
                                                    <Input
                                                        id="Paterno"
                                                        value={compromiso.Paterno}
                                                        type="text"
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            Paterno: e.target.value.toUpperCase(),
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>

                                                    <FormGroup className={"col-md-4"}>
                                                    <Label for="Materno">
                                                        <span className="text-danger">*</span>Apellido Materno
                                                    </Label>
                                                    <Input
                                                        id="Materno"
                                                        value={compromiso.Materno}
                                                        type="text"
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            Materno: e.target.value.toUpperCase(),
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup className={"col-md-4"}>
                                                    <Label for="Fechacumple">
                                                        <span className="text-danger">*</span>Fecha de
                                                        nacimiento
                                                    </Label>
                                                    <ReactDatetime
                                                        id="Fechacumple"
                                                        name="Fechacumple"
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        closeOnSelect
                                                        value={compromiso.FechaNacimiento}
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            FechaNacimiento: moment(e._d).format("YYYY-MM-DD"),
                                                        })
                                                        }
                                                        inputProps={{ className: "form-control" }}
                                                    />
                                                    </FormGroup>
                                                    <FormGroup className={"col-md-4"}>
                                                    <Label for="Sexo">
                                                        <span className="text-danger">*</span>Sexo
                                                    </Label>
                                                    <Select
                                                        options={catSexo}
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        value={compromiso.Sexo}
                                                        onChange={(e) =>handleSexo(e) }
                                                        id="Sexo"
                                                        placeholder="Sexo"
                                                    />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-4">
                                                    <Label for="EntidadNacimiento">
                                                        <span className="text-danger">*</span>Estado de
                                                        nacimiento
                                                    </Label>
                                                    <Select
                                                        options={catEntidad}
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        id="EntidadNacimiento"
                                                        value={compromiso.EstadoNacimiento}
                                                        onChange={(e) => handleEstadoNacimiento(e)}
                                                        
                                                        placeholder="Entidad"
                                                    />
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup className="col-md-12 text-center text-warning">
                                                    <strong>Capture dirección donde vive</strong>
                                                    <br />
                                                    </FormGroup>

                                                    <FormGroup className="col-md-4">
                                                    <Label for="CalleVive">
                                                        <span className="text-danger">*</span>Calle
                                                    </Label>
                                                    <Input
                                                        id="CalleVive"
                                                        value={compromiso.CalleVive}
                                                        type="text"
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            CalleVive: e.target.value,
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>

                                                    <FormGroup className="col-md-2">
                                                    <Label for="NumExtVive">
                                                        <span className="text-danger">*</span>Número Ext.
                                                    </Label>
                                                    <Input
                                                        id="NumExtVive"
                                                        value={compromiso.NumExtVive}
                                                        type="text"
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            NumExtVive: e.target.value,
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>

                                                    <FormGroup className="col-md-2">
                                                    <Label for="NumIntVive">Número Int.</Label>
                                                    <Input
                                                        id="NumIntVive"
                                                        value={compromiso.NumIntVive}
                                                        type="text"
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            NumIntVive: e.target.value,
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-2">
                                                    <Label for="CPVive">
                                                        <span className="text-danger">*</span>CP
                                                    </Label>
                                                    <Input
                                                        id="CPVive"
                                                        value={compromiso.CPVive}
                                                        type="text"
                                                        maxLength={5}
                                                        onChange={(event) => {
                                                        setCompromiso({
                                                            ...compromiso,
                                                            CPVive: event.target.value.replace(/\D/, ""),
                                                        });
                                                        }}
                                                    />
                                                    </FormGroup>
                                                    <Button onClick={() => searchCP()} color="primary">
                                                    Buscar{" "}
                                                    </Button>
                                                    <FormGroup className="col-md-4">
                                                    <Label for="MunicipioVive">
                                                        <span className="text-danger">*</span>Municipio Vive
                                                    </Label>
                                                    <Select
                                                        options={catMunicipio}
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        id="MunicipioVive"
                                                        defaultValue={{value:compromiso.MunicipioVive.value, label:compromiso.MunicipioVive.label}}
                                                        onChange={(e) => {setCompromiso({...compromiso, MunicipioVive: e });
                                                        handleLocalidadMpioVive(e.label); setMunicipiovive(e.value); }}
                                                        placeholder="Municipio Vive"
                                                    />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-4">
                                                    <Label for="LocalidadVive">
                                                        Localidad:<span className="text-danger">*</span>
                                                    </Label>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        options={catLocalidad}
                                                        id="Localidad"
                                                        onChange={(event) => {handleLocalidadVive(event)}}
                                                        placeholder="seleccione su localidad"
                                                    />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-3">
                                                    <Label for="ColoniaVive">
                                                        <span className="text-danger">*</span>Colonia
                                                    </Label>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        options={catColonias}
                                                        value={compromiso.ColoniaVive}
                                                        id="coloniavive"
                                                        onChange={(event) => {handleColoniaVive(event)}}
                                                        placeholder="seleccione su colonia"
                                                    />
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup className="col-md-2">

                                                    <Label for="SeccionVive">
                                                        <span className="text-danger">*</span>Sección Vota
                                                    </Label>
                                                    <Input
                                                        id="SeccionVive"
                                                        value={compromiso.SeccionVota}
                                                        pattern="[0-9]*"
                                                        maxLength={5}
                                                        type="text"
                                                        onChange={(event) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            SeccionVota: event.target.value.replace(/\D/, ""),
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-3">
                                                    <Label for="Tel">
                                                        <span className="text-danger">*</span>Teléfono celular
                                                    </Label>
                                                    <Input
                                                        id="Tel"
                                                        value={compromiso.Celular}
                                                        pattern="[0-9]*"
                                                        maxLength={10}
                                                        type="text"
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            Celular: e.target.value.replace(/\D/, ""),
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>

                                                    <FormGroup className="col-md-3">
                                                    <Label for="Celular">Teléfono fijo</Label>
                                                    <Input
                                                        id="Celular"
                                                        value={compromiso.Telefono}
                                                        pattern="[0-9]*"
                                                        maxLength={10}
                                                        type="text"
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            Telefono: e.target.value.replace(/\D/, ""),
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>

                                                    <FormGroup className="col-md-3">
                                                    <Label for="Celular_1">Teléfono mensajes</Label>
                                                    <Input
                                                        id="TelMSG"
                                                        value={compromiso.TelMensajes}
                                                        pattern="[0-9]*"
                                                        maxLength={10}
                                                        type="text"
                                                        onChange={(e) =>
                                                        setCompromiso({
                                                            ...compromiso,
                                                            TelMensajes: e.target.value.replace(/\D/, ""),
                                                        })
                                                        }
                                                    />
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup check className="col-md-3 text-center">
                                                    <Label check>
                                                        <Input
                                                        type="checkbox"
                                                        defaultChecked={compromiso.isVoluntario}
                                                        disabled
                                                        onChange={() =>
                                                            setCompromiso({
                                                            ...compromiso,
                                                            isVoluntario: !compromiso.isVoluntario,
                                                            })
                                                        }
                                                        />{" "}
                                                        Desea ser voluntario
                                                        <span className="form-check-sign  text-center">
                                                        <span className="check"></span>
                                                        </span>
                                                    </Label>
                                                    </FormGroup>
                                                </Row>

                                                {compromiso.isVoluntario && (
                                                    <div>
                                                    <Row>
                                                        <FormGroup className="col-md-3  text-center">
                                                        <Label for="Voluntario">Voluntario para</Label>
                                                        <Input
                                                            //options={catVoluntario}
                                                            //className="react-select primary"
                                                            //classNamePrefix="react-select"
                                                            id="Voluntario"
                                                            value={compromiso.Voluntario.label}
                                                            //onChange={(e) => handleVoluntario(e)}
                                                            disabled
                                                        />
                                                        </FormGroup>
                                                        {isVisible?<>
                                                        <FormGroup className="col-md-2">
                                                        <Label for="SeccionVota">
                                                            <span className="text-danger">*</span>Sección vota
                                                        </Label>
                                                        <Input
                                                            id="SeccionVota"
                                                            value={compromiso.SeccionVota}
                                                            pattern="[0-9]*"
                                                            maxLength={5}
                                                            type="text"
                                                            onChange={(event) =>
                                                            setCompromiso({
                                                                ...compromiso,
                                                                SeccionVota: event.target.value.replace(
                                                                /\D/,
                                                                ""
                                                                ),
                                                            })
                                                            }
                                                        />
                                                        </FormGroup></>:<></>}
                                                    </Row>
                                                    </div>
                                                )}
                                                <Row>
                                                    <FormGroup className="col-md-12 text-center">
                                                    <label>
                                                        ¿La dirección de INE y donde vive son la misma?
                                                    </label>
                                                    <br />
                                                    <div className="form-check-radio form-check-inline">
                                                        <Label check>
                                                        <Input
                                                            type="radio"
                                                            name="exampleRadios1"
                                                            id="exampleRadios11"
                                                            value="option1"
                                                            defaultChecked={
                                                            compromiso.MismaAddress ? true : false
                                                            }
                                                            onChange={() =>
                                                            setCompromiso({
                                                                ...compromiso,
                                                                MismaAddress: true,
                                                            })
                                                            }
                                                        />
                                                        Si {compromiso.MismaAddress}
                                                        <span className="form-check-sign"></span>
                                                        </Label>
                                                    </div>
                                                    <div className="form-check-radio form-check-inline">
                                                        <Label check>
                                                        <Input
                                                            type="radio"
                                                            name="exampleRadios1"
                                                            id="exampleRadios12"
                                                            value="option2"
                                                            defaultChecked={
                                                            !compromiso.MismaAddress ? true : false
                                                            }
                                                            onChange={() =>
                                                            setCompromiso({
                                                                ...compromiso,
                                                                MismaAddress: false,
                                                            })
                                                            }
                                                        />
                                                        No
                                                        <span className="form-check-sign"></span>
                                                        </Label>
                                                    </div>
                                                    </FormGroup>
                                                </Row>

                                                {!compromiso.MismaAddress && (
                                                    <div>
                                                    <Row>
                                                        <FormGroup className="col-md-12 text-center text-info">
                                                        <strong>
                                                            Capture información de la credencial INE
                                                        </strong>
                                                        <br />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup className="col-md-4">
                                                        <Label for="CalleINE">
                                                            <span className="text-danger">*</span>Calle
                                                        </Label>
                                                        <Input
                                                            id="CalleINE"
                                                            value={compromiso.CalleVota}
                                                            type="text"
                                                            onChange={(e) =>
                                                            setCompromiso({
                                                                ...compromiso,
                                                                CalleVota: e.target.value,
                                                            })
                                                            }
                                                        />
                                                        </FormGroup>

                                                        <FormGroup className="col-md-2">
                                                        <Label for="NumExtINE">
                                                            <span className="text-danger">*</span>Número Ext.
                                                        </Label>
                                                        <Input
                                                            id="NumExtINE"
                                                            value={compromiso.NumExtVota}
                                                            type="text"
                                                            onChange={(e) =>
                                                            setCompromiso({
                                                                ...compromiso,
                                                                NumExtVota: e.target.value,
                                                            })
                                                            }
                                                        />
                                                        </FormGroup>

                                                        <FormGroup className="col-md-2">
                                                        <Label for="NumIntINE">Número Int.</Label>
                                                        <Input
                                                            id="NumIntINE"
                                                            value={compromiso.NumIntVota}
                                                            type="text"
                                                            onChange={(e) =>
                                                            setCompromiso({
                                                                ...compromiso,
                                                                NumIntVota: e.target.value,
                                                            })
                                                            }
                                                        />
                                                        </FormGroup>
                                                        <FormGroup className="col-md-2">
                                                        <Label for="CPINE">
                                                            <span className="text-danger">*</span>CP
                                                        </Label>
                                                        <Input
                                                            id="CPINE"
                                                            value={compromiso.CPVota}
                                                            type="text"
                                                            maxLength={5}
                                                            onChange={(event) => {
                                                            setCompromiso({
                                                                ...compromiso,
                                                                CPVota: event.target.value.replace(/\D/, ""),
                                                            });
                                                            }}
                                                        />
                                                        </FormGroup>
                                                        <Button onClick={() => searchCP()} color="primary">
                                                        Buscar{" "}
                                                        </Button>
                                                        <FormGroup className="col-md-4">
                                                        <Label for="MunicipioVota">
                                                            <span className="text-danger">*</span>Municipio Vota
                                                        </Label>
                                                        <Select
                                                            options={catMunicipioVota}
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            id="MunicipioVota"
                                                            name="MunicipioVota"
                                                            defaultValue={{value:compromiso.MunicipioVota.value, label:compromiso.MunicipioVota.label}}
                                                            onChange={(e) => {handlemunicipioChangeVota(e);  }}
                                                            placeholder="Municipio Vota"

                                                        />
                                                        </FormGroup>
                                                        <FormGroup className="col-md-4">
                                                        <Label for="LocalidadINE">
                                                            Localidad:<span className="text-danger">*</span>
                                                        </Label>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            options={catLocalidadVota}
                                                            id="LocalidadINE"
                                                            onChange={(event) => {
                                                            setCompromiso({
                                                                ...compromiso,
                                                                LocalidadVota: event,
                                                            });
                                                            }}
                                                            placeholder="seleccione su localidad"
                                                        />
                                                        </FormGroup>
                                                        <FormGroup className="col-md-3">
                                                        <Label for="ColoniaINE">
                                                            <span className="text-danger">*</span>Colonia
                                                        </Label>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            options={catColoniasVota}
                                                            id="colonia"
                                                            onChange={(event) => {handleColoniaVota(event.label)}}
                                                            placeholder="seleccione su colonia"
                                                        />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup className="col-md-3 text-center">
                                                        <Label for="seccionVota">
                                                            <span className="text-danger">*</span>Sección Vota
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            value={compromiso.SeccionVota}
                                                            onChange={(e) =>
                                                            setCompromiso({
                                                                ...compromiso,
                                                                SeccionVota: e.target.value,
                                                            })
                                                            }
                                                        />{" "}
                                                        </FormGroup>
                                                    </Row>
                                                    </div>
                                                )}
                                                {isVisible?<><div>
                                                    <Row>
                                                    <FormGroup className="col-md-12 text-center text-info">
                                                        <strong>Información Adicional</strong>
                                                        <br />
                                                    </FormGroup>
                                                    </Row>
                                                    <Row>
                                                    <FormGroup className="col-md-5">
                                                        <Label>Nivel de Estudios:</Label>
                                                        <Select
                                                        id="nivel"
                                                        options={catNivelEstudios}
                                                        value={compromiso.NivelEstudios}
                                                        type="text"
                                                        onChange={(e) =>
                                                            setCompromiso({
                                                            ...compromiso,
                                                            NivelEstudios: e,
                                                            })
                                                        }
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-5">
                                                        <Label>Titulo Obtenido:</Label>
                                                        <Input
                                                        id="nivel"
                                                        value={compromiso.TituloObtenido}
                                                        type="text"
                                                        onChange={(e) =>
                                                            setCompromiso({
                                                            ...compromiso,
                                                            TituloObtenido: e.target.value,
                                                            })
                                                        }
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-5">
                                                        <Label>Situacion Laboral:</Label>
                                                        <Select
                                                        id="nivel"
                                                        options={catSituacionLaboral}
                                                        value={compromiso.SituacionLaboral}
                                                        type="text"
                                                        onChange={(e) =>
                                                            setCompromiso({
                                                            ...compromiso,
                                                            SituacionLaboral: e,
                                                            })
                                                        }
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="col-md-5">
                                                        <Label>Detalle Laboral:</Label>
                                                        <Input
                                                        id="detalle"
                                                        value={compromiso.EmpresaLabora}
                                                        type="text"
                                                        onChange={(e) =>
                                                            setCompromiso({
                                                            ...compromiso,
                                                            EmpresaLabora: e.target.value,
                                                            })
                                                        }
                                                        />
                                                    </FormGroup>
                                                    </Row>
                                                
                                                </div></>:<></>}
                                                </Form>
                                                {flagUpdatePerfil ? alertaActualizacion : <></>}
                                            </CardBody>
                                            <CardFooter>
                                                <Row>
                                                <FormGroup className="col-md-12 pr-2 text-center">
                                                    <Button
                                                    color="danger"
                                                    onClick={() => clearForm()}
                                                    >
                                                    Limpiar
                                                    </Button>{" "}
                                                    {isCompromisoSave?(<></>):(
                                                    <Button color="primary" onClick={() => btnSaveCompromiso()}>
                                                    {<RotateSpinner color={"#1261ff"}size={30} loading={loading}/>} 
                                                    Guardar
                                                    </Button>)}
                                                </FormGroup>
                                                </Row>
                                            </CardFooter>
                                            </Card>
                                        </Row>
                                        </div>
       
                                </CardBody>
                            </Card>
                        </Col> 
                    </Row>
                </div>
                <Footer fluid />
            </div>
        </div>
    );
}
export default ElectoralRGs;