import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";
import { RotateSpinner } from "react-spinners-kit";
import ReactTable from 'react-table';
import PerfectScrollbar from "perfect-scrollbar";

import { getVars, URL_PUBLICA } from "GlobalStorage";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from "data/menus";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
import ModalFiltro from 'components/Filtro/indexCasaAzul';

var ps;

function ElectoralCasaAzul(props) {
	const mainPanel = useRef();
	let history = useHistory();
	const token = getVars("Token");
	const [_menu, _setmenu] = useState({});
	const [loading, setLoading] = useState(false);
	const [TotalRegistros, setTotalRegistros] = useState(0);
	const [Registros, setRegistros] = useState([]);
	const [Filtro, setFiltro] = useState([]);
	const [MenuModal] = useState({
		Compromiso: false,
		Voluntarios: false,
		RCs: false,
		RGs: false,
		Abogado: false,
		CasaAzul: true,
		Municipio: false
	});

	useEffect(() => {

		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current);
			document.body.classList.toggle("perfect-scrollbar-on");
		}

		if (props.history.action === "PUSH") {
			mainPanel.current.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
		}

		let menu = getDataMenu(33, token.menus);
		if (Object.keys(menu).length > 0) {
			_setmenu(menu);
		} else {
			//El usuario no tiene el menú asignado 
		}

	}, [])

	const handleMiniClick = () => {
		document.body.classList.toggle("sidebar-mini");
	};

	const handleBtnSetResposabilidad = () => {
		history.push("/bunkers_cap");
	};

	const Exportar = () => {
		var url = new URL(URL_PUBLICA +
			`getReporteCasaAmiga?token=${token.access_token}`
		);
		window.open(url, "_blank");
	}

	const handleFiltro = (filter) => {
		var _filtro = [];

		if (filter.Region.value > 0) {
			_filtro.push({ id: 's2.idRegion', value: filter.Region.value })
		}
		if (filter.Municipio.value > 0) {
			_filtro.push({ id: 's2.idMunicipioReportes', value: filter.Municipio.value })
		}
		if (filter.Poligono.value > 0) {
			_filtro.push({ id: "s2.idPoligono", value: filter.Poligono.value })
		}
		if (filter.Seccion.value > 0) {
			_filtro.push({ id: "s2.Seccion", value: filter.Seccion.value })
		}
		if (filter.Direccion.length > 0) {
			_filtro.push({ id: "concat_ws(' ',rcs.Colonia,rcs.Calle,rcs.NumExt)", value: filter.Direccion })
		}
		if (filter.Colonia.length > 0) {
			_filtro.push({ id: "rcs.Colonia", value: filter.Colonia })
		}
		if (filter.Dueno.length > 0) {
			_filtro.push({ id: "rcs.NombreDueno", value: filter.Dueno })
		}
		if (filter.CelularDueno.length > 0) {
			_filtro.push({ id: "rcs.CelularDueno", value: filter.CelularDueno })
		}
		if (filter.Internet > 0) {
			_filtro.push({ id: "rcs.TieneInter", value: filter.Internet })
		}

		setFiltro(_filtro);

		const data = {
			page: 0,
			pageSize: 10,
			filtered: _filtro,
			idResponsabilidad: 15,
			tipo: 'and'
		};

		getRegistros(data);
	}

	const columns = [
		{
			Header: "Municipio",
			minWidth: 110,
			id: "Municipio",
			accessor: "Municipio",
			filterAll: false,
		},
		{
			Header: "Zona",
			minWidth: 40,
			id: "Poligono",
			accessor: "Poligono",
			filterAll: false,
		},
		{
			Header: "Sección",
			minWidth: 40,
			id: "Seccion",
			accessor: "Seccion",
			filterAll: false,
		},
		{
			Header: "Responsable",
			minWidth: 100,
			id: "Responsable",
			accessor: "Responsable",
			filterAll: false,
		},
		{
			Header: "Teléfono Contacto",
			accessor: "Telefono",
			minWidth: 100,
			id: "Telefono",
			filterable: false,
		},
		{
			Header: "Dirección",
			accessor: "Direccion",
			minWidth: 100,
			id: "Direccion",
			filterable: false,
		},
		{
			Header: "Colonia",
			accessor: "Colonia",
			minWidth: 100,
			id: "Colonia",
			filterable: false,
		},
	];

	const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
		const data = {
			page: page,
			pageSize: pageSize,
			filtered: params,
			sorted: sorted,
			idResponsabilidad: 15,
			tipo: "and"
		};
		getRegistros(data);
	}, []);

	const getRegistros = (data) => {
		setLoading(true);
		EjecutarApi(data, "getCompromisosResponsabilidadPropuesta", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				setLoading(false);
				setTotalRegistros(res.total);
				setRegistros(
					res.data.map((item) => {
						return {
							id: item.id,
							Region: item.Region.label,
							Municipio: item.Municipio.label,
							Poligono: item.Poligono.label,
							Seccion: item.Seccion,
							Responsable: item.ResponsablePropuesta,
							FechaPropuesta: item.FechaAsignoResponsabilidad,
							Telefono: item.TelefonoContacto,
							Colonia: item.Colonia,
							Direccion: item.Direccion
						};
					})
				);
			})
			.catch((e) => {
				setLoading(false);
				notify.show("Error al cargar registros", "error", 5000);
			});
	}
	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel ps ps--active-y" ref={mainPanel}>
				<Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
				<div className="content">
					<Row>
						<Col sm="12">
							<Card>
								<CardHeader>
									<h5 className="title">Bunkers</h5>
									<Col sm="12" md="12" lg="12">
										<Row>
											{/*_menu.Agregar === 1 ? (<><Button color="success" size="sm" onClick={() => handleBtnSetResposabilidad()} >
												<span className="btn-label">
													<i className="fa fa-user-plus fa-lg" />
												</span>
												Capturar
											</Button></>) : <></>*/}
											{_menu.Exportar === 1 ? (
												<Button size='sm' className='primary left-align' color='primary' onClick={() => Exportar()}>
													<span className="btn-label">
														<i className="fa fa-file-excel-o fa-lg" />
													</span>
													Exportar
											</Button>
											) : <></>}
										</Row>
										<Row>
											<Col sm="12">
												<ModalFiltro Menu={MenuModal} Datos={(e) => handleFiltro(e)} />
											</Col>
										</Row>
									</Col>
								</CardHeader>
								<CardBody>
									{<RotateSpinner color={"#1261ff"} size={30} loading={loading} />}
									<div style={{ 'width': '100%', 'overflow': 'auto' }}>
										<ReactTable
											style={{
												width: "100%",
												height: "100%"
											}}
											size='sm'
											data={Registros}
											noDataText="Sin información"
											manual
											columns={columns}
											className="-striped -highlight primary-pagination"
											loading={loading}
											showPagination={true}
											showPaginationBottom={true}
											showTotalPages={true}
											showPageJump={false}
											canNextFromData={true}
											defaultPageSize={10}
											total={TotalRegistros}
											pages={Math.floor(TotalRegistros / 10)}
											previousText="Anterior"
											nextText="Siguiente"
											ofText="de"
											pageSizeOptions={[10, 20, 25, 50, 100]}
											PaginationComponent={Pagination}
											onFetchData={fetchData}
											params={Filtro}
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

				</div>
				<Footer fluid />
			</div>
		</div>
	);
}
export default ElectoralCasaAzul;