import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const CompromisoSegmentoChart = (props) => {
   
  let serie = props.data.data
  let categoria = props.data.categories
  const options = {
    chart: {
      type: "spline"
    },
    title: {
      text: ""
    },
    xAxis: {
        categories: categoria
      },
    series: [
      {
        data: serie
      }
    ]
  };


  return (
      <HighchartsReact
       highcharts={Highcharts}
       options={options}
     />
  )
}

export default CompromisoSegmentoChart
