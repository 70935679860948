import React, { useState, useEffect } from 'react';
import {
    InputGroup, InputGroupAddon,
    InputGroupText, Row, Col, Input,
    CardBody, Card
} from 'reactstrap';
import { notify } from "react-notify-toast";
import { RotateSpinner } from "react-spinners-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import EjecutarApi from "../../data/getPOST";
import { getVars } from "../../GlobalStorage";

const TreeEnlaces = (props) => {
    const { estructura } = props;
    const [loading, setLoading] = useState(true);
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    const [HasMore, setHasMore] = useState(true);
    const [nextFetch, setnextFetch] = useState(1);
    const [responsable, setResponsable] = useState();
    const [DatosResponsable, setDatosResponsable] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setLoading(true);
        setDatosResponsable([]);
        setTotal(0);
        var parametro = {
            idEstructura: [estructura],
            page: nextFetch,
            pageSize: 10
        };
        EjecutarApi(parametro, 'getEnlacesSocialStructuresWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(res.data.Enlaces);
                    setnextFetch(1);
                    setTotal(res.data.TotalEnlaces);
                    if (res.data.Enlaces.length === 0) {
                        setHasMore(false);
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de enlaces: ${e.error}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }, [estructura])


    const handleBuscar = () => {
        setLoading(true);
        setDatosResponsable([]);
        setTotal(0);
        var parametro = {
            idEstructura: [estructura],
            page: 1,
            pageSize: 10,
            NombreCompleto: responsable
        };
        EjecutarApi(parametro, 'getEnlacesSocialStructuresWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(res.data.Enlaces);
                    setnextFetch(1);
                    setTotal(res.data.TotalEnlaces);
                    if (res.data.Enlaces.length === 0) {
                        setHasMore(false);
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de enlaces: ${e.error}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    };


    const fetchHasMore = () => {
        setLoading(true);
        if (nextFetch * 10 >= total) {
            setLoading(false);
            setHasMore(false);
            return;
        }

        var parametro = {
            idEstructura: [estructura],
            page: nextFetch,
            pageSize: 10,
            NombreCompleto: responsable
        };
        EjecutarApi(parametro, 'getEnlacesSocialStructuresWeb', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setDatosResponsable(DatosResponsable.concat(res.data.Enlaces));
                    setnextFetch(nextFetch + 1);
                    setHasMore(false);
                    setTotal(res.data.TotalEnlaces);
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de enlaces: ${e.error}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    };

    return (
        <>
            <Col md="12">
                <Row>
                    <Col sm="12">
                        <InputGroup className="no-border">
                            <Input value={responsable} onChange={(e) => setResponsable(e.target.value)} placeholder="Buscar..." type="text" />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <i className="nc-icon nc-zoom-split" onClick={() => handleBuscar()} />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>{(<>
                    <Col sm="12">
                        <RotateSpinner color={"#1261ff"} size={30} loading={loading} />
                        <InfiniteScroll
                            dataLength={DatosResponsable.length}
                            next={fetchHasMore}
                            hasMore={HasMore}
                            loader={DatosResponsable.length === 0 && !loading ? <h5>No hay Datos</h5> : <h5>cargando...</h5>}
                            height={500}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>¡Final de la lista de registros!</b>
                                </p>}
                        >
                            {DatosResponsable.map((item, key) =>

                                <div key={'key' + key.toString()} className="divv text-muted" /* onClick={() => DetalleUsuario(item)} */ >
                                    <Card>
                                        <CardBody className="card-body">
                                            <Row>
                                                <Col sm="12" className='text-center'>
                                                    <strong>{`${item.Nombre} ${item.Paterno} ${item.Materno}`}</strong>
                                                    <p className="card-category">
                                                        {item.Responsabilidad}
                                                    </p>
                                                </Col>
                                                <hr />
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <hr />
                                </div>
                            )}
                        </InfiniteScroll>
                    </Col>
                </>)}
                </Row>
                <Row>
                    <Col sm="12">
                        Enlaces : {DatosResponsable.length} de {total}
                    </Col>
                </Row>
            </Col>
        </>
    );
};
export default TreeEnlaces;