import React, { useState,useEffect } from 'react';
import { InputGroup,InputGroupAddon,InputGroupText,Row,Col, Input, Button, Label, Badge, CardBody,Card, CardTitle, CardFooter} from 'reactstrap';
import { notify } from "react-notify-toast";
import {RotateSpinner} from "react-spinners-kit";
import EjecutarApi from "../../data/getPOST";
import { getVars} from "../../GlobalStorage";
import ModalSocialAdd from './SocialAdd';
import ModalMensaje from './MensajeModal';
import {getDataMenu} from '../../data/menus.js';

const Estructuras = ({Data,onHide}) => {
    const [loading, setLoading] = useState(false);
    const [_menu, _setmenu] = useState({});
    const token = getVars("Token");
    const [catSubEstructuras,setCatSubEstructuras]=useState([]);
   
    const [Estructura,setEstructura]=useState({
        idEstructura:{value:Data.id,label:''},
        Codigo:'',
        idRegion:{},
        idMunicipio:{},
        idResponsabilidad:{},
        Responsable:'',
        idResponsable:0
    })
 
    const [NombreEstructura,setNombreEstructura] = useState(Data.NombreEstructura);//token.user.EstructuraSocial.label;
    const [NickName] = useState(Data.nickName);//token.user.EstructuraSocial.label;
    const [idSubEstructura,setIdSubEstructura]= useState(Data.id);
    var ubicaciónResponsabilidad = Data.id;//(token.user.EstructuraSocial.value === 1)?0:token.user.EstructuraSocial.value;
    const [segmentos, setSegmentos] =useState([ubicaciónResponsabilidad]);
    const [segmentosSelected, setSegmentosSelected] =useState(ubicaciónResponsabilidad);
    const [Editar, setEditar] = useState({
        NombreSocial: '',
       
      });
    const [isModalSocialAdd, setModalSocialAdd] = useState(false);
    const [isModalEliminarEstructura, setModalEliminarEstructura] = useState(false);
    const [Message, setMessage] = useState(false);
    
    useEffect(()=>{
        _setmenu(getDataMenu(23,token.menus));
        handleBuscar();
    },[])

    const handleBuscar =()=>{
        setLoading(true);
        var parametro={
            page:0, 
            pageSize:10,
            filtered:[{"id":"parentId","value":Data.id}],
            tipo:"and",
            sorted:[ {"id":"LastUpdate","desc":true}]
        };
        const data={
            "idEstructura":ubicaciónResponsabilidad,
            }
            let estructurasFormated =[];
        EjecutarApi(data, 'getSubestructuraSocialWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
                
                if (res.data) {
                    estructurasFormated = res.data.map(item => {
                      var dato=0
                      var Color="#00000"
                      var icon ="fa fa"
                      var flag = 0
                      res.data.forEach(element => {
                        if(element.parentId.value === item.id){
                         dato++
                        }
                        else{}
                      })
                      if(dato !== 0){
                        Color="#36BADA"
                        icon ="fa fa-plus"
                        flag =1
                      }
                      else{ Color="#13728E"
                      icon =""
                      flag= 0
                    }
                        return {
                          value: item.id,
                          label: item.nickName,
                          paredId: item.parentId.value,
                          color: Color,
                          icon : icon,
                          activo: flag
                        }
                      })
                    setCatSubEstructuras(estructurasFormated)
                    setLoading(false)
                  } else {
                    showMessage("No Info: catCompromisosWeb")
                    setLoading(false)
                  }
                
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
        
        //Listado de Usuarios FullSearch
        setLoading(true);
        parametro={
            page:0, 
            pageSize:10,
            filtered:[],
            tipo:"and",
            sorted:[ {"id":"LastUpdate","desc":true}]
        }
        
        setLoading(true);
        EjecutarApi({}, 'getCatalogosPorResponsabilidadSocialWeb', token.access_token)
			.then(function(res) {
				return res;
			})
			.then((res) => {
                if(res.success)
                {
                    let arr=[{value:0,label:'Seleccionar...'}];
                    res.data.forEach(item=>{
                        
                            arr.push(item)
                       
                    })
                    
                    setLoading(false);
                }
                
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'custom', 5000, {
					background: '#0857B6',
					fontSize: 14,
					text: '#FFFFFF'
				});
			});
    
    }
    const showMessage = (message) => {
        notify.show(message, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
    }
  

    /*const Buscar=()=>{
        var parametro={
            page:0, 
            pageSize:10,
            filtered:[{id:"FullSearch",value:Estructura.Responsable}],
            tipo:"and",
            sorted:[{"id":"LastUpdate","desc":true}]
        };
        EjecutarApi(parametro, 'getUsersEtiquetasWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                setLoading(false);
                setTotal(res.total)
            }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }*/
    const EliminarResponsabilidad=(idUser,Accion)=>{
        var json = {};
        var parametro={
            idUser:idUser
        };
        EjecutarApi(parametro, 'eliminarResponsablesWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
            if (res.results) {
                json = res
            }
            else{
                notify.show(`Error: No se puede eliminar la responsabilidad`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
        return json;
    }
 
    const segmento = (item) =>{
        setNombreEstructura (item.label)
        setIdSubEstructura(item.value)
        setSegmentosSelected(item.value)
        setSegmentos([...segmentos,item.value])
        const data={
        'estructura':[item.value]
        }
        
    }
    const AtrasSegmento = () =>{
       
        let ultimo=0
        if(segmentos.length > 1 ){
            ultimo=segmentos.pop()
            
            setSegmentosSelected(segmentos[segmentos.length-1])
            const data={
            'estructura':[segmentos[segmentos.length-1]]
            }
            let obj = catSubEstructuras.filter(data => data.value === segmentos[0])
            setNombreEstructura(obj[0].label)
        }
      else{
        ultimo=segmentos[segmentos.length-1]
        const data={
          'estructura': [ultimo]
        }
       
      }
    
    }
    const handleAgregar = () => {
        setEditar({ id:idSubEstructura, Tipo: 'Add', Data:{NombreEstructura:NombreEstructura,nickName:NickName}});
        setModalSocialAdd(true);
    };
    const onHideEnlace = () => {
        setModalSocialAdd(false)
        setModalEliminarEstructura(false);
        handleBuscar()
    }
    var resultado={}
    const eliminarEstructura = (idEstructur,delet)=>{
        //eliminarEstructuraSocialWeb eliminarEstructuraSocialWeb
        resultado={ delete: 0,
            message: 0,
            results: 0,
            success: 0};
        var parametro ={
            idEstructura:idEstructur,
            delete:delet
        }
        EjecutarApi(parametro, 'eliminarEstructuraSocialWeb', token.access_token)
            .then(function (res) {
            return res;
            })
            .then((res) => {
                resultado={
                    delete: res.delete,
                    message: res.message,
                    results: res.results,
                    success: res.success
                }
                return resultado;
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
        return resultado;
    }
    const btnAceptar= (idEliminar)=>{

        if(idEliminar===0){
            resultado = eliminarEstructura(NombreEstructura, idEliminar)
            if (resultado.success) 
            {
                notify.show(` ${resultado.message}`, "custom", 5000, {
                    background: "#0857B6",
                    text: "#FFFFFF",
                });
            }
        }
        
    }
    const onClickEliminar = ()=>{
        setMessage({Mensaje:`¿Desea eliminar la estructura ${NombreEstructura}?`,NombreEstructura:NombreEstructura,idEstructura:idSubEstructura});
        setModalEliminarEstructura(true);
    }
    return(
        <> 
            <Col sm='12'>
                <Row>
                    <Col  sm='4' >
                        <Button size="sm" color='primary' onClick={()=> onHide()}>Regresar</Button>
                    </Col>
                </Row>
                <Row>
                    {<RotateSpinner color={"#1261ff"}size={30} loading={loading}/>}
                    <Col  sm="6">
                        <Card>
                            <CardBody>
                                <Row>
                                        <Col md="12" xs="6">
                                        <div className="numbers">
                                            <h6>Estructura {NombreEstructura}</h6>
                                            
                                            {/* {structure.title}<CardTitle tag="p">{structure.value}</CardTitle> */}
                                            <p />
                                        </div>
                                        </Col>
                                        <Col>
                                        <Card>
                                            <CardTitle>
                                                <Button close onClick={()=>AtrasSegmento()} >
                                                    <i className="fa fa-reply-all" aria-hidden="true"></i>
                                                </Button>
                                                {_menu.Agregar===1&&(
                                                    <Button onClick={()=>handleAgregar()} size='sm' color='success'>
                                                        <i className="fa fa-plus">Subestructuras</i>
                                                    </Button>
                                                )}
                                                
                                                {_menu.Eliminar===1&&(
                                                     <Button onClick={()=>onClickEliminar()} size='sm' color='danger'>
                                                        <i className="fa fa-times danger">Delete</i>
                                                    </Button>
                                                )}
                                               
                                            </CardTitle>{/*</Card>style={{ 'height': '240px', 'overflow': 'scroll', 'display': 'block' }}*/} 
                                            <CardBody>
                                                <Row>
                                                    <Col sm="10">
                                                        <InputGroup className="no-border">
                                                        <Input value={Estructura.Responsable} onChange={(e)=>setEstructura({...Estructura,Responsable:e.target.value})} placeholder="Buscar..." type="text" />
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                            <i className="nc-icon nc-zoom-split" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>         
                                                </Row>
                                                <div style={{ 'height': '400px', 'overflow': 'scroll', 'display': 'block' }}  >
                                                { 
                                                    catSubEstructuras.filter(data => data.paredId === segmentosSelected).map((item,index )=> (
                                                    <><Badge key={index.toString()} style={{color: item.color}} size='sm'
                                                            tag="button" onClick={(e)=>segmento(item)}
                                                        >
                                                                {item.label} {"  "} <i className={item.icon}aria-hidden="true"></i>
                                                    </Badge>
                                                    </>
                                                    ))}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            {isModalSocialAdd && (
                                <ModalSocialAdd onHide={onHideEnlace} OnEdit={Editar} />
                                )}
                            {isModalEliminarEstructura && (
                                <ModalMensaje onHide={onHideEnlace} Datos={Message} btnAceptar={(e)=>btnAceptar(e)} />
                            )}
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card>
                            <CardTitle className="align-content-center">
                                <Col>
                                    <h6>Detalle {NombreEstructura}</h6>
                                </Col>
                            </CardTitle>
                            <CardBody>
                                <Row>
                                    <Col sm="10">
                                       
                                    </Col>
                                    <Col sm="2" className='text-align-bottom-left' >
                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Label>Responsabilidad</Label>
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>
                    </Col>    
                  
                </Row>
                <Row>
                    
                </Row>
            </Col>
        </>  
    );
};
export default Estructuras;
