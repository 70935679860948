import React, { useEffect, useState, useRef,useCallback } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import EjecutarApi from "data/getPOST";
import ReactTable from 'react-table';
import Pagination from "components/Pagination/pagination";
import { getVars, URL_PUBLICA } from "GlobalStorage";
import bloqueado from 'data/bloqueado.json';
import PerfectScrollbar from "perfect-scrollbar";
import catMunicipioReportes from '../../data/CallCenter/catMunicipioReportes.json';
import catPoligonoMunicipio from '../../data/CallCenter/catPoligonoMunicipio.json';
import { getDataMenu } from 'data/menus';
import { Link } from "react-router-dom";


var ps;
const RCO = (props) => {
  const mainPanel = useRef();
  const [state, setState] = useState({ Region: { value: null, label: 'Seleccione' }, Municipio: { value: null, label: 'Seleccione' }, Poligono: { value: null, label: 'Seleccione' } });
  const [isBloqueado, setIsBloqueado] = useState(false);
  const [cmbRegion, setCmbRegion] = useState([{ value: null, label: 'Seleccione' }]);
  const [cmbMunicipio, setCmbMunicipio] = useState([{ value: null, label: 'Seleccione' }]);
  const [cmbPoligono, setCmbPoligono] = useState([{ value: null, label: 'Seleccione' }]);
  const [infoPoligono, setInfoPoligono] = useState(null);
  const [menu, _setmenu] = useState({});
  const [TotalRegistros, setTotalRegistros] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
  const [paramData,setParamsData]=useState({});
  const [cmbMovilizacion]=useState([{value:1,label:"<10 %"},{value:2,label:"<20 %"},{value:1,label:"<10 %"},{value:3,label:"<30 %"}])

  useEffect(() => {
    _setmenu(getDataMenu(171, token.menus));
    setLoading(true);
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    if (props.history.action === "PUSH") {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    ///
    let encontrado = bloqueado.data.filter(item => item.value === token.user.id);

    if (encontrado.length > 0) {
      setIsBloqueado(true)
    }

    EjecutarApi({}, "getCatalogosFiltrosPromocionClonIdMunicipioReportes", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setCmbRegion(res.data.regiones);
          setCmbMunicipio(res.data.municipios);
          setCmbPoligono(res.data.poligonos);
          setLoading(false);
        }
      })
      .catch((e) => {
        notify.show(`Error al obtener catálogos: ${e.error}`, "error", 5000);
        setLoading(false);
      });

  }, []);


  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const Export = (seccion) => {
    var url = new URL(URL_PUBLICA +
      `getListadoMovilizacionSeccionExcel?token=${token.access_token}&Seccion=${seccion}`
    );
    window.open(url, "_blank");
  }

  const ExportRCO = (seccion) => {
    var url = new URL(URL_PUBLICA +
      `getInfoRCOsExcel?token=${token.access_token}&Seccion=${seccion}`
    );
    window.open(url, "_blank");
  }

  const ExportNoBingosRCO = (seccion) => {
    var url = new URL(URL_PUBLICA +
      `getInfoRCOsNoBingoExcel?token=${token.access_token}&Seccion=${seccion}`
    );
    window.open(url, "_blank");
  }

  const changeRegion = (Region) => {
    setLoading(true);
    setState({ ...state, Region, Municipio: { value: null, label: 'Todos' }, Poligono: { value: null, label: 'Todos' } });
    const itemfiltroM = catMunicipioReportes.data.filter(item => parseInt(item.idRegion) === parseInt(Region.value));
    const itemfiltroP = catPoligonoMunicipio.data.filter(item => parseInt(item.idRegion) === parseInt(Region.value));

    setCmbMunicipio(itemfiltroM);
    setCmbPoligono(itemfiltroP);
    let data={
        idRegion: Region.value,
        idMunicipio: null,
        idPoligono:null,
        page: 0,
        pageSize: 20
    };
    setParamsData(data);
    getInfoMovilizacion(data);

  }

  const changeMunicipio = (Municipio) => {
    setLoading(true);
    setState({ ...state, Municipio, Poligono: { value: null, label: 'Todos' } });

    const itemfiltro = catPoligonoMunicipio.data.filter(item => parseInt(item.idMunicipioReportes) === parseInt(Municipio.value));

    setCmbPoligono(itemfiltro);
    let data={
        idRegion: state.Region.value,
        idMunicipio: Municipio.value,
        idPoligono: null,
        page: 0,
        pageSize: 20
    };
    setParamsData(data);
    getInfoMovilizacion(data);

  }

  const changePoligono = (Poligono) => {
      setState({ ...state, Poligono });
      setLoading(true);
      let data={
          idRegion: state.Region.value,
          idMunicipio: state.Municipio.value,
          idPoligono: Poligono.value,
          page: 0,
          pageSize: 20
      };
      setParamsData(data);
      getInfoMovilizacion(data);

  }
  
  const columns =[
      {
        Header: "Municipio",
        id: "Municipio",
        accessor: "Municipio",
        minWidth: 40,
        filterAll: false,
        headerStyle:{ fontSize: 11}
      },
      {
        Header: "Poligono",
        id: "Poligono",
        accessor: "Poligono",
        minWidth: 30,
        filterAll: false,
        headerStyle:{ fontSize: 11}
      },
      {
        Header: "Seccion",
        id: "Seccion",
        accessor: "Seccion",
        minWidth: 30,
        filterAll: false,
        headerStyle:{ fontSize: 11}
      },
      {
        Header: "Meta",
        id: "MetaCompromisos",
        accessor: "MetaCompromisos",
        minWidth: 40,
        filterAll: false,
        headerStyle:{ fontSize: 11}
      },
      {
        Header: "Avance",
        id: "AvanceCompromisos",
        accessor: "AvanceCompromisos",
        minWidth: 40,
        filterAll: false,
        headerStyle:{ fontSize: 11}
      },
      {
        Header: "% Avance",
        id: "PorcentajeAvance",
        accessor: "PorcentajeAvance",
        Cell: props => <>{(props.value >50) ? (<strong style={{ color: "#026b2e" }}>{props.value} %</strong>) :<div>{props.value}%</div>}</>,
        minWidth: 40,
        filterAll: false,
        headerStyle:{ fontSize: 11}
      },
      {
        Header: "No Bingo",
        id: "NoBingo",
        accessor:"NoBingo",
        minWidth: 40,
        filterAll: false,
        show: menu.Exportar === 1 ? true : false,
        headerStyle:{ fontSize: 11}
      },
      {
        Header: "Bingo",
        id: "MetaBingo",
        accessor:"MetaBingo",
        minWidth: 40,
        show: menu.Exportar === 1 ? true : false,
        filterAll: false,
        headerStyle:{ fontSize: 11}
      },
      {
        Header: "Avance Bingo",
        id: "AvanceBingo",
        accessor: "AvanceBingo",
        show: menu.Exportar === 1 ? true : false,
        minWidth: 40,
        filterAll: false,
        headerStyle:{ fontSize: 11}
      },
      {
        Header: "Por Movilizar",
        id: "DiferenciaBingo",
        accessor: "DiferenciaBingo",
        minWidth: 40,
        filterAll: false,
        show: menu.Exportar === 1 ? true : false,
        headerStyle:{ fontSize: 11}
      },
    ];
   
    const fetchData = useCallback(({ pageSize, page, sorted, params }) => {

      const data = {
          idRegion: state.Region.value?state.Region.value:params.idRegion,
          idMunicipio: state.Municipio.value?state.Municipio.value:params.idMunicipio,
          idPoligono: state.Municipio.value?state.Municipio.value:params.idPoligono,
          page: page,
          pageSize: pageSize,
          sorted: sorted,
      };
      getInfoMovilizacion(data);
    }, []);
  
    const getInfoMovilizacion=(data)=>{
      const _menu= getDataMenu(171, token.menus);
      EjecutarApi(data, "getInfoMovilizacion", token.access_token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if(res.results)
          {
              setInfoPoligono(res.data.map(item=>{
              return {
                    AvanceCompromisos:item.AvanceCompromisos,
                    ClaveRegion:item.ClaveRegion ,
                    MetaCompromisos:item.MetaCompromisos, 
                    Municipio:item.Municipio, 
                    Poligono:item.Poligono, 
                    PorcentajeAvance:item.PorcentajeAvance, 
                    Seccion:((_menu.Exportar === 1)?(<Button className=" btn-round" size="sm" color="primary" onClick={() =>Export(item.Seccion)}>
                      <i class="fa fa-cloud-download " aria-hidden="true"><strong >{" "}{item.Seccion}</strong></i></Button>):(<>{item.Seccion}</>)),
                    NoBingo:((_menu.Exportar=== 1)?(<Button className=" btn-round" size="sm" color="success" /* onClick={() =>Export(item.Seccion)} */>
                      <strong>{item.NoBingo}{" "}<i class="fa fa-file-excel-o"  style={{color:"white", fontSize:'150%'}} onClick={() =>ExportNoBingosRCO(item.Seccion)} aria-hidden="true"></i></strong></Button>):
                      <>{item.NoBingo}</>),
                    MetaBingo:item.MetaBingo,
                    AvanceBingo:item.AvanceBingo,
                    DiferenciaBingo:((_menu.Exportar === 1)?(<Button className=" btn-round" size="sm" color="success" onClick={() =>ExportRCO(item.Seccion)} >
                    <strong>{item.DiferenciaBingo}{" "}<i class="fa fa-file-excel-o"  style={{color:"white", fontSize:'150%'}} aria-hidden="true"></i></strong></Button>):
                    <>{item.DiferenciaBingo}</>)
                    // avMovilizacion:<Button className=" btn-round" size="sm" color="primary" onClick={() =>AvanceMovilizacion(item.Seccion)}>
                    // <strong><i class="fa fa-cloud-download " aria-hidden="true">{"Ver"}</i></strong></Button>
              }}));
              setTotalRegistros(res.total);
              setLoading(false);
          }
          
          
        })
        .catch((e) => {
          notify.show(`Alerta: ${e.error}`, "error", 5000);
        }).
        finally(x => {
          setLoading(false);
        })
  
  }
  const AvanceMovilizacion=()=>{

  }
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {!isBloqueado && (<>

            <Row>
              <Col>
                <Card>
                  <CardHeader>

                  </CardHeader>
                  <CardBody>


                    <Row>

                      <Col sm="3" className="text-muted">Región:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbRegion}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Region}
                          onChange={(e) => changeRegion(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      <Col sm="3" className="text-muted">Municipio:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbMunicipio}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Municipio}
                          onChange={(e) => changeMunicipio(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      <Col sm="3" className="text-muted">Polígono:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbPoligono}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Poligono}
                          onChange={(e) => changePoligono(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>
                      <Col sm="3" className="text-muted">Avance Movilización:
                            {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        {!loading && (<Select
                          options={cmbMovilizacion}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          value={state.Poligono}
                          onChange={(e) => changePoligono(e)}
                          id="viewTypeP"
                          placeholder="Filtrar por"
                        />)}
                      </Col>

                    </Row>

                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
                <Card>
                  <CardBody>
                    {infoPoligono&&(<>
                      {loading&&<CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />}
                          <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                            <ReactTable
                                style={{
                                  width: "100%",
                                  height: "100%"
                                }}
                                size='sm'
                                data={infoPoligono}
                                noDataText="Sin información"
                                manual
                                columns={columns}
                                className="-striped -highlight primary-pagination"
                                loading={loading}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={20}
                                total={TotalRegistros}
                                pages={Math.floor(TotalRegistros / 10)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                PaginationComponent={Pagination}
                                onFetchData={fetchData}
                                params={paramData}
                            />
                        </div>
                    </>)} 
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>)
          }
        </div>

        <Footer fluid />
      </div>
    </div>
  );
};

export default RCO;  