import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Collapse } from 'reactstrap';
import PerfectScrollbar from 'perfect-scrollbar';

import avatar from 'assets/SIAN.png';

import { getVars, setVars } from '../GlobalStorage';

var ps;

const Sidebar = (props) => {
	const [First, setFirst] = useState(true);

	const [state, setState] = useState({ openAvatar: false, componentsCollapse: true });
	const sidebar = useRef();

	useEffect(() => {
		if (navigator.platform.indexOf('Win') > -1) {
			document.documentElement.className += ' perfect-scrollbar-on';
			document.documentElement.classList.remove('perfect-scrollbar-off');

			ps = new PerfectScrollbar(sidebar.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
		}

		// if (navigator.platform.indexOf('Win') > -1) {
		// 	 ps.destroy();
		// }

		// if (navigator.platform.indexOf('Linux') > -1) {
		// 	ps.destroy();
		// }

		if (First) {
			setState(getCollapseStates(getVars('Token', { menus: [] }).menus));
			setFirst(false);
		}
	}, [First]);

	// this creates the intial state of this component based on the collapse routes
	// that it gets through props.routes
	const getCollapseStates = (routes) => {
		let initialState = {};
		routes.map((item, key) => {
			if (item.collapse === 1) {
				initialState = {
					[item.state]: getCollapseInitialState(item.views),
					...getCollapseStates(item.views),
					...initialState,
				};
			}
			return null;
		});

		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
	const getCollapseInitialState = (routes) => {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse === 1 && getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (window.location.pathname === routes[i].path) {
				return true;
			}
		}
		return false;
	};

	const setMenu = (menu) => {
		setVars('CurrentMenu', { CurrentMenu: menu });
	};

	const logout = () => {
		setVars('Token', { access_token: null });
	};

	// this function creates the links and collapses that appear in the sidebar (left menu)
	const createLinks = (routes) => {
		if (routes.length < 1) return;
		return routes.map((itemMenu, key) => {
			if (itemMenu.redirect) {
				return null;
			}

			if (itemMenu.Ver === 0) {
				return null;
			}
			if (itemMenu.collapse === 1) {
				var st = {};
				st[itemMenu['state']] = !state[itemMenu.state];
				return (
					<li className={getCollapseInitialState(itemMenu.views) ? 'active' : ''} key={key}>
						<a
							href="#gto"
							data-toggle="collapse"
							aria-expanded={state[itemMenu.state]}
							onClick={(e) => {
								e.preventDefault();
								setState(st);
							}}
						>
							{itemMenu.icon !== 'undefined' ? (
								<>
									<i className={itemMenu.icon} />
									<p>
										{itemMenu.name}
										<b className="caret" />
									</p>
								</>
							) : (
									<>
										<span className="sidebar-mini-icon">{itemMenu.mini}</span>
										<span className="sidebar-normal">
											{itemMenu.name}
											<b className="caret" />
										</span>
									</>
								)}
						</a>
						<Collapse isOpen={state[itemMenu.state]}>
							<ul className="nav">{createLinks(itemMenu.views)}</ul>
						</Collapse>
					</li>
				);
			}
			return (
				<li className={activeRoute(itemMenu.path)} key={key} onClick={() => setMenu(itemMenu.name)}>
					<NavLink
						to={{
							pathname: itemMenu.path,
						}}
						activeClassName=""
					>
						{itemMenu.icon !== 'undefined' ? (
							<>
								<i className={itemMenu.icon} />
								<p>{itemMenu.name}</p>
							</>
						) : (
								<>
									<span className="sidebar-mini-icon">{itemMenu.mini}</span>
									<span className="sidebar-normal">{itemMenu.name}</span>
								</>
							)}
					</NavLink>
				</li>
			);
		});
	};
	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		return props.location.pathname === routeName ? 'active' : '';
	};

	return (
		<div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
			<div className="sidebar-wrapper" ref={sidebar}>
				<div className="user">
					<div className="photo">
						<img src={avatar} alt="Avatar" />
					</div>
					<div className="info">
						<a

							data-toggle="collapse"
							aria-expanded={state.openAvatar}
							onClick={() => setState({ openAvatar: !state.openAvatar })}
						>
							<span>
								{`${getVars('Token', { user: { Nombre: '', Paterno: '' } }).user.Nombre || ''} ${
									getVars('Token', { user: { Nombre: '', Paterno: '' } }).user.Paterno || ''
									}`}
								<b className="caret" />
							</span>
						</a>
						<Collapse isOpen={state.openAvatar}>
							<ul className="nav">
								<li onClick={() => setMenu('Perfil')}>
									<NavLink to="/perfil" activeClassName="">
										<span className="sidebar-mini-icon">MP</span>
										<span className="sidebar-normal">Mi Perfil</span>
									</NavLink>
								</li>

								<li onClick={() => logout()}>
									<NavLink to="/" activeClassName="">
										<span className="sidebar-mini-icon">S</span>
										<span className="sidebar-normal">Terminar Sesión</span>
									</NavLink>
								</li>
							</ul>
						</Collapse>
					</div>
				</div>
				<Nav>
					{/* <li className={activeRoute('/')} onClick={() => setMenu('Dashboard')}>
						<NavLink
							to={{
								pathname: '/',
							}}
							activeClassName=""
						>
							{/*<>
								<i className="nc-icon nc-tap-01" />
								<p>Dashboard</p>
							</>
						</NavLink>
					</li> */}
					{createLinks(getVars('Token', { menus: [] }).menus)}
				</Nav>
			</div >
		</div >
	);
};

export default Sidebar;
