import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';

import { getVars, setVars } from 'GlobalStorage';
import EjecutarApi from 'data/getPOST';
import Notifications, { notify } from 'react-notify-toast';

import {
  Card,
  CardHeader,
} from 'reactstrap';

import PartidoForm from './PartidosForm';

const PartidosAdd = (props) => {
  let history = useHistory();
  const token = getVars('Token');
  const [menu, _setmenu] = useState({});
  const [isLoadingGuardado, setIsLoadingGuardado] = useState(false);

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const guardarPartido = (partido) => {
    setIsLoadingGuardado(true);
    EjecutarApi(partido, 'createPartido', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          notify.show(`${res.message}`, 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
          history.push('/Partidos');
        } else {
          notify.show(
            `Error al crear partido: ${res.message} Favor de renovar sesión`,
            'danger',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        }
      })
      .catch((e) => {
        setIsLoadingGuardado(false);
        notify.show(
          `Error al crear partido: ${e.message} Favor de renovar sesión`,
          'danger',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  return (
    <div className="wrapper">
      {' '}
      <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info"
      />
      <div className="main-panel">
        <Navbar
          {...props}
          handleMiniClick={handleMiniClick}
          menus={token.menus}
        />
        <Notifications />
        <div className="content">
          <Card>
            <CardHeader>
              <h5 className="title">Nuevo partido</h5>
            </CardHeader>
            <PartidoForm
              token={token}
              savePartido={guardarPartido}
              isLoadingGuardado={isLoadingGuardado}
              handleCancelar={() => history.push('/Partidos')}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PartidosAdd;
