import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,

  Card,
  CardHeader,
  CardBody,

  FormGroup,
  Label,
} from 'reactstrap';
import { Link } from "react-router-dom";
import Notifications, { notify } from 'react-notify-toast';
import PerfectScrollbar from 'perfect-scrollbar';
import { CircleSpinner } from 'react-spinners-kit';
import Select from 'react-select';

import { getVars, INTERVAL } from 'GlobalStorage';
import EjecutarApi from 'data/getPOST';
import { getDataMenu } from 'data/menus';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import ChartMultipleColums from 'components/Charts/Sije/ChartMultipleColums';
import CasillasResumen from "components/Sije/CasillasResumen";
import AsistenciaResumen from "components/Sije/AsistenciaResumen";
import IncidenciasResumen from "components/Sije/IncidenciasResumen";
import Acta from "components/Sije/ActasResumen";
import NumeraliaGrf from 'components/Charts/Sije/ChartBarrasMetas';
import dataJson from 'pages/Sije/data.json';
var ps;

/* function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
} */

const SijeDashBoard = props => {
  const mainPanel = useRef();
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  const [dataSerie, setDataSerie] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [Colors, setColors] = useState([
    '#00A0DF',
    '#FFB600',
    '#005CB9',
    '#FF6B00',
  ]);
  const [catMunicipios, setCatMunicipios] = useState([]);
  const [catDF, setCatDF] = useState([]);
  const [catDL, setCatDL] = useState([]);
  const [catRegion, setCatRegion] = useState([]);
  const [catPoligono, setCatPoligono] = useState([]);
  const [catSeccion, setCatSeccion] = useState([]);
  const [scope, setScope] = useState([]);
  const [filters, setFilters] = useState({});
  const [optionsChart, setOptionsChart] = useState({});

  const [catTiposVista, setCatTiposVista] = useState([]);
  //distrito local, federal, municipios, estatal
  const [datasets, setDatasets] = useState([]);
  const [loadingCat, setLoadingCat] = useState(true);
  const [scopeLabel, setScopeLabel] = useState("");
  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingTotales, setLoadingTotales] = useState(false);
  const [totalesCasillas, setTotalesCasillas] = useState([]);
  const [totalAsistencias, setTotalAsistencias] = useState([]);
  const [totalIncidencias, setTotalIncidencias] = useState([]);
  const [totalActas, setTotalActas] = useState([]);
  const [counter, setCounter] = useState(0);
  // const [isScrollable, setIsScrollable] = useState(false);
  const [series, SetSeries] = useState([]);
  const [dataChart, SetDataChart] = useState({});
  const [logGraficaMeta, setLogGraficaMeta] = useState(false);

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }

    if (props.history.action === 'PUSH') {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    getFilters();
    getCatalogs();
    // getChartData(filters.tipoVista.value, filters.ambito.value);
    // getTotales(filters.tipoVista.value, filters.ambito.value);
    // getChartMetas(filters.tipoVista.value, filters.ambito.value);


  }, []);

  /*   useInterval(() => {
      setCounter(counter + 1);
    }, INTERVAL); */

  // useEffect(() => {
  //   getChartData(filters.tipoVista.value, filters.ambito.value);
  //   getTotales(filters.tipoVista.value, filters.ambito.value);
  //   //  getChartMetas(filters.tipoVista.value, filters.ambito.value);
  // }, [counter])

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const getCatalogs = () => {
    EjecutarApi(
      { catalogos: ['municipios_reportes', 'dfs', 'dls', 'tipo_vista', 'regiones', 'poligonos', 'secciones'] },
      'getCatalogosSije',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then(res => {
        if (res.success && res.results) {
          let item = [{ value: 0, label: 'Todos' }];
          setCatDF(item.concat(res.data.dfs));
          setCatDL(item.concat(res.data.dls));
          setCatMunicipios(item.concat(res.data.municipios_reportes));
          setCatRegion(item.concat(res.data.regiones));
          setCatPoligono(item.concat(res.data.poligonos));
          setCatSeccion(item.concat(res.data.secciones));
          setCatTiposVista(res.data.tipo_vista);
          let TipoVista = res.data.tipo_vista[0];
          switch (TipoVista.value) {
            case 1:
              setScope(item.concat(res.data.municipios_reportes));
              break;
            case 2:
              setScope(item.concat(res.data.dls));
              break;
            case 3:
              setScope(item.concat(res.data.dfs));
              break;
            case 5:
              setScope(item.concat(res.data.regiones));
              break;
            case 6:
              setScope(item.concat(res.data.poligonos));
              break;
            case 7:
              setScope(item.concat(res.data.secciones));
              break;
          }
        }
        setLoadingCat(false);
      })
      .catch(e => {
        setLoadingCat(false);
        notify.show(`Error al cargar filtros: ${e.errors}}`, 'error', 5000);
      });
  };

  const handleTipoVista = tipo => {
    switch (tipo.value) {
      case 1:
        setScopeLabel("Municipio");
        setScope(catMunicipios);
        // setOptionsChart({ ...optionsChart, Subtitle: "Vista por Municipios" });
        break;
      case 2:
        setScopeLabel("Distrito Local");
        setScope(catDL);
        // setOptionsChart({ ...optionsChart, Subtitle: "Vista por Distrito Locales" });
        break;
      case 3:
        setScopeLabel("Distrito Federal");
        setScope(catDF);
        // setOptionsChart({ ...optionsChart, Subtitle: "Vista por Distrito Federales" });
        break;
      case 5:
        setScopeLabel("Región");
        setScope(catRegion);
        // setOptionsChart({ ...optionsChart, Subtitle: "Vista por Distrito Federales" });
        break;
      case 6:
        setScopeLabel("Polígono");
        setScope(catPoligono);
        // setOptionsChart({ ...optionsChart, Subtitle: "Vista por Distrito Federales" });
        break;
      case 7:
        setScopeLabel("Sección");
        setScope(catSeccion);
        // setOptionsChart({ ...optionsChart, Subtitle: "Vista por Distrito Federales" });
        break;
    }

    setFilters({ ...filters, tipoVista: tipo, ambito: { value: 0, label: "Todos" } });
    getChartData(tipo.value, 0);
    getTotales(tipo.value, 0);
    // getChartMetas(filters.tipoVista.value, 0);
  };

  const handleScope = (scopeData) => {
    setFilters({ ...filters, ambito: scopeData });
    getChartData(filters.tipoVista.value, scopeData.value);
    getTotales(filters.tipoVista.value, scopeData.value);
    // getChartMetas(filters.tipoVista.value, scopeData.value);

    // if (scopeData.value > 0) {
    //   switch (filters.tipoVista.value) {
    //     case 1:
    //       setOptionsChart({ ...optionsChart, Subtitle: `Vista por Municipio: ${scopeData.label}` });
    //       break;
    //     case 2:
    //       setOptionsChart({ ...optionsChart, Subtitle: `Vista por Distrito Local: ${scopeData.label}` });
    //       break;
    //     case 3:
    //       setOptionsChart({ ...optionsChart, Subtitle: `Vista por Distrito Federal: ${scopeData.label}` });
    //       break;
    //   }
    // } else {
    //   switch (filters.tipoVista.value) {
    //     case 1:
    //       setOptionsChart({ ...optionsChart, Subtitle: "Vista por Municipios" });
    //       break;
    //     case 2:
    //       setOptionsChart({ ...optionsChart, Subtitle: "Vista por Distrito Locales" });
    //       break;
    //     case 3:
    //       setOptionsChart({ ...optionsChart, Subtitle: "Vista por Distrito Federales" });
    //       break;
    //   }
    // }
  }

  const getChartData = (tipoVista, ambito) => {
    setLoadingChart(true);
    EjecutarApi(
      {
        ambito: ambito,
        tipoVista: tipoVista
      },
      'sije/getChart',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then(res => {
        if (res.success) {
          if (res.results) {
            setDataSerie(res.data.series);
            setCategorias(res.data.categories);
            setOptionsChart(res.data.optionsChart);
          }
          else {
            notify.show(res.message, 'warning', 5000);
          }
        } else {
          notify.show(res.message, 'warning', 5000);
        }
        setLoadingChart(false);
      })
      .catch(e => {
        notify.show(`Error al cargar : ${e.message}}`, 'error', 5000);
        setLoadingChart(false);
      });
  }

  const getFilters = () => {
    EjecutarApi({},
      'sije/getFilters',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then(res => {
        if (res.success) {
          if (res.results) {
            setFilters(res.data)
            getChartData(res.data.tipoVista.value, res.data.ambito.value);
            getTotales(res.data.tipoVista, res.data.ambito.value);
            setScopeLabel(res.data.label)
          }
          else {
            notify.show(res.message, 'warning', 5000);
          }
        } else {
          notify.show(res.message, 'warning', 5000);
        }
      })
      .catch(e => {
        notify.show(`Error al cargar : ${e.message}}`, 'error', 5000);
      });
  }

  const getTotales = (tipoVista, ambito) => {
    setLoadingTotales(true);
    EjecutarApi(
      {
        ambito: ambito,
        tipoVista: tipoVista,
        totales: ["casillas", "asistencias", "incidencias", "Actas"]
      },
      'sije/getTotales',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then(res => {
        if (res.success) {
          if (res.results) {
            setTotalesCasillas(res.data.casillas);
            setTotalAsistencias(res.data.asistencias);
            var inciden = {
              "Favorables": res.data.incidencias[0] ? res.data.incidencias[0].Total : 0,
              "Competidas": res.data.incidencias[1] ? res.data.incidencias[1].Total : 0,
              "Adversas": res.data.incidencias[2] ? res.data.incidencias[2].Total : 0
            };
            setTotalIncidencias(inciden)
            setTotalActas({ "ActaRecibida": res.data.Actas.recibidas, "TotalActas": res.data.Actas.total })
          }
          else {
            notify.show(res.message, 'warning', 5000);
          }
        } else {
          notify.show(res.message, 'warning', 5000);
        }
        setLoadingTotales(false);
      })
      .catch(e => {
        notify.show(`Error al cargar : ${e.message}}`, 'error', 5000);
        setLoadingTotales(false);
      });
  }

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <CardBody>
                    {!loadingChart && (
                      <Row>
                        <Col sm="3" md="3" lg="3"></Col>

                        <Col sm="3" md="3" lg="3">
                          <FormGroup>
                            <Label for="selecMpiocasa" className="labelForm">
                              <span className="text-danger">*</span>Tipo de vista:
                          </Label>
                            <Select
                              options={catTiposVista}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={filters ? filters.tipoVista : []}
                              onChange={e => handleTipoVista(e)}
                              id="selecMpiocasa"
                              placeholder="Municipio"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                          <FormGroup>
                            <Label for="selecMpiocasa" className="labelForm">
                              <span className="text-danger">*</span>{scopeLabel}
                            </Label>
                            <Select
                              options={scope}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={filters ? filters.ambito : []}
                              onChange={(e) => handleScope(e)}
                              id="selecMpiocasa"
                              placeholder="Municipio"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6" lg="6" sm="12">
              <AsistenciaResumen
                data={totalAsistencias}
              ></AsistenciaResumen>
            </Col>
            <Col md="6" lg="6" sm="12">
              <CasillasResumen
                data={totalesCasillas}
              ></CasillasResumen>
            </Col>
          </Row>

          <Row>
            <Col md="6" lg="6" sm="12">
              <IncidenciasResumen
                data={totalIncidencias}
              ></IncidenciasResumen>
            </Col>
            <Col md="6" lg="6" sm="12">
              <Acta
                data={totalActas}
              ></Acta>
            </Col>
          </Row>

          <Card>
            <CardHeader>
              
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="12" md="12" sm="12">
                  {dataSerie && (
                    <div style={{ width: '100%' }}>
                      {loadingChart && (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={true}
                        />
                      )}
                      {!loadingChart && (<ChartMultipleColums
                        Categories={categorias}
                        Series={dataSerie}
                        Title={optionsChart.Title}
                        Subtitle={optionsChart.Subtitle}
                        Colors={Colors}
                      // isScrollable={optionsChart.isScrollable}
                      />)}
                    </div>

                  )}
                </Col>
              </Row>
              {/* <Row>
                <Col sm="3" md="3" lg="3"></Col>
                <Col sm="3" md="3" lg="3">
                  <FormGroup>
                    <Label for="selecMpiocasa" className="labelForm">
                      <span className="text-danger">*</span>Tipo de vista:
                    </Label>
                    <Select
                      options={catTiposVista}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={filters.tipoVista}
                      onChange={e => handleTipoVista(e)}
                      id="selecMpiocasa"
                      placeholder="Municipio"
                    />
                  </FormGroup>
                </Col>
                <Col sm="3" md="3" lg="3">
                  <FormGroup>
                    <Label for="selecMpiocasa" className="labelForm">
                      <span className="text-danger">*</span>{scopeLabel}
                    </Label>
                    <Select
                      options={scope}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={filters.ambito}
                      onChange={(e) => handleScope(e)}
                      id="selecMpiocasa"
                      placeholder="Municipio"
                    />
                  </FormGroup>
                </Col>
              </Row> */}
              <hr />
              {/* <Row>
                <Col lg="12" md="12" sm="12">
                  <div style={{ width: '100%' }}>
                    {!loadingChart2 && <ChartMultipleAreas data={datasets}></ChartMultipleAreas>}
                    {loadingChart2 && (
                      <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={true}
                      />
                    )}
                  </div>
                </Col>
              </Row> */}

              {/*    <Row>
                <Col lg="12" md="12" sm="12">
                  <div style={{ width: '100%' }}>
                    <NumeraliaGrf datos={series} Titulos={dataChart} />

                  </div>
                </Col>
              </Row> */}

            </CardBody>

          </Card>

        </div>
        <Footer fluid />
      </div>
    </div>
  );
};

export default SijeDashBoard;
