import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import {Link} from 'react-router-dom'
import { CircleSpinner } from 'react-spinners-kit';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Pagination from 'components/Pagination/pagination';
import EjecutarApi from 'data/getPOST';
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from 'GlobalStorage';
import ModalFiltro from 'components/Filtro/indexVisualizacionCasillas';
//import InfoResponsable from 'components/Casillas/InfoResponsable';
//import AsignacionResponsable from 'components/Casillas/AsignacionResponsable';
//import ChartCasilla from 'components/Charts/ElectoralCasillas/ChartCasillas';
import { numberWithCommas } from '../../utils/funciones';

const Capacitaciones = (props) => {
    const [compromisos, setCompromisos] = useState([]);
    const [total, setTotal] = useState({});
    const [totalCompromisos, setTotalCompromisos] = useState(0);
    const [_menu, _setmenu] = useState({});
    const [Filter, setFiltro] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = getVars('Token');
    const [MenuModal] = useState({
        Compromiso: true,
        Voluntarios: false,
        RCs: false,
        RGs: false,
        Abogado: false,
        CasaAzul: false,
        Municipio: false,
    });

    const handleMiniClick = () => {
        document.body.classList.toggle('sidebar-mini');
    };

    const columns = [
        {
        Header: 'Clave Evento',
        minWidth: 20,
        id: 'Region',
        accessor: (d) => d.Region,
        filterAll: false,
        },
        {
        Header: 'Tipo',
        minWidth: 20,
        id: 'Region',
        accessor: (d) => d.Region,
        filterAll: false,
        },
        {
        Header: 'Municipio',
        minWidth: 20,
        id: 'Region',
        accessor: (d) => d.Region,
        filterAll: false,
        },
        {
        Header: 'Nombre Evento',
        minWidth: 40,
        id: 'Region',
        accessor: (d) => d.Region,
        filterAll: false,
        },
        {
        Header: 'Fecha Evento',
        minWidth: 20,
        id: 'Municipio',
        accessor: (d) => d.Municipio,
        filterAll: false,
        },
        {
        Header: 'Capacitadores',
        minWidth: 20,
        id: 'Seccion',
        accessor: (d) => d.Seccion,
        filterAll: false,
        },
        {
        Header: 'Invitados',
        minWidth: 20,
        id: 'NombreCasilla',
        accessor: (d) => d.NombreCasilla,
        filterAll: false,
        },
        {
        Header: 'Estatus',
        accessor: (d) => d.idP1,
        minWidth: 20,
        id: 'idP1',
        filterable: false,
        },
    ];

    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                         {/*{_menu.Agregar === 1 ? (*/}
                                        <Link to="CapacitacionesAdd">
                                        <Button color="primary" size="sm">
                                            <span className="btn-label">
                                                <i className="fa fa-calendar-o" />
                                            </span>
                                            Nuevo
                                        </Button>
                                       </Link>
                                        {/* ) : (<></>)} */}
                                        <Button color="primary" size="sm">
                                        <span className="btn-label">
                                            <i className="fa fa-file-excel-o fa-lg" />
                                        </span>
                                        Exportar
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                        </Col>
                                    </Row>
                                    <div style={{ width: '100%', overflow: 'auto' }}>
                                        <ReactTable
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        data={compromisos}
                                        noDataText="Sin información"
                                        manual
                                        columns={columns}
                                        className="-striped -highlight primary-pagination"
                                        loading={loading}
                                        showPagination={true}
                                        showPaginationBottom={true}
                                        showTotalPages={true}
                                        showPageJump={false}
                                        canNextFromData={true}
                                        defaultPageSize={10}
                                        total={totalCompromisos}
                                        pages={Math.floor(totalCompromisos / 10)}
                                        previousText="Anterior"
                                        nextText="Siguiente"
                                        ofText="de"
                                        pageSizeOptions={[10, 20, 25, 50, 100]}
                                        PaginationComponent={Pagination}
                                        params={Filter}
                                        />
                                    </div>
                                </CardBody>
                                <CardFooter/>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Footer fluid />
            </div>
        </div>
    );
};
export default Capacitaciones;