import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col, Collapse, Input, FormGroup, Label, } from 'reactstrap';
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import Notifications, { notify } from "react-notify-toast";

import EjecutarApi from "../../data/getPOST";
import { getVars,URL_PUBLICA} from "../../GlobalStorage";
//import catMunicipioReportes from '../../data/CallCenter/catMunicipioReportes.json';
//import catPoligonoMunicipio from '../../data/CallCenter/catPoligonoMunicipio.json';
import { getDataMenu } from "data/menus";

function IndexTablero({ Menu, Datos }) {
    const token = getVars("Token");
    const [collapse, setCollapse] = useState({
        horizontalTabs: "home",
        verticalTabs: "info",
        pageTabs: "homePages",
        openedCollapses: ["collapseOne", "collapse1"]
    });
    const [state, setState] = useState({ Region: { value: null, label: 'Todas' }, 
                                        Municipio: { value: null, label: 'Todas' }, 
                                        Poligono: { value: null, label: 'Todas' } ,
                                        Seccion: { value: null, label: 'Todas' },
                                        Tipo: { value: null, label: 'Todas' },
                                        TipoVista: { value: null, label: 'Todas' },
                                        DistritoLocal: { value: null, label: 'Todas' },
                                        DistritoFederal: { value: null, label: 'Todas' }});
    const [cmbVista,setCmbVista] = useState([{ value: 1, label: 'Estatal' }
                                ,{ value: 2, label: 'Distrito Local'}
                                ,{ value: 3, label: 'Distrito Federal' } ]);
    const [valuVista, setvalueVista] = useState({ value: 1, label: 'Estatal' });
    const [cmbRegion, setCmbRegion] = useState([{ value: null, label: 'Todas' }]);
    const [catRegion, setCatRegion] = useState([{ value: null, label: 'Todas' }]);
    const [cmbMunicipio, setCmbMunicipio] = useState([{ value: null, label: 'Seleccione' }]);
    const [catMunicipioReportes,setCatMunicipioReportes]=useState([]);
    const [catPoligonoMunicipio,setCatPoligonoMunicipio]=useState([]);
    const [cmbPoligono, setCmbPoligono] = useState([{ value: null, label: 'Seleccione' }]);
    const [cmbSeccion, setCmbSeccion] = useState([{ value: null, label: 'Seleccione' }]);
    const [cmbTipo,setCmbTipo] = useState([{ value: null, label: 'Seleccione' },{ value: 'SWITCH', label: 'AMARILLO' },{ value: 'PERDIBLE', label: 'ROJO' },{ value: 'DURO', label: 'VERDE' }]);
    const [cmbDL, setCmbDL] = useState([{ value: null, label: 'Seleccione' }]);
    const [cmbDF, setCmbDF] = useState([{ value: null, label: 'Seleccione' }]);
    const [catSeccion, setCatSeccion] = useState([]);
    const [catDL, setCatDL] = useState([]);
    const [catDF, setCatDF] = useState([]);
    const [_menu, _setmenu] = useState({});
    const [loading, setLoading] = useState(false);
    const [vista,setVista]=useState({
            Region:false,
            Municipio:false,
            Poligono:false,
            Seccion:false,
            Tipo:false,
            DistritoFederal:false,
            DistritoLocal:false
    });
    const [Filtro,setFiltro]=useState({
        Region:null,
        Municipio:null,
        Poligono:null,
        Seccion:null,
        Tipo:null,
        DistritoFederal:null,
        DistritoLocal:null
    });
    useEffect(() => {
        _setmenu(getDataMenu(178, token.menus));
        setLoading(true);
        EjecutarApi({
          "catalogos":[
            "municipios_reportes",
            "poligonos",
            "dfs",
            "dls",
            "secciones",
            "regiones",
           "tipo_vista_mov"
          ]
          }, "getCatalogosSije", token.access_token)
          .then(function (res) {
            return res;
          })
          .then((res) => {
    
            if (res.results) {
              setCmbRegion(res.data.regiones);
              setCatRegion(res.data.regiones);
              setCmbMunicipio(res.data.municipios_reportes);
              setCatMunicipioReportes(res.data.municipios_reportes);
              setCatPoligonoMunicipio(res.data.poligonos);
              setCmbPoligono(res.data.poligonos);
              setCmbSeccion(res.data.secciones);
              setCatSeccion(res.data.secciones);
              setCmbDL(res.data.dls);
              setCmbDF(res.data.dfs);
              setCatDL(res.data.dls);
              setCatDF(res.data.dfs);
              //setCmbVista(res.data.tipo_vista_mov)
              //changeVista(res.data.tipo_vista_mov[0]);
              setLoading(false);
            }
          })
          .catch((e) => {
            notify.show(`Error al obtener catálogos: ${e.error}`, "error", 5000);
            setLoading(false);
          });
    }, []);

    const collapsesToggle = (colapse) => {
        let openedCollapses = collapse.openedCollapses;
        if (openedCollapses.includes(colapse)) {
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses.filter(item => item !== colapse)
            });
        } else {
            openedCollapses.push(colapse);
            setCollapse({
                ...collapse,
                openedCollapses: openedCollapses
            });
        }
    };
    const changeRegion = (Region) => {
        setLoading(true);
        setState({ ...state, Region, 
          Municipio: { value: null, label: 'Todos' }, 
          Poligono: { value: null, label: 'Todos' },
          Seccion: { value: null, label: 'Todos' } 
          });
        const itemfiltroM = catMunicipioReportes.filter(item => parseInt(item.idRegion) === parseInt(Region.value));
        const itemfiltroP = catPoligonoMunicipio.filter(item => parseInt(item.idRegion) === parseInt(Region.value));
        const itemfiltroS = catSeccion.filter(item => parseInt(item.idRegion) === parseInt(Region.value));
        setCmbMunicipio(itemfiltroM);
        setCmbPoligono(itemfiltroP);
        setCmbSeccion(itemfiltroS);
        setFiltro({...Filtro,Region:{"id":"a.idRegion", "value":Region.value}})
        setLoading(false);
    }
  
    const changeMunicipio = (Municipio) => {
        setLoading(true);
        setState({ ...state, Municipio
                  ,Poligono: { value: null, label: 'Todos' }
                  ,Seccion: { value: null, label: 'Todos' }
               });
        console.log(catPoligonoMunicipio)
        const itemfiltroP = catPoligonoMunicipio.filter(item => parseInt(item.idMunicipioReportes) === parseInt(Municipio.value));
        const itemfiltroS = catSeccion.filter(item => parseInt(item.idMunicipioReportes) === parseInt(Municipio.value));
        setCmbPoligono(itemfiltroP);
        setCmbSeccion(itemfiltroS);
        setFiltro({...Filtro, Municipio:{"id":"a.idMunicipioReportes", "value":Municipio.value}})
        console.log(itemfiltroP)
        setLoading(false);
    }
    const changePoligono = (Poligono) => {
      setLoading(true);
      setState({ ...state, Poligono });
      console.log(catSeccion);
      console.log(Poligono);
      const itemfiltroS = catSeccion.filter(item => parseInt(item.idPoligono) === parseInt(Poligono.value));
      console.log(itemfiltroS)
      setCmbSeccion(itemfiltroS);
      setFiltro({...Filtro, Poligono:{"id":"a.idPoligono", "value":Poligono.value}})
    
      setLoading(false);
      
    }
    const changeSecciones = (Seccion) => {
      setLoading(true);
      setState({ ...state, Seccion });
      setFiltro({...Filtro, Seccion:{"id":"a.Seccion", "value":Seccion.value}})
      setLoading(false);
    }
    const changeDistritoFederal = (DistritoFederal) => {
      setLoading(true);
      setState({ ...state, DistritoFederal, 
                            Region: { value: null, label: 'Todos' }, 
                            Municipio: { value: null, label: 'Todos' }, 
                            Poligono: { value: null, label: 'Todos' },
                            Seccion: { value: null, label: 'Todos' }, 
                            DistritoLocal: { value: null, label: 'Todos' } });
      
      setFiltro({...Filtro, DistritoFederal:{"id":"a.idDF", "value":DistritoFederal.value},
                            Region:null,
                            Municipio:null,
                            Poligono:null,
                            Seccion:null,
                            Tipo:null,
                            DistritoLocal:null,});
        const itemfiltroM = catMunicipioReportes.filter(item => parseInt(item.idDF) === parseInt(DistritoFederal.value));
        //const itemfiltroP = catPoligonoMunicipio.filter(item => parseInt(item.idDL) === parseInt(DistritoLocal.value));
         setCmbMunicipio(itemfiltroM);
        //setCmbPoligono(itemfiltroP);
        setLoading(false);
    }
    const changeDistritoLocal = (DistritoLocal) => {
        setLoading(true);
        console.log('Disrito Loca:',DistritoLocal)
        setState({ ...state, DistritoLocal, 
                            Region: { value: null, label: 'Todos' }, 
                            Municipio: { value: null, label: 'Todos' }, 
                            Poligono: { value: null, label: 'Todos' },
                            Seccion: { value: null, label: 'Todos' }, 
                            DistritoFederal: { value: null, label: 'Todos' } });
        //const itemfiltroM = catMunicipioReportes.filter(item => parseInt(item.idDL) === parseInt(DistritoLocal.value));
        //const itemfiltroP = catPoligonoMunicipio.filter(item => parseInt(item.idDL) === parseInt(DistritoLocal.value));
        const itemfiltroS = catSeccion.filter(item => parseInt(item.idDL) === parseInt(DistritoLocal.value));
        //setCmbMunicipio(itemfiltroM);
        //setCmbPoligono(itemfiltroP);
        setCmbSeccion(itemfiltroS);
        setFiltro({...Filtro, DistritoLocal:{"id":"a.idDL", "value":DistritoLocal.value},
                                Region:null,
                                Municipio:null,
                                Poligono:null,
                                Seccion:null,
                                Tipo:null,
                                DistritoFederal:null, })
        setLoading(false);
    }
    const changeTipo = (_tipo)=>{
        setState({ ...state, Tipo:_tipo })
        setFiltro({...Filtro,Tipo:{"id":"a.Tipo", "value":_tipo.value}});
        setLoading(false);
    } 
    const changeVista=(e)=>{
      
      setvalueVista({label:e.label,value:e.value});
      let valor=e.value;
        switch(valor)
        {
          case 1: //Estatal
              setVista({
                  Region:true,
                  Municipio:true,
                  Poligono:true,
                  Seccion:true,
                  Tipo:true,
                  DistritoFederal:false,
                  DistritoLocal:false
              })
              ClearFilter();
              setCmbRegion(catRegion);
              

          break;
          case 2: //Local
              setVista({
                  Region:false,
                  Municipio:false,
                  Poligono:false,
                  Seccion:true,
                  Tipo:true,
                  DistritoFederal:false,
                  DistritoLocal:true
              })
              ClearFilter();
              setCmbDL(catDL);
          break;
          case 3: //Federal
              setVista({
                Region:false,
                Municipio:true,
                Poligono:true,
                Seccion:true,
                Tipo:false,
                DistritoFederal:true,
                DistritoLocal:false

            })
            ClearFilter();
            setCmbDF(catDF);
          break;
          default:
              setVista({
                Region:false,
                Municipio:false,
                Poligono:false,
                Seccion:false,
                Tipo:false,
                DistritoFederal:false,
                DistritoLocal:false
            })
          break;
        }
     
    }
  

    const setFilter = (event) => {
        console.log("ARRAY_FILTROS",Filtro);
        let fill = [Filtro.Region,Filtro.Municipio,Filtro.Poligono,
            Filtro.Seccion,Filtro.DistritoFederal,Filtro.DistritoLocal,Filtro.Tipo];
        let auxF=[];
        fill.forEach(item=>{
            console.log(item)
            if(item)
            {
                auxF.push(item);
            }
            
            
        })
        console.log("auxF",auxF);
        var _Filtro ={
            idRegion: state.Region.value,
            idDL: state.DistritoLocal.value,
            idDF: state.DistritoFederal.value,
            idMunicipio: state.Municipio.value,
            idPoligono: state.Poligono.value,
            Seccion: state.Seccion.value,
            tipoVista: state.Tipo.label,
            tipoVista:valuVista.label,
            Filtro:auxF,
        }
        Datos(_Filtro);
    };

    const ClearFilter = () => {
        
        var _Filtro ={
            idRegion: null,
            idDL: null,
            idDF: null,
            idMunicipio: null,
            idPoligono: null,
            Seccion: null,
            tipoVista: null,
            valuVista:'Estatal',
            Filtro:[],
        }
        Datos(_Filtro);
        setState({ Region: { value: null, label: 'Todas' }, 
        Municipio: { value: null, label: 'Todas' }, 
        Poligono: { value: null, label: 'Todas' } ,
        Seccion: { value: null, label: 'Todas' },
        Tipo: { value: null, label: 'Todas' },
        DistritoLocal: { value: null, label: 'Todas' },
        DistritoFederal: { value: null, label: 'Todas' }});
        setvalueVista({ value: 1, label: 'Todas' });

    };

    return (
        <>
            <Card className="card-plain">
                <CardBody>
                    <div
                        aria-multiselectable={true}
                        className="card-collapse"
                        id="accordion"
                        role="tablist"
                    >
                        <Card className="card-plain">
                            <CardHeader role="tab">
                                <a
                                    aria-expanded={collapse.openedCollapses.includes("collapse3")}
                                    data-parent="#accordion"
                                    data-toggle="collapse"
                                    onClick={() => collapsesToggle("collapse3")}
                                    className="text-right"
                                >
                                 <Button
                                    className="btn-icon btn-neutral"
                                    outline
                                    size="sm"
                                    id="EditUser"
                                >
                                    <i className="fa fa-filter fa-3x" color='danger' aria-hidden="true"></i>
                                </Button>
                                Filtro
                                </a>
                            </CardHeader>
                            <Collapse
                                role="tabpanel"
                                isOpen={collapse.openedCollapses.includes("collapse3")}
                            >
                                <CardBody>
                                    <Row>
                                        <Col sm="3" className="text-muted">Vista:
                                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                            )}
                                            {!loading && (<Select
                                            options={cmbVista}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={valuVista}
                                            onChange={(e) => changeVista(e)}
                                            id="viewTypeP"
                                            placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        {vista.Region && (
                                            <Col sm="3" className="text-muted">Región:
                                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                            )}
                                            {!loading && (<Select
                                            options={cmbRegion}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={state.Region}
                                            onChange={(e) => changeRegion(e)}
                                            id="viewTypeP"
                                            placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        )}
                                        {vista.DistritoFederal&&(
                                            <Col sm="3" className="text-muted">Distrito Federal:
                                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                            )}
                                            {!loading && (<Select
                                            options={cmbDF}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={state.DistritoFederal}
                                            onChange={(e) => changeDistritoFederal(e)}
                                            id="viewTypeP"
                                            placeholder="Filtrar por"
                                            loading={loading}
                                            />)}
                                        </Col>
                                        )}
                                        {vista.DistritoLocal&&(
                                            <Col sm="3" className="text-muted">Distrito Local:
                                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                            )}
                                            {!loading && (<Select
                                            options={cmbDL}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={state.DistritoLocal}
                                            onChange={(e) => changeDistritoLocal(e)}
                                            id="viewTypeP"
                                            placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        )}
                                        {vista.Municipio&&(
                                            <Col sm="3" className="text-muted">Municipio:
                                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                            )}
                                            {!loading && (<Select
                                            options={cmbMunicipio}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={state.Municipio}
                                            onChange={(e) => changeMunicipio(e)}
                                            id="viewTypeP"
                                            placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        )}
                                        {vista.Poligono&&(
                                            <Col sm="3" className="text-muted">Polígono:
                                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                            )}
                                            {!loading && (<Select
                                            options={cmbPoligono}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={state.Poligono}
                                            onChange={(e) => changePoligono(e)}
                                            id="viewTypeP"
                                            placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        )}
                                        {vista.Seccion&&(
                                            <Col sm="3" className="text-muted">Sección:
                                                {loading && (
                                            <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                            />
                                            )}
                                            {!loading && (<Select
                                            options={cmbSeccion}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={state.Seccion}
                                            onChange={(e) => changeSecciones(e)}
                                            id="viewTypeP"
                                            placeholder="Filtrar por"
                                            />)}
                                        </Col>
                                        )}
                                        {vista.Tipo&&(
                                            <Col sm="3" className="text-muted">Filtro por Color:
                                            {loading && (
                                        <CircleSpinner
                                            className="text-center"
                                            size={30}
                                            color="#686769"
                                            loading={loading}
                                        />
                                        )}
                                        {!loading && (<Select
                                        options={cmbTipo}
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        value={state.Tipo}
                                        onChange={(e) => changeTipo(e)}
                                        id="viewTypeP"
                                        placeholder="Filtrar por"
                                        />)}
                                    </Col>
                                    )}
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Button size='sm' id='btnfiltro' color="primary" className="primary" onClick={(e) => setFilter()}>
                                        <i className="fa fa-filter fa-lg" aria-hidden="true"></i>
                                        Filtrar
                                    </Button>{' '}
                                    <Button size='sm' id='btnfiltro' color="danger" className="danger" onClick={(e) => ClearFilter()}>
                                        <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                                        Limpiar
                                    </Button>
                                </CardFooter>
                            </Collapse>
                        </Card>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default IndexTablero;