import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const Opcion = [
    {
        options1 : {

            title: {
                text: 'Combination chart'
            },
            xAxis: {
                categories: ['Apples', 'Oranges', 'Pears', 'Bananas', 'Plums']
            },
            labels: {
                items: [{
                    html: 'Total fruit consumption',
                    style: {
                        left: '50px',
                        top: '18px',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'black'
                    }
                }]
            },
            series: [{
                type: 'column',
                name: 'Jane',
                data: [3, 2, 1, 3, 4]
            }, {
                type: 'column',
                name: 'John',
                data: [2, 3, 5, 7, 6]
            }, {
                type: 'column',
                name: 'Joe',
                data: [4, 3, 3, 9, 0]
            }, {
                type: 'spline',
                name: 'Average',
                data: [3, 2.67, 3, 6.33, 3.33],
                marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: 'white'
                }
            }, {
                type: 'pie',
                name: 'Total consumption',
                data: [{
                    name: 'Jane',
                    y: 13,
                    color: Highcharts.getOptions().colors[0] // Jane's color
                }, {
                    name: 'John',
                    y: 23,
                    color: Highcharts.getOptions().colors[1] // John's color
                }, {
                    name: 'Joe',
                    y: 19,
                    color: Highcharts.getOptions().colors[2] // Joe's color
                }],
                center: [100, 80],
                size: 100,
                showInLegend: false,
                dataLabels: {
                    enabled: false
                }
            }]
        },
        options2 : {
            chart: {
              zoomType: "xy"
            },
            title: {
              text: "Inversión por dependecia"
            },
            subtitle: {
              text: "Secciones ganadas y perdidas"
            },
            xAxis: {
              categories: [
                "INGUDIS",
                "CECYTEG",
                "CONALEP",
                "IEE",
                "COVEG",
                "IMUG",
                "IECA",
                "SSP",
                "ISSEG",
                "IDEA GTO",
                "IMG",
                "SABES",
                "INAEBA",
                "PGJ",
                "SESEA",
                "ITESI",
                "IPLANEG",
                "FORUM",
                "INFOSPE",
                "UTLB"
              ],
              crosshair: true
            },
            plotOptions: {
              column: {
                stacking: "normal"
              }
            },
            credits: {
              enabled: false
            },
            yAxis: [
              {
                labels: {
                  format: "{value}%",
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                title: {
                  text: "%",
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                allowDecimals: false,
                min: 0,
                max: 100,
                tickInterval: 10
              },
              {
                // Secondary yAxis
                title: {
                  text: "Inversión (mil millones de pesos)",
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                labels: {
                  format: "${value}",
                  style: {
                    color: Highcharts.getOptions().colors[1]
                  }
                },
                opposite: true,
                allowDecimals: false,
                min: 0,
                max: 7,
                tickInterval: 0.5
              }
            ],
            series: [
              {
                type: "column",
                name: "Perdidas",
                color: "#D70126",
                data: [
                  { y: 34.0, text: "54" },
                  { y: 26.5, text: "27" },
                  { y: 26.8, text: "26" },
                  { y: 20.4, text: "10" },
                  { y: 19.6, text: "9" },
                  { y: 26.1, text: "12" },
                  { y: 26.3, text: "10" },
                  { y: 31.1, text: "14" },
                  { y: 20.7, text: "6" },
                  { y: 18.2, text: "4" },
                  { y: 46.4, text: "85" },
                  { y: 35.7, text: "15" },
                  { y: 41.1, text: "23" },
                  { y: 0.0, text: "0" },
                  { y: 0.0, text: "0" },
                  { y: 16.7, text: "1" },
                  { y: 28.6, text: "2" },
                  { y: 0.0, text: "0" },
                  { y: 0.0, text: "0" },
                  { y: 0.0, text: "0" }
                ],
                stack: "obra",
                dataLabels: {
                  enabled: true,
                  formatter: function() {
                    return "" + this.point.text + "";
                  },
                  shadow: false,
                  color: "white",
                  textShadow: false,
                  style: {
                    textShadow: "0px black",
                    fontSize: "0.4em"
                  }
                }
              },
              {
                type: "column",
                name: "Ganadas",
                color: "#1E5DB5",
                data: [
                  { y: 66.0, text: "105" },
                  { y: 73.5, text: "75" },
                  { y: 73.2, text: "71" },
                  { y: 79.6, text: "39" },
                  { y: 80.4, text: "37" },
                  { y: 73.9, text: "34" },
                  { y: 73.7, text: "28" },
                  { y: 68.9, text: "31" },
                  { y: 79.3, text: "23" },
                  { y: 81.8, text: "18" },
                  { y: 53.6, text: "98" },
                  { y: 64.3, text: "27" },
                  { y: 58.9, text: "33" },
                  { y: 100.0, text: "6" },
                  { y: 100.0, text: "5" },
                  { y: 83.3, text: "5" },
                  { y: 71.4, text: "5" },
                  { y: 100.0, text: "3" },
                  { y: 100.0, text: "3" },
                  { y: 100.0, text: "3" }
                ],
                stack: "obra",
                dataLabels: {
                  enabled: true,
                  formatter: function() {
                    return "" + this.point.text + "";
                  },
                  shadow: false,
                  color: "white",
                  textShadow: false,
                  style: {
                    textShadow: "0px black",
                    fontSize: "0.4em"
                  }
                }
              },
              {
                type: "spline",
                name: "Monto (Ganadas)",
                color: "#5EE67F",
                lineWidth: 3.5,
                data: [
                  1.5,
                  1.9,
                  1.1,
                  1.2,
                  0.6,
                  0.5,
                  1.6,
                  10.2,
                  0.4,
                  1.0,
                  1.3,
                  0.3,
                  0.2,
                  1.9,
                  0.0,
                  0.2,
                  0.3,
                  0.1,
                  1.2,
                  0.6
                ],
                marker: {
                  lineWidth: 2,
                  lineColor: Highcharts.getOptions().colors[3],
                  fillColor: "red"
                },
                dataLabels: {
                  enabled: false
                }
              },
              {
                type: "spline",
                name: "Monto (Perdidas)",
                color: "#FF9901",
                lineWidth: 3.5,
                data: [
                  0.1,
                  0.6,
                  0.3,
                  0.1,
                  0.1,
                  0.1,
                  0.2,
                  1.1,
                  0.1,
                  0.0,
                  1.1,
                  0.2,
                  0.1,
                  0.0,
                  0.0,
                  0.0,
                  0.0,
                  0.0,
                  0.0,
                  0.0
                ],
                marker: {
                  lineWidth: 2,
                  lineColor: Highcharts.getOptions().colors[3],
                  fillColor: "white"
                },
                dataLabels: {
                  enabled: false
                },
                legend: {
                  enabled: false
                }
              }
            ]
        },
        options3 : {
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "UTSOE",
                  "ITSPR",
                  "CECCEG",
                  "ITESG",
                  "UPB",
                  "UPGTO",
                  "UTNG",
                  "UVEG",
                  "EPRR",
                  "ITESA",
                  "MIQ",
                  "UPJR",
                  "UPPE",
                  "UTEG",
                  "UTS",
                  "GPI",
                  "UTL",
                  "ITSUR",
                  "UTSMA",
                  "ITESS"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 7,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Perdidas",
                  color: "#D70126",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 25.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 50.0, text: "2" },
                    { y: 66.7, text: "2" },
                    { y: 100.0, text: "1" },
                    { y: 75.0, text: "3" },
                    { y: 100.0, text: "2" }
                  ],
                  stack: "obra",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Ganadas",
                  color: "#1E5DB5",
                  data: [
                    { y: 100.0, text: "3" },
                    { y: 75.0, text: "3" },
                    { y: 100.0, text: "2" },
                    { y: 100.0, text: "2" },
                    { y: 100.0, text: "2" },
                    { y: 100.0, text: "2" },
                    { y: 100.0, text: "2" },
                    { y: 100.0, text: "2" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 50.0, text: "2" },
                    { y: 33.3, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 25.0, text: "1" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "obra",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Ganadas)",
                  color: "#5EE67F",
                  lineWidth: 3.5,
                  data: [
                    0.1,
                    1.3,
                    0.1,
                    0.3,
                    0.1,
                    1.0,
                    0.1,
                    0.5,
                    0.0,
                    0.1,
                    0.0,
                    0.5,
                    0.4,
                    0.1,
                    0.3,
                    0.3,
                    0.0,
                    0.0,
                    0.1,
                    0.0
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    1.2,
                    0.2,
                    0.1,
                    1.0,
                    0.1
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options4 : {
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "IMG",
                  "SABES",
                  "INAEBA",
                  "PGJ",
                  "SESEA",
                  "ITESI",
                  "IPLANEG",
                  "FORUM",
                  "INFOSPE",
                  "UTLB"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 6,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Urbana (Perdidas)",
                  color: "#D70126",
                  data: [
                    { y: 37.3, text: "19" },
                    { y: 25.0, text: "2" },
                    { y: 28.0, text: "7" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 33.3, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Urbana (Ganadas)",
                  color: "#1774B5",
                  data: [
                    { y: 62.7, text: "32" },
                    { y: 75.0, text: "6" },
                    { y: 72.0, text: "18" },
                    { y: 100.0, text: "5" },
                    { y: 100.0, text: "3" },
                    { y: 100.0, text: "4" },
                    { y: 66.7, text: "4" },
                    { y: 100.0, text: "3" },
                    { y: 100.0, text: "3" },
                    { y: 100.0, text: "3" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Perdidas)",
                  color: "#ff4251",
                  data: [
                    { y: 46.2, text: "12" },
                    { y: 42.9, text: "3" },
                    { y: 28.6, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Ganadas)",
                  color: "#A22C82",
                  data: [
                    { y: 53.8, text: "14" },
                    { y: 57.1, text: "4" },
                    { y: 71.4, text: "5" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Perdidas)",
                  color: "#ffa8af",
                  data: [
                    { y: 50.9, text: "54" },
                    { y: 37.0, text: "10" },
                    { y: 58.3, text: "14" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 50.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Ganadas)",
                  color: "#78B539",
                  data: [
                    { y: 49.1, text: "52" },
                    { y: 63.0, text: "17" },
                    { y: 41.7, text: "10" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "2" },
                    { y: 50.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Ganadas)",
                  color: "brown",
                  lineWidth: 3.5,
                  data: [1.3, 0.3, 0.2, 1.9, 0.0, 0.2, 0.3, 0.1, 1.2, 0.6],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [1.1, 0.2, 0.1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options5 : {
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "UTSOE",
                  "ITSPR",
                  "CECCEG",
                  "ITESG",
                  "UPB",
                  "UPGTO",
                  "UTNG",
                  "UVEG",
                  "EPRR",
                  "ITESA"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 6,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Urbana (Perdidas)",
                  color: "#D70126",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 50.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Urbana (Ganadas)",
                  color: "#1774B5",
                  data: [
                    { y: 100.0, text: "1" },
                    { y: 50.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Perdidas)",
                  color: "#ff4251",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Ganadas)",
                  color: "#A22C82",
                  data: [
                    { y: 100.0, text: "2" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Perdidas)",
                  color: "#ffa8af",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Ganadas)",
                  color: "#78B539",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Ganadas)",
                  color: "brown",
                  lineWidth: 3.5,
                  data: [0.1, 1.3, 0.1, 0.3, 0.1, 1.0, 0.1, 0.5, 0.0, 0.1],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options6 : {
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "MIQ",
                  "UPJR",
                  "UPPE",
                  "UTEG",
                  "UTS",
                  "GPI",
                  "UTL",
                  "ITSUR",
                  "UTSMA",
                  "ITESS"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 6,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Urbana (Perdidas)",
                  color: "#D70126",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Urbana (Ganadas)",
                  color: "#1774B5",
                  data: [
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Perdidas)",
                  color: "#ff4251",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Ganadas)",
                  color: "#A22C82",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Perdidas)",
                  color: "#ffa8af",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 33.3, text: "1" },
                    { y: 50.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "2" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Ganadas)",
                  color: "#78B539",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 66.7, text: "2" },
                    { y: 50.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Ganadas)",
                  color: "brown",
                  lineWidth: 3.5,
                  data: [0.0, 0.5, 0.4, 0.1, 0.3, 0.3, 0.0, 0.0, 0.1, 0.0],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [0.0, 0.0, 0.0, 0.0, 0.0, 1.2, 0.2, 0.1, 1.0, 0.1],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options7 : {
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "JUVENTUDES GTO",
                  "SDSH",
                  "SEG",
                  "DIF",
                  "SMAOT",
                  "SDAyR",
                  "PAOT",
                  "CODE",
                  "INIFEG",
                  "COFOCE",
                  "SMEI",
                  "SICOM",
                  "SICES",
                  "ISAPEG",
                  "CEAG",
                  "SDES",
                  "IEC",
                  "INJUG",
                  "SG",
                  "SECTUR",
                  "SFIA"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 7,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Perdidas",
                  color: "#D70126",
                  data: [
                    { y: 30.5, text: "951" },
                    { y: 32.9, text: "825" },
                    { y: 32.7, text: "802" },
                    { y: 36.0, text: "683" },
                    { y: 30.0, text: "393" },
                    { y: 37.7, text: "602" },
                    { y: 29.8, text: "276" },
                    { y: 27.8, text: "231" },
                    { y: 30.3, text: "177" },
                    { y: 10.5, text: "24" },
                    { y: 29.1, text: "104" },
                    { y: 31.7, text: "113" },
                    { y: 19.9, text: "42" },
                    { y: 28.9, text: "78" },
                    { y: 35.3, text: "130" },
                    { y: 32.4, text: "97" },
                    { y: 31.0, text: "71" },
                    { y: 14.3, text: "17" },
                    { y: 23.2, text: "35" },
                    { y: 25.3, text: "40" },
                    { y: 20.2, text: "18" }
                  ],
                  stack: "obra",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Ganadas",
                  color: "#1E5DB5",
                  data: [
                    { y: 69.4, text: "2,165" },
                    { y: 67.0, text: "1,677" },
                    { y: 67.3, text: "1,652" },
                    { y: 63.9, text: "1,211" },
                    { y: 70.0, text: "918" },
                    { y: 62.1, text: "991" },
                    { y: 70.2, text: "651" },
                    { y: 72.2, text: "599" },
                    { y: 69.7, text: "407" },
                    { y: 89.5, text: "205" },
                    { y: 70.9, text: "254" },
                    { y: 68.3, text: "244" },
                    { y: 79.6, text: "168" },
                    { y: 71.1, text: "192" },
                    { y: 64.7, text: "238" },
                    { y: 67.2, text: "201" },
                    { y: 69.0, text: "158" },
                    { y: 84.9, text: "101" },
                    { y: 76.8, text: "116" },
                    { y: 74.7, text: "118" },
                    { y: 79.8, text: "71" }
                  ],
                  stack: "obra",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Ganadas)",
                  color: "#5EE67F",
                  lineWidth: 3.5,
                  data: [
                    23.4,
                    49.3,
                    34.2,
                    30.0,
                    6.4,
                    50.2,
                    0.1,
                    9.3,
                    10.5,
                    1.5,
                    1.7,
                    72.5,
                    6.3,
                    40.8,
                    13.4,
                    51.3,
                    3.9,
                    0.1,
                    14.4,
                    8.8,
                    85.9
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "Monto (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [
                    5.3,
                    19.1,
                    9.7,
                    10.3,
                    0.5,
                    16.4,
                    0.1,
                    1.9,
                    4.0,
                    0.1,
                    0.5,
                    10.3,
                    0.6,
                    5.1,
                    5.4,
                    5.0,
                    1.0,
                    0.0,
                    2.1,
                    1.7,
                    10.7
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options8: {
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "SICOM",
                  "SICES",
                  "ISAPEG",
                  "CEAG",
                  "SDES",
                  "IEC",
                  "INJUG",
                  "SG",
                  "SECTUR",
                  "SFIA"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 6,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Urbana (Perdidas)",
                  color: "#D70126",
                  data: [
                    { y: 21.2, text: "21" },
                    { y: 18.9, text: "24" },
                    { y: 29.5, text: "65" },
                    { y: 28.8, text: "36" },
                    { y: 30.1, text: "41" },
                    { y: 25.5, text: "39" },
                    { y: 13.5, text: "12" },
                    { y: 22.0, text: "27" },
                    { y: 21.4, text: "24" },
                    { y: 21.7, text: "15" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Urbana (Ganadas)",
                  color: "#1774B5",
                  data: [
                    { y: 78.8, text: "78" },
                    { y: 81.1, text: "103" },
                    { y: 70.5, text: "155" },
                    { y: 71.2, text: "89" },
                    { y: 69.9, text: "95" },
                    { y: 74.5, text: "114" },
                    { y: 86.5, text: "77" },
                    { y: 78.0, text: "96" },
                    { y: 78.6, text: "88" },
                    { y: 78.3, text: "54" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Perdidas)",
                  color: "#ff4251",
                  data: [
                    { y: 30.0, text: "18" },
                    { y: 22.7, text: "5" },
                    { y: 21.2, text: "7" },
                    { y: 29.2, text: "14" },
                    { y: 20.0, text: "8" },
                    { y: 34.5, text: "10" },
                    { y: 11.8, text: "2" },
                    { y: 18.2, text: "4" },
                    { y: 25.0, text: "5" },
                    { y: 12.5, text: "2" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Ganadas)",
                  color: "#A22C82",
                  data: [
                    { y: 70.0, text: "42" },
                    { y: 77.3, text: "17" },
                    { y: 78.8, text: "26" },
                    { y: 70.8, text: "34" },
                    { y: 80.0, text: "32" },
                    { y: 65.5, text: "19" },
                    { y: 88.2, text: "15" },
                    { y: 81.8, text: "18" },
                    { y: 75.0, text: "15" },
                    { y: 87.5, text: "14" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Perdidas)",
                  color: "#ffa8af",
                  data: [
                    { y: 37.4, text: "74" },
                    { y: 21.3, text: "13" },
                    { y: 35.3, text: "6" },
                    { y: 41.0, text: "80" },
                    { y: 39.3, text: "48" },
                    { y: 46.8, text: "22" },
                    { y: 25.0, text: "3" },
                    { y: 66.7, text: "4" },
                    { y: 42.3, text: "11" },
                    { y: 25.0, text: "1" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Ganadas)",
                  color: "#78B539",
                  data: [
                    { y: 62.6, text: "124" },
                    { y: 78.7, text: "48" },
                    { y: 64.7, text: "11" },
                    { y: 59.0, text: "115" },
                    { y: 60.7, text: "74" },
                    { y: 53.2, text: "25" },
                    { y: 75.0, text: "9" },
                    { y: 33.3, text: "2" },
                    { y: 57.7, text: "15" },
                    { y: 75.0, text: "3" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Ganadas)",
                  color: "brown",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 63.8],
                    [-0.2+1, 6.7],
                    [-0.2+2, 36.1],
                    [-0.2+3, 7.9],
                    [-0.2+4, 56.1],
                    [-0.2+5, 3.4],
                    [-0.2+6, 0.1],
                    [-0.2+7, 15.8],
                    [-0.2+8, 7.5],
                    [-0.2+9, 94.0],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Ganadas)",
                  color: "#62EFC6",
                  lineWidth: 3.5,
                  data: [
                    8.6,
                    0.1,
                    3.7,
                    2.8,
                    1.2,
                    0.1,
                    0.0,
                    0.9,
                    0.2,
                    5.6,
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Ganadas)",
                  color: "#997777",
                  lineWidth: 3.5,
                  data: [
                    [0.2 + 0, 12.2],
                    [0.2 + 1, 0.6],
                    [0.2 + 2, 7.8],
                    [0.2 + 3, 4.9],
                    [0.2 + 4, 2.5],
                    [0.2 + 5, 1.1],
                    [0.2 + 6, 0.0],
                    [0.2 + 7, 0.1],
                    [0.2 + 8, 2.6],
                    [0.2 + 9, 0.7],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 2.3],
                    [-0.2+1, 0.2],
                    [-0.2+2, 5.1],
                    [-0.2+3, 1.7],
                    [-0.2+4, 3.3],
                    [-0.2+5, 0.8],
                    [-0.2+6, 0.0],
                    [-0.2+7, 2.1],
                    [-0.2+8, 0.9],
                    [-0.2+9, 7.8],
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Perdidas)",
                  color: "#CD62EF",
                  lineWidth: 3.5,
                  data: [
                    2.6,
                    0.3,
                    0.1,
                    0.8,
                    0.1,
                    0.1,
                    0.0,
                    0.1,
                    0.6,
                    1.1], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Perdidas)",
                  color: "#6B6AB0",
                  lineWidth: 3.5,
                  data: [
                    [0.2+0, 7.1],
                    [0.2+1, 0.2],
                    [0.2+2, 0.8],
                    [0.2+3, 3.9],
                    [0.2+4, 2.4],
                    [0.2+5, 0.3],
                    [0.2+6, 0.0],
                    [0.2+7, 0.2],
                    [0.2+8, 0.4],
                    [0.2+9, 3.6]
                    ], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options9:{
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "INGUDIS",
                  "CECYTEG",
                  "CONALEP",
                  "IEE",
                  "COVEG",
                  "IMUG",
                  "IECA",
                  "SSP",
                  "ISSEG",
                  "IDEA GTO"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 6,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Urbana (Perdidas)",
                  color: "#D70126",
                  data: [
                    { y: 33.3, text: "43" },
                    { y: 27.7, text: "18" },
                    { y: 17.9, text: "5" },
                    { y: 17.6, text: "6" },
                    { y: 17.6, text: "6" },
                    { y: 31.4, text: "11" },
                    { y: 26.7, text: "8" },
                    { y: 25.7, text: "9" },
                    { y: 20.8, text: "5" },
                    { y: 15.4, text: "2" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Urbana (Ganadas)",
                  color: "#1774B5",
                  data: [
                    { y: 66.7, text: "86" },
                    { y: 72.3, text: "47" },
                    { y: 82.1, text: "23" },
                    { y: 82.4, text: "28" },
                    { y: 82.4, text: "28" },
                    { y: 68.6, text: "24" },
                    { y: 73.3, text: "22" },
                    { y: 74.3, text: "26" },
                    { y: 79.2, text: "19" },
                    { y: 84.6, text: "11" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Perdidas)",
                  color: "#ff4251",
                  data: [
                    { y: 33.3, text: "6" },
                    { y: 30.4, text: "7" },
                    { y: 7.7, text: "1" },
                    { y: 18.2, text: "2" },
                    { y: 18.2, text: "2" },
                    { y: 10.0, text: "1" },
                    { y: 20.0, text: "1" },
                    { y: 33.3, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Ganadas)",
                  color: "#A22C82",
                  data: [
                    { y: 66.7, text: "12" },
                    { y: 69.6, text: "16" },
                    { y: 92.3, text: "12" },
                    { y: 81.8, text: "9" },
                    { y: 81.8, text: "9" },
                    { y: 90.0, text: "9" },
                    { y: 80.0, text: "4" },
                    { y: 66.7, text: "4" },
                    { y: 100.0, text: "2" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Perdidas)",
                  color: "#ffa8af",
                  data: [
                    { y: 41.7, text: "5" },
                    { y: 14.3, text: "2" },
                    { y: 35.7, text: "20" },
                    { y: 50.0, text: "2" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 33.3, text: "1" },
                    { y: 75.0, text: "3" },
                    { y: 33.3, text: "1" },
                    { y: 22.2, text: "2" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Ganadas)",
                  color: "#78B539",
                  data: [
                    { y: 58.3, text: "7" },
                    { y: 85.7, text: "12" },
                    { y: 64.3, text: "36" },
                    { y: 50.0, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 66.7, text: "2" },
                    { y: 25.0, text: "1" },
                    { y: 66.7, text: "2" },
                    { y: 77.8, text: "7" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Ganadas)",
                  color: "brown",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 0.5],
                    [-0.2+1, 1.6],
                    [-0.2+2, 0.9],
                    [-0.2+3, 1.1],
                    [-0.2+4, 0.6],
                    [-0.2+5, 0.5],
                    [-0.2+6, 1.6],
                    [-0.2+7, 9.1],
                    [-0.2+8, 0.4],
                    [-0.2+9, 1.1],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Ganadas)",
                  color: "#62EFC6",
                  lineWidth: 3.5,
                  data: [
                    0.0,
                    0.3,
                    0.2,
                    0.2,
                    0.1,
                    0.0,
                    0.0,
                    2.8,
                    0.0,
                    0.0,
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Ganadas)",
                  color: "#997777",
                  lineWidth: 3.5,
                  data: [
                    [0.2 + 0, 1.3],
                    [0.2 + 1, 0.3],
                    [0.2 + 2, 0.2],
                    [0.2 + 3, 0.0],
                    [0.2 + 4, 0.0],
                    [0.2 + 5, 0.0],
                    [0.2 + 6, 0.3],
                    [0.2 + 7, 0.0],
                    [0.2 + 8, 0.1],
                    [0.2 + 9, 0.0],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 0.1],
                    [-0.2+1, 0.4],
                    [-0.2+2, 0.2],
                    [-0.2+3, 0.0],
                    [-0.2+4, 0.2],
                    [-0.2+5, 0.1],
                    [-0.2+6, 0.1],
                    [-0.2+7, 0.5],
                    [-0.2+8, 0.0],
                    [-0.2+9, 0.0],
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Perdidas)",
                  color: "#CD62EF",
                  lineWidth: 3.5,
                  data: [
                    0.0,
                    0.3,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.6,
                    0.0,
                    0.0], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Perdidas)",
                  color: "#6B6AB0",
                  lineWidth: 3.5,
                  data: [
                    [0.2+0, 0.0],
                    [0.2+1, 0.0],
                    [0.2+2, 0.1],
                    [0.2+3, 0.1],
                    [0.2+4, 0.0],
                    [0.2+5, 0.0],
                    [0.2+6, 0.1],
                    [0.2+7, 0.2],
                    [0.2+8, 0.1],
                    [0.2+9, 0.0]
                    ], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options10:{
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "IMG",
                  "SABES",
                  "INAEBA",
                  "PGJ",
                  "SESEA",
                  "ITESI",
                  "IPLANEG",
                  "FORUM",
                  "INFOSPE",
                  "UTLB"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 6,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Urbana (Perdidas)",
                  color: "#D70126",
                  data: [
                    { y: 37.3, text: "19" },
                    { y: 25.0, text: "2" },
                    { y: 28.0, text: "7" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 33.3, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Urbana (Ganadas)",
                  color: "#1774B5",
                  data: [
                    { y: 62.7, text: "32" },
                    { y: 75.0, text: "6" },
                    { y: 72.0, text: "18" },
                    { y: 100.0, text: "5" },
                    { y: 100.0, text: "3" },
                    { y: 100.0, text: "4" },
                    { y: 66.7, text: "4" },
                    { y: 100.0, text: "3" },
                    { y: 100.0, text: "3" },
                    { y: 100.0, text: "3" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Perdidas)",
                  color: "#ff4251",
                  data: [
                    { y: 46.2, text: "12" },
                    { y: 42.9, text: "3" },
                    { y: 28.6, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Ganadas)",
                  color: "#A22C82",
                  data: [
                    { y: 53.8, text: "14" },
                    { y: 57.1, text: "4" },
                    { y: 71.4, text: "5" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Perdidas)",
                  color: "#ffa8af",
                  data: [
                    { y: 50.9, text: "54" },
                    { y: 37.0, text: "10" },
                    { y: 58.3, text: "14" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 50.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Ganadas)",
                  color: "#78B539",
                  data: [
                    { y: 49.1, text: "52" },
                    { y: 63.0, text: "17" },
                    { y: 41.7, text: "10" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "2" },
                    { y: 50.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Ganadas)",
                  color: "brown",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 0.8],
                    [-0.2+1, 0.0],
                    [-0.2+2, 0.2],
                    [-0.2+3, 2.1],
                    [-0.2+4, 0.0],
                    [-0.2+5, 0.3],
                    [-0.2+6, 0.2],
                    [-0.2+7, 0.1],
                    [-0.2+8, 1.4],
                    [-0.2+9, 0.7],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Ganadas)",
                  color: "#62EFC6",
                  lineWidth: 3.5,
                  data: [
                    0.2,
                    0.2,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Ganadas)",
                  color: "#997777",
                  lineWidth: 3.5,
                  data: [
                    [0.2 + 0, 0.5],
                    [0.2 + 1, 0.2],
                    [0.2 + 2, 0.0],
                    [0.2 + 3, 0.1],
                    [0.2 + 4, 0.0],
                    [0.2 + 5, 0.0],
                    [0.2 + 6, 0.2],
                    [0.2 + 7, 0.0],
                    [0.2 + 8, 0.0],
                    [0.2 + 9, 0.0],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 0.4],
                    [-0.2+1, 0.0],
                    [-0.2+2, 0.0],
                    [-0.2+3, 0.0],
                    [-0.2+4, 0.0],
                    [-0.2+5, 0.0],
                    [-0.2+6, 0.0],
                    [-0.2+7, 0.0],
                    [-0.2+8, 0.0],
                    [-0.2+9, 0.0],
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Perdidas)",
                  color: "#CD62EF",
                  lineWidth: 3.5,
                  data: [
                    0.1,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Perdidas)",
                  color: "#6B6AB0",
                  lineWidth: 3.5,
                  data: [
                    [0.2+0, 0.7],
                    [0.2+1, 0.2],
                    [0.2+2, 0.1],
                    [0.2+3, 0.0],
                    [0.2+4, 0.0],
                    [0.2+5, 0.0],
                    [0.2+6, 0.0],
                    [0.2+7, 0.0],
                    [0.2+8, 0.0],
                    [0.2+9, 0.0]
                    ], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options11:{
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "UTSOE",
                  "ITSPR",
                  "CECCEG",
                  "ITESG",
                  "UPB",
                  "UPGTO",
                  "UTNG",
                  "UVEG",
                  "EPRR",
                  "ITESA"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 6,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Urbana (Perdidas)",
                  color: "#D70126",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 50.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Urbana (Ganadas)",
                  color: "#1774B5",
                  data: [
                    { y: 100.0, text: "1" },
                    { y: 50.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Perdidas)",
                  color: "#ff4251",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Ganadas)",
                  color: "#A22C82",
                  data: [
                    { y: 100.0, text: "2" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "2" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Perdidas)",
                  color: "#ffa8af",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Ganadas)",
                  color: "#78B539",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Ganadas)",
                  color: "brown",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 0.0],
                    [-0.2+1, 0.6],
                    [-0.2+2, 0.1],
                    [-0.2+3, 0.1],
                    [-0.2+4, 0.0],
                    [-0.2+5, 0.3],
                    [-0.2+6, 0.0],
                    [-0.2+7, 0.6],
                    [-0.2+8, 0.0],
                    [-0.2+9, 0.1],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Ganadas)",
                  color: "#62EFC6",
                  lineWidth: 3.5,
                  data: [
                    0.1,
                    0.2,
                    0.0,
                    0.0,
                    0.1,
                    0.0,
                    0.1,
                    0.0,
                    0.0,
                    0.0,
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Ganadas)",
                  color: "#997777",
                  lineWidth: 3.5,
                  data: [
                    [0.2 + 0, 0.0],
                    [0.2 + 1, 0.8],
                    [0.2 + 2, 0.0],
                    [0.2 + 3, 0.3],
                    [0.2 + 4, 0.0],
                    [0.2 + 5, 0.8],
                    [0.2 + 6, 0.0],
                    [0.2 + 7, 0.0],
                    [0.2 + 8, 0.0],
                    [0.2 + 9, 0.0],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 0.0],
                    [-0.2+1, 0.0],
                    [-0.2+2, 0.0],
                    [-0.2+3, 0.0],
                    [-0.2+4, 0.0],
                    [-0.2+5, 0.0],
                    [-0.2+6, 0.0],
                    [-0.2+7, 0.0],
                    [-0.2+8, 0.0],
                    [-0.2+9, 0.0],
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Perdidas)",
                  color: "#CD62EF",
                  lineWidth: 3.5,
                  data: [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Perdidas)",
                  color: "#6B6AB0",
                  lineWidth: 3.5,
                  data: [
                    [0.2+0, 0.0],
                    [0.2+1, 0.0],
                    [0.2+2, 0.0],
                    [0.2+3, 0.0],
                    [0.2+4, 0.0],
                    [0.2+5, 0.0],
                    [0.2+6, 0.0],
                    [0.2+7, 0.0],
                    [0.2+8, 0.0],
                    [0.2+9, 0.0]
                    ], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options12:{
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "MIQ",
                  "UPJR",
                  "UPPE",
                  "UTEG",
                  "UTS",
                  "GPI",
                  "UTL",
                  "ITSUR",
                  "UTSMA",
                  "ITESS"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 6,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Urbana (Perdidas)",
                  color: "#D70126",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Urbana (Ganadas)",
                  color: "#1774B5",
                  data: [
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Perdidas)",
                  color: "#ff4251",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Ganadas)",
                  color: "#A22C82",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Perdidas)",
                  color: "#ffa8af",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 33.3, text: "1" },
                    { y: 50.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "2" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Ganadas)",
                  color: "#78B539",
                  data: [
                    { y: 0.0, text: "0" },
                    { y: 100.0, text: "1" },
                    { y: 100.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 66.7, text: "2" },
                    { y: 50.0, text: "1" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" },
                    { y: 0.0, text: "0" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.4em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Ganadas)",
                  color: "brown",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 0.0],
                    [-0.2+1, 0.0],
                    [-0.2+2, 0.0],
                    [-0.2+3, 0.1],
                    [-0.2+4, 0.0],
                    [-0.2+5, 0.0],
                    [-0.2+6, 0.0],
                    [-0.2+7, 0.0],
                    [-0.2+8, 0.0],
                    [-0.2+9, 0.0],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Ganadas)",
                  color: "#62EFC6",
                  lineWidth: 3.5,
                  data: [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.3,
                    0.0,
                    0.0,
                    0.0,
                    0.2,
                    0.0,
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Ganadas)",
                  color: "#997777",
                  lineWidth: 3.5,
                  data: [
                    [0.2 + 0, 0.0],
                    [0.2 + 1, 0.6],
                    [0.2 + 2, 0.5],
                    [0.2 + 3, 0.0],
                    [0.2 + 4, 0.0],
                    [0.2 + 5, 0.4],
                    [0.2 + 6, 0.0],
                    [0.2 + 7, 0.0],
                    [0.2 + 8, 0.0],
                    [0.2 + 9, 0.0],
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 0.0],
                    [-0.2+1, 0.0],
                    [-0.2+2, 0.0],
                    [-0.2+3, 0.0],
                    [-0.2+4, 0.0],
                    [-0.2+5, 0.8],
                    [-0.2+6, 0.0],
                    [-0.2+7, 0.0],
                    [-0.2+8, 0.0],
                    [-0.2+9, 0.0],
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Perdidas)",
                  color: "#CD62EF",
                  lineWidth: 3.5,
                  data: [
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.0,
                    0.1], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Perdidas)",
                  color: "#6B6AB0",
                  lineWidth: 3.5,
                  data: [
                    [0.2+0, 0.0],
                    [0.2+1, 0.0],
                    [0.2+2, 0.0],
                    [0.2+3, 0.0],
                    [0.2+4, 0.0],
                    [0.2+5, 0.6],
                    [0.2+6, 0.3],
                    [0.2+7, 0.1],
                    [0.2+8, 1.2],
                    [0.2+9, 0.0]
                    ], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        },
        options13:{
            chart: {
                zoomType: "xy"
              },
              title: {
                text: "Inversión por dependecia"
              },
              subtitle: {
                text: "Secciones ganadas y perdidas"
              },
              xAxis: {
                categories: [
                  "JUVENTUDES GTO",
                  "SDSH",
                  "SEG",
                  "DIF",
                  "SMAOT",
                  "SDAyR",
                  "PAOT",
                  "CODE",
                  "INIFEG",
                  "COFOCE",
                  "SMEI"
                ],
                crosshair: true
              },
              plotOptions: {
                column: {
                  stacking: "normal"
                }
              },
              credits: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    format: "{value}%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  title: {
                    text: "%",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  allowDecimals: false,
                  min: 0,
                  max: 100,
                  tickInterval: 10
                },
                {
                  // Secondary yAxis
                  title: {
                    text: "Inversión (mil millones de pesos)",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  labels: {
                    format: "${value}",
                    style: {
                      color: Highcharts.getOptions().colors[1]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  min: 0,
                  max: 6,
                  tickInterval: 0.5
                }
              ],
              series: [
                {
                  type: "column",
                  name: "Urbana (Perdidas)",
                  color: "#D70126",
                  data: [
                    { y: 25.1, text: "468" },
                    { y: 28.2, text: "383" },
                    { y: 26.2, text: "318" },
                    { y: 30.3, text: "193" },
                    { y: 23.8, text: "144" },
                    { y: 35.1, text: "155" },
                    { y: 25.3, text: "122" },
                    { y: 26.6, text: "163" },
                    { y: 30.2, text: "77" },
                    { y: 9.3, text: "18" },
                    { y: 27.9, text: "74" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.3em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Urbana (Ganadas)",
                  color: "#1774B5",
                  data: [
                    { y: 74.9, text: "1397" },
                    { y: 71.8, text: "977" },
                    { y: 73.8, text: "895" },
                    { y: 69.7, text: "445" },
                    { y: 76.2, text: "462" },
                    { y: 64.9, text: "286" },
                    { y: 74.7, text: "360" },
                    { y: 73.4, text: "450" },
                    { y: 69.8, text: "178" },
                    { y: 90.7, text: "176" },
                    { y: 72.1, text: "191" }
                  ],
                  stack: "urbana",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.3em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Perdidas)",
                  color: "#ff4251",
                  data: [
                    { y: 29.5, text: "66" },
                    { y: 28.7, text: "60" },
                    { y: 28.8, text: "61" },
                    { y: 29.5, text: "62" },
                    { y: 29.1, text: "41" },
                    { y: 31.7, text: "59" },
                    { y: 29.2, text: "35" },
                    { y: 29.3, text: "24" },
                    { y: 23.1, text: "18" },
                    { y: 0.0, text: "0" },
                    { y: 25.5, text: "12" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.3em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Mixta (Ganadas)",
                  color: "#A22C82",
                  data: [
                    { y: 70.5, text: "158" },
                    { y: 71.3, text: "149" },
                    { y: 71.2, text: "151" },
                    { y: 70.5, text: "148" },
                    { y: 70.9, text: "100" },
                    { y: 68.3, text: "127" },
                    { y: 70.8, text: "85" },
                    { y: 70.7, text: "58" },
                    { y: 76.9, text: "60" },
                    { y: 100.0, text: "14" },
                    { y: 74.5, text: "35" }
                  ],
                  stack: "mixta",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.3em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Perdidas)",
                  color: "#ffa8af",
                  data: [
                    { y: 40.6, text: "417" },
                    { y: 40.9, text: "382" },
                    { y: 41.1, text: "423" },
                    { y: 40.9, text: "428" },
                    { y: 36.9, text: "208" },
                    { y: 40.2, text: "388" },
                    { y: 36.6, text: "119" },
                    { y: 32.6, text: "44" },
                    { y: 32.7, text: "82" },
                    { y: 28.6, text: "6" },
                    { y: 39.1, text: "18" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.3em"
                    }
                  }
                },
                {
                  type: "column",
                  name: "Rural (Ganadas)",
                  color: "#78B539",
                  data: [
                    { y: 59.4, text: "610" },
                    { y: 59.1, text: "551" },
                    { y: 58.9, text: "606" },
                    { y: 59.1, text: "618" },
                    { y: 63.1, text: "356" },
                    { y: 59.8, text: "578" },
                    { y: 63.4, text: "206" },
                    { y: 67.4, text: "91" },
                    { y: 67.3, text: "169" },
                    { y: 71.4, text: "15" },
                    { y: 60.9, text: "28" }
                  ],
                  stack: "rural",
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return "" + this.point.text + "";
                    },
                    shadow: false,
                    color: "white",
                    textShadow: false,
                    style: {
                      textShadow: "0px black",
                      // fontSize: "0.3em"
                    }
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Ganadas)",
                  color: "brown",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 22.9],
                    [-0.2+1, 35.4],
                    [-0.2+2, 26.0],
                    [-0.2+3, 19.9],
                    [-0.2+4, 5.5],
                    [-0.2+5, 34.1],
                    [-0.2+6, 0.1],
                    [-0.2+7, 9.4],
                    [-0.2+8, 6.5],
                    [-0.2+9, 1.6],
                    [-0.2+10, 1.4]
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Ganadas)",
                  color: "#62EFC6",
                  lineWidth: 3.5,
                  data: [
                    2.4,
                    8.6,
                    5.1,
                    3.6,
                    0.2,
                    7.2,
                    0.0,
                    1.1,
                    2.1,
                    0.0,
                    0.4,
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Ganadas)",
                  color: "#997777",
                  lineWidth: 3.5,
                  data: [
                    [0.2 + 0, 1.9],
                    [0.2 + 1, 13.5],
                    [0.2 + 2, 8.8],
                    [0.2 + 3, 11.5],
                    [0.2 + 4, 1.9],
                    [0.2 + 5, 17.2],
                    [0.2 + 6, 0.0],
                    [0.2 + 7, 0.3],
                    [0.2 + 8, 3.6],
                    [0.2 + 9, 0.1],
                    [0.2 + 10, 0.2]
                  ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "red"
                  },
                  dataLabels: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Urbana (Perdidas)",
                  color: "#FF9901",
                  lineWidth: 3.5,
                  data: [
                    [-0.2+0, 4.2],
                    [-0.2+1, 10.5],
                    [-0.2+2, 5.7],
                    [-0.2+3, 2.9],
                    [-0.2+4, 0.3],
                    [-0.2+5, 5.8],
                    [-0.2+6, 0.0],
                    [-0.2+7, 1.4],
                    [-0.2+8, 2.3],
                    [-0.2+9, 0.1],
                    [-0.2+10, 0.5]
                    ],
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Mixto (Perdidas)",
                  color: "#CD62EF",
                  lineWidth: 3.5,
                  data: [0.7,
                    3.2,
                    1.0,
                    1.3,
                    0.1,
                    2.3,
                    0.0,
                    0.5,
                    0.4,
                    0.0,
                    0.1], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                },
                {
                  type: "spline",
                  name: "$ Rural (Perdidas)",
                  color: "#6B6AB0",
                  lineWidth: 3.5,
                  data: [
                    [0.2+0, 1.2],
                    [0.2+1, 8.6],
                    [0.2+2, 4.5],
                    [0.2+3, 7.9],
                    [0.2+4, 0.2],
                    [0.2+5, 11.0],
                    [0.2+6, 0.0],
                    [0.2+7, 0.4],
                    [0.2+8, 2.0],
                    [0.2+9, 0.0],
                    [0.2+10, 0.0]
                    ], 
                  marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: "white"
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    enabled: false
                  }
                }
              ]
        }
    }
]

export default Opcion ;