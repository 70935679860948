import React, { useEffect, useState } from "react";


//Zincri imports
import ChoropethMap from "../../../components/Maps/Promocion/ChoropethMapV5MovilCompromisosLibre.jsx";
import { getVars } from "../../../GlobalStorage";
import {
  Button
} from "reactstrap";
import { URL_GENERAL } from "../../../data/Consts";
import ReactBSAlert from "react-bootstrap-sweetalert";


const Mapas = (props) => {

  const [dataGeoJson, setDataGeoJson] = useState(
    {
      "type": "FeatureCollection",
      "features": [
      ]
    }
  );
  //const [menu,_setmenu]= useState({});
  const [tipoMapa, setTipoMapa] = useState(
    { value: "seccion", label: "Seccional" }

  );
  //const [textoInput, setTextoInput] = useState("");
  const zoom = 9;
  //const [derechoMapa, setDerechoMapa] = useState([]);
  //const [catTipoMapa, setCatTipoMapa] = useState([]);

  const [rangos, setRangos] = useState([]);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState("");
  const hostserver = URL_GENERAL;
  const [exportState, setExportState] = useState(0);
  const [DeviceID, setDeviceID] = useState(0);
  const [IdUser, setIdUser] = useState(0);

  const [dataTablaActual, setDataTablaActual] = useState([]);
  /* const position = {
    latitude: 20.678416,
    longitude: -101.354231,
  }; */
  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  useEffect(() => {

  }, [dataGeoJson]);


  const basicAlert = (
    <ReactBSAlert
      style={{
        display: "block",
        marginTop: "-100px",
        padding: '2px 4px',
        font: '10px/12px Arial, Helvetica, sans-serif'
      }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );
  const clickDownloadFile = (e) => {
    //const DeviceID = "1";
    //const IdUser = 1;
    if (exportState === 1) {
      var url = new URL(
        hostserver + `getArchivoMapaM?DeviceID=${DeviceID}&idUser=${IdUser}&HashCode=${e.feature.properties.HashCode}`
      );
      window.open(url, "_blank");

    }
    else {
      setMensajeAlert("¡No cuenta con permisos para exportar!");
      setFlagAlert(true);
    }

  }

  async function getRangos() {
    let config = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };
    try {
      let res = await fetch(hostserver + "getRangosCompromisosGtoML", config);
      let data = await res.json();
      setRangos(data);
    } catch (error) {
      setMensajeAlert("Favor de renovar sesión, si el error persiste no cuenta con permisos para ver este mapa.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }

  /* function selectDerecho(){
    //let derecho_mapa = getVars("Token").user.Responsabilidad.label; 
    setDerechoMapa(
      derecho_mapa === 'Coordinador General' ||
      derecho_mapa === 'Supervisor Estatal Electoral' ||
      derecho_mapa === 'Coordinador Estatal Territorial'?[1,2,3,4]:
      derecho_mapa === 'Regional'?[1,2,3,4]:
      derecho_mapa === 'Municipal'?[1,2,3,]:
      derecho_mapa === 'Poligonal'?[1,2]:
      derecho_mapa === 'Seccional'?[1]:
      []
    );
    if(derecho_mapa === 'Coordinador General' ||
    derecho_mapa === 'Supervisor Estatal Electoral' ||
    derecho_mapa === 'Coordinador Estatal Territorial' ||
    derecho_mapa === 'Regional'){
      getRegiones("","GET");
    }
    if(derecho_mapa === 'Municipal'){
      getMunicipios("","GET");
    }
    if(derecho_mapa === 'Poligonal'){
      getPoligonos("","GET");
    }
    if(derecho_mapa === 'Seccional'){
      getSecciones("","GET");
    }

  } */

  /* function setValue(item){
    setMyData(item);
    alert("Value:  " + item);
  } */

  function btnBuscar() {
    var exportMovil = parseInt(document.getElementById("exportMovil").innerHTML, 10);

    const Seccion = document.getElementById("dataVars").innerHTML;
    const idUser = document.getElementById("idUser").innerHTML;
    const deviceID = document.getElementById("DeviceID").innerHTML;
    setExportState(exportMovil);
    setDeviceID(deviceID);
    setIdUser(idUser);
    if (Seccion.length > 0) {
      btnSearchSeccion(Seccion, idUser, deviceID);
    } else {
      setMensajeAlert("¡Favor de especificar una sección!");
      setFlagAlert(true);
      //alert("Favor de especificar una sección: ", Seccion);
    }


  }

  async function btnSearchSeccion(Seccion, idUser, DeviceID) {
    getRangos();
    setDataGeoJson({});


    try {
      let config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ clave: Seccion, DeviceID: DeviceID, idUser: idUser }),
      };


      let res = await fetch(
        hostserver + "getSeccionesCompromisosGtoBusqueda",
        config
      );
      let data = await res.json();
      var json_parser_secciones = JSON.parse(data.seccion);
      var json_parser_manzanas = JSON.parse(data.manzanas);

      if (json_parser_secciones.features === null) {
        throw new Error("features null");
      }
      if (json_parser_manzanas.features === null) {
        json_parser_manzanas = {
          "type": "FeatureCollection",
          "features": [
          ]
        }
      }
      json_parser_manzanas.features.forEach(element => json_parser_secciones.features.push(element));
      setDataGeoJson(json_parser_secciones);
    } catch (e) {
      setMensajeAlert("Error 001: Renueve sesión, si el error persiste no tiene suficientes permisos.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }



  /* async function buscarSeccion(e) {
    setDataGeoJson({});
    const hostserver = "https://api.siangto.com/api/";
    //const hostserver = "https://api.siangto.com/api/";

    try 
    {
         let  config = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ clave: textoInput }),
          };
        
        
        let res = await fetch(
          hostserver + "getSeccionesCompromisosGtoUno",
          config
        );
        let data = await res.json();
        var json_parser = JSON.parse(data);
        setDataGeoJson(json_parser);
    } catch (error) {
    } 
  } */



  async function getRegiones(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }

      let res = await fetch(
        hostserver + "getRegionesCompromisosGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Favor de renovar sesión, si el problema persiste es probable que no cuente con permisos para ver este mapa.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function getMunicipios(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + "getMunicipiosCompromisosGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Favor de renovar sesión, si el problema persiste es probable que no cuente con permisos para ver este mapa.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function getSecciones(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + "getSeccionesCompromisosGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Favor de renovar sesión, si el problema persiste es probable que no cuente con permisos para ver este mapa.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }
  async function getPoligonos(clave_parametro, method) {
    const token = getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + "getPoligonosCompromisosGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
    } catch (error) {
      setMensajeAlert("Favor de renovar sesión, si el problema persiste es probable que no cuente con permisos para ver este mapa.");
      setFlagAlert(true);
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }

  const hadleDataTablaActual = (e) => {
    setDataTablaActual(e);
  }

  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapaparametros(e);
  }

  const handleTipoMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapa(e);
  }
  function selectmapa(tipomapa) {
    if (tipomapa.value === 'region') {
      getRegiones("", "GET");
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios("", "GET");
    }
    if (tipomapa.value === 'poligono') {
      getPoligonos("", "GET");
    }
    if (tipomapa.value === 'seccion') {
      getSecciones("", "GET");
    }
  }

  function selectmapaparametros(tipomapa) {
    if (tipomapa.value === 'region') {
      getRegiones(tipomapa.clave, "POST");
    }
    if (tipomapa.value === 'municipio') {
      getMunicipios(tipomapa.clave, "POST");
    }
    if (tipomapa.value === 'poligono') {
      getPoligonos(tipomapa.clave, "POST");
    }
    if (tipomapa.value === 'seccion') {
      getSecciones(tipomapa.clave, "POST");
    }
  }

  return (
    <div className="wrapper">

      <div hidden id="exportMovil" name="exportMovil" ></div>
      <div hidden id="dataVars" name="dataVars" ></div>
      <div hidden id="idUser" name="idUser" ></div>
      <div hidden id="DeviceID" name="DeviceID" ></div>
      <div hidden id="tabla" name="tabla" >{JSON.stringify(dataTablaActual)}</div>
      <div className="content">
        <Button hidden id="btnS" name="btnS" onClick={() => btnBuscar()}>Buscar</Button>
        <>
          {FlagAlert ? basicAlert : <></>}
          <ChoropethMap
            key={"ChoropethMapV4"}
            center={[position.latitude, position.longitude]}
            zoom={zoom}
            data={dataGeoJson}
            rangos={rangos}
            tipo={tipoMapa.value}
            label_tipo={tipoMapa.label}
            handleTipoMapa={handleTipoMapa}
            handleDetalleMapa={handleDetalleMapa}
            derecho_mapa={[]}
            hadleDataTabla={hadleDataTablaActual}
            clickDownloadFile={clickDownloadFile}
            exportState={exportState}
          ></ChoropethMap>

        </>
      </div>
    </div>
  );
};

export default Mapas;
