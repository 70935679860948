import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, Progress, Badge, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import CasillasResumenChart from "components/Sije/CasillasResumenChart";
import useWindowDimensions from "components/useWindowDimensions";

const CasillasResumen = ({ data, isLoading }) => {
    const { height, width } = useWindowDimensions();
    const [total, setTotal] = useState({
        CasillasInstaladas: 0,
        CasillasAprobadas: 0,
        CasillasAperturadas: 0,
        CasillasCerradas: 0,
        VotacionCerrada: 0
    });

    const [dataChart, setDataChart] = useState({
        data: [0, 0, 0],
        height: height * 0.15,
    })

    useEffect(() => {
        setTotal(data);
        setDataChart({ ...dataChart, data: [{ y: data.CasillasAprobadas, color: "#596269" }, { y: data.CasillasInstaladas, color: "#43AED2" }, { y: data.CasillasAperturadas, color: "#43AED2" }, { y: data.CasillasCerradas, color: "#FABB47" }, { y: data.VotacionCerrada, color: "#A90011" }] });
    }, [data])

    return (
        <Row>
            <Col>
                <Card className="card-stats">
                    <CardHeader style={{ backgroundColor: "#005CB9" }}>
                        <Row>
                            <Col>
                                {/* <div className="pull-right text-white" style={{ fontSize: "20px" }}>977 Aprobadas</div> */}
                                <h5 className="text-white text-medium margin-none strong"><i className="fa fa-home"></i> <Link className="text-white text-medium margin-none strong" to="casilla_operacion">Casillas</Link></h5>
                                {/* <h7 class="text-white">Casillas Incidentadas 2.36%</h7> */}
                                {/* <Progress max={100} animated value={20} className="bg-info-dark" color="white">
                                    </Progress> */}
                                {/* <br /> */}
                            </Col>
                            {isLoading && (
                                <Col md="2">
                                    <Spinner
                                        className='text-white text-medium margin-none strong'
                                    />
                                </Col>
                            )}
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="8" lg="8" sm="12">
                                <CasillasResumenChart data={dataChart}></CasillasResumenChart>
                            </Col>
                            <Col md="4" lg="4" sm="12">
                                <div className="innerAll">
                                    <ul className="list-unstyled">
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-muted"></i> <strong>{total.CasillasAprobadas}</strong> Aprobadas</li>
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-info"></i> <strong>{total.CasillasInstaladas}</strong> Instaladas</li>
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-success"></i> <strong>{total.CasillasAperturadas}</strong> Aperturadas</li>
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-warning"></i> <strong>{total.CasillasCerradas}</strong> Casillas Cerradas</li>
                                        <li className="innerAll half sije-subtitle"><i className="fa fa-fw fa-square text-danger"></i> <strong>{total.VotacionCerrada}</strong>Cierre Votación</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default CasillasResumen;