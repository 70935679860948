import React, {useState, useEffect} from "react";

import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official';

Highcharts.setOptions({
	lang: {
  	thousandsSep: ','
  }
})

//HC_exporting(Highcharts);


const ChartPorSeccionByMunicipio = ({ idRegion, Categorias, Avance, Meta, Subtitle, yAxisText, MetaLine }) => {

  const [charCategorias, setChartCategorias] = useState(null);
  const [charAvance, setChartAvance] = useState(null);
  const [charMeta, setChartMeta] = useState(null);
  const [charSubtitle, setChartSubtitle] = useState(null);
  const [charMetaLine, setChartMetaLine] = useState(null);

  useEffect(() => {
  },[Categorias])

    const options = {
      chart: {
        type: 'line'
    },
    title: {
        text: 'Meta vs Avance'
    },
    subtitle: {
        text: Subtitle
    },
    xAxis: {
        categories: Categorias
    },
    yAxis: {
        title: {
            text: yAxisText
        },
        plotLines: [{
          value: MetaLine,
          color: 'red',
          dashStyle: 'shortdash',
          width: 2,
          label: {
              text: 'Meta'
          }
      }]
    },
    plotOptions: {
        line: {
            dataLabels: {
                enabled: true
            },
            enableMouseTracking: false
        }
    },
    series: [{
        name: 'Avance',
        data: Avance
    }, {
      name: 'Meta',
      data: Meta
  }]
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            type='column'
        />
    )
}

export default ChartPorSeccionByMunicipio;