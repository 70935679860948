import React, { useState, } from "react";
import {
	Row,
	Col,
} from "reactstrap";
import Notifications, { notify } from 'react-notify-toast';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';
import DiaDInstalacion from 'components/Sije/DiaDInstalacion';
import EjecutarApi from "../../data/getPOST";
import { getVars } from "GlobalStorage";
import { CircleSpinner } from "react-spinners-kit";
import DiaDApertura from 'components/Sije/DiaDApertura';

const SijeDiaDPaseLista = (props) => {
	const token = getVars("Token");
	const [loading, setLoading] = useState(false);

	const savePaseLista = (parametro) => {
		var paselista = '';

		//let re = new RegExp("([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s])+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])+[\s]?([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])");
		if(parametro.idRG.value !== 0 )
		{paselista = {
			idUser: (parametro.idRG.value === 0)? null : parametro.idRG.value,
			Seccion: parametro.Seccion,
			NombreCasilla: parametro.NombreCasilla.label,
			FechaHora: `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.segundos}`,
			UserReporto:parametro.reporto
		}
		}else if(parametro.reporto !== ""){
			paselista = {
				idUser: (parametro.idRG.value === 0)? null : parametro.idRG.value,
				Seccion: parametro.Seccion,
				NombreCasilla: parametro.NombreCasilla.label,
				FechaHora: `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.segundos}`,
				UserReporto:parametro.reporto
			}
		} else{
			setLoading(false);
			notify.show(`Hace Falta registrar un Responsable`, 'warning', 5000);

			return true;
		}
		//setPasedeLista
		EjecutarApi(paselista, 'setPaseLista', token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.results) {
					notify.show(res.message, 'success', 5000);
				}
				else {

					notify.show(res.message, 'success', 5000);
				}

			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'error', 5000);
			});
	
		}

	const saveIntalacionCasilla = (parametro) => {
		var instalacionCasilla = '';
		if(parametro.idRG.value !== 0){
		instalacionCasilla = {
			idUser: (parametro.idRG.value === 0)? null : parametro.idRG.value,
			Seccion: parametro.Seccion,
			NombreCasilla: parametro.NombreCasilla.label,
			FechaHora: `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.minutos}:${parametro.segundos}`,
			tipoBitacora: 1,
			UserReporto:(parametro.reporto === "")? null : parametro.reporto
		}
		}else if(parametro.reporto !== ""){
			instalacionCasilla = {
				idUser: (parametro.idRG.value === 0)? null : parametro.idRG.value,
				Seccion: parametro.Seccion,
				NombreCasilla: parametro.NombreCasilla.label,
				FechaHora: `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.minutos}:${parametro.segundos}`,
				tipoBitacora: 1,
				UserReporto:(parametro.reporto === "")? null : parametro.reporto
			}
		
		}else{
			setLoading(false);
			notify.show(`Hace Falta registrar un Responsable`, 'warning', 5000);
			return true;
		}

		setBitacoraSije(instalacionCasilla);
		

	}
	const saveAperturaCasilla = (parametro) => {

		if(parametro.idRG.value !== 0 ){
		var aperturaCasilla = {
			"Seccion": parametro.seccion,
			"NombreCasilla": parametro.casilla.label,
			"FechaHora": `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.minutos}:${parametro.segundos}`,//"2021-05-01 07:30:00",
			"idUser": (parametro.idRG.value === 0)? null : parametro.idRG.value,
			"tipoBitacora": 2,
			"NumFuncionarios": parametro.numeroFuncionario,
			"TomadosFila": parametro.tomadosXfila,
			"UserReporto":(parametro.reporto === "")? null : parametro.reporto
		}
		}else if(parametro.reporto !== ""){
			var aperturaCasilla = {
				"Seccion": parametro.seccion,
				"NombreCasilla": parametro.casilla.label,
				"FechaHora": `${parametro.anio}-${parametro.mes}-${parametro.dia} ${parametro.hora}:${parametro.minutos}:${parametro.segundos}`,//"2021-05-01 07:30:00",
				"idUser": (parametro.idRG.value === 0)? null : parametro.idRG.value,
				"tipoBitacora": 2,
				"NumFuncionarios": parametro.numeroFuncionario,
				"TomadosFila": parametro.tomadosXfila,
				"UserReporto":(parametro.reporto === "")? null : parametro.reporto
			}
		}else{
			setLoading(false);
			notify.show(`Hace Falta registrar un Responsable`, 'warning', 5000);
			return true;
		}
		//setPasedeLista
		EjecutarApi(aperturaCasilla, 'setBitacoraSije', token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.results) {
					notify.show(res.message, 'success', 5000);
				}

			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error en: ${e.error}`, 'error', 5000);
			});
		

	}
	const setBitacoraSije = (parametro) => {
		setLoading(true);
		EjecutarApi(parametro, "setBitacoraSije", token.access_token)
			.then(function (res) {
				return res;
			})
			.then((res) => {
				if (res.success) {
					if (res.results) {
						notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
							background: "#18c97d",
							fontSize: 24,
							text: "#FFFFFF",
						});
						setLoading(false);
					} else {
						setLoading(false);
						notify.show(
							`Error al agregar registro: ${res.message}!!`,
							"custom",
							5000,
							{
								background: "#0857B6",
								fontSize: 24,
								text: "#FFFFFF",
							}
						);
						return true;
					}
				} else {
					setLoading(false);
					notify.show(
						`Error al insertar el registro: ${res.message}!!`,
						"custom",
						5000,
						{
							background: "#0857B6",
							fontSize: 24,
							text: "#FFFFFF",
						}
					);
				}
				return true;
			})
			.catch((e) => {
				setLoading(false);
				notify.show(`Error al agregar el registro: ${e.error}`, "custom", 5000, {
					background: "#0857B6",
					fontSize: 24,
					text: "#FFFFFF",
				});
			});
	}
	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel">
				<Navbar {...props} />
				<Notifications />
				<div className="content">
					<Row>
						<Col sm="12">
							{/* <Row>
						<Col lg="4" md="6" sm="6">
							<Card className="card-stats">
								<CardBody>
									<Row>
										<Col md="4" xs="5">
											<div className="icon-big text-center icon-warning">
												<i className="fa fa-users text-primary" />
											</div>
										</Col>
										<Col md="8" xs="7">
											<div className="numbers">
												<p className="card-category">Casillas</p>
												<CardTitle tag="p">0</CardTitle>
												<p />
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-muted text-center' >
											No Asignados
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											Aceptados
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											Propuestos
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-center' >
											<h6>0</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											<h6>0</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											<h6>0</h6>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<hr />
									<div className="stats">
										<i className="fa fa-clock-o" />
										Actual
										</div>
								</CardFooter>
							</Card>
						</Col>
						<Col lg="4" md="6" sm="6">
							<Card className="card-stats">
								<CardBody>
									<Row>
										<Col md="4" xs="5">
											<div className="icon-big text-center icon-warning">
												<i className="fa fa-sitemap text-warning" />
											</div>
										</Col>
										<Col md="8" xs="7">
											<div className="numbers">
												<p className="card-category">Estructura Electoral</p>
												    <CardTitle tag="p">0</CardTitle>
												<p />
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-muted text-center' >
											Avance
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											Aceptados
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											Propuestos
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-center' >
											<h6>0%</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											<h6>0</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											<h6>0</h6>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<hr />
									<div className="stats">
										<i className="fa fa-clock-o" />
										Actual
										</div>
								</CardFooter>
							</Card>
						</Col>
						<Col lg="4" md="6" sm="6">
							<Card className="card-stats">
								<CardBody>
									<Row>
										<Col md="4" xs="5">
											<div className="icon-big text-center icon-warning">
												<i className="fa fa-line-chart text-warning" />
											</div>
										</Col>
										<Col md="8" xs="7">
											<div className="numbers">
												<p className="card-category"></p>
												<CardTitle tag="p">0</CardTitle>
												<p />
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-muted text-center' >
											Avance
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											Aceptados
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											Propuestos
										</Col>
									</Row>
									<Row>
										<Col md="4" xs="5" className='text-center' >
											<h6>0%</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center' >
											<h6>0</h6>
										</Col>
										<Col md="4" xs="5" className='text-muted text-center'>
											<h6>0</h6>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<hr />
									<div className="stats">
										<i className="fa fa-clock-o" />
										Actual
										</div>
								</CardFooter>
							</Card>
						</Col>
					</Row> */}
							<Row>
								{loading && (
									<CircleSpinner
										className="text-center"
										size={30}
										color="#686769"
										loading={loading}
									/>
								)}
								<Col sm="4">
									{loading && (
									<CircleSpinner
										className="text-center"
										size={30}
										color="#686769"
										loading={loading}
									/>
									)}  
									<DiaDInstalacion
										Hora="Hora de Pase de Lista (Formato: 24 hrs, ejemplo: 13:30)"
										btnGuardar={savePaseLista}
										Titulo="Pase de Lista"
										isReporto={true}

									/>
								</Col>
								<Col sm="4">
									{loading && (
										<CircleSpinner
											className="text-center"
											size={30}
											color="#686769"
											loading={loading}
									/>
									)}  
									<DiaDInstalacion
										Hora="Hora de Instalación de Casillas (Formato: 24 hrs, ejemplo: 13:30)"
										btnGuardar={saveIntalacionCasilla}
										Titulo="Instalación de Casillas"
									/>
								</Col>
								<Col sm="4">
									{loading && (
										<CircleSpinner
											className="text-center"
											size={30}
											color="#686769"
											loading={loading}
									/>
									)}  
									<DiaDApertura
										Titulo="Apertura Casilla"
										btnGuardar={saveAperturaCasilla}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<Footer />
			</div>
		</div>
	)
}
export default SijeDiaDPaseLista; 