import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Label,
    CardHeader
} from "reactstrap";
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from "GlobalStorage";

const AvancesGeneralPoligono = (props) => {
    const [estatalDetalle, setEstatalDetalle] = useState([]);
    const [estatal, setEstatal] = useState({ Regional: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Municipios: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Poligonos: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Secciones: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 } });
    const [loading, setLoading] = useState(false);
    const [_menu, _setmenu] = useState({});
    const [total, setTotal] = useState(0);
    const [regionSelected, setRegionSelected] = useState({ value: 0, label: 'Todos' })
    const [municipioSelected, setMunicipioSelected] = useState({ value: 0, label: 'Todos' });
    const [catMunicipioFilter, setCatMunicipioFilter] = useState([]);
    const [catRegiones, setCatRegiones] = useState([{ value: 0, label: 'Todos' }]);
    const [catMunicipio, setCatMunicipio] = useState([{ value: 0, label: 'Todos' }]);
    const [catPoligono, setCatPoligono] = useState([{ value: 0, label: 'Todos' }]);
    const [catPoligonoFilter, setCatPoligonoFilter] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const token = getVars("Token");

    const responsibilityID = token.user.Responsabilidad.value;

    var ubicaciónRegion = null;
    var ubicaciónMunicipio = null;

    if (responsibilityID === 10) { token.users_region.forEach((prop) => { ubicaciónRegion = prop.idRegion }); }
    if (responsibilityID === 11) { token.users_municipios.forEach((prop) => { ubicaciónMunicipio = prop.idMunicipio }); }


    useEffect(() => {
        setLoading(true)
        _setmenu(getDataMenu(81, token.menus))

        let regionesFormated = [];
        let municipioFormated = [];
        EjecutarApi({}, "getCatalogosFiltrosPromocion", token.access_token, "POST")
            .then(res => {
                if (res.results) {
                    let regiones = [{ value: 0, label: 'Todos' }]
                    let municipios = [{ value: 0, label: 'Todos' }]

                    regionesFormated = res.data.regiones.map(item => {
                        return {
                            value: item.value,
                            label: item.label
                        }
                    })
                    const result = regionesFormated.filter(reg => reg.label !== 'NO DEFINIDO');
                    setCatRegiones(regiones.concat(result));
                    //CatMunicipio llenar
                    municipioFormated = res.data.municipios.map(item => {
                        return {
                            value: item.value,
                            label: item.label,
                            idRegion: item.idRegion,
                        }
                    })
                    setCatMunicipio(municipios.concat(municipioFormated))
                    setCatMunicipioFilter(municipios.concat(municipioFormated))
                } else {
                    showMessage("No Info: getCatalogosFiltrosPromocion", '#0E7CF1')
                }
            })
            .catch((e) => {
                showMessage("Error: getCatalogosFiltrosPromocion", "#F1370E")
            }).then(() => {
                setLoading(false);
            })

        EjecutarApi({}, "getResumenEstructuraEstatal", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                setLoading(false)
                setEstatal(res.data);
            })
            .catch((e) => {
                setLoading(false)
                notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });


    }, []); //loadingTotal, loadingCompromisos

    const showMessage = (message, background) => {
        notify.show(message, "custom", 5000, {
            background: background,
            fontSize: 14,
            text: "#FFFFFF",
        });
    }

    // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA

    const handleRegiones = (e) => {
        setLoading(true)
        setRegionSelected(e)

        if (e.value > 0) {
            let municipios = [{ value: 0, label: 'Todos' }]

            setCatMunicipioFilter(municipios.concat(catMunicipio.filter(data => data.idRegion === e.value)));
            fitroSend("", e, "region");

        } else {
            setCatMunicipioFilter(catMunicipio);
            fitroSend("", e, "region");
        }

    }


    const fitroSend = (municipio, region, dato) => {

        var Municipio = "";
        var Region = "";

        if (municipio.value > 0 && dato !== "clear") { Municipio = municipio.value }
        if (region.value > 0 && dato !== "clear") { Region = region.value }
        if (regionSelected.value > 0 && dato === "municipio") { Region = regionSelected.value }
        if (municipioSelected.value > 0 && dato === "region") { Municipio = municipioSelected.value }

        const data = {
            page: 0,
            pageSize: 10,
            sorted: [],
            tipo: 'and',
            Municipio: Municipio != "" ? Municipio : [],
            Region: Region != "" ? Region : []
        };
        EjecutarApi(data, "getAvancesCompromisosMunicipal", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success) {
                    if (res.results) {
                        setTotal(res.total);
                        setEstatalDetalle(
                            res.data.map((prop, key) => {
                                return {
                                    Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                    meta: prop.meta,
                                    data: prop,
                                    general: prop.general ? prop.general : "0",
                                    porcentaje: new Intl.NumberFormat().format(prop.porcentaje),
                                    promocion: prop.promocion ? prop.promocion : "0",
                                    social: prop.social ? prop.social : "0",
                                    metaEstructura: prop.MetaEstructuraPartido ? prop.MetaEstructuraPartido : "0",
                                    AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : "0",
                                    porcentajeEstructura: new Intl.NumberFormat().format(prop.porcentajeEstructura),
                                    Coordinadores: prop.Coordinadores ? prop.Coordinadores : "0",
                                    compromiso: prop.compromiso ? prop.compromiso : "0",
                                    electoral: prop.electoral ? prop.electoral : "0",
                                };
                            })
                        );
                        setLoading(false)
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(
                    `Error al obtener getMetaEstructurasGeneralFiltro: ${e.message}`,
                    "custom",
                    5000,
                    {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    }
                );
            });
    }

    const LimpiarFiltro = () => {
        setMunicipioSelected({ value: 0, label: 'Todos' })
        setRegionSelected({ value: 0, label: 'Todos' })
        fitroSend([], [], "clear");
    }


    const handleMunicipio = (e) => {
        setLoading(true)
        setMunicipioSelected(e)

        if (e.value > 1) {
            fitroSend(e, [], "municipio");

        } else {
            setCatMunicipioFilter(catMunicipio);
            fitroSend(e, [], "municipio");
        }
    }

    const Exportar = () => {
        var url = new URL(
            `${URL_PUBLICA}getReporteAvanceGeneralMunicipio?token=${token.access_token}`
        );
        window.open(url, "_blank");

    }

    const showRegion = responsibilityID === 99 || responsibilityID === 98 || responsibilityID === 10
    const showMunicipio = (responsibilityID === 99 || responsibilityID === 98 || responsibilityID === 10 || responsibilityID === 53 || responsibilityID === 54 || responsibilityID === 55)

    const styleHeader = {
        textAlign: "center",
        overflow: "visible",
        fontSize: "11px",
        color: "#363636",
        lineHeight: "normal !important"
    }

    const header = (text) => {
        return (<Label style={styleHeader}>{text}</Label>)
    }

    const columns = [
        {
            Header: header("Municipios"),
            minWidth: 150,
            id: "Municipios",
            accessor: (d) => d.Municipio,
            filterAll: false,
        },
        {
            Header: header("Meta"),
            minWidth: 150,
            id: "meta",
            accessor: (d) => d.meta,
            filterAll: false,
            Footer: (
                <span>
                    <strong>Total:</strong>{" "}
                    {estatal.Regional.MetaCompromisosPartido}
                </span>
            )
        }, {
            Header: header("Avance Compromisos"),
            minWidth: 150,
            id: "General",
            accessor: (d) => d.general,
            filterAll: false,
            Footer: (
                <span>
                    <strong>Total:</strong>{" "}
                    {estatal.Regional.AvanceGeneral}
                </span>
            )
        },
        {
            Header: header("% Avance Compromisos"),
            minWidth: 150,
            id: "Avance",
            accessor: (d) => d.porcentaje,
            Cell: props => <div>{props.value + " %"}</div>,
            filterAll: false,
            Footer: (
                <span>
                    <strong>Total:</strong>{" "}
                    {estatal.Regional.PorcentajeGeneral + " %"}
                </span>
            )
        },
        {
            Header: header("Meta Estructura"),
            minWidth: 150,
            id: "metaEstructura",
            accessor: (d) => d.metaEstructura,
            filterAll: false,
            Footer: (
                <span>
                    <strong>Total:</strong>{" "}
                    {estatal.Municipios.MetaEstructuraPartido}
                </span>
            )
        },
        {
            Header: header("Avance Estructura"),
            minWidth: 150,
            id: "GeneralEstructura",
            accessor: (d) => d.AvanceEstructura,
            filterAll: false,
            Footer: (
                <span>
                    <strong>Total:</strong>{" "}
                    {estatal.Municipios.AvanceEstructura}
                </span>
            )
        },
        {
            Header: header("% Avance Estructura"),
            minWidth: 150,
            id: "AvanceEstructura",
            accessor: (d) => d.porcentajeEstructura,
            Cell: props => <div>{props.value + " %"}</div>,
            filterAll: false,
            Footer: (
                <span>
                    <strong>Total:</strong>{" "}
                    {estatal.Municipios.porcentaje + " %"}
                </span>
            )
        }, {
            Header: header("Coordinadores Sociales"),
            id: "Coordinadores",
            accessor: (d) => d.Coordinadores,
            filterAll: false
        },
        {
            Header: header("Promoción"),
            id: "Promocion",
            accessor: (d) => d.promocion,
            filterAll: false,
        }, {
            Header: header("Electoral"),
            id: "electoral",
            accessor: (d) => d.electoral,
            filterAll: false,
        }, {
            Header: header("Social"),
            id: "Social",
            accessor: (d) => d.social,
            filterAll: false,
        }, {
            Header: header("Compromiso"),
            id: "compromiso",
            accessor: (d) => d.compromiso,
            filterAll: false,
        }
    ];

    const fetchData = useCallback(({ pageSize, page, sorted, params }) => {
        const data = {
            page: page,
            pageSize: pageSize,
            Region: ubicaciónRegion ? ubicaciónRegion : [],
            Municipio: ubicaciónMunicipio ? ubicaciónMunicipio : [],
            sorted: sorted,
            tipo: 'and',
            filtered: params
        };
        setPageSize(pageSize);
        setPage(pageSize);
        getRegistros(data);
    }, []);

    const getRegistros = (data) => {
        setLoading(true);
        EjecutarApi(data, "getAvancesCompromisosMunicipal", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success) {
                    if (res.results) {
                        setTotal(res.total);
                        setEstatalDetalle(
                            res.data.map((prop, key) => {
                                return {
                                    Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                    meta: prop.meta,
                                    data: prop,
                                    general: prop.general ? prop.general : "0",
                                    porcentaje: new Intl.NumberFormat().format(prop.porcentaje),
                                    promocion: prop.promocion ? prop.promocion : "0",
                                    social: prop.social ? prop.social : "0",
                                    metaEstructura: prop.MetaEstructuraPartido ? prop.MetaEstructuraPartido : "0",
                                    AvanceEstructura: prop.AvanceEstructura ? prop.AvanceEstructura : "0",
                                    porcentajeEstructura: new Intl.NumberFormat().format(prop.porcentajeEstructura),
                                    Coordinadores: prop.Coordinadores ? prop.Coordinadores : "0",
                                    compromiso: prop.compromiso ? prop.compromiso : "0",
                                    electoral: prop.electoral ? prop.electoral : "0",
                                };
                            })
                        );
                    }
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error al obtener tabla metas estructura region: ${e.message}`, 'error', 5000);
            });
    }
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="fa fa-users text-success" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Municipales</p>
                                                <CardTitle tag="p">{`${estatal.Municipios.AvanceEstructura} de ${estatal.Municipios.MetaEstructuraPartido}`}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-line-chart" />
                                        {`Avance ${new Intl.NumberFormat().format(estatal.Municipios.porcentaje)} %`}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="fa fa-users text-success" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Poligonales</p>
                                                <CardTitle tag="p">{`${estatal.Poligonos.AvanceEstructura} de ${estatal.Poligonos.MetaEstructuraPartido}`}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-line-chart" />
                                        {`Avance ${new Intl.NumberFormat().format(estatal.Poligonos.porcentaje)} %`}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="fa fa-users text-success" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Seccionales</p>
                                                <CardTitle tag="p">{`${estatal.Secciones.AvanceEstructura} de ${estatal.Secciones.MetaEstructuraPartido}`}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-line-chart" />
                                        {`Avance ${new Intl.NumberFormat().format(estatal.Secciones.porcentaje)} %`}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="fa fa-users text-success" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Compromisos</p>
                                                <CardTitle tag="p">{`${estatal.Regional.AvanceGeneral} de ${estatal.Regional.MetaCompromisosPartido}`}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-line-chart" />
                                        {`Avance ${new Intl.NumberFormat().format(estatal.Regional.PorcentajeGeneral)} %`}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                                        <Row >
                                            {showRegion &&
                                                <Col lg="4" md="4" sm="4">
                                                    <FormGroup>
                                                        <Label for="Estado">
                                                            Región
                              </Label>
                                                        <Select
                                                            options={catRegiones}
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            defaultValue={catRegiones[0]}
                                                            value={regionSelected}
                                                            onChange={(e) => handleRegiones(e)}
                                                            id="regiones"
                                                            placeholder="regiones"
                                                        />
                                                    </FormGroup>
                                                </Col>}
                                            {showMunicipio &&
                                                <Col lg="4" md="4" sm="4">
                                                    <FormGroup>
                                                        <Label for="Municipio">
                                                            Municipios
                              </Label>
                                                        <Select
                                                            options={catMunicipioFilter}
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            value={municipioSelected}
                                                            onChange={(e) => handleMunicipio(e)}
                                                            id="municipio"
                                                            placeholder="Municipio"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button name="btnLimpiar" size="sm" type="button" className="success float-left" color='warning' onClick={() => { LimpiarFiltro() }}>Limpiar</Button>
                                            </Col>
                                            {(_menu.Exportar === 1 && !loading) ? (
                                                <Col sm='8'>
                                                    <Button name="btnExportar" size='sm' type="button" className='primary float-right' color='primary' onClick={() => Exportar()}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-file-excel-o fa-lg" />
                                                        </span>
                                                        Exportar
							      </Button>

                                                </Col>
                                            ) : <></>}
                                        </Row>
                                    </div>
                                </CardHeader>
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                )}
                                <Col lg="12" md="12">
                                    <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                                        <ReactTable
                                            style={{
                                                width: "100%",
                                                height: "100%"
                                            }}
                                            data={estatalDetalle}
                                            noDataText="Sin información"
                                            manual
                                            columns={columns}
                                            className="-striped -highlight primary-pagination"
                                            loading={loading}
                                            showPagination={true}
                                            showPaginationBottom={true}
                                            showTotalPages={true}
                                            showPageJump={false}
                                            canNextFromData={true}
                                            defaultPageSize={10}
                                            total={total}
                                            previousText="Anterior"
                                            nextText="Siguiente"
                                            ofText="de"
                                            pageSizeOptions={[10, 20, 25, 50, 100]}
                                            PaginationComponent={Pagination}
                                            onFetchData={fetchData}
                                        />
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Footer fluid />
            </div>
        </div >
    );
};

export default AvancesGeneralPoligono;
