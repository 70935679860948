
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardHeader,  CardBody } from "reactstrap";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";

import Notifications, { notify } from "react-notify-toast";
import { CircleSpinner } from "react-spinners-kit";
import Select from "react-select";
import EjecutarApi from '../../data/getPOST';
import { getVars } from "../../GlobalStorage";
import NumeraliaGrf from 'components/Charts/NumeraliaMetas/index';


const ResultadoSimulacro = props => {
    const mainPanel = useRef();
    const token = getVars("Token").access_token;
    const [loading, setLoading] = useState(false);

    const [loadingChart, setLoadingChart] = useState(true);

    const [state, setState] = useState({ idRegion: { value: null, label: 'Seleccione' },
            idMunicipio: { label: 'Seleccione', value: 0 },
            dMunicipio: { label: 'Seleccione', value: 0 },
            Tipo: { label: 'Seccional', value: 2 } });

    const [series, setSeries] = useState([]);

    const [catRegionesFilter, setCatRegionesFilter] = useState([]);
    const [catMunicipios, setCatMunicipios] = useState([]);
    const [catMunicipiosFilter, setCatMunicipiosFilter] = useState([]);
    const [catRegiones, setCatRegiones] = useState([]);
    const [dataChart, setDataChart] = useState([]);

    useEffect(() =>{
        let region ={};

        EjecutarApi({}, "getCatalogosFiltrosPromocion", token, "POST")
        .then(res => {
            if (res.results) {

                if (res.data) {
                    let _mpio=[{value:0,label:'Todos'}];
                    setCatRegiones(res.data.regiones);
                    region = res.data.regiones[0];
                    var fmunicipios = res.data.municipios.filter(fil => (fil.idRegion === region.value ));
                    var mpios= _mpio.concat(fmunicipios);
                    setCatRegionesFilter(_mpio.concat(region));
                    setCatMunicipiosFilter(mpios);
                    setState({ ...state, idRegion: region, dMunicipio:mpios, idMunicipio:mpios});
                    setCatMunicipios(res.data.municipios);
                    setCatRegionesFilter(res.data.regiones);
                    
                }
            }
        }).catch((e) => {
            setLoading(false);
        })
        .finally(x => {
            setLoading(false);
        })
        getDataChartResultado(region.value,null)

    },[])

    // useEffect(() => {
    //     let tipo = state.Tipo;
    //     let idMunicipio = state.dMunicipio.value;

    //     if(state.idMunicipio.value>0)
    //     {
    //         //graficas(null,state.idMunicipio)
    //         //getDataChartResultado(null,state.idMunicipio.value)
    //     }else if (state.idRegion.value)
    //     {
    //         getDataChartResultado(state.idRegion.value,null)
    //     }

    // }, [state])

    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };
    const changeMunicipio = (idMunicipio) => {
        setLoading(true)
        if (idMunicipio.value > 1) {
            setState({ ...state, idRegion:state.idRegion, dMunicipio: idMunicipio, idMunicipio: idMunicipio });
            getDataChartResultado(idMunicipio.idRegion,idMunicipio.value)
            setLoading(false)
        } else {
            setState({ ...state, idRegion: state.idRegion , dMunicipio: state.dMunicipio ,idMunicipio: state.idMunicipio });
            setCatMunicipiosFilter(catMunicipiosFilter);
            setLoading(false)
        }
    }
    const changeRegion = (idRegion) => {
        if (idRegion.value > 1) {
            setState({ ...state, idRegion, dMunicipio: { label: 'Todos', value: 0 },idMunicipio: { label: 'Todos', value: 0 } });
            const misMunis = catMunicipios.filter(data => data.idRegion === idRegion.value);
            //setState({ ...state, idRegion, dMunicipio: misMunis[0] , idMunicipio: misMunis[0] });
            setCatMunicipiosFilter(misMunis);

            getDataChartResultado(idRegion.value)

        } else {
            const misMunis = catMunicipios.filter(data => data.idRegion === state.idRegion);
            setState({ ...state, idRegion: state.idRegion , dMunicipio: { label: 'Todos', value: 0 }  ,idMunicipio: { label: 'Todos', value: 0 }  });
            setCatMunicipiosFilter(misMunis);
            getDataChartResultado(idRegion.value)
        }
    }

    const getDataChartResultado = ( idRegion,idMunicipio) => {
        setLoadingChart(true);
        var parametro={};
        if(idMunicipio)
        {
            parametro={
                idRegion:idRegion,
                idMunicipio:idMunicipio,
            }
        }else
        {

            parametro={
                idRegion:idRegion,
            }
        }

        EjecutarApi(parametro, "getGraficaTablero", token,"POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            if (res.results) {
              let categorua=["Seccionales","Manzaneros","CompromisoSimulacro","SistemaÚnicos"]
              const dato = {

                title: `Gráfica Simulacro`,
                xAxis: {
                    categories: categorua,
                    title: {
                        text:``
                    }
                },
                yAxis: [{
                    min: 0,
                    title: {
                        text: 'compromiso'
                    }
                }],
            };

            const series = [
                {
                    name: 'Meta Papel',
                    color: 'rgba(147,220,236,.9)',
                    data:res.data.series[0][0].data,
                    pointPadding: 0.3,
                    pointPlacement: -0.2
                }, {
                    name: 'Avance Sistema',
                    color: '#0041A0',
                    data:res.data.series[0][1].data,
                    pointPadding: 0.4,
                    pointPlacement: -0.2
                }];
              setDataChart(dato);
              setSeries(series);
              setLoadingChart(false);
            }
            setLoadingChart(false);
          })
          .catch((e) => {
            notify.show(`Error al obtener datos de la gráfica`, "error", 5000);
            setLoadingChart(false);
          });
      }
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel ps ps--active-y" ref={mainPanel}>
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Col sm="12" md="12" lg="12">
                        <Row>
                            <Col sm="12" md="12" lg="12" className="text-muted">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm="4" className="text-muted">Región:
                                                {loading && (
                                                <CircleSpinner
                                                className="text-center"
                                                size={30}
                                                color="#686769"
                                                loading={loading}
                                                />
                                            )}            {!loading && (<Select
                                                options={catRegionesFilter}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                value={state.idRegion}
                                                onChange={(e) => changeRegion(e)}
                                                id="viewTypeM"
                                                placeholder="Filtrar por"
                                            />)}
                                            </Col>
                                            <Col sm="4" className="text-muted">Municipio:
                                                {loading && (
                                            <CircleSpinner
                                            className="text-center"
                                            size={30}
                                            color="#686769"
                                            loading={loading}
                                            />
                                        )}            {!loading && (<Select
                                            options={catMunicipiosFilter}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={state.idMunicipio}
                                            onChange={(e) => changeMunicipio(e)}
                                            id="viewTypeM"
                                            placeholder="Filtrar por"
                                        />)}
                                        </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <Card>
                                    <CardBody>
                                        <h5 className="title"></h5>
                                        <Row id="container">
                                            {dataChart && <div style={{ width: '100%' }}>
                                                <NumeraliaGrf datos={series} Titulos={dataChart} />
                                            </div>}
                                            {loadingChart && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={true}
                                                />
                                            )}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </Col>
                </div>
                <Footer fluid />
            </div>
        </div>
    )
}
export default ResultadoSimulacro;