import React from 'react';

import { Card, CardHeader, CardBody, Row, Col, Spinner } from 'reactstrap';

const StatsCard = (props) => {
  return (
    <Card>
      <CardHeader style={{ backgroundColor: props.headerColor /*"#001689"*/ }}>
        <Row>
          <Col>
            <h5
              className={
                props.class || 'text-white text-medium margin-none strong'
              }
            >
              <i className={props.icon || 'fa fa-file'}></i> {props.title}
            </h5>
          </Col>
          {props.isLoading && (
            <Col md="2">
              <Spinner
                className={
                  props.class || 'text-white text-medium margin-none strong'
                }
              />
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody>{props.children}</CardBody>
    </Card>
  );
};

export default StatsCard;
