import React, { useEffect, useState } from "react";


//Zincri imports
import ChoropethMap from "../../../../components/Maps/Impresion/Movil/ChoropethMapV5WebDistritoDL.jsx";
import {
  Button
} from "reactstrap";
import { URL_GENERAL } from "../../../../data/Consts";
import ReactBSAlert from "react-bootstrap-sweetalert";

const Mapas = (props) => {
  const [dataGeoJson, setDataGeoJson] = useState(
    {
      "type": "FeatureCollection",
      "features": [
      ]
    }
  );

  const [tipoMapa] = useState(
    { value: "distrito", label: "Distrital" }

  );
  //const [textoInput, setTextoInput] = useState("");
  //const zoom = 9; 
  const [zoom, setZoom] = useState(9);
  const [menu, _setmenu] = useState({
    Agregar: 1,
    Editar: 0,
    Electoral: 0,
    Eliminar: 0,
    Exportar: 0,
    Propuesta: 0,
    Seguimiento: 0,
    Territorial: 0,
    Ver: 1,
    ViewAll: 1
  });
  const [DeviceID, setDeviceID] = useState(0);
  const [IdUser, setIdUser] = useState(0);
  const [flagFinish, setFlagFinish] = useState(true);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState("");
  const hostserver = URL_GENERAL;


  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  useEffect(() => {
  }, [dataGeoJson]);
  const basicAlert = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );


  const clickDownloadFile = (e) => {
    if (menu.Exportar === 0) {
      setMensajeAlert("¡No cuenta con permisos para exportar!");
      setFlagAlert(true);
    }
    var url = new URL(
      hostserver + `getArchivoMapaDistritoDLMovil?DeviceID=${DeviceID}&idUser=${IdUser}&HashCode=${e.feature.properties.HashCode}`
    );
    window.open(url, "_blank");
  }


  function btnBuscar(busqueda) {
    var exportMovil = parseInt(document.getElementById("exportMovil").innerHTML, 10);
    const distrito = document.getElementById("dataVars").innerHTML;
    const idUser = document.getElementById("idUser").innerHTML;
    const deviceID = document.getElementById("DeviceID").innerHTML;

    _setmenu({ ...menu, Exportar: exportMovil });
    setDeviceID(deviceID);
    setIdUser(idUser);
    if (distrito.length > 0) {
      if (distrito <= 0 || distrito > 22) {
        setMensajeAlert("Distrito incorrecto!");
        setFlagAlert(true);
      }
      else {
        btnSearch(distrito, deviceID, idUser);
      }

    } else {
      setMensajeAlert("¡Favor de especificar un poligono!");
      setFlagAlert(true);
    }


  }

  async function FiltroSearch(body) {
    setZoom(12);
    setDataGeoJson({});
    const token = "";
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };


      let res = await fetch(
        hostserver + "getDistritoDLImpresionGto",
        config
      );
      let data = await res.json();
      var json_parser_principal = JSON.parse(data.distrito);
      var json_parser_secundario = JSON.parse(data.poligono);

      if (data.success && data.results) {
        if (json_parser_principal.features === null) {
          throw new Error(data.message);
        }
        if (json_parser_secundario.features === null) {
          json_parser_secundario = {
            "type": "FeatureCollection",
            "features": [
            ]
          }
        }
      }
      else {
        throw new Error(data.message);
      }

      json_parser_principal.features.forEach(item => {
        json_parser_secundario.features.push(item);
      });
      setDataGeoJson(json_parser_secundario);
    } catch (error) {
      if (error.message === 'Token Signature could not be verified.'
        || error.message === 'Sin autorizacion. El token ha expirado'
        || error.message === 'El token esta en la lista negra') {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      }
      else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }


  async function btnSearch(distrito, deviceID, idUser) {
    setZoom(12);
    setDataGeoJson({});

    try {

      let config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DeviceID: deviceID,
          idUser: idUser,
          Region: [],
          Municipio: [],
          Poligono: [],
          Distrito: [distrito],
          OneByOne: true,
          Seccion: []
        }),
      };

      let res = await fetch(
        hostserver + "getDistritoDLImpresionGtoMovil",
        config
      );
      let data = await res.json();
      if (data.success && data.results) {
        var json_parser_principal = JSON.parse(data.distrito);
        var json_parser_secundario = JSON.parse(data.poligono);

        if (json_parser_principal.features === null) {
          throw new Error(data.message);
        }
        if (json_parser_secundario.features === null) {
          json_parser_secundario = {
            "type": "FeatureCollection",
            "features": [
            ]
          }
        }
      }
      else {
        throw new Error(data.message);
      }

      json_parser_principal.features.forEach(item => {
        json_parser_secundario.features.push(item);
      });
      setDataGeoJson(json_parser_secundario);
    } catch (error) {
      if (error.message === 'Token Signature could not be verified.'
        || error.message === 'Sin autorizacion. El token ha expirado'
        || error.message === 'El token esta en la lista negra') {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      }
      else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const handleSearh = (e) => {
    btnBuscar(e);
  }

  const handleFiltro = (filtered, tipo_mapa) => {
    setZoom(10);
    setFlagFinish(false);

    var idMunicipio = filtered.filter((dato) => dato.id === 'idMunicipio');
    var idRegion = filtered.filter((dato) => dato.id === 'idRegion');
    var idPoligono = filtered.filter((dato) => dato.id === 'idPoligono');
    var idSeccion = filtered.filter((dato) => dato.id === 'idSeccion');
    var TieneActas = filtered.filter((dato) => dato.id === 'TieneActas');
    var idDistritos = filtered.filter((dato) => dato.id === 'idDistritos');


    idMunicipio = idMunicipio[0].value;
    idRegion = idRegion[0].value;
    idPoligono = idPoligono[0].value;
    idSeccion = idSeccion[0].value;
    TieneActas = TieneActas[0].value;
    idDistritos = idDistritos[0].value;


    var data_filtro_impresion = {};

    if (
      idRegion.length !== 0 &&
      idMunicipio.length === 0 &&
      idSeccion.length === 0
    ) {
      setDataGeoJson({});
      data_filtro_impresion = {
        "Region": idRegion,
        "Municipio": [],
        "Poligono": [],
        "Seccion": []
      };
    }

    if (idMunicipio.length !== 0 && idSeccion.length === 0) {
      setDataGeoJson({});
      data_filtro_impresion = {
        "Region": [],
        "Municipio": idMunicipio,
        "Poligono": [],
        "Seccion": []
      };
    }
    if (idDistritos.length !== 0) {
      setDataGeoJson({});
      data_filtro_impresion = {
        "Distrito": idDistritos,
        "Municipio": [],
        "Poligono": [],
        "Seccion": []

      };

    }
    else {
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
      setFlagFinish(true);
      setMensajeAlert("¡Debe especificar el(los) distrito(s) a buscar!")
      setFlagAlert(true);
      return true;
    }
    FiltroSearch(data_filtro_impresion);
    setFlagFinish(true);
    /* if (
      idDistritos.length === 0 &&
      idSeccion.length === 0 &&
      idMunicipio.length === 0 &&
      idRegion.length === 0
    ) {
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    } */
  };

  return (
    <div className="wrapper">
      <div hidden id="exportMovil" name="exportMovil" ></div>
      <div hidden id="dataVars" name="dataVars" ></div>
      <div hidden id="idUser" name="idUser" ></div>
      <div hidden id="DeviceID" name="DeviceID" ></div>

      <div className="content">
        <Button hidden id="btnS" name="btnS" onClick={() => btnBuscar()}> Buscar</Button>
        <>
          {FlagAlert ? basicAlert : <></>}
          <ChoropethMap
            key={"ChoropethMapV4"}
            center={[position.latitude, position.longitude]}
            zoom={zoom}
            data={dataGeoJson}
            rangos={[]}
            tipo={tipoMapa.value}
            label_tipo={tipoMapa.label}
            //handleTipoMapa = {handleTipoMapa}
            //handleDetalleMapa = {handleDetalleMapa}
            handleSearh={handleSearh}
            clickDownloadFile={clickDownloadFile}
            menuExportar={menu}
            MenuPermisos={menu}
            Responsabilidad={""/* getVars('Token').user.Responsabilidad.value */}
            _flagFinish={flagFinish}
            handleFiltro={handleFiltro}
          ></ChoropethMap>

        </>
      </div>
    </div>
  );
};

export default Mapas;
