import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Input, Table } from 'reactstrap';
import { URL_IMAGENES } from 'GlobalStorage';
import nulosImage from 'assets/img/prep/nulos.png';
import noregImage from 'assets/img/prep/noreg.png';

const ListaPartidos = (props) => {
  const {
    totalVotos,
    partidos,
    handleVotosPartido,
    errorVotos,
  } = props;

  return (
    <>
      <Row>
        <Col>
          <Table bordered striped>
            <tbody>
              {partidos.map((partido, index) => {
                return (
                  <tr key={index}>
                    <td width="70%">
                      <div align="center" className="picture-container">
                        {/* <div
                          className={
                            partido.isCoalicion
                              ? 'picture-partido-extended'
                              : 'picture-partido'
                          }
                        > */}
                        <img
                          src={`${URL_IMAGENES}/${partido.Logo}`}
                          alt={partido.Partido}
                          height="30px"
                        />
                        {/* </div> */}
                      </div>
                    </td>
                    <td>
                      <div className="center-holder">
                        <Input
                          align="center"
                          placeholder="Votos"
                          value={partido.votos}
                          onChange={(e) =>
                            handleVotosPartido(e.target.value, index)
                          }
                          type="number"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
                <td width="70%">
                  <div align="center" className="picture-container">
                    <div className="picture-partido">
                    <img src={nulosImage} alt="NULOS" height="30px" />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="center-holder">
                    <Input
                      align="center"
                      placeholder="Nulos"
                      value={nulos}
                      onChange={(e) =>
                        handleExtraValues(e.target.value, 'Nulos')
                      }
                      type="number"
                    />
                  </div>
                </td>
              </tr> */}
              {/* <tr>
                <td width="70%">
                  <div align="center" className="picture-container">
                    <div className="picture-partido">
                    <img src={noregImage} alt="NULOS" height="30px" />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="center-holder">
                    <Input
                      align="center"
                      placeholder="No registrados"
                      value={noReg}
                      onChange={(e) =>
                        handleExtraValues(e.target.value, 'NoReg')
                      }
                      type="text"
                    />
                  </div>
                </td>
              </tr> */}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 4, offset: 8 }} align="center">
          <strong>Total: {totalVotos}</strong>
        </Col>
      </Row>
      {errorVotos !== '' && (
        <Row>
          <Col align="center">
            <span className="form-check-sign" style={{ color: 'red' }}>
              {errorVotos}
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ListaPartidos;
