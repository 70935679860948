import React, { useEffect, useState } from "react";


//Zincri imports
import ChoropethMap from "../../../../components/Maps/Impresion/Movil/ChoropethMapV5WebSeccion.jsx";
//import { getVars } from "../../../../GlobalStorage";
import {
  Button
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { URL_GENERAL } from "../../../../data/Consts";
const Mapas = (props) => {
  //const token = getVars("Token");
  const [dataGeoJson, setDataGeoJson] = useState(
    {
      "type": "FeatureCollection",
      "features": [
      ]
    }
  );

  const [tipoMapa, setTipoMapa] = useState(
    { value: "seccion", label: "Seccional" }

  );
  const [zoom, setZoom] = useState(9);
  const [menu, _setmenu] = useState({
    Agregar: 1,
    Editar: 0,
    Electoral: 0,
    Eliminar: 0,
    Exportar: 0,
    Propuesta: 0,
    Seguimiento: 0,
    Territorial: 0,
    Ver: 1,
    ViewAll: 1
  });
  const [flagFinish, setFlagFinish] = useState(true);
  const [FlagAlert, setFlagAlert] = useState(false);
  const [MensajeAlert, setMensajeAlert] = useState("");
  const hostserver = URL_GENERAL;

  //const [exportState, setExportState] = useState(0);
  const [DeviceID, setDeviceID] = useState(0);
  const [IdUser, setIdUser] = useState(0);
  const position = {
    latitude: 20.917639,
    longitude: -101.091132,
  };

  useEffect(() => {
  }, [dataGeoJson]);
  const basicAlert = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={MensajeAlert}
      onConfirm={() => setFlagAlert(false)}
      onCancel={() => setFlagAlert(false)}
      confirmBtnBsStyle="info"
    />
  );


  const clickDownloadFile = (e) => {
    if (menu.Exportar === 0) {
      setMensajeAlert("¡No cuenta con permisos para exportar!");
      setFlagAlert(true);
    }
    var url = new URL(
      hostserver + `getArchivoMapaM?DeviceID=${DeviceID}&idUser=${IdUser}&HashCode=${e.feature.properties.HashCode}`
    );
    window.open(url, "_blank");
  }


  function btnBuscar(seccion) {

    var exportMovil = parseInt(document.getElementById("exportMovil").innerHTML, 10);
    const Seccion = document.getElementById("dataVars").innerHTML;
    const idUser = document.getElementById("idUser").innerHTML;
    const deviceID = document.getElementById("DeviceID").innerHTML;

    _setmenu({ ...menu, Exportar: exportMovil });
    setDeviceID(deviceID);
    setIdUser(idUser);

    if (Seccion.length > 0) {
      if (Seccion <= 0 || Seccion > 3155) {
        setMensajeAlert("¡Sección incorrecta!");
        setFlagAlert(true);
      }
      else {
        btnSearchSeccion(Seccion, deviceID, idUser);
      }

    } else {
      setMensajeAlert("¡Favor de especificar una sección!");
      setFlagAlert(true);
    }


  }

  async function FiltroSearchSeccion(body) {
    setZoom(12);
    setDataGeoJson({});
    const token = ""; //getVars("Token").access_token;
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };


      let res = await fetch(
        hostserver + "getSeccionesManzanasImpresionGtoWeb",
        config
      );
      let data = await res.json();
      var json_parser_secciones = JSON.parse(data.seccion);
      var json_parser_manzanas = JSON.parse(data.manzanas);

      if (data.success && data.results) {
        if (json_parser_secciones.features === null) {
          throw new Error(data.message);
        }
        if (json_parser_manzanas.features === null) {
          json_parser_manzanas = {
            "type": "FeatureCollection",
            "features": [
            ]
          }
        }
      }
      else {
        throw new Error(data.message);
      }

      json_parser_secciones.features.forEach(seccion_item => {
        json_parser_manzanas.features.push(seccion_item);
      });
      setDataGeoJson(json_parser_manzanas);
    } catch (error) {
      if (error.message === 'Token Signature could not be verified.'
        || error.message === 'Sin autorizacion. El token ha expirado'
        || error.message === 'El token esta en la lista negra') {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      }
      else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }


  async function btnSearchSeccion(Seccion, deviceID, idUser) {
    //getRangos();
    setZoom(12);
    setDataGeoJson({});
    const token = ""; //getVars("Token").access_token;
    try {
      let config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          DeviceID: deviceID, idUser: idUser,
          Region: [],
          Municipio: [],
          Poligono: [],
          Seccion: [Seccion]
        }),
      };


      let res = await fetch(
        hostserver + "getSeccionesManzanasImpresionGtoMovil",
        config
      );
      let data = await res.json();

      if (data.success && data.results) {
        var json_parser_secciones = JSON.parse(data.seccion);
        var json_parser_manzanas = JSON.parse(data.manzanas);

        if (json_parser_secciones.features === null) {
          throw new Error(data.message);
        }
        if (json_parser_manzanas.features === null) {
          json_parser_manzanas = {
            "type": "FeatureCollection",
            "features": [
            ]
          }
        }
      }
      else {
        throw new Error(data.message);
      }

      json_parser_secciones.features.forEach(seccion_item => {
        json_parser_manzanas.features.push(seccion_item);
      });
      setDataGeoJson(json_parser_manzanas);
    } catch (error) {
      if (error.message === 'Token Signature could not be verified.'
        || error.message === 'Sin autorizacion. El token ha expirado'
        || error.message === 'El token esta en la lista negra') {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      }
      else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };




  async function getSecciones(clave_parametro, method) {
    const token = "";//getVars("Token").access_token;
    try {
      var config = {};
      if (method === "GET") {
        config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
      }
      else {
        config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clave: clave_parametro }),
        };
      }
      let res = await fetch(
        hostserver + "getSeccionesCompromisosGto",
        config
      );
      let data = await res.json();
      var json_parser = JSON.parse(data);
      setDataGeoJson(json_parser);
    } catch (error) {
      if (error.message === 'Token Signature could not be verified.'
        || error.message === 'Sin autorizacion. El token ha expirado'
        || error.message === 'El token esta en la lista negra') {
        setMensajeAlert('¡La sesión ha expirado, favor de renovar sesión!');
        setFlagAlert(true);
      }
      else {
        setMensajeAlert(error.message);
        setFlagAlert(true);
      }
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    }
  }

  const handleDetalleMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapaparametros(e);
  }

  const handleSearhSeccion = (e) => {
    btnBuscar(e);
  }
  const handleTipoMapa = (e) => {
    setDataGeoJson({});
    setTipoMapa(e);
    selectmapa(e);
  }
  function selectmapa(tipomapa) {
    if (tipomapa.value === 'seccion') {
      getSecciones("", "GET");
    }
  }

  function selectmapaparametros(tipomapa) {

    if (tipomapa.value === 'seccion') {
      getSecciones(tipomapa.clave, "POST");
    }
  }

  const handleFiltro = (filtered, tipo_mapa) => {
    //setMunicipioSaved({});
    //setFilteredSaved(filtered);
    setFlagFinish(false);
    //setZoom(9);
    //setPosition([20.816157, -101.021312]);

    var idMunicipio = filtered.filter((dato) => dato.id === 'idMunicipio');
    var idRegion = filtered.filter((dato) => dato.id === 'idRegion');
    var idSeccion = filtered.filter((dato) => dato.id === 'idSeccion');
    var TieneActas = filtered.filter((dato) => dato.id === 'TieneActas');

    idMunicipio = idMunicipio[0].value;
    idRegion = idRegion[0].value;
    idSeccion = idSeccion[0].value;
    TieneActas = TieneActas[0].value;

    var filtered_plugin = [];
    var puntos = false;
    var data_filtro_impresion = {};
    if (TieneActas !== 9999) {
      puntos = true;
      if (TieneActas !== 3)
        filtered_plugin.push({
          id: 'cat_casillasgto.TieneActas',
          value: TieneActas,
        });
    }
    if (
      idRegion.length !== 0 &&
      idMunicipio.length === 0 &&
      idSeccion.length === 0
    ) {
      //setZoom(9);
      setDataGeoJson({});
      //setTipoMapa({ value: 'region', label: 'Regional' });
      filtered_plugin.push({ id: 'secciones.idRegion', value: idRegion });

      data_filtro_impresion = {
        "Region": idRegion,
        "Municipio": [],
        "Poligono": [],
        "Seccion": []
      };
      //getRegiones(idRegion, 'POST');
    }

    if (idMunicipio.length !== 0 && idSeccion.length === 0) {
      //setZoom(10);
      setDataGeoJson({});
      //setTipoMapa({ value: 'municipio', label: 'Municipal' });
      filtered_plugin.push({
        id: 'secciones.idMunicipioReportes',
        value: idMunicipio,
      });
      data_filtro_impresion = {
        "Region": [],
        "Municipio": idMunicipio,
        "Poligono": [],
        "Seccion": []
      };
      //getMunicipios(idMunicipio, 'POST');
    }
    if (idSeccion.length !== 0) {
      //setZoom(13);
      setDataGeoJson({});
      //setTipoMapa({ value: 'seccion', label: 'Seccional' });
      filtered_plugin.push({ id: 'secciones.Seccion', value: idSeccion });
      data_filtro_impresion = {
        "Region": [],
        "Municipio": [],
        "Poligono": [],
        "Seccion": idSeccion

      };
      //getSecciones(idSeccion, 'POST');

    }
    else {
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
      setFlagFinish(true);
      setMensajeAlert("¡Debe especificar el grupo de secciones a buscar!")
      setFlagAlert(true);
      return true;
    }
    FiltroSearchSeccion(data_filtro_impresion);
    setFlagFinish(true);
    /* if (
      idSeccion.length === 0 &&
      idMunicipio.length === 0 &&
      idRegion.length === 0
    ) {
      setDataGeoJson({
        "type": "FeatureCollection",
        "features": [
        ]
      });
    } */
  };

  return (
    <div className="wrapper">
      <div hidden id="exportMovil" name="exportMovil" ></div>
      <div hidden id="dataVars" name="dataVars" ></div>
      <div hidden id="idUser" name="idUser" ></div>
      <div hidden id="DeviceID" name="DeviceID" ></div>

      <div className="content">
        <Button hidden id="btnS" name="btnS" onClick={() => btnBuscar()}>Buscar</Button>
        <>
          {FlagAlert ? basicAlert : <></>}
          <ChoropethMap
            key={"ChoropethMapV4"}
            center={[position.latitude, position.longitude]}
            zoom={zoom}
            data={dataGeoJson}
            rangos={[]}
            tipo={tipoMapa.value}
            label_tipo={tipoMapa.label}
            handleTipoMapa={handleTipoMapa}
            handleDetalleMapa={handleDetalleMapa}
            handleSearhSeccion={handleSearhSeccion}
            clickDownloadFile={clickDownloadFile}
            menuExportar={menu}
            MenuPermisos={menu}
            Responsabilidad={""/* getVars('Token').user.Responsabilidad.value */}
            _flagFinish={flagFinish}
            handleFiltro={handleFiltro}
          ></ChoropethMap>

        </>
      </div>
    </div>
  );
};

export default Mapas;
