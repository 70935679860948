import React, { useState, useEffect } from 'react';
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { MagicSpinner } from 'react-spinners-kit';
import { SinPermisos } from '../ComponentesAuxiliares/SinPermisos';
import { centerOnOneShape } from '../ComponentesAuxiliares/AuxiliarFunctions';
import {
  Button,
  ButtonGroup,
  Table /* ,DropdownItem,DropdownToggle,DropdownMenu,ButtonDropdown  */,
} from 'reactstrap';
//import Select from 'react-select';
let style_b = {
  color: '#000000',
};
let _style_square_superior_left_manzanas = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
  position: 'relative',
  right: '280px',
};
let _style_square_superior_right_mp1 = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
  position: 'relative',
  top: '165px',
};
let _style_square_superior_right_mp2 = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
  position: 'relative',
  top: '295px',
};
let _style_square_superior_right_mp3 = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
  position: 'relative',
  top: '430px',
};
let style_button_radiogroup = {
  padding: '2px 4px',
  font: '10px/12px ',
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
};

let _style_square_superior_left_search = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
  position: 'relative',
  top: '80px',
};

let style_square_superior = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
};
let style_square_superior_left = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',

  //'width': '200px'
};
let style_square_inferior_tabla = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  background: 'rgba(255,255,255,0.9)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
};

let style_spinner = {
  height: '500pt',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
let style_square_inferior_rangos = {
  padding: '6px 8px',
  font: '14px/16px Arial, Helvetica, sans-serif',
  /* 'background': 'white', */
  background: 'rgba(255,255,255,0.8)',
  boxShadow: '0 0 15px rgba(0,0,0,0.2)',
  borderRadius: '5px',
  textAlign: 'left',
  lineHeight: '18px',
  color: '#555',
};

function ChoropethMap({
  center,
  zoom,
  data,
  rangos,
  handleSearh,
  tipo,
  label_tipo,
  handleTipoMapa,
  handleDetalleMapa,
  derecho_mapa,
  MenuPermisos,
  setExport,
}) {
  const [_center, setCenter] = useState(
    center ? center : [20.678416, -101.354231]
  );
  const [_zoom, setZoom] = useState(zoom ? zoom : 12);
  const _data = data ? data : null;
  const _rangos = rangos ? rangos : [];
  const flag = Object.keys(_data).length === 0 ? false : true;
  const _tipo = tipo ? tipo : 'municipio';
  const _derecho_mapa = derecho_mapa;
  //const exportar = Exportar ? Exportar : 0;
  const [tipoMapa, setTipoMapa] = useState({ value: tipo, label: label_tipo });
  const [countRango, setCountRango] = useState([]);
  const [flagData] = useState(true);
  const [flagTabla, setFlagTabla] = useState(false);
  const [buttonDetalle, setButonDetalle] = useState(false);
  const [textInput, setTextInput] = useState('');
  const [TipoPrioridad, setTipoPrioridad] = useState('0');

  //const toggle = () => setOpen(!dropdownOpen);
  const [prop, setProp] = useState(
    tipo === 'municipio'
      ? { nom_mun: '', cve_mun: '' }
      : tipo === 'region'
      ? { region: '' }
      : tipo === 'seccion'
      ? { seccion: '' }
      : { poligono: '' }
  );
  const [propManzana, setPropManzana] = useState({ MANZANA: '' });
  function roundTo(value, places) {
    var power = Math.pow(10, places);
    return Math.round(value * power) / power;
  }

  function style(feature, flag) {
    let style;
    if (flag === undefined || flag !== true) {
      if (feature.properties.MANZANA && tipoMapa.value === 'manzana') {
        style = {
          fillColor: feature.properties.color_porcentaje,
          weight: 2,
          opacity: 1,
          color: 'white',
          dashArray: '3',
          fillOpacity: 0.5,
        };
      } else if (feature.properties.poligono && tipoMapa.value === 'manzana') {
        style = {
          fillColor: feature.properties.color,
          weight: 2,
          opacity: 1,
          color: '#666',
          dashArray: '3',
          fillOpacity: 0.1,
        };
      } else {
        style = {
          fillColor: feature.properties.color,
          weight: 2,
          opacity: 1,
          color: 'white',
          dashArray: '3',
          fillOpacity: 0.3,
        };
      }
    } else {
      if (feature.properties.poligono && tipoMapa.value === 'manzana') {
        style = {
          weight: 5,
          color: '#666',
          dashArray: '',
          fillOpacity: 0.2,
        };
      } else {
        style = {
          weight: 5,
          color: '#666',
          dashArray: '',
          fillOpacity: 0.5,
        };
      }
    }
    return style;
  }

  function style_rangos(color, flag) {
    let style;
    if (flag && color !== null && color !== undefined) {
      style = {
        width: '18px',
        height: '18px',
        float: 'left',
        marginRight: '8px',
        opacity: '0.7',
        background: color,
      };
    } else {
      style = {
        width: '18px',
        height: '18px',
        float: 'left',
        marginRight: '8px',
        opacity: '0.7',
        background: '#FFEDA0',
      };
    }
    return style;
  }
  const handleExport = (e) => {
    setExport(e);
  };

  const highlightFeature = (e) => {
    //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.

    var layer = e.target;
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    if (_tipo === 'municipio')
      setProp({
        nom_mun: e.target.feature.properties.nom_mun,
        cve_mun: e.target.feature.properties.cve_mun,
        avance_porcentual: e.target.feature.properties.avance_porcentual,
        color: e.target.feature.properties.color,
        avance: e.target.feature.properties.avance,
        meta: e.target.feature.properties.meta,
        prop_save: e,
      });
    else if (_tipo === 'region')
      setProp({
        region: e.target.feature.properties.region,
        avance_porcentual: e.target.feature.properties.avance_porcentual,
        color: e.target.feature.properties.color,
        avance: e.target.feature.properties.avance,
        meta: e.target.feature.properties.meta,
        prop_save: e,
      });
    else if (_tipo === 'seccion')
      setProp({
        seccion: e.target.feature.properties.seccion,
        avance_porcentual: e.target.feature.properties.avance_porcentual,
        color: e.target.feature.properties.color,
        avance: e.target.feature.properties.avance,
        meta: e.target.feature.properties.meta,
        prop_save: e,
      });
    else if (_tipo === 'poligono')
      setProp({
        pg_poligon: e.target.feature.properties.pg_poligon,
        poligono: e.target.feature.properties.poligono,
        avance_porcentual: e.target.feature.properties.avance_porcentual,
        color: e.target.feature.properties.color,
        avance: e.target.feature.properties.avance,
        meta: e.target.feature.properties.meta,
        prop_save: e,
      });
    else if (_tipo === 'manzana')
      setPropManzana({
        MANZANA: e.target.feature.properties.MANZANA,
        SECCION: e.target.feature.properties.SECCION,
        PrioridadLabel: e.target.feature.properties.prioridadlabel,
        color: e.target.feature.properties.color,
        color_porcentaje: e.target.feature.properties.color_porcentaje,
        MetaEstructura: e.target.feature.properties.MetaEstructura,
        AvanceEstructura: e.target.feature.properties.AvanceEstructura,
        PorcentajeAvanceManzana:
          e.target.feature.properties.PorcentajeAvanceManzana,
        prop_save: e,
      });
    layer.setStyle(style(layer.feature, true));
  };

  const clickFeature = (e) => {
    setButonDetalle(true);
    highlightFeature(e);
  };

  const clickFeatureManzana = (e) => {
    setButonDetalle(true);
    highlightFeature(e);
  };
  const clickDetalle = (e) => {
    setButonDetalle(false);

    var siguiente_nivel = 'region';
    var siguiente_nivel_label = 'Regional';
    var siguiente_nivel_clave = '';

    if (tipoMapa.value === 'region') {
      siguiente_nivel = 'municipio';
      siguiente_nivel_label = 'Municipal';
      siguiente_nivel_clave = e.target.feature.properties.region;
    }
    if (tipoMapa.value === 'municipio') {
      siguiente_nivel = 'poligono';
      siguiente_nivel_label = 'Poligonal';
      siguiente_nivel_clave = e.target.feature.properties.cve_mun;
    }
    if (tipoMapa.value === 'poligono') {
      siguiente_nivel = 'seccion';
      siguiente_nivel_label = 'Seccional';
      siguiente_nivel_clave = e.target.feature.properties.poligono;
    }
    if (tipoMapa.value === 'seccion') {
      siguiente_nivel = 'seccion';
      siguiente_nivel_label = 'Seccional';
      /* siguiente_nivel = "manzana";
            siguiente_nivel_label = "Manzanas";
            siguiente_nivel_clave = e.target.feature.properties.seccion; */
      return; //CLICK NO PROGRAMADO
    }
    handleDetalleMapa({
      value: siguiente_nivel,
      label: siguiente_nivel_label,
      clave: siguiente_nivel_clave,
    });
    setTipoMapa({ value: siguiente_nivel, label: siguiente_nivel_label });
    //Aqui configuramos lo que queremos que haga un feature cuando le den click.
    //alert(e.target.feature.properties.nom_mun);
  };

  const resetHighlight = (e) => {
    //Con esto reseteamos el color en donde paso el mouse, para que no siga marcado.
    var layer = e.target;
    layer.setStyle(style(e.target.feature));
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  };

  const onEachFeature = (feature, layer) => {
    //Organizamos los eventos del GeoJson.
    if (tipoMapa.value === 'manzana') {
      if (layer.feature.properties.poligono !== undefined) {
        setProp({
          pg_poligon: layer.feature.properties.pg_poligon,
          poligono: layer.feature.properties.poligono,
          avance_porcentual: layer.feature.properties.avance_porcentual,
          color: layer.feature.properties.color,
          avance: layer.feature.properties.avance,
          meta: layer.feature.properties.meta,
          ManzanaGeneral: layer.feature.properties.ManzanaGeneral,
          AvanceGeneral: layer.feature.properties.AvanceGeneral,
          ManzanaP1: layer.feature.properties.ManzanaP1,
          ManzanaP2: layer.feature.properties.ManzanaP2,
          ManzanaP3: layer.feature.properties.ManzanaP3,
          faltantes_general: layer.feature.properties.faltantes_general,
          faltantes_mp1: layer.feature.properties.faltantes_mp1,
          faltantes_mp2: layer.feature.properties.faltantes_mp2,
          faltantes_mp3: layer.feature.properties.faltantes_mp3,
          AvanceManzanaP1: layer.feature.properties.AvanceManzanaP1,
          AvanceManzanaP2: layer.feature.properties.AvanceManzanaP2,
          AvanceManzanaP3: layer.feature.properties.AvanceManzanaP3,
          color_mp1: layer.feature.properties.color_mp1,
          color_mp2: layer.feature.properties.color_mp2,
          color_mp3: layer.feature.properties.color_mp3,
          color_general: layer.feature.properties.color_general,
          avance_porcentual_mp1: layer.feature.properties.avance_porcentual_mp1,
          avance_porcentual_mp2: layer.feature.properties.avance_porcentual_mp2,
          avance_porcentual_mp3: layer.feature.properties.avance_porcentual_mp3,
          avance_porcentual_general:
            layer.feature.properties.avance_porcentual_general,
          prop_save: layer,
        });
      } else {
        layer.on({
          //mouseover: highlightFeature,
          //mouseout: resetHighlight,
          click: clickFeatureManzana,
        });
        let text = layer.feature.properties.MANZANA;
        layer.bindTooltip(text + '', { permanent: false, direction: 'center' });
      }
    } else {
      layer.on({
        //mouseover: highlightFeature,
        //mouseout: resetHighlight,
        click: clickFeature,
      });
    }
  };

  var geojson = (
    <GeoJSON
      onEachFeature={onEachFeature}
      style={style}
      key={'geojson'}
      data={_data}
    ></GeoJSON>
  );
  useEffect(() => {
    setButonDetalle(false);
    setProp({});
    setPropManzana({});
    var array_counter = [];
    var color_rangos = [];
    if (_rangos.length !== 0 && _rangos.length !== undefined) {
      color_rangos = _rangos.map((item) => {
        return item.Color;
      });
      array_counter = color_rangos.map((item) => {
        return 0;
      });
    }
    if (Object.keys(_data).length !== 0) {
      _data.features.forEach(function (f) {
        color_rangos.forEach(function (item, i) {
          if (item === f.properties.color) {
            array_counter[i] = array_counter[i] + 1;
          }
        });
      });
      setCountRango(array_counter);
      if (_data.features.length !== 0) {
        if (tipoMapa.value === 'manzana') {
          const new_data = {};
          new_data.properties = _data.properties;
          new_data.type = 'FeatureCollection';
          new_data.features = _data.features.filter(
            (item) => item.properties.poligono !== undefined
          );
          const { zoom, center } = centerOnOneShape(flagData, new_data);
          setCenter(center);
          setZoom(zoom);
        } else {
          const { zoom, center } = centerOnOneShape(flagData, _data);
          setCenter(center);
          setZoom(zoom);
        }
      }
    }
  }, [data, rangos]);

  const miTipoMapaChange = (e) => {
    setTipoMapa(e);
    handleTipoMapa(e);
  };
  const eventHandleSearh = (e) => {
    if (textInput.length <= 0) {
      e.preventDefault();
    }
    handleSearh(textInput, TipoPrioridad);
  };
  return (
    <>
      {flag ? (
        MenuPermisos.Ver === 1 ? (
          <Map
            zoom={_zoom}
            zoomControl={false}
            center={_center}
            style={{ width: '100%', height: '90vh' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <div className="leaflet-control-container">
              <div className="leaflet-top leaflet-right">
                <div className="leaflet-control" style={style_square_superior}>
                  {_tipo === 'municipio' ? (
                    <>
                      <h6>INFORMACIÓN:</h6>
                      {prop.nom_mun !== '' && prop.nom_mun !== undefined ? (
                        <>
                          <b style={style_b}>Municipio:</b>
                          <b style={{ color: prop.color }}> {prop.nom_mun}</b>
                          <br></br>
                          <b style={style_b}>Estructuras:</b>
                          <b style={{ color: prop.color }}>
                            {' '}
                            {new Intl.NumberFormat('en-EN').format(prop.avance)}
                          </b>
                          <br></br>
                          <b style={style_b}>Meta:</b>
                          <b style={{ color: prop.color }}>
                            {' '}
                            {new Intl.NumberFormat('en-EN').format(prop.meta)}
                          </b>
                          <br></br>
                          <b style={style_b}>Avance:</b>
                          <b style={{ color: prop.color }}>
                            {roundTo(prop.avance_porcentual, 2)} %
                          </b>
                          <br></br>
                        </>
                      ) : (
                        <b> ¡Pulse sobre un municipio!</b>
                      )}
                    </>
                  ) : _tipo === 'region' ? (
                    <>
                      <h6>INFORMACIÓN:</h6>
                      {prop.region !== '' && prop.region !== undefined ? (
                        <>
                          <b style={style_b}>Región:</b>
                          <b style={{ color: prop.color }}> {prop.region}</b>
                          <br></br>
                          <b style={style_b}>Estructuras:</b>
                          <b style={{ color: prop.color }}>
                            {' '}
                            {new Intl.NumberFormat('en-EN').format(prop.avance)}
                          </b>
                          <br></br>
                          <b style={style_b}>Meta:</b>
                          <b style={{ color: prop.color }}>
                            {' '}
                            {new Intl.NumberFormat('en-EN').format(prop.meta)}
                          </b>
                          <br></br>
                          <b style={style_b}>Avance:</b>
                          <b style={{ color: prop.color }}>
                            {roundTo(prop.avance_porcentual, 2)} %
                          </b>
                          <br></br>
                        </>
                      ) : (
                        <b> ¡Pulse sobre una región!</b>
                      )}
                    </>
                  ) : _tipo === 'seccion' ? (
                    <>
                      <h6>INFORMACIÓN:</h6>
                      {prop.seccion !== '' && prop.seccion !== undefined ? (
                        <>
                          <b style={style_b}>Sección:</b>
                          <b style={{ color: prop.color }}> {prop.seccion}</b>
                          <br></br>
                          <b style={style_b}>Estructuras:</b>
                          <b style={{ color: prop.color }}>
                            {' '}
                            {new Intl.NumberFormat('en-EN').format(prop.avance)}
                          </b>
                          <br></br>
                          <b style={style_b}>Meta:</b>
                          <b style={{ color: prop.color }}>
                            {' '}
                            {new Intl.NumberFormat('en-EN').format(prop.meta)}
                          </b>
                          <br></br>
                          <b style={style_b}>Avance:</b>
                          <b style={{ color: prop.color }}>
                            {roundTo(prop.avance_porcentual, 2)} %
                          </b>
                          <br></br>
                        </>
                      ) : (
                        <b> ¡Pulse sobre una sección!</b>
                      )}
                    </>
                  ) : (
                    <>
                      <h6>INFORMACIÓN:</h6>
                      {prop.poligono !== '' && prop.poligono !== undefined ? (
                        <>
                          <b style={style_b}>Polígono:</b>
                          <b style={{ color: prop.color }}>
                            {' '}
                            {prop.pg_poligon}
                          </b>
                          <br></br>
                          <b style={style_b}>Estructuras:</b>
                          <b style={{ color: prop.color }}>
                            {' '}
                            {new Intl.NumberFormat('en-EN').format(prop.avance)}
                          </b>
                          <br></br>
                          <b style={style_b}>Meta:</b>
                          <b style={{ color: prop.color }}>
                            {' '}
                            {new Intl.NumberFormat('en-EN').format(prop.meta)}
                          </b>
                          <br></br>
                          <b style={style_b}>Avance:</b>
                          <b style={{ color: prop.color }}>
                            {roundTo(prop.avance_porcentual, 2)} %
                          </b>

                          {_tipo === 'manzana' ? (
                            <>
                              <br></br>
                              <b style={style_b}>Meta Manzana General:</b>
                              <b style={{ color: prop.color_general }}>
                                {prop.ManzanaGeneral}
                              </b>
                              <br></br>

                              <b style={style_b}>
                                Manzanas No Asignadas General:
                              </b>
                              <b style={{ color: prop.color_general }}>
                                {prop.faltantes_general}
                              </b>
                              <br></br>

                              <b style={style_b}>Manzanas Asignadas General:</b>
                              <b style={{ color: prop.color_general }}>
                                {prop.AvanceGeneral}
                              </b>
                              <br></br>

                              <b style={style_b}>
                                Porcentaje de Manzanas General:
                              </b>
                              <b style={{ color: prop.color_general }}>
                                {roundTo(prop.avance_porcentual_general, 2)} %
                              </b>
                              <br></br>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <b> ¡Pulse sobre un polígono!</b>
                      )}
                    </>
                  )}
                  {prop.poligono !== '' &&
                  prop.poligono !== undefined &&
                  (TipoPrioridad === '0' || TipoPrioridad === '1') ? (
                    <div className="leaflet-control-container">
                      <div className="leaflet-top leaflet-right">
                        <div
                          className="leaflet-control"
                          style={{
                            padding: '6px 8px',
                            font: '14px/16px Arial, Helvetica, sans-serif',
                            background: 'rgba(255,255,255,0.8)',
                            boxShadow: '0 0 15px rgba(0,0,0,0.2)',
                            borderRadius: '5px',
                            position: 'relative',
                            top: '165px',
                          }}
                        >
                          {
                            <>
                              <h6>INFORMACIÓN DEL POLÍGONO - MANZANA P1:</h6>
                              {_tipo === 'manzana' ? (
                                <>
                                  <b style={style_b}>Meta Manzana P1:</b>
                                  <b style={{ color: prop.color_mp1 }}>
                                    {prop.ManzanaP1}
                                  </b>
                                  <br></br>

                                  <b style={style_b}>
                                    Manzanas No Asignadas P1:
                                  </b>
                                  <b style={{ color: prop.color_mp1 }}>
                                    {prop.faltantes_mp1}
                                  </b>
                                  <br></br>

                                  <b style={style_b}>Manzanas Asignadas P1:</b>
                                  <b style={{ color: prop.color_mp1 }}>
                                    {prop.AvanceManzanaP1}
                                  </b>
                                  <br></br>

                                  <b style={style_b}>
                                    Porcentaje de Manzanas P1:
                                  </b>
                                  <b style={{ color: prop.color_mp1 }}>
                                    {roundTo(prop.avance_porcentual_mp1, 2)} %
                                  </b>
                                  <br></br>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {prop.poligono !== '' &&
                  prop.poligono !== undefined &&
                  (TipoPrioridad === '0' || TipoPrioridad === '2') ? (
                    <div className="leaflet-control-container">
                      <div className="leaflet-top leaflet-right">
                        <div
                          className="leaflet-control"
                          style={{
                            padding: '6px 8px',
                            font: '14px/16px Arial, Helvetica, sans-serif',
                            background: 'rgba(255,255,255,0.8)',
                            boxShadow: '0 0 15px rgba(0,0,0,0.2)',
                            borderRadius: '5px',
                            position: 'relative',
                            top: TipoPrioridad === '0' ? '295px' : '165px',
                          }}
                        >
                          {
                            <>
                              <h6>INFORMACIÓN DEL POLÍGONO - MANZANA P2:</h6>
                              {_tipo === 'manzana' ? (
                                <>
                                  <b style={style_b}>Meta Manzana P2:</b>
                                  <b style={{ color: prop.color_mp2 }}>
                                    {prop.ManzanaP2}
                                  </b>
                                  <br></br>

                                  <b style={style_b}>
                                    Manzanas No Asignadas P2:
                                  </b>
                                  <b style={{ color: prop.color_mp2 }}>
                                    {prop.faltantes_mp2}
                                  </b>
                                  <br></br>

                                  <b style={style_b}>Manzanas Asignadas P2:</b>
                                  <b style={{ color: prop.color_mp2 }}>
                                    {prop.AvanceManzanaP2}
                                  </b>
                                  <br></br>

                                  <b style={style_b}>
                                    Porcentaje de Manzanas P2:
                                  </b>
                                  <b style={{ color: prop.color_mp2 }}>
                                    {roundTo(prop.avance_porcentual_mp2, 2)} %
                                  </b>
                                  <br></br>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {prop.poligono !== '' &&
                  prop.poligono !== undefined &&
                  (TipoPrioridad === '0' || TipoPrioridad === '3') ? (
                    <div className="leaflet-control-container">
                      <div className="leaflet-top leaflet-right">
                        <div
                          className="leaflet-control"
                          style={{
                            padding: '6px 8px',
                            font: '14px/16px Arial, Helvetica, sans-serif',
                            background: 'rgba(255,255,255,0.8)',
                            boxShadow: '0 0 15px rgba(0,0,0,0.2)',
                            borderRadius: '5px',
                            position: 'relative',
                            top: TipoPrioridad === '0' ? '430px' : '165px',
                          }}
                        >
                          {
                            <>
                              <h6>INFORMACIÓN DEL POLÍGONO - MANZANA P3:</h6>
                              {_tipo === 'manzana' ? (
                                <>
                                  <b style={style_b}>Meta Manzana P3:</b>
                                  <b style={{ color: prop.color_mp3 }}>
                                    {prop.ManzanaP3}
                                  </b>
                                  <br></br>

                                  <b style={style_b}>
                                    Manzanas No Asignadas P3:
                                  </b>
                                  <b style={{ color: prop.color_mp3 }}>
                                    {prop.faltantes_mp3}
                                  </b>
                                  <br></br>

                                  <b style={style_b}>Manzanas Asignadas P3:</b>
                                  <b style={{ color: prop.color_mp3 }}>
                                    {prop.AvanceManzanaP3}
                                  </b>
                                  <br></br>

                                  <b style={style_b}>
                                    Porcentaje de Manzanas P3:
                                  </b>
                                  <b style={{ color: prop.color_mp3 }}>
                                    {roundTo(prop.avance_porcentual_mp3, 2)} %
                                  </b>
                                  <br></br>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {tipoMapa.value === 'manzana' &&
                  propManzana.MANZANA !== undefined ? (
                    <div className="leaflet-control-container">
                      <div className="leaflet-top leaflet-right">
                        <div
                          className="leaflet-control"
                          style={_style_square_superior_left_manzanas}
                        >
                          {
                            <>
                              <h6>INFORMACIÓN DE LA MANZANA:</h6>
                              {propManzana.MANZANA !== '' &&
                              propManzana.MANZANA !== undefined ? (
                                <>
                                  <b style={style_b}>Manzana:</b>
                                  <b
                                    style={{
                                      color: propManzana.color_porcentaje,
                                    }}
                                  >
                                    {' '}
                                    {propManzana.MANZANA}
                                  </b>
                                  <br></br>
                                  <b style={style_b}>Seccion:</b>
                                  <b
                                    style={{
                                      color: propManzana.color_porcentaje,
                                    }}
                                  >
                                    {' '}
                                    {propManzana.SECCION}
                                  </b>
                                  <br></br>
                                  <b style={style_b}>Prioridad:</b>
                                  <b
                                    style={{
                                      color: propManzana.color_porcentaje,
                                    }}
                                  >
                                    {' '}
                                    {propManzana.PrioridadLabel}
                                  </b>
                                  <br></br>
                                  <b style={style_b}>Meta Manzaneras:</b>
                                  <b
                                    style={{
                                      color: propManzana.color_porcentaje,
                                    }}
                                  >
                                    {' '}
                                    {propManzana.MetaEstructura}
                                  </b>
                                  <br></br>
                                  <b style={style_b}>Avance Manzaneras:</b>
                                  <b
                                    style={{
                                      color: propManzana.color_porcentaje,
                                    }}
                                  >
                                    {' '}
                                    {propManzana.AvanceEstructura}
                                  </b>
                                  <br></br>
                                  <b style={style_b}>
                                    Porcentaje Avance Manzaneras:
                                  </b>
                                  <b style={{ color: propManzana.color_porcentaje }}>
                                    {' '}
                                    {roundTo(
                                      propManzana.PorcentajeAvanceManzana,
                                      2
                                    ) + '%'}
                                  </b>
                                  <br></br>
                                </>
                              ) : (
                                <b> ¡Pulse sobre una manzana!</b>
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {buttonDetalle &&
                  prop.seccion === undefined &&
                  tipoMapa.value !== 'manzana' ? (
                    <ButtonGroup>
                      <Button
                        style={style_button_radiogroup}
                        size="sm"
                        className="btn btn-sm btn-round"
                        color={'success'}
                        outline
                        type="button"
                        onClick={(e) => {
                          clickDetalle(prop.prop_save);
                        }}
                      >
                        Ver Detalle
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {tipoMapa.value === 'manzana' ? (
              <div className="leaflet-control-container">
                <div className="leaflet-top leaflet-left">
                  <div
                    className="leaflet-control"
                    style={_style_square_superior_left_search}
                  >
                    <form onSubmit={eventHandleSearh}>
                      <input
                        className="btn-sm btn-round"
                        color={'success'}
                        style={{ height: '31px' }}
                        outline="true"
                        size="sm"
                        type="text"
                        value={textInput}
                        onChange={(e) => {
                          setTextInput(e.target.value);
                        }}
                        placeholder="20_20, 17_1, etc."
                      ></input>
                      <select
                        name="prioridad"
                        id="prioridad"
                        className="btn-sm btn-round"
                        color={'success'}
                        style={{ height: '31px' }}
                        outline="true"
                        size="sm"
                        //defaultValue ={TipoPrioridad === 0? {value: TipoPrioridad,label:'TODAS'}:{value: TipoPrioridad,label:'PRIORIDAD '+TipoPrioridad}}
                        //value={TipoPrioridad === 0? {value: TipoPrioridad,label:'TODAS'}:{value: TipoPrioridad,label:'PRIORIDAD '+TipoPrioridad}}
                        onChange={(e) => {
                          setTipoPrioridad(e.target.value);
                          //eventHandleSearh(e)
                        }}
                      >
                        {TipoPrioridad === '0' ? (
                          <>
                            <option selected value="0">
                              TODAS
                            </option>
                            <option value="1">PRIORIDAD 1</option>
                            <option value="2">PRIORIDAD 2</option>
                            <option value="3">PRIORIDAD 3</option>
                          </>
                        ) : TipoPrioridad === '1' ? (
                          <>
                            <option value="0">TODAS</option>
                            <option selected value="1">
                              PRIORIDAD 1
                            </option>
                            <option value="2">PRIORIDAD 2</option>
                            <option value="3">PRIORIDAD 3</option>
                          </>
                        ) : TipoPrioridad === '2' ? (
                          <>
                            <option value="0">TODAS</option>
                            <option value="1">PRIORIDAD 1</option>
                            <option selected value="2">
                              PRIORIDAD 2
                            </option>
                            <option value="3">PRIORIDAD 3</option>
                          </>
                        ) : (
                          <>
                            <option value="0">TODAS</option>
                            <option value="1">PRIORIDAD 1</option>
                            <option value="2">PRIORIDAD 2</option>
                            <option selected value="3">
                              PRIORIDAD 3
                            </option>
                          </>
                        )}
                      </select>
                      <ButtonGroup
                        size="sm"
                        className={'btn-group btn-group-sm'}
                        style={{ backgroundColor: 'FFF' }}
                      >
                        <Button
                          className="btn btn-sm btn-round"
                          color={'success'}
                          size="sm"
                          outline
                          type="submit"
                          onClick={eventHandleSearh}
                        >
                          Buscar Polígono
                        </Button>
                      </ButtonGroup>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {_tipo !== 'manzana' ? (
              <div className="leaflet-control-container">
                <div className="leaflet-bottom leaflet-left">
                  <div
                    className="leaflet-control"
                    style={style_square_inferior_tabla}
                  >
                    <Button
                      className="btn-round btn-sm"
                      color={'success'}
                      outline
                      size="sm"
                      type="button"
                      onClick={() => setFlagTabla(!flagTabla)}
                    >
                      {flagTabla ? 'Esconder' : 'Mostrar'}
                    </Button>
                    {flagTabla ? (
                      <>
                        {MenuPermisos.Exportar === 1 ? (
                          <Button
                            className="btn-round btn-sm"
                            color={'success'}
                            outline
                            size="sm"
                            type="button"
                            onClick={() => {
                              handleExport(_data);
                            }}
                          >
                            Exportar
                          </Button>
                        ) : (
                          <></>
                        )}

                        <Table
                          responsive
                          striped
                          border="2px"
                          style={{
                            height: '200px',
                            overflow: 'scroll',
                            display: 'block',
                          }}
                        >
                          <thead className="text-primary">
                            <tr>
                              {_tipo === 'region' ? (
                                <th>Región</th>
                              ) : _tipo === 'municipio' ? (
                                <th>Municipio</th>
                              ) : _tipo === 'poligono' ? (
                                <th>Polígono</th>
                              ) : _tipo === 'seccion' ? (
                                <th>Sección</th>
                              ) : (
                                <th>Sin especificar</th>
                              )}

                              <th>Meta </th>
                              <th>Estructuras</th>
                              <th>Porcentaje de Avance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_data.features.map(function (item, i) {
                              return (
                                <tr key={i}>
                                  {_tipo === 'region' ? (
                                    <td>
                                      <div
                                        style={{ color: item.properties.color }}
                                      >
                                        {item.properties.region}
                                      </div>
                                    </td>
                                  ) : _tipo === 'municipio' ? (
                                    <td>
                                      <div
                                        style={{ color: item.properties.color }}
                                      >
                                        {item.properties.nom_mun}
                                      </div>
                                    </td>
                                  ) : _tipo === 'poligono' ? (
                                    <td>
                                      <div
                                        style={{ color: item.properties.color }}
                                      >
                                        {item.properties.pg_poligon}
                                      </div>
                                    </td>
                                  ) : _tipo === 'seccion' ? (
                                    <td>
                                      <div
                                        style={{ color: item.properties.color }}
                                      >
                                        {item.properties.seccion}
                                      </div>
                                    </td>
                                  ) : (
                                    <th>Sin especificar</th>
                                  )}
                                  <td>
                                    <div
                                      style={{ color: item.properties.color }}
                                    >
                                      {new Intl.NumberFormat('en-EN').format(
                                        item.properties.meta
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{ color: item.properties.color }}
                                    >
                                      {new Intl.NumberFormat('en-EN').format(
                                        item.properties.avance
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{ color: item.properties.color }}
                                    >
                                      {item.properties.avance === 0 &&
                                      item.properties.meta === 0
                                        ? '--'
                                        : roundTo(
                                            item.properties.avance_porcentual,
                                            2
                                          ) + '%'}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="leaflet-control-container">
              <div className="leaflet-top leaflet-left">
                <div
                  className="leaflet-control"
                  style={style_square_superior_left}
                >
                  <ButtonGroup style={{ backgroundColor: '#FFF' }}>
                    {_derecho_mapa.includes(4) ? (
                      <Button
                        className="btn-round"
                        color={tipoMapa.value === 'region' ? 'success' : 'info'}
                        outline
                        type="button"
                        onClick={() =>
                          miTipoMapaChange({
                            value: 'region',
                            label: 'Regional',
                          })
                        }
                      >
                        Región
                      </Button>
                    ) : (
                      <></>
                    )}
                    {_derecho_mapa.includes(3) ? (
                      <Button
                        className="btn-round"
                        color={
                          tipoMapa.value === 'municipio' ? 'success' : 'info'
                        }
                        outline
                        type="button"
                        onClick={() =>
                          miTipoMapaChange({
                            value: 'municipio',
                            label: 'Municipal',
                          })
                        }
                      >
                        Municipio
                      </Button>
                    ) : (
                      <></>
                    )}
                    {_derecho_mapa.includes(2) ? (
                      <Button
                        className="btn-round"
                        color={
                          tipoMapa.value === 'poligono' ? 'success' : 'info'
                        }
                        outline
                        type="button"
                        onClick={() =>
                          miTipoMapaChange({
                            value: 'poligono',
                            label: 'Poligonal',
                          })
                        }
                      >
                        Polígono
                      </Button>
                    ) : (
                      <></>
                    )}
                    {_derecho_mapa.includes(1) ? (
                      <Button
                        className="btn-round"
                        color={
                          tipoMapa.value === 'seccion' ? 'success' : 'info'
                        }
                        outline
                        type="button"
                        onClick={() =>
                          miTipoMapaChange({
                            value: 'seccion',
                            label: 'Seccional',
                          })
                        }
                      >
                        Sección
                      </Button>
                    ) : (
                      <></>
                    )}
                    {_derecho_mapa.includes(2) ? (
                      <Button
                        className="btn-round"
                        color={
                          tipoMapa.value === 'manzana' ? 'success' : 'info'
                        }
                        outline
                        type="button"
                        onClick={() =>
                          miTipoMapaChange({
                            value: 'manzana',
                            label: 'Manzana',
                          })
                        }
                      >
                        Manzana
                      </Button>
                    ) : (
                      <></>
                    )}
                  </ButtonGroup>
                </div>
              </div>
            </div>

            {_tipo !== 'manzana' ? (
              <div className="leaflet-control-container">
                <div className="leaflet-bottom leaflet-right">
                  <div
                    className="legend leaflet-control"
                    style={style_square_inferior_rangos}
                  >
                    <h6>Avance Estructura:</h6>
                    {_rangos !== null &&
                    _rangos.length !== 0 &&
                    _rangos.length !== undefined ? (
                      <>
                        {_rangos.map((rango, i) => {
                          return (
                            <div key={rango.id.toString()}>
                              <i style={style_rangos(rango.Color, true)}></i>
                              <b>{`${rango.Label} - Cantidad ${countRango[i]}`}</b>
                              <br></br>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <b> Cargando...</b>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {geojson}
          </Map>
        ) : (
          <SinPermisos></SinPermisos>
        )
      ) : (
        <div style={style_spinner}>
          <MagicSpinner
            size={70}
            color={'#008000'}
            loading={true}
          ></MagicSpinner>
        </div>
      )}
    </>
  );
}

export default ChoropethMap;
