import React from 'react';
import Notifications from 'react-notify-toast';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';

const Principal = (props) => {
	const handleMiniClick = () => {
		document.body.classList.toggle('sidebar-mini');
	};

	return (
		<div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel">
				<Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
				<div className="content">
					<h3> sian-Guanajuato</h3>
				</div>
				<Footer fluid />
			</div>
		</div>
	);
};

export default Principal;
