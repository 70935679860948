import React, { useState, useEffect } from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Spinner,
  CardBody,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import { RotateSpinner } from "react-spinners-kit";
import EjecutarApi from "../../data/getPOST";

const CapacitadosRG = ({isOpen, item, setIsOpen, catCapacitaciones, token}) => {

    const style = {
        content: {
        border: '0',
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '10rem',
        maxHeight: '10rem',
        left: '50%',
        padding: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '20rem',
        width: '90%',
        maxWidth: '60rem',
        },
    };
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoUser, setInfoUser] = useState(null);
  const [state, setState] = useState({
    idCapacitacion: {label: '', value: ''}, Comentarios: '',
    Evaluacion: {label: '5', value: '5'},
    AceptaCargo: null,
    Rechazado: null,
    MotivoRechazo: "",
    Asistio: null,
    isMotivoDesconocido: null,
    MotivoNoAsistio: ""});
  const [catEvaluacion,setcatEvaluacion] = useState([
      {value:1,label:'1'},
      {value:2,label:'2'},
      {value:3,label:'3'},
      {value:4,label:'4'},
      {value:5,label:'5'},
      {value:6,label:'6'},
      {value:7,label:'7'},
      {value:8,label:'8'},
      {value:9,label:'9'},
      {value:10,label:'10'},
  ]);  

  useEffect(() =>{
  }, [item])

  const handleEvento = (e) => {
    setState({ ...state, idCapacitacion: e });
  }
  const btnSaveCapacitado = () => {

    const Invitado= {
      idCapacitacion: state.idCapacitacion.value,
      ClaveElector: item.ClaveElector,
      Nombre: item.Nombre,
      Paterno: item.Paterno,
      Materno: item.Materno,
      FechaNacimiento: item.FechaNacimiento,
      Sexo: item.Sexo,
      idEstadoNacimiento: item.idEstadoNacimiento,
      Calle: item.CalleVive,
      NumExt: item.NumExtVive,
      NumInt: item.NumIntVive,
      Colonia: item.Colonia,
      CP: item.CPVive,
      idMunicipio: item.Municipio.value,
      SeccionVota: item.SeccionVota,
      OCR: item.OCR,
      FechaVigencia: item.VigenciaINE,
      Celular: item.Celular,
      Celular2: '',
      TelCasa: item.TelCasa,
      TelRecados: item.TelMensajes,
      Correo: item.Correo,
      Comentarios: state.Comentarios,
      Evaluacion: state.Evaluacion.value,
      AceptaCargo: state.AceptaCargo,
      Rechazado: state.Rechazado,
      MotivoRechazo: state.MotivoRechazo,
      idResponsabilidad: item.idResponsabilidad,
      Responsabilidad: '',
      DFED: item.Distrito.value ,
      Seccion: item.Seccion,
      Casilla: item.TipoCasilla,
      Propietario: item.idPropietario,
      Asistio: state.Asistio,
      isMotivoDesconocido: state.isMotivoDesconocido,
      MotivoNoAsistio: state.MotivoNoAsistio,
      UserCrated: token.user.id
    }

     DatosCapacitacion(Invitado);
  };

  const DatosCapacitacion= (state) => {
    setLoading(true);
		
    if(!state.idCapacitacion ){
        notify.show("Error: Evento es requerido", "error", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      }
  
      if(state.Asistio !== null){
        if(state.Asistio===1){
          if(state.AceptaCargo !==null){
            if(state.AceptaCargo===2){
              if(state.MotivoRechazo ==="" ){
                notify.show("Error: Motivo Rechazo es requerido", "error", 5000, {
                  background: "#0857B6",
                  fontSize: 14,
                  text: "#FFFFFF",
                });
              }
            }
          }else{
            notify.show("Error: Aceptar Cargo es requerido", "error", 5000, {
              background: "#0857B6",
              fontSize: 14,
              text: "#FFFFFF",
            });
          }
  
        }if(state.Asistio===2){
          if(state.isMotivoDesconocido !==null){
            if(state.isMotivoDesconocido ===1){
              if(state.MotivoNoAsistio  ==="" ){
                notify.show("Error: Motivo no Asistio es requerido", "error", 5000, {
                  background: "#0857B6",
                  fontSize: 14,
                  text: "#FFFFFF",
                });
              }
            }
          }else{
            notify.show("Error: Aviso que no llegaría es requerido", "error", 5000, {
              background: "#0857B6",
              fontSize: 14,
              text: "#FFFFFF",
            });
          }
        }
  
      }else{
        notify.show("Error: Asistio es requerido", "error", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      }

		EjecutarApi(state, "setInvitadosSingleWeb", token.access_token)
		.then(function (res){
	
			return res;
		})
		.then((res)=>{
			if (res.success) {
				if (res.results) {
						notify.show(`El registro se agrego con éxito!!`, "success", 5000, {
							background: "#18c97d",
							fontSize: 24,
							text: "#FFFFFF",
						});
					}else
					{
						notify.show(
							`Error al agregar registro: ${res.message}!!`,
							"custom",
							5000,
							{
							background: "#0857B6",
							fontSize: 24,
							text: "#FFFFFF",
							}
						);
						return true;
					}
			} else {
			notify.show(
				`Error al insertar el registro: ${res.message}!!`,
				"custom",
				5000,
				{
				background: "#0857B6",
				fontSize: 24,
				text: "#FFFFFF",
				}
			);}
			return true;
		})
		.catch((e) => {
			notify.show(`Error al agregar el registro(catch): ${e.error}`, "custom", 5000, {
			background: "#0857B6",
			fontSize: 24,
			text: "#FFFFFF",
			});
		})
    .finally( e => {
      setLoading(false);
    })
		
	};
  
  return (
    <>
      <Modal
        isOpen={isOpen}
        style={style}
        toggle={setIsOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Notifications />
        <ModalHeader toggle={setIsOpen} className=" text-primary">
          <strong color="primary">{item.Fullname}</strong> 
        </ModalHeader>
        <ModalBody>
            {isLoading && <Spinner color="primary" />}
            {!isLoading && (<>
              <Row>
                <FormGroup className={"col-md-12"}>
                  <Label for="idCapacitacion">
                    <span className="text-danger">*</span>Evento de Capacitacion 
                  </Label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    id="idCapacitacion"
                    placeholder="Seleccion Evento Capacitación"
                    value={state.idCapacitacion}
                    options={catCapacitaciones}
                    onChange={(e) => handleEvento(e)}
                  />
                </FormGroup>

                <CardBody>
                  <Row>
                    <Col md="6">
                    <FormGroup className="col-md-6 float-center text-primary">
                          <strong>Asistio</strong>
                          <br />
                      </FormGroup>
                      <Col md="12" className="d-flex">
                          <FormGroup check className="col-md-3 ">
                              <Label check >
                              <Input 
                                  type="checkbox"
                                  checked={state.Asistio === 1}
                                  value={state.Asistio}
                                  onChange={() =>
                                  setState({
                                      ...state,
                                      Asistio: 1,
                                  })
                                  }
                              />{" "}
                              si
                              <span className="form-check-sign ">
                                  <span className="check"></span>
                              </span>
                              </Label>
                          </FormGroup>
                          <FormGroup check className="col-md-3 float-center">
                              <Label check>
                              <Input 
                                  type="checkbox"
                                  checked={state.Asistio === 2}
                                  value={state.Asistio}
                                  onChange={() =>
                                  setState({
                                      ...state,
                                      Asistio: 2,
                                  })
                                  }
                              />{" "}
                              No
                              <span className="form-check-sign  float-center">
                                  <span className="check"></span>
                              </span>
                              </Label>
                          </FormGroup>
                        </Col>
                    </Col>
                    <Col md="6">
                      {state.Asistio === 1 &&<Row>    
                        <FormGroup className="col-md-12 text-primary">
                            <strong>¿Aceptó Cargo?</strong>
                            <br />
                        </FormGroup>
                        <FormGroup check className="col-md-3 text-center">
                            <Label check >
                            <Input 
                                type="checkbox"
                                checked={state.AceptaCargo === 1}
                                value={state.AceptaCargo}
                                onChange={() =>
                                setState({
                                    ...state,
                                    AceptaCargo: 1,
                                })
                                }
                            />{" "}
                            si
                            <span className="form-check-sign  text-center">
                                <span className="check"></span>
                            </span>
                            </Label>
                        </FormGroup>
                        <FormGroup check className="col-md-3 text-center">
                          <Label check>
                            <Input 
                                type="checkbox"
                                checked={state.AceptaCargo === 2}
                                value={state.AceptaCargo}
                                onChange={() =>
                                setState({
                                    ...state,
                                    AceptaCargo: 2,
                                })
                                }
                            />{" "}
                            No
                            <span className="form-check-sign  text-center">
                                <span className="check"></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </Row>}
                      {state.Asistio === 2 &&<Row>
                        <FormGroup className="col-md-12 text-primary">
                            <strong>Aviso que no llegaria?</strong>
                            <br />
                        </FormGroup>
                        <FormGroup check className="col-md-3 text-center">
                            <Label check >
                            <Input 
                                type="checkbox"
                                checked={state.isMotivoDesconocido === 1}
                                value={state.isMotivoDesconocido}
                                onChange={() =>
                                setState({
                                    ...state,
                                    isMotivoDesconocido: 1,
                                })
                                }
                            />{" "}
                            si
                            <span className="form-check-sign  text-center">
                                <span className="check"></span>
                            </span>
                            </Label>
                        </FormGroup>
                        <FormGroup check className="col-md-3 text-center">
                            <Label check>
                            <Input 
                                type="checkbox"
                                checked={state.isMotivoDesconocido === 2}
                                value={state.isMotivoDesconocido}
                                onChange={() =>
                                setState({
                                    ...state,
                                    isMotivoDesconocido: 2,
                                })
                                }
                            />{" "}
                            No
                            <span className="form-check-sign  text-center">
                                <span className="check"></span>
                            </span>
                            </Label>
                        </FormGroup>
                      </Row>}
                    </Col>
                  </Row>
                </CardBody>
              </Row>

              <Row>
                <CardBody>
                  <Row>
                    <Col md="12">
                      { state.Asistio === 1 && state.AceptaCargo === 1 &&
                        <FormGroup className={"col-md-6" }>
                          <Label for="Evaluacion">
                          <span className="text-danger">*</span>Evaluación
                          </Label>
                          <Select
                          options={catEvaluacion}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="Evaluacion"
                          placeholder="Sexo"
                          value={state.Evaluacion}
                          onChange={(e) => setState({...state, Evaluacion:e})}
                          />
                        </FormGroup>
                      } 
                      {state.AceptaCargo === 2 &&
                        <FormGroup className={"col-md-12"}>
                          <Label for="Nombre">
                              <span className="text-danger">*</span>¿Cual fue el motivo para no aceptar el cargo?
                          </Label>
                          <Input
                              id="Nombre"
                              type="text"
                              value={state.MotivoRechazo}
                              onChange={(e) =>
                              setState({
                                  ...state,
                                  MotivoRechazo: e.target.value.toUpperCase(),
                              })}
                          />
                        </FormGroup>
                      }
                      {state.isMotivoDesconocido === 1 && state.Asistio === 2 &&<Row>
                        <FormGroup className={"col-md-12"}>
                          <Label for="Nombre">
                              <span className="text-danger">*</span>¿Cual fue el motivo?
                          </Label>
                          <Input
                              id="Nombre"
                              type="text"
                              value={state.MotivoNoAsistio}
                              onChange={(e) =>
                              setState({
                                  ...state,
                                  MotivoNoAsistio: e.target.value.toUpperCase(),
                              })}
                          />
                        </FormGroup>
                      </Row>}
                    </Col>
                  </Row>
                </CardBody>
              </Row>
              <Row>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup className={"col-md-12"}>
                        <Label for="Comentario">
                            Comentarios
                        </Label>
                        <Input
                            id="Comentario"
                            type="text"
                            value={state.Comentarios}
                            onChange={(e) =>
                            setState({
                                ...state,
                                Comentarios: e.target.value.toUpperCase(),
                            })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Row>
              <Row>
                <Col>
                  {/* {!loading && */}
                  <Button
                    color="primary" 
                    onClick={btnSaveCapacitado}
                    disabled={loading}
                  >Guardar
                  </Button>
                  {/* {loading && (
                    <Spinner className="pull-left" color="primary" />
                  )} */}
                </Col>
              </Row>

            </>
            )}
        </ModalBody>
        <ModalFooter>
          <Button className="mr-5" color="danger" onClick={setIsOpen}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CapacitadosRG;
