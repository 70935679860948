import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Label,
    CardHeader
} from "reactstrap";
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
import { URL_GENERAL } from "data/Consts";
//import Filter from "../components/compromisoFiltro/compromisoFiltro";
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';
import Filtro from "../../components/AvanceCaptura/Filtro";
import { getVars, URL_PUBLICA } from "GlobalStorage";

const AvanceCompromiso = (props) => {
    // const { dispatch } = useContext(Context);
    const [estatalDetalle, setEstatalDetalle] = useState([]);
    const [estatal, setEstatal] = useState({ Regional: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Municipios: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Poligonos: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 }, Secciones: { MetaCompromisosPartido: 0, MetaEstructuraPartido: 0, AvanceCompromisos: 0, AvanceEstructura: 0 } });
    const [loading, setLoading] = useState(false);
    const [_menu, _setmenu] = useState({});
    const [total, setTotal] = useState(0);
    const [sortered, setSortered] = useState([]);
    const [region, setRegion] = useState([]);
    const [municipio, setMunicipio] = useState([]);
    const [poligono, setPoligono] = useState([]);
    const [seccion, setSeccion] = useState([]);
    const [nombre, setNombre] = useState("");
    const [regionSelected, setRegionSelected] = useState({ value: 0, label: 'Todos' })
    const [municipioSelected, setMunicipioSelected] = useState({ value: 0, label: 'Todos' });
    const [catMunicipio, setCatMunicipio] = useState([{ value: 0, label: 'Todos' }])
    const token = getVars("Token");

    const responsibilityID = token.user.Responsabilidad.value;

    useEffect(() => {
        setLoading(true)
        _setmenu(getDataMenu(81, token.menus))


        EjecutarApi({}, "getResumenEstructuraEstatal", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                setLoading(false)
                setEstatal(res.data);
            })
            .catch((e) => {
                setLoading(false)
                notify.show(`Error en totales: ${e.error}`, "custom", 5000, {
                    background: "#0857B6",
                    fontSize: 14,
                    text: "#FFFFFF",
                });
            });


    }, []); //loadingTotal, loadingCompromisos

    const showMessage = (message, background) => {
        notify.show(message, "custom", 5000, {
            background: background,
            fontSize: 14,
            text: "#FFFFFF",
        });
    }

    // HANDLER DE TODOS LOS SELECT DEL FILTRO ESTRUCTURA


    const fitroSend = (filtered) => {

        setEstatalDetalle([])
        setLoading(true)
        var sorted = [];
        var filtro = [];
        var filtroGrafica = [];
        var region = [];
        var municipio = [];
        var seccion = [];
        var poligono = [];
        var manzana = [];
        var nombre = "";
        var Responsable ="";

        if (filtered.length !== 0) {
            region = filtered[0]['id'] === "idRegion" ? (filtered[0]['value']) : [];
            municipio = filtered[1]['id'] === "idMunicipio" ? (filtered[1]['value']) : [];
            seccion = filtered[3]['id'] === "S.Seccion" ? (filtered[3]['value']) : [];
            poligono = filtered[2]['id'] === "idPoligono" ? (filtered[2]['value']) : [];
            manzana = filtered[8]['id'] === "idManzana" ? (filtered[8]['value']) : [];
            nombre = filtered[4]['id'] === "Nombre" ? (filtered[4]['value']) : "";
            Responsable = filtered[9]['id'] === "Responsable" ? (filtered[9]['value']) : "";

            if (filtered[5]['id'] === "u.email" && filtered[5]['value'] !== "") {
                filtro.push(filtered[5])
            }
            if (filtered[6]['id'] === "S.Rentabilidad" && filtered[6]['value'] !== "") {
                filtro.push(filtered[6])
            }
            if (filtered[7]['id'] === "cm.PRIORIDAD" && filtered[7]['value'] !== "") {
                filtro.push(filtered[7])
            }

            setRegion(region)
            setMunicipio(municipio)
            setPoligono(poligono)
            setSeccion(seccion)
            setNombre(nombre)
        }

        const data = {
            page: 0,
            pageSize: 10,
            sorted: sortered ? [sortered] : [],
            Region: region ? region : [],
            Municipio: municipio ? municipio : [],
            Poligono: poligono ? poligono : [],
            Seccion: seccion ? seccion : [],
            Nombre: nombre ? nombre : "",
        };
        EjecutarApi(data, "getCompromisosCaptura", token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.success) {
                    if (res.results) {
                        setTotal(res.total);
                        setEstatalDetalle(
                            res.data.map((prop, key) => {
                                return {
                                    Region: prop.Region ? prop.Region : "Sin Región",
                                    Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                    Poligono: prop.Poligono ? prop.Poligono : "Sin Polígono",
                                    Seccion: prop.Seccion ? prop.Seccion : "Sin Seccón",
                                    Nombre: prop.Responsable,
                                    Celular: prop.ResponsableCel,
                                    Voluntarios: prop.Voluntarios,
                                    compromisos: prop.Compromisos,
                                    Responsabilidad: prop.ResponsabilidadData,
                                    data: prop,
                                };
                            })
                        );
                        setLoading(false)
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(
                    `Error al obtener getMetaEstructurasGeneralFiltro: ${e.message}`,
                    "custom",
                    5000,
                    {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                    }
                );
            });
    }

    const LimpiarFiltro = () => {
        setMunicipioSelected({ value: 0, label: 'Todos' })
        setRegionSelected({ value: 0, label: 'Todos' })
        fitroSend([], [], "clear");
    }




    const Exportar = () => {
        var url = new URL(
            `${URL_GENERAL}getReporteAvanceGeneralMunicipio?token=${token.access_token}`
        );
        window.open(url, "_blank");

    }

    const showRegion = responsibilityID === 99 || responsibilityID === 98 || responsibilityID === 10
    const showMunicipio = (responsibilityID === 99 || responsibilityID === 98 || responsibilityID === 10 || responsibilityID === 53 || responsibilityID === 54 || responsibilityID === 55)

    const styleHeader = {
        textAlign: "center",
        overflow: "visible",
        fontSize: "11px",
        color: "#363636",
        lineHeight: "normal !important"
    }

    const header = (text) => {
        return (<Label style={styleHeader}>{text}</Label>)
    }





    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <Filtro filtrar={fitroSend} limpiar={LimpiarFiltro} responsibilityID={responsibilityID} ></Filtro>
                                        </Col>

                                    </Row>
                                    <Row>
                                        {(_menu.Exportar === 1 && !loading) ? (
                                            <Col>
                                                <Button name="btnExportar" size='sm' type="button" className='primary float-right' color='primary' onClick={() => Exportar()}>
                                                    <span className="btn-label">
                                                        <i className="fa fa-file-excel-o fa-lg" />
                                                    </span>
                            Exportar
							      </Button>

                                            </Col>
                                        ) : <></>}
                                    </Row>

                                </CardHeader>
                                {loading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={loading}
                                    />
                                )}
                                <Col lg="12" md="12">
                                    <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                                        <ReactTable
                                            style={{
                                                width: "100%",
                                                height: "100%"
                                            }}
                                            data={estatalDetalle}
                                            noDataText="Sin información"
                                            manual
                                            columns={[
                                                {
                                                    Header: header("Región"),
                                                    minWidth: 150,
                                                    id: "idRegion",
                                                    accessor: (d) => d.Region,
                                                    filterAll: false,
                                                },
                                                {
                                                    Header: header("Municipios"),
                                                    minWidth: 150,
                                                    id: "Municipios",
                                                    accessor: (d) => d.Municipio,
                                                    filterAll: false,
                                                },
                                                {
                                                    Header: header("Polígono"),
                                                    minWidth: 150,
                                                    id: "idPolígono",
                                                    accessor: (d) => d.Poligono,
                                                    filterAll: false,
                                                }, {
                                                    Header: header("Sección"),
                                                    // minWidth: 20,
                                                    id: "Secion",
                                                    accessor: (d) => d.Seccion,
                                                    filterAll: false
                                                },
                                                {
                                                    Header: header("Nombre"),
                                                    // minWidth: 20,
                                                    id: "Nombres",
                                                    accessor: (d) => d.Nombre,
                                                    filterAll: false,
                                                }, {
                                                    Header: header("Celular"),
                                                    // minWidth: 20,
                                                    id: "email",
                                                    accessor: (d) => d.Celular,
                                                    filterAll: false,
                                                }, {
                                                    Header: header("Compromisos"),
                                                    // minWidth: 20,
                                                    id: "Compromisos",
                                                    accessor: (d) => d.compromisos,
                                                    filterAll: false,
                                                }, {
                                                    Header: header("Voluntarios"),
                                                    // minWidth: 20,
                                                    id: "Voluntarios",
                                                    accessor: (d) => d.Voluntarios,
                                                    filterAll: false,
                                                },
                                                {
                                                    Header: header("Responsabilidad"),
                                                    // minWidth: 20,
                                                    id: "u.ResponsabilidadData",
                                                    accessor: (d) => d.Responsabilidad,
                                                    filterAll: false,
                                                }
                                            ]}
                                            className="-striped -highlight primary-pagination"
                                            loading={loading}
                                            showPagination={true}
                                            showPaginationBottom={true}
                                            showTotalPages={true}
                                            showPageJump={false}
                                            canNextFromData={true}
                                            defaultPageSize={10}
                                            total={total}
                                            previousText="Anterior"
                                            nextText="Siguiente"
                                            ofText="de"
                                            pageSizeOptions={[10, 20, 25, 50, 100]}
                                            PaginationComponent={Pagination}
                                            onFetchData={(stateComp, instance) => {
                                                setSortered(instance.sorted)
                                                setLoading(true);
                                                const data = {
                                                    page: stateComp.page,
                                                    pageSize: stateComp.pageSize,
                                                    sorted: instance.sorted,
                                                    Region: region ? region : [],
                                                    Municipio: municipio ? municipio : [],
                                                    Poligono: poligono ? poligono : [],
                                                    Seccion: seccion ? seccion : [],
                                                    Nombre: nombre ? nombre : "",
                                                    tipo: 'and',
                                                    /* filtered: Filtro?Filtro:[], */
                                                };
                                                EjecutarApi(data, "getCompromisosCaptura", token.access_token)
                                                    .then(function (res) {
                                                        return res;
                                                    })
                                                    .then((res) => {
                                                        if (res.success) {
                                                            if (res.results) {
                                                                setTotal(res.total);
                                                                setEstatalDetalle(
                                                                    res.data.map((prop, key) => {
                                                                        return {
                                                                            Region: prop.Region ? prop.Region : "Sin Región",
                                                                            Municipio: prop.Municipio ? prop.Municipio : "Sin Municipio",
                                                                            Poligono: prop.Poligono ? prop.Poligono : "Sin Polígono",
                                                                            Seccion: prop.Seccion ? prop.Seccion : "Sin Seccón",
                                                                            Nombre: prop.Responsable,
                                                                            Celular: prop.ResponsableCel,
                                                                            Voluntarios: prop.Voluntarios,
                                                                            compromisos: prop.Compromisos,
                                                                            Responsabilidad: prop.ResponsabilidadData,
                                                                            data: prop,
                                                                        };
                                                                    })
                                                                );
                                                                setLoading(false);
                                                            }
                                                        }

                                                    })
                                                    .catch((e) => {
                                                        setLoading(false);
                                                        notify.show(
                                                            `Error al obtener tabla metas estructura region: ${e.message}`,
                                                            "custom",
                                                            5000,
                                                            {
                                                                background: "#0857B6",
                                                                fontSize: 14,
                                                                text: "#FFFFFF",
                                                            }
                                                        );
                                                    });
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                    </Row>


                </div>
                <Footer fluid />
            </div>
        </div >
    );
};

export default AvanceCompromiso;
