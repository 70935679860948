import React, { useState, useEffect } from 'react';
import defaultImage from 'assets/img/prep/Acta-01.svg';

import { Row, Col, Button } from 'reactstrap';

const ImagenActa = (props) => {
  const { imagen, returnImagen } = props;
  const [image, setImage] = useState({
    archivo: null,
    url: defaultImage,
  });

  useEffect(() => {
    if (imagen.url !== '') {
      setImage({ ...image, url: imagen.url });
    }
  }, [imagen]);

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      const image = {
        archivo: file,
        url: reader.result,
      };
      setImage(image);
      returnImagen(image);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Row>
        <Col className="center-holder">
          <img
            className="picture-acta"
            src={image.url}
          />
        </Col>
      </Row>
      <Row align="center">
        {/* <Col>
          <Button
            color="warning"
            size="sm"
            // onClick={handleValidarDatosBusqueda}
          >
            Ver imagen
          </Button>
        </Col> */}
        <Col>
          <input
            type="file"
            className="picture-input"
            onChange={(e) => handleImageChange(e)}
          />
          <Button
            color="primary"
            size="sm"
            // onClick={handleValidarDatosBusqueda}
          >
            Subir imagen
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ImagenActa;
