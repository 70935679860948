import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { notify } from "react-notify-toast";
import ReactTable from 'react-table';
import { RotateSpinner } from "react-spinners-kit";


import EjecutarApi from "data/getPOST";
import Pagination from "../Pagination/pagination";
import { getVars } from "GlobalStorage";


const BuscaResponsableElectoral = ({ props, onHide, datos }) => {
    const [loading, setLoading] = useState(false);
    const token = getVars("Token");
    const [DatosResponsable, setDatosResponsable] = useState([]);
    const [Responsable, setResponsable] = useState('');

    const handleResponsable = (item) => {
        setResponsable(item.Responsable);
        datos(item);
        onHide();
    }
    const handleBuscar = () => {
        var parametro = {
            page: 0,
            pageSize: 5,
            filtered: [
                { id: "NombrePropone", value: Responsable },
            ]
        };
        EjecutarApi(parametro, 'getElectoralPropone', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    if (res.total > 0) {
                        setLoading(false);
                        setDatosResponsable(res.data.map((item) => {
                            return {
                                Responsable: (<Button color='primary' className='primary' size='sm' onClick={() => handleResponsable(item)}>
                                    {item.Cargo}{' - '}{item.NombrePropone}
                                </Button>),

                            }
                        }));
                    }
                    else {
                        setLoading(false);
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                    background: "#d61818",
                    text: "#FFFFFF",
                });
            });
    }

    return (
        <Modal
            isOpen={true}
            toggle={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader toggle={onHide} >
                Agregar Responsabilidad
            </ModalHeader>
            <ModalBody>
                <Col sm="12">
                    {<RotateSpinner color={"#1261ff"} size={30} loading={loading} />}
                    <Row className='text-align-center-center'>
                        <Col sm="10">
                            Nombre:
                            <Input id="nombre" value={Responsable} onChange={(e) => setResponsable(e.target.value)} />
                        </Col>
                        <Col sm="2">
                            <Button id="bbuscar" size='sm' className='warning' color='warning' onClick={(e) => handleBuscar(e)} >
                                <i className="fa fa-search-fa-lg" aria-hidden="true"></i>Búscar</Button>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <ReactTable className="-striped -highlight primary-pagination"
                                size='sm'
                                data={DatosResponsable}
                                noDataText="Sin información"
                                manual
                                columns={[
                                    {
                                        Header: "Responsable",
                                        minWidth: 40,
                                        id: "Responsable",
                                        accessor: (d) => d.Responsable,
                                        filterAll: false,

                                    }
                                ]}
                                loading={loading}
                                showPagination={true}
                                showPaginationBottom={true}
                                showTotalPages={true}
                                showPageJump={false}
                                canNextFromData={true}
                                defaultPageSize={5}
                                total={DatosResponsable.length}
                                pages={Math.floor(DatosResponsable.length / 5)}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                pageSizeOptions={[5, 10]}
                                PaginationComponent={Pagination}
                                onFetchData={(stateComp) => {
                                    setLoading(true);
                                    var parametro = {
                                        page: stateComp.page,
                                        pageSize: stateComp.pageSize,
                                        filtered: [
                                            { id: "NombrePropone", value: Responsable },
                                        ]
                                    };
                                    EjecutarApi(parametro, 'getElectoralPropone', token.access_token)
                                        .then(function (res) {
                                            return res;
                                        })
                                        .then((res) => {
                                            if (res.results) {
                                                if (res.total > 0) {
                                                    setLoading(false);
                                                    setDatosResponsable(res.data.map((item) => {
                                                        return {
                                                            Responsable: (<Button color='primary' className='btn-link' size='sm' onClick={() => handleResponsable(item)}>
                                                                {item.Cargo}{' - '}{item.NombrePropone}
                                                            </Button>),

                                                        }
                                                    }));
                                                }
                                                else {
                                                    setLoading(false);
                                                }
                                            }
                                        })
                                        .catch((e) => {
                                            setLoading(false);
                                            notify.show(`Error en la búsqueda de Responsable: ${e.message}`, "error", 5000, {
                                                background: "#d61818",
                                                text: "#FFFFFF",
                                            });
                                        });
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>

    );
};
export default BuscaResponsableElectoral;
