import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col, ButtonGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Notifications, { notify } from "react-notify-toast";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Autosuggest from "react-autosuggest";
import moment from "moment";
import { CircleSpinner } from "react-spinners-kit";
import { RotateSpinner } from "react-spinners-kit";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import { getVars } from "GlobalStorage";
import BuscarOwned from "components/Usuarios/BuscarUserOwned"
import EjecutarApi from "data/getPOST";
import { getDataMenu } from 'data/menus.js';

const CompromisosAdd = (props) => {

    const [Capacitacion, setCapacitacion] =
    useState({
      isEstatal: null,
      idTipoCapacitacion: null,

    })

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };
  
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Card className="col-lg-12">
                            <CardHeader>
                                <h5 className="title">Datos Del Evento</h5>
                            </CardHeader>
                            <CardBody>
                                    <FormGroup className="col-md-6 text-warning">
                                        <Label for="Responsable">
                                            <span className="text-danger">*</span>Responsable:
                                            </Label>
                                        <InputGroup>
                                            <Input
                                            type="text"
                                            disabled
                                            />
                                            <InputGroupAddon addonType="append">
                                            <Button
                                                style={{ margin: '0px 1px' }}
                                                color="warning"
                                            ><i className="nc-icon nc-zoom-split" /></Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <Row>
                                        <FormGroup className={"col-md-4"}>
                                            <Label for="INE">
                                            {" "}
                                            <span className="text-danger">*</span>Clave Evento
                                            </Label>
                                            <Input
                                            type="text"
                                            id="INE"
                                            maxLength="18"
                                            />
                                        </FormGroup>
                                        <FormGroup className={"col-md-4"}>
                                            <Label for="Nombre">
                                            <span className="text-danger">*</span>Nombre Evento
                                            </Label>
                                            <Input
                                            id="Nombre"
                                            type="text"
                                            />
                                        </FormGroup>
                                    </Row>        
                                    <Row>
                                        <FormGroup className={"col-md-4"}>
                                            <Label for="Fechacumple">
                                            <span className="text-danger">*</span>Fecha de Evento
                                            </Label>
                                            <ReactDatetime
                                            id="Fechacumple"
                                            name="Fechacumple"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            closeOnSelect
                                            inputProps={{ className: "form-control" }}
                                            />
                                        </FormGroup>
                                        <FormGroup className={"col-md-4"}>
                                            <Label for="ssexo">
                                            <span className="text-danger">*</span>Hora inicial
                                            </Label>
                                            <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            id="ssexo"
                                            placeholder="Sexo"
                                            />
                                        </FormGroup>
                                        <FormGroup className={"col-md-4"}>
                                            <Label for="ssexo">
                                            <span className="text-danger">*</span>Hora Final
                                            </Label>
                                            <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            id="ssexo"
                                            placeholder="Sexo"
                                            />
                                        </FormGroup>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup className="col-md-12 text-center text-primary">
                                            <strong>Alcance del Evento</strong>
                                            <br />
                                        </FormGroup>
                                        <Col md="4" >
                                            <FormGroup check className="col-md-3 text-center">
                                                <Label check >
                                                <Input 
                                                    type="checkbox"
                                                    checked={Capacitacion.isEstatal === 1}
                                                    value={Capacitacion.isEstatal}
                                                    onChange={() =>
                                                    setCapacitacion({
                                                        ...Capacitacion,
                                                        isEstatal: 1,
                                                    })
                                                    }
                                                />{" "}
                                                Estatal
                                                <span className="form-check-sign  text-center">
                                                    <span className="check"></span>
                                                </span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup check className="col-md-3 text-center">
                                                <Label check>
                                                <Input 
                                                    type="checkbox"
                                                    checked={Capacitacion.isEstatal === 2}
                                                    value={Capacitacion.isEstatal}
                                                    onChange={() =>
                                                    setCapacitacion({
                                                        ...Capacitacion,
                                                        isEstatal: 2,
                                                    })
                                                    }
                                                />{" "}
                                                Municipal
                                                <span className="form-check-sign  text-center">
                                                    <span className="check"></span>
                                                </span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Col>
                                </Row>
                                    {Capacitacion.isEstatal ===2 &&<Row>
                                        <FormGroup className={"col-md-4" }>
                                            <Label for="ssexo">
                                            <span className="text-danger">*</span>Municipio
                                            </Label>
                                            <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            id="ssexo"
                                            placeholder="Sexo"
                                            />
                                        </FormGroup>
                                
                                    </Row>}
                                    <Row>
                                            <FormGroup className="col-md-12 text-center text-primary">
                                                <strong>Tipo del Evento</strong>
                                                <br />
                                            </FormGroup>
                                            <FormGroup check className="col-md-3 text-center">
                                                <Label check>
                                                <Input
                                                    type="checkbox"
                                                    checked={Capacitacion.idTipoCapacitacion === 1}
                                                    value={Capacitacion.idTipoCapacitacion}
                                                    onChange={() =>
                                                    setCapacitacion({
                                                        ...Capacitacion,
                                                        idTipoCapacitacion: 1,
                                                    })
                                                    }
                                                />{" "}
                                                Presencial
                                                <span className="form-check-sign  text-center">
                                                    <span className="check"></span>
                                                </span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check className="col-md-3 text-center">
                                                <Label check>
                                                <Input
                                                    type="checkbox"
                                                    checked={Capacitacion.idTipoCapacitacion === 2}
                                                    value={Capacitacion.idTipoCapacitacion}
                                                    onChange={() =>
                                                    setCapacitacion({
                                                        ...Capacitacion,
                                                        idTipoCapacitacion: 2,
                                                    })
                                                    }
                                                />{" "}
                                                En Linea
                                                <span className="form-check-sign  text-center">
                                                    <span className="check"></span>
                                                </span>
                                                </Label>
                                            </FormGroup>

                                        </Row>
                                    {Capacitacion.idTipoCapacitacion ===1 &&<Row>
                                        
                                            <FormGroup className={"col-md-4"}>
                                                <Label for="ssexo">
                                                <span className="text-danger">*</span>Ubicacion
                                                </Label>
                                                <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="ssexo"
                                                placeholder="Sexo"
                                                />
                                            </FormGroup>
                                            <FormGroup className={"col-md-4"}>
                                                <Label for="ssexo">
                                                <span className="text-danger">*</span>Municipio
                                                </Label>
                                                <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                id="ssexo"
                                                placeholder="Sexo"
                                                />
                                            </FormGroup>
                                            <FormGroup className="col-md-4">
                                                <Label for="CalleVive" className="labelForm">
                                                    <span className="text-danger">*</span>Calle
                                                </Label>
                                                <Input
                                                    id="CalleVive"
                                                    type="text"
                                                />
                                            </FormGroup>
                                            <FormGroup className="col-md-4">
                                                <Label for="CalleVive" className="labelForm">
                                                    <span className="text-danger">*</span>Número Ext.
                                                </Label>
                                                <Input
                                                    id="CalleVive"
                                                    type="text"
                                                />
                                            </FormGroup>
                                            <FormGroup className="col-md-4">
                                                <Label for="CalleVive" className="labelForm">
                                                    <span className="text-danger">*</span>Número Int.
                                                </Label>
                                                <Input
                                                    id="CalleVive"
                                                    type="text"
                                                />
                                            </FormGroup>
                                            <FormGroup className="col-md-4">
                                                <Label for="ColoniaVive" className="labelForm">
                                                    <span className="text-danger">*</span>Colonia
                                                </Label>
                                                <Autosuggest
                                                />
                                            </FormGroup>
                                            <FormGroup className="col-md-4">
                                                <Label for="CalleVive" className="labelForm">
                                                    <span className="text-danger">*</span>CP.
                                                </Label>
                                                <Input
                                                    id="CalleVive"
                                                    type="text"
                                                />
                                            </FormGroup>
                                    </Row>}
                                    {Capacitacion.idTipoCapacitacion ===2 &&<Row>
                                    
                                            <FormGroup className="col-md-6">
                                                <Label for="CalleVive" className="labelForm">
                                                    <span className="text-danger">*</span>Link del Evento
                                                </Label>
                                                <Input
                                                    id="CalleVive"
                                                    type="text"
                                                />
                                            </FormGroup>
                                
                                    </Row>}
                                    
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <FormGroup className="col-md-12 pr-2 text-center">
                                        <Button
                                            color="danger"
                                        >Limpiar
                                        </Button>{" "}
                                        <Button color="primary" >
                                            Guardar
                                        </Button>
                                    </FormGroup>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Row>
                    <Footer fluid />
                </div>
            </div>
        </div>
    );
};

export default CompromisosAdd;