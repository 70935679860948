import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Button, Collapse, NavbarBrand, Navbar, Container } from 'reactstrap';

import { getVars } from '../GlobalStorage';

const AdminNavbar = (props) => {
	const [sidebarOpen] = useState(false);
	const [mistate, setState] = useState({
		collapseOpen: true,
		color: 'navbar-transparent',
	});

	const updateColor = () => {
		if (window.innerWidth < 993 && mistate.collapseOpen) {
			setState({ ...mistate, color: 'bg-white' });
		} else {
			setState({ ...mistate, color: 'navbar-transparent' });
		}
	};

	const handleMiniClick = () => {
		document.body.classList.toggle("sidebar-mini");
	};

	useEffect(() => {
		window.addEventListener('resize', updateColor);


	}, []);
	// this function opens and closes the sidebar on small devices
	const toggleSidebar = () => {
		document.documentElement.classList.toggle('nav-open');
	};
	// this function opens and closes the collapse on small devices
	// it also adds navbar-transparent class to the navbar when closed
	// ad bg-white when opened
	const toggleCollapse = () => {
		let newState = {
			collapseOpen: !mistate.collapseOpen,
		};
		if (!mistate.collapseOpen) {
			newState['color'] = 'bg-white';
		} else {
			newState['color'] = 'navbar-transparent';
		}
		setState(newState);
	};

	return (
		<>
			<Navbar className={classnames('navbar-absolute fixed-top', mistate.color)} expand="lg">
				<Container fluid>
					<div className="navbar-wrapper">
						<div className="navbar-minimize">
							<Button
								className="btn-icon btn-round"
								color="default"
								id="minimizeSidebar"
								onClick={handleMiniClick}
							>
								<i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
								<i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
							</Button>
						</div>
						<div
							className={classnames('navbar-toggle', {
								toggled: sidebarOpen,
							})}
						>
							<button className="navbar-toggler" type="button" onClick={toggleSidebar}>
								<span className="navbar-toggler-bar bar1" />
								<span className="navbar-toggler-bar bar2" />
								<span className="navbar-toggler-bar bar3" />
							</button>
						</div>
						<NavbarBrand href="#chr" onClick={(e) => e.preventDefault()}>
							<span className="d-none d-md-block">{getVars('CurrentMenu').CurrentMenu}</span>
							<span className="d-block d-md-none">Guanajuato</span>
						</NavbarBrand>
					</div>
					<button
						aria-controls="navigation-index"
						aria-expanded={mistate.collapseOpen}
						aria-label="Toggle navigation"
						className="navbar-toggler"
						// data-target="#navigation"
						data-toggle="collapse"
						type="button"
						onClick={toggleCollapse}
					>
						<span className="navbar-toggler-bar navbar-kebab" />
						<span className="navbar-toggler-bar navbar-kebab" />
						<span className="navbar-toggler-bar navbar-kebab" />
					</button>
					<Collapse className="justify-content-end" navbar isOpen={mistate.collapseOpen}></Collapse>
				</Container>
			</Navbar>
		</>
	);
};

export default AdminNavbar;
