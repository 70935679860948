import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Input,
  Row,
  Col,InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";
import queryString from 'query-string';
import {RotateSpinner} from "react-spinners-kit";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";
import swal from 'sweetalert';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';
import EjecutarApi from '../../data/getPOST';
import { getVars } from '../../GlobalStorage';

import { makeid } from '../../utils/funciones';

import catTipoPregunta from '../../data/CallCenter/call_tipo_pregunta.json';



import 'react-tagsinput/react-tagsinput.css'


const CuestionariosEdit = (props) => {
  // const { dispatch } = useContext(Context);

  let params = queryString.parse(props.location.search);

  const [auditoriaData, setAuditoriaData] = useState(
    { 
      id: null,
      Nombre: '', 
      Descripcion: ''});
  const [respuestas, setRespuestas] = useState([]);
  const [preguntasCuest, setPreguntasCust] = useState([]);

  const [preguntas, setPreguntas] = useState(
    { 
      id: null,
      idTipoPregunta: {value: 1, label: 'Opción Multiple'},
      Pregunta: '', 
      Ordenado: null,
      Respuesta: '',
      Descripcion: ''
    });

  const [loading, setLoading] = useState(true);
  const token = getVars("Token");


  useEffect(() =>{

    const data = {
      page: 0,
      pageSize: 1,
      sorted: [],
      filtered: [{id: 'C.id', value: params.id}]
    };
    EjecutarApi(data, "getCuestionario", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        //setLoading(false);

        if(res.results)
        {
          if(res.data.length)
          {
            setAuditoriaData(res.data[0]);
          }else
          {
            props.history.goBack();

          }
          
        }
        
      
      })
      .catch((e) => {
        setLoading(false);
        
      });

      //Preguntas
      EjecutarApi({idCuestionario: params.id}, "getCuestionarioPreguntas", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false);

        if(res.results)
        {
          if(res.data.length)
          {
            setPreguntasCust(res.data);
          }
          
        }
        
      
      })
      .catch((e) => {
        setLoading(false);
        
      });

  },[])

  useEffect(() =>{
  },[respuestas, preguntas, preguntasCuest])

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  
  const frmCapturaCuestionario = () => {
    setLoading(true);

    if (auditoriaData.Nombre.length < 1) {
      setLoading(false);
      notify.show("Error: Nombre del cuestionario es requerido", "error", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }


    const data = {
      Nombre: auditoriaData.Nombre,
      Descripcion: auditoriaData.Descripcion
    }

    if(!auditoriaData.id)
    {
        const data = {
          Nombre: auditoriaData.Nombre,
          Descripcion: auditoriaData.Descripcion
        }

        //Captura nuevo
        EjecutarApi(data, "setCuestionario", token.access_token, "POST")
          .then(function (res) {
            return res;
          })
          .then((setCuestionarioRS) => {
            setLoading(false);

            if(setCuestionarioRS.results)
            {
              setAuditoriaData(setCuestionarioRS.data);
              const item =[{id: makeid(10), Respuesta: ''}];
              setRespuestas(item);
            }else
            {
              swal({
                title: "Error al guardar",
                text: setCuestionarioRS.message,
                icon: "error",
              });
            }

            
            setLoading(false);
          })
          .catch((e) => {

          setLoading(false);
          // notify.show(`Error: setCuestionarioRS ${e.message}`, "custom", 5000, {
          //   background: "#0857B6",
          //   fontSize: 14,
          //   text: "#FFFFFF",
          // });
        });
    }else
    {
      const data = {
        id: auditoriaData.id,
        Nombre: auditoriaData.Nombre,
        Descripcion: auditoriaData.Descripcion
      }


      EjecutarApi(data, "updCuestionario", token.access_token, "POST")
          .then(function (res) {
            return res;
          })
          .then((setCuestionarioRS) => {
            setLoading(false);

            if (setCuestionarioRS.results) {
              setAuditoriaData({...auditoriaData, id: setCuestionarioRS.data.id})
              
            } else {
              // notify.show("No Info: catCompromisos", "custom", 5000, {
              //   background: "#0857B6",
              //   fontSize: 14,
              //   text: "#FFFFFF",
              // });
            }
            setLoading(false);
          })
          .catch((e) => {
          setLoading(false);
          notify.show(`Error: setCuestionarioRS ${e.message}`, "custom", 5000, {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          });
        });
    }

    


    //swal("Hello world!");
    setLoading(false);
  }

  const btnAddRespuesta = () => {
  }

  const frmCapturaPregunta = () => {
  }

  const setTipoPreguntasSave = (index, info) => {


    const newList = preguntasCuest.map((item) => {
      if (item.id === preguntasCuest[index].id) {
        const updatedItem = {
          ...item,
          idTipoPregunta: info.value,
          TipoPreguntas: info.label
        };
 
        return updatedItem;
      }
 
      return item;
    });
 
    setPreguntasCust(newList);

  }

  const setPreguntasSave = (index, Pregunta) => {


    const newList = preguntasCuest.map((item) => {
      if (item.id === preguntasCuest[index].id) {
        const updatedItem = {
          ...item,
          Pregunta
        };
 
        return updatedItem;
      }
 
      return item;
    });
 
    setPreguntasCust(newList);

  }

  const setRespuestaData = (idPreg, idResp, Respuesta) => {
    const newList = preguntasCuest.map((item) => {
      if (item.id === preguntasCuest[idPreg].id) {
        const RespuestasTmp = item.Respuestas;
        const newListResp = RespuestasTmp.map((itemResp) => {
          if (itemResp.id === preguntasCuest[idPreg].Respuestas[idResp].id) {
            
            const updatedItemResp = {
              ...itemResp,
              Respuesta
            };
     
            return updatedItemResp;
          }
     
          return itemResp;
        });
        const updatedItemResp = {
          ...item,
          Respuestas: RespuestasTmp
        };
 
        return updatedItemResp;

      }
 
      return item;
    });
 
    setPreguntasCust(newList);
  }

 

  
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>
          <Card className="col-md-12">
              <CardHeader>
                <h5 className="title">Captura de Cuestionario</h5>
              </CardHeader>
              <CardBody>
                <Col className="col-md-5">
                  <Form className="form" onSubmit={(e) => {frmCapturaCuestionario(); e.preventDefault();}}>
                    <Row>
                      <FormGroup className={"col-md-5"}>
                        <Label for="Nombre">
                          {" "}
                          <span className="text-danger">*</span>Nombre 
                        </Label>
                        <Input
                          type="text"
                          id="Nombre"
                          value={auditoriaData.Nombre}
                          onChange={(event) => {
                            setAuditoriaData({
                              ...auditoriaData,
                              Nombre: event.target.value,
                            });
                          }}
                          maxLength={120}
                          
                        />
                      </FormGroup>
                      
                    </Row>
                    <Row>
                      <FormGroup className={"col-md-5"}>
                        <Label for="Descripcion">
                          <span className="text-danger">*</span>Descripción 
                        </Label>
                        <Input
                          type="textarea" 
                          id="Descripcion"
                          multiple={true}
                          value={auditoriaData.Descripcion}
                          onChange={(event) => {
                            setAuditoriaData({
                              ...auditoriaData,
                              Descripcion: event.target.value,
                            });
                          }}
                          
                        />
                      </FormGroup>
                      
                    </Row>
                    <Row>
                      <FormGroup className="col-md-4 pr-2 text-center">
    
                        {!loading &&<Button color="primary" onClick={() => frmCapturaCuestionario()}>                    
                          {auditoriaData.id ? `Guardar` : `Agregar`}
                        </Button>}
                        <RotateSpinner color={"#1261ff"}size={30} loading={loading}/>
                      </FormGroup>
                    </Row>
                  </Form>
                </Col>
              </CardBody>
              <CardFooter>
                
              </CardFooter>
            </Card>
          </Row>
          <Row>
          <Card className="col-md-12">
              <CardHeader>
                <h5 className="title">Preguntas para el Cuestionario</h5>
              </CardHeader>
              <CardBody>
                <Col className="col-md-6">
                  <Form className="form" onSubmit={(e) => {frmCapturaPregunta(); e.preventDefault();}}>
                    <Row>
                      <FormGroup className="col-md-4">
                        <Label for="TipoPregunta">
                          <span className="text-danger">*</span>Tipo Pregunta
                        </Label>
                        <Select
                          className="react-select primary"
                          options={catTipoPregunta.data}
                          value={preguntas.idTipoPregunta}
                          id="TipoPregunta"
                          onChange={(event) => {setPreguntas({...preguntas, idTipoPregunta: event})}}
                          placeholder="seleccione Tipo Pregunta"
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className={"col-md-12"}>
                        <Label for="Nombre">
                          <span className="text-danger">*</span>Pregunta 
                        </Label>
                        <Input
                          type="text"
                          id="Pregunta"
                          value={preguntas.Pregunta.toUpperCase()}
                          onChange={(event) => {
                            setPreguntas({
                              ...preguntas,
                              Pregunta: event.target.value.toUpperCase(),
                            });
                          }}
                          maxLength={120}
                        />
                      </FormGroup>
                      
                    </Row>
                    <Row>
                      <FormGroup check>
                        <Label check>
                          <Input type={preguntas.idTipoPregunta.value === 1 ? "radio" : preguntas.idTipoPregunta.value === 2 ? "checkbox": "text"}  />
                          <Input
                            type="text"
                            id="Respuesta"
                            value={preguntas.Respuesta}
                            onChange={(event) => {
                              setPreguntas({
                                ...preguntas,
                                Respuesta: event.target.value,
                              });
                            }}
                            maxLength={120}
                          />
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <Input addon type="checkbox" aria-label="Checkbox for following text input" />
                        </InputGroupText>
                        <Button onPress={btnAddRespuesta} color="link">agregar Otra</Button>
                      </InputGroupAddon>
                      
                    </InputGroup>
                    </Row>
                   
                    <Row>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Input addon type="checkbox" aria-label="Checkbox for following text input" />
                          </InputGroupText>
                        </InputGroupAddon>
                      
                        <Button color="link">X</Button>
                      </InputGroup>
                    </Row>
                    
                  </Form>
                </Col>
              </CardBody>
              <CardFooter>
                
              </CardFooter>
            </Card>
            {
                        preguntasCuest.map((item, index) => {

                          return <Card className="col-md-12">
                            <Row>
                              <FormGroup className="col-md-4">
                                <Label for="TipoPregunta">
                                  <span className="text-danger">*</span>Tipo Pregunta
                                </Label>
                                <Select
                                  className="react-select primary"
                                  options={catTipoPregunta.data}
                                  value={{value: preguntasCuest[index].idTipoPregunta, label: preguntasCuest[index].TipoPreguntas}}
                                  id="TipoPregunta"
                                  onChange={(event) => {setTipoPreguntasSave(index, event)}}
                                  placeholder="seleccione Tipo Pregunta"
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                      <FormGroup className={"col-md-12"}>
                        <Label for="Nombre">
                          <span className="text-danger">*</span>Pregunta 
                        </Label>
                        <Input
                          type="text"
                          id="Pregunta"
                          value={preguntasCuest[index].Pregunta}
                          onChange={(event) => {
                            setPreguntasSave(index, event.target.value);
                          }}
                          maxLength={120}
                        />
                      </FormGroup>
                      
                    </Row>
                    {item.Respuestas.map((resp, indexR) => {
                        {
                          return (<Row><InputGroup className={"col-md-4"}>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <Input addon type="checkbox" aria-label="Checkbox for following text input" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input 
                                type="text" 
                                value={preguntasCuest[index].Respuestas[indexR].Respuesta}
                                onChange={(event) => {
                                  setRespuestaData(index, indexR, event.target.value)
                                }}
                                />
                                
                              <Button color="link">X</Button>
                            </InputGroup>
                            </Row>)
                        }
                      })}
                          </Card>
                        })
                      }
          </Row>
          
        </div>
       
        <Footer fluid />
      </div>
    </div>
  );
};

export default CuestionariosEdit;