import React, {  useState, useEffect } from "react";
import { getVars } from '../../GlobalStorage';
import {
  Card,
  CardBody,
  CardTitle,
  Badge,
  Button,
  Row,
  Col,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "../../data/getPOST";
import { CircleSpinner } from "react-spinners-kit";

import SexoChart from '../Charts/SexoChart';
import RangoEdadChart from '../Charts/RangoEdadChart';
import CompromisoSegmentoChart from "../Charts/CompromisoSegmentoChart/indexCompromisos";

const NumeraliaSegmento = (props) => {

 // Configuracion

 const [loading, setLoading] = useState(false);
 const token = getVars('Token');
 var ubicaciónResponsabilidad = (token.user.EstructuraSocial.value === 1)?0:token.user.EstructuraSocial.value;
 const [segmentosSelected, setSegmentosSelected] =useState(ubicaciónResponsabilidad);
 var NombreEstructura = token.user.EstructuraSocial.label;

const [segmentos, setSegmentos] =useState([ubicaciónResponsabilidad]);

 const [catEstructuras, setcatEstructuras] = useState([{value: 0,label: 'Todos'}])



 // Datos de los charts
 //const [titleCardChart, setTitleCardChart] = useState('Region')
 const [dataChartAgeRange, setDataChartAgeRange] = useState([])
 const [dataChartGender, setChartGender] = useState({
   labels: ['Hombres', 'Mujeres'],
   data: [0,0],
   percentage: [0,0]
 })

 const [dataChartCompromisos, setChartCompromisos] = useState({
    data: [0,0],
    categories:["Enero","Febrero"]
  })

 const [listaNominal, setListaNominal] = useState([])
 

 const [structure, setStructure] = useState({title: 'Sin especificar', value: "ND"})
 //"Numeralia"
  useEffect(() => {
    setLoading(true);
  
        const data={
          "idEstructura":ubicaciónResponsabilidad,
          }
          let estructurasFormated =[];
          EjecutarApi(data, `getSubestructuraSocialWeb`, token.access_token, "POST")
            .then(res => {
              if (res.data) {
                estructurasFormated = res.data.map(item => {
                  var dato=0
                  var Color="#00000"
                  var icon ="fa fa"
                  var flag = 0
                  var paredId=item.parentId.value
                  res.data.forEach(element => {
                   
                    if(element.parentId.value === item.id){
                     dato++
                    }
                    else{}
                  })
                  if(dato !== 0){
                    Color="#36BADA"
                    icon ="fa fa-plus"
                    flag =1
                  }
                  else{ Color="#13728E"
                  icon =""
                  flag= 0
                }
                if(item.id === 2 || item.id === 3 || item.id === 4){paredId = ubicaciónResponsabilidad}
                    return {
                      value: item.id,
                      label: item.nickName,
                      paredId: paredId,
                      color: Color,
                      icon : icon,
                      activo: flag
                    }
                  })
                setcatEstructuras(estructurasFormated)
                if(token.user.EstructuraSocial.value !== 1){
                var nombre= res.data.filter(item => item.id === token.user.EstructuraSocial.value)
                NombreEstructura=nombre[0].NombreSocial
              }
                setLoading(false)
              } else {
                showMessage("No Info: catCompromisosWeb")
                setLoading(false)
              }
            })
            .catch((e) => {
              showMessage("Error: getSubestructuraSocialWeb",e.error)
              setLoading(false)
            })

            EjecutarApi({}, "getNumeraliaSegmentos",token.access_token, "GET")
            .then(res => {
              if (res.success) {
                setLoading(false)
                formatDataChart(res, NombreEstructura)
                
              } 
            })
            .catch((e) => {
              setLoading(false)
              showMessage("Error: getNumeraliaSegmentos",e.error)
            })


  }, []);

  const formatDataChart = (numeralia, structure) => {

  let totalCompromisos = numeralia.Total;
  let CompromisosEdad18 = 0;
  let CompromisosEdad19 = 0;

  let CompromisosEdad2024 = 0;
  let CompromisosEdad2529 =0;
  let CompromisosEdad3034 =0;
  let CompromisosEdad3539  = 0;

  let CompromisosEdad4044  =0;
  let CompromisosEdad4549  =0;
  let CompromisosEdad5054  =0;
  let CompromisosEdad5559  =0;

  let CompromisosEdad6064  = 0;
  let CompromisosEdad65mas  =0;

  numeralia.data.forEach(element => {
    if (element.RangoEdad === "18"){
        CompromisosEdad18 = element.TotalRango;
    }
    if (element.RangoEdad === "19"){
       CompromisosEdad19 = element.TotalRango;
   }
   if (element.RangoEdad === "20-24"){
       CompromisosEdad2024 = element.TotalRango;
    }
    if (element.RangoEdad === "25-29"){
       CompromisosEdad2529 = element.TotalRango;
    }
    if (element.RangoEdad === "30-34"){
       CompromisosEdad3034 = element.TotalRango;
    }
    if (element.RangoEdad === "35-39"){
       CompromisosEdad3539 = element.TotalRango;
    }
    if (element.RangoEdad === "40-44"){
       CompromisosEdad4044 = element.TotalRango;
    }
    if (element.RangoEdad === "45-49"){
       CompromisosEdad4549 = element.TotalRango;
    }
    if (element.RangoEdad === "50-54"){
       CompromisosEdad5054 = element.TotalRango;
    }
    if (element.RangoEdad === "55-59"){
       CompromisosEdad5559 = element.TotalRango;
    }
    if (element.RangoEdad === "60-64"){
       CompromisosEdad6064 = element.TotalRango;
    }
    if (element.RangoEdad === "65-mas"){
       CompromisosEdad65mas = element.TotalRango;
    }
  });

  
  let CompromisosHombres  = (numeralia.Hombres !==0)?numeralia.Hombres:0;
  let CompromisosMujeres  = (numeralia.Mujeres)?numeralia.Mujeres:0;
  
    let dataTotal = [];
    let categoria =[]
  numeralia.dataC.forEach(element => {
      dataTotal.push(element.TotalUnicos)  
      categoria.push(element.Dia)
  });
  
  setChartCompromisos(
    {
        data:dataTotal,
        categories:categoria
    }

  )

  setListaNominal(totalCompromisos)


  setDataChartAgeRange([
   CompromisosEdad18,
   CompromisosEdad19,
   CompromisosEdad2024,
   CompromisosEdad2529,
   CompromisosEdad3034,
   CompromisosEdad3539,
   CompromisosEdad4044,
   CompromisosEdad4549,
   CompromisosEdad5054,
   CompromisosEdad5559,
   CompromisosEdad6064,
   CompromisosEdad65mas
  ].reverse())



  const chartGender = dataChartGender

  chartGender.data = [
    CompromisosHombres,
    CompromisosMujeres
  ]

  chartGender.percentage = [0, 0]

  if (totalCompromisos > 0) {
    let percentageMan = (CompromisosHombres * 100) / totalCompromisos
    let percentageWoman = (CompromisosMujeres * 100) / totalCompromisos
    chartGender.percentage = [Math.round(percentageMan), Math.round(percentageWoman)]
  }

  setChartGender(chartGender)



  // Agregamos los detalles de la numeralia
  let titlosNumeralia = 'Segmento'
  let titleCard = 'Segmento'

  setStructure({
    title: titlosNumeralia,
    value: NombreEstructura,
    titleCardChart: titleCard
  })
}




// FUNCIONES QUE DAN FORMATOD A LOS DATOS PARA LOS DIAGRAMAS

  const showChartGender = dataChartGender.data[0] >= 0 && dataChartGender.data[1] >= 0

  const formatDataChartGender = () =>   {
    let data = [
        {
            name: dataChartGender.labels[0],
            color: '#36A2EB',
            y: dataChartGender.data[0],
            dataLabels: {
                enabled: false
            }
        },
        {
            name: dataChartGender.labels[1],
            color: '#FF6384',
            y: dataChartGender.data[1],
            dataLabels: {
                enabled: false
            }
        }
    ]

    return data
  }

  const formatDataAgeRange = () => {
    return dataChartAgeRange
  }

   const formatDataCompromisos= () => {
    return dataChartCompromisos
  }


  const showMessage = (message) => {
    notify.show(message, "custom", 5000, {
      background: "#0857B6",
      fontSize: 14,
      text: "#FFFFFF",
    });
  }

  const segmento = (activo,e,nombre) =>{
    NombreEstructura=nombre
    if(activo === 1){
      setSegmentosSelected(e)
    setSegmentos(
      [
        ...segmentos,
        e
      ]
    )
    const data={
      'estructura':[e]
    }
    EjecutarApi(data, "getNumeraliaSegmentosbyEstructura",token.access_token, "POST")
    .then(res => {
      if (res.success) {
        setLoading(false)
        formatDataChart(res, NombreEstructura)
        
      } 
    })
    .catch((e) => {
      setLoading(false)
      showMessage("Error: getNumeraliaSegmentos",e.error)
    })
  }
  else{
    const data={
      'estructura':[e]
    }
    EjecutarApi(data, "getNumeraliaSegmentosbyEstructura",token.access_token, "POST")
    .then(res => {
      if (res.success) {
        setLoading(false)
        formatDataChart(res, NombreEstructura)
        
      } 
    })
    .catch((e) => {
      setLoading(false)
      showMessage("Error: getNumeraliaSegmentos",e.error)
    })
  }

  }

  const AtrasSegmento = () =>{
    let ultimo=0
    if(segmentos.length > 1 ){
    ultimo=segmentos.pop()
    
    setSegmentosSelected(segmentos[segmentos.length-1])
    const data={
      'estructura':[segmentos[segmentos.length-1]]
    }
    EjecutarApi(data, "getNumeraliaSegmentosbyEstructura",token.access_token, "POST")
    .then(res => {
      if (res.success) {
        setLoading(false)
        formatDataChart(res, NombreEstructura)
        
      } 
    })
    .catch((e) => {
      setLoading(false)
      showMessage("Error: getNumeraliaSegmentos",e.error)
    })
  }
  else{
    ultimo=segmentos[segmentos.length-1]
    const data={
      'estructura': [ultimo]
    }
    EjecutarApi(data, "getNumeraliaSegmentosbyEstructura",token.access_token, "POST")
    .then(res => {
      if (res.success) {
        setLoading(false)
        formatDataChart(res, NombreEstructura)
        
      } 
    })
    .catch((e) => {
      setLoading(false)
      showMessage("Error: getNumeraliaSegmentos",e.error)
    })
  }

  }

  return(
                  <Row>
                     <Notifications />
                    { !loading?
                  <>
                    <Col lg="3" md="3" sm="3">
                      <Card className="card-stats" style={{height: "440px"}}>
                        <CardBody>
                          <Row>
                            <Col md="12" xs="12">
                              <div className="numbers">
                                <p className="card-category">Compromisos/{structure.titleCardChart}</p>
                                {structure.value}<CardTitle tag="p">{new Intl.NumberFormat('en-EN').format(listaNominal)}</CardTitle>
                                  {/* {structure.title}<CardTitle tag="p">{structure.value}</CardTitle> */}
                                <p />
                              </div>
                            </Col>
                          <Col>
                          <Card>
                            <CardTitle>
                             <Button close onClick={()=>AtrasSegmento()} >
                              <i className="fa fa-reply-all" aria-hidden="true"></i>
                              </Button>
                              Estructuras
                              </CardTitle>
                              <div style={{ 'height': '240px', 'overflow': 'scroll', 'display': 'block' }}>
                              { 
                                catEstructuras.filter(data => data.paredId === segmentosSelected).map(item => (
                                  <Badge key={item.value} style={{color: item.color}} tag="button" onClick={()=>segmento(item.activo,item.value,item.label)} >{item.label} {"  "} <i className={item.icon}aria-hidden="true"></i></Badge>
                                ))}
                              </div>
                          </Card>
                          </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="9" md="9" sm="9">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="12" xs="12">
                            <div style={{ 'width': '100%', 'overflow': 'auto'}}>
                                <p className="card-category">Compromisos Por día {structure.titleCardChart}/{structure.value}</p>
                                  <CompromisoSegmentoChart data={formatDataCompromisos()}/>
                                <p />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="4" sm="4">
                      <Card className="card-stats" style={{height: "300px"}}>
                        <CardBody>
                        <div className="numbers">
                          <p className="card-category">Sexo</p>
                        </div>
                        <Row>
                          <Col md="6" xs="6" style={{textAlign: 'right'}}>
                            <span style={{color: "#FF6384"}}>% {dataChartGender.percentage[1]}</span>
                          </Col>
                          <Col md="6" xs="6">
                            <span style={{color: "#36A2EB"}}>% {dataChartGender.percentage[0]}</span>
                          </Col>
                        </Row>
                          <Row>
                            <Col md="12" xs="12">
                              { showChartGender &&
                                <SexoChart data={formatDataChartGender()} />
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" xs="6" style={{textAlign: 'right'}}>
                              <span style={{color: "#FF6384"}}>Mujeres {new Intl.NumberFormat('en-EN').format(dataChartGender.data[1])}</span>
                            </Col>
                            <Col md="6" xs="6">
                              <p style={{color: "#36A2EB"}}>Hombres {new Intl.NumberFormat('en-EN').format(dataChartGender.data[0])}</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="8" md="8" sm="8">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="9" xs="9">
                              <div className="numbers">
                                <p className="card-category">Rango por edad</p>
                                  <RangoEdadChart data={formatDataAgeRange()} />
                                <p />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </>:<CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={loading}
                      />
                  }
                  </Row>
    );
};

export default NumeraliaSegmento;


