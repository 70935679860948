import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col, Button
} from "reactstrap";

import GraficaSocial from '../Charts/SocialChart';

const AvanceSocial = ({ Data, onHide }) => {

  useEffect(() => {
    handleMiniClick()
  }, []); 
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  return (
    <>
      <Row>
        <Col md="12" >
          <Button size="sm" color='primary' onClick={() => onHide()}>Regresar</Button>
        </Col>
      </Row>
      <Row className='text-center'>
        <Col sm='12' className='text-center'>
          <Card className='text-center' >
            <CardTitle className='text-center'>
              <h6 className='text-center'>Árbol de Redes Sociales</h6>
            </CardTitle>
            <CardBody>
              <GraficaSocial props={Data} />
            </CardBody>
            <CardFooter>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default AvanceSocial;
