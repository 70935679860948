// import { FormGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Label,
    Input,
    Button,
    InputGroup, InputGroupAddon, CardBody, CardFooter, Card, CardHeader, FormGroup
} from 'reactstrap';
import { notify } from "react-notify-toast";
import EjecutarApi from "../../data/getPOST";
import { getVars } from "../../GlobalStorage";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import useWindowDimensions from "components/useWindowDimensions";

const DiaDApertura = ({ menu, btnGuardar, Titulo }) => {
    const { height, width } = useWindowDimensions();
    const token = getVars("Token");
    const [loading, setLoading] = useState(false);
    const [catCasilla, setcatCasilla] = useState([{ value: 0, label: 'Debe validar la sección' }]);
    const [aperturaCasilla, setAperturaCasilla] = useState({
        seccion: '',
        casilla: '',
        municipio: { value: 0, label: 'Seleccionar...' },
        numeroFuncionario: '',
        tomadosXfila: '',
        foto: '',
        idRG: 0,
        hora: '',
        minutos: '',
        anio: '',
        mes: '',
        dia: '',
        segundos: '',
        reporto: '',
    });
    const [municipio, setMunicipio] = useState('');
    const [catRC, setCatRC] = useState([{ value: 0, label: "Otro" }])

    useEffect(() => {
        var d = new Date();

        var horas = d.getHours();
        var minutos = d.getMinutes();
        var segundos = d.getSeconds();

        if(horas < 10) { horas = '0' + horas; }
        if(minutos < 10) { minutos = '0' + minutos; }
        if(segundos < 10) { segundos = '0' + segundos; }
        
        setAperturaCasilla({
            ...aperturaCasilla,
            hora: `${horas}:${minutos}`,
            minutos: d.getMinutes(),
            anio: d.getFullYear(),
            mes: d.getMonth()+1,
            dia: d.getDate(),
            segundos: d.getSeconds()
        });
    }, [])

    const onblurSeccion = (Seccion) => {
        setLoading(true);
      if(Seccion){ EjecutarApi({ "seccion": Seccion }, 'validarSeccionSije', token.access_token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setLoading(false);
                    setMunicipio(res.data.map((item) => {
                        return item.MunicipioReporte;
                    }))
                    EjecutarApi({ "seccion": Seccion }, 'getCasillasBySeccion', token.access_token)
                        .then(function (res) {

                            return res;
                        })
                        .then((res) => {
                            if (res.results) {
                                setcatCasilla(res.data);

                            }

                        })
                        .catch((e) => {
                            setLoading(false);
                            notify.show(`${e.error}`, 'error', 5000);
                        });

                }
                else {
                    notify.show(res.message, 'error', 5000);

                    setLoading(false);
                }

            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en: ${e.error}`, 'error', 5000);
            });
        }
        else{
            notify.show(`Hace falta la sección`, 'warning', 5000);
        }
        setLoading(false);
    }

    const conChangeCasilla = (e) => {
        //getRepresentantesCasillas
        setLoading(true);
        setAperturaCasilla({ ...aperturaCasilla,idRG: {value: 0, label:"Seleccione"}, casilla: { value: e.value, label: e.label } })
        var parametro = {
            "Seccion": parseInt(aperturaCasilla.seccion),
            "NombreCasilla": e.label,
        };
        EjecutarApi(parametro, 'getRepresentantesCasillas', token.access_token)
            .then(function (res) {

                return res;
            })
            .then((res) => {
                if (res.results) {
                    let otro= { value: 0, label: "Otro" };
                    var dato = res.data.rcs;
                    dato.push(otro);
                    setCatRC(dato);
                }

            })
            .catch((e) => {
                setLoading(false);
                notify.show(`Error en: ${e.error}`, 'error', 5000);
            });
        setLoading(false);
    }
    const btnCancelar = () => {
        var d = new Date();
        var horas = d.getHours();
        var minutos = d.getMinutes();
        var segundos = d.getSeconds();

        if(horas < 10) { horas = '0' + horas; }
        if(minutos < 10) { minutos = '0' + minutos; }
        if(segundos < 10) { segundos = '0' + segundos; }
        setAperturaCasilla({
            seccion: '',
            casilla: '',
            municipio: { value: 0, label: 'Seleccionar...' },
            numeroFuncionario: '',
            tomadosXfila: '',
            foto: '',
            idRG: 0,
            reporto: '',
            hora: `${horas}:${minutos}`,
            minutos: d.getMinutes(),
            anio: d.getFullYear(),
            mes: d.getMonth(),
            dia: d.getDate(),
            segundos: d.getSeconds(),
            reporto: '',
        });
        setcatCasilla([{ value: 0, label: 'Debe validar la sección' }]);
        setMunicipio('');
        setCatRC([{ value: 0, label: "Seleccione" }]);
    }


    return (
        <>
            <Card style={{ height: height - 118 }}>
                <CardHeader>
                    <h6 className="text-center">{Titulo}</h6>
                </CardHeader>
                <CardBody style={{ 'width': '100%', 'overflow': 'auto' }}>
                    <Col sm="12" md="12" lg="12">
                            <Row>
                                <FormGroup className="col-md-12">
                                    {loading && (
                                        <CircleSpinner
                                            className="text-center"
                                            size={30}
                                            color="#686769"
                                            loading={loading}
                                    />
                                    )}  
                                    <Label for="seccion"><span className="text-danger">*</span>Sección:</Label>
                                    <Input id="seccion"
                                        maxLength='4' value={aperturaCasilla.seccion}
                                        type="text"
                                        onChange={(e) => { setAperturaCasilla({ ...aperturaCasilla, seccion: e.target.value.replace(/\D/, "") }) }}
                                        onBlur={(e) => onblurSeccion(e.target.value.replace(/\D/, ""))} />
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="col-md-12" >
                                    <Label for="Mpioseccion">Municipio:</Label>
                                    {loading && (
                                        <CircleSpinner
                                            className="text-center"
                                            size={30}
                                            color="#686769"
                                            loading={loading}
                                        />
                                    )}
                                    <Input id='Mpioseccion' value={municipio} disabled></Input>
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12" >
                                    <Label for="casilla"><span className="text-danger">*</span>Casilla:</Label>
                                    {loading && (
                                        <CircleSpinner
                                            className="text-center"
                                            size={30}
                                            color="#686769"
                                            loading={loading}
                                        />
                                    )}
                                    <Select className="react-select primary"
                                        classNamePrefix="react-select"
                                        id="casilla"
                                        placeholder="Debe validar la sección"
                                        options={catCasilla}
                                        value={aperturaCasilla.casilla}
                                        onChange={(e) => conChangeCasilla(e)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12" >
                                    <Label for="casilla"><span className="text-danger">*</span>RC:</Label>
                                    {loading && (
                                        <CircleSpinner
                                            className="text-center"
                                            size={30}
                                            color="#686769"
                                            loading={loading}
                                        />
                                    )}
                                    <Select className="react-select primary"
                                        classNamePrefix="react-select"
                                        id="casilla"
                                        placeholder="Debe validar la sección"
                                        options={catRC}
                                        value={aperturaCasilla.idRG}
                                        onChange={(e) => setAperturaCasilla({ ...aperturaCasilla, idRG: { value: e.value, label: e.label } })}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <FormGroup className="col-md-12">
                                    <Label for="seccion"><span className="text-danger">*</span>Número de funcionario de casilla presente:</Label>
                                    <Input id="seccion" maxLength='4' value={aperturaCasilla.numeroFuncionario}
                                        onChange={(e) => setAperturaCasilla({ ...aperturaCasilla, numeroFuncionario: e.target.value.replace(/\D/, "") })} ></Input>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="col-md-12">
                                    <Label for="seccion"><span className="text-danger">*</span>¿Tomandos de la fila?:</Label>
                                    <Input id="seccion" maxLength='4' value={aperturaCasilla.tomadosXfila}
                                        onChange={(e) => setAperturaCasilla({ ...aperturaCasilla, tomadosXfila: e.target.value.replace(/\D/, "") })} ></Input>
                                </FormGroup>
                            </Row>
                            { (aperturaCasilla.idRG.value === 0 && aperturaCasilla.idRG.label === "Otro")&&
                            <Row>
                                <FormGroup className="col-md-12">
                                    <Label for="rpt"><span className="text-danger">*</span>Reportó:</Label>
                                    <Input id="rpt"
                                        value={aperturaCasilla.reporto}
                                        onChange={(e) => { setAperturaCasilla({ ...aperturaCasilla, reporto: e.target.value }) }}

                                    ></Input>
                                </FormGroup>
                            </Row>
                            }
                            <Col sm="6" md="6" lg="12">
                                <Label className="text-muted"> <span className="text-danger text-center" >*</span>Hora de Apertura (Formato: 24 hrs, ejemplo: 13:30)</Label>
                                <Input id='btn' className='text-center' value={aperturaCasilla.hora} style={{ width: "100pt" }}
                                    onChange={(e) => setAperturaCasilla({ ...aperturaCasilla, hora: e.target.value })}
                                    type="time" />
                            </Col>
                            <Row>
                            <FormGroup className="col-md-12 text-center" >
                                <Button size='sm' color='primary'
                                    className='primary' 
                                    onClick={(e) => btnGuardar(aperturaCasilla)} 
                                    >Guardar</Button>{" "}
                                <Button size='sm' color='danger'
                                    className='danger'
                                    onClick={btnCancelar} >Limpiar</Button>
                            </FormGroup>
                        </Row>
                    </Col>
                </CardBody>
                <CardFooter>

                </CardFooter>
            </Card>
        </>
    );
}
export default DiaDApertura;