import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  CardHeader
} from "reactstrap";
import Select from "react-select";
import Notifications, { notify } from "react-notify-toast";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import Navbar from "components/Navbar";

import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import Pagination from "components/Pagination/pagination";
//import Filter from "../components/Filtro/compromisoFiltro";

import { URL_GENERAL } from "data/Consts";
import EjecutarApi from "data/getPOST";
import { getVars, URL_PUBLICA } from "GlobalStorage";
import { getDataMenu } from "data/menus";


const SocialRCO = (props) => {

  const [estatalDetalle, setEstatalDetalle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [total, setTotal] = useState(0);
  const [Filtro, setFiltro] = useState([]);
  const [page,setPage]= useState(0);
  const [pageSize,setPageSize]= useState(10);
  const [catMunicipio, setCatMunicipio] = useState([{ value: 0, label: 'Todos' }]);
  const [catResponsable, setCatResponsable] = useState([{ value: 0, label: 'Todos' }]);
  const [catSegmento, setCatSegmento] = useState([{ value: 0, label: 'Todos' }]);
  const [catSegmentoFilter, setCatSegmentoFilter] = useState([{ value: 0, label: 'Todos' }]);
  const [catMunicipioFilter, setCatMunicipioFilter] = useState([]);
  const [municipioSelected, setMunicipioSelected] = useState([{value: 0, label: 'Todos'}]);
  const [ResponsableSelected, setResponsableSelected] = useState([{ value: 0, label: 'Todos' }]);
  const [catResponsableFiltered, setCatResponsableFiltered] = useState([{ value: 0, label: 'Todos' }]);
  const [segmentoSelected, setCatSegmentoSelected] = useState([{ value: 0, label: 'Todos' }]);
  const [exportFilter, setExportFilter] = useState({
    UserOwned: null,
    idEstructura: null,
    idMunicipio: null
  })
  
  const token = getVars("Token");
/*   const responsibilityID = token.user.Responsabilidad.value; */
    
  useEffect(() => {
     _setmenu(getDataMenu(187, token.menus));
     console.log("useEfect")
     Catalogos()
  }, []); 


  const Catalogos = () =>{
    setLoading(true)
    var estructuraFormated = [];
    var municipioFormated = [];
    var responsableFormated = [];


    EjecutarApi({}, "getCatalogoRCOSocial", token.access_token)
      /* .then(function (res) {
        return res.json();
      }) */
      .then((res) => {
        if (res.results) {
          let municipios=[{ value: 0, label: 'Todos' }];
          municipioFormated = res.data.municipios.map(item => {
            return {
              value: item.value,
              label: item.label
            }
          })
       
          setCatMunicipio(municipios.concat(municipioFormated))
          setCatMunicipioFilter(municipios.concat(municipioFormated))

          let estructura=[{ value: 0, label: 'Todos' }];
          estructuraFormated = res.data.estructuras.map(item => {
            return {
              value: item.value,
              label: item.label,
              idMunicipio: item.municipios,
            }
          })
          setCatSegmento(estructura.concat(estructuraFormated))
          setCatSegmentoFilter(estructura.concat(estructuraFormated))


          let responsable=[{ value: 0, label: 'Todos' }]      ;
          responsableFormated = res.data.responsables.map(item => {
            return {
              value: item.value,
              label: item.label,
              idMunicipio: item.municipios,
              idEstructura: item.estructuras,
            }
          })
          setCatResponsable(responsable.concat(responsableFormated))
          setCatResponsableFiltered(responsable.concat(responsableFormated))
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error al obtener catálogos: ${e.error}`, "error", 5000);
        
      });

  }

  const showMessage = (message, background) => {
    notify.show(message, "custom", 5000, {
      background: background,
      fontSize: 14,
      text: "#FFFFFF",
    });
  }



  const handleResponsables = (e) => {
    setResponsableSelected(e)

    if (e.value > 1) {
  /*     let municipios = [{ value: 0, label: 'Todos' }]
      setCatSegmentoFilter(municipios.concat(catSegmento.filter(data => data.idMunicipio.includes(e.value))));
      setCatResponsableFiltered(municipios.concat(catResponsable.filter(data => data.idMunicipio.includes(e.value))));
      // */
      setFiltro([{
        "id": "users.id",
        "value": e.value
    }])
      table(0,10,[{
        "id": "users.id",
        "value": e.value
    }]);

    } else {
      setFiltro([])
      table(0,10,[]);
    }
  }

  const handleSegmento = (e) => {
    setCatSegmentoSelected(e)
    setResponsableSelected([{ value: 0, label: 'Todos' }])
    
    if (e.value > 1) {
      /*     let municipios = [{ value: 0, label: 'Todos' }]
          setCatSegmentoFilter(municipios.concat(catSegmento.filter(data => data.idMunicipio.includes(e.value))));
          setCatResponsableFiltered(municipios.concat(catResponsable.filter(data => data.idMunicipio.includes(e.value))));
          // */
          setFiltro([{
            "id": "compromisos_segmentos.idEstructura",
            "value": e.value
        }])
          table(0,10,[{
            "id": "compromisos_segmentos.idEstructura",
            "value": e.value
        }]);
    
        } else {
         
          setFiltro([])
          table(0,10,[]);
        }
  }
  const handleMunicipio = (e) => {
    setMunicipioSelected(e)
    setCatSegmentoSelected([{ value: 0, label: 'Todos' }])
    setResponsableSelected([{ value: 0, label: 'Todos' }])
    
    if (e.value > 1) {
      /*     let municipios = [{ value: 0, label: 'Todos' }]
          setCatSegmentoFilter(municipios.concat(catSegmento.filter(data => data.idMunicipio.includes(e.value))));
          setCatResponsableFiltered(municipios.concat(catResponsable.filter(data => data.idMunicipio.includes(e.value))));
          // */
          setFiltro([{
            "id": "secciones.idMunicipioReportes",
            "value": e.value
        }])
          table(0,10,[{
            "id": "secciones.idMunicipioReportes",
            "value": e.value
        }]);
    
        } else {
          setFiltro([])
          table(0,10,[]);
        }
  }

  const Exportar = (UserOwned) => {

    var url = new URL(
      `${URL_GENERAL}exportCompromisosRCOSocial?token=${token.access_token}&UserOwned=${UserOwned}`
    );
    window.open(url, "_blank");
  }

  const styleHeader = {
    textAlign: "center",
    overflow: "visible",
    fontSize: "11px",
    color: "#363636",
    lineHeight: "normal !important"
  }

  const header = (text) => {
    return (<Label style={styleHeader}>{text}</Label>)
  }

  const table = (pages,pageSizes,Filtros) =>{
    setLoadingTable(true);
    const data = {
      page: pages,
      pageSize: pageSizes,
      tipo: 'and',
      filtered: Filtros
    };
    _setmenu(_menu);
    EjecutarApi(data, "getRCOSocial", token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            setLoadingTable(false);
            setTotal(res.total);
            setEstatalDetalle(
              res.data.map((prop, key) => {
                return {
                  Municipio: prop.Municipio,
                  NombreSocial: prop.NombreSocial,
                  Responsable: prop.Responsable,
                  CompromisosUnicos:((_menu.Exportar === 1)?(<Button className=" btn-round" size="sm" color="success" onClick={() =>Exportar(prop.UserOwned)} >
                  <strong>{prop.Cantidad}{" "}<i class="fa fa-file-excel-o"  style={{color:"white", fontSize:'150%'}} aria-hidden="true"></i></strong></Button>):
                  <>{prop.Cantidad}</>),
                  data: prop
                };
              })
            );
          }
        }

      })
      .catch((e) => {
        notify.show(
          `Error al obtener tabla metas: ${e.message}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
  }


  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} />
        <Notifications />
        <div className="content">
        <Card>
            <CardHeader>
        <Row>
            {!loading ?
            <>
            <Col>
            <FormGroup>
                Municipios
                <Select
                options={catMunicipioFilter}
                className="react-select primary"
                classNamePrefix="react-select"
                value={municipioSelected}
                onChange={(e) => handleMunicipio(e)}
                id="municipio"
                placeholder="Municipio"
                />
            </FormGroup>
            </Col>
            <Col>
            <FormGroup>
                Estructura
                <Select
                options={catSegmentoFilter}
                className="react-select primary"
                classNamePrefix="react-select"
                value={segmentoSelected}
                onChange={(e) => handleSegmento(e)}
                id="municipio"
                placeholder="Municipio"
                />
            </FormGroup>
            </Col>
            <Col>
            <FormGroup>
                Responsable
                <Select
                options={catResponsableFiltered}
                className="react-select primary"
                classNamePrefix="react-select"
                value={ResponsableSelected}
                onChange={(e) => handleResponsables(e)}
                id="municipio"
                placeholder="Municipio"
                />
            </FormGroup>
            </Col>
            </>
       :<CircleSpinner
       className="text-center"
       size={30}
       color="#686769"
       loading={loading}
     />
        }
       </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Card>
                <Col lg="12" md="12">
                  <div style={{ 'width': '100%', 'overflow': 'auto' }}>
                    <ReactTable
                      style={{
                        width: "100%",
                        height: "100%"
                      }}
                      data={estatalDetalle}
                      noDataText="Sin información"
                      manual
                      columns={[
                        {
                          Header: header("Municipio"),
                          minWidth: 50,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                        },
                        {
                          Header: header("Segmento"),
                          minWidth: 40,
                          id: "NombreSocial",
                          accessor: (d) => d.NombreSocial,
                          filterAll: false,
                        },
                        {
                          Header: header("Responsable"),
                          minWidth: 40,
                          id: "Responsable",
                          accessor: (d) => d.Responsable,
                          filterAll: false,
                        },
                        {
                          Header: header("Compromisos"),
                          minWidth: 40,
                          id: "CompromisosUnicos",
                          accessor: (d) => d.CompromisosUnicos,
                          filterAll: false,
                        }
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loadingTable}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={total}
                      pages={Math.floor(total / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      PaginationComponent={Pagination}
                      onFetchData={(stateComp, instance) => {
                        setPage(stateComp.page);
                        setPageSize(stateComp.pageSize);
                        setLoadingTable(true);
                        const data = {
                          page: stateComp.page,
                          pageSize: stateComp.pageSize,
                          sorted: [],
                          tipo: 'and',
                          filtered: Filtro ? Filtro : []
                        };
                        let _menu=getDataMenu(187, token.menus);
                        _setmenu(_menu);
                        EjecutarApi(data, "getRCOSocial", token.access_token)
                          .then(function (res) {
                            return res;
                          })
                          .then((res) => {
                            if (res.success) {
                              if (res.results) {
                                setLoadingTable(false);
                                setTotal(res.total);
                                setEstatalDetalle(
                                  res.data.map((prop, key) => {
                                    return {
                                      Municipio: prop.Municipio,
                                      NombreSocial: prop.NombreSocial,
                                      Responsable: prop.Responsable,
                                      CompromisosUnicos:((_menu.Exportar === 1)?(<Button className=" btn-round" size="sm" color="success" onClick={() =>Exportar(prop.UserOwned)} >
                                      <strong>{prop.Cantidad}{" "}<i class="fa fa-file-excel-o"  style={{color:"white", fontSize:'150%'}} aria-hidden="true"></i></strong></Button>):
                                      <>{prop.Cantidad}</>),
                                      data: prop
                                    };
                                  })
                                );
                              }
                            }

                          })
                          .catch((e) => {
                            notify.show(
                              `Error al obtener tabla metas: ${e.message}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );
                          });
                      }}

                    />
                  </div>
                </Col>
              </Card>
            </Col>
          </Row>
        </CardBody>
          </Card>
        </div>
        <Footer fluid />
      </div>
    </div >
  );
};

export default SocialRCO;
