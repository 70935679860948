import React, { useState, useCallback } from "react";
import { Row, Col, Button, Form, Card, CardHeader, CardBody, CardFooter, FormGroup, Label, Tooltip } from "reactstrap";


import { getVars } from "GlobalStorage";
import useWindowDimensions from "components/useWindowDimensions";

const CandidatosDetalle = props => {
    const token = getVars("Token").access_token;
    const { height, width } = useWindowDimensions();
    const [loading, setLoading] = useState(true);

    return (
        <Card style={{ height: height - 118 }}>
            <CardHeader>
            </CardHeader>
            <CardBody style={{ overflowY: "auto", overflowX: "hidden", position: "relative" }}>
                <Row>
                    <Col md="4">
                        <Card className="card-user">
                            <div className="image">
                                <img
                                    alt="..."
                                    width="100%"
                                // src={require("assets/iconoimpulso.png")}
                                />
                            </div>
                            <CardBody></CardBody>
                        </Card>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
};

export default CandidatosDetalle;