import { required, minLength, maxLength, minValue, sizeLength, requiredSelect } from "data/validations";

const RCFormCasilla = {
    ocr: '',
    vigenciaClave: '',
    haSidoFuncionario: 0,
    tieneExperiencia: 0,
    tieneAuto: 0,
    otraCasilla: 0,
}

const RCParamsFormCasilla = (params) => {
    let data = {
        ocr: params.ocr,
        vigenciaClave: params.vigenciaClave,
        haSidoFuncionario: params.haSidoFuncionario,
        tieneExperiencia: params.tieneExperiencia,
        tieneAuto: params.tieneAuto,
        otraCasilla: params.otraCasilla
    }
    return data;
}

const RCFormCasillaErrors = {
    ocr: '',
    vigenciaClave: ''
}

const RCRulesCasilla = {
    ocr: { valid: false, rule: [minLength, required], params: { minValue: 2 } },
    vigenciaClave: { valid: false, rule: [required], params: {} }
}


export { RCFormCasilla, RCParamsFormCasilla, RCFormCasillaErrors, RCRulesCasilla }