import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Card,
  CardTitle,
  CardFooter,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Table,
} from 'reactstrap';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Notifications, { notify } from 'react-notify-toast';
import Select from 'react-select';

import { RotateSpinner, MetroSpinner } from 'react-spinners-kit';
import { getDataMenu } from 'data/menus';
import EjecutarApi from 'data/getPOST';
import { getVars, URL_PUBLICA } from 'GlobalStorage';
import useWindowDimensions from 'components/useWindowDimensions';
import { CircleSpinner } from "react-spinners-kit";
import ListSeccionesComponent from 'components/MovilizacionPromocion/listSeccion';
import { element } from 'prop-types';

let _Movilizado = new Array();
let checkedArray = new Array();

function PaseListaMovilizacion(props) {

  const mainPanel = useRef();
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [filtro, setFiltro] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const token = getVars('Token');
  const [_menu, _setmenu] = useState({});
  const [selectedMovilizado, setSelectedMovilizado] = useState(0);
  const [Movilizados, setMovilizados] = useState([]);
  const [MovilizadosFiltered, setMovilizadosFiltered] = useState([]);

  const [isMovilizados, setIsMovilizados] = useState(false);
  const [municipioFiltered, setMunicipioFiltered] = useState([]);
  const [seccionesFiltered, setSeccionesFiltered] = useState([]);
  const [seccionesShowFiltered, setSeccionesShowFiltered] = useState([]);
  const [poligonoFiltered, setPoligonoFiltered] = useState([]);
  const [catalogos, setCatalogos] = useState({
    Region: [],
    Municipios: [],
    secciones: [],
    poligono: []
  });

  const [dataTiempo, setDataTiempo] = useState({ value: 1, label: "Pase de Lista", clave: 0 })
  const [tiempos, setTiempos] = useState([]);
  const [Datos, setDatos] = useState({
    Region: { label: "Seleccione", value: 0 },
    Municipios: { label: "Seleccione", value: 0 },
    Poligono: { label: "Seleccione", value: 0 },
    SeccionVota: { label: "Seleccione", value: 0 },
    NombreCompleto: ""
  })
  const [ListadoMovilizados, setListadoMovilizados] = useState({
    id: null,
    UserMovilizo: '',
    idMovilizado: '',
    NMovilizados: 0,
    _Movilizado: [],
  });
  var NMovilizados = 0;

  useEffect(() => {
    _setmenu(getDataMenu(182, token.menus));
    getCatalogos();
  }, [])

  useEffect(() => {

  }, [dataTiempo, MovilizadosFiltered])

  /*  useEffect(() => {
     if(Datos.Poligono !==0 && Datos.SeccionVota !==0) getMovilizado()
 
   }, [Movilizados]) */

  const getCatalogos = () => {
    setLoading(true);
    EjecutarApi(
      {}, 'getCatalogosFiltrosPromocionClonIdMunicipioReportes', token.access_token)
      .then((res) => {
        setLoading(false);
        if (res.success && res.results) {
          let region = [{ label: "Seleccione", value: 0 }];
          let municipio = [{ label: "Seleccione", value: 0 }];
          let _poligono = [{ label: "Seleccione", value: 0 }];
          let _seccion = [{ label: "Seleccione", value: 0 }];
          setCatalogos({
            ...catalogos,
            Region: region.concat(res.data.regiones),
            Municipios: municipio.concat(res.data.municipios),
            poligono: _poligono.concat(res.data.poligonos),
            secciones: _seccion.concat(res.data.secciones),
          });
          setMunicipioFiltered(municipio.concat(res.data.municipios));
          setSeccionesFiltered(_seccion.concat(res.data.secciones));
          setPoligonoFiltered(_poligono.concat(res.data.poligonos));
        } else {
          notify.show(res.message, 'custom', 5000, {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al cargar catalogos: ${e.message} Favor de renovar sesión`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const getMovilizado = (data) => {
    setMovilizados([])
    setMovilizadosFiltered([])
    setLoading2(true)
    EjecutarApi(data, 'movilizacion/getMovilizacionEstructura', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            if (res.data.length > 0) {
              setMovilizados(res.data)
              setMovilizadosFiltered(res.data)
              setLoading2(false)
              setIsMovilizados(true)
            }
          }
          else {
            setMovilizados(res.data)
            setLoading2(false)
            setMovilizadosFiltered(res.data)

            notify.show(` ${res.message}`, 'warning', 5000, {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            });
          }
        }
      })
      .catch((e) => {
        notify.show(`Error: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
        setLoading2(false)
      });


  }


  const ClearFilter = () => {
  };

  const btnSaveMovilizado = () => {
    setSelectedMovilizado(0)

    var d = new Date();

    var horas = d.getHours();
    var minutos = d.getMinutes();
    var segundos = d.getSeconds();
    var anio = d.getFullYear();
    var dia = d.getDate();
    var mes = d.getMonth() + 1
    if (horas < 10) { horas = '0' + horas; }
    if (minutos < 10) { minutos = '0' + minutos; }
    if (segundos < 10) { segundos = '0' + segundos; }
    if (mes < 10) { mes = '0' + mes; }
    if (dia < 10) { dia = '0' + dia; }

    let arrayMovilizado =
      _Movilizado.map(item => {
        return {
          idUser: item.idUser,
          FechaHora: `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`,
        }
      });
    DatosMovilizado(arrayMovilizado);
  }

  const DatosMovilizado = (Data) => {
    setLoading(true);
    EjecutarApi({ users: Data }, "movilizacion/setMovilizacionAsistencia", token.access_token)
      .then(function (res) {

        return res;
      })
      .then((res) => {
        if (res.success) {
          if (res.results) {
            notify.show(`Registros actualizados con éxito!!`, "success", 5000, {
              background: "#18c97d",
              fontSize: 24,
              text: "#FFFFFF",
            });
            setMovilizados([]);
            setMovilizadosFiltered([]);
            getMovilizado(filtro);
          } else {
            notify.show(
              `Error al agregar registro: ${res.message}!!`,
              "custom",
              5000,
              {
                background: "#0857B6",
                fontSize: 24,
                text: "#FFFFFF",
              }
            );
            return true;
          }
        }
      })
      .catch((e) => {
        notify.show(`Error al agregar el registro: ${e.error}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 24,
          text: "#FFFFFF",
        });
      })
      .finally(e => {
        setLoading(false);
      })
  };

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const onChangeCheck = (e, item, k) => {
    if (e.target.checked) {

      checkedArray[k] = true;
      _Movilizado.push(item);
      let nmovilizados = ListadoMovilizados.NMovilizados + _Movilizado.length;
      setSelectedMovilizado(nmovilizados);
      NMovilizados = ListadoMovilizados.nmovilizados + _Movilizado.length;

    } else {
      checkedArray[k] = false;
      let copy = _Movilizado.filter((x) => {
        return (x.id !== item.id)
      });
      let nmovilizados = ListadoMovilizados.NMovilizados + copy.length;
      setSelectedMovilizado(nmovilizados);
      NMovilizados = ListadoMovilizados.nmovilizados + copy.length;
      if (nmovilizados < 1) {
        setIsDisabled(true);
        notify.show(
          'No se ha seleccionado a ningun Movilizado',
          'error',
          5000
        );
      } else {
        setIsDisabled(false);
      }
      _Movilizado = copy;
    }
  };



  const onChangeRegion = (idReg) => {
    setIsMovilizados(false)
    if (idReg > 0) {
      let municipios = catalogos.Municipios.filter((item) => { return parseInt(item.idRegion) === parseInt(idReg) });
      let secciones = catalogos.secciones.filter((item) => { return parseInt(item.idRegion) === parseInt(idReg) });
      let poligono = catalogos.poligono.filter((item) => { return parseInt(item.idRegion) === parseInt(idReg) });
      let todos = [{ label: "Seleccione", value: 0 }]
      setMunicipioFiltered(todos.concat(municipios));
      setPoligonoFiltered(todos.concat(poligono));
      setSeccionesFiltered(todos.concat(secciones));
    }
    else {
      setMunicipioFiltered(catalogos.Municipios)
      setPoligonoFiltered(catalogos.poligono);
      setSeccionesFiltered(catalogos.secciones);
    }

  }

  const onChangeMunicipio = (idMun) => {
    setIsMovilizados(false)
    if (idMun > 0) {
      let secciones = catalogos.secciones.filter((item) => { return parseInt(item.idMunicipio) === parseInt(idMun) });
      let poligono = catalogos.poligono.filter((item) => { return parseInt(item.idMunicipio) === parseInt(idMun) });
      let todos = [{ label: "Seleccione", value: 0 }]
      setPoligonoFiltered(todos.concat(poligono));
      setSeccionesFiltered(todos.concat(secciones));
    }
    else {
      setPoligonoFiltered(catalogos.poligono);
      setSeccionesFiltered(catalogos.secciones);
    }

  }



  function onChangePoligono(idPoli) {

    if (idPoli > 0) {
      let secciones = catalogos.secciones.filter((item) => { return parseInt(item.idPoligono) === parseInt(idPoli) });
      let todos = [{ label: "Seleccione", value: 0 }]
      setSeccionesFiltered(todos.concat(secciones));
      setIsMovilizados(true)
    } else {
      setSeccionesFiltered(catalogos.secciones);
    }


  }

  const onChangeNombre = () => {

    var _filtro = {
      idRegion: 0,
      idMunicipioReportes: 0,
      idPoligono: 0,
      Seccion: 0,
      NombreCompleto: "",
      flag: 0
    };

    if (Datos.Region.value > 0) {
      _filtro.idRegion = Datos.Region.value;
      _filtro.flag = 1;
    }
    if (Datos.Municipios.value > 0) {
      _filtro.idMunicipioReportes = Datos.Municipios.value;
      _filtro.flag = 1;
    }
    if (Datos.Poligono.value > 0) {
      _filtro.idPoligono = Datos.Poligono.value;
      _filtro.flag = 1;
    }
    if (Datos.SeccionVota.value > 0) {
      _filtro.Seccion = Datos.SeccionVota.value;
      _filtro.flag = 1;
    }
    if (Datos.NombreCompleto !== "") {
      _filtro.NombreCompleto = Datos.NombreCompleto;
      _filtro.flag = 1;
    }


    if(_filtro.flag === 0){
      setMovilizadosFiltered([]);
    }else{
      setFiltro(_filtro);
      getMovilizado(_filtro);
    }
  }

  const onChangeClear = () => {
    var data = {
      Region: { label: "Seleccione", value: 0 },
      Municipios: { label: "Seleccione", value: 0 },
      Poligono: { label: "Seleccione", value: 0 },
      SeccionVota: { label: "Seleccione", value: 0 },
      NombreCompleto: "",
      flag: 0
    };

    var _filtro = {
      idRegion: 0,
      idMunicipioReportes: 0,
      idPoligono: 0,
      Seccion: 0,
      NombreCompleto: "",
      flag: 0
    };

    setFiltro(_filtro)
    setDatos(data);
    setMovilizadosFiltered([]);

  }




  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel ps ps--active-y" ref={mainPanel}>
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          {_menu.Ver === 1 ? (
            <>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5 className="title">Pase de Lista</h5>
                    <Col md="12">
                      {loading3 && (
                        <RotateSpinner
                          color={'#1261ff'}
                          size={30}
                          loading={loading3}
                        />
                      )}
                    </Col>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="4" md="4" sm="6">
                        <Card style={{ height: height - 299 }}>
                          <CardBody>
                          {
                                loading ?
                                
                                  <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                :
                            <FormGroup>
                              <Label className="labelForm">Región</Label>
                              <Select
                                options={catalogos.Region}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={Datos.Region}
                                onChange={(e) => {
                                  setDatos({
                                    ...Datos, Region: e, Municipios: { label: "Seleccione", value: 0 },
                                    Poligono: { label: "Seleccione", value: 0 },
                                    SeccionVota: { label: "Seleccione", value: 0 }
                                  });
                                  onChangeRegion(e.value)
                                }
                                }
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                isLoading={loading}
                                loadingMessage="Cargando filtro"
                              />
                              <div className="errorFields">
                              </div>
                            </FormGroup>
                             }
                               {
                                loading ?
                                
                                  <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                :
                            <FormGroup>
                              <Label className="labelForm">Municipio</Label>
                              <Select
                                options={municipioFiltered}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={Datos.Municipios}
                                onChange={(e) => {
                                  setDatos({
                                    ...Datos, Municipios: e,
                                    Poligono: { label: "Seleccione", value: 0 },
                                    SeccionVota: { label: "Seleccione", value: 0 }
                                  });
                                  onChangeMunicipio(e.value)
                                }
                                }
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                isLoading={loading}
                                loadingMessage="Cargando filtro"
                              />
                              <div className="errorFields">
                              </div>
                            </FormGroup>
                            }
                            {
                              loading ?
                              
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#686769"
                                  loading={loading}
                                />
                              :
                            <FormGroup>
                              <Label className="labelForm">Polígono</Label>
                              <Select
                                options={poligonoFiltered}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={Datos.Poligono}
                                onChange={(e) => {
                                  setDatos({ ...Datos, Poligono: e, SeccionVota: { label: "Seleccione", value: 0 } });
                                  setIsMovilizados(false)

                                  onChangePoligono(e.value)
                                }}
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                isLoading={loading}
                                loadingMessage="Cargando filtro"
                              />
                              <div className="errorFields">
                              </div>
                            </FormGroup>
                            }
                               {
                                loading ?
                                
                                  <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                :
                            <FormGroup>
                              <Label className="labelForm">Seccion</Label>
                              <Select
                                options={seccionesFiltered}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={Datos.SeccionVota}
                                onChange={(e) => {
                                  setDatos({ ...Datos, SeccionVota: e });
                                  setIsMovilizados(false)
                                }}
                                id="viewTypeM"
                                placeholder="Filtrar por"
                                isLoading={loading}
                                loadingMessage="Cargando filtro"
                              />
                              <div className="errorFields">
                              </div>
                            </FormGroup>
                            }
                               {
                                loading ?
                                
                                  <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                :
                            <FormGroup >
                              <Label for="NombreCompleto">Nombre o Celular:</Label>

                              <Input id="NombreCompleto" value={Datos.NombreCompleto}
                                onChange={(e) => {
                                  setDatos({ ...Datos, NombreCompleto: e.target.value })
                                }}
                              ></Input>
                              <div className="errorFields">
                              </div>
                            </FormGroup>
                            }
                               {
                                loading ?
                                
                                  <CircleSpinner
                                    className="text-center"
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                :
                            <FormGroup>
                              <InputGroup>
                                <InputGroupAddon addonType="append">
                                  <Button
                                    style={{ margin: '0px 1px', zIndex: 0 }}
                                    id="butonName"
                                    color="warning"
                                    onClick={e => {
                                      onChangeNombre()
                                    }}>
                                    <i className="fa fa-search fa-lg" />
                                  </Button>
                                  <Button
                                    style={{ margin: '0px 1px', zIndex: 0 }}
                                    id="butonName"
                                    color="danger"
                                    onClick={e => {
                                      onChangeClear()
                                    }}>
                                    <i className="fa fa-trash fa-lg" />
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                            }
                          </CardBody>
                        </Card>
                      </Col>
                      {dataTiempo.value === 1 &&
                        <Col lg="7" md="7" sm="7">
                          {
                            <Col md="12">
                              <MetroSpinner
                                color={'#1261ff'}
                                size={40}
                                loading={loading2}
                              />
                            </Col>
                          }
                          <Card style={{ height: height - 300 }}>
                            <CardHeader>
                              <Table>
                                <thead>
                                  <tr>
                                    <th className="text-center" style={{ width: "5%" }}>
                                      Asistencia
                                </th>
                                    <th className="text-center" style={{ width: "15%" }}>Nombre</th>
                                    <th className="text-center" style={{ width: "8%" }}>Responsabilidad</th>
                                    <th className="text-center" style={{ width: "4%" }}>Sección</th>
                                  </tr>
                                </thead>
                              </Table>
                            </CardHeader>
                            <CardBody height={300}>
                              <Table
                                width="100%"
                                style={{
                                  height: height - 500,
                                  overflow: 'scroll',
                                  display: 'block',
                                }}
                              >
                                {(MovilizadosFiltered.length > 0) ?
                                  <tbody>
                                    {MovilizadosFiltered.map((item, k) => {

                                      return (
                                        <tr key={`row_${k}`}>
                                          <td style={{ width: "5%" }} className="text-center">


                                            {(item.isPaseLista !== 0) ? <CustomInput type="checkbox" name="1" disabled checked={true}
                                            /> :
                                              <CustomInput
                                                key={k + 1}
                                                id={`check_${k + 1}`}
                                                checked={checkedArray[k]}
                                                onChange={(e) =>
                                                  onChangeCheck(e, item, k)

                                                }
                                                type="checkbox"
                                              />}
                                          </td>
                                          <td
                                            style={{ width: "10%" }}
                                            className="text-center"
                                          >
                                            {item.NombreCompleto}
                                          </td>
                                          <td style={{ width: "8%" }}
                                            className="text-center"
                                          >
                                            {item.ResponsabilidadData}
                                          </td>
                                          <td style={{ width: "4%" }}
                                            className="text-center"
                                          >
                                            {item.Seccion}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody> :
                                  <tbody>
                                    <tr>
                                      <td style={{ width: "50%" }}>
                                        Seleccione un filtro
                                      </td>
                                    </tr>
                                  </tbody>
                                }
                              </Table>
                            </CardBody>
                            <CardFooter>
                              <Row>
                                {_menu.Agregar === 1 && (
                                  <Col sm="12" md="12" lg="12" className='text-center text-center'>
                                    <>
                                      <FormGroup className="text-center">{/* 
                                        <Button
                                          style={{ marginTop: '30px' }}
                                          color="danger"
                                          onClick={() => ClearFilter()}
                                          size="sm"
                                        >
                                          Limpiar
                                  </Button> */}{' '}
                                        <Button
                                          style={{ marginTop: '30px' }}
                                          size="ml"
                                          color="primary"
                                          disabled={loading}
                                          onClick={(e) => btnSaveMovilizado()}
                                        //disabled={isDisabled}
                                        >
                                          Enviar
                                  </Button>
                                        {
                                          <Col>
                                            <RotateSpinner
                                              color={'#1261ff'}
                                              size={30}
                                              loading={loading}
                                            />
                                          </Col>
                                        }
                                      </FormGroup>
                                    </>
                                  </Col>
                                )}
                              </Row>
                            </CardFooter>
                          </Card>
                        </Col>}
                    </Row>
                  </CardBody>

                </Card>
              </Col>
            </>
          ) : (
              <>
                <Label>No tiene permisos para ver</Label>
              </>
            )}
        </div>
        <Footer fluid />
      </div>
    </div>
  );
}

export default PaseListaMovilizacion;
