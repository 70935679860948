import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
} from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import Footer from 'components/Footer';
import NumeraliaSegmento from 'components/Social/NumeraliaSegmento'
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import EjecutarApi from 'data/getPOST';
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from 'GlobalStorage';


const SocialNumeralia = (props) => {
  const [Estructura, setEstructura] = useState([]);

  const [totalEstructura, setTotalEstructura] = useState(0);
  const [Editar, setEditar] = useState({
    NombreSocial: '',
    Responsable: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isCompromiso, setIsCompromiso] = useState(false);
  const [compromisoEdit, setCompromisoEdit] = useState(0);
  const [bResponsable, setBResponsable] = useState([]);
  const [_menu, _setmenu] = useState({});
  const [isModalSocialAdd, setModalSocialAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = getVars('Token');
  const [ShowListado, setShowListado] = useState(false);
  const [Listado, setListado] = useState({});
  const [SocialParametro, setSocialParametro] = useState({
    sorted: [{ id: 'LastUpdate', desc: true }],
    NickName: null,
    EstructuraSocial: null,
    Responsable: null,
    page: 0,
    pageSize: 10
  })

  useEffect(() => {
    _setmenu(getDataMenu(23, token.menus));
    //llenadoArbol();
  }, []); //loadingTotal, loadingCompromisos

  useEffect(() => {
  }, [isEditing, isCompromiso])
  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const Exportar = () => {
    var url = new URL(URL_PUBLICA +
      `getReporteEstructuraSocial?token=${token.access_token}`
    );
    window.open(url, '_blank');
  };
  const handleEditar = (e, tipo) => {
    if (tipo === 'Edit') {
      setEditar({ idSocial: e.id, Data: e, Tipo: 'Edit' });
      setModalSocialAdd(true);
    } else if (tipo === 'Add') {
      setEditar({ idSocial: e.id, Data: e, Tipo: 'Add' });
      setIsEditing(true);
      //setModalSocialAdd(true)
    }
  };
  const handleAgregar = () => {
    setEditar({ idSocial: 0, Data: [] });
    setModalSocialAdd(true);
  };
  const handleBuscaResponsable = (e) => {
    setBResponsable(e);
    setIsEditing(true);

  };
  const handleFiltro = (filter) => {
    var _NickName = "";
    var _EstructuraSocial = "";
    var _Responsable = null;
    if (filter.NombreSocial) {
      _EstructuraSocial = filter.NombreSocial
    }
    if (filter.Nickname) {
      _NickName = filter.Nickname
    }
    if (filter.Responsable.NombreCompleto || filter.Responsable.Celular || filter.Responsable.Responsabilidad) {
      _Responsable = {
        NombreCompleto: filter.Responsable.NombreCompleto,
        Celular: filter.Responsable.Celular,
        Responsabilidad: filter.Responsable.Responsabilidad ? filter.Responsable.Responsabilidad.value : null
      };
    }

    setLoading(true);
    var _SocialParametro = {
      sorted: [{ id: 'LastUpdate', desc: true }],
      NickName: _NickName,
      EstructuraSocial: _EstructuraSocial,
      Responsable: _Responsable,
      page: 0,
      pageSize: 10
    };
    setSocialParametro(_SocialParametro);
    EjecutarApi(_SocialParametro, 'getStatsSociales', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          if (res.data) {
            setLoading(false);
            setTotalEstructura(res.total);
            setEstructura(
              res.data.map((prop) => {
                return {
                  id: prop.id,
                  NombreSocial: (
                    <>
                      <Button
                        name="bAddSubStructura"
                        color="primary"
                        size="sm"
                        onClick={() =>
                          handleBuscaResponsable(
                            prop,
                            'Edit'
                          )
                        }
                      >
                        <span className="btn-label">
                          <i className="fa fa-pencil fa-lg"></i>
                          {prop.NombreEstructura}
                        </span>
                      </Button>
                    </>
                  ),
                  NickName: prop.nickName,
                  Responsable: (<><Button onClick={() => onClickShowListado(prop.NombreEstructura, 'Coordinadores')} className="btn-link" color="primary">{prop.coordinadores}</Button></>),
                  Enlaces: (<><Button onClick={() => onClickShowListado(prop.NombreEstructura, 'Enlaces')} className="btn-link" color="primary">{prop.enlaces}</Button></>),
                  TotalCompromisosUnicos: (<><Button onClick={() => onClickShowListado(prop.NombreEstructura, 'Compromisos')} className="btn-link" color="primary">{prop.compromisosUnicos}</Button></>),
                  TotalCompromiso: (<><Button onClick={() => onClickShowListado(prop.NombreEstructura, 'Compromisos')} className="btn-link" color="primary">{prop.total}</Button></>),
                  data: prop,
                };
              })
            );
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  };
  const onClickShowListado = (Estructura, Tipo) => {
    setListado({ Estructura: Estructura, Tipo: Tipo })
    setShowListado(true)
  }
  const onClickEditarCompromiso = (idCompromiso) => {
    setIsEditing(true);
    setIsCompromiso(true);
    setCompromisoEdit(idCompromiso);
    setShowListado(false);
    setModalSocialAdd(false);
  }
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">

          <Row>
            <NumeraliaSegmento />
          </Row>

        </div>
        <Footer fluid />
      </div>
    </div>
  );
};

export default SocialNumeralia;
