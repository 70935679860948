import React, { useState, useEffect } from "react";
import { Map, TileLayer, Popup, Marker, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { MagicSpinner } from "react-spinners-kit";
import MarkerClusterGroup from "react-leaflet-markercluster";
import 'react-leaflet-markercluster/dist/styles.min.css';

import Notifications from 'react-notify-toast';
import { CardBody, Card, CardHeader, Row,Button, ButtonGroup } from "reactstrap";
import PluginFilterMap from "./ClusterFiles/PluginFilterMap";
import {SinPermisos} from "../ComponentesAuxiliares/SinPermisos";
import {centerOnOneShape} from "../ComponentesAuxiliares/AuxiliarFunctions";
let style_square_superior_left = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
    //'width':'200px'
}
let style_b = {
    'color': '#000000',
}
let style_button_radiogroup = {
    'padding': '2px 4px',
    'font': '10px/12px ',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px'
}
let style_square_superior = {
    'padding': '6px 8px',
    'font': '14px/16px Arial, Helvetica, sans-serif',
    'background': 'rgba(255,255,255,0.8)',
    'boxShadow': '0 0 15px rgba(0,0,0,0.2)',
    'borderRadius': '5px',
}

const SimpleMap = ({ center, zoom, derecho, data,_flagFinish,handleFiltro,MenuPermisos, handleTipoMapa, Shape, TipoMapa,handleDetalleMapa, Responsabilidad }) => {


    const [_zoom, setZoom] = useState(zoom ? zoom : 5);
    const [_center, setCenter] = useState(center ? center : [20.816157, -101.021312]);
    const puntos = data;
    //const [resumen] = useState([]);
    //const [leyendaResumen] = useState('GENERAL');
    const _data = Shape ? Shape : null;
    const flag = Object.keys(_data).length === 0 ? false : true;
    //const DataFiltroGeoJson 
    const [tipoMapa, setTipoMapa] = useState({ value: TipoMapa.value, label: TipoMapa.label});
    const flagFinish = _flagFinish;
    const _derecho_mapa = derecho;
    const _tipo = TipoMapa.value ? TipoMapa.value : 'seccion';
    const [buttonDetalle, setButonDetalle] = useState(false);
    const [flagData] = useState(true);
    const [prop, setProp] = useState(
        TipoMapa.value === 'municipio' ?
            { 'nom_mun': '', 'cve_mun': '' }
            : TipoMapa.value === 'region' ?
                { 'region': '' } : TipoMapa.value === 'seccion' ? { 'seccion': '' } : { 'poligono': '' }
    );
    function roundTo(value, places) {
        var power = Math.pow(10, places);
        return Math.round(value * power) / power;
    }
    let style_spinner = {
        'height': '500pt',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center'
    }
    const green_icon = new L.Icon({

        iconUrl: require('./ClusterFiles/green_icon.png'),
        iconSize: [40, 45], // tamaño del icono
        //shadowSize:   [50, 64], // tamaño de la sombra
        iconAnchor: [20, 31] // punto del icono que corresponde a la posición del marcador
        //popupAnchor:  [0, -40] // punto relativo al marcador desde donde se deberá abrir el popup
    });
    const red_icon = new L.Icon({

        iconUrl: require('./ClusterFiles/red_icon.png'),
        iconSize: [40, 45], // tamaño del icono
        //shadowSize:   [50, 64], // tamaño de la sombra
        iconAnchor: [20, 31] // punto del icono que corresponde a la posición del marcador
        //popupAnchor:  [0, -40] // punto relativo al marcador desde donde se deberá abrir el popup
    });
    useEffect(() => {
        setTipoMapa(TipoMapa);
        if (Object.keys(_data).length !== 0) {
            if(_data.features.length !== 0){
                const {zoom, center} = centerOnOneShape(flagData,_data);
                setCenter(center);
                setZoom(zoom);
            }
        }
    }, [_flagFinish,data,Shape,TipoMapa])

    /* FUNCIONES DEL CHOROPETH MAP*/
    function style(feature, flag) {
        let style;
        if (flag === undefined || flag !== true) {
            style = {
                fillColor: feature.properties.color_actas,
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.3
            };
        }
        else {
            style = {
                weight: 5,
                color: '#666',
                dashArray: '',
                fillOpacity: 0.5
            };

        }
        return style;
    }



    const highlightFeature = (e) => {
        //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.

        var layer = e.target;
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
        //Si seteamos todo esto se va a renderizar por que es un estado.
        /* setProp({
            'nom_mun':e.target.feature.properties.nom_mun,
            'cve_mun':e.target.feature.properties.cve_mun,
            'avance_porcentual':e.target.feature.properties.avance_porcentual,
            'color':e.target.feature.properties.color,
            'avance':e.target.feature.properties.avance,
            'meta':e.target.feature.properties.meta
        }); */
        
        var text;
        if(layer.feature.properties.region !== undefined){
            text = "REGIÓN " + layer.feature.properties.region;
            setProp({
                'region': e.target.feature.properties.region,
                'avance_actas': e.target.feature.properties.avance_actas,
                'color_actas': e.target.feature.properties.color_actas,
                'total_ca': e.target.feature.properties.totalcasillas_con_actas,
                'total_sa': e.target.feature.properties.totalcasillas_sin_actas,
                'total': e.target.feature.properties.totalcasillas,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e
            });
        }
        else if(layer.feature.properties.nom_mun !== undefined){
            text = "" + layer.feature.properties.nom_mun;
            setProp({
                'nom_mun': e.target.feature.properties.nom_mun,
                'cve_mun': e.target.feature.properties.cve_mun,
                'avance_actas': e.target.feature.properties.avance_actas,
                'color_actas': e.target.feature.properties.color_actas,
                'total_ca': e.target.feature.properties.totalcasillas_con_actas,
                'total_sa': e.target.feature.properties.totalcasillas_sin_actas,
                'total': e.target.feature.properties.totalcasillas,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e
            
            });
        }
        else{
            text = "SECCIÓN " + layer.feature.properties.seccion;
            setProp({
                'seccion': e.target.feature.properties.seccion,
                'avance_actas': e.target.feature.properties.avance_actas,
                'color_actas': e.target.feature.properties.color_actas,
                'total_ca': e.target.feature.properties.totalcasillas_con_actas,
                'total_sa': e.target.feature.properties.totalcasillas_sin_actas,
                'total': e.target.feature.properties.totalcasillas,
                'lnom': e.target.feature.properties.lnom,
                'prop_save': e
            });
        }
        
        layer.bindTooltip(text + "", { permanent: false, direction: "center" });
        layer.setStyle(style(layer.feature, true));
    }

    const clickFeature = (e) => {
        setButonDetalle(true);
        highlightFeature(e);
       
        //Aqui configuramos lo que queremos que haga un feature cuando le den click.
        
    }

    const clickDetalle = (e) => {
        setButonDetalle(false);
        if (e.latlng) {
            setCenter([e.latlng.lat, e.latlng.lng]);
        }


        var siguiente_nivel = "region";
        var siguiente_nivel_label = "Regional";
        var siguiente_nivel_clave = "";

        if (tipoMapa.value === "region") {
            setZoom(_zoom + 1);
            siguiente_nivel = "municipio";
            siguiente_nivel_label = "Municipal";
            siguiente_nivel_clave = e.target.feature.properties.region;
        }
        if (tipoMapa.value === "municipio") {
            setZoom(_zoom + 1);
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Seccional";
            siguiente_nivel_clave = e.target.feature.properties.cve_mun;
        }
        if (tipoMapa.value === "seccion") {
            siguiente_nivel = "seccion";
            siguiente_nivel_label = "Seccional";
            //siguiente_nivel = "manzana";
            //siguiente_nivel_label = "Manzanas";
            //siguiente_nivel_clave = e.target.feature.properties.seccion; 
            return; //CLICK NO PROGRAMADO
        }
        handleDetalleMapa({ value: siguiente_nivel, label: siguiente_nivel_label, clave: siguiente_nivel_clave });
        setTipoMapa({ value: siguiente_nivel, label: siguiente_nivel_label });
    }

    const resetHighlight = (e) => {
        //Con esto reseteamos el color en donde paso el mouse, para que no siga marcado.
        var layer = e.target;
        layer.setStyle(style(e.target.feature));
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
    }

    const onEachFeature = (feature, layer) => {
        //Organizamos los eventos del GeoJson.
        layer.on({
            //mouseout: resetHighlight,
            click: clickFeature
        });
        var text;
        if(layer.feature.properties.region !== undefined){
            text = "REGIÓN " + layer.feature.properties.region;
        }
        else if(layer.feature.properties.nom_mun !== undefined){
            text = "" + layer.feature.properties.nom_mun;
        }
        else{
            text = "SECCIÓN " + layer.feature.properties.seccion;
        }
        
        layer.bindTooltip(text + "", { permanent: false, direction: "center" });
    }

    var geojson = (
        <GeoJSON onEachFeature={onEachFeature} style={style} key={"geojson"} data={_data}></GeoJSON>
    )
    /* FUNCIONES DEL CHOROPETH MAP*/
    const setFilter = (filtered) => {
        handleFiltro(filtered,_tipo);
    }
    const miTipoMapaChange = (e) => {
        if (e.value !== 'poligono' && e.value !== 'seccion') {
            setCenter([20.917639, -101.091132]);
            setZoom(9);
        }
        
        setTipoMapa(e);
        handleTipoMapa(e);
    }

    return (
        <>
            <Notifications />
            {
                /* puntos.lenght !== 0 && */ flagFinish && flag ?
                MenuPermisos.Ver === 1 ?
                <>
                    <Map
                        className="markercluster-map"
                        center={_center}
                        zoom={_zoom}
                        style={{ width: "100%", height: "90vh" }}>
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MarkerClusterGroup
                        >
                            {puntos.map((dato, key) => {

                                if (dato.Latitude !== null && dato.Longitude !== null && dato.Latitude.length > 5 && dato.Longitude.length > 5) {

                                    return (
                                        <Marker
                                            icon={dato.TieneActas === 1 ? green_icon : red_icon}
                                            style={{
                                                'background-color': '#1c9489 !important'
                                            }} 
                                            key={"Marker_" + dato.data.Seccion + "" + key} position={[dato.Latitude.replace(',', ''), dato.Longitude.replace(',', '')]}>{/* [dato.Latitude,dato.Longitude]}> */}
                                            <Popup>
                                                <Card>
                                                    <CardHeader>

                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <strong>Casilla: </strong>{dato.Casilla}
                                                        </Row>
                                                        <Row>
                                                            <strong>Tipo Casilla: </strong>{dato.TipoCasilla}
                                                        </Row>
                                                        <Row>
                                                            <strong>Tiene Actas: </strong>{dato.TieneActas === 1? "Si": "No"}
                                                        </Row>
                                                        <Row>
                                                            <strong>Propietario 1: </strong>{dato.idRCPropietario1 !== null && dato.idRCPropietario1 !== undefined ? dato.RCPropietario1: "No Asignado"}
                                                        </Row>
                                                        <Row>
                                                            <strong>Propietario 2: </strong>{dato.idRCPropietario2 !== null && dato.idRCPropietario2 !== undefined ? dato.RCPropietario2: "No Asignado"}
                                                        </Row>
                                                        <Row>
                                                            <strong>Suplente 1: </strong>{dato.idRCSuplente1 !== null && dato.idRCSuplente1 !== undefined ? dato.idRCSuplente1: "No Asignado"}
                                                        </Row>
                                                        <Row>
                                                            <strong>Suplente 2: </strong>{dato.idRCSuplente2 !== null && dato.idRCSuplente2 !== undefined ? dato.idRCSuplente2: "No Asignado"}
                                                        </Row>
                                                        <Row>
                                                            <strong>LNOM: </strong>{dato.LNOM}
                                                        </Row>
                                                        <Row>
                                                            <strong>Localida y Manzana: </strong>{dato.LocalidadManzana}
                                                        </Row>
                                                        <Row>
                                                            <strong>Seccion: </strong>{dato.Seccion}
                                                        </Row>
                                                        <Row>
                                                            <strong>Tipo Seccion: </strong>{dato.TipoSeccion}
                                                        </Row>
                                                        <Row>
                                                            <strong>Domicilio: </strong>{dato.Domicilio}
                                                        </Row>
                                                        <Row>
                                                            <strong>Tipo Domicilio: </strong>{dato.TipoDomicilio}
                                                        </Row>
                                                        <Row>
                                                            <strong>Municipio: </strong>{dato.Municipio}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Popup>
                                        </Marker>

                                    )
                                }
                            })
                            }
                        </MarkerClusterGroup>
                        {
                            geojson
                        /* DataFiltroGeoJson.length === 0 ? geojson :
                            DataFiltroGeoJson.map((dato, i) => {
                                return (
                                    <GeoJSON key={"GEOJSON_" + i} onEachFeature={onEachFeature} style={style} data={dato}></GeoJSON>
                                )

                            })
 */
                        }

                        <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-left">
                                <div className="leaflet-control" style={style_square_superior_left}>


                                    <ButtonGroup style={{ backgroundColor: '#FFF' }}>
                                        {_derecho_mapa.includes(4) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'region' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "region", label: "Regional" })}
                                            >
                                                Región
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(3) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'municipio' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "municipio", label: "Municipal" })}
                                            >
                                                Municipio
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(2) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'poligono' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "poligono", label: "Poligonal" })}
                                            >
                                                Polígono
                                            </Button>
                                            :
                                            <></>}
                                        {_derecho_mapa.includes(1) ?
                                            <Button
                                                className="btn-round"
                                                color={tipoMapa.value === 'seccion' ? 'success' : 'info'}
                                                outline
                                                type="button"
                                                onClick={() => miTipoMapaChange({ value: "seccion", label: "Seccional" })}
                                            >
                                                Seccion
                                            </Button>
                                            :
                                            <></>}
                                    </ButtonGroup>

                                </div>
                            </div>
                        </div>

                        <div className="leaflet-control-container">
                            <div className="leaflet-top leaflet-right">
                                <div className="leaflet-control" style={style_square_superior}>
                                {
                                        (_tipo === 'municipio') ?
                                            <>
                                                <h6>INFORMACIÓN:</h6>
                                                {(prop.nom_mun !== '') ?
                                                    <>
                                                        <b style={style_b}>Municipio:</b><b style={{ color: prop.color_actas }}> {prop.nom_mun}</b><br></br>
                                                        <b style={style_b}>LNOM:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                        <b style={style_b}>Total de Casillas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total)}</b><br></br>
                                                        <b style={style_b}>Total de Casillas con Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_sa)}</b><br></br>
                                                        <b style={style_b}>Total de Casillas sin Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_ca)}</b><br></br>
                                                        <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color_actas }}>{prop.total_ca === 0 && prop.total === 0 ? "--" : roundTo(prop.avance_actas, 2) + "%"}</b><br></br>
                                                    </>
                                                    : <b> Pulse sobre un municipio</b>}
                                            </>
                                            :
                                            (_tipo === 'region') ?
                                                <>
                                                    <h6>INFORMACIÓN:</h6>
                                                    {(prop.region !== '') ?
                                                        <>
                                                            <b style={style_b}>Región:</b><b style={{ color: prop.color_actas }}> {prop.region}</b><br></br>
                                                            <b style={style_b}>LNOM:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                            <b style={style_b}>Total de Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total)}</b><br></br>
                                                            <b style={style_b}>Total de Casillas con Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_ca)}</b><br></br>
                                                            <b style={style_b}>Total de Casillas sin Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_sa)}</b><br></br>
                                                            <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color_actas }}>{prop.total_ca === 0 && prop.total === 0 ? "--" : roundTo(prop.avance_actas, 2) + "%"}</b><br></br>

                                                        </>
                                                        : <b> Pulse sobre una region</b>}
                                                </>
                                                :
                                                (_tipo === 'seccion') ?
                                                    <>
                                                        <h6>INFORMACIÓN:</h6>
                                                        {(prop.seccion !== '') ?
                                                            <>
                                                                <b style={style_b}>Sección:</b><b style={{ color: prop.color_actas }}> {prop.seccion}</b><br></br>
                                                                <b style={style_b}>LNOM:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                <b style={style_b}>Total de Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total)}</b><br></br>
                                                                <b style={style_b}>Total de Casillas con Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_ca)}</b><br></br>
                                                                <b style={style_b}>Total de Casillas sin Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_sa)}</b><br></br>
                                                                <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color_actas }}>{prop.total_ca === 0 && prop.total === 0 ? "--" : roundTo(prop.avance_actas, 2) + "%"}</b><br></br>

                                                            </>
                                                            : <b> Pulse sobre una seccion</b>}
                                                    </>
                                                    :
                                                    <>
                                                        <h6>INFORMACIÓN:</h6>
                                                        {(prop.poligono !== '') ?
                                                            <>
                                                                <b style={style_b}>Polígono:</b><b style={{ color: prop.color_actas }}> {prop.pg_poligon}</b><br></br>
                                                                <b style={style_b}>LNOM:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.lnom)}</b><br></br>
                                                                <b style={style_b}>Total de Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total)}</b><br></br>
                                                                <b style={style_b}>Total de Casillas con Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_ca)}</b><br></br>
                                                                <b style={style_b}>Total de Casillas sin Actas:</b><b style={{ color: prop.color_actas }}> {new Intl.NumberFormat('en-EN').format(prop.total_sa)}</b><br></br>
                                                                <b style={style_b}>Porcentaje de Avance:</b><b style={{ color: prop.color_actas }}>{prop.total_ca === 0 && prop.total === 0 ? "--" : roundTo(prop.avance_actas, 2) + "%"}</b><br></br>

                                                            </>
                                                            : <b> Pulse sobre un poligono</b>}
                                                    </>

                                    }
                                    {
                                    buttonDetalle && prop.seccion === undefined? 
                                    <ButtonGroup>
                                        <Button
                                        style={style_button_radiogroup}
                                        size ="sm"
                                        className="btn btn-sm btn-round"
                                        color={'success'}
                                        outline
                                        type="button" 
                                        onClick={(e)=>{clickDetalle(prop.prop_save)}}>Ver Detalle</Button>
                                    </ButtonGroup>:<></>
                                    }
                                </div>
                            </div>
                        </div>

                    </Map>
                    </>
                    :
                    <SinPermisos></SinPermisos>
                    :

                    <div style={style_spinner}>
                        <MagicSpinner size={70} color={"#008000"} loading={true}></MagicSpinner>
                    </div>
            }
            <PluginFilterMap
                /* isLoading={isLoading} */
                flagFinish={_flagFinish}
                filtrar={setFilter}
                clasess="dropdowns"
                Responsabilidad={Responsabilidad}
                derechoCombo={[1,2,4]}
            />
        </>

    );
};
export default SimpleMap;