import React from "react";

import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official';

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
})

//HC_exporting(Highcharts);


const BarraChart = ({ Categorias, Series, TituloMpio,Titulo }) => {
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: Titulo
        },
        credits: {
            enabled: false
        },
        subtitle: {
            text: TituloMpio
        },
        xAxis: {
            categories: Categorias,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y} mm</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: { fontSize: "8px" }
                }
            },
        },
        series: Series
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            type='column'
        />
    )
}

export default BarraChart;