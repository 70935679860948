import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import Notifications, { notify } from 'react-notify-toast';
import ReactTable from 'react-table';
import { CircleSpinner } from 'react-spinners-kit';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Pagination from 'components/Pagination/pagination';
import EjecutarApi from 'data/getPOST';
import { getDataMenu } from 'data/menus.js';
import { getVars, URL_PUBLICA } from 'GlobalStorage';
import ModalFiltro from 'components/Filtro/indexVisualizacionCasillas';
import InfoResponsable from 'components/Casillas/InfoResponsable';
import AsignacionResponsable from 'components/Casillas/AsignacionResponsable';
import ChartCasilla from 'components/Charts/ElectoralCasillas/ChartCasillas';
import { numberWithCommas } from '../../utils/funciones';

const Casillas = (props) => {
  const [compromisos, setCompromisos] = useState([]);
  const [total, setTotal] = useState({
    totalCasillas: 0,
    P1: { total: 0, asignados: 0, porcentaje: 0 },
    P2: { total: 0, asignados: 0, porcentaje: 0 },
    S1: { total: 0, asignados: 0, porcentaje: 0 },
    S2: { total: 0, asignados: 0, porcentaje: 0 },
  });
  const [totalCompromisos, setTotalCompromisos] = useState(0);
  const [_menu, _setmenu] = useState({});
  const [Filter, setFiltro] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getVars('Token');
  const [MenuModal] = useState({
    Compromiso: true,
    Voluntarios: false,
    RCs: false,
    RGs: false,
    Abogado: false,
    CasaAzul: false,
    Municipio: false,
  });
  const [detalleResponsableActivo, setDetalleResponsableActivo] = useState(
    false
  );
  const [isLoadingResponsable, setIsLoadingResponsable] = useState(false);
  const [responsable, setResponsable] = useState({});
  const [
    modalAsignarResponsableActivo,
    setModalAsignarResponsableActivo,
  ] = useState(false);
  const [voluntarios, setVoluntarios] = useState([]);
  const [isLoadingAsignacion, setIsLoadingAsignacion] = useState(false);
  const [casilla, setCasilla] = useState({});
  const [tipoResponsable, setTipoResponsable] = useState('');
  const [errorsForm, setErrorsForm] = useState('');
  const [loadingChart, setLoadingChart] = useState(true);
  const [grafica, setGrafica] = useState({
    data: {
      title: `Avance`,
      xAxis: {
        categories: [1, 2, 3],
      },
      yAxis: [
        {
          min: 0,
          title: {
            text: 'titulo',
          },
        },
        {
          title: {
            text: `Avance`,
          },
          opposite: true,
        },
      ],
    },
    series: [
      {
        name: 'Meta',
        color: 'rgba(165,170,217,1)',
        data: [1, 2, 3],
        pointPadding: 0.3,
        pointPlacement: -0.2,
      },
      {
        name: 'Avance',
        color: 'rgba(126,86,134,.9)',
        data: [1, 2, 3],
        pointPadding: 0.4,
        pointPlacement: -0.2,
      },
    ],
  });

  useEffect(() => {
    let _menuStorage = getDataMenu(138, token.menus);
    _setmenu(_menuStorage)
    EjecutarApi({}, 'getTotalVisualizacionCasillas', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setTotal(res.data);
      })
      .catch((e) => {
        notify.show(`Error en totales: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  }, []);

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  const Export = () => {

    const data = {
      filtered: Filter,
      Export: true
    };

    var datos = JSON.stringify(data)
    var url = new URL(
        `${URL_PUBLICA}getReporteCasilla?token=${token.access_token}&serializar=${datos}`
    );
    window.open(url, "_blank");
    
  }

  const handleFiltro = (filter) => {
    setLoading(true);
    var _filtro = [];
    if (filter.Region.value > 0) {
      _filtro.push({ id: 'C.idRegion', value: filter.Region.value });
    }
    if (filter.Municipio.value > 0) {
      _filtro.push({
        id: 'C.idMunicipioReportes',
        value: filter.Municipio.value,
      });
    }
    if (filter.Seccion.value > 0) {
      _filtro.push({ id: 'C.Seccion', value: filter.Seccion.value });
    }
    if (filter.P1.value > -1) {
      _filtro.push({ id: 'SinP1', value: filter.P1.value });
    }
    if (filter.P2.value > -1) {
      _filtro.push({ id: 'SinP2', value: filter.P2.value });
    }
    if (filter.S1.value > -1) {
      _filtro.push({ id: 'SinS1', value: filter.S1.value });
    }
    if (filter.S2.value > -1) {
      _filtro.push({ id: 'SinS2', value: filter.S2.value });
    }
    setFiltro(_filtro);
    //Ejecutando Filtro
    const data = {
      page: 0,
      pageSize: 10,
      sorted: [],
      filtered: _filtro,
      tipo: 'and',
    };
    getRegistros(data);
  };

  const columns = [
    {
      Header: 'Región',
      minWidth: 20,
      id: 'Region',
      accessor: (d) => d.Region,
      filterAll: false,
    },
    {
      Header: 'Municipio',
      minWidth: 35,
      id: 'Municipio',
      accessor: (d) => d.Municipio,
      filterAll: false,
    },
    {
      Header: 'Sección',
      minWidth: 30,
      id: 'Seccion',
      accessor: (d) => d.Seccion,
      filterAll: false,
    },
    {
      Header: 'Casilla',
      minWidth: 30,
      id: 'NombreCasilla',
      accessor: (d) => d.NombreCasilla,
      filterAll: false,
    },
    {
      Header: 'P1',
      accessor: (d) => d.idP1,
      minWidth: 30,
      id: 'idP1',
      filterable: false,
    },
    {
      Header: 'P2',
      accessor: (d) => d.idP2,
      minWidth: 30,
      id: 'idP2',
      filterable: false,
    },
    {
      Header: 'S1',
      accessor: (d) => d.idS1,
      minWidth: 30,
      id: 'idS1',
      filterable: false,
    },
    {
      Header: 'S2',
      accessor: (d) => d.idS2,
      minWidth: 30,
      id: 'idS2',
      filterable: false,
    },
  ];
  useEffect(() => {
    EjecutarApi({}, 'getTotalVisualizacionCasillas', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotal(res.data);
          const data = {
            title: `Estructura de Casillas`,
            xAxis: {
              categories: [
                'Propietario 1',
                'Propietario 2',
                'Suplente 1',
                'Suplente 2',
              ],
              title: {
                text: 'Tipo de Propietario',
              },
            },
            yAxis: [
              {
                min: 0,
                title: {
                  text: `Total Casillas`,
                },
              },
            ],
          };
          const series = [
            {
              name: 'Total Casillas',
              color: 'rgba(147,220,236,.9)',
              data: [
                res.data.totalCasillas,
                res.data.totalCasillas,
                res.data.totalCasillas,
                res.data.totalCasillas,
              ],
              pointPadding: 0.3,
              pointPlacement: -0.2,
            },
            {
              name: 'Total Propietarios',
              color: '#0041A0',
              data: [
                res.data.P1.total,
                res.data.P2.total,
                res.data.S1.total,
                res.data.S2.total,
              ],
              pointPadding: 0.4,
              pointPlacement: -0.2,
            }
          ];
          setGrafica({
            data: data,
            series: series,
          });
        }
        setLoadingChart(false);
      })
      .catch((e) => {
        notify.show(`Error en totales: ${e.error}`, 'custom', 5000, {
          background: '#0857B6',
          fontSize: 14,
          text: '#FFFFFF',
        });
      });
  }, []);
  const [isChartView, setIsChartView] = useState(true);

  const fetchData = useCallback(({ pageSize, page, sorted,params }) => {
    const data = {
      page: page,
      pageSize: pageSize,
      filtered: params,
      sorted: sorted,
      idResponsabilidad: 2,
      tipo: 'and',
    };
    getRegistros(data);
  }, []);

  const getRegistros = (data) => {
    setLoading(true);
    EjecutarApi(data, 'getCasillasByListado', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setLoading(false);
        setTotalCompromisos(res.total);
        setCompromisos(
          res.data.map((prop, key) => {
            return {
              Seccion: prop.Seccion,
              Region: prop.Region,
              Municipio: prop.Municipio,
              NombreCasilla: prop.NombreCasilla ? prop.NombreCasilla : 'null',
              idP1:
                prop.idP1 != null ? (
                  <>
                    <Button
                      className="btn-round"
                      size="sm"
                      color="primary"
                      onClick={() => getInfoResponsable(prop.idP1)}
                    >
                      Asignado
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="btn-round"
                      size="sm"
                      color="danger"
                      onClick={() =>
                        getVoluntariosResponsables(prop, {
                          value: 1,
                          label: 'Propietario1',
                        })
                      }
                    >
                      Asignar
                    </Button>
                  </>
                ),
              idP2:
                prop.idP2 != null ? (
                  <>
                    <Button
                      className="btn-round"
                      size="sm"
                      color="primary"
                      onClick={() => getInfoResponsable(prop.idP2)}
                    >
                      Asignado
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="btn-round"
                      size="sm"
                      color="danger"
                      onClick={() =>
                        getVoluntariosResponsables(prop, {
                          value: 2,
                          label: 'Propietario2',
                        })
                      }
                    >
                      Asignar
                    </Button>
                  </>
                ),
              idS1:
                prop.idS1 != null ? (
                  <>
                    <Button
                      className="btn-round"
                      size="sm"
                      color="primary"
                      onClick={() => getInfoResponsable(prop.idS1)}
                    >
                      Asignado
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="btn-round"
                      size="sm"
                      color="danger"
                      onClick={() =>
                        getVoluntariosResponsables(prop, {
                          value: 3,
                          label: 'Suplente1',
                        })
                      }
                    >
                      Asignar
                    </Button>
                  </>
                ),
              idS2:
                prop.idS2 != null ? (
                  <>
                    <Button
                      className="btn-round"
                      size="sm"
                      color="primary"
                      onClick={() => getInfoResponsable(prop.idS2)}
                    >
                      Asignado
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="btn-round"
                      size="sm"
                      color="danger"
                      onClick={() =>
                        getVoluntariosResponsables(prop, {
                          value: 4,
                          label: 'Suplente1',
                        })
                      }
                    >
                      Asignar
                    </Button>
                  </>
                ),
              prop,
            };
          })
        );
      })
      .catch((e) => {
        setLoading(false);
        notify.show(
          `Error al obtener getCasillasByListado: ${e.error}`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const getInfoResponsable = (idResponsable) => {
    setIsLoadingResponsable(true);
    setDetalleResponsableActivo(true);
    EjecutarApi(
      { idRC: idResponsable },
      'getDetalleResponsable',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setIsLoadingResponsable(false);
          setResponsable(res.data);
        } else {
          setIsLoadingResponsable(false);
          setDetalleResponsableActivo(false);
          notify.show(
            `Error al obtener los detalles del responsable: ${res.message}`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        }
      })
      .catch((e) => {
        setIsLoadingResponsable(false);
        setDetalleResponsableActivo(false);
        notify.show(
          `Error al obtener los detalles del responsable: ${e.error}`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const getVoluntariosResponsables = (
    casilla,
    tipoResponsable,
    filtro = []
  ) => {
    setCasilla(casilla);
    setTipoResponsable(tipoResponsable);
    setIsLoadingResponsable(true);
    setModalAsignarResponsableActivo(true);
    EjecutarApi(
      { Seccion: casilla.Seccion, filtered: filtro },
      'getVoluntariosCasilla',
      token.access_token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setIsLoadingResponsable(false);
          setVoluntarios(res.data);
        } else {
          setIsLoadingResponsable(false);
          setDetalleResponsableActivo(false);
          notify.show(
            `Error al obtener los detalles del responsable: ${res.message}`,
            'custom',
            5000,
            {
              background: '#0857B6',
              fontSize: 14,
              text: '#FFFFFF',
            }
          );
        }
      })
      .catch((e) => {
        setIsLoadingResponsable(false);
        setModalAsignarResponsableActivo(false);
        notify.show(
          `Error al obtener los detalles del responsable: ${e.error}`,
          'custom',
          5000,
          {
            background: '#0857B6',
            fontSize: 14,
            text: '#FFFFFF',
          }
        );
      });
  };

  const asignarRC = (RC) => {
    //RC.UserOwned = token.user.id;
    setIsLoadingAsignacion(true);
    EjecutarApi(RC, 'crearPropuestaElectoral', token.access_token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setModalAsignarResponsableActivo(false);
          const data = {
            page: 0,
            pageSize: 10,
            sorted: [],
            filtered: [],
            tipo: 'and',
          };
          getRegistros(data);
          notify.show('Responsable asignado con exito', 'success', 5000);
        } else {
          setErrorsForm(`Error al crear el registro: ${res.message}!!`);
        }
        setIsLoadingAsignacion(false);
      })
      .catch((e) => {
        setErrorsForm(`Error al agregar la Responsabilidad: ${e.error}`);
        setIsLoadingAsignacion(false);
      });
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>{/* 
            <Card className="card-stats">
              <CardBody> */}
                <Col sm="12">
                  {isChartView &&
                    (!loadingChart ? (
                      <ChartCasilla
                        datos={grafica.series}
                        Titulos={grafica.data}
                      />
                    ) : (
                      <Row>
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loadingChart}
                        />
                      </Row>
                    ))}
                </Col>{/* 
              </CardBody>
            </Card> */}
            {/* <Col lg="4" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fa fa-users text-success" />
                                    </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Casillas</p>
                                        <CardTitle tag="p">{`${total.totalCasillas} De ${totalCompromisos}`}</CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                                </CardBody>
                                <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-calendar-o" />
                                    {`${new Intl.NumberFormat().format(totalCompromisos)}`}
                                </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fa fa-users text-success" />
                                    </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Propietario 1</p>
                                        <CardTitle tag="p">{`${total.P1.asignados} de ${total.P1.total}`}</CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                                </CardBody>
                                <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-calendar-o" />
                                    {`${new Intl.NumberFormat().format(total.P1.porcentaje)}%`}
                                </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fa fa-users text-success" />
                                    </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Propietario 2</p>
                                        <CardTitle tag="p">{`${total.P2.asignados} de ${total.P2.total}`}</CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                                </CardBody>
                                <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-calendar-o" />
                                    {`${new Intl.NumberFormat().format(total.P2.porcentaje)}%`}
                                </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fa fa-users text-success" />
                                    </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Suplente 1</p>
                                        <CardTitle tag="p">{`${total.S1.asignados} de ${total.S1.total}`}</CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                                </CardBody>
                                <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-calendar-o" />
                                    {`${new Intl.NumberFormat().format(total.S1.porcentaje)}%`}
                                </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fa fa-users text-success" />
                                    </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Suplente 2</p>
                                        <CardTitle tag="p">{`${total.S2.asignados} de ${total.S2.total}`}</CardTitle>
                                        <p />
                                    </div>
                                    </Col>
                                </Row>
                                </CardBody>
                                <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-calendar-o" />
                                    {`${new Intl.NumberFormat().format(total.S2.porcentaje)}%`}
                                </div>
                                </CardFooter>
                            </Card>
                        </Col> */}
          </Row>
          {detalleResponsableActivo && (
            <InfoResponsable
              responsable={responsable}
              isOpen={detalleResponsableActivo}
              setIsOpen={() =>
                setDetalleResponsableActivo(!detalleResponsableActivo)
              }
              isLoading={isLoadingResponsable}
            />
          )}
          {modalAsignarResponsableActivo && (
            <AsignacionResponsable
              voluntarios={voluntarios}
              isOpen={modalAsignarResponsableActivo}
              casilla={casilla}
              tipoResponsable={tipoResponsable}
              setIsOpen={() =>
                setModalAsignarResponsableActivo(!modalAsignarResponsableActivo)
              }
              asignarRC={asignarRC}
              isLoading={isLoadingResponsable}
              isLoadingAsignacion={isLoadingAsignacion}
              errorsForm={errorsForm}
              setErrorsForm={setErrorsForm}
            />
          )}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                  {_menu.Exportar === 1 ? (
                    <Button color="primary" size="sm" onClick={() => Export()}>
                      <span className="btn-label">
                        <i className="fa fa-file-excel-o fa-lg" />
                      </span>
                      Exportar
                    </Button>
                     ) : (<></>)}
                     
                  </Row>
                  <Row>
                    <Col sm="12">
                      <ModalFiltro
                        Menu={MenuModal}
                        Datos={(e) => handleFiltro(e)}
                      />
                    </Col>
                  </Row>
                  <div style={{ width: '100%', overflow: 'auto' }}>
                    <ReactTable
                      style={{
                        width: '110%',
                        height: '100%',
                      }}
                      data={compromisos}
                      noDataText="Sin información"
                      manual
                      columns={columns}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={totalCompromisos}
                      pages={Math.floor(totalCompromisos / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      PaginationComponent={Pagination}
                      onFetchData={fetchData}
                      params={Filter}
                    />
                  </div>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};

export default Casillas;